import { ReportSummaryCellConfig, ReportSummaryTableOptions } from '@hcs/types';

import { REPORT_SUMMARY_AMOUNT_ORDERED_CONFIG } from './AmountOrdered';
import { REPORT_SUMMARY_COMPLETED_DATE_CONFIG } from './CompletedDate';
import { REPORT_SUMMARY_DOWNLOAD_CONFIG } from './Download';
import { REPORT_SUMMARY_LABEL_CONFIG } from './Label';
import { REPORT_SUMMARY_ORDER_CONFIG } from './Order';
import { REPORT_SUMMARY_ORDERED_BY_CONFIG } from './OrderedBy';
import { REPORT_SUMMARY_MORE_CONFIG } from './OrderItems';
import { REPORT_SUMMARY_PRODUCT_TYPE_CONFIG } from './ProductType';

export const REPORT_SUMMARY_TABLE_CELLS_CONFIG: {
  [key in ReportSummaryTableOptions]: ReportSummaryCellConfig;
} = {
  [ReportSummaryTableOptions.completedDate]:
    REPORT_SUMMARY_COMPLETED_DATE_CONFIG,
  [ReportSummaryTableOptions.order]: REPORT_SUMMARY_ORDER_CONFIG,
  [ReportSummaryTableOptions.orderedBy]: REPORT_SUMMARY_ORDERED_BY_CONFIG,
  [ReportSummaryTableOptions.amountOrdered]:
    REPORT_SUMMARY_AMOUNT_ORDERED_CONFIG,
  [ReportSummaryTableOptions.productType]: REPORT_SUMMARY_PRODUCT_TYPE_CONFIG,
  [ReportSummaryTableOptions.label]: REPORT_SUMMARY_LABEL_CONFIG,
  [ReportSummaryTableOptions.download]: REPORT_SUMMARY_DOWNLOAD_CONFIG,
  [ReportSummaryTableOptions.more]: REPORT_SUMMARY_MORE_CONFIG,
};
