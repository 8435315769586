import React from 'react';
import classNames from 'classnames';

import { OmCalendarIcon } from '@hcs/design-system';
import { InspectionsIcon } from '@hcs/design-system';
import { DeliverySpeed, INSPECTION_TYPE, STEP } from '@hcs/types';

import { INSPECTION_NAMES } from '../constants';
import { getAsRepairedLabel } from '../utils';

import styles from './SelectedOptions.module.css';

interface Props {
  dataHcName: string;
  onChangeClick: (number: number) => void;
  deliverySpeed?: DeliverySpeed;
  inspectionType?: INSPECTION_TYPE | null;
  includesAsRepairedValue?: boolean;
  disabled?: boolean;
}

export const InspectionOption = ({
  dataHcName,
  onChangeClick,
  deliverySpeed,
  inspectionType,
  disabled,
  includesAsRepairedValue,
}: Props) => {
  const handleChangeClick = () => {
    if (!disabled) {
      onChangeClick(STEP.INSPECTION_METHOD);
    }
  };

  const getDeliveryText = (deliverySpeed: DeliverySpeed) => {
    return deliverySpeed.surcharge
      ? `${deliverySpeed.label} + $${deliverySpeed.surcharge}/address`
      : deliverySpeed.label;
  };

  const asRepairedLabel = getAsRepairedLabel(!!includesAsRepairedValue);

  return (
    <div data-hc-name={dataHcName} className={styles.Option}>
      <div className={styles.OptionContent}>
        <div className={styles.OptionIcon}>
          <InspectionsIcon
            dataHcName="inspections-icon"
            size={35}
            color="neutral-dark-20"
          />
        </div>
        <div>
          <h4
            className={styles.OptionTitle}
            data-hc-name="inspection-type-label"
          >
            {inspectionType && INSPECTION_NAMES[inspectionType]}
          </h4>
          {asRepairedLabel && (
            <div
              className={styles.OptionDescription}
              data-hc-name="as-repaired-label"
            >
              {asRepairedLabel}
            </div>
          )}
        </div>
      </div>
      <div className={styles.OptionActions}>
        {deliverySpeed && (
          <div className={styles.OptionDescription}>
            <div className={styles.CalendarIconContainer}>
              <OmCalendarIcon
                dataHcName={`${dataHcName}-calendar-icon`}
                size="sm"
                color="neutral-dark-20"
              />
            </div>{' '}
            {getDeliveryText(deliverySpeed)}
          </div>
        )}
        <div
          className={classNames(styles.OptionChange, {
            [styles.disabled]: disabled,
          })}
          onClick={handleChangeClick}
        >
          Change
        </div>
      </div>
    </div>
  );
};
