import { UseQueryResult } from '@tanstack/react-query';

import { sum } from './math.utils';
import { areAllTruthy, isAnyTruthy } from './validators.utils';

type CombinedUseQueryResultNoData = Omit<
  // jdimattia: purposefully excluding isLoading to avoid confusion - we should prefer using isInitialLoading instead as it's more predictable with disabled queries
  Omit<UseQueryResult, 'data' | 'isLoading'>,
  'refetch'
>;

export const combineReactQueryStatus = (
  statuses: UseQueryResult['status'][]
): UseQueryResult['status'] => {
  let isSuccess = true;
  for (const status of statuses) {
    if (status === 'error') {
      return 'error';
    } else if (status === 'loading') {
      return 'loading';
    }
    if (status !== 'success') {
      isSuccess = false;
    }
  }

  if (isSuccess) {
    return 'success';
  }
  return 'loading';
};

export const combineReactQueryFetchStatus = (
  statuses: UseQueryResult['fetchStatus'][]
): UseQueryResult['fetchStatus'] => {
  for (const status of statuses) {
    if (status === 'paused') {
      return 'paused';
    } else if (status === 'fetching') {
      return 'fetching';
    } else if (status === 'idle') {
      return 'idle';
    }
  }

  return 'idle';
};

interface CombineUseQueryResultInputs {
  isFetching: UseQueryResult['isFetching'][];
  // isLoading: UseQueryResult['isLoading'][];
  isInitialLoading: UseQueryResult['isInitialLoading'][];
  isLoadingError: UseQueryResult['isLoadingError'][];
  isPaused: UseQueryResult['isPaused'][];
  isPlaceholderData: UseQueryResult['isPlaceholderData'][];
  isPreviousData: UseQueryResult['isPreviousData'][];
  isRefetching: UseQueryResult['isRefetching'][];
  isRefetchError: UseQueryResult['isRefetchError'][];
  isStale: UseQueryResult['isStale'][];
  isSuccess: UseQueryResult['isSuccess'][];
  remove: UseQueryResult['remove'][];
  status: UseQueryResult['status'][];
  fetchStatus: UseQueryResult['fetchStatus'][];
  dataUpdatedAt: UseQueryResult['dataUpdatedAt'][];
  error: UseQueryResult['error'][];
  errorUpdatedAt: UseQueryResult['errorUpdatedAt'][];
  errorUpdateCount: UseQueryResult['errorUpdateCount'][];
  failureCount: UseQueryResult['failureCount'][];
  failureReason: UseQueryResult['failureReason'][];
  isError: UseQueryResult['isError'][];
  isFetched: UseQueryResult['isFetched'][];
  isFetchedAfterMount: UseQueryResult['isFetchedAfterMount'][];
}

export const buildCombineQueryInputs = (
  queries: CombinedUseQueryResultNoData[]
) => {
  const inputs: CombineUseQueryResultInputs = {
    isFetching: [],
    // isLoading: [],
    isInitialLoading: [],
    isLoadingError: [],
    isPaused: [],
    isPlaceholderData: [],
    isPreviousData: [],
    isRefetching: [],
    isRefetchError: [],
    isStale: [],
    isSuccess: [],
    isError: [],
    isFetched: [],
    isFetchedAfterMount: [],
    dataUpdatedAt: [],
    remove: [],
    error: [],
    errorUpdatedAt: [],
    errorUpdateCount: [],
    failureCount: [],
    failureReason: [],
    status: [],
    fetchStatus: [],
  };
  queries.forEach((query) => {
    inputs.isFetching.push(query.isFetching);
    // inputs.isLoading.push(query.isLoading);
    inputs.isInitialLoading.push(query.isInitialLoading);
    inputs.isLoadingError.push(query.isLoadingError);
    inputs.isPaused.push(query.isPaused);
    inputs.isPlaceholderData.push(query.isPlaceholderData);
    inputs.isPreviousData.push(query.isPreviousData);
    inputs.isRefetchError.push(query.isRefetchError);
    inputs.isStale.push(query.isStale);
    inputs.isSuccess.push(query.isSuccess);
    inputs.isError.push(query.isError);
    inputs.isFetched.push(query.isFetched);
    inputs.isFetchedAfterMount.push(query.isFetchedAfterMount);
    inputs.isRefetching.push(query.isRefetching);
    inputs.remove.push(query.remove);
    inputs.status.push(query.status);
    inputs.fetchStatus.push(query.fetchStatus);
    inputs.dataUpdatedAt.push(query.dataUpdatedAt);
    inputs.error.push(query.error);
    inputs.errorUpdateCount.push(query.errorUpdateCount);
    inputs.errorUpdatedAt.push(query.errorUpdatedAt);
    inputs.failureCount.push(query.failureCount);
    inputs.failureReason.push(query.failureReason);
  });
  return inputs;
};
export const combineUseQueryResult = (
  queries: CombinedUseQueryResultNoData[]
): CombinedUseQueryResultNoData => {
  const inputs = buildCombineQueryInputs(queries);
  const d: Omit<UseQueryResult, 'refetch' | 'data' | 'isLoading'> = {
    isFetching: isAnyTruthy(inputs.isFetching),
    // isLoading: isAnyTruthy(inputs.isLoading),
    isInitialLoading: isAnyTruthy(inputs.isInitialLoading),
    isLoadingError: isAnyTruthy(inputs.isLoadingError),
    isPaused: isAnyTruthy(inputs.isPaused),
    isPlaceholderData: isAnyTruthy(inputs.isPlaceholderData),
    isPreviousData: isAnyTruthy(inputs.isPreviousData),
    isRefetching: isAnyTruthy(inputs.isRefetching),
    isRefetchError: isAnyTruthy(inputs.isRefetchError),
    isStale: isAnyTruthy(inputs.isStale),
    isSuccess: areAllTruthy(inputs.isSuccess),
    remove: () => {
      inputs.remove.forEach((remove) => remove());
    },
    status: combineReactQueryStatus(inputs.status),
    fetchStatus: combineReactQueryFetchStatus(inputs.fetchStatus),
    isError: isAnyTruthy(inputs.isError),
    isFetched: areAllTruthy(inputs.isFetched),
    isFetchedAfterMount: areAllTruthy(inputs.isFetchedAfterMount),
    // not sure how to type this
    // refetch: (options) => {
    //   return inputs.refetch.forEach((refetch) => refetch(options));
    // },
    dataUpdatedAt: Math.max(...inputs.dataUpdatedAt),
    error: inputs.error,
    errorUpdatedAt: Math.max(...inputs.errorUpdatedAt),
    errorUpdateCount: sum(inputs.errorUpdateCount),
    failureCount: sum(inputs.failureCount),
    failureReason: inputs.failureReason,
  };
  return d;
};
