import React from 'react';
import classNames from 'classnames';

import { NoContent } from '../../../../global/loading-errors-null/NoContent';

import styles from './LowToHighBandChart.module.css';

interface ColorLabelMap {
  veryHigh: number;
  high: number;
  neutral: number;
  low: number;
}

export interface LowToHighBandChartProps {
  dataHcName: string;
  className?: string;
  label: string;
  reverseColors?: boolean;
  valueAsPercent: number | null;
  tickRangePercent?: number[];
  colorLabelMap?: ColorLabelMap;
  noDataLabel?: string;
}

/**
 * Colors also exist in CSS.
 * CHART_COLORS is used to correctly color the ticketLabel.
 */
export const CHART_COLORS = [
  '#2bdba9',
  '#a7e765',
  '#eed43e',
  '#fab34e',
  '#fd895e',
];
const TICK_LABELS = ['Very Low', 'Low', 'Neutral', 'High', 'Very High'];
const TICK_WIDTH = 4;

export const LowToHighBandChart = ({
  dataHcName,
  className,
  valueAsPercent,
  label,
  reverseColors,
  tickRangePercent,
  colorLabelMap,
  noDataLabel,
}: LowToHighBandChartProps) => {
  let position = { left: `${valueAsPercent}%` };
  let alignment = 'Center';

  // These values were chosen so the subject value label doesn't render off-screen
  if (typeof valueAsPercent === 'number') {
    if (valueAsPercent < 18) {
      position = { left: `calc(${valueAsPercent}% - ${TICK_WIDTH / 2}px)` };
      alignment = 'Left';
    } else if (valueAsPercent > 72) {
      position = { left: `${valueAsPercent}%` };
      alignment = 'Right';
    }
  }
  const colorLabel = (value: number | null) => {
    if (typeof value !== 'number') return;
    const colors = [...CHART_COLORS];
    if (reverseColors) colors.reverse();
    if (colorLabelMap) {
      if (value >= colorLabelMap.veryHigh) {
        return {
          color: colors[4],
          label: TICK_LABELS[4],
        };
      } else if (value >= colorLabelMap.high) {
        return {
          color: colors[3],
          label: TICK_LABELS[3],
        };
      } else if (value >= colorLabelMap.neutral) {
        return {
          color: colors[2],
          label: TICK_LABELS[2],
        };
      } else if (value >= colorLabelMap.low) {
        return {
          color: colors[1],
          label: TICK_LABELS[1],
        };
      }
    }
    if (value >= 81) {
      return {
        color: colors[4],
        label: TICK_LABELS[4],
      };
    } else if (value >= 61) {
      return {
        color: colors[3],
        label: TICK_LABELS[3],
      };
    } else if (value >= 41) {
      return {
        color: colors[2],
        label: TICK_LABELS[2],
      };
    } else if (value >= 21) {
      return {
        color: colors[1],
        label: TICK_LABELS[1],
      };
    }
    return {
      color: colors[0],
      label: TICK_LABELS[0],
    };
  };
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ChartContent, className)}
    >
      {typeof valueAsPercent === 'number' ? (
        <>
          <div
            className={classNames(
              styles.Subject,
              styles[
                `Subject${alignment}` as
                  | 'SubjectRight'
                  | 'SubjectLeft'
                  | 'SubjectCenter'
              ]
            )}
            style={position}
          >
            <div
              className={styles.ValueLabel}
              data-hc-name={`${dataHcName}-value-label`}
            >
              <div
                className={styles.ValueLabelPercent}
                data-hc-name={`${dataHcName}-value-percent`}
              >
                {`${valueAsPercent.toFixed(1)}%`}
              </div>
              <div className={styles.ValueLabelDescription}>
                <div data-hc-name={`${dataHcName}-value-description`}>
                  {label}
                </div>
                <div
                  className={styles.LevelIndicator}
                  data-hc-name={`${dataHcName}-value-risk`}
                  style={{
                    color: colorLabel(valueAsPercent)?.color,
                  }}
                >
                  {colorLabel(valueAsPercent)?.label}
                </div>
              </div>
            </div>
            <div
              data-hc-name={`${dataHcName}-tick-mark`}
              className={styles.ValueTick}
            />
          </div>
          <div
            data-hc-name={`${dataHcName}-bar`}
            className={classNames(styles.Bar, {
              [styles.reverseColors]: reverseColors,
            })}
          >
            <div className={styles.TickContainer}>
              {TICK_LABELS.map((tickLabel, i) => {
                const width = (tickRangePercent && tickRangePercent[i]) || 20;
                return (
                  <div
                    key={`${i + tickLabel}`}
                    className={styles.Tick}
                    style={{ width: `${width}%` }}
                  >
                    <div
                      data-hc-name={`${dataHcName}-chart-label`}
                      className={classNames(styles.TickLabel, {
                        [styles.left]: i === 0,
                        [styles.right]: i === TICK_LABELS.length - 1,
                        [styles.center]: i > 0 && i < TICK_LABELS.length - 1,
                      })}
                    >
                      {tickLabel}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <NoContent dataHcName={`${dataHcName}-no-content`}>
          {noDataLabel || 'No data available'}
        </NoContent>
      )}
    </div>
  );
};
