import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { UserContactInfo } from '@hcs/types';
import { ChangePasswordErrorData, ChangePasswordPayload } from '@hcs/types';

import { UserAdminApi } from '../api';

export const useUserChangePassword = (
  options?: UseMutationOptions<
    UserContactInfo,
    AxiosError<ChangePasswordErrorData>,
    ChangePasswordPayload
  >
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    UserContactInfo,
    AxiosError<ChangePasswordErrorData>,
    ChangePasswordPayload
  >(
    async (formData) => {
      return await UserAdminApi.changeUserPassword(formData);
    },
    {
      ...options,
      onError: (error, variables, context) => {
        toastOpen({
          type: 'error',
          title: 'Failed to update password',
        });
        options?.onError?.(error, variables, context);
      },
      onSuccess: (data, variables, context) => {
        toastOpen({
          type: 'success',
          title: 'Your password has been changed',
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
