import React from 'react';
import classNames from 'classnames';

import { DexpJobStatus } from '@hcs/types';

import {
  formatJobStatusMessage,
  jobIsCompleted,
} from '../../utils/jobStatus.utils';
import { AnalysisDownloadLink } from '../AnalysisDownloadLink';

import styles from './AnalysisStatus.module.css';

interface AnalysisStatusProps {
  className?: string;
  status: DexpJobStatus;
  outputFile?: string | null;
  statusMessage: string | null;
  statusDisplay: string;
}
const dataHcName = 'analysis-status';
export const AnalysisStatus = (props: AnalysisStatusProps) => {
  const { status, statusDisplay, statusMessage, outputFile, className } = props;
  if (jobIsCompleted(status) && outputFile) {
    return (
      <AnalysisDownloadLink
        label="Download"
        theme={{ Link: className }}
        outputFile={outputFile}
        error={
          status === DexpJobStatus.CompletedWithErrors
            ? {
                message:
                  statusMessage ||
                  'Some of the addresses in your file failed to process',
              }
            : undefined
        }
      />
    );
  }
  return (
    <span
      className={classNames(styles.Status, className)}
      data-hc-name={dataHcName}
    >
      {formatJobStatusMessage({ status, statusDisplay, statusMessage })}
    </span>
  );
};
