import { useQuery } from '@tanstack/react-query';

import { LeadFeedApi } from '../';

export const QUERY_KEY_BUY_BOX_SUMMARIES = 'buyBoxSummaries';
export const useBuyBoxSummaries = () => {
  return useQuery(
    [QUERY_KEY_BUY_BOX_SUMMARIES],
    async () => await LeadFeedApi.fetchBuyBoxSummaries()
  );
};
