import React, { useCallback, useMemo } from 'react';

import { DownloadMlsSheetLink } from '@hcs/pdf/listing';
import {
  PropertyTransactionHistoryTable,
  PropertyTransactionHistoryTableProps,
} from '@hcs/property-state';
import {
  TransactionHistoryActions,
  TransactionHistoryActionsCallback,
} from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { usePhotosPage, useSubjectDocument } from '../../hooks';
import { useReportConfig } from '../../hooks/useReportConfig';
import { useSubjectPhotos } from '../../hooks/useSubjectPhotos';
import { useSubjectTransactionHistoryDocument } from '../../hooks/useSubjectTransactionHistoryDocument';
import {
  reportFeaturesSupportedAll,
  reportFeaturesSupportedAny,
} from '../../utils/reportConfig.utils';

interface Props
  extends Omit<
    PropertyTransactionHistoryTableProps,
    'propertyTransactionHistory' | 'dataHcEventSection'
  > {
  reportId: ReportId;
  className?: string;
  includePhotos?: boolean;
  includeMlsSheet?: boolean;
}
export const SUBJECT_TRANSACTION_HISTORY_FEATURES = [
  ReportFeatures.SubjectTransactionHistory,
];
const dataHcName = 'report-property-transaction-history';
export const dataHcEventSectionSubjectTransactionHistorySale =
  'Subject Sale History';
export const dataHcEventSectionSubjectTransactionHistoryRental =
  'Subject Rental History';
export const SubjectTransactionHistory = ({
  reportId,
  className,
  includePhotos = true,
  includeMlsSheet = true,
  actions: actionsProp,
  ...tableProps
}: Props) => {
  const dataHcEventSection =
    tableProps.valueType === 'RENTAL'
      ? dataHcEventSectionSubjectTransactionHistoryRental
      : dataHcEventSectionSubjectTransactionHistorySale;
  const { data: reportConfig } = useReportConfig(reportId);
  const subjectTransactionHistoryQuery =
    useSubjectTransactionHistoryDocument(reportId);
  const subjectQuery = useSubjectDocument(reportId);
  const hcAddressId = subjectQuery.data?.data.propertyState.hcAddressId;
  const photosQuery = useSubjectPhotos(reportId);
  const listingPhotos = photosQuery.data?.listingPhotos;
  const { photosPageOpen } = usePhotosPage();
  const listingsWithPhotos: Record<`${string}-${string}`, boolean> =
    useMemo(() => {
      const result: Record<`${string}-${string}`, boolean> = {};
      listingPhotos?.forEach((l) => {
        const { listingId, hcMlsId } = l.listingDetails;
        if (listingId && hcMlsId && l.media?.images?.length) {
          result[`${hcMlsId}-${listingId}`] = true;
        }
      });
      return result;
    }, [listingPhotos]);
  const actionsCallback: TransactionHistoryActionsCallback = useCallback(
    (transactionHistoryEvent, isMostRecentEventInGroup) => {
      const actions: TransactionHistoryActions = [];
      if (isMostRecentEventInGroup) {
        const { listingId, hcMlsId } = transactionHistoryEvent;
        if (listingId && hcMlsId) {
          if (
            reportFeaturesSupportedAll(reportConfig, [
              ReportFeatures.MlsSheet,
            ]) &&
            hcAddressId &&
            includeMlsSheet
          ) {
            actions.push({
              type: 'ReactNode',
              value: (
                <DownloadMlsSheetLink
                  key={`mls-${listingId}-${hcMlsId}`}
                  hcAddressId={hcAddressId}
                  mlsId={hcMlsId}
                  mlsNumber={listingId}
                />
              ),
            });
          }
          if (
            reportFeaturesSupportedAll(reportConfig, [
              ReportFeatures.SubjectPhotosAll,
            ]) &&
            listingsWithPhotos[`${hcMlsId}-${listingId}`] &&
            includePhotos
          ) {
            actions.push({
              type: 'anchorProps',
              value: {
                dataHcName: `${dataHcName}-action-photos`,
                children: 'View Photos',
                onClick: () => {
                  photosPageOpen({
                    type: 'subject',
                    listingIdentifier: {
                      listingId,
                      hcMlsId,
                    },
                  });
                },
              },
            });
          }
        }
      }
      if (actionsProp) {
        return [
          ...actions,
          ...(actionsProp(transactionHistoryEvent, isMostRecentEventInGroup) ||
            []),
        ];
      } else {
        return actions;
      }
    },
    [reportConfig, hcAddressId, includePhotos, includeMlsSheet, actionsProp]
  );
  const { isInitialLoading, isError } = combineUseQueryResult([
    subjectQuery,
    photosQuery,
    subjectTransactionHistoryQuery,
  ]);
  return (
    <PropertyTransactionHistoryTable
      {...tableProps}
      dataHcEventSection={dataHcEventSection}
      actions={
        reportFeaturesSupportedAny(reportConfig, [
          ReportFeatures.MlsSheet,
          ReportFeatures.SubjectPhotosAll,
        ])
          ? actionsCallback
          : undefined
      }
      className={className}
      isLoading={isInitialLoading}
      isError={isError}
      propertyTransactionHistory={subjectTransactionHistoryQuery.data?.data}
    />
  );
};
