import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableHeaderCellProps } from '@hcs/types';
import { TableCellProps } from '@hcs/types';
import {
  PortfolioAsset,
  PortfolioAssetContentCellProps,
  PortfolioAssetFieldConfig,
} from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL } from '../../constants/PortfolioTable.constants';

import styles from './LastUpdate.module.css';

const Display = ({ portfolioAsset }: PortfolioAssetContentCellProps) => {
  return (
    <div
      className={styles.LastUpdated}
      data-hc-name={`${DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL}-last-update`}
    >
      <div>{formatDateShort(portfolioAsset.data?.lastUpdate)}</div>
      {/* data that will be displayed in the tooltip will be implemented in the post MVP
      ref: https://housecanary.atlassian.net/browse/DP-340 */}
      {/* <Tooltip
        label={<div>Displaying list of updates...</div>}
        trigger={
          <IconButton
            className={styles.TooltipIcon}
            dataHcName={`${DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL}-last-update-tooltip-icon`}
            icon={<InfoIcon size="sm" color="neutral-dark-40" />}
          />
        }
        dataHcName={`${DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL}-last-update-tooltip`}
      /> */}
    </div>
  );
};

const ContentCell = ({
  portfolioAsset,
  ...tableCellProps
}: {
  portfolioAsset: PortfolioAsset;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display portfolioAsset={portfolioAsset} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return (
    <TableHeaderCell {...tableHeaderCellProps}>Last Update</TableHeaderCell>
  );
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export const PORTFOLIO_ASSET_LAST_UPDATE_CONFIG: PortfolioAssetFieldConfig = {
  ContentCell,
  HeaderCell,
  label: 'Last Update',
};
