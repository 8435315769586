import { useQuery } from '@tanstack/react-query';

import { LeadFeedApi } from '../';

export const QUERY_KEY_MSAS = 'msas';
export const useMsas = () => {
  return useQuery([QUERY_KEY_MSAS], async () => await LeadFeedApi.fetchMsas(), {
    staleTime: Infinity, // msas pretty much never change, or will at least unlikely change while a user is on the page
  });
};
