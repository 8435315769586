import React, { ReactNode } from 'react';
import classNames from 'classnames';
import pluralize from 'pluralize';

import {
  Anchor,
  ResizeHandleVertical,
  Skeleton,
  SkeletonLoader,
  ThumbnailImage,
} from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { KeywordSearchText } from '@hcs/keyword-search';
import {
  PropertyStateArgsProps,
  PropertyStateFields,
  PropertyStateFieldsConfigProp,
  PropertyStateFieldsConfigProps,
  PropertyStateType,
} from '@hcs/types';
import {
  formatCityStateZip,
  formatMissing,
  formatPropertyType,
  formatSqFt,
  formatStreetAddress,
  NULL_VALUE,
} from '@hcs/utils';

import { getPropertyStateFieldValue } from '../../utils';
import { PropertySummaryDetails } from '../PropertySummaryDetails';

import styles from './PropertySummaryHeader.module.css';

const DEFAULT_FIELDS_SALE: PropertyStateFieldsConfigProp = [
  PropertyStateFields.propertyType,
  [
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.livingArea,
  ],
];
const DEFAULT_FIELDS_RENTAL: PropertyStateFieldsConfigProp = [
  PropertyStateFields.propertyType,
  [
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.livingArea,
  ],
];

export interface PropertySummaryHeaderProps
  extends PropertyStateArgsProps,
    PropertyStateFieldsConfigProps {
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  isLoading?: boolean;
  expanded?: 'partial' | 'full';
  photo?:
    | {
        isLoading?: boolean;
        url: string | null | undefined;
        onClick?: VoidFunction;
      }
    | ReactNode;
  keywordSearchKey?: string;
  expandable?: boolean;
  beforeStreetAddress?: ReactNode;
  afterStreetAddress?: ReactNode;
  noPadding?: boolean;
  addressOneLine?: boolean;
  summaryDetailsFullWidth?: boolean;
}
const COLLAPSED_LISTING_CHAR_LIMIT = 275;
const dataHcName = 'property-summary-header';
export const PropertySummaryHeader = ({
  className,
  photo,
  isLoading,
  propertyStateArgs,
  keywordSearchKey,
  isRental,
  expandable,
  fields: fieldsProp,
  afterStreetAddress,
  beforeStreetAddress,
  expanded,
  noPadding,
  addressOneLine,
  summaryDetailsFullWidth,
}: PropertySummaryHeaderProps) => {
  const fields = fieldsProp
    ? fieldsProp
    : isRental
    ? DEFAULT_FIELDS_RENTAL
    : DEFAULT_FIELDS_SALE;
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  const {
    active: propertySummaryExpanded,
    handleToggle: propertySummaryToggle,
  } = useActiveState({ activeInitial: !!expanded });
  const {
    active: listingRemarksExpanded,
    handleToggle: handleListingRemarksToggle,
    handleClose: handleCloseListingRemarks,
  } = useActiveState({ activeInitial: expanded === 'full' });
  const handlePropertySummaryToggle = () => {
    propertySummaryToggle();
    handleCloseListingRemarks();
  };
  const listingRemarks =
    propertyStateType === PropertyStateType.Core
      ? propertyState?.[
          isRental ? 'listingDetailsRental' : 'listingDetailsSale'
        ]?.remarks?.find((r) => r?.type === 'Public')?.value
      : undefined;

  const listingRemarksMore = listingRemarks?.slice(
    COLLAPSED_LISTING_CHAR_LIMIT
  );

  const removeIfNullOrAddPadding = (value: string) =>
    value.includes(NULL_VALUE) ? null : <>{value}&nbsp;&nbsp;&nbsp;</>;
  const propertyType = formatPropertyType(
    getPropertyStateFieldValue(
      PropertyStateFields.propertyType,
      propertyStateArgs
    )
  );

  const bedrooms = `${formatMissing(
    getPropertyStateFieldValue(PropertyStateFields.bedrooms, propertyStateArgs)
  )} ${pluralize(
    'Bed',
    getPropertyStateFieldValue(
      PropertyStateFields.bedrooms,
      propertyStateArgs
    ) || 0
  )}`;

  const bathrooms = `${formatMissing(
    getPropertyStateFieldValue(PropertyStateFields.bathrooms, propertyStateArgs)
  )} ${pluralize(
    'Bath',
    getPropertyStateFieldValue(
      PropertyStateFields.bathrooms,
      propertyStateArgs
    ) || 0
  )}`;

  const squareFt = formatSqFt(
    getPropertyStateFieldValue(
      PropertyStateFields.livingArea,
      propertyStateArgs
    )
  );

  return (
    <header
      data-hc-name={dataHcName}
      className={classNames(styles.PropertySummaryHeader, className)}
    >
      <div
        className={classNames(styles.PropertySummary, {
          [styles.propertySummaryExpanded]: propertySummaryExpanded,
          [styles.noPadding]: noPadding,
        })}
      >
        {photo && (
          <div className={styles.PropertyPhoto}>
            {typeof photo === 'object' && 'url' in photo ? (
              <ThumbnailImage
                isLoading={photo.isLoading}
                url={photo.url}
                onClick={photo.onClick}
              />
            ) : (
              photo
            )}
          </div>
        )}
        <div className={styles.PropertyInfo}>
          <div
            className={classNames(styles.TitleSubtitleContainer, {
              [styles.addressOneLine]:
                addressOneLine && propertySummaryExpanded,
            })}
          >
            <div data-hc-name={`${dataHcName}-title`} className={styles.Title}>
              {beforeStreetAddress}
              <span data-hc-name={`${dataHcName}-streetAddress`}>
                {isLoading ? (
                  <Skeleton
                    dataHcName={`${dataHcName}-skeleton`}
                    type="text"
                    width="350px"
                  />
                ) : (
                  formatStreetAddress(propertyState?.location)
                )}
              </span>
              {afterStreetAddress}
            </div>
            <div className={styles.Subtitle}>
              <span
                className={styles.CityStateZip}
                data-hc-name={`${dataHcName}-cityStateZip`}
              >
                {isLoading ? (
                  <Skeleton
                    dataHcName={`${dataHcName}-skeleton`}
                    type="text"
                    width="210px"
                  />
                ) : (
                  formatCityStateZip(propertyState?.location)
                )}
              </span>
              {!propertySummaryExpanded && (
                <div
                  className={styles.SubtitleDetails}
                  data-hc-name={`${dataHcName}-summary-details`}
                >
                  <div className={styles.SubtitleDivider} />
                  <div className={styles.SubtitleDetailsFields}>
                    <SkeletonLoader
                      dataHcName={`${dataHcName}-street-address-skeleton`}
                      isLoading={!!isLoading}
                      type="text"
                    >
                      <span data-hc-name={`${dataHcName}-property-info`}>
                        {removeIfNullOrAddPadding(propertyType)}
                        {removeIfNullOrAddPadding(bedrooms)}
                        {removeIfNullOrAddPadding(bathrooms)}
                        {removeIfNullOrAddPadding(squareFt)}
                      </span>
                    </SkeletonLoader>
                  </div>
                </div>
              )}
            </div>
          </div>
          {propertySummaryExpanded && (
            <div data-hc-name={`${dataHcName}-expanded-details`}>
              <PropertySummaryDetails
                propertyStateArgs={propertyStateArgs}
                className={classNames(styles.SummaryDetails, {
                  [styles.fullWidth]: summaryDetailsFullWidth,
                })}
                isLoading={isLoading}
                isRental={isRental}
                fields={fields}
              />
            </div>
          )}
        </div>
      </div>
      {propertySummaryExpanded && listingRemarks && (
        <div
          className={classNames(styles.ListingRemarks, {
            [styles.noPadding]: noPadding,
          })}
        >
          {expanded && <div className={styles.ExpandableDivider} />}
          {isLoading ? (
            <>
              <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
              <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
              <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
            </>
          ) : (
            <>
              <div
                data-hc-name={`${dataHcName}-remarks-label`}
                className={styles.ListingRemarksLabel}
              >
                Listing Comments
              </div>
              <KeywordSearchText
                dataHcName={`${dataHcName}-remarks`}
                className={styles.Remarks}
                searchKey={keywordSearchKey || ''}
                textToHighlight={
                  listingRemarksMore && !listingRemarksExpanded
                    ? `${listingRemarks.slice(
                        0,
                        COLLAPSED_LISTING_CHAR_LIMIT
                      )}...`
                    : listingRemarks
                }
              />
              {listingRemarksMore && expanded !== 'full' && (
                <Anchor
                  dataHcName={`${dataHcName}-remarks-toggle`}
                  onClick={handleListingRemarksToggle}
                >
                  {listingRemarksExpanded ? 'less' : 'more'}
                </Anchor>
              )}
            </>
          )}
        </div>
      )}
      {!expanded && expandable !== false && (
        <ResizeHandleVertical
          dataHcName={`${dataHcName}-property-summary-resize`}
          onClick={handlePropertySummaryToggle}
        />
      )}
    </header>
  );
};
