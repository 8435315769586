import { RefObject, useCallback, useState } from 'react';

import { findOverflowParent } from '@hcs/utils';

import { useComponentDidMount } from './useComponentDidMount';
import { useWindowResize } from './useWindowResize';

// NOTE: Parent of ref must have non-static visibility and overflow: hidden
export const useVisibleInOverflow = <T extends HTMLElement>(
  ref: RefObject<T>
) => {
  // Rerender this component when the window resizes and after the initial mount
  // TODO: It would be nice to useResizeObserver but it wasn't behaving as I expected
  // and I decided to move on
  const [rerenders, setRerenders] = useState(0);
  const incRerenders = useCallback(() => {
    setRerenders(rerenders + 1);
  }, [setRerenders, rerenders]);
  // Rerender in case paren't is mounted at the same time
  useComponentDidMount(incRerenders);
  // Listen to window resizes
  useWindowResize(incRerenders);
  // Visibility variables to return
  let visible = false;
  let visiblePartial = false;
  let visibleFull = false;
  // Elements to compare
  const el = ref.current;
  const parent = findOverflowParent(el);
  let debug = undefined;

  if (el && parent) {
    // For Readability
    const { height, width, top, left } = el.getBoundingClientRect();
    const {
      height: parentHeight,
      width: parentWidth,
      top: parentTop,
      left: parentLeft
    } = parent.getBoundingClientRect();

    // EXP-1304: Cannot rely on el.offsetTop/Left because
    // we cannot guarantee el is a direct descendant of parent
    const offsetTop = top - parentTop;
    const offsetLeft = left - parentLeft;

    debug = {
      height,
      width,
      offsetLeft,
      offsetTop,
      parentWidth,
      parentHeight
    };
    // Handle negative offsets, which cannot be fully visible
    // No need to compare to the parent dimensions
    if (offsetLeft < 0 || offsetTop < 0) {
      if (Math.abs(offsetLeft) <= width && Math.abs(offsetTop) <= height) {
        visible = true;
        visiblePartial = true;
      }
    } else if (offsetLeft < parentWidth && offsetTop < parentHeight) {
      visible = true;
      visiblePartial = true;
      if (
        offsetLeft + width <= parentWidth &&
        offsetTop + height <= parentHeight
      ) {
        visibleFull = true;
      }
    }
  }
  return {
    visible,
    visiblePartial,
    visibleFull,
    debug
  };
};
