import { useQuery } from '@tanstack/react-query';

import { logException } from '@hcs/utils';

import { LegacySelfServiceApi } from '../api';

export const SELF_SERVICE_PACKAGE_QUERY_KEY = 'self-service-package';
export const useSelfServicePackage = () => {
  return useQuery(
    [SELF_SERVICE_PACKAGE_QUERY_KEY],
    async () => {
      const packages = await LegacySelfServiceApi.fetchPackages();
      // assumes just one package
      const selfServicePackage = packages[0];
      if (!selfServicePackage) {
        const error = new Error(
          'useSelfServicePackage: expected there to be one package but none was found'
        );
        logException(error);
        throw error;
      }
      return selfServicePackage;
    },
    {
      staleTime: Infinity, // don't expect these packages to change while the user is using the app
    }
  );
};
