import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { HEADER_HEIGHT_LG } from '../Header';

import styles from './LayoutGlobalHeader.module.css';

export interface LayoutGlobalHeaderProps {
  type: 'outlet' | 'children';
  children?: ReactNode;
  header?: ReactNode;
}

export const LayoutGlobalHeader = ({
  type,
  header,
  children,
}: LayoutGlobalHeaderProps) => {
  return (
    <>
      {header || null}
      <div
        className={styles.Layout}
        style={{ paddingTop: `${HEADER_HEIGHT_LG}px` }}
      >
        {type === 'outlet' && <Outlet />}
        {children}
      </div>
    </>
  );
};

export const LayoutGlobalHeaderOutlet = (
  props: Omit<LayoutGlobalHeaderProps, 'type'>
) => {
  return <LayoutGlobalHeader type="outlet" {...props} />;
};

export const LayoutGlobalHeaderChildren = (
  props: Omit<LayoutGlobalHeaderProps, 'type'> & { children: ReactNode }
) => {
  return <LayoutGlobalHeader type="children" {...props} />;
};
