import { gql } from 'graphql-request';

export const QUERY_MLS_LOOKUP = gql`
  query MlsLookup($cerberusMlsIdentifierInput: CerberusMlsIdentifierInput!) {
    # HC APP: Value Report
    # HC APP: Lead Feed
    lookupMls(id: $cerberusMlsIdentifierInput) {
      hcMlsId
      name
      nameShort
      regulations {
        augmenting
        cancelled
        closed
        closedLoginPrice
        coMingling
        comingSoon
        contingent
        contingentWithKickOut
        cooperatingBrokerage
        copyright
        deleted
        disclaimer
        expired
        leased
        logo
        logoOverlay
        pending
        photosClosedAll
        photosClosedFirstOnly
        photosClosedHistory
        photosClosedLogin
        photosLogoOverlay
        sold
        unknown
        withdrawn
      }
      lastRefreshed
      stateMainDisplay
      fips {
        id
      }
    }
  }
`;
