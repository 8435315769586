import React, { useMemo, useState } from 'react';
import { Control } from 'react-hook-form';

import { DotLabel } from '@hcs/design-system';
import { BuyBoxFormData } from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { usePropertySpatialCountByBB } from '../../';
import { useBuyBoxFormChange } from '../../hooks/useBuyBoxFormChange';

const DEFAULT_COUNT_VALUE = '0';
interface BuyBoxMatchCountProps {
  className?: string;
  control: Control<BuyBoxFormData>;
}
const dataHcName = 'buy-box-match-count';
export const BuyBoxMatchCount = ({
  control,
  className,
}: BuyBoxMatchCountProps) => {
  const [editedBuyBox, setEditedBuyBox] = useState<BuyBoxFormData | null>(null);
  const { data, isInitialLoading, isFetching, isSuccess } =
    usePropertySpatialCountByBB(editedBuyBox);
  const callback = (formData: BuyBoxFormData) => {
    setEditedBuyBox(formData);
  };
  const { isValid, hasPendingChange } = useBuyBoxFormChange(control, callback);

  // when refetching, data becomes undefined. This is to avoid a flickering of 0
  const count = useMemo(() => {
    // if the form is invalid, we want to show 0 matched properties
    if (!isValid) {
      return DEFAULT_COUNT_VALUE;
    }
    if (data) {
      if (data.__typename === 'SpatialSearchOverCountResult') {
        return data.moreThan
          ? `${formatNumber(data.moreThan)}+`
          : DEFAULT_COUNT_VALUE;
      } else if (data.__typename === 'SpatialSearchCountResult') {
        return data.count ? formatNumber(data.count) : DEFAULT_COUNT_VALUE;
      }
    }
    return DEFAULT_COUNT_VALUE;
  }, [data, isValid]);

  return (
    <div data-hc-name={dataHcName} className={className}>
      <DotLabel
        dataHcName={`${dataHcName}-indicator`}
        dotColor={{
          colorType:
            isInitialLoading || !isValid || !isSuccess ? 'gray' : 'green',
        }}
        pulsate={hasPendingChange || isFetching}
        label={<>{count} Matched Properties with current filter set</>}
      />
    </div>
  );
};
