import axios from 'axios';

import { OmAdminTokenResponse } from '@hcs/types';

import { authHeaderFnJwt } from '../utils';

import { queryClient } from '.';

export const QUERY_KEY_OM_ADMIN_TOKEN = 'QUERY_KEY_OM_ADMIN_TOKEN';
export const AxiosOmAdminApiClient = (() => {
  const axiosInstance = axios.create();

  // Instantiate the interceptor to add the authorization header
  axiosInstance.interceptors.request.use((request) => {
    const token = queryClient.getQueryData<OmAdminTokenResponse>([
      QUERY_KEY_OM_ADMIN_TOKEN,
    ])?.token;
    if (!token) {
      return request;
    }
    const authHeaders = authHeaderFnJwt(
      queryClient.getQueryData<OmAdminTokenResponse>([QUERY_KEY_OM_ADMIN_TOKEN])
        ?.token || null
    );
    for (const key in authHeaders) {
      request.headers.set(key, authHeaders[key]);
    }
    return request;
  });

  return axiosInstance;
})();
