import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactMapGL, { MapProps, MapRef } from 'react-map-gl';
import debounce from 'lodash.debounce';
import { Map } from 'mapbox-gl';

import { Skeleton } from '@hcs/design-system';
import { useParentDimensions, usePrevious, useRerender } from '@hcs/hooks';
import {
  ControlConfig,
  FitBoundsPayload,
  HcMapState,
  LayersControlConfig,
  MapOutlineConfig,
  MapPreferences,
  MapStyles,
  MoveMapProps,
  PropertyTypeEnum,
  VectilesMetricGroups,
  VectilesMetricIds,
} from '@hcs/types';
import { isRefObject } from '@hcs/utils';

import { MAPBOX_KEY } from '../../constants';
import { HcMapOutline } from '../../features/HcMap/HcMapOutline';
import { HcMapLayers } from '../../features/HcMapLayers';
import { HcMapLayersControl } from '../../features/HcMapLayersControl';
import { SatelliteToggle } from '../../features/SatelliteToggle';
import { ZoomControl } from '../../features/ZoomControl';
import { useHcMap } from '../../hooks/useHcMap';
import { useHcMapSearchParams } from '../../hooks/useHcMapSearchParams';
import { INITIAL_ZOOM } from '../../rtk';
import { fitGeoLocationsToViewPort } from '../../utils/viewport.utils';
import { ClusterToggle } from '../ClusterToggle';

import styles from './HcMap.module.css';

const TRANSITION_DURATION = 300;
const DEBOUNCE_WAIT = 30;

export interface HcMapProps extends MapProps {
  mapId: string;
  dataHcName: string;
  dataHcEventSection?: string;
  className?: string;
  loading?: boolean;
  mapStyle?: MapStyles;
  mapOutlineConfigs?: MapOutlineConfig[];
  uiPreferencesKey?: HcMapState['uiPreferencesKey'];
  vectilesMetricId?: VectilesMetricIds;
  vectilesMetricGroup?: VectilesMetricGroups;
  propertyType?: PropertyTypeEnum | null;
  layersControl?: LayersControlConfig;
  zoomControl?: ControlConfig;
  satelliteControl?: ControlConfig;
  clusterControl?: ControlConfig;
  fitBounds?: Omit<FitBoundsPayload, 'mapId'>;
  fitBoundsMaxZoom?: number;
  mapPreferences?: MapPreferences;
  onZoomControlChange?: (zoom: number) => void;
  enableSearchParams?: boolean;
}

export const HcMap = forwardRef<MapRef, HcMapProps>(
  (
    {
      dataHcName,
      dataHcEventSection,
      mapId,
      className,
      propertyType,
      fitBounds,
      fitBoundsMaxZoom,
      loading,
      layersControl,
      mapOutlineConfigs,
      zoomControl,
      mapStyle,
      onZoomControlChange,
      clusterControl,
      satelliteControl,
      vectilesMetricGroup,
      uiPreferencesKey,
      vectilesMetricId,
      enableSearchParams,
      ...mapProps
    }: HcMapProps,
    ref
  ) => {
    const [isMapStateMounted, setIsMapStateMounted] = useState<boolean>(false);
    const {
      mapState,
      actions: {
        hcMapViewportChange,
        hcMapMount,
        hcMapLayersChangePropertyType,
        hcMapChangeZoom,
        hcMapFitBoundsToGeoLocations,
        hcMapChangeVectilesMetric,
        hcMapChangeVectilesMetricGroup,
      },
    } = useHcMap(mapId);
    const { children, onMove, latitude, longitude } = mapProps;
    const [hcMapSearchParams, setHcMapSearchParams] =
      useHcMapSearchParams(mapId);
    const containerRef = useRef<HTMLDivElement>(null);
    const mapRef = ref && isRefObject<MapRef>(ref) ? ref : createRef<MapRef>();
    const { current: theMap } = mapRef;
    const parentDimensions = useParentDimensions({
      ref: containerRef,
    });
    const previousParentDimensions = usePrevious(parentDimensions);
    const debouncedResize = useCallback(
      (map: Map | undefined) =>
        debounce(() => {
          map?.resize();
        }, DEBOUNCE_WAIT),
      []
    );

    // Rerender if parent isn't ready
    useRerender({
      shouldRerender: !parentDimensions?.height || !parentDimensions.width,
      max: 1,
    });

    useEffect(() => {
      if (
        (mapRef.current !== null &&
          parentDimensions?.width &&
          parentDimensions?.height &&
          parentDimensions?.width !== previousParentDimensions?.width) ||
        parentDimensions?.height !== previousParentDimensions?.height
      ) {
        debouncedResize(mapRef.current?.getMap())();
      }
    }, [mapRef, debouncedResize, parentDimensions, previousParentDimensions]);

    // useEffects to allow props to control the hcMapSlice
    useEffect(() => {
      if (
        !isMapStateMounted &&
        parentDimensions?.width &&
        parentDimensions?.height &&
        latitude &&
        longitude &&
        !loading
      ) {
        let viewport = mapState
          ? mapState.viewport
          : {
              height: parentDimensions?.height,
              width: parentDimensions?.width,
              zoom: mapProps.zoom || INITIAL_ZOOM,
              latitude,
              longitude,
            };
        // Search Params take priority over fit on mount
        if (enableSearchParams && hcMapSearchParams) {
          viewport = {
            ...viewport,
            ...hcMapSearchParams,
          };
        } else if (fitBounds) {
          const fitResult = fitGeoLocationsToViewPort(
            fitBounds.coords,
            { viewport },
            fitBounds.padding || 100
          );
          if (fitResult) {
            viewport = {
              latitude: fitResult.coords.lat,
              longitude: fitResult.coords.lng,
              height: fitResult.viewport.height,
              width: fitResult.viewport.width,
              zoom:
                fitBoundsMaxZoom && fitResult.viewport.zoom > fitBoundsMaxZoom
                  ? fitBoundsMaxZoom
                  : fitResult.viewport.zoom,
            };
          }
        }
        hcMapMount({
          mapId,
          mapState: mapState
            ? {
                ...mapState,
                fitId: fitBounds?.fitId,
                viewport,
              }
            : {
                uiPreferencesKey,
                viewport,
                fitId: fitBounds?.fitId,
                heatmap: {
                  vectilesMetricId: vectilesMetricId || null,
                  vectilesMetricGroup: vectilesMetricGroup || null,
                  showMonochrome: false,
                  propertyType,
                },
                markers: {
                  showMarkers: true,
                },
                mapStyle: mapStyle || MapStyles.Default,
              },
        });
        setIsMapStateMounted(true); // Do not add hcMapMount to deps else
        // cause infinite re render
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [longitude, mapProps.zoom, latitude, parentDimensions, loading]);

    useEffect(() => {
      if (isMapStateMounted && latitude && longitude) {
        hcMapViewportChange({
          mapId,
          viewport: {
            latitude,
            longitude,
          },
        });
        // Do not add hcMapViewportChange to deps else cause infinite re render
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapId, latitude, longitude]);

    // Support changing map layers externally
    useEffect(() => {
      hcMapChangeVectilesMetric({
        mapId,
        vectilesMetricId: vectilesMetricId || null,
      });
    }, [mapId, vectilesMetricId]);

    // Support changing map layer groups externally
    useEffect(() => {
      hcMapChangeVectilesMetricGroup({
        mapId,
        vectilesMetricGroup: vectilesMetricGroup || null,
      });
    }, [mapId, vectilesMetricGroup]);

    const handleUpdateSearchParams = useCallback(
      debounce(setHcMapSearchParams, 500),
      [setHcMapSearchParams]
    );

    useEffect(() => {
      // it's important handleUpdateSearchParams is debounced as viewport can change multiple times as the result of one user drag
      if (
        enableSearchParams &&
        mapState?.viewport.latitude &&
        mapState?.viewport.longitude &&
        mapState?.viewport.zoom
      ) {
        handleUpdateSearchParams({
          latitude: mapState.viewport.latitude,
          longitude: mapState.viewport.longitude,
          zoom: mapState.viewport.zoom,
        });
      }
      return () => {
        handleUpdateSearchParams.cancel();
      };
    }, [mapState, enableSearchParams, handleUpdateSearchParams]);

    useEffect(() => {
      hcMapLayersChangePropertyType({
        mapId,
        propertyType: propertyType,
        // Do not add hcMapLayersChangePropertyType to deps else cause infinite re render
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyType, mapId]);

    useEffect(() => {
      if (
        fitBounds?.coords.length &&
        fitBounds.fitId &&
        fitBounds.fitId !== mapState?.fitId &&
        isMapStateMounted
      ) {
        hcMapFitBoundsToGeoLocations({ mapId, ...fitBounds });
        // Do not add hcMapFitBoundsToGeoLocations to deps else cause infinite re render
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fitBounds, isMapStateMounted, mapId]);

    const animateMapMove = useCallback(
      ({ longitude, latitude }: MoveMapProps) => {
        theMap?.flyTo({
          center: [longitude, latitude],
          duration: TRANSITION_DURATION,
        });
      },
      [mapRef]
    );

    return (
      <div
        data-hc-name={dataHcName}
        data-hc-event-section={dataHcEventSection}
        className={styles.HcMap}
        ref={containerRef}
      >
        {loading || !isMapStateMounted ? (
          <Skeleton dataHcName={`${dataHcName}-skeleton`} />
        ) : (
          <ReactMapGL
            {...mapProps}
            {...mapState?.viewport}
            onZoom={(event) => {
              const { zoom } = event.viewState;
              hcMapChangeZoom({
                mapId,
                zoom,
              });
            }}
            key={mapId}
            ref={mapRef}
            mapStyle={mapState?.mapStyle || MapStyles.Default}
            mapboxAccessToken={MAPBOX_KEY}
            onMove={(event) => {
              const viewState = event.viewState;
              onMove?.(event);
              const { latitude, longitude, zoom } = viewState;
              animateMapMove({ longitude, latitude });

              if (latitude && longitude && zoom) {
                hcMapViewportChange({
                  mapId,
                  viewport: {
                    latitude,
                    longitude,
                    zoom,
                    width: parentDimensions?.width,
                    height: parentDimensions?.height,
                  },
                });
              }
            }}
            attributionControl={false}
          >
            {mapOutlineConfigs &&
              mapOutlineConfigs.map((outlineConfig) => (
                <HcMapOutline key={outlineConfig.id} {...outlineConfig} />
              ))}
            {zoomControl && (
              <ZoomControl
                zoom={mapState?.viewport.zoom || INITIAL_ZOOM}
                position={zoomControl.position}
                duration={TRANSITION_DURATION}
                mapId={mapId}
                onChange={onZoomControlChange}
              />
            )}
            {satelliteControl && (
              <SatelliteToggle
                mapId={mapId}
                mapStyle={mapState?.mapStyle || MapStyles.Default}
                position={satelliteControl.position}
              />
            )}
            {clusterControl && (
              <ClusterToggle
                className={styles.ClusterToggle}
                position={clusterControl.position}
              />
            )}
            {children}
            {layersControl && (
              <HcMapLayersControl mapId={mapId} {...layersControl} />
            )}
            {mapState?.heatmap.vectilesMetricId && (
              <HcMapLayers mapId={mapId} />
            )}
          </ReactMapGL>
        )}
      </div>
    );
  }
);
