import React from 'react';

import { LogoutIcon,UserMenuButton } from '@hcs/design-system';

import { useLogout } from '../../hooks/useLogout';

interface UserMenuLogoutButtonProps {
  dataHcName: string;
  className?: string;
  onLogout?: VoidFunction;
}
export const UserMenuLogoutButton = ({
  dataHcName,
  className,
  onLogout,
}: UserMenuLogoutButtonProps) => {
  const logoutMutation = useLogout();
  return (
    <UserMenuButton
      className={className}
      dataHcName={dataHcName}
      onClick={() => {
        logoutMutation.mutate();
        onLogout?.();
      }}
      icon={<LogoutIcon dataHcName={`${dataHcName}-icon`} />}
      label="Logout"
    />
  );
};
