import {
  ListingStatusCerberus,
  PropertyTransactionHistory,
  PropertyTransactionHistoryQuery,
  TransactionHistoryEvent,
} from '@hcs/types';

export const sortTransactionHistoryEvents = (
  e1: TransactionHistoryEvent,
  e2: TransactionHistoryEvent
) =>
  (e1.date || '0') < (e2.date || '0')
    ? 1
    : (e1.date || '0') > (e2.date || '0')
    ? -1
    : 0;

export const cerberusLookupResponseToPropertyTransactionHistory = (
  response: PropertyTransactionHistoryQuery
): PropertyTransactionHistory => {
  const eventHistorySale: PropertyTransactionHistory['eventHistorySale'] = [];
  response.lookup?.complexFields?.[0]?.sale?.eventHistory?.forEach((e) => {
    if (e) {
      eventHistorySale.push({
        ...e,
        // TODO: Remove casting when enums are defined in cerberus
        status: e.status ? (e.status as ListingStatusCerberus) : undefined,
      });
    }
  });
  const eventHistoryRental: PropertyTransactionHistory['eventHistoryRental'] =
    [];
  response.lookup?.complexFields?.[0]?.rental?.eventHistory?.forEach((e) => {
    if (e) {
      eventHistoryRental.push({
        ...e,
        // TODO: Remove casting when enums are defined in cerberus
        status: e.status ? (e.status as ListingStatusCerberus) : undefined,
      });
    }
  });
  return {
    eventHistorySale: eventHistorySale.sort(sortTransactionHistoryEvents),
    eventHistoryRental: eventHistoryRental.sort(sortTransactionHistoryEvents),
  };
};
