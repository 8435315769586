import { gql } from 'graphql-request';

import {
  FRAGMENT_TRANSACTION_HISTORY_EVENT_HISTORY_RENTAL,
  FRAGMENT_TRANSACTION_HISTORY_EVENT_HISTORY_SALE,
} from './fragments';

export const QUERY_PROPERTY_TRANSACTION_HISTORY = gql`
  ${FRAGMENT_TRANSACTION_HISTORY_EVENT_HISTORY_SALE}
  ${FRAGMENT_TRANSACTION_HISTORY_EVENT_HISTORY_RENTAL}
  query PropertyTransactionHistory($cerberusInput: CerberusInput!) {
    # HC INTERNAL
    lookup(id: $cerberusInput) {
      complexFields {
        sale {
          eventHistory {
            ...TransactionHistoryEventSale
          }
        }
        rental {
          eventHistory {
            ...TransactionHistoryEventRental
          }
        }
      }
    }
  }
`;
