import React, { useMemo } from 'react';

import { NoContent, NoDataIcon, Table } from '@hcs/design-system';
import {
  PropertyTransactionHistory,
  TransactionHistoryActionsCallback,
  TransactionHistoryEvent,
  TransactionHistoryOnClickRow,
  ValueTypes,
} from '@hcs/types';

import { PropertyTransactionHistoryTableHeader } from '../../components/PropertyTransactionHistoryTable/PropertyTransactionHistoryTableHeader';
import { PropertyTransactionHistoryTableRow } from '../../components/PropertyTransactionHistoryTable/PropertyTransactionHistoryTableRow';
import { sortTransactionHistoryEvents } from '../../utils/propertyTransactionHistory.utils';

export interface PropertyTransactionHistoryTableProps {
  dataHcName?: string;
  dataHcEventSection?: string;
  propertyTransactionHistory: PropertyTransactionHistory | null | undefined;
  isLoading?: boolean;
  valueType?: ValueTypes;
  className?: string;
  showPriceChanges?: boolean;
  isError?: boolean;
  onClickRow?: TransactionHistoryOnClickRow;
  actions?: TransactionHistoryActionsCallback;
}

// Account for missing listingGroupIds (effects rental events)
const getListingGroupId = (event: TransactionHistoryEvent) => {
  return event.listingGroupId || `${event.hcMlsId}-${event.listingId}`;
};

const getPreviousEvent = (
  transactionHistoryEvents: TransactionHistoryEvent[],
  currentIndex: number
) => {
  const currentEvent = transactionHistoryEvents[currentIndex];
  let prevEvent: TransactionHistoryEvent | undefined;
  let prevIndex = currentIndex - 1;
  if (currentEvent) {
    const isRental = 'isRental' in currentEvent && currentEvent.isRental;
    while (!prevEvent && prevIndex > -1) {
      const event = transactionHistoryEvents[prevIndex];
      if (event) {
        if (isRental && 'isRental' in event && event.isRental) {
          prevEvent = event;
        } else if (!isRental && (!('isRental' in event) || !event.isRental)) {
          prevEvent = event;
        }
      }
      prevIndex--;
    }
  }
  return prevEvent;
};

export const PropertyTransactionHistoryTable = ({
  dataHcName: dataHcNameProp,
  dataHcEventSection,
  propertyTransactionHistory,
  valueType,
  className,
  showPriceChanges,
  onClickRow,
  isLoading,
  actions,
}: PropertyTransactionHistoryTableProps) => {
  const dataHcName = dataHcNameProp || 'property-transaction-history-table';
  const transactionHistoryEvents = useMemo(() => {
    if (valueType === 'RENTAL') {
      return propertyTransactionHistory?.eventHistoryRental;
    } else if (valueType === 'SOLD') {
      return propertyTransactionHistory?.eventHistorySale;
    } else {
      return [
        ...(propertyTransactionHistory?.eventHistoryRental?.map((e) => {
          return {
            ...e,
            isRental: true,
          };
        }) || []),
        ...(propertyTransactionHistory?.eventHistorySale || []),
      ].sort(sortTransactionHistoryEvents);
    }
  }, [propertyTransactionHistory, valueType]);
  return (
    <>
      <Table
        dataHcName={dataHcName}
        dataHcEventSection={dataHcEventSection}
        className={className}
        skeletonConfig={{
          isLoading: !!isLoading,
          colCount: 8,
          rows: 10,
        }}
      >
        <PropertyTransactionHistoryTableHeader
          actions={actions}
          dataHcName={dataHcName}
        />
        {transactionHistoryEvents?.map((transactionHistoryEvent, i) => {
          const previousEvent = getPreviousEvent(transactionHistoryEvents, i);
          const isMostRecentEventInGroup =
            !previousEvent ||
            getListingGroupId(previousEvent) !==
              getListingGroupId(transactionHistoryEvent);
          const rowContent = (
            <PropertyTransactionHistoryTableRow
              dataHcName={`${dataHcName}-row-${i}`}
              key={`transaction-${transactionHistoryEvent.hcMlsId}-${transactionHistoryEvent.listingId}-${i}`}
              transactionHistoryEvent={transactionHistoryEvent}
              actions={actions}
              onClick={onClickRow}
              showPriceChanges={showPriceChanges}
              isMostRecentEventInGroup={isMostRecentEventInGroup}
            />
          );
          return rowContent;
        })}
      </Table>
      {!isLoading && !transactionHistoryEvents?.length && (
        <NoContent
          dataHcName={`${dataHcName}-null`}
          Icon={NoDataIcon}
          margins
        >{`No ${
          valueType === 'RENTAL'
            ? 'rental '
            : valueType === 'SOLD'
            ? 'sale '
            : ''
        }transaction history available.`}</NoContent>
      )}
    </>
  );
};
