import { DocumentRoles, ForecastChartZipDocument, ReportId } from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useForecastChartZipDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<ForecastChartZipDocument>(
    reportId,
    DocumentRoles.ForecastChartZip
  );
};
