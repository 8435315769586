import { useMemo } from 'react';

import { AppSlugs, ZuoraSubscription } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useAccount } from './useAccount';
import { useCheckAppAccess } from './useCheckAppAccess';

export const useAppZuoraSubscription = <T extends AppSlugs>(appSlug: T) => {
  const accountQuery = useAccount();
  const hasAppAccessQuery = useCheckAppAccess(appSlug);

  const combinedQuery = combineUseQueryResult([
    accountQuery,
    hasAppAccessQuery,
  ]);

  const subscriptions: Extract<ZuoraSubscription, { name: T }> | undefined =
    useMemo(() => {
      if (combinedQuery.isSuccess && hasAppAccessQuery.data) {
        const app = accountQuery.data?.accessibleApplications.find(
          (
            accessibleApp
          ): accessibleApp is Extract<ZuoraSubscription, { name: T }> =>
            accessibleApp.name === appSlug
        );

        return app;
      }

      return undefined;
    }, [
      accountQuery.data,
      hasAppAccessQuery.data,
      appSlug,
      combinedQuery.isSuccess,
    ]);

  return {
    ...combinedQuery,
    data: subscriptions,
  };
};
