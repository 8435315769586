import {
  ReportSummaryOrderItemsTableOptions,
  ReportSummaryTableOptions,
} from '@hcs/types';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL =
  'report-summary-table-cell';
export const REPORT_SUMMARY_TABLE_OPTIONS = [
  ReportSummaryTableOptions.completedDate,
  ReportSummaryTableOptions.order,
  ReportSummaryTableOptions.orderedBy,
  ReportSummaryTableOptions.amountOrdered,
  ReportSummaryTableOptions.productType,
  ReportSummaryTableOptions.label,
  ReportSummaryTableOptions.download,
  ReportSummaryTableOptions.more,
];

export const DATA_HC_NAME_REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELL =
  'report-summary-order-items-table-cell';
export const REPORT_SUMMARY_ORDER_ITEMS_TABLE_OPTIONS = [
  ReportSummaryOrderItemsTableOptions.completedDate,
  ReportSummaryOrderItemsTableOptions.fileId,
  ReportSummaryOrderItemsTableOptions.address,
  ReportSummaryOrderItemsTableOptions.city,
  ReportSummaryOrderItemsTableOptions.state,
  ReportSummaryOrderItemsTableOptions.zip,
];
