import { useMemo } from 'react';

import { FeatureFlags } from '@hcs/types';

import { useHcsAdminFeatureFlagsForOrg } from './useHcsAdminFeatureFlagsForOrg';

interface HcsAdminFeatureFlagForOrgProps {
  orgId?: number;
  featureFlag: FeatureFlags;
}

export const useHcsAdminFeatureFlagForOrg = ({
  orgId,
  featureFlag,
}: HcsAdminFeatureFlagForOrgProps) => {
  const featureFlagsQuery = useHcsAdminFeatureFlagsForOrg(orgId);
  return {
    ...featureFlagsQuery,
    data: useMemo(
      () =>
        featureFlagsQuery.data?.find(
          (ele) => ele.featureFlagConfig.key === featureFlag
        ),
      [featureFlagsQuery.data, featureFlag]
    ),
  };
};
