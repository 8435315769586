import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import { CompTypes } from '@hcs/types';
import {
  CompsFarmDocument,
  RentalCompsFarmDocument,
  ReportApiDocument,
  ReportId,
  SelectCompArg,
} from '@hcs/types';

import { ReportApi } from '../api';
import { QUERY_KEY_DOCUMENT_ROLE } from '../hooks/useDocumentRole';

export const useAddCompsToFarm = (
  reportId: ReportId,
  compType: CompTypes,
  options?: UseMutationOptions<
    (CompsFarmDocument | RentalCompsFarmDocument)[] | null,
    unknown,
    SelectCompArg
  >
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation(
    async (selectCompsArg: SelectCompArg) => {
      toastOpen({
        type: 'loading',
        title: 'Updating report values...',
        duration: null,
      });
      return await ReportApi.addToCompsFarm({
        ...selectCompsArg,
        reportId,
        compType,
      });
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        data?.forEach((doc) => {
          const queryKeyDocRole = [QUERY_KEY_DOCUMENT_ROLE, reportId, doc.role];
          let docInCache = false;
          const cacheDocRole =
            queryClient
              .getQueryData<ReportApiDocument[]>(queryKeyDocRole)
              ?.map((d) => {
                if (d.documentId === doc.documentId) {
                  docInCache = true;
                  return doc;
                } else {
                  return d;
                }
              }) || [];
          if (!docInCache) {
            cacheDocRole?.push(doc);
          }
          queryClient.setQueryData<ReportApiDocument[]>(
            queryKeyDocRole,
            cacheDocRole
          );
        });
        options?.onSuccess?.(data, variables, context);
      },
      onError: (err, vars, context) => {
        options?.onError?.(err, vars, context);
        toastOpen({
          type: 'error',
          title: 'Error adding to farm ',
        });
      },
    }
  );
};
