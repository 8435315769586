import React, { Fragment } from 'react';
import classNames from 'classnames';

import { Anchor, Button } from '@hcs/design-system';
import {
  AgileSuiteProductConfigExtended,
  AppConfigExtended,
  MeaningfulEventTypes,
} from '@hcs/types';

import styles from './ProductPreview.module.css';

interface ProductPreviewProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  premiumFeature?: boolean;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  /** Auth AppConfig */
  appConfigExtended: AppConfigExtended | AgileSuiteProductConfigExtended;
  onClickRequestAccess?: VoidFunction;
  limitedAccess?: boolean;
}

export const ProductPreview = ({
  dataHcName,
  className,
  appConfigExtended,
  limitedAccess,
  premiumFeature,
  onClickRequestAccess,
}: ProductPreviewProps) => {
  const {
    hasAccess,
    appConfig: {
      Icon,
      name,
      descriptionLong,
      marketingUrl,
      marketingImage,
      appSlug,
      premiumDescription,
    },
  } = appConfigExtended;

  /**
   * Currently, Market Insights app has Acquisition Explorer as its name.
   *
   * const appName is meant to ensure that, when a user views Market Insights
   * ProductPreview, the correct name "Market Insights" appears vs.
   * Acquisition Explorer.
   *
   * See AppConfigMarketInsights.constants.ts for additional details.
   */

  const appName = appSlug === 'Market Insights' ? 'Market Insights' : name;

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={`${appConfigExtended.appConfig.name} Product Preview`}
      className={classNames(styles.ProductPreview, className)}
    >
      <div className={styles.ProductModalContent}>
        <div className={styles.ProductDetails}>
          {Icon && (
            <div className={styles.ProductIcon}>
              <Icon
                dataHcName={`${dataHcName}-icon`}
                height="100px"
                width="100px"
                size={'xl'}
              />
            </div>
          )}
          <div className={styles.ProductName}>
            <h1 data-hc-name={`${dataHcName}-name`}>
              {premiumFeature ? `${appName} Premium` : appName}
            </h1>
          </div>
          <div className={styles.ProductFullDescription}>
            <p data-hc-name={`${dataHcName}-description`}>
              {premiumFeature
                ? typeof premiumDescription === 'string'
                  ? premiumDescription
                  : premiumDescription?.map((line, i) => (
                      <Fragment key={i}>
                        {i !== 0 && <br />}
                        {line}
                      </Fragment>
                    ))
                : typeof descriptionLong === 'string'
                ? descriptionLong
                : descriptionLong.map((line, i) => (
                    <Fragment key={i}>
                      {i !== 0 && <br />}
                      {line}
                    </Fragment>
                  ))}
            </p>
          </div>

          <div className={classNames(styles.ProductActions)}>
            {((!hasAccess && onClickRequestAccess) || limitedAccess) && (
              <Button
                dataHcEventType={MeaningfulEventTypes.Goal}
                dataHcEventName={`${appConfigExtended.appConfig.name} Contact Sales Click`}
                dataHcName={`${dataHcName}-request-access`}
                onClick={onClickRequestAccess}
              >
                {premiumFeature ? `Upgrade to Premium` : 'Request Access'}
              </Button>
            )}
            {marketingUrl && (
              <Anchor
                dataHcEventType={MeaningfulEventTypes.Goal}
                dataHcEventName={`${appConfigExtended.appConfig.name} Learn More Click`}
                className={styles.LearnMore}
                dataHcName={`${dataHcName}-learn-more`}
                target="_blank"
                href={marketingUrl}
              >
                Learn More
              </Anchor>
            )}
          </div>
        </div>
        <div className={styles.ProductImage}>
          {marketingImage && (
            <img
              alt="logo"
              data-hc-name={`${dataHcName}-product-image`}
              src={marketingImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
