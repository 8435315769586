import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

import { AnchorProps } from '@hcs/types';

import styles from './Anchor.module.css';

export const Anchor = ({
  dataHcName,
  dataHcEventName,
  dataHcEventType,
  className,
  onClick,
  children,
  href,
  rel,
  target,
  type,
  disabled,
}: AnchorProps) => {
  return (
    <a
      data-hc-name={dataHcName}
      data-hc-event-name={dataHcEventName}
      data-hc-event-type={dataHcEventType}
      className={classNames(styles.Anchor, className, {
        [styles.disabled]: disabled,
      })}
      onClick={!disabled ? onClick : undefined}
      href={!disabled ? href : undefined}
      type={type}
      target={target}
      {...(target === '_blank' ? { rel: 'noreferrer' } : { rel })}
    >
      {children}
    </a>
  );
};

export type LinkAnchorProps = LinkProps & {
  dataHcName: string;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
};

export const LinkAnchor = ({
  dataHcName,
  children,
  className,
  disabled,
  ...restProps
}: LinkAnchorProps) => {
  return (
    <Link
      data-hc-name={dataHcName}
      className={classNames(styles.Anchor, className, {
        [styles.disabled]: disabled,
      })}
      {...restProps}
    >
      {children}
    </Link>
  );
};
