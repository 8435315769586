import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { Popover } from '@hcs/design-system';
import { SortIcon } from '@hcs/design-system';
import { useClickOutsideComponent } from '@hcs/hooks';
import { PropertyListSortOption } from '@hcs/types';

import styles from './SortPopover.module.css';

interface SortPopoverProps {
  selected: PropertyListSortOption | null;
  sortOptions: PropertyListSortOption[];
  onSelect: (sortOption: PropertyListSortOption) => void;
  dataHcName: string;
}

export const SortPopover = ({
  selected,
  sortOptions,
  onSelect,
  dataHcName,
}: SortPopoverProps) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const isActive = (option: PropertyListSortOption) => {
    return option.field === selected?.field && option.order === selected.order;
  };

  const contentRef = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(contentRef, () => setPopoverActive(false));

  return (
    <Popover
      dataHcName={dataHcName}
      trigger={
        <div
          className={styles.Trigger}
          onClick={() => setPopoverActive(!popoverActive)}
          data-hc-name={`${dataHcName}-trigger`}
        >
          <SortIcon dataHcName={`${dataHcName}-sort-icon`} />
          Sort
          {selected !== null && (
            <span data-hc-name={`${dataHcName}-selected-label`}>
              :&nbsp;{selected?.label}
            </span>
          )}
        </div>
      }
      content={
        <div ref={contentRef} className={styles.Content}>
          {sortOptions.map((option) => {
            return (
              <button
                key={option.label}
                data-hc-name={`${dataHcName}-option`}
                onClick={() => {
                  onSelect(option);
                  setPopoverActive(false);
                }}
                className={classNames({
                  [styles.activeOption]: isActive(option),
                })}
              >
                {option.label}
              </button>
            );
          })}
        </div>
      }
      active={popoverActive}
    />
  );
};
