import { TableCellProps } from '@hcs/types';
import { TableHeaderCellProps } from '@hcs/types';
import { OmOrder, OrdersTableOptions } from '@hcs/types';

import { ORDER_ACTION_CONFIG } from './Action';
import { ORDER_ACTUAL_DELIVERY_DATE_CONFIG } from './ActualDeliveryDate';
import { ORDER_CREATED_CONFIG } from './Created';
import { ORDER_LABEL_CONFIG } from './Label';
import { ORDER_NAME_CONFIG } from './Name';
import { ORDER_ORDER_ITEMS_COUNT_CONFIG } from './OrderItemsCount';
import { ORDER_ORDER_TYPE_DESCRIPTOR_NAME_CONFIG } from './OrderTypeDescriptorName';
import { ORDER_OWNER_EMAIL_CONFIG } from './OwnerEmail';
import { ORDER_STATUS_CONFIG } from './Status';

export interface OrdersTableCellsDisplayProps {
  order: OmOrder;
}

export interface OrdersTableCellConfig {
  HeaderCell: {
    ({ ...tableHeaderCellProps }): JSX.Element;
    defaultProps: Partial<TableHeaderCellProps>;
  };
  ContentCell: {
    ({ order }: { order: OmOrder }): JSX.Element;
    defaultProps: Partial<TableCellProps>;
  };
  label: string;
  sortable?: boolean;
}

export const ORDERS_TABLE_CELLS_CONFIG: {
  [key in OrdersTableOptions]: OrdersTableCellConfig;
} = {
  [OrdersTableOptions.actualDeliveryDate]: ORDER_ACTUAL_DELIVERY_DATE_CONFIG,
  [OrdersTableOptions.action]: ORDER_ACTION_CONFIG,
  [OrdersTableOptions.label]: ORDER_LABEL_CONFIG,
  [OrdersTableOptions.name]: ORDER_NAME_CONFIG,
  [OrdersTableOptions.orderItemsCount]: ORDER_ORDER_ITEMS_COUNT_CONFIG,
  [OrdersTableOptions.createdAt]: ORDER_CREATED_CONFIG,
  [OrdersTableOptions.ownerEmail]: ORDER_OWNER_EMAIL_CONFIG,
  [OrdersTableOptions.status]: ORDER_STATUS_CONFIG,
  [OrdersTableOptions.orderType]: ORDER_ORDER_TYPE_DESCRIPTOR_NAME_CONFIG,
};
