import React, { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { Popover } from '@hcs/design-system';
import { TOOLTIP_OFFSET } from '@hcs/design-system';
import { useRerender } from '@hcs/hooks';
import { useVisibleInOverflow } from '@hcs/hooks';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { FilterButton } from '../../../components-deprecated';
import { useCompsFiltersDocument, useReportPermissions } from '../../../hooks';
import { useCompFilterBarSlice } from '../hooks/useCompFilterBarSlice';

import styles from './CompFilterButton.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  label: string;
  content: ReactNode;
  active: boolean;
  compField: PropertyStateFields | CompFields;
}
const MoreFiltersPortal = ({ children }: { children: ReactNode }) => {
  const {
    state: { overflowPortalId },
  } = useCompFilterBarSlice();
  const elm = overflowPortalId
    ? document.getElementById(overflowPortalId)
    : null;
  // Rerender in case the portal is mounted simultaneously
  useRerender({ deps: [elm], shouldRerender: !elm, max: 1 });
  if (!elm) return null;
  return createPortal(children, elm);
};

const dataHcName = 'comp-filter-button';
export const CompFilterButton = ({
  className,
  compType,
  label,
  content,
  active,
  compField,
  reportId,
}: Props) => {
  const {
    state: {
      buttonsVisible,
      overflowVisible,
      overflowButtonVisible,
      overflowCount,
    },
    actions: { buttonVisibilityChange },
  } = useCompFilterBarSlice();
  const {
    data: { isEditable },
  } = useReportPermissions(reportId);
  const ref = useRef<HTMLButtonElement>(null);
  const { visibleFull } = useVisibleInOverflow(ref);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  // Rerender whenever the filters change to ensure button is still visible
  useRerender({ deps: [filterDocument] });
  useEffect(() => {
    if (buttonsVisible[compField] !== visibleFull && ref.current) {
      // Need to wait until the overflow button is in the dom since it affects the parent size
      if (!!overflowCount === overflowButtonVisible) {
        buttonVisibilityChange({ compField, visible: visibleFull });
      }
    }
  }, [
    buttonsVisible[compField],
    visibleFull,
    overflowButtonVisible,
    overflowCount,
  ]);
  if (!isEditable && !active) return null;
  const button = (
    // The button needs this extra div in order to calculate visibility correctly
    // Without it the margin can cause an infinite loop
    <div className={styles.CompFilterButton}>
      <FilterButton
        ref={ref}
        dataHcName={dataHcName}
        className={className}
        active={active}
      >
        {label}
      </FilterButton>
    </div>
  );
  return (
    <>
      {isEditable ? (
        <Popover
          positionOffset={TOOLTIP_OFFSET}
          showOnMouseEnter={false}
          dataHcName={`${dataHcName}-popover`}
          trigger={button}
          theme={{
            Button: classNames({ [styles.hidden]: !visibleFull }),
          }}
          content={
            visibleFull && (
              <div className={styles.PopoverContent}>{content}</div>
            )
          }
          active={!visibleFull ? false : undefined}
        />
      ) : (
        button
      )}
      {!visibleFull && overflowVisible && (
        <MoreFiltersPortal>
          <div
            className={styles.OverflowFilter}
            data-hc-name={`${dataHcName}-filter-overflow-${compField}`}
          >
            {content}
          </div>
        </MoreFiltersPortal>
      )}
    </>
  );
};
