import { ENV } from '@hcs/webapps';

export const useHcsDevToolsEnabled = () => {
  // @jnettleman - safe usage of localStorage
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  const localStorageSet = localStorage.getItem('canary-tools') === 'true';
  return {
    data:
      // On by default for HC users in lower envs
      ENV !== 'prod' || localStorageSet,
  };
};
