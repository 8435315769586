import React from 'react';
import classNames from 'classnames';

import { ErrorIcon } from '../../../../svgs/icons/indicator';
import { LoadingSpinner } from '../../../global/loading-errors-null/LoadingSpinner';
import { Tooltip } from '../../popovers/Tooltip';
import { Anchor } from '../Anchor';

import styles from './DownloadLink.module.css';

export interface DownloadLinkTheme {
  Container?: string;
  Link?: string;
}

export interface DownloadLinkProps {
  dataHcName: string;
  label: string;
  showDownloadLink: boolean;
  isLoading: boolean;
  error?: {
    message: string;
  };
  theme?: DownloadLinkTheme;
  onDownload: VoidFunction;
}
export const DownloadLink = (props: DownloadLinkProps) => {
  const {
    dataHcName,
    theme,
    label,
    showDownloadLink,
    isLoading,
    error,
    onDownload,
  } = props;
  return (
    <span
      data-hc-name={dataHcName}
      className={classNames(styles.DownloadLink, theme?.Container)}
    >
      {showDownloadLink && (
        <span className={styles.DownloadLinkSection}>
          {isLoading && (
            <LoadingSpinner
              dataHcName={`${dataHcName}-download-link-loading`}
              className={styles.LoadingSpinner}
              small
              absoluteCenter
            />
          )}
          <Anchor
            dataHcName={`${dataHcName}-download-link`}
            onClick={onDownload}
            className={theme?.Link}
            disabled={isLoading}
          >
            {label}
          </Anchor>
        </span>
      )}
      {error && (
        <Tooltip
          dataHcName={`${dataHcName}-error-popover`}
          trigger={
            <ErrorIcon
              className={styles.ErrorIcon}
              size="sm"
              color="error-10"
              dataHcName={`${dataHcName}-error-icon`}
            />
          }
          label={
            <span data-hc-name={`${dataHcName}-error-message`}>
              {error.message}
            </span>
          }
        />
      )}
    </span>
  );
};
