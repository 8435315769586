import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CompFieldConfig,
  CompFields,
  CompFieldsArgsProps,
  CompFieldsTableCellProps,
} from '@hcs/types';
import { formatMissing } from '@hcs/utils';

const FIELD = CompFields.locationSimilarity;
type FieldConfig = CompFieldConfig<typeof FIELD>;
const label = 'Location';
const labelShort = 'Location';
const compPath: FieldConfig['compPath'] = '/locationSimilarity';
const getValue: FieldConfig['getValue'] = (comp) => comp?.locationSimilarity;
const formatValue: FieldConfig['formatValue'] = (comp) => {
  const locationSimilarity = getValue(comp);
  return `${formatMissing(locationSimilarity)}`;
};
// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Location';
const formatValueShort: FieldConfig['formatValueShort'] = formatValue;

const formatInline: FieldConfig['formatInline'] = (comp) => formatValue(comp);

// Component for displaying a field from a comp
const Display: FieldConfig['Display'] = ({ comp }: CompFieldsArgsProps) => {
  return <>{formatValue(comp)}</>;
};

const HeaderCell = (props: TableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};

// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'center',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({ comp, ...tableCellProps }: CompFieldsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display comp={comp} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'center',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const LOCATION_SIMILARITY_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  compPath,
  getValue,
  formatValue,
  labelMicro,
  formatValueShort,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
};
