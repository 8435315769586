import { urlSearchParamsToObject } from '@hcs/utils';

export const useUrlSearchParamEngagementEventData = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const result = urlSearchParamsToObject(searchParams);
  if (Object.keys(result).length) {
    return result?.token
      ? {
          ...result,
          token: 'removed',
        }
      : result;
  } else {
    return undefined;
  }
};
