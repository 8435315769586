import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  PropertySpatialDetailResultsHit,
  PropertySpatialHit,
  PropertyStateComplexFieldsRentalPreview,
} from '@hcs/types';
import {
  PropertyStateCerberusInput,
  PropertyStateComplexFieldsSaleCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { formatNumberAbbrev } from '@hcs/utils';

export const getPropertyListPriceLabel = (
  propertyHit: PropertySpatialHit,
  isRental?: boolean
): string => {
  return formatNumberAbbrev(
    getPropertyStateFieldValue(
      isRental
        ? PropertyStateFields.currentPriceRental
        : PropertyStateFields.currentPrice,
      {
        // pretending to be property state core
        propertyStateType: PropertyStateType.Core,
        propertyState: propertyHit,
      }
    )
  );
};

export const convertSpatialHitToPropertySpatialHit = (
  hit: PropertySpatialDetailResultsHit
): PropertySpatialHit => {
  if (hit) {
    return {
      hcAddressId: hit.propertyDetails.hcAddressId,
      location:
        hit.propertyDetails?.location?.discovery?.hcAddress ||
        hit.propertyDetails?.location?.hc
          ? {
              ...hit.propertyDetails.location?.discovery?.hcAddress,
              ...hit.propertyDetails.location?.hc,
            }
          : undefined,
      // TODO: Remove casting when enum types are defined in cerberus. Enums defined as strings are currently causing type errors
      complexFieldsSale: hit.complexFields?.[0]?.sale as
        | PropertyStateComplexFieldsSaleCore
        | undefined,
      // TODO: Remove casting when enum types are defined in cerberus. Enums defined as strings are currently causing type errors
      complexFieldsRental: hit.complexFields?.[0]?.rental as
        | PropertyStateComplexFieldsRentalPreview
        | undefined,
    };
  }
  return hit;
};

export const getInputFromPropertySpatialHit = (
  propertyHit: PropertySpatialHit
): PropertyStateCerberusInput | null => {
  if (propertyHit.hcAddressId) {
    return {
      cerberusInput: {
        hcAddressId: propertyHit.hcAddressId,
      },
      propertyStateType: PropertyStateType.Core,
    };
  } else {
    return null;
  }
};

export const propertySpatialHitsToPropertyStateCerberusInputs = (
  hits: PropertySpatialHit[] | undefined | null
): (PropertyStateCerberusInput | null)[] => {
  return (
    hits?.map((hit) =>
      hit.hcAddressId
        ? {
            propertyStateType: PropertyStateType.Core,
            cerberusInput: {
              hcAddressId: hit.hcAddressId,
            },
          }
        : null
    ) || []
  );
};
