import React, { useMemo } from 'react';

import {
  DataPriorityControl,
  DataPriorityControlLabelsType,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { usePatchUiPreferencesForUser } from '@hcs/huell';
import { useUiPreferencesForUser } from '@hcs/huell';
import {
  COMP_FIELD_ORDER_TABLE,
  RENTAL_COMP_FIELD_ORDER_TABLE,
} from '@hcs/property-state';
import { getCompFieldConfig } from '@hcs/property-state';
import {
  DataPriorityComp,
  MeaningfulEventTypes,
  PreferencesKeys,
} from '@hcs/types';
import { CompTypes } from '@hcs/types';

const dataHcName = 'comp-data-preferences';
export const dataHcEventSectionDataPriorityComps = 'Comp Data Priority';
export const dataHcEventSectionDataPriorityRentalComps =
  'Rental Comp Data Priority';
export interface CompDataPriorityUserProps {
  compType: CompTypes;
  actionsPortalIdRender?: string;
  numCols?: number;
  onSubmit?: (compDataPriority: DataPriorityComp) => void;
  onCancel?: VoidFunction;
  onChangeNumColumns?: (numCols: number) => void;
}
export const CompDataPriorityUser = ({
  compType,
  ...props
}: CompDataPriorityUserProps) => {
  const isRental = compType === CompTypes.Rental;
  const dataHcEventSection = isRental
    ? dataHcEventSectionDataPriorityRentalComps
    : dataHcEventSectionDataPriorityComps;
  const preferenceKey = isRental
    ? PreferencesKeys.RentalCompTableColumns
    : PreferencesKeys.CompTableColumns;
  const defaultFieldOrder = isRental
    ? RENTAL_COMP_FIELD_ORDER_TABLE
    : COMP_FIELD_ORDER_TABLE;
  const {
    isInitialLoading: isPreferencesLoading,
    data: mergedUserOrgUiPreferences,
  } = useUiPreferencesForUser();
  const { mutate: patchPreferences } = usePatchUiPreferencesForUser();
  const handleSave = (updated: DataPriorityComp) => {
    // This will actually always be defined here because of the `disabled` prop on the submit button
    patchPreferences?.([
      {
        op: 'add',
        path: `/${preferenceKey}`,
        value: updated,
      },
    ]);
    props.onSubmit?.(updated);
  };
  const handleReset = () => {
    patchPreferences?.([
      {
        op: 'remove',
        path: `/${preferenceKey}`,
      },
    ]);
  };

  const labels = useMemo(() => {
    return defaultFieldOrder.reduce<
      DataPriorityControlLabelsType<(typeof defaultFieldOrder)[number]>
    >((accum, f) => {
      accum[f] = {
        label: getCompFieldConfig(f)?.label || f,
      };
      return accum;
    }, {});
  }, [defaultFieldOrder]);

  if (isPreferencesLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  return (
    <DataPriorityControl
      {...props}
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      onSubmit={handleSave}
      saveEngagement={{
        dataHcEventName: isRental
          ? 'Update Rental Comp Data Priority'
          : 'Update Comp Data Priority',
        dataHcEventType: MeaningfulEventTypes.Goal,
      }}
      defaultOrder={defaultFieldOrder}
      labels={labels}
      dataPreferences={mergedUserOrgUiPreferences?.[preferenceKey]}
      hasReset
      resetTableOptionsPrefs={handleReset}
    />
  );
};
