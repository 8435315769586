import { gql } from 'graphql-request';

import { FRAGMENT_BLOCK_DETAILS_CORE } from './fragments/BlockDetails.graphql';
import {
  FRAGMENT_COMPLEX_FIELDS_RENTAL_CORE,
  FRAGMENT_COMPLEX_FIELDS_RENTAL_PREVIEW,
} from './fragments/ComplexFieldsRental.graphql';
import {
  FRAGMENT_COMPLEX_FIELDS_SALE_CORE,
  FRAGMENT_COMPLEX_FIELDS_SALE_PREVIEW,
} from './fragments/ComplexFieldsSale.graphql';
import { FRAGMENT_LAND_CORE } from './fragments/Land.graphql';
import {
  FRAGMENT_LISTING_DETAILS_CORE,
  FRAGMENT_LISTING_DETAILS_PREVIEW,
} from './fragments/ListingDetails.graphql';
import {
  FRAGMENT_PROPERTY_DETAILS_CORE,
  FRAGMENT_PROPERTY_DETAILS_PREVIEW,
} from './fragments/PropertyDetails.graphql';
import { FRAGMENT_PROPERTY_LOCATION } from './fragments/PropertyStateLocation.graphql';
import {
  FRAGMENT_RENTAL_VALUE_CORE,
  FRAGMENT_VALUE_CORE,
} from './fragments/PropertyValue.graphql';
import { FRAGMENT_TAX_HISTORY_CORE } from './fragments/TaxHistory.graphql';
import { FRAGMENT_MSA_CORE } from './fragments';

export const QUERY_PROPERTY_STATE_PREVIEW_HC = gql`
  ${FRAGMENT_PROPERTY_LOCATION}
  ${FRAGMENT_PROPERTY_DETAILS_PREVIEW}
  ${FRAGMENT_COMPLEX_FIELDS_SALE_PREVIEW}
  ${FRAGMENT_COMPLEX_FIELDS_RENTAL_PREVIEW}
  ${FRAGMENT_LISTING_DETAILS_PREVIEW}
  query PropertyStatePreviewHc($cerberusInput: CerberusInput!) {
    # HC APP: Value Report
    # HC APP: Lead Feed
    lookup(id: $cerberusInput) {
      propertyDetails {
        hcAddressId
        location {
          ...PropertyLocation
        }
        hc {
          property {
            ...PropertyDetailsPreview
          }
        }
      }
      latestListing {
        sale {
          ...ListingDetailsPreview
        }
        rental {
          ...ListingDetailsPreview
        }
      }
      complexFields {
        sale {
          ...ComplexFieldsSalePreview
        }
        rental {
          ...ComplexFieldsRentalPreview
        }
      }
    }
  }
`;

export const QUERY_PROPERTY_STATE_CORE_HC = gql`
  ${FRAGMENT_PROPERTY_LOCATION}
  ${FRAGMENT_PROPERTY_DETAILS_CORE}
  ${FRAGMENT_COMPLEX_FIELDS_SALE_CORE}
  ${FRAGMENT_COMPLEX_FIELDS_RENTAL_CORE}
  ${FRAGMENT_TAX_HISTORY_CORE}
  ${FRAGMENT_VALUE_CORE}
  ${FRAGMENT_LISTING_DETAILS_CORE}
  ${FRAGMENT_VALUE_CORE}
  ${FRAGMENT_RENTAL_VALUE_CORE}
  ${FRAGMENT_LAND_CORE}
  ${FRAGMENT_BLOCK_DETAILS_CORE}
  ${FRAGMENT_MSA_CORE}
  query PropertyStateCoreHc($cerberusInput: CerberusInput!) {
    # HC APP: Value Report
    # HC APP: Lead Feed
    lookup(id: $cerberusInput) {
      propertyDetails {
        hcAddressId
        location {
          ...PropertyLocation
        }
        hc {
          property {
            ...PropertyDetailsCore
          }
        }
        blockDetails {
          ...BlockDetailsCore
        }
        msaDetails {
          ...MsaCore
        }
      }
      complexFields {
        sale {
          ...ComplexFieldsSaleCore
        }
        rental {
          ...ComplexFieldsRentalCore
        }
      }
      taxHistory {
        hc {
          ...TaxHistoryCore
        }
      }
      latestListing {
        sale {
          ...ListingDetailsCore
        }
        rental {
          ...ListingDetailsCore
        }
      }
      value {
        value {
          ...ValueCore
        }
        land {
          ...LandCore
        }
        rental {
          ...RentalValueCore
        }
      }
    }
  }
`;
