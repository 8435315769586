import React, { ReactElement } from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  PropertyStateArgs,
  PropertyStateTableHeaderCellProps,
} from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import styles from './address.module.css';
// Component for displaying a field from a schema
const Display = ({
  propertyStateArgs,
}: {
  propertyStateArgs: PropertyStateArgs | undefined;
}) => {
  return (
    <div>
      <div
        className={styles.StreetAddress}
        data-hc-name="address-street-address"
      >
        {formatStreetAddress(propertyStateArgs?.propertyState?.location)}
      </div>
      <div
        className={styles.CityStateZip}
        data-hc-name="address-city-state-zip"
      >
        {formatCityStateZip(propertyStateArgs?.propertyState?.location)}
      </div>
    </div>
  );
};

const HeaderCell = ({
  noLabel,
  ...props
}: PropertyStateTableHeaderCellProps): ReactElement<TableHeaderCellProps> => {
  return (
    <TableHeaderCell {...props}>
      {noLabel ? null : 'Address'}
      {props.children}
    </TableHeaderCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  width: 200,
  sticky: true,
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  children,
  ...tableCellProps
}: { propertyStateArgs: PropertyStateArgs | undefined } & TableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
      {children}
    </TableCell>
  );
};

// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  width: 200,
  sticky: true,
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const ADDRESS_CONFIG = {
  HeaderCell,
  ContentCell,
  Display,
  label: 'Address',
  labelShort: 'Address',
};
