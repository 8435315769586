import React from 'react';
import { Navigate } from 'react-router';

import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderChildren } from '@hcs/design-system';
import {
  APP_CONFIG_AGILE_SUITE,
  VIEW_PATHS_ORDER_MANAGER,
} from '@hcs/hc-products';
import { HeaderContent } from '@hcs/order-manager';
import { OrderUpdatesProvider } from '@hcs/order-manager';

import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { useNavigateViewOrder } from '../hooks/useNavigateViewOrder';
import { AgileSuiteNav } from '../navigation';
import {
  BPOReportPdfPage,
  OrdersPage,
  ReportSummaryPage,
  ViewOrderPage,
} from '../pages';

const Header = () => {
  const onClickOrder = useNavigateViewOrder();
  return (
    <SolutionsPlatformGlobalHeader
      authenticatedAdditionalHeaderContent={
        <HeaderContent onClickOrder={onClickOrder} />
      }
    />
  );
};

export const routes = () => [
  {
    path: APP_CONFIG_AGILE_SUITE.rootPath,
    element: (
      <PrivateApplicationPageRedirect>
        <LayoutGlobalHeaderChildren header={<Header />}>
          <AgileSuiteNav />
          <OrderUpdatesProvider>
            <LayoutContent />
          </OrderUpdatesProvider>
        </LayoutGlobalHeaderChildren>
      </PrivateApplicationPageRedirect>
    ),
    children: [
      {
        path: `${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.ORDERS}`,
        element: <OrdersPage />,
      },
      {
        path: `${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.ORDER}/:id`,
        element: <ViewOrderPage />,
      },
      {
        path: `${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.REPORT_SUMMARY}`,
        element: <ReportSummaryPage />,
      },
      {
        path: APP_CONFIG_AGILE_SUITE.rootPath,
        element: (
          <Navigate
            to={`${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.ORDERS}`}
          />
        ),
      },

      {
        path: `${APP_CONFIG_AGILE_SUITE.rootPath}/${VIEW_PATHS_ORDER_MANAGER.PDF}`,
        element: (
          <PrivateApplicationPageRedirect>
            <BPOReportPdfPage />
          </PrivateApplicationPageRedirect>
        ),
      },
    ],
  },
];
