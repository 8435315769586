import { CerberusInputHcs } from '../cerberus';
import { DateStr, TDateISO } from '../Date.types';
import { ReportPreferences } from '../huell';

import { DocumentBase, ReportApiDocument } from './Document.types';
import {
  ReportConfigApi,
  ReportConfigSlugs,
  ReportFeatures,
} from './ReportConfig.types';
import { LimitedReportTypes } from './ReportTypeSupport.types';

// Utility type to account for publicLinkUid being used as the reportId
export type ReportId = number | string;
// Utility type for callbacks that can also accept a `Report` as an argument
export type ReportOrReportId = ReportId | Report;

export enum ReportTypes {
  Appraisal = 'appraisal',
  Value = 'value',
  EffectiveDate = 'effectivedate',
  Legacy = 'legacy',
}

export enum ReportStatus {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Error = 'ERROR',
}

export interface ReportBase {
  addressID: number;
  city: string;
  clientID: string | null;
  createdAt: string;
  createdBy?: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  deletedAt: string | null;
  effectiveDate: DateStr;
  id: number;
  limitedType?: LimitedReportTypes[];
  metadata: {
    activeStatus: 'active' | 'expired';
    creationDate: DateStr;
    expirationDate: DateStr;
    tagOptions: string[] | null;
    rentalTagOptions: string[] | null;
    intraOrgReportAccess: ReportPreferences['intraOrgReportAccess'];
    publicLinkUid: PublicLinkCreateResponse['publicLinkUid'] | null;
    editable: boolean;
    revisionID: number;
  };
  orderDetails?: {
    clientName: string | null;
    hcFileId: string | null;
    orderName: string | null;
    orderedAt: string | null;
    originalAddressInputted: string | null;
  };
  ownerId: number;
  ownerEmail: string;
  ownerOrganizationId: number;
  realtorDetails?: {
    email: string | null;
    firm: string | null;
    fullName: string | null;
    licenseExpiredAt: string | null;
    licenseNumber: string | null;
    licenseState: string | null;
    signatureUrl: string | null;
    signedAt: DateStr | null;
  };
  reportType: ReportTypes;
  // Report Features from config when the report was created
  // Use these for determining which features are supported on the existing report
  reportFeatures: ReportFeatures[];
  // Current report Config
  reportConfig: ReportConfigApi;
  reportConfigSlug: ReportConfigSlugs;
  reportName?: string; // From report config
  slug: string;
  status: ReportStatus;
  state: string;
  streetAddress: string;
  unit: string;
  unitDesignator: string;
  updatedAt: string | null;
  zipcode: string;
  externalID?: string;
}

export interface Report extends ReportBase {
  documents: DocumentBase[];
}

export interface ReportWithData extends ReportBase {
  documents: ReportApiDocument[];
}

// Abstracting inputs to match conventions used in this project
export interface ReportSearchInputs {
  activeStatus?: 'active' | 'expired';
  reportConfigSlug?: ReportConfigSlugs | (ReportConfigSlugs | null)[];
  reportType?: ReportTypes;
  ownerId?: number;
  hcAddressId?: number | null;
  effectiveDate?: DateStr;
  effectiveDateStart?: DateStr;
  clientId?: string;
  streetAddress?: string;
  slug?: string;
  zipcode?: string;
  partialMatch?: boolean;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: string;
}

export interface ReportSearchApiInputs {
  type?: ReportTypes;
  ownerID?: number;
  reportConfigSlug?: string;
  addressID?: number;
  effectiveDate?: DateStr;
  clientID?: string;
  streetAddress?: string;
  slug?: string;
  zipcode?: string;
  partialMatch?: boolean;
}

export interface ReportCreateInputs {
  hcAddressId: number;
  reportType?: ReportTypes;
  effectiveDate?: DateStr;
  reportConfigSlug: ReportConfigSlugs;
  reportFeatures?: ReportFeatures[];
  // Optional user-defined ID
  clientFileId?: string;
  // Create documents synchronously (typically defaulted to async)
  synchronous?: boolean;
  appraisalSubjectValue?: number;
  comps?: (CerberusInputHcs & { value: number })[];
}
export interface ReportCreateApiInputs {
  addressID: number;
  reportType?: ReportTypes;
  effectiveDate?: DateStr;
  // Optional user-defined ID
  clientID?: string;
  async?: boolean;
  comps?: { addressID: number; value: number }[];
  appraisalSubjectValue?: number;
}

export interface ReportPermissions {
  isActive: boolean;
  isEditable: boolean;
  isExpired: boolean;
  isOwnedByOrg: boolean;
  isOwnedByUser: boolean;
}

export type SortableReportFields =
  | 'type'
  | 'orderedDate'
  | 'effectiveDate'
  | 'clientID'
  | 'zipcode'
  | 'ownerName'
  | 'cityStateZip';

export interface EditableLinkPayload {
  ttl: number;
  reportFeaturesExcluded?: ReportFeatures[];
}

export interface PublicLinkCreateResponse {
  createdAt: TDateISO;
  creatorId: number;
  publicLinkUid: string;
  updatedAt: TDateISO;
}

export interface EditableLinkCreateResponse extends PublicLinkCreateResponse {
  complianceFlag: boolean;
  editable: boolean;
  reportFeaturesExcluded: ReportFeatures[] | null;
  expiresAt: TDateISO;
}
