import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { TextArea } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { MeaningfulEventTypes, ReportFeatures, ReportId } from '@hcs/types';
import { SubjectPaths, SubjectSchema } from '@hcs/types';

import { useSubjectDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import styles from './SubjectComment.module.css';

interface Props {
  reportId: ReportId;
  isEditable?: boolean;
  className?: string;
  actionsPortalIdRender?: string;
  onSubmit?: (value: string) => void;
}
export const SUBJECT_COMMENT_FEATURES = [ReportFeatures.SubjectComment];
const PATH_COMMENT: SubjectPaths = '/data/comments';
const dataHcName = 'subject-comment';
export const dataHcEventSectionSubjectComment = 'Comments';
export const SubjectComment = ({
  reportId,
  className,
  onSubmit,
  actionsPortalIdRender,
  isEditable = true,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const [value, setValue] = useState('');
  const subjectSchema: SubjectSchema | undefined = subjectDocument?.data;
  // return loading if we are loading or no doc or schema this reduces checks later

  useEffect(() => {
    if (subjectSchema?.comments) {
      // comments might be null
      setValue(subjectSchema?.comments);
    }
  }, [subjectSchema]);

  if (subjectDocument == null || subjectSchema == null || !reportPermissions) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  if (isEditable) {
    const isDisabled =
      value === subjectDocument.data.comments ||
      (!value && !subjectDocument.data.comments);
    return (
      <div
        data-hc-name={dataHcName}
        data-hc-event-section={dataHcEventSectionSubjectComment}
        className={styles.SubjectComment}
      >
        <TextArea
          readOnly={!reportPermissions.isEditable}
          className={className}
          disabled={!reportPermissions.isEditable}
          dataHcName={`${dataHcName}-text-area`}
          placeholder="Type your comments here"
          maxLength={5000}
          value={value}
          onChange={setValue}
        />
        {reportPermissions.isEditable && (
          <ActionButtons
            actions={[
              {
                dataHcName: `${dataHcName}-save-button`,
                dataHcEventSection: dataHcEventSectionSubjectComment,
                dataHcEventName: isDisabled ? undefined : 'Save Comment',
                dataHcEventType: isDisabled
                  ? undefined
                  : MeaningfulEventTypes.Goal,
                label: 'Save',
                onClick: () => {
                  documentPatchMutation.mutate({
                    reportId,
                    document: subjectDocument,
                    operations: [{ op: 'replace', path: PATH_COMMENT, value }],
                  });
                  onSubmit?.(value);
                },
                disabled: isDisabled,
              },
            ]}
            dataHcName={`${dataHcName}-actions`}
            portalIdRender={actionsPortalIdRender}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        className={classNames(styles.ReadOnly, className)}
        data-hc-name={dataHcName}
      >
        {value || (
          <span
            className={styles.Placeholder}
            data-hc-name={`${dataHcName}-no-comments`}
          >
            No comments
          </span>
        )}
      </div>
    );
  }
};
