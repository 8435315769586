import { useSearchParams } from 'react-router-dom';

import { SS_CHECKOUT_SESSION_PARAM } from '../constants/selfService.constants';

export const useSelfServiceCheckoutSessionParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionParam = searchParams.get(SS_CHECKOUT_SESSION_PARAM);
  return {
    sessionParam,
    setSessionParam: (value: string) => {
      searchParams.set(SS_CHECKOUT_SESSION_PARAM, value);
      setSearchParams(searchParams);
    },
  };
};
