import { differenceInMinutes } from 'date-fns';

import {
  LOCAL_STORAGE_SESSION_ID,
  LOCAL_STORAGE_SESSION_TIME,
  SESSION_TIMEOUT_MINUTES,
} from '../constants';

export const setNewSessionId = () => {
  const sessionId = crypto.randomUUID();
  const sessionTime = new Date();
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  localStorage.setItem(LOCAL_STORAGE_SESSION_ID, sessionId);
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  localStorage.setItem(LOCAL_STORAGE_SESSION_TIME, sessionTime.toString());
  return sessionId;
};

export const getSessionId = () => {
  // SECURITY: localStorage is used safely to store a session id that carries no meaning outside of engagement events
  // the sessionId needs to persist across multiple tabs so localStorage is used instead of sessionStorage
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  const sessionId = localStorage.getItem(LOCAL_STORAGE_SESSION_ID);
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  const sessionTimeRaw = localStorage.getItem(LOCAL_STORAGE_SESSION_TIME);
  if (
    !sessionId ||
    !sessionTimeRaw ||
    differenceInMinutes(new Date(), new Date(sessionTimeRaw)) >
      SESSION_TIMEOUT_MINUTES
  ) {
    return setNewSessionId();
  } else {
    // Update time
    // eslint-disable-next-line scanjs-rules/identifier_localStorage
    localStorage.setItem(LOCAL_STORAGE_SESSION_TIME, new Date().toString());
    return sessionId;
  }
};
