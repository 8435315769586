import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGeoInterestedUiPreferences } from '@hcs/huell';
import { CerberusStatsFields } from '@hcs/types';
import { CerberusStatsFilterType, GeoInterestedType } from '@hcs/types';
import { SORT_ORDER } from '@hcs/types';

import { useCerberusStatsAllMsasWithHpi } from '../hooks/useCerberusStatsAllMsasWithHpi';
import { filterList, sortList } from '../utils/cerberusStatsTable.utils';

import { useCerberusStatsFilters } from './';

enum LegacyQueryParamNames {
  Search = 'msa-search',
  Sort = 'msa-sort',
  Order = 'msa-order',
}

const sortParamToCerberusStatsSortField = (
  msaSortableField: string | null
): CerberusStatsFields | null => {
  if (msaSortableField === null) {
    return null;
  } else if (msaSortableField === 'gross_yield') {
    return CerberusStatsFields.grossYieldMedian;
  } else if (msaSortableField === 'hpi_forecast') {
    return CerberusStatsFields.return1YearForecast;
  } else if (msaSortableField === 'months_of_supply') {
    return CerberusStatsFields.monthsOfSupplyMedian;
  } else if (msaSortableField === 'msa_name') {
    return CerberusStatsFields.displayName;
  } else if (msaSortableField === 'number_of_listings') {
    return CerberusStatsFields.listingsOnMarketCount;
  } else if (msaSortableField === 'sale_to_list_price') {
    return CerberusStatsFields.saleToListPriceOriginalMedian;
  }
  return null;
};

const sortOrderParamToSortOrderEnum = (
  msaSortOrder: string | null
): SORT_ORDER | null => {
  if (msaSortOrder === null) {
    return null;
  } else if (msaSortOrder === 'asc') {
    return SORT_ORDER.Asc;
  } else if (msaSortOrder === 'desc') {
    return SORT_ORDER.Desc;
  }
  return null;
};

const DEFAULT_SORT = {
  field: CerberusStatsFields.listingsOnMarketCount,
  order: SORT_ORDER.Desc,
};

export const useCerberusStatsMsasTable = () => {
  const { data: msaList, isInitialLoading: msaListIsLoading } =
    useCerberusStatsAllMsasWithHpi();
  const {
    state: {
      filterSet,
      filterSetQueryIsLoading,
      filterSetMutationIsLoading,
      search: msaSearch,
    },
    actions: {
      onToggleInterestedOnly,
      onSort,
      onSearch,
      onUpdateFieldFilters,
      onClearFieldFilter,
      onUpdateFilterableFields,
    },
  } = useCerberusStatsFilters(CerberusStatsFilterType.msa);
  const {
    state: { geoInterestedMap: interestedInMsaMap },
    actions: { onToggleInterested },
  } = useGeoInterestedUiPreferences(GeoInterestedType.msas);

  const [searchParams] = useSearchParams();
  const searchFromParams = searchParams.get(LegacyQueryParamNames.Search);
  const sortFieldFromParams = searchParams.get(LegacyQueryParamNames.Sort);
  const sortOrderFromParams = searchParams.get(LegacyQueryParamNames.Order);
  const { fieldFilters } = filterSet || {};

  const interestedOnly = filterSet?.interestedOnly || null;
  const search = searchFromParams || msaSearch;
  let sortField = sortFieldFromParams
    ? sortParamToCerberusStatsSortField(sortFieldFromParams)
    : filterSet?.sortField;
  if (!sortField) {
    sortField = DEFAULT_SORT.field;
  }
  let sortOrder = sortOrderFromParams
    ? sortOrderParamToSortOrderEnum(sortOrderFromParams)
    : filterSet?.sortOrder;
  if (!sortOrder) {
    sortOrder = DEFAULT_SORT.order;
  }

  const filteredList = useMemo(() => {
    return filterList({
      list: msaList,
      fieldFilters,
      filterSetIsLoading: filterSetQueryIsLoading,
      search,
      interestedOnly,
      interestedInMap: interestedInMsaMap,
    });
  }, [
    msaList,
    fieldFilters,
    filterSetQueryIsLoading,
    search,
    interestedOnly,
    interestedInMsaMap,
  ]);

  const filteredSortedList = useMemo(() => {
    return sortList({
      list: filteredList,
      sortField,
      sortOrder,
      filterSetIsLoading: filterSetQueryIsLoading,
    });
  }, [filteredList, sortField, sortOrder, filterSetQueryIsLoading]);

  return {
    state: {
      isLoading: filterSetQueryIsLoading || msaListIsLoading,
      filterSetMutationIsLoading,
      filteredSortedList,
      totalCount: msaList?.length,
      interestedInMsaMap,
      search,
      sortField,
      sortOrder,
      fieldFilters: filterSet?.fieldFilters,
      interestedOnly: filterSet?.interestedOnly,
    },
    actions: {
      onToggleInterestedOnly,
      onSort,
      onToggleMsaInterested: onToggleInterested,
      onSearch,
      onUpdateFieldFilters,
      onClearFieldFilter,
      onUpdateFilterableFields,
    },
  };
};
