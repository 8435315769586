import React from 'react';
import classNames from 'classnames';

import { SearchChip } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { KeywordSearchByListings } from '@hcs/keyword-search';
import { useKeywordSearch } from '@hcs/keyword-search';
import { ReportFeatures } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import { useCompCompareDialogSlice } from '../../../hooks';
import { useCompKeywordSearchInputs } from '../../../hooks/useCompKeywordSearchInputs';
import { useCompKeywordSearchMatches } from '../../../hooks/useCompKeywordSearchMatches';
import { useReportPermissions } from '../../../hooks/useReportPermissions';
import { compKeywordSearchKey } from '../../../utils';

import styles from '../CompFilters.module.css';

const dataHcName = 'comp-filter-keyword-search';
export const COMP_FILTER_KEYWORD_SEARCH_FEATURES_SALE = [
  ReportFeatures.CompsFilterKeyword,
];
export const COMP_FILTER_KEYWORD_SEARCH_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsFilterKeyword,
];
export const COMP_FILTER_KEYWORD_SEARCH: CompFilterConfig = {
  Chip: ({ compType, className, onClick }: CompFilterChipTypeProps) => {
    const keywordSearchKey = compKeywordSearchKey(compType);
    const {
      state: { inputs },
      actions: { keywordSearchClear },
    } = useKeywordSearch(keywordSearchKey);
    if (!inputs) {
      return <span />;
    }
    return (
      <SearchChip
        className={className}
        dataHcName={`${dataHcName}-chip`}
        onClick={onClick}
        onClose={keywordSearchClear}
        label={<>Keyword {inputs.keywords.map((k) => `"${k}"`).join(', ')}</>}
      />
    );
  },
  Control: ({ reportId, compType, className }: CompFilterControlTypeProps) => {
    const {
      actions: { compCompareDialogOpen },
    } = useCompCompareDialogSlice();
    const { isInitialLoading: isLoadingInputs, data: listingsInput } =
      useCompKeywordSearchInputs({
        reportId,
        compType,
      });
    const {
      isFetching,
      isSuccess,
      data: { keywordMatchCompIds, keywordMatchCompSchemas },
    } = useCompKeywordSearchMatches({
      reportId,
      compType,
    });
    const { data: reportPermissions } = useReportPermissions(reportId);
    if (!reportPermissions || isLoadingInputs) {
      return (
        <Skeleton
          type="input"
          dataHcName={`${dataHcName}-skeleton`}
          style={{ padding: '0 15px' }}
        />
      );
    }

    return (
      <>
        <KeywordSearchByListings
          searchKey={compKeywordSearchKey(compType)}
          dataHcName={dataHcName}
          className={className}
          listingsInput={listingsInput}
          disabled={!reportPermissions.isEditable}
        />
        <p data-hc-name={`${dataHcName}-info`} className={styles.InfoText}>
          Keyword search enables you to search comps with particular keywords or
          a combination of keywords.
        </p>

        <div
          className={classNames(styles.KeyWordSearch, {
            [styles.keyWordSearchValues]: isFetching || isSuccess,
          })}
        >
          {isFetching ? (
            <Skeleton
              repeat={4}
              paddingBottom={13}
              dataHcName={`${dataHcName}-skeleton`}
              type="input"
            />
          ) : (
            isSuccess && (
              <ul className={styles.FilterControls}>
                {!keywordMatchCompIds.length && (
                  <li
                    className={classNames(
                      styles.FilterButton,
                      styles.AddressButton
                    )}
                  >
                    No Results
                  </li>
                )}
                {keywordMatchCompIds.map((compId, i) => {
                  const compSchema = keywordMatchCompSchemas[compId];
                  return (
                    <li
                      key={`${compSchema?.compID}-${i}`}
                      className={classNames(
                        styles.FilterButton,
                        styles.AddressButton
                      )}
                      onClick={() =>
                        compSchema &&
                        compCompareDialogOpen({
                          type: 'schema',
                          compType,
                          compSchema,
                        })
                      }
                    >
                      <div
                        data-hc-name={`${dataHcName}-hit-streetAddress`}
                        className={styles.StreetAddress}
                      >
                        {formatStreetAddress(
                          compSchema?.propertyState.location
                        )}
                      </div>
                      <div
                        data-hc-name={`${dataHcName}-hit-cityStateZip`}
                        className={styles.CityStateZip}
                      >
                        {formatCityStateZip(compSchema?.propertyState.location)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )
          )}
        </div>
      </>
    );
  },
};
