import { useQuery } from '@tanstack/react-query';

import { logException } from '@hcs/utils';

import { AccountOrgApiExternal } from '../api';

export const QUERY_KEY_ORG_SAML_PROVIDER = 'org-saml-provider';
export const useOrgSamlProvider = (orgId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_ORG_SAML_PROVIDER, orgId],
    async () => {
      if (orgId) {
        return await AccountOrgApiExternal.fetchOrgSamlProvider(orgId);
      } else {
        logException(
          'useOrgSamlProvider: orgId is undefined, check enabled logic'
        );
        throw new Error('An Error Occurred');
      }
    },
    {
      enabled: !!orgId,
    }
  );
};
