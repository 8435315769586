import { useAccount } from '@hcs/auth';
import { combineUseQueryResult } from '@hcs/utils';

import { getOrgSubscriptionType } from '../utils/organization.utils';

import { useOrgSelfServeInfo } from './useOrgSelfServeInfo';

export const useOrgSubscriptionType = () => {
  const queryAccount = useAccount();
  const querySsInfo = useOrgSelfServeInfo();
  const combinedQuery = combineUseQueryResult([queryAccount, querySsInfo]);
  return {
    ...combinedQuery,
    data: getOrgSubscriptionType(
      queryAccount.data?.subscriptionType,
      querySsInfo.data === null ? undefined : querySsInfo.data?.self_serve_plan
    ),
  };
};
