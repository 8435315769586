import React from 'react';

import styles from './TagCount.module.css';

interface Props {
  count: number;
}
export const TagCount = ({ count }: Props) => {
  return <div className={styles.TagCount}>{count}</div>;
};
