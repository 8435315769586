import { useMutation } from '@tanstack/react-query';

import { ViewOrderApi } from '../api/viewOrderApi';

interface Props {
  orderId: number;
  fileName: string;
}

export const useDownloadAllOrderItemData = () => {
  return useMutation(async ({ orderId, fileName }: Props) => {
    return await ViewOrderApi.downloadAllOrderItemData(orderId, fileName);
  });
};
