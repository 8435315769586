import React, { useState } from 'react';

import { Dialog } from '@hcs/design-system';
import { StatusMessageProps } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import {
  dataHcEventSectionSaveFilterSetComps,
  dataHcEventSectionSaveFilterSetRental,
  SavedCompFilterSetForm,
} from '../../features/SavedCompFilterSetForm/SavedCompFilterSetForm';

interface Props {
  reportId: ReportId;
  active: boolean;
  compType: CompTypes;
  onClose: VoidFunction;
  onSuccess?: () => void;
}
const dataHcName = 'saved-comp-filter-set-form-dialog';
export const SavedCompFilterSetFormDialog = ({
  active,
  reportId,
  compType,
  onClose,
  onSuccess,
}: Props) => {
  const [notifications, setNotifications] = useState<StatusMessageProps[]>([]);

  return (
    <Dialog
      dataHcName={dataHcName}
      dataHcEventSection={
        compType === CompTypes.Rental
          ? dataHcEventSectionSaveFilterSetRental
          : dataHcEventSectionSaveFilterSetComps
      }
      title="Save filter set"
      active={active}
      onClose={onClose}
      width={500}
      notifications={notifications}
      noContentPadding
    >
      <SavedCompFilterSetForm
        onSuccess={() => {
          onClose();
          onSuccess?.();
        }}
        reportId={reportId}
        onNotificationChange={setNotifications}
        compType={compType}
      />
    </Dialog>
  );
};
