import React from 'react';
import classNames from 'classnames';

import {
  PropertySummaryHeader,
  PropertySummaryHeaderProps,
} from '@hcs/property-state';
import { PropertyStateType } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { SubjectPhotoCarousel } from '../../features/SubjectPhotoCarousel';
import { useSubjectDocument } from '../../hooks';

import styles from './SubjectPropertySummaryHeader.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
  beforeStreetAddress?: PropertySummaryHeaderProps['beforeStreetAddress'];
  /** keyword-search instance id */
  keywordSearchKey?: string;
  isRental?: boolean;
}
export const SubjectPropertySummaryHeader = ({
  reportId,
  className,
  keywordSearchKey,
  beforeStreetAddress,
  isRental,
}: Props) => {
  const { isInitialLoading, data: subjectDocument } =
    useSubjectDocument(reportId);
  return (
    <PropertySummaryHeader
      className={classNames(styles.SubjectPropertySummaryHeader, className)}
      isLoading={isInitialLoading}
      beforeStreetAddress={beforeStreetAddress}
      photo={
        <SubjectPhotoCarousel reportId={reportId} showStreetViewIcon={false} />
      }
      propertyStateArgs={{
        propertyStateType: PropertyStateType.Core,
        propertyState: subjectDocument?.data.propertyState,
      }}
      keywordSearchKey={keywordSearchKey}
      isRental={isRental}
    />
  );
};
