import React from 'react';
import classNames from 'classnames';

import { LinkComponentType } from '@hcs/routing';
import { CerberusStatsFields } from '@hcs/types';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';
import { useCerberusStatsMsa } from '../../hooks/useCerberusStatsMsa';
import { useCerberusStatsZip } from '../../hooks/useCerberusStatsZip';

import styles from './ZipPageBreadcrumbs.module.css';

const DisplayName =
  CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.displayName].Display;

interface ZipPageBreadcrumbsProps {
  className?: string;
  msaId: string;
  zipcode: string;
  MsaLink: LinkComponentType<{ msaId: string }>;
  ZipSearchLink: LinkComponentType<{ msaId: string }>;
}
const dataHcName = 'zip-page-breadcrumbs';
export const ZipPageBreadcrumbs = ({
  className,
  msaId,
  zipcode,
  MsaLink,
  ZipSearchLink,
}: ZipPageBreadcrumbsProps) => {
  const { data: cerberusStatsMsa } = useCerberusStatsMsa({ msaId });
  const { data: cerberusStatsZip } = useCerberusStatsZip({ zipcode });

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Links, className)}
    >
      <MsaLink msaId={msaId} dataHcName={`${dataHcName}-msa-link`} underline>
        <DisplayName cerberusStats={cerberusStatsMsa} />
      </MsaLink>{' '}
      /{' '}
      <ZipSearchLink
        dataHcName={`${dataHcName}-zip-search-link`}
        msaId={msaId}
        underline
      >
        ZIP Codes
      </ZipSearchLink>{' '}
      / <DisplayName cerberusStats={cerberusStatsZip} />
    </div>
  );
};
