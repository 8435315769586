import { useEffect, useState } from 'react';

import { AppConfig } from '@hcs/types';
import { getRootPath } from '@hcs/utils';

export const useCurrentUrlRootPath = (): AppConfig['rootPath'] | undefined => {
  const [rootPath, setRootPath] = useState<AppConfig['rootPath'] | undefined>(
    getRootPath()
  );
  const pathName = window.location.pathname;
  useEffect(() => {
    setRootPath(getRootPath());
  }, [pathName]);
  return rootPath;
};
