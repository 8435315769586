import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EntitlementDefsAutoComplete } from '@hcs/account-api';
import { ActionButtons, TextButton } from '@hcs/design-system';
import { DialogInputLayout, FormFieldsDate } from '@hcs/forms';
import {
  EntitlementapiEntitlementDefRespInt,
  EntitlementapiInputEntitlementRequestInt,
} from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { useAddEntitlementToOrg } from '../../hooks/useAddEntitlementToOrg';

import styles from './AddEntitlementToOrg.module.css';

const FORM_SCHEMA = yup.object().shape({
  entitlement_key: yup.string().required('Entitlement Key is required'),
  active_on: yup.string().required('Active On is required'),
  active_until: yup.string().required('Active On is required'),
});

interface Props {
  orgId: number;
  actionButtonPortalIdRender?: string;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
}
const dataHcName = 'add-entitlement-to-org-form';
export const AddEntitlementToOrgForm = ({
  orgId,
  actionButtonPortalIdRender,
  onCancel,
  onSuccess,
}: Props) => {
  const { mutate: addEntitlementToOrg } = useAddEntitlementToOrg({ onSuccess });
  const [selectedEntitlementDef, setSelectedEntitlementDef] =
    useState<EntitlementapiEntitlementDefRespInt | null>(null);
  const form = useForm<EntitlementapiInputEntitlementRequestInt>({
    resolver: yupResolver(FORM_SCHEMA),
  });
  return (
    <FormProvider {...form}>
      <form className={styles.Form}>
        <DialogInputLayout
          label="Entitlement"
          dataHcName={`${dataHcName}_on`}
          required
        >
          <Controller
            name="entitlement_key"
            control={form.control}
            render={({ field }) => {
              if (!field.value) {
                return (
                  <EntitlementDefsAutoComplete
                    value={undefined}
                    onSelect={(entitlementDef) => {
                      field.onChange(entitlementDef?.key);
                      setSelectedEntitlementDef(entitlementDef);
                      form.clearErrors('entitlement_key');
                    }}
                  />
                );
              }
              return (
                <div className={styles.SelectedEntitlement}>
                  <div>
                    <div className={styles.EntitlementName}>
                      {selectedEntitlementDef?.display_name || field.value}
                    </div>
                    <div className={styles.EntitlementDescription}>
                      {formatMissing(selectedEntitlementDef?.description)}
                    </div>
                  </div>
                  <TextButton
                    dataHcName={`${dataHcName}-chagne-entitlementdef`}
                    onClick={() => {
                      field.onChange(null);
                    }}
                    noPadding
                    label="Change"
                  />
                </div>
              );
            }}
          />
        </DialogInputLayout>
        <DialogInputLayout
          label="Active On"
          dataHcName={`${dataHcName}-activeOn`}
          required
        >
          <FormFieldsDate name="active_on" />
        </DialogInputLayout>
        <DialogInputLayout
          label="Active Until"
          dataHcName={`${dataHcName}-activeUntil`}
          required
        >
          <FormFieldsDate name="active_until" />
        </DialogInputLayout>
        <ActionButtons
          dataHcName={`${dataHcName}-actions`}
          portalIdRender={actionButtonPortalIdRender}
          actions={[
            {
              label: 'Cancel',
              onClick: onCancel,
              dataHcName: `${dataHcName}-cancel`,
              secondary: true,
            },
            {
              label: 'Submit',
              type: 'submit',
              disabled: !form.formState.isValid,
              onClick: () => {
                const formData = form.getValues();
                addEntitlementToOrg({
                  orgId,
                  ...formData,
                  active_on: new Date(formData.active_on || '').toISOString(),
                  active_until: new Date(
                    formData.active_until || ''
                  ).toISOString(),
                });
              },
              dataHcName: `${dataHcName}-cancel`,
              primary: true,
            },
          ]}
        />
      </form>
    </FormProvider>
  );
};
