import { CompsFilteredSchema } from '.';

export type CompsListTypes =
  // activeTop4 and closedTop4 only used in pdf
  | Exclude<keyof CompsFilteredSchema, 'activeTop4' | 'closedTop4'>
  | 'selected'
  | 'appraisal';

export enum CompsListViewType {
  Card = 'card',
  Table = 'table',
  Compare = 'compare',
}
