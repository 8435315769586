import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { BuyBox, BuyBoxFormData } from '@hcs/types';

import { createFormToNewBuyBox } from '../utils';
import { LeadFeedApi } from '../';

import { QUERY_KEY_BUY_BOX_SUMMARIES } from './useBuyBoxSummaries';
import { QUERY_KEY_TEMPLATES } from './useTemplate';

export const useBuyBoxCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<BuyBox, AxiosError, BuyBoxFormData, void>(
    async (formData) => {
      const newBuyBox = createFormToNewBuyBox(formData);
      return await LeadFeedApi.createBuyBox(newBuyBox);
    },
    {
      onSuccess: (newBuyBox, formData) => {
        queryClient.invalidateQueries([QUERY_KEY_BUY_BOX_SUMMARIES]);
        // if template was also updated, invalidate templates cache
        if (formData.shouldSaveAsTemplate) {
          queryClient.invalidateQueries([QUERY_KEY_TEMPLATES]);
        }
      },
    }
  );
};
