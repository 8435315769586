import React, { ReactNode } from 'react';

import { IconButton } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { DataPriorityIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { CompTypes } from '@hcs/types';

import {
  dataHcEventSectionDataPriorityComps,
  dataHcEventSectionDataPriorityRentalComps,
} from './CompDataPriorityUser';
import { CompDataPriorityUserDialog } from './CompDataPriorityUserDialog';

import styles from './CompDataPriorityUser.module.css';

interface Props {
  className?: string;
  trigger?: ReactNode;
  compType: CompTypes;
  showTooltip?: boolean;
}
const dataHcName = 'comp-farm-table-options-launcher';
export const CompDataPriorityUserLauncher = ({
  className,
  compType,
  trigger,
  showTooltip = true,
}: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const isRental = compType === CompTypes.Rental;
  const dataHcEventSection = isRental
    ? dataHcEventSectionDataPriorityRentalComps
    : dataHcEventSectionDataPriorityComps;
  const renderedTrigger = trigger ? (
    <span
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      onClick={handleOpen}
      className={styles.Trigger}
    >
      {trigger}
    </span>
  ) : (
    <IconButton
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      className={className}
      onClick={handleOpen}
      icon={<DataPriorityIcon size="lg" />}
    />
  );

  return (
    <>
      {showTooltip ? (
        <Tooltip
          dataHcName={`${dataHcName}-tooltip`}
          theme={{
            Trigger: styles.Trigger,
          }}
          trigger={renderedTrigger}
          label="Reorder columns"
        />
      ) : (
        renderedTrigger
      )}
      <CompDataPriorityUserDialog
        compType={compType}
        active={active}
        onClose={handleClose}
      />
    </>
  );
};
