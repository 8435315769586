import React, { ReactElement } from 'react';
import classNames from 'classnames';

import { PhotoProp } from '@hcs/types';

import { PhotoGridThumbnail } from './PhotoGridThumbnail';

import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from './PhotoGrid.module.css';

export interface PhotoGridProps {
  dataHcName: string;
  // custom className for component
  className?: string;
  photos?: PhotoProp[];
  // children of type PhotoGridThumbnail
  children?: ReactElement<PhotoProp>[] | ReactElement<PhotoProp>;
  // size integer for image calcs
  size: number;
}

export const PhotoGrid = ({
  dataHcName,
  className,
  children,
  photos,
  size,
}: PhotoGridProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.PhotoGrid, className)}
      style={{
        gridTemplateColumns: `repeat(${size}, 1fr`,
      }}
    >
      {photos?.map((photo) => (
        <PhotoGridThumbnail
          key={photo.photo.url}
          {...photo}
          dataHcName={`${dataHcName}-thumbnail`}
          hideFullscreenIcon
        />
      ))}
      {children}
    </div>
  );
};
