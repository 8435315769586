import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CompFieldConfig,
  CompFields,
  CompFieldsArgs,
  CompFieldsTableCellProps,
} from '@hcs/types';
import { formatMoney } from '@hcs/utils';

const FIELD = CompFields.adjustedCompValue;
type FieldConfig = CompFieldConfig<typeof FIELD>;
const label = 'Adjusted Value';
const labelShort = 'Adjusted Value';
const compPath: FieldConfig['compPath'] = '/adjustedCompValue';
const getValue: FieldConfig['getValue'] = (comp) =>
  comp?.adjustedCompValue?.value;
const formatValue: FieldConfig['formatValue'] = (comp) =>
  formatMoney(getValue(comp));
const formatInline: FieldConfig['formatInline'] = (comp) =>
  formatMoney(getValue(comp));
// Info for glossary or tooltip
const info: FieldConfig['info'] =
  'The estimated property value after adjustments';
// Component for displaying a field from a schema
const Display: FieldConfig['Display'] = ({ comp }: CompFieldsArgs) => {
  return <>{formatValue(comp)}</>;
};

const HeaderCell = (props: TableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({ comp, ...tableCellProps }: CompFieldsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display comp={comp} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const ADJUSTED_COMP_VALUE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  compPath,
  info,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
};
