import React from 'react';

import { ReportSummaryOrderItemsCellContentProps } from '@hcs/types';

import { DATA_HC_NAME_REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELL } from '../../../constants/ReportSummary.constants';

import { createReportSummaryOrderItemsTableCellConfig } from './ReportSummaryOrderItemsTableCell';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELL}-state`;
const CellContentComponent = ({
  orderItem,
}: ReportSummaryOrderItemsCellContentProps) => (
  <div data-hc-name={dataHcName}>{orderItem.state}</div>
);

export const REPORT_SUMMARY_ORDER_ITEMS_STATE_CONFIG =
  createReportSummaryOrderItemsTableCellConfig({
    CellContentComponent,
    tableHeaderName: 'State',
  });
