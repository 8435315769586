import React, { useMemo } from 'react';
import classNames from 'classnames';

import { CardTabs, Tab } from '@hcs/design-system';
import { DexpEndpointLevel, DexpEndpointsParams } from '@hcs/types';

import { DEXP_ENDPOINT_LEVEL_LABEL } from '../../constants';
import { useDexpEndpointsTable } from '../../hooks/useDexpEndpointsTable';
import { EndpointsDataTable } from '../EndpointsDataTable';

import styles from './EndpointsTabSection.module.css';

const dataHcName = 'dexp-endpoints';

interface Props {
  className?: string;
  filters?: DexpEndpointsParams;
  darkTabs?: boolean;
}

export const EndpointsTabSection = ({
  className,
  filters: filtersProp,
  darkTabs,
}: Props) => {
  const {
    state: { filters, dexpEndpointsQuery },
  } = useDexpEndpointsTable(filtersProp);
  const { isInitialLoading, data: endpoints } = dexpEndpointsQuery;

  const getHeaderTabs = (): DexpEndpointLevel[] => {
    const headerTabs: DexpEndpointLevel[] = [];
    endpoints?.forEach((endpoint) => {
      if (!headerTabs.includes(endpoint.level)) {
        headerTabs.push(endpoint.level);
      }
    });
    return headerTabs;
  };

  const tabs = useMemo(() => {
    const headerTabs = getHeaderTabs();
    const allTabs: Tab[] = [];
    headerTabs?.forEach((tab) => {
      if (DEXP_ENDPOINT_LEVEL_LABEL[tab]) {
        const obj = {
          tabId: tab,
          label: DEXP_ENDPOINT_LEVEL_LABEL[tab],
          noPadding: true,
          content: <EndpointsDataTable filters={filters} endpointLevel={tab} />,
        };

        allTabs.push(obj);
      }
    });
    return allTabs;
  }, [endpoints, isInitialLoading, filters]);

  if (isInitialLoading || !endpoints || !tabs) {
    return null;
  }
  return (
    <CardTabs
      dataHcName={dataHcName}
      searchParamId={'endpointsTabs'}
      className={classNames(styles.EndpointTabSection, className)}
      tabs={tabs}
      theme={{
        Content: styles.CardTabsContent,
        ActiveTabLabelText: styles.ActiveLabel,
        TabButton: classNames(styles.TabButton, {
          [styles.darkTabs]: darkTabs,
        }),
        lastSelected:
          tabs.length === 1 ? styles.SingleTabContent : styles.lastSelected,
      }}
      active={tabs?.[0] && tabs[0].tabId}
    />
  );
};
