import React, { useMemo } from 'react';
import classNames from 'classnames';

import { TwoColumnTable } from '@hcs/design-system';
import { InfoIcon } from '@hcs/design-system';
import {
  CerberusStats,
  CerberusStatsFields,
  CerberusStatsType,
} from '@hcs/types';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';

import styles from './CerberusStatsDetailsTable.module.css';

interface CerberusStatsDetailsTableProps {
  dataHcName: string;
  className?: string;
  cerberusStats: CerberusStats;
}

interface ColumnConfig {
  label: string;
  Display: React.ReactNode;
  DateDisplay?: React.ReactNode;
  dataHcName: string;
}

export const CerberusStatsDetailsTable = ({
  dataHcName,
  className,
  cerberusStats,
}: CerberusStatsDetailsTableProps) => {
  const detailsDataConfig = useMemo(() => {
    // please keep alphabetical
    const daysOnMarketConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.daysOnMarketMedian];
    const grossYieldConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.grossYieldMedian];
    const hpiLatestMonthConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.hpiLatestMonth];
    const listingStatsEventDateConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.listingStatsEventDate];
    const listingsOnMarketConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.listingsOnMarketCount];
    const marketGradeConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.marketGrade];
    const monthsOfSupplyConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.monthsOfSupplyMedian];
    const netPopulationGrowthConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.netPopulationGrowth];
    const populationLatestMonthConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.populationLatestMonth];
    const priceOnMarketConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.priceOnMarketMedian];
    const pricePerSqftConfig =
      CERBERUS_STATS_FIELD_CONFIGS[
        CerberusStatsFields.pricePerSqftOnMarketMedian
      ];
    const return1YearForecastConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.return1YearForecast];
    const risk1YearLossConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.risk1YearLoss];
    const rpiLatestMonthConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.rpiLatestMonth];
    const rpi1YearReturnConfig =
      CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.rpiReturn1Year];
    const saleToListConfig =
      CERBERUS_STATS_FIELD_CONFIGS[
        CerberusStatsFields.saleToListPriceOriginalMedian
      ];

    const col1Configs: ColumnConfig[] = [
      {
        label: return1YearForecastConfig.label,
        Display: (
          <return1YearForecastConfig.Display cerberusStats={cerberusStats} />
        ),
        DateDisplay: (
          <hpiLatestMonthConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.return1YearForecast}`,
      },
      {
        label: risk1YearLossConfig.label,
        Display: <risk1YearLossConfig.Display cerberusStats={cerberusStats} />,
        dataHcName: `${dataHcName}-${CerberusStatsFields.risk1YearLoss}`,
      },
      {
        label: grossYieldConfig.label,
        Display: <grossYieldConfig.Display cerberusStats={cerberusStats} />,
        dataHcName: `${dataHcName}-${CerberusStatsFields.grossYieldMedian}`,
      },
      {
        label: saleToListConfig.label,
        Display: <saleToListConfig.Display cerberusStats={cerberusStats} />,
        DateDisplay: (
          <listingStatsEventDateConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.saleToListPriceOriginalMedian}`,
      },
      {
        label: daysOnMarketConfig.label,
        Display: <daysOnMarketConfig.Display cerberusStats={cerberusStats} />,
        DateDisplay: (
          <listingStatsEventDateConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.daysOnMarketMedian}`,
      },
      {
        label: rpi1YearReturnConfig.label,
        Display: <rpi1YearReturnConfig.Display cerberusStats={cerberusStats} />,
        DateDisplay: (
          <rpiLatestMonthConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.rpiReturn1Year}`,
      },
    ];
    const col2Configs: ColumnConfig[] = [
      {
        label: listingsOnMarketConfig.label,
        Display: (
          <listingsOnMarketConfig.Display cerberusStats={cerberusStats} />
        ),
        DateDisplay: (
          <listingStatsEventDateConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.listingsOnMarketCount}`,
      },
      {
        label: monthsOfSupplyConfig.label,
        Display: <monthsOfSupplyConfig.Display cerberusStats={cerberusStats} />,
        DateDisplay: (
          <listingStatsEventDateConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.monthsOfSupplyMedian}`,
      },
      {
        label: priceOnMarketConfig.label,
        Display: <priceOnMarketConfig.Display cerberusStats={cerberusStats} />,
        DateDisplay: (
          <listingStatsEventDateConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.priceOnMarketMedian}`,
      },
      {
        label: pricePerSqftConfig.label,
        Display: <pricePerSqftConfig.Display cerberusStats={cerberusStats} />,
        DateDisplay: (
          <listingStatsEventDateConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.pricePerSqftOnMarketMedian}`,
      },
    ];

    // only have net population growth on msa
    if (cerberusStats?.type === CerberusStatsType.Msa) {
      col2Configs.push({
        label: netPopulationGrowthConfig.label,
        Display: (
          <netPopulationGrowthConfig.Display cerberusStats={cerberusStats} />
        ),
        DateDisplay: (
          <populationLatestMonthConfig.Display cerberusStats={cerberusStats} />
        ),
        dataHcName: `${dataHcName}-${CerberusStatsFields.netPopulationGrowth}`,
      });
    }

    if (cerberusStats?.type === CerberusStatsType.Zip) {
      col2Configs.push({
        label: marketGradeConfig.label,
        Display: <marketGradeConfig.Display cerberusStats={cerberusStats} />,
        dataHcName: `${dataHcName}-${CerberusStatsFields.marketGrade}`,
      });
    }
    return [col1Configs, col2Configs];
  }, [cerberusStats, dataHcName]);

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Container, className)}
    >
      <div className={styles.InfoTooltipContainer}>
        <InfoIcon size={10} dataHcName={`${dataHcName}-info-icon`} />
        <div>Hover for last data update</div>
      </div>
      <div
        className={styles.TwoColumnTableContainer}
        data-hc-name={`${dataHcName}-data-table-container`}
      >
        {detailsDataConfig.map((columnArray, i) => {
          return (
            <TwoColumnTable
              dataHcName={`${dataHcName}-data-table`}
              key={`config-array-${i}`}
              compact
              borders
              valueWidth="150px"
              rows={columnArray.map(
                ({
                  label,
                  Display,
                  DateDisplay,
                  dataHcName: rowDataHcName,
                }) => {
                  return {
                    label,
                    value: (
                      <div
                        data-hc-name={`${rowDataHcName}-value-indicator`}
                        className={styles.CurrentValueIndicator}
                      >
                        {Display}
                      </div>
                    ),
                    // If no DateDisplay key, no popover is shown on mouseover.
                    tooltipContent: DateDisplay && (
                      <div
                        className={styles.LastUpdate}
                        data-hc-name={`${rowDataHcName}-last-data-update`}
                      >
                        <span>Last Data Update</span>
                        <span>{DateDisplay}</span>
                      </div>
                    ),
                  };
                }
              )}
            />
          );
        })}
      </div>
    </div>
  );
};
