import React from 'react';

import { TableHeaderCell } from '@hcs/design-system';
import { CompTypes, TableHeaderCellProps } from '@hcs/types';

import { CompDataPriorityUserLauncher } from '../../CompDataPriorityUser';

type TableOptionsHeaderCellProps = TableHeaderCellProps & {
  compType: CompTypes;
};
export const TableOptionsHeaderCell = (props: TableOptionsHeaderCellProps) => {
  return (
    <TableHeaderCell {...props}>
      <CompDataPriorityUserLauncher compType={props.compType} />
    </TableHeaderCell>
  );
};

// Using default props allows for child.props checks
// in the Table components to work properly
const defaultProps: Partial<TableOptionsHeaderCellProps> = {
  width: 50,
  sticky: true,
  align: 'center',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
TableOptionsHeaderCell.defaultProps = defaultProps;
