import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './FilterCard.module.css';

export interface FilterCardProps {
  dataHcName: string;
  className?: string;
  label: string;
  info?: ReactNode;
  error?: string;
  bordered?: boolean;
  children: ReactNode;
  clearFilterButton?: ReactNode;
}
export const FilterCard = ({
  dataHcName,
  className,
  error,
  info,
  label,
  bordered,
  children,
  clearFilterButton,
}: FilterCardProps) => {
  return (
    <div
      className={classNames(styles.FilterCard, className, {
        [styles.bordered]: bordered,
      })}
      data-hc-name={dataHcName}
    >
      <h4 data-hc-name={`${dataHcName}-title`} className={styles.Title}>
        {label}
      </h4>
      {(error || info) && (
        <h5
          data-hc-name={`${dataHcName}-info`}
          className={classNames(styles.Info, {
            [styles.error]: !!error,
          })}
        >
          {error || info}
        </h5>
      )}
      {children}
      <div className={styles.Clear}>{clearFilterButton}</div>
    </div>
  );
};
