import React from 'react';
import classNames from 'classnames';

import { NoContent } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { HcCompsIcon } from '@hcs/design-system';
import { PropertyMarker } from '@hcs/maps';
import { PropertyMarkerTypes } from '@hcs/maps';
import { CompTypes } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';

import { useSelectedOrSuggestedComps } from '../../hooks';
import { CompPropertyCard } from '../CompPropertyCard';

import styles from './SelectedOrSuggestedComps.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const dataHcName = 'selected-or-suggested-comps-cards';
export const SELECTED_OR_SUGGESETED_COMPS_CARDS_FEATURES_SALE = [
  ReportFeatures.CompsHcSuggested,
  ReportFeatures.CompsSelect,
];
export const SELECTED_OR_SUGGESETED_COMPS_CARDS_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsHcSuggested,
  ReportFeatures.RentalCompsSelect,
];
export const SelectedOrSuggestedCompsCards = ({
  reportId,
  compType,
  className,
}: Props) => {
  const isRental = compType === CompTypes.Rental;
  const { isError, data, isFetched } = useSelectedOrSuggestedComps(
    reportId,
    compType
  );
  const { comps } = data || {};
  return (
    <div
      className={classNames(styles.CompCardsHorizontal, className)}
      data-hc-name={`${dataHcName}-gallery`}
    >
      {isError && (
        <NullState
          title="An error occurred while loading comps."
          dataHcName={`${dataHcName}-error`}
        />
      )}
      {!isFetched ? (
        new Array(8).fill(null).map((v, i) => {
          return (
            <div
              className={classNames(styles.PropertyCard, styles.Skeleton)}
              key={`skeleton-${i}`}
            >
              <Skeleton dataHcName={`${dataHcName}-skeleton`} />
            </div>
          );
        })
      ) : comps && comps.length > 0 ? (
        comps.map((comp, i) => {
          const compGeoLocation = locationToGeoLocation(
            comp.compSchema.propertyState.location
          );
          if (!compGeoLocation) {
            return null;
          }
          return (
            <CompPropertyCard
              key={`gallery-card-${comp.compIdentifier.compId}`}
              reportId={reportId}
              compIdentifier={comp.compIdentifier}
              className={styles.PropertyCard}
              content={
                <PropertyMarker
                  markerId={`summary-marker-${comp.compIdentifier.compId}`}
                  geoLocation={compGeoLocation}
                  type={PropertyMarkerTypes.Comp}
                  label={i + 1}
                  renderedInMap={false}
                  mapMarkerTheme={{
                    Position: styles.PropertyMarkerPosition,
                  }}
                  selected
                />
              }
            />
          );
        })
      ) : (
        <NoContent
          dataHcName={`${dataHcName}-no-comps`}
          className={styles.NoContent}
          Icon={HcCompsIcon}
        >
          No Suggested {isRental ? 'Rental ' : null}Comps Found
        </NoContent>
      )}
    </div>
  );
};
