import React from 'react';

import styles from './BiasBanner.module.css';

export const BiasBanner = () => {
  return (
    <div className={styles.BiasBanner}>
      <div className={styles.Gradient} />
    </div>
  );
};
