import React from 'react';
import classNames from 'classnames';

import { PropertyStateCore } from '@hcs/types';
import { RiskSchema } from '@hcs/types';
import { formatRiskDescription, formatRiskLevel } from '@hcs/utils';

import styles from './AppraisalRiskOverallSection.module.css';

const dataHcName = 'appraisal-risk-overall-section';
export const AppraisalRiskOverallSectionContent = ({
  appraisalRisk,
  className,
}: {
  subject: PropertyStateCore;
  className?: string;
  appraisalRisk: RiskSchema;
}) => {
  const riskOverall = appraisalRisk.overall;
  const riskValue = appraisalRisk.value.riskLevel;
  const riskComps = appraisalRisk.comparables.riskLevel;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.BiasRiskOverallSection, className, {
        [styles.low]: riskOverall === 'LOW_RISK',
        [styles.med]: riskOverall === 'RISK',
        [styles.high]: riskOverall === 'HIGH_RISK',
      })}
    >
      <div className={styles.Heading}>{formatRiskLevel(riskOverall)}</div>
      <div className={styles.SubHeading}>
        {formatRiskDescription(riskOverall, riskValue, riskComps)}
      </div>
    </div>
  );
};
