import {
  SetupIntentResult,
  Stripe,
  StripeCardElement
} from '@stripe/stripe-js';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

interface MutationVariables {
  stripe: Stripe;
  cardElement: StripeCardElement;
  clientSecret: string;
}
export const useStripeConfirmCardSetup = (
  options?: UseMutationOptions<SetupIntentResult, unknown, MutationVariables>
) => {
  return useMutation(
    async ({ stripe, cardElement, clientSecret }: MutationVariables) => {
      return await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement
        }
      });
    },
    {
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        options?.onError?.(error, variables, context);
      }
    }
  );
};
