import { useMemo } from 'react';

import {
  AgileBillingComponents,
  AgileProductGroupings,
  AppSlugs,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useAccount } from './useAccount';
import { useCheckAppZuoraSubscription } from './useCheckAppZuoraSubscription';

/**
 * Billing codes (uoms) are used to determine
 * which subscriptions a user/org has access to within
 * Order Manager
 */
export const AGILE_PRODUCT_BILLING_CODES_BY_PRODUCT_NAME = {
  [AgileProductGroupings.AgileInsights]: [AgileBillingComponents.AgileInsights],
  [AgileProductGroupings.AgileEvaluation]: [
    AgileBillingComponents.AgileEvaluationExterior,
    AgileBillingComponents.AgileEvaluationInterior,
    AgileBillingComponents.AgileEvaluationExterior2Day,
  ],
  [AgileProductGroupings.AgileInspection]: [
    AgileBillingComponents.AgileInspectionExterior,
    AgileBillingComponents.AgileInspectionInterior,
  ],
};

const AGILE_APP_SLUG = AppSlugs.AgileSuite;

export const useCheckAgileProductZuoraSubscription = (
  agileProductGrouping: AgileProductGroupings
) => {
  const accountQuery = useAccount();
  const agileSuiteSubscriptionQuery =
    useCheckAppZuoraSubscription(AGILE_APP_SLUG);
  const combinedQuery = combineUseQueryResult([
    accountQuery,
    agileSuiteSubscriptionQuery,
  ]);

  const productBillingCodes =
    AGILE_PRODUCT_BILLING_CODES_BY_PRODUCT_NAME[agileProductGrouping];

  const hasAgileProductAccess = useMemo(() => {
    if (combinedQuery.isSuccess) {
      const agileSuiteAccessibleApp =
        accountQuery.data?.accessibleApplications.find(
          (accessibleApplication) =>
            accessibleApplication.name === AGILE_APP_SLUG
        );
      return (
        agileSuiteSubscriptionQuery.data &&
        productBillingCodes &&
        agileSuiteAccessibleApp?.name === AGILE_APP_SLUG &&
        productBillingCodes.some((code) => {
          return agileSuiteAccessibleApp.components.indexOf(code) > -1;
        })
      );
    }
    return undefined;
  }, [
    productBillingCodes,
    accountQuery.data,
    agileSuiteSubscriptionQuery.data,
    combinedQuery.isSuccess,
  ]);

  // Return account query access but change data to boolean result
  return {
    ...combinedQuery,
    data: hasAgileProductAccess,
  };
};
