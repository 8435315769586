import {
  AppraisalCompDataSourcesDocument,
  DocumentRoles,
  ReportId,
} from '@hcs/types';

import { useDocumentRole } from '.';

export const useAppraisalCompDataSourcesDocuments = (reportId: ReportId) => {
  return useDocumentRole<AppraisalCompDataSourcesDocument>(
    reportId,
    DocumentRoles.AppraisalCompDataSources
  );
};
