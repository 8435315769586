import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const DELIM = ':';
export interface HcMapSearchParams {
  latitude: number;
  longitude: number;
  zoom: number;
}
export const useHcMapSearchParams = (
  mapId: string
): [HcMapSearchParams | undefined, (v: HcMapSearchParams) => void] => {
  const [searchParams, setSearchParamsInner] = useSearchParams();
  const paramName = `${mapId}-params`;
  const paramStr = searchParams.get(paramName);
  const setSearchParams = useCallback(
    ({ latitude, longitude, zoom }: HcMapSearchParams) => {
      searchParams.set(
        paramName,
        `${latitude}${DELIM}${longitude}${DELIM}${zoom}`
      );
      setSearchParamsInner(searchParams, { replace: true });
    },
    [paramName, searchParams, setSearchParamsInner]
  );
  if (paramStr) {
    const [latitude, longitude, zoom] = paramStr.split(DELIM).map(parseFloat);
    if (latitude && longitude && zoom) {
      const hcMapSearchParams: HcMapSearchParams = {
        latitude,
        longitude,
        zoom
      };
      return [hcMapSearchParams, setSearchParams];
    }
  }
  return [undefined, setSearchParams];
};
