import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { CompFields, CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

import { useCompsFiltersDocument } from '../../../hooks';
import { CompFilterDistance } from '../../CompFiltersDeprecated/CompFilterDistance';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  navigateToCompSelectionDraw: NavigateToCompSelectionDrawFn;
}
const COMP_FIELD = CompFields.distance;
const dataHcName = 'comp-filter-button-distance';
export const CompFilterButtonDistance = ({
  reportId,
  compType,
  className,
  navigateToCompSelectionDraw,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }
  const filterValue = filterDocument.data.filters?.[CompFields.distance];
  const active = !!filterValue;
  const label = filterValue?.relativeValue
    ? `${filterValue.relativeValue} Mile${
        filterValue.relativeValue === 1 ? '' : 's'
      } from Subject`
    : filterValue?.absoluteValue?.properties?.drawType &&
      filterValue.absoluteValue.properties.shape
    ? `${capitalizeFirstLetter(
        `${filterValue.absoluteValue.properties.drawType}`
      )} ${`${filterValue.absoluteValue.properties.shape}`}`
    : 'Distance';

  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={label}
      compField={COMP_FIELD}
      className={className}
      content={
        <CompFilterDistance
          reportId={reportId}
          compType={compType}
          navigateToCompSelectionDraw={navigateToCompSelectionDraw}
        />
      }
      active={active}
    />
  );
};
