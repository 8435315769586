import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './SectionTitle.module.css';

export interface SectionTitleProps {
  dataHcName: string;
  className?: string;
  children?: ReactNode;
  onClick?: VoidFunction;
}

export const SectionTitle = ({
  dataHcName,
  className,
  onClick,
  children,
}: SectionTitleProps) => {
  return (
    <h3
      data-hc-name={dataHcName}
      className={classNames(styles.SectionTitle, className)}
      onClick={onClick}
    >
      {children}
    </h3>
  );
};
