import React from 'react';

import { TableRow } from '@hcs/design-system';
import { TableRowProps } from '@hcs/types';
import { PropertyStateArgsProps } from '@hcs/types';

import { PropertyTableProps } from '../../components/PropertyTable/PropertyTable';
import { CustomCellData } from '../../components/PropertyTable/PropertyTable.types';
import { PROPERTY_STATE_FIELD_CONFIGS } from '../../configs';
import { ADDRESS_CONFIG } from '../../configs/PropertyStateFields/address';
import { PropertyBrokerageCredit } from '../PropertyBrokerageCredit';

const { ContentCell: ContentCellAddress } = ADDRESS_CONFIG;

export interface PropertyTableRowProps<D extends CustomCellData>
  extends TableRowProps,
    PropertyStateArgsProps {
  isLoading?: boolean;
  customData?: D;
  fields: PropertyTableProps<D>['fields'];
  showAddress?: boolean;
}

export const PropertyTableRow = <D extends CustomCellData>({
  propertyStateArgs,
  fields,
  customData,
  showAddress = true,
  ...tableRowProps
}: PropertyTableRowProps<D>) => {
  const { propertyState } = propertyStateArgs || {};
  return (
    <TableRow {...tableRowProps}>
      {showAddress ? (
        <ContentCellAddress propertyStateArgs={propertyStateArgs}>
          <PropertyBrokerageCredit
            propertyStateArgs={propertyStateArgs}
            isRental={false}
          />
        </ContentCellAddress>
      ) : null}
      {fields.map((field, c) => {
        const key = `${propertyState?.hcAddressId}-${c}`;
        if (field.type === 'propertyStateFields') {
          const { ContentCell } = PROPERTY_STATE_FIELD_CONFIGS[field.value];
          return (
            <ContentCell
              propertyStateArgs={propertyStateArgs}
              key={key}
              {...field.contentCellProps}
            />
          );
        } else {
          const { CustomCell, value, tableCellProps } = field;
          return (
            <CustomCell
              {...tableCellProps}
              isTableCell={true}
              key={value}
              propertyStateArgs={propertyStateArgs}
              customData={customData}
            />
          );
        }
      })}
    </TableRow>
  );
};

// Needed for Table child checks
PropertyTableRow.defaultProps = {
  isTableRow: true,
};
