import React from 'react';

import { Dropdown } from '@hcs/design-system';
import { MenuItem } from '@hcs/design-system';
import { HomeMagnifyingGlassIcon } from '@hcs/design-system';
import { usePatchUiPreferencesForUser } from '@hcs/huell';
import { useUiPreferencesForUser } from '@hcs/huell';
import { COMPS_LIST_VIEW_TYPE_CONFIGS } from '@hcs/report-api';
import { CompsListViewType, PreferencesKeys } from '@hcs/types';

import styles from './UserExperiencePreferences.module.css';

const dataHcName = 'comps-list-view-type-preference-control';
export const CompsListViewTypePreferenceControl = () => {
  const { isSuccess, data: userPrefs } = useUiPreferencesForUser();
  const { mutate: patchUserPrefs } = usePatchUiPreferencesForUser();
  const compsListViewTypeDefault =
    userPrefs?.compsListViewTypeDefault || CompsListViewType.Card;
  return (
    <MenuItem
      dataHcName={dataHcName}
      Icon={HomeMagnifyingGlassIcon}
      label="Comps List View Type"
      description="Controls whether lists of comps are displayed in Card, List, or Comparison View by default."
      contentRight={
        isSuccess && (
          <Dropdown
            dataHcName={`${dataHcName}-listViewType-control`}
            value={compsListViewTypeDefault}
            className={styles.CompsListTypeDropdown}
            options={[
              CompsListViewType.Card,
              CompsListViewType.Table,
              CompsListViewType.Compare,
            ].map((v) => {
              const { name, listViewType } = COMPS_LIST_VIEW_TYPE_CONFIGS[v];
              return {
                value: listViewType,
                label: name,
              };
            })}
            onSelect={(listViewType) => {
              patchUserPrefs([
                {
                  op: 'add',
                  path: `/${PreferencesKeys.CompListViewTypeDefault}`,
                  value: listViewType,
                },
              ]);
            }}
          />
        )
      }
    />
  );
};
