import { BulkHpiIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, CapabilityApplications } from '@hcs/types';

export const APP_CONFIG_BULK_RPI: AppConfig = {
  name: 'Bulk RPI Data',
  appSlug: AppSlugs.Platform,
  rootPath: `/`,
  Icon: BulkHpiIcon,
  applicationKey: CapabilityApplications.BulkRpi,
  descriptionLong:
    'Bulk RPI Data at the Zip/MSA/State levels, delivered monthly.',
  descriptionShort: 'Bulk RPI Data at the Zip/MSA/State levels',
};
