import React from 'react';

import { Dialog } from '@hcs/design-system';
import {
  AgileSuiteProductConfigExtended,
  AppConfigExtended,
  OptionalEngagementProps,
} from '@hcs/types';
import { formatForDataHcName } from '@hcs/utils';

import { useRequestProductAccess } from '../../hooks/useRequestProductAccess';

import { ProductPreview } from './ProductPreview';

import styles from './ProductPreviewDialog.module.css';

export interface ProductPreviewDialogProps extends OptionalEngagementProps {
  active: boolean;
  appConfigExtended:
    | AppConfigExtended
    | AgileSuiteProductConfigExtended
    | null
    | undefined;
  premiumFeature?: boolean;
  limitedAccess?: boolean;
  navigateToSSSelectPlan?: VoidFunction;
  onClose: VoidFunction;
}
export const ProductPreviewDialog = ({
  premiumFeature,
  active,
  appConfigExtended,
  limitedAccess,
  navigateToSSSelectPlan,
  onClose,
}: ProductPreviewDialogProps) => {
  const requestProductQueryMutation = useRequestProductAccess(
    navigateToSSSelectPlan
  );
  if (!appConfigExtended) return null;
  const {
    appConfig: { appSlug },
  } = appConfigExtended;

  const dataHcName = `product-preview${formatForDataHcName(appSlug)}`;
  const handleClickRequestAccess = () => {
    requestProductQueryMutation.mutate?.({
      requestedProduct: appConfigExtended.appConfig.appSlug,
    });
  };
  return (
    <Dialog
      type="auto"
      dataHcName={`${dataHcName}-dialog`}
      theme={{
        DialogContent: styles.DialogContent,
        DialogWrapper: styles.DialogWrapper,
        Actions: styles.Actions,
        Dialog: styles.Dialog,
      }}
      active={active}
      onClose={onClose}
      noContentPadding
    >
      <ProductPreview
        limitedAccess={limitedAccess}
        premiumFeature={premiumFeature}
        dataHcName={dataHcName}
        appConfigExtended={appConfigExtended}
        onClickRequestAccess={handleClickRequestAccess}
      />
    </Dialog>
  );
};
