import React from 'react';

import { OmPropertyType } from '@hcs/types';

import styles from './DataItem.module.css';

interface Props {
  data: string | OmPropertyType;
  dataHcName: string;
  label: string;
}

export const DataItem = ({ data, dataHcName, label }: Props) => {
  return (
    <div className={styles.DataItem} data-hc-name={dataHcName}>
      <div className={styles.DataLabel}>{label}</div>
      <div className={styles.DataValue}>{data.toString() || '-'}</div>
    </div>
  );
};
