import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import CardView from './CardViewIcon.svg?react';
import CompactView from './CompactViewIcon.svg?react';
import CompareView from './CompareViewIcon.svg?react';
import TableView from './TableViewIcon.svg?react';

export const CardViewIcon = (props: IconProps) => useIcon(CardView, props);
export const CompactViewIcon = (props: IconProps) =>
  useIcon(CompactView, props);
export const CompareViewIcon = (props: IconProps) =>
  useIcon(CompareView, props);
export const TableViewIcon = (props: IconProps) => useIcon(TableView, props);
