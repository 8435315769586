export enum IconTypeEnum {
  SVG = 'svg',
  NEW_SVG = 'newSvg',
  PNG = 'png',
  PNG_RETINA = 'pngRetina'
}

export enum ScreenSize {
  SCREEN_WIDTH_800 = 800,
  SCREEN_WIDTH_1000 = 1000,
  SCREEN_WIDTH_1200 = 1200,
  SCREEN_WIDTH_1400 = 1400
}
