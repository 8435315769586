import { AddressParts } from './CreateNewOrder.types';
import { OrderTypeId } from './Order.types';

export enum OrderItemStatus {
  None = '',
  AppraisalPending = 'AppraisalPending',
  AppraisalRevisionPending = 'AppraisalRevisionPending',
  InspectionPending = 'InspectionPending',
  InspectionComplete = 'InspectionComplete',
  EvaluationComplete = 'EvaluationComplete',
  ReportPending = 'ReportPending',
  Complete = 'Complete',
  CancelPending = 'CancelPending',
  Cancelled = 'Cancelled',
  QCReview = 'QCReview',
  InspectionRevision = 'InspectionRevision',
  InspectionReview = 'InspectionReview',
  InspectionCancelled = 'InspectionCancelled',
  InspectionCorrectionReview = 'InspectionCorrectionReview',
  ValuationReview = 'ValuationReview',
  RevisionRequested = 'RevisionRequested',
}

export type AvailableDownloadable = {
  key: string;
  displayName: string;
  outputFileName: string;
};

export type ValidationResult = {
  canOverride: boolean;
  message: string;
  detail: string;
  level: 'error' | 'warning';
  errorCode?: number;
};

interface OrderItemCommon extends AddressParts {
  id: number;
  customerItemId: string;
  status: OrderItemStatus;
  createdAt: string;
  completionDate: string | null;
  integrationKey: string;
  cancelled: boolean | null;
}

export interface OrderItem extends OrderItemCommon {
  addressValidationStatus: string;
  orderId: number;
  processItem?: boolean | null;
  propertyType:
    | 'singlefamily'
    | 'condo'
    | 'multifamily'
    | 'mobile'
    | 'vacantlot'
    | 'townhouse';
  inspectionDate?: string; // legacy
  inspectionCompletionDate?: string | null;
  appraisalDate?: string;
  appraisedValue?: number;
  incomingCommentsCount: number;
  canCancel: boolean;
  canRequestRevision: boolean;
  availableDownloadables: AvailableDownloadable[];
  startedAt: null | string;
  validationResults: ValidationResult[];
  canOverrideAllIssues?: boolean;
  validationChecksComplete: boolean;
  dueDate?: string | null;
  validationIssueSummary?: string | null;
  county?: string;
  isCertified?: boolean;
  estimatedValue?: number | null;
  evaluationCompletionDate?: string | null;
  valueRange?: string | null;
  inspectionContactName?: string | null;
  inspectionContactPhone: string | null;
  inspectionContactPhoneIsCell: boolean | null;
  inspectionContactEmail: string | null;
  inspectionCondition?: string | null;
  secondaryInspectionContactName: string | null;
  secondaryInspectionContactPhone: string | null;
  secondaryInspectionContactPhoneIsCell: boolean | null;
  secondaryInspectionContactEmail: string | null;
  specialCommentsAmc: string | null;
  specialCommentsInspection: string | null;
  supportingDocuments: [];
  folderNumber: null;
}

export interface SearchResultOrderItem extends OrderItem {
  orderId: number;
  orderName: string;
  orderType: OrderTypeId;
  orderTypeName: string;
  label: string;
  startedAt: string | null;
}

export enum OrderItemGroup {
  InOrder = 'in-order',
  NeedReview = 'need-review',
  Removed = 'removed',
}

export type OrderItemProcessCounts = {
  includedItems: number;
  verifyItems: number;
  excludedItems: number;
};

export type SelectedItemsObject = {
  selectedItemIds: string[];
  areAllSelected: boolean;
};

export type OrderItemBulkUpdateResponse = {
  errors: {
    message: string;
    id: number;
  }[];
  results: {
    process_item: boolean | null;
    id: number;
  }[];
};
