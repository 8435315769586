import React from 'react';
import classNames from 'classnames';

import { FlexScrollFixedContentProp } from '../../layout/FlexScroll/FlexScroll';
import { Card, CardProps } from '../Card';

import styles from './FlexScrollCard.module.css';

export interface FlexScrollCardProps extends CardProps {
  // Rendered in scrollable area
  header?: FlexScrollFixedContentProp;
  footer?: FlexScrollFixedContentProp;
  // Set height to fit in the visible area of the browser while accounting for global header height
  fitViewHeight?: boolean;
}

export const FlexScrollCard = ({
  dataHcName,
  dataHcEventSection,
  className,
  children,
  header,
  footer,
  fitViewHeight = false,
  ...cardProps
}: FlexScrollCardProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={classNames(styles.FlexScrollCard, className, {
        [styles.fitViewHeight]: fitViewHeight,
      })}
    >
      <Card
        {...cardProps}
        dataHcName={`${dataHcName}-card`}
        className={classNames(styles.Card, className)}
      >
        <div
          className={classNames(styles.Header, header?.className)}
          style={{ flex: `0 0 ${header?.height || 0}px` }}
        >
          {header?.content || null}
        </div>
        <div
          className={styles.Scroll}
          data-hc-name={`${dataHcName}-scroll-cell`}
        >
          {children}
        </div>
        <div
          className={classNames(styles.Footer, footer?.className)}
          style={{ flex: `0 0 ${footer?.height || 0}px` }}
        >
          {footer?.content || null}
        </div>
      </Card>
    </div>
  );
};
