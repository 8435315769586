import React from 'react';

import { CopyInput } from '@hcs/design-system';
import { Switch } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { UserGroupIcon } from '@hcs/design-system';
import { MeaningfulEventTypes, ReportFeatures, ReportId } from '@hcs/types';

import { useReportPermissions } from '../../hooks';
import { usePublicReportLink } from '../../hooks/usePublicReportLink';
import { useReportPublicLinkCreate } from '../../hooks/useReportPublicLinkCreate';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './ReportPublicLinkReadOnly.module.css';

interface Props {
  className?: string;
  reportId: ReportId;
}
const dataHcName = 'report-public-link';
export const REPORT_PUBLIC_LINK_READ_ONLY_FEATURES = [
  ReportFeatures.PublicLinks,
];
export const ReportPublicLinkReadOnly = ({ className, reportId }: Props) => {
  const {
    data: publicReportData,
    isInitialLoading: isLoadingPublicReportData,
  } = usePublicReportLink(reportId);
  const {
    data: reportPermissionsData,
    isInitialLoading: isLoadingReportPermissionsData,
  } = useReportPermissions(reportId);
  const { mutate: createPublicLink } = useReportPublicLinkCreate(reportId);
  if (isLoadingPublicReportData || isLoadingReportPermissionsData) {
    return <LoadingSpinner dataHcName={`${dataHcName}-loading-spinner`} />;
  }
  const isCreateDisabled =
    !reportPermissionsData.isEditable || !publicReportData?.allowed;
  const hasPublicLink = !!publicReportData?.publicLink;
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={REPORT_PUBLIC_LINK_READ_ONLY_FEATURES}
    >
      <div className={className}>
        <div className={styles.TextIconContainer}>
          <UserGroupIcon dataHcName={`${dataHcName}-user-icon`} />
          <div>
            <div
              data-hc-name={`${dataHcName}-account-name-label`}
              className={styles.Label}
            >
              Generate Read Only Public Link
            </div>
            {!publicReportData?.publicLink &&
            publicReportData?.allowed &&
            !reportPermissionsData.isEditable ? (
              <div>
                Report expired. Please generate a new report to share publicly.
              </div>
            ) : (
              <div>Anyone on the internet with this link can view only.</div>
            )}
          </div>
          <div className={styles.SwitchContainer}>
            <Switch<boolean>
              dataHcName={`${dataHcName}-switch`}
              value={!!publicReportData?.publicLink}
              theme={{
                SwitchOn: publicReportData?.publicLink ? styles.SwitchOn : '',
                Track: publicReportData?.publicLink
                  ? styles.Track
                  : styles.TrackOff,
              }}
              onChange={() => createPublicLink()}
              switchOnOption={{
                value: true,
                dataHcEventName: hasPublicLink
                  ? undefined
                  : 'Create Public Link',
                dataHcEventType: hasPublicLink
                  ? undefined
                  : MeaningfulEventTypes.Goal,
              }}
              switchOffOption={{ value: false }}
              disabled={isCreateDisabled}
            />
          </div>
        </div>
        {publicReportData?.publicLink && (
          <CopyInput
            dataHcName={`${dataHcName}-copy-url`}
            value={publicReportData.publicLink}
            copyEngagement={{
              dataHcEventName: 'Copy Public Link',
              dataHcEventType: MeaningfulEventTypes.Goal,
            }}
          />
        )}
      </div>
    </ReportFeaturesSupported>
  );
};
