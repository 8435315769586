import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useRerender } from '@hcs/hooks';

import {
  Button,
  ButtonProps,
} from '../../../controls-and-inputs/buttons/Button';

import styles from './ActionButtons.module.css';

export interface ActionButtonProps {
  dataHcName: string;
  className?: string;
  // Buttons to Render
  actions?: ButtonProps[];
  // ID to assign to container to allow other ActionButtons features to render inside
  portalId?: string;
  // ID of Another container to render the buttons in (such as dialog actions)
  portalIdRender?: string;
  children?: ReactNode;
  /**
   * Prevent the buttons from stretching to fill the container
   */
  noStretch?: boolean;
}

export const ActionButtons = ({
  dataHcName,
  className,
  actions,
  portalId,
  portalIdRender,
  children,
  noStretch,
}: ActionButtonProps) => {
  const elm = portalIdRender
    ? document.querySelector(`#${portalIdRender}`)
    : undefined;
  const renderedActions = (
    <>
      {actions?.map((b, i) => (
        <Button {...b} key={`action-${i}-${b.dataHcName}`} />
      ))}
      {children}
    </>
  );
  // Re-render once if the target element isn't rendered
  // This is needed when this feature and the portal mount simultaneously
  useRerender({
    deps: [portalIdRender],
    shouldRerender: !!portalIdRender && !elm,
    max: 1,
  });

  if (portalIdRender) {
    if (elm) {
      return createPortal(renderedActions, elm);
    } else {
      return null;
    }
  } else {
    return (
      <div
        id={portalId}
        data-hc-name={dataHcName}
        className={classNames(styles.ActionButtons, className, {
          [styles.noStretch]: noStretch,
        })}
      >
        {renderedActions}
      </div>
    );
  }
};
