import React, { ReactNode } from 'react';
import { formatNumber } from 'accounting';

import { Button } from '@hcs/design-system';
import { OverlayPage } from '@hcs/design-system';
import { DataExplorerIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import {
  DexpCreateJobInputs,
  DexpEndpointsParams,
  DexpEvent,
} from '@hcs/types';

import { EndpointsTableSearchCard } from '../../features/EndpointsTableSearchCard';
import { useDexpCreateJob } from '../../hooks/useDexpCreateJob';
import { useDexpEndpointsTable } from '../../hooks/useDexpEndpointsTable';

import styles from './DexpShoppingCartLauncher.module.css';

export type AddressesInput = {
  hcAddressId: number;
  clientFileId?: string;
}[];

interface Props {
  theme?: Partial<{
    Trigger: string;
    OverlayPage: string;
  }>;
  trigger?: ReactNode;
  addressesInput: AddressesInput;
  endpointFilters?: DexpEndpointsParams;
  onSuccess?: (data: DexpEvent, variables: DexpCreateJobInputs) => void;
}
const dataHcName = 'dexp-shopping-cart-launcher';
export const DexpShoppingCartLauncher = ({
  theme,
  trigger,
  addressesInput,
  endpointFilters,
  onSuccess,
}: Props) => {
  const numAddresses = addressesInput.length;
  const disabled = !numAddresses;
  const {
    actions: { clearCart },
  } = useDexpEndpointsTable(endpointFilters);
  const { mutate: createDexpJob } = useDexpCreateJob({ onSuccess });
  const { active, handleClose, handleOpen } = useActiveState();
  return (
    <>
      {trigger ? (
        <span
          data-hc-name={`${dataHcName}-button`}
          onClick={disabled ? undefined : handleOpen}
          className={theme?.Trigger}
        >
          {trigger}
        </span>
      ) : (
        <Button
          dataHcName={`${dataHcName}-button`}
          className={theme?.Trigger}
          label="Create DEXP Analysis"
          disabled={disabled}
          icon={<DataExplorerIcon />}
          iconPosition="right"
        />
      )}
      <OverlayPage
        dataHcName={`${dataHcName}-overlayPage`}
        className={theme?.OverlayPage}
        active={active}
        headerProps={{
          title: `Select Endpoints for ${formatNumber(
            numAddresses
          )} Property Analysis`,
          onBack: handleClose,
        }}
      >
        <div className={styles.Content}>
          <EndpointsTableSearchCard
            darkTabs
            filters={endpointFilters}
            shoppingCartCardProps={{
              onClickCta: (shoppingCart) => {
                const endpoints: string[] = [];
                Object.entries(shoppingCart).forEach(
                  ([endpointId, endpointData]) => {
                    if (endpointData.isSelected) {
                      endpoints.push(endpointId);
                    }
                  }
                );
                if (endpoints.length) {
                  const createJobInput: DexpCreateJobInputs = {
                    endpoints: endpoints.join(','),
                    properties: addressesInput,
                  };
                  createDexpJob(createJobInput);
                }
                handleClose();
                clearCart();
              },
              ctaLabel: `Create Analysis for ${formatNumber(
                numAddresses
              )} Properties`,
            }}
          />
        </div>
      </OverlayPage>
    </>
  );
};
