import React from 'react';
import { Link } from 'react-router-dom';

import {
  APP_CONFIG_AGILE_SUITE,
  VIEW_PATHS_ORDER_MANAGER,
} from '@hcs/hc-products';
import { ReportSummaryCellContentProps } from '@hcs/types';

import { DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL } from '../../../constants/ReportSummary.constants';

import { createReportSummaryTableCellConfig } from './ReportSummaryTableCell';

import styles from './Order.module.css';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL}-order`;
const CellContentComponent = ({ order }: ReportSummaryCellContentProps) => (
  <div data-hc-name={`${dataHcName}`}>
    <Link
      data-hc-name={`${dataHcName}-link`}
      to={`${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.ORDER}/${order.id}`}
      className={styles.Link}
    >
      {order.name}
    </Link>
  </div>
);

export const REPORT_SUMMARY_ORDER_CONFIG = createReportSummaryTableCellConfig({
  CellContentComponent,
  tableHeaderName: 'Order',
});
