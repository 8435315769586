import { useEffect } from 'react';

import { useAccount } from '@hcs/auth';
import {
  useGlobalEngagementEventSlice,
  useRegisterAndPeristEngagementTrackingData,
} from '@hcs/engagement-tracking';
import { useOrgSelfServeInfo, useOrgSubscriptionType } from '@hcs/organization';

import { PLAN_EVENT_DATA_ID } from '../../constants';

export const SelfServiceEngagementData = () => {
  const {
    actions: { registerGlobalEventData },
  } = useGlobalEngagementEventSlice();
  const accountQuery = useAccount();
  const { data: orgSsInfo } = useOrgSelfServeInfo();
  const { self_serve_plan: plan } = orgSsInfo || {};
  const { registerAndPersistEventData } =
    useRegisterAndPeristEngagementTrackingData(PLAN_EVENT_DATA_ID, plan);
  const { data: subscriptionType } = useOrgSubscriptionType();
  useEffect(() => {
    registerGlobalEventData({
      authUserId: accountQuery.data?.user.id,
      email: accountQuery.data?.user.email,
      authOrganizationSlug: accountQuery.data?.currentOrganization.slug,
      authOrganizationId: accountQuery.data?.currentOrganization.id,
      subscriptionType,
    });
  }, [accountQuery.data, registerGlobalEventData, subscriptionType]);
  useEffect(() => {
    if (plan) {
      registerAndPersistEventData(PLAN_EVENT_DATA_ID, { plan });
    }
  }, [plan, registerAndPersistEventData]);
  return null;
};
