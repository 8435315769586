import { CompFields, PropertyStateFields } from '@hcs/types';
import { CompFilterConfig } from '@hcs/types';

import { COMP_FILTER_BASEMENT } from './CompFilterBasement';
import { COMP_FILTER_BATHROOMS } from './CompFilterBathrooms';
import { COMP_FILTER_BEDROOMS } from './CompFilterBedrooms';
import { COMP_FILTER_CLOSE_DATE } from './CompFilterCloseDate';
import { COMP_FILTER_CLOSE_DATE_RENTAL } from './CompFilterCloseDateRental';
import { COMP_FILTER_CLOSE_PRICE } from './CompFilterClosePrice';
import { COMP_FILTER_CLOSE_PRICE_RENTAL } from './CompFilterClosePriceRental';
import { COMP_FILTER_CONDITION } from './CompFilterCondition';
import { COMP_FILTER_CURRENT_VALUE_RENTAL } from './CompFilterCurrentValueRental';
import { COMP_FILTER_GARAGE } from './CompFilterGarage';
import { COMP_FILTER_KEYWORD_SEARCH } from './CompFilterKeywordSearch';
import { COMP_FILTER_LISTING_DATE } from './CompFilterListingDate';
import { COMP_FILTER_LISTING_DATE_RENTAL } from './CompFilterListingDateRental';
import { COMP_FILTER_LIST_PRICE } from './CompFilterListPrice';
import { COMP_FILTER_LIST_PRICE_RENTAL } from './CompFilterListPriceRental';
import { COMP_FILTER_LIVING_AREA } from './CompFilterLivingArea';
import { COMP_FILTER_LOT_SIZE } from './CompFilterLotSize';
import { COMP_FILTER_POOL } from './CompFilterPool';
import { COMP_FILTER_PROPERTY_STATUS } from './CompFilterPropertyStatus';
import { COMP_FILTER_PROPERTY_STATUS_RENTAL } from './CompFilterPropertyStatusRental';
import { COMP_FILTER_PROPERTY_TYPE } from './CompFilterPropertyType';
import { COMP_FILTER_SIMILARITY } from './CompFilterSimilarity';
import { COMP_FILTERS_STORIES } from './CompFilterStories';
import { COMP_FILTER_YEAR_BUILT } from './CompFilterYearBuilt';

export * from './CompFilterDistance';

export const COMP_FILTER_FIELD_CONTROLS: Partial<{
  [key in PropertyStateFields]: CompFilterConfig;
}> &
  Partial<
    {
      [key in CompFields]: CompFilterConfig;
    } & {
      'listingDetailsSale.remarks': CompFilterConfig;
      'listingDetailsRental.remarks': CompFilterConfig;
    }
  > = {
  'listingDetailsSale.remarks': COMP_FILTER_KEYWORD_SEARCH,
  'listingDetailsRental.remarks': COMP_FILTER_KEYWORD_SEARCH,
  [CompFields.similarity]: COMP_FILTER_SIMILARITY,
  [PropertyStateFields.basementHas]: COMP_FILTER_BASEMENT,
  [PropertyStateFields.bathrooms]: COMP_FILTER_BATHROOMS,
  [PropertyStateFields.bedrooms]: COMP_FILTER_BEDROOMS,
  [PropertyStateFields.lastCloseDate]: COMP_FILTER_CLOSE_DATE,
  [PropertyStateFields.lastCloseDateRental]: COMP_FILTER_CLOSE_DATE_RENTAL,
  [PropertyStateFields.lastClosePrice]: COMP_FILTER_CLOSE_PRICE,
  [PropertyStateFields.lastClosePriceRental]: COMP_FILTER_CLOSE_PRICE_RENTAL,
  [PropertyStateFields.garageSpaces]: COMP_FILTER_GARAGE,
  [PropertyStateFields.currentListDate]: COMP_FILTER_LISTING_DATE,
  [PropertyStateFields.currentListDateRental]: COMP_FILTER_LISTING_DATE_RENTAL,
  [PropertyStateFields.currentListingPrice]: COMP_FILTER_LIST_PRICE,
  [PropertyStateFields.currentValueRental]: COMP_FILTER_CURRENT_VALUE_RENTAL,
  [PropertyStateFields.currentListingPriceRental]:
    COMP_FILTER_LIST_PRICE_RENTAL,
  [PropertyStateFields.livingArea]: COMP_FILTER_LIVING_AREA,
  [PropertyStateFields.lotSize]: COMP_FILTER_LOT_SIZE,
  [PropertyStateFields.pool]: COMP_FILTER_POOL,
  [PropertyStateFields.currentStatus]: COMP_FILTER_PROPERTY_STATUS,
  [PropertyStateFields.currentStatusRental]: COMP_FILTER_PROPERTY_STATUS_RENTAL,
  [PropertyStateFields.propertyType]: COMP_FILTER_PROPERTY_TYPE,
  [PropertyStateFields.stories]: COMP_FILTERS_STORIES,
  [PropertyStateFields.yearBuilt]: COMP_FILTER_YEAR_BUILT,
  [PropertyStateFields.condition]: COMP_FILTER_CONDITION,
};
