import { useMemo } from 'react';

import { useUiPreferencesForUser } from '@hcs/huell';
import {
  CompFields,
  CompTypes,
  PreferencesKeys,
  PropertyStateFields,
} from '@hcs/types';

import {
  COMP_FIELD_ORDER_TABLE,
  RENTAL_COMP_FIELD_ORDER_TABLE,
} from '../constants';

// A Hook to return comp fields based on user preferences and defaults
export const useCompFields = (compType: CompTypes) => {
  const preferenceKey =
    compType === CompTypes.Rental
      ? PreferencesKeys.RentalCompTableColumns
      : PreferencesKeys.CompTableColumns;
  const mergedUserOrgUiPreferences = useUiPreferencesForUser();
  const { isInitialLoading, data } = mergedUserOrgUiPreferences;
  const preferredOrder = data?.[preferenceKey]?.order;
  const preferredInactive = data?.[preferenceKey]?.inactive;
  return useMemo(() => {
    const order: (PropertyStateFields | CompFields)[] = [];
    const active: { [key in PropertyStateFields | CompFields]?: boolean } = {};
    const inactive: { [key in PropertyStateFields | CompFields]?: boolean } =
      preferredInactive || {};
    // Columns that were added to the product after the user set their preferences
    // This feature is unused now but could be used to highlight new features for users.
    const newCols: { [key in PropertyStateFields | CompFields]?: boolean } = {};

    if (!isInitialLoading) {
      preferredOrder?.forEach((compField: PropertyStateFields | CompFields) => {
        order.push(compField);
        active[compField] = true;
      });
      // Include any columns that were added to the product after preferences were set
      (compType === CompTypes.Rental
        ? RENTAL_COMP_FIELD_ORDER_TABLE
        : COMP_FIELD_ORDER_TABLE
      ).forEach((compField) => {
        if (!active[compField] && !inactive[compField]) {
          order.push(compField);
          newCols[compField] = true;
          active[compField] = true;
        }
      });
    }
    if (!isInitialLoading) {
      return {
        ...mergedUserOrgUiPreferences,
        data: { order, active, inactive, newCols },
      };
    } else {
      return {
        ...mergedUserOrgUiPreferences,
        data: undefined,
      };
    }
  }, [data, compType]);
};
