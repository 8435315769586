import React from 'react';

import { ErrorIcon } from '@hcs/design-system';
import { DexpJob } from '@hcs/types';
import { formatDateLong, formatPercent } from '@hcs/utils';

export const infoSectionItems: {
  key: string;
  title: string;
  content: (dexpJob: DexpJob) => string | number;
  Icon?: JSX.Element;
}[] = [
  {
    key: 'total-errors',
    title: 'Total errors',
    content: (dexpJob: DexpJob) =>
      dexpJob.dataPointsTotal - dexpJob.dataPointsCompleted,
    Icon: <ErrorIcon size="sm" color="error-10" />,
  },
  {
    key: 'endpoints-requested',
    title: 'Endpoints requested',
    content: (dexpJob: DexpJob) => dexpJob.endpointsCount,
  },
  {
    key: 'properties-submitted',
    title: 'Properties submitted',
    content: (dexpJob: DexpJob) => dexpJob.addressesCount,
  },
  {
    key: 'success-rate',
    title: 'Success rate',
    content: (dexpJob: DexpJob) =>
      formatPercent(dexpJob.dataPointsCompleted / dexpJob.dataPointsTotal),
  },
  /**
   * TODO: Add run-by once BE implements response data
   * {
        key: 'run-by',
        title: 'Run by',
        content: (dexpJob: DexpJob) => dexpJob.ownerEmail
      },
    */
  {
    key: 'run-on',
    title: 'Run on',
    content: (dexpJob: DexpJob) => formatDateLong(dexpJob.createdAt),
  },
];
