import React from 'react';

import { DownloadIcon } from '@hcs/design-system';
import { ReportSummaryCellContentProps } from '@hcs/types';

import { DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL } from '../../../constants/ReportSummary.constants';
import { useReportSummaryCsv } from '../../../hooks/useReportSummaryCsv';

import { createReportSummaryTableCellConfig } from './ReportSummaryTableCell';

import styles from './Download.module.css';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL}-download-spreadsheet`;
const CellContentComponent = ({ order }: ReportSummaryCellContentProps) => {
  const { mutate: downloadReport } = useReportSummaryCsv();
  const fileName = `${order.name.replace(/\s|\\|\//g, '-')}.csv`;
  return (
    <div
      onClick={() => downloadReport({ orderId: order.id, fileName })}
      data-hc-name={dataHcName}
      className={styles.Download}
    >
      <DownloadIcon dataHcName={`${dataHcName}-download-icon`} />
      <div>Download Spreadsheet</div>
    </div>
  );
};

export const REPORT_SUMMARY_DOWNLOAD_CONFIG =
  createReportSummaryTableCellConfig({
    CellContentComponent,
    tableHeaderName: '',
  });
