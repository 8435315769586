import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ExperienceFlagsDevtoolSliceState,
  ExperienceFlagsType,
} from '@hcs/types';

const initialState: ExperienceFlagsDevtoolSliceState = {};
export const experienceFlagsSlice = createSlice({
  name: 'experienceFlags',
  initialState,
  reducers: {
    experienceFlagSet(
      state,
      action: PayloadAction<{
        type: keyof ExperienceFlagsType;
        value: boolean;
      }>
    ) {
      state[action.payload.type] = action.payload;
      return state;
    },
    experienceFlagReset(
      state,
      action: PayloadAction<{
        type: keyof ExperienceFlagsType;
      }>
    ) {
      delete state[action.payload.type];
      return state;
    },
    experienceFlagsResetAll(state) {
      state = {};
      return state;
    },
  },
});
