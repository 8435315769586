import { gql } from 'graphql-request';

export const FRAGMENT_POPULATION = gql`
  fragment PopulationCore on Population {
    month
    population1YearAgo
    population1YearChange
    population1YearRatio
  }
`;

export const FRAGMENT_POPULATION_TIME_SERIES = gql`
  fragment PopulationTimeSeries on PopulationTimeSeriesElement {
    month
    population
  }
`;

export const FRAGMENT_UNEMPLOYMENT_RATE_TIME_SERIES = gql`
  fragment UnemploymentRateTimeSeries on UnemploymentRateTimeSeriesElement {
    month
    unemploymentRate
  }
`;
