import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Input } from '@hcs/design-system';
import { DexpTemplateFormValues } from '@hcs/types';

export interface CommonInputProps {
  required?: boolean;
  className?: string;
}
export const SaveAnalysisNameInput = (props: CommonInputProps) => {
  const dataHcName = 'name-field';
  const { required, className } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext<DexpTemplateFormValues>();
  return (
    <Controller
      name="name"
      control={control}
      render={({ field }) => {
        return (
          <Input
            dataHcName={`${dataHcName}-input`}
            placeholder={'Template Name'}
            className={className}
            {...field}
            required={required}
            value={field.value || ''}
            error={errors.name?.message}
          />
        );
      }}
    />
  );
};
