import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ViewOrderApi } from '../api/viewOrderApi';

import { QUERY_KEY_ORDER } from './useOrder';
import { QUERY_KEY_ORDER_ITEMS } from './useOrderItems';

interface Params {
  orderId: number;
  orderItemId: string;
}

export const useCancelOrderItem = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, Params>(
    async ({ orderId, orderItemId }) => {
      return await ViewOrderApi.cancelOrderItem(orderId, orderItemId);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEY_ORDER_ITEMS,
          variables.orderId,
        ]);
        queryClient.invalidateQueries([QUERY_KEY_ORDER, variables.orderId]);
      },
    }
  );
};
