import { useContext, useEffect } from 'react';

import { useComponentInternalId } from '@hcs/hooks';
import { ReportEvent } from '@hcs/types';

import { ReportApiEventsContext } from '../context';

export const useSubscribeToReportEvents = (config: {
  callbackId: string; // unique id for the onMessage callback
  /*
   * onMessage: callback to handle report messages
   * note that if multiple hooks subscribing with the same callback id are rendered on the same page,
   * the last in callback will win and the others won't be called
   * so if you have local state (for example useState variables) you should not rely on it getting updated
   * (unless each callback has it's own id)
   */
  onMessage: (reportEvent: ReportEvent) => void;
}) => {
  const { callbackId, onMessage } = config;
  // Internal Component Id used to identify the component that is
  // subscribed to the websocket updates
  const internalComponentId = useComponentInternalId();

  const { subscribeToReportEvents, unsubscribeFromReportEvents } = useContext(
    ReportApiEventsContext
  );

  useEffect(() => {
    subscribeToReportEvents({
      callbackId,
      componentId: internalComponentId,
      onMessage,
    });
  }, [callbackId, internalComponentId, onMessage, subscribeToReportEvents]);

  return () => {
    unsubscribeFromReportEvents({
      callbackId,
      componentId: internalComponentId,
    });
  };
};
