import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OrderTypeDescriptor } from '@hcs/types';

import { NewOrderApi } from '../api/newOrderApi';

const QUERY_KEY_ORDER_TYPES = 'order-types';
export const useOrderTypes = () =>
  useQuery<OrderTypeDescriptor[], AxiosError>(
    [QUERY_KEY_ORDER_TYPES],
    NewOrderApi.orderTypes
  );
