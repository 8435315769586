import { useSearchParams } from 'react-router-dom';

import { DateStr } from '@hcs/types';
import { ReportConfigSlugs } from '@hcs/types';
import { AppraisalCompareCompInput } from '@hcs/types';
import { isDateStr } from '@hcs/utils';

import { ReportPreviewSearchParams } from '../features/ReportPreview';

export const useReportPreviewInputs = (): {
  reportConfigSlug: ReportConfigSlugs;
  clientId: string | undefined;
  effectiveDate:
    | {
        isValid: true;
        value: DateStr;
      }
    | {
        isValid: false;
        value: string | undefined;
      };

  appraisalComps: AppraisalCompareCompInput[] | undefined;
  appraisalSubjectValue: number;
} => {
  const [searchParams] = useSearchParams();
  const effectiveDate =
    searchParams.get(ReportPreviewSearchParams.EffectiveDate) || undefined;
  const appraisalCompsParam = searchParams.get(
    ReportPreviewSearchParams.AppraisalComps
  );
  // Appraisal Comps stored in search params as hcAddressId:value,hcAddressId:value
  // TODO: Remove this code if appraisal comparison UI does not become part of the product
  const appraisalComps: AppraisalCompareCompInput[] | undefined =
    appraisalCompsParam
      ? appraisalCompsParam.split(',').map((v) => {
          const vt = v as `${string}:${string}`;
          const [hcAddressIdStr, valueStr] = vt.split(':') as [string, string];
          return {
            cerberusInput: { hcAddressId: Number(hcAddressIdStr) },
            value: Number(valueStr),
          };
        })
      : undefined;
  const reportConfigSlug = (searchParams.get(
    ReportPreviewSearchParams.ReportConfigSlug
  ) || ReportConfigSlugs.PexpDynamic) as ReportConfigSlugs;

  const appraisalSubjectValueParam = searchParams.get(
    ReportPreviewSearchParams.AppraisalSubjectValue
  );
  const appraisalSubjectValue = Number(appraisalSubjectValueParam) || 0;
  return {
    reportConfigSlug,
    effectiveDate:
      reportConfigSlug === ReportConfigSlugs.PexpEffectiveDateDynamic &&
      effectiveDate &&
      isDateStr(effectiveDate)
        ? {
            isValid: true,
            value: effectiveDate,
          }
        : {
            isValid: false,
            value: effectiveDate,
          },
    clientId: searchParams.get(ReportPreviewSearchParams.ClientId) || undefined,
    appraisalSubjectValue,
    appraisalComps,
  };
};
