import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import {
  useNavigateToPlatformLanding,
  VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM,
} from '@hcs/hc-products';
import { SelfServeStatus } from '@hcs/self-service';
import { createRedirectQuerystring } from '@hcs/utils';

import { SolutionsPlatformGlobalHeader } from '../../navigation/SolutionsPlatformGlobalHeader';

export const SelfServeStatusRedirect = ({
  children,
}: {
  children: ReactNode;
}) => {
  const redirectQuery = createRedirectQuerystring();
  const navigateToPlatformLanding = useNavigateToPlatformLanding();
  return (
    <SelfServeStatus
      header={<SolutionsPlatformGlobalHeader />}
      checkoutInProgress={
        <Navigate to={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.CHECKOUT} />
      }
      checkoutAbandonded={
        <Navigate to={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.SELECT_PLAN} />
      }
      error401={
        <Navigate
          to={`${VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGIN}${redirectQuery}`}
          replace
        />
      }
      onConfirmUserSuccess={navigateToPlatformLanding}
    >
      {children}
    </SelfServeStatus>
  );
};
