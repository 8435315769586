import accounting from 'accounting';

import { FilterRangeType, FORMAT_TYPES, RANGE_TYPES } from '@hcs/design-system';
import { getCompFieldConfig } from '@hcs/property-state';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyTypeEnum } from '@hcs/types';
import { CompFields, PropertyStateFields } from '@hcs/types';
import { SavedCompFilterSet } from '@hcs/types';
import {
  CompFiltersAll,
  FILTER_MATCH_SUBJECT,
  FilterTypeNumberRange,
} from '@hcs/types';
import { ListingStatusNormalized } from '@hcs/types';
import {
  formatListingStatusNormalized,
  formatMissing,
  formatMoney,
  formatNumber,
  formatNumberAbbrev,
  formatPercent,
  formatPropertyType,
  NULL_VALUE,
} from '@hcs/utils';
import { isPositive } from '@hcs/utils';
import { capitalizeFirstLetter } from '@hcs/utils';

export const undefinedNullOrEmptyString = (value?: string | null): boolean => {
  return value === undefined || value === null || value.length === 0;
};

export const relativeSubjectFormatter = (
  isPercentage: boolean,
  value?: string | null | number
): string => {
  const newVal = !value
    ? undefined
    : isPercentage
    ? formatPercent(typeof value === 'string' ? parseFloat(value) : value)
    : value;
  return `${Number(value) > 0 ? '+' : ''}${formatMissing(newVal)} from subject`;
};

export const formatOrUndefined = (
  formatter: (value: string, options?: accounting.NumberSettings) => string,
  value: FilterRangeType,
  options?: accounting.NumberSettings
): FilterRangeType => {
  return {
    [RANGE_TYPES.MIN]: undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN])
      ? undefined
      : formatter(value?.[RANGE_TYPES.MIN] || '', options),
    [RANGE_TYPES.MAX]: undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX])
      ? undefined
      : formatter(value?.[RANGE_TYPES.MAX] || '', options),
  };
};

export const formatStripFormatting = (value: string): string => {
  return value.replace(/(sqft\.|m|k|\$|,)/gi, '').trim();
};

export const subjectDisplay = (
  isPercentage: boolean,
  value?: FilterRangeType
): FilterRangeType => {
  return {
    [RANGE_TYPES.MIN]: relativeSubjectFormatter(
      isPercentage,
      value?.[RANGE_TYPES.MIN]
    ),
    [RANGE_TYPES.MAX]: relativeSubjectFormatter(
      isPercentage,
      value?.[RANGE_TYPES.MAX]
    ),
  };
};

export const getFilterLabel = ({
  field,
  value,
}: {
  field: PropertyStateFields;
  value?: FilterRangeType;
}) => {
  const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[field];
  if (
    value &&
    !undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN]) &&
    undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX])
  ) {
    return `${value?.[RANGE_TYPES.MIN]}+ ${fieldConfig.labelShort}`;
  }
  if (
    value &&
    undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN]) &&
    !undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX])
  ) {
    return `Up to ${value?.[RANGE_TYPES.MAX]} ${fieldConfig.labelShort}`;
  }
  if (
    !value ||
    (undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN]) &&
      undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX]))
  ) {
    return `${fieldConfig.labelShort}`;
  }
  if (value?.[RANGE_TYPES.MIN] === value?.[RANGE_TYPES.MAX]) {
    return `${value?.[RANGE_TYPES.MIN]} ${fieldConfig.labelShort}`;
  }
  return `${value?.[RANGE_TYPES.MIN]}-${value?.[RANGE_TYPES.MAX]} ${
    fieldConfig.labelShort
  }`;
};

export const convertNumArrayToFilterRange = (
  val?: (null | number)[] | null
): FilterRangeType => {
  return {
    [RANGE_TYPES.MIN]:
      val?.[0] === undefined || val?.[0] === null ? undefined : `${val?.[0]}`,
    [RANGE_TYPES.MAX]:
      val?.[1] === undefined || val?.[1] === null ? undefined : `${val?.[1]}`,
  };
};

//Simple wrapper for formatNumber to fulfill formatter contract
const formatComma = (
  value?: string,
  options?: accounting.NumberSettings
): string => {
  return formatNumber(Number(value), options);
};

export const formatMoneyStr = (
  value: string,
  options?: accounting.NumberSettings
): string => {
  return formatMoney(Number(value), options);
};

export const formatMoneyAbbrStr = (value: string): string => {
  return `$${formatNumberAbbrev(Number(formatStripFormatting(value)))}`;
};

export const formatSqrFt = (
  value?: string,
  options?: accounting.NumberSettings
): string => {
  return `${formatComma(value, options)} ft²`;
};

const formatDecimalNumber = (value: string | null) => {
  return `${Number(value)?.toFixed(2)}`;
};

export const formatValue = (
  value: FilterRangeType,
  decimal: boolean,
  formatType: FORMAT_TYPES
): FilterRangeType => {
  const cleanValue: FilterRangeType = formatOrUndefined(
    formatStripFormatting,
    value
  );
  const options = decimal ? { precision: 2 } : { precision: 0 };
  switch (formatType) {
    case FORMAT_TYPES.COMMA:
      return formatOrUndefined(
        //Simple wrapper for formatNumber to fulfill formatter contract
        (value?: string, options?: accounting.NumberSettings): string => {
          return formatNumber(Number(value), options);
        },
        cleanValue,
        options
      );
    case FORMAT_TYPES.DECIMAL_NUMBER:
      return formatOrUndefined(formatDecimalNumber, cleanValue);
    case FORMAT_TYPES.MONEY:
      return formatOrUndefined(formatMoneyStr, cleanValue, options);
    default: {
      // number type return as is
      return value;
    }
  }
};

export const validateRange = (value: FilterRangeType): string | undefined => {
  const types: RANGE_TYPES[] = [RANGE_TYPES.MAX, RANGE_TYPES.MIN];
  for (const type in types) {
    if (value[type as RANGE_TYPES] && isNaN(Number(value[type as RANGE_TYPES])))
      return `${capitalizeFirstLetter(type as string)} must be a number`;
    if (
      value[type as RANGE_TYPES] &&
      !isPositive(Number(value[type as RANGE_TYPES]))
    )
      return `${capitalizeFirstLetter(type as string)} must be positive`;
  }
  if (Number(value[RANGE_TYPES.MIN]) > Number(value[RANGE_TYPES.MAX])) {
    return 'There is a problem with this filter! The minimum value is greater than the maximum value.';
  }

  return undefined;
};

export const isNumberOrDecimalPoint = (
  val: string,
  decimalAllowed: boolean
) => {
  val = val.replace(/,/g, ' ').replace(/\$/g, '');
  if ((val.match(/\./g) || []).length > (decimalAllowed ? 1 : 0)) {
    return false; //too many decimal points
  }
  if (decimalAllowed && val.length > 0 && val.charAt(val.length - 1) === '.') {
    val = val.slice(0, val.length - 1);
  }
  return !isNaN(Number(val));
};

export const testForCommaLastChar = (newValue: FilterRangeType): boolean => {
  if (RANGE_TYPES.MIN in newValue) {
    return newValue[RANGE_TYPES.MIN]?.slice(-1) === ',';
  }
  return newValue[RANGE_TYPES.MAX]?.slice(-1) === ',';
};

export const formatRangeYearBuilt = (
  value?: (number | undefined | null)[] | null
): string => {
  if (
    !value ||
    ((!value?.[0] || value?.[0] === 0) && (!value?.[1] || value?.[1] === 0))
  ) {
    return 'Year Built';
  }
  const currentYear = new Date().getFullYear();
  if (value && value[0] && value[0] !== 0 && (!value[1] || value[1] === 0)) {
    return `${value[0]} - ${currentYear} Year Built`;
  }
  if (value && (!value[0] || value[0] === 0) && value[1] && value[1] !== 0) {
    return `Up to ${value[1]} Year Built`;
  }
  return `${value?.[0]}-${value?.[1]} Year Built`;
};

export const formatPlusMinus = (
  v: number | null | undefined,
  formatter: (v: number | null | undefined) => string
) => {
  return v == null
    ? NULL_VALUE
    : v < 0
    ? `-${formatter(Math.abs(v))}`
    : `+${formatter(v)}`;
};

export const formatRelativeNumberRange = ({
  field,
  relativeValue,
}: FilterTypeNumberRange) => {
  const filterLabel = PROPERTY_STATE_FIELD_CONFIGS[field].labelShort;
  const formatter =
    field === PropertyStateFields.bathrooms
      ? formatMissing
      : field === PropertyStateFields.lastClosePrice ||
        field === PropertyStateFields.lastClosePriceRental ||
        field === PropertyStateFields.currentListingPrice ||
        field === PropertyStateFields.currentListingPriceRental ||
        field === PropertyStateFields.livingArea ||
        field === PropertyStateFields.lotSize
      ? formatPercent
      : formatNumber;

  if (relativeValue) {
    if (relativeValue[0] == null) {
      return `Up to ${formatPlusMinus(
        relativeValue[1],
        formatter
      )} ${filterLabel} from Subject`;
    } else if (relativeValue[1] == null) {
      return `At least ${formatPlusMinus(
        relativeValue[0],
        formatter
      )} ${filterLabel} from Subject`;
    } else if (relativeValue[0] === 0 && relativeValue[1] === 0) {
      return `Match Subject ${filterLabel}`;
    } else if (Math.abs(relativeValue[0]) === Math.abs(relativeValue[1])) {
      return `+/-${formatter(
        Math.abs(relativeValue[0])
      )} ${filterLabel} from Subject`;
    } else {
      return `${formatPlusMinus(
        relativeValue[0],
        formatter
      )} to ${formatPlusMinus(
        relativeValue[1],
        formatter
      )} ${filterLabel} from Subject`;
    }
  }
  return NULL_VALUE;
};

export const formatRelativeMonthsAgo = ({
  field,
  relativeValue,
}:
  | CompFiltersAll[PropertyStateFields.lastCloseDate]
  | CompFiltersAll[PropertyStateFields.lastCloseDateRental]
  | CompFiltersAll[PropertyStateFields.currentListDate]
  | CompFiltersAll[PropertyStateFields.currentListDateRental]) => {
  const label = PROPERTY_STATE_FIELD_CONFIGS[field].labelShort;
  if (!relativeValue) return NULL_VALUE;
  if (relativeValue >= 12) {
    const years = Math.round(relativeValue / 12);
    return `${label} Last ${years} year${years === 1 ? '' : 's'}`;
  } else {
    return `${label} Last ${relativeValue} month${
      relativeValue === 1 ? '' : 's'
    }`;
  }
};

export const formatRelativeMatchRange = ({
  field,
  relativeValue,
}:
  | CompFiltersAll[PropertyStateFields.garageSpaces]
  | CompFiltersAll[PropertyStateFields.stories]) => {
  const label = PROPERTY_STATE_FIELD_CONFIGS[field].labelShort;
  if (relativeValue === FILTER_MATCH_SUBJECT) {
    return `Match Subject ${label}`;
  } else if (relativeValue?.[0] === 0 && relativeValue[1] === 0) {
    return `No ${label}`;
  } else if (relativeValue?.[0] === 1 && relativeValue[1] === null) {
    return `Has ${label}`;
  } else if (relativeValue?.[0] === 1 && relativeValue[1] === 1) {
    return `Single ${label}`;
  } else if (relativeValue?.[0] === 2 && relativeValue[1] === null) {
    return `Multi ${label}`;
  }
  return NULL_VALUE;
};

export const formatRelativeMatchBoolean = ({
  field,
  relativeValue,
}:
  | CompFiltersAll[PropertyStateFields.basementHas]
  | CompFiltersAll[PropertyStateFields.pool]) => {
  const label = PROPERTY_STATE_FIELD_CONFIGS[field].labelShort;
  if (relativeValue === FILTER_MATCH_SUBJECT) {
    return `Match Subject ${label}`;
  } else if (relativeValue) {
    return `Has ${label}`;
  } else {
    return `No ${label}`;
  }
};

export const formatRelativeMatchOrExact = ({
  field,
  relativeValue,
}:
  | CompFiltersAll[PropertyStateFields.currentStatus]
  | CompFiltersAll[PropertyStateFields.currentStatusRental]
  | CompFiltersAll[PropertyStateFields.propertyType]) => {
  const { labelShort } = PROPERTY_STATE_FIELD_CONFIGS[field];
  if (relativeValue === FILTER_MATCH_SUBJECT) {
    return `Match Subject ${labelShort}`;
  } else if (relativeValue?.length) {
    if (field === PropertyStateFields.propertyType) {
      return `${(relativeValue as PropertyTypeEnum[])
        .map(formatPropertyType)
        .join(', ')} ${labelShort}`;
    } else {
      return `${(relativeValue as ListingStatusNormalized[])
        .map(formatListingStatusNormalized)
        .join(', ')} ${labelShort}`;
    }
  } else {
    return NULL_VALUE;
  }
};

export const formatRelativeExact = ({
  field,
  relativeValue,
}: CompFiltersAll[CompFields.similarity]) => {
  const { labelShort } = getCompFieldConfig(field);
  if (relativeValue?.length) {
    return `${relativeValue
      .map(capitalizeFirstLetter)
      .join(', ')} ${labelShort}`;
  } else {
    return NULL_VALUE;
  }
};

export const formatRelativeFilterValue = (
  compFilterValue: CompFiltersAll[keyof CompFiltersAll]
): string => {
  if (!compFilterValue) {
    return NULL_VALUE;
  }
  if (
    // Number Range
    compFilterValue.field === PropertyStateFields.bathrooms ||
    compFilterValue.field === PropertyStateFields.bedrooms ||
    compFilterValue.field === PropertyStateFields.yearBuilt ||
    compFilterValue.field === PropertyStateFields.lastClosePrice ||
    compFilterValue.field === PropertyStateFields.lastClosePriceRental ||
    compFilterValue.field === PropertyStateFields.currentListingPrice ||
    compFilterValue.field === PropertyStateFields.currentListingPriceRental ||
    compFilterValue.field === PropertyStateFields.livingArea ||
    compFilterValue.field === PropertyStateFields.lotSize
  ) {
    return formatRelativeNumberRange(compFilterValue);
  } else if (
    compFilterValue.field === PropertyStateFields.lastCloseDate ||
    compFilterValue.field === PropertyStateFields.lastCloseDateRental ||
    compFilterValue.field === PropertyStateFields.currentListDate ||
    compFilterValue.field === PropertyStateFields.currentListDateRental
  ) {
    return formatRelativeMonthsAgo(compFilterValue);
  } else if (
    compFilterValue.field === PropertyStateFields.garageSpaces ||
    compFilterValue.field === PropertyStateFields.stories
  ) {
    return formatRelativeMatchRange(compFilterValue);
  } else if (
    compFilterValue.field === PropertyStateFields.basementHas ||
    compFilterValue.field === PropertyStateFields.pool
  ) {
    return formatRelativeMatchBoolean(compFilterValue);
  } else if (
    compFilterValue.field === PropertyStateFields.propertyType ||
    compFilterValue.field === PropertyStateFields.currentStatus ||
    compFilterValue.field === PropertyStateFields.currentStatusRental
  ) {
    return formatRelativeMatchOrExact(compFilterValue);
  } else if (compFilterValue.field === CompFields.similarity) {
    return formatRelativeExact(compFilterValue);
  } else if (compFilterValue.field === CompFields.distance) {
    return `${formatMissing(compFilterValue.relativeValue)} Miles`;
  }
  return NULL_VALUE;
};

export const isAppliedFilterNotSavable = (
  appliedFilterValue: CompFiltersAll[keyof CompFiltersAll]
) =>
  appliedFilterValue.relativeValue === null ||
  appliedFilterValue.relativeValue === undefined ||
  (Array.isArray(appliedFilterValue.relativeValue) &&
    !appliedFilterValue.relativeValue.some((v) => v !== null));

export const convertAppliedFiltersToSavedFilterSetValues = (
  appliedFilters: Partial<CompFiltersAll>
): SavedCompFilterSet['values'] => {
  const values: SavedCompFilterSet['values'] = {};
  Object.entries(appliedFilters).forEach((entry) => {
    const compFilterValue = entry[1];
    if (
      compFilterValue.field &&
      compFilterValue.relativeValue != null &&
      !isAppliedFilterNotSavable(compFilterValue) &&
      compFilterValue.error?.field !== 'relativeValue'
    ) {
      // This is incredibly ugly but I can't get this to pass type-checking
      // without explicitly checking each possible field.
      // Applied filter is saveable if there is a relative value
      if (compFilterValue.field === PropertyStateFields.basementHas) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.bathrooms) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.bedrooms) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.lastCloseDate) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (
        compFilterValue.field === PropertyStateFields.lastCloseDateRental
      ) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.lastClosePrice) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (
        compFilterValue.field === PropertyStateFields.lastClosePriceRental
      ) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.garageSpaces) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.livingArea) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (
        compFilterValue.field === PropertyStateFields.currentListDate
      ) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (
        compFilterValue.field === PropertyStateFields.currentListDateRental
      ) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (
        compFilterValue.field === PropertyStateFields.currentListingPrice
      ) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (
        compFilterValue.field === PropertyStateFields.currentListingPriceRental
      ) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.lotSize) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.pool) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.currentStatus) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (
        compFilterValue.field === PropertyStateFields.currentStatusRental
      ) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.propertyType) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.stories) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.yearBuilt) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === CompFields.distance) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === CompFields.similarity) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      } else if (compFilterValue.field === PropertyStateFields.condition) {
        values[compFilterValue.field] = {
          field: compFilterValue.field,
          relativeValue: compFilterValue.relativeValue,
        };
      }
    }
  });
  return values;
};
