import { useLocalStorageState } from '@hcs/hooks';
import { EngagementTrackingDevToolState } from '@hcs/types';

export const useEngagementTrackingDevToolState = () => {
  const localStorageState = useLocalStorageState('engagementTrackingDevTool', [
    'logEvents',
    'outlineSections',
  ]);
  const state: EngagementTrackingDevToolState = {
    logEvents:
      localStorageState.state.logEvents === 'false'
        ? false
        : localStorageState.state.logEvents === 'all'
        ? 'all'
        : 'meaningful',
    outlineSections: localStorageState.state.outlineSections === 'true',
  };
  return {
    state,
    actions: {
      setOutlineSections: (value: boolean) => {
        localStorageState.actions.setItem(
          'outlineSections',
          value ? 'true' : 'false'
        );
      },
      setLogEvents: (value: 'all' | 'meaningful' | false) => {
        localStorageState.actions.setItem(
          'logEvents',
          !value ? 'false' : value
        );
      },
    },
  };
};
