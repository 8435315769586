import React from 'react';

import styles from './BuyBoxSaveUnexpectedError.module.css';

interface BuyBoxSaveUnexpectedErrorProps {
  dataHcName: string;
  className?: string;
}
export const BuyBoxSaveUnexpectedError = (
  props: BuyBoxSaveUnexpectedErrorProps
) => {
  const { className, dataHcName } = props;
  return (
    <div data-hc-name={dataHcName} className={className}>
      <div className={styles.MessageSection}>
        We&apos;re sorry, but there&apos;s been an error and your changes have
        not been saved. Our team has been alerted to this issue.
      </div>
      <div>
        By clicking below, you will be taken back to the previous page where you
        will be given the opportunity to try again.
      </div>
    </div>
  );
};
