import React from 'react';

import { Anchor } from '@hcs/design-system';
import { VR_SHARE_URL } from '@hcs/urls';

interface MlsSheetInputs {
  hcAddressId: number;
  mlsNumber: string;
  mlsId: number;
}
interface Props extends MlsSheetInputs {
  className?: string;
  onClick?: (mlsSheetInputs: MlsSheetInputs) => void;
}
const dataHcName = 'download-mls-sheet-button';
export const DownloadMlsSheetLink = ({
  hcAddressId,
  mlsId,
  mlsNumber,
  className,
  onClick,
}: Props) => {
  const url = `${VR_SHARE_URL}/emporium/vrshare/mls/listing/${hcAddressId}/${mlsNumber}/${mlsId}/MLS Sheet`;
  return (
    <Anchor
      onClick={() => onClick?.({ hcAddressId, mlsId, mlsNumber })}
      href={url}
      type="application/pdf"
      target="_blank"
      rel="noreferrer"
      dataHcName={dataHcName}
      className={className}
    >
      MLS Sheet
    </Anchor>
  );
};
