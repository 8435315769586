import React, { ReactNode } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { PrivateApplicationPage } from '@hcs/auth';
import { VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM } from '@hcs/hc-products';
import { createRedirectQuerystring } from '@hcs/utils';
import { URL_PARAM_NO_REDIRECT } from '@hcs/webapps';

import { PLATFORM_ROOT_PATH } from '../../apps/platform/routes';

// Creates a page component that is accessible to anyone with access to the application
export const PrivateApplicationPageRedirect = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const redirect =
    searchParams.get(URL_PARAM_NO_REDIRECT) === 'true'
      ? ''
      : createRedirectQuerystring();
  return (
    <PrivateApplicationPage
      unauthorizedRender={<Navigate to={PLATFORM_ROOT_PATH} replace />}
      unauthenticatedRender={
        <Navigate
          to={`${VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGIN}${redirect}`}
          replace
        />
      }
    >
      {children}
    </PrivateApplicationPage>
  );
};
