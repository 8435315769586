export const HCS_DEV_TOOLS_QUERY_SELECTOR = '#hcs-dev-tools-portal';
export const HCS_DEV_TOOLS_ID = HCS_DEV_TOOLS_QUERY_SELECTOR.replace('#', '');
export const PETER_MESSAGES = [
  'Hello there!',
  'Can I interest you in some devtools?',
  'Whatchu lookin at?',
  'I see you!',
  'Ouch! You poked my eye!',
  'Did you file a ticket for that?',
  'Back again I see...',
  "I bet you're investigating a bug I found",
  'How many refreshes does it take to find the bug?',
  'You must be truly desperate to come to me for help.',
  'How did you find me?'
];
