import React from 'react';
import { AxiosError } from 'axios';

import { TwoColumnTable } from '@hcs/design-system';

import { BuyBoxSaveUnexpectedError } from '../../components/BuyBoxSaveUnexpectedError';
import { BUY_BOX_RANGE_FIELD_LABELS } from '../../constants';
import { useBuyBoxMap } from '../../hooks/useBuyBoxMap';
import { BuyBoxBulkEditError } from '../../utils/api.utils';

import styles from './BulkEditSaveError.module.css';

interface BulkEditSaveErrorProps {
  className?: string;
  bulkEditError: BuyBoxBulkEditError | AxiosError | null;
}
const dataHcName = 'bulk-edit-save-error';
const dataHcNameUnexpectedError = 'bulk-edit-save-unexpected-error';
export const BulkEditSaveError = (props: BulkEditSaveErrorProps) => {
  const { className, bulkEditError } = props;
  const buyBoxMap = useBuyBoxMap();

  if (bulkEditError instanceof BuyBoxBulkEditError) {
    const errorBuyBox = buyBoxMap[bulkEditError.buyBoxId];
    const errorField = bulkEditError.field;
    const rows = [
      {
        label: 'Buy Box Name',
        value: errorBuyBox?.name,
      },
      {
        label: 'Field',
        value: BUY_BOX_RANGE_FIELD_LABELS[errorField],
      },
      {
        label: 'Message',
        value: bulkEditError.message,
      },
    ];
    return (
      <div data-hc-name={dataHcName} className={className}>
        <div className={styles.MessageSection}>
          We&apos;re sorry, but there&apos;s been an error and your changes have
          not been saved. The error occurred with:
        </div>
        <TwoColumnTable
          theme={{ TwoColumnTable: styles.TwoColumnTable, Row: styles.Row }}
          dataHcName={`${dataHcName}-error-table`}
          rows={rows}
        />
        <div>
          By clicking below, you will be taken back to the previous page where
          you will be given the opportunity to try again.
        </div>
      </div>
    );
  } else {
    return (
      <BuyBoxSaveUnexpectedError
        dataHcName={dataHcNameUnexpectedError}
        className={className}
      />
    );
  }
};
