import { useCallback, useMemo } from 'react';

import { CompFields, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useReportConfig } from '../hooks/useReportConfig';

type FieldType = PropertyStateFields | CompFields;

// Filters out fields that are not supported by the report config
export const usePropertyStateFieldsSupported = (
  reportId: ReportId,
  fields: FieldType[]
): FieldType[] => {
  const { data: reportConfig } = useReportConfig(reportId);
  const checkField = useCallback(
    (field: FieldType): boolean => {
      return !!reportConfig?.fieldsSupport[field];
    },
    [reportConfig?.fieldsSupport]
  );
  return useMemo(() => {
    return fields.filter(checkField);
  }, [checkField, fields]);
};
