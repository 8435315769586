import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
} from '@hcs/types';
import { formatMoney } from '@hcs/utils';
import { listingStatusNormalized } from '@hcs/utils';

import { CURRENT_STATUS_CONFIG } from '../../PropertyStateFields/currentStatus';

const FIELD = PropertyStateFields.currentPrice;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Market Price';
const labelShort = 'Market $';
const propertyStatePath: PropertyStatePaths = '/complexFieldsSale/currentPrice';
const info: FieldConfig['info'] =
  'The current list price for an actively marketed property or the sale price.';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  }
  return propertyState?.complexFieldsSale?.currentPrice;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatMoney(getValue(propertyStateArgs));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Price';
const formatValueShort: FieldConfig['formatValueShort'] = (propertyStateArgs) =>
  formatMoney(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)}`;

const labelDynamic: FieldConfig['labelDynamic'] = (propertyStateArgs) => {
  const listingStatus =
    listingStatusNormalized(
      CURRENT_STATUS_CONFIG.getValue(propertyStateArgs)
    ) === 'Closed'
      ? 'Closed'
      : 'List';
  return `${listingStatus} Price`;
};
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const CURRENT_PRICE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelDynamic,
  labelShort,
  propertyStatePath,
  getValue,
  labelMicro,
  formatValueShort,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  info,
};
