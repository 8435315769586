import React from 'react';

import {
  PropertyStateCerberusInput,
  TableProps,
  TableRowProps,
} from '@hcs/types';

import {
  CustomCellData,
  PropertyTable,
  PropertyTableProps,
} from '../../components/PropertyTable';
import { PropertyStateTableRow } from '../../features/PropertyStateTable/PropertyStateTableRow';

export interface PropertyStateTableDatum<D extends CustomCellData = undefined> {
  propertyStateCerberusInput: PropertyStateCerberusInput | undefined | null;
  customData?: D;
  rowProps?: TableRowProps;
  tableRowProps?: TableRowProps;
}
export interface PropertyStateTableProps<D extends CustomCellData = undefined>
  extends TableProps {
  data: PropertyStateTableDatum<D>[];
  fields: PropertyTableProps<D>['fields'];
  showAddress?: boolean;
}

export const PropertyStateTable = <D extends CustomCellData = undefined>({
  data,
  fields,
  children,
  showAddress = true,
  ...propertyTableProps
}: PropertyStateTableProps<D>) => {
  return (
    <PropertyTable
      {...propertyTableProps}
      fields={fields}
      showAddress={showAddress}
    >
      {children}
      {data.map(({ propertyStateCerberusInput, customData, tableRowProps }) => {
        const rowId = `${propertyStateCerberusInput?.cerberusInput?.hcAddressId}`;
        if (!propertyStateCerberusInput) return null;
        return (
          <PropertyStateTableRow
            {...tableRowProps}
            customData={customData}
            key={rowId}
            propertyStateCerberusInput={propertyStateCerberusInput}
            fields={fields}
            showAddress={showAddress}
          />
        );
      })}
    </PropertyTable>
  );
};
