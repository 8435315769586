import { Operation } from 'fast-json-patch';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import {
  CerberusStatsFilters,
  OrgReportPreferencesResponse,
  ReportPreferences,
  UiPreferences,
} from '@hcs/types';
import {
  CompTypes,
  DexpTemplates,
  FeatureFlagsData,
  OmAdminUserPrefs,
  SavedCompFilters,
} from '@hcs/types';
import { PREFERENCES_API_URL } from '@hcs/urls';
import { expect404 } from '@hcs/utils';

// All Huell endpoints: https://git.housecanary.net/Engineering/huell/-/blob/develop/huell/urls.py
const fetchUiPreferencesForUser = async () => {
  try {
    const response = await AxiosAccessTokenClientEhrm.get<UiPreferences>(
      `${PREFERENCES_API_URL}/merged_preferences/`
    );
    return response.data;
  } catch (err) {
    return expect404(err);
  }
};

const patchUiPreferencesForUser = async (operations: Operation[]) => {
  const response = await AxiosAccessTokenClientEhrm.patch<UiPreferences>(
    `${PREFERENCES_API_URL}/preferences/`,
    operations
  );
  return response.data;
};

const fetchReportPreferencesForUser = async () => {
  try {
    const response = await AxiosAccessTokenClientEhrm.get<ReportPreferences>(
      `${PREFERENCES_API_URL}/merged_report_preferences/`
    );
    return response.data;
  } catch (err) {
    return expect404(err);
  }
};

const patchReportPreferencesForUser = async (operations: Operation[]) => {
  const response =
    await AxiosAccessTokenClientEhrm.patch<OrgReportPreferencesResponse>(
      `${PREFERENCES_API_URL}/report_preferences/`,
      operations
    );
  return response.data;
};

const fetchSavedCompFilterSetsUser = async (compType: CompTypes) => {
  try {
    const response = await AxiosAccessTokenClientEhrm.get<SavedCompFilters>(
      `${PREFERENCES_API_URL}/merged_comp_filters/?compType=${compType}`
    );
    return response.data;
  } catch (err) {
    return expect404(err);
  }
};

const patchSavedCompFilterSetsForUser = async ({
  compType,
  operations,
}: {
  compType: CompTypes;
  operations: Operation[];
}) => {
  const response = await AxiosAccessTokenClientEhrm.patch<SavedCompFilters>(
    `${PREFERENCES_API_URL}/comp_filters/?compType=${compType}`,
    operations
  );
  return response.data;
};

const fetchFeatureFlagsForUser = async () => {
  const response = await AxiosAccessTokenClientEhrm.get<FeatureFlagsData>(
    `${PREFERENCES_API_URL}/emporium/feature_flag/user_flags/`
  );
  return response.data;
};

const fetchOmAdminPrefsForUser = async () => {
  try {
    const response = await AxiosAccessTokenClientEhrm.get<OmAdminUserPrefs>(
      `${PREFERENCES_API_URL}/agile_ops_preferences/`
    );
    return response.data;
  } catch (error) {
    return expect404(error);
  }
};

const patchOmAdminPrefsForUser = async ({
  operations,
}: {
  operations: Operation[];
}) => {
  const response = await AxiosAccessTokenClientEhrm.patch<OmAdminUserPrefs>(
    `${PREFERENCES_API_URL}/agile_ops_preferences`,
    operations
  );
  return response.data;
};

const fetchCerberusStatsFiltersForUser = async () => {
  try {
    const response = await AxiosAccessTokenClientEhrm.get<CerberusStatsFilters>(
      `${PREFERENCES_API_URL}/cerberus_stats_filters`
    );
    return response.data;
  } catch (err) {
    return expect404(err);
  }
};

const patchCerberusStatsFiltersForUser = async (operations: Operation[]) => {
  const response = await AxiosAccessTokenClientEhrm.patch<CerberusStatsFilters>(
    `${PREFERENCES_API_URL}/cerberus_stats_filters`,
    operations
  );
  return response.data;
};

export const HuellUserPreferencesApi = {
  // UI Preferences
  fetchUiPreferencesForUser,
  patchUiPreferencesForUser,
  // Report Preferences
  fetchReportPreferencesForUser,
  patchReportPreferencesForUser,
  // Saved Comp Filters
  fetchSavedCompFilterSetsUser,
  patchSavedCompFilterSetsForUser,
  // Feature Flags
  fetchFeatureFlagsForUser,
  // Om Admin Prefs
  fetchOmAdminPrefsForUser,
  patchOmAdminPrefsForUser,
  // Cerberus stats filters
  fetchCerberusStatsFiltersForUser,
  patchCerberusStatsFiltersForUser,
  // DEXP Templates
  fetchDexpTemplatesForUser: async () => {
    try {
      const response = await AxiosAccessTokenClientEhrm.get<DexpTemplates>(
        `${PREFERENCES_API_URL}/merged_dexp_templates/`
      );
      return response.data;
    } catch (err) {
      return expect404(err);
    }
  },
  patchDexpTemplatesForUser: async (operations: Operation[]) => {
    const response = await AxiosAccessTokenClientEhrm.patch<DexpTemplates>(
      `${PREFERENCES_API_URL}/dexp_templates/`,
      operations
    );
    return response.data;
  },
};
