import React, { useMemo, useState } from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { DirectionalChevron } from '@hcs/design-system';
import { DownloadIcon } from '@hcs/design-system';
import { CloseIcon } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmOrder } from '@hcs/types';

import {
  DATA_HC_NAME_ORDERS_TABLE_CELL,
  ORDER_STATUSES,
} from '../../../constants';
import { useCancelOrder, useOrderPdfDownload } from '../../../hooks';
import { CancelOrderDialog } from '../../CancelOrderDialog';
import { DownloadAllDrawer } from '../../DownloadAllDrawer';
import OrderHint from '../OrderHint/OrderHint';
import { orderNeedReview } from '../utils';

import { OrdersTableCellsDisplayProps } from '.';

import styles from './Action.module.css';

const Display = ({
  order,
}: OrdersTableCellsDisplayProps): JSX.Element | null => {
  const {
    mutate: cancelOrder,
    isLoading: isLoadingCancelOrder,
    isSuccess: isSuccessCancelOrder,
    isError: isErrorCancelOrder,
    error: errorCancelOrder,
  } = useCancelOrder();
  const { mutate: downloadOrderPdf } = useOrderPdfDownload();
  const [showDownloadAllDrawer, setShowDownloadAllDrawer] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const actionCellContent = useMemo(() => {
    if (orderNeedReview(order)) {
      return (
        <OrderHint
          label="Needs Review"
          isNotification
          icon={
            <DirectionalChevron
              dataHcName={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-chevron`}
              direction="right"
              size="sm"
            />
          }
        />
      );
    } else if (order.status === ORDER_STATUSES.NEW && !order.cancelled) {
      return (
        <OrderHint
          label="Cancel Order"
          isNotification
          icon={
            <CloseIcon
              className={styles.CancelButton}
              dataHcName={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-cancel-button`}
            />
          }
          onHintClick={(e) => {
            e.stopPropagation();
            setShowCancelDialog(true);
          }}
        />
      );
    } else if (order.status === ORDER_STATUSES.COMPLETE) {
      if (order.orderItemsCount === 1) {
        return (
          <OrderHint
            label="Download Report"
            icon={
              <DirectionalChevron
                dataHcName={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-download-report-button`}
                direction="right"
                size="sm"
              />
            }
            onHintClick={(e) => {
              e.stopPropagation();
              downloadOrderPdf({ orderId: order.id });
            }}
          />
        );
      } else {
        return (
          <OrderHint
            label="Export Order"
            icon={
              <DownloadIcon
                className={styles.DownloadButton}
                dataHcName={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-export-order-download-icon`}
                height={22}
                width={22}
              />
            }
            onHintClick={(e) => {
              e.stopPropagation();
              setShowDownloadAllDrawer(true);
            }}
          />
        );
      }
    } else {
      return (
        <OrderHint
          label="View Order"
          icon={
            <DirectionalChevron
              dataHcName={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-chevron`}
              direction="right"
              size="sm"
            />
          }
        />
      );
    }
  }, [order, setShowCancelDialog, downloadOrderPdf]);
  return (
    <div data-hc-name={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-action`}>
      {order && actionCellContent}
      {showCancelDialog && (
        <CancelOrderDialog
          cancelOrder={() => cancelOrder({ orderId: order.id })}
          isLoadingCancelOrder={isLoadingCancelOrder}
          isSuccessCancelOrder={isSuccessCancelOrder}
          hideModal={() => setShowCancelDialog(false)}
          notifications={[
            {
              dataHcName: 'cancel-order-is-error',
              show: isErrorCancelOrder,
              type: 'error',
              title: errorCancelOrder?.message || 'Failed to cancel order',
            },
          ]}
        />
      )}
      {showDownloadAllDrawer && (
        <DownloadAllDrawer
          orderId={order.id}
          hideModal={() => setShowDownloadAllDrawer(false)}
        />
      )}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Action</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export type OrdersTableCellProps = TableCellProps & OmOrder;

const ContentCell = ({ order, ...tableCellProps }: { order: OmOrder }) => {
  return (
    <TableCell {...tableCellProps}>
      <Display order={order} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_ACTION_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Action',
};
