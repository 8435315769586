import {
  ListingListingDetailsHcConcessions,
  ListingListingDetailsReso,
  ListingStatusCerberus,
  Maybe,
  PropertyTypeEnum,
} from '../cerberus';
import { PropertyStateCalculatedFields } from '../property-state/PropertyStateCalculatedFields.types';
import { PropertyStateFields } from '../property-state/PropertyStateFields.types';
import {
  PropertyStateAgentOffice,
  PropertyStateBlockDetailsCore,
  PropertyStateComplexFieldsRentalCore,
  PropertyStateComplexFieldsRentalPreview,
  PropertyStateComplexFieldsSaleCore,
  PropertyStateComplexFieldsSalePreview,
  PropertyStateLandCore,
  PropertyStateListingDetailsCore,
  PropertyStateListingMetadata,
  PropertyStatePropertyDetailsCore,
  PropertyStatePropertyDetailsPreview,
  PropertyStatePropertyLocationDiscovery,
  PropertyStatePropertyLocationHc,
  PropertyStateRentalValueCore,
  PropertyStateTaxHistoryCore,
  PropertyStateValueCore,
} from '../property-state/PropertyStateFragments.types';

// The PropertyStateType enum documents the different variations of PropertyState
// PropertyStateLocation and PropertyStateFlat are omitted from the enum.
// PropertyStateFlat is intended to be constructed manually
// Most location related code expects PropertyState['location'] as the input rather than PropertyStateArgs
export enum PropertyStateType {
  // Fields shown for "Property Preview" or Property Cards
  Preview = 'Preview',
  // Fields shown for "value" report-api reports
  Core = 'Core',
}

export type PropertyStateLocation = {
  hcAddressId: Maybe<number> | undefined;
  location?: Maybe<
    PropertyStatePropertyLocationDiscovery & PropertyStatePropertyLocationHc
  >;
};

export type PropertyStatePreview = PropertyStateLocation &
  Partial<{
    propertyDetails: Maybe<PropertyStatePropertyDetailsPreview>;
    complexFieldsSale: Maybe<PropertyStateComplexFieldsSalePreview>;
    complexFieldsRental: Maybe<PropertyStateComplexFieldsRentalPreview>;
    // Laundered data
    listingDetailsSale: Maybe<PropertyStateListingMetadata>;
    listingDetailsRental: Maybe<PropertyStateListingMetadata>;
    // Calculated data
    calculatedFields: Maybe<Partial<PropertyStateCalculatedFields>>;
  }>;

export interface PropertyStateMsaDetails {
  msaName?: string | null;
  msaId?: string | null;
}

export type PropertyStateCore = PropertyStatePreview &
  Partial<{
    propertyDetails: Maybe<PropertyStatePropertyDetailsCore>;
    complexFieldsSale: Maybe<PropertyStateComplexFieldsSaleCore>;
    complexFieldsRental: Maybe<PropertyStateComplexFieldsRentalCore>;
    land: Maybe<PropertyStateLandCore>;
    taxDetails: Maybe<PropertyStateTaxHistoryCore>;
    propertyValue: Maybe<PropertyStateValueCore>;
    propertyValueRental: Maybe<PropertyStateRentalValueCore>;
    // Laundered data
    listingDetailsSale: Maybe<PropertyStateListingDetailsCore>;
    listingDetailsRental: Maybe<PropertyStateListingDetailsCore>;
    // Calculated data
    calculatedFields: Maybe<Partial<PropertyStateCalculatedFields>>;
    blockDetails: Maybe<PropertyStateBlockDetailsCore>;
    msaDetails: Maybe<PropertyStateMsaDetails>;
  }>;

export type PropertyStateFlat = Partial<{
  location?: PropertyStateLocation['location'] | null;
  hcAddressId?: number | null;
  [PropertyStateFields.age]?: PropertyStateCalculatedFields['age'];
  [PropertyStateFields.apn]?: PropertyStatePropertyDetailsCore['apn'];
  [PropertyStateFields.basement]?: PropertyStatePropertyDetailsCore['basement'];
  [PropertyStateFields.basementHas]?: NonNullable<
    PropertyStatePropertyDetailsCore['basement']
  >['has'];
  [PropertyStateFields.bathrooms]?: NonNullable<
    PropertyStatePropertyDetailsPreview['bathrooms']
  >['totalProjected'];
  [PropertyStateFields.bedrooms]?: PropertyStatePropertyDetailsPreview['bedrooms'];
  [PropertyStateFields.concessionBuyerAmount]?: ListingListingDetailsHcConcessions['buyerAmount'];
  [PropertyStateFields.concessionBuyerDescription]?: ListingListingDetailsHcConcessions['buyerDescription'];
  [PropertyStateFields.concessions]?: ListingListingDetailsReso['concessions'];
  [PropertyStateFields.concessionsAmount]?: ListingListingDetailsReso['concessionsAmount'];
  [PropertyStateFields.concessionsComments]?: ListingListingDetailsReso['concessionsComments'];
  [PropertyStateFields.concessionSellerAmount]?: ListingListingDetailsHcConcessions['sellerAmount'];
  [PropertyStateFields.concessionSellerDescription]?: ListingListingDetailsHcConcessions['sellerDescription'];
  [PropertyStateFields.lastCloseDate]?: PropertyStateComplexFieldsSaleCore['lastCloseDate'];
  [PropertyStateFields.lastCloseDateRental]?: PropertyStateComplexFieldsRentalCore['lastCloseDate'];
  [PropertyStateFields.lastClosePrice]?: PropertyStateComplexFieldsSaleCore['lastClosePrice'];
  [PropertyStateFields.lastClosePriceRental]?: PropertyStateComplexFieldsRentalCore['lastClosePrice'];
  [PropertyStateFields.lastClosePricePerSqFt]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsSale']
  >['lastClosePricePerSqFt'];
  [PropertyStateFields.lastClosePricePerSqFtRental]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsRental']
  >['lastClosePricePerSqFt'];
  [PropertyStateFields.roomsTotal]: PropertyStatePropertyDetailsCore['roomsTotal'];
  [PropertyStateFields.heating]: PropertyStatePropertyDetailsCore['heating'];
  [PropertyStateFields.cooling]: PropertyStatePropertyDetailsCore['cooling'];
  [PropertyStateFields.condition]?: NonNullable<
    NonNullable<PropertyStateValueCore>['valueAtSixConditions']
  >['conditionClass'];
  [PropertyStateFields.county]?: NonNullable<
    PropertyStateLocation['location']
  >['county'];
  [PropertyStateFields.currentValue]?: PropertyStateValueCore['value'];
  [PropertyStateFields.currentValuePerSqFt]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsSale']
  >['propertyValueMeanPerSqFt'];
  [PropertyStateFields.currentValuePerSqFtRental]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsRental']
  >['propertyValueMeanPerSqFt'];
  [PropertyStateFields.currentValueRental]?: PropertyStateRentalValueCore['value'];
  [PropertyStateFields.daysOnMarketActive]?: PropertyStateComplexFieldsSaleCore['currentDaysOnMarketCumulative'];
  [PropertyStateFields.daysOnMarketCumulative]?: PropertyStateComplexFieldsSaleCore['currentDaysToCloseCumulative'];
  [PropertyStateFields.daysOnMarketMls]?: PropertyStateComplexFieldsSalePreview['currentDaysOnMarketMLS'];
  [PropertyStateFields.daysOnMarketMlsRental]?: PropertyStateComplexFieldsRentalPreview['currentDaysOnMarketMLS'];
  [PropertyStateFields.parking]?: PropertyStatePropertyDetailsCore['parking'];
  [PropertyStateFields.parkingTotal]?: NonNullable<
    PropertyStatePropertyDetailsCore['parking']
  >['total'];
  [PropertyStateFields.garageSpaces]?: NonNullable<
    PropertyStatePropertyDetailsCore['parking']
  >['total'];
  [PropertyStateFields.garageType]?: NonNullable<
    NonNullable<
      NonNullable<PropertyStatePropertyDetailsCore['parking']>['parkingDetails']
    >[0]
  >['type'][];
  [PropertyStateFields.currentDistressed]?: PropertyStateComplexFieldsSaleCore['currentDistressed'];
  [PropertyStateFields.currentFlip]?: PropertyStateComplexFieldsSaleCore['currentFlipYN'];
  [PropertyStateFields.livingArea]?: PropertyStatePropertyDetailsPreview['livingArea'];
  [PropertyStateFields.associationFee]?: NonNullable<
    NonNullable<PropertyStatePropertyDetailsCore['association']>[0]
  >['fee'][];
  [PropertyStateFields.association]: PropertyStatePropertyDetailsCore['association'];
  [PropertyStateFields.associationFeeFrequency]?: NonNullable<
    NonNullable<PropertyStatePropertyDetailsCore['association']>[0]
  >['frequency'][];
  [PropertyStateFields.associationAmenities]?: PropertyStatePropertyDetailsCore['associationAmenities'];
  [PropertyStateFields.associationFeeIncludes]?: PropertyStatePropertyDetailsCore['associationFeeIncludes'];
  [PropertyStateFields.associationName]?: NonNullable<
    NonNullable<PropertyStatePropertyDetailsCore['association']>[0]
  >['name'][];
  [PropertyStateFields.listingAgentOffice]?: PropertyStateAgentOffice | null;
  [PropertyStateFields.listingAgentOfficeRental]?: PropertyStateAgentOffice | null;
  [PropertyStateFields.currentListDate]?: PropertyStateComplexFieldsSaleCore['currentListDate'];
  [PropertyStateFields.currentListDateRental]?: PropertyStateComplexFieldsRentalCore['currentListDate'];
  [PropertyStateFields.currentListingPrice]?: PropertyStateComplexFieldsSaleCore['currentListingPrice'];
  [PropertyStateFields.currentListingPriceRental]?: PropertyStateComplexFieldsRentalCore['currentListingPrice'];
  [PropertyStateFields.currentListingPricePerSqFt]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsSale']
  >['currentListingPricePerSqFt'];
  [PropertyStateFields.currentListingPricePerSqFtRental]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsRental']
  >['currentListingPricePerSqFt'];
  [PropertyStateFields.lotSize]?: PropertyStatePropertyDetailsPreview['lotSize'];
  [PropertyStateFields.currentPrice]?: PropertyStateComplexFieldsSalePreview['currentPrice'];
  [PropertyStateFields.currentStatusDate]?: PropertyStateComplexFieldsSalePreview['currentStatusDate'];
  [PropertyStateFields.currentPricePerSqFt]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsSale']
  >['currentPricePerSqFt'];
  [PropertyStateFields.currentPriceRental]?: PropertyStateComplexFieldsRentalPreview['currentPrice'];
  [PropertyStateFields.currentStatusDateRental]?: PropertyStateComplexFieldsRentalPreview['currentStatusDate'];
  [PropertyStateFields.currentPricePerSqFtRental]?: NonNullable<
    PropertyStateCalculatedFields['livingAreaFieldsRental']
  >['currentPricePerSqFt'];
  [PropertyStateFields.ownerOccupied]?: NonNullable<
    PropertyStateTaxHistoryCore['ownership']
  >['ownerOccupied'];
  [PropertyStateFields.pool]?: PropertyStatePropertyDetailsCore['poolYN'];
  [PropertyStateFields.currentStatus]?: ListingStatusCerberus | null;
  [PropertyStateFields.currentStatusRental]?: ListingStatusCerberus | null;
  [PropertyStateFields.propertyType]?: PropertyTypeEnum | null;
  [PropertyStateFields.rentalYield]?:
    | PropertyStateRentalValueCore['yield']
    | null;
  [PropertyStateFields.stories]?: PropertyStatePropertyDetailsCore['storiesNumber'];
  [PropertyStateFields.subdivisionName]?: NonNullable<
    PropertyStateLocation['location']
  >['subdivision'];
  [PropertyStateFields.taxAmount]?: NonNullable<
    PropertyStatePropertyDetailsCore['tax']
  >['amountAnnual'];
  [PropertyStateFields.taxYear]?: NonNullable<
    PropertyStatePropertyDetailsCore['tax']
  >['year'];
  [PropertyStateFields.yearBuilt]?: PropertyStatePropertyDetailsCore['yearBuilt'];
  [PropertyStateFields.zoning]?: NonNullable<
    PropertyStatePropertyDetailsCore['zoning']
  >['code'];
  [PropertyStateFields.landValue]?: PropertyStateLandCore['valueLand'];
  [PropertyStateFields.saleType]?: PropertyStateCalculatedFields['saleType'];
}>;
