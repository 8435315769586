import React from 'react';
import { BarDatum, BarItemProps } from '@nivo/bar';
import { animated, useSpring } from '@react-spring/web';

import { BarData } from '@hcs/types';

const RADIUS = 8;
const LABEL_ABOVE_BAR_POSITION = 15;
const ANIMATION_DURATION = 700;
const STANDARD_CHART_SIZE = 700;

interface Props extends BarItemProps<BarDatum> {
  subjectIndex?: number;
  previousNumberOfBars?: number;
  barData: Map<number, BarData>;
  prevBarData?: Map<number, BarData>;
  barDataList?: Map<number, BarData>[];
  chartWidth?: number;
}

export const NearbyChartBarComponent = ({
  bar: { color, data, x, y, width, height, index },
  subjectIndex,
  barData,
  prevBarData,
  previousNumberOfBars,
  chartWidth,
  label,
}: Props) => {
  barData.set(data.index, {
    width,
    height,
    x,
    y,
    count: parseInt(`${data.data.count}`),
  });

  const prevColumnData = prevBarData?.get(data.index);
  const radius = RADIUS < height ? RADIUS : height;

  const transforms = useSpring({
    from: {
      transform: `translate(${
        previousNumberOfBars && index > previousNumberOfBars - 1
          ? (chartWidth || STANDARD_CHART_SIZE) - 150
          : prevColumnData?.x || x
      }, ${y})`,
    },
    to: {
      transform: `translate(${x}, ${y})`,
    },
    config: {
      mass: 10,
      tension: 10,
      friction: 250,
      clamp: true,
      precision: 0.659,
      duration: ANIMATION_DURATION,
    },
  });

  const firstBarWidthOrWidth = prevBarData?.get(0)?.width || width;

  const textTransforms = useSpring({
    from: {
      x: prevColumnData ? prevColumnData.width / 2 : firstBarWidthOrWidth / 2,
      y:
        previousNumberOfBars && index > previousNumberOfBars - 1
          ? +height - LABEL_ABOVE_BAR_POSITION
          : prevColumnData
          ? -(prevColumnData.height - height) - LABEL_ABOVE_BAR_POSITION
          : -LABEL_ABOVE_BAR_POSITION,
    },
    to: {
      x: width / 2,
      y: -LABEL_ABOVE_BAR_POSITION,
    },
    config: {
      mass: 10,
      tension: 10,
      friction: 200,
      clamp: true,
      precision: 0.659,
      duration: ANIMATION_DURATION,
    },
  });

  const svgUpdates = useSpring({
    from: {
      d: `M 0 0
        h ${!prevColumnData ? firstBarWidthOrWidth : prevColumnData.width}
        v ${
          previousNumberOfBars && index > previousNumberOfBars - 1
            ? 0
            : prevColumnData
            ? prevColumnData?.height - radius
            : 0
        }
        a ${radius} , ${radius} 0 0 1 -${radius}, ${radius}
        h ${
          radius -
          (!prevColumnData
            ? firstBarWidthOrWidth
            : prevColumnData.width - radius)
        }
        a ${radius}, ${radius} 0 0 1 -${radius}, -${radius} z`,
    },
    to: {
      d: `M 0 0
        h ${width}
        v ${height - radius}
        a ${radius} , ${radius} 0 0 1 -${radius}, ${radius}
        h ${RADIUS - (width - radius)}
        a ${radius}, ${radius} 0 0 1 -${radius}, -${radius} z`,
    },
    config: {
      duration: ANIMATION_DURATION,
      delay: 100,
    },
  });

  return (
    <animated.g {...transforms} id={`a-bar-${index}`}>
      <animated.path
        transform={`translate(0,${height}) scale(1, -1)`}
        {...svgUpdates}
        fill={color}
      />
      <animated.text
        {...textTransforms}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 12,
          fontWeight:
            subjectIndex && data.index === subjectIndex ? 'bold' : 'initial',
          pointerEvents: 'none',
          fill: 'black',
          opacity: 0.75,
        }}
      >
        {label}
      </animated.text>
    </animated.g>
  );
};
