import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CompFieldConfig,
  CompFields,
  CompFieldsArgs,
  CompFieldsTableCellProps,
} from '@hcs/types';
import { formatMoney } from '@hcs/utils';

const FIELD = CompFields.hcAdjustmentPropertyDetails;
type FieldConfig = CompFieldConfig<typeof FIELD>;
const label = 'HC Adjustment';
// TODO: Fix PDF inconsistency
const labelShort = 'Net Dollar Adjustment';
const compPath: FieldConfig['compPath'] =
  '/hcAdjustments/hcAdjustmentPropertyDetails';
const getValue: FieldConfig['getValue'] = (comp) =>
  comp?.hcAdjustments?.hcAdjustmentPropertyDetails;
const formatValue: FieldConfig['formatValue'] = (comp) =>
  formatMoney(getValue(comp));
const formatInline: FieldConfig['formatInline'] = (comp) => formatValue(comp);
// Info for glossary or tooltip
const info: FieldConfig['info'] =
  'HouseCanary Adjustment is an attribute-based adjustment to match the subject property';
// Component for displaying a field from a comp
const Display = ({ comp }: CompFieldsArgs) => {
  return <>{formatValue(comp)}</>;
};

const HeaderCell = (props: TableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({ comp, ...tableCellProps }: CompFieldsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display comp={comp} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const HC_ADJUSTMENT_PROPERTY_DETAILS_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  compPath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  info,
};
