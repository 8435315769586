import React from 'react';

import { DownloadLink, DownloadLinkTheme } from '@hcs/design-system';

import { useDownloadDexpJob } from '../../hooks/useDownloadDexpJob';

type AnalysisDownloadLinkProps = {
  theme?: DownloadLinkTheme;
  outputFile: string;
  label: string;
  error?: {
    message: string;
  };
};

const dataHcName = 'analysis-download-link';
export const AnalysisDownloadLink = ({
  theme,
  outputFile,
  label,
  error,
}: AnalysisDownloadLinkProps) => {
  const downloadFileMutation = useDownloadDexpJob();
  const handleDownload = () => {
    downloadFileMutation.mutate(outputFile);
  };

  return (
    <DownloadLink
      dataHcName={dataHcName}
      theme={theme}
      label={label}
      showDownloadLink={outputFile !== ''}
      isLoading={downloadFileMutation.isLoading}
      onDownload={handleDownload}
      error={error}
    />
  );
};
