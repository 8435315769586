import React, { ReactNode } from 'react';

import { Tooltip } from '@hcs/design-system';

import styles from './UserPropertyAdjustedBadge.module.css';

interface UserPropertyAdjustedBadgeProps {
  dataHcName: string;
  label: ReactNode;
  value: ReactNode;
}

export const UserPropertyAdjustedBadge = ({
  dataHcName,
  label,
  value,
}: UserPropertyAdjustedBadgeProps) => {
  const adjustedBadge = (
    <div
      className={styles.UserPropertyAdjustedBadge}
      data-hc-name={`${dataHcName}-user-property-adjusted-badge`}
    >
      Adjusted
    </div>
  );
  return label && value ? (
    <Tooltip
      dataHcName={`${dataHcName}-user-property-adjusted-badge-tooltip`}
      trigger={adjustedBadge}
      label={'Original Value'}
      description={
        <>
          <span data-hc-name={`${dataHcName}-original-label`}>{label}</span>:{' '}
          <span data-hc-name={`${dataHcName}-original-value`}>{value}</span>
        </>
      }
    />
  ) : (
    adjustedBadge
  );
};
