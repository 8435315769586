import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './PageTitle.module.css';

export interface PageTitleProps {
  dataHcName: string;
  className?: string;
  children: ReactNode;
}
export const PageTitle = ({
  dataHcName,
  className,
  children,
}: PageTitleProps) => {
  return (
    <h1
      data-hc-name={dataHcName}
      className={classNames(styles.PageTitle, className)}
    >
      {children}
    </h1>
  );
};
