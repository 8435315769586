import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { Skeleton } from '@hcs/design-system';
import {
  DialogInputLayout,
  FormFieldsInput,
  FormFieldsSwitch,
} from '@hcs/forms';
import { OrganizationapiCreateOrgRequestInt } from '@hcs/types';

import styles from './OrganizationForm.module.css';

const MULTI_INPUT_THEME = { InputContainer: styles.MultiInputLine };
const dataHcName = 'org-form';
interface Props {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
}
export const OrganizationForm = ({ children, className, isLoading }: Props) => {
  const form = useFormContext<OrganizationapiCreateOrgRequestInt>();
  const { formState } = form;
  const { errors } = formState;
  const inputSkeleton = (
    <Skeleton
      dataHcName={`${dataHcName}-skeleton`}
      type="input"
      className={styles.Input}
    />
  );
  return (
    <form
      data-hc-name={`${dataHcName}-form`}
      className={classNames(styles.Form, className)}
    >
      <DialogInputLayout
        label="Name"
        required
        dataHcName={`${dataHcName}-name`}
      >
        {isLoading ? (
          inputSkeleton
        ) : (
          <FormFieldsInput
            name="name"
            label=""
            error={errors.name?.message?.toString()}
            className={styles.Input}
          />
        )}
      </DialogInputLayout>
      <DialogInputLayout
        label="Zuora"
        dataHcName={`${dataHcName}-salesforce`}
        theme={MULTI_INPUT_THEME}
        required={false}
      >
        {isLoading ? (
          <>
            {inputSkeleton}
            {inputSkeleton}
          </>
        ) : (
          <>
            <FormFieldsInput
              name="zAccountId"
              label="Account ID"
              error={errors.zAccountId?.message?.toString()}
              className={styles.Input}
            />
            <FormFieldsInput
              name="zAccountNumber"
              label="Account Number"
              error={errors.zAccountNumber?.message?.toString()}
              className={styles.Input}
            />
          </>
        )}
      </DialogInputLayout>
      <DialogInputLayout
        label=""
        dataHcName={`${dataHcName}-salesforce`}
        theme={MULTI_INPUT_THEME}
        required={false}
      >
        <FormFieldsSwitch name="active" label="Active" />
        <FormFieldsSwitch name="enterprise" label="Enterprise" />
        <FormFieldsSwitch name="test" label="Test Account" />
      </DialogInputLayout>
      {children}
    </form>
  );
};
