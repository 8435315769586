/** Dev / Debug / Internal feature used in Cerberus Explorer and Property State Storybook */
import React from 'react';

import { Dropdown, DropdownProps } from '@hcs/design-system';
import { ApplicationType } from '@hcs/types';

interface Props {
  value: ApplicationType | null;
  onSelect: DropdownProps<ApplicationType | null>['onSelect'];
  className?: string;
}
const OPTIONS = [
  {
    value: null,
    label: 'HCS Default (VOW)',
  },
  {
    value: ApplicationType.Vow,
    label: 'Virtual Office Website (HCS Default)',
  },
  {
    value: ApplicationType.Idx,
    label: 'Internet Data Exchange (Publicly Displayed Data)',
  },
];
const dataHcName = 'cerberus-application-type-dropdown';
export const CerberusApplicationTypeDropdown = (props: Props) => {
  return (
    <Dropdown
      dataHcName={dataHcName}
      options={OPTIONS}
      placeholder="Application Type"
      {...props}
    />
  );
};
