import React from 'react';
import classNames from 'classnames';

import { TextButton } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { ChevronLeftSmallIcon } from '@hcs/design-system';
import { MeaningfulEventTypes } from '@hcs/types';

import { useDexpJob } from '../../hooks/useDexpJob';
import { useDownloadDexpJob } from '../../hooks/useDownloadDexpJob';

import { infoSectionItems } from './AnalysisResultsErrorSummaryTableData';

import styles from './AnalysisResultsErrorSummary.module.css';

interface Props {
  dexpJobId: number;
  backToResults: () => void;
}

const dataHcName = 'analysis-results-error-summary';
export const dataHcEventSectionDexpErrorSummary = 'DEXP Analysis Error Summary';
export const AnalysisResultsErrorSummary = ({
  dexpJobId,
  backToResults,
}: Props) => {
  const { data: dexpJobData, isInitialLoading: dexpJobIsLoading } =
    useDexpJob(dexpJobId);

  const { mutate: downloadFileMutation } = useDownloadDexpJob();
  const handleDownload = () =>
    dexpJobData?.outputFile && downloadFileMutation(dexpJobData.outputFile);

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSectionDexpErrorSummary}
    >
      <TextButton
        dataHcName={`${dataHcName}-back-to-results`}
        onClick={backToResults}
        className={styles.Back}
        icon={<ChevronLeftSmallIcon />}
      >
        Back
      </TextButton>
      {dexpJobIsLoading || !dexpJobData ? (
        <Skeleton dataHcName={`${dataHcName}-loading-skeleton`} />
      ) : (
        <Card noPadding dataHcName={`${dataHcName}-card`}>
          <div className={styles.Header}>
            <div className={styles.Title}>{dexpJobData.fileName}</div>
            <TextButton
              onClick={handleDownload}
              dataHcName={`${dataHcName}-download-file`}
              dataHcEventName="DEXP Analysis Download"
              dataHcEventType={MeaningfulEventTypes.Goal}
            >
              Download
            </TextButton>
          </div>
          <div className={styles.InfoSection}>
            {infoSectionItems.map(({ key, title, content, Icon }) => {
              return (
                <div
                  key={key}
                  className={classNames(styles.InfoSectionItem, {
                    [styles.noBorder]: key === 'run-by' || key === 'run-on',
                  })}
                  data-hc-name={`${dataHcName}-info-section-item`}
                >
                  <div className={styles.InfoSectionTitle}>{title}</div>
                  <div className={styles.InfoSectionContent}>
                    {Icon && <span>{Icon}</span>}
                    {content(dexpJobData)}
                  </div>
                </div>
              );
            })}
          </div>

          {/**
            * TODO: Currently waiting in BE to return the endpoints
            *       associated with each failed address.
            *       FAILED_ADDRESSES_WITH_ENDPOINTS should be replaced
            *       with the the var name in the response containing this info.
           
            <div className={styles.Errors}>
              <div className={styles.ErrorTitle}>
                The following errors occurred:
              </div>
              {dexpJobData?.FAILED_ADDRESSES_WITH_ENDPOINTS.map(
                ({ address, zipcode, endpoints }) => {
                  return (
                    <div key={address}>
                      <div className={styles.Address}>
                        {address}, {zipcode}
                      </div>

                      {endpoints.map((endpoint) => (
                        <div key={endpoint} className={styles.ErrorEndpoints}>
                          <ErrorIcon width={15} height={15} />
                          <span>{endpoint}</span>
                        </div>
                      ))}
                    </div>
                  );
                }
              )}
            </div>
          */}
        </Card>
      )}
    </div>
  );
};
