import {
  HcCompsIcon,
  InspectionCorrectionIcon,
  UserSettingsIcon,
} from '@hcs/design-system';
import {
  CardViewIcon,
  CompareViewIcon,
  TableViewIcon,
} from '@hcs/design-system';
import { CompsListTypes, CompsListViewType, IconProps } from '@hcs/types';
import { formatCompsListType } from '@hcs/utils';

interface CompsListViewTypeConfig {
  listViewType: CompsListViewType;
  name: string;
  Icon: (props: IconProps) => JSX.Element;
}

interface CompsListTypeConfig {
  name: string;
  Icon: (props: IconProps) => JSX.Element;
}
export const COMPS_LIST_VIEW_TYPE_CONFIGS: {
  [key in CompsListViewType]: CompsListViewTypeConfig;
} = {
  [CompsListViewType.Card]: {
    listViewType: CompsListViewType.Card,
    name: 'Card',
    Icon: CardViewIcon,
  },
  [CompsListViewType.Table]: {
    listViewType: CompsListViewType.Table,
    name: 'List',
    Icon: TableViewIcon,
  },
  [CompsListViewType.Compare]: {
    listViewType: CompsListViewType.Compare,
    name: 'Comparison',
    Icon: CompareViewIcon,
  },
};

export const COMPS_LIST_TYPE_CONFIGS: {
  [key in CompsListTypes]: CompsListTypeConfig;
} = {
  appraisal: {
    name: formatCompsListType('appraisal'),
    Icon: InspectionCorrectionIcon,
  },
  hcSuggestedComps: {
    name: formatCompsListType('hcSuggestedComps'),
    Icon: HcCompsIcon,
  },
  competitiveActive: {
    name: formatCompsListType('competitiveActive'),
    Icon: HcCompsIcon,
  },
  competitiveClosed: {
    name: formatCompsListType('competitiveClosed'),
    Icon: HcCompsIcon,
  },
  compIDs: {
    name: formatCompsListType('compIDs'),
    Icon: UserSettingsIcon,
  },
  selected: {
    name: formatCompsListType('selected'),
    Icon: UserSettingsIcon,
  },
};

// Comp compare doesn't lazy render and can only show 20 comps at a time for performance reasons
// Pagination controls are shown when there are multiple pages of comps
export const COMPS_LIST_MAX_COMPARE_COMPS = 10;

// Used in calculations to determine expandable container width
export const COMPS_LIST_CARDS_GAP = 15;
export const COMPS_LIST_CARDS_WIDTH = 250;
