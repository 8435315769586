import { gql } from 'graphql-request';

export const QUERY_PROPERTY_STATE_STRENGTH_OF_OFFER = gql`
  query PropertyStateStrengthOfOffer(
    $cerberusInput: CerberusInput!
    $offerPrice: Int
  ) {
    # HC APP: Value Report
    lookup(id: $cerberusInput) {
      latestListing {
        sale {
          derived {
            hc {
              strengthOfOffer(offerPrice: $offerPrice) {
                fipsProbabilityOfAccept
                msaProbabilityOfAccept
              }
            }
          }
        }
      }
    }
  }
`;
