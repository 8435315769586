import { SpatialSimilarity } from '../../cerberus';
import { PropertyStateCore } from '../../property-state';
import { CompId } from '../Comps.types';

export enum SimilarityLevel {
  High = 'high',
  Moderate = 'moderate',
  Low = 'low',
}

interface TimelineAdjustedAVMValues {
  block: number | null;
  blockgroup: number | null;
  msa: number | null;
  state: number | null;
  zip: number | null;
}

interface AdjustedAVM {
  fromDate: string | null;
  fromValue: number | null;
  toDate: string | null;
  adjustedBy: TimelineAdjustedAVMValues;
  adjustedByMax: TimelineAdjustedAVMValues | null;
  adjustedByMin: TimelineAdjustedAVMValues | null;
}

interface HcAdjustments {
  hcAdjustmentDate: number | null;
  hcAdjustmentPropertyDetails: number | null;
}

interface UserAdjustments {
  totalDollarAdjustment?: number | null;
}

export type LocationSimilarityType = 'Inferior' | 'Similar' | 'Superior';

export interface CompSchema {
  compID: CompId;
  distance: number | null;
  adjustedAVM?: AdjustedAVM;
  locationSimilarity?: LocationSimilarityType | null;
  similarity: Omit<SpatialSimilarity, 'level' | 'levelAdjusted'> & {
    level: SimilarityLevel | null;
    levelAdjusted: SimilarityLevel | null;
  };
  hcAdjustments: HcAdjustments;
  propertyState: PropertyStateCore;
  adjustedCompValue: {
    value: number | null;
    valuePerSqFt: number | null;
  };
  userAdjustments?: UserAdjustments | null;
}
