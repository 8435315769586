import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CerberusStatsFieldConfig,
  CerberusStatsFields,
  CerberusStatsProps,
  CerberusStatsTableCellProps,
  CerberusStatsTableHeaderCellProps,
  CerberusStatsType,
} from '@hcs/types';
import { convertDateStrToDate, formatDateMonth } from '@hcs/utils';

import { logValueUnsupportedWarning } from '../../utils/cerberusStatsConfig.utils';

const FIELD = CerberusStatsFields.rpiLatestMonth;
type FieldConfig = CerberusStatsFieldConfig<typeof FIELD>;
const label = 'Last Data Update';
const labelShort = 'Last Data Update';
const getValue: FieldConfig['getValue'] = (cerberusStats) => {
  if (!cerberusStats) {
    return undefined;
  }
  if (
    cerberusStats.type !== CerberusStatsType.ListingStats &&
    cerberusStats.type !== CerberusStatsType.RentalListingStats
  ) {
    return cerberusStats?.rpi?.latestMonth;
  } else {
    logValueUnsupportedWarning(FIELD, cerberusStats);
    return undefined;
  }
};
const formatValue: FieldConfig['formatValue'] = (cerberusStats) => {
  const dateFromDateStr = convertDateStrToDate(getValue(cerberusStats));
  return formatDateMonth(dateFromDateStr);
};

// Component for displaying a field from a schema
const Display = ({ cerberusStats }: CerberusStatsProps) => {
  return <>{formatValue(cerberusStats)}</>;
};

const HeaderCell = (props: CerberusStatsTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{label}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  cerberusStats,
  ...tableCellProps
}: CerberusStatsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display cerberusStats={cerberusStats} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const RPI_LATEST_MONTH_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  getValue,
  formatValue,
  HeaderCell,
  ContentCell,
  Display,
};
