import React from 'react';

import { capitalizeFirstLetter } from '@hcs/utils';

import styles from './SchoolMapMarkerPopup.module.css';

type Props = {
  featureProperties: {
    name: string;
    rank_unrounded?: number;
    uid: string;
  } | null;
};

/**
 * A popup for school map markers
 */
export const SchoolMapMarkerPopup: React.FC<Props> = ({
  featureProperties,
}) => {
  if (featureProperties) {
    const { name, rank_unrounded: rank, uid } = featureProperties;
    return (
      <div
        data-hc-name="school-marker-popup"
        data-school-id={uid}
        data-school-name={name}
        className={styles.SchoolMapMarkerPopup}
        aria-labelledby={`title-${uid}`}
        role="dialog"
        tabIndex={-1}
      >
        <div id={`title-${uid}`} className={styles.SchoolName}>
          {capitalizeFirstLetter(name)}
        </div>
        {rank && rank >= 0 && (
          <div className={styles.RankingCol}>
            <div className={styles.topLine}>Ranking</div>
            <div className={styles.midLine}>{Math.round(rank)}</div>
            <div className={styles.bottomLine}>Percentile</div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};
