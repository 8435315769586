import { OrderItemGroup } from './OrderItem.types';

export enum OrderItemsTableInOrderOptions {
  Address = 'address',
  DueDate = 'dueDate',
  EstimatedValue = 'estimatedValue',
  CustomerItemId = 'customerItemId',
  CreatedAt = 'createdAt',
  InOrderActions = 'inOrderActions',
  PropertyType = 'propertyType',
  Status = 'status',
  ValueRange = 'valueRange',
  InspectionCondition = 'inspectionCondition',
}

export enum OrderItemsTableNeedReviewOptions {
  Address = 'address',
  City = 'city',
  CustomerItemId = 'customerItemId',
  Issues = 'issues',
  NeedReviewActions = 'needReviewActions',
  PropertyType = 'propertyType',
  State = 'state',
  ZipCode = 'zipCode',
}

export enum OrderItemsTableRemovedOptions {
  Address = 'address',
  City = 'city',
  CustomerItemId = 'customerItemId',
  Issues = 'issues',
  PropertyType = 'propertyType',
  State = 'state',
  ZipCode = 'zipCode',
}

export type OrderItemsTableOptions =
  | OrderItemsTableNeedReviewOptions
  | OrderItemsTableInOrderOptions
  | OrderItemsTableRemovedOptions;

export enum OrderItemsFilterIds {
  Page = 'page',
  PageSize = 'pageSize',
  Search = 'search',
  SortBy = 'sortBy',
  SortOrder = 'sortOrder',
  OrderItemGroup = 'orderItemGroup',
}
export interface OrderItemsParams {
  [OrderItemsFilterIds.Page]?: number;
  [OrderItemsFilterIds.PageSize]?: number;
  [OrderItemsFilterIds.Search]?: string;
  [OrderItemsFilterIds.SortOrder]?: 'ASC' | 'DESC';
  [OrderItemsFilterIds.SortBy]?: OrderItemsTableOptions;
  [OrderItemsFilterIds.OrderItemGroup]: OrderItemGroup;
}
export interface OrderItemsParamsForApi {
  page?: number;
  pageSize?: number;
  ordering?: string | string[];
  processItem?: boolean | null;
}

export interface OrderItemsUpdateParams {
  orderId: number;
  orderItemGroup: OrderItemGroup;
  updateType:
    | 'RECOVER_REMOVED_ITEMS'
    | 'REMOVE_ITEMS_FROM_ORDER'
    | 'ADD_ITEMS_TO_ORDER';
  orderItems: {
    id: number;
    processItem: boolean | null;
  }[];
}
