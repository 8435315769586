export enum BrokerLicenseField {
  LicenseState = 'licenseState',
  Number = 'number',
  Expiration = 'expiration',
  Name = 'name',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  City = 'city',
  State = 'state',
  Zipcode = 'zipcode',
  Email = 'email',
  Phone = 'phone',
}

export enum BrokerLicenseFieldLabel {
  LicenseState = 'License State',
  Number = 'License Number',
  Expiration = 'License Expiration',
  Name = 'Company Name',
  AddressLine1 = 'Address Line 1',
  AddressLine2 = 'Address Line 2',
  City = 'City',
  State = 'State',
  Zipcode = 'Zipcode',
  Email = 'Email',
  Phone = 'Phone',
}

export interface BrokerLicense {
  [BrokerLicenseField.LicenseState]?: string | null;
  [BrokerLicenseField.Number]?: string | null;
  [BrokerLicenseField.Expiration]?: string | null;
  [BrokerLicenseField.Name]?: string | null;
  [BrokerLicenseField.AddressLine1]?: string | null;
  [BrokerLicenseField.AddressLine2]?: string | null;
  [BrokerLicenseField.City]?: string | null;
  [BrokerLicenseField.State]?: string | null;
  [BrokerLicenseField.Zipcode]?: string | null;
  [BrokerLicenseField.Email]?: string | null;
  [BrokerLicenseField.Phone]?: string | null;
}
export interface Personalization {
  ackPrompt: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  brokerLicenses?: BrokerLicense[] | null;
  city: string | null;
  companyLogoUrl: string | null;
  companyName: string | null;
  createdAt: string | null;
  email: string | null;
  firstName: string | null;
  id: number;
  includeAddressLine1: boolean;
  includeAddressLine2: boolean;
  includeAskQuestion: boolean;
  includeCityStateZipcode: boolean;
  includeCompanyLogo: boolean;
  includeCompanyName: boolean;
  includeEmail: boolean;
  includeLicenseNumber?: boolean;
  includeLicenseState?: boolean;
  includeLicenseExpiration?: boolean;
  includeName: boolean;
  includePhone: boolean;
  includePhoto: boolean;
  includeWebsite: boolean;
  lastName: string | null;
  licenseNumber?: string | null;
  licenseState?: string | null;
  licenseExpiration?: string | null;
  organizationId: number;
  phone: string | null;
  photoUrl: string | null;
  state: string | null;
  updatedAt: string | null;
  userEmail: string | null;
  userId: number;
  websiteUrl: string | null;
  zipcode: string | null;
  signatureUrl?: string | null;
  includeSignature?: boolean;
}
