import React from 'react';
import classNames from 'classnames';

import { MinusIcon, PlusIcon } from '../../../../svgs/icons/navigation';
import { RangeSlider } from '../../../controls-and-inputs/controls/RangeSlider';

import styles from './PhotoGridSizeControl.module.css';

export interface PhotoGridSizeControlProps {
  // Current size value
  value: number;
  // Whether size control is hidden
  hidden?: boolean;
  // Callback when the slider is moved to new position
  onChange: (size: number) => void;
  // Callback on mouseup after a change
  onChangeComplete?: (size: number) => void;
  dataHcName: string;
}

const MIN_VALUE = 1;
const MAX_VALUE = 7;
const STEP = 1;

export const PhotoGridSizeControl = ({
  dataHcName,
  onChange,
  onChangeComplete,
  value,
  hidden = false,
}: PhotoGridSizeControlProps) => {
  const hiddenStyle = classNames(styles.SizeToggle, {
    [styles.hidden]: hidden,
  });
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SizeToggle, hiddenStyle)}
    >
      <div className={styles.SizeToggleCell}>
        <div className={styles.ToggleIcon}>
          <MinusIcon size={12} color="neutral-dark-10" />
        </div>
      </div>
      <div
        className={classNames(styles.SizeToggleCell)}
        data-hc-name={`${dataHcName}-slider-cell`}
      >
        <RangeSlider
          initialValue={MAX_VALUE - (value as number) + MIN_VALUE}
          dataHcName={`${dataHcName}-range-slider`}
          className={styles.RangeSlider}
          theme={{
            activeTrack: styles.activeTrack,
            slider: styles.slider,
            track: styles.track,
          }}
          minValue={MIN_VALUE}
          maxValue={MAX_VALUE}
          step={STEP}
          onChange={(v) => onChange?.(MAX_VALUE - (v as number) + MIN_VALUE)}
          onChangeComplete={(v) =>
            onChangeComplete?.(MAX_VALUE - (v as number) + MIN_VALUE)
          }
        />
      </div>
      <div className={styles.SizeToggleCell}>
        <div className={styles.ToggleIcon}>
          <PlusIcon size={12} color="neutral-dark-10" />
        </div>
      </div>
    </div>
  );
};
