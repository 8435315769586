import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { SCROLL_ID } from '@hcs/webapps';

import styles from './LayoutBanner.module.css';

export interface LayoutBannerProps {
  dataHcName: string;
  children?: ReactNode;
  bannerContent?: ReactNode;
  className?: string;
  noPadding?: boolean;
  noOutlet?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  theme?: {
    Banner?: string;
  };
  compact?: boolean;
  backgroundImage?: string;
  light?: boolean;
}
export const LayoutBanner = ({
  dataHcName,
  className,
  noPadding,
  title,
  noOutlet,
  description,
  backgroundImage,
  bannerContent,
  children,
  theme,
  compact = false,
  light,
}: LayoutBannerProps) => {
  return (
    <div
      id={SCROLL_ID}
      className={classNames(styles.LayoutBanner, className, {
        [styles.noPadding]: noPadding,
        [styles.light]: light,
      })}
      data-hc-name={dataHcName}
    >
      <div
        data-hc-name={`${dataHcName}-banner`}
        className={classNames(styles.Banner, theme?.Banner)}
        style={
          backgroundImage
            ? { backgroundImage: `url(${backgroundImage})` }
            : undefined
        }
      >
        <div className={styles.BannerContent}>{bannerContent}</div>
      </div>
      <div className={styles.Content}>
        {title && (
          <>
            <div
              data-hc-name={`${dataHcName}-title`}
              className={compact ? styles.TitleCompact : styles.Title}
            >
              {title}
            </div>
            {description && (
              <div
                data-hc-name={`${dataHcName}-description`}
                className={
                  compact ? styles.DescriptionCompact : styles.Description
                }
              >
                {description}
              </div>
            )}
          </>
        )}
        {noOutlet === true ? null : <Outlet />}
        {children}
      </div>
    </div>
  );
};
