import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { DirectionalChevron } from '../../../../foundations';
import { CloseButton, IconButton } from '../../../controls-and-inputs';

import styles from './Header.module.css';

export const HEADER_HEIGHT_LG = 80;
export const HEADER_HEIGHT_SM = 60;

export interface HeaderProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  dataHcEventSection?: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  /** Title text */
  title?: ReactNode;
  /** Height of header */
  size?: 'lg' | 'sm';
  /** Header content justification  */
  justifyContent?: 'spaceBetween' | 'flexStart';
  /** Show Back Button */
  onBack?: VoidFunction;
  /** Show Close Button */
  onClose?: VoidFunction;
  children?: ReactNode;
}
export const Header = ({
  dataHcName,
  dataHcEventSection,
  className,
  children,
  size = 'lg',
  justifyContent = 'flexStart',
  title,
  onBack,
  onClose,
}: HeaderProps) => {
  return (
    <header
      data-hc-name={`${dataHcName}-header`}
      data-hc-event-section={dataHcEventSection}
      className={classNames(styles.Header, styles[size], className, {
        [styles.noBack]: !onBack,
      })}
    >
      {onBack && (
        <IconButton
          className={styles.ChevronButton}
          dataHcName={`${dataHcName}-close-button`}
          icon={
            <DirectionalChevron
              className={styles.Chevron}
              direction="left"
              size="sm"
            />
          }
          onClick={onBack}
        />
      )}
      <div
        className={classNames(styles.HeaderContent, {
          [styles.flexStart]: justifyContent === 'flexStart',
          [styles.spaceBetween]: justifyContent === 'spaceBetween',
        })}
      >
        {title && (
          <h1 data-hc-name={`${dataHcName}-title`} className={styles.Title}>
            {title}
          </h1>
        )}
        {children}
      </div>
      {onClose && (
        <CloseButton
          dataHcName={`${dataHcName}-closeButton`}
          onClick={onClose}
        />
      )}
    </header>
  );
};
