import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmOrder } from '@hcs/types';
import { ReportSummaryCellContentProps } from '@hcs/types';

interface Params {
  tableHeaderName: string;
  CellContentComponent: React.ElementType<ReportSummaryCellContentProps>;
}

export const createReportSummaryTableCellConfig = ({
  CellContentComponent,
  tableHeaderName,
}: Params) => {
  const Display = ({ order }: ReportSummaryCellContentProps) => (
    <CellContentComponent order={order} />
  );

  const HeaderCell = ({ ...tableHeaderCellProps }) => {
    return (
      <TableHeaderCell {...tableHeaderCellProps}>
        {tableHeaderName}
      </TableHeaderCell>
    );
  };

  const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
    isTableHeaderCell: true,
  };

  HeaderCell.defaultProps = defaultHeaderCellProps;

  const ContentCell = ({ order, ...tableCellProps }: { order: OmOrder }) => {
    return (
      <TableCell {...tableCellProps}>
        <Display order={order} />
      </TableCell>
    );
  };

  const defaultContentProps: Partial<TableCellProps> = {
    isTableCell: true,
  };

  ContentCell.defaultProps = defaultContentProps;

  return {
    HeaderCell,
    ContentCell,
    label: tableHeaderName,
  };
};
