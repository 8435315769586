import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { OptionalEngagementProps } from '@hcs/types';

import { CheckIcon } from '../../../../svgs/icons/indicator';
import { PlusIcon } from '../../../../svgs/icons/navigation';

import styles from './SelectButton.module.css';

export interface SelectButtonProps extends OptionalEngagementProps {
  dataHcName: string;
  className?: string;
  /** Render in the selected state. Will default to false. */
  selected: boolean;
  /** Disables the button, making it unclickable and grey. */
  disabled?: boolean;
  small?: boolean;
  hoverText?: {
    selected: ReactNode;
    unselected: ReactNode;
  };
  /** Fires after clicking the button when enabled. */
  onClick: () => void;
}

export const SelectButton = ({
  className,
  dataHcName,
  dataHcEventName,
  dataHcEventSection,
  dataHcEventType,
  selected,
  small,
  disabled,
  hoverText,
  onClick,
}: SelectButtonProps) => {
  const Icon = selected ? CheckIcon : PlusIcon;
  return (
    <button
      data-hc-name={dataHcName}
      data-hc-event-name={dataHcEventName}
      data-hc-event-type={dataHcEventType}
      data-hc-event-section={dataHcEventSection}
      className={classNames(
        styles.SelectButton,
        {
          [styles.hoverable]: !!hoverText && !disabled,
          [styles.small]: small,
          [styles.selected]: selected,
          [styles.disabled]: disabled,
        },
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
      }}
      role="option"
      aria-selected={selected}
    >
      {hoverText && (
        <div className={styles.HoverText}>
          <div
            className={styles.Text}
            data-hc-name={`${dataHcName}-hover-text`}
          >
            {selected ? hoverText.selected : hoverText.unselected}
          </div>
        </div>
      )}

      <div className={styles.IconContainer}>
        <Icon
          size={selected ? 40 : 16}
          color={!selected ? 'neutral-dark-10' : 'neutral-light-10'}
        />
      </div>
    </button>
  );
};
