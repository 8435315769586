import {
  EngagementEvent,
  EngagementEventData,
  MeaningfulEventTypes,
} from '@hcs/types';

export const isMeaningfulEvent = (event: EngagementEvent) => {
  return (
    event.eventType &&
    Object.values<string>(MeaningfulEventTypes).includes(event.eventType)
  );
};
const findEventSection = (element: Element): string | undefined => {
  const dataHcEventSection = element.getAttribute('data-hc-event-section');
  if (dataHcEventSection) {
    return dataHcEventSection;
  } else {
    const parentElement = element.parentElement;
    if (parentElement) {
      return findEventSection(parentElement);
    } else {
      return undefined;
    }
  }
};

export interface BuildEngagementEventOptions {
  findAncestor?: boolean;
  eventData?: Partial<EngagementEventData>;
  eventType?: string;
}
export const buildEngagementEventFromElement = (
  element: Element,
  eventPrefix: string,
  options?: BuildEngagementEventOptions
): EngagementEvent | undefined => {
  const dataHcName = element.getAttribute('data-hc-name');
  const dataHcEventName = element.getAttribute('data-hc-event-name');
  const dataHcEventIgnore = element.getAttribute('data-hc-event-ignore');
  if (!dataHcEventIgnore && (dataHcName || dataHcEventName)) {
    const dataHcEventType =
      element.getAttribute('data-hc-event-type') || undefined;
    const dataHcEventDataRaw = element.getAttribute('data-hc-event-data');
    const dataHcEventData = dataHcEventDataRaw
      ? JSON.parse(dataHcEventDataRaw)
      : undefined;
    const dataHcEventSection = findEventSection(element);
    return {
      eventData: {
        ...dataHcEventData,
        ...options?.eventData,
        eventSection: dataHcEventSection,
        dataHcName,
        originalEventType: dataHcEventType ? options?.eventType : undefined,
      },
      eventType: dataHcEventType || options?.eventType,
      eventName: dataHcEventName || `${eventPrefix}-${dataHcName}`,
    };
  } else if (options?.findAncestor && element.parentElement) {
    return buildEngagementEventFromElement(
      element.parentElement,
      eventPrefix,
      options
    );
  }
  return undefined;
};

export const getPageGroupFromUrl = () => {
  return window.location.pathname.split('/')[2];
};
