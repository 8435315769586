import React, { ReactNode } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { LoadingSpinner } from '@hcs/design-system';
import { URL_PARAM_REDIRECT } from '@hcs/webapps';

import { DATA_HC_NAME_PAGE_AUTH_SPINNER } from '../../constants';
import { useAccount } from '../../hooks/useAccount';

export interface UnauthenticatedPageProps {
  authenticatedRender?: ReactNode;
  children: ReactNode;
}
// Renders Page content (children) when if the user is not logged,
// a loading spinner if checking the token
// value of the `authenticatedRender` prop if the token is valid
export const UnauthenticatedPage = ({
  children,
  authenticatedRender,
}: UnauthenticatedPageProps) => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get(URL_PARAM_REDIRECT);
  const { isSuccess, isFetched } = useAccount();
  if (isSuccess) {
    return authenticatedRender ? (
      authenticatedRender
    ) : (
      <Navigate to={redirect || '/'} replace />
    );
  } else if (!isFetched) {
    return (
      <LoadingSpinner fixedCenter dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />
    );
  }
  return children;
};
