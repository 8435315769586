import React from 'react';

import { CardContentTabs, VerticalTab } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { ReportId } from '@hcs/types';
import { ArrayOneOrMore } from '@hcs/types';

import {
  NEARBY_CHART_FEATURES,
  NearbyChart,
} from '../../features/NearbyPropertiesChart';
import {
  SIMILAR_PROPERTIES_CHARTS_FEATURES,
  SimilarPropertiesCharts,
} from '../../features/SimilarPropertiesChart';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import { FORECAST_CHART_FEATURES, ForecastChart } from '../ForecastChart';
import { ForecastChartDeprecated } from '../ForecastChartDeprecated';
import { ForecastChartButtonSummary } from '../ForecastChartDeprecated/ForecastChartButtonSummary';
import { MARKET_ANALYSIS_FEATURES } from '../MarketAnalysis';
import { MarketAnalysisButtonSummary } from '../MarketAnalysis/MarketAnalysisButtonSummary';
import { MarketAnalysisCarousel } from '../MarketAnalysis/MarketAnalysisCarousel';
import { SimilarPropertiesChartButtonSummary } from '../SimilarPropertiesChart/SimilarPropertiesChartButtonSummary';

import styles from './NeighborhoodInsightsSection.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}

const dataHcName = 'property-explorer-charts';
export const dataHcEventSectionNeighborhoodInsights = 'Neighborhood Insights';
export const NEIGHBORHOOD_INSIGHTS_SECTION_FEATURES = [
  ...MARKET_ANALYSIS_FEATURES,
  ...FORECAST_CHART_FEATURES,
  ...NEARBY_CHART_FEATURES,
  ...SIMILAR_PROPERTIES_CHARTS_FEATURES,
];
export const NeighborhoodInsightsSection = ({ reportId, className }: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const tabs: VerticalTab[] = [];
  if (reportFeaturesSupportedAny(reportConfig, NEARBY_CHART_FEATURES)) {
    tabs.push({
      tabId: 'nearbyProperties',
      tabLabelTop: 'Nearby Properties',
      tabContent:
        'See how this property matches up against other properties close by.',
      noPadding: true,
      content: (
        <NearbyChart
          reportId={reportId}
          className={styles.NearbyChart}
          theme={{ ChartLegend: styles.ChartLegend }}
        />
      ),
    });
  }
  if (
    reportFeaturesSupportedAny(reportConfig, SIMILAR_PROPERTIES_CHARTS_FEATURES)
  ) {
    tabs.push({
      tabId: 'similarProperties',
      tabLabelTop: 'Similar Properties Sold',
      tabContent: <SimilarPropertiesChartButtonSummary reportId={reportId} />,
      content: (
        <SimilarPropertiesCharts
          className={styles.SimilarChart}
          reportId={reportId}
        />
      ),
    });
  }
  if (reportFeaturesSupportedAny(reportConfig, FORECAST_CHART_FEATURES)) {
    tabs.push({
      tabId: 'forecast',
      tabLabelTop: 'HouseCanary Forecast',
      tabContent: <ForecastChartButtonSummary reportId={reportId} />,
      content: (
        <>
          <ExperienceFlag
            experienceFlagId="PEXP_REDESIGN"
            now={
              <ForecastChartDeprecated
                reportId={reportId}
                chartDimensions={{
                  height: 250,
                  width: 700,
                  padding: { top: 15, right: 15, bottom: 30, left: 80 },
                }}
              />
            }
          />
          <ExperienceFlag
            experienceFlagId="PEXP_REDESIGN"
            next={<ForecastChart reportId={reportId} />}
          />
        </>
      ),
    });
  }
  if (reportFeaturesSupportedAny(reportConfig, MARKET_ANALYSIS_FEATURES)) {
    tabs.push({
      tabId: 'market',
      tabLabelTop: 'Market Analysis',
      tabContent: <MarketAnalysisButtonSummary reportId={reportId} />,
      // "1y Risk of Decline: 2.6% (Very_low) Market Status: Seller's Market",
      content: <MarketAnalysisCarousel reportId={reportId} />,
    });
  }
  if (!tabs.length) return null;
  return (
    <CardContentTabs
      searchParamId="neighborhoodInsightsSection"
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSectionNeighborhoodInsights}
      className={className}
      tabs={
        // safe to cast because we check tabs.length above
        tabs as ArrayOneOrMore<VerticalTab>
      }
    />
  );
};
