import { OmOrder } from '@hcs/types';

import { ORDER_STATUSES } from '../../constants/Order.constants';

export const orderNeedReview = (order: OmOrder): boolean => {
  return (
    !order.cancelled &&
    (order.status === ORDER_STATUSES.CLIENT_REVIEW ||
      order.orderItemsToReviewCount > 0)
  );
};
