import { isAxiosError } from 'axios';
import { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import fileDownload from 'js-file-download';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import {
  ExportRequest,
  OrderTypeDescriptor,
  OrderViaAddressEntry,
  OrderViaAddressEntryError,
  OrderViaCsv,
  OrderViaCsvError,
  ParsedAddressResponse,
} from '@hcs/types';
import { ORDER_MANAGER_CLIENT_API_URL } from '@hcs/urls';

import { convertToFormDataShallow } from '../hooks/utils';
import { orderTypeDescriptorValuesSnakeToCamel } from '../utils/orders.utils';

export class ForbiddenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

export class ValidationErrorCsv extends Error {
  data: OrderViaCsvError;
  constructor(message: string, data: OrderViaCsvError) {
    super(message);
    this.name = 'ValidationErrorCsv';
    this.data = data;
  }
}

export class ValidationErrorAddressEntry extends Error {
  data: OrderViaAddressEntryError;
  constructor(message: string, data: OrderViaAddressEntryError) {
    super(message);
    this.name = 'ValidationErrorAddressEntry';
    this.data = data;
  }
}

export type OrderSubmitEntryError =
  | ForbiddenError
  | ValidationErrorAddressEntry
  | AxiosError;

export type OrderSubmitCsvError =
  | ForbiddenError
  | ValidationErrorCsv
  | AxiosError;

const ORDERS_URL = `${ORDER_MANAGER_CLIENT_API_URL}/orders/`;

const orderTypes = async () => {
  const url = `${ORDER_MANAGER_CLIENT_API_URL}/order_types`;
  const response = await AxiosAccessTokenClientJwt.get<OrderTypeDescriptor[]>(
    url
  );

  return response.data.map((descriptor) => {
    const snakeToCamelDescriptor = camelcaseKeys<OrderTypeDescriptor>(
      descriptor,
      { deep: true }
    );

    return orderTypeDescriptorValuesSnakeToCamel(snakeToCamelDescriptor);
  });
};

const handleOrderAddressEntryError = (e: unknown) => {
  if (isAxiosError<OrderViaAddressEntryError>(e)) {
    if (e.response?.status === 403) {
      throw new ForbiddenError(
        'You do not have permission to create orders. Please contact your administrator.'
      );
    } else if (e.response?.status === 400 && e.response?.data) {
      const data = camelcaseKeys(e.response.data, { deep: true });
      throw new ValidationErrorAddressEntry(e.message, data);
    }
  }
  throw e;
};

const handleOrderCsvError = (e: unknown) => {
  if (isAxiosError<OrderViaCsvError>(e)) {
    if (e.response?.status === 403) {
      throw new ForbiddenError(
        'You do not have permission to create orders. Please contact your administrator.'
      );
    } else if (e.response?.status === 400 && e.response?.data) {
      const data = camelcaseKeys(e.response.data, { deep: true });
      throw new ValidationErrorCsv(e.message, data);
    }
  }
  throw e;
};

const newOrderSubmitEntry = async (body: OrderViaAddressEntry) => {
  try {
    const url = `${ORDERS_URL}json/`;
    const snakecasePayload = snakecaseKeys(body, {
      deep: true,
    });

    await AxiosAccessTokenClientJwt.post(url, snakecasePayload);
  } catch (e) {
    handleOrderAddressEntryError(e);
  }
};

const newOrderSubmitCsv = async (body: OrderViaCsv) => {
  try {
    const url = `${ORDERS_URL}csv/`;
    const snakecasePayload = snakecaseKeys(body, { deep: false });

    const formDataPayload = convertToFormDataShallow(snakecasePayload);

    await AxiosAccessTokenClientJwt.post(url, formDataPayload);
  } catch (e) {
    handleOrderCsvError(e);
  }
};

const newOrderAddAddresses = async (
  orderId: number,
  addresses: ParsedAddressResponse[]
) => {
  try {
    const url = `${ORDERS_URL}${orderId}/json`;
    const snakecasePayload = snakecaseKeys(addresses, { deep: true });

    await AxiosAccessTokenClientJwt.post(url, {
      items: snakecasePayload,
    });
  } catch (e) {
    handleOrderAddressEntryError(e);
  }
};

const newOrderAddAddressesCsv = async (orderId: number, body: OrderViaCsv) => {
  try {
    const url = `${ORDERS_URL}${orderId}/csv/`;
    const snakecasePayload = snakecaseKeys(body, { deep: false });

    const formDataPayload = convertToFormDataShallow(snakecasePayload);

    await AxiosAccessTokenClientJwt.post(url, formDataPayload);
  } catch (e) {
    handleOrderCsvError(e);
  }
};

const downloadSummary = async (orderId: number, fileName: string) => {
  const url = `${ORDERS_URL}${orderId}/summary_csv`;

  await AxiosAccessTokenClientJwt.get(url, {
    headers: {
      'Content-Type': 'application/zip',
    },
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, fileName);
  });
};

const downloadReportSummaryCsv = async (orderId: number, fileName: string) => {
  const url = `${ORDERS_URL}${orderId}/report_summary_csv`;

  await AxiosAccessTokenClientJwt.get(url, {
    headers: {
      'Content-Type': 'application/zip',
    },
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, fileName);
  });
};

const exportRequests = async (orderId: number) => {
  const url = `${ORDERS_URL}${orderId}/export-requests/`;

  const response = await AxiosAccessTokenClientJwt.get<ExportRequest[]>(url, {
    params: {
      ordering: '-id',
    },
  });

  return camelcaseKeys(response.data);
};

const initiateNewDownload = async (orderId: number, excludeJson: boolean) => {
  const url = `${ORDERS_URL}${orderId}/export/zip`;

  await AxiosAccessTokenClientJwt.post(url, undefined, {
    params: {
      exclude_json: excludeJson ? 'True' : 'False',
    },
  });
};

const cancelOrder = async (orderId: number) => {
  const url = `${ORDERS_URL}${orderId}/cancel`;

  await AxiosAccessTokenClientJwt.post(url);
};

const reviewAcceptedOrder = async (orderId: number) => {
  const url = `${ORDERS_URL}${orderId}/accept`;

  await AxiosAccessTokenClientJwt.post(url);
};

export const NewOrderApi = {
  orderTypes,
  cancelOrder,
  downloadReportSummaryCsv,
  downloadSummary,
  exportRequests,
  initiateNewDownload,
  newOrderSubmitEntry,
  newOrderSubmitCsv,
  newOrderAddAddresses,
  newOrderAddAddressesCsv,
  reviewAcceptedOrder,
};
