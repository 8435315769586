import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useControlledInputHybrid = <T extends string | number | null>(
  value: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);
  return [internalValue, setInternalValue];
};
