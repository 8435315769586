import { gql } from 'graphql-request';

export const FRAGMENT_PROPERTY_VALUE_RENTAL_CORE = gql`
  fragment PropertyValueRentalCore on RentalValue {
    condition
    fsd
    fsd90Percent
    value
    valueLower
    valueLowerMin
    valueMedian
    valueUpper
    valueUpperMax
  }
`;
