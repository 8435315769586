import React from 'react';
import classNames from 'classnames';

import { DotLabel } from '../DotLabel';

import styles from './ActiveDisabledCount.module.css';

export interface ActiveDisabledCountProps {
  className?: string;
  dataHcName: string;
  numActive: number;
  activeLabel: string;
  numDisabled: number;
  disabledLabel: string;
  hideDisabled?: boolean;
}
export const ActiveDisabledCount = (props: ActiveDisabledCountProps) => {
  const {
    className,
    dataHcName,
    numActive,
    activeLabel,
    numDisabled,
    disabledLabel,
    hideDisabled = false,
  } = props;

  return (
    <div
      className={classNames(styles.ActiveDisabledCount, className)}
      data-hc-name={dataHcName}
    >
      <div className={styles.Container}>
        <DotLabel
          dataHcName={`${dataHcName}-active-indicator`}
          dotColor={{ colorType: 'green' }}
          label={
            <>
              <span
                className={styles.Total}
                data-hc-name={`${dataHcName}-active-total`}
              >
                {numActive}
              </span>
              {activeLabel}
            </>
          }
        />
      </div>
      {!hideDisabled && (
        <div className={styles.Container}>
          <DotLabel
            dataHcName={`${dataHcName}-disabled-indicator`}
            dotColor={{ colorType: 'red' }}
            label={
              <>
                <span
                  className={styles.Total}
                  data-hc-name={`${dataHcName}-disabled-total`}
                >
                  {numDisabled}
                </span>
                {disabledLabel}
              </>
            }
          />
        </div>
      )}
    </div>
  );
};
