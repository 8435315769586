import React from 'react';
import classNames from 'classnames';

import { AvmFactor } from '@hcs/types';

import { BAR_ONE_COLOR } from '../../../features/AvmBreakdown';
import { AvmBreakdownPieChart } from '../../../features/AvmBreakdown/AvmBreakdownPieChart/AvmBreakdownPieChart';

import styles from './AvmBreakdownPie.module.css';

const dataHcName = 'pool-and-basement-pie-chart';

interface Props {
  avmFactor: AvmFactor;
  id: string;
  labelValue: number;
  count: number;
  label: string;
  chartType: 'Pool' | 'Basement';
}

const CHART_HEIGHT = 420;
const CHART_WIDTH = 500;

export const AvmBreakdownPie = ({
  avmFactor,
  id,
  labelValue,
  chartType,
  count,
  label,
}: Props) => {
  const isPositive = avmFactor.value >= 0;

  const data = [
    {
      id,
      labelValue,
      count,
      label,
      value: avmFactor?.value || 0,
      color: BAR_ONE_COLOR,
    },
  ];

  return (
    <div data-hc-name={dataHcName} className={styles.ChartContainer}>
      <AvmBreakdownPieChart
        chartType={chartType}
        data={data}
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
      />
      <div className={styles.ChartFooter}>
        <label
          data-hc-name={`${dataHcName}-footer-label`}
        >{`${chartType}`}</label>
        <br />
        <span
          data-hc-name={`${dataHcName}-footer-accessible-comparison-description`}
        >
          {avmFactor.accessibleComparisonDescription}{' '}
        </span>
        <strong
          data-hc-name={`${dataHcName}-footer-value-description`}
          className={classNames({
            [styles.TextBlue || '']: isPositive,
            [styles.TextRed || '']: !isPositive,
          })}
        >
          {avmFactor.valueDescription}
        </strong>
      </div>
    </div>
  );
};
