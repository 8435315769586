import { EventTypes, ListingStatusCerberus, ListingStatusNormalized } from '@hcs/types';

export const LISTING_STATUSES_ACTIVE: ListingStatusCerberus[] = [
  ListingStatusCerberus.Active,
  ListingStatusCerberus.ComingSoon
];
export const LISTING_STATUSES_PENDING: ListingStatusCerberus[] = [
  ListingStatusCerberus.Pending,
  ListingStatusCerberus.ActiveUnderContract
];
export const LISTING_STATUSES_ACTIVE_PENDING: ListingStatusCerberus[] = [
  ...LISTING_STATUSES_ACTIVE,
  ...LISTING_STATUSES_PENDING
];

export const LISTING_STATUSES_CLOSED: ListingStatusCerberus[] = [
  ListingStatusCerberus.Closed,
  ListingStatusCerberus.Canceled,
  ListingStatusCerberus.Expired,
  ListingStatusCerberus.Withdrawn
];
export const LISTING_STATUSES_LEASED: ListingStatusCerberus[] = [
  ListingStatusCerberus.Closed
];

export const LISTING_STATUSES_BY_NORMALIZED_STATUS: {
  [ListingStatusCerberus.Closed]: ListingStatusCerberus[];
  [ListingStatusCerberus.Pending]: ListingStatusCerberus[];
  [ListingStatusCerberus.Active]: ListingStatusCerberus[];
} = {
  [ListingStatusCerberus.Closed]: LISTING_STATUSES_CLOSED,
  [ListingStatusCerberus.Pending]: LISTING_STATUSES_PENDING,
  [ListingStatusCerberus.Active]: LISTING_STATUSES_ACTIVE
};

export const lookupListingStatusByNormalizedStatus = (
  listingStatusNormalized: ListingStatusNormalized
): ListingStatusCerberus[] =>
  LISTING_STATUSES_BY_NORMALIZED_STATUS[listingStatusNormalized];

export const listingStatusNormalized = (
  listingStatus: ListingStatusCerberus | undefined | null
): ListingStatusNormalized | undefined => {
  return listingStatus
    ? LISTING_STATUSES_ACTIVE.includes(listingStatus)
      ? ListingStatusCerberus.Active
      : LISTING_STATUSES_PENDING.includes(listingStatus)
      ? ListingStatusCerberus.Pending
      : LISTING_STATUSES_CLOSED.includes(listingStatus)
      ? ListingStatusCerberus.Closed
      : undefined
    : undefined;
};

export const listingStatusNormalizedFromListingEvent = (
  listingEvent: EventTypes | null | undefined
): ListingStatusNormalized | undefined => {
  switch (listingEvent) {
    case 'Sold':
    case 'Leased': {
      return ListingStatusCerberus.Closed;
    }
    case 'Lease Pending':
    case 'Pending': {
      return ListingStatusCerberus.Pending;
    }
    case 'Active':
    case 'Listed For Rent':
    case 'Listed': {
      return ListingStatusCerberus.Active;
    }
    default: {
      return undefined;
    }
  }
};

export const listingStatusToEvent = (
  listingStatusCerberus: ListingStatusCerberus | null | undefined,
  isRental?: boolean
): EventTypes | undefined => {
  switch (listingStatusCerberus) {
    case ListingStatusCerberus.Active:
    case ListingStatusCerberus.ComingSoon: {
      return isRental ? 'Listed For Rent' : 'Listed';
    }

    case ListingStatusCerberus.ActiveUnderContract:
    case ListingStatusCerberus.Pending: {
      return isRental ? 'Lease Pending' : 'Pending';
    }

    case ListingStatusCerberus.Closed: {
      return isRental ? 'Leased' : 'Sold';
    }

    case ListingStatusCerberus.Canceled:
    case ListingStatusCerberus.Delete: {
      return isRental ? 'Deleted Rental Listing' : 'Cancelled';
    }

    case ListingStatusCerberus.Withdrawn: {
      return 'Withdrawn';
    }

    default: {
      return 'Off Market';
    }
  }
};

export const isListStatusNormalizedArray = (
  listingStatus?: ListingStatusCerberus | ListingStatusNormalized[]
): listingStatus is ListingStatusNormalized[] => {
  return (listingStatus as ListingStatusNormalized[])?.length !== undefined;
};

export const listingStatusListNormalizedToFull = (
  normalizedStatuses: ListingStatusNormalized[]
): ListingStatusCerberus[] => {
  return normalizedStatuses.flatMap((n) => {
    switch (n) {
      case ListingStatusCerberus.Active: {
        return LISTING_STATUSES_ACTIVE;
      }
      case ListingStatusCerberus.Pending: {
        return LISTING_STATUSES_PENDING;
      }
      default: {
        return LISTING_STATUSES_CLOSED;
      }
    }
  });
};

export const listingStatusListFullToNormalized = (
  statuses: ListingStatusCerberus[]
): ListingStatusNormalized[] => {
  const normalized: ListingStatusNormalized[] = [];
  statuses.forEach((n) => {
    if (LISTING_STATUSES_ACTIVE.indexOf(n) > -1) {
      normalized.push(ListingStatusCerberus.Active);
    } else if (LISTING_STATUSES_PENDING.indexOf(n) > -1) {
      normalized.push(ListingStatusCerberus.Pending);
    } else {
      normalized.push(ListingStatusCerberus.Closed);
    }
  });
  return normalized;
};
