import { RefObject } from 'react';
import { MultiPolygon } from 'geojson';

import {
  Bounds,
  CompDocument,
  DocumentBase,
  DocumentRoles,
  OrgUser,
  OrgUserOrInvitation} from '@hcs/types';

export const isCompDocument = (doc: DocumentBase): doc is CompDocument => {
  return doc.role === DocumentRoles.Comp;
};

export const isMultiPolygon = (
  geom?: GeoJSON.Geometry
): geom is MultiPolygon => {
  return geom?.type === 'MultiPolygon';
};

export const isBounds = (bounds?: unknown[][]): bounds is Bounds => {
  return (
    typeof bounds?.[0]?.[0] === 'number' &&
    typeof bounds?.[0]?.[1] === 'number' &&
    typeof bounds?.[1]?.[0] === 'number' &&
    typeof bounds?.[1]?.[1] === 'number' &&
    bounds?.length === 2 &&
    bounds[0].length === 2 &&
    bounds[1].length === 2
  );
};

export const isRefObject = <T>(ref?: unknown): ref is RefObject<T> => {
  return typeof ref === 'object' && ref !== null && 'current' in ref;
};

export const isOrgUser = (
  userOrInvite: OrgUserOrInvitation
): userOrInvite is OrgUser => {
  return userOrInvite.status === 'Active';
};
