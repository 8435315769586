import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Card, CloseButton, MapButton } from '@hcs/design-system';
import { LayersControlConfig } from '@hcs/types';

import { useHcMap } from '../../hooks/useHcMap';

import { VectilesMetricControl } from './VectilesMetricControl';
import { VectilesMetricGroupControl } from './VectilesMetricGroupControl';

import styles from './HcMapLayersControl.module.css';

interface Props extends LayersControlConfig {
  mapId: string;
  className?: string;
}
const dataHcName = 'heatmaps-control';
export const HcMapLayersControl = ({
  mapId,
  vectilesMetricGroups,
  position,
  toggles,
  className,
}: Props) => {
  const {
    mapState,
    actions: { hcMapShowMarkers, hcMapChangeVectilesMetricGroup },
  } = useHcMap(mapId);
  const [flowStarted, setFlowStarted] = useState(
    !!mapState?.heatmap.vectilesMetricId ||
      !!mapState?.heatmap.vectilesMetricGroup
  );
  const { vectilesMetricId, vectilesMetricGroup } = mapState?.heatmap || {};

  useEffect(() => {
    // Reset flow when layer is turned off
    if (flowStarted && !vectilesMetricId && !vectilesMetricGroup) {
      setFlowStarted(false);
    } // Cannot add flowStarted to deps because it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vectilesMetricId, vectilesMetricGroup]);

  if (!mapState) return null;
  const handleFlowStart = () => {
    setFlowStarted(true);
  };

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.HcMapLayerControl,
        styles[position],
        className
      )}
    >
      {!flowStarted && !vectilesMetricGroup && !vectilesMetricId && (
        <MapButton
          dataHcName={`${dataHcName}-start-button`}
          onClick={handleFlowStart}
          label="Map Layers"
        />
      )}
      {(flowStarted || vectilesMetricId) && (
        <Card
          dataHcName={`${dataHcName}-controls`}
          noPadding
          theme={{ Card: styles.Card }}
        >
          {!vectilesMetricGroup && !vectilesMetricId && (
            <VectilesMetricGroupControl
              mapId={mapId}
              vectilesMetricGroups={vectilesMetricGroups}
            />
          )}
          {vectilesMetricId && (
            <VectilesMetricControl
              mapId={mapId}
              toggles={toggles}
              hideMonochrome
            />
          )}
          <CloseButton
            size="sm"
            className={styles.CloseButton}
            dataHcName={`${dataHcName}-close-button`}
            onClick={() => {
              hcMapShowMarkers({ mapId, showMarkers: true });
              hcMapChangeVectilesMetricGroup({
                mapId,
                vectilesMetricGroup: null,
              });
              setFlowStarted(false);
            }}
          />
        </Card>
      )}
    </div>
  );
};
