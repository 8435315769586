import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import {
  QUERY_PROPERTY_STATE_CORE_HC,
  QUERY_PROPERTY_STATE_CORE_MLS,
  QUERY_PROPERTY_STATE_CORE_PR,
  QUERY_PROPERTY_STATE_PREVIEW_HC,
  QUERY_PROPERTY_STATE_PREVIEW_MLS,
  QUERY_PROPERTY_STATE_PREVIEW_PR,
} from '@hcs/cerberus';
import {
  CerberusInput,
  Exact,
  PropertyStateCoreHcDocument,
  PropertyStateCoreHcQuery,
  PropertyStateCoreMlsDocument,
  PropertyStateCoreMlsQuery,
  PropertyStateCorePrDocument,
  PropertyStateCorePrQuery,
  PropertyStatePreviewHcDocument,
  PropertyStatePreviewHcQuery,
  PropertyStatePreviewMlsDocument,
  PropertyStatePreviewMlsQuery,
  PropertyStatePreviewPrDocument,
  PropertyStatePreviewPrQuery,
} from '@hcs/types';
import { PropertyStateSources, PropertyStateType } from '@hcs/types';

export const QUERIES_BY_TYPE_BY_SOURCE: {
  [key in PropertyStateType]: {
    [PropertyStateSources.HC]: TypedDocumentNode<
      PropertyStatePreviewHcQuery | PropertyStateCoreHcQuery,
      Exact<{ cerberusInput: CerberusInput }>
    >;
    [PropertyStateSources.MLS]: TypedDocumentNode<
      PropertyStatePreviewMlsQuery | PropertyStateCoreMlsQuery,
      Exact<{ cerberusInput: CerberusInput }>
    >;
    [PropertyStateSources.PR]: TypedDocumentNode<
      PropertyStatePreviewPrQuery | PropertyStateCorePrQuery,
      Exact<{ cerberusInput: CerberusInput }>
    >;
  };
} = {
  [PropertyStateType.Preview]: {
    [PropertyStateSources.HC]: PropertyStatePreviewHcDocument,
    [PropertyStateSources.MLS]: PropertyStatePreviewMlsDocument,
    [PropertyStateSources.PR]: PropertyStatePreviewPrDocument,
  },
  [PropertyStateType.Core]: {
    [PropertyStateSources.HC]: PropertyStateCoreHcDocument,
    [PropertyStateSources.MLS]: PropertyStateCoreMlsDocument,
    [PropertyStateSources.PR]: PropertyStateCorePrDocument,
  },
};

export const QUERIES_BY_TYPE_BY_SOURCE_FOR_DOCS: {
  [key in PropertyStateType]: {
    [key in PropertyStateSources]: string;
  };
} = {
  [PropertyStateType.Preview]: {
    [PropertyStateSources.HC]: QUERY_PROPERTY_STATE_PREVIEW_HC,
    [PropertyStateSources.MLS]: QUERY_PROPERTY_STATE_PREVIEW_MLS,
    [PropertyStateSources.PR]: QUERY_PROPERTY_STATE_PREVIEW_PR,
  },
  [PropertyStateType.Core]: {
    [PropertyStateSources.HC]: QUERY_PROPERTY_STATE_CORE_HC,
    [PropertyStateSources.MLS]: QUERY_PROPERTY_STATE_CORE_MLS,
    [PropertyStateSources.PR]: QUERY_PROPERTY_STATE_CORE_PR,
  },
};

export const PROPERTY_STATE_TYPE_DESCRIPTIONS = {
  [PropertyStateType.Preview]: `For requesting limited data shown on PropertyCards, "Preview" screens, or the OfferNow form`,
  [PropertyStateType.Core]: `For requesting all fields shown on a full PEXP report`,
};
