import React from 'react';

import { Skeleton, SkeletonProps } from './Skeleton';

export const SkeletonLoader = ({
  children,
  isLoading,
  ...skeletonProps
}: SkeletonProps & { isLoading: boolean }) => {
  if (isLoading) {
    return <Skeleton {...skeletonProps} />;
  } else {
    return children;
  }
};
