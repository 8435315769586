import { useQuery } from '@tanstack/react-query';

import { useIsLoggedIn } from '@hcs/auth';

import { HuellApi } from '../api';

const QUERY_KEY_FEATURE_FLAGS_USER = 'featureFlags-user';
export const useFeatureFlagsForUser = () => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  return useQuery(
    [QUERY_KEY_FEATURE_FLAGS_USER],
    HuellApi.fetchFeatureFlagsForUser,
    {
      enabled: isLoggedIn,
    }
  );
};
