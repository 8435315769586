import { useAccount } from '@hcs/auth';
import { CapabilityKeys } from '@hcs/types';

import { useCapabilityCheckForUser } from './useCapabilityCheckForUser';

export const useCapabilityCheck = (capabilityKey: CapabilityKeys) => {
  const legacyAccountQuery = useAccount();
  return useCapabilityCheckForUser(
    legacyAccountQuery.data?.user.id,
    capabilityKey
  );
};
