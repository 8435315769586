import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { PrivatePage } from '@hcs/auth';
import { VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM } from '@hcs/hc-products';
import { createRedirectQuerystring } from '@hcs/utils';

// Creates a page component that is accessible to anyone with a valid token
// Attempts to refresh accessToken if it is invalid and will redirect to login
// If token is invalid
export const PrivatePageRedirect = ({
  children,
  noRedirectQuery
}: {
  children: ReactNode;
  noRedirectQuery?: boolean;
}) => {
  const redirectQuery = noRedirectQuery ? '' : createRedirectQuerystring();
  return (
    <PrivatePage
      unauthenticatedRender={
        <Navigate
          to={`${VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGIN}${redirectQuery}`}
          replace
        />
      }
    >
      {children}
    </PrivatePage>
  );
};
