import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';

import { MapOutlineConfig } from '@hcs/types';

const DEFAULT_LAYER_STYLE: LayerProps[] = [
  {
    id: 'msa-fill-style',
    type: 'fill',
    paint: {
      'fill-color': '#0A62FF',
      'fill-opacity': 0.3,
    },
  },
  {
    id: 'msa-outline-style',
    type: 'line',
    paint: {
      'line-color': '#0A62FF',
      'line-width': 2,
    },
  },
];

type HcMapOutlineProps = MapOutlineConfig;

export const HcMapOutline = ({
  geom,
  layerStyle = DEFAULT_LAYER_STYLE,
}: HcMapOutlineProps) => {
  if (geom) {
    return (
      <Source id={'outline'} type="geojson" data={geom}>
        {layerStyle.map((layer) => (
          <Layer key={layer.type} {...layer} />
        ))}
      </Source>
    );
  }
  return null;
};
