import React from 'react';

import { TableHeader } from '@hcs/design-system';
import { TableHeaderProps } from '@hcs/types';

import { PropertyTableProps } from '../../components/PropertyTable/PropertyTable';
import { CustomCellData } from '../../components/PropertyTable/PropertyTable.types';
import { PROPERTY_STATE_FIELD_CONFIGS } from '../../configs';
import { ADDRESS_CONFIG } from '../../configs/PropertyStateFields/address';

const { HeaderCell: HeaderCellAddress } = ADDRESS_CONFIG;

export interface PropertyTableHeaderProps<D extends CustomCellData = undefined>
  extends TableHeaderProps {
  fields: PropertyTableProps<D>['fields'];
  showAddress?: boolean;
}

export const PropertyTableHeader = <D extends CustomCellData = undefined>({
  fields,
  showAddress = true,
  ...rest
}: PropertyTableHeaderProps<D>) => {
  return (
    <TableHeader {...rest}>
      {showAddress ? <HeaderCellAddress /> : null}
      {fields.map((field, c) => {
        const key = `${field.value}-${c}`;
        if (field.type === 'propertyStateFields') {
          const { HeaderCell } = PROPERTY_STATE_FIELD_CONFIGS[field.value];
          return <HeaderCell key={key} {...field.headerCellProps} />;
        } else {
          return <React.Fragment key={key}>{field.header}</React.Fragment>;
        }
      })}
    </TableHeader>
  );
};

// Needed for Table child checks
PropertyTableHeader.defaultProps = {
  isTableHeader: true,
};
