import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';

import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { SubjectIcon } from '@hcs/design-system';
import { useKeywordSearchByListingsByCerberusId } from '@hcs/keyword-search';
import { PropertyBrokerageCredit, useCompFields } from '@hcs/property-state';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { ADDRESS_CONFIG } from '@hcs/property-state';
import { getCompFieldConfig, isCompField } from '@hcs/property-state';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { TableRowProps } from '@hcs/types';
import {
  CompFields,
  CompTypes,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import {
  CompId,
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportId,
} from '@hcs/types';
import { CompSchema, CompsFiltersPaths } from '@hcs/types';

import { COMP_TAGS_FEATURES, CompTags } from '../../../features/CompTags';
import {
  useComp,
  useCompCompareDialogSlice,
  useCompDocuments,
  useCompsFarmDocument,
  useCompsFilteredDocument,
  useCompsFiltersDocument,
  useDocumentPatch,
  useSubjectDocument,
} from '../../../hooks';
import { useReportConfig } from '../../../hooks/useReportConfig';
import { useReportPermissions } from '../../../hooks/useReportPermissions';
import { compKeywordSearchKey } from '../../../utils';
import { reportFeaturesSupported } from '../../../utils/reportConfig.utils';
import { ReportFeaturesSupported } from '../../ReportFeaturesSupported';
import { ReportLegalDisclaimers } from '../../ReportLegalDisclaimers';

import {
  SELECT_CELL_FEATURES_RENTAL,
  SELECT_CELL_FEATURES_SALE,
  SelectCell,
} from './SelectCell';
import { TableOptionsHeaderCell } from './TableOptionsHeaderCell';

import styles from './CompFarmListTable.module.css';

export const COMP_FARM_LIST_TABLE_FEATURES_SALE = [
  ...SELECT_CELL_FEATURES_SALE,
  ...COMP_TAGS_FEATURES,
];
export const COMP_FARM_LIST_TABLE_FEATURES_RENTAL = [
  ...SELECT_CELL_FEATURES_RENTAL,
];
type OnMouseEnter = (hovered: {
  compSchema: CompSchema;
  documentId: string | undefined;
}) => void;

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  selectedCompsOnly?: boolean;
  onMouseEnter?: OnMouseEnter;
  onMouseLeave?: VoidFunction;
}

const CompTagsContentCell = ({
  reportId,
  children,
  ...tableCellProps
}: {
  reportId: ReportId;
  isTableCell?: boolean;
  width: number;
  sticky: boolean;
  children?: ReactNode;
}) => {
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={COMP_TAGS_FEATURES}
    >
      <TableCell {...tableCellProps} width={50} sticky noPadding>
        {children}
      </TableCell>
    </ReportFeaturesSupported>
  );
};
CompTagsContentCell.defaultProps = {
  isTableCell: true,
  width: 50,
  sticky: true,
};

const CompTagsHeaderCell = ({
  reportId,
  children,
  ...tableCellProps
}: {
  reportId: ReportId;
  width: number;
  sticky: boolean;
  isHeaderCell?: boolean;
  children?: ReactNode;
}) => {
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={COMP_TAGS_FEATURES}
    >
      <TableHeaderCell {...tableCellProps}>{children}</TableHeaderCell>
    </ReportFeaturesSupported>
  );
};
CompTagsHeaderCell.defaultProps = {
  isTableHeaderCell: true,
  width: 50,
  sticky: true,
};

const { ContentCell: AddressContentCell, HeaderCell: AddressHeaderCell } =
  ADDRESS_CONFIG;

const dataHcName = 'comp-farm-table';

const Row = ({
  compId,
  compType,
  height,
  columnIds,
  reportId,
  selectedCompsOnly,
  onMouseEnter,
  onMouseLeave,
  onOpen,
  onClose,
  ...tableRowProps
}: {
  compId: CompId;
  compType: CompTypes;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  columnIds: (PropertyStateFields | CompFields)[];
  height: number;
} & Props &
  Omit<TableRowProps, 'onMouseEnter'>) => {
  const compQuery = useComp(reportId, { type: 'compId', compType, compId });
  const { data: reportConfig } = useReportConfig(reportId);
  const isTagsSupported = reportFeaturesSupported(
    reportConfig,
    [ReportFeatures.Tags],
    ReportFeaturesSupportTypes.Any
  );
  const { compSchema, documentId } = compQuery.data || {};
  const {
    actions: { compCompareDialogOpen },
  } = useCompCompareDialogSlice();
  const keywordSearchKey = compKeywordSearchKey(compType);
  const { data: keywordMatches } =
    useKeywordSearchByListingsByCerberusId(keywordSearchKey);

  if (!compSchema) return null;
  const cerberusId =
    compType === CompTypes.Rental
      ? compSchema.propertyState.listingDetailsRental?.entityId
      : compSchema.propertyState.listingDetailsSale?.entityId;
  const isRental = compType === CompTypes.Rental;
  const hasKeywordMatch = cerberusId ? keywordMatches[cerberusId] : false;

  return (
    <TableRow
      {...tableRowProps}
      key={`comp-row-${compId}`}
      onClick={() =>
        compCompareDialogOpen({
          type: 'compId',
          compType,
          compId: compSchema.compID,
        })
      }
      onMouseEnter={() => {
        onMouseEnter?.({ compSchema, documentId });
      }}
      onMouseLeave={onMouseLeave}
      height={height}
    >
      <SelectCell
        reportId={reportId}
        compIdentifier={{ type: 'schema', compType, compSchema }}
        className={classNames({
          [styles.keywordSearchMatch]: hasKeywordMatch,
        })}
      />

      {!isRental && isTagsSupported && (
        <CompTagsContentCell reportId={reportId}>
          {documentId && (
            <CompTags
              onOpen={onOpen}
              onClose={onClose}
              overlay={'transparent'}
              reportId={reportId}
              documentId={documentId}
            />
          )}
        </CompTagsContentCell>
      )}

      <AddressContentCell
        propertyStateArgs={{
          propertyStateType: PropertyStateType.Core,
          propertyState: compSchema.propertyState,
        }}
      >
        <PropertyBrokerageCredit
          isRental={compType === CompTypes.Rental}
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: compSchema.propertyState,
          }}
        />
      </AddressContentCell>
      {columnIds.map((columnId) => {
        if (isCompField(columnId)) {
          const { ContentCell } = COMP_FIELDS_CONFIG[columnId];
          return (
            <ContentCell
              key={`column-${compSchema.propertyState.hcAddressId}${columnId}`}
              comp={compSchema}
            />
          );
        } else {
          const { ContentCell } = PROPERTY_STATE_FIELD_CONFIGS[columnId];
          return (
            <ContentCell
              key={`column-${compSchema.propertyState.hcAddressId}${columnId}`}
              propertyStateArgs={{
                propertyStateType: PropertyStateType.Core,
                propertyState: compSchema.propertyState,
              }}
            />
          );
        }
      })}
    </TableRow>
  );
};

// Use defaultProps to satisfy child prop checks in Table
Row.defaultProps = { isTableRow: true };

const SORT_PATH: CompsFiltersPaths = '/data/sort';
const ROW_HEIGHT = 70;
// Needs to be split into separate component to allow hooks to function properly
const TableContent = (props: { compIds: CompId[] } & Props) => {
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const { reportId, compIds, compType } = props;
  const {
    data: { isEditable },
  } = useReportPermissions(reportId);
  const { data: compFields } = useCompFields(compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: reportConfig } = useReportConfig(reportId);
  const isTagsSupported = reportFeaturesSupported(
    reportConfig,
    [ReportFeatures.Tags],
    ReportFeaturesSupportTypes.Any
  );
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!compFields || !subjectDocument || !reportPermissions) return null;
  const columnIds = compFields?.order.filter((columnId) => {
    return !!getCompFieldConfig(columnId);
  });
  const onOpen = () => {
    setScrollDisabled(true);
  };
  const onClose = () => {
    setScrollDisabled(false);
  };
  const isRental = compType === CompTypes.Rental;

  return (
    <Table
      dataHcName={dataHcName}
      scrollDisabled={scrollDisabled}
      belowRows={
        <ReportLegalDisclaimers
          reportId={reportId}
          className={styles.Disclaimers}
        />
      }
    >
      <TableHeader height={66} sticky>
        {isEditable ? (
          <TableOptionsHeaderCell compType={compType} />
        ) : (
          <TableHeaderCell width={50} sticky align="center" />
        )}
        {!isRental && isTagsSupported && (
          <CompTagsHeaderCell reportId={reportId} />
        )}
        <AddressHeaderCell />
        {columnIds.map((columnId) => {
          const sort =
            filterDocument?.data.sort?.field === columnId
              ? filterDocument.data.sort.order
              : undefined;
          const nextSort =
            sort === 'DESC' ? 'ASC' : sort === 'ASC' ? undefined : 'DESC';
          const { HeaderCell } = getCompFieldConfig(columnId);
          return (
            <HeaderCell
              key={`col-header-${columnId}`}
              onClick={
                reportPermissions.isEditable
                  ? () => {
                      if (filterDocument?.documentId) {
                        if (nextSort !== undefined) {
                          documentPatchMutation.mutate({
                            reportId,
                            document: filterDocument,
                            operations: [
                              {
                                op: 'add',
                                path: SORT_PATH,
                                value: {
                                  field: columnId,
                                  order: nextSort,
                                },
                              },
                            ],
                          });
                        } else {
                          documentPatchMutation.mutate({
                            reportId,
                            document: filterDocument,
                            operations: [
                              {
                                op: 'remove',
                                path: SORT_PATH,
                              },
                            ],
                          });
                        }
                      }
                    }
                  : undefined
              }
              sort={sort}
            />
          );
        })}
      </TableHeader>
      <TableRow height={ROW_HEIGHT} sticky>
        <TableCell key="subject-icon-cell" width={50} sticky>
          <SubjectIcon dataHcName={`${dataHcName}-subject-icon`} />
        </TableCell>
        {!isRental && isTagsSupported && (
          <CompTagsContentCell reportId={reportId} />
        )}
        <AddressContentCell
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: subjectDocument.data.propertyState,
          }}
        />
        {columnIds.map((columnId) => {
          if (isCompField(columnId)) {
            return <TableCell key={`subject-col-${columnId}`} />;
          } else {
            const { ContentCell } = PROPERTY_STATE_FIELD_CONFIGS[columnId];
            return (
              <ContentCell
                key={`subject-col-${columnId}`}
                propertyStateArgs={{
                  propertyStateType: PropertyStateType.Core,
                  propertyState: subjectDocument.data.propertyState,
                }}
              />
            );
          }
        })}
      </TableRow>
      {compIds.map((compId) => {
        return (
          <Row
            {...props}
            onOpen={onOpen}
            onClose={onClose}
            key={`row-${compId}`}
            height={ROW_HEIGHT}
            compId={compId}
            columnIds={columnIds}
          />
        );
      })}
    </Table>
  );
};

export const CompFarmListTable = ({
  reportId,
  selectedCompsOnly,
  ...props
}: Props) => {
  const { data: filteredDocument, isInitialLoading: isFilteredLoading } =
    useCompsFilteredDocument(reportId, props.compType);
  const { isInitialLoading: isFarmLoading } = useCompsFarmDocument(
    reportId,
    props.compType
  );
  const { isInitialLoading: isCompLoading, data: compDocuments } =
    useCompDocuments(reportId, props.compType);

  if (isFilteredLoading && isFarmLoading && isCompLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const compIds: CompId[] = [];
  if (selectedCompsOnly) {
    compDocuments?.forEach((compDocument) => {
      compIds.push(compDocument.data.compID);
    });
  } else {
    filteredDocument?.data.compIDs?.forEach((compId) => {
      compIds.push(compId);
    });
  }
  return (
    <TableContent
      {...props}
      key={`table-content-${compIds.length}`}
      reportId={reportId}
      compIds={compIds}
    />
  );
};
