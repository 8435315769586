import React, { useEffect } from 'react';

import { TextButton } from '@hcs/design-system';
import { CompleteIcon } from '@hcs/design-system';

import { useDexpJob } from '../../hooks/useDexpJob';
import { useDownloadDexpJob } from '../../hooks/useDownloadDexpJob';
import { GenerateAnalysisComplete } from '../GenerateAnalysisComplete';

import styles from './GenerateAnalysisSuccess.module.css';

interface Props {
  inputFile: File | null;
  jobId: number;
}

const dataHcName = 'analysis-create-success';
export const GenerateAnalysisSuccess = ({ inputFile, jobId }: Props) => {
  const { data: dexpJobData } = useDexpJob(jobId);
  const { mutate: downloadDexpJob } = useDownloadDexpJob();

  useEffect(() => {
    const outputFile = dexpJobData?.outputFile;
    outputFile && downloadDexpJob(outputFile);
  }, [dexpJobData]);

  return (
    <GenerateAnalysisComplete
      dataHcName={dataHcName}
      Icon={CompleteIcon}
      title="Analysis Complete"
      inputFile={inputFile}
      theme={{
        Title: styles.Title,
        Icon: styles.Icon,
      }}
    >
      <div>Download didn&apos;t start?</div>
      <TextButton
        onClick={() => {
          const outputFile = dexpJobData?.outputFile;
          outputFile && downloadDexpJob(outputFile);
        }}
        dataHcName={`${dataHcName}-download-button`}
      >
        Click here
      </TextButton>
    </GenerateAnalysisComplete>
  );
};
