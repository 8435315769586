import { useDispatch, useSelector } from 'react-redux';

import {
  PortfolioNotificationsSliceState,
  PortfolioPartialAppState,
} from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { PORTFOLIO_REDUCER_KEY, portfolioNotificationsSlice } from '../rtk';

export const usePortfolioNotificationsSlice = () => {
  const dispatch = useDispatch();

  return {
    state: useSelector<
      PortfolioPartialAppState,
      PortfolioNotificationsSliceState
    >((state) => state[PORTFOLIO_REDUCER_KEY].portfolioNotificationsSlice),
    actions: mapDispatchToSliceActions(dispatch, portfolioNotificationsSlice),
  };
};
