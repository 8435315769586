import React from 'react';

import { MonthsAgoSelect } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { CompsFiltersPaths } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';
import { formatMonthsAgo } from '@hcs/utils';

import { useCompsFiltersDocument, useSubjectDocument } from '../../../hooks';
import { useDocumentPatch } from '../../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../../hooks/useReportPermissions';

import { CompFilterChip } from './CompFilterChip';

const FIELD = PropertyStateFields.currentListDateRental;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = `comp-filter-${FIELD}`;
export const COMP_FILTER_LISTING_DATE_RENTAL: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const active = !!filterValue;
    const label = active
      ? formatMonthsAgo(filterValue?.relativeValue, 'Listed')
      : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType, className }: CompFilterControlTypeProps) => {
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument || !reportPermissions) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }
    const filterValue = filterDocument.data.filters?.[FIELD];
    return (
      <MonthsAgoSelect
        dataHcName={`${dataHcName}-select`}
        disabled={!reportPermissions.isEditable}
        value={filterValue?.relativeValue || null}
        className={className}
        onChange={(value) => {
          if (value === null) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_REL,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    );
  },
};
