import {
  DraggingEvent,
  FeatureCollection,
  ModeProps,
  TransformMode,
} from '@nebula.gl/edit-modes';

import { handleDragging } from '@hcs/maps';

export class MeasureTransformMode extends TransformMode {
  handleDragging(event: DraggingEvent, props: ModeProps<FeatureCollection>) {
    const guides = this.getGuides(props);
    handleDragging(event, props, guides);
    super.handleDragging(event, props);
  }
}
