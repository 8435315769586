import React from 'react';

import { useAccount } from '@hcs/auth';
import { CopyInput } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { BuildingIcon } from '@hcs/design-system';
import { MeaningfulEventTypes, ReportFeatures, ReportId } from '@hcs/types';

import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './ReportIntraOrgLink.module.css';

interface Props {
  className?: string;
  reportId: ReportId;
}
export const REPORT_INTRA_ORG_LINK_FEATURES = [ReportFeatures.IntraOrgLinks];
const dataHcName = 'report-intra-org-link';
export const ReportIntraOrgLink = ({ className, reportId }: Props) => {
  const { data, isInitialLoading } = useAccount();
  if (isInitialLoading)
    return <LoadingSpinner dataHcName={`${dataHcName}-loading-spinner`} />;
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={REPORT_INTRA_ORG_LINK_FEATURES}
    >
      <div className={className}>
        <div className={styles.TextIconContainer}>
          <BuildingIcon dataHcName={`${dataHcName}-building-icon`} />
          <div>
            <div
              data-hc-name={`${dataHcName}-account-name-label`}
              className={styles.Label}
            >
              {data?.currentOrganization.name}
            </div>
            <div>Anyone in this organization with a link can view.</div>
          </div>
        </div>
        <CopyInput
          dataHcName={`${dataHcName}-copy-input`}
          copyEngagement={{
            dataHcEventName: 'Copy Intra-Org Link',
            dataHcEventType: MeaningfulEventTypes.Goal,
          }}
          className={styles.CopyInput}
          value={`${window.location.origin}${window.location.pathname}`}
        />
      </div>
    </ReportFeaturesSupported>
  );
};
