import React from 'react';
import classNames from 'classnames';

import { useActiveState } from '@hcs/hooks';
import { Personalization } from '@hcs/types';
import { formatCityStateZip } from '@hcs/utils';

import { DirectionalChevron } from '../../../../foundations';
import { Avatar } from '../../../display';

import styles from './PersonalizationHeader.module.css';

export interface PersonalizationHeaderProps {
  personalization: Personalization;
  dataHcName: string;
  dataHcEventSection?: string;
  className?: string;
  expandedInitial?: boolean;
}

export const PersonalizationHeader = ({
  personalization,
  expandedInitial,
  dataHcName,
  dataHcEventSection,
  className,
}: PersonalizationHeaderProps) => {
  const {
    photoUrl,
    includePhoto,
    firstName,
    lastName,
    includeName,
    companyName,
    includeCompanyName,
    phone,
    email,
    websiteUrl,
    includeCompanyLogo,
    companyLogoUrl,
    includeAddressLine1,
    addressLine1,
    includeAddressLine2,
    addressLine2,
    includeCityStateZipcode,
    city,
    state,
    zipcode,
  } = personalization;
  const { active, handleToggle } = useActiveState({
    activeInitial: expandedInitial,
  });
  const fullName = (firstName || lastName) && includeName && (
    <div
      data-hc-name={`${dataHcName}-cobrand-fullname`}
      className={active ? styles.UserName : undefined}
    >{`${firstName} ${lastName}`}</div>
  );
  const avatar = photoUrl && includePhoto && (
    <Avatar
      url={photoUrl}
      size={active ? 'lg' : 'sm'}
      text={(firstName?.slice(0, 1) || '') + (lastName?.slice(0, 1) || '')}
      dataHcName={`${dataHcName}-cobrand-user-photo`}
      className={styles.UserPhoto}
    />
  );
  return (
    <header
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={classNames(
        styles.PersonalizationHeader,
        {
          [styles.collapsed]: !active,
        },
        className
      )}
      aria-expanded={active}
    >
      <div className={styles.ControlSection}>
        {active ? (
          <span
            data-hc-name={`${dataHcName}-toggle`}
            className={styles.ControlButton}
            onClick={handleToggle}
            aria-expanded={active}
          >
            Collapse{' '}
            <DirectionalChevron
              dataHcName={`${dataHcName}-chevron`}
              direction="up"
              className={styles.Chevron}
              size="sm"
            />
          </span>
        ) : (
          <span
            data-hc-name={`${dataHcName}-toggle`}
            className={styles.ControlButton}
            onClick={handleToggle}
            aria-expanded={active}
          >
            {avatar}
            {fullName}
            <DirectionalChevron
              direction="down"
              className={styles.Chevron}
              size="sm"
            />
          </span>
        )}
      </div>
      {active && (
        <div data-hc-name={`${dataHcName}-content`} className={styles.Content}>
          <div className={styles.UserInfo}>
            {avatar}
            <div>
              {fullName}
              <div>
                {companyName && includeCompanyName && (
                  <div className={styles.Row}>
                    {companyName && includeCompanyName && (
                      <span data-hc-name={`${dataHcName}-cobrand-companyname`}>
                        {companyName}
                      </span>
                    )}
                  </div>
                )}
                {(phone || websiteUrl || email) && (
                  <div className={styles.Row}>
                    {phone && (
                      <span data-hc-name={`${dataHcName}-cobrand-phone`}>
                        {phone}
                      </span>
                    )}
                    {email && (
                      <span data-hc-name={`${dataHcName}-cobrand-email-text`}>
                        {email}
                      </span>
                    )}
                    {websiteUrl && (
                      <span data-hc-name={`${dataHcName}-cobrand-website-url`}>
                        {websiteUrl}
                      </span>
                    )}
                  </div>
                )}
              </div>
              {(includeAddressLine1 ||
                includeAddressLine2 ||
                includeCityStateZipcode) && (
                <div className={styles.Address}>
                  {includeAddressLine1 && addressLine1 && (
                    <div>
                      <span data-hc-name={`${dataHcName}-address-line-1`}>
                        {addressLine1}
                      </span>
                      {includeAddressLine2 && addressLine2 && (
                        <span
                          className={styles.AddressLine2}
                          data-hc-name={`${dataHcName}-address-line-2`}
                        >
                          {addressLine2}
                        </span>
                      )}
                    </div>
                  )}
                  {includeCityStateZipcode && city && state && zipcode && (
                    <div data-hc-name={`${dataHcName}-city-state-zip`}>
                      {formatCityStateZip({ city, state, zipcode })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {includeCompanyLogo && companyLogoUrl && (
            <img
              alt="Company Logo"
              className={styles.CompanyLogo}
              src={companyLogoUrl}
              data-hc-name={`${dataHcName}-cobrand-company-logo`}
            />
          )}
        </div>
      )}
    </header>
  );
};
