import React from 'react';
import { Bar, BarDatum, BarItemProps } from '@nivo/bar';
import { BarSvgProps } from '@nivo/bar/dist/types/types';

import { formatNumberAbbrev } from '@hcs/utils';

import styles from './AvmBreakdownBarChart.module.css';

const LABEL_ABOVE_BAR_POSITION = -5;

interface AvmBreakdownChartData extends BarDatum {
  color: string;
  bar: number;
  value: number;
  barValue: number;
  height: number;
}

interface Props extends BarSvgProps<AvmBreakdownChartData> {
  data: AvmBreakdownChartData[];
  width: number;
  height: number;
}

interface BarComponentProps extends BarItemProps<AvmBreakdownChartData> {
  selectedBar: number | null;
  setSelectedBar: (selectedBar: number | null) => void;
}

const Y_OFFSET = 110;
const BAR_WIDTH = 30;

const BarComponent = ({
  selectedBar,
  setSelectedBar,
  bar: { x, data },
}: BarComponentProps) => {
  const height = data.data.height;
  const y = Y_OFFSET - height;
  const colorTranslate = (color: string) => {
    const colorTransform: Record<string, string> = {
      '#D9EFB0': '#C9E790',
      '#CDECCD': '#9FDC9F',
      '#9ED9C3': '#7ED1AE',
    };
    return colorTransform[color];
  };

  const setCursorForBar = (elementId: string, pointerType: string) => {
    const bar = window.document.getElementById(elementId);
    if (bar) {
      bar.style.cursor = pointerType;
    }
  };

  const handleMouseOver = () => {
    setSelectedBar(data.data.bar);
    setCursorForBar(`bar-${data.data.bar}`, 'pointer');
  };

  const handleMouseOut = () => {
    setSelectedBar(null);
    setCursorForBar(`bar-${data.data.bar}`, 'default');
  };

  const selected = selectedBar && data.data.bar === selectedBar;
  const prevOrNext =
    (selectedBar && data.data.bar === selectedBar - 1) ||
    (selectedBar && data.data.bar === selectedBar + 1);

  return (
    <g
      id={`bar-${data.data.bar}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      transform={`translate(${selected ? x - 2 : x}, ${selected ? y - 2 : y})`}
    >
      <defs>
        <clipPath id={`round-corner-${data.data.bar}`}>
          <rect
            x="0"
            y="0"
            width={selected ? BAR_WIDTH + 4 : BAR_WIDTH}
            height="200"
            rx="5"
            ry="5"
          />
        </clipPath>
      </defs>
      <rect
        width={selected ? BAR_WIDTH + 4 : BAR_WIDTH}
        height={selected ? height + 2 : height}
        clipPath={`url(#round-corner-${data.data.bar})`}
        fill={selected ? colorTranslate(data.data.color) : data.data.color}
        strokeWidth="1"
        stroke={`${data.data[`${data.id}Color_Border`]}`}
      />
      {(prevOrNext || selected) && (
        <text
          x={BAR_WIDTH / 2}
          y={LABEL_ABOVE_BAR_POSITION}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontFamily: 'Avenir',
            fontSize: 10,
            lineHeight: 14,
            pointerEvents: 'none',
            fill: selected ? 'black' : '#C4C4C4',
            opacity: 1,
          }}
        >
          {formatNumberAbbrev(Math.round(data.data.barValue))}
        </text>
      )}
    </g>
  );
};

export const AvmBreakdownBarChart = ({
  data,
  width,
  height,
  ...chartProps
}: Props) => {
  const [selectedBar, setSelectedBar] = React.useState<number | null>(null);

  return (
    <div className={styles.BarGraph}>
      <Bar
        {...chartProps}
        width={width}
        height={height}
        borderRadius={10}
        barComponent={(props) => (
          <BarComponent
            selectedBar={selectedBar}
            setSelectedBar={setSelectedBar}
            {...props}
          />
        )}
        enableGridY={false}
        enableLabel={false}
        isInteractive={false}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        data={data}
        keys={['value']}
        indexBy="bar"
      />
    </div>
  );
};
