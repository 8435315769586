import React from 'react';

import { Button } from '@hcs/design-system';
import { HouseCanaryLogoBlack } from '@hcs/design-system';
import { useExperienceFlags } from '@hcs/experience-flags';
import { useActiveState } from '@hcs/hooks';
import { usePropertyStateCore } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';

import { OfferFormOverlay } from '../../features/OfferFromOverlay';
import { useOfferNowAvailableForReport } from '../../hooks/useOfferNowAvailableForReport';
import { useReport } from '../../hooks/useReport';

interface OfferFromReportIdTheme {
  ButtonContainer?: string;
  Button?: string;
}

interface OfferFromReportIdProps {
  reportId: ReportId;
  theme?: OfferFromReportIdTheme;
}

const dataHcName = 'offer-from-report-id';

export const OfferFromReportId = ({
  theme,
  reportId,
}: OfferFromReportIdProps) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const EXPERIENCE_FLAGS = useExperienceFlags();
  const expNext = EXPERIENCE_FLAGS.PEXP_REDESIGN;
  const { data: offerNowAvailableForReport } =
    useOfferNowAvailableForReport(reportId);
  const { data: reportData } = useReport(reportId);
  const hcAddressId = reportData?.addressID;
  const { data: propertyStateArgs } = usePropertyStateCore(
    hcAddressId ? { hcAddressId } : undefined
  );
  const listingStatus =
    propertyStateArgs &&
    getPropertyStateFieldValue(
      PropertyStateFields.currentStatus,
      propertyStateArgs
    );
  const listPrice =
    propertyStateArgs &&
    getPropertyStateFieldValue(
      PropertyStateFields.currentPrice,
      propertyStateArgs
    );
  const avm =
    propertyStateArgs &&
    getPropertyStateFieldValue(
      PropertyStateFields.currentValue,
      propertyStateArgs
    );

  if (offerNowAvailableForReport) {
    return (
      <>
        <div data-hc-name={dataHcName} className={theme?.ButtonContainer}>
          <Button
            secondary={expNext}
            dataHcName={`${dataHcName}-open-button`}
            onClick={handleOpen}
            className={theme?.Button}
          >
            {expNext ? (
              <>
                Submit an offer via <HouseCanaryLogoBlack width={97} />
              </>
            ) : (
              'Offer now'
            )}
          </Button>
        </div>
        <OfferFormOverlay
          fullAddress={formatFullAddress(
            propertyStateArgs?.propertyState?.location
          )}
          active={active}
          onClose={handleClose}
          reportId={reportId}
          listPrice={listPrice || null}
          listingStatus={listingStatus || null}
          avm={avm || null}
        />
      </>
    );
  } else {
    return null;
  }
};
