import { DocumentRoles, MarketAnalysisDocument, ReportId } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useMarketAnalysisDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<MarketAnalysisDocument>(
    reportId,
    DocumentRoles.MarketAnalysis
  );
};
