import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useIsHelpChatEnabled } from '../../hooks';

const HelpChatHideWhileMountedIntercomActive = () => {
  const { update } = useIntercom();
  useEffect(() => {
    // hide on load
    update({
      hideDefaultLauncher: true
    });

    // show on unmount
    return () => {
      update({
        hideDefaultLauncher: false
      });
    };
  }, [update]);

  return null;
};

export const HelpChatHideWhileMounted = () => {
  const isHelpChatEnabled = useIsHelpChatEnabled();
  if (isHelpChatEnabled) {
    return <HelpChatHideWhileMountedIntercomActive />;
  }
  return null;
};
