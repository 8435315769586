import { gql } from 'graphql-request';

export const FRAGMENT_MLS_REGULATIONS = gql`
  fragment MlsRegulationsFragment on MlsRegulations {
    augmenting
    cancelled
    closed
    closedLoginPrice
    coMingling
    comingSoon
    contingent
    contingentWithKickOut
    cooperatingBrokerage
    copyright
    deleted
    disclaimer
    expired
    leased
    logo
    logoOverlay
    pending
    photosClosedAll
    photosClosedFirstOnly
    photosClosedHistory
    photosClosedLogin
    photosLogoOverlay
    sold
    unknown
    withdrawn
  }
`;

export const FRAGMENT_LISTING_MLS = gql`
  ${FRAGMENT_MLS_REGULATIONS}
  fragment ListingMlsFragment on ListingMls {
    hcMlsId
    lastRefreshed
    name
    nameShort
    regulations {
      ...MlsRegulationsFragment
    }
  }
`;
