import { useCallback } from 'react';
import { Path, useNavigate } from 'react-router';

import { useExperienceFlags } from '@hcs/experience-flags';
import { CerberusInputHcs } from '@hcs/types';
import { PropertyIdentifierSearchParams } from '@hcs/types';
import { NavigateToPropertyPreviewFn } from '@hcs/types';
import { LEGACY_PEXP_UI_URL, SOLUTIONS_URL } from '@hcs/urls';
import { objectToUrlSearchParamString } from '@hcs/utils';

import {
  APP_CONFIG_PEXP,
  VIEW_PATHS_PEXP,
} from '../constants/AppConfigPexp.constants';

export const getPropertyPreviewPath = (
  cerberusInput: CerberusInputHcs,
  addtlQueryParams?: Record<string, string>
): Partial<Path> => {
  return {
    pathname: `${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.SEARCH}`,
    search: objectToUrlSearchParamString({
      [PropertyIdentifierSearchParams.HcAddressId]: cerberusInput.hcAddressId
        ? cerberusInput.hcAddressId.toString()
        : undefined,
      [PropertyIdentifierSearchParams.Slug]: cerberusInput.slug,
      [PropertyIdentifierSearchParams.StreetAddress]:
        cerberusInput.streetAddress,
      [PropertyIdentifierSearchParams.Zipcode]: cerberusInput.zipcode,
      [PropertyIdentifierSearchParams.Unit]: cerberusInput.unit || undefined,
      ...addtlQueryParams,
    }),
  };
};

export const getPropertyPreviewLegacyUrl = (
  cerberusInput: CerberusInputHcs
): string => {
  const { slug, hcAddressId, streetAddress, zipcode, unit } = cerberusInput;
  const redirectUrlParams: { [key: string]: string } = {};
  if (hcAddressId) {
    redirectUrlParams.address_id = hcAddressId.toString();
  }
  if (slug) {
    redirectUrlParams.address_slug = slug;
  }
  if (streetAddress) {
    redirectUrlParams.street_address = streetAddress;
  }
  if (zipcode) {
    redirectUrlParams.zipcode = zipcode;
  }
  if (unit) {
    redirectUrlParams.unit = unit;
  }
  const url = `${LEGACY_PEXP_UI_URL}/search?${new URLSearchParams(
    redirectUrlParams
  ).toString()}`;
  return url;
};

export const useNavigateToPexp = () => {
  const navigate = useNavigate();
  const EXPERIENCE_FLAGS = useExperienceFlags();
  if (EXPERIENCE_FLAGS.PEXP_REPORT_API) {
    return () => {
      navigate(APP_CONFIG_PEXP.rootPath);
    };
  } else {
    return () => {
      // SECURITY - Known internal URL @jnettleman
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(LEGACY_PEXP_UI_URL, '_self');
    };
  }
};

export const useNavigateToPropertyPreview = (
  addtlQueryParams?: Record<string, string>
): NavigateToPropertyPreviewFn => {
  const navigate = useNavigate();
  const experienceFlags = useExperienceFlags();
  return useCallback(
    (cerberusInput: CerberusInputHcs, blank?: boolean) => {
      if (experienceFlags.PEXP_REPORT_API) {
        const path = getPropertyPreviewPath(cerberusInput, addtlQueryParams);
        if (blank) {
          // SECURITY - Known internal URL
          // eslint-disable-next-line security/detect-non-literal-fs-filename
          window
            .open(`${SOLUTIONS_URL}${path.pathname}${path.search}`, '_blank')
            ?.focus();
        } else {
          return navigate(path);
        }
      } else {
        const url = getPropertyPreviewLegacyUrl(cerberusInput);
        if (blank) {
          // SECURITY - Uses react-router function to construct url @jnettleman
          // eslint-disable-next-line security/detect-non-literal-fs-filename
          window.open(url, '_blank')?.focus();
        } else {
          // navigate(url);
          // SECURITY - Uses react-router function to construct url @jnettleman
          // eslint-disable-next-line security/detect-non-literal-fs-filename
          window.open(url);
        }
      }
    },
    [experienceFlags, navigate]
  );
};
