import React from 'react';

import { LayoutBanner } from '@hcs/design-system';
import { EntryPageContainer } from '@hcs/design-system';
import { LinkComponentType } from '@hcs/routing';
import { CerberusStatsFields } from '@hcs/types';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';
import { CerberusStatsZipsTable } from '../../features/CerberusStatsTables';
import { useCerberusStatsMsa } from '../../hooks/useCerberusStatsMsa';

import styles from './ZipSearchPageLayout.module.css';

const DisplayName =
  CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.displayName].Display;

const dataHcName = 'zip-search-banner';
interface ZipsSearchPageLayoutProps {
  msaId: string;
  MsaSearchLink: LinkComponentType<unknown>;
  MsaLink: LinkComponentType<{ msaId: string }>;
  ZipLink: LinkComponentType<{ msaId: string; zipcode: string }>;
}
export const ZipSearchPageLayout = ({
  msaId,
  MsaSearchLink,
  MsaLink,
  ZipLink,
}: ZipsSearchPageLayoutProps) => {
  const { data: cerberusStats } = useCerberusStatsMsa({ msaId });
  return (
    <EntryPageContainer>
      <LayoutBanner
        dataHcName={dataHcName}
        title="Market Insights"
        compact
        theme={{ Banner: styles.Banner }}
        description={
          <>
            <MsaSearchLink
              dataHcName={`${dataHcName}-msa-search-link`}
              underline
            >
              MSA Search
            </MsaSearchLink>{' '}
            /{' '}
            <MsaLink
              dataHcName={`${dataHcName}-msa-link`}
              msaId={msaId}
              underline
            >
              MSA: <DisplayName cerberusStats={cerberusStats} />
            </MsaLink>{' '}
            / Zip Codes
          </>
        }
      >
        <CerberusStatsZipsTable
          msaId={msaId}
          MsaLink={MsaLink}
          ZipLink={ZipLink}
        />
      </LayoutBanner>
    </EntryPageContainer>
  );
};
