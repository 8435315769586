import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';

import { AccountOrgApiExternal } from '../api/AccountOrgApiExternal.api';

import { QUERY_KEY_ORG_SAML_PROVIDER } from './useOrgSamlProvider';

interface Params {
  orgId: number;
  providerId: string;
  onDeleteSuccess?: VoidFunction;
}

export const useDeleteOrgSamlProvider = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError, Params>(
    async ({ providerId, orgId }) =>
      await AccountOrgApiExternal.deleteOrgSamlProvider(orgId, providerId),
    {
      onSuccess: (data, variables) => {
        queryClient.resetQueries({
          queryKey: [QUERY_KEY_ORG_SAML_PROVIDER, variables.orgId],
          exact: true,
        });
        toastOpen({
          type: 'success',
          title: 'Saml Settings Deleted',
        });
        variables.onDeleteSuccess?.();
      },
    }
  );
};
