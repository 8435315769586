import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Button, ButtonProps } from '../../buttons/Button';

import styles from './FileInputButton.module.css';

export interface FileInputButtonProps {
  children?: ReactNode;
  buttonProps: ButtonProps;
  fileSizeLimit?: number;
  inputProps: {
    name: string;
    accept: string;
    onChange: (file: File | undefined) => void;
  };
  onError: (error: string) => void;
}
export const FileInputButton = ({
  buttonProps,
  fileSizeLimit,
  inputProps,
  onError,
  children,
}: FileInputButtonProps) => {
  return (
    <Button
      {...buttonProps}
      className={classNames(styles.FileInputButton, buttonProps.className)}
    >
      {children}
      <input
        className={styles.Input}
        type="file"
        {...inputProps}
        onChange={(e) => {
          const file = e.target.files?.[0];
          const fileTooLarge =
            fileSizeLimit && file?.size && file.size > fileSizeLimit;
          const acceptableFileType =
            file?.type &&
            inputProps.accept
              .split(',')
              .map((a) => a.trim())
              .includes(file?.type);
          if (fileTooLarge) {
            onError(`File Size Too Large`);
          } else if (acceptableFileType) {
            inputProps.onChange(file);
          } else {
            onError(`File Type Not Supported`);
          }
        }}
      />
    </Button>
  );
};
