import React, { useEffect } from 'react';
import classNames from 'classnames';

import { TableCellProps } from '@hcs/types';

import styles from './Table.module.css';

export const TableCell = ({
  dataHcName,
  align = 'left',
  stickyRowOffset,
  sticky,
  stickyOffset,
  height,
  width,
  className,
  noBorder,
  wordWrap = false,
  onClick,
  renderStickyShadow,
  style = {},
  colSpan,
  children,
  noPadding = false,
}: TableCellProps) => {
  useEffect(() => {
    if (sticky && width === undefined) {
      console.warn('[Table] sticky is true but a width is not defined');
    }
  }, [sticky, width]);
  return (
    <td
      data-hc-name={dataHcName}
      onClick={onClick}
      width={width !== undefined ? width : style?.width || 0}
      className={classNames(styles.TableCell, className, {
        [styles.sticky]: sticky,
        [styles.noBorder]: noBorder,
        [styles.left]: align === 'left',
        [styles.right]: align === 'right',
        [styles.center]: align === 'center',
        [styles.noPadding]: noPadding,
        [styles.wordWrap]: wordWrap,
      })}
      style={{
        ...style,
        height: height !== undefined ? `${height}px` : style.height,
        width: width !== undefined ? `${width}px` : style.width,
        maxWidth: width !== undefined ? `${width}px` : style.width,
        minWidth: width !== undefined ? `${width}px` : style.width,
        top: stickyRowOffset !== undefined ? `${stickyRowOffset}px` : style.top,
        left: stickyOffset !== undefined ? `${stickyOffset}px` : style.left,
      }}
      colSpan={colSpan}
    >
      {renderStickyShadow && <div className={styles.ShadowStickyCol} />}
      {children}
    </td>
  );
};

TableCell.defaultProps = {
  isTableCell: true,
};
