import { gql } from 'graphql-request';

// Ownership Fragments

export const FRAGMENT_TAX_HISTORY_OWNERSHIP_CORE = gql`
  fragment TaxHistoryOwnershipCore on TaxHistoryHcOwnership {
    ownerOccupied
    lastUpdatedDate
  }
`;

/** PROPERTY STATE | taxHistory  */

// Tax History shown for full reports
export const FRAGMENT_TAX_HISTORY_CORE = gql`
  ${FRAGMENT_TAX_HISTORY_OWNERSHIP_CORE}
  fragment TaxHistoryCore on TaxHistoryHc {
    ownership {
      ...TaxHistoryOwnershipCore
    }
  }
`;
