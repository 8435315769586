import React, { useEffect, useState } from 'react';

import { Button } from '@hcs/design-system';
import { DragAndDropUpload } from '@hcs/design-system';
import { HomeMagnifyingGlassIcon } from '@hcs/design-system';
import { CreateNewOrderState, OrderTypeDescriptor, STEP } from '@hcs/types';

import desktopSample from '../../../assets/files/agile-appraisal-desktop-sample-order.csv';
import appraisalExteriorSample from '../../../assets/files/agile-appraisal-exterior-sample-order.csv';
import appraisalInteriorSample from '../../../assets/files/agile-appraisal-interior-sample-order.csv';
import evalExteriorCertifiedInspectionSample from '../../../assets/files/agile-evaluation-certified-sample-order.csv';
import evalExteriorSample from '../../../assets/files/agile-evaluation-exterior-sample-order.csv';
import evalInteriorSample from '../../../assets/files/agile-evaluation-interior-sample-order.csv';
import exteriorInspectionAndValueReport from '../../../assets/files/exterior-inspection-and-value-report.csv';
import exteriorInspectionSample from '../../../assets/files/exterior-inspection-sample-order.csv';
import interiorInspectionSample from '../../../assets/files/interior-inspection-sample-order.csv';
import sampleOrderFile from '../../../assets/files/sample-order.csv';

import styles from './AddOrderAddressUploadForm.module.css';

interface Props {
  orderType?: OrderTypeDescriptor;
  updateStep: (stateToUpdate: Partial<CreateNewOrderState>) => void;
  uploadAddress?: {
    uploadAddressSubmit: (csv: File) => void;
    uploadAddressSubmitIsLoading: boolean;
  };
  clearUploadErrors: VoidFunction;
}

const ORDER_TYPE_ID_TO_FILE_MAP = {
  evaluate: { name: 'evaluate', file: desktopSample },
  inspectAndEvaluate: {
    name: 'inspectAndEvaluate.csv',
    file: appraisalExteriorSample,
  },
  devFakeInspectAndEvaluate: {
    name: 'devFakeInspectAndEvaluate.csv',
    file: appraisalExteriorSample,
  },
  devFakeInspectV2AndEvaluate: {
    name: 'devFakeInspectV2AndEvaluate.csv',
    file: appraisalExteriorSample,
  },
  devFakeInspectAndFakeEvaluate: {
    name: 'devFakeInspectAndFakeEvaluate.csv',
    file: appraisalExteriorSample,
  },
  devFakeInspectV2AndFakeEvaluate: {
    name: 'devFakeInspectV2AndFakeEvaluate.csv',
    file: appraisalExteriorSample,
  },
  inspectV2AndEvaluate: {
    name: 'inspectV2AndEvaluate.csv',
    file: appraisalExteriorSample,
  },
  interiorExteriorInspectAndEvaluate: {
    name: 'interiorExteriorInspectAndEvaluate.csv',
    file: appraisalInteriorSample,
  },
  devFakeInteriorExteriorInspectAndEvaluate: {
    name: 'devFakeInteriorExteriorInspectAndEvaluate.csv',
    file: appraisalInteriorSample,
  },
  devFakeInteriorExteriorInspectAndFakeEvaluate: {
    name: 'devFakeInteriorExteriorInspectAndFakeEvaluate.csv',
    file: appraisalInteriorSample,
  },
  exteriorInspectionAndValueReport: {
    name: 'exteriorInspectionAndValueReport.csv',
    file: evalExteriorSample,
  },
  exteriorInspectionV2AndValueReport: {
    name: 'exteriorInspectionV2AndValueReport.csv',
    file: evalExteriorSample,
  },
  interiorExteriorInspectionAndValueReport: {
    name: 'interiorExteriorInspectionAndValueReport.csv',
    file: evalInteriorSample,
  },
  exteriorInspection: {
    name: 'exteriorInspection.csv',
    file: exteriorInspectionSample,
  },
  exteriorInspectionV2: {
    name: 'exteriorInspectionV2.csv',
    file: exteriorInspectionSample,
  },
  interiorExteriorInspection: {
    name: 'interiorExteriorInspection.csv',
    file: interiorInspectionSample,
  },
  valueReport: { name: 'valueReport.csv', file: sampleOrderFile },
  exteriorInspectionAndAgileEvaluationCertified: {
    name: 'exteriorInspectionAndAgileEvaluationCertified.csv',
    file: evalExteriorCertifiedInspectionSample,
  },
  brokerPriceOpinionExterior: {
    name: 'brokerPriceOpinionExterior.csv',
    file: exteriorInspectionAndValueReport,
  },
};

const dataHcName = 'add-order-address-upload';
export const AddOrderAddressUploadForm = ({
  orderType,
  updateStep,
  uploadAddress,
  clearUploadErrors,
}: Props) => {
  const [orderFile, setOrderFile] = useState<File | null>(null);

  useEffect(() => {
    if (orderFile) {
      if (!uploadAddress) {
        updateStep({
          step: STEP.ORDER_DETAILS,
          orderFile: orderFile,
          itemsSource: 'csv',
        });
      }
    }
  }, [orderFile, updateStep, uploadAddress]);

  if (!orderType) return null;

  return (
    <div data-hc-name={dataHcName}>
      <h3 className={styles.Title}>Add addresses</h3>
      <div className={styles.AddressOptionsContainer}>
        <div
          onClick={() => {
            clearUploadErrors();
            updateStep({
              step: STEP.UPLOAD_OR_ENTER_ADDRESS,
              itemsSource: 'entry',
            });
          }}
          className={styles.AddressOption}
        >
          <HomeMagnifyingGlassIcon
            dataHcName={`${dataHcName}-house-icon`}
            size={50}
            color="neutral-dark-20"
          />
          <div>Enter Manually</div>
        </div>
        <div className={styles.AddressUploadOption}>
          <DragAndDropUpload
            dataHcName={`${dataHcName}-drag-and-drop`}
            header={{
              title: '',
              sampleFile: {
                file: ORDER_TYPE_ID_TO_FILE_MAP[orderType.id].file,
                name: ORDER_TYPE_ID_TO_FILE_MAP[orderType.id].name,
              },
            }}
            config={{
              multipleFiles: false,
              onChange: (addressFile) => {
                clearUploadErrors();
                setOrderFile(addressFile);
              },
            }}
            extensions={['.xlsx', '.csv']}
          />
        </div>
      </div>
      {uploadAddress && (
        <Button
          className={styles.SubmitOrder}
          onClick={() =>
            orderFile && uploadAddress.uploadAddressSubmit(orderFile)
          }
          loading={uploadAddress.uploadAddressSubmitIsLoading}
          dataHcName={`${dataHcName}-add-addresses-submit-order`}
          disabled={!orderFile}
        >
          Submit Order
        </Button>
      )}
    </div>
  );
};
