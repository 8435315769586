import { Order, SpatialSortField } from '@hcs/types';
import {
  BuyBoxPageParams,
  MapPageSearchParams,
  PropertyListSortOption,
  RangeFilters,
} from '@hcs/types';

export const BUY_BOX_RANGE_FIELD_LABELS: Record<keyof RangeFilters, string> = {
  listPrice: 'Listing Price',
  beds: 'Beds',
  baths: 'Baths',
  sqft: 'GLA (sqft)',
  lotArea: 'Lot Size (sqft)',
  yearBuilt: 'Year Built',
  noOfStories: '# of Stories',
  parkingGarageCount: 'Garage Spaces',
};

const BUY_BOX_PAGE_SEARCH_PARAMS_PREFIX = 'buy-box';

export const BUY_BOX_PAGE_PARAMS: BuyBoxPageParams = {
  Search: `${BUY_BOX_PAGE_SEARCH_PARAMS_PREFIX}-search`,
  Sort: `${BUY_BOX_PAGE_SEARCH_PARAMS_PREFIX}-sort`,
  Order: `${BUY_BOX_PAGE_SEARCH_PARAMS_PREFIX}-order`,
  Create: `${BUY_BOX_PAGE_SEARCH_PARAMS_PREFIX}-create`,
};

const MAP_PAGE_SEARCH_PARAMS_PREFIX = 'mapPage';

export const MAP_PAGE_SEARCH_PARAMS: MapPageSearchParams = {
  BuyBoxId: `${MAP_PAGE_SEARCH_PARAMS_PREFIX}-buyBoxId`,
  Latitude: `${MAP_PAGE_SEARCH_PARAMS_PREFIX}-lat`,
  Longitude: `${MAP_PAGE_SEARCH_PARAMS_PREFIX}-long`,
  Zoom: `${MAP_PAGE_SEARCH_PARAMS_PREFIX}-zoom`,
};

// normally these would be defined in CSS but the chart will need to define the colors explicitly
export const BUY_BOX_BLUES = ['#0A62FF', '#6BA0FF', '#CDDFFF'];
export const DEFAULT_BUY_BOX_BLUE = '#6BA0FF';

export const MAP_PAGE_SORT_OPTIONS: PropertyListSortOption[] = [
  {
    field: SpatialSortField.ListPrice,
    order: Order.Ascending,
    label: 'List Price - Ascending',
  },
  {
    field: SpatialSortField.ListPrice,
    order: Order.Descending,
    label: 'List Price - Descending',
  },
  {
    field: SpatialSortField.ListDate,
    order: Order.Ascending,
    label: 'List Date - Ascending',
  },
  {
    field: SpatialSortField.ListDate,
    order: Order.Descending,
    label: 'List Date - Descending',
  },
  {
    field: SpatialSortField.RentalAvm,
    order: Order.Ascending,
    label: 'HC Rental AVM - Ascending',
  },
  {
    field: SpatialSortField.RentalAvm,
    order: Order.Descending,
    label: 'HC Rental AVM - Descending',
  },
  {
    field: SpatialSortField.RentalYield,
    order: Order.Ascending,
    label: 'Gross Yield - Ascending',
  },
  {
    field: SpatialSortField.RentalYield,
    order: Order.Descending,
    label: 'Gross Yield - Descending',
  },
  {
    field: SpatialSortField.Beds,
    order: Order.Ascending,
    label: 'Beds - Ascending',
  },
  {
    field: SpatialSortField.Beds,
    order: Order.Descending,
    label: 'Beds - Descending',
  },
  {
    field: SpatialSortField.Baths,
    order: Order.Ascending,
    label: 'Baths - Ascending',
  },
  {
    field: SpatialSortField.Baths,
    order: Order.Descending,
    label: 'Baths - Descending',
  },
  {
    field: SpatialSortField.Sqft,
    order: Order.Ascending,
    label: 'GLA - Ascending',
  },
  {
    field: SpatialSortField.Sqft,
    order: Order.Descending,
    label: 'GLA - Descending',
  },
  {
    field: SpatialSortField.LotArea,
    order: Order.Ascending,
    label: 'Lot Size - Ascending',
  },
  {
    field: SpatialSortField.LotArea,
    order: Order.Descending,
    label: 'Lot Size - Descending',
  },
  {
    field: SpatialSortField.YearBuilt,
    order: Order.Ascending,
    label: 'Year Built - Ascending',
  },
  {
    field: SpatialSortField.YearBuilt,
    order: Order.Descending,
    label: 'Year Built - Descending',
  },
];

// not prefixing these query params since they will always come from a link outside the app
export const OFFER_PAGE_SEARCH_PARAMS = {
  HcAddressId: `hc_address_id`,
  ListingId: `listing_id`,
  MlsId: `mls_id`,
};
