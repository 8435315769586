import { Capability } from '../account-api';
import { PaginatedResponseData } from '../Pagination.types';

export interface EndpointParameter {
  description: string;
  name: string;
  required: boolean;
  type: string;
  enum?: string[];
}

export enum DexpEndpointLevel {
  Block = 'block',
  Blockgroup = 'blockgroup',
  Msa = 'msa',
  Metrodiv = 'metrodiv',
  Property = 'property',
  State = 'state',
  ValueReport = 'value_report',
  Zip = 'zip',
}

export enum DexpEndpointTierEntitlements {
  Basic = 'data-points-basic',
  Premium = 'data-points-premium',
  PremiumPlus = 'data-points-premium-plus',
}

export interface DexpEndpointCategory {
  category: string;
  endpoints: Capability[];
}

export interface DexpEndpointTier {
  tier: string;
  categories: DexpEndpointCategory[];
}
export type DexpEndpointTiers = Record<
  DexpEndpointTierEntitlements,
  DexpEndpointTier
>;

export interface DexpEndpoint {
  access: boolean;
  cents: number;
  description: string;
  id: string;
  level: DexpEndpointLevel;
  name: string;
  parameters: EndpointParameter[];
  supportsOrdering: boolean;
}

export type DexpTemplateFormValues = Pick<DexpEndpoint, 'name'>;
interface DexpAddress {
  meta?: string;
  address: string;
  zipcode: string;
}

export interface DexpJob {
  id: number;
  status: DexpJobStatus;
  errorMessage: string | null;
  statusMessage: string | null;
  endpoints: string[];
  endpointsCount: number;
  userId: number | null; // Only null in pseudo event from upload response
  statusDisplay: string;
  chunksTotal: number;
  chunksCompleted: number;
  chunksUnauthorized: number;
  chunksInError: number;
  chunksUnprocessed: number;
  dataPointsTotal: number;
  dataPointsCompleted: number;
  dataPointsUnauthorized: number;
  dataPointsMissingData: number;
  dataPointsInError: number;
  pausedAt: string | null;
  pausedFor: number | null;
  retryNo: number;
  extraData?: { clientFileId?: string }[];
  addressesCount: number;
  addresses?: DexpAddress[];
  apiAuthKeyId: number | null; // Only null in pseudo event from upload response
  createdAt: string | null; // Only null in pseudo event from upload response
  updatedAt: string | null; // Only null in pseudo event from upload response
  fileName: string | null; // Only null in pseudo event from upload response
  outputFile: string | null;
  ownerEmail?: string | null; // Only null in pseudo event from upload response
}

export type DexpJobsResponse = PaginatedResponseData<DexpJob[]>;

export interface DexpCreateJobInputs {
  endpoints: string;
  properties: {
    hcAddressId: number;
    clientFileId?: string;
  }[];
}

export interface DexpJobCreateResponse {
  templateId: string;
  endpoints: string;
  id: number;
}

export interface DexpJobCreateErrorDict {
  addresses: string | string[];
}

export type DexpJobCreateError = string | DexpJobCreateErrorDict;

export enum DexpEventType {
  Created = 'created',
  Updated = 'updated',
  Submitted = 'submitted',
}

export enum DexpJobStatus {
  Created = 'Created',
  Processing = 'Processing',
  Queued = 'Queued',
  GeneratingWorkbook = 'GeneratingWorkbook',
  Completed = 'Completed',
  CompletedWithErrors = 'CompletedWithErrors',
  Error = 'Error',
}

export interface DexpEvent {
  event: DexpEventType;
  job: DexpJob;
}

export interface DexpEventDebug {
  event: 'debug';
  job: DexpJob;
}

export type DexpEventsAll = DexpEvent | DexpEventDebug;

export enum DexpEndpointsFilterIds {
  Search = 'search',
  Access = 'access',
  ExcludedEndpoints = 'excluded',
  IncludedEndpoints = 'included',
}
export interface DexpEndpointsParams {
  [DexpEndpointsFilterIds.Search]?: string;
  [DexpEndpointsFilterIds.Access]?: boolean;
  [DexpEndpointsFilterIds.ExcludedEndpoints]?: string[];
  [DexpEndpointsFilterIds.IncludedEndpoints]?: string[];
}

export enum DexpSteps {
  SELECT_ENDPOINTS = 'select-endpoints',
  GENERATE_ANALYSIS = 'generate-analysis',
}

export enum GenerateAnalysisState {
  UPLOAD = 'upload',
  GENERATING = 'generating',
  SUCCESS = 'success',
  FAILURE = 'failure',
}
