import React from 'react';
import classNames from 'classnames';

import { Dropdown, DropdownOptionTypeExtends, DropdownProps } from './Dropdown';

import styles from './DropdownMini.module.css';

export const DropdownMini = <
  OptionValueType extends DropdownOptionTypeExtends
>({
  theme,
  ...props
}: DropdownProps<OptionValueType>) => {
  return (
    <Dropdown
      {...props}
      hideSelectedOption={true}
      theme={{
        DropdownContainer: classNames(
          styles.DropdownContainer,
          theme?.DropdownContainer
        ),
        Dropdown: classNames(styles.Dropdown, theme?.Dropdown),
        SelectContainer: classNames(
          styles.SelectContainer,
          theme?.SelectContainer
        ),
        SelectElement: classNames(styles.SelectElement, theme?.SelectElement),
        ChevronContainer: classNames(
          styles.ChevronContainer,
          theme?.ChevronContainer
        ),
        OptionsContainer: classNames(
          styles.OptionsContainer,
          theme?.OptionsContainer
        ),
        Option: classNames(styles.Option, theme?.Option),
        selected: classNames(theme?.selected),
        active: classNames(styles.active, theme?.active),
      }}
    />
  );
};
