import { useQuery } from '@tanstack/react-query';

import { logException } from '@hcs/utils';

import { AuthzApi } from '../api';

export const QUERY_KEY_ORG_INVITES = 'QUERY_KEY_ORG_INVITES';
export const useOrgInvites = (orgId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_ORG_INVITES, orgId],
    async () => {
      if (!orgId) {
        logException('useOrgInvites called with undefined orgId');
        throw new Error('Failed to get organization invites');
      }
      return await AuthzApi.getOrgInvites(orgId);
    },
    {
      enabled: !!orgId,
    }
  );
};
