import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../../hooks';
import { CompFilterGarage } from '../../CompFiltersDeprecated/CompFilterGarage';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const COMP_FIELD = PropertyStateFields.garageSpaces;
const dataHcName = 'comp-filter-button-garage';
export const CompFilterButtonGarage = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }
  const filterValue = filterDocument.data.filters?.[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={
        active && filterValue.absoluteValue
          ? filterValue.absoluteValue[0] === 0
            ? 'No Garage'
            : 'Has Garage'
          : 'Garage'
      }
      className={className}
      compField={COMP_FIELD}
      content={<CompFilterGarage reportId={reportId} compType={compType} />}
      active={active}
    />
  );
};
