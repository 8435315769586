import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmOrder } from '@hcs/types';

import {
  DATA_HC_NAME_ORDERS_TABLE_CELL,
  ORDER_STATUSES,
} from '../../../constants';
import { OrderProgress } from '../OrderProgress/OrderProgress';

import { OrdersTableCellsDisplayProps } from '.';

import styles from './Status.module.css';

const statusCellContent = (order: OmOrder) => {
  if (order.status === ORDER_STATUSES.NEW) {
    return 'Verifying Addresses';
  } else if (order.status === ORDER_STATUSES.CLIENT_REVIEW) {
    return <span className={styles.Paused}>Paused</span>;
  } else if (order.status === ORDER_STATUSES.ACCEPTED) {
    return (
      <OrderProgress
        dataHcName="order-status-progress"
        order={order}
        customerOrderId={order.customerOrderId}
        total={order.orderItemsToProcessCount}
      />
    );
  } else {
    return order.status;
  }
};

const Display = ({
  order,
}: OrdersTableCellsDisplayProps): JSX.Element | null => {
  return (
    <div data-hc-name={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-status`}>
      {order && statusCellContent(order)}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Status</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({ order, ...tableCellProps }: { order: OmOrder }) => {
  return (
    <TableCell {...tableCellProps}>
      <Display order={order} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_STATUS_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Status',
};
