import React from 'react';

import { AutoComplete, AutoCompleteInputStyle } from '@hcs/design-system';
import { OptionalEngagementProps, PropertyGraphListing } from '@hcs/types';
import { formatDateShort, formatFullAddress, formatMissing } from '@hcs/utils';

import { useSearchByMlsNumber } from '../../hooks/useSearchByMlsNumber';

import styles from './SearchByMlsNumber.module.css';

export interface SearchByMlsNumberProps {
  className?: string;
  placeholder?: string;
  selectEngagement?: OptionalEngagementProps;
  // Unique query id allowing multiple instances of mls search
  queryId?: string;
  onSelect: (listing: PropertyGraphListing) => void;
  // Render input without border
  inputStyle?: AutoCompleteInputStyle;
  hideChevron?: boolean;
  disableClear?: boolean;
}
const dataHcName = 'search-by-mls-number';
export const SearchByMlsNumber = ({
  className,
  placeholder = 'Search for an MLS Number',
  queryId = 'default',
  selectEngagement,
  onSelect,
  inputStyle,
  hideChevron,
  disableClear,
}: SearchByMlsNumberProps) => {
  const { query, onSubmit } = useSearchByMlsNumber({ queryId });
  const handleSelect = (index: number | null) => {
    if (index != null) {
      const listing = query.data?.[index];
      if (listing) {
        onSelect(listing);
      }
    }
  };
  return (
    <AutoComplete
      dataHcName={dataHcName}
      className={className}
      placeholder={placeholder}
      onChange={onSubmit}
      disableClear={disableClear}
      hideChevron={hideChevron}
      inputStyle={inputStyle}
      options={
        query.data?.map((hit, i) => {
          const key = `mls-number-search-hit-${i}`;
          return {
            ...selectEngagement,
            searchString: `${formatDateShort(
              hit.listingStatus?.listingDate
            )} | ${formatFullAddress({
              ...hit.address,
              address: hit.address?.streetAddress,
            })}`,
            value: i,
            label: (
              <div key={key}>
                <span
                  className={styles.Date}
                  data-hc-name={`${dataHcName}-hit-listing-id`}
                >
                  {formatMissing(hit.listingID)}
                </span>
                <span
                  className={styles.Date}
                  data-hc-name={`${dataHcName}-hit-date`}
                >
                  {formatDateShort(hit.listingStatus?.listingDate)}
                </span>
                <span data-hc-name={`${dataHcName}-hit-${i}-address`}>
                  {formatFullAddress({
                    ...hit.address,
                    address: hit.address?.streetAddress,
                  })}
                </span>
              </div>
            ),
          };
        }) || []
      }
      optionMode="async"
      config={{
        selectType: 'single',
        value: undefined,
        onSelect: handleSelect,
      }}
    />
  );
};
