import React from 'react';

import { CircleButton } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { StarIcon, StarOutlineIcon } from '@hcs/design-system';
import { usePremiumMarketInsightsFeatures } from '@hcs/self-service';
import { MarketLevel, MeaningfulEventTypes } from '@hcs/types';

interface InterestedInTableCheckboxProps {
  dataHcName: string;
  whiteBg?: boolean;
  checkedMessage?: string;
  uncheckedMessage?: string;
  checked: boolean;
  recordGoal?: boolean;
  marketLevel?: MarketLevel;
  onChange: VoidFunction;
}
export const InterestedInTableCheckbox = ({
  dataHcName,
  checked,
  checkedMessage = 'Remove Interested',
  uncheckedMessage = 'Interested',
  whiteBg = false,
  recordGoal = false,
  marketLevel = undefined,
  onChange,
}: InterestedInTableCheckboxProps) => {
  const {
    handleMarketInsightsOpen,
    isFreeMiUser,
    renderedProductPreviewDialog,
  } = usePremiumMarketInsightsFeatures();

  return (
    <>
      <Tooltip
        label={checked ? checkedMessage : uncheckedMessage}
        position="midLeft"
        dataHcName={`${dataHcName}-popover`}
        trigger={
          <CircleButton
            dataHcName={`${dataHcName}-button`}
            dataHcEventType={recordGoal ? MeaningfulEventTypes.Goal : undefined}
            dataHcEventName={
              recordGoal
                ? `MI ${checked ? 'remove' : 'add'} interested ${marketLevel}`
                : undefined
            }
            shadow={false}
            buttonStyle={
              checked
                ? 'highlightYellow'
                : whiteBg
                ? 'secondaryNeutral'
                : 'tertiary'
            }
            onClick={() => {
              if (isFreeMiUser) {
                handleMarketInsightsOpen();
                return;
              }
              onChange();
            }}
            size="sm"
          >
            {checked ? (
              <StarIcon
                dataHcName={`${dataHcName}-icon-checked`}
                dataHcEventIgnore
                size={18}
                color="neutral-dark-10"
              />
            ) : (
              <StarOutlineIcon
                dataHcName={`${dataHcName}-icon-unchecked`}
                dataHcEventIgnore
                size={18}
                color="neutral-dark-10"
              />
            )}
          </CircleButton>
        }
      />
      {renderedProductPreviewDialog}
    </>
  );
};
