import React from 'react';

import { Tooltip } from '@hcs/design-system';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { Pagination } from '@hcs/design-system';
import { InfoIcon } from '@hcs/design-system';
import { useComponentDidMount } from '@hcs/hooks';
import { DexpJobStatus } from '@hcs/types';
import { formatDateLong, formatNumber, formatPercent } from '@hcs/utils';

import { useDexpJobs } from '../../hooks/useDexpJobs';
import { AnalysisStatus } from '../AnalysisStatus';

import styles from './AnalysisResultsTable.module.css';

interface AnalysisResultsTableProps {
  className?: string;
  onChangePage?: (page: number) => void;
}

const DEXP_COMPLETED_STATUSES = [
  DexpJobStatus.Completed,
  DexpJobStatus.CompletedWithErrors,
  DexpJobStatus.GeneratingWorkbook,
];
const dataHcName = 'analysis-results-table';
export const AnalysisResultsTable = (props: AnalysisResultsTableProps) => {
  const { className } = props;
  const {
    isInitialLoading,
    isError,
    data,
    refetch,
    loadNextPage,
    loadPrevPage,
  } = useDexpJobs();
  useComponentDidMount(refetch);

  if (isError) {
    return (
      <NullState
        title="Failed to Load Analysis Results"
        dataHcName={`${dataHcName}-error`}
        absCenter
      />
    );
  }
  if (isInitialLoading || !data?.data) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  return (
    <>
      <Table dataHcName={dataHcName} className={className}>
        <TableHeader sticky>
          <TableHeaderCell dataHcName={`${dataHcName}-created-at-header`}>
            Date
          </TableHeaderCell>
          <TableHeaderCell dataHcName={`${dataHcName}-analysis-status-header`}>
            Status
          </TableHeaderCell>
          <TableHeaderCell
            dataHcName={`${dataHcName}-analyzed-data-points-header`}
            align="right"
          >
            Analyzed Data Points
            <Tooltip
              dataHcName={`${dataHcName}-analyzed-data-points-popover`}
              trigger={
                <InfoIcon
                  className={styles.InfoIcon}
                  size="xs"
                  color="neutral-dark-10"
                  dataHcName={`${dataHcName}-analyzed-data-points-info-icon`}
                />
              }
              label={
                <span
                  data-hc-name={`${dataHcName}-analyzed-data-points-info-text`}
                >
                  Percentage of data points analyzed in the uploaded file.
                </span>
              }
              showOnMouseEnter
            />
          </TableHeaderCell>
          <TableHeaderCell dataHcName={`${dataHcName}-file-name-header`}>
            File
          </TableHeaderCell>
          <TableHeaderCell
            dataHcName={`${dataHcName}-properties-header`}
            align="right"
          >
            Properties
          </TableHeaderCell>
          <TableHeaderCell dataHcName={`${dataHcName}-created-by-header`}>
            Created By
          </TableHeaderCell>
        </TableHeader>
        {data.data?.map((result) => {
          return (
            <TableRow
              key={`result-${result.id}`}
              dataHcName={`${dataHcName}-row`}
              height={60}
              dataHcEventData={JSON.stringify({ jobId: result.id })}
            >
              <TableCell dataHcName={`${dataHcName}-created-at`}>
                {formatDateLong(result.createdAt)}
              </TableCell>
              <TableCell>
                <AnalysisStatus
                  className={styles.Status}
                  statusDisplay={result.statusDisplay}
                  statusMessage={result.statusMessage}
                  outputFile={result.outputFile}
                  status={result.status}
                />
              </TableCell>
              <TableCell align="right">
                {DEXP_COMPLETED_STATUSES.includes(result.status) ? (
                  <Tooltip
                    dataHcName={`${dataHcName}-data-points-analyzed-rate-popover`}
                    showOnMouseEnter
                    trigger={
                      <span
                        data-hc-name={`${dataHcName}-data-points-analyzed-rate`}
                      >
                        {formatPercent(
                          (result.dataPointsTotal -
                            result.dataPointsMissingData) /
                            result.dataPointsTotal
                        )}
                      </span>
                    }
                    label={
                      <span
                        className={styles.HelpText}
                        data-hc-name={`${dataHcName}-data-points-analyzed-text`}
                      >
                        {`${formatNumber(
                          result.dataPointsTotal - result.dataPointsMissingData
                        )} out of ${formatNumber(
                          result.dataPointsTotal
                        )} Data Points Populated`}
                      </span>
                    }
                  />
                ) : (
                  <span
                    data-hc-name={`${dataHcName}-data-points-analyzed-incomplete`}
                  >
                    --
                  </span>
                )}
              </TableCell>
              <TableCell
                className={styles.FileNameCell}
                dataHcName={`${dataHcName}-file-name`}
              >
                {result.fileName}
              </TableCell>
              <TableCell dataHcName={`${dataHcName}-properties`} align="right">
                {result.addressesCount}
              </TableCell>
              <TableCell dataHcName={`${dataHcName}-created-by`}>
                {result.ownerEmail}
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
      <Pagination
        dataHcName={`${dataHcName}-pagination`}
        {...data.pagination}
        className={styles.Pagination}
        onClickNext={loadNextPage}
        onClickPrev={loadPrevPage}
      />
    </>
  );
};
