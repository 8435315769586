import React, { ReactNode, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { useUrlState } from '@hcs/hooks';
import { TabIds } from '@hcs/types';

import { TabButton } from '../TabButton';

import styles from './CardTabs.module.css';

export interface Tab {
  tabId: string;
  noPadding?: boolean;
  dataHcEventSection?: string;
  label: ReactNode;
  content: ReactNode;
  scrollable?: boolean;
  onClick?: (tabId: string) => void;
}

interface CardTabsTheme {
  ActiveTabLabel: string;
  ActiveTabLabelText: string;
  TabButton: string;
  Content: string;
  lastSelected: string;
}

// This allows other components to change the tab programmatically
export const useCardTabsState = (
  searchParamId: string,
  activeTabId?: string
) => {
  const stateId = `tabsSection_${searchParamId}`;
  const INITIAL_STATE: { activeTabId: string | null } = {
    activeTabId: activeTabId || null,
  };
  const urlState = useUrlState(stateId, INITIAL_STATE);
  const setActiveTabId = (value: string) =>
    urlState.actions.patchUrlState([
      { op: 'add', path: '/activeTabId', value },
    ]);
  return {
    ...urlState,
    actions: {
      setActiveTabId,
    },
  };
};

interface ActionTextConfig {
  tabId: TabIds;
  content: ReactNode;
}
export interface CardTabsProps {
  dataHcName: string;
  /** For searchParam active tab */
  searchParamId: string;
  className?: string;
  theme?: Partial<CardTabsTheme>;
  actionTextConfig?: ActionTextConfig;
  tabs: Tab[];
  active?: string;
  maxWidth?: number;
  minWidth?: number;
  flexTabs?: boolean;
  minContentHeight?: number | string;
}
export const CardTabs = ({
  searchParamId,
  active,
  dataHcName,
  theme,
  actionTextConfig,
  className,
  minWidth,
  maxWidth,
  tabs,
  flexTabs,
  minContentHeight,
}: CardTabsProps) => {
  const {
    state: { activeTabId: activeTabIdState },
    actions: { setActiveTabId },
  } = useCardTabsState(searchParamId, active);
  const activeTabId = activeTabIdState
    ? activeTabIdState
    : tabs?.[0]
    ? tabs[0].tabId
    : null;
  const activeTab = useMemo(() => {
    return tabs.find((t) => t.tabId === activeTabId);
  }, [activeTabId, tabs]);
  useEffect(() => {
    if (!activeTab) {
      tabs?.[0] && setActiveTabId(tabs[0].tabId);
    }
  }, [activeTab]);
  useEffect(() => {
    if (active) {
      setActiveTabId(active);
    }
  }, [active]);

  return (
    <section
      data-hc-name={dataHcName}
      className={className}
      style={{ minWidth, maxWidth }}
    >
      <div
        className={classNames(styles.Tabs, { [styles.flexTabs]: flexTabs })}
        role="tablist"
      >
        {tabs?.map((t, i) => {
          const isActiveTab = t.tabId === activeTabId;
          return (
            <TabButton
              dataHcName={`${dataHcName}-${t.tabId}`}
              dataHcEventSection={t.dataHcEventSection}
              onClick={() => {
                t.onClick?.(t.tabId);
                setActiveTabId(t.tabId);
              }}
              key={`tab-${t.tabId}`}
              isActive={isActiveTab}
              tabIndex={i}
              label={t.label}
              curveRight={flexTabs && i === tabs.length - 1 ? false : undefined}
              theme={{
                Label: theme?.ActiveTabLabelText,
                TabButton: theme?.TabButton,
              }}
            />
          );
        })}
      </div>
      <div
        data-hc-name={`${dataHcName}-content`}
        style={minContentHeight ? { minHeight: minContentHeight } : undefined}
        className={classNames(styles.Content, theme?.Content, {
          [styles.firstSelected]: tabs?.[0] && tabs[0].tabId === activeTabId,
          [styles.lastSelected]:
            tabs[tabs.length - 1]?.tabId === activeTabId && flexTabs,
          [theme?.lastSelected || '']:
            tabs[tabs.length - 1]?.tabId === activeTabId && flexTabs,
          [styles.ContentPadding]: activeTab?.noPadding !== true,
          [theme?.ActiveTabLabel || '']: activeTab,
          [styles.scrollingContent]: activeTab?.scrollable,
        })}
      >
        {actionTextConfig &&
          activeTabId === actionTextConfig.tabId &&
          actionTextConfig.content}
        {activeTab?.content}
      </div>
    </section>
  );
};
