import React from 'react';

import { TabOption, Tabs } from '@hcs/design-system';

import { BulkUploadAddProperties } from '../../features/BulkUploadAddProperties';
import { ManuallyAddProperty } from '../../features/ManuallyAddProperty';

import styles from './AddPropertiesTabSection.module.css';

interface Props {
  portfolioId: string;
  onClickGoToPortfolio: (portfolioId: string) => void;
  onBulkUploadSuccess: VoidFunction;
}

type TabType = TabOption<'bulkUpload' | 'manuallyAdd'>;

const dataHcName = 'add-properties-section';

export const AddPropertiesTabSection = ({
  portfolioId,
  onBulkUploadSuccess,
  onClickGoToPortfolio,
}: Props) => {
  const tabs: TabType[] = [
    {
      value: 'bulkUpload',
      label: 'Bulk Upload',
      content: (
        <BulkUploadAddProperties
          portfolioId={portfolioId}
          dataHcName="portfolio-bulk-upload-add-properties"
          onSuccess={onBulkUploadSuccess}
        />
      ),
    },
    {
      value: 'manuallyAdd',
      label: 'Manually Add',
      content: (
        <ManuallyAddProperty
          onClickGoToPortfolio={onClickGoToPortfolio}
          portfolioId={portfolioId}
          dataHcName="portfolio-manually-add-property"
        />
      ),
    },
  ];

  return (
    <div data-hc-name={dataHcName} className={styles.AddPropertiesContainer}>
      <Tabs
        theme={{ Content: styles.Content }}
        dataHcName={`${dataHcName}-tabs`}
        searchParamId="subject-property-details-section-tabs"
        tabs={tabs}
      />
    </div>
  );
};
