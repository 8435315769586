import {
  AddressObj,
  PortfolioAvmThresholdValue,
  PortfolioConfigFields,
  PortfolioEventsFields,
  PortfolioEventsPayload,
  PortfolioEventsResponse,
  PortfolioFields,
  PortfolioForm,
  PortfolioFormFields,
  PortfolioFormNotificationTypes,
  PortfolioLtvThreshold,
  PortfolioNotificationFields,
  PortfolioPayload,
  PortfolioResponse,
  UploadStatus,
} from '@hcs/types';
import { formatTime } from '@hcs/utils';

import {
  EVENT_FIELDS_TO_NOTIFICATION_TYPE,
  PORTFOLIO_EVENTS_FIELDS_LIST,
} from '../constants/Portfolio.constants';
import { DEFAULT_PORTFOLIO_SETUP_FORM_VALUES } from '../constants/PortfolioForm.constants';

const transformLtvThresholds = (
  ltvThresholds: PortfolioForm[PortfolioFormFields.LtvThresholds]
): PortfolioEventsPayload[PortfolioEventsFields.LtvThresholds] => {
  return (
    ltvThresholds
      ?.filter((ltvThreshold): ltvThreshold is PortfolioLtvThreshold => {
        return !!ltvThreshold.threshold;
      })
      .map((ltvThreshold) => ({
        ...ltvThreshold,
        threshold: ltvThreshold.threshold / 100,
      })) || null
  );
};

const transformAvmThresholds = (
  avmThresholds: PortfolioForm[PortfolioFormFields.AvmThresholds]
): PortfolioEventsPayload[PortfolioEventsFields.Avm] => {
  return avmThresholds && avmThresholds.thresholds
    ? {
        ...avmThresholds,
        thresholds: avmThresholds.thresholds
          .filter(
            (threshold): threshold is PortfolioAvmThresholdValue =>
              !!threshold.value
          )
          .map((threshold) => ({
            ...threshold,
            value: threshold.value / 100,
          })),
      }
    : {};
};

export const getCreatePortfolioPayload = (
  formData: PortfolioForm
): PortfolioPayload => {
  const {
    name,
    notificationType,
    mlsStatus,
    ltvThresholds,
    emails,
    avmThresholds,
    liens,
    webhookUrl,
    username,
    password,
  } = formData;
  const isLtvSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Ltv
  );
  const isMlsSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Mls
  );
  const isAvmSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Avm
  );
  const isLienSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Lien
  );
  const isNodSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Nod
  );
  const webhookConfig =
    webhookUrl && username && password
      ? {
          [PortfolioNotificationFields.WebhookUrl]: webhookUrl,
          [PortfolioNotificationFields.WebhookUsername]: username,
          [PortfolioNotificationFields.WebhookPassword]: password,
        }
      : null;

  const transformedLtvThresholds = transformLtvThresholds(ltvThresholds);
  const transformedAvmThresholds = transformAvmThresholds(avmThresholds);

  return {
    [PortfolioFields.Name]: name,
    [PortfolioFields.Config]: {
      [PortfolioConfigFields.Notification]: {
        [PortfolioNotificationFields.Mechanism]: 'EMAIL',
        [PortfolioNotificationFields.Emails]: emails,
      },
      [PortfolioConfigFields.Webhook]: webhookConfig,
      [PortfolioConfigFields.Events]: {
        [PortfolioEventsFields.Status]: isMlsSelected ? mlsStatus : null,
        [PortfolioEventsFields.Nod]: isNodSelected ? isNodSelected : null,
        [PortfolioEventsFields.LtvThresholds]: isLtvSelected
          ? transformedLtvThresholds
          : null,
        [PortfolioEventsFields.Avm]: isAvmSelected
          ? transformedAvmThresholds
          : null,
        [PortfolioEventsFields.Lien]: isLienSelected ? liens : null,
      },
    },
  };
};

/**
 * @todo Create payload based on original portfolio
 *       - payload will contain only differences between
 *         form data and original state
 */
export const getUpdatePortfolioPayload = (
  formData: PortfolioForm,
  accessibleNotifications?: PortfolioFormNotificationTypes[]
): PortfolioPayload => {
  const {
    name,
    notificationType,
    mlsStatus,
    ltvThresholds,
    emails,
    avmThresholds,
    liens,
    webhookUrl,
    username,
    password,
  } = formData;

  const nodAccessible =
    accessibleNotifications &&
    accessibleNotifications?.includes(PortfolioFormNotificationTypes.Nod);

  const isLtvSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Ltv
  );
  const isMlsSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Mls
  );
  const isAvmSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Avm
  );
  const isLienSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Lien
  );
  const isNodSelected = notificationType.includes(
    PortfolioFormNotificationTypes.Nod
  );

  const transformedLtvThresholds = transformLtvThresholds(ltvThresholds);
  const transformedAvmThresholds = transformAvmThresholds(avmThresholds);

  const mlsPayload = isMlsSelected ? mlsStatus : [];
  const nodPayload = nodAccessible ? isNodSelected : null;
  const ltvPayload = isLtvSelected ? transformedLtvThresholds : [];

  const isAvmAccessible = accessibleNotifications?.includes(
    PortfolioFormNotificationTypes.Avm
  );
  const avmPayload =
    isAvmSelected && isAvmAccessible
      ? transformedAvmThresholds
      : isAvmAccessible
      ? {}
      : null;

  const isLienAccessible = accessibleNotifications?.includes(
    PortfolioFormNotificationTypes.Lien
  );
  const lienPayload =
    isLienSelected && isLienAccessible ? liens : isLienAccessible ? [] : null;

  const webhookConfig =
    webhookUrl && username && password
      ? {
          [PortfolioNotificationFields.WebhookUrl]: webhookUrl,
          [PortfolioNotificationFields.WebhookUsername]: username,
          [PortfolioNotificationFields.WebhookPassword]: password,
        }
      : webhookUrl
      ? {
          [PortfolioNotificationFields.WebhookUrl]: webhookUrl,
          [PortfolioNotificationFields.WebhookUsername]: null,
          [PortfolioNotificationFields.WebhookPassword]: null,
        }
      : null;

  return {
    [PortfolioFields.Name]: name,
    [PortfolioFields.Config]: {
      [PortfolioConfigFields.Notification]: {
        [PortfolioNotificationFields.Mechanism]: 'EMAIL',
        [PortfolioNotificationFields.Emails]: emails,
      },
      [PortfolioConfigFields.Events]: {
        [PortfolioEventsFields.Status]: mlsPayload,
        [PortfolioEventsFields.LtvThresholds]: ltvPayload,
        [PortfolioEventsFields.Avm]: avmPayload,
        [PortfolioEventsFields.Lien]: lienPayload,
        [PortfolioEventsFields.Nod]: nodPayload,
      },
      [PortfolioConfigFields.Webhook]: webhookConfig,
    },
  };
};

/**
 * 0.5 seconds per address is the agreed upon approx
 * process time by product and BE
 */
const APPROXIMATE_TIME_TO_PROCESS_ADDRESS = 0.5;
export const getEstimatedUploadCompletionTime = (
  uploadStatus: UploadStatus
) => {
  const { totalAddressesInFile, processedCount } = uploadStatus;

  const addressesLeftToUpload = totalAddressesInFile - processedCount;
  const timeRemainingSeconds =
    APPROXIMATE_TIME_TO_PROCESS_ADDRESS * addressesLeftToUpload;

  const currentDate = new Date();
  currentDate.setSeconds(currentDate.getSeconds() + timeRemainingSeconds);

  return formatTime(currentDate);
};

const getNotificationsTypeArr = (
  mlsStatus: PortfolioEventsResponse[PortfolioEventsFields.Status],
  ltvThresholds: PortfolioEventsResponse[PortfolioEventsFields.LtvThresholds],
  avmThresholds: PortfolioEventsResponse[PortfolioEventsFields.Avm],
  liens: PortfolioEventsResponse[PortfolioEventsFields.Lien],
  nod: PortfolioEventsResponse[PortfolioEventsFields.Nod]
): PortfolioFormNotificationTypes[] => {
  const notificationsTypeArr = [];
  if (mlsStatus && mlsStatus.length > 0) {
    notificationsTypeArr.push(PortfolioFormNotificationTypes.Mls);
  }

  if (ltvThresholds && ltvThresholds.length > 0) {
    notificationsTypeArr.push(PortfolioFormNotificationTypes.Ltv);
  }

  if (avmThresholds)
    notificationsTypeArr.push(PortfolioFormNotificationTypes.Avm);

  if (liens && liens.length > 0)
    notificationsTypeArr.push(PortfolioFormNotificationTypes.Lien);

  if (nod) notificationsTypeArr.push(PortfolioFormNotificationTypes.Nod);

  return notificationsTypeArr;
};

export const getNotificationsTypeStr = (
  portfolio: PortfolioResponse
): string => {
  const {
    config: {
      events: {
        status: mlsStatus,
        ltvThresholds,
        avm: avmThresholds,
        lien,
        nod,
      },
    },
  } = portfolio;

  const notificationTypeArr = getNotificationsTypeArr(
    mlsStatus,
    ltvThresholds,
    avmThresholds,
    lien,
    nod
  );
  return notificationTypeArr.join();
};

export const getFormattedInitialPortfolioFormData = (
  data: PortfolioResponse
): PortfolioForm => {
  const {
    name,
    config: {
      events: {
        status: mlsStatus,
        ltvThresholds,
        avm: avmThresholds,
        lien,
        nod,
      },
      notification: { emails },
      webhook,
    },
  } = data;

  const notificationTypeArr = getNotificationsTypeArr(
    mlsStatus,
    ltvThresholds,
    avmThresholds,
    lien,
    nod
  );
  const initialMlsStatus =
    mlsStatus && mlsStatus.length > 0
      ? mlsStatus
      : DEFAULT_PORTFOLIO_SETUP_FORM_VALUES[PortfolioFormFields.MlsStatus];

  const initialLiens =
    lien && lien.length > 0
      ? lien
      : DEFAULT_PORTFOLIO_SETUP_FORM_VALUES[PortfolioFormFields.Liens];

  const initialNod = nod
    ? nod
    : DEFAULT_PORTFOLIO_SETUP_FORM_VALUES[PortfolioFormFields.Nod];

  const transformedLtvThresholds =
    ltvThresholds?.map((ltvThreshold) => ({
      ...ltvThreshold,
      threshold: ltvThreshold.threshold * 100,
    })) ||
    DEFAULT_PORTFOLIO_SETUP_FORM_VALUES[PortfolioFormFields.LtvThresholds];

  const transformedAvmThresholds: PortfolioForm[PortfolioFormFields.AvmThresholds] =
    avmThresholds
      ? {
          ...avmThresholds,
          thresholds:
            avmThresholds?.thresholds.map((threshold) => ({
              ...threshold,
              // Convert percent to display value and limit to 2 decimal places i.e. 0.1234 => 12.34
              value: parseFloat((threshold.value * 100).toFixed(2)),
            })) || null,
        }
      : DEFAULT_PORTFOLIO_SETUP_FORM_VALUES[PortfolioFormFields.AvmThresholds];

  return {
    [PortfolioFormFields.Name]: name,
    [PortfolioFormFields.NotificationType]: notificationTypeArr,
    [PortfolioFormFields.MlsStatus]: initialMlsStatus,
    [PortfolioFormFields.LtvThresholds]: transformedLtvThresholds,
    [PortfolioFormFields.AvmThresholds]: transformedAvmThresholds,
    [PortfolioFormFields.Liens]: initialLiens,
    [PortfolioFormFields.Nod]: initialNod,
    [PortfolioFormFields.Emails]: emails,
    [PortfolioFormFields.WebhookUrl]: webhook?.webhookUrl || null,
  };
};

export const getAssetFullAddress = ({
  address,
  unit,
  city,
  state,
  zipcode,
}: AddressObj) => {
  return `${address}
  ${unit && ` ${unit}`}
  ${city && `, ${city}`}
  ${state && `, ${state}`}
  ${zipcode && ` ${zipcode}`}`;
};

/**
 * If any events exist in the config where the user is not
 * subscribed to them - set them to null
 *
 * TODO: Once BE does a check for subscriptions before returning
 * config there will be no need for this check
 */
export const removeUnsubscribedPortfolioEvents = (
  portfolio: PortfolioResponse,
  accessibleNotifications: PortfolioFormNotificationTypes[]
) => {
  // Remove unsubscribed AVM column from portfolio
  if (!accessibleNotifications.includes(PortfolioFormNotificationTypes.Avm)) {
    portfolio.columns = portfolio.columns.filter((col) => col !== 'avm');
  }

  const events = portfolio.config.events;
  PORTFOLIO_EVENTS_FIELDS_LIST.forEach((eventField) => {
    if (
      !accessibleNotifications.includes(
        EVENT_FIELDS_TO_NOTIFICATION_TYPE[eventField]
      )
    ) {
      events[eventField] = null;
    }
  });
};
