import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ExportRequest } from '@hcs/types';

import { NewOrderApi } from '../api/newOrderApi';

export const QUERY_KEY_EXPORT_REQUESTS = 'export-requests';
export const useExportRequests = (
  orderId: number,
  options?: UseQueryOptions<ExportRequest[], AxiosError, ExportRequest[]>
) =>
  useQuery<ExportRequest[], AxiosError>(
    [QUERY_KEY_EXPORT_REQUESTS, orderId],
    () => NewOrderApi.exportRequests(orderId),
    options
  );
