import { useMemo } from 'react';

import { KeywordListingInput } from '@hcs/types';
import { CompTypes } from '@hcs/types';
import { CompId, ReportId } from '@hcs/types';

import { useCompsFarmDocument } from './useCompsFarmDocument';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
}
export const useCompKeywordSearchInputs = ({ reportId, compType }: Props) => {
  const farmQuery = useCompsFarmDocument(reportId, compType);
  const listingsInput = useMemo((): KeywordListingInput[] => {
    // Call the keyword search api for the whole unfiltered farm
    // We only display results from the filtered farm
    const compIds = Object.keys(farmQuery.data?.data.farm || {}) as CompId[];
    const results: KeywordListingInput[] = [];
    compIds.forEach((compId) => {
      const compSchema = farmQuery.data?.data.farm?.[compId];
      if (compSchema) {
        const cerberusId =
          compType === CompTypes.Rental
            ? compSchema.propertyState.listingDetailsRental?.entityId
            : compSchema.propertyState.listingDetailsSale?.entityId;
        if (cerberusId) {
          results.push({
            cerberus_id: cerberusId,
          });
        }
      }
    });
    return results;
  }, [farmQuery.data]);
  return {
    ...farmQuery,
    data: listingsInput,
  };
};
