export const QUARTER_OF_AN_ACRE_IN_SQUARE_FEET = 10890;

export const degreesToRadians = (degrees: number | undefined) => {
  if (degrees !== undefined) {
    const radians = degrees % 360;
    return (radians * Math.PI) / 180;
  }
  return undefined;
};

export const radiansToDegrees = (radians: number | undefined) => {
  if (radians !== undefined) {
    const degrees = radians % (2 * Math.PI);
    return (degrees * 180) / Math.PI;
  }
  return undefined;
};

export const hexToRgb = (
  hex: string
): { red: number; green: number; blue: number } | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result?.[1] || '0', 16),
        green: parseInt(result?.[2] || '0', 16),
        blue: parseInt(result?.[3] || '0', 16)
      }
    : null;
};

export const nullToUndefined = <T>(v: T | null): T | undefined =>
  v === null ? undefined : v;
