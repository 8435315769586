import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { PropertyStateLocation } from '@hcs/types';
import { StreetViewOptions, StreetViewUrlResponse } from '@hcs/types';

import { StreetViewApi } from '../api/streetView.api';

const STREET_VIEW_QUERY_KEY = 'googleStreetView';
export const useStreetView = (
  location?: PropertyStateLocation['location'] | null,
  options?: StreetViewOptions,
  useQueryOptions?: UseQueryOptions<
    StreetViewUrlResponse | null,
    unknown,
    StreetViewUrlResponse,
    [string, number | null | undefined, number | null | undefined]
  >
) => {
  const hasCoordinates = !!(location?.latitude && location.longitude);
  return useQuery(
    [STREET_VIEW_QUERY_KEY, location?.latitude, location?.longitude],
    async () => {
      return await StreetViewApi.fetchStreetViewUrl(location, options);
    },
    {
      ...useQueryOptions,
      enabled: hasCoordinates
        ? useQueryOptions?.enabled !== undefined
          ? useQueryOptions.enabled
          : true
        : false,
    }
  );
};
