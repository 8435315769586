import React from 'react';

import { PropertyStateCerberusInput } from '@hcs/types';

import { CustomCellData } from '../../components/PropertyTable';
import {
  PropertyTableRow,
  PropertyTableRowProps,
} from '../../components/PropertyTable/PropertyTableRow';
import { usePropertyState } from '../../hooks/usePropertyState';

export interface PropertyStateTableRowProps<D extends CustomCellData>
  extends Omit<PropertyTableRowProps<D>, 'propertyStateArgs' | 'isLoading'> {
  propertyStateCerberusInput: PropertyStateCerberusInput;
}

export const PropertyStateTableRow = <D extends CustomCellData>({
  propertyStateCerberusInput,
  ...propertyTableRowProps
}: PropertyStateTableRowProps<D>) => {
  const { data: propertyStateArgs } = usePropertyState(
    propertyStateCerberusInput
  );

  return (
    <PropertyTableRow
      {...propertyTableRowProps}
      propertyStateArgs={propertyStateArgs}
    />
  );
};

// Needed for Table child checks
PropertyStateTableRow.defaultProps = {
  isTableRow: true,
};
