import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PortfolioNotificationsSliceState } from '@hcs/types';
import { resetOnLogoutAction } from '@hcs/utils';

export const PORTFOLIO_NOTIFICATION_STORAGE_ID =
  'portfolio-monitor-acknowledged-notifications';

const localStorageActions = {
  acknowledgedNotifications: (): string[] => {
    // SECURITY: localStorage is referenced to access values from global local storage @drifkin
    // eslint-disable-next-line scanjs-rules/identifier_localStorage
    const rawIds = localStorage.getItem(PORTFOLIO_NOTIFICATION_STORAGE_ID);
    return rawIds ? JSON.parse(rawIds) : [];
  },
  setAcknowledgedNotifications: (portfolioIds: string[]) => {
    if (portfolioIds.length === 0) {
      // SECURITY: localStorage is referenced to access values from global local storage @drifkin
      // eslint-disable-next-line scanjs-rules/identifier_localStorage
      localStorage.removeItem(PORTFOLIO_NOTIFICATION_STORAGE_ID);
    } else {
      // SECURITY: localStorage is referenced to access values from global local storage @drifkin
      // eslint-disable-next-line scanjs-rules/identifier_localStorage
      localStorage.setItem(
        PORTFOLIO_NOTIFICATION_STORAGE_ID,
        JSON.stringify(portfolioIds)
      );
    }
  },
};

const initialState: PortfolioNotificationsSliceState = {
  acknowledgedNotifications: localStorageActions.acknowledgedNotifications(),
};

export const portfolioNotificationsSlice = createSlice({
  name: 'portfolioNotificationsSlice',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(resetOnLogoutAction, () => initialState),
  reducers: {
    acknowledgeNotification: (
      state,
      action: PayloadAction<{ portfolioId: string }>
    ) => {
      const currAcknowledgedNotifications =
        localStorageActions.acknowledgedNotifications();
      const allAcknowledgedNotifications = [
        ...currAcknowledgedNotifications,
        action.payload.portfolioId,
      ];

      localStorageActions.setAcknowledgedNotifications(
        allAcknowledgedNotifications
      );

      state.acknowledgedNotifications = allAcknowledgedNotifications;
    },
    removeAcknowledgedNotification: (
      state,
      action: PayloadAction<{ portfolioId: string }>
    ) => {
      const currAcknowledgedNotifications =
        localStorageActions.acknowledgedNotifications();
      const filteredAcknowledgedNotifications =
        currAcknowledgedNotifications.filter(
          (notifPortfolioId) => notifPortfolioId !== action.payload.portfolioId
        );

      localStorageActions.setAcknowledgedNotifications(
        filteredAcknowledgedNotifications
      );

      state.acknowledgedNotifications = filteredAcknowledgedNotifications;
    },
  },
});
