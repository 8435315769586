import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Card } from '@hcs/design-system';
import { AccountFields, ForgotPasswordFormFields } from '@hcs/types';

import { ACCOUNT_FIELD_CONFIGS } from '../../constants';
import { AccountFieldInput } from '../../features/AccountFieldInput';
import { useForgotPassword } from '../../hooks/useForgotPassword';

const EMAIL_CONFIG = ACCOUNT_FIELD_CONFIGS[AccountFields.Email];

interface Props {
  className?: string;
  resetPasswordUrl?: string;
}
const yupSchema = yup.object().shape({
  [AccountFields.Email]: EMAIL_CONFIG.validator,
});

const dataHcName = 'forgot-password';
export const ForgotPassword = ({ className, resetPasswordUrl }: Props) => {
  const { isLoading, mutate } = useForgotPassword();
  const form = useForm<ForgotPasswordFormFields>({
    resolver: yupResolver(yupSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const handleSubmit = (forgotPasswordForm: ForgotPasswordFormFields) => {
    mutate({
      ...forgotPasswordForm,
      resetPasswordUrl,
    });
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Card dataHcName={dataHcName} className={className}>
          <Controller
            name={AccountFields.Email}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Button
            dataHcName={`${dataHcName}-submit-button`}
            label="Submit"
            disabled={!form.formState.isValid || isLoading}
          />
        </Card>
      </form>
    </FormProvider>
  );
};
