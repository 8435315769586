import { useNavigate } from 'react-router';

import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';

export const useNavigateToReportsHistory = (): VoidFunction => {
  const navigate = useNavigate();
  return () => {
    navigate(`${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.REPORT_HISTORY}`);
  };
};
