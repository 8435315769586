import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { ItemObjInShoppingCart } from '@hcs/types';

import { Button } from '../../../controls-and-inputs/buttons/Button';
import { FlexScroll } from '../../layout/FlexScroll';
import { ShoppingCartTable } from '../../shopping-cart/ShoppingCart/ShoppingCartTable';

import styles from './ShoppingCart.module.css';

interface ShoppingCartProps {
  className?: string;
  dataHcName?: string;
  itemsInShoppingCart?: Record<string, ItemObjInShoppingCart>;
  footerCtaText: string;
  footerCtaDataHcName: string;
  headerText: string;
  clearAllCtaText?: string;
  onClearCart: VoidFunction;
  onClickFooterCta: VoidFunction;
  onSelectItem: (itemId: string) => void;
  onDeselectAndLeaveInCart: (itemId: string) => void;
  onRemoveFromCart: (itemId: string) => void;
  removeFromCartCtaText?: string;
  rightSideHeader?: ReactNode;
  selectedLength: number;
}

const defaultDataHcName = 'shopping-cart';
export const ShoppingCart = ({
  className,
  dataHcName = defaultDataHcName,
  itemsInShoppingCart,
  footerCtaText,
  footerCtaDataHcName,
  headerText,
  clearAllCtaText = 'Clear all',
  onClearCart,
  onClickFooterCta,
  onSelectItem,
  onDeselectAndLeaveInCart,
  onRemoveFromCart,
  removeFromCartCtaText,
  rightSideHeader,
  selectedLength,
}: ShoppingCartProps) => {
  const itemIdsInShoppingCart =
    itemsInShoppingCart && Object.keys(itemsInShoppingCart);

  if (!itemIdsInShoppingCart || itemIdsInShoppingCart.length === 0) {
    return null;
  }

  const Header = () => {
    return (
      <div className={styles.HeaderContainer}>
        <div
          className={styles.HeaderTextLeft}
          data-hc-name={`${dataHcName}-header`}
        >
          {headerText}
        </div>
        <button
          className={styles.HeaderButton}
          data-hc-name={`${dataHcName}-clear-all-button`}
          onClick={onClearCart}
        >
          {clearAllCtaText}
        </button>
        {rightSideHeader}
      </div>
    );
  };

  const Footer = () => {
    return (
      <Button
        className={styles.FooterButton}
        dataHcName={`${footerCtaDataHcName}`}
        onClick={onClickFooterCta}
        disabled={selectedLength === 0}
      >
        {footerCtaText}
      </Button>
    );
  };
  return (
    <div className={styles.ShoppingCartContainer}>
      <FlexScroll
        className={classNames(styles.ShoppingCart, className)}
        dataHcName={dataHcName}
        theme={{ Footer: styles.Footer }}
        header={{ height: 50, content: <Header /> }}
        footer={{ height: 50, content: <Footer /> }}
      >
        <ShoppingCartTable
          dataHcName={dataHcName}
          itemsInShoppingCart={itemsInShoppingCart}
          onSelectItem={onSelectItem}
          onDeselectAndLeaveInCart={onDeselectAndLeaveInCart}
          onRemoveFromCart={onRemoveFromCart}
          removeFromCartCtaText={removeFromCartCtaText}
        />
      </FlexScroll>
    </div>
  );
};
