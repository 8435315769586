import axios, { AxiosError, AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import FileDownload from 'js-file-download';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import {
  DexpCreateJobInputs,
  DexpEndpoint,
  DexpEvent,
  DexpEventType,
  DexpJob,
  DexpJobCreateError,
  DexpJobCreateResponse,
  DexpJobsResponse,
  DexpJobStatus,
} from '@hcs/types';
import { DATA_EXPLORER_URL } from '@hcs/urls';
import { makePaginatedResponseData } from '@hcs/utils';

import { DEXP_REPORTS_PER_PAGE } from '../constants';

export interface DexpJobsPaginationOptions {
  page?: number;
  pageSize?: number;
}

const handleCreateJobResponse = (
  response: AxiosResponse<DexpJobCreateResponse>
) => {
  const data = camelcaseKeys(response.data, { deep: true });
  const endpoints = data.endpoints.split(',');
  const createdPseudoEvent: DexpEvent = {
    event: DexpEventType.Created,
    job: {
      id: data.id,
      status: DexpJobStatus.Created,
      errorMessage: null,
      statusMessage: null,
      endpoints,
      endpointsCount: endpoints.length,
      userId: null,
      statusDisplay: 'Created',
      chunksTotal: 0,
      chunksCompleted: 0,
      chunksUnauthorized: 0,
      chunksInError: 0,
      chunksUnprocessed: 0,
      dataPointsTotal: 0,
      dataPointsCompleted: 0,
      dataPointsUnauthorized: 0,
      dataPointsMissingData: 0,
      dataPointsInError: 0,
      pausedAt: null,
      pausedFor: null,
      retryNo: 0,
      addressesCount: 0,
      addresses: [],
      apiAuthKeyId: null,
      createdAt: null,
      updatedAt: null,
      fileName: null,
      outputFile: null,
      ownerEmail: null,
    },
  };
  return createdPseudoEvent;
};

export const DataExplorerApi = {
  fetchDexpJobs: async (
    paginationOptions?: DexpJobsPaginationOptions
  ): Promise<DexpJobsResponse> => {
    const { page = 1, pageSize = DEXP_REPORTS_PER_PAGE } =
      paginationOptions || {};
    const response = await AxiosAccessTokenClientJwt.get<DexpJob[]>(
      `${DATA_EXPLORER_URL}/api/job/?page_size=${pageSize}&page=${page}`
    );
    const dexpJobsResponse: DexpJobsResponse = makePaginatedResponseData(
      response,
      {
        page,
        pageSize,
      },
      { camelCaseKeys: true }
    );
    return dexpJobsResponse;
  },
  fetchDexpJob: async (jobId: number): Promise<DexpJob> => {
    const response = await AxiosAccessTokenClientJwt.get<DexpJob>(
      `${DATA_EXPLORER_URL}/api/job/${jobId}/`
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  fetchDexpEndpoints: async () => {
    const response = await AxiosAccessTokenClientJwt.get<DexpEndpoint[]>(
      `${DATA_EXPLORER_URL}/api/endpoints/`
    );
    const data: DexpEndpoint[] = camelcaseKeys(response.data, { deep: true });
    return data;
  },
  downloadDexpJob: async (url: string) => {
    const response = await AxiosAccessTokenClientJwt({
      url,
      responseType: 'blob',
    });

    const file = response.data as Blob;
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
      const fileNameSearch = contentDisposition.match('filename="(.*)"');
      if (fileNameSearch !== null && fileNameSearch[1]) {
        const fileName = fileNameSearch[1];
        FileDownload(file, fileName);
      }
    }
    return { data: null };
  },
  uploadAndCreateJob: async (formData: FormData) => {
    try {
      const response =
        await AxiosAccessTokenClientJwt.post<DexpJobCreateResponse>(
          `${DATA_EXPLORER_URL}/upload/`,
          formData
        );

      return handleCreateJobResponse(response);
    } catch (err: unknown) {
      let message = '';
      if (axios.isAxiosError(err)) {
        // Unfortunately, the above util doesn't accept a type argument for the error data
        const error = err as AxiosError<DexpJobCreateError>;
        const data = error.response?.data;
        if (typeof data === 'string') {
          message = data;
        } else if (data?.addresses) {
          if (typeof data.addresses === 'string') {
            message = data.addresses;
          } else {
            message = data.addresses.join('\n');
          }
        }
      }
      throw new Error(message);
    }
  },
  createJob: async (inputs: DexpCreateJobInputs) => {
    try {
      const response =
        await AxiosAccessTokenClientJwt.post<DexpJobCreateResponse>(
          `${DATA_EXPLORER_URL}/create/`,
          snakecaseKeys(inputs, { deep: true })
        );

      return handleCreateJobResponse(response);
    } catch (err: unknown) {
      let message = '';
      if (axios.isAxiosError(err)) {
        // Unfortunately, the above util doesn't accept a type argument for the error data
        const error = err as AxiosError<DexpJobCreateError>;
        const data = error.response?.data;
        if (typeof data === 'string') {
          message = data;
        } else if (data?.addresses) {
          if (typeof data.addresses === 'string') {
            message = data.addresses;
          } else {
            message = data.addresses.join('\n');
          }
        }
      }
      throw new Error(message);
    }
  },
};
