import { combineReducers } from '@reduxjs/toolkit';

import { compFilterBarSlice } from '../features/CompFilterBar/rtk/compFilterBar.slice';

import { compCompareDialogSlice } from './compCompareDialog.slice';
import { reportConfigSlice } from './reportConfig.slice';

export const REPORT_API_REDUCER_KEY = 'reportApi';
export const reportApiReducer = combineReducers({
  [compCompareDialogSlice.name]: compCompareDialogSlice.reducer,
  [compFilterBarSlice.name]: compFilterBarSlice.reducer,
  [reportConfigSlice.name]: reportConfigSlice.reducer,
});
