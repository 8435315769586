import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './EntryPageContainer.module.css';

export interface EntryPageContainerProps {
  children: ReactNode;
  className?: string;
}

const dataHcName = 'entry-page-container';
export const EntryPageContainer = ({
  children,
  className,
}: EntryPageContainerProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.EntryPageContainer, className)}
    >
      {children}
    </div>
  );
};
