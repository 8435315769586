import React, { MouseEvent, ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';

import { ListingImageFragment } from '@hcs/types';

import { usePlaceholderImages } from '../../../../hooks';
import {
  WhiteChevronLeftIcon,
  WhiteChevronRightIcon,
} from '../../../../svgs';
import { Skeleton } from '../../../global';

import { ThumbnailImage } from './ThumbnailImage';

import styles from './ThumbnailCarousel.module.css';

export type ClickCallback = (e: MouseEvent, index: number) => void;

export type ThumbnailCarouselProps = {
  /** Class to apply to the root element */
  className?: string;
  /** Array of image URLs to render inside of slider */
  photos: (ListingImageFragment & { overlayUrl?: string })[];
  /** Whether to always show controls */
  alwaysShowControls?: boolean;
  /** Whether to show controls on hover */
  showControlsOnHover?: boolean;
  /** Placeholder images that are shown while thumbnails are loading */
  placeholderImgs?: Array<string>;
  /** Render skeleton */
  isLoading?: boolean;
  style?: { [key: string]: string | number };
  onClick?: ClickCallback;
  onMouseLeave?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onClickNext?: ClickCallback;
  onClickPrev?: ClickCallback;
  children?: ReactNode;
  dataHcName: string;
};

/**
 * Displays a simple image thumbnail carousel for use in property cards and other
 * list items. We're opting not to use React-slick here since we might have tens
 * or hundreds of these rendered at once, which would dramatically hinder performance.
 */
const DEFAULT_CURRENT_INDEX = 0;
export const ThumbnailCarousel = ({
  photos,
  alwaysShowControls = false,
  showControlsOnHover = true,
  style = {},
  dataHcName = 'thumbnail-carousel',
  className,
  isLoading,
  children,
  onClick,
  onClickNext,
  onClickPrev,
  onMouseEnter,
  onMouseLeave,
}: ThumbnailCarouselProps) => {
  const placeholders = usePlaceholderImages(
    useMemo(() => photos.map((u) => u.url || ''), [photos])
  );
  const numPhotos = photos.length;
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_CURRENT_INDEX);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = (): void => {
    setIsHovering(showControlsOnHover);
    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const handleClickNext = (e: MouseEvent): void => {
    e.stopPropagation();
    const newIndex = currentIndex === numPhotos - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    if (onClickNext) {
      onClickNext(e, newIndex);
    }
  };

  const handleClickPrev = (e: MouseEvent): void => {
    e.stopPropagation();
    const newIndex = currentIndex === 0 ? numPhotos - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    if (onClickPrev) {
      onClickPrev(e, newIndex);
    }
  };

  const handleOnClick = (e: MouseEvent) => {
    if (typeof onClick === 'function') {
      e.stopPropagation();
      onClick(e, currentIndex);
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const props = {
        currentIndex,
      };
      return React.cloneElement(child, {
        ...props,
      });
    }
    return child;
  });

  const photoUrl = photos[currentIndex]?.url;
  const overlayUrl = photos[currentIndex]?.overlayUrl;
  const placeholderUrl = photoUrl ? placeholders[photoUrl] : undefined;
  return (
    <div
      className={classNames(styles.ThumbnailCarousel, className, {
        [styles.clickable]: !!onClick,
      })}
      style={style}
      data-hc-name={dataHcName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      {isLoading ? (
        <Skeleton dataHcName={`${dataHcName}-skeleton`}/>
      ) : (
        <>
          <div className={styles.CurrentImage}>
            {childrenWithProps}
            {photoUrl && (
              <ThumbnailImage
                url={photoUrl}
                dataHcName={`${dataHcName}-image-${currentIndex}`}
              />
            )}
          </div>
          {placeholderUrl && (
            <div
              className={styles.Loading}
              style={{
                backgroundImage: `url('${placeholderUrl}')`,
              }}
            />
          )}
          {overlayUrl && (
            <div
              className={styles.Overlay}
              style={{backgroundImage: `url('${overlayUrl}')`}}
            />
          )}

          {(isHovering || alwaysShowControls) && numPhotos > 0 && (
            <div className={styles.Controls}>
              {numPhotos > 1 && (
                <>
                  <WhiteChevronRightIcon
                    dataHcName={`${dataHcName}-button-next`}
                    className={classNames(styles.Arrow, styles.ArrowNext)}
                    color="neutral-light-10"
                    onClick={handleClickNext}
                  />
                  <WhiteChevronLeftIcon
                    dataHcName={`${dataHcName}-button-prev`}
                    className={styles.Arrow}
                    color="neutral-light-10"
                    onClick={handleClickPrev}
                  />
                </>
              )}
              {isHovering && (
                // Only show counter on hover even if alwaysShowControls is true
                <div
                  className={styles.Counter}
                  data-hc-name={`${dataHcName}-counter`}
                >
                  {currentIndex + 1}/{numPhotos}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
