import { useMemo } from 'react';

import { useReportPreferencesForOrg } from '@hcs/huell';
import { ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { generatePublicLinkUrl } from '../utils/report.utils';

import { useReport } from './useReport';

export const usePublicReportLink = (reportId: ReportId) => {
  const reportPrefsQuery = useReportPreferencesForOrg();
  const useReportQuery = useReport(reportId);
  const combinedQuery = combineUseQueryResult([
    reportPrefsQuery,
    useReportQuery,
  ]);

  const data = useMemo(() => {
    if (combinedQuery.isSuccess) {
      return {
        publicLink: useReportQuery.data?.metadata.publicLinkUid
          ? generatePublicLinkUrl(useReportQuery.data.metadata.publicLinkUid)
          : null,
        allowed:
          reportPrefsQuery.data?.preferences?.publicLinkPermissions ===
            'read-only' ||
          reportPrefsQuery.data?.preferences?.publicLinksReadOnly,
      };
    }
    return null;
  }, [reportPrefsQuery.data, useReportQuery.data]);

  return {
    ...combinedQuery,
    data,
  };
};
