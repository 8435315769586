import React from 'react';
import classNames from 'classnames';

import { formatNumber } from '@hcs/utils';

import styles from './CountChip.module.css';

export interface CountChipProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  count: number | null | undefined;
}
export const CountChip = ({ dataHcName, className, count }: CountChipProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CountChip, className)}
    >
      {count ? formatNumber(count) : 0}
    </div>
  );
};
