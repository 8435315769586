import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';

import { PhotoProp } from '@hcs/types';

import { FullscreenButton } from '../../../controls-and-inputs';

import styles from './PhotoGridThumbnail.module.css';

export interface PhotoGridThumbnailProps extends PhotoProp {
  hideFullscreenIcon?: boolean;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}

export const PhotoGridThumbnail = ({
  dataHcName,
  label,
  fullscreen = false,
  hideFullscreenIcon = false,
  photo,
  children,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: PhotoGridThumbnailProps) => {
  const { url } = photo;
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      data-hc-name={`${dataHcName}`}
      className={classNames(className, styles.Thumbnail)}
    >
      {!hideFullscreenIcon && fullscreen && (
        <FullscreenButton
          theme={styles}
          onClick={onClick}
          dataHcName={`${dataHcName}-fullscreen-button`}
        />
      )}
      <LazyLoadImage
        wrapperClassName={classNames(styles.LazyLoadImage, {
          [styles.clickable as string]: !!onClick,
          [styles.loaded]: isLoaded,
        })}
        alt={url || ''}
        data-hc-name={`${dataHcName}-image`}
        onLoad={() => {
          setIsLoaded(true);
        }}
        src={url || ''}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {label && (
        <div
          className={styles.ThumbnailOverlay}
          data-hc-name={`${dataHcName}-thumbnail-overlay`}
        >
          {label}
        </div>
      )}
      {children}
    </div>
  );
};
