import React, { useState } from 'react';

import { FilterRange, Input } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateFilterProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateEditCallbackArgs,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
} from '@hcs/types';
import {
  formatMissing,
  getDecimalCount,
  truncateToDecimalPlace,
} from '@hcs/utils';

const FIELD = PropertyStateFields.bathrooms;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Bathrooms';
const labelShort = 'Baths';
const propertyStatePath: PropertyStatePaths =
  '/propertyDetails/bathrooms/totalProjected';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  }
  return propertyState?.propertyDetails?.bathrooms?.totalProjected;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatMissing(getValue(propertyStateArgs));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Bath';
const formatValueShort: FieldConfig['formatValueShort'] = formatValue;

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)} ${labelShort}`;

// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const normalizeBathroomVal = (value: string | undefined) => {
  if (value && value !== '0') {
    let formatted = value.replace(/[^\d.]/g, '');
    const decIndex = formatted.indexOf('.');
    if (decIndex > -1 && decIndex + 1 !== formatted.length) {
      const valFloat = parseFloat(formatted);
      formatted = valFloat.toString();
      if (!(valFloat % 1)) {
        formatted += '.0';
      }
    }
    return formatted;
  } else {
    return undefined;
  }
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: PropertyStateEditCallbackArgs<typeof FIELD>) => void;
  className?: string;
}) => {
  const defaultValue = getValue(propertyStateArgs);
  const [value, setValue] = useState(
    defaultValue === null ? null : defaultValue?.toString()
  );

  return (
    <Input
      dataHcName="bathroom-adjust-input"
      className={className}
      value={normalizeBathroomVal(value?.toString()) || ''}
      onChange={(value) => {
        const valueOrNull = value === '' ? null : normalizeBathroomVal(value);
        setValue(valueOrNull);

        if (valueOrNull) {
          const decimalCount = getDecimalCount(Number(valueOrNull));
          const truncatedVal = truncateToDecimalPlace(
            parseFloat(valueOrNull),
            decimalCount && decimalCount > 2 ? 2 : decimalCount
          );
          onChange({
            path: propertyStatePath,
            value: Number(truncatedVal.toString()),
            field: FIELD,
          });
        }
      }}
      maxLength={5}
      onBlur={() => {
        if (value) {
          const decimalCount = getDecimalCount(Number(value));
          const truncatedVal = truncateToDecimalPlace(
            parseFloat(value),
            decimalCount && decimalCount > 2 ? 2 : decimalCount
          );
          setValue(truncatedVal.toString());
        } else {
          onChange({
            path: propertyStatePath,
            value: null,
            field: FIELD,
          });
        }
      }}
    />
  );
};

const parseFilterValue = (str: string | null | undefined): number | null => {
  if (str === '' || str == null) {
    return null;
  }
  const decimalCount = getDecimalCount(Number(str));
  const truncatedVal = truncateToDecimalPlace(
    parseFloat(str),
    decimalCount && decimalCount > 2 ? 2 : decimalCount
  );
  return Number(truncatedVal);
};
const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  const minVal =
    spatialFiltersInput.minBathsWithHalfs != null
      ? spatialFiltersInput.minBathsWithHalfs
      : spatialFiltersInput.minBaths != null
      ? spatialFiltersInput.minBaths
      : '';
  const maxVal =
    spatialFiltersInput.maxBathsWithHalfs != null
      ? spatialFiltersInput.maxBathsWithHalfs
      : spatialFiltersInput.maxBaths != null
      ? spatialFiltersInput.maxBaths
      : '';
  return (
    <FilterRange
      className={className}
      disabled={disabled}
      decimal
      maxVal={999}
      initialValue={{
        min: normalizeBathroomVal(minVal.toString()),
        max: normalizeBathroomVal(maxVal.toString()),
      }}
      onBlur={(value) => {
        const parsedMin = parseFilterValue(value.min);
        const parsedMax = parseFilterValue(value.max);
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            // Set the other baths field to null
            [parsedMin != null && parsedMin % 1
              ? 'minBaths'
              : 'minBathsWithHalfs']: null,
            [parsedMax != null && parsedMax % 1
              ? 'maxBaths'
              : 'maxBathsWithHalfs']: null,
            // Set the actual filter value using the correct key
            [parsedMin != null && parsedMin % 1
              ? 'minBathsWithHalfs'
              : 'minBaths']: parsedMin,
            [parsedMax != null && parsedMax % 1
              ? 'maxBathsWithHalfs'
              : 'maxBaths']: parsedMax,
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const BATHROOMS_CONFIG: PropertyStateFieldConfig<typeof FIELD> = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  labelMicro,
  formatValueShort,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
  Filter,
  spatialSortField: SpatialSortField.Baths,
};
