import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { OptionalEngagementProps } from '@hcs/types';

import styles from './FormError.module.css';

export interface FormErrorProps extends OptionalEngagementProps {
  /** The text content of the error */
  value: ReactNode;
  dataHcName: string;
  className?: string;
}

export const FormError = ({
  value,
  className,
  dataHcName,
  dataHcEventSection,
  dataHcEventType,
}: FormErrorProps) => {
  return (
    <div
      className={classNames(styles.FormError, className)}
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      data-hc-event-type={dataHcEventType}
    >
      {value}
    </div>
  );
};
