import React, { ReactNode, useRef } from 'react';
import classNames from 'classnames';

import { useIsOverflowed } from '@hcs/hooks';

import { Tooltip, TooltipProps } from '../../popovers/Tooltip';

import styles from './LineClamp.module.css';

const LINES_CLASSNAME_MAPPING = {
  1: styles.one,
  2: styles.two,
  3: styles.three,
  4: styles.four,
  5: styles.five,
  6: styles.six,
};
export interface LineClampProps
  extends Pick<TooltipProps, 'isLargeTooltip' | 'theme'> {
  /**
   * Optional className applied to main HTML element.
   */
  dataHcName?: string;
  className?: string;
  children: ReactNode;
  lines?: keyof typeof LINES_CLASSNAME_MAPPING;
  isLargeTooltip?: TooltipProps['isLargeTooltip'];
  renderChildrenAs?: 'tooltipLabel' | 'tooltipDescription';
}
export const LineClamp = ({
  className,
  children,
  dataHcName,
  lines = 2,
  isLargeTooltip,
  renderChildrenAs = 'tooltipLabel',
  theme,
}: LineClampProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflowed = useIsOverflowed(ref);
  const renderedContent = (
    <div
      ref={ref}
      data-hc-name={dataHcName}
      className={classNames(
        styles.LineClamp,
        className,
        LINES_CLASSNAME_MAPPING[lines]
      )}
    >
      {children}
    </div>
  );
  return isOverflowed ? (
    <Tooltip
      dataHcName="line-clamp-tooltip"
      trigger={renderedContent}
      theme={{
        ...theme,
        Trigger: classNames(styles.LineClampContainer, theme?.Trigger),
      }}
      label={renderChildrenAs === 'tooltipLabel' ? children : null}
      description={renderChildrenAs === 'tooltipDescription' ? children : null}
      isLargeTooltip={isLargeTooltip}
    />
  ) : (
    renderedContent
  );
};
