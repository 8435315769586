import React, { useState } from 'react';
import classNames from 'classnames';

import { StarFilledIcon, StarOutlineIcon } from '@hcs/design-system';

import styles from './StarCheckbox.module.css';

interface Props {
  dataHcName: string;
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (v: boolean) => void;
}

export const StarCheckbox = ({
  dataHcName,
  checked,
  disabled,
  className,
  onChange,
}: Props) => {
  const [showCheckedStar, setShowCheckedStar] = useState<boolean>(false);
  return (
    <span
      onMouseEnter={() => {
        if (!disabled) {
          setShowCheckedStar(true);
        }
      }}
      onMouseLeave={() => {
        if (!disabled) {
          setShowCheckedStar(false);
        }
      }}
      data-hc-name={dataHcName}
      className={classNames(styles.StarCheckbox, className, {
        [styles.disabled]: disabled,
      })}
      onClick={
        !disabled
          ? (e) => {
              e.stopPropagation();
              onChange(!checked);
            }
          : undefined
      }
    >
      {checked ? (
        <StarFilledIcon dataHcName={`${dataHcName}-checked`} />
      ) : showCheckedStar ? (
        <StarFilledIcon dataHcName={`${dataHcName}-checked`} />
      ) : (
        <StarOutlineIcon dataHcName={`${dataHcName}-unchecked`} />
      )}
    </span>
  );
};
