import React, { ReactNode } from 'react';
import classNames from 'classnames';
import merge from 'lodash.merge';
import {
  VictoryAxis,
  VictoryAxisProps,
  VictoryChart,
  VictoryChartProps,
} from 'victory';

import { Skeleton } from '@hcs/design-system';
import { ChartDimensions, ChartSizes } from '@hcs/types';

import {
  AXIS_PROPS_DEFAULT_X,
  AXIS_PROPS_DEFAULT_Y,
  AXIS_STYLES_X,
  AXIS_STYLES_Y,
  CHART_DIMENSIONS,
} from '../../constants';

import { ChartTitle } from './ChartTitle';

import styles from './Chart.module.css';

export interface ChartProps {
  title?: ReactNode;
  dataHcName: string;
  className?: string;
  children: ReactNode;
  chartSize?: ChartSizes;
  chartDimensions?: ChartDimensions;
  chart?: VictoryChartProps;
  yAxis?: VictoryAxisProps;
  xAxis?: VictoryAxisProps;
  hideAxisX?: boolean;
  hideAxisY?: boolean;
  contentAbove?: ReactNode;
  contentBelow?: ReactNode;
  isLoading?: boolean;
  error?: ReactNode;
}

export const Chart = ({
  dataHcName,
  className,
  title,
  chart,
  yAxis,
  xAxis,
  hideAxisX,
  hideAxisY,
  chartSize = 'medium',
  chartDimensions: chartDimensionsProp,
  children,
  contentAbove,
  contentBelow,
  isLoading,
  error,
}: ChartProps) => {
  const chartDimensions = chartDimensionsProp || CHART_DIMENSIONS[chartSize];
  const axisDimensions = {
    width: chartDimensions.width * 0.8907563025210085,
    height: chartDimensions.height * 0.75,
  };
  return (
    <div
      className={classNames(className, styles[chartSize])}
      data-hc-name={dataHcName}
      style={{
        width: `${chartDimensions.width}px`,
      }}
    >
      {title && (
        <ChartTitle dataHcName={`${dataHcName}-title`}>{title}</ChartTitle>
      )}
      {contentAbove && (
        <div data-hc-name={`${dataHcName}-content-above`}>{contentAbove}</div>
      )}
      {isLoading ? (
        <Skeleton
          dataHcName={`${dataHcName}-skeleton`}
          width={chartDimensions.width}
          height={chartDimensions.height}
          position="static"
        />
      ) : error ? (
        error
      ) : (
        <div
          style={{
            width: `${chartDimensions.width}px`,
            height: `${chartDimensions.height}px`,
          }}
        >
          <VictoryChart
            {...chart}
            width={chartDimensions.width}
            height={chartDimensions.height}
            padding={chartDimensions.padding}
          >
            {!hideAxisY && (
              <VictoryAxis
                {...merge(yAxis, AXIS_PROPS_DEFAULT_Y)}
                style={merge(yAxis?.style || {}, AXIS_STYLES_Y[chartSize])}
              />
            )}
            {!hideAxisX && (
              <VictoryAxis
                {...merge(xAxis, AXIS_PROPS_DEFAULT_X)}
                width={axisDimensions.width}
                height={axisDimensions.height}
                style={merge(xAxis?.style || {}, AXIS_STYLES_X[chartSize])}
              />
            )}
            {children}
          </VictoryChart>
        </div>
      )}

      {contentBelow && (
        <div data-hc-name={`${dataHcName}-content-below`}>{contentBelow}</div>
      )}
    </div>
  );
};
