import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useClickOutsideComponent, useForwardedRef } from '@hcs/hooks';
import { scrollFocusEl } from '@hcs/utils';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';
import { DropdownOption } from '../Dropdown/DropdownOption';

import styles from './DropdownMenu.module.css';

export interface DropdownMenuTheme {
  DropdownMenuContainer?: string;
  DropdownMenu?: string;
  SelectElement?: string;
  OptionsContainer?: string;
  active?: string;
  Option?: string;
}

export type DropdownMenuOptionType = {
  label: ReactNode;
  onClick: VoidFunction;
};

export interface DropdownMenuProps {
  className?: string;
  dataHcName: string;
  disabled?: boolean;
  error?: string;
  title: string;
  name?: string;
  onBlur?: VoidFunction;
  options: DropdownMenuOptionType[];
  role?: string;
  theme?: DropdownMenuTheme;
}

export const DropdownMenuInner = (
  props: DropdownMenuProps,
  inputRef: React.ForwardedRef<HTMLInputElement>
) => {
  const {
    className,
    dataHcName,
    disabled,
    error,
    name,
    onBlur,
    options,
    title,
    role,
    theme,
  } = props;

  const [isActive, setIsActive] = useState(false);
  const [focusedOption, setFocusedOption] = useState<{
    idx: number;
  } | null>(null);
  const forwardedSelectedRef = useForwardedRef<HTMLInputElement>(inputRef);

  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const scrollFocusToOption = (
    optionIdx: number,
    shouldScroll: boolean,
    shouldFocus: boolean
  ) => {
    if (!optionRefs.current) return;
    const scrollToOption = optionRefs.current[optionIdx];
    if (!scrollToOption) return;

    scrollFocusEl(
      scrollToOption,
      optionsContainerRef.current,
      shouldScroll,
      shouldFocus
    );

    if (shouldFocus) {
      setFocusedOption({
        idx: optionIdx,
      });
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleArrowDown = () => {
    // if focused on last item in list, focus on input and scroll to the top of the list
    if (focusedOption?.idx === optionRefs.current.length - 1) {
      forwardedSelectedRef.current?.focus();
      setFocusedOption(null);
      scrollFocusToOption(0, true, false);
      return;
    }

    const nextFocusedOptionIdx =
      focusedOption !== null ? focusedOption.idx + 1 : 0;
    scrollFocusToOption(nextFocusedOptionIdx, nextFocusedOptionIdx === 0, true);
  };

  const handleArrowUp = () => {
    // if no option is focused, focus on and scroll to the last item in list
    if (focusedOption === null) {
      const nextFocusedOptionIdx = optionRefs.current.length - 1;
      scrollFocusToOption(nextFocusedOptionIdx, true, true);
      return;
    }

    // if focused on first item in list, focus goes back to input for accessibility
    if (focusedOption?.idx === 0) {
      forwardedSelectedRef.current?.focus();
      setFocusedOption(null);
      return;
    }

    const nextFocusedOptionIdx = focusedOption.idx - 1;
    scrollFocusToOption(nextFocusedOptionIdx, false, true);
  };

  const activate = (activeStatus: boolean) => {
    if (!focusedOption) {
      optionRefs.current[0]?.focus();
    }
    setIsActive(activeStatus);
  };

  const deActivate = () => {
    setFocusedOption(null);
    setIsActive(false);
  };

  useClickOutsideComponent(containerRef, deActivate);

  const handleSelect = (option: DropdownMenuOptionType) => {
    deActivate();
    option.onClick();
    if (onBlur) {
      onBlur();
    }
  };

  const keyboardNavigationListener = (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      handleArrowDown();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      handleArrowUp();
    } else if (e.key === 'Escape') {
      deActivate();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyboardNavigationListener);

    return () => {
      document.removeEventListener('keydown', keyboardNavigationListener);
    };
  });

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.DropdownMenuContainer,
        className,
        theme?.DropdownMenuContainer
      )}
    >
      <div
        className={classNames(
          {
            [styles.active]: isActive,
            [theme?.active || '']: isActive,
            [styles.dropdownMenuError]: error,
          },
          styles.DropdownMenu,
          theme?.DropdownMenu
        )}
        data-hc-name={`${dataHcName}-key-trigger`}
        ref={containerRef}
      >
        <div
          className={styles.SelectContainer}
          onClick={() => {
            if (!disabled) activate(!isActive);
          }}
        >
          <div
            className={classNames(
              {
                [styles.active]: isActive,
                [theme?.active || '']: isActive,
                [styles.disabled]: disabled,
                [styles.dropdownMenuError]: error,
              },
              styles.SelectElement,
              theme?.SelectElement
            )}
            data-hc-name={`${dataHcName}-selected`}
            ref={forwardedSelectedRef}
          >
            <span data-hc-name={`${dataHcName}-placeholder`}>{title}</span>
            <input
              data-hc-name={`${dataHcName}-input`}
              name={name}
              role={role}
              type="hidden"
            />
          </div>
          <div
            className={classNames(
              {
                [styles.disabled]: disabled,
              },
              styles.ChevronContainer
            )}
          >
            <DirectionalChevron
              dataHcName={`${dataHcName}-open-close`}
              direction={isActive ? 'up' : 'down'}
              size="sm"
            />
          </div>
        </div>
        {isActive && (
          <div
            className={classNames(
              styles.OptionsContainer,
              theme?.OptionsContainer
            )}
            data-hc-name={`${dataHcName}-options`}
            ref={optionsContainerRef}
          >
            {options?.map((option, idx) => {
              const focused = idx === focusedOption?.idx;

              return (
                <DropdownOption
                  dataHcName={`${dataHcName}-option`}
                  focused={focused}
                  handleSelect={() => handleSelect(option)}
                  key={`${idx}-${option.label}`}
                  label={option.label}
                  selected={false}
                  setRef={(el) => (optionRefs.current[idx] = el)}
                  theme={theme}
                />
              );
            })}
            {options.length === 0 && (
              <div
                className={styles.NoOptions}
                data-hc-name={`${dataHcName}-no-options`}
              >
                No Options
              </div>
            )}
          </div>
        )}
      </div>
      {error ? (
        <div className={styles.Error} data-hc-name={`${dataHcName}-error`}>
          {error}
        </div>
      ) : null}
    </div>
  );
};

export const DropdownMenu = React.forwardRef(DropdownMenuInner);
