import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import {
  Organization,
  OrganizationapiCreateOrgInviteRequest,
} from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

import { AuthzApi } from '../api';

import { QUERY_KEY_ORG_INVITES } from './useOrgInvites';

export type CreateInvitationPayload = OrganizationapiCreateOrgInviteRequest & {
  orgId: Organization['id'];
};

export const useCreateInvitation = (
  options: UseMutationOptions<
    void,
    AxiosError<{ status: string }>,
    CreateInvitationPayload
  >
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    void,
    AxiosError<{ status: string }>,
    CreateInvitationPayload
  >(
    async ({ orgId, ...invitation }: CreateInvitationPayload) => {
      await AuthzApi.createOrgInvite(orgId, invitation);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_KEY_ORG_INVITES, variables.orgId]);
        toastOpen({
          type: 'success',
          title: 'Successfully sent invitation',
        });
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        toastOpen({
          type: 'error',
          title:
            error.response?.status === 400
              ? capitalizeFirstLetter(error.response?.data.status)
              : 'Error sending invitation',
        });
        options?.onError?.(error, variables, context);
      },
    }
  );
};
