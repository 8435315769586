import React from 'react';
import classNames from 'classnames';

import { InfoTooltip, TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CompFieldConfig,
  CompFields,
  CompFieldsArgsProps,
  CompFieldsTableCellProps,
} from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { SimilarityLevel } from '../../../components/SimilarityLevel/SimilarityLevel';

import styles from './similarity.module.css';

const FIELD = CompFields.similarity;
type FieldConfig = CompFieldConfig<typeof FIELD>;
const label = 'Similarity';
const labelShort = 'Similarity';
const compPath: FieldConfig['compPath'] = '/similarity';
const getValue: FieldConfig['getValue'] = (comp) => comp?.similarity;
const formatValue: FieldConfig['formatValue'] = (comp) => {
  const similarity = getValue(comp);
  return `${formatMissing(similarity?.scoreAdjusted)}`;
};
// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Similarity';
const formatValueShort: FieldConfig['formatValueShort'] = formatValue;

const formatInline: FieldConfig['formatInline'] = (comp) => formatValue(comp);

// Component for displaying a field from a comp
const Display: FieldConfig['Display'] = ({
  comp,
  className,
}: CompFieldsArgsProps) => {
  const { similarity } = comp || {};
  return (
    <div className={classNames(styles.SimilarityCell, className)}>
      {similarity?.levelAdjusted && (
        <SimilarityLevel
          dataHcName={'similarity-level'}
          similarityLevel={similarity.levelAdjusted}
        />
      )}
      <div className={styles.Score}>
        {formatMissing(similarity?.scoreAdjusted)}
      </div>
    </div>
  );
};

const HeaderCell = (props: TableHeaderCellProps) => {
  return (
    <TableHeaderCell {...props}>
      <div className={styles.HeaderCell}>
        <span className={styles.HeaderCellLabel}>Similarity</span>
        <InfoTooltip
          size="small"
          dataHcName="similarity-info-tooltip"
          isLargeTooltip
          label={'Similarity'}
          description={
            <div className={styles.Info}>
              <label>Similarity</label>
              <div
                className={styles.InfoContent}
                data-hc-name="similarity-info-tooltip-content"
              >
                HouseCanary&apos;s Similarity Score uses Data Points including
                bed, bath, GLA, and other property details along with distance
                and time to create a regression model for the subject property
                and nearby comparable properties. The location based model
                provides a numerical score for each possible comparable, ranked
                from 1-100 in similarity.
              </div>
            </div>
          }
        />
      </div>
    </TableHeaderCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'center',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({ comp, ...tableCellProps }: CompFieldsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display comp={comp} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'center',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const SIMILARITY_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  compPath,
  getValue,
  formatValue,
  labelMicro,
  formatValueShort,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
};
