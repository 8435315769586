import React from 'react';
import { AxisTickProps } from '@nivo/axes/dist/types/types';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import classNames from 'classnames';

import { NoContent } from '@hcs/design-system';
import { HomeCircleIcon } from '@hcs/design-system';
import { NoDataIcon } from '@hcs/design-system';
import { SimilarPropertiesChartSchema } from '@hcs/types';
import { ChartType } from '@hcs/types';
import { formatMoney, formatNumber } from '@hcs/utils';

import { BarComponent } from './BarComponent';

import styles from './SimilarPropertiesChart.module.css';

const SHOW_LABEL = false;
const ANIMATE_CHART = false;
const INTERACTIVE_CHART = true;
const MARGIN = {
  top: 60,
  right: 50,
  bottom: 50,
  left: 50,
};

export interface AxisProps {
  legend?: string;
  renderTick?: (props: AxisTickProps<number>) => JSX.Element;
}
interface Props {
  chart?: SimilarPropertiesChartSchema['chart'];
  chartType: ChartType;
  subjectValue: number;
  appraisalValue?: number | null;
  className?: string;
  axisLeft?: AxisProps;
  axisBottom?: AxisProps;
}

// We want to disable the tooltip but still want isInteractive to be true
// This is so that our onMouseEnter and onMouseLeave events are handled
const BarTooltip = () => {
  return null;
};

const dataHcName = 'valuation-chart-display';

export const Tick = ({ value, x, y }: AxisTickProps<number>) => {
  return (
    <text
      x={x - 20}
      y={y}
      data-hc-name={`${dataHcName}-one-text`}
      className={styles.AxisLabelY}
    >
      {formatNumber(value)}
    </text>
  );
};

export const SimilarPropertiesChartDisplay = ({
  chart,
  className,
  subjectValue,
  appraisalValue,
  axisBottom,
  axisLeft,
}: Props) => {
  const firstBucket = chart?.buckets[0];
  const lastBucket = chart?.buckets[chart?.buckets.length - 1];
  const xMin = firstBucket
    ? Math.min(firstBucket.start, subjectValue)
    : undefined;
  const xMax = lastBucket ? Math.max(lastBucket.end, subjectValue) : undefined;
  if (firstBucket && subjectValue < firstBucket.start) {
    chart.buckets.unshift({
      start: subjectValue,
      end: subjectValue,
      count: 0,
    });
  } else if (lastBucket && subjectValue > lastBucket.end) {
    chart.buckets.push({
      start: subjectValue,
      end: subjectValue,
      count: 0,
    });
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SimilarPropertiesChart, className)}
    >
      {chart?.buckets.length ? (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 2,
              pointerEvents: 'none',
            }}
          >
            {/* Responsive Line series is needed for the subject marker and salePriceMean line */}
            <ResponsiveLine
              margin={{ ...MARGIN, top: 0 }}
              axisLeft={null}
              axisBottom={null}
              xScale={{
                type: 'linear',
                min: xMin,
                max: xMax,
              }}
              data={[
                {
                  id: 'domain',
                  data: [
                    { x: xMin, y: firstBucket?.count },
                    { x: xMax, y: lastBucket?.count },
                  ],
                },
              ]}
              layers={[
                (props) => {
                  const isAppraisalLine = !!appraisalValue;
                  const lineValue = isAppraisalLine
                    ? appraisalValue
                    : chart?.stats?.similarPropertiesStats?.salePriceMean;
                  if (lineValue) {
                    const { xScale, yScale } = props;
                    const x = xScale(lineValue);
                    const xSubject = xScale(
                      chart.stats.subjectValueStats.subjectValue
                    );
                    const y = yScale(0);
                    if (typeof x === 'number' && typeof y === 'number') {
                      const tickMiddleBottomYOffset = axisLeft?.legend
                        ? 30
                        : 35;

                      return (
                        <>
                          <line
                            data-hc-name={dataHcName}
                            x1={x}
                            y1={0}
                            x2={x}
                            y2={y + 20}
                            stroke="#4A4A4A"
                            strokeWidth={1}
                            strokeDasharray={3}
                          />
                          <g data-hc-name={`${dataHcName}-avg-label`}>
                            <rect
                              x={x - 80}
                              y={0}
                              rx="8"
                              ry="8"
                              width={160}
                              height={38}
                              fill="black"
                            />

                            <text
                              data-hc-name={`${dataHcName}-one-text`}
                              x={x}
                              y={23}
                              fill="white"
                              fontWeight={800}
                              className={styles.Heading}
                              textAnchor="middle"
                            >
                              {isAppraisalLine
                                ? 'Appraisal Value'
                                : 'Average Sale Price'}
                            </text>
                          </g>
                          <text
                            data-hc-name={`${dataHcName}-avg-price`}
                            x={x}
                            y={y + tickMiddleBottomYOffset}
                            fill="#4a4a4a"
                            fontWeight={800}
                            className={styles.Heading}
                            textAnchor="middle"
                          >
                            {formatMoney(lineValue)}
                          </text>
                          {typeof xSubject === 'number' && (
                            <HomeCircleIcon
                              x={xSubject - 15}
                              className={styles.AppraisalIcon}
                              y={y - 15}
                              height={30}
                              width={30}
                              dataHcName={`${dataHcName}-subject`}
                            />
                          )}
                        </>
                      );
                    }
                  }
                  return null;
                },
              ]}
              enableGridX={false}
              enableGridY={false}
            />
          </div>
          <ResponsiveBar
            // Casting like this because of library types
            data={(chart?.buckets || []) as unknown as BarDatum[]}
            indexBy="start"
            animate={ANIMATE_CHART}
            isInteractive={INTERACTIVE_CHART}
            tooltip={BarTooltip}
            keys={['count']}
            margin={MARGIN}
            barComponent={BarComponent}
            enableLabel={SHOW_LABEL}
            borderRadius={8}
            axisLeft={{
              tickValues: 5,
              tickSize: 0,
              renderTick: (props: AxisTickProps<number>) =>
                axisLeft?.renderTick ? (
                  axisLeft.renderTick(props)
                ) : (
                  <Tick {...props} />
                ),
              tickPadding: 3,
              legend: axisLeft?.legend,
              legendPosition: axisLeft?.legend ? 'middle' : undefined,
              legendOffset: axisLeft?.legend ? -40 : undefined,
            }}
            colors={(d) => {
              const start = d.data.start;
              const end = d.data.end;
              if (
                !appraisalValue ||
                (typeof start === 'number' &&
                  typeof end === 'number' &&
                  subjectValue >= start &&
                  subjectValue <= end)
              ) {
                return '#6BA0FF';
              }
              return '#EDEDED';
            }}
            axisBottom={{
              tickSize: 0,
              legend: axisBottom?.legend,
              legendOffset: axisBottom?.legend ? 45 : undefined,
              legendPosition: axisBottom?.legend ? 'middle' : undefined,
              renderTick: (p: AxisTickProps<number>) => {
                const { value } = p;
                const firstPoint = chart?.buckets[0];
                const lastPoint = chart?.buckets[chart?.buckets.length - 1];
                const tickBottomYOffset = axisBottom?.legend ? 30 : 25;
                if (value === firstPoint?.start) {
                  return (
                    <text
                      data-hc-name={`${dataHcName}-axis-label`}
                      className={styles.AxisLabel}
                      x={p.x}
                      y={p.y + tickBottomYOffset}
                      textAnchor="end"
                    >
                      {formatMoney(value)}
                    </text>
                  );
                } else if (value === lastPoint?.start) {
                  return (
                    <text
                      data-hc-name={`${dataHcName}-axis-label`}
                      className={styles.AxisLabel}
                      x={p.x}
                      y={p.y + tickBottomYOffset}
                      textAnchor="start"
                    >
                      {formatMoney(lastPoint?.end)}
                    </text>
                  );
                } else {
                  return <span />;
                }
              },
            }}
            gridYValues={5}
            enableGridX={false}
            enableGridY={true}
            layers={['grid', 'bars', 'markers', 'legends', 'axes']}
          />
        </>
      ) : (
        <NoContent
          dataHcName={`${dataHcName}-null`}
          Icon={NoDataIcon}
          style={{ width: '50%', margin: '70px auto' }}
        >
          Not Enough Highly Similar Comparables
        </NoContent>
      )}
    </div>
  );
};
