import { gql } from 'graphql-request';

// ListingStats Fragments
export const FRAGMENT_LISTING_STATS = gql`
  fragment ListingStats on HCListingStats {
    daysOnMarketMedian
    daysOnMarketMedianMovingAvg
    daysOnMarketNotContractMedian
    daysOnMarketNotContractMedianMovingAvg
    daysToCloseMedian
    daysToCloseMedianMovingAvg
    daysToContractMedian
    daysToContractMedianMovingAvg
    daysToFirstPriceDropMedian
    daysToFirstPriceDropMedianMovingAvg
    eventDate
    listingsClosedCount
    listingsClosedCountMovingAvg
    listingsNewCount
    listingsNewCountMovingAvg
    listingsOnMarketCount
    listingsOnMarketCountMovingAvg
    listingsPriceChangeCount
    listingsPriceChangeCountMovingAvg
    listingsPriceDropCount
    listingsPriceDropCountMovingAvg
    listingsPriceDropPercent
    listingsPriceDropPercentMovingAvg
    listingsRemovedCount
    listingsRemovedCountMovingAvg
    listingsRemovedPercent
    listingsRemovedPercentMovingAvg
    listingsUnderContractCount
    listingsUnderContractCountMovingAvg
    listingsUnderContractPercent
    listingsUnderContractPercentMovingAvg
    monthsOfSupplyMedian
    monthsOfSupplyMedianMovingAvg
    priceClosedMedian
    priceClosedMedianMovingAvg
    priceNewListMedian
    priceNewListMedianMovingAvg
    priceOnMarketMedian
    priceOnMarketMedianMovingAvg
    pricePerSqftClosedMedian
    pricePerSqftClosedMedianMovingAvg
    pricePerSqftNewListMedian
    pricePerSqftNewListMedianMovingAvg
    pricePerSqftOnMarketMedian
    pricePerSqftOnMarketMedianMovingAvg
    saleToListPriceMedian
    saleToListPriceMedianMovingAvg
    saleToListPriceOriginalMedian
    saleToListPriceOriginalMedianMovingAvg
  }
`;
