import { useMemo } from 'react';

import { useGeoInterestedUiPreferences } from '@hcs/huell';
import { CerberusStatsFields } from '@hcs/types';
import { CerberusStatsFilterType, GeoInterestedType } from '@hcs/types';
import { SORT_ORDER } from '@hcs/types';

import { useCerberusStatsAllMsaZips } from '../hooks/useCerberusStatsAllMsaZips';
import { useCerberusStatsMsa } from '../hooks/useCerberusStatsMsa';
import { filterList, sortList } from '../utils/cerberusStatsTable.utils';

import { useCerberusStatsFilters } from './useCerberusStatsFilters';

const DEFAULT_SORT = {
  field: CerberusStatsFields.listingsOnMarketCount,
  order: SORT_ORDER.Desc,
};

export const useCerberusStatsZipsTable = (msaId: string) => {
  const { data: zipsList, isInitialLoading: zipsListIsLoading } =
    useCerberusStatsAllMsaZips({ msaId });
  const { data: cerberusStatsMsa, isInitialLoading: msaIsLoading } =
    useCerberusStatsMsa({ msaId });

  const {
    state: {
      filterSet,
      filterSetQueryIsLoading,
      filterSetMutationIsLoading,
      search,
    },
    actions: {
      onToggleInterestedOnly,
      onSort,
      onSearch,
      onUpdateFieldFilters,
      onClearFieldFilter,
      onUpdateFilterableFields,
    },
  } = useCerberusStatsFilters(CerberusStatsFilterType.zip);
  const {
    state: { geoInterestedMap: interestedInZipMap },
    actions: { onToggleInterested: onToggleZipInterested },
  } = useGeoInterestedUiPreferences(GeoInterestedType.zips);
  const {
    state: { geoInterestedMap: interestedInMsaMap },
    actions: { onToggleInterested: onToggleMsaInterested },
  } = useGeoInterestedUiPreferences(GeoInterestedType.msas);

  const { fieldFilters } = filterSet || {};

  const interestedOnly = filterSet?.interestedOnly || null;
  const sortField = filterSet?.sortField || DEFAULT_SORT.field;
  const sortOrder = filterSet?.sortOrder || DEFAULT_SORT.order;

  const filteredList = useMemo(() => {
    return filterList({
      list: zipsList,
      fieldFilters,
      filterSetIsLoading: filterSetQueryIsLoading,
      search,
      interestedOnly,
      interestedInMap: interestedInZipMap,
    });
  }, [
    zipsList,
    fieldFilters,
    filterSetQueryIsLoading,
    search,
    interestedOnly,
    interestedInZipMap,
  ]);

  const filteredSortedList = useMemo(() => {
    return sortList({
      list: filteredList,
      sortField,
      sortOrder,
      filterSetIsLoading: filterSetQueryIsLoading,
    });
  }, [filteredList, sortField, sortOrder, filterSetQueryIsLoading]);

  return {
    state: {
      isLoading: filterSetQueryIsLoading || zipsListIsLoading || msaIsLoading,
      filteredSortedList,
      totalCount: zipsList?.length,
      interestedInZipMap,
      interestedInMsaMap,
      cerberusStatsMsa,
      search,
      sortField,
      sortOrder,
      fieldFilters: filterSet?.fieldFilters,
      interestedOnly: filterSet?.interestedOnly,
      filterSetMutationIsLoading,
    },
    actions: {
      onToggleInterestedOnly,
      onSort,
      onSearch,
      onUpdateFieldFilters,
      onClearFieldFilter,
      onToggleZipInterested,
      onToggleMsaInterested,
      onUpdateFilterableFields,
    },
  };
};
