import React from 'react';
import classNames from 'classnames';

import { InfoTooltip } from '@hcs/design-system';

import styles from './DialogInputLayout.module.css';

interface DialogInputLayoutTheme {
  InputLayout?: string;
  InputLabelContainer?: string;
  InputLabel?: string;
  InputContainer?: string;
}
export interface DialogInputLayoutProps {
  dataHcName: string;
  className?: string;
  label: string | React.ReactNode;
  labelHelper?: string | React.ReactNode;
  required: boolean;
  children: React.ReactNode;
  readonly?: boolean;
  fullWidth?: boolean;
  theme?: DialogInputLayoutTheme;
}

export const DialogInputLayout = (props: DialogInputLayoutProps) => {
  const {
    dataHcName,
    className,
    label,
    labelHelper,
    required,
    children,
    readonly = false,
    fullWidth,
    theme,
  } = props;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.InputLayout, theme?.InputLayout, className, {
        [styles['full-width']]: fullWidth,
      })}
    >
      <div
        className={classNames(
          styles.InputLabelContainer,
          {
            [styles['full-width']]: fullWidth,
          },
          theme?.InputLabelContainer
        )}
      >
        <div
          data-hc-name={`${dataHcName}-label`}
          className={classNames(styles.InputLabel, theme?.InputLabel)}
        >
          {label}
          {labelHelper && (
            <InfoTooltip
              dataHcName={`${dataHcName}-label-helper-popover`}
              theme={{
                Button: styles.LabelHelperTrigger,
              }}
              label={labelHelper}
            />
          )}
        </div>
        {required && (
          <div
            className={styles.InputRequired}
            data-hc-name={`${dataHcName}-required`}
          >
            Required
          </div>
        )}
      </div>
      <div
        data-hc-name={
          readonly
            ? `${dataHcName}-input-container-readonly`
            : `${dataHcName}-input-container`
        }
        className={classNames(
          {
            [styles.ReadOnlyInput]: readonly,
            [styles['full-width']]: fullWidth,
          },
          styles.InputContainer,
          theme?.InputContainer
        )}
      >
        {children}
      </div>
    </div>
  );
};
