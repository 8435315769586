import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { ReportId, SubjectValuePaths, ValuationMethod } from '@hcs/types';

import { ValueSelectorButton } from '../../components-deprecated';
import { ValueSelectorItem } from '../../components-deprecated';
import {
  VALUATION_METHOD_DESCRIPTIONS,
  VALUATION_METHOD_DESCRIPTIONS_RENTAL,
  VALUATION_METHOD_LABELS,
  VALUATION_METHOD_LABELS_RENTAL,
} from '../../constants/valuationMethods.constants';
import { useSubjectValueDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import styles from './ReportValueSelector.module.css';

interface Props {
  reportId: ReportId;
  showRentalValue: boolean;
  onClose: VoidFunction;
}
const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const dataHcName = 'avm-selector-item';
export const AvmSelectorItem = ({
  reportId,
  showRentalValue,
  onClose,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: subjectValueDocument } = useSubjectValueDocument(reportId, {
    showRentalValue,
  });

  if (!subjectValueDocument || !reportPermissions) {
    return (
      <LoadingSpinner
        dataHcName={`${dataHcName}-skeleton`}
        containerHeight="380px"
      />
    );
  }

  const handleSelect = () => {
    documentPatchMutation.mutate({
      reportId,
      document: subjectValueDocument,
      operations: [
        {
          op: 'replace',
          path: PATH_SELECTED_VALUE,
          value: ValuationMethod.Avm,
        },
      ],
    });

    onClose();
  };

  return (
    <ValueSelectorItem
      title={
        showRentalValue
          ? VALUATION_METHOD_LABELS_RENTAL.avm
          : VALUATION_METHOD_LABELS.avm
      }
      dataHcName={dataHcName}
      value={subjectValueDocument.data[ValuationMethod.Avm]?.value.value}
      className={styles.AVMSelectionItem}
    >
      {showRentalValue
        ? VALUATION_METHOD_DESCRIPTIONS_RENTAL.avm
        : VALUATION_METHOD_DESCRIPTIONS.avm}
      {reportPermissions.isEditable && (
        <ValueSelectorButton
          dataHcName={`${dataHcName}-value-selector-button`}
          onClick={handleSelect}
        >
          Use For Report
        </ValueSelectorButton>
      )}
    </ValueSelectorItem>
  );
};
