import { useCallback, useEffect } from 'react';

import { BaseEventTypes } from '@hcs/types';

import { buildEngagementEventFromElement } from '../utils';

import { useGlobalEngagementTrackingCallbackDeps } from './useGlobalEngagementTrackingCallbackDeps';
import { useTrackEngagementEvent } from './useTrackEngagementEvent';

// Tracks click events if a data-hc-name is found in the dom ancestors
export const useTrackClickEvents = () => {
  // Need dependent data here so the listener gets rebound with the updated data
  const callbackDeps = useGlobalEngagementTrackingCallbackDeps();

  const trackEngagementEventMutation = useTrackEngagementEvent();

  const clickHandler = useCallback((e: Event) => {
    const element = e.target as Element;
    const engagementEvent = buildEngagementEventFromElement(
      element,
      BaseEventTypes.Click,
      {
        eventType: BaseEventTypes.Click,
        findAncestor: true,
      }
    );
    if (engagementEvent) {
      trackEngagementEventMutation.mutate(engagementEvent);
    }
  }, callbackDeps);

  useEffect(() => {
    window.addEventListener('click', clickHandler, true);
    return () => {
      window.removeEventListener('click', clickHandler, true);
    };
  }, [clickHandler]);
};
