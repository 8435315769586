import { Personalization } from '@hcs/types';

export const hasDefaultCobrandingData = (data: Personalization) => {
  return (
    (data.photoUrl && data.includePhoto) ||
    ((data.firstName || data.lastName) && data.includeName) ||
    (data.companyName && data.includeCompanyName) ||
    (data.includeCompanyLogo && data.companyLogoUrl)
  );
};

export const hasCobrandingData = (data: Personalization) => {
  return Object.keys(data).filter((value) => {
    if (value?.slice(0, 7) === 'include') {
      return data[value as keyof Personalization];
    } else {
      return false;
    }
  }).length >= 1
    ? true
    : false;
};
