import { AddressSearchHit } from '../address-search/AddressSearch.types';

import { DeliverySpeed, GroupKey, OrderTypeDescriptor } from './Order.types';

export enum STEP {
  SELECT_PRODUCT = 1,
  INSPECTION_METHOD = 2,
  ADD_ADDRESS_METHOD = 3,
  UPLOAD_OR_ENTER_ADDRESS = 4,
  ORDER_DETAILS = 5,
}

type SupportingDocument = {
  id: number;
  file: string;
  filePath: string;
  fileName: string;
};

interface ItemSupportingDocumentFields {
  supportingDocuments?: SupportingDocument[];
}

export interface AddAddressFormFieldsMeta {
  customerItemId?: string;
  propertyType?: string;
  specialCommentsInspection?: string;
  specialCommentsAmc?: string;
  inspectionContactName?: string;
  inspectionContactPhone?: string;
  inspectionContactPhoneIsCell?: boolean;
  inspectionContactEmail?: string;
  secondaryInspectionContactName?: string;
  secondaryInspectionContactPhone?: string;
  secondaryInspectionContactPhoneIsCell?: boolean;
  secondaryInspectionContactEmail?: string;
  county?: string;
  loanValue?: string;

  address: Partial<AddressSearchHit> | null;

  itemSupportingDocumentFields: ItemSupportingDocumentFields | null;
}

export interface AddAddressFormFields {
  addressMeta: AddAddressFormFieldsMeta[];
}

export interface AddressFormFields {
  customerItemId?: string;
  propertyType?: string;
  specialCommentsInspection?: string;
  specialCommentsAmc?: string;
  inspectionContactName?: string;
  inspectionContactPhone?: string;
  inspectionContactPhoneIsCell?: boolean;
  inspectionContactEmail?: string;
  secondaryInspectionContactName?: string;
  secondaryInspectionContactPhone?: string;
  secondaryInspectionContactPhoneIsCell?: boolean;
  secondaryInspectionContactEmail?: string;
  county?: string;
  loanValue?: string;

  addressId: string;
  addressFullLine: string;

  itemSupportingDocumentFields: ItemSupportingDocumentFields | null;
}

export interface OrderDetailsFormFields {
  name?: string;
  clientName?: string;
  clientAddress?: string;
  clientCity?: string;
  clientState?: string;
  clientZipcode?: string;
  lenderName?: string;
  lenderAddress?: string;
  lenderCity?: string;
  lenderState?: string;
  lenderZipcode?: string;
  intendedUse?: string;
  customerOrderId?: string;
  label?: string;
  specialCommentsInspection?: string;
  specialCommentsAmc?: string;
  intendedUserSame?: boolean;
}

export type ProductType =
  | 'AGILE_EVALUATION'
  | 'INSPECTION'
  | 'VALUE_REPORT'
  | 'BROKER_PRICE_OPINION';

export type ProductTypeDescriptor = {
  groupKey: GroupKey;
  productType: ProductType;
  orderTypes: OrderTypeDescriptor[];
};

export enum INSPECTION_TYPE {
  exterior = 'exterior',
  interior_exterior = 'interior_exterior',
  desktop = 'desktop',
}

export interface CreateNewOrderState {
  step: STEP;
  deliverySpeed?: DeliverySpeed;
  inspectionType?: INSPECTION_TYPE | null;
  includesAsRepairedValue?: boolean;

  productType?: ProductType;
  orderTypes: OrderTypeDescriptor[];
  selectedOrderType?: OrderTypeDescriptor;

  itemsSource?: 'entry' | 'csv';
  orderFile?: File;

  addressFormFields?: AddressFormFields[];

  orderDetailsFormFields?: OrderDetailsFormFields;
}

export enum FIELD_NAMES {
  CUSTOMER_ORDER_ID = 'customerOrderId',
  ORDER_NAME = 'name',
  CLIENT_NAME = 'clientName',
  CLIENT_ADDRESS = 'clientAddress',
  CLIENT_CITY = 'clientCity',
  CLIENT_STATE = 'clientState',
  CLIENT_ZIPCODE = 'clientZipcode',
  LABEL = 'label',
  INTENDED_USE = 'intendedUse',
  LENDER_NAME = 'lenderName',
  LENDER_ADDRESS = 'lenderAddress',
  LENDER_CITY = 'lenderCity',
  LENDER_ZIPCODE = 'lenderZipcode',
  LENDER_STATE = 'lenderState',
  SPECIAL_COMMENTS_INSPECTION = 'specialCommentsInspection',
  SPECIAL_COMMENTS_AMC = 'specialCommentsAmc',
}

export type FieldMeta = {
  [fieldName in FIELD_NAMES]: {
    shown: boolean;
    required: boolean;
  };
};

export enum ITEM_FIELD_NAMES {
  CUSTOMER_ITEM_ID = 'customerItemId',
  ADDRESS = 'address',
  UNIT = 'unit',
  CITY = 'city',
  STATE = 'state',
  ZIPCODE = 'zipcode',
  COUNTY = 'county',
  PROPERTY_TYPE = 'propertyType',
  SPECIAL_COMMENTS_INSPECTION = 'specialCommentsInspection',
  SPECIAL_COMMENTS_AMC = 'specialCommentsAmc',
  INSPECTION_CONTACT_NAME = 'inspectionContactName',
  INSPECTION_CONTACT_PHONE = 'inspectionContactPhone',
  INSPECTION_CONTACT_PHONE_IS_CELL = 'inspectionContactPhoneIsCell',
  INSPECTION_CONTACT_EMAIL = 'inspectionContactEmail',
  SECONDARY_INSPECTION_CONTACT_NAME = 'secondaryInspectionContactName',
  SECONDARY_INSPECTION_CONTACT_PHONE = 'secondaryInspectionContactPhone',
  SECONDARY_INSPECTION_CONTACT_PHONE_IS_CELL = 'secondaryInspectionContactPhoneIsCell',
  SECONDARY_INSPECTION_CONTACT_EMAIL = 'secondaryInspectionContactEmail',
  LOAN_VALUE = 'loanValue',
  SUPPORTING_DOCUMENTS = 'supportingDocuments',
}

export type ItemFieldMeta = {
  [fieldName in ITEM_FIELD_NAMES]: {
    shown: boolean;
    required: boolean;
  };
};

export type AddOrExtendOrderItemFields = {
  itemsSource: 'entry' | 'csv';
  // individual addresses
  addressFormFields?: AddressFormFields[];
  // bulk addresses
  orderFile?: File | null;
  orderType: string;
  deliverySpeed?: string | null;
};

export type AddOrderFields = OrderDetailsFormFields &
  AddOrExtendOrderItemFields;

export type AddOrExtendFileFields = {
  itemsSource: 'csv';
  orderFile: File;
};

export type OrderViaCsv = OrderDetailsFormFields & AddOrExtendFileFields;

export type OrderViaCsvError = {
  orderFile: string | string[];
};

export type ParseAddressPayload = {
  addressId: string;
  addressString: string;
};

export type AddressParts = {
  address: string;
  unit?: string | null;
  unitDesignator?: string;
  city: string;
  state: string;
  zipcode: string;
};

export type ParsedAddressResponse = AddressParts &
  Omit<AddAddressFormFieldsMeta, 'address'> & {
    addressUnparsed?: string;
  };

export type AddOrExtendJSONFields = {
  itemsSource?: 'entry';
  items?: AddressParts[];
};

export type OrderViaAddressEntry = OrderDetailsFormFields &
  AddOrExtendJSONFields;

export type OrderViaAddressEntryError = {
  items: {
    [fieldName: string]: string;
  }[];
};

export type OrderExportStatus =
  | 'queued'
  | 'archive_created'
  | 'complete'
  | 'archiving_failed'
  | 'upload_failed'
  | 'sftp_upload'
  | 'sftp_upload_failed'
  | 'sftp_upload_complete'
  | 'error';

export type ExportRequest = {
  order: number;
  checkStatusUrl: string;
  id: number;
  status: OrderExportStatus;
  requesterId: string;
  updatedAt?: string;
  excludeJson: boolean;
  completedAt?: string;
  percentComplete: number;
  exportedData?: string;
  sftpUploadCompletedAt?: string | null;
};
