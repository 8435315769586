import { ReactNode } from 'react';

import { ExperienceFlagIds } from '@hcs/types';

import { useExperienceFlag } from '../../hooks/useExperienceFlag';

interface Props {
  experienceFlagId: ExperienceFlagIds;
  next?: ReactNode;
  now?: ReactNode;
}
export const ExperienceFlag = ({ experienceFlagId, next, now }: Props) => {
  return useExperienceFlag(experienceFlagId) ? next : now;
};
