import { CompTypes } from '@hcs/types';
import {
  CompIdentifier,
  CompsFarmTransactionHistoryDocument,
  DocumentRoles,
  RentalCompsFarmTransactionHistoryDocument,
  ReportId,
} from '@hcs/types';

import { getCompTransientDocumentId } from '../utils';

import { useDocumentById } from './useDocumentById';
import { useComp } from '.';

export const useCompTransactionHistoryDocument = (
  reportId: ReportId,
  compIdentifier: CompIdentifier
) => {
  const compQuery = useComp(reportId, compIdentifier);
  const documentId = getCompTransientDocumentId(
    compIdentifier.type === 'appraisalComp'
      ? DocumentRoles.AppraisalCompTransactionHistory
      : compIdentifier.compType === CompTypes.Rental
      ? DocumentRoles.RentalCompsFarmTransactionHistory
      : DocumentRoles.CompsFarmTransactionHistory,
    compQuery.data?.compSchema.compID
  );
  return useDocumentById<
    | CompsFarmTransactionHistoryDocument
    | RentalCompsFarmTransactionHistoryDocument
  >(reportId, documentId);
};
