import React from 'react';
import classNames from 'classnames';

import { Skeleton } from '../../../global/loading-errors-null/Skeleton';

import styles from './Avatar.module.css';

type AvatarSize = 'lg' | 'md' | 'sm' | 'xs';
export interface AvatarProps {
  dataHcName: string;
  className?: string;
  text: string;
  size?: AvatarSize;
  url?: string;
  isLoading?: boolean;
}
export const Avatar = ({
  dataHcName,
  className,
  isLoading,
  text,
  url,
  size = 'md',
}: AvatarProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Avatar, styles[size], className)}
      style={url ? { backgroundImage: `url(${url})` } : undefined}
    >
      {isLoading && <Skeleton dataHcName={`${dataHcName}-skeleton`} />}
      {url ? null : <span data-hc-name={`${dataHcName}-text`}>{text}</span>}
    </div>
  );
};
