import React from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { TextButton } from '@hcs/design-system';
import { FlexScrollCard } from '@hcs/design-system';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { LinkComponentType } from '@hcs/routing';
import { TableCellProps } from '@hcs/types';
import { CerberusStatsFields, CerberusStatsType } from '@hcs/types';
import { logWarning } from '@hcs/utils';

import { CerberusStatsFilters } from '../../components/CerberusStatsFilters';
import { InterestedInTableCheckbox } from '../../components/InterestedInTableCheckbox';
import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';
import { useCerberusStatsFields } from '../../hooks/useCerberusStatsFields';
import { useCerberusStatsMsasTable } from '../../hooks/useCerberusStatsMsasTable';
import { MarketStateColumnsMsaForUserForUserLauncher } from '../';

import styles from './CerberusStatsTables.module.css';

const displayNameConfig =
  CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.displayName];

const ROW_HEIGHT = 60;
const STAT_CELL_WIDTH = 150;
const dataHcName = 'msa-filter-table';
interface CerberusStatsMsasTablerops {
  className?: string;
  MsaLink: LinkComponentType<{ msaId: string }>;
  ZipSearchLink: LinkComponentType<{ msaId: string }>;
}

const NAME_WIDTH_LOOKUP: Record<string, number> = {
  icon: 70,
  name: 320,
  button: 100,
};

export const TableCellWrapper = ({
  children,
  ...tableCellProps
}: TableCellProps) => {
  return <TableCell {...tableCellProps}>{children}</TableCell>;
};

const defaultProps: Partial<TableCellProps> = {
  sticky: true,
  renderStickyShadow: true,
  isTableCell: true,
};

TableCellWrapper.defaultProps = defaultProps;

export const CerberusStatsMsasTable = ({
  className,
  MsaLink,
  ZipSearchLink,
}: CerberusStatsMsasTablerops) => {
  const {
    state: {
      isLoading,
      filteredSortedList,
      filterSetMutationIsLoading,
      totalCount,
      search,
      sortField,
      sortOrder,
      fieldFilters,
      interestedInMsaMap,
      interestedOnly,
    },
    actions: {
      onSort,
      onToggleInterestedOnly,
      onToggleMsaInterested,
      onSearch,
      onUpdateFieldFilters,
      onUpdateFilterableFields,
      onClearFieldFilter,
    },
  } = useCerberusStatsMsasTable();
  const { data: customFieldsData } = useCerberusStatsFields('columnsMsa');

  return (
    <FlexScrollCard
      className={classNames(styles.CardContainer, styles.viewHeight, className)}
      dataHcName={dataHcName}
      header={{
        height: 110,
        content: (
          <div className={styles.FlexScrollCardHeader}>
            <CerberusStatsFilters
              dataHcName={`cerberus-stats-msa-filters`}
              searchPlaceholder="MSA Search"
              search={search}
              fieldFilters={fieldFilters}
              onSearch={onSearch}
              onClearFieldFilter={onClearFieldFilter}
              saveIsLoading={filterSetMutationIsLoading}
              filterSetQueryIsLoading={isLoading}
              onUpdateFieldFilters={onUpdateFieldFilters}
              marketLevel="msa"
            />
            <div className={styles.Count}>
              <span data-hc-name={`${dataHcName}-title`}>MSAs</span>{' '}
              <span data-hc-name={`${dataHcName}-count`}>
                {filteredSortedList.length} of {totalCount}
              </span>
            </div>
          </div>
        ),
      }}
      noPadding
    >
      <Table
        skeletonConfig={{
          isLoading: isLoading,
          colCount: 9,
          rows: 10,
        }}
        dataHcName={`${dataHcName}-table`}
        belowRows={
          !isLoading &&
          filteredSortedList.length === 0 && (
            <div
              className={styles.NoResultsFound}
              data-hc-name={`${dataHcName}-no-results`}
            >
              No results found
            </div>
          )
        }
      >
        <TableHeader
          sticky
          height={ROW_HEIGHT}
          dataHcName={`${dataHcName}-table-header`}
        >
          <TableHeaderCell
            dataHcName={`${dataHcName}-interested-header-cell`}
            width={NAME_WIDTH_LOOKUP['icon']}
            sticky
          >
            <InterestedInTableCheckbox
              dataHcName={`${dataHcName}-interested-icon-header`}
              checked={!!interestedOnly}
              onChange={() => onToggleInterestedOnly()}
              uncheckedMessage="Only show interested"
              checkedMessage="Show all"
            />
          </TableHeaderCell>
          <displayNameConfig.HeaderCell
            cerberusStatsType={CerberusStatsType.Msa}
            className={styles.TableDisplayNameHeaderCell}
            dataHcName={`${dataHcName}-name-header-cell`}
            onClick={() => onSort(CerberusStatsFields.displayName)}
            sortable
            sort={
              sortField === CerberusStatsFields.displayName && sortOrder
                ? sortOrder
                : undefined
            }
            width={NAME_WIDTH_LOOKUP['name']}
            sticky
            afterSortContent={
              <MarketStateColumnsMsaForUserForUserLauncher
                onUpdate={(dataPriorityData) => {
                  onUpdateFilterableFields(dataPriorityData.order);
                }}
                showTooltip={false}
                trigger={
                  <TextButton
                    dataHcName={`${dataHcName}-data-priority`}
                    noPadding
                  >
                    Choose Fields
                  </TextButton>
                }
              />
            }
          />
          <TableHeaderCell
            dataHcName={`${dataHcName}-view-button-header-cell`}
            align="right"
            width={NAME_WIDTH_LOOKUP['button']}
            sticky
          />
          <TableHeaderCell
            dataHcName={`${dataHcName}-zip-search-link-header-cell`}
            wordWrap
            width={115}
          >
            Zip Codes Within MSA
          </TableHeaderCell>
          {customFieldsData?.order.map((field) => {
            const config = CERBERUS_STATS_FIELD_CONFIGS[field];
            if (!config) {
              logWarning(
                `CerberusStatsMsasTable: config not found for field ${field}`
              );
              return null;
            }
            return (
              <config.HeaderCell
                key={field}
                dataHcName={`${dataHcName}-${field}-header-cell`}
                sortable
                sort={sortField === field && sortOrder ? sortOrder : undefined}
                width={STAT_CELL_WIDTH}
                wordWrap
                onClick={() => onSort(field)}
                isTableHeaderCell
              />
            );
          })}
        </TableHeader>
        {filteredSortedList &&
          filteredSortedList.map((listItem) => {
            return (
              <TableRow
                style={{ background: 'none' }}
                key={listItem.id}
                height={ROW_HEIGHT}
              >
                <TableCell
                  dataHcName={`${dataHcName}-msa-interested-cell`}
                  width={NAME_WIDTH_LOOKUP['icon']}
                  sticky
                >
                  <InterestedInTableCheckbox
                    dataHcName={`${dataHcName}-msa-interested`}
                    checked={!!interestedInMsaMap?.[listItem.id]}
                    onChange={() => onToggleMsaInterested(listItem.id)}
                    recordGoal
                    marketLevel="msa"
                  />
                </TableCell>
                <displayNameConfig.ContentCell
                  sticky
                  isLineClamp
                  width={NAME_WIDTH_LOOKUP['name']}
                  className={styles.TableMsaNameCell}
                  dataHcName={`${dataHcName}-msa-name-cell`}
                  cerberusStats={listItem}
                  isTableCell
                />
                <TableCellWrapper
                  dataHcName={`${dataHcName}-msa-view-button-cell`}
                  align="right"
                  width={NAME_WIDTH_LOOKUP['button']}
                >
                  <MsaLink
                    msaId={listItem.id}
                    dataHcName={`${dataHcName}-msa-view-link`}
                  >
                    <Button dataHcName={`${dataHcName}-msa-view-button`}>
                      View
                    </Button>
                  </MsaLink>
                </TableCellWrapper>
                <TableCell dataHcName={`${dataHcName}-zip-search-link-cell`}>
                  <ZipSearchLink
                    dataHcName={`${dataHcName}-zip-search-link`}
                    msaId={listItem.id}
                  >
                    {listItem.zipCount != null ? listItem.zipCount : 'Zips'}
                  </ZipSearchLink>
                </TableCell>
                {customFieldsData?.order.map((field) => {
                  const config = CERBERUS_STATS_FIELD_CONFIGS[field];
                  if (!config) {
                    return null;
                  }
                  return (
                    <config.ContentCell
                      key={field}
                      cerberusStats={listItem}
                      width={STAT_CELL_WIDTH}
                      wordWrap
                      dataHcName={`${dataHcName}-${field}-msa-cell`}
                      isTableCell
                    />
                  );
                })}
              </TableRow>
            );
          })}
      </Table>
    </FlexScrollCard>
  );
};
