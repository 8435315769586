import React from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';

import { useKeywordSearch } from '../../hooks/useKeywordSearch';

import styles from './KeywordSearchResults.module.css';

interface Props {
  dataHcName: string;
  className?: string;
  // Key identifying this instance of keyword search
  searchKey: string;
  // Allows display of a subset of results
  // Used in PEXP, we call the api for the entire farm
  // and show results based on the filtered list
  // Count defaults to the number of matches if prop isn't passed
  count?: number;
  onClick?: VoidFunction;
}

export const KeywordSearchResults = ({
  dataHcName,
  className,
  searchKey,
  count,
  onClick,
}: Props) => {
  const {
    state: {
      resultsQuery: { data, isInitialLoading, fetchStatus, isFetched },
    },
  } = useKeywordSearch(searchKey);
  const isUninitialized = fetchStatus === 'idle' && !isFetched;
  const numMatches = count !== undefined ? count : data?.length;
  if (isUninitialized) {
    return null;
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.KeywordSearchResults, className)}
      onClick={onClick}
    >
      {isInitialLoading ? (
        <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} small />
      ) : (
        <div
          data-hc-name={`${dataHcName}-count`}
          className={classNames(styles.Count, {
            [styles.matches]: !!numMatches,
            [styles.noMatches]: !numMatches,
          })}
        >
          {numMatches}
        </div>
      )}
    </div>
  );
};
