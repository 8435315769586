import React from 'react';

import { PropertyDataSourcesTable } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useSubjectDataSourcesDocument } from '../../hooks/useSubjectDataSourcesDocument';

interface Props {
  reportId: ReportId;
  className?: string;
  propertyFields?: PropertyStateFields[];
}
export const SubjectDataSources = ({ reportId, propertyFields }: Props) => {
  const { isInitialLoading, data: dataSourcesDoc } =
    useSubjectDataSourcesDocument(reportId);
  return (
    <PropertyDataSourcesTable
      isLoading={isInitialLoading}
      propertyFields={propertyFields}
      propertyStateSources={dataSourcesDoc?.data}
    />
  );
};
