import {
  DocumentRoles,
  RentalSubjectValueDocument,
  ReportId,
  SubjectValueDocument,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useSubjectValueDocument = (
  reportId: ReportId,
  options?: { showRentalValue: boolean }
) => {
  return useSingleDocumentRole<
    SubjectValueDocument | RentalSubjectValueDocument
  >(
    reportId,
    options?.showRentalValue
      ? DocumentRoles.RentalSubjectValue
      : DocumentRoles.SubjectValue
  );
};
