import React, { useMemo, useState } from 'react';

import {
  AutoComplete,
  AutoCompleteInputStyle,
  AutoCompleteOptionType,
} from '@hcs/design-system';
import { SearchIcon } from '@hcs/design-system';
import { OptionalEngagementProps, Report } from '@hcs/types';

import { useReports } from '../../hooks';

interface Props {
  className?: string;
  placeholder?: string;
  selectEngagement?: OptionalEngagementProps;
  minChars?: number;
  disableClear?: boolean;
  hideChevron?: boolean;
  showSearchIcon?: boolean;
  inputStyle?: AutoCompleteInputStyle;
  clearAfterSelect?: boolean;
  onChange?: (newSearchString: string) => void;
  onSelect: (report: Report) => void;
}

const dataHcName = 'search-by-client-id';
export const SearchByClientId = ({
  className,
  minChars = 3,
  placeholder = 'Search by Client ID',
  onChange,
  selectEngagement,
  disableClear,
  hideChevron,
  showSearchIcon,
  inputStyle,
  clearAfterSelect,
  onSelect,
}: Props) => {
  const [searchStr, setSearchStr] = useState('');
  const { data } = useReports(
    {
      clientId: searchStr,
      partialMatch: true,
    },
    {
      enabled: searchStr.length >= minChars,
    }
  );
  const options: AutoCompleteOptionType<Report>[] = useMemo(() => {
    const results: AutoCompleteOptionType<Report>[] = [];
    data?.data.forEach((report) => {
      if (report.clientID) {
        results.push({
          label: `${report.clientID} | ${report.streetAddress}`,
          searchString: report.clientID,
          value: report,
          ...selectEngagement,
        });
      }
    });
    return results;
  }, [data]);
  const handleSelect = (report: Report | null) => {
    if (report) {
      onSelect(report);
    }

    if (clearAfterSelect) {
      setSearchStr('');
    }
  };
  const handleChange = (newSearchStr: string) => {
    if (newSearchStr?.length && newSearchStr.length >= minChars) {
      onChange?.(newSearchStr);
      setSearchStr(newSearchStr);
    }
  };
  return (
    <AutoComplete
      dataHcName={dataHcName}
      className={className}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      optionMode="async"
      disableClear={disableClear}
      hideChevron={hideChevron}
      inputStyle={inputStyle}
      config={{
        selectType: 'single',
        value: undefined,
        onSelect: handleSelect,
      }}
      inputIcon={
        showSearchIcon ? (
          <SearchIcon size="sm" dataHcName={`${dataHcName}-search-icon`} />
        ) : undefined
      }
      clearAfterSelect={clearAfterSelect}
    />
  );
};
