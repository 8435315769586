import { format as d3Format } from 'd3-format';

import { NULL_VALUE } from './formatters.utils';

export const formatWithD3FormatStr = (value: number, d3FormatStr: string) => {
  return d3Format(d3FormatStr)(value);
};

export interface D3NumberFormatConfig {
  commasForThousands?: boolean;
  precision?: number;
}
export const getD3NumberFormat = (config: D3NumberFormatConfig = {}) => {
  const { commasForThousands = true, precision = 0 } = config;
  return `${commasForThousands ? ',' : ''}${
    precision ? `.${precision}f` : 'd'
  }`;
};
export const formatNumberD3 = (
  value: number | null | undefined,
  config?: D3NumberFormatConfig
) => {
  if (value == null) {
    return NULL_VALUE;
  }
  const formatStr = getD3NumberFormat(config);
  return formatWithD3FormatStr(value, formatStr);
};

export interface D3CurrencyFormatConfig {
  commasForThousands?: boolean;
  showCents?: boolean;
}
export const getD3MoneyFormat = (config: D3CurrencyFormatConfig = {}) => {
  const { commasForThousands = true, showCents = false } = config;
  return `$${commasForThousands ? ',' : ''}${showCents ? '.2f' : 'd'}`;
};
export const formatMoneyD3 = (
  value: number | null | undefined,
  config?: D3CurrencyFormatConfig
) => {
  if (value == null) {
    return NULL_VALUE;
  }
  const formatStr = getD3MoneyFormat(config);
  return formatWithD3FormatStr(value, formatStr);
};

export interface D3PercentFormatConfig {
  precision?: number;
  isDecimal?: boolean;
}
export const getD3PercentFormat = (config: D3PercentFormatConfig = {}) => {
  const { precision = 0 } = config;
  return `.${precision}%`;
};
export const formatPercentD3 = (
  value: number | null | undefined,
  config?: D3PercentFormatConfig
) => {
  if (value == null) {
    return NULL_VALUE;
  }
  const isDecimal = config?.isDecimal !== undefined ? config.isDecimal : true;
  const formatStr = getD3PercentFormat(config);
  // the d3 % format assumes the data is in decimal format, so if it's not, we need to divide by 100
  return formatWithD3FormatStr(isDecimal ? value : value / 100, formatStr);
};
