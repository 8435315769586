import {
  ActionButtons,
  Card,
  HouseCanaryLogoFullColor,
  Menu,
  MenuItemProps,
} from '@hcs/design-system';
import {
  APP_CONFIG_AEXP,
  APP_CONFIG_CANARYAI,
  APP_CONFIG_DEXP,
  APP_CONFIG_MARKET_INSIGHTS,
  APP_CONFIG_PEXP,
} from '@hcs/hc-products';
import { AppConfig } from '@hcs/types';

import styles from './SignUpLandingPageCta.module.css';
interface SignUpLandingPageCtaProps {
  navigateToSignUp: VoidFunction;
}
const dataHcName = 'sign-up-landing-page-cta';
const _makeMenuItem = (appConfig: AppConfig): MenuItemProps => {
  return {
    dataHcName: `${dataHcName}-product-${appConfig.applicationKey}`,
    label: appConfig.name,
    Icon: appConfig.Icon,
    description: appConfig.descriptionShort,
  };
};
const PRODUCT_MENU_ITEMS: MenuItemProps[] = [
  _makeMenuItem(APP_CONFIG_PEXP),
  _makeMenuItem(APP_CONFIG_MARKET_INSIGHTS),
  _makeMenuItem(APP_CONFIG_AEXP),
  _makeMenuItem(APP_CONFIG_DEXP),
  _makeMenuItem(APP_CONFIG_CANARYAI),
];

export const SignUpLandingPageCta = ({
  navigateToSignUp,
}: SignUpLandingPageCtaProps) => {
  return (
    <div className={styles.SignUpLandingPageCta}>
      <div>
        <h1 className={styles.Headline}>
          <HouseCanaryLogoFullColor />
          <br />
          Solutions Platform
        </h1>
        <h2>
          Try HouseCanary's best in class Real Estate Data & Valuation Tools for
          free for a limited time<sup>*</sup>.
        </h2>
        <Card dataHcName={`${dataHcName}-card`}>
          <Menu menuItems={PRODUCT_MENU_ITEMS} />
          <ActionButtons
            dataHcName={`${dataHcName}-actions`}
            actions={[
              {
                dataHcName,
                label: 'Get Started',
                onClick: navigateToSignUp,
              },
            ]}
          />
        </Card>
      </div>
      <div className={styles.Disclaimer}>
        * Free trial available with select plans only.
      </div>
    </div>
  );
};
