import { useAccount } from '@hcs/auth';
import { AccountFields } from '@hcs/types';
import { UserContactInfoForm } from '@hcs/types';

export const useUserContactInfo = () => {
  const accountQuery = useAccount();
  const data: UserContactInfoForm | undefined = accountQuery.data
    ? {
        [AccountFields.Email]: accountQuery.data.user.email,
        [AccountFields.FirstName]: accountQuery.data.user.firstName,
        [AccountFields.LastName]: accountQuery.data.user.lastName,
        [AccountFields.StreetAddress]: accountQuery.data.user.streetAddress,
        [AccountFields.City]: accountQuery.data.user.city,
        [AccountFields.State]: accountQuery.data.user.state,
        [AccountFields.Zipcode]: accountQuery.data.user.zipCode,
        [AccountFields.Phone]: accountQuery.data.user.phone,
      }
    : undefined;
  return {
    ...accountQuery,
    data,
  };
};
