import React, { MouseEvent, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import {
  useBodyOverflowHidden,
  useFixedPortalElement,
  useMountTransition,
} from '@hcs/hooks';

import {
  StatusMessage,
  StatusMessageProps,
} from '../../notifications/StatusMessage';

import { DrawerPosition } from './types';

import styles from './Drawer.module.css';

export interface DrawerProps {
  active?: boolean;
  children: ReactNode;
  dataHcName: string;
  position: DrawerPosition;
  className?: string;
  onClose?: <Element>(e?: MouseEvent<Element>) => void;
  removeWhenClosed?: boolean;
  title?: string;
  notifications?: StatusMessageProps[];
}

/*
 * This component is based on the following blog post:
 * https://letsbuildui.dev/articles/building-a-drawer-component-with-react-portals
 */
export const Drawer = ({
  active = false,
  children,
  className,
  dataHcName,
  onClose,
  title,
  notifications,
  position = DrawerPosition.RIGHT,
  removeWhenClosed = true,
}: DrawerProps) => {
  const portalElm = useFixedPortalElement();
  const isTransitioning = useMountTransition(active, 300);

  useBodyOverflowHidden(active);
  // Allow Escape key to dismiss the drawer
  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onClose) {
        onClose();
      }
    };

    if (active) {
      window.addEventListener('keyup', onKeyPress);
    }

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  }, [active, onClose]);

  if (!portalElm || (!isTransitioning && removeWhenClosed && !active)) {
    return null;
  }

  return (
    <>
      {createPortal(
        <div
          data-hc-name={dataHcName}
          aria-hidden={active ? 'false' : 'true'}
          className={classNames(styles.DrawerContainer, {
            [styles.active]: active,
            [styles.in]: isTransitioning,
          })}
          onClick={(e) => {
            // Was getting click propagation to table rows when this component was rendered inside one
            e.stopPropagation();
          }}
        >
          <div
            className={classNames(className, styles.Drawer, styles[position])}
            role="dialog"
          >
            {title && <h2>{title}</h2>}
            {notifications &&
              notifications.map(({ dataHcName, ...rest }) => (
                <StatusMessage
                  key={dataHcName}
                  dataHcName={dataHcName}
                  {...rest}
                />
              ))}
            {children}
          </div>
          <div className={styles.Backdrop} onClick={onClose} />
        </div>,
        portalElm
      )}
    </>
  );
};
