import React, { useEffect } from 'react';
import classNames from 'classnames';

import { TableHeaderCellProps } from '@hcs/types';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';

import styles from './Table.module.css';

export const TableHeaderCell = ({
  dataHcName,
  dataHcEventData,
  className,
  align = 'left',
  height,
  width,
  children,
  sticky,
  stickyOffset,
  sort,
  sortable,
  style = {},
  onClick,
  wordWrap,
  noBorderBottom,
  isTableHeaderCell,
  afterSortContent,
  ...props
}: TableHeaderCellProps & React.ThHTMLAttributes<HTMLTableCellElement>) => {
  useEffect(() => {
    if (sticky && width === undefined) {
      console.warn(
        '[TableHeaderCell] sticky is true but a width is not defined'
      );
    }
  }, [sticky, width]);
  return (
    <th
      data-hc-name={dataHcName}
      data-hc-event-data={dataHcEventData}
      className={classNames(styles.TableHeaderCell, className, {
        [styles.sticky]: sticky,
        [styles.clickable]: !!onClick,
        [styles.wordWrap]: wordWrap,
        [styles.noBorderBottom]: !!noBorderBottom,
      })}
      style={{
        ...style,
        height: height !== undefined ? `${height}px` : style.height,
        width: width !== undefined ? `${width}px` : style.width,
        maxWidth: width !== undefined ? `${width}px` : style.width,
        minWidth: width !== undefined ? `${width}px` : style.width,
        left: stickyOffset !== undefined ? `${stickyOffset}px` : style.left,
      }}
      onClick={onClick}
      aria-sort={
        sort === 'ASC'
          ? 'ascending'
          : sort === 'DESC'
          ? 'descending'
          : undefined
      }
      {...props}
    >
      <div
        className={classNames(styles.Content, {
          [styles.left]: align === 'left',
          [styles.right]: align === 'right',
          [styles.center]: align === 'center',
        })}
      >
        <div>{children}</div>
        {(sort || sortable) && (
          <DirectionalChevron
            className={classNames(styles.Sort, {
              [styles.visible]: sort === 'ASC' || sort === 'DESC',
            })}
            dataHcName={`${dataHcName}-sort-icon`}
            direction={sort === 'ASC' ? 'up' : 'down'}
            size="sm"
          />
        )}
        {afterSortContent && (
          <div
            className={styles.AfterSortContent}
            // we don't necessarily want to sort the column b/c of a click on this extra content
            onClick={(e) => e.stopPropagation()}
          >
            {afterSortContent}
          </div>
        )}
      </div>
    </th>
  );
};

TableHeaderCell.defaultProps = {
  isTableHeaderCell: true,
};
