import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SubNav } from '@hcs/design-system';
import { APP_CONFIG_PORTFOLIO, VIEW_PATHS_PORTFOLIO } from '@hcs/hc-products';

const dataHcName = 'new-portfolio-page-nav';
export const NewPortfolioPageNav = () => {
  const [searchParams] = useSearchParams();
  const portfolioId = searchParams.get('portfolioId');
  const { pathname } = useLocation();
  const selectPropertiesPathname = `${APP_CONFIG_PORTFOLIO.rootPath}/${VIEW_PATHS_PORTFOLIO.NEW_PORTFOLIO}/${VIEW_PATHS_PORTFOLIO.UPLOAD}`;

  const SUB_NAV_ITEMS_CONFIG = [
    {
      navigateTo: `${APP_CONFIG_PORTFOLIO.rootPath}/${VIEW_PATHS_PORTFOLIO.NEW_PORTFOLIO}`,
      label: 'New Portfolio',
      dataHcName: `${dataHcName}-new-portfolio-header`,
      children: [
        {
          navigateTo: `${APP_CONFIG_PORTFOLIO.rootPath}/${VIEW_PATHS_PORTFOLIO.NEW_PORTFOLIO}/${VIEW_PATHS_PORTFOLIO.PORTFOLIO_SETUP}`,
          label: 'Portfolio Setup',
          dataHcName: `${dataHcName}-portfolio-setup`,
          disabled: pathname === selectPropertiesPathname
        },
        {
          navigateTo: selectPropertiesPathname,
          label: 'Select Properties',
          dataHcName: `${dataHcName}-upload`,
          disabled: !portfolioId
        }
      ]
    }
  ];

  return (
    <SubNav dataHcName={dataHcName} subNavItemsConfig={SUB_NAV_ITEMS_CONFIG} />
  );
};
