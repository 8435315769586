import React from 'react';
import classNames from 'classnames';

import styles from './Disclosure.module.css';

export interface DisclosureProps {
  dataHcName: string;
  className?: string;
  includeAVMSection?: boolean;
  horzLinksSection?: boolean;
}
export const Disclosure = (props: DisclosureProps) => {
  const { dataHcName, className, includeAVMSection, horzLinksSection } = props;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Disclosure, className)}
    >
      <section data-hc-name={`${dataHcName}-agent-solicitation`}>
        If you are currently working with a real estate agent, this is not meant
        as a solicitation of your business.
      </section>
      <section data-hc-name={`${dataHcName}-licensed-brokerage`}>
        HouseCanary, Inc. is a Licensed Real Estate Brokerage in KS, NM, SC and
        under the Trade Name ComeHome in AL, AK, AZ, CA, CO, CT, DC, DE, FL, GA,
        HI, IA, ID, IL, IN, KY, LA, MA, MD, ME, MO, MN, MS, MT, NC, ND, NE, NH,
        NJ, NV, NY, OH, OK, OR, PA, RI, SD, TN, TX, VA, VT, WA, WI, WV, WY.
        Trade Name ComeHome Real Estate in MI and UT. Trade Name ComeHome by
        HouseCanary in AR.
      </section>
      <section
        className={classNames({ [styles.horzLinksSection]: horzLinksSection })}
      >
        <div data-hc-name={`${dataHcName}-brokerage-info`}>
          HouseCanary, Inc.,{' '}
          <a
            data-hc-name={`${dataHcName}-brokerage-info-link`}
            href="https://www.housecanary.com/brokerage/"
            target="_blank"
            rel="noreferrer"
          >
            brokerage information
          </a>
        </div>
        <div>
          <a
            data-hc-name={`${dataHcName}-trec-info-link`}
            href="https://www.housecanary.com/wp-content/uploads/2021/02/Texas-Form-IABS-1-0-1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            TREC Info About Brokerage Services
          </a>
        </div>
        <div>
          <a
            data-hc-name={`${dataHcName}-trec-consumer-protection-link`}
            href="https://www.trec.texas.gov/forms/consumer-protection-notice"
            target="_blank"
            rel="noreferrer"
          >
            TREC Consumer Protection Notice
          </a>
        </div>
        <div data-hc-name={`${dataHcName}-california-dre`}>
          California DRE #02113022
        </div>
      </section>
      {includeAVMSection && (
        <p data-hc-name={`${dataHcName}-avm-disclosure`}>
          AVM Disclosure: An AVM is an estimated sale price for a property. It
          is not the same as the opinion of value in an appraisal developed by a
          licensed appraiser under the Uniform Standards of Professional
          Appraisal Practice.
        </p>
      )}
    </div>
  );
};
