import React, { useMemo, useState } from 'react';
import getTime from 'date-fns/getTime';
import { Operation } from 'fast-json-patch';
import {
  Point,
  PointProps,
  VictoryArea,
  VictoryLine,
  VictoryScatter,
} from 'victory';

import { Toggle } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { SubjectIcon } from '@hcs/design-system';
import { Chart } from '@hcs/pdf/charts';
import { ChartDimensions, ChartSizes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { ReportFeatures, ReportFeaturesSupportTypes } from '@hcs/types';
import {
  ForecastChartDatum,
  ForecastChartPaths,
  ForecastChartSchema,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useDocumentPatch, useReportPermissions } from '../../hooks';
import { useForecastChartBlockDocument } from '../../hooks/useForecastChartBlockDocument';
import { useForecastChartZipDocument } from '../../hooks/useForecastChartZipDocument';
import { useForecastStats } from '../../hooks/useForecastStats';
import { useReportConfig } from '../../hooks/useReportConfig';
import { FORECAST_CHART_FEATURES } from '../ForecastChart/ForecastChart';
import { ForecastStatsDeprecated } from '../ForecastStatsDeprecated';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './ForecastChartDeprecated.module.css';

const PATH_SELECTED: ForecastChartPaths = '/data/metaData/selected';

const SUBJECT_SIZES = {
  small: 19,
  medium: 22,
  large: 26,
  xlarge: 26,
};
const POINT_SIZES = {
  small: 3,
  medium: 4,
  large: 8,
  xlarge: 8,
};

const dataHcName = 'forecast-chart';

const ScatterDataComponentFactory =
  (chartSize: ChartSizes) => (props: PointProps) => {
    const subjectSize = SUBJECT_SIZES[chartSize];
    const subjectAdjustment = subjectSize / 2;
    const pointSize = POINT_SIZES[chartSize];
    return props.index === 0 ? (
      <SubjectIcon
        dataHcName={`${dataHcName}-subject-icon`}
        x={(props.x || 0) - subjectAdjustment}
        y={(props.y || 0) - subjectAdjustment}
        height={subjectSize}
        width={subjectSize}
      />
    ) : (
      <Point {...props} size={pointSize} />
    );
  };
interface Props {
  reportId: ReportId;
  className?: string;
  chartDimensions?: ChartDimensions;
  theme?: {
    Title?: string;
  };
  chartSize?: ChartSizes;
}
export const ForecastChartDeprecated = ({
  reportId,
  chartSize = 'medium',
  chartDimensions,
}: Props) => {
  const [readOnlyChartType, setReadOnlyChartType] = useState<
    'zip' | 'block' | undefined
  >();
  const { data: reportConfig } = useReportConfig(reportId);
  const reportPermissionsQuery = useReportPermissions(reportId);
  const forecastStatsQuery = useForecastStats(reportId);
  const forecastChartBlockQuery = useForecastChartBlockDocument(reportId);
  const forecastChartZipQuery = useForecastChartZipDocument(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);

  const forecastType =
    readOnlyChartType ||
    (reportConfig?.reportFeaturesSupport[ReportFeatures.SubjectForecastBlock] &&
    forecastChartBlockQuery.data?.data?.metaData.selected
      ? 'block'
      : 'zip');
  const ScatterDataComponent = useMemo(
    () => ScatterDataComponentFactory(chartSize),
    [chartSize]
  );
  const chart =
    forecastType === 'block'
      ? forecastChartBlockQuery.data?.data?.chart
      : forecastChartZipQuery.data?.data?.chart;
  const firstPoint = chart?.data?.[0];
  const lastPoint = chart?.data?.[chart?.data.length - 1];
  const currentYear = chart?.data?.[chart.currentMonthIndex];
  const domain:
    | {
        x: [Date, Date];
        y: [number, number];
      }
    | undefined =
    firstPoint && lastPoint && chart.data
      ? {
          x: [new Date(firstPoint.x), new Date(lastPoint.x)],
          y: [
            Math.min(...chart.data.map((d) => d.y)),
            Math.max(...chart.data.map((d) => d.y)),
          ],
        }
      : undefined;

  // For accessing stats
  const idxYear1 = chart ? chart.currentMonthIndex + 12 : undefined;
  const idxYear2 = chart ? chart.currentMonthIndex + 24 : undefined;
  const stats = forecastStatsQuery.data?.[forecastType];
  const forecastYears: NonNullable<ForecastChartSchema['chart']>['data'] =
    stats?.map((year) => ({
      x: year.date,
      y: year.price,
      metaData: {
        percentDiff: year.percentDiff,
        hpi: year.hpi,
      },
    })) || [];

  const presentDate = currentYear ? new Date(currentYear.x) : undefined;

  // Combine query metadata for easy loading checking
  const combinedQueryStatus = combineUseQueryResult([
    forecastStatsQuery,
    forecastChartBlockQuery,
    forecastChartZipQuery,
    reportPermissionsQuery,
  ]);
  if (!combinedQueryStatus.isFetched) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const hasBlockChart = !!forecastChartBlockQuery.data?.data?.chart;
  const hasZipChart = forecastChartZipQuery.data?.data?.chart;
  if (!hasBlockChart && !hasZipChart) {
    return (
      <NullState title="Forecast Unavailable" dataHcName={`${dataHcName}-null`}>
        Sorry! There is not enough data to generate the forecast - but our data
        improves everyday. Please check again later!
      </NullState>
    );
  }

  const handleForecastTypeChange = (chartType: 'zip' | 'block') => {
    if (reportPermissionsQuery.data?.isEditable) {
      if (
        forecastChartZipQuery.data?.documentId &&
        forecastChartBlockQuery.data?.documentId
      ) {
        const zipOperation: Operation = {
          op: 'add',
          path: PATH_SELECTED,
          value: chartType === 'zip',
        };

        const zipPatch = {
          document: forecastChartZipQuery.data,
          reportId,
          operations: [zipOperation],
        };

        const blockOperation: Operation = {
          op: 'add',
          path: PATH_SELECTED,
          value: chartType === 'block',
        };

        const blockPatch = {
          document: forecastChartBlockQuery.data,
          reportId,
          operations: [blockOperation],
        };

        documentPatchMutation.mutate(zipPatch);
        documentPatchMutation.mutate(blockPatch);
      }
    } else {
      setReadOnlyChartType(chartType);
    }
  };

  return (
    <Chart
      dataHcName={dataHcName}
      isLoading={!combinedQueryStatus.isFetched}
      chartDimensions={chartDimensions}
      chartSize={chartSize}
      chart={{
        domain,
        domainPadding: { y: 50 },
        scale: { x: 'time', y: 'linear' },
      }}
      contentBelow={
        <>
          <ForecastStatsDeprecated
            reportId={reportId}
            forecastType={forecastType}
          />
          <ReportFeaturesSupported
            reportId={reportId}
            reportFeatures={FORECAST_CHART_FEATURES}
            reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
          >
            <div className={styles.Controls}>
              <Toggle
                dataHcName={`${dataHcName}-toggle`}
                options={[
                  {
                    label: 'Zip-Code',
                    value: 'zip',
                  },
                  {
                    label: 'Block',
                    value: 'block',
                  },
                ]}
                value={forecastType}
                onChange={handleForecastTypeChange}
              />
            </div>
          </ReportFeaturesSupported>
        </>
      }
      error={
        !chart?.data?.length && (
          <NullState
            title={
              forecastType === 'block'
                ? 'Block-level Forecast Unavailable'
                : 'Zip-Code-level Forecast Unavailable'
            }
            dataHcName={`${dataHcName}-null`}
            absCenter
          >
            Sorry! There is not enough data to generate the forecast - but our
            data improves everyday. Please check again later!
          </NullState>
        )
      }
    >
      <defs>
        <linearGradient
          id="1YearGrowthGradient"
          x1="0%"
          x2="0%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#FAD961" />
          <stop offset="100%" stopColor="#FFB854" />
        </linearGradient>
      </defs>
      {chart?.data?.slice(chart.currentMonthIndex) && (
        <VictoryArea
          x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
          y={(datum: ForecastChartDatum) => datum.y}
          data={chart.data.slice(chart.currentMonthIndex)}
          style={{
            data: {
              fill: 'rgb(216,216,216)',
              opacity: 0.1,
            },
          }}
        />
      )}
      {idxYear1 != null &&
        chart?.data?.slice(chart.currentMonthIndex, idxYear1 + 1) && (
          <VictoryArea
            x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
            y={(datum: ForecastChartDatum) => datum.y}
            data={chart.data.slice(chart.currentMonthIndex, idxYear1 + 1)}
            style={{
              data: {
                fill: 'url(#1YearGrowthGradient)',
                opacity: 0.33,
              },
            }}
          />
        )}
      {idxYear1 != null &&
        idxYear2 != null &&
        chart?.data?.slice(idxYear1, idxYear2 + 1) && (
          <VictoryArea
            x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
            y={(datum: ForecastChartDatum) => datum.y}
            data={chart.data.slice(idxYear1, idxYear2 + 1)}
            style={{
              data: {
                fill: 'url(#1YearGrowthGradient)',
                opacity: 0.66,
              },
            }}
          />
        )}
      {idxYear2 != null && chart?.data?.slice(idxYear2) && (
        <VictoryArea
          x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
          y={(datum: ForecastChartDatum) => datum.y}
          data={chart.data.slice(idxYear2)}
          style={{
            data: {
              fill: 'url(#1YearGrowthGradient)',
              opacity: 0.99,
            },
          }}
        />
      )}
      {chart?.data && (
        <VictoryLine
          interpolation="natural"
          x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
          y={(datum: ForecastChartDatum) => datum.y}
          data={chart.data}
          style={{
            data: {
              stroke: '#FDB813',
            },
          }}
        />
      )}
      {presentDate && (
        <VictoryLine
          x={() => getTime(new Date(presentDate))}
          samples={1}
          style={{ data: { stroke: '#FDB813' } }}
        />
      )}
      {chart && (
        <VictoryScatter
          x={(datum: ForecastChartDatum) => new Date(datum.x).getTime()}
          y={(datum: ForecastChartDatum) => datum.y}
          data={[
            chart.data?.[chart.currentMonthIndex],
            ...forecastYears,
          ].filter((data) => data)}
          dataComponent={<ScatterDataComponent />}
          style={{
            data: {
              stroke: '#FDB813',
              strokeWidth:
                chartSize === 'large' || chartSize === 'xlarge' ? '2px' : '1px',
              fill: '#fff',
            },
          }}
        />
      )}
    </Chart>
  );
};
