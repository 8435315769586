import { MultiPolygon } from 'geojson';

import { BboxInput, SpatialSortInput } from '../cerberus';
import { PropertySpatialHit } from '../cerberus-spatial-search/PropertySpatialHit';
import { MarkerOffset } from '../maps';
import { ListingStatus } from '../property-graph/PropertyLookup.types';
import { RangeFilterOrNull } from '../Range.types';
import { SortType } from '../Sort.types';

export interface OrganizationSummary {
  hcOrgId: string;
  name: string;
}

export interface BuyBoxSummaryLastModified {
  email: string | null;
  fullName: string | null;
  modifiedAt: string;
  userId: number;
}

export interface BuyBoxSummary {
  id: number;
  msaId: string | null;
  name: string;
  enabled: boolean;
  lastModified: BuyBoxSummaryLastModified;
}
export interface MSA {
  msaId: string;
  msaName: string;
  coverage?:
  | 'full' // 80-100%  https://git.housecanary.net/Engineering/lead-feed/blob/develop/leadfeed/msa.py#L9-14
  | 'partial' // 40-80%
  | 'limited' // 10-40%
  | 'absent'; // 0-10%
  lat: number;
  lng: number;
  bbox: BboxInput;
}

// backend sends the geom over as stringified JSON
export interface MsaWithGeomFromServer extends MSA {
  geom: string;
}

export interface MSAWithGeom extends MSA {
  geom: MultiPolygon;
}

export type MsaLookup = { [key: string]: MSA };
export interface BuyBoxListItem extends Pick<BuyBoxSummary, 'id' | 'name'> {
  msaName: string | null;
  lastModified: Date;
  isActive: boolean;
  lastModifiedBy: string | null;
}

export enum LeadFeedPropertyTypeEnum {
  sfr = 'SFR',
  condo = 'CONDO',
  manufactured = 'MANUFACTURED',
  multi = 'MULTI',
  townhouse = 'TOWNHOUSE',
  coop = 'COOP'
}

export type PropertyTypes = `${LeadFeedPropertyTypeEnum}`;

export type BuyBoxSortableFields = Extract<
  keyof BuyBoxListItem,
  'name' | 'msaName' | 'lastModified'
>;

export interface BuyBoxRangeFormData {
  listingPriceMin: number | null;
  listingPriceMax: number | null;
  bedsMin: number | null;
  bedsMax: number | null;
  bathsMin: number | null;
  bathsMax: number | null;
  glaMin: number | null;
  glaMax: number | null;
  lotSizeMin: number | null;
  lotSizeMax: number | null;
  yearBuiltMin: number | null;
  yearBuiltMax: number | null;
  numStoriesMin: number | null;
  numStoriesMax: number | null;
  garageSpacesMin: number | null;
  garageSpacesMax: number | null;
}

export interface BuyBoxSetFormData {
  propertyType: PropertyTypes[];
}
export interface BuyBoxExcludeFormData {
  excludePool: boolean;
  excludeRentalListings: boolean;
  excludeSeptic: boolean;
}

export interface BuyBoxFormData
  extends BuyBoxRangeFormData,
  BuyBoxSetFormData,
  BuyBoxExcludeFormData {
  name: string;
  msaId: MSA['msaId'];
  selectedZipcodes: BuyBoxZipcode[];
  unSelectedZipcodes: BuyBoxZipcode[];
  historicalDetection: boolean;
  templateId?: number;
  shouldSaveAsTemplate: boolean;
}

export interface BulkEditRangeActiveFormData {
  listingPriceMinActive: boolean;
  listingPriceMaxActive: boolean;
  bedsMinActive: boolean;
  bedsMaxActive: boolean;
  bathsMinActive: boolean;
  bathsMaxActive: boolean;
  glaMinActive: boolean;
  glaMaxActive: boolean;
  lotSizeMinActive: boolean;
  lotSizeMaxActive: boolean;
  yearBuiltMinActive: boolean;
  yearBuiltMaxActive: boolean;
  numStoriesMinActive: boolean;
  numStoriesMaxActive: boolean;
  garageSpacesMinActive: boolean;
  garageSpacesMaxActive: boolean;
}

export interface BulkEditBuyBoxFormData
  extends BuyBoxRangeFormData,
  BulkEditRangeActiveFormData {
  collectionIds: BuyBox['id'][];
  field?: keyof RangeFilters;
}
export interface BulkEditRangeFilter {
  min?: number | null;
  max?: number | null;
}
export type BulkEditRangeFilterOrNull = BulkEditRangeFilter | null;
export interface SetFilter<SetValueType> {
  set: SetValueType[];
  negate?: boolean;
  matchCase?: boolean;
  matchNone?: boolean;
}
export type SetFilterOrNull<SetValueType> = SetFilter<SetValueType> | null;
export interface ExcludeFilter<ExcludeValueType> {
  set: [ExcludeValueType];
  negate?: boolean;
}
export type ExcludeFilterOrNull<ExcludeValueType> =
  ExcludeFilter<ExcludeValueType> | null;

export interface SetFilters {
  propertyType: SetFilterOrNull<PropertyTypes>;
}
export interface RangeFilters {
  yearBuilt: RangeFilterOrNull;
  listPrice: RangeFilterOrNull;
  beds: RangeFilterOrNull;
  baths: RangeFilterOrNull;
  sqft: RangeFilterOrNull;
  lotArea: RangeFilterOrNull;
  noOfStories: RangeFilterOrNull;
  parkingGarageCount: RangeFilterOrNull;
}

export interface BulkEditRangeFilters {
  yearBuilt: BulkEditRangeFilterOrNull;
  listPrice: BulkEditRangeFilterOrNull;
  beds: BulkEditRangeFilterOrNull;
  baths: BulkEditRangeFilterOrNull;
  sqft: BulkEditRangeFilterOrNull;
  lotArea: BulkEditRangeFilterOrNull;
  noOfStories: BulkEditRangeFilterOrNull;
  parkingGarageCount: BulkEditRangeFilterOrNull;
}

export interface ExcludeFilters {
  pool: ExcludeFilterOrNull<1>;
  sewer: ExcludeFilterOrNull<'septic'>;
  solar?: ExcludeFilterOrNull<1>;
  rental: ExcludeFilterOrNull<1>;
}
export type Filters = SetFilters &
  RangeFilters &
  ExcludeFilters & {
    id?: number;
    qual?: boolean;
    zipcode?: null;
    mlsState?: null;
    address?: null;
    county?: null;
    city?: null;
    subdivision?: null;
    filterHash?: string;
    isDisabled: boolean | null;
  };

export interface ZipCodeFilter {
  id: number;
  zipcode: SetFilter<string>;
};

export type BuyBoxZipcode = string;
export interface BuyBox {
  id: number;
  organizationId: number;
  enabled: boolean;
  insertedAt: Date;
  name: string;
  groupName: string | null;
  version: string | null;
  msaId: string;
  filters: [];
  defaults: Filters;
  defaultFilters: ZipCodeFilter[];
  disabledFilters: Filters | [];
  enabledZipcodes: BuyBoxZipcode[];
  qualifiers: null;
  disabledZipcodes: BuyBoxZipcode[];
  lastModified: BuyBoxSummaryLastModified;
}

export type NewBuyBox = Pick<BuyBox, 'enabled' | 'name' | 'msaId'> & {
  defaults: Filters;
  disabledZipcodes: BuyBoxZipcode[];
  historicalDetectionAfterCreated: boolean;
  saveAsDefaultTemplate: boolean;
};

export type BuyBoxPatch = Pick<BuyBox, 'id'> &
  Partial<Pick<BuyBox, 'enabled' | 'name' | 'defaults' | 'disabledZipcodes'>>;

export interface BulkEditPatch {
  ids: BuyBox['id'][];
  default: Partial<BulkEditRangeFilters>;
}
export interface BulkEditErrorResponse {
  ids: [BuyBox['id']]; // we only ever expect one id here
  default: Record<keyof RangeFilters, string>;
}

export interface Template {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  template: Filters;
}

export interface BuyBoxSearchSort {
  search?: string;
  sort?: SortType<BuyBoxSortableFields>;
  removeNotActive?: boolean;
}

export type MapPageViewMode = 'list' | 'card';

export interface SelectedMarker {
  property: PropertySpatialHit;
  tileKey: string; // built using coordsToTileKey()
  markerOffset?: MarkerOffset;
}

export enum TimeSeriesResolution {
  'Hour' = 'hour',
  'Day' = 'day',
  'Week' = 'week',
  'Month' = 'month',
  'Quarter' = 'quarter',
  'Year' = 'year'
}

interface Lead {
  datetimeStart: string;
  deliveredCount: number;
  failedCount?: number;
  totalCount?: number;
}
export interface BuyBoxActivityFromServer {
  insertedAt: Date;
  enabled: boolean;
  collectionId: number;
  collectionName: string;
  organizationId: number;
  msaId: string;
  deliveredCountTotal: number;
  failedCountTotal?: number;
  leads: Lead[];
}

export type BuyBoxActivityFromServerMap = Record<
  number,
  BuyBoxActivityFromServer
>;

export interface BuyBoxActivity {
  buyBoxId: BuyBoxActivityFromServer['collectionId'];
  buyBoxName: BuyBoxActivityFromServer['collectionName'];
  deliveredCount: Lead['deliveredCount'];
}

// date is created by calling date.toISOString();
export interface BuyBoxActivityWithDate extends BuyBoxActivity {
  date: string;
}

// date is the key which is created by calling date.toISOString();
export type BuyBoxActivityByDate = Record<string, BuyBoxActivity[]>;

interface PropertyHistoryFromServerDelivery {
  deliveredAt: string | null;
  deliveryId: number,
  insertedAt: string,
  status: string,
  deliveryMethod: string,
  deliveryAttempt: number,
  httpResponseCode: number | null,
  responsePreview: string | null
}
interface PropertyHistoryFromServerHistory {
  delivery: PropertyHistoryFromServerDelivery[];
  eventMeta: {
    hcMlsId: number | null;
    mlsName: string | null;
    listingId: string | null;
    addressId: number;
    documentId: string;
    entityId: string;
    listDate: string | null;
    listPrice: number | null;
    mlsState: string;
    propertyType: string | null;
    rental: boolean;
    slug: string;
    source: string;
    url: string;
  };
  hitId: number;
  eventType: string;
  insertedAt: string;
  listingPrice: number | null;
  priceChange: number | null;
  statusChange: string | null;
  mlsStatus: ListingStatus | null;
  calculatedStatus: string;
}
interface PropertyHistoryFromServerMatch {
  addressId: number | null;
  slug: string | null;
  fullLine: string | null;
  address: string | null;
  unit: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  latLon: string | null;
  mlsStatus: ListingStatus | null;
  lastSeen: string;
  collectionName: BuyBox['name'];
  collectionId: BuyBox['id'];
  history: PropertyHistoryFromServerHistory[];
}

// the response is an array since you can search for multiple addresses at once, though we aren't using it that way
export type PropertyHistoryFromServer = {
  search: {
    addressId: number;
  };
  // match will have an item per collection (as the address could be found in multiple collections)
  match: PropertyHistoryFromServerMatch[];
}[];

export interface PropertyHistory {
  collectionName: PropertyHistoryFromServerMatch['collectionName'];
  collectionId: PropertyHistoryFromServerMatch['collectionId'];
  deliveryDate: Date | null;
  status: PropertyHistoryFromServerHistory['mlsStatus'];
  statusDate: Date;
  listingId: PropertyHistoryFromServerHistory['eventMeta']['listingId'];
  mlsName: PropertyHistoryFromServerHistory['eventMeta']['mlsName'];
}

export interface PropertyListSortOption {
  field: SpatialSortInput['field'] | 'RENTAL_YIELD_BY_LIST_PRICE';
  order: NonNullable<SpatialSortInput['order']>;
  label: string;
}

export type BuyBoxSearch = 'buy-box-search';
export type BuyBoxSort = 'buy-box-sort';
export type BuyBoxOrder = 'buy-box-order';
export type BuyBoxCreate = 'buy-box-create';

export interface BuyBoxPageParams {
  Search: BuyBoxSearch;
  Sort: BuyBoxSort;
  Order: BuyBoxOrder;
  Create: BuyBoxCreate;
}
export interface MapPageSearchParams {
  BuyBoxId: string;
  Latitude: string;
  Longitude: string;
  Zoom: string;
}
