import { useMemo } from 'react';

import { usePropertySpatialSearchCount } from '@hcs/cerberus-spatial-search';
import { BuyBoxFormData } from '@hcs/types';

import { getCommonIdParams, getSpatialFiltersInput } from '../';

export const usePropertySpatialCountByBB = (
  buyBoxData: BuyBoxFormData | null
) => {
  // make params for calling usePropertiesForList
  const params = useMemo(() => {
    if (buyBoxData === null) {
      return null;
    }
    return {
      id: {
        ...getCommonIdParams(buyBoxData),
      },
      spatialFilters: getSpatialFiltersInput(buyBoxData),
    };
  }, [buyBoxData]);
  return usePropertySpatialSearchCount(params);
};
