import { DependencyList, useEffect, useRef } from 'react';

export const useComponentDidUpdate = (
  effect: VoidFunction,
  dependencies: DependencyList
) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    effect();
  }, dependencies);
};
