import React, { ReactNode, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useFixedPortalElement } from '@hcs/hooks';

import {
  Header,
  HEADER_HEIGHT_LG,
  HEADER_HEIGHT_SM,
  HeaderProps,
} from '../../../global/headers';
import { FlexScroll } from '../FlexScroll';

import styles from './OverlayPage.module.css';

export interface OverlayPageProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  dataHcEventSection?: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  active: boolean;
  children?: ReactNode;
  headerProps?: Omit<HeaderProps, 'dataHcName'>;
}
export const OverlayPage = ({
  dataHcName,
  dataHcEventSection,
  className,
  headerProps,
  children,
  active,
}: OverlayPageProps) => {
  const keyboardClose = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        headerProps?.onBack ? headerProps?.onBack() : headerProps?.onClose?.();
      }
    },
    [headerProps?.onBack, headerProps?.onClose]
  );
  useEffect(() => {
    window.addEventListener('keyup', keyboardClose);
    return () => {
      window.removeEventListener('keyup', keyboardClose);
    };
  }, [keyboardClose]);
  const portalElm = useFixedPortalElement();
  if (!portalElm) return null;
  return (
    <>
      {createPortal(
        active && (
          <FlexScroll
            dataHcName={dataHcName}
            dataHcEventSection={dataHcEventSection}
            className={classNames(styles.OverlayPage, className)}
            header={
              headerProps && {
                height:
                  headerProps.size === 'sm'
                    ? HEADER_HEIGHT_SM
                    : HEADER_HEIGHT_LG,
                content: (
                  <Header
                    dataHcName={`${dataHcName}-header`}
                    {...headerProps}
                  />
                ),
              }
            }
          >
            {children}
          </FlexScroll>
        ),
        portalElm
      )}
    </>
  );
};
