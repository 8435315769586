import React from 'react';
import classNames from 'classnames';

import { TextButton } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';

import { RefreshReport } from '../../features/RefreshReport';
import { useReportPermissions } from '../../hooks';
import {
  COMP_FILTER_DISTANCE_FEATURES_RENTAL,
  COMP_FILTER_DISTANCE_FEATURES_SALE,
} from '../CompFilters';
import { CompFiltersClearAllButton } from '../CompFiltersDeprecated/CompFiltersClearAllButton';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import { SavedCompFilterSetFormLauncher } from '../SavedCompFilterSetForm';
import { SavedCompFilterSetSelector } from '../SavedCompFilterSetSelector';

import { CompFilterButtonBasement } from './buttons/CompFilterButtonBasement';
import { CompFilterButtonBathrooms } from './buttons/CompFilterButtonBathrooms';
import { CompFilterButtonBedrooms } from './buttons/CompFilterButtonBedrooms';
import { CompFilterButtonCloseDateRental } from './buttons/CompFilterButtonCloseDateRental';
import { CompFilterButtonClosePriceRental } from './buttons/CompFilterButtonClosePriceRental';
import { CompFilterButtonCurrentRentalValue } from './buttons/CompFilterButtonCurrentRentalValue';
import { CompFilterButtonDistance } from './buttons/CompFilterButtonDistance';
import { CompFilterButtonGarage } from './buttons/CompFilterButtonGarage';
import { CompFilterButtonGrossLivingArea } from './buttons/CompFilterButtonGrossLivingArea';
import { CompFilterButtonListPrice } from './buttons/CompFilterButtonListPrice';
import { CompFilterButtonListPriceRental } from './buttons/CompFilterButtonListPriceRental';
import { CompFilterButtonLotSize } from './buttons/CompFilterButtonLotSize';
import { CompFilterButtonPool } from './buttons/CompFilterButtonPool';
import { CompFilterButtonPropertyStatus } from './buttons/CompFilterButtonPropertyStatus';
import { CompFilterButtonSalePrice } from './buttons/CompFilterButtonSalePrice';
import { CompFilterButtonSimilarity } from './buttons/CompFilterButtonSimilarity';
import { CompFilterButtonStories } from './buttons/CompFilterButtonStories';
import { CompFilterButtonYearBuilt } from './buttons/CompFilterButtonYearBuilt';
import {
  CompFilterButtonListDate,
  CompFilterButtonListingDateRental,
  CompFilterButtonPropertyStatusRental,
  CompFilterButtonPropertyType,
  CompFilterButtonSaleDate,
} from './buttons';
import { MoreFiltersButton } from './MoreFiltersButton';

import styles from './CompFilterBar.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  navigateToCompSelectionDraw: NavigateToCompSelectionDrawFn;
}
const dataHcName = 'comp-filter-bar';
export const COMP_FILTER_BAR_FEATURES_SALE = [
  ReportFeatures.CompsSelect,
  ReportFeatures.CompsFilterGeojson,
  ReportFeatures.CompsFilterDistance,
  ReportFeatures.CompsSavedFilterSets,
];
export const COMP_FILTER_BAR_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsSelect,
  ReportFeatures.RentalCompsFilterGeojson,
  ReportFeatures.RentalCompsFilterDistance,
  ReportFeatures.RentalCompsSavedFilterSets,
];
export const CompFilterBar = ({
  reportId,
  compType,
  className,
  navigateToCompSelectionDraw,
}: Props) => {
  const isRental = compType === CompTypes.Rental;
  const {
    data: { isEditable },
  } = useReportPermissions(reportId);
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompFilterBar, className)}
    >
      {isEditable && (
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[
            isRental
              ? ReportFeatures.RentalCompsSavedFilterSets
              : ReportFeatures.CompsSavedFilterSets,
          ]}
        >
          <div className={styles.SavedFiltersCell}>
            <SavedCompFilterSetSelector
              reportId={reportId}
              compType={compType}
            />
          </div>
        </ReportFeaturesSupported>
      )}
      <div className={styles.FiltersCell}>
        <CompFilterButtonPropertyType reportId={reportId} compType={compType} />
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental
              ? COMP_FILTER_DISTANCE_FEATURES_RENTAL
              : COMP_FILTER_DISTANCE_FEATURES_SALE
          }
        >
          <CompFilterButtonDistance
            reportId={reportId}
            compType={compType}
            navigateToCompSelectionDraw={navigateToCompSelectionDraw}
          />
        </ReportFeaturesSupported>
        <CompFilterButtonBedrooms reportId={reportId} compType={compType} />
        <CompFilterButtonBathrooms reportId={reportId} compType={compType} />
        <CompFilterButtonGrossLivingArea
          reportId={reportId}
          compType={compType}
        />
        <CompFilterButtonYearBuilt reportId={reportId} compType={compType} />
        {isRental ? (
          <>
            <CompFilterButtonPropertyStatusRental
              reportId={reportId}
              compType={compType}
            />
            <CompFilterButtonListingDateRental
              reportId={reportId}
              compType={compType}
            />
            <CompFilterButtonCloseDateRental
              reportId={reportId}
              compType={compType}
            />
            <CompFilterButtonListPriceRental
              reportId={reportId}
              compType={compType}
            />
            <CompFilterButtonClosePriceRental
              reportId={reportId}
              compType={compType}
            />
            <CompFilterButtonCurrentRentalValue
              reportId={reportId}
              compType={compType}
            />
          </>
        ) : (
          <>
            <CompFilterButtonPropertyStatus
              reportId={reportId}
              compType={compType}
            />
            <CompFilterButtonListDate reportId={reportId} compType={compType} />
            <CompFilterButtonSaleDate reportId={reportId} compType={compType} />
            <CompFilterButtonListPrice
              reportId={reportId}
              compType={compType}
            />
            <CompFilterButtonSalePrice
              reportId={reportId}
              compType={compType}
            />
          </>
        )}

        <CompFilterButtonLotSize reportId={reportId} compType={compType} />
        <CompFilterButtonSimilarity reportId={reportId} compType={compType} />
        <CompFilterButtonPool reportId={reportId} compType={compType} />
        <CompFilterButtonGarage reportId={reportId} compType={compType} />
        <CompFilterButtonStories reportId={reportId} compType={compType} />
        <CompFilterButtonBasement reportId={reportId} compType={compType} />
      </div>
      <div className={styles.ButtonCell}>
        <MoreFiltersButton reportId={reportId} compType={compType} />
      </div>
      {isEditable && (
        <div className={styles.ButtonCell}>
          <CompFiltersClearAllButton reportId={reportId} compType={compType} />
        </div>
      )}
      {isEditable && (
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[
            isRental
              ? ReportFeatures.RentalCompsSavedFilterSets
              : ReportFeatures.CompsSavedFilterSets,
          ]}
        >
          <div className={styles.ButtonCell}>
            <SavedCompFilterSetFormLauncher
              reportId={reportId}
              compType={compType}
            />
          </div>
        </ReportFeaturesSupported>
      )}

      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={[ReportFeatures.ReportDataRefresh]}
      >
        <RefreshReport
          reportId={reportId}
          trigger={
            <TextButton
              dataHcName={`${dataHcName}-refresh`}
              className={classNames({ [styles.disabledButton]: !isEditable })}
            >
              Refresh Data
            </TextButton>
          }
        />
      </ReportFeaturesSupported>
    </div>
  );
};
