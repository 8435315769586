import { FilterRangeType, RANGE_TYPES } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { CompsFiltersKeys, ReportId } from '@hcs/types';

import { undefinedNullOrEmptyString } from '../utils';

import { useCompsFiltersDocument } from './useCompsFiltersDocument';
import { useDocumentPatch } from './useDocumentPatch';

export const usePatchCompNumberRangeFilter = (
  reportId: ReportId,
  compType: CompTypes
) => {
  const filterDocQuery = useCompsFiltersDocument(reportId, compType);
  const documentPatchMutation = useDocumentPatch(reportId);
  const document = filterDocQuery.data;

  return {
    ...documentPatchMutation,
    mutate: document
      ? {
          absoluteValue: (field: CompsFiltersKeys, value: FilterRangeType) => {
            const filterValue = document?.data.filters?.[field];
            if (
              undefinedNullOrEmptyString(value[RANGE_TYPES.MIN]) &&
              undefinedNullOrEmptyString(value[RANGE_TYPES.MAX]) // If we have empty value remove filter
            ) {
              if (!filterValue) {
                return;
              }
              documentPatchMutation.mutate({
                reportId,
                document,
                operations: [
                  {
                    op: 'remove',
                    path: `/data/filters/${field}/absoluteValue`,
                  },
                ],
              });
            } else {
              const min = undefinedNullOrEmptyString(value[RANGE_TYPES.MIN])
                ? null
                : Number(value[RANGE_TYPES.MIN]);
              const max = undefinedNullOrEmptyString(value[RANGE_TYPES.MAX])
                ? null
                : Number(value[RANGE_TYPES.MAX]);
              documentPatchMutation.mutate({
                reportId,
                document,
                operations: [
                  {
                    op: 'add',
                    path: `/data/filters/${field}/absoluteValue`,
                    value: [min, max],
                  },
                  {
                    op: 'add',
                    path: `/data/filters/${field}/field`,
                    value: field,
                  },
                ],
              });
            }
          },
        }
      : undefined,
  };
};
