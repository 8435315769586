import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../../hooks';
import {
  convertNumArrayToFilterRange,
  formatOrUndefined,
  formatSqrFt,
  getFilterLabel,
} from '../../../utils';
import { CompFilterLotSize } from '../../CompFiltersDeprecated/CompFilterLotSize';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const COMP_FIELD = PropertyStateFields.lotSize;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[COMP_FIELD];
const dataHcName = 'comp-filter-button-site-area';

export const CompFilterButtonLotSize = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }

  const filterValue = filterDocument.data.filters?.[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={
        active
          ? `${getFilterLabel({
              field: COMP_FIELD,
              value: formatOrUndefined(
                formatSqrFt,
                convertNumArrayToFilterRange(filterValue?.absoluteValue)
              ),
            })} `
          : fieldConfig.labelShort
      }
      className={className}
      compField={COMP_FIELD}
      content={<CompFilterLotSize reportId={reportId} compType={compType} />}
      active={active}
    />
  );
};
