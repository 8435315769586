import { useCallback, useEffect, useState } from 'react';

interface Props {
  // Set initial active state and let the component handle it internally after mount
  activeInitial?: boolean;
  // Allow parent to control active state
  active?: boolean;
  // Modify browser history to close with back button
  // pushState?: boolean;
}
export const useActiveState = (options: Props | undefined = {}) => {
  const { active, activeInitial = false } = options;
  const [activeInternal, setActiveInternal] = useState<boolean>(
    activeInitial === undefined
      ? active === undefined
        ? false
        : active
      : activeInitial
  );
  useEffect(() => {
    if (active !== undefined && active !== activeInternal) {
      setActiveInternal(active);
    }
  }, [active, activeInternal]);
  const setActiveState = useCallback(
    (a: boolean) => {
      if (active === undefined) {
        setActiveInternal(a);
      }
    },
    [active]
  );
  const handleOpen = useCallback(() => {
    setActiveState(true);
  }, [setActiveState]);
  const handleClose = useCallback(() => {
    setActiveState(false);
  }, [setActiveState]);
  const handleToggle = useCallback(() => {
    setActiveState(!activeInternal);
  }, [setActiveState, activeInternal]);
  return {
    active: activeInternal,
    setActiveState,
    handleOpen,
    handleClose,
    handleToggle,
  };
};
