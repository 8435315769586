import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { MutateTagsArg } from '@hcs/types';

import { ReportApi } from '../api';
import { QUERY_KEY_TAGS } from '../hooks/useTags';

export const useDeleteTags = (
  options?: Omit<
    UseMutationOptions<string[] | null, unknown, MutateTagsArg>,
    'mutationFn'
  >
) => {
  const queryClient = useQueryClient();
  const newOptions = {
    ...options,
    onMutate: async (tagsInputs: MutateTagsArg) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([
        QUERY_KEY_TAGS,
        tagsInputs.reportId,
        tagsInputs.documentId,
      ]);

      // Snapshot the previous value
      const previousTags = queryClient.getQueryData<string[]>([
        QUERY_KEY_TAGS,
        tagsInputs.reportId,
        tagsInputs.documentId,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData<string[]>(
        [QUERY_KEY_TAGS, tagsInputs.reportId, tagsInputs.documentId],
        (old?: string[]) =>
          old
            ? old.filter((aTag) => !tagsInputs.tags.includes(aTag))
            : [...tagsInputs.tags]
      );

      // Return a context object with the snapshotted value
      return { previousTags };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (
      err: AxiosError<{ message: string }>,
      tagsArg: MutateTagsArg,
      context?: { previousTags: unknown }
    ) => {
      console.error(err);
      if (context?.previousTags && Array.isArray(context.previousTags)) {
        queryClient.setQueryData<string[]>(
          [QUERY_KEY_TAGS, tagsArg],
          context.previousTags
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEY_TAGS]);
    },
  };
  return useMutation(
    async (addTagsArgs: MutateTagsArg) => ReportApi.deleteTags(addTagsArgs),
    newOptions
  );
};
