import React, { useState } from 'react';
import { unformat } from 'accounting';
import classNames from 'classnames';

import { useComponentDidUpdate, usePrevious } from '@hcs/hooks';
import {
  capitalizeFirstLetter,
  formatMoney,
  formatNumber,
  isNumberOrDecimalPoint,
  isPositive,
} from '@hcs/utils';

import { Input } from '../../inputs/Input';

import {
  FilterRangeType,
  FORMAT_TYPES,
  RANGE_TYPES,
} from './FilterRange.types';

import styles from './FilterRange.module.css';

const undefinedNullOrEmptyString = (value?: string | null): boolean => {
  return value === undefined || value === null || value.length === 0;
};

const formatDecimalNumber = (value: string | null) => {
  return `${Number(value)?.toFixed(2)}`;
};

export const formatMoneyStr = (
  value: string,
  options?: accounting.NumberSettings
): string => {
  return formatMoney(Number(value), options);
};

const formatOrUndefined = (
  formatter: (value: string, options?: accounting.NumberSettings) => string,
  value: FilterRangeType,
  options?: accounting.NumberSettings
): FilterRangeType => {
  return {
    [RANGE_TYPES.MIN]: undefinedNullOrEmptyString(value?.[RANGE_TYPES.MIN])
      ? undefined
      : formatter(value?.[RANGE_TYPES.MIN] || '', options),
    [RANGE_TYPES.MAX]: undefinedNullOrEmptyString(value?.[RANGE_TYPES.MAX])
      ? undefined
      : formatter(value?.[RANGE_TYPES.MAX] || '', options),
  };
};

const formatValue = (
  value: FilterRangeType,
  decimal: boolean,
  formatType: FORMAT_TYPES
): FilterRangeType => {
  const cleanValue: FilterRangeType = formatOrUndefined(
    formatStripFormatting,
    value
  );
  const options = decimal ? { precision: 2 } : { precision: 0 };
  switch (formatType) {
    case FORMAT_TYPES.COMMA:
      return formatOrUndefined(
        //Simple wrapper for formatNumber to fulfill formatter contract
        (value?: string, options?: accounting.NumberSettings): string => {
          return formatNumber(Number(value), options);
        },
        cleanValue,
        options
      );
    case FORMAT_TYPES.DECIMAL_NUMBER:
      return formatOrUndefined(formatDecimalNumber, cleanValue);
    case FORMAT_TYPES.MONEY:
      return formatOrUndefined(formatMoneyStr, cleanValue, options);
    default: {
      // number type return as is
      return value;
    }
  }
};

export const validateRange = (value: FilterRangeType): string | undefined => {
  const types: RANGE_TYPES[] = [RANGE_TYPES.MAX, RANGE_TYPES.MIN];
  for (const type in types) {
    if (value[type as RANGE_TYPES] && isNaN(Number(value[type as RANGE_TYPES])))
      return `${capitalizeFirstLetter(type as string)} must be a number`;
    if (
      value[type as RANGE_TYPES] &&
      !isPositive(Number(value[type as RANGE_TYPES]))
    )
      return `${capitalizeFirstLetter(type as string)} must be positive`;
  }
  if (Number(value[RANGE_TYPES.MIN]) > Number(value[RANGE_TYPES.MAX])) {
    return 'There is a problem with this filter! The minimum value is greater than the maximum value.';
  }

  return undefined;
};

export interface FilterRangeProps {
  initialValue?: FilterRangeType;
  subjectDisplay?: FilterRangeType;
  className?: string;
  decimal?: boolean;
  maxVal?: number;
  minVal?: number;
  dataHcName: string;
  formatType?: FORMAT_TYPES;
  onBlur?: (value: FilterRangeType) => void;
  extraValidators?: ((v: FilterRangeType) => string | undefined)[];
  disabled?: boolean;
}

const formatStripFormatting = (value: string) =>
  value.replace(/(sqft\.|m|k|\$|,)/gi, '').trim();

const defaultValidators = [validateRange];

export const FilterRange = ({
  initialValue = { [RANGE_TYPES.MIN]: undefined, [RANGE_TYPES.MAX]: undefined },
  onBlur,
  className,
  maxVal,
  minVal,
  decimal = false,
  formatType = FORMAT_TYPES.NUMBER,
  extraValidators = [],
  subjectDisplay,
  dataHcName,
  disabled,
}: FilterRangeProps) => {
  const formattedValue = formatValue(initialValue, decimal, formatType);
  const [value, setValue] = useState<FilterRangeType>(formattedValue);
  const prevInitialValue = usePrevious<FilterRangeType>(formattedValue);

  const [error, setError] = useState<string>('');
  const validators = [...defaultValidators, ...extraValidators];

  useComponentDidUpdate(() => {
    // If we pass in a new initial value then update
    if (
      JSON.stringify(value) !== JSON.stringify(initialValue) &&
      JSON.stringify(value) === JSON.stringify(prevInitialValue) // make sure parent has been updated before setting new value
    ) {
      setValue(formatValue(initialValue, decimal, formatType));
    }
  }, [initialValue]);

  const checkValidity = (): boolean => {
    const isValid = validators.every((validator) => {
      const validationResult = validator(
        formatOrUndefined(formatStripFormatting, value)
      );
      if (validationResult) {
        setError(validationResult);
      }
      return !validationResult;
    });
    if (isValid) setError('');
    return isValid;
  };

  const handleChange = (newValue: FilterRangeType) => {
    // If the last character is a comma it must have been typed
    // so we reject it, we only want to have comma's in the formatted value
    if (
      (RANGE_TYPES.MIN in newValue &&
        newValue[RANGE_TYPES.MIN]?.slice(-1) === ',') ||
      newValue[RANGE_TYPES.MAX]?.slice(-1) === ','
    ) {
      return;
    }

    const currentVal = { ...value, ...newValue };
    const primVal =
      RANGE_TYPES.MIN in newValue
        ? newValue[RANGE_TYPES.MIN]
        : newValue[RANGE_TYPES.MAX];

    if (
      primVal === undefined ||
      primVal.length === 0 ||
      // if decimal numbers are allowed allow 1 decimal point else 0
      (primVal &&
        isNumberOrDecimalPoint(formatStripFormatting(primVal), decimal))
    ) {
      setValue(currentVal);
    }
  };

  const handleBlur = () => {
    let newVal = formatValue(value, decimal, formatType);
    if (maxVal) {
      if (newVal.max && Number(unformat(newVal.max)) > maxVal) {
        newVal.max = maxVal.toString();
      }
      if (newVal.min && Number(unformat(newVal.min)) > maxVal) {
        newVal.min = maxVal.toString();
      }
      newVal = formatValue(newVal, decimal, formatType);
    }
    if (minVal) {
      if (newVal.max && Number(unformat(newVal.max)) < minVal) {
        newVal.max = minVal.toString();
      }
      if (newVal.min && Number(unformat(newVal.min)) < minVal) {
        newVal.min = minVal.toString();
      }
      newVal = formatValue(newVal, decimal, formatType);
    }

    setValue(newVal);
    const isValid = checkValidity();
    if (onBlur && isValid) {
      onBlur(formatOrUndefined(formatStripFormatting, newVal));
    }
  };

  const setToNum = (type: RANGE_TYPES) => {
    const cleanValue = formatOrUndefined(formatStripFormatting, value);
    if (!undefinedNullOrEmptyString(value?.[type])) {
      const newVal = { ...cleanValue, [type]: `${Number(cleanValue?.[type])}` };
      setValue(newVal);
    }
  };

  return (
    <>
      <div className={classNames(styles.inputContainer, className)}>
        <div className={styles.InputCell}>
          <Input
            theme={{
              InputElement: styles.Input,
            }}
            disabled={disabled}
            dataHcName={`${dataHcName}-min-input`}
            placeholder={'Min'}
            value={value?.[RANGE_TYPES.MIN] || ''}
            onFocus={() => {
              checkValidity();
              setValue({
                [RANGE_TYPES.MIN]: formatStripFormatting(
                  value?.[RANGE_TYPES.MIN] || ''
                ),
                [RANGE_TYPES.MAX]: value?.[RANGE_TYPES.MAX],
              });

              if (formatType === FORMAT_TYPES.DECIMAL_NUMBER) {
                setToNum(RANGE_TYPES.MIN);
              }
            }}
            onChange={(newVal: string) =>
              handleChange({
                [RANGE_TYPES.MIN]: newVal,
              })
            }
            onBlur={handleBlur}
          />
          {subjectDisplay && (
            <div
              data-hc-name={`${dataHcName}-relative-value`}
              className={styles.RelativeValue}
            >
              {subjectDisplay[RANGE_TYPES.MIN]}
            </div>
          )}
        </div>
        <span className={styles.RangeDelimiter}>-</span>
        <div className={styles.InputCell}>
          <Input
            theme={{
              InputElement: styles.Input,
            }}
            disabled={disabled}
            dataHcName={`${dataHcName}-max-output`}
            placeholder={'Max'}
            value={
              value[RANGE_TYPES.MAX] !== undefined
                ? `${value[RANGE_TYPES.MAX]}`
                : ''
            }
            onChange={(newVal: string) =>
              handleChange({
                [RANGE_TYPES.MAX]: newVal,
              })
            }
            onFocus={() => {
              checkValidity();
              setValue({
                [RANGE_TYPES.MIN]: value?.[RANGE_TYPES.MIN],
                [RANGE_TYPES.MAX]: formatStripFormatting(
                  value?.[RANGE_TYPES.MAX] || ''
                ),
              });
              if (formatType === FORMAT_TYPES.DECIMAL_NUMBER) {
                setToNum(RANGE_TYPES.MAX);
              }
            }}
            onBlur={handleBlur}
          />
          {subjectDisplay && (
            <div className={styles.RelativeValue}>
              {subjectDisplay[RANGE_TYPES.MAX]}
            </div>
          )}
        </div>
      </div>
      {error && (
        <div
          data-hc-name={`${dataHcName}-error`}
          className={styles.rangeInputError}
        >
          {error}
        </div>
      )}
    </>
  );
};
