import { gql } from 'graphql-request';

// Hcri (HouseCanary Rental Index) Fragments
export const FRAGMENT_MSA_HCRI_CORE = gql`
  fragment MsaHcriCore on MsaHcri {
    grossYieldMedian
  }
`;

export const FRAGMENT_ZIP_HCRI_CORE = gql`
  fragment ZipHcriCore on ZipHcri {
    grossYieldMedian
  }
`;
