import {
  OrderItemsTableInOrderOptions,
  OrderItemsTableNeedReviewOptions,
  OrderItemsTableRemovedOptions,
  PropertyTypeEnum,
} from '@hcs/types';

export const DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL = 'order-items-table-cell';
export const ORDER_ITEMS_PER_PAGE = 20;
export const ORDER_MANAGER_PROPERTY_TYPE_TO_CERBERUS = {
  singlefamily: PropertyTypeEnum.Sfr,
  condo: PropertyTypeEnum.Condo,
  multifamily: PropertyTypeEnum.MultiFamily,
  mobile: PropertyTypeEnum.Manufactured,
  vacantlot: PropertyTypeEnum.Land,
  townhouse: PropertyTypeEnum.Townhouse,
};
export const IN_ORDER_TABLE_OPTIONS = [
  OrderItemsTableInOrderOptions.CustomerItemId,
  OrderItemsTableInOrderOptions.PropertyType,
  OrderItemsTableInOrderOptions.Address,
  OrderItemsTableInOrderOptions.CreatedAt,
  OrderItemsTableInOrderOptions.Status,
  OrderItemsTableInOrderOptions.InOrderActions,
];

export const NEED_REVIEW_TABLE_OPTIONS = [
  OrderItemsTableNeedReviewOptions.CustomerItemId,
  OrderItemsTableNeedReviewOptions.PropertyType,
  OrderItemsTableNeedReviewOptions.Address,
  OrderItemsTableNeedReviewOptions.City,
  OrderItemsTableNeedReviewOptions.State,
  OrderItemsTableNeedReviewOptions.ZipCode,
  OrderItemsTableNeedReviewOptions.Issues,
  OrderItemsTableNeedReviewOptions.NeedReviewActions,
];

export const REMOVED_TABLE_OPTIONS = [
  OrderItemsTableRemovedOptions.CustomerItemId,
  OrderItemsTableRemovedOptions.PropertyType,
  OrderItemsTableRemovedOptions.Address,
  OrderItemsTableRemovedOptions.City,
  OrderItemsTableRemovedOptions.State,
  OrderItemsTableRemovedOptions.ZipCode,
  OrderItemsTableRemovedOptions.Issues,
];

export const ORDER_ITEMS_SEARCH_RESULTS_LIMIT = 5;
