import React from 'react';
import classNames from 'classnames';

import { useMls } from '../../hooks/useMls';

import styles from './MlsAttribution.module.css';

interface Props {
  mlsId: number;
  className?: string;
}
const dataHcName = 'mls-disclaimer';
export const MlsAttribution = ({ mlsId, className }: Props) => {
  const { isSuccess, data: mls } = useMls(mlsId);
  if (!isSuccess || !mls) return null;
  const { lastRefreshed } = mls;
  const { logo, copyright, disclaimer } = mls.regulations || {};
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.MlsAttribution, className)}
    >
      {logo && <img src={logo} alt="MLS logo" className={styles.Logo} />}
      {disclaimer && <p>{disclaimer}</p>}
      {copyright && <p>{copyright}</p>}
      {lastRefreshed && (
        <p>Updated on {new Date(lastRefreshed).toLocaleString()}</p>
      )}
    </div>
  );
};
