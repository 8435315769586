import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  AllMsaDetailsDocument,
  AllMsaDetailsQuery,
  AllMsaDetailsQueryVariables,
} from '@hcs/types';

import { allMsaDetailsQueryToCerberusStatsMsa } from '../utils/cerberus-stats.utils';

const QUERY_KEY_CERBERUS_STATS_ALL_MSAS = 'cerberus-stats-all-msas';

export const useCerberusStatsAllMsas = () => {
  return useQuery([QUERY_KEY_CERBERUS_STATS_ALL_MSAS], async () => {
    const response = await CerberusApi.fetchQuery<
      AllMsaDetailsQuery,
      AllMsaDetailsQueryVariables
    >(AllMsaDetailsDocument, {});
    return allMsaDetailsQueryToCerberusStatsMsa(response);
  });
};
