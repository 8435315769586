import React from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { DistanceSelect } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { DrawType } from '@hcs/types';
import { CompFields, CompTypes } from '@hcs/types';
import {
  CompsFiltersPaths,
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportId,
} from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

import { FilterCard } from '../../components-deprecated';
import { useReportPermissions } from '../../hooks';
import {
  useCompsFiltersDocument,
  useDocumentPatch,
  useSubjectDocument,
} from '../../hooks';
import {
  COMP_FILTER_DISTANCE_FEATURES_RENTAL,
  COMP_FILTER_DISTANCE_FEATURES_SALE,
} from '../CompFilters/CompFiltersControls';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import { CompFilterClearButton } from './CompFilterClearButton';

import styles from './CompFilterDistance.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  navigateToCompSelectionDraw: NavigateToCompSelectionDrawFn;
  className?: string;
  disabled?: boolean;
}

const FIELD = CompFields.distance;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const dataHcName = 'comp-filter-distance';
export const CompFilterDistance = ({
  reportId,
  disabled = false,
  compType,
  navigateToCompSelectionDraw,
  className,
}: Props) => {
  const isRental = compType === CompTypes.Rental;
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!filterDocument || !subjectDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const geoJsonFeature =
    filterDocument.data.filters?.[CompFields.distance]?.absoluteValue || null;
  const distanceRadiusMiles =
    filterDocument.data.filters?.[CompFields.distance]?.relativeValue || null;
  const distanceSelectValue = distanceRadiusMiles
    ? distanceRadiusMiles
    : // If no radius but there is a geoJson pass undefined so select option is checked
    geoJsonFeature
    ? undefined
    : null;

  return (
    <FilterCard
      label="Distance"
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={FIELD}
        />
      }
    >
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={
          isRental
            ? [ReportFeatures.RentalCompsFilterDistance]
            : [ReportFeatures.CompsFilterDistance]
        }
      >
        <DistanceSelect
          dataHcName={`${dataHcName}-select`}
          disabled={!reportPermissions?.isEditable}
          value={distanceSelectValue}
          className={classNames(className, styles.DistanceSelect)}
          onChange={(radius) => {
            if (radius) {
              documentPatchMutation.mutate({
                reportId,
                document: filterDocument,
                operations: [
                  {
                    op: 'add',
                    path: PATH_REL,
                    value: radius,
                  },
                  {
                    op: 'add',
                    path: PATH_FIELD,
                    value: CompFields.distance,
                  },
                ],
              });
            } else if (radius === null) {
              documentPatchMutation.mutate({
                reportId,
                document: filterDocument,
                operations: [
                  {
                    op: 'remove',
                    path: PATH_FILTER,
                  },
                ],
              });
            }
          }}
        />
      </ReportFeaturesSupported>
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={
          compType === CompTypes.Rental
            ? COMP_FILTER_DISTANCE_FEATURES_RENTAL
            : COMP_FILTER_DISTANCE_FEATURES_SALE
        }
        reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
      >
        <div className={styles.Or}>or</div>
      </ReportFeaturesSupported>
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={
          compType === CompTypes.Rental
            ? COMP_FILTER_DISTANCE_FEATURES_RENTAL
            : COMP_FILTER_DISTANCE_FEATURES_SALE
        }
      >
        <Button
          disabled={disabled}
          onClick={() => navigateToCompSelectionDraw(reportId, compType)}
          className={styles.Button}
          primary={false}
          dataHcName="distance-draw-button"
        >
          {geoJsonFeature &&
          'properties' in geoJsonFeature &&
          geoJsonFeature.properties?.drawType === ('custom' as DrawType)
            ? `Edit ${capitalizeFirstLetter(
                `${geoJsonFeature.properties.drawType}`
              )}
        ${capitalizeFirstLetter(`${geoJsonFeature.properties.shape}`)}`
            : 'Draw Custom Area'}
        </Button>
      </ReportFeaturesSupported>
    </FilterCard>
  );
};
