import { useQuery } from '@tanstack/react-query';

import { AuthApi } from '../api';

export const QUERY_KEY_SYSTEM_ROLES = 'QUERY_KEY_SYSTEM_ROLES';
export const useSystemRoles = () => {
  return useQuery([QUERY_KEY_SYSTEM_ROLES], async () => {
    return await AuthApi.fetchSystemRoles();
  });
};
