import React from 'react';
import { VictoryLine } from 'victory';

import { ChartDatum, ChartDatumTimeSeries } from '@hcs/types';

import { Chart, ChartProps } from '../Chart';

interface Props extends Omit<ChartProps, 'children'> {
  chartData?: ChartDatumTimeSeries[] | ChartDatum[] | null;
  isXDateStr?: boolean;
}
export const LineChart = ({
  chartData,
  isXDateStr = true,
  ...chartProps
}: Props) => {
  const xFormatter = isXDateStr
    ? (datum: ChartDatumTimeSeries) => new Date(datum.x)
    : (datum: ChartDatum) => datum.x;

  return (
    <Chart
      chart={{
        domainPadding: {
          y: 50,
        },
        scale: {
          x: 'time',
          y: 'linear',
        },
      }}
      {...chartProps}
    >
      {chartData && (
        <VictoryLine
          data={chartData}
          // @ts-expect-error: Incorrect type from library
          x={xFormatter}
          y={(datum: ChartDatumTimeSeries) => datum.y}
          interpolation="natural"
          style={{
            data: {
              stroke: '#6ba0ff',
              strokeWidth: 2,
            },
          }}
        />
      )}
    </Chart>
  );
};
