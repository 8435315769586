import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { useUserData } from '@hcs/org-admin';
import { OrderItem } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { useOrder } from '../../hooks/useOrder';
import { DataItem } from '../DataItem';

import styles from './Metadata.module.css';

interface Props {
  dataHcName: string;
  orderId: number;
  orderItem: OrderItem;
}

export const Metadata = ({ dataHcName, orderId, orderItem }: Props) => {
  const { data: order, isInitialLoading: orderIsLoading } = useOrder(orderId);
  const { data: userData } = useUserData(order?.ownerId);
  if (!order || orderIsLoading) {
    return (
      <Skeleton
        type="text"
        width={'700px'}
        height={'100px'}
        marginBottom={'10px'}
        dataHcName={`${dataHcName}-skeleton`}
      />
    );
  }
  const selectedOrderTypeDescriptor = order?.orderTypeDescriptor;
  const {
    county,
    integrationKey,
    customerItemId,
    createdAt,
    inspectionCompletionDate,
    inspectionDate,
    propertyType,
  } = orderItem;

  const propertyTypeData = order?.orderTypeDescriptor.propertyTypes?.find(
    (ele) => ele.value === propertyType
  );
  const propertyTypeDataLabel = propertyTypeData?.label;
  const DEFAULT_METADATA = '-';

  const METADATA_ORDER_ITEM_DETAILS_CONFIG = [
    {
      dataHcName: 'housecanary-id-div',
      data: integrationKey,
      label: 'HouseCanary ID',
    },
    {
      dataHcName: 'customer-id-div',
      data: customerItemId,
      label: 'Client File ID',
    },
    {
      dataHcName: 'property-type-div',
      data: propertyTypeDataLabel ?? DEFAULT_METADATA,
      label: 'Property Type',
    },
    {
      dataHcName: 'county-div',
      data: county ?? DEFAULT_METADATA,
      label: 'County',
    },
    {
      dataHcName: 'item-created-div',
      data: createdAt ? formatDateShort(createdAt) : DEFAULT_METADATA,
      label: 'Created',
    },
  ];

  const allMetaDataConfig = [...METADATA_ORDER_ITEM_DETAILS_CONFIG];

  if (selectedOrderTypeDescriptor?.needsInspection) {
    const newObj = {
      dataHcName: 'inspection-date-div',
      data: inspectionCompletionDate
        ? formatDateShort(inspectionCompletionDate)
        : inspectionDate
        ? formatDateShort(inspectionDate)
        : DEFAULT_METADATA,
      label: 'Inspection Date',
    };
    allMetaDataConfig.push(newObj);
  }

  if (userData?.email) {
    const newObj = {
      dataHcName: 'ordered-by-div',
      data: userData?.email ? userData.email : DEFAULT_METADATA,
      label: 'Ordered By',
    };
    allMetaDataConfig.push(newObj);
  }

  return (
    <div className={styles.MetaData} data-hc-name={dataHcName}>
      <div className={styles.Title}>Metadata</div>
      {allMetaDataConfig.map((config) => {
        return (
          <DataItem
            key={config.dataHcName}
            dataHcName={config.dataHcName}
            data={config.data}
            label={config.label}
          />
        );
      })}
    </div>
  );
};
