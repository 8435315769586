import snakecaseKeys from 'snakecase-keys';

import { AxiosClient } from '@hcs/http-clients';
import { EngagementEventPayload } from '@hcs/types';
import { BEACON_URL } from '@hcs/urls';

export const BeaconApi = {
  trackEvent: async (EngagementEventPayload: EngagementEventPayload) => {
    await AxiosClient.post(
      `${BEACON_URL}/v1/t`,
      snakecaseKeys(EngagementEventPayload),
      {
        headers: {
          'Content-type': 'application/json',
        },
        withCredentials: true,
      }
    );
  },
};
