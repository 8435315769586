import React from 'react';

import { CerberusValueType } from '@hcs/types';
import { formatAvmQuality, formatMoney, formatMoneyPerMonth } from '@hcs/utils';

import { HighConfidenceBadgeIcon } from '../../../../foundations';
import { InfoTooltip } from '../../../display/popovers/InfoTooltip';
import { Tooltip, TooltipProps } from '../../../display/popovers/Tooltip';

import styles from './AvmQualityBadge.module.css';

export interface AvmQualityBadgeProps extends Pick<TooltipProps, 'position'> {
  dataHcName: string;
  avm: CerberusValueType;
  showRentalValue: boolean;
  // Whether to show the actual quality description or only the range
  // defaults to true
  showQuality?: boolean;
  darkInfoIcon?: boolean;
}
export const AvmQualityBadge = ({
  dataHcName,
  avm,
  showRentalValue,
  position,
  showQuality = true,
  darkInfoIcon,
}: AvmQualityBadgeProps) => {
  const valueFormatter = showRentalValue ? formatMoneyPerMonth : formatMoney;
  const rentalModifier = showRentalValue ? 'rental ' : '';
  const label =
    avm.quality && showQuality
      ? `${formatAvmQuality(avm.quality)} Confidence`
      : undefined;
  const description = `
  We ${
    showRentalValue ? 'estimate rent for' : 'value'
  } this property at ${valueFormatter(avm.value)}${showRentalValue ? '' : '.'}
  The ${
    showRentalValue ? 'rental' : 'sales'
  } price will likely fall between ${valueFormatter(
    avm.valueLower
  )} and ${valueFormatter(avm.valueUpper)}${showRentalValue ? '' : '.'}
  This is HouseCanary’s estimated market ${rentalModifier}value for this home.
  It is not a formal appraisal.
  This estimate is based on our market knowledge, and it should be used as a starting point to determine a home’s value.
`;
  return (
    <Tooltip
      dataHcName={`${dataHcName}-high-confidence-tooltip`}
      position={position}
      isLargeTooltip
      label={label}
      description={description}
      trigger={
        showQuality && avm.quality === 'HIGH' ? (
          <HighConfidenceBadgeIcon
            className={styles.HighConfidence}
            height="78px"
          />
        ) : (
          <InfoTooltip
            dataHcName={`${dataHcName}-info-tooltip`}
            theme={{
              Trigger: styles.TooltipTrigger,
            }}
            position={position}
            isLargeTooltip
            label={label}
            description={description}
            color={darkInfoIcon ? 'neutral-dark-10' : 'neutral-light-10'}
            size="large"
          />
        )
      }
    />
  );
};
