import {
  DocumentRoles,
  ReportId,
  SubjectDataSourcesDocument,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useSubjectDataSourcesDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<SubjectDataSourcesDocument>(
    reportId,
    DocumentRoles.SubjectDataSources
  );
};
