import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM } from '../constants';

export const useNavigateToForgotPassword = (): VoidFunction => {
  const navigate = useNavigate();
  return useCallback(() => {
    navigate(VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.FORGOT_PASSWORD, {});
  }, [navigate]);
};
