import {
  APP_CONFIG_AEXP,
  APP_CONFIG_AGILE_SUITE,
  APP_CONFIG_CANARYAI,
  APP_CONFIG_DEXP,
  APP_CONFIG_MARKET_INSIGHTS,
  APP_CONFIG_ORG_ADMIN,
  APP_CONFIG_PEXP,
  APP_CONFIG_PLATFORM,
  APP_CONFIG_PORTFOLIO,
  APP_CONFIG_USER_ADMIN,
} from '@hcs/hc-products';
import { AppConfig } from '@hcs/types';

export const SOLUTIONS_PLATFORM_APPS: AppConfig[] = [
  APP_CONFIG_DEXP,
  APP_CONFIG_PORTFOLIO,
  APP_CONFIG_PEXP,
  APP_CONFIG_AEXP,
  APP_CONFIG_MARKET_INSIGHTS,
  APP_CONFIG_AGILE_SUITE,
  APP_CONFIG_PLATFORM,
  APP_CONFIG_CANARYAI,
  APP_CONFIG_ORG_ADMIN,
  APP_CONFIG_USER_ADMIN,
];
