import React from 'react';
import classNames from 'classnames';

import { SVGProps } from '@hcs/hooks';

import { CheckCircleIcon } from '../../../../svgs/color-icons';

import styles from './AnimatedCheck.module.css';

export const AnimatedCheck = ({
  dataHcName,
  className,
  ...props
}: SVGProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.AnimatedCheck, className)}
    >
      <CheckCircleIcon dataHcName={`${dataHcName}-icon`} {...props} />
    </div>
  );
};
