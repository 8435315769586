import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextButton } from '@hcs/design-system';
import { Dialog, DialogAction } from '@hcs/design-system';
import { BuyBoxFormData, MeaningfulEventTypes } from '@hcs/types';

import { BuyBoxSaveUnexpectedError } from '../../components/BuyBoxSaveUnexpectedError';
import { useBuyBoxCreate } from '../../hooks/useBuyBoxCreate';
import { useTemplate } from '../../hooks/useTemplate';
import {
  buyBoxValidationSchema,
  DEFAULT_FORM_VALUES,
  templateToFormDefaultValues,
} from '../../utils';
import {
  BathsRange,
  BedsRange,
  BuyBoxZipMultiSelectField,
  ExcludeCheckboxesInput,
  GarageSpacesRange,
  GlaRange,
  HistoricalDetectionToggle,
  ListingPriceRange,
  LotSizeRange,
  MsaAutoComplete,
  NameInput,
  NumStoriesRange,
  PropertyTypeMultiSelect,
  TemplateToggle,
  YearBuiltRange,
} from '../BuyBoxInputs';
import { BuyBoxMatchCount } from '../BuyBoxMatchCount/BuyBoxMatchCount';

import styles from './CreateBuyBoxDialog.module.css';

const getDefaultFormValues = ({
  hasTemplate,
  msaId,
}: {
  hasTemplate: boolean;
  msaId?: string;
}): DefaultValues<BuyBoxFormData> => {
  return {
    ...DEFAULT_FORM_VALUES,
    msaId,
    historicalDetection: true,
    shouldSaveAsTemplate: !hasTemplate,
  };
};

interface CreateBuyBoxDialogProps {
  active: boolean;
  msaId?: string;
  onClose: (savedId?: number) => void;
}
const dataHcName = 'create-buy-box-dialog';
export const CreateBuyBoxDialog = (props: CreateBuyBoxDialogProps) => {
  const { active, msaId, onClose } = props;

  const {
    mutate: createBuyBox,
    isLoading,
    isSuccess,
    isError,
    data: newBuyBox,
  } = useBuyBoxCreate();
  const template = useTemplate();
  const [errorCleared, setErrorCleared] = useState(false);

  const methods = useForm<BuyBoxFormData>({
    mode: 'onBlur',
    resolver: yupResolver(buyBoxValidationSchema),
    defaultValues: getDefaultFormValues({
      hasTemplate: template !== undefined,
      msaId,
    }),
  });
  const { reset, getValues } = methods;

  const clearFields = useCallback(() => {
    // reset form with default values object
    reset(getDefaultFormValues({ hasTemplate: template !== undefined }));
  }, [reset, template]);

  const handleClose = useCallback(
    (savedId?: number) => {
      setErrorCleared(true);
      clearFields();
      onClose(savedId);
    },
    [clearFields, onClose]
  );

  // update default values after template loads (if there is one)
  useEffect(() => {
    if (template) {
      reset({
        ...getValues(),
        ...templateToFormDefaultValues(template),
      });
    }
  }, [template, reset, getValues]);

  useEffect(() => {
    if (isSuccess) {
      handleClose(newBuyBox?.id);
    }
  }, [isSuccess, handleClose, newBuyBox?.id]);

  useEffect(() => {
    if (isError) {
      setErrorCleared(false);
    }
  }, [isError]);

  const onSubmit: SubmitHandler<BuyBoxFormData> = (data) => {
    createBuyBox(data);
  };

  // show error dialog if there is a back-end save error AND it's not cleared on front-end
  const showErrorDialog = isError && !errorCleared;

  const actions: DialogAction[] = !showErrorDialog
    ? [
        {
          label: 'Save',
          onClick: () => methods.handleSubmit(onSubmit)(),
          dataHcName: `${dataHcName}-save-button`,
          dataHcEventType: MeaningfulEventTypes.Goal,
          dataHcEventName: 'Create Buy Box',
          disabled: isLoading || !methods.formState.isValid,
        },
      ]
    : [
        {
          label: 'Back',
          onClick: () => setErrorCleared(true),
          dataHcName: `${dataHcName}-back-button`,
          disabled: false,
        },
      ];
  const content = !showErrorDialog ? (
    <div data-hc-name={dataHcName} className={styles.CreateBuyBoxForm}>
      {template && (
        <div>
          <div
            data-hc-name={`${dataHcName}-template-filled-message`}
            className={styles.PreFilledMessage}
          >
            We have pre-filled the form with your default buy box parameters
          </div>
          <div className={styles.ClearFieldsButtonContainer}>
            <TextButton
              className={styles.ClearFieldsButton}
              dataHcName={`${dataHcName}-clear-fields-button`}
              label="Clear Fields"
              onClick={clearFields}
            />
          </div>
        </div>
      )}
      <FormProvider {...methods}>
        <form>
          <NameInput required />
          <MsaAutoComplete required />
          <ListingPriceRange required />
          <BedsRange />
          <BathsRange />
          <GlaRange />
          <LotSizeRange />
          <YearBuiltRange />
          <NumStoriesRange />
          <GarageSpacesRange />
          <PropertyTypeMultiSelect required />
          <ExcludeCheckboxesInput />
          <BuyBoxZipMultiSelectField required config={{ source: 'msa' }} />
          <HistoricalDetectionToggle />
          <TemplateToggle updateMode={template !== undefined} />
        </form>
      </FormProvider>
    </div>
  ) : (
    <BuyBoxSaveUnexpectedError
      dataHcName={`${dataHcName}-save-unexpected-error`}
      className={styles.ErrorMsg}
    />
  );

  return (
    <Dialog
      dataHcName={dataHcName}
      type="small"
      active={active}
      title={
        !showErrorDialog
          ? 'Create New Buy Box'
          : "We're sorry, there's been an error."
      }
      subtitle={
        !showErrorDialog ? <BuyBoxMatchCount control={methods.control} /> : null
      }
      onClose={() => handleClose()}
      actions={actions}
      preventClickOutsideClose
      maxHeight={'calc(100vh - 160px)'}
    >
      {content}
    </Dialog>
  );
};
