import React, { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import { Input } from '@hcs/design-system';
import { AccountFields, InputProps } from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

import { ACCOUNT_FIELD_CONFIGS } from '../../constants';

import styles from './AccountFieldInput.module.css';

interface AccountInfoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value: InputHTMLAttributes<HTMLInputElement>['value'] | null;
  dataHcName?: string;
  name: AccountFields;
  onChange: InputProps['onChange'];
  error?: string;
  className?: string;
}
export const AccountFieldInput = forwardRef<HTMLInputElement, AccountInfoProps>(
  (
    {
      className,
      name,
      error,
      onChange,
      dataHcName = 'account-field-input',
      ...inputProps
    }: AccountInfoProps,
    ref
  ) => {
    const fieldConfig = ACCOUNT_FIELD_CONFIGS[name];
    return (
      <Input
        {...fieldConfig.inputProps}
        name={name}
        ref={ref}
        className={classNames(styles.AccountFieldInput, className)}
        dataHcName={`${dataHcName}-${name}`}
        placeholder={fieldConfig.label}
        onChange={onChange}
        error={
          typeof error === 'string' ? capitalizeFirstLetter(error) : undefined
        }
        {...inputProps}
        value={inputProps.value?.toString() || ''}
      />
    );
  }
);
