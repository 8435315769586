import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmOrder } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { DATA_HC_NAME_ORDERS_TABLE_CELL } from '../../../constants';

import { OrdersTableCellsDisplayProps } from '.';

const Display = ({
  order,
}: OrdersTableCellsDisplayProps): JSX.Element | null => {
  return (
    <div data-hc-name={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-created-at`}>
      {formatDateShort(order?.createdAt)}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Created</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({ order, ...tableCellProps }: { order: OmOrder }) => {
  return (
    <TableCell {...tableCellProps}>
      <Display order={order} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_CREATED_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Created',
  sortable: true,
};
