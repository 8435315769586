import React from 'react';
import classNames from 'classnames';

import { HouseCanaryLogomarkFullColor } from '@hcs/design-system';
import { InspectionCorrectionIcon } from '@hcs/design-system';
import { ReportId } from '@hcs/types';

import {
  ReportValueAppraisal,
  ReportValueAppraisalComps,
  ReportValueAvm,
  ReportValueHcComparable,
} from '../../features/ReportValue/ReportValue';

import styles from './ReportValueComparison.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}
const dataHcName = 'report-value-comparison';
export const ReportValueComparison = ({ reportId, className }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ReportValueComparison, className)}
    >
      <div className={styles.Column}>
        <label className={styles.ColumnLabel}>
          HouseCanary Values <HouseCanaryLogomarkFullColor />
        </label>
        <div>
          <ReportValueAvm reportId={reportId} showRentalValue={false} />
        </div>
        <div>
          <ReportValueHcComparable
            reportId={reportId}
            showRentalValue={false}
          />
        </div>
      </div>
      <div className={styles.Column}>
        <label className={styles.ColumnLabel}>
          Appraisal Values <InspectionCorrectionIcon size="lg" />
        </label>
        <div>
          <ReportValueAppraisal reportId={reportId} />
        </div>
        <div>
          <ReportValueAppraisalComps reportId={reportId} />
        </div>
      </div>
    </div>
  );
};
