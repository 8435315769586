import { GeoInterested, PreferencesKeys, UiPreferences } from '@hcs/types';

// TODO: remove when we fully switch over to geoInterested (EXP-741)
// we're switching from msaFavorites to geoInterested to encompass saving interested areas from multiple geos instead of just msa
// this util is meant to work with the legacy msaFavorites as well as geoInterested so we don't have to time the releases
// but can be removed after the full transition
export const getGeoInterestedFromUiPreferences = (
  uiPreferences: UiPreferences | null
): GeoInterested | undefined => {
  if (!uiPreferences) {
    return undefined;
  }
  if (uiPreferences[PreferencesKeys.GeoInterested]) {
    return uiPreferences[PreferencesKeys.GeoInterested];
  } else if (uiPreferences[PreferencesKeys.MsaFavorites]) {
    const geoInterested: GeoInterested = {
      msas: uiPreferences[PreferencesKeys.MsaFavorites],
    };
    return geoInterested;
  }
  return undefined;
};
