import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import {
  AddPortfolioAssetsPayload,
  AddPortfolioAssetsResponse,
} from '@hcs/types';

import { PortfolioApi } from '../api';
import { QUERY_KEY_PORTFOLIO } from '../hooks/usePortfolio';
import { QUERY_KEY_PORTFOLIO_ASSETS } from '../hooks/usePortfolioAssets';
import { QUERY_KEY_PORTFOLIOS } from '../hooks/usePortfolios';

export const useAddPortfolioAssets = (
  options?: UseMutationOptions<
    AddPortfolioAssetsResponse,
    AxiosError,
    AddPortfolioAssetsPayload,
    void
  >
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();

  return useMutation<
    AddPortfolioAssetsResponse,
    AxiosError,
    AddPortfolioAssetsPayload,
    void
  >(
    async ({ portfolioId, addressesPayload }: AddPortfolioAssetsPayload) => {
      return await PortfolioApi.addPortfolioAssets({
        portfolioId,
        addressesPayload,
      });
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_KEY_PORTFOLIO_ASSETS]);
        queryClient.invalidateQueries([QUERY_KEY_PORTFOLIOS]);
        queryClient.invalidateQueries([
          QUERY_KEY_PORTFOLIO,
          variables.portfolioId,
        ]);
        toastOpen({
          type: 'success',
          title: 'Successfully added a property',
        });
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables) => {
        toastOpen({
          type: 'error',
          title: `${
            error ? `${error}` : `Error adding a property`
          }, please try again`,
        });
        options?.onError?.(error, variables);
      },
    }
  );
};
