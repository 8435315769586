import { useMutation } from '@tanstack/react-query';

import { ViewOrderApi } from '../api/viewOrderApi';

export const useOrderPdfDownload = () => {
  return useMutation(
    async ({ orderId }: { orderId: number }) => {
      return await ViewOrderApi.fetchOrderPdfDownload(orderId);
    },
    {
      onSuccess: (data) => {
        window.open(data.url, '_blank');
      },
    }
  );
};
