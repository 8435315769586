import React from 'react';

import { Dialog, DIALOG_ACTIONS_PORTAL_ID } from '@hcs/design-system';
import { useRerender } from '@hcs/hooks';
import { DataPriorityCerberusStats } from '@hcs/types';

import { MarketStateColumnsZipForUser } from './MarketStateColumnsZipForUser';

import styles from './MarketStateColumnsZipForUser.module.css';

interface MarketStateColumnsZipForUserForUserDialogProps {
  active: boolean;
  onUpdate?: (chartDataPriority: DataPriorityCerberusStats) => void;
  onClose: VoidFunction;
}
const dataHcName = 'cerberus-status-column-options-dialog';
export const MarketStateColumnsZipForUserForUserDialog = ({
  active: dialogActive,
  onUpdate,
  onClose,
}: MarketStateColumnsZipForUserForUserDialogProps) => {
  // Rerender when dialog opens so we can get an accurate parent height for column calcs
  useRerender({
    deps: [dialogActive],
    shouldRerender: dialogActive,
  });

  return (
    <Dialog
      title={'Customize Table Columns'}
      subtitle={
        'Drag and drop the columns labels to reorder the table columns. Uncheck a column to hide it.'
      }
      type="large"
      dataHcName={dataHcName}
      theme={{
        Dialog: styles.ChartOptionsDialog,
      }}
      active={dialogActive}
      forceIsOverflowed
      onClose={onClose}
    >
      <MarketStateColumnsZipForUser
        onUpdate={(updatedData) => {
          onUpdate?.(updatedData);
          onClose();
        }}
        onCancel={onClose}
        actionsPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
      />
    </Dialog>
  );
};
