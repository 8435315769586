import { CerberusStatsFieldConfig } from '@hcs/types';
import { CerberusStatsFields } from '@hcs/types';

import {
  DAYS_ON_MARKET_MEDIAN_MOVING_AVG_CONFIG,
  DAYS_ON_MARKET_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/daysOnMarketMedianMovingAvg/daysOnMarketMedianMovingAvg';
import {
  DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_CONFIG,
  DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_RENTAL_CONFIG,
} from '../configs/daysOnMarketNotContractMedian';
import {
  DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_MOVING_AVG_CONFIG,
  DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/daysOnMarketNotContractMedianMovingAvg';
import {
  DAYS_TO_CLOSE_MEDIAN_CONFIG,
  DAYS_TO_CLOSE_MEDIAN_RENTAL_CONFIG,
} from '../configs/daysToCloseMedian';
import {
  DAYS_TO_CLOSE_MEDIAN_MOVING_AVG_CONFIG,
  DAYS_TO_CLOSE_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/daysToCloseMedianMovingAvg';
import {
  DAYS_TO_CONTRACT_MEDIAN_CONFIG,
  DAYS_TO_CONTRACT_MEDIAN_RENTAL_CONFIG,
} from '../configs/daysToContractMedian';
import {
  DAYS_TO_CONTRACT_MEDIAN_MOVING_AVG_CONFIG,
  DAYS_TO_CONTRACT_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/daysToContractMedianMovingAvg';
import {
  DAYS_TO_FIRST_PRICE_DROP_MEDIAN_CONFIG,
  DAYS_TO_FIRST_PRICE_DROP_MEDIAN_RENTAL_CONFIG,
} from '../configs/daysToFirstPriceDropMedian';
import {
  DAYS_TO_FIRST_PRICE_DROP_MEDIAN_MOVING_AVG_CONFIG,
  DAYS_TO_FIRST_PRICE_DROP_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/daysToFirstPriceDropMedianMovingAvg';
import { DISPLAY_NAME_CONFIG } from '../configs/displayName';
import { GROSS_YIELD_MEDIAN_CONFIG } from '../configs/grossYieldMedian';
import { HPI_LATEST_MONTH_CONFIG } from '../configs/hpiLatestMonth';
import {
  LISTINGS_CLOSED_COUNT_CONFIG,
  LISTINGS_CLOSED_COUNT_RENTAL_CONFIG,
} from '../configs/listingsClosedCount';
import {
  LISTINGS_CLOSED_COUNT_MOVING_AVG_CONFIG,
  LISTINGS_CLOSED_COUNT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsClosedCountMovingAvg';
import {
  LISTINGS_NEW_COUNT_CONFIG,
  LISTINGS_NEW_COUNT_RENTAL_CONFIG,
} from '../configs/listingsNewCount';
import {
  LISTINGS_NEW_COUNT_MOVING_AVG_CONFIG,
  LISTINGS_NEW_COUNT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsNewCountMovingAvg';
import {
  LISTINGS_ON_MARKET_COUNT_MOVING_AVG_CONFIG,
  LISTINGS_ON_MARKET_COUNT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsOnMarketCountMovingAvg';
import {
  LISTINGS_PRICE_CHANGE_COUNT_CONFIG,
  LISTINGS_PRICE_CHANGE_COUNT_RENTAL_CONFIG,
} from '../configs/listingsPriceChangeCount';
import {
  LISTINGS_PRICE_CHANGE_COUNT_MOVING_AVG_CONFIG,
  LISTINGS_PRICE_CHANGE_COUNT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsPriceChangeCountMovingAvg';
import {
  LISTINGS_PRICE_DROP_COUNT_CONFIG,
  LISTINGS_PRICE_DROP_COUNT_RENTAL_CONFIG,
} from '../configs/listingsPriceDropCount';
import {
  LISTINGS_PRICE_DROP_COUNT_MOVING_AVG_CONFIG,
  LISTINGS_PRICE_DROP_COUNT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsPriceDropCountMovingAvg';
import {
  LISTINGS_PRICE_DROP_PERCENT_CONFIG,
  LISTINGS_PRICE_DROP_PERCENT_RENTAL_CONFIG,
} from '../configs/listingsPriceDropPercent';
import {
  LISTINGS_PRICE_DROP_PERCENT_MOVING_AVG_CONFIG,
  LISTINGS_PRICE_DROP_PERCENT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsPriceDropPercentMovingAvg';
import {
  LISTINGS_REMOVED_COUNT_CONFIG,
  LISTINGS_REMOVED_COUNT_RENTAL_CONFIG,
} from '../configs/listingsRemovedCount';
import {
  LISTINGS_REMOVED_COUNT_MOVING_AVG_CONFIG,
  LISTINGS_REMOVED_COUNT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsRemovedCountMovingAvg';
import {
  LISTINGS_REMOVED_PERCENT_CONFIG,
  LISTINGS_REMOVED_PERCENT_RENTAL_CONFIG,
} from '../configs/listingsRemovedPercent';
import {
  LISTINGS_REMOVED_PERCENT_MOVING_AVG_CONFIG,
  LISTINGS_REMOVED_PERCENT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsRemovedPercentMovingAvg';
import {
  LISTINGS_UNDER_CONTRACT_COUNT_CONFIG,
  LISTINGS_UNDER_CONTRACT_COUNT_RENTAL_CONFIG,
} from '../configs/listingsUnderContractCount';
import {
  LISTINGS_UNDER_CONTRACT_COUNT_MOVING_AVG_CONFIG,
  LISTINGS_UNDER_CONTRACT_COUNT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsUnderContractCountMovingAvg';
import {
  LISTINGS_UNDER_CONTRACT_PERCENT_CONFIG,
  LISTINGS_UNDER_CONTRACT_PERCENT_RENTAL_CONFIG,
} from '../configs/listingsUnderContractPercent';
import {
  LISTINGS_UNDER_CONTRACT_PERCENT_MOVING_AVG_CONFIG,
  LISTINGS_UNDER_CONTRACT_PERCENT_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/listingsUnderContractPercentMovingAvg';
import { MARKET_GRADE_CONFIG } from '../configs/marketGrade';
import {
  MONTHS_OF_SUPPLY_MEDIAN_MOVING_AVG_CONFIG,
  MONTHS_OF_SUPPLY_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/monthsOfSupplyMedianMovingAvg';
import {
  PRICE_CLOSED_MEDIAN_CONFIG,
  PRICE_CLOSED_MEDIAN_RENTAL_CONFIG,
} from '../configs/priceClosedMedian';
import {
  PRICE_CLOSED_MEDIAN_MOVING_AVG_CONFIG,
  PRICE_CLOSED_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/priceClosedMedianMovingAvg';
import {
  PRICE_NEW_LIST_MEDIAN_CONFIG,
  PRICE_NEW_LIST_MEDIAN_RENTAL_CONFIG,
} from '../configs/priceNewListMedian';
import {
  PRICE_NEW_LIST_MEDIAN_MOVING_AVG_CONFIG,
  PRICE_NEW_LIST_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/priceNewListMedianMovingAvg';
import {
  PRICE_ON_MARKET_MEDIAN_MOVING_AVG_CONFIG,
  PRICE_ON_MARKET_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/priceOnMarketMedianMovingAvg';
import {
  PRICE_PER_SQFT_CLOSED_MEDIAN_CONFIG,
  PRICE_PER_SQFT_CLOSED_MEDIAN_RENTAL_CONFIG,
} from '../configs/pricePerSqFtClosedMedian';
import {
  PRICE_PER_SQFT_CLOSED_MEDIAN_MOVING_AVG_CONFIG,
  PRICE_PER_SQFT_CLOSED_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/pricePerSqftClosedMedianMovingAvg';
import {
  PRICE_PER_SQFT_NEW_LIST_MEDIAN_CONFIG,
  PRICE_PER_SQFT_NEW_LIST_MEDIAN_RENTAL_CONFIG,
} from '../configs/pricePerSqftNewListMedian';
import {
  PRICE_PER_SQFT_NEW_LIST_MEDIAN_MOVING_AVG_CONFIG,
  PRICE_PER_SQFT_NEW_LIST_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/pricePerSqftNewListMedianMovingAvg';
import {
  PRICE_PER_SQFT_ON_MARKET_MEDIAN_MOVING_AVG_CONFIG,
  PRICE_PER_SQFT_ON_MARKET_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/pricePerSqFtOnMarketMedianMovingAvg';
import { RPI_LATEST_MONTH_CONFIG } from '../configs/rpiLatestMonth';
import { RPI_RETURN_1_YEAR_CONFIG } from '../configs/rpiReturn1Year';
import {
  SALE_TO_LIST_PRICE_MEDIAN_MOVING_AVG_CONFIG,
  SALE_TO_LIST_PRICE_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/saleToListPriceMedianMovingAvg';
import {
  SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_CONFIG,
  SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_RENTAL_CONFIG,
} from '../configs/saleToListPriceOriginalMedian';
import {
  SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_MOVING_AVG_CONFIG,
  SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
} from '../configs/saleToListPriceOriginalMedianMovingAvg';

import {
  DAYS_ON_MARKET_MEDIAN_CONFIG,
  DAYS_ON_MARKET_MEDIAN_RENTAL_CONFIG,
} from './daysOnMarketMedian';
import {
  LISTINGS_ON_MARKET_COUNT_CONFIG,
  LISTINGS_ON_MARKET_COUNT_RENTAL_CONFIG,
} from './listingsOnMarketCount';
import {
  LISTING_STATS_EVENT_DATE_CONFIG,
  LISTING_STATS_RENTAL_EVENT_DATE_CONFIG,
} from './listingStatsEventDate';
import {
  MONTHS_OF_SUPPLY_MEDIAN_CONFIG,
  MONTHS_OF_SUPPLY_MEDIAN_RENTAL_CONFIG,
} from './monthsOfSupplyMedian';
import { NET_POPULATION_GROWTH_CONFIG } from './netPopulationGrowth';
import { POPULATION_LATEST_MONTH_CONFIG } from './populationLatestMonth';
import {
  PRICE_ON_MARKET_MEDIAN_CONFIG,
  PRICE_ON_MARKET_MEDIAN_RENTAL_CONFIG,
} from './priceOnMarketMedian';
import {
  PRICE_PER_SQFT_ON_MARKET_MEDIAN_CONFIG,
  PRICE_PER_SQFT_ON_MARKET_MEDIAN_RENTAL_CONFIG,
} from './pricePerSqFtOnMarketMedian';
import { RETURN_1_YEAR_FORECAST_CONFIG } from './return1YearForecast';
import { RISK_1_YEAR_LOSS_CONFIG } from './risk1YearLoss';
import {
  SALE_TO_LIST_PRICE_MEDIAN_CONFIG,
  SALE_TO_LIST_PRICE_MEDIAN_RENTAL_CONFIG,
} from './saleToListPriceMedian';

export const CERBERUS_STATS_FIELD_CONFIGS: {
  [F in CerberusStatsFields]: CerberusStatsFieldConfig<F>;
} = {
  [CerberusStatsFields.daysOnMarketMedian]: DAYS_ON_MARKET_MEDIAN_CONFIG,
  [CerberusStatsFields.daysOnMarketMedianRental]:
    DAYS_ON_MARKET_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.daysOnMarketMedianMovingAvg]:
    DAYS_ON_MARKET_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.daysOnMarketMedianMovingAvgRental]:
    DAYS_ON_MARKET_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.daysOnMarketNotContractMedian]:
    DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_CONFIG,
  [CerberusStatsFields.daysOnMarketNotContractMedianRental]:
    DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.daysOnMarketNotContractMedianMovingAvg]:
    DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.daysOnMarketNotContractMedianMovingAvgRental]:
    DAYS_ON_MARKET_NOT_CONTRACT_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.daysToCloseMedian]: DAYS_TO_CLOSE_MEDIAN_CONFIG,
  [CerberusStatsFields.daysToCloseMedianRental]:
    DAYS_TO_CLOSE_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.daysToCloseMedianMovingAvg]:
    DAYS_TO_CLOSE_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.daysToCloseMedianMovingAvgRental]:
    DAYS_TO_CLOSE_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.daysToContractMedian]: DAYS_TO_CONTRACT_MEDIAN_CONFIG,
  [CerberusStatsFields.daysToContractMedianRental]:
    DAYS_TO_CONTRACT_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.daysToContractMedianMovingAvg]:
    DAYS_TO_CONTRACT_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.daysToContractMedianMovingAvgRental]:
    DAYS_TO_CONTRACT_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.daysToFirstPriceDropMedian]:
    DAYS_TO_FIRST_PRICE_DROP_MEDIAN_CONFIG,
  [CerberusStatsFields.daysToFirstPriceDropMedianRental]:
    DAYS_TO_FIRST_PRICE_DROP_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.daysToFirstPriceDropMedianMovingAvg]:
    DAYS_TO_FIRST_PRICE_DROP_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.daysToFirstPriceDropMedianMovingAvgRental]:
    DAYS_TO_FIRST_PRICE_DROP_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.displayName]: DISPLAY_NAME_CONFIG,
  [CerberusStatsFields.grossYieldMedian]: GROSS_YIELD_MEDIAN_CONFIG,
  [CerberusStatsFields.hpiLatestMonth]: HPI_LATEST_MONTH_CONFIG,
  [CerberusStatsFields.listingsClosedCount]: LISTINGS_CLOSED_COUNT_CONFIG,
  [CerberusStatsFields.listingsClosedCountRental]:
    LISTINGS_CLOSED_COUNT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsClosedCountMovingAvg]:
    LISTINGS_CLOSED_COUNT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsClosedCountMovingAvgRental]:
    LISTINGS_CLOSED_COUNT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsNewCount]: LISTINGS_NEW_COUNT_CONFIG,
  [CerberusStatsFields.listingsNewCountRental]:
    LISTINGS_NEW_COUNT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsNewCountMovingAvg]:
    LISTINGS_NEW_COUNT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsNewCountMovingAvgRental]:
    LISTINGS_NEW_COUNT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsOnMarketCount]: LISTINGS_ON_MARKET_COUNT_CONFIG,
  [CerberusStatsFields.listingsOnMarketCountRental]:
    LISTINGS_ON_MARKET_COUNT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsOnMarketCountMovingAvg]:
    LISTINGS_ON_MARKET_COUNT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsOnMarketCountMovingAvgRental]:
    LISTINGS_ON_MARKET_COUNT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsPriceChangeCount]:
    LISTINGS_PRICE_CHANGE_COUNT_CONFIG,
  [CerberusStatsFields.listingsPriceChangeCountRental]:
    LISTINGS_PRICE_CHANGE_COUNT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsPriceChangeCountMovingAvg]:
    LISTINGS_PRICE_CHANGE_COUNT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsPriceChangeCountMovingAvgRental]:
    LISTINGS_PRICE_CHANGE_COUNT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsPriceDropCount]:
    LISTINGS_PRICE_DROP_COUNT_CONFIG,
  [CerberusStatsFields.listingsPriceDropCountRental]:
    LISTINGS_PRICE_DROP_COUNT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsPriceDropCountMovingAvg]:
    LISTINGS_PRICE_DROP_COUNT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsPriceDropCountMovingAvgRental]:
    LISTINGS_PRICE_DROP_COUNT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsPriceDropPercent]:
    LISTINGS_PRICE_DROP_PERCENT_CONFIG,
  [CerberusStatsFields.listingsPriceDropPercentRental]:
    LISTINGS_PRICE_DROP_PERCENT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsPriceDropPercentMovingAvg]:
    LISTINGS_PRICE_DROP_PERCENT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsPriceDropPercentMovingAvgRental]:
    LISTINGS_PRICE_DROP_PERCENT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsRemovedCount]: LISTINGS_REMOVED_COUNT_CONFIG,
  [CerberusStatsFields.listingsRemovedCountRental]:
    LISTINGS_REMOVED_COUNT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsRemovedCountMovingAvg]:
    LISTINGS_REMOVED_COUNT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsRemovedCountMovingAvgRental]:
    LISTINGS_REMOVED_COUNT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsRemovedPercent]: LISTINGS_REMOVED_PERCENT_CONFIG,
  [CerberusStatsFields.listingsRemovedPercentRental]:
    LISTINGS_REMOVED_PERCENT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsRemovedPercentMovingAvg]:
    LISTINGS_REMOVED_PERCENT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsRemovedPercentMovingAvgRental]:
    LISTINGS_REMOVED_PERCENT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingStatsEventDate]: LISTING_STATS_EVENT_DATE_CONFIG,
  [CerberusStatsFields.listingStatsEventDateRental]:
    LISTING_STATS_RENTAL_EVENT_DATE_CONFIG,
  [CerberusStatsFields.listingsUnderContractCount]:
    LISTINGS_UNDER_CONTRACT_COUNT_CONFIG,
  [CerberusStatsFields.listingsUnderContractCountRental]:
    LISTINGS_UNDER_CONTRACT_COUNT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsUnderContractCountMovingAvg]:
    LISTINGS_UNDER_CONTRACT_COUNT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsUnderContractCountMovingAvgRental]:
    LISTINGS_UNDER_CONTRACT_COUNT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.listingsUnderContractPercent]:
    LISTINGS_UNDER_CONTRACT_PERCENT_CONFIG,
  [CerberusStatsFields.listingsUnderContractPercentRental]:
    LISTINGS_UNDER_CONTRACT_PERCENT_RENTAL_CONFIG,
  [CerberusStatsFields.listingsUnderContractPercentMovingAvg]:
    LISTINGS_UNDER_CONTRACT_PERCENT_MOVING_AVG_CONFIG,
  [CerberusStatsFields.listingsUnderContractPercentMovingAvgRental]:
    LISTINGS_UNDER_CONTRACT_PERCENT_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.marketGrade]: MARKET_GRADE_CONFIG,
  [CerberusStatsFields.monthsOfSupplyMedian]: MONTHS_OF_SUPPLY_MEDIAN_CONFIG,
  [CerberusStatsFields.monthsOfSupplyMedianRental]:
    MONTHS_OF_SUPPLY_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.monthsOfSupplyMedianMovingAvg]:
    MONTHS_OF_SUPPLY_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.monthsOfSupplyMedianMovingAvgRental]:
    MONTHS_OF_SUPPLY_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.netPopulationGrowth]: NET_POPULATION_GROWTH_CONFIG,
  [CerberusStatsFields.populationLatestMonth]: POPULATION_LATEST_MONTH_CONFIG,
  [CerberusStatsFields.priceClosedMedian]: PRICE_CLOSED_MEDIAN_CONFIG,
  [CerberusStatsFields.priceClosedMedianRental]:
    PRICE_CLOSED_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.priceClosedMedianMovingAvg]:
    PRICE_CLOSED_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.priceClosedMedianMovingAvgRental]:
    PRICE_CLOSED_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.priceNewListMedian]: PRICE_NEW_LIST_MEDIAN_CONFIG,
  [CerberusStatsFields.priceNewListMedianRental]:
    PRICE_NEW_LIST_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.priceNewListMedianMovingAvg]:
    PRICE_NEW_LIST_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.priceNewListMedianMovingAvgRental]:
    PRICE_NEW_LIST_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.priceOnMarketMedian]: PRICE_ON_MARKET_MEDIAN_CONFIG,
  [CerberusStatsFields.priceOnMarketMedianRental]:
    PRICE_ON_MARKET_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.priceOnMarketMedianMovingAvg]:
    PRICE_ON_MARKET_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.priceOnMarketMedianMovingAvgRental]:
    PRICE_ON_MARKET_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.pricePerSqftClosedMedianMovingAvg]:
    PRICE_PER_SQFT_CLOSED_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.pricePerSqftClosedMedianMovingAvgRental]:
    PRICE_PER_SQFT_CLOSED_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.pricePerSqftNewListMedian]:
    PRICE_PER_SQFT_NEW_LIST_MEDIAN_CONFIG,
  [CerberusStatsFields.pricePerSqftNewListMedianRental]:
    PRICE_PER_SQFT_NEW_LIST_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.pricePerSqftNewListMedianMovingAvg]:
    PRICE_PER_SQFT_NEW_LIST_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.pricePerSqftNewListMedianMovingAvgRental]:
    PRICE_PER_SQFT_NEW_LIST_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.pricePerSqftOnMarketMedian]:
    PRICE_PER_SQFT_ON_MARKET_MEDIAN_CONFIG,
  [CerberusStatsFields.pricePerSqftOnMarketMedianRental]:
    PRICE_PER_SQFT_ON_MARKET_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.pricePerSqftClosedMedian]:
    PRICE_PER_SQFT_CLOSED_MEDIAN_CONFIG,
  [CerberusStatsFields.pricePerSqftClosedMedianRental]:
    PRICE_PER_SQFT_CLOSED_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.pricePerSqftOnMarketMedianMovingAvg]:
    PRICE_PER_SQFT_ON_MARKET_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.pricePerSqftOnMarketMedianMovingAvgRental]:
    PRICE_PER_SQFT_ON_MARKET_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.return1YearForecast]: RETURN_1_YEAR_FORECAST_CONFIG,
  [CerberusStatsFields.risk1YearLoss]: RISK_1_YEAR_LOSS_CONFIG,
  [CerberusStatsFields.rpiLatestMonth]: RPI_LATEST_MONTH_CONFIG,
  [CerberusStatsFields.rpiReturn1Year]: RPI_RETURN_1_YEAR_CONFIG,
  [CerberusStatsFields.saleToListPriceMedian]: SALE_TO_LIST_PRICE_MEDIAN_CONFIG,
  [CerberusStatsFields.saleToListPriceMedianRental]:
    SALE_TO_LIST_PRICE_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.saleToListPriceMedianMovingAvg]:
    SALE_TO_LIST_PRICE_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.saleToListPriceMedianMovingAvgRental]:
    SALE_TO_LIST_PRICE_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
  [CerberusStatsFields.saleToListPriceOriginalMedian]:
    SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_CONFIG,
  [CerberusStatsFields.saleToListPriceOriginalMedianRental]:
    SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_RENTAL_CONFIG,
  [CerberusStatsFields.saleToListPriceOriginalMedianMovingAvg]:
    SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_MOVING_AVG_CONFIG,
  [CerberusStatsFields.saleToListPriceOriginalMedianMovingAvgRental]:
    SALE_TO_LIST_PRICE_ORIGINAL_MEDIAN_MOVING_AVG_RENTAL_CONFIG,
};
