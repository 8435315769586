import { ReactNode } from 'react';

import {
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportId,
} from '@hcs/types';

import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupported } from '../../utils/reportConfig.utils';

export interface ReportFeaturesSupportedProps {
  reportId: ReportId;
  reportFeatures: (ReportFeatures | null)[];
  reportFeaturesSupportType?: ReportFeaturesSupportTypes;
  children: ReactNode;
  // Content rendered if the reportFeaturesSupported check is false
  notSupportedContent?: ReactNode;
}
export const ReportFeaturesSupported = ({
  reportId,
  reportFeatures,
  reportFeaturesSupportType = ReportFeaturesSupportTypes.Any,
  notSupportedContent = null,
  children,
}: ReportFeaturesSupportedProps) => {
  const { data: reportConfig } = useReportConfig(reportId);
  return reportFeaturesSupported(
    reportConfig,
    reportFeatures,
    reportFeaturesSupportType
  )
    ? children
    : notSupportedContent;
};
