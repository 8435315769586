import React, { useMemo } from 'react';

import { AutoComplete } from '@hcs/design-system';
import { AdminFeatureOrgProps } from '@hcs/types';
import { OrgInvitation, OrgUser, OrgUserStatus } from '@hcs/types';

import { useOrgUsersLegacy } from '../../hooks/useOrgUsersLegacy';

type Props = AdminFeatureOrgProps & {
  className?: string;
  value: number | null;
  handleOnOptionSelect: (orgUser: OrgUser | OrgInvitation | null) => void;
};

const filterObj = { status: OrgUserStatus.Active };
const dataHcName = 'organization-users-autocomplete';

export const OrgUsersAutoComplete = ({
  className,
  value,
  handleOnOptionSelect,
  ...adminFeatureOrgProps
}: Props) => {
  const { data: orgUsers } = useOrgUsersLegacy({
    ...adminFeatureOrgProps,
    requestAllUsers: true,
    filters: filterObj,
  });

  const orgUsersData = orgUsers?.objects;

  const options = useMemo(() => {
    return [
      {
        label: 'All Users',
        value: null,
        searchString: 'All Users',
      },
      ...(orgUsersData || []).map((user) => {
        return {
          searchString: `${user.email} ${user.firstName} ${user.lastName}`,
          label: user.email,
          value: user.id,
        };
      }),
    ];
  }, [orgUsersData]);

  return (
    <AutoComplete
      dataHcName={dataHcName}
      className={className}
      config={{
        selectType: 'single',
        value: value || null,
        onSelect: (orgUserId) => {
          const user = orgUsersData?.find((a) => a.id === orgUserId);
          handleOnOptionSelect(user || null);
        },
      }}
      options={options}
    />
  );
};
