import { useMemo } from 'react';

import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useCompsFarmDocument } from './useCompsFarmDocument';
import { useSubjectDocument } from './useSubjectDocument';

interface Props {
  reportId: ReportId;
}
export const useReportMlsIds = ({ reportId }: Props) => {
  const farmDocumentQuery = useCompsFarmDocument(reportId, CompTypes.Sold);
  const subjectDocumentQuery = useSubjectDocument(reportId);
  const { data: farmDocument } = farmDocumentQuery;
  const { data: subjectDocument } = subjectDocumentQuery;
  const mlsIds = useMemo(() => {
    const ids: Record<string, boolean> = {};
    if (
      subjectDocument &&
      subjectDocument.data.propertyState.complexFieldsSale?.currentHcMlsId
    ) {
      ids[subjectDocument.data.propertyState.complexFieldsSale.currentHcMlsId] =
        true;
    }
    if (farmDocument) {
      Object.keys(farmDocument.data.farm || {}).forEach((compId) => {
        const comp = farmDocument.data.farm?.[compId];
        const compHcMlsId =
          comp?.propertyState.complexFieldsSale?.currentHcMlsId;
        if (compHcMlsId && !ids[compHcMlsId]) {
          ids[compHcMlsId] = true;
        }
      });
    }
    return Object.keys(ids);
  }, [farmDocument]);

  return {
    ...combineUseQueryResult([subjectDocumentQuery, farmDocumentQuery]),
    data: mlsIds,
  };
};
