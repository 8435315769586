import { useSelector } from 'react-redux';

import { useMapDispatchToSliceActions } from '@hcs/hooks';
import { ToastPartialAppState, ToastSliceState } from '@hcs/types';

import { TOAST_REDUCER_KEY, toastSlice } from '../rtk';

export const useToastSlice = () => {
  const actions = useMapDispatchToSliceActions(toastSlice);
  return {
    state: useSelector<ToastPartialAppState, ToastSliceState>(
      (state) => state[TOAST_REDUCER_KEY]
    ),
    actions,
  };
};
