import React from 'react';
import classNames from 'classnames';

import { ProductType, STEP } from '@hcs/types';

import { PRODUCT_DETAILS } from '../constants';

import styles from './SelectedOptions.module.css';

interface Props {
  dataHcName: string;
  onChangeClick: (number: number) => void;
  productType: ProductType;
  disabled?: boolean;
}

export const ProductOption = ({
  dataHcName,
  onChangeClick,
  productType,
  disabled,
}: Props) => {
  const handleChangeClick = () => {
    if (!disabled) {
      onChangeClick(STEP.SELECT_PRODUCT);
    }
  };
  const { Icon, title, description, iconProps } = PRODUCT_DETAILS[productType];

  return (
    <div data-hc-name={dataHcName} className={styles.Option}>
      <div className={styles.OptionContent} data-hc-name="order-form-option">
        <div className={styles.OptionIcon}>
          <Icon {...iconProps} />
        </div>
        <div>
          <h4 className={styles.OptionTitle} data-hc-name="option-form-name">
            {title}
          </h4>
          <div
            className={styles.OptionDescription}
            data-hc-name="option-form-description"
          >
            {description}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.OptionChange, {
          [styles.disabled]: disabled,
        })}
        onClick={handleChangeClick}
        data-hc-name="option-form-change-link"
      >
        Change
      </div>
    </div>
  );
};
