import React from 'react';

export const ActivityMonitorPage = React.lazy(
  () => import('./ActivityMonitorPage')
);
export const ActivityPropertySearchPage = React.lazy(
  () => import('./ActivityPropertySearchPage')
);
export const BuyBoxesPage = React.lazy(() => import('./BuyBoxes'));
export const AexpMapPage = React.lazy(() => import('./AexpMapPage'));
