import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Notification } from '@hcs/types';

import { CloseIcon } from '../../../../svgs/icons/navigation';

import styles from './StatusMessage.module.css';

const Title = ({
  type,
  title,
}: {
  type: Notification['type'];
  title: string;
}) => {
  const titlePrefix = type.charAt(0).toUpperCase() + type.slice(1) + ': ';

  return (
    <div>
      <b>{titlePrefix}</b> {title}
    </div>
  );
};

export interface StatusMessageProps extends Omit<Notification, 'title'> {
  dataHcName: string;
  show: boolean;
  enableClose?: boolean;
  title: string | string[];
  onClose?: () => void;
}

export const StatusMessage = ({
  dataHcName,
  show,
  title,
  message,
  duration,
  type,
  enableClose,
  onClose,
}: StatusMessageProps) => {
  const [showStatusMessage, setShowStatusMessage] = useState(show);

  useEffect(() => {
    setShowStatusMessage(show);
    let hideTimeout: NodeJS.Timeout;
    if (show && duration) {
      hideTimeout = setTimeout(() => {
        setShowStatusMessage(false);
        onClose?.();
      }, duration);
    }

    return () => clearTimeout(hideTimeout);
  }, [show, duration, onClose]);

  if (!showStatusMessage) return null;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.StatusMessage, {
        [styles.error]: type === 'error',
        [styles.success]: type === 'success',
        [styles.warning]: type === 'warning',
      })}
    >
      <div
        data-hc-name={`${dataHcName}-header`}
        className={styles.StatusMessageHeader}
      >
        <div data-hc-name={`${dataHcName}-title`}>
          {Array.isArray(title) ? (
            title.map((t) => <Title key={t} type={type} title={t} />)
          ) : (
            <Title type={type} title={title} />
          )}
        </div>

        {enableClose && (
          <CloseIcon
            onClick={() => {
              setShowStatusMessage(false);
              onClose && onClose();
            }}
            dataHcName={`${dataHcName}-close-button`}
          />
        )}
      </div>
      {message && <p>{message}</p>}
    </div>
  );
};
