import React from 'react';

import { Popover } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { KeywordSearchByListings } from '@hcs/keyword-search';
import { KeywordSearchResults } from '@hcs/keyword-search';
import { useKeywordSearchByListingsByCerberusId } from '@hcs/keyword-search';
import { CompTypes } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { CompSchema } from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import { useCompCompareDialogSlice } from '../../hooks';
import { useCompKeywordSearchInputs } from '../../hooks/useCompKeywordSearchInputs';
import { useCompKeywordSearchMatches } from '../../hooks/useCompKeywordSearchMatches';
import { compKeywordSearchKey } from '../../utils';

import styles from './CompKeywordSearch.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
}
const dataHcName = 'comp-keyword-search';
export const COMP_KEYWORD_SEARCH_FEATURES_SALE = [
  ReportFeatures.CompsFilterKeyword,
];
export const COMP_KEYWORD_SEARCH_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsFilterKeyword,
];
export const CompKeywordSearch = ({ reportId, compType }: Props) => {
  const keywordSearchKey = compKeywordSearchKey(compType);
  const { data: listingsInput } = useCompKeywordSearchInputs({
    reportId,
    compType,
  });
  const {
    data: { keywordMatchCompIds, keywordMatchCompSchemas },
  } = useCompKeywordSearchMatches({
    reportId,
    compType,
  });
  const {
    actions: { compCompareDialogOpen },
  } = useCompCompareDialogSlice();
  const { fetchStatus, isFetched } =
    useKeywordSearchByListingsByCerberusId(keywordSearchKey);
  const isUninitialized = fetchStatus === 'idle' && !isFetched;
  const handleResultClick = (compSchema: CompSchema) => {
    compCompareDialogOpen({
      type: 'compId',
      compType,
      compId: compSchema.compID,
    });
  };
  if (!listingsInput) {
    return (
      <Skeleton
        type="input"
        dataHcName={`${dataHcName}-skeleton`}
        style={{ padding: '0 15px' }}
      />
    );
  }
  return (
    <div data-hc-name={dataHcName} className={styles.CompKeywordSearch}>
      <div className={styles.InputCell}>
        <KeywordSearchByListings
          listingsInput={listingsInput}
          dataHcName={`${dataHcName}-control`}
          searchKey={keywordSearchKey}
          noPadding
        />
      </div>
      {!isUninitialized && (
        <div className={styles.ResultsCell}>
          {keywordMatchCompIds.length ? (
            <Popover
              dataHcName={`${dataHcName}-popover`}
              trigger={
                <KeywordSearchResults
                  dataHcName={`${dataHcName}-results-count`}
                  count={keywordMatchCompIds.length}
                  className={styles.clickable}
                  searchKey={keywordSearchKey}
                />
              }
              content={
                <div className={styles.MatchesList}>
                  {keywordMatchCompIds.map((compId, i) => {
                    const schema = keywordMatchCompSchemas[compId];
                    if (!schema) return null;
                    return (
                      <div
                        key={`${dataHcName}-match-${i}`}
                        data-hc-name={`${dataHcName}-match-${i}`}
                        className={styles.Match}
                        onClick={() => handleResultClick(schema)}
                      >
                        <div className={styles.StreetAddress}>
                          {formatStreetAddress(schema.propertyState.location)}
                        </div>
                        <div className={styles.CityStateZip}>
                          {formatCityStateZip(schema.propertyState.location)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
              contentPadding={0}
              closeOnClick
            />
          ) : (
            <KeywordSearchResults
              dataHcName={`${dataHcName}-results-count`}
              searchKey={keywordSearchKey}
              count={keywordMatchCompIds.length}
            />
          )}
        </div>
      )}
    </div>
  );
};
