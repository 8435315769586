import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dropdown } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { FormContainer } from '@hcs/forms';
import { FormFieldsInput } from '@hcs/forms';
import { FormRow } from '@hcs/forms';
import {
  HcsAdminOrgApiRateLimit,
  HcsAdminOrgApiRateLimitMultiplier,
} from '@hcs/types';

import { ADD_RATE_LIMIT_FORM_SCHEMA } from '../../constants/hcsAdmin.constants';
import { useHcsAdminOrgApiRateLimitsAdd } from '../../hooks/useHcsAdminOrgApiRateLimitsAdd';

import styles from './OrgApiRateLimits.module.css';

interface AddRateLimitProps {
  orgId: number;
  actionButtonsPortalId?: string;
  onClose: () => void;
}

const timeUnitsOptions = [
  {
    value: HcsAdminOrgApiRateLimitMultiplier.second,
    label: 'Seconds',
  },
  {
    value: HcsAdminOrgApiRateLimitMultiplier.minute,
    label: 'Minutes',
  },
  {
    value: HcsAdminOrgApiRateLimitMultiplier.hour,
    label: 'Hours',
  },
  {
    value: HcsAdminOrgApiRateLimitMultiplier.day,
    label: 'Days',
  },
];

const endpointsOptions = [
  {
    label: 'All Endpoints',
    value: '*',
  },
  {
    label: 'Analytics API',
    value: 'Analytics API',
  },
  {
    label: 'Rental Report API',
    value: 'Rental Report API',
  },
  {
    label: 'Value Report',
    value: 'Value Report',
  },
  {
    label: 'Value Report API',
    value: 'Value Report API',
  },
];

const dataHcName = 'add-rate-limit';
export const AddRateLimit = ({
  orgId,
  actionButtonsPortalId,
  onClose,
}: AddRateLimitProps) => {
  const form = useForm<HcsAdminOrgApiRateLimit>({
    resolver: yupResolver(ADD_RATE_LIMIT_FORM_SCHEMA),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      windowLength: undefined,
      noOfRequests: undefined,
      endpointName: undefined,
      organizationId: orgId,
      name: '',
      limitByRequest: false,
    },
  });

  const { reset, setValue } = form;

  const [duration, setDuration] = useState('');

  const [rateLimitMultiplier, setRateLimitMultiplier] =
    useState<HcsAdminOrgApiRateLimitMultiplier>(
      HcsAdminOrgApiRateLimitMultiplier.second
    );

  const addRateLimit = useHcsAdminOrgApiRateLimitsAdd();

  const handleFormSubmit = (data: HcsAdminOrgApiRateLimit) => {
    addRateLimit.mutate(data, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    if (addRateLimit.isSuccess) {
      reset();
    }
  }, [reset, addRateLimit.isSuccess]);

  useEffect(() => {
    if (rateLimitMultiplier) {
      setValue('windowLength', Number(duration) * rateLimitMultiplier);
    }
  }, [rateLimitMultiplier, setValue, duration]);

  return (
    <FormProvider {...form}>
      <form data-hc-name={`${dataHcName}-form`}>
        <FormContainer dataHcName={`${dataHcName}-form-container`}>
          <div className={styles.AddRateLimitDialogFormWrapper}>
            <FormRow dataHcName={`${dataHcName}-form-row`}>
              <div className={styles.AddRateLimitInputGroup}>
                <label>Duration</label>
                <Input
                  dataHcName={`${dataHcName}-name-input`}
                  value={duration}
                  onChange={(val) => setDuration(val)}
                  className={styles.AddRateLimitInput}
                />
              </div>
            </FormRow>
            <FormRow dataHcName={`${dataHcName}-form-row`}>
              <div className={styles.AddRateLimitInputGroup}>
                <label>Time Units</label>
                <Dropdown
                  dataHcName={`${dataHcName}-time-units-dropdown`}
                  options={timeUnitsOptions}
                  name="Time Units"
                  value={HcsAdminOrgApiRateLimitMultiplier.second}
                  onSelect={(val) => setRateLimitMultiplier(val)}
                  className={styles.AddRateLimitInput}
                />
              </div>
            </FormRow>
            <FormRow dataHcName={`${dataHcName}-form-row`}>
              <FormFieldsInput
                name="noOfRequests"
                label="# of Requests"
                error={form.formState.errors.noOfRequests?.message}
                className={styles.AddRateLimitInput}
              />
            </FormRow>
            <FormRow dataHcName={`${dataHcName}-form-row`}>
              <div className={styles.AddRateLimitInputGroup}>
                <Controller
                  name="endpointName"
                  control={form.control}
                  render={({ field }) => (
                    <>
                      <label>Endpoints</label>
                      <Dropdown
                        dataHcName={`${dataHcName}-endpoints-dropdown`}
                        options={endpointsOptions}
                        name="Endpoints"
                        value={field.value || ''}
                        onSelect={(val) => field.onChange(val)}
                        className={styles.AddRateLimitInput}
                      />
                    </>
                  )}
                />
              </div>
            </FormRow>
            <FormRow dataHcName={`${dataHcName}-form-row`}>
              <FormFieldsInput
                name="name"
                label="Notes"
                error={form.formState.errors.name?.message}
                className={styles.AddRateLimitInput}
              />
            </FormRow>
          </div>
        </FormContainer>
        <ActionButtons
          portalIdRender={actionButtonsPortalId}
          dataHcName={`${dataHcName}-action-buttons`}
          noStretch
          actions={[
            {
              dataHcName: `${dataHcName}-save`,
              label: 'Save',
              type: 'submit',
              disabled: !form.formState.isValid,
              onClick: form.handleSubmit(handleFormSubmit),
              primary: true,
            },
            {
              dataHcName: `${dataHcName}-cancel`,
              label: 'Cancel',
              type: 'button',
              onClick: () => {
                reset();
                onClose();
              },
              secondary: true,
            },
          ]}
        />
      </form>
    </FormProvider>
  );
};
