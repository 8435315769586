import React from 'react';

export const UserContactInfoPage = React.lazy(
  () => import('./UserContactInfoPage')
);
export const UserChangePasswordPage = React.lazy(
  () => import('./UserChangePasswordPage')
);
export const UserUsagePage = React.lazy(() => import('./UserUsagePage'));
export const ApiKeysPage = React.lazy(() => import('./ApiKeysPage'));
export const ApiSubscriptionsPage = React.lazy(
  () => import('./ApiSubscriptionsPage')
);

export const UserReportPreferencesPage = React.lazy(
  () => import('./UserReportPreferencesPage')
);
