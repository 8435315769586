import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { CerberusStatsFields } from '@hcs/types';

import { CerberusStatsDetailsTable } from '../../components/CerberusStatsDetailsTable';
import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';
import { useCerberusStatsZip } from '../../hooks/useCerberusStatsZip';

import styles from './ZipDetailsTable.module.css';

const DisplayName =
  CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.displayName].Display;

interface ZipDetailsTableProps {
  className?: string;
  zipcode: string;
}

const dataHcName = 'zip-details';
export const ZipDetailsTable = ({
  className,
  zipcode,
}: ZipDetailsTableProps) => {
  const { data: cerberusStats, isInitialLoading: cerberusStatsIsLoading } =
    useCerberusStatsZip({ zipcode });

  if (cerberusStatsIsLoading) {
    return (
      <div className={className}>
        <LoadingSpinner dataHcName={`${dataHcName}-loading-spinner`} />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={styles.Zip} data-hc-name={`${dataHcName}-zip-name`}>
        <DisplayName cerberusStats={cerberusStats} />
      </div>
      <CerberusStatsDetailsTable
        dataHcName={dataHcName}
        cerberusStats={cerberusStats}
      />
    </div>
  );
};
