import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmOrder } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { DATA_HC_NAME_ORDERS_TABLE_CELL } from '../../../constants';

import { OrdersTableCellsDisplayProps } from '.';

const Display = ({
  order,
}: OrdersTableCellsDisplayProps): JSX.Element | null => {
  return order?.actualDeliveryDate ? (
    <div
      data-hc-name={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-actual-delivery-date`}
    >
      {formatDateShort(order?.actualDeliveryDate)}
    </div>
  ) : null;
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Completed</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export type OrdersTableCellProps = TableCellProps & OmOrder;

const ContentCell = ({ order, ...tableCellProps }: { order: OmOrder }) => {
  return (
    <TableCell {...tableCellProps}>
      <Display order={order} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_ACTUAL_DELIVERY_DATE_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Completed',
  sortable: true,
};
