import { gql } from 'graphql-request';

// Hpi (House Price Index) Fragments
export const FRAGMENT_MSA_HPI_CORE = gql`
  fragment MsaHpiCore on MsaHpi {
    forecastCalcs {
      risk1YearLoss
      return {
        return1YearForecast
      }
    }
    timeSeriesCurrent
  }
`;

export const FRAGMENT_MSA_HPI_TIME_SERIES = gql`
  fragment MsaHpiTimeSeries on MsaHpiTimeSeriesElement {
    month
    valueIndexed
  }
`;

// Hpi (House Price Index) Fragments
export const FRAGMENT_ZIP_HPI_CORE = gql`
  fragment ZipHpiCore on ZipHpi {
    forecastCalcs {
      risk1YearLoss
      return {
        return1YearForecast
      }
    }
    timeSeriesCurrent
  }
`;

export const FRAGMENT_ZIP_HPI_TIME_SERIES = gql`
  fragment ZipHpiTimeSeries on ZipHpiTimeSeriesElement {
    month
    valueIndexed
  }
`;
