import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './FormRow.module.css';

interface FormRowProps {
  dataHcName: string;
  className?: string;
  children: ReactNode;
}

export const FormRow = ({ dataHcName, className, children }: FormRowProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.FormRow, className)}
    >
      {children}
    </div>
  );
};
