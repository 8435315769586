import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { Input } from '@hcs/design-system';
import { InputNumber } from '@hcs/design-system';
import { Toggle, ToggleOption } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { OfferNowDisclaimer } from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import { CerberusInputHcs } from '@hcs/types';
import { OfferNowFormData } from '@hcs/types';
import { formatPercent } from '@hcs/utils';
import { logException } from '@hcs/utils';

import { useOfferCreate } from '../../hooks/useOfferCreate';
import { useOfferNowEngagementTrackingData } from '../../hooks/useOfferNowEngagementTrackingData';
import {
  OFFER_FORM_DEFAULT_VALUES,
  offerNowValidationSchema,
} from '../../utils/offerForm.utils';

import { OfferSuccess } from './OfferSuccess';

import styles from './OfferForm.module.css';

const TOGGLE_OPTIONS: ToggleOption<boolean>[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

interface OfferFormTheme {
  InputContainer?: string;
  DialogInputLayout?: string;
}

export interface OfferFormProps {
  theme?: OfferFormTheme;
  hcAddressId?: number;
  addressSlug?: string | null;
  fullAddress?: string;
  listPrice?: number | null;
  actionsPortalIdRender?: string;
  showDisclaimerText?: boolean;
  showOfferSuccess?: boolean;
  onInitialAmountChange?: (v: number | null) => void;
  onSuccess?: () => void;
  hideSubmitOnSuccess?: boolean;
}

const dataHcName = 'offer-form';
export const OfferForm = ({
  theme,
  hcAddressId,
  addressSlug,
  fullAddress,
  listPrice,
  actionsPortalIdRender,
  showDisclaimerText = true,
  showOfferSuccess = true,
  hideSubmitOnSuccess,
  onInitialAmountChange,
  onSuccess,
}: OfferFormProps) => {
  const formMethods = useForm<OfferNowFormData>({
    mode: 'onTouched',
    resolver: yupResolver(offerNowValidationSchema),
    defaultValues: OFFER_FORM_DEFAULT_VALUES,
  });
  const { reset: resetForm } = formMethods;
  const {
    mutate: createOffer,
    isLoading,
    isSuccess,
    isError,
    error: createError,
  } = useOfferCreate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (listPrice) {
      resetForm({
        ...OFFER_FORM_DEFAULT_VALUES,
        initialAmount: listPrice,
      });
    }
  }, [listPrice, resetForm]);

  useEffect(() => {
    if (isError) {
      setError(createError.message);
    }
  }, [isError, createError]);

  const cerberusInputHcs: CerberusInputHcs | undefined = useMemo(() => {
    return hcAddressId
      ? {
          hcAddressId,
          slug: addressSlug,
        }
      : undefined;
  }, [hcAddressId, addressSlug]);
  // Register global engagement tracking data whenever this form is mounted
  useOfferNowEngagementTrackingData(cerberusInputHcs, listPrice);

  const onSubmit: SubmitHandler<OfferNowFormData> = (formData) => {
    if (hcAddressId && fullAddress && addressSlug) {
      createOffer({ hcAddressId, fullAddress, addressSlug, formData });
    } else {
      setError('Error: address not found in system.');
      logException(
        `OfferForm onSubmit: hcAddressId: ${hcAddressId}, fullAddress: ${fullAddress}, or addressSlug: ${addressSlug} are null or undefined`
      );
      return;
    }
  };

  const initialAmount = formMethods.watch('initialAmount');
  const amountDown = formMethods.watch('amountDown');
  const hasInspection = formMethods.watch('hasInspection');
  const hasDueDiligence = formMethods.watch('hasDueDiligence');

  useEffect(() => {
    if (onInitialAmountChange) {
      onInitialAmountChange(initialAmount);
    }
  }, [initialAmount, onInitialAmountChange]);

  useEffect(() => {
    if (isSuccess) onSuccess?.();
  }, [isSuccess, onSuccess]);

  const { control } = formMethods;
  const { errors, isValid } = formMethods.formState;

  if (isSuccess && showOfferSuccess) {
    return <OfferSuccess dataHcName={`${dataHcName}-success`} />;
  }

  return (
    <FormProvider {...formMethods}>
      <form data-hc-name={dataHcName}>
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Initial Offer Amount"
          labelHelper={
            listPrice ? 'Pre-filled value is the list price' : undefined
          }
          required
          dataHcName={`${dataHcName}-initial-offer-amount-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <Controller
            name="initialAmount"
            control={control}
            render={({ field }) => {
              return (
                <InputNumber
                  dataHcName={`${dataHcName}-initial-offer-amount-field-input`}
                  {...field}
                  disabled={isSuccess}
                  error={errors.initialAmount?.message}
                />
              );
            }}
          />
        </DialogInputLayout>
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Max Offer"
          required
          dataHcName={`${dataHcName}-max-offer-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <Controller
            name="maxAmount"
            control={control}
            render={({ field }) => {
              return (
                <InputNumber
                  dataHcName={`${dataHcName}-max-offer-field-input`}
                  {...field}
                  disabled={isSuccess}
                  error={errors.maxAmount?.message}
                />
              );
            }}
          />
        </DialogInputLayout>
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Amount Down"
          required
          dataHcName={`${dataHcName}-amount-down-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <div className={styles.AmountDownRow}>
            <Controller
              name="amountDown"
              control={control}
              render={({ field }) => {
                return (
                  <InputNumber
                    className={styles.AmountDownField}
                    dataHcName={`${dataHcName}-amount-down-field-input`}
                    {...field}
                    disabled={isSuccess}
                    error={errors.amountDown?.message}
                  />
                );
              }}
            />
            {initialAmount && amountDown && (
              <div
                data-hc-name="amount-down-percent"
                className={styles.AmountDownPercent}
              >
                {formatPercent(amountDown / initialAmount)} of Offer
              </div>
            )}
          </div>
        </DialogInputLayout>
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Days To Close"
          required
          dataHcName={`${dataHcName}-days-to-close-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <Controller
            name="daysToClose"
            control={control}
            render={({ field }) => {
              return (
                <InputNumber
                  dataHcName={`${dataHcName}-days-to-close-field-input`}
                  {...field}
                  error={errors.daysToClose?.message}
                  disabled={isSuccess}
                />
              );
            }}
          />
        </DialogInputLayout>
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Inspection"
          required={false}
          dataHcName={`${dataHcName}-inspection-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <Controller
            name="hasInspection"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle<boolean>
                primary
                dataHcName={`${dataHcName}-inspection-field-toggle`}
                value={value}
                options={TOGGLE_OPTIONS}
                onChange={onChange}
                disabled={isSuccess}
              />
            )}
          />
        </DialogInputLayout>
        {hasInspection && (
          <DialogInputLayout
            theme={{
              InputContainer: theme?.InputContainer,
            }}
            label="Days to Inspection"
            required
            dataHcName={`${dataHcName}-days-to-inspection-field`}
            className={classNames(styles.FormRow, theme?.DialogInputLayout)}
          >
            <Controller
              name="daysToInspection"
              control={control}
              render={({ field }) => {
                return (
                  <InputNumber
                    dataHcName={`${dataHcName}-days-to-inspection-field-input`}
                    {...field}
                    error={errors.daysToInspection?.message}
                    disabled={isSuccess}
                  />
                );
              }}
            />
          </DialogInputLayout>
        )}
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Due Diligence"
          required={false}
          dataHcName={`${dataHcName}-due-diligence-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <Controller
            name="hasDueDiligence"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle<boolean>
                primary
                dataHcName={`${dataHcName}-due-diligence-field-toggle`}
                value={value}
                options={TOGGLE_OPTIONS}
                onChange={onChange}
                disabled={isSuccess}
              />
            )}
          />
        </DialogInputLayout>
        {hasDueDiligence && (
          <DialogInputLayout
            theme={{
              InputContainer: theme?.InputContainer,
            }}
            label="Days to Due Diligence"
            required
            dataHcName={`${dataHcName}-days-to-due-diligence-field`}
            className={classNames(styles.FormRow, theme?.DialogInputLayout)}
          >
            <Controller
              name="daysToDueDiligence"
              control={control}
              render={({ field }) => {
                return (
                  <InputNumber
                    dataHcName={`${dataHcName}-days-to-due-diligence-field-input`}
                    shouldFormat={{
                      shouldFormatNumber: false,
                    }}
                    {...field}
                    error={errors.daysToDueDiligence?.message}
                    disabled={isSuccess}
                  />
                );
              }}
            />
          </DialogInputLayout>
        )}
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Signer's Name on Title"
          required
          dataHcName={`${dataHcName}-signer-name-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <Controller
            name="signerNameOnTitle"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  dataHcName={`${dataHcName}-signer-name-field-input`}
                  {...field}
                  value={field.value || ''}
                  error={errors.signerNameOnTitle?.message}
                  disabled={isSuccess}
                />
              );
            }}
          />
        </DialogInputLayout>
        <DialogInputLayout
          theme={{
            InputContainer: theme?.InputContainer,
          }}
          label="Business Name"
          required={false}
          dataHcName={`${dataHcName}-business-name-field`}
          className={classNames(styles.FormRow, theme?.DialogInputLayout)}
        >
          <Controller
            name="businessName"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  dataHcName={`${dataHcName}-business-name-field-input`}
                  {...field}
                  value={field.value || ''}
                  error={errors.businessName?.message}
                  disabled={isSuccess}
                />
              );
            }}
          />
        </DialogInputLayout>
        {error && (
          <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
            {error}
          </div>
        )}
        {hideSubmitOnSuccess && isSuccess ? null : (
          <ActionButtons
            className={styles.SubmitButton}
            actions={[
              {
                dataHcName: `${dataHcName}-submit-button`,
                label: 'Submit Offer',
                onClick: () => formMethods.handleSubmit(onSubmit)(),
                disabled: isLoading || !isValid,
              },
            ]}
            dataHcName={`${dataHcName}-actions`}
            portalIdRender={actionsPortalIdRender}
          />
        )}
        {showDisclaimerText && (
          <OfferNowDisclaimer
            dataHcName={`${dataHcName}-disclaimer`}
            className={styles.DisclaimerText}
          />
        )}
      </form>
    </FormProvider>
  );
};
