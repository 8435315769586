import { DexpJobStatus } from '@hcs/types';

export const JOB_STATUS_LABELS: { [key in DexpJobStatus]: string } = {
  [DexpJobStatus.GeneratingWorkbook]: 'Generating report',
  [DexpJobStatus.CompletedWithErrors]: 'Download',
  [DexpJobStatus.Completed]: 'Download',
  [DexpJobStatus.Queued]: 'Queued',
  [DexpJobStatus.Created]: 'Created',
  [DexpJobStatus.Processing]: 'Processing',
  [DexpJobStatus.Error]: 'Error',
};

export const formatJobStatus = (status: DexpJobStatus) =>
  JOB_STATUS_LABELS[status];

export const jobIsCompleted = (status: DexpJobStatus) =>
  [DexpJobStatus.CompletedWithErrors, DexpJobStatus.Completed].includes(status);

export const formatJobStatusMessage = ({
  status,
  statusDisplay,
  statusMessage,
}: {
  status: DexpJobStatus;
  statusDisplay?: string;
  statusMessage?: string | null;
}) => {
  return statusDisplay || statusMessage || formatJobStatus(status);
};
