import React from 'react';
import classNames from 'classnames';

import { UserMenuButton, UserMenuButtonProps } from './UserMenuButton';

import styles from './UserMenu.module.css';

export const UserMenuHeaderButton = ({
  className,
  ...props
}: UserMenuButtonProps) => {
  return (
    <UserMenuButton
      {...props}
      className={classNames(styles.HeaderButton, className)}
    />
  );
};
