import React from 'react';

import { Toggle } from '@hcs/design-system';
import {
  HorizontalSelector,
  HorizontalSelectorOption,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import {
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportId,
} from '@hcs/types';
import { ChartType, MenuItemType } from '@hcs/types';
import { ArrayOneOrMore } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useNearbyFarmDocument } from '../../hooks/useNearbyFarmDocument';
import { useNearbyFilteredDocument } from '../../hooks/useNearbyFilteredDocument';
import { useReportConfig } from '../../hooks/useReportConfig';
import { TITLE_CONSTANTS } from '../../utils/charts.utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import { NearbyChartDisplay } from './NearbyChartDisplay';

import styles from './NearbyChart.module.css';

const OPTIONS: ArrayOneOrMore<HorizontalSelectorOption<MenuItemType>> = [
  {
    label: 'Sq Ft',
    value: MenuItemType.SQFT,
  },
  {
    label: '$ / Sq Ft',
    value: MenuItemType.PRICE_PER_SQ_FT,
  },
  {
    label: 'Lot',
    value: MenuItemType.SITE_AREA,
  },
  {
    label: '$ / Lot',
    value: MenuItemType.PRICE_SITE_AREA,
  },
  {
    label: 'Beds',
    value: MenuItemType.BEDS,
  },
  {
    label: 'Baths',
    value: MenuItemType.BATHS,
  },
  {
    label: 'Age',
    value: MenuItemType.AGE,
  },
];
interface Props {
  reportId: ReportId;
  className?: string;
  theme?: Partial<{ ChartLegend: string }>;
  onChartTypeChange?: (chartType: ChartType) => void;
}

const dataHcName = 'nearby-chart';
export const NEARBY_CHART_FEATURES = [
  ReportFeatures.NearbyPropertiesChart,
  ReportFeatures.CompsNearbyFilteredCharts,
];

export const NearbyChart = ({
  reportId,
  className,
  theme,
  onChartTypeChange,
}: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const [chartType, setChartType] = React.useState<ChartType>(
    reportConfig?.reportFeaturesSupport[ReportFeatures.NearbyPropertiesChart]
      ? ChartType.Nearby
      : ChartType.Comparables
  );
  const nearbyFarmQuery = useNearbyFarmDocument(reportId);
  const nearbyFilteredQuery = useNearbyFilteredDocument(reportId);
  const { isInitialLoading } = combineUseQueryResult([
    nearbyFarmQuery,
    nearbyFilteredQuery,
  ]);

  const charts =
    chartType === ChartType.Nearby
      ? nearbyFarmQuery.data?.data?.charts
      : nearbyFilteredQuery.data?.data.charts;
  const bothChartsNull =
    !nearbyFarmQuery.data?.data?.charts &&
    !nearbyFilteredQuery.data?.data.charts;
  const [value, setValue] = React.useState<MenuItemType | undefined>(
    OPTIONS[0].value
  );

  const handleChange = (option: ChartType) => {
    setChartType(option);
    onChartTypeChange?.(option);
  };

  const handleSelect = (newValue: MenuItemType) => {
    setValue(newValue);
  };
  if (isInitialLoading) {
    return (
      <div className={styles.NullState}>
        <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} absoluteCenter />
      </div>
    );
  }

  return (
    <div data-hc-name={dataHcName} className={className}>
      {charts ? (
        <>
          <div className={styles.Controls}>
            <HorizontalSelector
              dataHcName={`${dataHcName}-radio-menu`}
              value={value || OPTIONS[0].value}
              options={OPTIONS}
              onSelect={handleSelect}
            />
          </div>
          <div
            data-hc-name={`${dataHcName}-chart-container`}
            className={styles.Chart}
          >
            <NearbyChartDisplay
              charts={charts}
              chartType={chartType}
              valueSelected={value}
              theme={theme}
            />
          </div>
        </>
      ) : (
        <NullState
          title="Nearby Properties Unavailable"
          dataHcName={`${dataHcName}-nullstate`}
          absCenter
        >
          <span>
            Sorry! There aren’t enough nearby similar homes to generate this
            chart - but our data improves everyday. Please check again later!
          </span>
        </NullState>
      )}
      {!bothChartsNull && (
        <div className={styles.Controls}>
          <ReportFeaturesSupported
            reportId={reportId}
            reportFeatures={NEARBY_CHART_FEATURES}
            reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
          >
            <Toggle
              className={styles.ChartTypeToggle}
              dataHcName={`${dataHcName}-toggle`}
              value={chartType}
              options={[
                {
                  label: TITLE_CONSTANTS.NEARBY_PROPERTIES,
                  value: ChartType.Nearby,
                },
                {
                  label: TITLE_CONSTANTS.FILTERED_COMPARABLES,
                  value: ChartType.Comparables,
                },
              ]}
              onChange={handleChange}
            />
          </ReportFeaturesSupported>
        </div>
      )}
    </div>
  );
};
