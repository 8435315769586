import { OrdersTableOptions } from '@hcs/types';

export const DATA_HC_NAME_ORDERS_TABLE_CELL = 'order-table-cell';
export const ORDERS_PER_PAGE = 20;
export const ORDERS_TABLE_OPTIONS = [
  OrdersTableOptions.name,
  OrdersTableOptions.label,
  OrdersTableOptions.createdAt,
  OrdersTableOptions.actualDeliveryDate,
  OrdersTableOptions.orderItemsCount,
  OrdersTableOptions.orderType,
  OrdersTableOptions.status,
  OrdersTableOptions.action,
];
