import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  OrderItem,
  OrderItemsTableInOrderOptions,
  OrderItemsTableNeedReviewOptions,
  OrderItemsTableRemovedOptions,
} from '@hcs/types';

import { ORDER_ITEM_ADDRESS_CONFIG } from './Address';
import { ORDER_ITEM_CITY_CONFIG } from './City';
import { ORDER_ITEM_CREATED_AT_CONFIG } from './CreatedAt';
import { ORDER_ITEM_CUSTOMER_ITEM_ID_CONFIG } from './CustomerItemId';
import { ORDER_ITEM_DUE_DATE_CONFIG } from './DueDate';
import { ORDER_ITEM_ESTIMATED_VALUE_CONFIG } from './EstimatedValue';
import { ORDER_ITEM_IN_ORDER_ACTIONS_CONFIG } from './InOrderActions';
import { ORDER_ITEM_INSPECTION_CONDITION_CONFIG } from './InspectionCondition';
import { ORDER_ITEM_ISSUES_CONFIG } from './Issues';
import { ORDER_ITEM_NEED_REVIEW_ACTIONS_CONFIG } from './NeedReviewActions';
import { ORDER_ITEM_PROPERTY_TYPE_CONFIG } from './PropertyType';
import { ORDER_ITEM_STATE_CONFIG } from './State';
import { ORDER_ITEM_STATUS_CONFIG } from './Status';
import { ORDER_ITEM_VALUE_RANGE_CONFIG } from './ValueRange';
import { ORDER_ITEM_ZIP_CODE_CONFIG } from './ZipCode';

export interface DisplayProps {
  orderItem: OrderItem;
  orderId?: number;
}

export interface OrderItemsTableCellConfig {
  HeaderCell: {
    ({ ...tableHeaderCellProps }): JSX.Element;
    defaultProps: Partial<TableHeaderCellProps>;
  };
  ContentCell: {
    ({
      orderItem,
      orderId,
    }: {
      orderItem: OrderItem;
      orderId: number;
    }): JSX.Element;
    defaultProps: Partial<TableCellProps>;
  };
  label: string;
  sortable?: boolean;
}

export const IN_ORDER_TABLE_CELLS_CONFIG: {
  [key in OrderItemsTableInOrderOptions]: OrderItemsTableCellConfig;
} = {
  [OrderItemsTableInOrderOptions.CustomerItemId]:
    ORDER_ITEM_CUSTOMER_ITEM_ID_CONFIG,
  [OrderItemsTableInOrderOptions.PropertyType]: ORDER_ITEM_PROPERTY_TYPE_CONFIG,
  [OrderItemsTableInOrderOptions.Address]: ORDER_ITEM_ADDRESS_CONFIG,
  [OrderItemsTableInOrderOptions.CreatedAt]: ORDER_ITEM_CREATED_AT_CONFIG,
  [OrderItemsTableInOrderOptions.EstimatedValue]:
    ORDER_ITEM_ESTIMATED_VALUE_CONFIG,
  [OrderItemsTableInOrderOptions.ValueRange]: ORDER_ITEM_VALUE_RANGE_CONFIG,
  [OrderItemsTableInOrderOptions.InspectionCondition]:
    ORDER_ITEM_INSPECTION_CONDITION_CONFIG,
  [OrderItemsTableInOrderOptions.DueDate]: ORDER_ITEM_DUE_DATE_CONFIG,
  [OrderItemsTableInOrderOptions.Status]: ORDER_ITEM_STATUS_CONFIG,
  [OrderItemsTableInOrderOptions.InOrderActions]:
    ORDER_ITEM_IN_ORDER_ACTIONS_CONFIG,
};

export const NEED_REVIEW_TABLE_CELLS_CONFIG: {
  [key in OrderItemsTableNeedReviewOptions]: OrderItemsTableCellConfig;
} = {
  [OrderItemsTableNeedReviewOptions.CustomerItemId]:
    ORDER_ITEM_CUSTOMER_ITEM_ID_CONFIG,
  [OrderItemsTableNeedReviewOptions.PropertyType]:
    ORDER_ITEM_PROPERTY_TYPE_CONFIG,
  [OrderItemsTableNeedReviewOptions.Address]: ORDER_ITEM_ADDRESS_CONFIG,
  [OrderItemsTableNeedReviewOptions.City]: ORDER_ITEM_CITY_CONFIG,
  [OrderItemsTableNeedReviewOptions.State]: ORDER_ITEM_STATE_CONFIG,
  [OrderItemsTableNeedReviewOptions.ZipCode]: ORDER_ITEM_ZIP_CODE_CONFIG,
  [OrderItemsTableNeedReviewOptions.Issues]: ORDER_ITEM_ISSUES_CONFIG,
  [OrderItemsTableNeedReviewOptions.NeedReviewActions]:
    ORDER_ITEM_NEED_REVIEW_ACTIONS_CONFIG,
};

export const REMOVED_TABLE_CELLS_CONFIG: {
  [key in OrderItemsTableRemovedOptions]: OrderItemsTableCellConfig;
} = {
  [OrderItemsTableRemovedOptions.CustomerItemId]:
    ORDER_ITEM_CUSTOMER_ITEM_ID_CONFIG,
  [OrderItemsTableRemovedOptions.PropertyType]: ORDER_ITEM_PROPERTY_TYPE_CONFIG,
  [OrderItemsTableRemovedOptions.Address]: ORDER_ITEM_ADDRESS_CONFIG,
  [OrderItemsTableRemovedOptions.City]: ORDER_ITEM_CITY_CONFIG,
  [OrderItemsTableRemovedOptions.State]: ORDER_ITEM_STATE_CONFIG,
  [OrderItemsTableRemovedOptions.ZipCode]: ORDER_ITEM_ZIP_CODE_CONFIG,
  [OrderItemsTableRemovedOptions.Issues]: ORDER_ITEM_ISSUES_CONFIG,
};
