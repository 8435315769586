import React, { Fragment, useMemo } from 'react';

import { ItemObjInShoppingCart } from '@hcs/types';

import { Checkbox } from '../../../../controls-and-inputs/inputs/Checkbox';
import { Table, TableCell, TableRow } from '../../../tables/Table';

import styles from './ShoppingCartTable.module.css';

interface ShoppingCartTableProps {
  dataHcName: string;
  itemsInShoppingCart?: Record<string, ItemObjInShoppingCart>;
  onSelectItem: (itemId: string) => void;
  onDeselectAndLeaveInCart: (itemId: string) => void;
  onRemoveFromCart: (itemId: string) => void;
  removeFromCartCtaText?: string;
}

export const ShoppingCartTable = ({
  dataHcName,
  itemsInShoppingCart,
  onSelectItem,
  onDeselectAndLeaveInCart,
  onRemoveFromCart,
  removeFromCartCtaText = 'Remove',
}: ShoppingCartTableProps) => {
  const shoppingCartTableDataHcName = `${dataHcName}-table`;
  const tableRows = useMemo(() => {
    if (!itemsInShoppingCart) return null;
    const itemIdsInShoppingCart = Object.keys(itemsInShoppingCart);

    return itemIdsInShoppingCart?.map((itemId) => {
      const isUnselectedInShoppingCart =
        !itemsInShoppingCart[itemId]?.isSelected;

      return (
        <Fragment key={`${shoppingCartTableDataHcName}-endpoint-${itemId}`}>
          <TableRow
            key={`${shoppingCartTableDataHcName}-endpoint-row-${itemId}`}
            highlightOnHover
            className={styles.TableRow}
          >
            <TableCell width={40} noBorder className={styles.CheckboxContainer}>
              <Checkbox
                dataHcName={`${shoppingCartTableDataHcName}-checkbox-${itemId}`}
                checked={!isUnselectedInShoppingCart}
                onChange={(checked) => {
                  if (checked) {
                    onSelectItem(itemId);
                  } else {
                    onDeselectAndLeaveInCart(itemId);
                  }
                }}
              />
            </TableCell>
            <TableCell noBorder className={styles.ItemLabelContainer}>
              <div className={styles.ItemLabel}>
                {itemsInShoppingCart[itemId]?.label}
              </div>
              {isUnselectedInShoppingCart && !!removeFromCartCtaText && (
                <button
                  className={styles.RemoveButton}
                  data-hc-name={`${shoppingCartTableDataHcName}-remove-button-${itemId}`}
                  onClick={() => {
                    onRemoveFromCart(itemId);
                  }}
                >
                  {removeFromCartCtaText}
                </button>
              )}
            </TableCell>
            {itemsInShoppingCart[itemId]?.rightSideLabel && (
              <TableCell noBorder className={styles.RightSideLabel}>
                {itemsInShoppingCart[itemId]?.rightSideLabel}
              </TableCell>
            )}
          </TableRow>
        </Fragment>
      );
    });
  }, [itemsInShoppingCart]);

  return (
    <Table
      scrollDisabled
      className={styles.ShoppingCartTable}
      dataHcName={`${shoppingCartTableDataHcName}`}
    >
      {tableRows}
    </Table>
  );
};
