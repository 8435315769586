import { CompFields, PropertyStateFields } from '@hcs/types';

type FieldType = PropertyStateFields | CompFields;

export const COMP_FIELD_ORDER_ADJUST: FieldType[] = [
  PropertyStateFields.currentValue,
  PropertyStateFields.currentValuePerSqFt,
  PropertyStateFields.currentStatus,
  PropertyStateFields.currentListDate,
  PropertyStateFields.currentListingPrice,
  PropertyStateFields.currentListingPricePerSqFt,
  PropertyStateFields.lastCloseDate,
  PropertyStateFields.lastClosePrice,
  PropertyStateFields.lastClosePricePerSqFt,
  // Adjustment Fields
  PropertyStateFields.currentPrice,
  CompFields.hcAdjustmentDate,
  CompFields.hcAdjustmentPropertyDetails,
  CompFields.userTotalDollarAdjustment,
  CompFields.adjustedCompValue,
  CompFields.adjustedCompValuePerSqFt,
  // NEED TO ADD FIELDS FOR propertyValueAdjusted
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
  PropertyStateFields.lotSize,
  PropertyStateFields.propertyType,
  PropertyStateFields.yearBuilt,
  PropertyStateFields.garageType,
  PropertyStateFields.garageSpaces,
  PropertyStateFields.stories,
  PropertyStateFields.basementHas,
  PropertyStateFields.pool,
  PropertyStateFields.associationName,
  PropertyStateFields.associationFee,
  PropertyStateFields.associationFeeFrequency,
  PropertyStateFields.associationFeeIncludes,
  PropertyStateFields.taxYear,
  PropertyStateFields.taxAmount,
  PropertyStateFields.subdivisionName,
  PropertyStateFields.zoning,
  PropertyStateFields.daysOnMarketCumulative,
];

export const COMP_FIELD_ORDER_TABLE: FieldType[] = [
  CompFields.similarity,
  CompFields.distance,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
  PropertyStateFields.lotSize,
  PropertyStateFields.propertyType,
  PropertyStateFields.yearBuilt,
  PropertyStateFields.currentStatus,
  PropertyStateFields.subdivisionName,
  PropertyStateFields.lastCloseDate,
  PropertyStateFields.lastClosePrice,
  PropertyStateFields.lastClosePricePerSqFt,
  PropertyStateFields.currentListDate,
  PropertyStateFields.currentListingPrice,
  PropertyStateFields.currentListingPricePerSqFt,
  PropertyStateFields.daysOnMarketActive,
  PropertyStateFields.daysOnMarketCumulative,
  PropertyStateFields.currentValue,
  PropertyStateFields.pool,
  PropertyStateFields.garageSpaces,
  PropertyStateFields.stories,
  PropertyStateFields.basementHas,
  PropertyStateFields.currentDistressed,
  PropertyStateFields.currentFlip,
  PropertyStateFields.condition,
];

export const RENTAL_COMP_FIELD_ORDER_ADJUST: FieldType[] = [
  PropertyStateFields.currentValueRental,
  PropertyStateFields.currentValuePerSqFtRental,
  PropertyStateFields.currentStatusRental,
  PropertyStateFields.currentListDateRental,
  PropertyStateFields.currentListingPriceRental,
  PropertyStateFields.currentListingPricePerSqFtRental,
  PropertyStateFields.lastCloseDateRental,
  PropertyStateFields.lastClosePriceRental,
  PropertyStateFields.lastClosePricePerSqFtRental,
  // Adjustment Fields
  PropertyStateFields.currentPriceRental,
  CompFields.hcAdjustmentDateRental,
  CompFields.userTotalDollarAdjustment,
  CompFields.adjustedCompValueRental,
  CompFields.adjustedCompValuePerSqFtRental,
  //
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
  PropertyStateFields.lotSize,
  PropertyStateFields.propertyType,
  PropertyStateFields.yearBuilt,
  PropertyStateFields.garageType,
  PropertyStateFields.garageSpaces,
  PropertyStateFields.stories,
  PropertyStateFields.basementHas,
  PropertyStateFields.pool,
  PropertyStateFields.associationName,
  PropertyStateFields.associationFee,
  PropertyStateFields.associationFeeFrequency,
  PropertyStateFields.associationFeeIncludes,
  PropertyStateFields.taxYear,
  PropertyStateFields.taxAmount,
  PropertyStateFields.subdivisionName,
  PropertyStateFields.zoning,
  PropertyStateFields.daysOnMarketCumulative,
];

export const RENTAL_COMP_FIELD_ORDER_TABLE: FieldType[] = [
  CompFields.similarity,
  CompFields.distance,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
  PropertyStateFields.lotSize,
  PropertyStateFields.propertyType,
  PropertyStateFields.yearBuilt,
  PropertyStateFields.currentStatusRental,
  PropertyStateFields.subdivisionName,
  PropertyStateFields.lastCloseDateRental,
  PropertyStateFields.lastClosePriceRental,
  PropertyStateFields.lastClosePricePerSqFtRental,
  PropertyStateFields.currentListDateRental,
  PropertyStateFields.currentListingPriceRental,
  PropertyStateFields.currentListingPricePerSqFtRental,
  PropertyStateFields.currentValueRental,
  PropertyStateFields.pool,
  PropertyStateFields.garageSpaces,
  PropertyStateFields.stories,
  PropertyStateFields.basementHas,
];
