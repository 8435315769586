import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './UserMenu.module.css';

export interface UserMenuButtonProps {
  dataHcName: string;
  icon: ReactNode;
  label: ReactNode;
  description?: ReactNode;
  className?: string;
  onClick?: VoidFunction;
  // Wraps the li with a NavLink
  navigateTo?: string;
}
export const UserMenuButton = ({
  dataHcName,
  className,
  icon,
  label,
  description,
  navigateTo,
  onClick,
}: UserMenuButtonProps) => {
  const button = (
    <li
      data-hc-name={dataHcName}
      className={classNames(styles.Button, className)}
      onClick={onClick}
    >
      <div
        className={styles.ButtonIcon}
        data-hc-name={`${dataHcName}-icon-container`}
      >
        {icon}
      </div>
      <div className={styles.ButtonContent}>
        <div
          className={styles.ButtonLabel}
          data-hc-name={`${dataHcName}-label`}
        >
          {label}
        </div>
        {description && (
          <div
            className={styles.ButtonDescription}
            data-hc-name={`${dataHcName}-description`}
          >
            {description}
          </div>
        )}
      </div>
    </li>
  );
  if (navigateTo) {
    return (
      <NavLink to={navigateTo}>
        <li
          data-hc-name={dataHcName}
          className={classNames(styles.Button, className)}
          onClick={onClick}
        >
          <div
            data-hc-name={`${dataHcName}-icon-container`}
            className={styles.ButtonIcon}
          >
            {icon}
          </div>
          <div className={styles.ButtonContent}>
            <div
              className={styles.ButtonLabel}
              data-hc-name={`${dataHcName}-label`}
            >
              {label}
            </div>
            {description && (
              <div
                className={styles.ButtonDescription}
                data-hc-name={`${dataHcName}-description`}
              >
                {description}
              </div>
            )}
          </div>
        </li>
      </NavLink>
    );
  } else {
    return button;
  }
};
