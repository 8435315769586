import React, { useState } from 'react';

import { AddressSearch } from '@hcs/address-search';
import { Button } from '@hcs/design-system';
import { TextButton } from '@hcs/design-system';
import { AddressSearchHit } from '@hcs/types';
import { AddPortfolioAssetsAddressPayload } from '@hcs/types';

import { useAddPortfolioAssets } from '../../hooks/useAddPortfolioAssets';

import styles from './ManuallyAddProperty.module.css';

const DEFAULT_DATA_HC_NAME = 'manually-add-property';
interface Props {
  portfolioId: string;
  dataHcName?: string;
  onClickGoToPortfolio: (portfolioId: string) => void;
}

export const ManuallyAddProperty = ({
  dataHcName = DEFAULT_DATA_HC_NAME,
  portfolioId,
  onClickGoToPortfolio,
}: Props) => {
  const [selectedAddress, setSelectedAddress] = useState<
    AddressSearchHit['fields'] | undefined
  >();
  const [addedAddressesNames, setAddedAddressesNames] = useState<
    AddressSearchHit['fields']['fullLine'][]
  >([]);
  const [value, setValue] = useState('');

  const addPortfolioAssetsMutation = useAddPortfolioAssets({
    onSuccess: () => {
      setAddedAddressesNames((prev) =>
        selectedAddress ? [...prev, selectedAddress.fullLine] : prev
      );
    },
  });

  const handleAddPropertiesBtnClick = () => {
    if (selectedAddress?.slug && portfolioId) {
      const addressPayload: AddPortfolioAssetsAddressPayload = {
        addressSlug: selectedAddress.slug,
      };

      addPortfolioAssetsMutation.mutate(
        {
          portfolioId,
          addressesPayload: [addressPayload],
        },
        {
          onSuccess: () => setValue(''),
        }
      );
    }
  };

  return (
    <div className={styles.ManuallyAddPropertyContainer}>
      <div className={styles.AddressSearchContainer}>
        <AddressSearch
          placeholder={'Enter Property Address'}
          onSelect={(addressSearchHit) => {
            setSelectedAddress(addressSearchHit.fields);
          }}
          disableClear
          hideChevron
          inputStyle="search"
          controlled={{
            value,
            setValue,
          }}
        />
      </div>

      <Button
        className={styles.AddPropertyButton}
        dataHcName={`${dataHcName}-add-property-button`}
        onClick={handleAddPropertiesBtnClick}
        disabled={!selectedAddress}
        loading={addPortfolioAssetsMutation.isLoading}
      >
        Add Property
      </Button>

      {addedAddressesNames.length ? (
        <>
          <TextButton
            className={styles.AddPropertyButton}
            dataHcName={`${dataHcName}-go-to-portfolio-button`}
            onClick={() => onClickGoToPortfolio(portfolioId)}
          >
            Go to Portfolio
          </TextButton>
          <div data-hc-name={`${dataHcName}-added-properties-text`}>
            <div
              className={styles.AddedPropertiesHeader}
              data-hc-name={`${dataHcName}-added-properties-header`}
            >
              <strong>You&apos;ve added these properties:</strong>
            </div>
            {addedAddressesNames.map((addressName) => (
              <div key={addressName}>{addressName}</div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};
