import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { IconButton } from '@hcs/design-system';
import { TextButton } from '@hcs/design-system';
import { InputNumber, InputNumberProps } from '@hcs/design-system';
import { RemoveIcon } from '@hcs/design-system';
import {
  BulkEditBuyBoxFormData,
  BulkEditRangeActiveFormData,
  BuyBoxRangeFormData,
} from '@hcs/types';
import { getRelatedTargetHcName } from '@hcs/utils';

import styles from './BulkEditRangeField.module.css';
import commonStyles from './common.module.css';

interface BulkEditRangeFieldProps {
  dataHcName: string;
  className?: string;
  minName: keyof BuyBoxRangeFormData;
  minActiveName: keyof BulkEditRangeActiveFormData;
  maxName: keyof BuyBoxRangeFormData;
  maxActiveName: keyof BulkEditRangeActiveFormData;
  shouldFormat?: InputNumberProps['shouldFormat'];
  isFloat?: boolean;
}

export const BulkEditRangeField = (props: BulkEditRangeFieldProps) => {
  const {
    dataHcName,
    className,
    minName,
    minActiveName,
    maxName,
    maxActiveName,
    shouldFormat = {
      shouldFormatNumber: true,
    },
    isFloat = false,
  } = props;
  const {
    control,
    formState: { errors },
    trigger,
    register,
    setValue,
  } = useFormContext<BulkEditBuyBoxFormData>();
  const [isMinChangeActive, isMaxChangeActive] = useWatch({
    name: [minActiveName, maxActiveName],
    control,
  });

  const maxHcName = `${dataHcName}-max-input`;
  const minHcName = `${dataHcName}-min-input`;

  return (
    <div className={className} data-hc-name={dataHcName}>
      <div className={styles.BulkRangeContainer}>
        {isMinChangeActive ? (
          <div className={styles.BulkRangeInputGroup}>
            <Controller
              name={minName}
              control={control}
              render={({ field }) => (
                <>
                  <InputNumber
                    placeholder="No Min"
                    dataHcName={minHcName}
                    className={commonStyles.RangeInput}
                    {...field}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={(e) => {
                      field.onBlur();

                      // if focus is not shifted to the MAX input, force validation of MAX
                      if (getRelatedTargetHcName(e) !== maxHcName) {
                        trigger(maxName);
                      }
                    }}
                    shouldFormat={shouldFormat}
                    isFloat={isFloat}
                    error={errors[minName]?.message}
                  />
                  <IconButton
                    className={styles.CloseButton}
                    dataHcName={`${dataHcName}-close-min-button`}
                    onClick={() => {
                      setValue(minActiveName, false);
                      field.onChange(null);
                      trigger(minName);
                    }}
                    icon={
                      <RemoveIcon
                        dataHcName={`${dataHcName}-close-min-button-icon`}
                        size="lg"
                        color="error-10"
                      />
                    }
                  />
                </>
              )}
            />
          </div>
        ) : (
          <TextButton
            dataHcName={`${dataHcName}-change-min-button`}
            label="Change Min Value"
            onClick={() => setValue(minActiveName, true)}
          />
        )}
        {isMaxChangeActive ? (
          <div className={styles.BulkRangeInputGroup}>
            <Controller
              name={maxName}
              control={control}
              render={({ field }) => (
                <>
                  <InputNumber
                    placeholder="No Max"
                    dataHcName={maxHcName}
                    className={commonStyles.RangeInput}
                    {...field}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={(e) => {
                      field.onBlur();

                      // if focus is not shifted to the MIN input, force validation of MIN
                      if (getRelatedTargetHcName(e) !== minHcName) {
                        trigger(minName);
                      }
                    }}
                    shouldFormat={shouldFormat}
                    isFloat={isFloat}
                    error={errors[maxName]?.message}
                  />
                  <IconButton
                    className={styles.CloseButton}
                    dataHcName={`${dataHcName}-close-max-button`}
                    onClick={() => {
                      setValue(maxActiveName, false);
                      field.onChange(null);
                      trigger(maxName);
                    }}
                    icon={
                      <RemoveIcon
                        dataHcName={`${dataHcName}-close-max-button-icon`}
                        size="lg"
                        color="error-10"
                      />
                    }
                  />
                </>
              )}
            />
          </div>
        ) : (
          <TextButton
            dataHcName={`${dataHcName}-change-max-button`}
            label="Change Max Value"
            onClick={() =>
              setValue(maxActiveName, true, { shouldValidate: true })
            }
          />
        )}
        <input key={minActiveName} type="hidden" {...register(minActiveName)} />
        <input key={maxActiveName} type="hidden" {...register(maxActiveName)} />
      </div>
    </div>
  );
};
