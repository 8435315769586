import { useFeatureFlagsForUser } from '@hcs/huell';
import { combineUseQueryResult } from '@hcs/utils';

import { checkHasCapability } from '../utils/capabilityCheck.utils';

import { useCapabilitiesForUser } from './useCapabilitiesForUser';

export const useCapabilityCheckForUser = (
  userId: number | undefined,
  capabilityKey: string
) => {
  const capabilitiesQuery = useCapabilitiesForUser(userId);
  const ffQuery = useFeatureFlagsForUser();
  const { data: featureFlags } = ffQuery;
  const combinedQuery = combineUseQueryResult([capabilitiesQuery, ffQuery]);
  return {
    ...combinedQuery,
    data: capabilitiesQuery.data
      ? checkHasCapability(capabilityKey, capabilitiesQuery.data, featureFlags)
      : undefined,
  };
};
