import React, { useMemo } from 'react';
import classNames from 'classnames';

import {
  Anchor,
  ErrorIcon,
  HorizontalSelector,
  InfoTooltip,
  Switch,
} from '@hcs/design-system';
import {
  LayersControlConfig,
  VectilesLayerSource,
  VectilesMetricGroups,
  VectilesMetricIds,
} from '@hcs/types';

import {
  VECTILES_METRICS_CONFIG,
  VECTILES_METRICS_GROUP_CONFIG,
} from '../../constants';
import { useHcMap } from '../../hooks/useHcMap';
import { useHcMapLayers } from '../../hooks/useHcMapLayers';
import { HcMapLayerLegend } from '../HcMapLayerLegend';

import styles from './VectilesMetricControl.module.css';

interface Props {
  mapId: string;
  className?: string;
  toggles?: LayersControlConfig['toggles'];
  hideMonochrome?: boolean;
}
const dataHcName = 'heatmaps-control';
export const VectilesMetricControl = ({
  mapId,
  className,
  toggles,
  hideMonochrome,
}: Props) => {
  const { vectilesMetricGroupConfig } = useHcMapLayers(mapId);
  const {
    mapState,
    actions: {
      hcMapChangeVectilesMetric,
      hcMapShowMarkers,
      hcMapLayersShowMonochrome,
      hcMapViewportChange,
    },
  } = useHcMap(mapId);

  const zoom = mapState?.viewport.zoom;
  const latitude = mapState?.viewport.latitude;
  const longitude = mapState?.viewport.longitude;

  const zoomError = useMemo(() => {
    const vectilesMetricGroup = mapState?.heatmap.vectilesMetricGroup;
    const groupConfig =
      vectilesMetricGroup && VECTILES_METRICS_GROUP_CONFIG[vectilesMetricGroup];
    const maxZoom = groupConfig
      ? groupConfig.vectilesLayers.reduce((acc, vectileLayer) => {
          return Math.max(acc, vectileLayer.zoomRange.max);
        }, 0)
      : null;
    const minZoom = groupConfig
      ? groupConfig.vectilesLayers.reduce((acc, vectileLayer) => {
          return Math.min(acc, vectileLayer.zoomRange.min);
        }, Infinity)
      : null;

    if (zoom && minZoom && maxZoom) {
      const groupZoomRange: VectilesLayerSource['zoomRange'] = {
        max: maxZoom,
        min: minZoom,
      };
      groupConfig?.vectilesLayers.forEach(({ zoomRange }) => {
        if (zoomRange.min < groupZoomRange.min) {
          groupZoomRange.min = zoomRange.min;
        }
        if (zoomRange.max > groupZoomRange.max) {
          groupZoomRange.max = zoomRange.max;
        }
      });
      return zoom < groupZoomRange.min
        ? minZoom
        : zoom > groupZoomRange.max
        ? maxZoom
        : undefined;
    }
    return undefined;
  }, [mapState?.heatmap.vectilesMetricGroup, zoom]);

  if (
    !vectilesMetricGroupConfig ||
    !mapState?.heatmap.vectilesMetricId ||
    !mapState.heatmap.vectilesMetricGroup
  )
    return null;
  const {
    markers: { showMarkers },
    heatmap: { vectilesMetricId, vectilesMetricGroup, showMonochrome },
  } = mapState;
  const handleMenuSelect = (newVectilesMetric: VectilesMetricIds) => {
    hcMapChangeVectilesMetric({
      mapId,
      vectilesMetricId: newVectilesMetric,
    });
  };

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.VectilesMetricControl, className, {
        [styles.VectilesMetricControlNoToggles]:
          toggles?.showProperties === false,
      })}
    >
      <div data-hc-name={`${dataHcName}-header`} className={styles.Header}>
        <label
          data-hc-name={`${dataHcName}-vectiles-metric-group`}
          className={styles.MetricGroupLabel}
        >
          {vectilesMetricGroupConfig.label}
        </label>

        {vectilesMetricGroupConfig.vectilesMetricIds.length > 1 && (
          <HorizontalSelector
            className={styles.MetricToggle}
            currentMenuIndicatorHeight="0"
            dotSeparator
            options={vectilesMetricGroupConfig.vectilesMetricIds.map((m) => {
              const { label } = VECTILES_METRICS_CONFIG[m];
              return {
                value: m,
                label,
              };
            })}
            value={vectilesMetricId}
            dataHcName={`${dataHcName}-vectiles-metric-toggle`}
            onSelect={handleMenuSelect}
            theme={{
              ListItem: styles.ListItem,
            }}
          />
        )}
      </div>
      <div className={styles.Content}>
        <div className={styles.Legend}>
          {zoomError ? (
            <div className={styles.ErrorLabel}>
              <ErrorIcon className={styles.ErrorIcon} />
              To display the heatmap layer, please
              <Anchor
                className={styles.ZoomLink}
                dataHcName={`${dataHcName}-zoom-link`}
                onClick={() => {
                  if (latitude && longitude) {
                    hcMapViewportChange({
                      mapId,
                      viewport: {
                        latitude,
                        longitude,
                        zoom: zoomError,
                      },
                    });
                  }
                }}
              >
                zoom here
              </Anchor>
            </div>
          ) : (
            <HcMapLayerLegend mapId={mapId} />
          )}
        </div>
        {!zoomError && (
          <div className={styles.Toggles}>
            {toggles?.showProperties !== false && (
              <>
                <label
                  data-hc-name={`${dataHcName}-show-properties-label`}
                  className={styles.ToggleLabel}
                >
                  Show Properties
                </label>
                <Switch
                  switchOnOption={{ value: true }}
                  switchOffOption={{ value: false }}
                  onChange={(showMarkers: boolean) => {
                    // Note toggle is reversed so !showProperties is actual show markers
                    hcMapShowMarkers({ mapId, showMarkers });
                  }}
                  value={showMarkers}
                  theme={{
                    SwitchOn: styles.SwitchOn,
                  }}
                  className={styles.Switch}
                  dataHcName={`${dataHcName}-show-properties-toggle`}
                />
              </>
            )}
            {!hideMonochrome &&
              vectilesMetricGroup !== VectilesMetricGroups.Schools && (
                <div data-hc-name={`${dataHcName}-toggle-container`}>
                  <label
                    data-hc-name={`${dataHcName}-monochrome-label`}
                    className={styles.ToggleLabel}
                  >
                    Monochrome{' '}
                    <InfoTooltip
                      dataHcName={`${dataHcName}-monochrome-tooltip`}
                      isLargeTooltip
                      description="Monochrome heatmap layers can be used as an alternative to the standard color-based heatmaps to aid users with difficulties distinguishing colors."
                      size="small"
                    />
                  </label>

                  <Switch
                    switchOnOption={{ value: true }}
                    switchOffOption={{ value: false }}
                    onChange={(showMonochrome: boolean) => {
                      hcMapLayersShowMonochrome({
                        mapId,
                        visible: showMonochrome,
                      });
                    }}
                    theme={{
                      SwitchOn: styles.SwitchOn,
                    }}
                    value={!!showMonochrome}
                    className={styles.Switch}
                    dataHcName={`${dataHcName}-show-monochrome-toggle`}
                  />
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};
