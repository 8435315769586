import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { DateStr } from '@hcs/types';
import { formatDateLong } from '@hcs/utils';

import { KeywordSearchText } from '../KeywordSearchText/KeywordSearchText';

import styles from './KeywordSearchListingRemarks.module.css';

export interface KeywordSearchListingRemarksProps {
  /** Keyword Search Instance Key */
  keywordSearchKey?: string;
  remarks: string | null | undefined;
  date: DateStr | string | null | undefined;
  /** Use rental listing remarks */
  isRental?: boolean;
  className?: string;
  isLoading?: boolean;
}
const dataHcName = 'keyword-search-listing-remarks';
export const KeywordSearchListingRemarks = ({
  className,
  keywordSearchKey,
  isLoading,
  isRental,
  remarks,
  date,
}: KeywordSearchListingRemarksProps) => {
  const remarksUnavailable = !remarks;
  return (
    <span data-hc-name={dataHcName} className={className}>
      <span className={styles.KeywordSearchListingRemarks}>
        {isLoading ? (
          <>
            <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
            <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
            <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
          </>
        ) : remarksUnavailable ? (
          <span
            className={styles.Date}
            data-hc-name={`${dataHcName}-null-message`}
          >
            Public remarks unavailable
          </span>
        ) : keywordSearchKey ? (
          <KeywordSearchText
            dataHcName={`${dataHcName}-text`}
            textToHighlight={remarks || ''}
            searchKey={keywordSearchKey}
          />
        ) : (
          <span data-hc-name={`${dataHcName}-text`}>{remarks}</span>
        )}
      </span>
      {!remarksUnavailable && date && (
        <span className={styles.Date} data-hc-name={`${dataHcName}-date`}>
          {formatDateLong(date)}
        </span>
      )}
    </span>
  );
};
