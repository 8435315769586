import React, { useMemo } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Input } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { StatusMessage } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import {
  AccRoleAppAccessKeys,
  AccRoleExternalRoleKeys,
  Role,
} from '@hcs/types';
import { capitalizeFirstLetter, logException } from '@hcs/utils';

import { RoleSwitch } from '../../components/RoleSwitch';
import { useCreateInvitation } from '../../hooks/useCreateInvitation';
import { OrgRoleGroups, useOrgRoleGroups } from '../../hooks/useOrgRoleGroups';

import styles from './InviteUser.module.css';

interface CreateInviteForm {
  firstName: string;
  lastName: string;
  email: string;
  roleKeys: Role['key'][];
  orderManagerRoleKeys: Role['key'][];
  excludedProductAccessRoles: Role['key'][];
}

interface RolesFieldProps {
  roleGroups: OrgRoleGroups;
  orgId: number | undefined;
}
const RolesField = ({ roleGroups }: RolesFieldProps) => {
  const { control } = useFormContext<CreateInviteForm>();

  return (
    <Controller
      name="roleKeys"
      control={control}
      render={({ field }) => {
        const { onChange, value: fieldValue } = field;
        const fieldValueNotUndefined = fieldValue || [];
        const otherRolesSwitches = roleGroups.otherRoles.map((role) => {
          return (
            <RoleSwitch
              className={styles.FormRow}
              key={role.key}
              value={fieldValueNotUndefined.includes(role.key)}
              role={role}
              onChange={(value) => {
                if (value) {
                  onChange([...fieldValueNotUndefined, role.key]);
                } else {
                  onChange(
                    fieldValueNotUndefined?.filter((v) => v !== role.key)
                  );
                }
              }}
            />
          );
        });
        return <span>{otherRolesSwitches}</span>;
      }}
    />
  );
};

const OrderManagerRolesField = ({ roleGroups }: RolesFieldProps) => {
  const { control } = useFormContext<CreateInviteForm>();

  return (
    <Controller
      name="orderManagerRoleKeys"
      control={control}
      render={({ field }) => {
        const { onChange, value: fieldValue } = field;
        const fieldValueNotUndefined = fieldValue || [];
        const omRoleSwitches = roleGroups.omAccessRoles.map((role) => {
          return (
            <RoleSwitch
              className={styles.FormRow}
              key={role.key}
              value={fieldValueNotUndefined.includes(role.key)}
              role={role}
              onChange={(value) => {
                if (value) {
                  onChange([...fieldValueNotUndefined, role.key]);
                } else {
                  onChange(
                    fieldValueNotUndefined?.filter((v) => v !== role.key)
                  );
                }
              }}
            />
          );
        });
        return <div className={styles.SubToggles}>{omRoleSwitches}</div>;
      }}
    />
  );
};

const ProductAccessField = ({ roleGroups, orgId }: RolesFieldProps) => {
  const { control } = useFormContext<CreateInviteForm>();

  return (
    <Controller
      name="excludedProductAccessRoles"
      control={control}
      render={({ field }) => {
        const { onChange, value: fieldValue } = field;
        const fieldValueNotUndefined = fieldValue || [];
        const productAccessRoleSwitches = roleGroups.productAccessRoles.map(
          (productAccessRoleConfig) => {
            const { role, appConfig } = productAccessRoleConfig;
            const accessRoleSwitch = (
              <RoleSwitch
                key={role.key}
                className={styles.FormRow}
                appConfig={appConfig}
                value={!fieldValueNotUndefined.includes(role.key)}
                role={role}
                onChange={(value) => {
                  if (value) {
                    onChange(
                      fieldValueNotUndefined.filter((v) => v !== role.key)
                    );
                  } else {
                    onChange([...fieldValueNotUndefined, role.key]);
                  }
                }}
              />
            );
            if (role.key === AccRoleAppAccessKeys.AgileSuite) {
              return (
                <>
                  {accessRoleSwitch}
                  <OrderManagerRolesField
                    key="om-roles"
                    roleGroups={roleGroups}
                    orgId={orgId}
                  />
                </>
              );
            } else {
              return accessRoleSwitch;
            }
          }
        );
        return <span>{productAccessRoleSwitches}</span>;
      }}
    />
  );
};

export const inviteValidationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .max(80)
    .email('Must be a valid email')
    .required('Email is required'),
});

type InviteUserProps = {
  orgId?: number;
  actionsPortalIdRender?: string;
  onSuccess?: VoidFunction;
};
interface InviteUserInternalFormProps {
  roleGroups: OrgRoleGroups;
  defaultValues: {
    roleKeys: Role['key'][];
    orderManagerRoleKeys: Role['key'][];
    excludedProductAccessRoles: Role['key'][];
  };
}
const dataHcName = 'invite-user';
const InviteUserInner = ({
  actionsPortalIdRender,
  defaultValues,
  onSuccess,
  orgId,
  roleGroups,
}: InviteUserProps & InviteUserInternalFormProps) => {
  const formMethods = useForm<CreateInviteForm>({
    mode: 'onBlur',
    resolver: yupResolver(inviteValidationSchema),
    defaultValues: defaultValues,
  });

  const mutation = useCreateInvitation({
    onSuccess: () => {
      mutation.reset();
      formMethods.reset(defaultValues);
      onSuccess?.();
    },
  });

  const onSubmit: SubmitHandler<CreateInviteForm> = (formData) => {
    if (!orgId) {
      logException('InviteUserDialog: onSubmit - orgId was unexpected null');
      return;
    }
    const { roleKeys, orderManagerRoleKeys, ...restFormData } = formData;
    mutation.mutate({
      orgId,
      ...restFormData,
      // combine om roles and other roles
      roleKeys: [...formData.roleKeys, ...formData.orderManagerRoleKeys],
    });
  };

  return (
    <div className={styles.ContentContainer}>
      <FormProvider {...formMethods}>
        <form>
          <DialogInputLayout
            label="First Name"
            required
            dataHcName={`${dataHcName}-first-name-field`}
            className={styles.FormRow}
          >
            <Controller
              name="firstName"
              control={formMethods.control}
              render={({ field }) => {
                return (
                  <Input
                    dataHcName={`${dataHcName}-first-name-field`}
                    placeholder="First Name"
                    {...field}
                    value={field.value || ''}
                    error={formMethods.formState.errors.firstName?.message}
                  />
                );
              }}
            />
          </DialogInputLayout>
          <DialogInputLayout
            label="Last Name"
            required
            dataHcName={`${dataHcName}-last-name-field`}
            className={styles.FormRow}
          >
            <Controller
              name="lastName"
              control={formMethods.control}
              render={({ field }) => {
                return (
                  <Input
                    dataHcName={`${dataHcName}-last-name-field`}
                    placeholder="Last Name"
                    {...field}
                    value={field.value || ''}
                    error={formMethods.formState.errors.lastName?.message}
                  />
                );
              }}
            />
          </DialogInputLayout>
          <DialogInputLayout
            label="Email"
            required
            dataHcName={`${dataHcName}-email-field`}
            className={styles.FormRow}
          >
            <Controller
              name="email"
              control={formMethods.control}
              render={({ field }) => {
                return (
                  <Input
                    dataHcName={`${dataHcName}-email-field-input`}
                    placeholder="Email"
                    {...field}
                    value={field.value || ''}
                    error={formMethods.formState.errors.email?.message}
                  />
                );
              }}
            />
          </DialogInputLayout>
          <RolesField roleGroups={roleGroups} orgId={orgId} />
          <ProductAccessField roleGroups={roleGroups} orgId={orgId} />
        </form>
      </FormProvider>
      <StatusMessage
        show={mutation.isError}
        dataHcName={`${dataHcName}-error`}
        type="error"
        title={capitalizeFirstLetter(
          mutation.error?.response?.data.status || ''
        )}
      />
      <ActionButtons
        dataHcName={`${dataHcName}-actions`}
        portalIdRender={actionsPortalIdRender}
        actions={[
          {
            label: 'Invite',
            dataHcName: `${dataHcName}-invite-button`,
            loading: mutation.isLoading,
            disabled: mutation.isLoading || !formMethods.formState.isValid,
            onClick: () => formMethods.handleSubmit(onSubmit)(),
          },
        ]}
      />
    </div>
  );
};

export const InviteUser = (externalProps: InviteUserProps) => {
  const orgId = Number(externalProps.orgId);
  const roleGroups = useOrgRoleGroups(orgId);
  const internalProps: InviteUserInternalFormProps | null = useMemo(() => {
    if (roleGroups === null) {
      return null;
    }
    const excludedProductAccessRoles: AccRoleExternalRoleKeys[] = [];
    const orderManagerRoleKeys: AccRoleExternalRoleKeys[] = [];
    // we should disallow view all orders by default
    if (
      roleGroups.omAccessRoles.find(
        (role) => role.key === AccRoleExternalRoleKeys.OmCreateOrder
      )
    ) {
      orderManagerRoleKeys.push(AccRoleExternalRoleKeys.OmCreateOrder);
    }
    return {
      roleGroups,
      defaultValues: {
        roleKeys: [],
        orderManagerRoleKeys,
        excludedProductAccessRoles,
      },
    };
  }, [roleGroups]);

  if (internalProps) {
    return <InviteUserInner {...externalProps} {...internalProps} />;
  } else {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
};
