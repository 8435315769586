import { useQuery } from '@tanstack/react-query';

import { AuthApi } from '@hcs/auth';
import { useAccount } from '@hcs/auth';
import { DISCONTINUED_PRODUCT_NAMES } from '@hcs/hc-products';
import { useHcProductsAppConfigs } from '@hcs/hc-products';
import {
  AppConfig,
  Application,
  AppSlugs,
  OrganizationWithApplications,
} from '@hcs/types';
import { AdminFeatureOrgProps } from '@hcs/types';

import { HcsAdminOrgsApi } from '../api';

const filterApplications = (
  applications: Application[],
  productAppConfigs: Partial<Record<AppSlugs, AppConfig>>
): Application[] => {
  return (
    applications
      .filter(
        (app) =>
          // Filter out discontinued apps
          !DISCONTINUED_PRODUCT_NAMES[app.name]
      )
      // Sort by display name
      .sort((a, b) => {
        const nameA = productAppConfigs[a.name]?.name || a.displayName;
        const nameB = productAppConfigs[b.name]?.name || b.displayName;
        return nameA < nameB ? -1 : 1;
      }) || []
  );
};
export const QUERY_KEY_ORGANIZATION_LEGACY = 'QUERY_KEY_ORGANIZATION_LEGACY';
export const useOrganizationLegacy = ({
  mode,
  orgId,
}: AdminFeatureOrgProps) => {
  const productAppConfigs = useHcProductsAppConfigs();
  const { data: loggedInAccount } = useAccount();
  return useQuery<OrganizationWithApplications>(
    [QUERY_KEY_ORGANIZATION_LEGACY, mode === 'internal' ? orgId : 'external'],
    async () => {
      if (mode === 'external') {
        // External mode can only fetch the current org
        const organization = await AuthApi.fetchCurrentOrg();
        organization.applications = filterApplications(
          organization.applications,
          productAppConfigs
        );
        return organization;
      } else {
        const [organization, applications] = await Promise.all([
          await HcsAdminOrgsApi.fetchOrg(orgId),
          await HcsAdminOrgsApi.fetchOrgApplications(orgId),
        ]);
        return {
          ...organization,
          applications: filterApplications(
            applications.filter((a) => a.available),
            productAppConfigs
          ),
        };
      }
    },
    {
      enabled: !!loggedInAccount,
    }
  );
};
