import { useEffect, useRef } from 'react';

export const useBodyOverflowHidden = (hidden: boolean) => {
  const bodyRef = useRef(document.querySelector('body'));

  // Append portal root on mount
  useEffect(() => {
    const bodyEl = bodyRef.current;

    return () => {
      // Ensure scroll overflow is removed
      if (bodyEl) {
        bodyEl.style.overflow = '';
      }
    };
  }, []);

  // Prevent page scrolling when the drawer is open
  useEffect(() => {
    if (bodyRef.current) {
      if (hidden) {
        bodyRef.current.style.overflow = 'hidden';
      } else {
        bodyRef.current.style.overflow = '';
      }
    }
  }, [hidden]);
};
