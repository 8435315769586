import React, { useRef } from 'react';
import classNames from 'classnames';

import { TagButton } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { Popover } from '@hcs/design-system';
import { TOOLTIP_OFFSET } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import {
  MeaningfulEventTypes,
  ReportFeatures,
  ReportId,
  ReportTypes,
} from '@hcs/types';

import {
  useAddTags,
  useDeleteTags,
  useReport,
  useReportPermissions,
  useTags,
} from '../../hooks';

import { TagCount } from './TagCount';

import styles from './CompTags.module.css';

interface Props {
  reportId: ReportId;
  documentId: string;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  overlay?: 'transparent' | 'translucent';
  className?: string;
}

const dataHcName = 'comp-tags';
// Note: tags disabled on rental comps
const dataHcEventSectionCompTags = 'Comp Tags';
export const DEFAULT_TAGS = [
  'Fair Market Value Match',
  'After Repair Value Match',
];
export const COMP_TAGS_FEATURES = [ReportFeatures.Tags];
export const CompTags = ({
  reportId,
  documentId,
  onOpen,
  onClose,
  overlay = undefined,
  className,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { data: report } = useReport(reportId);
  const {
    isFetched,
    isError,
    data: tags,
  } = useTags({
    reportId,
    documentId,
  });

  const tagOptionList = report?.metadata?.tagOptions
    ? report.metadata.tagOptions
    : DEFAULT_TAGS;
  const popupRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { active, handleClose, handleToggle } = useActiveState();

  const closeMenu = () => {
    onClose?.();
    handleClose();
  };

  const addTags = useAddTags();
  const deleteTags = useDeleteTags();

  const onClick = () => {
    active ? onClose?.() : onOpen?.();
    handleToggle();
  };

  const updateTags = (checked: boolean, tag: string) => {
    if (checked) {
      addTags.mutate({ reportId, documentId, tags: [tag] });
    } else {
      deleteTags.mutate({ reportId, documentId, tags: [tag] });
    }
  };

  if (isError) {
    return (
      <NullState
        title={'Error fetching tag data'}
        dataHcName={`${dataHcName}-tag-data-error`}
      />
    );
  }

  if (!isFetched || !tags || report?.reportType !== ReportTypes.Value) {
    return null;
  }

  const tagsBoxes = tagOptionList.map((tag: string, index: number) => {
    const isChecked = tags.includes(tag);
    return (
      <li className={styles.ListItem} key={`${tag}-${index}`}>
        <Checkbox
          disabled={!reportPermissions?.isEditable}
          checked={isChecked}
          dataHcName={`${dataHcName}-${index}`}
          dataHcEventName={isChecked ? 'Remove Comp Tag' : 'Add Comp Tag'}
          dataHcEventType={MeaningfulEventTypes.Goal}
          onChange={(checked) => updateTags(checked, tag)}
          label={tag}
        />
      </li>
    );
  });

  return (
    <div
      className={classNames(styles.CompTags, className, {
        [styles.tagsApplied]: tags.length > 0,
      })}
      ref={containerRef}
      data-hc-event-section={dataHcEventSectionCompTags}
    >
      <Popover
        positionOffset={TOOLTIP_OFFSET}
        active={active}
        overlay={overlay}
        onClose={closeMenu}
        dataHcName={dataHcName}
        trigger={
          <TagButton
            dataHcName={`${dataHcName}-button`}
            dataHcEventSection={dataHcEventSectionCompTags}
            onClick={onClick}
          />
        }
        content={
          <ul
            ref={popupRef}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.UL}
            data-hc-name={`${dataHcName}-tag-menu`}
            data-hc-event-section={dataHcEventSectionCompTags}
          >
            {tagsBoxes}
          </ul>
        }
      />
      {tags.length > 0 && <TagCount count={tags.length} />}
    </div>
  );
};
