import { useMemo } from 'react';

import { useCapabilities } from '@hcs/authz';
import {
  Capability,
  CapabilityApplications,
  CapabilityCategories,
  EntitlementDef,
} from '@hcs/types';

interface CapabilityPriceTier {
  entitlementKey: string;
  capabilityKey: string;
  unitPrice?: number;
  lowerLimit?: number;
}

interface OrgPricingTiered {
  applicationKey: CapabilityApplications.DataExplorer;
  capabilityPrices: Record<string, CapabilityPriceTier[]>;
}
type OrgPricing = Partial<{
  [CapabilityApplications.CanaryAI]: OrgPricingTiered;
}>;

interface EntitlementCapabilityResponse {
  entitlementDef: EntitlementDef;
  capabilities: Capability[];
}

const FRAGMENT_PRICE_MARKETING_AVM = 0.05;
const FRAGMENT_PRICE_BASIC = 0.13;
const FRAGMENT_PRICE_PREMIUM = 0.8;
const FRAGMENT_PRICE_PREMIUM_PLUS = 1.5;

// TODO: Need an unauthenticated endpoint to get this data
const CANARY_AI_ENTITLEMENTS: EntitlementCapabilityResponse[] = [
  {
    entitlementDef: {
      key: 'fragments-basic',
      display_name: 'Basic Data Fragments',
      description: 'Basic Data Fragments',
    },
    capabilities: [
      {
        key: 'subdivisiondetails',
        display_name: 'SubdivisionDetails',
        description: 'SubdivisionDetails',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'bestpropertycharacteristics',
        display_name: 'BestPropertyCharacteristics',
        description: 'BestPropertyCharacteristics',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'mlspropertycharacteristics',
        display_name: 'MLSPropertyCharacteristics',
        description: 'MLSPropertyCharacteristics',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'publicrecordpropertycharacteristics',
        display_name: 'PublicRecordPropertyCharacteristics',
        description: 'PublicRecordPropertyCharacteristics',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'schooldetails',
        display_name: 'SchoolDetails',
        description: 'SchoolDetails',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'crimedetails',
        display_name: 'CrimeDetails',
        description: 'CrimeDetails',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'statesalelistingstats',
        display_name: 'StateSaleListingStats',
        description: 'StateSaleListingStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'statehomepriceindex',
        display_name: 'StateHomePriceIndex',
        description: 'StateHomePriceIndex',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'staterentalyieldstats',
        display_name: 'StateRentalYieldStats',
        description: 'StateRentalYieldStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'affordabilitystats',
        display_name: 'AffordabilityStats',
        description: 'AffordabilityStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'msarentallistingstats',
        display_name: 'MSARentalListingStats',
        description: 'MSARentalListingStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'msasalelistingstats',
        display_name: 'MSASaleListingStats',
        description: 'MSASaleListingStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'msahomepriceindex',
        display_name: 'MSAHomePriceIndex',
        description: 'MSAHomePriceIndex',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'msarentalyieldstats',
        display_name: 'MSARentalYieldStats',
        description: 'MSARentalYieldStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'populationstats',
        display_name: 'PopulationStats',
        description: 'PopulationStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'unemploymentstats',
        display_name: 'UnemploymentStats',
        description: 'UnemploymentStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'ziprentallistingstats',
        display_name: 'ZipRentalListingStats',
        description: 'ZipRentalListingStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'zipsalelistingstats',
        display_name: 'ZipSaleListingStats',
        description: 'ZipSaleListingStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'ziprentalyieldstats',
        display_name: 'ZipRentalYieldStats',
        description: 'ZipRentalYieldStats',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'ziphomepriceindex',
        display_name: 'ZipHomePriceIndex',
        description: 'ZipHomePriceIndex',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
    ],
  },
  {
    entitlementDef: {
      key: 'fragments-premium',
      display_name: 'Premium Data Fragments',
      description: 'Premium Data Fragments',
    },
    capabilities: [
      {
        key: 'saleavmdetails',
        display_name: 'SaleAVMDetails',
        description: 'SaleAVMDetails',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'rentalvaluedetails',
        display_name: 'RentalValueDetails',
        description: 'RentalValueDetails',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
    ],
  },
  {
    entitlementDef: {
      key: 'fragments-premium-plus',
      display_name: 'Premium-Plus Data Fragments',
      description: 'Premium-Plus Data Fragments',
    },
    capabilities: [
      {
        key: 'propensitytosell',
        display_name: 'PropensityToSell',
        description: 'PropensityToSell',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
      {
        key: 'condition',
        display_name: 'Condition',
        description: 'Condition',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
    ],
  },
  {
    entitlementDef: {
      key: 'fragment-property-estimate',
      display_name: 'Marketing AVM Details',
      description: 'Marketing AVM Details',
    },
    capabilities: [
      {
        key: 'marketingavmdetails',
        display_name: 'MarketingAVMDetails',
        description: 'MarketingAVMDetails',
        application: CapabilityApplications.CanaryAI,
        category: CapabilityCategories.Fragment,
      },
    ],
  },
];

export const QUERY_KEY_ORG_PRICING = 'QUERY_KEY_ORG_PRICING';
export const useOrgPricing = () => {
  const capabilitiesQuery = useCapabilities();
  const data = useMemo<OrgPricing | undefined>(() => {
    if (!capabilitiesQuery.data) {
      return undefined;
    }
    const capabilityKeyToEntitlementKey: Record<string, string> = {};
    CANARY_AI_ENTITLEMENTS.forEach((entitlement) => {
      entitlement.capabilities.forEach((capability) => {
        capabilityKeyToEntitlementKey[capability.key || ''] =
          entitlement.entitlementDef.key || '';
      });
    });
    const orgPricing: OrgPricing = {};
    capabilitiesQuery.data?.forEach((capability) => {
      if (capability.application === CapabilityApplications.CanaryAI) {
        const pricingCanaryAI = orgPricing[CapabilityApplications.CanaryAI] || {
          applicationKey: CapabilityApplications.DataExplorer,
          capabilityPrices: {},
        };
        // Get capability from tier
        const entitlementKey =
          capabilityKeyToEntitlementKey[capability.key] || '';
        pricingCanaryAI.capabilityPrices[capability.key] = [
          {
            entitlementKey,
            capabilityKey: capability.key,
            lowerLimit: 1,
            unitPrice:
              entitlementKey === 'fragment-property-estimate'
                ? FRAGMENT_PRICE_MARKETING_AVM
                : entitlementKey === 'fragments-basic'
                ? FRAGMENT_PRICE_BASIC
                : entitlementKey === 'fragments-premium'
                ? FRAGMENT_PRICE_PREMIUM
                : FRAGMENT_PRICE_PREMIUM_PLUS,
          },
        ];
        orgPricing[CapabilityApplications.CanaryAI] = pricingCanaryAI;
      }
    });
    return orgPricing;
  }, [capabilitiesQuery.data]);
  return { ...capabilitiesQuery, data };
};
