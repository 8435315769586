import React from 'react';

import { OmFileIcon, OmFileIdIcon } from '@hcs/design-system';
import { STEP } from '@hcs/types';

import styles from './SelectedOptions.module.css';

interface Props {
  dataHcName: string;
  onChangeClick: (number: number) => void;
  orderFile?: File;
  itemsSource?: string;
}

export const PropertiesOption = ({
  dataHcName,
  onChangeClick,
  orderFile,
  itemsSource,
}: Props) => {
  const handleClick = () => {
    if (orderFile) {
      onChangeClick(STEP.ADD_ADDRESS_METHOD);
    } else {
      onChangeClick(STEP.UPLOAD_OR_ENTER_ADDRESS);
    }
  };

  const isCSV = itemsSource === 'csv';
  const Icon = isCSV ? OmFileIcon : OmFileIdIcon;
  const title = isCSV ? 'Properties from File' : 'Properties Entered Manually';

  const description = isCSV ? orderFile?.name : '';

  return (
    <div data-hc-name={dataHcName} className={styles.Option}>
      <div className={styles.OptionContent}>
        <div className={styles.OptionIcon}>
          <Icon
            dataHcName={`${dataHcName}-icon`}
            size="lg"
            color="neutral-dark-20"
          />
        </div>
        <h4 className={styles.OptionTitle}>{title}</h4>
      </div>
      <div className={styles.OptionActions}>
        {description && isCSV && (
          <div className={styles.OptionDescription}>
            <div className={styles.OptionDescriptionIcon}>
              <Icon dataHcName={`${dataHcName}-action-icon`} size="sm" />
            </div>{' '}
            {isCSV ? description : `#${description}`}
          </div>
        )}
        <div className={styles.OptionChange} onClick={handleClick}>
          Change
        </div>
      </div>
    </div>
  );
};
