import React from 'react';
import classNames from 'classnames';

import { RadioOption, RadioSelect } from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import { ArrayOneOrMore, VectilesMetricGroups } from '@hcs/types';

import { VECTILES_METRICS_GROUP_CONFIG } from '../../constants';
import { useHcMap } from '../../hooks/useHcMap';

import styles from './VectilesMetricGroupControl.module.css';

export interface VectilesMetricGroupControlProps {
  mapId: string;
  className?: string;
  vectilesMetricGroups: ArrayOneOrMore<VectilesMetricGroups>;
}
const dataHcName = 'vectiles-metric-group-control';
export const VectilesMetricGroupControl = ({
  mapId,
  vectilesMetricGroups,
  className,
}: VectilesMetricGroupControlProps) => {
  const experienceFlag = useExperienceFlag('PEXP_REDESIGN');
  const {
    mapState,
    actions: { hcMapChangeVectilesMetricGroup },
  } = useHcMap(mapId);
  if (!mapState) return null;
  const {
    heatmap: { vectilesMetricGroup },
  } = mapState;

  const experienceFlagOptions = experienceFlag
    ? vectilesMetricGroups
    : vectilesMetricGroups.filter(
        (metricGroup) => metricGroup !== VectilesMetricGroups.Crime
      );
  const options: RadioOption<VectilesMetricGroups>[] =
    experienceFlagOptions.map((metricGroup) => {
      const config = VECTILES_METRICS_GROUP_CONFIG[metricGroup];
      return {
        value: config.vectilesMetricGroup,
        label: (
          <>
            <div
              className={styles.Label}
              data-hc-name={`${dataHcName}-${config.vectilesMetricGroup}-label`}
            >
              {config.label}
            </div>
            <div
              className={styles.Description}
              data-hc-name={`${dataHcName}-${config.vectilesMetricGroup}-description`}
            >
              {config.description}
            </div>
          </>
        ),
      };
    });
  const handleChange = (newVectilesMetricGroup: VectilesMetricGroups) => {
    hcMapChangeVectilesMetricGroup({
      mapId,
      vectilesMetricGroup: newVectilesMetricGroup,
    });
  };
  return (
    <RadioSelect
      value={vectilesMetricGroup}
      options={options}
      className={classNames(styles.VectilesMetricGroupControl, className)}
      dataHcName={dataHcName}
      onChange={handleChange}
    />
  );
};
