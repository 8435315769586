import React, { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './FilterButton.module.css';

export interface FilterButtonProps {
  active: boolean;
  label?: string;
  children?: React.ReactNode;
  className?: string;
  dataHcName: string;
  error?: boolean;
  onClick?: VoidFunction;
}
export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  (
    {
      active,
      label,
      children,
      className,
      dataHcName,
      error,
      onClick,
    }: FilterButtonProps,
    ref
  ) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className={classNames(styles.FilterButton, className, {
          [styles.active]: active,
          [styles.error]: error,
        })}
        data-hc-name={dataHcName}
      >
        {label || children}
      </button>
    );
  }
);
