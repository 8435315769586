import React, { useMemo } from 'react';

import { formatMoney, NULL_VALUE } from '@hcs/utils';

import { useSelfServicePackage } from '../../hooks/useSelfServicePackage';

interface SelfServiceReportPriceProps {
  className?: string;
}
const dataHcName = 'self-service-report-price';
export const SelfServiceReportPrice = ({
  className,
}: SelfServiceReportPriceProps) => {
  const { data: selfServicePackage } = useSelfServicePackage();
  const price = useMemo(() => {
    if (selfServicePackage) {
      // price comes across like 2999 to represent $29.99 for some reason
      const pricePerReport =
        selfServicePackage.price / 100 / selfServicePackage.amount;
      return formatMoney(pricePerReport, { precision: 2 });
    }
    return NULL_VALUE;
  }, [selfServicePackage]);

  return (
    <span data-hc-name={dataHcName} className={className}>
      {price}
    </span>
  );
};
