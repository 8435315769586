import { NestedObjectPaths } from '../../json-patch/JsonPatch.types';
import { ValueExtended } from '../../Value.types';

export enum ValuationMethod {
  Avm = 'avm',
  Adjusted = 'adjustedAvm',
  Comps = 'comparableValue',
  UserEntered = 'userValue',
}

export enum ValuationMethodLabel {
  avm = 'HouseCanary Value',
  adjustedAvm = 'Adjusted Value',
  comparableValue = 'Comparable Value',
  userValue = 'User Opinion of Price',
}

export enum ValuationMethodLabelRental {
  avm = 'HouseCanary Rental Value',
  adjustedAvm = 'Adjusted Rental Value',
  comparableValue = 'Rental Comparable Value',
  userValue = 'User Opinion of Rental Price',
}

export interface IncludeHcAdjustments {
  hcAdjustmentDate: boolean | null;
  hcAdjustmentPropertyDetails: boolean | null;
}

export type ComparableValue = ValueExtended & {
  hcAdjustments: IncludeHcAdjustments;
};

export interface ValueComments {
  reason: 'avm-over' | 'avm-under' | 'missing-details';
  comments: string | null;
}

export interface SubjectFloodSchema {
  effectiveDate: string | null;
  zone: string | null;
  floodRisk: string | null;
  mapId: string | null;
}

// Schema used in `comparable_value_hc_suggested` docs
export interface ComparableValueSchema {
  [ValuationMethod.Comps]: ComparableValue;
}
export interface SubjectValueSchema extends ComparableValueSchema {
  [ValuationMethod.Avm]: ValueExtended;
  [ValuationMethod.Adjusted]?: ValueExtended | null;
  [ValuationMethod.UserEntered]: ValueExtended & {
    valueComments?: ValueComments;
  };
  selectedValue: ValuationMethod;
}

export type SubjectValuePaths =
  `/data/${NestedObjectPaths<SubjectValueSchema>}`;
