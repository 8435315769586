import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import Exclude from './ExcludeIcon.svg?react';
import Info from './InfoIcon.svg?react';
import InfoSmall from './InfoSmallIcon.svg?react';
import SavedFilters from './SavedFiltersIcon.svg?react';
import StarFilled from './StarFilledIcon.svg?react';
import Star from './StarIcon.svg?react';
import StarOutline from './StarOutlineIcon.svg?react';
import StreetView from './StreetViewIcon.svg?react';
import Tag from './TagIcon.svg?react';
import UnselectedFilters from './UnselectedFiltersIcon.svg?react';

export const ExcludeIcon = (props: IconProps) => useIcon(Exclude, props);
export const InfoIcon = (props: IconProps) => useIcon(Info, props);
export const InfoSmallIcon = (props: IconProps) => useIcon(InfoSmall, props);
export const SavedFiltersIcon = (props: IconProps) =>
  useIcon(SavedFilters, props);
export const StarFilledIcon = (props: IconProps) => useIcon(StarFilled, props);
export const StarIcon = (props: IconProps) => useIcon(Star, props);
export const StarOutlineIcon = (props: IconProps) =>
  useIcon(StarOutline, props);
export const StreetViewIcon = (props: IconProps) => useIcon(StreetView, props);
export const TagIcon = (props: IconProps) => useIcon(Tag, props);
export const UnselectedFiltersIcon = (props: IconProps) =>
  useIcon(UnselectedFilters, props);
