import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { hcsConsole } from '@hcs/console';
import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import {
  AddressParts,
  OmOrder,
  OrderItemBulkUpdateResponse,
  OrderItemsSearchInputs,
  OrderItemsUpdateParams,
  OrderProgress,
  OrdersParams,
  SaveSftpSettings,
  SearchResultOrderItem,
  SftpSettings,
} from '@hcs/types';
import { ORDER_MANAGER_API_URL, ORDER_MANAGER_CLIENT_API_URL } from '@hcs/urls';
import { makePaginatedResponseData } from '@hcs/utils';
import { getDebugTime } from '@hcs/utils';

import { ORDERS_PER_PAGE } from '../constants';
import { orderItemsSearchInputsToApiParams } from '../utils/orderItem.utils';
import {
  orderTypeDescriptorValuesSnakeToCamel,
  prepOrdersParamsForApi,
} from '../utils/orders.utils';

const SFTP_URL = `${ORDER_MANAGER_CLIENT_API_URL}/sftp/`;

const fetchSftpSettings = async () => {
  const response = await AxiosAccessTokenClientJwt.get<SftpSettings>(SFTP_URL);
  return camelcaseKeys(response.data);
};

const createSftpSettings = async (payload: SaveSftpSettings) => {
  const response = await AxiosAccessTokenClientJwt.post<SftpSettings>(
    SFTP_URL,
    snakecaseKeys(payload)
  );
  return camelcaseKeys(response.data);
};

// on update, we will only include the password if it changed
const updateSftpSettings = async (
  payload: Omit<SaveSftpSettings, 'sftpPassword'> &
    Partial<Pick<SaveSftpSettings, 'sftpPassword'>>
) => {
  const response = await AxiosAccessTokenClientJwt.put<SftpSettings>(
    SFTP_URL,
    snakecaseKeys(payload)
  );
  return camelcaseKeys(response.data);
};

const fetchOrders = async (params?: OrdersParams, signal?: AbortSignal) => {
  hcsConsole.logVerbose('fetchOrders called', getDebugTime());
  const response = await AxiosAccessTokenClientJwt.get<OmOrder[]>(
    `${ORDER_MANAGER_CLIENT_API_URL}/orders`,
    {
      signal,
      params: params ? prepOrdersParamsForApi(params) : undefined,
    }
  );
  hcsConsole.logVerbose('fetchOrders completed', getDebugTime());

  return makePaginatedResponseData(
    response,
    {
      page: params?.page || 1,
      pageSize: params?.pageSize || ORDERS_PER_PAGE,
    },
    { camelCaseKeys: true }
  );
};

// for the orders page, when websocket updates are coming in and we only need to refetch the updated orders
const fetchOrderIds = async (orderIds: number[]) => {
  const response = await AxiosAccessTokenClientJwt.get<OmOrder[]>(
    `${ORDER_MANAGER_CLIENT_API_URL}/orders`,
    // paramsSerializer needed so that we get repeated query params for each id like ?id=1&id=2&id=3
    { params: { id: orderIds }, paramsSerializer: { indexes: null } }
  );
  return camelcaseKeys<OmOrder[]>(response.data, { deep: true });
};

const fetchOrderProgress = async (orderId: number) => {
  const ORDER_PROGRESS_URL = `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/progress`;
  const response = await AxiosAccessTokenClientJwt.get<OrderProgress>(
    ORDER_PROGRESS_URL
  );
  return response.data;
};

const fetchOrder = async (orderId: number) => {
  const ORDER_URL = `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}`;
  const response = await AxiosAccessTokenClientJwt.get<OmOrder>(ORDER_URL);
  const order = camelcaseKeys<OmOrder>(response.data, { deep: true });
  order.orderTypeDescriptor = orderTypeDescriptorValuesSnakeToCamel(
    order.orderTypeDescriptor
  );
  return order;
};

const updateOrder = async (
  orderId: number,
  payload: { id: number; propertyType: string } & AddressParts
) => {
  const url = `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/items/${payload.id}/update/`;

  const snakecasePayload = snakecaseKeys(payload, {
    deep: true,
  });

  await AxiosAccessTokenClientJwt.post(url, snakecasePayload);
};

const updateOrderItems = async ({
  orderId,
  orderItems,
}: OrderItemsUpdateParams) => {
  const url = `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/items/bulk/`;

  const snakecasePayload = orderItems.map((orderItem) =>
    snakecaseKeys(orderItem)
  );

  const response =
    await AxiosAccessTokenClientJwt.put<OrderItemBulkUpdateResponse>(
      url,
      snakecasePayload
    );
  return response.data;
};

const fetchOrderItemsSearch = async (params: OrderItemsSearchInputs) => {
  const response = await AxiosAccessTokenClientJwt.get<SearchResultOrderItem[]>(
    `${ORDER_MANAGER_CLIENT_API_URL}/items`,
    { params: orderItemsSearchInputsToApiParams(params) }
  );

  return camelcaseKeys(response.data);
};

export const OrderManagerApi = {
  createSftpSettings,
  fetchOrder,
  fetchOrderIds,
  fetchOrderItemsSearch,
  fetchOrders,
  fetchOrderProgress,
  fetchSftpSettings,
  updateOrder,
  updateOrderItems,
  updateSftpSettings,
  websocketTokenExchange: async () => {
    const response = await AxiosAccessTokenClientJwt.post<{ token: string }>(
      `${ORDER_MANAGER_API_URL}/websockets/v1/exchange/`
    );

    return camelcaseKeys(response.data);
  },
};
