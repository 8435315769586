import { useQuery } from '@tanstack/react-query';

import { HuellAdminApi } from '../api/huellAdmin.api';

export const QUERY_KEY_FEATURE_FLAG_CONFIGS = 'featureFlagConfigs';
export const useFeatureFlagConfigs = () => {
  return useQuery(
    [QUERY_KEY_FEATURE_FLAG_CONFIGS],
    HuellAdminApi.fetchFeatureFlagConfigs
  );
};
