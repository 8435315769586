import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { LineClamp } from '@hcs/design-system';

import styles from './CompCompare.module.css';

const dataHcName = 'comp-compare-row';
export const CompCompareRow = ({
  children,
  className,
  isAdjusting,
  badge,
}: {
  className?: string;
  children: ReactNode;
  isAdjusting?: boolean;
  badge?: ReactNode;
}) => {
  return (
    <div
      className={classNames(styles.Row, className, {
        [styles.isAdjusting]: isAdjusting,
      })}
      data-hc-name={dataHcName}
    >
      {isAdjusting ? (
        <>
          {children}
          {badge && <div className={styles.AdjustingBadge}>{badge}</div>}
        </>
      ) : (
        <>
          <LineClamp renderChildrenAs="tooltipDescription" isLargeTooltip>
            {children}
          </LineClamp>
          {badge}
        </>
      )}
    </div>
  );
};
