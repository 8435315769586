import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { NewOrderApi } from '../api/newOrderApi';

import { QUERY_KEY_ORDERS } from './useOrders';

interface Params {
  orderId: number;
}

export const useCancelOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, Params>(
    async ({ orderId }) => await NewOrderApi.cancelOrder(orderId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY_ORDERS]);
      },
    }
  );
};
