import { useCapabilityCheck } from '@hcs/authz';

export const useOfferNowAvailable = () => {
  const capCheckQuery = useCapabilityCheck('offer-now');
  const { isSuccess, data } = capCheckQuery;
  if (isSuccess) {
    return {
      ...capCheckQuery,
      data: data || false,
    };
  } else {
    return {
      ...capCheckQuery,
      data: undefined,
    };
  }
};
