import React from 'react';
import classNames from 'classnames';

import { IconButton } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { Header } from '@hcs/design-system';
import { RefreshIcon } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import {
  CompsListTypes,
  CompsListViewType,
  NavigateToReportFn,
} from '@hcs/types';

import {
  REPORT_FEATURES_COMP_LIST_DEXP_ANALYSIS_RENTAL,
  REPORT_FEATURES_COMP_LIST_DEXP_ANALYSIS_SALE,
  ReportCompsListDexpAnalysis,
} from '../../features/ReportCompsListDexpAnalysis';
import { ReportFeaturesSupported } from '../../features/ReportFeaturesSupported';
import { SubjectPropertySummaryHeader } from '../../features/SubjectPropertySummaryHeader';
import { useReportPermissions } from '../../hooks';
import { useCompsList } from '../../hooks/useCompsList';
import { CompDataPriorityUserLauncher } from '../CompDataPriorityUser';
import { CompSearchTabPopover } from '../CompSearch';
import { RefreshReport } from '../RefreshReport';

import { CompsListViewTypeControl } from './CompsListControls/CompsListViewTypeControl';

import styles from './CompsListHeader.module.css';

interface Props {
  reportId: ReportId;
  dataHcEventSection?: string;
  compType: CompTypes;
  compsListType: CompsListTypes;
  className?: string;
  hideControls?: boolean;
  navigateToReport: NavigateToReportFn;
}
export const COMPS_LIST_HEADER_ACTIONS_PORTAL_ID = 'comps-list-header-actions';
const dataHcName = 'comp-list-header';
export const CompsListHeader = ({
  reportId,
  dataHcEventSection,
  compType,
  compsListType,
  hideControls,
  className,
  navigateToReport,
}: Props) => {
  const isRental = compType === CompTypes.Rental;
  const {
    state: { listViewType },
  } = useCompsList({ reportId, compType, compsListType });
  const {
    data: { isEditable },
  } = useReportPermissions(reportId);
  return (
    <>
      <Header
        size="sm"
        className={classNames(styles.Header, className)}
        dataHcName={dataHcName}
        dataHcEventSection={dataHcEventSection}
        onClose={() =>
          navigateToReport(reportId, {
            valuationType: compType === CompTypes.Rental ? 'rental' : 'sale',
          })
        }
      >
        <div className={styles.Controls}>
          <div className={styles.Buttons}>
            {!hideControls && (
              <>
                {isEditable && (
                  <ReportFeaturesSupported
                    reportId={reportId}
                    reportFeatures={[ReportFeatures.ReportDataRefresh]}
                  >
                    <RefreshReport
                      reportId={reportId}
                      trigger={
                        <IconButton
                          disabled={!isEditable}
                          dataHcName={`${dataHcName}-refresh-report-btn`}
                          icon={<RefreshIcon />}
                          stopPropagation={false}
                        />
                      }
                    />
                  </ReportFeaturesSupported>
                )}
                <CompSearchTabPopover reportId={reportId} compType={compType} />
                <CompsListViewTypeControl
                  reportId={reportId}
                  compType={compType}
                  compsListType={compsListType}
                  dataHcEventSection={dataHcEventSection}
                />
                {listViewType === CompsListViewType.Table && isEditable && (
                  <CompDataPriorityUserLauncher compType={compType} />
                )}
                <ReportFeaturesSupported
                  reportId={reportId}
                  reportFeatures={
                    compType === CompTypes.Rental
                      ? REPORT_FEATURES_COMP_LIST_DEXP_ANALYSIS_RENTAL
                      : REPORT_FEATURES_COMP_LIST_DEXP_ANALYSIS_SALE
                  }
                >
                  <ReportCompsListDexpAnalysis
                    reportId={reportId}
                    compType={compType}
                    compsListType={compsListType}
                  />
                </ReportFeaturesSupported>
              </>
            )}
            <ActionButtons
              dataHcName={`${dataHcName}-actions`}
              portalId={COMPS_LIST_HEADER_ACTIONS_PORTAL_ID}
              className={styles.ActionButtons}
            />
          </div>
          <div className={styles.HeaderShadow} />
        </div>
      </Header>
      {listViewType !== CompsListViewType.Compare && (
        <SubjectPropertySummaryHeader
          reportId={reportId}
          beforeStreetAddress={isRental ? 'Rental Comps for ' : 'Comps for '}
        />
      )}
    </>
  );
};
