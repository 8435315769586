import { AppSlugs } from '@hcs/types';

import { useAccount } from './useAccount';
import { useCheckAppAccess } from './useCheckAppAccess';

export const useCheckAppZuoraSubscription = (appSlug: AppSlugs) => {
  const accountQuery = useAccount();
  // Needs to have app access, this also checks "FE only Apps"
  const hasAppAccessQuery = useCheckAppAccess(appSlug);
  if (accountQuery.isSuccess && hasAppAccessQuery.isSuccess) {
    // Return account query access but change data to boolean result
    return {
      ...accountQuery,
      data: !!(
        hasAppAccessQuery.data &&
        accountQuery.data.accessibleApplications.find(
          (accessibleApp) => accessibleApp.name === appSlug
        ) !== undefined
      ),
    };
  } else {
    // Return account query status if data is not yet fetched
    return {
      ...accountQuery,
      data: undefined,
    };
  }
};
