import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ACCOUNT_FIELD_CONFIGS } from '@hcs/auth';
import { AccountFieldInput } from '@hcs/auth';
import { Button } from '@hcs/design-system';
import { AccountFields } from '@hcs/types';
import { ChangePasswordErrorData, ChangePasswordPayload } from '@hcs/types';

import { useUserChangePassword } from '../../hooks/useUserChangePassword';

import styles from './UserChangePasswordForm.module.css';

const FORM_SCHEMA = yup.object().shape({
  [AccountFields.CurrentPassword]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.CurrentPassword].validator,
  [AccountFields.NewPassword]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.NewPassword].validator,
  [AccountFields.ConfirmPassword]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.ConfirmPassword].validator,
});

interface Props {
  className?: string;
}
const dataHcName = 'user-change-password-form';
export const UserChangePasswordForm = ({ className }: Props) => {
  const form = useForm<ChangePasswordPayload>({
    resolver: yupResolver(FORM_SCHEMA),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { mutate: changeUserPassword } = useUserChangePassword({
    onError: (error) => {
      const errors = error.response?.data.errors;
      for (const formFieldStr in errors) {
        const formField =
          formFieldStr as keyof ChangePasswordErrorData['errors'];
        const fieldErrors = errors[formField];
        if (fieldErrors?.length) {
          form.setError(
            formField,
            {
              type: 'manual',
              message: fieldErrors.join(', '),
            },
            { shouldFocus: true }
          );
        }
      }
    },
    onSuccess: () => form.reset(),
  });
  const handleSubmit = (changePasswordPayload: ChangePasswordPayload) => {
    changeUserPassword(changePasswordPayload);
  };

  return (
    <FormProvider {...form}>
      <form
        data-hc-name={dataHcName}
        className={className}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Controller
          name="currentPassword"
          control={form.control}
          render={({ field }) => {
            return (
              <AccountFieldInput
                {...field}
                className={styles.Input}
                name={AccountFields.CurrentPassword}
                error={form.formState.errors[field.name]?.message}
              />
            );
          }}
        />
        <Controller
          name="newPassword"
          control={form.control}
          render={({ field }) => {
            return (
              <AccountFieldInput
                {...field}
                className={styles.Input}
                name={AccountFields.NewPassword}
                error={form.formState.errors[field.name]?.message}
              />
            );
          }}
        />
        <Controller
          name="confirmPassword"
          control={form.control}
          render={({ field }) => {
            return (
              <AccountFieldInput
                {...field}
                className={styles.Input}
                name={AccountFields.ConfirmPassword}
                error={form.formState.errors[field.name]?.message}
              />
            );
          }}
        />
        <Button
          dataHcName={`${dataHcName}-submit`}
          type="submit"
          label="Submit"
          disabled={!form.formState.isValid}
        />
      </form>
    </FormProvider>
  );
};
