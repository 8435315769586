import React from 'react';
import classNames from 'classnames';

import { LowToHighBandChart } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { Glossary } from '@hcs/glossary';
import { ChartTitle } from '@hcs/pdf/charts';
import { GlossaryTerms } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';

import { useMarketAnalysisDocument } from '../../hooks/useMarketAnalysisDocument';

import styles from './MarketRiskChart.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}

const MARKET_RISK_MARKERS = [15, 20, 20, 20, 25];
const MARKET_RISK_CHART_LABELS = {
  veryHigh: 70,
  high: 50,
  neutral: 30,
  low: 10,
};
export const MARKET_RISK_CHART_FEATURES = [ReportFeatures.MarketAnalysisChart];
const dataHcName = 'market-risk-chart';
export const MarketRiskChart = ({ reportId, className }: Props) => {
  const marketAnalysisQuery = useMarketAnalysisDocument(reportId);
  if (!marketAnalysisQuery.isFetched) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const value = (marketAnalysisQuery.data?.data?.risk.current || 0) * 100;
  const riskLevel = marketAnalysisQuery.data?.data?.risk.score;
  if (value == null || riskLevel == null) {
    return (
      <NullState
        title="Market Risk Unavailable"
        dataHcName={`${dataHcName}-error`}
        absCenter
      />
    );
  }

  const valueAsPercent = value ? (value / 100) * 100 : 0;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.MarketRiskChart, className)}
    >
      <ChartTitle
        className={styles.ChartTitle}
        dataHcName={`${dataHcName}-title`}
      >
        MSA Risk of Decline
        <Glossary
          theme={{
            Icon: styles.GlossaryIcon,
          }}
          glossaryTerms={[GlossaryTerms.RiskOfDecline]}
        />
      </ChartTitle>
      <div
        className={styles.RiskOfDeclineChart}
        data-hc-name={`${dataHcName}-content`}
      >
        <LowToHighBandChart
          dataHcName={`${dataHcName}-low-to-high-chart`}
          label={'1 Year Risk'}
          valueAsPercent={valueAsPercent}
          tickRangePercent={MARKET_RISK_MARKERS}
          colorLabelMap={MARKET_RISK_CHART_LABELS}
        />
      </div>
    </div>
  );
};
