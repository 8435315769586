import React, { CSSProperties, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

import { OptionalEngagementProps } from '@hcs/types';

import TabCurveBottomLeft from '../../../../assets/images/tab-curve-bottom-left.svg?react';
import TabCurveBottomRight from '../../../../assets/images/tab-curve-bottom-right.svg?react';
import TabCurveTopLeft from '../../../../assets/images/tab-curve-top-left.svg?react';
import TabCurveTopRight from '../../../../assets/images/tab-curve-top-right.svg?react';
import {
  DirectionalChevron,
  DirectionalChevronProps,
} from '../../../../foundations/svgs/icons/animated/DirectionalChevron';

import styles from './TabButton.module.css';

interface TabButtonTheme {
  Label?: string;
  TabButton?: string;
}

interface Props extends OptionalEngagementProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  isActive?: boolean;
  onClick: VoidFunction;
  theme?: TabButtonTheme;
  label: ReactNode;
  blue?: boolean;
  /**
   * This Tab's index if displayed with multiple tabs
   */
  tabIndex?: number;
  style?: CSSProperties;
  curveLeft?: boolean;
  curveRight?: boolean;
  tabPosition?: 'top' | 'bottom';
  chevron?: DirectionalChevronProps['direction'];
}
export const TabButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      dataHcName,
      dataHcEventSection,
      dataHcEventName,
      dataHcEventType,
      className,
      isActive,
      label,
      onClick,
      curveLeft,
      curveRight = true,
      tabIndex,
      theme,
      style,
      blue,
      chevron,
      tabPosition = 'top',
    }: Props,
    ref
  ) => {
    return (
      <button
        ref={ref}
        style={style}
        className={classNames(styles.TabButton, className, theme?.TabButton, {
          [styles.active]: isActive,
          [styles.blue]: blue,
          [styles.positionedBottom]: tabPosition === 'bottom',
        })}
        role="tab"
        aria-selected={isActive}
        onClick={onClick}
        data-hc-name={dataHcName}
        data-hc-event-section={dataHcEventSection}
        data-hc-event-name={dataHcEventName}
        data-hc-event-type={dataHcEventType}
      >
        {isActive && !!(tabIndex || curveLeft) && (
          <div className={styles.RoundedEdgeLeft}>
            {tabPosition === 'bottom' ? (
              <TabCurveTopLeft />
            ) : (
              <TabCurveBottomLeft />
            )}
          </div>
        )}
        {!isActive && label}
        {isActive && <span className={theme?.Label}>{label}</span>}
        {chevron && (
          <div className={styles.Chevron}>
            <DirectionalChevron direction={chevron} size={20} smallIcon />
          </div>
        )}
        {isActive && curveRight && (
          <div className={styles.RoundedEdgeRight}>
            {tabPosition === 'bottom' ? (
              <TabCurveTopRight />
            ) : (
              <TabCurveBottomRight />
            )}
          </div>
        )}
      </button>
    );
  }
);
