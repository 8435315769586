import { useOfferNowAvailable } from '@hcs/lead-feed';
import { usePropertyStateCore } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { CerberusInputHcs, ListingStatusCerberus } from '@hcs/types';
import { PropertyStateFields } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

export const useOfferNowAvailableForProperty = (
  cerberusInput: CerberusInputHcs | undefined
) => {
  const userOfferNowAvailableQuery = useOfferNowAvailable();
  const propertyStateCoreQuery = usePropertyStateCore(
    cerberusInput?.hcAddressId
      ? { hcAddressId: cerberusInput.hcAddressId }
      : undefined
  );
  const combinedQuery = combineUseQueryResult([
    userOfferNowAvailableQuery,
    propertyStateCoreQuery,
  ]);
  if (combinedQuery.isSuccess) {
    const { data: offerNowAvailable } = userOfferNowAvailableQuery;
    const { data: propertyStateArgs } = propertyStateCoreQuery;
    const listingStatus =
      propertyStateArgs &&
      getPropertyStateFieldValue(
        PropertyStateFields.currentStatus,
        propertyStateArgs
      );
    const data =
      offerNowAvailable &&
      (listingStatus === ListingStatusCerberus.Active ||
        listingStatus === ListingStatusCerberus.Pending);
    return {
      ...combinedQuery,
      data,
    };
  } else {
    return {
      ...combinedQuery,
      data: undefined,
    };
  }
};
