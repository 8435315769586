import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  MsaZipsDocument,
  MsaZipsQuery,
  MsaZipsQueryVariables,
} from '@hcs/types';
import { logException } from '@hcs/utils';

export const QUERY_KEY_MSA_ZIPS = 'msa_zips';
export const useMsaZips = (msaId: string | null) => {
  return useQuery(
    [QUERY_KEY_MSA_ZIPS, msaId],
    async () => {
      const data = await CerberusApi.fetchQuery<
        MsaZipsQuery,
        MsaZipsQueryVariables
      >(MsaZipsDocument, { msaId });
      // convert to array of zipcode strings
      const zipList = data.lookupMsaDetails?.zips?.reduce<string[]>(
        (accum, zipDetails) => {
          const zipcode = zipDetails?.zipcode;
          if (zipcode != null) {
            accum.push(zipcode);
          }
          return accum;
        },
        []
      );
      if (!zipList || zipList.length === 0) {
        logException(new Error('useMsaZips: ziplist was undefined or empty'));
        return [];
      }
      return zipList.sort();
    },
    {
      staleTime: Infinity, // msas pretty much never change, or will at least unlikely change while a user is on the page
      enabled: msaId !== null,
    }
  );
};
