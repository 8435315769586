import React, { ReactNode } from 'react';

import { IconButton } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { ShareIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { ReportId } from '@hcs/types';

import { checkIsPublicLink } from '../../utils/report.utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import {
  dataHcEventSectionShareReport,
  SHARE_REPORT_FEATURES,
  ShareReport,
} from '.';

import styles from './ShareReportDialog.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
  trigger?: ReactNode;
}
export const SHARE_REPORT_DIALOG_FEATURES = SHARE_REPORT_FEATURES;
const dataHcName = 'share-report-dialog';
export const ShareReportDialog = ({ className, trigger, reportId }: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const isPublicReport = checkIsPublicLink(reportId);
  if (isPublicReport) return null;
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={SHARE_REPORT_FEATURES}
    >
      {trigger ? (
        <span
          data-hc-name={`${dataHcName}-button`}
          onClick={handleOpen}
          data-hc-event-section={dataHcEventSectionShareReport}
        >
          {trigger}
        </span>
      ) : (
        <IconButton
          dataHcName={`${dataHcName}-button-icon`}
          dataHcEventSection={dataHcEventSectionShareReport}
          label="Share"
          className={className}
          icon={<ShareIcon size="lg" style={{ marginBottom: '5px' }} />}
          onClick={handleOpen}
        />
      )}

      <Dialog
        theme={{
          DialogContent: styles.DialogContent,
        }}
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        title="Share Link"
        onClose={handleClose}
        type="auto"
      >
        <ShareReport reportId={reportId} />
      </Dialog>
    </ReportFeaturesSupported>
  );
};
