// DO NOT CHANGE THESE SEARCH PARAMS WITHOUT EXPLICIT PRODUCT REQUIREMENTS
// These query params are used by clients to construct links
export enum PropertyIdentifierSearchParams {
  // CLIENT FACING:
  StreetAddress = 'street_address',
  Zipcode = 'zipcode',
  Unit = 'unit',
  // INTERNAL ONLY
  HcAddressId = 'hcAddressId',
  Slug = 'slug'
}
