import { combineReducers } from '@reduxjs/toolkit';

import { portfolioAssetsSlice } from './portfolioAssets.slice';
import { portfolioNotificationsSlice } from './portfolioNotifications.slice';

export const PORTFOLIO_REDUCER_KEY = 'portfolio';
export const portfolioReducer = combineReducers({
  [portfolioAssetsSlice.name]: portfolioAssetsSlice.reducer,
  [portfolioNotificationsSlice.name]: portfolioNotificationsSlice.reducer,
});
