import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { AutoComplete, AutoCompleteOptionType } from '@hcs/design-system';
import { OrderItemStatus, SearchResultOrderItem } from '@hcs/types';
import { formatDateTime } from '@hcs/utils';

import { ORDER_ITEMS_SEARCH_RESULTS_LIMIT } from '../../constants';
import { useOrderItemsSearch } from '../../hooks/useOrderItemsSearch';
import { getFullAddress } from '../../utils/orderItem.utils';

import styles from './OrderItemsSearchAutoComplete.module.css';

const highlightQuery = (text: string, searchStr: string) => {
  // SECURITY: non-literal argument to RegExp
  // eslint-disable-next-line security/detect-non-literal-regexp
  const words = text.split(new RegExp(`(${searchStr})`, 'gi'));
  return (
    <span>
      {words.map((word) => (
        <span
          key={word}
          className={classNames({
            [styles.Highlighted]:
              word.toLowerCase() === searchStr.toLowerCase(),
          })}
        >
          {word}
        </span>
      ))}
    </span>
  );
};

const OrderItemLabel = ({
  orderItem,
  searchStr,
}: {
  orderItem: SearchResultOrderItem;
  searchStr: string;
}) => {
  const fullAddress = getFullAddress({
    address: orderItem.address,
    unit: orderItem.unit,
    city: orderItem.city,
    state: orderItem.state,
    zipcode: orderItem.zipcode,
  });
  const highlightedFullAddress = highlightQuery(fullAddress, searchStr);
  const highlightedItemId = highlightQuery(orderItem.customerItemId, searchStr);

  return (
    <>
      <div className={styles.Address}>{highlightedFullAddress}</div>
      <div className={styles.OrderInfo}>
        <span>
          {orderItem.orderName} ({orderItem.orderTypeName})
        </span>
      </div>
      <div className={styles.MetaInfo}>
        <span>
          {orderItem.status === OrderItemStatus.Complete ? (
            <>Completed {formatDateTime(orderItem.completionDate)}</>
          ) : (
            <>Created: {formatDateTime(orderItem.createdAt)}</>
          )}
        </span>
        <span>FILE ID: {highlightedItemId}</span>
      </div>
    </>
  );
};

export interface Props {
  dataHcName?: string;
  className?: string;
  // Minimum number of characters before calling api
  minChars?: number;
  placeholder?: string;
  hideChevron?: boolean;
  onChange?: (searchStr: string) => void;
  onSelect: (orderItemSearchHit: SearchResultOrderItem) => void;
}

const ORDER_ITEMS_SEARCH_MIN_CHARS = 2;
export const OrderItemsSearchAutoComplete = ({
  dataHcName = 'order-items-search-field',
  placeholder = 'Search for address or client file id',
  onChange,
  className,
  onSelect,
  hideChevron,
  minChars = ORDER_ITEMS_SEARCH_MIN_CHARS,
}: Props) => {
  const [searchStr, setSearchStr] = useState('');
  const [selectedOptionValue, setSelectedOptionValue] = useState<
    number | undefined
  >();
  const { data, mutate: orderItemsSearchSubmit } = useOrderItemsSearch();

  const options: AutoCompleteOptionType<number>[] = useMemo(
    () =>
      data?.map((orderItemsSearchHit: SearchResultOrderItem) => {
        const fullAddress = getFullAddress({
          address: orderItemsSearchHit.address,
          unit: orderItemsSearchHit.unit,
          city: orderItemsSearchHit.city,
          state: orderItemsSearchHit.state,
          zipcode: orderItemsSearchHit.zipcode,
        });
        return {
          label: (
            <OrderItemLabel
              orderItem={orderItemsSearchHit}
              searchStr={searchStr}
            />
          ),
          searchString: fullAddress,
          value: orderItemsSearchHit.id,
        };
      }) || [],
    [data, searchStr]
  );

  const handleChange = (newSearchStr: string | null) => {
    if (newSearchStr?.length && newSearchStr.length >= minChars) {
      onChange?.(newSearchStr);
      if (newSearchStr) {
        orderItemsSearchSubmit({
          searchStr: newSearchStr,
          limit: ORDER_ITEMS_SEARCH_RESULTS_LIMIT,
        });
      }

      setSearchStr(newSearchStr);
    }
  };

  const handleSelect = (id: number | null) => {
    if (id) {
      const selectedOption = data?.find((d: { id: number }) => d.id === id);
      if (selectedOption) {
        setSelectedOptionValue(id);
        onSelect(selectedOption);
      }
    }
  };

  return (
    <AutoComplete
      dataHcName={dataHcName}
      inputStyle="search"
      className={classNames(styles.AutoComplete, className)}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      optionMode="async"
      hideChevron={hideChevron}
      config={{
        onSelect: handleSelect,
        selectType: 'single',
        value: selectedOptionValue,
      }}
      theme={{
        OptionsContainer: styles.OptionsContainer,
      }}
    />
  );
};
