import React from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { useKeywordSearchByListingsByCerberusId } from '@hcs/keyword-search';
import { PropertyBrokerageCredit } from '@hcs/property-state';
import { SimilarityLevel } from '@hcs/property-state';
import { useCompPropertyStateConfigs } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { ListingStatusCerberus, ListingStatusNormalized } from '@hcs/types';
import {
  CompTypes,
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { CompIdentifier, ReportId } from '@hcs/types';
import { formatMissing, formatNumber, formatSqFt } from '@hcs/utils';
import { listingStatusNormalized } from '@hcs/utils';

import { Details, Price } from '../../components-deprecated';
import {
  COMP_SELECT_BUTTON_FEATURES_RENTAL,
  COMP_SELECT_BUTTON_FEATURES_SALE,
  CompSelectButton,
} from '../../features/CompSelectButton';
import { useComp } from '../../hooks';
import { compKeywordSearchKey } from '../../utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './CompPropertyCard.module.css';

interface Props {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  showPrice?: boolean;
  flat?: boolean;
}
const dataHcName = 'comp-property-card-content';
export const CompPropertyCardContent = ({
  reportId,
  compIdentifier,
  flat,
  showPrice = true,
}: Props) => {
  const isRental = compIdentifier.compType === CompTypes.Rental;
  const compQuery = useComp(reportId, compIdentifier);
  const { compSchema } = compQuery.data || {};
  const marketPriceFieldConfigs = useCompPropertyStateConfigs(
    compIdentifier.compType === CompTypes.Rental
  );
  const { Display: MarketPriceDisplay } =
    marketPriceFieldConfigs[PropertyStateFields.currentPrice];
  const { Display: MarketPriceDateDisplay } =
    marketPriceFieldConfigs[PropertyStateFields.currentStatusDate];
  const { Display: MarketPriceStatusDisplay } =
    marketPriceFieldConfigs[PropertyStateFields.currentStatus];
  const keywordSearchKey = compKeywordSearchKey(compIdentifier.compType);
  const { data: keywordMatches } =
    useKeywordSearchByListingsByCerberusId(keywordSearchKey);
  if (!compSchema)
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  const cerberusId =
    compIdentifier.compType === CompTypes.Rental
      ? compSchema.propertyState.listingDetailsRental?.entityId
      : compSchema.propertyState.listingDetailsSale?.entityId;
  const hasKeywordMatch = cerberusId ? keywordMatches[cerberusId] : false;
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: compSchema.propertyState,
  };
  const marketPriceStatus: ListingStatusNormalized | undefined =
    listingStatusNormalized(
      compIdentifier.compType === CompTypes.Rental
        ? getPropertyStateFieldValue(
            PropertyStateFields.currentStatusRental,
            propertyStateArgs
          ) || ListingStatusCerberus.Closed
        : getPropertyStateFieldValue(
            PropertyStateFields.currentStatus,
            propertyStateArgs
          ) || ListingStatusCerberus.Closed
    );
  return (
    <>
      <Details
        dataHcName={`${dataHcName}-details`}
        location={compSchema.propertyState.location}
        attributes={[
          `${formatMissing(
            getPropertyStateFieldValue(
              PropertyStateFields.bedrooms,
              propertyStateArgs
            )
          )} Bd`,
          `${formatMissing(
            getPropertyStateFieldValue(
              PropertyStateFields.bathrooms,
              propertyStateArgs
            )
          )} Ba`,
          `${formatSqFt(
            getPropertyStateFieldValue(
              PropertyStateFields.livingArea,
              propertyStateArgs
            )
          )}`,
          `${formatNumber(compSchema.distance, { precision: 2 })} mi`,
        ]}
        hasKeywordMatch={hasKeywordMatch}
      />
      <div
        data-hc-name={`${dataHcName}-similarity`}
        className={styles.Similarity}
      >
        Similarity:{' '}
        <span data-hc-name={`${dataHcName}-similarity-score`}>
          {compSchema.similarity.scoreAdjusted}
        </span>
        <SimilarityLevel
          dataHcName={`${dataHcName}-similarity-level`}
          similarityLevel={compSchema.similarity.levelAdjusted || null}
        />
      </div>
      {showPrice && (
        <Price
          dataHcName={`${dataHcName}-price`}
          theme={{
            Label: styles.Label,
            Value: styles.Value,
          }}
          price={<MarketPriceDisplay propertyStateArgs={propertyStateArgs} />}
          date={
            <MarketPriceDateDisplay propertyStateArgs={propertyStateArgs} />
          }
          label={
            <MarketPriceStatusDisplay propertyStateArgs={propertyStateArgs} />
          }
          listingStatusColor={marketPriceStatus}
        />
      )}

      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={
          isRental
            ? COMP_SELECT_BUTTON_FEATURES_RENTAL
            : COMP_SELECT_BUTTON_FEATURES_SALE
        }
      >
        <div
          className={classNames(styles.SelectButton, {
            [styles.noPrice]: !showPrice,
            [styles.flat]: flat,
          })}
        >
          <CompSelectButton
            reportId={reportId}
            compIdentifier={compIdentifier}
          />
        </div>
      </ReportFeaturesSupported>

      <div
        className={classNames(styles.Legal, {
          [styles.flat]: flat,
        })}
      >
        <PropertyBrokerageCredit
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: compSchema.propertyState,
          }}
          isRental={compIdentifier.compType === CompTypes.Rental}
        />
      </div>
    </>
  );
};
