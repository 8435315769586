import { useQuery } from '@tanstack/react-query';

import { MSA } from '@hcs/types';
import { logException } from '@hcs/utils';

import { LeadFeedApi } from '../';

export const QUERY_KEY_MSA_GEMO = 'msa_geom';
export const useMsaGeom = (msaId: MSA['msaId'] | null | undefined) => {
  return useQuery(
    [QUERY_KEY_MSA_GEMO, msaId],
    async () => {
      if (!msaId) {
        logException('useMsaGeom: Missing msaId');
        throw new Error('Missing MSA ID');
      }
      return await LeadFeedApi.fetchMsaGeom(msaId);
    },
    {
      staleTime: Infinity, // msas pretty much never change, or will at least unlikely change while a user is on the page
      enabled: !!msaId,
    }
  );
};
