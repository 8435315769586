import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { useIsOverflowed, useUrlState } from '@hcs/hooks';
import { ArrayOneOrMore } from '@hcs/types';

import {
  FlexScroll,
  FlexScrollFixedContentProp,
} from '../../layout/FlexScroll';

import styles from './CardContentTabs.module.css';

export interface VerticalTab {
  tabId: string;
  noPadding?: boolean;
  tabIcon?: ReactNode;
  tabIconLabel?: ReactNode;
  tabLabelTop?: ReactNode;
  tabLabelBottom?: ReactNode;
  tabContent?: ReactNode;
  content: ReactNode;
}

export interface CardContentTabsProps {
  dataHcName: string;
  dataHcEventSection?: string;
  /** For searchParam active tab */
  searchParamId: string;
  tabs: ArrayOneOrMore<VerticalTab>;
  footer?: FlexScrollFixedContentProp;
  active?: string;
  className?: string;
  theme?: Partial<{
    Tabs: string;
    Content: string;
  }>;
}

export const CardContentTabs = ({
  tabs,
  dataHcName,
  dataHcEventSection,
  className,
  searchParamId,
  active,
  footer,
  theme,
}: CardContentTabsProps) => {
  const stateId = `CardContentTabs_${searchParamId}`;
  const INITIAL_STATE: { activeTabId: string | null } = {
    activeTabId: active || null,
  };
  const {
    state: { activeTabId: activeTabIdState },
    actions: { patchUrlState },
  } = useUrlState(stateId, INITIAL_STATE);
  const setActiveTabId = (value: string) =>
    patchUrlState([{ op: 'add', path: '/activeTabId', value }]);
  const activeTabId = activeTabIdState || tabs[0].tabId;
  const activeTab = useMemo(() => {
    return tabs.find((t) => t.tabId === activeTabId);
  }, [activeTabId, tabs]);
  const elm = useRef<HTMLDivElement | null>(null);
  const isOverflowed = useIsOverflowed(elm, [tabs]);
  const tabsHaveIcons = !!tabs[0].tabIcon;
  const showFooter = !!(footer && footer.content);

  useEffect(() => {
    if (active) {
      setActiveTabId(active);
    }
  }, [active]);
  return (
    <section
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={classNames(
        styles.CardContentTabs,
        {
          [styles.withFooter]: footer && footer.content,
          [styles.isOverflowed]: isOverflowed,
        },
        className
      )}
      role="tablist"
    >
      <FlexScroll
        className={classNames(styles.Tabs, theme?.Tabs, {
          [styles.withFooter]: showFooter,
          [styles.withIcon]: tabsHaveIcons,
          [styles.withoutIcon]: !tabsHaveIcons,
        })}
        dataHcName={`${dataHcName}-flex-scroll`}
        footer={footer}
        theme={{
          Footer: styles.FlexScrollFooter,
          Scroll: styles.FlexScroll,
        }}
        ref={elm}
        noPadding
      >
        {tabs.map((verticalTab, index) => {
          const isSelected = verticalTab.tabId === activeTabId;
          return (
            <div
              key={`tab-${index}`}
              onClick={() => {
                setActiveTabId(verticalTab.tabId);
              }}
              data-hc-name={`${dataHcName}-tab-${index}`}
              className={classNames(styles.Tab, {
                [styles.active]: isSelected,
                [styles.withIcon]: tabsHaveIcons,
              })}
              role="tab"
              aria-selected={isSelected}
            >
              {verticalTab.tabIcon && (
                <div
                  data-hc-name={`${dataHcName}-tab-${index}-left`}
                  className={styles.LeftTabContent}
                >
                  <div className={styles.IconContent}>
                    {verticalTab.tabIconLabel && (
                      <label
                        className={styles.TabIconLabel}
                        data-hc-name={`${dataHcName}-tab-${index}-left-icon-label`}
                      >
                        {verticalTab.tabIconLabel}
                      </label>
                    )}
                    {verticalTab.tabIcon}
                  </div>
                </div>
              )}
              <div
                data-hc-name={`${dataHcName}-tab-${index}-right`}
                className={classNames(styles.RightTabContent, {
                  [styles.noIcon]: !verticalTab.tabIcon,
                  [styles.withIcon]: tabsHaveIcons,
                })}
              >
                {verticalTab.tabLabelTop && (
                  <label
                    className={styles.TabLabel}
                    data-hc-name={`${dataHcName}-tab-${index}-right-label`}
                  >
                    {verticalTab.tabLabelTop}
                  </label>
                )}
                {verticalTab.tabContent && (
                  <div
                    className={classNames(styles.TabContent, {
                      [styles.withoutIcon]: !tabsHaveIcons,
                    })}
                    data-hc-name={`${dataHcName}-tab-${index}-right-content`}
                  >
                    {verticalTab.tabContent}
                  </div>
                )}
                {verticalTab.tabLabelBottom && (
                  <label
                    className={classNames(styles.TabLabel, {
                      [styles.withoutIcon]: !tabsHaveIcons,
                    })}
                    data-hc-name={`${dataHcName}-tab-${index}-right-label`}
                  >
                    {verticalTab.tabLabelBottom}
                  </label>
                )}
              </div>
            </div>
          );
        })}
      </FlexScroll>
      <div
        data-hc-name={`${dataHcName}-tab-content`}
        className={classNames(theme?.Content, styles.Content, {
          [styles.noPadding]: activeTab?.noPadding,
        })}
      >
        {activeTab?.content || null}
      </div>
    </section>
  );
};
