import { gql } from 'graphql-request';

export const FRAGMENT_DISCOVERY_HC_ADDRESS = gql`
  fragment PropertyLocationDiscovery on CommonLocationDiscoveryHcAddress {
    address
    addressSlug
    blockGroupId
    blockId
    city
    completeYN
    county
    fips
    geoPrecision
    latitude
    longitude
    metroDivisionId
    msaId
    realYN
    state
    tractId
    unit
    verifiedYN
    zipcode
    zipcodePlus4
  }
`;

export const FRAGMENT_PROPERTY_LOCATION_HC = gql`
  fragment PropertyLocationHc on CommonLocationHc {
    neighborhood
    subdivision
  }
`;
export const FRAGMENT_PROPERTY_LOCATION = gql`
  ${FRAGMENT_DISCOVERY_HC_ADDRESS}
  ${FRAGMENT_PROPERTY_LOCATION_HC}
  fragment PropertyLocation on PropertyDetailsLocation {
    hc {
      ...PropertyLocationHc
    }
    discovery {
      hcAddress {
        ...PropertyLocationDiscovery
      }
    }
  }
`;
