import { useMemo } from 'react';

import { useLocalStorageState } from '@hcs/hooks';
import {
  ExperienceFlagIds,
  ExperienceFlagsDevtoolSliceState,
} from '@hcs/types';

import { EXPERIENCE_FLAG_CONFIGS } from '../constants';

const ALL_FLAG_IDS: ExperienceFlagIds[] = Object.keys(
  EXPERIENCE_FLAG_CONFIGS
) as ExperienceFlagIds[];
export const useExperienceFlagsDevLocalStorageState = () => {
  const localStorageResults = useLocalStorageState('expflags', ALL_FLAG_IDS);
  const results: {
    state: ExperienceFlagsDevtoolSliceState;
    actions: typeof localStorageResults.actions;
  } = useMemo(() => {
    const state: ExperienceFlagsDevtoolSliceState = {};
    Object.entries(localStorageResults.state).forEach(
      ([experienceFlagIdStr, stateStr]) => {
        const experienceFlagId = experienceFlagIdStr as ExperienceFlagIds;
        state[experienceFlagId] = {
          value: stateStr === 'true',
        };
      }
    );
    return {
      ...localStorageResults,
      state,
    };
  }, [localStorageResults]);
  return results;
};
