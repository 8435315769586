/** Dev / Debug / Internal feature used in Cerberus Explorer and Property State Storybook */
import React from 'react';

import { Dropdown, DropdownProps } from '@hcs/design-system';
import { PropertyStateSources } from '@hcs/types';

interface Props {
  value: PropertyStateSources | null;
  onSelect: DropdownProps<PropertyStateSources | null>['onSelect'];
  className?: string;
}
const OPTIONS = [
  {
    value: null,
    label: 'HCS Default (HouseCanary)',
  },
  {
    value: PropertyStateSources.HC,
    label: 'HouseCanary (HCS Default)',
  },
  {
    value: PropertyStateSources.MLS,
    label: 'MLS',
  },
  {
    value: PropertyStateSources.PR,
    label: 'Public Record',
  },
];
const dataHcName = 'property-state-source-dropdown';
export const PropertyStateSourceDropdown = (props: Props) => {
  return (
    <Dropdown
      dataHcName={dataHcName}
      options={OPTIONS}
      placeholder="Property State Source"
      {...props}
    />
  );
};
