import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Button, ButtonProps } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import {
  CompsListTypes,
  ReportFeatures,
  ReportId,
  SubjectValuePaths,
  ValuationMethod,
} from '@hcs/types';
import {
  formatDateShort,
  formatMoney,
  formatMoneyPerMonth,
  formatNumber,
} from '@hcs/utils';

import {
  VALUATION_METHOD_LABELS,
  VALUATION_METHOD_LABELS_RENTAL,
} from '../../constants';
import { CompValueInfoIcon } from '../../features/CompValueInfoIcon';
import { ReportFeaturesSupported } from '../../features/ReportFeaturesSupported';
import {
  useCompDocuments,
  useDocumentPatch,
  useReport,
  useReportPermissions,
  useSubjectValueDocument,
} from '../../hooks';
import { useAppraisalSubjectValueDocument } from '../../hooks/useAppraisalSubjectValueDocument';
import { useComparableValueHcSuggestedDocument } from '../../hooks/useComparableValueHcSuggestedDocument';
import { useCompsList } from '../../hooks/useCompsList';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';

import styles from './CompsListFooter.module.css';

export const REPORT_FEATURES_COMPS_LIST_FOOTER_SALE = [
  ReportFeatures.CompsSelect,
  ReportFeatures.CompsFarmView,
  ReportFeatures.CompsHcSuggested,
];
export const REPORT_FEATURES_COMPS_LIST_FOOTER_RENTAL = [
  ReportFeatures.RentalCompsSelect,
  ReportFeatures.RentalCompsFarmView,
];
interface PrimaryCtaBase
  extends Omit<ButtonProps, 'dataHcName' | 'secondary' | 'primary'> {
  actionType: 'selectComparableValue' | 'custom';
}

interface PrimaryCtaSelectComparable extends PrimaryCtaBase {
  actionType: 'selectComparableValue';
}
interface PrimaryCtaSelectCustom extends PrimaryCtaBase {
  actionType: 'custom';
  onClick: VoidFunction;
  label?: ReactNode;
}
export interface CompsListFooterProps {
  reportId: ReportId;
  compType: CompTypes;
  compsListType: CompsListTypes;
  className?: string;
  primaryCta?: PrimaryCtaSelectComparable | PrimaryCtaSelectCustom;
}
const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const dataHcName = 'comps-list-footer';
export const CompsListFooter = ({
  reportId,
  compType,
  compsListType,
  className,
  primaryCta,
}: CompsListFooterProps) => {
  const isRental = compType === CompTypes.Rental;
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: report } = useReport(reportId);
  const { data: reportConfig } = useReportConfig(reportId);
  const { data: compDocuments } = useCompDocuments(reportId, compType);
  const { data: subjectValueDocument } = useSubjectValueDocument(reportId, {
    showRentalValue: isRental,
  });
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { data: appraisalSubjectValueDoc } =
    useAppraisalSubjectValueDocument(reportId);
  const { data: compValueHcSuggestedDoc } =
    useComparableValueHcSuggestedDocument(reportId);
  const {
    state: { compsListQuery, pagination },
  } = useCompsList({ reportId, compType, compsListType });

  // Potential Comparable Values to display
  const compValueSelected =
    subjectValueDocument?.data.comparableValue.value.value;
  const compValueAppraisal =
    appraisalSubjectValueDoc?.data?.[ValuationMethod.Comps].value.value;
  const compValueHcSuggested =
    compValueHcSuggestedDoc?.data[ValuationMethod.Comps].value.value;
  // Pick a Comparable Value based on the compsListType
  const compValueToDisplay =
    compsListType === 'appraisal'
      ? compValueAppraisal
      : compsListType === 'hcSuggestedComps'
      ? compValueHcSuggested
      : compValueSelected;

  const canSelectComps = reportFeaturesSupportedAny(
    reportConfig,
    isRental
      ? [
          ReportFeatures.RentalCompsSelect,
          ReportFeatures.RentalCompsSelectByAddress,
          ReportFeatures.RentalCompsSelectByListing,
        ]
      : [
          ReportFeatures.CompsSelect,
          ReportFeatures.CompsSelectByAddress,
          ReportFeatures.CompsSelectByListing,
        ]
  );
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={
        compType === CompTypes.Rental
          ? REPORT_FEATURES_COMPS_LIST_FOOTER_RENTAL
          : REPORT_FEATURES_COMPS_LIST_FOOTER_SALE
      }
    >
      <footer
        data-hc-name={dataHcName}
        className={classNames(styles.CompsListFooter, className)}
      >
        <div className={styles.ValueButtonContainer}>
          <div>
            <ReportFeaturesSupported
              reportId={reportId}
              reportFeatures={
                isRental
                  ? [ReportFeatures.RentalComparableValue]
                  : [
                      ReportFeatures.ComparableValue,
                      compsListType === 'hcSuggestedComps'
                        ? ReportFeatures.CompsHcSuggested
                        : null,
                      compsListType === 'appraisal'
                        ? ReportFeatures.AppraisalComps
                        : null,
                    ]
              }
            >
              <div
                data-hc-name={`${dataHcName}-value-line`}
                className={styles.ValueLine}
              >
                <span
                  data-hc-name={`${dataHcName}-value`}
                  className={styles.Value}
                >
                  {compValueToDisplay
                    ? isRental
                      ? formatMoneyPerMonth(compValueToDisplay)
                      : formatMoney(compValueToDisplay)
                    : '$0'}
                </span>{' '}
                /{' '}
                <span>
                  {compsListType === 'appraisal'
                    ? `Appraisal ${
                        VALUATION_METHOD_LABELS[ValuationMethod.Comps]
                      }`
                    : compsListType === 'hcSuggestedComps'
                    ? `HC ${VALUATION_METHOD_LABELS[ValuationMethod.Comps]}`
                    : isRental
                    ? VALUATION_METHOD_LABELS_RENTAL[ValuationMethod.Comps]
                    : VALUATION_METHOD_LABELS[ValuationMethod.Comps]}
                </span>
                <CompValueInfoIcon
                  isRental={isRental}
                  theme={{
                    Button: styles.InfoTooltipButton,
                  }}
                />
              </div>
            </ReportFeaturesSupported>
            <div>
              <span data-hc-name={`${dataHcName}-available-comps-count`}>
                {formatNumber(
                  pagination?.totalCount || compsListQuery?.data.length
                )}
              </span>{' '}
              {compsListType === 'selected' || compsListType === 'appraisal' ? (
                `Comp${compsListQuery?.data.length === 1 ? '' : 's'} Selected`
              ) : (
                <>
                  Results as of{' '}
                  <span data-hc-name={`${dataHcName}-effective-date`}>
                    {formatDateShort(report?.effectiveDate)}
                  </span>
                </>
              )}
            </div>
          </div>
          <Button
            {...primaryCta}
            className={styles.Button}
            dataHcName={`${dataHcName}-button`}
            secondary={true}
            loading={!subjectValueDocument}
            onClick={(e) => {
              if (primaryCta?.actionType === 'selectComparableValue') {
                if (subjectValueDocument) {
                  documentPatchMutation.mutate({
                    reportId,
                    document: subjectValueDocument,
                    operations: [
                      {
                        op: 'replace',
                        path: PATH_SELECTED_VALUE,
                        value: ValuationMethod.Comps,
                      },
                    ],
                  });
                }
              }
              primaryCta?.onClick?.(e);
            }}
            label={
              primaryCta?.label ? (
                primaryCta.label
              ) : reportPermissions?.isEditable && canSelectComps ? (
                `Use ${formatNumber(compDocuments?.length || 0)} ${
                  compType === CompTypes.Rental ? 'Rental ' : ''
                }Comps`
              ) : canSelectComps ? (
                `${
                  compType === CompTypes.Rental ? 'Rental ' : ''
                }Comps Selected: ${formatNumber(compDocuments?.length || 0)}`
              ) : (
                <>Back to Report</>
              )
            }
          />
        </div>
      </footer>
    </ReportFeaturesSupported>
  );
};
