import {
  MlsState,
  PropertyTypeEnum,
  SpatialFiltersInput,
  SpatialSortPropertiesIdentifierInput,
} from '@hcs/types';
import { DateRange } from '@hcs/types';
import {
  BuyBox,
  BuyBoxFormData,
  BuyBoxZipcode,
  ExcludeFilterOrNull,
  LeadFeedPropertyTypeEnum,
  PropertyTypes as LeadFeedPropertyTypes,
  RangeFilters,
  TimeSeriesResolution,
} from '@hcs/types';
import { nullToUndefined } from '@hcs/utils';
import { dateToISOString, getTimeZone } from '@hcs/utils';

import { FetchBuyBoxActivityArg } from '../api';

export class BuyBoxBulkEditError extends Error {
  // base constructor only accepts string message as an argument
  // we extend it here to accept an object, allowing us to pass other data
  constructor(
    public buyBoxId: BuyBox['id'],
    public field: keyof RangeFilters,
    message: string
  ) {
    super(message);
    // Set the prototype explicitly.
    // Object.setPrototypeOf(this, BuyBoxBulkEditError.prototype);
    this.name = 'BulkEditBuyBoxError';
    this.buyBoxId = buyBoxId; // this property is defined in parent
    this.field = field;
  }
}

export const getIsExcludedFromexcludeFilter = <ExcludeValueType>(
  excludeFilter: ExcludeFilterOrNull<ExcludeValueType>
): boolean => {
  if (excludeFilter === null) {
    return false;
  }
  // if the exclude filter is not null, we should assume the exclusion is applied.
  return true;
};

export const getExcludedZipcodesInput = (
  unSelectedZips?: BuyBoxZipcode[]
): SpatialSortPropertiesIdentifierInput['excludeZipcodes'] => {
  if (!unSelectedZips || unSelectedZips.length === 0) {
    return undefined;
  }
  return { zipcodes: unSelectedZips };
};

const LeadFeedToSpatialPropertyTypeMap = {
  [LeadFeedPropertyTypeEnum.sfr]: PropertyTypeEnum.Sfr,
  [LeadFeedPropertyTypeEnum.condo]: PropertyTypeEnum.Condo,
  [LeadFeedPropertyTypeEnum.manufactured]: PropertyTypeEnum.Manufactured,
  [LeadFeedPropertyTypeEnum.multi]: PropertyTypeEnum.MultiFamily,
  [LeadFeedPropertyTypeEnum.townhouse]: PropertyTypeEnum.Townhouse,
  [LeadFeedPropertyTypeEnum.coop]: PropertyTypeEnum.Condo,
};

export const getPropertyTypeCerberusFromData = (
  propertyTypes: LeadFeedPropertyTypes[]
): PropertyTypeEnum[] | undefined => {
  return propertyTypes.reduce<PropertyTypeEnum[]>((accum, leadFeedPT) => {
    // coop is under the umbrella of condo in cerberus
    if (
      leadFeedPT === LeadFeedPropertyTypeEnum.coop ||
      leadFeedPT === LeadFeedPropertyTypeEnum.condo
    ) {
      // add condo if not already included
      if (accum.indexOf(PropertyTypeEnum.Condo) === -1) {
        accum.push(PropertyTypeEnum.Condo);
      }
    } else {
      accum.push(LeadFeedToSpatialPropertyTypeMap[leadFeedPT]);
    }
    return accum;
  }, []);
};

const FALSE_OR_NULL_SPATIAL_BOOL: [boolean, null] = [false, null];

export const getSpatialFiltersInput = (
  buyBoxData: BuyBoxFormData
): SpatialFiltersInput => {
  return {
    mlsState: [MlsState.Active, MlsState.ComingSoon],
    minListPrice: nullToUndefined(buyBoxData.listingPriceMin),
    maxListPrice: nullToUndefined(buyBoxData.listingPriceMax),
    minBathsWithHalfs: nullToUndefined(buyBoxData.bathsMin),
    maxBathsWithHalfs: nullToUndefined(buyBoxData.bathsMax),
    minBeds: nullToUndefined(buyBoxData.bedsMin),
    maxBeds: nullToUndefined(buyBoxData.bedsMax),
    minLotArea: nullToUndefined(buyBoxData.lotSizeMin),
    maxLotArea: nullToUndefined(buyBoxData.lotSizeMax),
    minStories: nullToUndefined(buyBoxData.numStoriesMin),
    maxStories: nullToUndefined(buyBoxData.numStoriesMax),
    minSqft: nullToUndefined(buyBoxData.glaMin),
    maxSqft: nullToUndefined(buyBoxData.glaMax),
    minYearBuilt: nullToUndefined(buyBoxData.yearBuiltMin),
    maxYearBuilt: nullToUndefined(buyBoxData.yearBuiltMax),
    pool: buyBoxData.excludePool ? FALSE_OR_NULL_SPATIAL_BOOL : undefined,
    privateSewer: buyBoxData.excludeSeptic
      ? FALSE_OR_NULL_SPATIAL_BOOL
      : undefined,
    propertyTypeEnum: getPropertyTypeCerberusFromData(buyBoxData.propertyType),
  };
};

export const getCommonIdParams = (
  buyBoxData: BuyBoxFormData
): SpatialSortPropertiesIdentifierInput => {
  return {
    msa: {
      msaIds: [`${buyBoxData.msaId}`],
    },
    excludeZipcodes: getExcludedZipcodesInput(buyBoxData.unSelectedZipcodes),
  };
};

export const dateRangeToFetchBuyBoxActivityArg = (
  dateRange: DateRange | null
): FetchBuyBoxActivityArg | null => {
  if (dateRange === null) {
    return null;
  }
  return {
    startDate: dateToISOString(dateRange.startDate),
    endDate: dateToISOString(dateRange.endDate),
    timeZone: getTimeZone(),
    resolution: TimeSeriesResolution.Day,
  };
};
