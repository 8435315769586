import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Dialog } from '@hcs/design-system';
import { StatusMessageProps } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import {
  AddressFormFields,
  CreateNewOrderState,
  OrderViaAddressEntry,
  OrderViaCsv,
  STEP,
} from '@hcs/types';

import {
  OrderSubmitCsvError,
  OrderSubmitEntryError,
  ValidationErrorAddressEntry,
  ValidationErrorCsv,
} from '../../api';
import { useNewOrderSubmitCsv } from '../../hooks/useNewOrderSubmitCsv';
import { useNewOrderSubmitEntry } from '../../hooks/useNewOrderSubmitEntry';
import { useOrderTypes } from '../../hooks/useOrderTypes';

import { AddAddress } from './AddAddress';
import { AddOrderAddressUploadForm } from './AddOrderAddressUploadForm';
import { AddOrderInspectionMethodForm } from './AddOrderInspectionMethodForm';
import { OrderDetails } from './OrderDetails';
import { ProductSelector } from './ProductSelector';
import { SelectedOptions } from './SelectedOptions';
import { createProductTypeChoices, getAddOrderFields } from './utils';

import styles from './NewOrderDialog.module.css';

const NUMBER_OF_ERRORS_TO_SHOW = 4;

// if number of messages is too many, add one that says (n) more errors
const getErrorMessagesToShow = (errorMessages: string[]): string[] => {
  const errorMessagesToShow: string[] = [];
  if (errorMessages.length > 0) {
    if (errorMessages.length > NUMBER_OF_ERRORS_TO_SHOW) {
      errorMessagesToShow.push(
        ...errorMessages.slice(0, NUMBER_OF_ERRORS_TO_SHOW)
      );
      errorMessagesToShow.push(
        `(${errorMessages.length - NUMBER_OF_ERRORS_TO_SHOW}) more errors`
      );
    } else {
      errorMessagesToShow.push(...errorMessages);
    }
  }
  return errorMessagesToShow;
};

export const getErrorMessagesFromCsvError = (
  submitCsvError: OrderSubmitCsvError | null,
  fallbackMessage: string
) => {
  if (submitCsvError === null) {
    return '';
  }
  let errorMessage: string | string[] =
    submitCsvError?.message || fallbackMessage;
  if (
    submitCsvError instanceof ValidationErrorCsv &&
    submitCsvError.data.orderFile
  ) {
    const errorMessages: string[] = [];
    if (Array.isArray(submitCsvError.data.orderFile)) {
      submitCsvError.data.orderFile.forEach((orderFileError) => {
        errorMessages.push(orderFileError);
      });
    } else {
      errorMessages.push(submitCsvError.data.orderFile);
    }
    errorMessage = getErrorMessagesToShow(errorMessages);
  }
  return errorMessage;
};

export const getErrorMessagesFromEntryError = (
  submitEntryError: OrderSubmitEntryError | null,
  fallbackMessage: string
): string | string[] => {
  if (submitEntryError === null) {
    return '';
  }
  let errorMessage: string | string[] =
    submitEntryError?.message || fallbackMessage;
  if (
    submitEntryError instanceof ValidationErrorAddressEntry &&
    submitEntryError.data.items
  ) {
    const errorMessages: string[] = [];
    submitEntryError.data.items.forEach((item) => {
      Object.entries(item).forEach(([key, value]: [string, string]) => {
        if (value !== null && value !== undefined) {
          // legacy had the value.toString(), not positive if it's still needed
          errorMessages.push(`${key}: ${value.toString()}`);
        }
      });
    });
    errorMessage = getErrorMessagesToShow(errorMessages);
  }

  return errorMessage;
};

interface Props {
  hide: () => void;
  onSuccess?: () => void;
  disabledSteps: STEP[];
  initialState?: CreateNewOrderState;
  uploadAddressCsvOrEntry?: {
    uploadAddressSubmit: {
      uploadCsv: (csv: File) => void;
      uploadEntry: (addressFormFields: AddressFormFields[]) => void;
    };
    uploadAddressSubmitIsLoading: boolean;
    resetUploadCsv: VoidFunction;
  };
  errors?: StatusMessageProps[];
  isAddingAddresses?: boolean;
}

const defaultState = {
  step: STEP.SELECT_PRODUCT,
  orderTypes: [],
};

const dataHcName = 'new-order-dialog';
const MAX_PRODUCT_CARD_PER_ROW = 3;

export const NewOrderDialog = ({
  hide,
  onSuccess,
  disabledSteps,
  initialState,
  uploadAddressCsvOrEntry,
  errors,
  isAddingAddresses = false,
}: Props) => {
  const {
    data: orderTypesData,
    isError: orderTypesIsError,
    error: orderTypesError,
  } = useOrderTypes();

  const [formState, setFormState] = useState<CreateNewOrderState>({
    ...defaultState,
    ...initialState,
  });

  const {
    mutate: submitEntry,
    isLoading: submitEntryIsLoading,
    isError: submitEntryIsError,
    error: submitEntryError,
  } = useNewOrderSubmitEntry();

  const {
    mutate: submitCsv,
    isLoading: submitCsvIsLoading,
    isError: submitCsvIsError,
    error: submitCsvError,
    reset: resetSubmitCsv,
  } = useNewOrderSubmitCsv();

  const updateStep = useCallback(
    (stateToUpdate: Partial<CreateNewOrderState>) => {
      setFormState((prevFormState) => ({
        ...prevFormState,
        ...stateToUpdate,
      }));
    },
    []
  );

  const productTypeChoices = useMemo(() => {
    return createProductTypeChoices(orderTypesData);
  }, [orderTypesData]);

  useEffect(() => {
    if (orderTypesData) {
      const orderTypes = formState.productType
        ? productTypeChoices.find(
            (productTypeChoice) =>
              productTypeChoice.productType === formState.productType
          )?.orderTypes || []
        : orderTypesData;

      setFormState((prevFormState) => ({ ...prevFormState, orderTypes }));
    }
  }, [orderTypesData, formState.productType, productTypeChoices]);

  useEffect(() => {
    /**
     * When there is inspection type with only 1 delivery speed,
     * add both data to formState so the inspection option
     * will be displayed on the dialog.
     */
    if (
      formState?.orderTypes?.length === 1 &&
      formState?.orderTypes?.[0]?.deliverySpeeds.length === 1 &&
      formState?.orderTypes?.[0]?.inspectionType
    ) {
      setFormState((prevFormState) => ({
        ...prevFormState,
        deliverySpeed: formState.orderTypes[0].deliverySpeeds[0],
        inspectionType: formState.orderTypes[0].inspectionType,
      }));
    }
  }, [formState.orderTypes]);

  const {
    productType,
    step,
    deliverySpeed,
    inspectionType,
    includesAsRepairedValue,
    orderTypes,
    itemsSource,
    orderFile,
    addressFormFields,
    selectedOrderType,
  } = formState;

  const newOrderDialogErrors: StatusMessageProps[] = [
    {
      dataHcName: 'order-types-error',
      show: orderTypesIsError,
      type: 'error',
      title: orderTypesError?.message || 'Failed to fetch order types',
    },
  ];

  if (submitEntryError) {
    newOrderDialogErrors.push({
      dataHcName: 'submit-entry-error',
      show: submitEntryIsError,
      type: 'error',
      title: getErrorMessagesFromEntryError(
        submitEntryError,
        'Failed to create new order via entry(s)'
      ),
    });
  }

  if (submitCsvError) {
    newOrderDialogErrors.push({
      dataHcName: 'submit-csv-error',
      show: submitCsvIsError,
      type: 'error',
      title: getErrorMessagesFromCsvError(
        submitCsvError,
        'Failed to create new order via CSV'
      ),
    });
  }

  const uploadAddressCsv = useMemo(() => {
    if (!uploadAddressCsvOrEntry) return undefined;
    return {
      uploadAddressSubmit:
        uploadAddressCsvOrEntry.uploadAddressSubmit.uploadCsv,
      uploadAddressSubmitIsLoading:
        uploadAddressCsvOrEntry.uploadAddressSubmitIsLoading,
    };
  }, [uploadAddressCsvOrEntry]);

  const uploadAddressEntry = useMemo(() => {
    if (!uploadAddressCsvOrEntry) return undefined;
    return {
      uploadAddressSubmit:
        uploadAddressCsvOrEntry.uploadAddressSubmit.uploadEntry,
      uploadAddressSubmitIsLoading:
        uploadAddressCsvOrEntry.uploadAddressSubmitIsLoading,
    };
  }, [uploadAddressCsvOrEntry]);

  return (
    <Dialog
      title={isAddingAddresses ? 'Add More Addresses' : 'Choose a Product'}
      active={true}
      dataHcName={dataHcName}
      onClose={hide}
      width={800}
      notifications={
        errors ? [...errors, ...newOrderDialogErrors] : newOrderDialogErrors
      }
    >
      {orderTypesData ? (
        <div className={styles.DialogContentContainer}>
          {productType && step > STEP.SELECT_PRODUCT && (
            <SelectedOptions
              orderTypes={orderTypes}
              updateStep={updateStep}
              productType={productType}
              inspectionType={inspectionType}
              deliverySpeed={deliverySpeed}
              stepNum={step}
              orderFile={orderFile}
              itemsSource={itemsSource}
              includesAsRepairedValue={includesAsRepairedValue}
              disabledSteps={disabledSteps}
            />
          )}
          {step === STEP.SELECT_PRODUCT && (
            <ProductSelector
              orderTypeDescriptors={orderTypesData}
              updateStep={updateStep}
              className={classNames({
                [styles.ProductSelectorContainer]:
                  productTypeChoices.length > MAX_PRODUCT_CARD_PER_ROW,
              })}
            />
          )}
          {step === STEP.INSPECTION_METHOD && (
            <AddOrderInspectionMethodForm
              showDeliveryOptions={!!inspectionType}
              updateStep={updateStep}
              orderTypes={orderTypes}
              deliverySpeed={deliverySpeed}
              inspectionType={inspectionType}
              includesAsRepairedValue={includesAsRepairedValue}
            />
          )}
          {productType && step === STEP.ADD_ADDRESS_METHOD && (
            <AddOrderAddressUploadForm
              updateStep={updateStep}
              orderType={selectedOrderType}
              uploadAddress={uploadAddressCsv}
              clearUploadErrors={() => {
                resetSubmitCsv();
                if (uploadAddressCsvOrEntry) {
                  uploadAddressCsvOrEntry.resetUploadCsv();
                }
              }}
            />
          )}
          {productType && step === STEP.UPLOAD_OR_ENTER_ADDRESS && (
            <AddAddress
              selectedOrderType={selectedOrderType}
              addressFormFields={addressFormFields}
              updateStep={updateStep}
              uploadAddress={uploadAddressEntry}
            />
          )}
          {productType && step === STEP.ORDER_DETAILS && (
            <OrderDetails
              itemsSource={itemsSource || ''}
              orderFileName={orderFile?.name || ''}
              deliverySpeed={deliverySpeed}
              selectedOrderType={selectedOrderType}
              items={addressFormFields || []}
              onSubmit={(data) => {
                const orderValues = getAddOrderFields(data, formState);

                if (itemsSource === 'csv' && orderFile) {
                  const orderViaCsv: OrderViaCsv = {
                    ...orderValues,
                    itemsSource,
                    orderFile,
                  };

                  submitCsv({ addOrderWithFile: orderViaCsv }, { onSuccess });
                } else if (
                  itemsSource === 'entry' &&
                  addressFormFields &&
                  addressFormFields.length > 0
                ) {
                  const orderViaAddressEntry: OrderViaAddressEntry = {
                    ...orderValues,
                    itemsSource,
                  };

                  submitEntry(
                    {
                      orderViaAddressEntry: orderViaAddressEntry,
                      preParsedItems: addressFormFields,
                    },
                    { onSuccess }
                  );
                }
              }}
              onSubmitIsLoading={submitEntryIsLoading || submitCsvIsLoading}
            />
          )}
        </div>
      ) : (
        <LoadingSpinner
          containerWidth="100%"
          containerHeight="100px"
          absoluteCenter
          dataHcName={`${dataHcName}-loading`}
        />
      )}
    </Dialog>
  );
};
