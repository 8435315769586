import { CompTypes, ReportPreferences } from '@hcs/types';

export const getPrimaryFilterSetPreferencesKey = (compType: CompTypes) =>
  compType === CompTypes.Rental ? 'rentalCompFilterSetId' : 'compFilterSetId';

export const getPrimaryFilterSetIdByCompType = (
  reportPreferences: ReportPreferences | undefined | null,
  compType: CompTypes
) => {
  return reportPreferences?.[getPrimaryFilterSetPreferencesKey(compType)];
};
