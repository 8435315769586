import { DropdownOptionType } from '@hcs/design-system';

export const BUY_BOX_SEARCH_PARAM_DELIMITER = ',';

// Days ago options for the activity monitor params
export const DAYS_AGO_OPTIONS: DropdownOptionType<number>[] = [
  {
    label: 'Last 7 Days',
    value: 7,
  },
  {
    label: 'Last 14 Days',
    value: 14,
  },
  {
    label: 'Last 30 Days',
    value: 30,
  },
];

// assert b/c we know DAYS_AGO_OPTIONS will be available and unchanging
export const DEFAULT_DAYS_AGO_OPTION =
  DAYS_AGO_OPTIONS[0] as DropdownOptionType<number>;

const SEARCH_PARAMS_PREFIX = 'activityMonitor';

export const SEARCH_PARAMS = {
  BUY_BOXES: `${SEARCH_PARAMS_PREFIX}-buy_boxes`,
  DAYS_AGO: `${SEARCH_PARAMS_PREFIX}-days_ago`,
};

// The page uses a color scheme for 3 or less selected buy boxes
export const MAX_BUY_BOX_FOR_COLORS = 3;
