import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { addHours } from 'date-fns';

import {
  OrganizationapiUsageQueryListResponse,
  OrgUsageQueryFilters,
  OrgUsageQueryOptions,
  OrgUsageQueryPagination,
  PaginatedResponseData,
} from '@hcs/types';

import { BillingApi } from '../api';

export const QUERY_KEY_ORG_USAGE_QUERY_LIST = 'QUERY_KEY_ORG_USAGE_QUERY_LIST';
export const useOrgUsageQueryList = (
  orgUsageQueryOptions: OrgUsageQueryOptions,
  options?: UseQueryOptions<
    PaginatedResponseData<OrganizationapiUsageQueryListResponse[]>,
    unknown,
    PaginatedResponseData<OrganizationapiUsageQueryListResponse[]>,
    [
      typeof QUERY_KEY_ORG_USAGE_QUERY_LIST,
      number,
      OrgUsageQueryPagination | undefined,
      OrgUsageQueryFilters | undefined
    ]
  >
) => {
  return useQuery(
    [
      QUERY_KEY_ORG_USAGE_QUERY_LIST,
      orgUsageQueryOptions.filters.orgId,
      orgUsageQueryOptions.pagination,
      orgUsageQueryOptions.filters,
    ],
    async () => {
      return await BillingApi.getOrgUsageQueryList(orgUsageQueryOptions);
    },
    {
      refetchInterval: (data) => {
        if (data) {
          if (
            data.data.some((query) => {
              const { status, createdAt } = query;
              if (
                createdAt &&
                (status === 'In Progress' || status === 'Created')
              ) {
                const createdAtDate = new Date(createdAt);
                const sixHoursAfterCreatedAt = addHours(createdAtDate, 6);
                // don't poll if it's been more than 6 hours since creation (since job has likely been abandoned)
                return new Date() < sixHoursAfterCreatedAt;
              }
              return false;
            })
          ) {
            return 5000;
          }
        }
        return false;
      },
      ...options,
    }
  );
};
