import {
  checkUserIsOrgAdmin,
  checkUserIsSelfService,
} from '../utils/appAccess.utils';

import { useAccount } from './useAccount';

export const useUserIsOrgAdmin = () => {
  const accountQuery = useAccount();
  if (accountQuery.isSuccess) {
    return {
      ...accountQuery,
      data:
        checkUserIsOrgAdmin(accountQuery.data) &&
        !checkUserIsSelfService(accountQuery.data),
    };
  } else {
    // Return account query status if data is not yet fetched
    return {
      ...accountQuery,
      data: undefined,
    };
  }
};
