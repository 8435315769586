import { useMemo } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { ItemObjInShoppingCart } from '@hcs/types';
import { DexpEndpoint, DexpEndpointsParams } from '@hcs/types';

import {
  DexpTemplateForUser,
  useDexpTemplatesForUser,
} from '../hooks/useDexpTemplatesForUser';

import { useDataExplorerSlice } from './useDataExplorerSlice';
import { useDexpEndpoints } from './useDexpEndpoints';

export const useDexpEndpointsTable = (
  filtersProp?: DexpEndpointsParams
): {
  state: {
    filters: DexpEndpointsParams;
    allAvailableDexpEndpoints: UseQueryResult<DexpEndpoint[]>;
    selectedDexpTemplate: DexpTemplateForUser | undefined;
    dexpEndpointsQuery: UseQueryResult<DexpEndpoint[]>;
    endpointsInShoppingCart: Record<DexpEndpoint['id'], ItemObjInShoppingCart>;
    selectedLength: number;
  };
  actions: {
    tableChangeFilter: (filterChanges: DexpEndpointsParams) => void;
    addToCart: (
      endpointId: DexpEndpoint['id'],
      name: DexpEndpoint['name'],
      level: DexpEndpoint['level']
    ) => void;
    removeFromCart: (endpointId: DexpEndpoint['id']) => void;
    deselectAndLeaveInCart: (endpointId: DexpEndpoint['id']) => void;
    clearCart: () => void;
    setEndpointsInCart: typeof actions.setEndpointsInCart;
  };
} => {
  const { data: dexpTemplates } = useDexpTemplatesForUser();
  const { state, actions } = useDataExplorerSlice();
  const filters = { ...state.filters, ...filtersProp };
  const dexpEndpointsQuery = useDexpEndpoints(filters);
  const allAvailableDexpEndpoints = useDexpEndpoints({
    ...filters,
    access: true,
  });

  return {
    state: {
      ...state,
      filters,
      allAvailableDexpEndpoints: allAvailableDexpEndpoints,
      dexpEndpointsQuery: dexpEndpointsQuery,
      selectedDexpTemplate: useMemo(() => {
        for (const templateId in dexpTemplates) {
          const template = dexpTemplates?.[templateId];
          if (template) {
            const { endpointsData } = template;

            const endpointsToCheck = { ...state.endpointsInShoppingCart };
            const endpointsIdsInShoppingCart = Object.keys(endpointsToCheck);
            const endpointsIdsInTemplate = Object.keys(endpointsData);
            const isSelectedTemplate =
              endpointsIdsInShoppingCart.length ===
                endpointsIdsInTemplate.length &&
              endpointsIdsInShoppingCart.every(function (element, index) {
                return element === endpointsIdsInTemplate[index];
              });

            if (isSelectedTemplate) {
              return template;
            }
          }
        }
        return undefined;
      }, [dexpTemplates, state.endpointsInShoppingCart]),
      selectedLength:
        useMemo(() => {
          const valuesInShoppingCart = Object.values(
            state.endpointsInShoppingCart
          );
          const selected = valuesInShoppingCart.filter(
            (value) => !!value.isSelected
          );
          return selected.length;
        }, [state.endpointsInShoppingCart]) || 0,
    },
    actions: {
      addToCart: (
        endpointId: DexpEndpoint['id'],
        name: DexpEndpoint['name'],
        level: DexpEndpoint['level']
      ) => {
        actions.addToCart({ endpointId, name, level });
      },
      removeFromCart: (endpointId: string) => {
        actions.removeFromCart({ endpointId });
      },
      deselectAndLeaveInCart: (endpointId: DexpEndpoint['id']) => {
        actions.deselectAndLeaveInCart({ endpointId });
      },
      clearCart: () => {
        actions.clearCart();
      },
      tableChangeFilter: (filterChanges: DexpEndpointsParams) => {
        actions.tableChangeFilter({ filterChanges });
      },
      setEndpointsInCart: actions.setEndpointsInCart,
    },
  };
};
