import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './MapButton.module.css';

export interface MapButtonProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  children?: ReactNode;
  label?: ReactNode;
  onClick?: VoidFunction;
}
export const MapButton = ({
  dataHcName,
  className,
  children,
  label,
  onClick,
}: MapButtonProps) => {
  return (
    <button
      data-hc-name={dataHcName}
      className={classNames(styles.MapButton, className)}
      onClick={onClick}
    >
      {label}
      {children}
    </button>
  );
};
