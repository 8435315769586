import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderOutlet } from '@hcs/design-system';
import {
  APP_CONFIG_MARKET_INSIGHTS,
  VIEW_PATHS_MARKET_INSIGHTS,
} from '@hcs/hc-products';

import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { MarketInsightsPageNav } from '../navigation/MarketInsightsPageNav/MarketInsightsPageNav';
import { MsaPage, MsaSearchPage, ZipPage, ZipSearchPage } from '../pages';

const MARKET_INSIGHTS_ROUTE_OBJECT: RouteObject[] = [
  {
    path: APP_CONFIG_MARKET_INSIGHTS.rootPath,
    element: (
      <PrivateApplicationPageRedirect>
        <LayoutGlobalHeaderOutlet header={<SolutionsPlatformGlobalHeader />} />
      </PrivateApplicationPageRedirect>
    ),
    children: [
      {
        path: '',
        element: (
          <LayoutContent noPadding>
            <Navigate
              to={`${APP_CONFIG_MARKET_INSIGHTS.rootPath}/${VIEW_PATHS_MARKET_INSIGHTS.SEARCH}`}
              replace
            />
          </LayoutContent>
        ),
      },
      {
        path: `${VIEW_PATHS_MARKET_INSIGHTS.SEARCH}`,
        element: (
          <>
            <MarketInsightsPageNav />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '',
            element: <Navigate to={VIEW_PATHS_MARKET_INSIGHTS.MSAS} replace />,
          },
          {
            path: VIEW_PATHS_MARKET_INSIGHTS.MSAS,
            element: <MsaSearchPage />,
          },
          {
            path: VIEW_PATHS_MARKET_INSIGHTS.MSA_REPORT,
            element: (
              <LayoutContent>
                <MsaPage />
              </LayoutContent>
            ),
          },
          {
            path: VIEW_PATHS_MARKET_INSIGHTS.ZIPS,
            element: <ZipSearchPage />,
          },
          {
            path: VIEW_PATHS_MARKET_INSIGHTS.ZIP_REPORT,
            element: (
              <LayoutContent>
                <ZipPage />
              </LayoutContent>
            ),
          },
        ],
      },
    ],
  },
];

export const routes = (): RouteObject[] => {
  return MARKET_INSIGHTS_ROUTE_OBJECT;
};
