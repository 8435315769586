import { AppSlugs, CapabilityApplications } from '@hcs/types';
import { AppConfig } from '@hcs/types';

// Fallback AppConfig that allows user access to the app
export const APP_CONFIG_FALLBACK: AppConfig = {
  appSlug: AppSlugs.Platform,
  applicationKey: CapabilityApplications.Platform,
  rootPath: '/',
  name: '',
  descriptionLong: '',
  descriptionShort: '',
};
