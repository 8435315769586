import React, { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as yup from 'yup';

import {
  ACCOUNT_FIELD_CONFIGS,
  AccountFieldInput,
  ResetPasswordInvalidTokenError,
  useCheckResetPasswordToken,
  useResetPassword,
} from '@hcs/auth';
import { Button, Card, LoadingSpinner } from '@hcs/design-system';
import {
  useNavigateToForgotPassword,
  useNavigateToRoot,
} from '@hcs/hc-products';
import { useToastSlice } from '@hcs/toast';
import { AccountFields, ResetPasswordFormFields } from '@hcs/types';

import styles from './ResetPassword.module.css';

const SearchParams = {
  Token: 'token',
};

interface Props {
  className?: string;
}
const yupSchema = yup.object().shape({
  [AccountFields.NewPassword]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.NewPassword].validator,
  [AccountFields.ConfirmPassword]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.ConfirmPassword].validator,
});
const dataHcName = 'reset-password';
export const ResetPassword = ({ className }: Props) => {
  const navigateToForgotPassword = useNavigateToForgotPassword();
  const navigateToDashboard = useNavigateToRoot();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(SearchParams.Token);
  const form = useForm<ResetPasswordFormFields>({
    resolver: yupResolver(yupSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const handleBadToken = useCallback(() => {
    toastOpen({
      type: 'error',
      title:
        'Reset password link is expired or invalid. Please request a new one.',
      duration: 10000,
    });
    navigateToForgotPassword();
  }, [toastOpen, navigateToForgotPassword]);
  const resetPasswordMutation = useResetPassword({
    onSuccess: () => {
      toastOpen({
        type: 'success',
        title: 'Password reset successful',
      });
      navigateToDashboard();
    },
    onError: (err) => {
      if (err instanceof ResetPasswordInvalidTokenError) {
        handleBadToken();
      } else {
        toastOpen({
          type: 'error',
          title: `Reset Password Error: ${err?.message}`,
        });
      }
    },
  });
  const resetPasswordTokenQuery = useCheckResetPasswordToken(token);

  useEffect(() => {
    if (resetPasswordTokenQuery.error) {
      if (
        resetPasswordTokenQuery.error instanceof ResetPasswordInvalidTokenError
      ) {
        handleBadToken();
      } else {
        toastOpen({
          type: 'error',
          title: `Reset Password Error: ${resetPasswordTokenQuery.error.message}`,
        });
      }
    }
  }, [resetPasswordTokenQuery.error, handleBadToken, toastOpen]);

  const handleSubmit = ({ newPassword }: ResetPasswordFormFields) => {
    if (token) {
      resetPasswordMutation.mutate({
        newPassword,
        token,
      });
    }
  };

  if (!resetPasswordTokenQuery.isFetched) {
    return (
      <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} absoluteCenter />
    );
  }

  return (
    <FormProvider {...form}>
      <form
        data-hc-name={dataHcName}
        className={classNames(styles.ResetPassword, className)}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Card dataHcName={dataHcName} className={className}>
          <Controller
            name={AccountFields.NewPassword}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                autoComplete="off"
                disabled={!resetPasswordTokenQuery.isSuccess}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.ConfirmPassword}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                autoComplete="off"
                error={form.formState.errors[field.name]?.message}
                disabled={!resetPasswordTokenQuery.isSuccess}
              />
            )}
          />
          <Button
            dataHcName={`${dataHcName}-submit-button`}
            label="Submit"
            type="submit"
            disabled={
              !form.formState.isValid || !resetPasswordTokenQuery.isSuccess
            }
          />
        </Card>
      </form>
    </FormProvider>
  );
};
