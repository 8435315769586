import React, { useMemo } from 'react';

import {
  HcMapMapboxLayerIds,
  LegendInterval,
  VectilesLayerSource,
  VectilesMetricGroups,
} from '@hcs/types';

import {
  SCHOOLS_LAYER_FILTER_DEF,
  SCHOOLS_LAYER_SCHOOL_KIND,
  VECTILES_METRICS_GROUP_CONFIG,
} from '../../constants';
import { useHcMap } from '../../hooks/useHcMap';
import { useHcMapLayerLegendBreaks } from '../../hooks/useHcMapLayerLegendBreaks';
import { useHcMapLayers } from '../../hooks/useHcMapLayers';
import {
  getBlocksLayerFillDefinition,
  isConsumerLayerEnum,
} from '../../utils/heatmap.utils';

import { BlocksLayer } from './BlocksLayer';
import { SchoolLayer } from './SchoolLayer';

interface Props {
  mapId: string;
}

const dataHcName = 'hcmap-layers';

export const HcMapLayers = ({ mapId }: Props) => {
  const { mapState } = useHcMap(mapId);
  const { vectilesMetricConfig } = useHcMapLayers(mapId);
  const intervals: LegendInterval[] | undefined =
    useHcMapLayerLegendBreaks(mapId);

  const groupZoomRange = useMemo(() => {
    const vectilesMetricGroup = mapState?.heatmap.vectilesMetricGroup;
    const groupConfig =
      vectilesMetricGroup && VECTILES_METRICS_GROUP_CONFIG[vectilesMetricGroup];
    const groupZoomRange: VectilesLayerSource['zoomRange'] = {
      max: 0,
      min: 1000,
    };
    groupConfig?.vectilesLayers.forEach(({ zoomRange }) => {
      if (zoomRange.min < groupZoomRange.min) {
        groupZoomRange.min = zoomRange.min;
      }
      if (zoomRange.max > groupZoomRange.max) {
        groupZoomRange.max = zoomRange.max;
      }
    });
    return groupZoomRange;
  }, [mapState?.heatmap.vectilesMetricGroup]);
  if (!mapState || !vectilesMetricConfig || !groupZoomRange) {
    return null;
  }
  const {
    markers: { showMarkers },
    heatmap: {
      vectilesMetricId,
      vectilesMetricGroup,
      showMonochrome,
      propertyType,
    },
  } = mapState;

  const beneathLayerId = showMarkers
    ? undefined
    : HcMapMapboxLayerIds.SCHOOL_SYMBOL_LAYER;

  return (
    <div data-hc-name={dataHcName}>
      {vectilesMetricGroup && vectilesMetricId && (
        <BlocksLayer
          /* HACK(mikep): BlocksLayer can't handle changing of the keys specified in the paint object,
            so force a rerender based on hasMonochromeOverlay which changes the fill properties */
          key={`mo-${showMonochrome}`}
          mapId={mapId}
          paint={{
            'fill-opacity': 0.4,
            ...getBlocksLayerFillDefinition(
              showMonochrome,
              propertyType,
              intervals,
              vectilesMetricConfig.vectilesMetric
            ),
          }}
          beneathLayerId={beneathLayerId}
        />
      )}

      {vectilesMetricGroup === VectilesMetricGroups.Schools && (
        <SchoolLayer
          featureFilter={(feature) =>
            !!feature.properties &&
            vectilesMetricConfig.vectilesMetric &&
            isConsumerLayerEnum(vectilesMetricConfig.vectilesMetric) &&
            SCHOOLS_LAYER_FILTER_DEF[vectilesMetricConfig.vectilesMetric] ===
              feature.properties.level &&
            feature.properties.kind === SCHOOLS_LAYER_SCHOOL_KIND
          }
          changeFilterTrigger={vectilesMetricId}
        />
      )}
    </div>
  );
};
