import { gql } from 'graphql-request';

import {
  FRAGMENT_COMPLEX_FIELDS_RENTAL_PREVIEW,
  FRAGMENT_COMPLEX_FIELDS_SALE_CORE,
} from '@hcs/cerberus';
import { FRAGMENT_PROPERTY_LOCATION } from '@hcs/cerberus';

const FRAGMENT_PROPERTY_SPATIAL_DETAIL_RESULTS = gql`
  ${FRAGMENT_PROPERTY_LOCATION}
  ${FRAGMENT_COMPLEX_FIELDS_SALE_CORE}
  ${FRAGMENT_COMPLEX_FIELDS_RENTAL_PREVIEW}
  fragment PropertySpatialDetailResults on SpatialSearchDetailResults {
    __typename
    atEnd
    cursor
    totalCount
    hits {
      propertyDetails {
        hcAddressId
        location {
          ...PropertyLocation
        }
      }
      complexFields {
        sale {
          ...ComplexFieldsSaleCore
        }
        rental {
          ...ComplexFieldsRentalPreview
        }
      }
    }
  }
`;

export const QUERY_PROPERTY_SPATIAL_SEARCH_LIST = gql`
  ${FRAGMENT_PROPERTY_SPATIAL_DETAIL_RESULTS}
  query PropertySpatialSearchList(
    $id: SpatialSortPropertiesIdentifierInput!
    $limit: Int!
    $cursor: String
    $sort: SpatialSortInput
    $spatialFilters: SpatialSortFiltersInput!
  ) {
    # HC APP: Lead Feed
    propertySpatialESSearch(
      id: $id
      limit: $limit
      sort: $sort
      pagination: { paginate: true, cursor: $cursor }
      spatialFilters: $spatialFilters
    ) {
      ... on SpatialSearchDetailResults {
        ...PropertySpatialDetailResults
      }
    }
  }
`;

export const QUERY_PROPERTY_SPATIAL_SEARCH_MAP = gql`
  ${FRAGMENT_PROPERTY_SPATIAL_DETAIL_RESULTS}
  query PropertySpatialSearchMap(
    $id: SpatialSortPropertiesIdentifierInput!
    $limit: Int!
    $spatialFilters: SpatialSortFiltersInput!
  ) {
    # HC APP: Lead Feed
    propertySpatialESSearch(
      id: $id
      limit: $limit
      countLimit: { type: AUTO }
      spatialFilters: $spatialFilters
    ) {
      __typename
      ... on SpatialSearchDetailResults {
        ...PropertySpatialDetailResults
      }
      ... on SpatialSearchCountResult {
        count
        labelLocation(format: DICT)
      }
      ... on SpatialSearchOverCountResult {
        moreThan
        labelLocation(format: DICT)
      }
    }
  }
`;

export const QUERY_PROPERTY_SPATIAL_SEARCH_COUNT = gql`
  query PropertySpatialSearchCount(
    $id: SpatialSortPropertiesIdentifierInput!
    $spatialFilters: SpatialSortFiltersInput!
  ) {
    # HC APP: Lead Feed
    propertySpatialESSearch(
      id: $id
      pagination: { paginate: false }
      spatialFilters: $spatialFilters
    ) {
      __typename
      ... on SpatialSearchCountResult {
        count
      }
      ... on SpatialSearchOverCountResult {
        moreThan
      }
    }
  }
`;
