import React, { useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import classNames from 'classnames';

import { AddIcon, Button, Input, StateDropdown } from '@hcs/design-system';
import {
  BrokerLicenseField,
  BrokerLicenseFieldLabel,
  Personalization,
} from '@hcs/types';

import styles from './PersonalizationForm.module.css';

export const licenseTemplate = {
  [BrokerLicenseField.LicenseState]: null,
  [BrokerLicenseField.Number]: null,
  [BrokerLicenseField.Expiration]: null,
  [BrokerLicenseField.Name]: null,
  [BrokerLicenseField.AddressLine1]: null,
  [BrokerLicenseField.AddressLine2]: null,
  [BrokerLicenseField.City]: null,
  [BrokerLicenseField.State]: null,
  [BrokerLicenseField.Zipcode]: null,
  [BrokerLicenseField.Email]: null,
  [BrokerLicenseField.Phone]: null,
};

export const BrokerLicensesField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<Personalization>();

  const { fields, append, remove } = useFieldArray<
    Personalization,
    'brokerLicenses'
  >({
    control: control,
    name: 'brokerLicenses',
  });
  const brokerLicenseValuesObj = useWatch({
    name: 'brokerLicenses',
    control: control,
  });

  useEffect(() => {
    if (brokerLicenseValuesObj?.length === 0) {
      append(licenseTemplate);
    }
  }, [brokerLicenseValuesObj, append]);

  const dataHcName = 'broker-licenses-field';
  const newLicense =
    brokerLicenseValuesObj?.[brokerLicenseValuesObj?.length - 1] || {};
  const validNewLicense = !!(
    newLicense?.[BrokerLicenseField.LicenseState] &&
    newLicense?.[BrokerLicenseField.Number] &&
    newLicense?.[BrokerLicenseField.Expiration] &&
    newLicense?.[BrokerLicenseField.Name] &&
    newLicense?.[BrokerLicenseField.AddressLine1] &&
    newLicense?.[BrokerLicenseField.City] &&
    newLicense?.[BrokerLicenseField.State] &&
    newLicense?.[BrokerLicenseField.Zipcode] &&
    newLicense?.[BrokerLicenseField.Email] &&
    newLicense?.[BrokerLicenseField.Phone]
  );

  return (
    <>
      <div className={styles.LicenseFieldContainer}> License</div>
      {fields.map((item, index) => {
        return (
          <div className={styles.LicenseContainer} key={item.id}>
            <Controller
              name={`brokerLicenses.${index}`}
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                const { ref, ...rest } = field;
                const eachErrorMsg =
                  errors?.['brokerLicenses'] &&
                  errors?.['brokerLicenses'][index]?.message;
                const getEachErrorMsg = (
                  field: BrokerLicenseField,
                  label: BrokerLicenseFieldLabel
                ) =>
                  errors?.['brokerLicenses']?.[index]?.[
                    field
                  ]?.message?.replace(
                    `brokerLicenses[${index}][${field}]`,
                    label
                  );

                const fieldValues = {
                  [BrokerLicenseField.LicenseState]:
                    field.value[BrokerLicenseField.LicenseState],
                  [BrokerLicenseField.Number]:
                    field.value[BrokerLicenseField.Number],
                  [BrokerLicenseField.Expiration]:
                    field.value[BrokerLicenseField.Expiration],
                  [BrokerLicenseField.Name]:
                    field.value[BrokerLicenseField.Name],
                  [BrokerLicenseField.AddressLine1]:
                    field.value[BrokerLicenseField.AddressLine1],
                  [BrokerLicenseField.AddressLine2]:
                    field.value[BrokerLicenseField.AddressLine2],
                  [BrokerLicenseField.City]:
                    field.value[BrokerLicenseField.City],
                  [BrokerLicenseField.State]:
                    field.value[BrokerLicenseField.State],
                  [BrokerLicenseField.Zipcode]:
                    field.value[BrokerLicenseField.Zipcode],
                  [BrokerLicenseField.Email]:
                    field.value[BrokerLicenseField.Email],
                  [BrokerLicenseField.Phone]:
                    field.value[BrokerLicenseField.Phone],
                };

                return (
                  <>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.LicenseState}
                      </label>
                      <StateDropdown
                        // field includes a ref property so it caused
                        // "Warning: Function components cannot be given refs" on the dropdown field.
                        // Since Controller already takes care of the registration process,
                        // we can just remove ref from field to prevent this warning.
                        {...rest}
                        className={classNames(
                          styles.Input,
                          styles.StateDropdown
                        )}
                        theme={{ DropdownError: styles.DropdownError }}
                        value={
                          field.value[BrokerLicenseField.LicenseState] || ''
                        }
                        dataHcName={`${dataHcName}-${field.name}`}
                        onSelect={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.LicenseState]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.LicenseState,
                          BrokerLicenseFieldLabel.LicenseState
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.Number}
                      </label>
                      <Input
                        {...field}
                        value={field.value[BrokerLicenseField.Number] || ''}
                        maxLength={35}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.Number]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.Number,
                          BrokerLicenseFieldLabel.Number
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.Expiration}
                      </label>
                      <Input
                        {...field}
                        placeholder="YYYY-MM-DD"
                        value={field.value[BrokerLicenseField.Expiration] || ''}
                        maxLength={10}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.Expiration]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.Expiration,
                          BrokerLicenseFieldLabel.Expiration
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.Name}
                      </label>
                      <Input
                        {...field}
                        value={field.value[BrokerLicenseField.Name] || ''}
                        maxLength={64}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.Name]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.Name,
                          BrokerLicenseFieldLabel.Name
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.AddressLine1}
                      </label>
                      <Input
                        {...field}
                        value={
                          field.value[BrokerLicenseField.AddressLine1] || ''
                        }
                        maxLength={40}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.AddressLine1]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.AddressLine1,
                          BrokerLicenseFieldLabel.AddressLine1
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.AddressLine2}
                      </label>
                      <Input
                        {...field}
                        value={
                          field.value[BrokerLicenseField.AddressLine2] || ''
                        }
                        maxLength={40}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.AddressLine2]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.AddressLine2,
                          BrokerLicenseFieldLabel.AddressLine2
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.City}
                      </label>
                      <Input
                        {...field}
                        value={field.value[BrokerLicenseField.City] || ''}
                        maxLength={25}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.City]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.City,
                          BrokerLicenseFieldLabel.City
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.State}
                      </label>
                      <StateDropdown
                        // field includes a ref property so it caused
                        // "Warning: Function components cannot be given refs" on the dropdown field.
                        // Since Controller already takes care of the registration process,
                        // we can just remove ref from field to prevent this warning.
                        {...rest}
                        className={classNames(
                          styles.Input,
                          styles.StateDropdown
                        )}
                        theme={{ DropdownError: styles.DropdownError }}
                        value={field.value[BrokerLicenseField.State] || ''}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onSelect={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.State]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.State,
                          BrokerLicenseFieldLabel.State
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.Zipcode}
                      </label>
                      <Input
                        {...field}
                        value={field.value[BrokerLicenseField.Zipcode] || ''}
                        maxLength={10}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.Zipcode]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.Zipcode,
                          BrokerLicenseFieldLabel.Zipcode
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.Email}
                      </label>
                      <Input
                        {...field}
                        value={field.value[BrokerLicenseField.Email] || ''}
                        maxLength={40}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.Email]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.Email,
                          BrokerLicenseFieldLabel.Email
                        )}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        {BrokerLicenseFieldLabel.Phone}
                      </label>
                      <Input
                        {...field}
                        value={field.value[BrokerLicenseField.Phone] || ''}
                        maxLength={20}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          field.onChange({
                            ...fieldValues,
                            [BrokerLicenseField.Phone]: value,
                          });
                        }}
                        error={getEachErrorMsg(
                          BrokerLicenseField.Phone,
                          BrokerLicenseFieldLabel.Phone
                        )}
                      />
                    </div>
                    <div className={styles.RemoveButtonContainer}>
                      <Button
                        type="button"
                        className={styles.RemoveButton}
                        dataHcName={`${dataHcName}-remove-value-button`}
                        label="Remove"
                        primary={false}
                        onClick={() => remove(index)}
                      />
                      {invalid && (
                        <div
                          data-hc-name={`${dataHcName}-error`}
                          className={styles.Error}
                        >
                          {eachErrorMsg}
                        </div>
                      )}
                    </div>
                  </>
                );
              }}
            />
          </div>
        );
      })}

      {errors?.['brokerLicenses']?.message && (
        <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
          {errors?.['brokerLicenses']?.message}
        </div>
      )}

      {/* Add new License */}
      <div className={styles.LicenseNewFieldContainer}>
        <div className={styles.AddLicenseButtonContainer}>
          <Button
            dataHcName={`${dataHcName}-button`}
            icon={<AddIcon />}
            label="Add License"
            disabled={!!errors?.['brokerLicenses'] || !validNewLicense}
            onClick={() => {
              append(licenseTemplate);
            }}
            secondary
            type="button"
          />
        </div>
      </div>
    </>
  );
};
