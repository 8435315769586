// Removes special characters and white space, makes all characters lowercase
const _searchSafe = (str?: string | null): string =>
  str ? str.toLocaleLowerCase().replace(/[^A-Z0-9]/gi, '') : '';

export const matchBeginningOfAllWords = (input: string, src: string) => {
  const inputWords = input.replace(/\s/g, ' ').split(' ');
  const srcWords = src.replace(/\s/g, ' ').split(' ');
  let match = false;
  for (const word of inputWords) {
    const inputWord = _searchSafe(word);
    let inputWordMatch = false;
    if (inputWord) {
      // Match can only be true if one of the input words isa  valid search string
      match = true;
      for (const sWord of srcWords) {
        const srcWord = _searchSafe(sWord);
        if (srcWord && srcWord.slice(0, inputWord.length) === inputWord) {
          inputWordMatch = true;
          break;
        }
      }
      if (!inputWordMatch) {
        // The input word doesn't have a match, exit
        return false;
      }
    }
  }
  return match;
};
