import React, { useState } from 'react';

import { Input } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  CompFieldConfig,
  CompFields,
  CompFieldsArgs,
  CompFieldsEditArg,
  CompFieldsTableCellProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatMoney, formatNumber, unformat } from '@hcs/utils';

const FIELD = CompFields.userTotalDollarAdjustment;
type FieldConfig = CompFieldConfig<typeof FIELD>;
const label = 'Your Adjustment';
const labelShort = 'Your Adjustment';
const compPath: FieldConfig['compPath'] =
  '/userAdjustments/totalDollarAdjustment';
const getValue: FieldConfig['getValue'] = (comp) =>
  comp?.userAdjustments?.totalDollarAdjustment;
const formatValue: FieldConfig['formatValue'] = (comp) =>
  formatMoney(getValue(comp));
const formatInline: FieldConfig['formatInline'] = (comp) =>
  formatMoney(getValue(comp));

// Component for displaying a field from a comp
const Display = ({ comp }: CompFieldsArgs) => {
  return <>{formatValue(comp)}</>;
};

const Edit = ({
  comp,
  onChange,
  className,
}: CompFieldsArgs & {
  onChange: (args: CompFieldsEditArg<typeof FIELD>) => void;
}) => {
  const defaultValue = getValue(comp);
  const [value, setValue] = useState(formatMoney(defaultValue));

  return (
    <Input
      className={className}
      dataHcName={`edit-${FIELD}`}
      value={value?.toString() || ''}
      onChange={(value) => setValue(value)}
      onFocus={() => {
        setValue(unformat(value).toString());
      }}
      onBlur={() => {
        setValue(formatNumber(Number(value)));
        onChange({
          path: compPath,
          value: Number(value),
          field: CompFields.userTotalDollarAdjustment,
        });
      }}
      maxLength={25}
    />
  );
};

const HeaderCell = (props: TableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({ comp, ...tableCellProps }: CompFieldsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display comp={comp} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const USER_TOTAL_DOLLAR_ADJUSTMENT_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  compPath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
};
