import React from 'react';
import { RouteObject } from 'react-router-dom';

import { LayoutGlobalHeaderChildren } from '@hcs/design-system';
import { APP_CONFIG_CANARYAI } from '@hcs/hc-products';

import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { CanaryAI } from '../pages';

const CANARYAI_ROUTE_OBJECT: RouteObject[] = [
  {
    path: APP_CONFIG_CANARYAI.rootPath,
    element: (
      <PrivateApplicationPageRedirect>
        <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
          <CanaryAI />
        </LayoutGlobalHeaderChildren>
      </PrivateApplicationPageRedirect>
    ),
  },
];

export const routes = (): RouteObject[] => {
  return CANARYAI_ROUTE_OBJECT;
};
