import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useAccount } from '@hcs/auth';
import { SelfServeOrgInfo } from '@hcs/types';

import { OrganizationApi } from '../api';

export const QUERY_KEY_ORG_SELF_SERVE_INFO = 'QUERY_KEY_ORG_SELF_SERVE_INFO';

export const useOrgSelfServeInfo = (
  options?: UseQueryOptions<
    SelfServeOrgInfo | null,
    unknown,
    SelfServeOrgInfo | null,
    [string]
  >
) => {
  const accountQuery = useAccount();
  return useQuery<
    SelfServeOrgInfo | null,
    unknown,
    SelfServeOrgInfo | null,
    [string]
  >(
    [QUERY_KEY_ORG_SELF_SERVE_INFO],
    async () => {
      try {
        const orgSsInfo = (await OrganizationApi.fetchOrgInfo()).data;
        const activeUntil = orgSsInfo?.active_plan?.active_until;
        const currentPlan = orgSsInfo?.self_serve_plan;
        const upcomingPlan = orgSsInfo?.upcoming_self_serve_plan;
        return {
          ...orgSsInfo,
          cancelled_subscription: !!(activeUntil !== null && !upcomingPlan),
          changed_plan: !!(
            activeUntil !== null &&
            upcomingPlan &&
            (upcomingPlan.name !== currentPlan?.name ||
              upcomingPlan.term !== currentPlan?.term)
          ),
        };
      } catch (e) {
        return null;
      }
    },
    {
      ...options,
      enabled: options?.enabled === false ? false : !!accountQuery.data,
    }
  );
};
