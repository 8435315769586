import React, { useEffect } from 'react';
import classNames from 'classnames';

import { ToastProps } from '@hcs/types';

import { AnimatedCheck } from '../../../../foundations/svgs/icons/animated/AnimatedCheck';
import { InfoIcon } from '../../../../svgs/icons/function';
import { RefreshIcon } from '../../../../svgs/icons/generics';
import { ErrorIcon } from '../../../../svgs/icons/indicator';
import { LoadingBar } from '../../../global/loading-errors-null/LoadingBar';

import styles from './ToastMessage.module.css';

const DEFAULT_DURATION = 5000;
export interface ToastMessageProps {
  className?: string;
  toast: ToastProps;
  closeToast: () => { payload: undefined; type: `${string}/toastClose` };
}

const dataHcName = 'toast-message';
export const ToastMessage = ({
  className,
  toast,
  closeToast,
}: ToastMessageProps) => {
  const { type, title } = toast || {};

  useEffect(() => {
    // This can be null because there's cases where we want to leave a notification open.
    if (toast?.duration === null) {
      return;
    }
    const timerId = toast
      ? setTimeout(closeToast, toast.duration || DEFAULT_DURATION)
      : undefined;
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [closeToast, toast]);
  return (
    <div
      key="toast"
      data-hc-name={dataHcName}
      className={classNames(styles.Toast, className, toast.className)}
    >
      <div className={styles.Content}>
        {
          <div className={styles.IconCell}>
            {type === 'loading' && (
              <RefreshIcon dataHcName={`${dataHcName}-icon`} />
            )}
            {(type === 'success' || type === 'loading-success') && (
              <AnimatedCheck
                dataHcName={`${dataHcName}-icon`}
                height={25}
                width={25}
              />
            )}
            {(type === 'error' || type === 'loading-failure') && (
              <ErrorIcon
                dataHcName={`${dataHcName}-failure-icon`}
                size="lg"
                color="error-10"
              />
            )}
            {type === 'warning' && (
              <InfoIcon
                dataHcName={`${dataHcName}-warning-icon`}
                size="lg"
                color="secondary-10"
              />
            )}
            {type === 'info' && (
              <InfoIcon
                dataHcName={`${dataHcName}-info-icon`}
                size="lg"
                color="neutral-dark-20"
              />
            )}
          </div>
        }
        <div data-hc-name={`${dataHcName}-title`} className={styles.TitleCell}>
          {title}
        </div>
      </div>
      {(type === 'loading' || type === 'loading-success') && (
        <LoadingBar
          key="toast-progress"
          height={6}
          className={styles.Progress}
          dataHcName={`${dataHcName}-progress`}
          complete={type === 'loading-success'}
        />
      )}
    </div>
  );
};
