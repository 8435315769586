import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  HorizontalSelector,
  HorizontalSelectorOption,
} from '../../../controls-and-inputs/selects/HorizontalSelector';

import styles from './Tabs.module.css';

export interface TabOption<T extends string | number>
  extends HorizontalSelectorOption<T> {
  content: ReactNode;
  // Allow click callbacks to be fired but do not allow the tab to appear "active"
  preventActiveState?: boolean;
}

export interface TabsProps<T extends string | number> {
  /** For searchParam active tab */
  searchParamId: string;
  className?: string;
  dataHcName: string;
  dataHcEventSection?: string;
  tabs: TabOption<T>[];
  active?: T;
  theme?: Partial<{ Content: string; Tabs: string; TabsSection: string }>;
  children?: ReactNode;
  /** Allow temporarily hiding the tabs */
  hideTabs?: boolean;
  onChange?: (value: T) => void;
  persistState?: boolean;
}

const SEARCH_PARAM_ACTIVE_TAB = 'tabs-activeTab';
export const Tabs = <T extends string | number>({
  dataHcName,
  dataHcEventSection,
  className,
  searchParamId,
  tabs,
  onChange,
  hideTabs,
  active,
  children,
  theme,
  persistState = true,
}: TabsProps<T>) => {
  const searchParamActiveTab = `${SEARCH_PARAM_ACTIVE_TAB}-${searchParamId}`;
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabId, setActiveTabId] = useState<T | undefined>(
    (persistState === true
      ? (searchParams.get(searchParamActiveTab) as T | undefined)
      : undefined) ||
      active ||
      tabs[0]?.value
  );
  const activeTab = useMemo(() => {
    const a = tabs.find((t) => t.value === activeTabId);
    return a;
  }, [tabs, activeTabId, active]);
  const handleSetActiveTabId = (newActiveTabId: T) => {
    const newActiveTab = tabs.find((t) => t.value === newActiveTabId);
    if (!newActiveTab?.preventActiveState) {
      setActiveTabId(newActiveTabId);
      if (persistState) {
        searchParams.set(searchParamActiveTab, newActiveTabId.toString());
        setSearchParams(searchParams);
      }
      onChange?.(newActiveTabId);
    }
  };
  useEffect(() => {
    if (active) {
      setActiveTabId(active);
    }
  }, [active]);
  return (
    <>
      {!hideTabs && (
        <div
          data-hc-name={dataHcName}
          data-hc-event-section={dataHcEventSection}
          className={classNames(
            styles.TabsSection,
            className,
            theme?.TabsSection
          )}
        >
          <HorizontalSelector
            className={classNames(styles.Tabs, theme?.Tabs)}
            dataHcName={`${dataHcName}-horz-selector`}
            options={tabs}
            onSelect={handleSetActiveTabId}
            value={activeTabId}
            theme={{
              List: styles.List,
              ListItem: styles.ListItem,
              Hr: styles.Hr,
            }}
          />
          <div>{children}</div>
        </div>
      )}

      {activeTab?.content && (
        <div data-hc-name={`${dataHcName}-content`} className={theme?.Content}>
          {activeTab.content}
        </div>
      )}
    </>
  );
};
