import {
  useTrackClickEvents,
  useUtmCodeEngagementEventData,
} from '../../hooks';
import { useTrackBlurEvents } from '../../hooks/useTrackBlurEvents';
import { useTrackPageViewedEvents } from '../../hooks/useTrackPageViewedEvents';

export const EngagementTracking = () => {
  useUtmCodeEngagementEventData();
  useTrackPageViewedEvents();
  useTrackClickEvents();
  useTrackBlurEvents();
  return null;
};
