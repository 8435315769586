import React from 'react';
import classnames from 'classnames';

import { Dialog } from '@hcs/design-system';
import { StatusMessageProps } from '@hcs/design-system';

import styles from './CancelOrderDialog.module.css';

interface Props {
  headerText?: string;
  hideModal: () => void;
  cancelOrder: () => void;
  isLoadingCancelOrder: boolean;
  isSuccessCancelOrder: boolean;
  isOrderItem?: boolean;
  notifications?: StatusMessageProps[];
}

const dataHcName = 'cancel-order-dialog';
export const CancelOrderDialog = ({
  headerText,
  hideModal,
  cancelOrder,
  isLoadingCancelOrder,
  isSuccessCancelOrder,
  isOrderItem,
  notifications,
}: Props) => {
  const HEADER_TEXT_DEFAULT = 'Canceling Order';

  return (
    <Dialog
      dataHcName={dataHcName}
      active={true}
      title={headerText || HEADER_TEXT_DEFAULT}
      onClose={hideModal}
      theme={{
        Dialog: styles.Dialog,
      }}
      actions={
        !isSuccessCancelOrder
          ? [
              {
                dataHcName: `${dataHcName}-cancel`,
                secondary: true,
                onClick: () => hideModal(),
                children: (
                  <div>No, Keep This {isOrderItem ? 'Address' : 'Order'}</div>
                ),
              },
              {
                dataHcName: `${dataHcName}-confirm`,
                primary: true,
                onClick: () => cancelOrder(),
                children: <div>Cancel {isOrderItem ? 'Address' : 'Order'}</div>,
                loading: isLoadingCancelOrder,
              },
            ]
          : undefined
      }
      notifications={notifications}
    >
      <div
        className={classnames({
          [styles.AreYouSure]: isSuccessCancelOrder,
        })}
      >
        {isOrderItem
          ? 'Are you sure you want to cancel this address? Any addresses cancelled may incur a fee. We will reach out to our partner(s) to cancel the address.'
          : 'Are you sure you want to cancel this order? Any orders cancelled may incur a fee. We will reach out to our partner(s) to cancel the order.'}
      </div>
      <br />
      {isSuccessCancelOrder && (
        <div className={styles.CancelSuccess}>
          The {isOrderItem ? 'address' : 'order'} is being cancelled. We will
          send an email when the process is complete.
        </div>
      )}
    </Dialog>
  );
};
