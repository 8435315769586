import { gql } from 'graphql-request';

export const FRAGMENT_RESO_PROPERTY_DETAILS = gql`
  fragment ResoPropertyDetails on ListingPropertyDetailsReso {
    appliances
    architecturalStyle
    constructionMaterials
    exteriorFeatures
    greenEnergyEfficient
    fireplaceFeatures
    fireplacesTotal
    fireplaceYN
    foundationDetails
    interiorFeatures
    propertyAttachedYN
    yearBuiltEffective
    heating
    cooling
    coolingYN
    heatingYN
  }
`;

export const FRAGMENT_RESO_LISTING_DETAILS = gql`
  fragment ResoListingDetails on ListingListingDetailsReso {
    listingTerms
  }
`;
