import React, { MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';

import { OptionalEngagementProps } from '@hcs/types';

import styles from './CircleButton.module.css';

export const FLOATING_CIRCLE_BUTTON_ICON_SIZE = {
  xs: 14,
  sm: 16,
  md: 18,
  lg: 24,
  xl: 60,
};
export interface FloatingCircleButtonProps extends OptionalEngagementProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  ariaLabel?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
  label?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  buttonStyle?:
    | 'primary'
    | 'secondary'
    | 'secondaryNeutral'
    | 'tertiary'
    | 'dark'
    | 'highlightYellow'
    | 'highlightGreen';
  shadow?: boolean;
  /**
   * Sets the Button `title`.
   */
  title?: React.ButtonHTMLAttributes<HTMLButtonElement>['title'];
}
export const CircleButton = ({
  ariaLabel,
  dataHcName,
  dataHcEventSection,
  dataHcEventName,
  dataHcEventType,
  className,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled,
  label,
  size = 'md',
  buttonStyle = 'secondary',
  shadow = true,
  title,
}: FloatingCircleButtonProps) => {
  return (
    <button
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      data-hc-event-name={dataHcEventName}
      data-hc-event-type={dataHcEventType}
      aria-label={ariaLabel}
      className={classNames(styles.CircleButton, className, styles[size], {
        [styles.primary]: buttonStyle === 'primary',
        [styles.secondary]: buttonStyle === 'secondary',
        [styles.secondaryNeutral]: buttonStyle === 'secondaryNeutral',
        [styles.tertiary]: buttonStyle === 'tertiary',
        [styles.dark]: buttonStyle === 'dark',
        [styles.highlightYellow]: buttonStyle === 'highlightYellow',
        [styles.highlightGreen]: buttonStyle === 'highlightGreen',
        [styles.shadow]: shadow,
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? undefined : onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      title={title}
    >
      {children}
      {label && (
        <div
          data-hc-name={`${dataHcName}-label`}
          className={styles.Label}
          data-hc-event-ignore
        >
          {label}
        </div>
      )}
    </button>
  );
};
