import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './FormContainer.module.css';

interface FormContainerProps {
  dataHcName: string;
  children: ReactNode;
  className?: string;
}

export const FormContainer = ({
  dataHcName,
  children,
  className,
}: FormContainerProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.FormContainer, className)}
    >
      {children}
    </div>
  );
};
