import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Skeleton } from '../../../global/loading-errors-null/Skeleton';

import styles from './ThumbnailImage.module.css';

// interface Theme {
//   ThumbnailImage: string;
// }
export interface ThumbnailImageProps {
  /** The url of the image to display */
  url?: string | null;
  /** Classname to apply to the root element */
  className?: string;
  dataHcName?: string;
  isLoading?: boolean;
  children?: ReactNode;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  onClick?: VoidFunction;
}

export const ThumbnailImage = ({
  url,
  className,
  dataHcName,
  isLoading,
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
}: ThumbnailImageProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ThumbnailImage, className, {
        [styles.clickable]: !!onClick,
      })}
      style={
        url && !isLoading ? { backgroundImage: `url('${url}')` } : undefined
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {isLoading && <Skeleton dataHcName={`${dataHcName}-skeleton`} />}
      {children}
    </div>
  );
};
