import React from 'react';

import { SkeletonLoader } from '@hcs/design-system';
import { ReportFeatures, ReportId } from '@hcs/types';
import { SubjectSchema } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';

import { useSubjectDocument } from '../../hooks';
import { SubjectListingStatusBadge } from '../SubjectListingStatusBadge';

import { SubjectMainPropertyDetailsText } from './SubjectMainPropertyDetailsText';

import styles from './SubjectMainDetails.module.css';

interface Props {
  reportId: ReportId;
  isRental?: boolean;
}
export const SUBJECT_MAIN_DETAILS_FEATURES = [
  ReportFeatures.SubjectPropertyDetails,
];
const dataHcName = 'subject-main-details';
export const SubjectMainDetails = ({ reportId, isRental }: Props) => {
  const { isInitialLoading, data: subjectDocument } =
    useSubjectDocument(reportId);
  const subjectSchema: SubjectSchema | undefined = subjectDocument?.data;
  return (
    <div>
      <div className={styles.Address} data-hc-name={`${dataHcName}-address`}>
        <SkeletonLoader
          dataHcName={`${dataHcName}-address-skeleton`}
          isLoading={isInitialLoading}
          type="text"
        >
          {formatFullAddress(subjectSchema?.propertyState.location)}{' '}
          <SubjectListingStatusBadge reportId={reportId} isRental={isRental} />
        </SkeletonLoader>
      </div>
      <SubjectMainPropertyDetailsText
        reportId={reportId}
        className={styles.Details}
      />
    </div>
  );
};
