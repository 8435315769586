import {
  DocumentRoles,
  ReportId,
  SimilarPropertiesChartDocument,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useSimilarPropertiesChartDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<SimilarPropertiesChartDocument>(
    reportId,
    DocumentRoles.SimilarPropertiesChart
  );
};
