import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { Point } from 'mapbox-gl';

import { MapCluster } from '@hcs/design-system';
import { MultiUnitMarker } from '@hcs/maps';
import { PropertyMarker } from '@hcs/maps';
import { PropertyMarkerProps } from '@hcs/maps';
import { useHcMap } from '@hcs/maps';
import { getCenterPointOfTile } from '@hcs/maps';
import { GeoLocation } from '@hcs/types';
import { PropertySpatialHit } from '@hcs/types';
import { TileCoords } from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';
import { logException } from '@hcs/utils';

import { PropertySpatialSearchMapProps } from '../../features/PropertySpatialSearchMap/PropertySpatialSearchMap';
import { usePropertySpatialSearchMap } from '../../hooks/usePropertySpatialSearchMap';
import { getPropertyListPriceLabel } from '../../utils';

interface PropertySpatialSearchMapTileProps
  extends Pick<PropertySpatialSearchMapProps, 'spatialSearchVariables'> {
  tile: TileCoords;
  mapId: string;
  isRental?: boolean;
  dataHcName: string;
  onClickPropertyMarker: (hit: PropertySpatialHit) => void;
}
const MARKER_POINT = new Point(-28 / 2, -28);

const getPropertyGeoLocation = (
  propertyHit: PropertySpatialHit
): GeoLocation | undefined => {
  return locationToGeoLocation(propertyHit.location);
};

export const PropertySpatialSearchMapTile = ({
  mapId,
  dataHcName,
  tile,
  isRental,
  onClickPropertyMarker,
  spatialSearchVariables,
}: PropertySpatialSearchMapTileProps) => {
  const {
    mapState,
    actions: { hcMapViewportChange },
  } = useHcMap(mapId);
  const tileVariables = useMemo(() => {
    return {
      ...spatialSearchVariables,
      id: {
        ...(spatialSearchVariables.id || {}),
        tile,
      },
    };
  }, [tile, spatialSearchVariables]);
  const { data } = usePropertySpatialSearchMap(tileVariables);
  const markers = useMemo(() => {
    if (!data || mapState?.markers.showMarkers === false) {
      return [];
    }

    const dataTypeName = data.__typename;
    if (
      dataTypeName === 'SpatialSearchCountResult' ||
      dataTypeName === 'SpatialSearchOverCountResult'
    ) {
      const labelLocation = data.labelLocation;
      let count = null;
      if (data.__typename === 'SpatialSearchCountResult') {
        count = data.count;
      } else if (data.__typename === 'SpatialSearchOverCountResult') {
        count = data.moreThan;
      }
      const updatedMarkers = [];
      const { latitude, longitude } = labelLocation || {};
      if (latitude && longitude && count) {
        updatedMarkers.push(
          <Marker
            longitude={longitude}
            latitude={latitude}
            offset={MARKER_POINT}
            key={`cluster-${latitude}-${longitude}`}
          >
            <MapCluster
              onClick={() => {
                hcMapViewportChange({
                  mapId,
                  viewport: { latitude, longitude, zoom: tile.zoom + 1 },
                });
              }}
              dataHcName={`${dataHcName}-cluster`}
              pointCount={count}
            />
          </Marker>
        );
      } else if (count) {
        // sometimes graphQL gives up on trying to find a cluster center point
        // so we supplement by finding the center of the tile and putting the cluster there (as long as there's still a count to display)
        const tileCenter = getCenterPointOfTile(tile);
        updatedMarkers.push(
          <Marker
            longitude={tileCenter.lng}
            latitude={tileCenter.lat}
            offset={MARKER_POINT}
            key={`cluster-${latitude}-${longitude}`}
          >
            <MapCluster
              onClick={() => {
                hcMapViewportChange({
                  mapId,
                  viewport: {
                    latitude: tileCenter.lat,
                    longitude: tileCenter.lng,
                    zoom: tile.zoom + 1,
                  },
                });
              }}
              dataHcName={`${dataHcName}-cluster`}
              pointCount={count}
            />
          </Marker>
        );
      }
      return updatedMarkers;
    } else if (dataTypeName === 'SpatialSearchDetailResults') {
      // Create a map of unique street addresses as the key and the values
      // being an array of properties at that address
      const uniqueStreetAddressHits = new Map<string, PropertySpatialHit[]>();

      // Populate map
      data.hits?.forEach((hit) => {
        const streetAddress = hit.location?.address;
        if (!streetAddress) return;

        const uniqueHit = uniqueStreetAddressHits.get(streetAddress);

        // If street address already in map, add property to array
        if (uniqueHit) {
          uniqueStreetAddressHits.set(streetAddress, [...uniqueHit, hit]);
        }
        // Street Address not in map, initialize array with property
        else {
          uniqueStreetAddressHits.set(streetAddress, [hit]);
        }
      });

      return Array.from(uniqueStreetAddressHits).map(
        ([streetAddress, properties]) => {
          if (properties.length === 1 && properties[0]) {
            const property = properties[0];

            const geoLocation = locationToGeoLocation(property.location);
            const hcAddressId = property.hcAddressId;
            if (!geoLocation || !hcAddressId) {
              return null;
            }
            return (
              <PropertyMarker
                key={hcAddressId}
                markerId={hcAddressId}
                geoLocation={geoLocation}
                // pulse={highlightMatch}
                onClick={() => {
                  onClickPropertyMarker(property);
                }}
                dataHcName={`${dataHcName}-marker`}
                label={getPropertyListPriceLabel(property, isRental)}
              />
            );
          }
          const propertyMarkers: PropertyMarkerProps[] = [];
          properties.forEach((property) => {
            const geoLocation = getPropertyGeoLocation(property);
            const hcAddressId = property.hcAddressId;
            if (geoLocation && hcAddressId) {
              propertyMarkers.push({
                label: getPropertyListPriceLabel(property, isRental),
                markerId: hcAddressId,
                geoLocation: geoLocation,
                onClick: () => {
                  onClickPropertyMarker(property);
                },
              });
            }
          });
          const multiUnitGeoLocation = propertyMarkers[0]?.geoLocation;
          if (!multiUnitGeoLocation) {
            return null;
          }

          return (
            <MultiUnitMarker
              key={streetAddress}
              geoLocation={multiUnitGeoLocation}
              propertyMarkers={propertyMarkers}
              dataHcName={`${dataHcName}-multi-property-marker`}
            />
          );
        }
      );
    } else {
      logException(
        new Error(
          `MapTile: encountered property spatial query result with unexpected __typename: ${dataTypeName}`
        )
      );
      return [];
    }
  }, [
    dataHcName,
    mapState?.markers.showMarkers,
    data,
    mapId,
    hcMapViewportChange,
    onClickPropertyMarker,
    tile,
  ]);
  return markers;
};
