import React from 'react';

import { Dialog } from '@hcs/design-system';
import { CompIdentifier, ReportId } from '@hcs/types';

import { CompDetailsHeader } from '../CompDetailsHeader';

import {
  COMP_TRANSACTION_HISTORY_FEATURES_RENTAL,
  COMP_TRANSACTION_HISTORY_FEATURES_SALE,
  CompTransactionHistory,
} from './CompTransactionHistory';

interface Props {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  active: boolean;
  onClose: VoidFunction;
  className?: string;
}

const dataHcName = 'comp-transaction-history-dialog';
export const COMP_TRANSACTION_HISTORY_DIALOG_FEATURES_SALE =
  COMP_TRANSACTION_HISTORY_FEATURES_SALE;
export const COMP_TRANSACTION_HISTORY_DIALOG_FEATURES_RENTAL =
  COMP_TRANSACTION_HISTORY_FEATURES_RENTAL;
export const CompTransactionHistoryDialog = ({
  reportId,
  compIdentifier,
  className,
  active,
  onClose,
}: Props) => {
  return (
    <Dialog
      dataHcName={dataHcName}
      theme={{
        Dialog: className,
      }}
      active={active}
      onClose={onClose}
      type="large"
      title={
        <CompDetailsHeader
          reportId={reportId}
          compIdentifier={compIdentifier}
        />
      }
    >
      <CompTransactionHistory
        reportId={reportId}
        compIdentifier={compIdentifier}
      />
    </Dialog>
  );
};
