import {
  AccroleapiInputKeyRequest as AccroleapiInputKeyRequestExt,
  AccroleapiRoleResp as AccroleapiRoleRespExt,
  AccroleapiRolesResp as AccroleapiRolesRespExt,
} from './generated/AccountApi.types';
import {
  AccroleapiInputKeyRequest as AccroleapiInputKeyRequestInt,
  AccroleapiRoleResp as AccroleapiRoleRespInt,
  AccroleapiRolesResp as AccroleapiRolesRespInt,
} from './generated/ManagerApi.types';

export type {
  AccroleapiPermissionsResp as AccroleapiPermissionsRespInt,
  AccroleapiUserRolesResp as AccroleapiUserRolesRespInt,
  AccroleapiInputKeyRequest as AccroleapiInputKeyRequestInt,
} from './generated/ManagerApi.types';

export enum AccRoleAppAccessKeys {
  AcquisitionExplorer = 'access-aexp',
  AgileSuite = 'access-agile-suite',
  BulkPropertyData = 'access-bpd',
  CanaryAI = 'access-canaryai',
  DataExplorer = 'access-dexp',
  MarketInsights = 'access-market-insights',
  MarketPulse = 'access-market-pulse',
  Portfolio = 'access-portfolio',
  PropertyExplorer = 'access-pexp',
}

export enum AccRoleExternalRoleKeys {
  OrgAdmin = 'org-admin',
  BrokerPartner = 'partner-broker',
  OmCreateOrder = 'om-create-order',
  OmViewOrders = 'om-view-orders',
}

export enum RoleType {
  ProductAccess = 'product-access',
}

export type Role = Omit<
  AccroleapiRoleRespInt | AccroleapiRoleRespExt,
  'type' | 'applications' | 'key'
> & {
  key: AccRoleAppAccessKeys | AccRoleExternalRoleKeys;
  type: RoleType;
  applications: string[];
};

export type RolesFilters = Partial<{
  type: RoleType | null;
  search: string | null;
}>;

export type AccroleapiRolesResp = Omit<
  AccroleapiRolesRespExt | AccroleapiRolesRespInt,
  'roles'
> & {
  roles: Role[];
};

export type AccroleapiInputKeyRequest =
  | AccroleapiInputKeyRequestExt
  | AccroleapiInputKeyRequestInt;
