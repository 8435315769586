import React from 'react';

import { AppConfigExtended } from '@hcs/types';

import { AppLauncherOnClickAppCallback } from './AppLauncher.types';

import styles from './AppLauncher.module.css';

interface Props {
  appConfigExtended: AppConfigExtended;
  onClick: AppLauncherOnClickAppCallback;
}

export const AppLauncherButton = ({ appConfigExtended, onClick }: Props) => {
  const { appConfig, hasAccess } = appConfigExtended;
  const { appSlug, Icon, name, descriptionShort } = appConfig;
  const dataHcName = `app-launcher-button-${appSlug}`;
  return (
    <li
      onClick={() => onClick(appConfigExtended)}
      data-hc-name={dataHcName}
      className={styles.AppLauncherButton}
    >
      {Icon && (
        <div className={styles.IconCell}>
          <Icon dataHcName={`${dataHcName}-icon`} height="24px" width="24px" />
        </div>
      )}
      <div className={styles.InfoCell}>
        <div className={styles.AppName} data-hc-name={`${dataHcName}-name`}>
          {name}
        </div>
        <div
          className={styles.ByLine}
          data-hc-name={`${dataHcName}-description`}
        >
          {descriptionShort}
        </div>
        {hasAccess ? (
          <div className={styles.Launch} data-hc-name={`${dataHcName}-launch`}>
            Launch
          </div>
        ) : (
          <div
            className={styles.Launch}
            data-hc-name={`${dataHcName}-get-access`}
          >
            Get Access
          </div>
        )}
      </div>
    </li>
  );
};
