import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAccount } from '@hcs/auth';
import {
  DocumentBase,
  DocumentRoles,
  ReportApiDocument,
  ReportApiDocumentQueryParamsDocumentRole,
  ReportEventData,
  ReportEventNoData,
  ReportEventTypes,
  ReportId,
  ReportStatus,
} from '@hcs/types';
import {
  CompFarmUserPropertyAdjustments,
  UserPropertyAdjustments,
} from '@hcs/types';
import { jsonPatchApplyEnhanced } from '@hcs/utils';
import { isCompDocument } from '@hcs/utils';

import { ReportApi } from '../api';
import { QUERY_KEY_COMPS_FARM_ADJUSTMENTS } from '../hooks/useCompsFarmAdjustments';
import { usePendingOptimisticUpdates } from '../hooks/usePendingOptimisticUpdates';
import { QUERY_KEY_TAGS } from '../hooks/useTags';
import { makeOptimisticDocumentId } from '../utils';
import { processUserAdjustmentChanges } from '../utils/userAdjustments.utils';

import { useReport } from './useReport';
import { useReportConfig } from './useReportConfig';
import { useSubscribeToReportEvents } from './useSubscribeToReportEvents';
import { OptimisticUpdatesState, QUERY_KEY_OPT_UPDATES } from '.';

// Do not use this directly in features. Use the hook that is meant
// to get the specific role you are attempting to request
export const QUERY_KEY_DOCUMENT_ROLE = 'report-api-document-role';

const buildLookupTable = <T extends DocumentBase>(documents: T[]) => {
  const lookup: Record<DocumentRoles, Map<string, T>> = <
    Record<DocumentRoles, Map<string, T>>
  >{};
  documents.forEach((document) => {
    if (!lookup[document.role]) {
      lookup[document.role] = new Map();
    }
    lookup[document.role]?.set(document.documentId, document);
  });
  return lookup;
};

const getLookupBaseDocumentReportData = (
  report: ReportEventNoData | ReportEventData
) => {
  return buildLookupTable<DocumentBase>(report.documents);
};

const getLookupReportApiDocumentReportData = (report: ReportEventData) => {
  return buildLookupTable<ReportApiDocument>(report.documents);
};

export const useDocumentRole = <DocType extends ReportApiDocument>(
  reportId: ReportId,
  documentRole: DocumentRoles,
  params?: ReportApiDocumentQueryParamsDocumentRole
) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const documentRoleSupported = !!reportConfig?.documentRoles[documentRole];
  const reportQuery = useReport(reportId);
  const queryKey = [QUERY_KEY_DOCUMENT_ROLE, reportId, documentRole];
  const queryClient = useQueryClient();
  const accountQuery = useAccount();
  const { removePendingOperations } = usePendingOptimisticUpdates();

  useSubscribeToReportEvents({
    callbackId: QUERY_KEY_DOCUMENT_ROLE,
    // Handler to update the query cache for this hook when we receive relevant SSEs
    onMessage: (reportEvent) => {
      if (documentRoleSupported) {
        // First adjustmentsLookup
        const adjustmentsLookup: { [key: string]: UserPropertyAdjustments } =
          {};
        if (
          reportEvent.type === ReportEventTypes.DocumentsUpdated ||
          reportEvent.type === ReportEventTypes.DocumentsCreated
        ) {
          const { userPropertyAdjustmentChanges } = reportEvent.report;
          userPropertyAdjustmentChanges?.forEach((adjustmentChange) => {
            adjustmentsLookup[adjustmentChange.documentID] = adjustmentChange;
          });
        }
        if (reportEvent.type === ReportEventTypes.DocumentsRefreshed) {
          // All report data should be refetched after a refresh action is complete.
          if (reportEvent.patchStatus === 'DONE') {
            queryClient.refetchQueries([QUERY_KEY_DOCUMENT_ROLE, reportId]);
          }
        } else if (reportEvent.type === ReportEventTypes.DocumentsUpdated) {
          reportEvent.report.documentEdits.forEach(
            ({ documentRole, documentId, patchData, userId }) => {
              const documentRoleQueryKey = [
                QUERY_KEY_DOCUMENT_ROLE,
                reportId,
                documentRole,
              ];

              if (accountQuery?.data?.user?.id === userId) {
                removePendingOperations({
                  reportId: reportEvent.report.id,
                  documentId,
                  operations: patchData,
                });
              }
              const pendingUpdates =
                queryClient.getQueryData<OptimisticUpdatesState>([
                  QUERY_KEY_OPT_UPDATES,
                ])?.[reportId]?.[documentId];
              const cacheForRole =
                queryClient.getQueryData<ReportApiDocument[]>(
                  documentRoleQueryKey
                );
              const updatedCache: ReportApiDocument[] = [];
              if (cacheForRole) {
                cacheForRole.forEach((cacheDoc) => {
                  if (cacheDoc.documentId === documentId) {
                    const filteredPatchData = patchData.filter((operation) => {
                      return (
                        !pendingUpdates?.[operation.path] ||
                        pendingUpdates?.[operation.path] === 0
                      );
                    });

                    const updatedDoc = filteredPatchData
                      ? jsonPatchApplyEnhanced(cacheDoc, filteredPatchData)
                          .newDocument
                      : cacheDoc;
                    const userAdjustmentChangesForDoc =
                      adjustmentsLookup[updatedDoc.documentId];
                    if (
                      (updatedDoc.role === DocumentRoles.Subject ||
                        updatedDoc.role === DocumentRoles.Comp ||
                        updatedDoc.role === DocumentRoles.RentalComp) &&
                      userAdjustmentChangesForDoc
                    ) {
                      updatedDoc.userPropertyAdjustments = {
                        ...userAdjustmentChangesForDoc,
                        adjustments: processUserAdjustmentChanges({
                          ...updatedDoc.userPropertyAdjustments?.adjustments,
                          ...userAdjustmentChangesForDoc.adjustments,
                        }),
                      };
                    }
                    updatedCache.push(updatedDoc);
                  } else {
                    updatedCache.push(cacheDoc);
                  }
                });
                queryClient.setQueryData<ReportApiDocument[]>(
                  documentRoleQueryKey,
                  updatedCache
                );
              }
            }
          );
        } else if (reportEvent.type === ReportEventTypes.DocumentsDeleted) {
          const lookup = getLookupBaseDocumentReportData(reportEvent.report);
          Object.keys(lookup).forEach((roleStr) => {
            const documentRole = roleStr as DocumentRoles;
            const roleQueryKey = [
              QUERY_KEY_DOCUMENT_ROLE,
              reportId,
              documentRole,
            ];
            const cacheForRole =
              queryClient.getQueryData<ReportApiDocument[]>(roleQueryKey);
            const documentMap = lookup[documentRole];

            if (cacheForRole) {
              queryClient.setQueryData<ReportApiDocument[]>(
                roleQueryKey,
                cacheForRole.filter(
                  (document) => !documentMap?.get(document.documentId)
                )
              );
            }
          }); // We have full documents when documents are created
        } else if (reportEvent.type === ReportEventTypes.DocumentsCreated) {
          const lookup = getLookupReportApiDocumentReportData(
            reportEvent.report
          );

          Object.keys(lookup).forEach((roleStr) => {
            const documentRole = roleStr as DocumentRoles;
            const roleQueryKey = [
              QUERY_KEY_DOCUMENT_ROLE,
              reportId,
              documentRole,
            ];
            const cacheForRole =
              queryClient.getQueryData<ReportApiDocument[]>(roleQueryKey);
            const documentMap = lookup[documentRole];

            const updatedCache: ReportApiDocument[] = [];
            if (cacheForRole) {
              cacheForRole.forEach((cacheDoc) => {
                // If the newly created document is replacing an optimistic comp document in the cache,
                // lookup the updatedDoc differently
                const isOptmisticCacheDoc =
                  (cacheDoc.role === DocumentRoles.Comp ||
                    cacheDoc.role === DocumentRoles.RentalComp) &&
                  cacheDoc.documentId ===
                    makeOptimisticDocumentId(cacheDoc.data);
                const updatedDoc = isOptmisticCacheDoc
                  ? reportEvent.report.documents.find((d) => {
                      return (
                        d.role === cacheDoc.role &&
                        makeOptimisticDocumentId(d.data) === cacheDoc.documentId
                      );
                    })
                  : documentMap.get(cacheDoc.documentId);
                if (updatedDoc) {
                  const userAdjustmentChangesForDoc =
                    adjustmentsLookup[updatedDoc.documentId];
                  if (
                    (updatedDoc.role === DocumentRoles.Subject ||
                      updatedDoc.role === DocumentRoles.Comp ||
                      updatedDoc.role === DocumentRoles.RentalComp) &&
                    userAdjustmentChangesForDoc
                  ) {
                    updatedDoc.userPropertyAdjustments = {
                      ...userAdjustmentChangesForDoc,
                      adjustments: processUserAdjustmentChanges({
                        ...updatedDoc.userPropertyAdjustments?.adjustments,
                        ...userAdjustmentChangesForDoc.adjustments,
                      }),
                    };
                  }
                  updatedCache.push(updatedDoc);
                  documentMap.delete(updatedDoc.documentId);
                } else {
                  updatedCache.push({ ...cacheDoc });
                }
              });
            }

            if (documentMap.size) {
              documentMap.forEach((document) => {
                updatedCache.push(document);
              });
            }
            queryClient.setQueryData<ReportApiDocument[]>(
              roleQueryKey,
              updatedCache
            );
          });
        }
      }
    },
  });

  const options = {
    enabled: documentRoleSupported,
    onSuccess: (data: DocType[]) => {
      data.forEach((doc) => {
        if (
          doc.role === DocumentRoles.Comp ||
          doc.role === DocumentRoles.RentalComp
        ) {
          // Update farm user property adjustments
          const queryKeyAdjustments = [
            QUERY_KEY_COMPS_FARM_ADJUSTMENTS,
            reportId,
            doc.role,
          ];
          const farmAdjustmentsCache =
            queryClient.getQueryData<CompFarmUserPropertyAdjustments>(
              queryKeyAdjustments
            );
          queryClient.setQueryData<CompFarmUserPropertyAdjustments>(
            queryKeyAdjustments,
            {
              ...farmAdjustmentsCache,
              [doc.data.compID]: doc.userPropertyAdjustments,
            }
          );
        }
        if (isCompDocument(doc)) {
          // if we already have some tags use them else populate from comp document
          queryClient.setQueryData<string[]>(
            [QUERY_KEY_TAGS, reportId, doc.documentId],
            (old?: string[]) =>
              old && old.length > 0 ? [...old] : [...(doc.tagsForAddress || [])]
          );
        }
      });
    },
  };
  const documentRoleQuery = useQuery(
    queryKey,
    async () => {
      if (
        (documentRole === DocumentRoles.CompsFarm ||
          documentRole === DocumentRoles.RentalCompsFarm) &&
        params?.includeUserPropertyAdjustments
      ) {
        console.warn(
          'Do not use ?includeUserPropertyAdjustments to request comp farm user adjustments. Request them using the separate endpoint. The useCompsFarmDocument hook will automatically request and transform the adjustments.'
        );
      }
      return await ReportApi.fetchDocumentRole<DocType>(
        reportId,
        documentRole,
        params
      );
    },
    options
  );

  const fakeDocumentRoleSuccessQuery = useQuery(
    [...queryKey, 'fakeSuccess'],
    () => {
      const emptyDocuments: DocType[] = [];
      return emptyDocuments;
    }
  );

  const fakeDocumentRoleLoadingQuery = useQuery(
    [...queryKey, 'fakeLoading'],
    () => {
      return new Promise<DocType[]>(() => {
        // should never resolve
      });
    }
  );

  // Treat the query as successful with empty array data if the document is not supported by the reportConfig
  if (!documentRoleSupported) {
    return fakeDocumentRoleSuccessQuery;
  }
  // This block will overwrite the UseQueryResult status fields if the report is not finished being created
  // Some documents take a relatively long time to create and this behavior prevents us showing null states
  // when the data is still being created and will be populated via SSE
  else if (
    // First see if the report is in progress
    reportQuery.data?.status === ReportStatus.InProgress &&
    // Then ensure the documentRole request is complete...
    documentRoleQuery.isFetched &&
    // ...and it contains data
    !documentRoleQuery.data?.length &&
    // The document role must be one that is generated at report creation time
    // and not and created via a report action
    ![
      DocumentRoles.Comp,
      DocumentRoles.RentalComp,
      DocumentRoles.CompPhotos,
      DocumentRoles.CompTransactionHistory,
    ].includes(documentRole)
  ) {
    // Mimic a loading state
    return fakeDocumentRoleLoadingQuery;
  }

  return documentRoleQuery;
};
