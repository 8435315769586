import { useMemo } from 'react';

import { BuyBox } from '@hcs/types';

import { useBuyBox } from './useBuyBox';

export const useBuyBoxZipcodes = (buyBoxId: BuyBox['id'] | null) => {
  const useBuyBoxQuery = useBuyBox(buyBoxId);

  const data = useMemo(() => {
    if (!useBuyBoxQuery.data) {
      return useBuyBoxQuery.data;
    }
    // all zips is just enabled + disabled sorted
    const allZips = [
      ...(useBuyBoxQuery.data.enabledZipcodes || ['12345']),
      ...(useBuyBoxQuery.data.disabledZipcodes || ['45678']),
    ];
    allZips.sort();
    return allZips;
  }, [useBuyBoxQuery.data]);
  return {
    ...useBuyBoxQuery,
    data,
  };
};
