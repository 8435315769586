import React from 'react';

import { TextButton } from '@hcs/design-system';
import { RemoveIcon } from '@hcs/design-system';

import { GenerateAnalysisComplete } from '../GenerateAnalysisComplete';

import styles from './GenerateAnalysisError.module.css';

interface Props {
  inputFile: File | null;
  error: Error | null;
  onTryAgain: () => void;
}

const dataHcName = 'analysis-create-error';
export const GenerateAnalysisError = ({
  inputFile,
  error,
  onTryAgain,
}: Props) => {
  const errors = error?.message ? error.message.split('\n') : ['Server error'];

  return (
    <GenerateAnalysisComplete
      dataHcName={dataHcName}
      Icon={() => <RemoveIcon color="error-10" />}
      title="Analysis failed"
      inputFile={inputFile}
      message={
        <div className={styles.Errors} data-hc-name={`${dataHcName}-errors`}>
          <b>Error</b>
          {errors.map((err) => (
            <div key={err}>{err}</div>
          ))}
        </div>
      }
      theme={{
        Title: styles.Title,
      }}
    >
      <TextButton
        onClick={onTryAgain}
        dataHcName={`${dataHcName}-download-button`}
      >
        Try again
      </TextButton>
    </GenerateAnalysisComplete>
  );
};
