import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { MarkerPulse } from './MarkerPulse';

import styles from './MapMarkers.module.css';

export interface MapMarkerTheme {
  Position?: string;
  Marker?: string;
  Content?: string;
  circle?: string;
  pin?: string;
}
export interface MapMarkerProps {
  dataHcName: string;
  className?: string;
  children?: ReactNode;
  url?: string | null | undefined;
  hoverable?: boolean;
  onClick?: VoidFunction;
  isPhoto?: boolean;
  addElementToRefList?: (node: HTMLElement) => void;
  pulse?: boolean;
  theme?: MapMarkerTheme;
  shape?: 'pin' | 'circle';
}
export const MapMarker = ({
  className,
  dataHcName,
  children,
  isPhoto,
  hoverable,
  addElementToRefList,
  url,
  pulse,
  onClick,
  theme,
  shape = 'pin',
}: MapMarkerProps) => {
  return (
    <div
      ref={(el) => {
        if (addElementToRefList && el) {
          addElementToRefList(el);
        }
      }}
      className={classNames(styles.Position, theme?.Position)}
    >
      <div
        data-hc-name={dataHcName}
        className={classNames(styles.MapMarker, className, theme?.Marker, {
          [styles.markerPulse]: pulse,
          [styles.clickable]: !!onClick,
          [styles.photo]: !!url || isPhoto,
        })}
        onClick={onClick}
        style={url ? { backgroundImage: `url(${url})` } : undefined}
      >
        {children}
      </div>
      {pulse && <MarkerPulse dataHcName={`${dataHcName}-pulse`} />}
    </div>
  );
};
