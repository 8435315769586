import { useMemo } from 'react';

import { useAccount } from '@hcs/auth';
import { AppSlugs, CapabilityApplications, Roles } from '@hcs/types';

import { useCapabilitiesForUser } from './useCapabilitiesForUser';

export const useAppAccessCheckForUserMap = (userId: number | undefined) => {
  const query = useCapabilitiesForUser(userId);
  const data = useMemo(() => {
    return query.data?.reduce<Record<CapabilityApplications, boolean>>(
      (accum, capability) => {
        accum[capability.application] = true;
        return accum;
      },
      {} as Record<CapabilityApplications, boolean>
    );
  }, [query.data]);
  return {
    ...query,
    data,
  };
};

export const useAppAccessCheckForUser = (
  userId: number | undefined,
  applicationKey: CapabilityApplications
) => {
  const query = useAppAccessCheckForUserMap(userId);
  const data = query.data?.[applicationKey];
  return {
    ...query,
    data,
  };
};

const LEGACY_SLUGS_TO_CAP_APPS: Partial<
  Record<AppSlugs, CapabilityApplications>
> = {
  [AppSlugs.AcquisitionExplorer]: CapabilityApplications.AcquisitionExplorer,
  [AppSlugs.AgileSuite]: CapabilityApplications.AgileSuite,
  [AppSlugs.CanaryAI]: CapabilityApplications.CanaryAI,
  [AppSlugs.DataExplorer]: CapabilityApplications.DataExplorer,
  [AppSlugs.MarketInsights]: CapabilityApplications.MarketInsights,
  [AppSlugs.PortfolioMonitor]: CapabilityApplications.PortfolioMonitor,
  [AppSlugs.PropertyExplorer]: CapabilityApplications.PropertyExplorer,
};
export const useAppAccessCheck = (applicationKey: CapabilityApplications) => {
  const query = useAppAccessCheckMap();
  return {
    ...query,
    data: query.data?.[applicationKey],
  };
};

export const useAppAccessCheckMap = () => {
  const accountQuery = useAccount();
  const legacyRoles = accountQuery.data?.user.roles;
  const legacyApplications = accountQuery.data?.user.userApplications;
  const query = useAppAccessCheckForUserMap(accountQuery.data?.user.id);
  // Handle legacy app access for now
  const data = useMemo(() => {
    const data = { ...query.data };
    legacyApplications?.forEach((app) => {
      const applicationKey = LEGACY_SLUGS_TO_CAP_APPS[app.application.name];
      if (applicationKey) {
        data[applicationKey] = true;
      }
    });
    if (!data[CapabilityApplications.OrgSettings]) {
      data[CapabilityApplications.OrgSettings] = !!legacyRoles?.find((role) => {
        return role.name === Roles.OrgAdmin;
      });
    }
    return query.data;
  }, [query.data, legacyApplications, legacyRoles]);
  return {
    ...query,
    data,
  };
};
