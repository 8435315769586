import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  ZipDetailsDocument,
  ZipDetailsQuery,
  ZipDetailsQueryVariables,
} from '@hcs/types';

import { zipDetailsQueryToCerberusStatsZip } from '../utils/cerberus-stats.utils';

const QUERY_KEY_CERBERUS_STATS_ZIP = 'cerberus-stats-zip';

export const useCerberusStatsZip = ({ zipcode }: ZipDetailsQueryVariables) => {
  return useQuery([QUERY_KEY_CERBERUS_STATS_ZIP, zipcode], async () => {
    const response = await CerberusApi.fetchQuery<
      ZipDetailsQuery,
      ZipDetailsQueryVariables
    >(ZipDetailsDocument, {
      zipcode,
    });
    return zipDetailsQueryToCerberusStatsZip(response);
  });
};
