import { ReportId } from '@hcs/types';

import { useReport } from './useReport';

export const useReportPermissions = (reportId: ReportId) => {
  const reportQuery = useReport(reportId);
  const isActive = reportQuery.data?.metadata.activeStatus === 'active';
  return {
    ...reportQuery,
    data: {
      isEditable: (isActive && reportQuery.data?.metadata.editable) || false,
      isExpired: !isActive,
      isActive,
    },
  };
};
