import { ENV, PULL_REQUEST_SUBDOMAIN } from '@hcs/webapps';

const IS_INTERNAL_HOST = window.location.host.split('.').slice(-1)[0] === 'net';

const buildUrlForEnv = (
  subdomain: string,
  env: string,
  isInternalProdSubdomain?: boolean
) => {
  const tld =
    env === 'prod' && !isInternalProdSubdomain && !IS_INTERNAL_HOST
      ? '.com'
      : '.net';
  const apiEnvSubdomain =
    env === 'prod' && !isInternalProdSubdomain && !IS_INTERNAL_HOST
      ? ''
      : `${env}.hc.`;
  return `https://${subdomain}.${apiEnvSubdomain}housecanary${tld}`;
};

// Builds microservice urls based on env config files.
// Useful for pointing a subset of services to local instances.
export const buildServiceUrl = (
  subdomain: string,
  isInternalProdSubdomain?: boolean
): string => {
  const serviceEnv: string = ENV;
  return buildUrlForEnv(subdomain, serviceEnv, isInternalProdSubdomain);
};

export const buildServiceUrlEhrmMounted = (subdomain: string) => {
  const serviceEnv: string = ENV;
  return `${buildUrlForEnv('api', serviceEnv)}/${subdomain}`;
};

export const convertUrlToPrPreview = (url: string): string => {
  if (PULL_REQUEST_SUBDOMAIN) {
    const splittedArr = url.split('https://');
    return `https://${PULL_REQUEST_SUBDOMAIN}.${splittedArr[1]}`;
  } else {
    return url;
  }
};

export const IS_INTERNAL_PROD = IS_INTERNAL_HOST && ENV === 'prod';
