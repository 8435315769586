import React from 'react';

import { Button } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { ReportSummaryCellContentProps } from '@hcs/types';

import { DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL } from '../../../constants/ReportSummary.constants';
import { ReportSummaryOrderItemsTable } from '../ReportSummaryOrderItemsTable';

import { createReportSummaryTableCellConfig } from './ReportSummaryTableCell';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL}-order-items`;
const CellContentComponent = ({ order }: ReportSummaryCellContentProps) => {
  const { active, handleClose, handleOpen } = useActiveState();
  return (
    <div data-hc-name={dataHcName}>
      <Button dataHcName={`${dataHcName}-button`} onClick={() => handleOpen()}>
        Order Items
      </Button>
      <Dialog
        dataHcName={`${dataHcName}-dialog`}
        onClose={handleClose}
        title={order.name}
        active={active}
      >
        <ReportSummaryOrderItemsTable orderId={order.id} />
      </Dialog>
    </div>
  );
};

export const REPORT_SUMMARY_MORE_CONFIG = createReportSummaryTableCellConfig({
  CellContentComponent,
  tableHeaderName: '',
});
