import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import { OptionalEngagementProps } from '@hcs/types';

import styles from './DrawControlButton.module.css';

interface Props extends OptionalEngagementProps {
  isActive: boolean;
  children?: React.ReactNode;
  onClick: (event?: MouseEvent) => void;
  title: string;
  helpText: string;
  disabled?: boolean;
  type?: string;
  iconActive: ReactNode;
  className?: string;
  icon: ReactNode;
}

const dataHcName = 'draw-control-button';
export const DrawControlButton = ({
  icon,
  iconActive,
  dataHcEventName,
  dataHcEventSection,
  dataHcEventType,
  helpText,
  disabled = false,
  title,
  isActive,
  className,
  children,
  onClick,
}: Props) => {
  return (
    <div
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        if (!disabled) {
          onClick(e);
        }
      }}
      title={helpText}
      data-hc-name={dataHcName}
      data-hc-event-name={dataHcEventName}
      data-hc-event-section={dataHcEventSection}
      data-hc-event-type={dataHcEventType}
      className={classNames(styles.DrawControlButton, className, {
        [styles.isActive as string]: isActive,
        [styles.isDisabled as string]: disabled,
      })}
    >
      <div className={classNames(styles.IconContainer)}>
        {isActive ? (
          <div
            data-hc-name={`${dataHcName}-active`}
            className={styles.IconActive}
          >
            {iconActive}
          </div>
        ) : (
          <div className={styles.Icon} data-hc-name={`${dataHcName}-normal`}>
            {icon}
          </div>
        )}
      </div>
      <label data-hc-name={`${dataHcName}-label`} className={styles.Label}>
        {title}
      </label>
      {children}
    </div>
  );
};
