import { MapPageViewMode } from '@hcs/types';

export const AEXP_LOCAL_STORAGE_MAP_VIEW_MODE = 'aexp-map-view-mode';

export const getLastMapViewMode = () => {
  // SECURITY: localStorage is referenced to access values from global local storage @bhanson
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  return localStorage.getItem(
    AEXP_LOCAL_STORAGE_MAP_VIEW_MODE
  ) as MapPageViewMode | null;
};

export const setLastMapViewMode = (view: MapPageViewMode) => {
  // SECURITY: localStorage is referenced to access values from global local storage @bhanson
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  localStorage.setItem(AEXP_LOCAL_STORAGE_MAP_VIEW_MODE, view);
};
