import {
  CaseReducerActions,
  configureStore,
  MiddlewareArray,
  Slice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  combineReducers,
  Dispatch,
  Middleware,
  ReducersMapObject,
} from 'redux';

export const resetOnLogoutAction = createAction('RESET_ON_LOGOUT');

export const createFactorySelector =
  <I, S>(factorySelector: (i: I) => S) =>
  (i: I) => {
    // This is a util that allows us to utilize reselect to memoize selector factories.
    // Example:
    // You need to write a selector to get data for a specific Comp based on hcAddressId.
    // The selector involves expensive computation so you only want it to be called when
    // an input changes. The factory-pattern (which is the best we've found so far) breaks
    // reselect because it creates a new selector each time the factory is called.
    // This util will cache the created selectors which will allow 'createSelector'
    // to do it's intended job.
    const cache: {
      [key: string]: S;
    } = {};
    // TypeScript requires only strings and numbers for indices.
    // As a work-around, factories with multiple arguments need to be passed as an array
    // and the key for the cache is converted to a string.
    let tsIndex: undefined | string;
    if (typeof i === 'string') {
      tsIndex = i;
    } else if (typeof i === 'number') {
      tsIndex = i.toString();
    } else if (Array.isArray(i)) {
      tsIndex = i.map((j) => j.toString()).join('-');
    } else {
      throw new Error('Index must be string, number, or array');
    }
    // Now that we have a safe key, check the cache and populate it if it's empty
    if (!cache[tsIndex]) {
      cache[tsIndex] = factorySelector(i);
    }
    // Return the cached selector
    const selector = cache[tsIndex];
    if (selector) {
      return selector;
    } else {
      throw new Error('Corrupted Selector Cache');
    }
  };

type DispatchWrap = (...args: unknown[]) => void;
export const mapDispatchToSliceActions = <
  S,
  A extends SliceCaseReducers<S>,
  N extends string
>(
  dispatch: Dispatch,
  slice: Slice<S, A>
) => {
  const results: { [key: string]: DispatchWrap } = {};
  for (const name in slice.actions) {
    const fn = slice.actions[name];
    results[name] = (payload: unknown) => {
      const action = fn?.(payload);
      if (action) {
        dispatch(action);
      }
      return action;
    };
  }
  return results as CaseReducerActions<A, N>;
};

export const createHcReduxStore = (
  slices: Slice[],
  additionalReducers: ReducersMapObject = {}
) => {
  const sliceMapping: ReducersMapObject = { ...additionalReducers };
  slices.forEach((slice) => {
    sliceMapping[slice.name] = slice.reducer;
  });
  const reducers = combineReducers(sliceMapping);
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Options: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
    // actionTransformer: catch actions to prevent logging or removing sensitive info
    // stateTransformer: transform state to remove sending sensitive info before logging
    // configureScopeWithState: configure Sentry scope with Redux state
  });
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware() as unknown as MiddlewareArray<Middleware[]>,
    enhancers: [sentryReduxEnhancer],
  });
};
