import React from 'react';
import isEqual from 'lodash.isequal';

import { FilterRange } from '@hcs/design-system';
import { FilterRangeType, FORMAT_TYPES } from '@hcs/design-system';
import { QuickFilter } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompFiltersAll, CompsFiltersPaths } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';

import { useDocumentPatch } from '../../../hooks';
import { useCompsFiltersDocument } from '../../../hooks/useCompsFiltersDocument';
import { usePatchCompNumberRangeFilter } from '../../../hooks/usePatchCompNumberRangeFilter';
import { useSubjectDocument } from '../../../hooks/useSubjectDocument';
import {
  convertNumArrayToFilterRange,
  formatMoneyAbbrStr,
  formatOrUndefined,
  getFilterLabel,
  subjectDisplay,
} from '../../../utils';

import { CompFilterChip } from './CompFilterChip';

const FIELD = PropertyStateFields.currentListingPriceRental;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = `comp-filter-${FIELD}`;
export const COMP_FILTER_LIST_PRICE_RENTAL: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const active = !!filterValue;
    const label = active
      ? getFilterLabel({
          field: FIELD,
          value: formatOrUndefined(
            formatMoneyAbbrStr,
            convertNumArrayToFilterRange(filterValue?.absoluteValue)
          ),
        })
      : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType }: CompFilterControlTypeProps) => {
    const documentPatchMutation = useDocumentPatch(reportId);
    const { mutate: patchFilter } = usePatchCompNumberRangeFilter(
      reportId,
      compType
    );
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);

    if (!filterDocument || !subjectDocument) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }

    const filterValue = filterDocument.data.filters?.[FIELD];
    const subjectValue = getPropertyStateFieldValue(FIELD, {
      propertyStateType: PropertyStateType.Core,
      propertyState: subjectDocument.data.propertyState,
    });

    const handleChangeRelativeValue = (
      relativeValue: CompFiltersAll[PropertyStateFields.currentListingPriceRental]['relativeValue']
    ) => {
      documentPatchMutation.mutate({
        reportId,
        document: filterDocument,
        operations: [
          {
            op: 'add',
            path: PATH_REL,
            value: relativeValue,
          },
          {
            op: 'add',
            path: PATH_FIELD,
            value: FIELD,
          },
        ],
      });
    };

    return (
      <>
        {subjectValue && (
          <QuickFilter
            dataHcName={`${dataHcName}-quick-filter`}
            buttons={[
              {
                label: '+/- 5%',
                active: isEqual([-0.05, 0.05], filterValue?.relativeValue),
                dataHcName: `${dataHcName}-quick-filter-btn-0`,
                onClick: () => handleChangeRelativeValue([-0.05, 0.05]),
              },
              {
                label: '+/- 10%',
                active: isEqual([-0.1, 0.1], filterValue?.relativeValue),
                dataHcName: `${dataHcName}-quick-filter-btn-1`,
                onClick: () => handleChangeRelativeValue([-0.1, 0.1]),
              },
              {
                label: '+/- 15%',
                active: isEqual([-0.15, 0.15], filterValue?.relativeValue),
                dataHcName: `${dataHcName}-quick-filter-btn-2`,
                onClick: () => handleChangeRelativeValue([-0.15, 0.15]),
              },
              {
                label: '+/- 20%',
                active: isEqual([-0.2, 0.2], filterValue?.relativeValue),
                dataHcName: `${dataHcName}-quick-filter-btn-3`,
                onClick: () => handleChangeRelativeValue([-0.2, 0.2]),
              },
            ]}
          />
        )}
        <FilterRange
          maxVal={999999}
          initialValue={convertNumArrayToFilterRange(
            filterValue?.absoluteValue
          )}
          subjectDisplay={subjectDisplay(
            true,
            convertNumArrayToFilterRange(filterValue?.relativeValue)
          )}
          formatType={FORMAT_TYPES.MONEY}
          onBlur={(value: FilterRangeType) =>
            patchFilter?.absoluteValue(FIELD, value)
          }
          dataHcName={`${dataHcName}-range`}
        />
      </>
    );
  },
};
