import React from 'react';

import { Table } from '@hcs/design-system';
import {
  PropertyStateArgsProps,
  PropertyStateFields,
  TableCellProps,
  TableHeaderCellProps,
  TableProps,
} from '@hcs/types';

import {
  CustomCellData,
  CustomCellField,
} from '../../components/PropertyTable/PropertyTable.types';
import { PropertyTableHeader } from '../../components/PropertyTable/PropertyTableHeader';
import { PropertyTableRow } from '../../components/PropertyTable/PropertyTableRow';

export interface PropertyTableProps<D extends CustomCellData = undefined>
  extends TableProps {
  propertyStateData?: (PropertyStateArgsProps & { customData?: D })[] | null;
  fields: (
    | {
        type: 'propertyStateFields';
        value: PropertyStateFields;
        headerCellProps?: TableHeaderCellProps;
        contentCellProps?: TableCellProps;
      }
    | CustomCellField<D>
  )[];
  showAddress?: boolean;
}

export const PropertyTable = <D extends CustomCellData = undefined>({
  propertyStateData,
  children,
  fields,
  showAddress = true,
  ...tableProps
}: PropertyTableProps<D>) => {
  return (
    <Table {...tableProps}>
      {!!fields.length && (
        <PropertyTableHeader sticky fields={fields} showAddress={showAddress} />
      )}
      {children}
      {propertyStateData?.map(({ propertyStateArgs, customData }) => {
        const rowId = `${propertyStateArgs?.propertyState?.hcAddressId}`;

        return (
          <PropertyTableRow
            key={rowId}
            customData={customData}
            propertyStateArgs={propertyStateArgs}
            fields={fields}
            showAddress={showAddress}
          />
        );
      })}
    </Table>
  );
};
