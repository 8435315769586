import React, { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';

import { removeSpecialChars } from '@hcs/utils';

import { useKeywordSearch } from '../../hooks/useKeywordSearch';
import { findChunks } from '../../utils';

import styles from './KeywordSearchText.module.css';

const HighlightTag = ({
  children,
  highlightIndex,
}: {
  children: ReactNode;
  highlightIndex: number;
}) => {
  return (
    <mark
      className={styles.Keyword}
      data-hc-name={`keyword-search-match-${highlightIndex}`}
    >
      {children}
    </mark>
  );
};

interface Props {
  dataHcName: string;
  className?: string;
  searchKey: string;
  textToHighlight: string;
}
export const KeywordSearchText = ({
  dataHcName,
  className,
  searchKey,
  textToHighlight,
}: Props) => {
  const {
    state: { inputs },
  } = useKeywordSearch(searchKey);
  return (
    <Highlighter
      findChunks={findChunks}
      searchWords={inputs?.keywords.map((s) => removeSpecialChars(s)) || []}
      textToHighlight={textToHighlight}
      data-hc-name={dataHcName}
      className={className}
      highlightTag={HighlightTag}
      autoEscape
    />
  );
};
