import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { AuthApi, useCurrentAppConfig } from '@hcs/auth';
import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { Account, SignUpPayload } from '@hcs/types';

// Export hook-specific types here rather than in types file
export type UseSignUpOptions = UseMutationOptions<
  Account,
  AxiosError<{ message: string }>,
  SignUpPayload,
  void
>;

const EVENT_NAME_SIGN_UP = 'user-sign-up';

export const useSignUp = (options?: UseSignUpOptions) => {
  const { appSlug } = useCurrentAppConfig();
  const trackEngagementEventMutation = useTrackEngagementEvent();
  const queryClient = useQueryClient();
  return useMutation<
    Account,
    AxiosError<{ message: string }>,
    SignUpPayload,
    void
  >(
    async (signUpPayload) => {
      const payload: SignUpPayload = {
        application: appSlug,
        ...signUpPayload,
      };
      return await AuthApi.signUp(payload);
    },
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          eventName: `${EVENT_NAME_SIGN_UP}-error`,
        });
      },
      onSuccess(account, variables) {
        queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
        options?.onSuccess?.(account, variables);
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          eventName: `${EVENT_NAME_SIGN_UP}-success`,
        });
      },
    }
  );
};
