import React from 'react';

import { TabOption, Tabs } from '@hcs/design-system';
import { ReportFeatures, ReportId } from '@hcs/types';
import { ArrayOneOrMore } from '@hcs/types';

import { SubjectDetailsTable } from '../../features';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import { SubjectDataSources } from '../SubjectDataSources';

import styles from './SubjectPropertyDetailsSection.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}
type TabType = TabOption<'generalDetails' | 'dataSources'>;
const dataHcName = 'subject-property-details-section';

export const SUBJECT_PROPERTY_DETAILS_SECTION_FEATURES = [
  ReportFeatures.SubjectPropertyDetails,
  ReportFeatures.SubjectEditDetails,
  ReportFeatures.SubjectEditCondition,
  ReportFeatures.SubjectDataSources,
];
export const SubjectPropertyDetailsSection = ({
  reportId,
  className,
}: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const includeSubjectDetails = reportFeaturesSupportedAny(reportConfig, [
    ReportFeatures.SubjectPropertyDetails,
    ReportFeatures.SubjectEditDetails,
    ReportFeatures.SubjectEditCondition,
  ]);
  const includeSubjectDataSources = reportFeaturesSupportedAny(reportConfig, [
    ReportFeatures.SubjectDataSources,
  ]);
  const subjectDetailsTab = <SubjectDetailsTable reportId={reportId} />;
  const dataSourcesTab = <SubjectDataSources reportId={reportId} />;
  const tabs: TabType[] = [];
  if (includeSubjectDetails) {
    tabs.push({
      value: 'generalDetails',
      label: 'General Details',
      dataHcEventSection: 'Property Details',
      content: subjectDetailsTab,
    });
  }

  if (includeSubjectDataSources) {
    tabs.push({
      value: 'dataSources',
      label: 'Data Sources',
      dataHcEventSection: 'Data Sources',
      content: dataSourcesTab,
    });
  }
  const content =
    tabs.length > 1 ? (
      // We can be sure that tabs has an item because we checked the length
      <Tabs
        theme={{ Content: styles.Content }}
        dataHcName={`${dataHcName}-tabs`}
        searchParamId="subject-property-details-section-tabs"
        tabs={tabs as ArrayOneOrMore<TabType>}
      />
    ) : reportFeaturesSupportedAny(reportConfig, [
        ReportFeatures.SubjectPropertyDetails,
      ]) ? (
      subjectDetailsTab
    ) : (
      dataSourcesTab
    );
  return (
    <div data-hc-name={dataHcName} className={className}>
      {content}
    </div>
  );
};
