import React from 'react';

import { InfoTooltip, InfoTooltipProps } from '@hcs/design-system';
import { CompFields, PropertyStateFields } from '@hcs/types';

import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '../../configs';
import { isCompField } from '../../utils';

export interface PropertyFieldInfoTooltipProps
  extends Omit<InfoTooltipProps, 'label' | 'description' | 'dataHcName'> {
  field: PropertyStateFields | CompFields;
}
const dataHcName = 'property-field-info-tooltip';
export const PropertyFieldInfoTooltip = ({
  field,
  ...infoTooltipProps
}: PropertyFieldInfoTooltipProps) => {
  const { label, info } = isCompField(field)
    ? COMP_FIELDS_CONFIG[field]
    : PROPERTY_STATE_FIELD_CONFIGS[field];
  return info ? (
    <InfoTooltip
      {...infoTooltipProps}
      dataHcName={dataHcName}
      label={label}
      description={info}
    />
  ) : null;
};
