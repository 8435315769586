import React from 'react';
import classNames from 'classnames';

import { SwitchProps } from '@hcs/types';

import { CheckIcon } from '../../../../svgs/icons/indicator';

import styles from './Switch.module.css';

// heavily inspired by http://aaronshaf.github.io/react-toggle/
export const Switch = <T extends string | number | null | boolean>({
  className,
  value,
  switchOnOption,
  switchOffOption,
  onChange,
  disabled,
  dataHcName,
  theme,
}: SwitchProps<T>) => {
  const isSwitchOn = switchOnOption.value === value;
  const nextSwitchOption = isSwitchOn ? switchOffOption : switchOnOption;
  const handleChange = () => {
    if (disabled) {
      return;
    }
    const newValue = nextSwitchOption.value;
    onChange(newValue);
  };

  return (
    <div
      className={classNames(styles.Switch, className, {
        [classNames(styles.SwitchOn, theme?.SwitchOn)]: isSwitchOn,
        [classNames(styles.SwitchDisabled, theme?.SwitchDisabled)]: disabled,
      })}
      data-hc-name={dataHcName}
      data-hc-event-section={nextSwitchOption.dataHcEventSection}
      data-hc-event-type={nextSwitchOption.dataHcEventType}
      data-hc-event-name={nextSwitchOption.dataHcEventName}
      onClick={(e) => {
        e.stopPropagation();
        handleChange();
      }}
      aria-selected={isSwitchOn}
    >
      <div className={classNames(styles.Track, theme?.Track)}>
        <CheckIcon
          className={styles.Check}
          dataHcName={dataHcName}
          color="neutral-light-10"
          size={27}
        />
        <div className={styles.SwitchThumb}></div>
      </div>
      <input
        className={styles.Checkbox}
        readOnly
        type="checkbox"
        value={value !== null ? value.toString() : ''}
        checked={isSwitchOn}
        data-hc-name={`${dataHcName}-checkbox`}
      />
    </div>
  );
};
