import { Chunk, FindChunks } from 'react-highlight-words';

const escapeRegExpFn = (string: string) =>
  // Disabling because this regex was copied from the react-highlight-words package
  // eslint-disable-next-line no-useless-escape
  string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

export const findChunks = ({
  autoEscape,
  caseSensitive,
  sanitize = (v) => v,
  searchWords,
  textToHighlight
}: FindChunks) => {
  textToHighlight = sanitize(textToHighlight);
  const initialChunks: Chunk[] = [];
  return searchWords
    .filter((searchWord) => searchWord) // Remove empty words
    .reduce((chunks, searchWord) => {
      searchWord = sanitize(searchWord.toString());

      if (autoEscape) {
        searchWord = escapeRegExpFn(searchWord);
      }
      // The \\b is the only change from the default implementation
      // It forces a whole-word match like the API does
      // SECURITY: @jnettleman - Ignoring the secscan rule because according to the docs it is intended to prevent
      // a DOS attack but this code will never run server-side. Docs: https://github.com/nodesecurity/eslint-plugin-security/blob/master/README.md#detect-non-literal-regexp
      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(
        '\\b' + searchWord + '\\b',
        caseSensitive ? 'g' : 'gi'
      );

      let match;
      // SECURITY: @jnettleman - Intentional according to the library
      // eslint-disable-next-line scanjs-rules/accidental_assignment
      while ((match = regex.exec(textToHighlight))) {
        const start = match.index;
        const end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ start, end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, initialChunks);
};
