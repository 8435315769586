import { gql } from 'graphql-request';

/** PROPERTY STATE | blockDetails  */

export const FRAGMENT_BLOCK_DETAILS_CORE = gql`
  fragment BlockDetailsCore on BlockDetails {
    superFund {
      withinMiles0 {
        count
      }
      withinMiles1 {
        count
      }
      withinMiles4 {
        count
      }
    }
    value {
      propertyType
      propertyTypeEnum
      distribution {
        valuePerSqft {
          percentile50
        }
      }
    }
  }
`;
