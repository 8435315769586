import { useMemo } from 'react';

import { useFeatureFlagConfigs } from '@hcs/huell';
import { FeatureFlagAdminConfig } from '@hcs/types';

export const useHcsAdminFeatureFlagsForOrg = (orgId?: number) => {
  const featureFlagsConfigsQuery = useFeatureFlagConfigs();
  const data = useMemo(() => {
    if (!featureFlagsConfigsQuery.isSuccess || !orgId) {
      return undefined;
    }
    const results: {
      status: 'enabled' | 'blocked' | 'unassigned';
      featureFlagConfig: FeatureFlagAdminConfig;
    }[] = [];
    featureFlagsConfigsQuery.data?.forEach((featureFlagConfig) => {
      if (featureFlagConfig.enabled) {
        const isBlocked = featureFlagConfig.orgIdsBlocklist
          .split(',')
          .includes(orgId.toString());
        const isEnabled =
          featureFlagConfig.orgIdsEnabled
            .split(',')
            .includes(orgId.toString()) || featureFlagConfig.enabledByDefault;
        results.push({
          status: isBlocked ? 'blocked' : isEnabled ? 'enabled' : 'unassigned',
          featureFlagConfig,
        });
      }
    });
    return results.sort((a, b) => {
      return a.featureFlagConfig.name > b.featureFlagConfig.name
        ? 1
        : a.featureFlagConfig.name > b.featureFlagConfig.name
        ? -1
        : 0;
    });
  }, [
    featureFlagsConfigsQuery.isSuccess,
    featureFlagsConfigsQuery.data,
    orgId,
  ]);
  return {
    ...featureFlagsConfigsQuery,
    data,
  };
};
