import React from 'react';

import { ReportSummaryCellContentProps } from '@hcs/types';

import { DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL } from '../../../constants/ReportSummary.constants';

import { createReportSummaryTableCellConfig } from './ReportSummaryTableCell';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL}-amount-ordered`;
const CellContentComponent = ({ order }: ReportSummaryCellContentProps) => (
  <div data-hc-name={dataHcName}>{order.completeOrderItemsCount}</div>
);

export const REPORT_SUMMARY_AMOUNT_ORDERED_CONFIG =
  createReportSummaryTableCellConfig({
    CellContentComponent,
    tableHeaderName: 'Amount Ordered',
  });
