import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AssetParamIds,
  AssetsParams,
  PortfolioAsset,
  PortfolioAssetsSliceState,
  PortfolioTableColumns,
} from '@hcs/types';
import { resetOnLogoutAction } from '@hcs/utils';

const initialState: PortfolioAssetsSliceState = {
  filters: {},
  itemsInShoppingCart: {},
  order: {
    orderBy: undefined,
    orderDirection: 'ASC',
  },
};

export const portfolioAssetsSlice = createSlice({
  name: 'portfolioAssetsSlice',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(resetOnLogoutAction, () => initialState),
  reducers: {
    tableChangeFilter: (
      state: PortfolioAssetsSliceState,
      action: PayloadAction<{ filterChanges: AssetsParams }>
    ) => {
      let field: AssetParamIds;
      for (field in action.payload.filterChanges) {
        const filterValue = action.payload.filterChanges[field];
        state.filters = {
          ...state.filters,
          [field]: filterValue ? filterValue : undefined,
        };
      }
    },
    tableChangeOrder(
      state,
      action: PayloadAction<{
        orderBy?: PortfolioTableColumns | 'address';
        orderDirection: 'ASC' | 'DESC';
      }>
    ) {
      state.order.orderBy = action.payload.orderBy;
      state.order.orderDirection = action.payload.orderDirection;
    },
    addToCart(
      state,
      action: PayloadAction<{
        assetId: PortfolioAsset['assetId'];
        address: string;
      }>
    ) {
      state.itemsInShoppingCart = {
        ...state.itemsInShoppingCart,
        [action.payload.assetId]: {
          isSelected: true,
          label: action.payload.address,
        },
      };
    },
    removeFromCart(
      state,
      action: PayloadAction<{ assetId: PortfolioAsset['assetId'] }>
    ) {
      delete state.itemsInShoppingCart[action.payload.assetId];
    },
    deselectAndLeaveInCart(
      state,
      action: PayloadAction<{ assetId: PortfolioAsset['assetId'] }>
    ) {
      state.itemsInShoppingCart = {
        ...state.itemsInShoppingCart,
        [action.payload.assetId]: {
          ...state.itemsInShoppingCart[action.payload.assetId],
          isSelected: false,
        },
      };
    },
    clearCart(state) {
      state.itemsInShoppingCart = initialState.itemsInShoppingCart;
    },
    clearFilters(state) {
      state.filters = initialState.filters;
    },
  },
});
