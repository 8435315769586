import React, { FocusEvent, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Button.module.css';

export interface ButtonProps {
  /**
   * Required Integration Testing & Engagement Tracking ID
   */
  dataHcName: string;
  dataHcEventSection?: string;
  dataHcEventName?: string;
  dataHcEventType?: string;
  children?: ReactNode;
  /**
   * Adds a label to the button. Primarily used to label a button that does not have children text.
   */
  label?: string | ReactNode;
  /**
   * Defines whether `Button` displays primary color.
   */
  primary?: boolean;
  /**
   * Defines whether `Button` displays overlay colors
   */
  overlay?: boolean;
  /**
   * Disables the button and prevents user interaction.
   */
  disabled?: boolean;
  className?: string;
  /**
   *
   * Defines whether `Button` displays secondary color.
   */
  secondary?: boolean;
  /**
   * Defines whether `Button` should be highlighted (yellow variety)
   */
  highlightYellow?: boolean;
  /**
   * Defines whether `Button` should be highlighted (green variety)
   */
  highlightGreen?: boolean;
  /**
   * Defines whether `Button` should be highlighted (red variety)
   */
  highlightRed?: boolean;
  /**
   * When enabled, adds CSS that signals the button is dependent on a loading state.
   */
  loading?: boolean;
  /**
   * Sets the Button `type`.
   */
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  /**
   * Adds an icon to the button. Accepts a react component.
   */
  icon?: ReactNode;
  /**
   * Determines icon orientation. Defaults to `left`.
   */
  iconPosition?: 'left' | 'right';

  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (e: MouseEvent<HTMLButtonElement>) => void;
  onFocus?: (e: FocusEvent<HTMLButtonElement>) => void;
  onBlur?: (e: FocusEvent<HTMLButtonElement>) => void;
}

export const Button = ({
  dataHcName,
  dataHcEventSection,
  dataHcEventName,
  dataHcEventType,
  primary = true,
  secondary = false,
  highlightYellow = false,
  highlightGreen = false,
  highlightRed = false,
  overlay = false,
  label,
  children,
  disabled,
  className,
  loading,
  type,
  icon,
  iconPosition = 'left',
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  onFocus,
  onBlur,
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      data-hc-event-name={dataHcEventName}
      data-hc-event-type={dataHcEventType}
      className={classNames(styles.Button, className, {
        [styles.primary]: !loading && primary && !secondary && !overlay,
        [styles.overlay]: !loading && overlay,
        [styles.disabled]: disabled,
        [styles.loading]: loading,
        [styles.withIcon]: icon,
        [styles.iconRight]: icon && iconPosition === 'right',
        [styles.highlightYellow]: highlightYellow,
        [styles.highlightGreen]: highlightGreen,
        [styles.highlightRed]: highlightRed,
      })}
      disabled={disabled || loading}
      {...(disabled
        ? {}
        : {
            onClick,
            onMouseEnter,
            onMouseLeave,
            onMouseDown,
            onMouseUp,
            onFocus,
            onBlur,
          })}
      type={type}
    >
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      {label} {children}
    </button>
  );
};
