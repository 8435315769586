import React from 'react';

import { Dialog } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { SavedCompFilterSetFormDeprecated } from '../../features/SavedCompFilterSetForm/SavedCompFilterSetFormDeprecated';

interface Props {
  reportId: ReportId;
  active: boolean;
  compType: CompTypes;
  onClose: VoidFunction;
}
const dataHcName = 'saved-comp-filter-set-form-dialog';
export const SavedCompFilterSetFormDialogDeprecated = ({
  active,
  reportId,
  compType,
  onClose,
}: Props) => {
  return (
    <Dialog
      dataHcName={dataHcName}
      title="Save Filters"
      active={active}
      onClose={onClose}
      width={600}
    >
      <SavedCompFilterSetFormDeprecated
        onSuccess={onClose}
        reportId={reportId}
        compType={compType}
      />
    </Dialog>
  );
};
