import React from 'react';
import classNames from 'classnames';

import styles from './ProgressBar.module.css';

export interface ProgressBarProps {
  dataHcName: string;
  className?: string;
  completedPercentage?: number;
}
export const ProgressBar = ({
  dataHcName,
  className,
  completedPercentage,
}: ProgressBarProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ProgressBar, className)}
    >
      <div
        className={styles.Progress}
        style={{ width: `${completedPercentage}%` }}
      />
    </div>
  );
};
