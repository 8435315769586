import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import { ContractIcon, ExpandIcon } from '../../../../foundations';

import styles from './FullscreenButton.module.css';

export type FullscreenButtonTheme = {
  FullscreenButton: string;
  FullscreenIcon: string;
};

export interface FullscreenButtonProps {
  // Whether to show expand icon or contract (false = contract)
  expand?: boolean;
  className?: string;
  dataHcName: string;
  // Click Callback
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  theme?: Partial<FullscreenButtonTheme>;
}

export const FullscreenButton = ({
  onClick,
  expand = true,
  theme,
  dataHcName,
}: FullscreenButtonProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      onClick={(event: MouseEvent<HTMLElement>) => onClick?.(event)}
      className={classNames(styles.FullscreenButton, theme?.FullscreenButton)}
    >
      {expand ? (
        <ExpandIcon
          dataHcName={`${dataHcName}-expand-icon`}
          height="18px"
          width="18px"
          className={theme?.FullscreenIcon}
        />
      ) : (
        <ContractIcon
          dataHcName={`${dataHcName}-contract-icon`}
          height="18px"
          width="18px"
          className={theme?.FullscreenIcon}
        />
      )}
    </div>
  );
};
