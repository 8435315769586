import { useQuery } from '@tanstack/react-query';

import { logException } from '@hcs/utils';

import { AuthzApi } from '../api';

export const QUERY_KEY_ORG_ROLES = 'org-roles';

export const useOrgRoles = (orgId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_ORG_ROLES, orgId],
    async () => {
      if (!orgId) {
        logException('useOrgRoles called with undefined orgId');
        throw new Error('Failed to get organization roles');
      }
      const response = await AuthzApi.getOrgRoles(orgId);
      return response.roles;
    },
    {
      enabled: !!orgId,
    }
  );
};
