import { useQuery } from '@tanstack/react-query';

import { AuthApi } from '../api';

import { useAccount } from './useAccount';

export const QUERY_KEY_USER_HMAC_HASH = 'QUERY_KEY_USER_HMAC_HASH';
export const useUserHmacHash = () => {
  const { data: account } = useAccount();
  const isLoggedIn = !!account?.validity.token;
  return useQuery(
    [QUERY_KEY_USER_HMAC_HASH],
    async () => {
      if (account?.user.id) {
        return await AuthApi.fetchUserHmacHash(account.user.id);
      }
    },
    {
      enabled: isLoggedIn,
    }
  );
};
