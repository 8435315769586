import { useNavigate } from 'react-router';

import { APP_CONFIG_PLATFORM } from '../constants';

export const useNavigateToPlatformLanding = () => {
  const navigate = useNavigate();
  return () => {
    navigate(APP_CONFIG_PLATFORM.rootPath);
  };
};
