import React from 'react';

import { MultiSelect, Option } from '@hcs/design-system';
import { FormError } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { ListingStatusCerberus } from '@hcs/types';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompsFiltersPaths, ReportId } from '@hcs/types';
import { ListingStatusNormalized } from '@hcs/types';

import { FilterCard } from '../../components-deprecated';
import {
  useCompsFiltersDocument,
  useDocumentPatch,
  useSubjectDocument,
} from '../../hooks';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const OPTIONS_SOLD: Option<ListingStatusNormalized>[] = [
  {
    value: ListingStatusCerberus.Active,
    label: 'Active',
  },
  {
    value: ListingStatusCerberus.Pending,
    label: 'Pending',
  },
  {
    value: ListingStatusCerberus.Closed,
    label: 'Closed',
  },
];

const OPTIONS_RENTAL: Option<ListingStatusNormalized>[] = [
  {
    value: ListingStatusCerberus.Active,
    label: 'Active',
  },
  {
    value: ListingStatusCerberus.Pending,
    label: 'Pending',
  },
  {
    value: ListingStatusCerberus.Closed,
    label: 'Closed',
  },
];

const FIELD = PropertyStateFields.currentStatusRental;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const dataHcName = `comp-filter-${FIELD}`;
export const CompFilterPropertyStatusRental = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!filterDocument || !subjectDocument || !reportPermissions) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters?.[FIELD];
  const subjectValue = getPropertyStateFieldValue(FIELD, {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument.data.propertyState,
  });
  return (
    <FilterCard
      label="Status"
      info={
        filterValue?.error?.field === 'absoluteValue' ? (
          <FormError
            dataHcName={`${dataHcName}-not-savable`}
            value="Cannot apply the saved filter because the subject value is missing"
          />
        ) : subjectValue === null ? (
          'Missing Subject Value'
        ) : (
          `Subject: ${subjectValue}`
        )
      }
      className={className}
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={FIELD}
        />
      }
    >
      <MultiSelect<ListingStatusNormalized>
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        disabled={!reportPermissions.isEditable}
        options={compType === CompTypes.Rental ? OPTIONS_RENTAL : OPTIONS_SOLD}
        onChange={(value: ListingStatusNormalized[]) => {
          if (value.length === 0) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    </FilterCard>
  );
};
