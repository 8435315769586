import { useCallback, useEffect, useState } from 'react';

import { useComponentInternalId } from '@hcs/hooks';
import { EngagementEventData } from '@hcs/types';

import { useEngagementTrackingEventDataSlice } from './useEngagementTrackingEventDataSlice';

// Registers engagement tracking data that remains registered until the browser is refreshed
export const useRegisterAndPeristEngagementTrackingData = (
  eventDataId: string | undefined,
  eventData: EngagementEventData | undefined
) => {
  const componentId = useComponentInternalId();
  const {
    actions: { registerEventData },
  } = useEngagementTrackingEventDataSlice();
  const [registered, setRegistered] = useState<string | undefined>();
  const registerAndPersistEventData = useCallback(
    (eId: string | undefined, data: EngagementEventData | undefined) => {
      if (data && eId && registered !== eId) {
        setRegistered(eId);
        registerEventData({
          componentId,
          eventDataId: eId,
          eventData: data,
        });
      }
    },
    [registered, componentId, registerEventData]
  );
  useEffect(() => {
    registerAndPersistEventData(eventDataId, eventData);
  }, [eventData, eventDataId, registerAndPersistEventData]);
  return {
    registerAndPersistEventData,
  };
};
