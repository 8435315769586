const searchParamsPrefix = 'compFarmList';
export const CompFarmListSearchParams = {
  ListViewType: `${searchParamsPrefix}-listViewType`,
  SelectedOnly: `${searchParamsPrefix}-selectedOnly`
};

export enum CompFarmListViewType {
  Card = 'card',
  Table = 'table',
  Compare = 'compare'
}
