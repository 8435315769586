import { DataExplorerIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, CapabilityApplications } from '@hcs/types';

import DexpProductImage from '../assets/product-images/data-explorer-image.png';

export const APP_CONFIG_DEXP: AppConfig = {
  name: 'Data Explorer',
  appSlug: AppSlugs.DataExplorer,
  applicationKey: CapabilityApplications.DataExplorer,
  rootPath: `/dexp`,
  Icon: DataExplorerIcon,
  descriptionLong: `
        Access industry-leading, proprietary analytics for more than 100 million
        US homes, choosing from among dozens of Data Points to customize your experience
        and surface information for making informed decisions.
      `,
  descriptionShort:
    'Add real-estate data to Excel spreadsheet from a web browser',
  marketingUrl: 'https://www.housecanary.com/products/data-points/',
  marketingImage: DexpProductImage,
};

export enum VIEW_PATHS_DEXP {
  NEW_ANALYSIS = 'new-analysis',
  SELECT_DATA_POINTS = 'select-data-points',
  GENERATE_ANALYSIS = 'generate-analysis',
  PREVIOUS_ANALYSES = 'previous-analyses',
}
