import {
  LOCAL_STORAGE_KEY_CONSOLE_ENABLED,
  LOCAL_STORAGE_KEY_CONSOLE_VERBOSE
} from '../constants';

export const getIsHcsConsoleEnabled = () => {
  // SECURITY: localStorage is referenced to access values from global local storage @jnettleman
  // eslint-disable-next-line scanjs-rules/identifier_localStorage
  return localStorage.getItem(LOCAL_STORAGE_KEY_CONSOLE_ENABLED) === 'true';
};

export const getIsHcsConsoleEnabledVerbose = () => {
  return (
    getIsHcsConsoleEnabled() &&
    // SECURITY: localStorage is referenced to access values from global local storage @jnettleman
    // eslint-disable-next-line scanjs-rules/identifier_localStorage
    localStorage.getItem(LOCAL_STORAGE_KEY_CONSOLE_VERBOSE) === 'true'
  );
};
