import React, { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Card,
  CardTitle,
  LoadingSpinner,
  StatusMessage,
} from '@hcs/design-system';
import { Input, TextArea } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { DialogInputLayout } from '@hcs/forms';
import { SamlProviderConfig, SamlProviderConfigFormData } from '@hcs/types';
import { CONTACT_SALES_URL } from '@hcs/urls';
import { logException } from '@hcs/utils';

import { useOrgSamlProvider } from '../../hooks/useOrgSamlProvider';
import { useSaveOrgSamlProvider } from '../../hooks/useSaveOrgSamlProvider';
import { DeleteOrgSamlProviderDialog } from '../DeleteOrgSamlProviderDialog';

import styles from './OrgSamlProvider.module.css';

const FORM_SCHEMA = yup.object().shape({
  certificate: yup.string().required('Certificate is required'),
  authMetadata: yup.string().required('Auth Metadata is required'),
  authAudience: yup.string().nullable(),
  domain: yup.string().required('Domain is required'),
});

interface BaseInputProps {
  label: string;
  labelHelper?: string;
  required: boolean;
  form: UseFormReturn<SamlProviderConfigFormData>;
  dataHcName: string;
}

interface TextAreaProps extends BaseInputProps {
  fieldName: Exclude<keyof SamlProviderConfigFormData, 'authAudience'>;
}

const TextAreaField = ({
  label,
  required,
  fieldName,
  form,
  dataHcName,
}: TextAreaProps) => {
  return (
    <DialogInputLayout
      label={label}
      required={required}
      dataHcName={dataHcName}
      className={styles.Field}
    >
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <TextArea
            {...field}
            placeholder={label}
            value={field.value || ''}
            dataHcName={`${dataHcName}-input`}
            onChange={field.onChange}
            required={required}
            error={form.formState.errors[fieldName]?.message}
          />
        )}
      />
    </DialogInputLayout>
  );
};

interface TextInputProps extends BaseInputProps {
  fieldName: Extract<
    keyof SamlProviderConfigFormData,
    'authAudience' | 'domain'
  >;
}

const TextField = ({
  label,
  labelHelper,
  required,
  fieldName,
  form,
  dataHcName,
}: TextInputProps) => {
  return (
    <DialogInputLayout
      label={label}
      labelHelper={labelHelper}
      required={required}
      dataHcName={dataHcName}
      className={styles.Field}
    >
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <Input
            {...field}
            placeholder={label}
            value={field.value || ''}
            dataHcName={`${dataHcName}-input`}
            onChange={field.onChange}
            required={required}
            error={form.formState.errors[fieldName]?.message}
          />
        )}
      />
    </DialogInputLayout>
  );
};

interface Props {
  orgId?: number;
}

const dataHcName = 'org-saml-provider';
export const OrgSamlProvider = ({ orgId }: Props) => {
  const { data: existingSamlProvider, isLoading: useSamlProviderLoading } =
    useOrgSamlProvider(orgId);
  const {
    mutate: saveMutationMutate,
    isLoading: saveMutationIsLoading,
    isError: saveMutationIsError,
    error: saveMutationError,
  } = useSaveOrgSamlProvider();

  const form = useForm<SamlProviderConfigFormData>({
    resolver: yupResolver(FORM_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      certificate: null,
      authMetadata: null,
      authAudience: null,
      domain: null,
    },
  });
  const [deleteDialogActive, setDeleteDialogActive] = useState(false);

  const { reset: formReset } = form;

  const resetToExsitingProvider = useCallback(
    (existingSamlProvider: SamlProviderConfig) => {
      console.log('existingSamlProvider', existingSamlProvider);
      formReset({
        authAudience: existingSamlProvider.authAudience,
        authMetadata: existingSamlProvider.authMetadata,
        certificate: existingSamlProvider.certificate,
        domain: existingSamlProvider.domain,
      });
    },
    [formReset]
  );

  const clearForm = useCallback(() => {
    formReset({
      authAudience: null,
      authMetadata: null,
      certificate: null,
    });
  }, [formReset]);

  // update default values after saml config load (if they exist)
  useEffect(() => {
    if (existingSamlProvider) {
      resetToExsitingProvider(existingSamlProvider);
    }
  }, [existingSamlProvider, resetToExsitingProvider]);

  if (useSamlProviderLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-loader`} />;
  }

  const handleSubmit = (formData: SamlProviderConfigFormData) => {
    if (orgId !== undefined) {
      saveMutationMutate({
        formData,
        orgId,
        existingSamlProvider: existingSamlProvider || null,
      });
    } else {
      logException('OrgSamlProvider: orgId is undefined on submit');
    }
  };

  const showDeleteButton =
    existingSamlProvider !== undefined &&
    existingSamlProvider !== null &&
    orgId !== undefined;

  return (
    <ExperienceFlag
      experienceFlagId="CLIENT_SSO_RELEASE"
      now={
        <Card
          dataHcName={`${dataHcName}-contact-sales`}
          title={'Contact sales to enable access to enterprise features.'}
          maxWidth={500}
        >
          <a
            href={CONTACT_SALES_URL}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'block', width: '100%' }}
          >
            <Button dataHcName={`${dataHcName}-contact-sales-button`}>
              Contact Sales
            </Button>
          </a>
        </Card>
      }
      next={
        <Card dataHcName={`${dataHcName}-card`} maxWidth={900} noGap>
          <CardTitle
            dataHcName={`${dataHcName}-card-title`}
            className={styles.CardTitle}
          >
            <div>SAML Provider Config</div>
            {showDeleteButton ? (
              <Button
                dataHcName={`${dataHcName}-delete-button`}
                label="Delete"
                onClick={() => setDeleteDialogActive(true)}
                highlightRed
              />
            ) : null}
          </CardTitle>
          {saveMutationIsError && (
            <StatusMessage
              dataHcName={`${dataHcName}-error-message`}
              show={saveMutationIsError}
              type="error"
              title={saveMutationError.message}
            />
          )}
          <FormProvider {...form}>
            <form
              data-hc-name={dataHcName}
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <TextAreaField
                label="Certificate"
                required
                fieldName="certificate"
                dataHcName={`${dataHcName}-certificate-field`}
                form={form}
              />
              <TextAreaField
                label="Auth Metadata"
                required
                fieldName="authMetadata"
                dataHcName={`${dataHcName}-auth-metadata-field`}
                form={form}
              />
              <TextField
                label="Auth Audience"
                required={false}
                fieldName="authAudience"
                dataHcName={`${dataHcName}-auth-audience-field`}
                form={form}
              />
              <TextField
                label="Domain"
                labelHelper='Domain should match the email address domain of your users. Example: for user email "bob@company1.com" the domain is "company1.com" (without quotes)'
                required
                fieldName="domain"
                dataHcName={`${dataHcName}-domain-field`}
                form={form}
              />
              <div className={styles.FormButtons}>
                {existingSamlProvider ? (
                  <Button
                    dataHcName={`${dataHcName}-cancel-button`}
                    label="Cancel Changes"
                    disabled={!form.formState.isDirty || saveMutationIsLoading}
                    primary={false}
                    onClick={() =>
                      resetToExsitingProvider(existingSamlProvider)
                    }
                  />
                ) : null}

                <Button
                  dataHcName={`${dataHcName}-save-button`}
                  type="submit"
                  label="Save"
                  disabled={saveMutationIsLoading}
                />
              </div>
            </form>
          </FormProvider>
          {showDeleteButton ? (
            <DeleteOrgSamlProviderDialog
              active={deleteDialogActive}
              onClose={() => setDeleteDialogActive(false)}
              providerId={existingSamlProvider.id}
              orgId={orgId}
              onDeleteSuccess={clearForm}
            />
          ) : null}
        </Card>
      }
    />
  );
};
