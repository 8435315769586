import snakecaseKeys from 'snakecase-keys';

export const stringifyHcsAdminFilters = <
  T extends {
    page?: number;
    resultsPerPage?: number;
    q: Record<string, unknown>;
  }
>(
  payload: T
): T => {
  return snakecaseKeys(
    {
      ...payload,
      q: JSON.stringify(snakecaseKeys(payload.q, { deep: true })),
    },
    { deep: true }
  );
};
