import { PropertyStateLocation } from '@hcs/types';
import { StreetViewOptions } from '@hcs/types';
import { ACCOUNT_URL } from '@hcs/urls';

import {
  GOOGLE_STREET_VIEW_STATIC_KEY,
  GOOGLE_STREET_VIEW_STATIC_PDF_KEY,
} from '../constants';

const SIZES = {
  sm: {
    width: 372,
    height: 300,
  },
  lg: {
    width: 1000,
    height: 806,
  },
};
export const buildStaticStreetViewUrl = (
  location: PropertyStateLocation['location'],
  options?: StreetViewOptions
) => {
  const height =
    options?.height && options.width
      ? options.height
      : SIZES[options?.size || 'sm'].height;
  const width =
    options?.height && options.width
      ? options.width
      : SIZES[options?.size || 'sm'].width;
  return `${ACCOUNT_URL}/${
    options?.publicLinkUid ? `svp` : 'sv'
  }/url?size=${width}x${height}&location=${location?.latitude},${
    location?.longitude
  }&source=outdoor&key=${GOOGLE_STREET_VIEW_STATIC_KEY}${
    options?.publicLinkUid ? `&publicLinkUid=${options.publicLinkUid}` : ''
  }`;
};

export const buildStaticStreetViewUrlForPdf = (
  location: PropertyStateLocation['location'],
  options?: StreetViewOptions
) => {
  const height =
    options?.height && options.width
      ? options.height
      : SIZES[options?.size || 'sm'].height;
  const width =
    options?.height && options.width
      ? options.width
      : SIZES[options?.size || 'sm'].width;
  return `https://maps.googleapis.com/maps/api/streetview?size=${width}x${height}&location=${location?.latitude},${location?.longitude}&source=outdoor&key=${GOOGLE_STREET_VIEW_STATIC_PDF_KEY}`;
};
