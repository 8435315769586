import React, { Fragment, ReactNode } from 'react';
import classnames from 'classnames';

import { MenuItem, MenuItemProps } from './MenuItem';

import styles from './Menu.module.css';

export interface MenuProps {
  className?: string;
  // nulls get filtered out
  menuItems?: (null | Omit<MenuItemProps, 'skeletonConfig'>)[];
  children?: ReactNode;
  skeletonConfig?: MenuItemProps['skeletonConfig'];
  itemBorders?: boolean;
}
export const Menu = ({
  menuItems,
  className,
  children,
  skeletonConfig,
  itemBorders = true,
}: MenuProps) => {
  return (
    <ul
      className={classnames(styles.Menu, className, {
        [styles.border]: itemBorders,
      })}
    >
      {skeletonConfig?.isLoading
        ? Array.from(Array(skeletonConfig.numItems).keys()).map((k) => {
            return (
              <MenuItem
                key={`menu-skeleton-${k}`}
                dataHcName={`menu-skeleton-${k}`}
                label=""
                skeletonConfig={skeletonConfig}
              />
            );
          })
        : menuItems?.map((menuItem, i) => {
            return menuItem === null ? (
              <Fragment key={`menuItem-${i}`}>{null}</Fragment>
            ) : (
              <MenuItem key={`menuItem-${i}`} {...menuItem} />
            );
          })}
      {children}
    </ul>
  );
};
