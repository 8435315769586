import { useDispatch, useSelector } from 'react-redux';

import {
  ExperienceFlagsDevtoolPartialAppState,
  ExperienceFlagsDevtoolSliceState,
} from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { EXPERIENCE_FLAGS_REDUCER_KEY, experienceFlagsSlice } from '../rtk';

export const useExperienceFlagsSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<
      ExperienceFlagsDevtoolPartialAppState,
      ExperienceFlagsDevtoolSliceState
    >((state) => {
      const v = state[EXPERIENCE_FLAGS_REDUCER_KEY];
      return v;
    }),
    actions: mapDispatchToSliceActions(dispatch, experienceFlagsSlice),
  };
};
