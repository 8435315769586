import React, { SetStateAction } from 'react';
import {
  DrawCircleFromCenterMode,
  DrawPolygonMode,
  FeatureCollection,
  ViewMode,
} from '@nebula.gl/edit-modes';

import {
  MapDrawCircle,
  MapDrawCircleActive,
  MapDrawPolygon,
  MapDrawPolygonActive,
  MapDrawRectangle,
  MapDrawRectangleActive,
  MapDrawTrash,
} from '@hcs/design-system';
import { HomeDarkIcon } from '@hcs/design-system';
import { GeometryTypesEnum, OptionalEngagementProps } from '@hcs/types';

import { MeasureDrawCircle } from './modes/MeasureDrawCircle';
import { MeasureDrawPolygon } from './modes/MeasureDrawPolygon';
import { MeasureDrawRectangle } from './modes/MeasureDrawRectangle';
import { DrawControlButton } from './DrawControlButton';

import styles from './DrawControls.module.css';

type Mode = typeof DrawCircleFromCenterMode | typeof DrawPolygonMode;

interface Props {
  setMode: (func: () => Mode) => void;
  deleteEngagement?: OptionalEngagementProps;
  trashDisabled: boolean;
  setEditedPolygon: (isEdited: boolean) => void;
  setTrashDisabled: (isDisabled: boolean) => void;
  setSelectedFeatureIndexes: (indexes: number[]) => void;
  onSetGeoJson: (value: SetStateAction<FeatureCollection | undefined>) => void;
  onDelete: VoidFunction;
  setTooltipText: (tooltip: string) => void;
  setActiveControl: (activeControl: GeometryTypesEnum | undefined) => void;
  activeControl: GeometryTypesEnum | undefined;
  showLocationControl?: boolean;
  locationControlActive?: boolean;
  onClickLocationControl?: VoidFunction;
}

const dataHcName = 'map-draw-controls';
export const DrawControls = ({
  activeControl,
  setActiveControl,
  setMode,
  trashDisabled,
  deleteEngagement,
  setEditedPolygon,
  setTooltipText,
  onDelete,
  setSelectedFeatureIndexes,
  setTrashDisabled,
  onSetGeoJson,
  showLocationControl,
  onClickLocationControl,
  locationControlActive,
}: Props) => {
  const trashDrawing = () => {
    setSelectedFeatureIndexes([]); // deselect before deleting
    setMode(() => ViewMode);
    onSetGeoJson({ type: 'FeatureCollection', features: [] });
  };

  const handleTooltipText = (drawControl: GeometryTypesEnum): void => {
    const start = 'Click and move mouse to draw';
    switch (drawControl) {
      case GeometryTypesEnum.CIRCLE:
        setTooltipText(`${start} circle.`);
        break;
      case GeometryTypesEnum.RECTANGLE:
        setTooltipText(`${start} rectangle.`);
        break;
      case GeometryTypesEnum.POLYGON:
        setTooltipText('Click to start drawing shape.');
        break;
    }
  };

  const setDrawingMode = (drawControl?: GeometryTypesEnum, mode?: Mode) => {
    trashDrawing();
    setEditedPolygon(false);
    if (drawControl) {
      handleTooltipText(drawControl);
    }
    setTrashDisabled(true);
    setActiveControl(drawControl);
    if (mode) {
      setMode(() => mode);
    }
  };

  return (
    <div className={styles.DrawContainer} data-hc-name={dataHcName}>
      {showLocationControl && onClickLocationControl && (
        <DrawControlButton
          isActive={!!locationControlActive}
          onClick={() => {
            setActiveControl(undefined);
            onClickLocationControl();
          }}
          title="Location"
          helpText="Position the subject property"
          iconActive={
            <HomeDarkIcon
              className={styles.active}
              width="60%"
              height="60%"
              dataHcName={`${dataHcName}-circle-icon-active`}
            />
          }
          icon={
            <HomeDarkIcon
              className={styles.inactive}
              width="60%"
              height="60%"
              dataHcName={`${dataHcName}-circle-icon`}
            />
          }
        />
      )}
      <DrawControlButton
        isActive={activeControl === GeometryTypesEnum.CIRCLE}
        onClick={() => {
          setDrawingMode(GeometryTypesEnum.CIRCLE, MeasureDrawCircle);
        }}
        title="Radius"
        helpText="Draw a circle"
        iconActive={
          <MapDrawCircleActive
            width="60%"
            height="60%"
            dataHcName={`${dataHcName}-circle-icon-active`}
          />
        }
        icon={
          <MapDrawCircle
            width="60%"
            height="60%"
            dataHcName={`${dataHcName}-circle-icon`}
          />
        }
      />
      <DrawControlButton
        isActive={activeControl === GeometryTypesEnum.RECTANGLE}
        onClick={() => {
          setDrawingMode(GeometryTypesEnum.RECTANGLE, MeasureDrawRectangle);
        }}
        title="Rectangle"
        helpText="Draw a rectangle"
        iconActive={
          <MapDrawRectangleActive
            width="60%"
            height="60%"
            dataHcName={`${dataHcName}-rectangle-icon-active`}
          />
        }
        icon={
          <MapDrawRectangle
            width="60%"
            height="60%"
            dataHcName={`${dataHcName}-rectangle-icon`}
          />
        }
      />
      <DrawControlButton
        isActive={activeControl === GeometryTypesEnum.POLYGON}
        onClick={() => {
          setDrawingMode(GeometryTypesEnum.POLYGON, MeasureDrawPolygon);
        }}
        title="Draw"
        helpText="Draw a polygon"
        iconActive={
          <MapDrawPolygonActive
            width="60%"
            height="60%"
            dataHcName={`${dataHcName}-rectangle-icon-active`}
          />
        }
        icon={
          <MapDrawPolygon
            width="60%"
            height="60%"
            dataHcName={`${dataHcName}-polygon-icon`}
          />
        }
      />
      <DrawControlButton
        {...deleteEngagement}
        isActive={false}
        onClick={() => {
          setDrawingMode();
          onDelete();
        }}
        title="Delete"
        helpText="Delete shapes"
        disabled={trashDisabled}
        iconActive={true}
        icon={
          <MapDrawTrash
            width="60%"
            height="60%"
            dataHcName={`${dataHcName}-trash-icon`}
          />
        }
      />
    </div>
  );
};
