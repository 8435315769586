import React from 'react';

import { arrayToChunks } from '@hcs/utils';

import { useHcMap } from '../../hooks/useHcMap';
import { useHcMapLayerLegendBreaks } from '../../hooks/useHcMapLayerLegendBreaks';
import { useHcMapLayers } from '../../hooks/useHcMapLayers';
import { getLegendColorTableForLayer } from '../../utils/heatmap.utils';

import styles from './HcMapLayerLegend.module.css';

interface Props {
  mapId: string;
  itemsPerCol?: number;
}

const ITEMS_PER_COL = 3;
const dataHcName = 'hcmap-layers-legend';
export const HcMapLayerLegend = ({
  mapId,
  itemsPerCol = ITEMS_PER_COL,
}: Props) => {
  const { mapState } = useHcMap(mapId);
  const { vectilesMetricGroupConfig } = useHcMapLayers(mapId);
  const intervals = useHcMapLayerLegendBreaks(mapId);
  if (
    !vectilesMetricGroupConfig ||
    !mapState?.heatmap.vectilesMetricId ||
    !mapState.heatmap.vectilesMetricGroup ||
    !intervals
  ) {
    return null;
  }
  const {
    heatmap: { vectilesMetricGroup, showMonochrome },
  } = mapState;
  const legendColorTable = getLegendColorTableForLayer(
    vectilesMetricGroup,
    intervals
  );
  return (
    <div className={styles.HcMapLayerLegend} data-hc-name={dataHcName}>
      {arrayToChunks(legendColorTable, itemsPerCol).map((column, colIdx) => {
        return (
          <ul className={styles.List} key={`legend-${colIdx}`}>
            {column?.map((interval, idx) => {
              return (
                <li key={interval.color} className={styles.ListItem}>
                  {showMonochrome ? (
                    <div
                      className={styles.MonochromeIcon}
                      data-hc-name={`${dataHcName}-monochrome-icon-${idx}`}
                      style={{
                        background: `url('${interval.patternImageIcon}')`,
                      }}
                    />
                  ) : (
                    <div
                      className={styles.KeyColor}
                      style={{ backgroundColor: interval.color }}
                    />
                  )}
                  <div
                    className={styles.Text}
                    data-hc-name={`${dataHcName}-from-to-label-${idx}`}
                  >
                    {interval.label[0]} - {interval.label[1]}
                  </div>
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
};
