import React, { ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';

import { DropdownMini } from '../Dropdown';
import { SearchInputStyle } from '../SearchInputStyle';
import { Toggle } from '../Toggle';

import styles from './MultiSearch.module.css';

export interface MultiSearchInput {
  input: ReactNode;
  label: string;
  value: string;
}

export interface MultiSearchProps {
  dataHcName: string;
  dataHcEventSection?: string;
  className?: string;
  selectedInput?: string | null;
  invertedColors?: boolean;
  inputs: MultiSearchInput[];
  onToggle?: (value: string | null) => void;
}
export const MultiSearch = ({
  dataHcName,
  dataHcEventSection,
  className,
  inputs,
  selectedInput: selectedInputProp,
  invertedColors,
  onToggle,
}: MultiSearchProps) => {
  const [selectedId, setSelectedId] = useState<string | null>(
    selectedInputProp || inputs[0]?.value || null
  );
  const selectedInput = useMemo(
    () => inputs.find((s) => s.value === selectedId),
    [selectedId, inputs]
  );
  const handleChangeSearchType = (value: string | null) => {
    setSelectedId(value);
    onToggle?.(value);
  };
  return (
    <SearchInputStyle
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      className={classNames(styles.MultiSearch, className, {
        [styles.invertedColors]: invertedColors,
      })}
    >
      <div className={styles.Input}>{selectedInput?.input}</div>
      {inputs.length === 2 && (
        <div className={styles.ToggleCell}>
          <Toggle
            dataHcName={`${dataHcName}-toggle`}
            options={inputs}
            value={selectedInput?.value || null}
            onChange={handleChangeSearchType}
            theme={{
              Toggle: styles.Toggle,
            }}
          />
        </div>
      )}
      {inputs.length > 2 && (
        <>
          <div className={styles.Border} />
          <DropdownMini
            value={selectedInput?.value || null}
            theme={{
              Dropdown: styles.Dropdown,
              OptionsContainer: styles.OptionsContainer,
            }}
            dataHcName={`${dataHcName}-dropdown`}
            options={inputs}
            onSelect={handleChangeSearchType}
          />
        </>
      )}
    </SearchInputStyle>
  );
};
