import React from 'react';

import { MainNav, NavItemConfig } from '@hcs/design-system';
import { AddItemIcon, SegmentIcon } from '@hcs/design-system';
import { APP_CONFIG_PORTFOLIO, VIEW_PATHS_PORTFOLIO } from '@hcs/hc-products';

interface PortfolioNavProps {
  className?: string;
}

const dataHcName = 'portfolio-left-nav';

export const PortfolioNav = (props: PortfolioNavProps) => {
  const { className } = props;

  const NAV_ITEMS_CONFIG: NavItemConfig[] = [
    {
      label: 'All Portfolios',
      navigateTo: `${APP_CONFIG_PORTFOLIO.rootPath}`,
      end: true,
      Icon: SegmentIcon,
      dataHcName: `${dataHcName}-all-portfolios`
    },
    {
      label: 'New Portfolio',
      navigateTo: `${APP_CONFIG_PORTFOLIO.rootPath}/${VIEW_PATHS_PORTFOLIO.NEW_PORTFOLIO}`,
      Icon: AddItemIcon,
      dataHcName: `${dataHcName}-new-portfolio`
    }
  ];

  return (
    <MainNav
      dataHcName={dataHcName}
      className={className}
      navItemsConfig={NAV_ITEMS_CONFIG}
      hoverToOpen
    />
  );
};
