import React from 'react';
import { formatMoney } from 'accounting';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';
import { formatPercent } from '@hcs/utils';

import { useReportValue } from '../../hooks';
import { useForecastStats } from '../../hooks/useForecastStats';

import styles from './ForecastStats.module.css';

interface Props {
  className?: string;
  reportId: ReportId;
  forecastType: 'zip' | 'block';
}
const dataHcName = 'forecast-stats';
export const ForecastStats = ({ className, reportId, forecastType }: Props) => {
  const reportValueQuery = useReportValue(reportId);
  const forecastStatsQuery = useForecastStats(reportId);
  const combinedQuery = combineUseQueryResult([
    reportValueQuery,
    forecastStatsQuery,
  ]);
  if (!combinedQuery.isFetched) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const subjectValue = reportValueQuery.data?.selectedValue?.value.value;
  const forecastStats = forecastStatsQuery.data?.[forecastType];
  if (!forecastStats?.length || !subjectValue) {
    return null;
  }
  return (
    <div className={styles.Legend}>
      <div className={styles.LegendItem}>
        <div data-hc-name={`${dataHcName}-current-year-label`}>
          Current Value
        </div>
        <div
          data-hc-name={`${dataHcName}-current-year-value`}
          className={styles.LegendValue}
        >
          {formatMoney(subjectValue, { precision: 0 })}{' '}
        </div>
      </div>
      {forecastStats.map(({ year, price }, i) => {
        if (!year || !price) return null;
        let yearOverYearPercentDiff = null;
        if (subjectValue) {
          yearOverYearPercentDiff = (price - subjectValue) / subjectValue;
        }
        return (
          <div
            key={`${i}-${price}`}
            className={classNames(styles.LegendItem, className)}
          >
            <div data-hc-name={`${dataHcName}-current-year-label`}>
              {i + 1} Year
            </div>
            <div
              data-hc-name={`${dataHcName}-current-year-value`}
              className={styles.LegendValue}
            >
              {formatMoney(price, { precision: 0 })}{' '}
              {yearOverYearPercentDiff && (
                <span
                  data-hc-name={`${dataHcName}-current-year-value-percent-change`}
                  className={classNames(styles.PositiveYearOverYearValue, {
                    [styles.negativeYearOverYearValue]:
                      yearOverYearPercentDiff < 0,
                  })}
                >
                  {yearOverYearPercentDiff > 0
                    ? `(+${formatPercent(yearOverYearPercentDiff)})`
                    : `(${formatPercent(yearOverYearPercentDiff)})`}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
