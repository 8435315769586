import React from 'react';
import isEqual from 'lodash.isequal';

import { FilterRange, FilterRangeType } from '@hcs/design-system';
import { FormError } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompFiltersAll, CompsFiltersPaths, ReportId } from '@hcs/types';

import { FilterCard } from '../../components-deprecated';
import { QuickFilter, QuickFilterButton } from '../../components-deprecated';
import {
  useCompsFiltersDocument,
  useDocumentPatch,
  usePatchCompNumberRangeFilter,
  useSubjectDocument,
} from '../../hooks';
import { convertNumArrayToFilterRange, subjectDisplay } from '../../utils';

import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const currentYear = new Date().getFullYear();
const FIELD = PropertyStateFields.yearBuilt;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = 'comp-filter-year-built';

export const CompFilterYearBuilt = ({
  reportId,
  compType,
  className,
}: Props) => {
  const documentPatchMutation = useDocumentPatch(reportId);
  const { mutate: patchFilter } = usePatchCompNumberRangeFilter(
    reportId,
    compType
  );
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);

  if (!filterDocument || !subjectDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const filterValue = filterDocument.data.filters?.[FIELD];
  const subjectValue = getPropertyStateFieldValue(FIELD, {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument.data.propertyState,
  });

  const handleChangeRelativeValue = (
    relativeValue: CompFiltersAll[PropertyStateFields.yearBuilt]['relativeValue']
  ) => {
    documentPatchMutation.mutate({
      reportId,
      document: filterDocument,
      operations: [
        {
          op: 'add',
          path: PATH_REL,
          value: relativeValue,
        },
        {
          op: 'add',
          path: PATH_FIELD,
          value: FIELD,
        },
      ],
    });
  };

  return (
    <FilterCard
      label={fieldConfig.label}
      info={
        filterValue?.error?.field === 'absoluteValue' ? (
          <FormError
            dataHcName={`${dataHcName}-not-savable`}
            value="Cannot apply the saved filter because the subject value is missing"
          />
        ) : subjectValue === null ? (
          'Missing Subject Value'
        ) : (
          `Subject: ${subjectValue}`
        )
      }
      dataHcName={dataHcName}
      className={className}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={FIELD}
        />
      }
    >
      {subjectValue && (
        <QuickFilter dataHcName={`${dataHcName}-quick-filter`}>
          <QuickFilterButton
            label="5yrs"
            active={isEqual([-5, 5], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-btn-0`}
            onClick={() => handleChangeRelativeValue([-5, 5])}
          />
          <QuickFilterButton
            label="15yrs"
            active={isEqual([-15, 15], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-btn-1`}
            onClick={() => handleChangeRelativeValue([-15, 15])}
          />
          <QuickFilterButton
            label="25yrs"
            active={isEqual([-25, 25], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-btn-2`}
            onClick={() => handleChangeRelativeValue([-25, 25])}
          />
          <QuickFilterButton
            label="35yrs"
            active={isEqual([-35, 35], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-btn-3`}
            onClick={() => handleChangeRelativeValue([-35, 35])}
          />
        </QuickFilter>
      )}
      <FilterRange
        initialValue={convertNumArrayToFilterRange(filterValue?.absoluteValue)}
        subjectDisplay={subjectDisplay(
          false,
          convertNumArrayToFilterRange(filterValue?.relativeValue)
        )}
        onBlur={(value: FilterRangeType) =>
          patchFilter?.absoluteValue(FIELD, value)
        }
        maxVal={currentYear}
        dataHcName={`${dataHcName}-bed`}
        extraValidators={[
          (value: FilterRangeType) => {
            if (
              (value.max && parseInt(value.max) > currentYear) ||
              (value.min && parseInt(value.min) > currentYear)
            ) {
              return `Cannot set a year greater than ${currentYear}.`;
            }
            return undefined;
          },
        ]}
      />
    </FilterCard>
  );
};
