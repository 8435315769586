import React from 'react';

import { KeywordSearchListingRemarks } from '@hcs/keyword-search';
import { ReportFeatures, ReportId } from '@hcs/types';

import { useSubjectDocument } from '../../hooks';

interface Props {
  reportId: ReportId;
  className?: string;
  keywordSearchKey?: string;
  isRental?: boolean;
}
export const SUBJECT_PUBLIC_REMARKS_FEATURES = [
  ReportFeatures.SubjectListingRemarks,
];
export const SubjectPublicRemarks = ({
  reportId,
  isRental,
  ...keywordSearchProps
}: Props) => {
  const { isInitialLoading, data: subjectDocument } =
    useSubjectDocument(reportId);
  // Rental report should fallback to displaying sale listing data
  const listDate = isRental
    ? subjectDocument?.data.propertyState.complexFieldsRental
        ?.currentListDate ||
      subjectDocument?.data.propertyState.complexFieldsSale?.currentListDate
    : subjectDocument?.data.propertyState.complexFieldsSale?.currentListDate;
  const remarks = isRental
    ? subjectDocument?.data.propertyState.listingDetailsRental?.remarks ||
      subjectDocument?.data.propertyState.listingDetailsSale?.remarks
    : subjectDocument?.data.propertyState.listingDetailsSale?.remarks;
  return (
    <KeywordSearchListingRemarks
      isLoading={isInitialLoading}
      {...keywordSearchProps}
      date={listDate}
      remarks={remarks?.find((r) => r?.type === 'Public')?.value}
    />
  );
};
