import { ReactNode } from 'react';

import { MapMarkerProps, MapMarkerTheme } from '@hcs/design-system';
import {
  GeoLocation,
  ListingStatusCerberus,
  MarkerAnimationStep,
  MarkerOffset,
} from '@hcs/types';

export enum PropertyMarkerTypes {
  Property = 'property',
  Comp = 'comp',
}
export interface PropertyMarkerProps {
  type?: PropertyMarkerTypes;
  geoLocation: GeoLocation;
  label: ReactNode;
  /** Id for marker (e.g. hcAddressId) */
  markerId: string | number;
  /** Optional markerOffset argument is passed by MultiUnitMarker if it is rendering the PropertyMarker */
  onClick?: (markerOffset?: MarkerOffset) => void;
  /** Optional dataHcName, defaults to property-marker */
  dataHcName?: string;
  /** Moves marker to the top layer with z-indexing */
  moveToTop?: boolean;
  pulse?: boolean;
  offsetLeft?: number;
  addElementToRefList?: (node: HTMLElement) => void;
  offsetTop?: number;
  className?: string;
  /** Optional listing status input to render a listing status indicator in the marker */
  listingStatus?: ListingStatusCerberus | null;
  selected?: boolean;
  /**
   * An array of animation states that is walked through incrementally, every 50ms,
   * on mount
   */
  animationSteps?: MarkerAnimationStep[];
  reverseAnimationOnUnMount?: boolean;
  hoverable?: boolean;
  /** Is the feature rendered in a map context? Defaults to true */
  renderedInMap?: boolean;
  mapMarkerTheme?: MapMarkerTheme;
  shape?: MapMarkerProps['shape'];
  mapId?: string;
}
