import { BulkDataIcon } from '@hcs/design-system';
import { AppSlugs, CapabilityApplications, Roles } from '@hcs/types';
import { AppConfig } from '@hcs/types';

export const APP_CONFIG_BULK_DATA: AppConfig = {
  name: 'Bulk Data',
  appSlug: AppSlugs.BulkData,
  applicationKey: CapabilityApplications.InternalBulkData,
  rootPath: '/bulk-data',
  Icon: BulkDataIcon,
  descriptionLong: 'Manage Bulk Data Jobs and Deliveries',
  descriptionShort: 'Manage Bulk Data Jobs and Deliveries',
  requiredRole: [Roles.BulkData],
  feOnlyApp: true,
};

export enum VIEW_PATHS_BULK_DATA {
  SALES_JOB = 'sales-job',
  OWNERSHIP_JOB = 'ownership-job',
}
