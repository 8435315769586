import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../../hooks';
import { CompFilterPool } from '../../CompFiltersDeprecated/CompFilterPool';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const COMP_FIELD = PropertyStateFields.pool;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[COMP_FIELD];
const dataHcName = 'comp-filter-button-pool';
export const CompFilterButtonPool = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }

  const filterValue = filterDocument.data.filters?.[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={
        active
          ? filterValue.absoluteValue
            ? 'Has Pool'
            : 'No Pool'
          : fieldConfig.labelShort
      }
      className={className}
      compField={COMP_FIELD}
      content={<CompFilterPool reportId={reportId} compType={compType} />}
      active={active}
    />
  );
};
