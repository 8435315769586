import React from 'react';

import { ExperienceFlag } from '@hcs/experience-flags';
import { ReportId } from '@hcs/types';

import { EffectiveDateBadge } from '../../features/EffectiveDateBadge';

import styles from './ReportGlobalHeaderContent.module.css';

export const ReportGlobalHeaderContent = ({
  reportId,
}: {
  reportId: ReportId;
}) => {
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      now={
        <EffectiveDateBadge
          reportId={reportId}
          className={styles.EffectiveDateBadge}
        />
      }
    />
  );
};
