import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { DateInput } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';

import styles from './FormFields.module.css';

interface Props {
  name: string;
  label?: string;
  isLoading?: boolean;
  className?: string;
  error?: string;
  onChange?: (value: string) => void;
}

const dataHcName = 'form-fields-datestr';
export const FormFieldsDate = ({
  name,
  label,
  isLoading,
  onChange,
  error,
  className,
}: Props) => {
  const { control, clearErrors } = useFormContext();
  if (isLoading) {
    return (
      <Skeleton dataHcName={`${dataHcName}-${name}-skeleton`} type="text" />
    );
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...fieldProps } = field;
        return (
          <div className={classNames(styles.FormGroup, className)}>
            {label && <label>{label}</label>}
            <DateInput
              {...fieldProps}
              dataHcName={`${dataHcName}-${name}`}
              value={field.value || ''}
              onSetDate={(val) => {
                fieldOnChange(val);
                clearErrors(name);
                onChange?.(val || '');
              }}
              error={error}
            />
          </div>
        );
      }}
    />
  );
};
