import React from 'react';
import classNames from 'classnames';

import { ErrorIcon } from '../../../../svgs/icons/indicator';

import styles from './NullState.module.css';

export interface NullStateProps {
  icon?: React.ReactNode;
  title: string;
  dataHcName: string;
  children?: React.ReactNode;
  className?: string;
  absCenter?: boolean;
}

export const NullState = ({
  icon,
  title,
  dataHcName,
  children,
  absCenter,
  className,
}: NullStateProps) => {
  return (
    <div
      className={classNames(styles.NullState, className, {
        [styles.absCenter]: absCenter,
      })}
      data-hc-name={dataHcName}
    >
      <div data-hc-name={`${dataHcName}-icon`} className={styles.Icon}>
        {icon ? (
          icon
        ) : (
          <ErrorIcon
            dataHcName={`${dataHcName}-alert-icon`}
            color="secondary-10"
          />
        )}
      </div>
      <h3 data-hc-name={`${dataHcName}-title`} className={styles.Title}>
        {title}
      </h3>
      <div>{children}</div>
    </div>
  );
};
