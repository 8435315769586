import React, { Fragment, useMemo } from 'react';
import classNames from 'classnames';

import { Checkbox } from '@hcs/design-system';
import { Table, TableCell, TableRow } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import {
  DexpEndpoint,
  DexpEndpointLevel,
  DexpEndpointsParams,
} from '@hcs/types';

import { useDexpEndpointsTable } from '../../hooks/useDexpEndpointsTable';
import {
  dexpFixEndpointId,
} from '../../utils/endpointId.utils';

import styles from './EndpointsDataTable.module.css';

interface Props {
  endpointLevel: DexpEndpointLevel | 'all';
  filters?: DexpEndpointsParams;
  selectable?: boolean;
}

const dataHcName = 'endpoints-data-table';

export const EndpointsDataTable = ({
  endpointLevel,
  selectable = true,
  filters: filtersProp,
}: Props) => {
  const {
    state: { filters, dexpEndpointsQuery, endpointsInShoppingCart },
    actions: { addToCart, removeFromCart },
  } = useDexpEndpointsTable(filtersProp);
  const { data: endpoints, isInitialLoading } = dexpEndpointsQuery;
  const { availableEndpoints, unavailableEndpoints } = useMemo(() => {
    const filteredEndpoints: DexpEndpoint[] = [];
    const available: DexpEndpoint[] = [];
    const unavailable: DexpEndpoint[] = [];
    endpoints?.forEach((endpoint) => {
      if (endpointLevel === 'all' || endpoint.level === endpointLevel) {
        filteredEndpoints.push(endpoint);
        if (endpoint.access) {
          available.push(endpoint);
        } else {
          unavailable.push(endpoint);
        }
      }
    });
    return {
      filteredEndpoints,
      availableEndpoints: available,
      unavailableEndpoints: unavailable,
    };
  }, [endpoints, endpointLevel]);

  const getTableRows = (filteredEndpoints: DexpEndpoint[]) => {
    return filteredEndpoints?.map((endpoint, i) => {
      // Endpoint Id used for dataHcName selectors
      const fixedEndpointId = dexpFixEndpointId(endpoint);
      const isSelected =
        endpointsInShoppingCart &&
        !!endpointsInShoppingCart[endpoint.id]?.isSelected;

      return (
        <Fragment key={`endpoint-${i}`}>
          <TableRow
            key={`endpoint-row-${endpoint.id}`}
            highlightOnHover={endpoint.access}
            className={styles.TableRow}
          >
            {selectable && (
              <TableCell width={40} noBorder>
                <Checkbox
                  className={styles.EndpointCheckbox}
                  dataHcName={`${dataHcName}-checkbox-${fixedEndpointId}`}
                  disabled={!endpoint.access}
                  checked={isSelected}
                  onChange={(checked) => {
                    if (checked) {
                      addToCart(endpoint.id, endpoint.name, endpoint.level);
                    } else {
                      removeFromCart(endpoint.id);
                    }
                  }}
                  theme={{
                    checked: styles.checked,
                    Check: styles.Check,
                  }}
                />
              </TableCell>
            )}
            <TableCell width={270} noBorder>
              <b>{endpoint.name}</b>
            </TableCell>
            <TableCell noBorder>
              <div className={styles.Description}>{endpoint.description}</div>
            </TableCell>
          </TableRow>
        </Fragment>
      );
    });
  };

  const availableEndpointsTableRows = useMemo(() => {
    return getTableRows(availableEndpoints);
  }, [
    endpoints,
    filters,
    endpointsInShoppingCart,
    endpointLevel,
    availableEndpoints,
  ]);

  const unavailableEndpointsTableRows = useMemo(() => {
    return getTableRows(unavailableEndpoints);
  }, [
    endpoints,
    filters,
    endpointsInShoppingCart,
    endpointLevel,
    unavailableEndpoints,
  ]);

  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  return (
    <>
      {availableEndpoints.length > 0 && (
        <Table
          className={classNames(styles.EndpointsDataTable, {
            [styles.shoppingCartCardShowing]:
              endpointsInShoppingCart &&
              Object.keys(endpointsInShoppingCart).length > 0,
          })}
          dataHcName={`available-${dataHcName}-table`}
        >
          {availableEndpointsTableRows}
        </Table>
      )}

      {unavailableEndpoints.length > 0 && (
        <>
          <div className={styles.HeaderContentContainer}>
            <div className={styles.HeaderContent}>Additional data points</div>
            <div className={styles.SubHeaderContent}>
              Contact HouseCanary sales if you are interested in using any of
              these data points.
            </div>
          </div>
          <Table
            className={classNames(styles.EndpointsDataTable, {
              [styles.shoppingCartCardShowing]:
                endpointsInShoppingCart &&
                Object.keys(endpointsInShoppingCart).length > 0,
            })}
            dataHcName={`unavailable-${dataHcName}-table`}
          >
            {unavailableEndpointsTableRows}
          </Table>
        </>
      )}
    </>
  );
};
