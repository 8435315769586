import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { AgileProductGroupings, AppSlugs } from '@hcs/types';
import { CONTACT_SALES_URL } from '@hcs/urls';

interface RequestProductAccessPayload {
  requestedProduct: AppSlugs | AgileProductGroupings;
}
export const useRequestProductAccess = (
  navigateToSSSelectPlan?: VoidFunction
) => {
  const { mutate: trackEngagementEvent } = useTrackEngagementEvent();
  return useMutation<void, AxiosError, RequestProductAccessPayload, void>(
    async (requestAccessPayload) => {
      trackEngagementEvent({
        eventName: 'request-product-access',
        eventSpecificField: requestAccessPayload.requestedProduct,
        eventData: { ...requestAccessPayload },
      });
      if (navigateToSSSelectPlan) {
        navigateToSSSelectPlan();
        return;
      }
      /* eslint-disable-next-line security/detect-non-literal-fs-filename -- Safe as no value holds user input. Used in ProductPreviewDialog. */
      window.open(CONTACT_SALES_URL, '_blank');
    }
  );
};
