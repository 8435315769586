import { gql } from 'graphql-request';

/** PROPERTY STATE | land  */

export const FRAGMENT_LAND_CORE = gql`
  fragment LandCore on LandValue {
    valueLand
    valueLandLower
    valueLandPerSqFt
    valueLandUpper
  }
`;
