import React, { useState } from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { ReportId, SubjectValuePaths, ValuationMethod } from '@hcs/types';

import { ValueSelectorButton } from '../../components-deprecated';
import { ValueSelectorInput } from '../../components-deprecated';
import { ValueSelectorItem } from '../../components-deprecated';
import {
  VALUATION_METHOD_LABELS,
  VALUATION_METHOD_LABELS_RENTAL,
} from '../../constants';
import { useSubjectValueDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import styles from './ReportValueSelector.module.css';

interface Props {
  reportId: ReportId;
  showRentalValue?: boolean;
  onClose?: VoidFunction;
}
const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const PATH_USER_VALUE: SubjectValuePaths = `/data/${ValuationMethod.UserEntered}/value/value`;
const dataHcName = 'user-value-selector-item';
export const UserValueSelectorItem = ({
  reportId,
  showRentalValue,
  onClose,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { data: subjectValueDocument } = useSubjectValueDocument(reportId, {
    showRentalValue: !!showRentalValue,
  });
  const value = subjectValueDocument?.data.userValue.value;
  const documentPatchMutation = useDocumentPatch(reportId);
  const [inputValue, setInputValue] = useState(value ? value.toString() : '');
  const matchedValue = inputValue?.match(/\d/g)?.join('');
  if (!subjectValueDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const handleSelect = () => {
    if (subjectValueDocument) {
      documentPatchMutation.mutate(
        {
          reportId,
          document: subjectValueDocument,
          operations: [
            {
              op: 'replace',
              path: PATH_SELECTED_VALUE,
              value: ValuationMethod.UserEntered,
            },
            {
              op: 'add',
              path: PATH_USER_VALUE,
              value: matchedValue
                ? Math.round(parseFloat(inputValue.replace(/,/g, '')))
                : value,
            },
          ],
        },
        {
          onSettled: () => {
            onClose?.();
          },
        }
      );
    }
  };
  return (
    <ValueSelectorItem
      title={`${
        showRentalValue
          ? VALUATION_METHOD_LABELS_RENTAL[ValuationMethod.UserEntered]
          : VALUATION_METHOD_LABELS[ValuationMethod.UserEntered]
      }`}
      dataHcName={dataHcName}
      className={styles.AVMSelectionItem}
    >
      <ValueSelectorInput
        maxVal={subjectValueDocument.data.avm.value.valueUpper}
        minVal={subjectValueDocument.data.avm.value.valueLower}
        disabled={!reportPermissions?.isEditable}
        onChange={(val) => setInputValue(val)}
        dataHcName={`${dataHcName}-value-selector-input`}
        userValue={value?.value}
      />
      {reportPermissions?.isEditable && (
        <ValueSelectorButton
          dataHcName={`${dataHcName}-value-selector-button`}
          onClick={handleSelect}
          disabled={
            !matchedValue ||
            (subjectValueDocument.data.selectedValue ===
              ValuationMethod.UserEntered &&
              value?.toString() === matchedValue)
          }
        >
          Use for report
        </ValueSelectorButton>
      )}
    </ValueSelectorItem>
  );
};
