import { UseQueryResult } from '@tanstack/react-query';
import { Operation } from 'fast-json-patch';

import { useUrlState } from '@hcs/hooks';
import {
  OmOrder,
  OrderFilterIds,
  OrdersParams,
  OrdersTableOptions,
} from '@hcs/types';
import { PaginatedResponseData } from '@hcs/types';

import { useOrders } from './useOrders';

interface OrdersTableState {
  filters: OrdersParams;
}
const STATE_ID = 'ordersTable';

export const useOrdersTable = (): {
  state: {
    filters: OrdersParams;
    ordersQuery: UseQueryResult<PaginatedResponseData<OmOrder[]>>;
  };
  actions: {
    ordersTableChangeFilter: (filterChanges: OrdersParams) => void;
    ordersTableGoToPageNext: VoidFunction;
    ordersTableGoToPagePrev: VoidFunction;
    ordersTableSort: (columnId: OrdersTableOptions) => void;
  };
} => {
  const initialState: OrdersTableState = {
    filters: {
      [OrderFilterIds.page]: 1,
    },
  };

  const { state, actions } = useUrlState(STATE_ID, initialState);
  const ordersQuery = useOrders(state.filters);

  // operations get passed through to determine if this is a filter change
  const patchUrlStateEnhanced = (operations: Operation[]) => {
    actions.patchUrlState(operations);
  };

  return {
    state: {
      ...state,
      filters: state.filters || {},
      ordersQuery,
    },
    actions: {
      ordersTableChangeFilter: (filterChanges: OrdersParams) => {
        const operations: Operation[] = [];
        for (const field in filterChanges) {
          if (field in OrderFilterIds) {
            const filterId = field as OrderFilterIds;
            const newValue = filterChanges[filterId];
            if (newValue) {
              operations.push({
                op: 'add',
                path: `/filters/${filterId}`,
                value: newValue,
              });
            } else {
              /**
               * This is the case where all options is selected.
               * All data can be displayed by removing that filter id.
               */
              operations.push({
                op: 'remove',
                path: `/filters/${filterId}`,
              });
            }
          }
        }
        /**
         * When there are other filter changes, we should set the page filter
         * to page 1 to get correct filtered data.
         */
        if (!filterChanges[OrderFilterIds.page]) {
          operations.push({
            op: 'add',
            path: `/filters/${OrderFilterIds.page}`,
            value: 1,
          });
        }
        patchUrlStateEnhanced(operations);
      },
      ordersTableGoToPageNext: () => {
        patchUrlStateEnhanced([
          {
            op: 'add',
            path: `/filters/${OrderFilterIds.page}`,
            value: (state?.filters.page || 1) + 1,
          },
        ]);
      },
      ordersTableGoToPagePrev: () => {
        patchUrlStateEnhanced([
          {
            op: 'add',
            path: `/filters/${OrderFilterIds.page}`,
            value: (state?.filters.page || 2) - 1,
          },
        ]);
      },
      ordersTableSort: (columnId: OrdersTableOptions) => {
        patchUrlStateEnhanced([
          {
            op: 'add',
            path: `/filters/${OrderFilterIds.page}`,
            value: 1,
          },
          {
            op: 'add',
            path: `/filters/${OrderFilterIds.sortBy}`,
            value: columnId,
          },
          {
            op: 'add',
            path: `/filters/${OrderFilterIds.sortOrder}`,
            value:
              state.filters.sortBy === columnId
                ? state.filters.sortOrder === 'ASC'
                  ? 'DESC'
                  : 'ASC'
                : 'ASC',
          },
        ]);
      },
    },
  };
};
