import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { CloseButton } from '../../../controls-and-inputs/buttons/CloseButton';
import { LineClamp } from '../../text/LineClamp';

import styles from './SearchChip.module.css';

export interface SearchChipProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  label: ReactNode;
  onClose?: VoidFunction;
  onClick?: VoidFunction;
  type?: 'submit' | 'reset' | 'button';
}
export const SearchChip = ({
  dataHcName,
  className,
  label,
  onClick,
  onClose,
  type = 'button',
}: SearchChipProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SearchChip, className, {
        [styles.clickable]: !!onClick,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      <LineClamp theme={{ Trigger: styles.Trigger }} lines={1}>
        {label}
      </LineClamp>
      {onClose && (
        <CloseButton
          size={'sm'}
          type={type}
          onClick={onClose}
          dataHcName={`${dataHcName}-close-button`}
          className={styles.CloseButton}
        />
      )}
    </div>
  );
};
