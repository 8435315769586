import { useAccount } from './useAccount';

export const useCurrentOrgName = () => {
  const accountQuery = useAccount();
  if (accountQuery.isSuccess) {
    // Return account query access but change data to org object
    return {
      ...accountQuery,
      data: accountQuery.data.currentOrganization.name
    };
  } else {
    // Return account query status if data is not yet fetched
    return {
      ...accountQuery,
      data: undefined
    };
  }
};
