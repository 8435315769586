import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import styles from './OrderHint.module.css';

type OrderHintProps = {
  label: string;
  isNotification?: boolean;
  icon: React.ReactNode;
  onHintClick?: (e: MouseEvent) => void;
};

export const OrderHint = (props: OrderHintProps) => {
  const { label, isNotification, icon, onHintClick } = props;

  return (
    <div
      onClick={onHintClick}
      className={classNames(styles.Hint, {
        [styles.IsNotification]: isNotification,
      })}
    >
      <span>{label}</span>
      {icon}
    </div>
  );
};

export default OrderHint;
