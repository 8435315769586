import React from 'react';

import { RadioOption, RadioSelect } from '@hcs/design-system';
import { FormError } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import {
  CompFiltersAll,
  CompsFiltersPaths,
  FILTER_MATCH_SUBJECT,
  ReportId,
} from '@hcs/types';

import { FilterCard } from '../../components-deprecated';
import { QuickFilter, QuickFilterButton } from '../../components-deprecated';
import {
  useCompsFiltersDocument,
  useDocumentPatch,
  useReportPermissions,
  useSubjectDocument,
} from '../../hooks';

import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const OPTIONS: RadioOption<null | boolean>[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const FIELD = PropertyStateFields.pool;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const dataHcName = 'comp-filter-pool';
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];

export const CompFilterPool = ({ reportId, compType, className }: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!filterDocument || !subjectDocument || !reportPermissions) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const filterValue = filterDocument.data.filters?.[FIELD];
  const subjectValue = getPropertyStateFieldValue(FIELD, {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument.data.propertyState,
  });

  const handleChangeRelativeValue = (
    relativeValue: CompFiltersAll[PropertyStateFields.pool]['relativeValue']
  ) => {
    documentPatchMutation.mutate({
      reportId,
      document: filterDocument,
      operations: [
        {
          op: 'add',
          path: PATH_REL,
          value: relativeValue,
        },
        {
          op: 'add',
          path: PATH_FIELD,
          value: FIELD,
        },
      ],
    });
  };

  return (
    <FilterCard
      label={fieldConfig.label}
      info={
        filterValue?.error?.field === 'absoluteValue' ? (
          <FormError
            dataHcName={`${dataHcName}-not-savable`}
            value="Cannot apply the saved filter because the subject value is missing"
          />
        ) : subjectValue === null ? (
          'Missing Subject Value'
        ) : (
          `Subject: ${subjectValue ? 'Has Pool' : 'No Pool'}`
        )
      }
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={FIELD}
        />
      }
    >
      {subjectValue != null && (
        <QuickFilter dataHcName={`${dataHcName}-quick-filter`}>
          <QuickFilterButton
            label="Match to Subject"
            active={filterValue?.relativeValue === FILTER_MATCH_SUBJECT}
            dataHcName={`${dataHcName}-quick-filter-btn-0`}
            onClick={() => handleChangeRelativeValue(FILTER_MATCH_SUBJECT)}
          />
        </QuickFilter>
      )}
      <RadioSelect
        dataHcName={`${dataHcName}-select`}
        value={
          filterValue?.absoluteValue == null ? null : filterValue.absoluteValue
        }
        disabled={!reportPermissions.isEditable}
        options={OPTIONS}
        className={className}
        onChange={(value) => {
          if (value === null) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    </FilterCard>
  );
};
