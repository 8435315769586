import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import {
  OrderItemBulkUpdateResponse,
  OrderItemGroup,
  OrderItemsUpdateParams,
} from '@hcs/types';

import { OrderManagerApi } from '../api/orderManagerApi';

import { QUERY_KEY_ORDER_ITEMS } from './useOrderItems';
import { QUERY_KEY_ORDERS } from './useOrders';

export const useUpdateOrderItems = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    OrderItemBulkUpdateResponse,
    AxiosError,
    OrderItemsUpdateParams
  >(
    (payload: OrderItemsUpdateParams) =>
      OrderManagerApi.updateOrderItems(payload),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([QUERY_KEY_ORDER_ITEMS]);
        queryClient.invalidateQueries([QUERY_KEY_ORDERS]);

        const { updateType, orderItemGroup } = variables;

        const succeededItemIds = data.results.map((result) => result.id);
        const failedItemIds = data.errors.map((error) => error.id);

        let succeededMessage: string | null = null;
        let failedMessage: string | null = null;
        if (updateType === 'RECOVER_REMOVED_ITEMS') {
          succeededMessage =
            succeededItemIds.length > 0
              ? `${succeededItemIds.length} added to review. `
              : null;
          failedMessage =
            failedItemIds.length > 0
              ? `${failedItemIds.length} failed to recover.`
              : null;
        } else if (updateType === 'REMOVE_ITEMS_FROM_ORDER') {
          succeededMessage =
            succeededItemIds.length > 0
              ? `${succeededItemIds.length} removed from order. `
              : null;
          failedMessage =
            failedItemIds.length > 0
              ? `${failedItemIds.length} ${
                  failedItemIds.length === 1 ? 'remains' : 'remain'
                } in ${
                  orderItemGroup === OrderItemGroup.InOrder ? 'order' : 'review'
                }.`
              : null;
        } else if (updateType === 'ADD_ITEMS_TO_ORDER') {
          succeededMessage =
            succeededItemIds.length > 0
              ? `${succeededItemIds.length} added to order. `
              : null;
          failedMessage =
            failedItemIds.length > 0
              ? `${failedItemIds.length} ${
                  failedItemIds.length === 1 ? 'needs' : 'need'
                } further review.`
              : null;
        }

        if (failedMessage !== null) {
          toastOpen({
            type: 'warning',
            title: `${succeededMessage} ${failedMessage}`,
          });
        } else if (succeededMessage !== null) {
          toastOpen({
            type: 'success',
            title: succeededMessage,
          });
        }
      },
    }
  );
};
