import { Operation } from 'fast-json-patch';

import { ReportId, ValuationMethod } from '@hcs/types';

import { SELECTED_VALUE_PATH } from '../constants/subjectDetails.constants';
import { useDocumentPatch } from '../hooks/useDocumentPatch';
import { useSubjectDocument } from '../hooks/useSubjectDocument';
import { useSubjectValueDocument } from '../hooks/useSubjectValueDocument';

export const useRevertSubjectPropertyAdjustments = (reportId: ReportId) => {
  const subjectDocumentQuery = useSubjectDocument(reportId);
  const subjectValueDocumentQuery = useSubjectValueDocument(reportId);
  const { data: subjectDocument } = subjectDocumentQuery;
  const { data: subjectValueDocument } = subjectValueDocumentQuery;
  const documentPatchMutation = useDocumentPatch(reportId);

  return (cb?: () => void) => {
    if (
      subjectValueDocument &&
      subjectValueDocument?.data.selectedValue === ValuationMethod.Adjusted
    ) {
      documentPatchMutation.mutate({
        reportId,
        document: subjectValueDocument,
        operations: [
          {
            op: 'replace',
            path: SELECTED_VALUE_PATH,
            value: ValuationMethod.Avm,
          },
        ],
      });
    }

    const adjustments = subjectDocument?.userPropertyAdjustments?.adjustments;
    if (adjustments) {
      const operations: Operation[] = [];
      for (const keyValue of Object.entries(adjustments)) {
        const op: Operation = {
          op: 'add',
          value: keyValue[1].sourceValue,
          path: keyValue[1].path,
        };

        operations.push(op);
      }
      if (operations.length) {
        documentPatchMutation.mutate({
          reportId,
          document: subjectDocument,
          operations: operations,
        });
      }
    }

    cb && cb();
  };
};
