import React, { useEffect } from 'react';

import { LoadingSpinner } from '@hcs/design-system';

import { DATA_HC_NAME_PAGE_AUTH_SPINNER } from '../../constants';
import { useLegacyAccessToken } from '../../hooks/useLegacyAccessToken';
import { useLogout } from '../../hooks/useLogout';

export const Logout = () => {
  const logoutMutation = useLogout();
  const accessTokenQuery = useLegacyAccessToken();
  useEffect(() => {
    // Ensure the access token is not in the process of refreshing before logging out.
    if (accessTokenQuery.isSuccess && !accessTokenQuery.isFetching) {
      logoutMutation.mutate();
    }
    // mutate will likely change on every render, but we don't expect it to change in functionality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenQuery.isSuccess, accessTokenQuery.isFetching]);
  return (
    <LoadingSpinner
      dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER}
      absoluteCenter
    />
  );
};
