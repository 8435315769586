import React from 'react';
import classNames from 'classnames';

import styles from './FilterIcon.module.css';

export interface FilterIconProps {
  active?: boolean;
  dataHcName: string;
}
export const FilterIcon = ({ dataHcName, active }: FilterIconProps) => {
  const color = active ? '#FFFFFF' : '#A8A8A8';
  return (
    <svg
      height={22}
      width={22}
      className={classNames({ [styles.filled]: active })}
      data-hc-name={dataHcName}
    >
      <g className={styles.LineGroup1}>
        <path
          className={styles.Line}
          fill={color}
          d="M0.903340599,2 C0.404439362,2 0,1.55228475 0,1 C0,0.44771525 0.404439362,0 0.903340599,0 L16.0966594,0 C16.5955606,0 17,0.44771525 17,1 C17,1.55228475 16.5955606,2 16.0966594,2 L0.903340599,2 Z"
        />
        <circle
          className={styles.Circle1}
          cx={3}
          cy={3}
          r={2.75}
          stroke={color}
          strokeWidth={1.5}
        />
      </g>
      <g className={styles.LineGroup2}>
        <path
          className={styles.Line}
          fill={color}
          d="M0.903340599,2 C0.404439362,2 0,1.55228475 0,1 C0,0.44771525 0.404439362,0 0.903340599,0 L16.0966594,0 C16.5955606,0 17,0.44771525 17,1 C17,1.55228475 16.5955606,2 16.0966594,2 L0.903340599,2 Z"
        />
        <circle
          className={styles.Circle2}
          cx={3}
          cy={3}
          r={2.75}
          stroke={color}
          strokeWidth={1.5}
        />
      </g>
    </svg>
  );
};
