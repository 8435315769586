import { gql } from 'graphql-request';

// Zip Fragments
// hopefully we can add in MSA name here as well
export const FRAGMENT_ZIP_CORE = gql`
  fragment ZipCore on ZipDetails {
    zipcode
    msa
    preferredName
  }
`;

export const FRAGMENT_ZIP_GEOM = gql`
  fragment ZipGeom on ZipDetails {
    geometry
    geoLatitude
    geoLongitude
  }
`;

export const FRAGMENT_ZIP_MARKET_QUALITY = gql`
  fragment ZipMarketQuality on ZipMarketQuality {
    grade
  }
`;
