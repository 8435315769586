import React from 'react';

import { SelectButton } from '@hcs/design-system';
import {
  CompIdentifier,
  CompTypes,
  MeaningfulEventTypes,
  ReportFeatures,
  ReportId,
} from '@hcs/types';

import {
  useComp,
  useCompsSelect,
  useDocumentDelete,
  useReportPermissions,
  useSelectedCompsLimit,
} from '../../hooks';

interface Props {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  className?: string;
  onClick?: VoidFunction;
  hoverable?: boolean;
  small?: boolean;
}
const dataHcName = 'comp-select-button';
export const COMP_SELECT_BUTTON_FEATURES_SALE = [ReportFeatures.CompsSelect];
export const COMP_SELECT_BUTTON_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsSelect,
];
export const CompSelectButton = ({
  reportId,
  compIdentifier,
  className,
  hoverable,
  small,
  onClick,
}: Props) => {
  const isRental = compIdentifier.compType === CompTypes.Rental;
  const { data: compData } = useComp(reportId, compIdentifier);
  const { compSchema, documentId } = compData || {};
  const compsSelectMutation = useCompsSelect(reportId, compIdentifier.compType);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const {
    data: { hasReachedSelectedCompsLimit },
  } = useSelectedCompsLimit(reportId, compIdentifier.compType);
  const documentDeleteMutation = useDocumentDelete(reportId);
  const handleClick = () => {
    if (!reportPermissions?.isEditable) return;
    if (documentId) {
      documentDeleteMutation.mutate({ reportId, documentId });
    } else if (compSchema) {
      if (compIdentifier.type === 'listing') {
        compsSelectMutation.mutate?.({
          type: 'listing',
          listingIdentifiers: [compIdentifier.listingIdentifier],
        });
      } else if (compSchema.propertyState.hcAddressId) {
        compsSelectMutation.mutate?.({
          type: 'hcAddressId',
          hcAddressIds: [compSchema.propertyState.hcAddressId],
        });
      }
    }
    onClick?.();
  };
  if (!documentId && !reportPermissions?.isEditable) return null;
  const isDisabled =
    (hasReachedSelectedCompsLimit && !documentId) ||
    // Disable select button until comp has been created on the server
    documentId?.includes('optimistic-comp');
  return (
    <SelectButton
      className={className}
      disabled={isDisabled}
      selected={!!documentId}
      dataHcName={dataHcName}
      dataHcEventType={isDisabled ? undefined : MeaningfulEventTypes.Goal}
      dataHcEventName={
        isDisabled
          ? undefined
          : isRental
          ? documentId
            ? 'Unselect Rental Comp'
            : 'Select Rental Comp'
          : documentId
          ? 'Unselect Sale Comp'
          : 'Select Sale Comp'
      }
      onClick={handleClick}
      small={small}
      hoverText={
        hoverable
          ? {
              unselected: 'Add Comp',
              selected: reportPermissions?.isEditable
                ? 'Remove Comp'
                : 'Selected Comp',
            }
          : undefined
      }
    />
  );
};
