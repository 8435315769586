import React from 'react';

import { useAppConfigExtended } from '@hcs/auth';
import { MainNav, NavItemConfig } from '@hcs/design-system';
import { AddItemIcon, ChartIcon, MapIcon } from '@hcs/design-system';
import { APP_CONFIG_AEXP, VIEW_PATHS_AEXP } from '@hcs/hc-products';

interface AexpNavProps {
  className?: string;
}
const dataHcName = 'aexp-left-nav';
export const AexpNav = (props: AexpNavProps) => {
  const { className } = props;
  const aexpAppConfigExtended = useAppConfigExtended(APP_CONFIG_AEXP.appSlug);

  let NAV_ITEMS_CONFIG: NavItemConfig[] = [];
  if (aexpAppConfigExtended?.hasAccess) {
    NAV_ITEMS_CONFIG = [
      {
        label: 'Buy Boxes',
        navigateTo: `${APP_CONFIG_AEXP.rootPath}/${VIEW_PATHS_AEXP.BUY_BOXES}`,
        Icon: AddItemIcon,
        dataHcName: `${dataHcName}-buy-box`,
      },
      {
        label: 'Interactive Map',
        navigateTo: `${APP_CONFIG_AEXP.rootPath}/${VIEW_PATHS_AEXP.MAP}`,
        Icon: MapIcon,
        dataHcName: `${dataHcName}-map`,
      },
      {
        label: 'Activity',
        navigateTo: `${APP_CONFIG_AEXP.rootPath}/${VIEW_PATHS_AEXP.ACTIVITY}`,
        Icon: ChartIcon,
        dataHcName: `${dataHcName}-activity`,
      },
    ];
  }

  // leaving this commented out code here as an example
  // development mode nav items
  // if (process.env.NODE_ENV === 'development') {
  //   NAV_ITEMS_CONFIG.push({
  //     label: 'Activity',
  //     navigateTo: VIEW_PATHS_AEXP.ACTIVITY_MONITOR,
  //     Icon: TimeIcon,
  //     dataHcName: `${dataHcName}-activity-nav-item`
  //   });
  // }

  return (
    <MainNav
      dataHcName={dataHcName}
      className={className}
      navItemsConfig={NAV_ITEMS_CONFIG}
      hoverToOpen
    />
  );
};
