import { useMemo } from 'react';

import { CompTypes } from '@hcs/types';
import {
  CompsFarmDocument,
  DocumentRoles,
  RentalCompsFarmDocument,
  ReportId,
} from '@hcs/types';

import { useCompsFarmAdjustments } from '../hooks/useCompsFarmAdjustments';

import { useSingleDocumentRole } from '.';

export const useCompsFarmDocument = (
  reportId: ReportId,
  compType: CompTypes
) => {
  const documentRole =
    compType === CompTypes.Rental
      ? DocumentRoles.RentalCompsFarm
      : DocumentRoles.CompsFarm;
  const compsFarmQuery = useSingleDocumentRole<
    CompsFarmDocument | RentalCompsFarmDocument
  >(reportId, documentRole);
  const { data: userPropertyAdjustments } = useCompsFarmAdjustments(
    reportId,
    documentRole
  );

  const data = useMemo(() => {
    return compsFarmQuery.data
      ? {
          ...compsFarmQuery.data,
          userPropertyAdjustments: userPropertyAdjustments || undefined,
        }
      : undefined;
  }, [compsFarmQuery.data, userPropertyAdjustments]);

  return {
    // Do not combine query. Requesting adjustments should be done in the bg
    ...compsFarmQuery,
    data,
  };
};
