// import { useCallback, useMemo } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OmOrder } from '@hcs/types';

import { OrderManagerApi } from '../api/orderManagerApi';

export const QUERY_KEY_ORDER = 'order';
export const useOrder = (
  orderId: number,
  options?: UseQueryOptions<
    OmOrder,
    AxiosError,
    OmOrder,
    [typeof QUERY_KEY_ORDER, number]
  >
) => {
  return useQuery(
    [QUERY_KEY_ORDER, orderId],
    () => OrderManagerApi.fetchOrder(orderId),
    options
  );
};
