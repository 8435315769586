import React from 'react';
import { createPortal } from 'react-dom';

import { useRerender } from '@hcs/hooks';
import { HcsDevToolGroupType } from '@hcs/types';

import { HCS_DEV_TOOLS_QUERY_SELECTOR } from '../../constants';
import { useHcsDevToolsEnabled } from '../../hooks/useHcsDevToolsEnabled';
import { HcsDevToolsGroup } from '../HcsDevTools/HcsDevToolsGroup';

export interface HcsDevToolsPortalProps<T> {
  groups: HcsDevToolGroupType<T>[];
}

export const HcsDevToolsPortal = <T extends string | number | boolean | null>({
  groups
}: HcsDevToolsPortalProps<T>) => {
  const portalElm = document.querySelector<HTMLDivElement>(
    HCS_DEV_TOOLS_QUERY_SELECTOR
  );
  const { data: hcsDevToolsEnabled } = useHcsDevToolsEnabled();
  // Forcing rerender with this settings will ensure the devtool is rendered
  // anytime the portal is rendered, no matter the component hierarchy
  useRerender({
    shouldRerender: !portalElm,
    max: 2,
    deps: [hcsDevToolsEnabled]
  });
  if (!portalElm || !hcsDevToolsEnabled) {
    return null;
  }
  return createPortal(
    <>
      {groups.map((group, g) => {
        return <HcsDevToolsGroup key={`devtool-group-${g}`} {...group} />;
      })}
    </>,
    portalElm
  );
};
