import React, { ReactNode, useMemo } from 'react';

import { CircleButton } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { SavedFiltersIcon, UnselectedFiltersIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';

import { useDexpEndpointsTable } from '../../hooks/useDexpEndpointsTable';
import {
  dataHcEventSectionDexpTemplateForm,
  SaveAnalysisTemplateProps,
} from '../SaveAnalysisTemplate/SaveAnalysisTemplate';
import { SaveAnalysisTemplateDialog } from '../SaveAnalysisTemplate/SaveAnalysisTemplateDialog';

export interface SaveAnalysisTemplateLauncherProps
  extends SaveAnalysisTemplateProps {
  theme?: Partial<{ Trigger: string }>;
  trigger?: ReactNode;
  disabled?: boolean;
}
const dataHcName = 'save-analysis-template-launcher';
export const SaveAnalysisTemplateLauncher = ({
  trigger,
  theme,
  disabled: disabledProp,
  ...props
}: SaveAnalysisTemplateLauncherProps) => {
  const { active, handleClose, handleOpen } = useActiveState();
  const {
    state: { selectedDexpTemplate, endpointsInShoppingCart },
  } = useDexpEndpointsTable();
  const endpointsToSave = useMemo(() => {
    let endpointsToSave = 0;
    Object.entries(endpointsInShoppingCart).forEach((entry) => {
      if (entry[1]) {
        endpointsToSave++;
      }
    });
    return endpointsToSave;
  }, [endpointsInShoppingCart]);
  const disabled = disabledProp || !endpointsToSave;
  return (
    <>
      {trigger ? (
        <span
          data-hc-name={`${dataHcName}-trigger`}
          data-hc-event-section={dataHcEventSectionDexpTemplateForm}
          onClick={!disabled ? handleOpen : undefined}
        >
          {trigger}
        </span>
      ) : (
        <CircleButton
          className={theme?.Trigger}
          dataHcName={`${dataHcName}-template-save-button`}
          dataHcEventSection={dataHcEventSectionDexpTemplateForm}
          onClick={!disabled ? handleOpen : undefined}
          disabled={disabled}
          size="md"
        >
          <Tooltip
            dataHcName={`${dataHcName}-template-tooltip`}
            trigger={
              selectedDexpTemplate ? (
                <SavedFiltersIcon size="lg" color="primary-10" />
              ) : (
                <UnselectedFiltersIcon size="lg" color="primary-10" />
              )
            }
            label={
              selectedDexpTemplate
                ? selectedDexpTemplate.name
                : endpointsToSave
                ? 'Save these endpoints as an analysis template. The template will only be available to your user.'
                : 'Select endpoints to save a new analysis template.'
            }
          />
        </CircleButton>
      )}
      <SaveAnalysisTemplateDialog
        active={active}
        onClose={handleClose}
        {...props}
      />
    </>
  );
};
