import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { ErrorIcon } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import {
  CompsListTypes,
  CompsListViewType,
  NavigateToPropertyPreviewFn,
  NavigateToReportFn,
} from '@hcs/types';
import { CompSchema } from '@hcs/types';

import { useCompsList } from '../../hooks/useCompsList';

import { CompsListCompare } from './CompsListCompare/CompsListCompare';
import { CompsListCards } from './CompsListCards';
import { CompsListFooter, CompsListFooterProps } from './CompsListFooter';
import {
  COMPS_LIST_HEADER_ACTIONS_PORTAL_ID,
  CompsListHeader,
} from './CompsListHeader';
import { CompsListTable } from './CompsListTable';

import styles from './CompsList.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  dataHcEventSection?: string;
  compsListType: CompsListTypes;
  isAdjustable?: boolean;
  className?: string;
  showDisclaimer?: boolean;
  dataHcEventsSection?: string;
  onMouseEnter?: (hovered: {
    compSchema: CompSchema;
    documentId: string | undefined;
  }) => void;
  /** ID of portal for rendering Comp Compare adjustment actions */
  compareActionsPortalIdRender?: string;
  primaryCta?: CompsListFooterProps['primaryCta'];
  includeHeader?: boolean;
  onMouseLeave?: VoidFunction;
  onChangeListViewType?: (listViewType: CompsListViewType) => void;
  onChangeIsAdjusting?: (isAdjusting: boolean) => void;
  navigateToReport: NavigateToReportFn;
  navigateToPropertyPreview: NavigateToPropertyPreviewFn;
}
const dataHcName = 'comps-list';
export const CompsList = ({
  reportId,
  className,
  isAdjustable = true,
  compType,
  dataHcEventSection,
  compsListType,
  primaryCta,
  compareActionsPortalIdRender,
  showDisclaimer,
  includeHeader = true,
  navigateToPropertyPreview,
  navigateToReport,
  onChangeListViewType,
  onChangeIsAdjusting,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const {
    state: { listViewType, compsListQuery },
  } = useCompsList({ reportId, compType, compsListType });
  const [isAdjusting, setIsAdjusting] = useState(false);
  const handleChangeIsAdjusting = (newValue: boolean) => {
    setIsAdjusting(newValue);
    onChangeIsAdjusting?.(newValue);
  };
  useEffect(() => {
    onChangeListViewType?.(listViewType);
  }, [onChangeListViewType, listViewType]);
  return (
    <section
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={classNames(styles.CompsList, className)}
    >
      <div className={styles.FlexScroll}>
        {includeHeader && !isAdjusting && (
          <CompsListHeader
            reportId={reportId}
            dataHcEventSection={dataHcEventSection}
            compType={compType}
            compsListType={compsListType}
            hideControls={isAdjusting}
            navigateToReport={navigateToReport}
          />
        )}
        {compsListQuery.isInitialLoading ? (
          <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />
        ) : compsListQuery.data?.length ? (
          <>
            {listViewType === CompsListViewType.Card && (
              <CompsListCards
                reportId={reportId}
                compType={compType}
                compsListType={compsListType}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                showDisclaimer={showDisclaimer}
              />
            )}
            {listViewType === CompsListViewType.Table && (
              <CompsListTable
                reportId={reportId}
                compType={compType}
                compsListType={compsListType}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                showDisclaimer={showDisclaimer}
              />
            )}
            {listViewType === CompsListViewType.Compare && (
              <CompsListCompare
                reportId={reportId}
                compType={compType}
                compsListType={compsListType}
                isAdjustable={isAdjustable}
                onChangeIsAdjusting={handleChangeIsAdjusting}
                className={styles.Scroll}
                actionsPortalIdRender={
                  compareActionsPortalIdRender ||
                  COMPS_LIST_HEADER_ACTIONS_PORTAL_ID
                }
                navigateToPropertyPreview={navigateToPropertyPreview}
              />
            )}
          </>
        ) : (
          <div data-hc-name={`${dataHcName}-no-comp`} className={styles.Null}>
            <div className={styles.NullMessage}>
              <ErrorIcon
                className={styles.AlertIcon}
                dataHcName={`${dataHcName}-no-comp`}
              />
              <h3
                className={styles.NullTitle}
                data-hc-name={`${dataHcName}-no-comp-title`}
              >
                {`Sorry we weren't able to find any comps.`}
              </h3>
              <div data-hc-name={`${dataHcName}-no-comp-sub-title`}>
                Adjust filters to find more homes
              </div>
            </div>
          </div>
        )}
      </div>
      {!isAdjusting && (
        <CompsListFooter
          reportId={reportId}
          compType={compType}
          compsListType={compsListType}
          primaryCta={primaryCta}
        />
      )}
    </section>
  );
};
