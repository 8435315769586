import { Operation } from 'fast-json-patch';

import { DateStr } from '../Date.types';
import { ReportId } from '../report-api';
import {
  CompFarmUserPropertyAdjustments,
  UserPropertyAdjustments,
} from '../report-api/PropertyAdjustments.types';
import { AppraisalInputSchema } from '../report-api/schemas/AppraisalInputSchema.types';
import { AvmBreakdownSchema } from '../report-api/schemas/AvmBreakdownSchema.types';
import { RiskSchema } from '../report-api/schemas/RiskSchema.types';

import { ForecastChartSchema } from './schemas/ForecastChartSchema.types';
import { InspectionSchema } from './schemas/InspectionSchema.types';
import { MarketAnalysisSchema } from './schemas/MarketAnalysisSchema.types';
import { MarketChartSchema } from './schemas/MarketChartSchema.types';
import { PhotosSchema } from './schemas/PhotosSchema.types';
import { TransactionHistorySchema } from './schemas/TransactionHistorySchema.types';
import { PublicLinkCreateResponse } from './Report.types';
import {
  AppraisalXmlSchema,
  CommentSchema,
  ComparableValueSchema,
  CompSchema,
  CompsFarmSchema,
  CompsFilteredSchema,
  CompsFiltersSchema,
  NearbyChartSchema,
  PropertyStateSourcesSchema,
  SaleToListPriceRatioChartSchema,
  Schema,
  SchemaIds,
  SimilarPropertiesChartSchema,
  SubjectFloodSchema,
  SubjectSchema,
  SubjectValueSchema,
} from './schemas';

export enum DocumentRoles {
  SubjectValue = 'subject_value',
  Comp = 'comp',
  SimilarPropertiesChart = 'similar_properties_chart',
  SimilarPropertiesChartFiltered = 'similar_properties_chart_filtered',
  CompsFarm = 'comps_farm',
  NearbyFarm = 'nearby_properties_chart',
  NearbyFiltered = 'nearby_properties_chart_filtered',
  CompsFilters = 'comps_filters',
  CompsFiltered = 'comps_filtered',
  Subject = 'subject',
  SubjectDataSources = 'subject_data_sources',
  AvmBreakdown = 'avm_breakdown',
  ForecastChartZip = 'forecast_chart_zip',
  ForecastChartBlock = 'forecast_chart_block',
  ReconciliationComments = 'reconciliation_comments',
  // Market Documents
  MarketAnalysis = 'market_analysis',
  DaysOnMarketChart = 'days_on_market_chart',
  MonthsOfSupplyChart = 'months_of_supply_chart',
  // Rental Report Documents
  RentalSubjectValue = 'rental_subject_value',
  RentalComp = 'rental_comp',
  RentalCompsFarm = 'rental_comps_farm',
  RentalCompsFilters = 'rental_comps_filters',
  RentalCompsFiltered = 'rental_comps_filtered',
  // Appraisal Documents
  AppraisalComparison = 'appraisal_comparison',
  AppraisalInput = 'appraisal_input',
  AppraisalComp = 'appraisal_comp',
  AppraisalCompPhotos = 'appraisal_comp_photos',
  AppraisalCompDataSources = 'appraisal_comp_data_sources',
  AppraisalCompTransactionHistory = 'appraisal_comp_transaction_history',
  AppraisalSubject = 'appraisal_subject',
  AppraisalSubjectValue = 'appraisal_subject_value',
  AppraisalXml = 'appraisal_xml',
  // Transient Documents
  InspectionPhotos = 'inspection_photos',
  SubjectPhotos = 'subject_photos',
  SubjectTransactionHistory = 'subject_transaction_history',
  CompValueHcSuggested = 'comparable_value_hc_suggested',
  CompPhotos = 'comp_photos',
  CompTransactionHistory = 'comp_transaction_history',
  CompsFarmPhotos = 'comps_farm_photos',
  RentalCompsFarmPhotos = 'rental_comps_farm_photos',
  CompsFarmTransactionHistory = 'comps_farm_transaction_history',
  RentalCompsFarmTransactionHistory = 'rental_comps_farm_transaction_history',
  HcSuggestedComp = 'hc_suggested_comp',
  HcSuggestedCompPhotos = 'hc_suggested_comp_photos',
  RentalHcSuggestedComp = 'hc_suggested_rental_comp',
  ActiveTop4Comp = 'active_top_4_comp',
  ClosedTop4Comp = 'closed_top_4_comp',
  ActiveTop4CompPhotos = 'active_top_4_comp_photos',
  ClosedTop4CompPhotos = 'closed_top_4_comp_photos',
  SaleToListPriceRatioChart = 'sale_to_list_price_ratio_chart',
  PricingMarketStrategyComments = 'pricing_market_strategy_comments',
  SubjectFlood = 'subject_flood',
  Inspection = 'inspection',
}

export interface ReportApiDocumentQueryParams {
  role?: DocumentRoles;
  includeUserPropertyAdjustments?: boolean;
}

export type ReportApiDocumentQueryParamsDocumentRole = Omit<
  ReportApiDocumentQueryParams,
  'role'
>;

export interface DocumentBase {
  createdAt: string;
  deletedAt?: string | null;
  documentId: string;
  id?: number;
  role: DocumentRoles;
  schemaId: SchemaIds;
  updatedAt: string | null;
  dataRetrievedAt?: string;
  metadata?: {
    activeStatus: 'active' | 'expired';
    creationDate: DateStr;
    expirationDate: DateStr;
    editable: boolean;
    intraOrgReportAccess: 'editable' | 'visible' | 'owner-only';
    revisionID: number;
    publicLinkUid: PublicLinkCreateResponse['publicLinkUid'] | null;
    tagOptions?: string[] | null;
    rentalTagOptions?: string[] | null;
  };
  data: Schema | null;
  revisionID?: number;
}

export interface AppraisalCompDataSourcesDocument extends DocumentBase {
  role: DocumentRoles.AppraisalCompDataSources;
  schemaId: SchemaIds.PropertyStateSources;
  data: PropertyStateSourcesSchema;
}

export interface AppraisalCompTransactionHistory extends DocumentBase {
  role: DocumentRoles.AppraisalCompTransactionHistory;
  schemaId: SchemaIds.TransactionHistory;
  data: TransactionHistorySchema;
}

export interface AppraisalInputDocument extends DocumentBase {
  role: DocumentRoles.AppraisalInput;
  schemaId: SchemaIds.AppraisalInput;
  data: AppraisalInputSchema;
}

export interface AppraisalXmlDocument extends DocumentBase {
  role: DocumentRoles.AppraisalXml;
  schemaId: SchemaIds.AppraisalXml;
  data: AppraisalXmlSchema;
}

export interface SubjectDocument extends DocumentBase {
  hcAddressId: number;
  // Subject
  role: DocumentRoles.Subject;
  schemaId: SchemaIds.Subject;
  data: SubjectSchema;
  userPropertyAdjustments?: UserPropertyAdjustments;
}

export interface AppraisalSubjectDocument extends DocumentBase {
  hcAddressId: number;
  // Subject
  role: DocumentRoles.AppraisalSubject;
  schemaId: SchemaIds.Subject;
  data: SubjectSchema;
}

export interface BiasRiskDocument extends DocumentBase {
  hcAddressId: number;
  // Subject
  role: DocumentRoles.AppraisalComparison;
  schemaId: SchemaIds.BiasRisk;
  data: RiskSchema;
}

export interface AvmBreakdownDocument extends DocumentBase {
  role: DocumentRoles.AvmBreakdown;
  schemaId: SchemaIds.AvmBreakdown;
  data: AvmBreakdownSchema;
}

// Charts
export interface NearbyFarmDocument extends DocumentBase {
  role: DocumentRoles.NearbyFarm;
  schemaId: SchemaIds.NearbyFarm;
  data: NearbyChartSchema;
}

export interface NearbyFilteredDocument extends DocumentBase {
  role: DocumentRoles.NearbyFiltered;
  schemaId: SchemaIds.NearbyFarm;
  data: NearbyChartSchema;
}

export interface SimilarPropertiesChartDocument extends DocumentBase {
  role: DocumentRoles.SimilarPropertiesChart;
  schemaId: SchemaIds.SimilarPropertiesChart;
  data: SimilarPropertiesChartSchema;
}

export interface SimilarPropertiesChartFilteredDocument extends DocumentBase {
  role: DocumentRoles.SimilarPropertiesChartFiltered;
  schema: SchemaIds.SimilarPropertiesChart;
  data: SimilarPropertiesChartSchema;
}

export interface ForecastChartZipDocument extends DocumentBase {
  role: DocumentRoles.ForecastChartZip;
  schemaId: SchemaIds.ForecastChart;
  data: ForecastChartSchema | null;
}

export interface ForecastChartBlockDocument extends DocumentBase {
  role: DocumentRoles.ForecastChartBlock;
  schemaId: SchemaIds.ForecastChart;
  data: ForecastChartSchema | null;
}

export interface MarketAnalysisDocument extends DocumentBase {
  role: DocumentRoles.MarketAnalysis;
  schemaId: SchemaIds.MarketAnalysis;
  data: MarketAnalysisSchema | null;
}

export interface MonthsOfSupplyChartDocument extends DocumentBase {
  role: DocumentRoles.MonthsOfSupplyChart;
  schemaId: SchemaIds.MarketChart;
  data: MarketChartSchema | null;
}

export interface DaysOnMarketChartDocument extends DocumentBase {
  role: DocumentRoles.DaysOnMarketChart;
  schemaId: SchemaIds.MarketChart;
  data: MarketChartSchema | null;
}

// Sold Value + Comps
export interface SubjectValueDocument extends DocumentBase {
  role: DocumentRoles.SubjectValue;
  schemaId: SchemaIds.SubjectValue;
  data: SubjectValueSchema;
}

export interface AppraisalSubjectValueDocument extends DocumentBase {
  role: DocumentRoles.AppraisalSubjectValue;
  schemaId: SchemaIds.SubjectValue;
  data: SubjectValueSchema;
}

export interface CompsFarmDocument extends DocumentBase {
  role: DocumentRoles.CompsFarm;
  schemaId: SchemaIds.CompsFarm;
  data: CompsFarmSchema;
  userPropertyAdjustments?: CompFarmUserPropertyAdjustments;
}

export interface CompsFiltersDocument extends DocumentBase {
  role: DocumentRoles.CompsFilters;
  schemaId: SchemaIds.CompsFilters;
  data: CompsFiltersSchema;
}

export interface CompsFilteredDocument extends DocumentBase {
  role: DocumentRoles.CompsFiltered;
  schemaId: SchemaIds.CompsFiltered;
  data: CompsFilteredSchema;
}

export interface CompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.Comp;
  schemaId: SchemaIds.Comp;
  tagsForAddress?: string[] | null;
  data: CompSchema;
  userPropertyAdjustments?: UserPropertyAdjustments;
}

export interface AppraisalCompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.AppraisalComp;
  schemaId: SchemaIds.Comp;
  data: CompSchema;
  tagsForAddress?: string[] | null;
  userPropertyAdjustments?: UserPropertyAdjustments;
}

export interface ActiveTop4CompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.ActiveTop4Comp;
  schemaId: SchemaIds.Comp;
  data: CompSchema;
}

export interface ClosedTop4CompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.ClosedTop4Comp;
  schemaId: SchemaIds.Comp;
  data: CompSchema;
}

export interface HcSuggestedCompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.HcSuggestedComp;
  schemaId: SchemaIds.Comp;
  data: CompSchema;
}

export interface RentalHcSuggestedCompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.RentalHcSuggestedComp;
  schemaId: SchemaIds.Comp;
  data: CompSchema;
}

export interface HcSuggestedCompPhotosDocument extends DocumentBase {
  role: DocumentRoles.HcSuggestedCompPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface ActiveTop4CompPhotosDocument extends DocumentBase {
  role: DocumentRoles.ActiveTop4CompPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface ClosedTop4CompPhotosDocument extends DocumentBase {
  role: DocumentRoles.ClosedTop4CompPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

// Rental Value + Rental Comps
export interface RentalSubjectValueDocument extends DocumentBase {
  role: DocumentRoles.RentalSubjectValue;
  schemaId: SchemaIds.SubjectValue;
  data: SubjectValueSchema;
}

export interface RentalCompsFarmDocument extends DocumentBase {
  role: DocumentRoles.RentalCompsFarm;
  schemaId: SchemaIds.CompsFarm;
  data: CompsFarmSchema;
  userPropertyAdjustments?: CompFarmUserPropertyAdjustments;
}

export interface RentalCompsFiltersDocument extends DocumentBase {
  role: DocumentRoles.RentalCompsFilters;
  schemaId: SchemaIds.CompsFilters;
  data: CompsFiltersSchema;
}

export interface RentalCompsFilteredDocument extends DocumentBase {
  role: DocumentRoles.RentalCompsFiltered;
  schemaId: SchemaIds.CompsFiltered;
  data: CompsFilteredSchema;
}

export interface RentalCompDocument extends DocumentBase {
  hcAddressId: number;
  role: DocumentRoles.RentalComp;
  schemaId: SchemaIds.Comp;
  tagsForAddress?: string[] | null;
  data: CompSchema;
  userPropertyAdjustments?: UserPropertyAdjustments;
}

export interface SubjectPhotosDocument extends DocumentBase {
  role: DocumentRoles.SubjectPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface InspectionPhotosDocument extends DocumentBase {
  role: DocumentRoles.InspectionPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface CompPhotosDocument extends DocumentBase {
  role: DocumentRoles.CompPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface AppraisalCompPhotosDocument extends DocumentBase {
  role: DocumentRoles.AppraisalCompPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface CompsFarmPhotosDocument extends DocumentBase {
  role: DocumentRoles.CompsFarmPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface RentalCompsFarmPhotosDocument extends DocumentBase {
  hcAddressId: number;
  compID: string;
  role: DocumentRoles.RentalCompsFarmPhotos;
  schemaId: SchemaIds.Photos;
  data: PhotosSchema;
}

export interface SubjectTransactionHistoryDocument extends DocumentBase {
  role: DocumentRoles.SubjectTransactionHistory;
  schemaId: SchemaIds.TransactionHistory;
  data: TransactionHistorySchema;
}

export interface CompTransactionHistoryDocument extends DocumentBase {
  role: DocumentRoles.CompTransactionHistory;
  schemaId: SchemaIds.TransactionHistory;
  data: TransactionHistorySchema;
}

export interface CompsFarmTransactionHistoryDocument extends DocumentBase {
  role: DocumentRoles.CompsFarmTransactionHistory;
  schemaId: SchemaIds.TransactionHistory;
  data: TransactionHistorySchema;
}

export interface RentalCompsFarmTransactionHistoryDocument
  extends DocumentBase {
  role: DocumentRoles.RentalCompsFarmTransactionHistory;
  schemaId: SchemaIds.TransactionHistory;
  data: TransactionHistorySchema;
}

export interface SubjectDataSourcesDocument extends DocumentBase {
  role: DocumentRoles.SubjectDataSources;
  schemaId: SchemaIds.PropertyStateSources;
  data: PropertyStateSourcesSchema;
}

export interface CompValueHcSuggestedDocument extends DocumentBase {
  role: DocumentRoles.CompValueHcSuggested;
  schemaId: SchemaIds.ComparableValue;
  data: ComparableValueSchema;
}

export interface PricingMarketStrategyCommentsDocument extends DocumentBase {
  role: DocumentRoles.PricingMarketStrategyComments;
  schemaId: SchemaIds.Comment;
  data: CommentSchema;
}

export interface SubjectFloodDocument extends DocumentBase {
  role: DocumentRoles.SubjectFlood;
  schemaId: SchemaIds.SubjectFlood;
  data: SubjectFloodSchema;
}

export interface ReconciliationCommentsDocument extends DocumentBase {
  role: DocumentRoles.ReconciliationComments;
  schemaId: SchemaIds.Comment;
  data: CommentSchema;
}
export interface InspectionDocument extends DocumentBase {
  role: DocumentRoles.Inspection;
  schemaId: SchemaIds.Inspection;
  data: InspectionSchema;
}

export interface SaleToListPriceRatioChartDocument extends DocumentBase {
  role: DocumentRoles.SaleToListPriceRatioChart;
  schemaId: SchemaIds.SaleToListPriceRatioChart;
  data: SaleToListPriceRatioChartSchema;
}

export type ReportApiDocument =
  | AvmBreakdownDocument
  | AppraisalCompDataSourcesDocument
  | SubjectDocument
  | SubjectDataSourcesDocument
  | NearbyFarmDocument //this is data used for nearby comp chart
  | NearbyFilteredDocument
  | SimilarPropertiesChartDocument
  | SimilarPropertiesChartFilteredDocument
  | ForecastChartZipDocument
  | ForecastChartBlockDocument
  | MonthsOfSupplyChartDocument
  | DaysOnMarketChartDocument
  | MarketAnalysisDocument
  | ReconciliationCommentsDocument
  // Sold Value + Comps
  | SubjectValueDocument
  | CompDocument
  | CompsFarmDocument
  | CompsFiltersDocument
  | CompsFilteredDocument
  // Rental Value + Comps
  | RentalSubjectValueDocument
  | RentalCompDocument
  | RentalCompsFarmDocument
  | RentalCompsFiltersDocument
  | RentalCompsFilteredDocument
  // Appraisal
  | AppraisalInputDocument
  | AppraisalCompTransactionHistory
  | AppraisalCompDocument
  | AppraisalCompPhotosDocument
  | AppraisalSubjectDocument
  | AppraisalSubjectValueDocument
  | AppraisalXmlDocument
  | BiasRiskDocument
  // Transient Documents
  | SubjectPhotosDocument
  | InspectionPhotosDocument
  | CompPhotosDocument
  | CompsFarmPhotosDocument
  | RentalCompsFarmPhotosDocument
  | SubjectTransactionHistoryDocument
  | CompTransactionHistoryDocument
  | HcSuggestedCompDocument
  | HcSuggestedCompPhotosDocument
  | RentalHcSuggestedCompDocument
  | CompsFarmTransactionHistoryDocument
  | RentalCompsFarmTransactionHistoryDocument
  | CompValueHcSuggestedDocument
  | PricingMarketStrategyCommentsDocument
  | ActiveTop4CompDocument
  | ClosedTop4CompDocument
  | ActiveTop4CompPhotosDocument
  | ClosedTop4CompPhotosDocument
  | SubjectFloodDocument
  | SaleToListPriceRatioChartDocument
  | InspectionDocument;

export interface PatchDocumentArg {
  reportId: ReportId;
  document: ReportApiDocument;
  operations: Operation[];
}

export interface DeleteDocumentArg {
  reportId: ReportId;
  documentId: string;
}

export interface GetTagsArg {
  reportId: ReportId;
  documentId?: string;
}

export interface MutateTagsArg extends GetTagsArg {
  tags: string[];
}
