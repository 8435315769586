import { MutableRefObject, useEffect, useRef } from 'react';

export const usePrevious = <T,>(value: T): T | undefined => {
  const ref: MutableRefObject<T | undefined> = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
