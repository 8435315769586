import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './SingleColumnContent.module.css';

export interface SingleColumnContentProps {
  children: ReactNode;
  className?: string;
  horizontalPadding?: boolean;
}
export const SingleColumnContent = ({
  children,
  className,
  horizontalPadding = true,
}: SingleColumnContentProps) => {
  return (
    <div
      className={classNames(styles.SingleColumnContent, className, {
        [styles.noPaddingHorz]: !horizontalPadding,
      })}
    >
      {children}
    </div>
  );
};
