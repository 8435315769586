import { degreesToRadians, radiansToDegrees } from '@hcs/utils';

export const loadMapApi = (apiKey: string) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  // eslint-disable-next-line
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
  script.async = true;
  document.body.append(script);
  return script;
};

interface Props {
  lat: number | undefined;
  lng: number | undefined;
}

export const getHeading = (start: Props, end: Props) => {
  const lng1 = degreesToRadians(start.lng);
  const lng2 = degreesToRadians(end.lng);
  const lat1 = degreesToRadians(start.lat);
  const lat2 = degreesToRadians(end.lat);
  if (lng1 && lng2 && lat1 && lat2) {
    const a = Math.sin(lng2 - lng1) * Math.cos(lat2);
    const b =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
    return radiansToDegrees(Math.atan2(a, b));
  } else {
    return undefined;
  }
};
