import React, { ReactNode } from 'react';

import { IconButton } from '@hcs/design-system';
import { Dialog, DIALOG_ACTIONS_PORTAL_ID } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { ReportId } from '@hcs/types';

import { useReportPermissions, useSubjectDocument } from '../../hooks';

import {
  dataHcEventSectionSubjectComment,
  SUBJECT_COMMENT_FEATURES,
  SubjectComment,
  SubjectCommentIcon,
} from '.';

interface Props {
  reportId: ReportId;
  trigger?: ReactNode;
}
const dataHcName = 'subject-comment-dialog';
export const SUBJECT_COMMENT_LAUNCHER_FEATURES = SUBJECT_COMMENT_FEATURES;
export const SubjectCommentDialog = ({ reportId, trigger }: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const { data: reportPermissions } = useReportPermissions(reportId);
  if (!reportPermissions?.isEditable && !subjectDocument?.data.comments) {
    return null;
  }
  return (
    <>
      {trigger ? (
        <span
          data-hc-name={`${dataHcName}-button`}
          data-hc-event-section={dataHcEventSectionSubjectComment}
          onClick={handleOpen}
        >
          {trigger}
        </span>
      ) : (
        <IconButton
          dataHcName={`${dataHcName}-button-icon`}
          dataHcEventSection={dataHcEventSectionSubjectComment}
          icon={<SubjectCommentIcon reportId={reportId} />}
          label="Comment"
          onClick={handleOpen}
        />
      )}
      <Dialog
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        onClose={handleClose}
        title="Comment on this report"
      >
        <SubjectComment
          reportId={reportId}
          onSubmit={handleClose}
          actionsPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
        />
      </Dialog>
    </>
  );
};
