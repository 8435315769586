import React, { CSSProperties, JSXElementConstructor, ReactNode } from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';

import { NoDataIcon } from '../../../../svgs/icons/generics';

import styles from './NoContent.module.css';

export interface NoContentProps {
  dataHcName: string;
  className?: string;
  Icon?: JSXElementConstructor<IconProps>;
  children: ReactNode;
  style?: CSSProperties;
  margins?: boolean;
}
export const NoContent = ({
  dataHcName,
  className,
  Icon = NoDataIcon,
  style,
  children,
  margins,
}: NoContentProps) => {
  return (
    <div
      style={style}
      data-hc-name={dataHcName}
      className={classNames(styles.NoContent, className, {
        [styles.margins]: margins,
      })}
    >
      {Icon && <Icon className={styles.Icon} size="lg" />} {children}
    </div>
  );
};
