import React, { ReactNode } from 'react';

import { IconButton } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { DataPriorityIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { DataPriorityCerberusStats } from '@hcs/types';

import { MarketStateColumnsZipForUserForUserDialog } from '../MarketStateColumnsZipForUser/MarketStateColumnsZipForUserDialog';

import styles from './MarketStateColumnsZipForUser.module.css';

interface MarketStateColumnsZipForUserLauncherProps {
  className?: string;
  trigger?: ReactNode;
  showTooltip?: boolean;
  onUpdate?: (chartDataPriority: DataPriorityCerberusStats) => void;
}
const dataHcName = 'cerberus-stats-columns-options-launcher';
export const MarketStateColumnsZipForUserForUserLauncher = ({
  className,
  trigger,
  onUpdate,
  showTooltip = true,
}: MarketStateColumnsZipForUserLauncherProps) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const renderedTrigger = trigger ? (
    <span
      data-hc-name={dataHcName}
      onClick={handleOpen}
      className={styles.Trigger}
    >
      {trigger}
    </span>
  ) : (
    <IconButton
      dataHcName={dataHcName}
      className={className}
      onClick={handleOpen}
      icon={<DataPriorityIcon size="lg" />}
    />
  );

  return (
    <>
      {showTooltip ? (
        <Tooltip
          dataHcName={`${dataHcName}-tooltip`}
          theme={{
            Trigger: styles.Trigger,
          }}
          trigger={renderedTrigger}
          label="Reorder Table"
        />
      ) : (
        renderedTrigger
      )}
      <MarketStateColumnsZipForUserForUserDialog
        active={active}
        onUpdate={onUpdate}
        onClose={handleClose}
      />
    </>
  );
};
