import React, { ReactNode } from 'react';

import { TextButton } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';

import {
  APPRAISAL_COMP_DATA_SOURCES_FEATURES,
  AppraisalCompDataSources,
  AppraisalCompDataSourcesProps,
} from '../../features/AppraisalCompDataSources/AppraisalCompDataSources';
import { CompDetailsHeader } from '../../features/CompDetailsHeader';

interface Props extends AppraisalCompDataSourcesProps {
  trigger?: ReactNode;
}

export const APPRAISAL_COMP_DATA_SOURCES_LAUNCHER_FEATURES =
  APPRAISAL_COMP_DATA_SOURCES_FEATURES;
const dataHcName = 'appraisal-comps-data-sources-launcher';
export const AppraisalCompDataSourcesLauncher = ({
  reportId,
  compIdentifier,
  trigger,
  className,
  ...dataSourcesProps
}: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  return (
    <>
      {trigger ? (
        <span
          onClick={handleOpen}
          data-hc-name={dataHcName}
          style={{ cursor: 'pointer' }}
        >
          {trigger}
        </span>
      ) : (
        <TextButton onClick={handleOpen} dataHcName={dataHcName}>
          View Transaction History
        </TextButton>
      )}
      <Dialog
        dataHcName={dataHcName}
        type="auto"
        title={
          <CompDetailsHeader
            reportId={reportId}
            compIdentifier={compIdentifier}
          />
        }
        theme={{
          Dialog: className,
        }}
        active={active}
        onClose={() => {
          handleClose();
        }}
      >
        <AppraisalCompDataSources
          {...dataSourcesProps}
          reportId={reportId}
          compIdentifier={compIdentifier}
        />
      </Dialog>
    </>
  );
};
