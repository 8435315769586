import { gql } from 'graphql-request';

import { FRAGMENT_PROPERTY_LOCATION } from './fragments/PropertyStateLocation.graphql';

export const QUERY_PROPERTY_LOCATION = gql`
  ${FRAGMENT_PROPERTY_LOCATION}
  query PropertyLocation($cerberusInput: CerberusInput!) {
    # HC APP: Value Report
    # HC APP: Lead Feed
    lookup(id: $cerberusInput) {
      propertyDetails {
        hcAddressId
        location {
          ...PropertyLocation
        }
      }
    }
  }
`;
