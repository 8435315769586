import React, { useCallback, useState } from 'react';

import { NullState } from '@hcs/design-system';
import { HcMap, HcMapProps } from '@hcs/maps';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PreferencesKeys } from '@hcs/types';
import { ControlPosition } from '@hcs/types';
import { VectilesMetricGroups } from '@hcs/types';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompIdentifier, ReportFeatures, ReportId } from '@hcs/types';
import { ArrayOneOrMore } from '@hcs/types';

import { useSubjectDocument } from '../../hooks';
import { useReportConfig } from '../../hooks/useReportConfig';
import { CompMarkerPopup } from '../CompMarkerPopup';
import { SubjectMarker } from '../SubjectMarker';

const SUBJECT_MAP_ID = 'SUBJECT_MAP_ID';
const VECTILES_METRIC_GROUPS: ArrayOneOrMore<VectilesMetricGroups> = [
  VectilesMetricGroups.Price,
  VectilesMetricGroups.Growth,
  VectilesMetricGroups.Schools,
];
interface Props extends Omit<HcMapProps, 'dataHcName' | 'mapId'> {
  reportId: ReportId;
}
const dataHcName = 'subject-map';
export const SubjectMap = ({ reportId, children, ...mapProps }: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const { data: subjectDocument, isFetched } = useSubjectDocument(reportId);
  const { latitude, longitude } =
    subjectDocument?.data.propertyState.location || {};
  const [openMarkerPopup, setOpenMarkerPopup] = useState<CompIdentifier | null>(
    null
  );
  const onCloseMarkerPopup = useCallback(() => setOpenMarkerPopup(null), []);

  const subjectPropertyType = subjectDocument?.data.propertyState
    ? getPropertyStateFieldValue(PropertyStateFields.propertyType, {
        propertyStateType: PropertyStateType.Core,
        propertyState: subjectDocument.data.propertyState,
      })
    : undefined;

  if (isFetched && (!latitude || !longitude)) {
    return (
      <NullState
        dataHcName={`${dataHcName}-error`}
        title="Error: Missing subject coordinates"
      />
    );
  }

  return (
    <HcMap
      mapId={`${SUBJECT_MAP_ID}-${reportId}`}
      latitude={latitude || undefined}
      longitude={longitude || undefined}
      dataHcName={dataHcName}
      uiPreferencesKey={PreferencesKeys.CompsMapSale}
      layersControl={
        reportConfig?.reportFeaturesSupport[ReportFeatures.Heatmaps]
          ? {
              position: ControlPosition.BottomRight,
              vectilesMetricGroups: VECTILES_METRIC_GROUPS,
              toggles: { showProperties: false },
            }
          : undefined
      }
      propertyType={subjectPropertyType}
      zoomControl={{ position: ControlPosition.TopLeft }}
      loading={!isFetched}
      zoom={14}
      {...mapProps}
    >
      <SubjectMarker reportId={reportId} />
      {children}
      {openMarkerPopup && (
        <CompMarkerPopup
          compIdentifier={openMarkerPopup}
          reportId={reportId}
          onClose={onCloseMarkerPopup}
        />
      )}
    </HcMap>
  );
};
