import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './PropertyCard.module.css';

export interface PropertyCardProps {
  dataHcName: string;
  photos: ReactNode;
  content: ReactNode;
  flat?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}
export const PropertyCard = ({
  dataHcName,
  photos,
  content,
  className,
  style,
  flat,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: PropertyCardProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.PropertyCard, className, {
        [styles.flat]: flat,
      })}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div data-hc-name={`${dataHcName}-photos`} className={styles.Photos}>
        {photos}
      </div>
      <div data-hc-name={`${dataHcName}-content`} className={styles.Content}>
        {content}
      </div>
    </div>
  );
};
