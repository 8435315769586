import React from 'react';
import classNames from 'classnames';

import styles from './LoadingBar.module.css';

export interface LoadingBarProps {
  dataHcName: string;
  className?: string;
  height?: number;
  complete?: boolean;
}
export const LoadingBar = ({
  dataHcName,
  className,
  complete,
  height = 6,
}: LoadingBarProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.LoadingBar, className, {
        [styles.complete]: complete,
      })}
      style={{
        height: height ? `${height}px` : undefined,
      }}
    >
      <div className={styles.Inner} />
    </div>
  );
};
