import React, { useEffect, useState } from 'react';

import { DateStrInput, TableCell, TableHeaderCell } from '@hcs/design-system';
import { DateStr, TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateEditProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
} from '@hcs/types';
import { dateStrToHumanOrderDate } from '@hcs/utils';
import { formatDateShort } from '@hcs/utils';

const FIELD = PropertyStateFields.currentListDate;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'List Date';
const labelShort = 'List Date';
const propertyStatePath: PropertyStatePaths =
  '/complexFieldsSale/currentListDate';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.complexFieldsSale?.currentListDate;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatDateShort(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `Listed ${formatValue(propertyStateArgs)}`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const Edit = ({
  propertyStateArgs,
  onChange,
  onErrorChange,
  className,
}: PropertyStateEditProps<typeof FIELD>) => {
  const defaultValue = getValue(propertyStateArgs);
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue) {
      const humanOrderDateFormat = dateStrToHumanOrderDate(defaultValue);
      setValue(humanOrderDateFormat || null);
    }
  }, [defaultValue]);

  return (
    <DateStrInput
      dataHcName="list-date-adjust-input"
      tooltipError={true}
      allowNull={true}
      className={className}
      value={value || ''}
      onErrorChange={onErrorChange}
      onChange={(value) => {
        setValue(value || null);
        onChange({
          path: propertyStatePath,
          value: value as DateStr,
          field: FIELD,
        });
      }}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const CURRENT_LIST_DATE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
  spatialSortField: SpatialSortField.ListDate,
};
