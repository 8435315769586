import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import fileDownload from 'js-file-download';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import { FetchOrgUsersParams } from '@hcs/org-admin';
import {
  Application,
  AppSlugs,
  HcsAdminUser,
  Organization,
  OrgDomain,
} from '@hcs/types';
import { HcsAdminApiTaskStatus } from '@hcs/types';
import {
  HcsAdminImportSubscriptionsResponse,
  HcsAdminNewOrg,
  HcsAdminNewUsageQuery,
  HcsAdminOrgApiComponent,
  HcsAdminOrgApiComponentExclusion,
  HcsAdminOrgApiComponentExclusionCreate,
  HcsAdminOrgApiPrincipalComponent,
  HcsAdminOrgApiRateLimit,
  HcsAdminOrgApiRequestContentLimit,
  HcsAdminOrgApplication,
  HcsAdminOrgOptions,
  HcsAdminOrgSubscription,
  HcsAdminUsageQuery,
  HcsAdminUsageQueryDownloadPayload,
} from '@hcs/types';
import { CheckInvitationCodeResponse, OrgUsersResponse } from '@hcs/types';
import { PaginatedResponseData } from '@hcs/types';
import { EHRMANTRAUT_ADMIN_INTERNAL_URL } from '@hcs/urls';
import { camelCaseToSnakeCase } from '@hcs/utils';

import { HCS_ADMIN_PAGE_SIZE_DEFAULT } from '../constants/hcsAdmin.constants';
import { stringifyHcsAdminFilters } from '../utils';
import { prepHcsAdminOrgFiltersForApi } from '../utils/hcsAdminOrg.utils';

export const HcsAdminOrgsApi = {
  fetchOrgs: async (hcsAdminOrgOptions?: HcsAdminOrgOptions) => {
    const { pagination } = hcsAdminOrgOptions || {};
    const response = camelcaseKeys(
      await AxiosAccessTokenClientEhrm.get<{
        numResults: number;
        page: number;
        totalPages: number;
        objects: Organization[];
      }>(`${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/organization`, {
        params: prepHcsAdminOrgFiltersForApi(hcsAdminOrgOptions),
      }),
      { deep: true }
    );
    const paginatedData: PaginatedResponseData<Organization[]> = {
      pagination: {
        totalCount: response.data.numResults,
        numPages: response.data.totalPages,
        page: response.data.page,
        pageSize: pagination?.pageSize || HCS_ADMIN_PAGE_SIZE_DEFAULT,
      },
      data: response.data.objects,
    };
    return paginatedData;
  },
  fetchOrg: async (orgId: number): Promise<Organization> => {
    try {
      const response = await AxiosAccessTokenClientEhrm.get<Organization>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/organization/${orgId}`
      );
      return camelcaseKeys<Organization>(response.data, {
        deep: true,
      });
    } catch (error) {
      throw new Error('data not available');
    }
  },
  downloadUsageQuery: async (query: HcsAdminUsageQueryDownloadPayload) => {
    const response = await AxiosAccessTokenClientEhrm.get<Blob>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/usage/organization/${query.orgId}/usage-query/${query.usageQueryId}/download`,
      { responseType: 'blob' }
    );
    fileDownload(response.data, query.fileName);
  },
  addNewOrg: async (data: HcsAdminNewOrg) => {
    const response = await AxiosAccessTokenClientEhrm.post<Organization>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/organization`,
      snakecaseKeys(data)
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  createNewUsageQuery: async (data: HcsAdminNewUsageQuery, orgId: number) => {
    const response = await AxiosAccessTokenClientEhrm.post<HcsAdminUsageQuery>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/usage/organization/${orgId}/usage-query`,
      snakecaseKeys(data)
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  updateOrgDetails: async (org: Organization) => {
    const response = await AxiosAccessTokenClientEhrm.put<Organization>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/organization/${org.id}`,
      snakecaseKeys(org)
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  addOrgDomain: async (orgDomain: OrgDomain) => {
    const { organizationId, domain } = orgDomain;
    const response = await AxiosAccessTokenClientEhrm.post<OrgDomain>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/organizationemaildomain`,
      snakecaseKeys({
        organizationId,
        domain,
      })
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  deleteUserFromOrg: async (orgId: number, userId: number) => {
    await AxiosAccessTokenClientEhrm.delete(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/org_users/${orgId}/${userId}`
    );
  },
  removeOrgDomain: async (domain: string) => {
    await AxiosAccessTokenClientEhrm.delete<string>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/organizationemaildomain/${domain}`
    );
  },
  fetchOrgInvitations: async (orgId: number) => {
    const params = stringifyHcsAdminFilters({
      q: {
        filters: [
          {
            name: 'organization_id',
            val: orgId,
            op: 'eq',
          },
        ],
        orderBy: [{ field: 'email', direction: 'asc' }],
      },
      page: 1,
      resultsPerPage: 10000,
    });

    const response = camelcaseKeys(
      await AxiosAccessTokenClientEhrm.get<{
        numResults: number;
        page: number;
        totalPages: number;
        objects: CheckInvitationCodeResponse[];
      }>(`${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/invitation`, { params }),
      { deep: true }
    );

    const paginatedData: PaginatedResponseData<CheckInvitationCodeResponse[]> =
      {
        pagination: {
          totalCount: response.data.numResults,
          numPages: response.data.totalPages,
          page: response.data.page,
          pageSize: HCS_ADMIN_PAGE_SIZE_DEFAULT,
        },
        data: response.data.objects,
      };
    return paginatedData;
  },
  sendOrgInvitationEmail: async (invitationId: number) => {
    const response = await AxiosAccessTokenClientEhrm.post(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/invitation/send-email`,
      {
        emailType: 'welcome',
        invitationId,
      }
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOrgApiRateLimits: async (orgId: number) => {
    const response = await AxiosAccessTokenClientEhrm.get<
      HcsAdminOrgApiRateLimit[]
    >(`${EHRMANTRAUT_ADMIN_INTERNAL_URL}/rate_limits/${orgId}/0`);

    return camelcaseKeys(response.data, { deep: true });
  },
  addOrgApiRateLimit: async (rateLimit: HcsAdminOrgApiRateLimit) => {
    const response =
      await AxiosAccessTokenClientEhrm.post<HcsAdminOrgApiRateLimit>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/ratelimit`,
        snakecaseKeys(rateLimit)
      );

    return camelcaseKeys(response.data, { deep: true });
  },
  removeOrgApiRateLimit: async (rateLimitId: number) => {
    const response = await AxiosAccessTokenClientEhrm.delete(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/ratelimit/${rateLimitId}`
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOrgApiRequestContentLimits: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientEhrm.get<HcsAdminOrgApiRequestContentLimit>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/request_content_limits/${orgId}`
      );

    return camelcaseKeys(response.data, { deep: true });
  },
  addOrgApiRequestContentLimits: async (
    payload: HcsAdminOrgApiRequestContentLimit
  ) => {
    const response =
      await AxiosAccessTokenClientEhrm.post<HcsAdminOrgApiRequestContentLimit>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/requestcontentlimit`,
        snakecaseKeys(payload)
      );

    return camelcaseKeys(response.data, { deep: true });
  },
  updateOrgApiRequestContentLimits: async (
    payload: HcsAdminOrgApiRequestContentLimit
  ) => {
    const response =
      await AxiosAccessTokenClientEhrm.put<HcsAdminOrgApiRequestContentLimit>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/requestcontentlimit/${payload.id}`,
        snakecaseKeys(payload)
      );

    return camelcaseKeys(response.data, { deep: true });
  },
  resetOrgApiRequestContentLimits: async (id: number) => {
    const response = await AxiosAccessTokenClientEhrm.delete(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/requestcontentlimit/${id}`
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOrgApiComponents: async () => {
    const params = stringifyHcsAdminFilters({
      q: {
        orderBy: [{ field: 'name', direction: 'asc' }],
      },
      resultsPerPage: 5000,
    });
    const response = await AxiosAccessTokenClientEhrm.get<{
      numResults: number;
      page: number;
      totalPages: number;
      objects: HcsAdminOrgApiComponent[];
    }>(`${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/component`, { params });

    return camelcaseKeys(response.data.objects, { deep: true });
  },
  fetchOrgApiComponentExclusions: async (orgId: number) => {
    const params = stringifyHcsAdminFilters({
      q: {
        filters: [{ op: 'eq', name: 'organization_id', val: orgId }],
      },
      resultsPerPage: 1000,
    });
    const response = await AxiosAccessTokenClientEhrm.get<{
      numResults: number;
      page: number;
      totalPages: number;
      objects: HcsAdminOrgApiComponentExclusion[];
    }>(`${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/componentexclusion`, { params });

    return camelcaseKeys(response.data.objects, { deep: true });
  },
  createOrgApiComponentExclusion: async (
    payload: HcsAdminOrgApiComponentExclusionCreate
  ) => {
    const response =
      await AxiosAccessTokenClientEhrm.post<HcsAdminOrgApiComponentExclusionCreate>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/componentexclusion`,
        snakecaseKeys(payload)
      );

    return camelcaseKeys(response.data, { deep: true });
  },
  removeOrgApiComponentExclusion: async (id: number) => {
    await AxiosAccessTokenClientEhrm.delete(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/componentexclusion/${id}`
    );
  },
  fetchOrgApiPrincipalComponents: async (orgId: number) => {
    const params = stringifyHcsAdminFilters({
      page: 1,
      q: {
        filters: [
          { name: 'principal_type', val: 'Organization', op: 'eq' },
          { name: 'principal_id', val: `${orgId}`, op: 'eq' },
          { name: 'is_active', val: true, op: 'eq' },
        ],
      },
      resultsPerPage: 1000,
    });
    const response = await AxiosAccessTokenClientEhrm.get<{
      numResults: number;
      page: number;
      totalPages: number;
      objects: HcsAdminOrgApiPrincipalComponent[];
    }>(`${EHRMANTRAUT_ADMIN_INTERNAL_URL}/crud/principalcomponent`, { params });

    return camelcaseKeys(response.data.objects, { deep: true });
  },
  fetchOrgApplications: async (orgId: number) => {
    const response = camelcaseKeys(
      await AxiosAccessTokenClientEhrm.get<HcsAdminOrgApplication[]>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/provisioning/${orgId}`
      ),
      { deep: true }
    );
    return response.data;
  },
  fetchOrgApplicationProvisionedUsers: async (
    orgId: number,
    appName: AppSlugs
  ) => {
    const response = camelcaseKeys(
      await AxiosAccessTokenClientEhrm.get<HcsAdminUser[]>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/provisioning/${orgId}/users`,
        {
          params: { application_name: appName },
        }
      ),
      { deep: true }
    );
    return response.data;
  },
  fetchOrgSubscriptions: async (orgId: number) => {
    try {
      const response = camelcaseKeys(
        await AxiosAccessTokenClientEhrm.get<HcsAdminOrgSubscription[]>(
          `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/subscription/organization/${orgId}`
        ),
        { deep: true }
      );
      return response.data;
    } catch (error) {
      throw new Error('data not available');
    }
  },
  importOrgSubscriptions: async (orgId: number) => {
    const response = camelcaseKeys(
      await AxiosAccessTokenClientEhrm.get<HcsAdminImportSubscriptionsResponse>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/subscription/organization/${orgId}/import-subscriptions`,
        { params: snakecaseKeys({ deleteOld: 1 }) }
      ),
      { deep: true }
    );
    return response.data;
  },
  fetchTaskStatus: async (taskId: string) => {
    const response = camelcaseKeys(
      await AxiosAccessTokenClientEhrm.get<HcsAdminApiTaskStatus>(
        `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/task/${taskId}`
      ),
      { deep: true }
    );
    return response.data;
  },
  updateOrgApplicationAvailability: async (
    orgId: number,
    applicationName: string,
    available: boolean
  ) => {
    const response = await AxiosAccessTokenClientEhrm.post<Application[]>(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/provisioning/available/${orgId}${
        available ? '' : '/remove'
      }`,
      snakecaseKeys({ applicationName })
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  createOrgApplicationProvisioning: async (
    orgId: number,
    applicationName: string
  ) => {
    const response = await AxiosAccessTokenClientEhrm.post<
      HcsAdminOrgApplication[]
    >(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/provisioning/${orgId}`,
      snakecaseKeys({ applicationName })
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  removeOrgApplicationProvisioning: async (
    orgId: number,
    applicationName: string
  ) => {
    const response = await AxiosAccessTokenClientEhrm.post<
      HcsAdminOrgApplication[]
    >(
      `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/provisioning/${orgId}/remove`,
      snakecaseKeys({ applicationName })
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  /** These endpoints work the same way as the client-facing self-service admin equivalents  */
  fetchOrgUsers: async (orgId: number, params?: FetchOrgUsersParams) => {
    /*
     * Some janky conversion here to give the BE the strange (for querystrings) format it expects for order_by
     * i.e. The BE expects the key for order_by to not be an array key i.e. order_by[]= (as axios.getUri would normally make it since the value is an array)
     * the BE also expects the value to be an array
     */
    const modifiedParams = (params: FetchOrgUsersParams) => {
      return {
        ...params,
        orderBy: params.orderBy
          ? JSON.stringify([
              snakecaseKeys({
                ...params.orderBy,
                field: camelCaseToSnakeCase(params.orderBy.field),
              }),
            ])
          : undefined,
      };
    };

    const response = await AxiosAccessTokenClientEhrm.get<OrgUsersResponse>(
      axios.getUri({
        url: `${EHRMANTRAUT_ADMIN_INTERNAL_URL}/command_org_users`,
        params: {
          ...(params ? snakecaseKeys(modifiedParams(params)) : {}),
          org_id: orgId,
        },
      })
    );
    return camelcaseKeys(response.data, { deep: true });
  },
};
