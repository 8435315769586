import { gql } from 'graphql-request';

/** PROPERTY STATE | propertyValue  */

export const FRAGMENT_VALUE_CORE = gql`
  fragment ValueCore on Value {
    fsd
    fsd90Percent
    value
    valueLower
    valueMedian
    valueUpper
    quality
    valueAtSixConditions {
      conditionClass
      neighborhoodConditionClass
      valueAtCondC1
      valueAtCondC2
      valueAtCondC3
      valueAtCondC4
      valueAtCondC5
      valueAtCondC6
    }
  }
`;

/** PROPERTY STATE | propertyValueRental  */

export const FRAGMENT_RENTAL_VALUE_CORE = gql`
  fragment RentalValueCore on RentalValue {
    fsd
    fsd90Percent
    quality
    value
    valueLower
    valueMedian
    valueUpper
    valueAtSixConditions {
      valueAtCondC1
      valueAtCondC2
      valueAtCondC3
      valueAtCondC4
      valueAtCondC5
      valueAtCondC6
    }
    yield
  }
`;
