import { useQuery } from '@tanstack/react-query';

import { useIsLoggedIn } from '@hcs/auth';
import { CompTypes } from '@hcs/types';

import { HuellApi } from '../api';

export const QUERY_KEY_SAVED_COMP_FILTER_SETS_USER = 'savedCompFilterSets-user';
export const useSavedCompFilterSetsForUser = (compType: CompTypes) => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  return useQuery(
    [QUERY_KEY_SAVED_COMP_FILTER_SETS_USER, compType],
    async () => {
      return await HuellApi.fetchSavedCompFilterSetsUser(compType);
    },
    {
      enabled: isLoggedIn,
    }
  );
};
