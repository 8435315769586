import React from 'react';
import classNames from 'classnames';

import { ReportFeaturesSupportTypes, ReportId } from '@hcs/types';

import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import {
  REPORT_INTRA_ORG_LINK_FEATURES,
  ReportIntraOrgLink,
} from '../ReportIntraOrgLink';
import {
  REPORT_PUBLIC_LINK_EDITABLE_FEATURES,
  ReportPublicLinkEditable,
} from '../ReportPublicLinkEditable';
import {
  REPORT_PUBLIC_LINK_READ_ONLY_FEATURES,
  ReportPublicLinkReadOnly,
} from '../ReportPublicLinkReadOnly';

import styles from './ShareReport.module.css';

export const SHARE_REPORT_FEATURES = [
  ...REPORT_PUBLIC_LINK_READ_ONLY_FEATURES,
  ...REPORT_INTRA_ORG_LINK_FEATURES,
];
interface ShareReportProps {
  reportId: ReportId;
  className?: string;
}
const dataHcName = 'share-report';
export const dataHcEventSectionShareReport = 'Share Report';
export const ShareReport = ({ className, reportId }: ShareReportProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSectionShareReport}
      className={classNames(styles.ShareReport, className)}
    >
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={REPORT_INTRA_ORG_LINK_FEATURES}
      >
        <ReportIntraOrgLink reportId={reportId} />
      </ReportFeaturesSupported>

      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={[
          ...REPORT_PUBLIC_LINK_READ_ONLY_FEATURES,
          ...REPORT_INTRA_ORG_LINK_FEATURES,
        ]}
        reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
      >
        <div
          data-hc-name={`${dataHcName}-feature-separator`}
          className={styles.OrContainer}
        >
          <div className={styles.Line} />
          or
          <div className={styles.Line} />
        </div>
      </ReportFeaturesSupported>
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={REPORT_PUBLIC_LINK_READ_ONLY_FEATURES}
      >
        <ReportPublicLinkReadOnly reportId={reportId} />
      </ReportFeaturesSupported>
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={[
          ...REPORT_PUBLIC_LINK_READ_ONLY_FEATURES,
          ...REPORT_INTRA_ORG_LINK_FEATURES,
        ]}
        reportFeaturesSupportType={ReportFeaturesSupportTypes.Any}
      >
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[...REPORT_PUBLIC_LINK_EDITABLE_FEATURES]}
          reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
        >
          <div
            data-hc-name={`${dataHcName}-feature-separator`}
            className={styles.OrContainer}
          >
            <div className={styles.Line} />
            or
            <div className={styles.Line} />
          </div>
        </ReportFeaturesSupported>
      </ReportFeaturesSupported>
      <ReportPublicLinkEditable reportId={reportId} />
    </div>
  );
};
