import React, { KeyboardEvent, useState } from 'react';
import isEqual from 'lodash.isequal';

import { InputComplex, SearchInput } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { KeywordListingInput } from '@hcs/types';

import { useKeywordSearch } from '../../hooks/useKeywordSearch';

interface Props {
  dataHcName: string;
  listingsInput: KeywordListingInput[];
  className?: string;
  searchKey: string;
  noPadding?: boolean;
  disabled?: boolean;
}

const _formatValue = (value: string) => {
  const words: string[] = [];
  value.split(',').forEach((w) => {
    // Done this way to prevent empty strings
    // and to avoid multiple loops
    const word = w.trim();
    if (word) {
      words.push(word);
    }
  });
  return words;
};

export const KeywordSearchByListings = ({
  listingsInput,
  dataHcName,
  className,
  disabled,
  searchKey,
  noPadding,
}: Props) => {
  const {
    state: { inputs },
    actions: { keywordSearchSubmit },
  } = useKeywordSearch(searchKey);
  const [value, setValue] = useState(inputs?.keywords.join(', ') || '');
  const handleSubmit = () => {
    const newKeywords = _formatValue(value);
    if (!isEqual(inputs?.keywords, newKeywords)) {
      keywordSearchSubmit({
        keywords: newKeywords,
        // Listings to search
        listings: listingsInput,
        // Search public remarks
        match_remarks_public: true,
        // Search private remarks
        match_remarks_private: false,
        // Return remarks with matches
        return_remarks: false,
      });
    }
  };
  const onKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <SearchInput
          dataHcName={dataHcName}
          className={className}
          value={value}
          onChange={setValue}
          placeholder="Search"
          onKeyUp={onKeyUp}
          onBlur={handleSubmit}
          disabled={!listingsInput || disabled}
        />
      }
      now={
        <InputComplex
          dataHcName={dataHcName}
          className={className}
          value={value}
          onChange={setValue}
          placeholder="Keyword Search"
          onKeyUp={onKeyUp}
          onBlur={handleSubmit}
          noPadding={noPadding}
        />
      }
    />
  );
};
