import React from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { SectionTitle } from '@hcs/design-system';
import { ReportId } from '@hcs/types';

import { useReportPermissions } from '../../hooks';
import { DOWNLOAD_REPORT_FEATURES, DownloadReport } from '../DownloadReport';
import { REFRESH_REPORT_FEATURES, RefreshReport } from '../RefreshReport';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import {
  SHARE_REPORT_DIALOG_FEATURES,
  ShareReportDialog,
} from '../ShareReport';
import {
  SUBJECT_COMMENT_LAUNCHER_FEATURES,
  SubjectCommentDialog,
} from '../SubjectComment';

import styles from './ReportQuickActions.module.css';

interface Props {
  className?: string;
  reportId: ReportId;
}
const dataHcName = 'report-quick-actions';
export const REPORT_QUICK_ACTIONS_FEATURES = [
  ...SUBJECT_COMMENT_LAUNCHER_FEATURES,
  ...SHARE_REPORT_DIALOG_FEATURES,
  ...DOWNLOAD_REPORT_FEATURES,
  ...REFRESH_REPORT_FEATURES,
];
export const ReportQuickActions = ({ className, reportId }: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ReportQuickActions, className)}
    >
      <SectionTitle dataHcName={`${dataHcName}-title`}>
        Quick Actions
      </SectionTitle>
      <div className={styles.Actions}>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={SUBJECT_COMMENT_LAUNCHER_FEATURES}
        >
          <SubjectCommentDialog
            reportId={reportId}
            trigger={
              <Button
                dataHcName={`${dataHcName}-comment`}
                label="Comment"
                secondary
              />
            }
          />
        </ReportFeaturesSupported>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={SHARE_REPORT_DIALOG_FEATURES}
        >
          <ShareReportDialog
            reportId={reportId}
            trigger={
              <Button
                dataHcName={`${dataHcName}-share`}
                label="Share"
                secondary
              />
            }
          />
        </ReportFeaturesSupported>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={DOWNLOAD_REPORT_FEATURES}
        >
          <DownloadReport
            reportId={reportId}
            trigger={
              <Button
                dataHcName={`${dataHcName}-download`}
                label="Download"
                secondary
              />
            }
          />
        </ReportFeaturesSupported>
        {reportPermissions?.isEditable && (
          <ReportFeaturesSupported
            reportId={reportId}
            reportFeatures={REFRESH_REPORT_FEATURES}
          >
            <RefreshReport
              reportId={reportId}
              trigger={
                <Button
                  dataHcName={`${dataHcName}-refresh`}
                  label="Refresh Data"
                  secondary
                />
              }
            />
          </ReportFeaturesSupported>
        )}
      </div>
    </div>
  );
};
