import { gql } from 'graphql-request';

import { FRAGMENT_AGENT_OFFICE } from './fragments/ListingDetails.graphql';
import { FRAGMENT_LISTING_MEDIA } from './fragments/ListingMedia.graphql';
import { FRAGMENT_LISTING_MLS } from './fragments/Mls.graphql';
import { FRAGMENT_PROPERTY_DETAILS_CORE } from './fragments/PropertyDetails.graphql';
import {
  FRAGMENT_DISCOVERY_HC_ADDRESS,
  FRAGMENT_PROPERTY_LOCATION_HC,
} from './fragments/PropertyStateLocation.graphql';
import {
  FRAGMENT_RESO_LISTING_DETAILS,
  FRAGMENT_RESO_PROPERTY_DETAILS,
} from './fragments/ResoFragments.graphql';

export const QUERY_PROPERTY_STATE_LISTING = gql`
  query PropertyStateListing($cerberusListingsInput: CerberusListingsInput!) {
    ${FRAGMENT_DISCOVERY_HC_ADDRESS}
    ${FRAGMENT_PROPERTY_LOCATION_HC}
    ${FRAGMENT_LISTING_MEDIA}
    ${FRAGMENT_PROPERTY_DETAILS_CORE}
    ${FRAGMENT_RESO_PROPERTY_DETAILS}
    ${FRAGMENT_RESO_LISTING_DETAILS}
    ${FRAGMENT_LISTING_MLS}
    ${FRAGMENT_AGENT_OFFICE}
    # HC INTERNAL
    lookupListings(id: $cerberusListingsInput) {
        listings {
        id {
            discovery {
                hcMlsId
                listingId
            }
        }
        agentOffice {
          hc {
            agentOffice {
              ...AgentOffice
            }
          }
        }
        entityId
        hcAddressId
        mls {
            ...ListingMlsFragment
        }
        location {
          hc {
            ...PropertyLocationHc
          }
          discovery {
            hcAddress {
                ...PropertyLocationDiscovery
            }
          }
        }
        media {
            hc {
              ...ListingMedia
            }
        }
        listingDetails {
            reso {
                ...ResoListingDetails
            }
            hc {
              addressDisplayNo
              ageRestricted
              armsLength
              avmDisplayNo
              buyerBrokerageCompensationDisplay
              closeDate
              closePrice
              commentsDisplayNo
              concessions {
                buyerAmount
                buyerDescription
                sellerAmount
                sellerDescription
              }
              currentPrice
              currentSaleStatus
              currentStatusDate
              daysOnMarket
              daysOnMarketCumulative
              daysOnMarketMLS
              daysToClose
              daysToCloseCumulative
              daysToContract
              distressed
              flipYN
              idxDisplayNo
              listPrice
              listPriceOriginal
              listPriceRangeMax
              listPriceRangeMin
              listingDate
              mlsLastUpdate
              mlsName
              remarks {
                type
                value
              }
              rentalAmountFrequency
              rentalLeaseTerm
              rentalListing
              rentalLogic
              reo
              status
            }
        }
        propertyDetails {
            reso {
                ...ResoPropertyDetails
            }
            hc {
                ...PropertyDetailsCore
            }
        }
      }
    }
  }
`;
