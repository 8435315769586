import { ReactNode } from 'react';

import { useAccount } from '@hcs/auth';
import { AuthenticatedCheck } from '@hcs/authz';
import { LoadingSpinner } from '@hcs/design-system';
import { useOrgIsSelfServe, useOrgSelfServeInfo } from '@hcs/organization';
import { ConfirmUser } from '@hcs/user';
import { combineUseQueryResult } from '@hcs/utils';

import { useSelfServiceCheckoutSessionParam } from '../../hooks';

interface SelfServeStatusProps {
  skeleton?: ReactNode;
  header: ReactNode;
  children: ReactNode;
  // User completed the sign up flow but never completed the stripe checkout
  checkoutAbandonded: ReactNode;
  // User has been redirected back to the platform from stripe checkout
  checkoutInProgress: ReactNode;
  // User is not logged in
  error401: ReactNode;
  onConfirmUserSuccess: VoidFunction;
}

const dataHcName = 'self-serve-status';
export const SelfServeStatus = ({
  children,
  skeleton,
  header,
  error401,
  checkoutAbandonded,
  onConfirmUserSuccess,
  checkoutInProgress,
}: SelfServeStatusProps) => {
  const orgInfoQuery = useOrgSelfServeInfo();
  const accountQuery = useAccount();
  const combinedQuery = combineUseQueryResult([orgInfoQuery, accountQuery]);
  const { sessionParam } = useSelfServiceCheckoutSessionParam();
  const { data: isSelfServe } = useOrgIsSelfServe();
  const { data: selfServiceOrgInfo } = orgInfoQuery;
  const { data: account } = accountQuery;
  let renderedContent = children;
  if (combinedQuery.isInitialLoading) {
    renderedContent = skeleton || (
      <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />
    );
  }
  if (isSelfServe && selfServiceOrgInfo?.checkout_completed === false) {
    if (sessionParam) {
      renderedContent = checkoutInProgress;
    } else {
      renderedContent = checkoutAbandonded;
    }
  }
  if (account?.user && !account.user.confirmed) {
    renderedContent = (
      <ConfirmUser onClickSuccess={onConfirmUserSuccess} header={header} />
    );
  }
  return (
    <AuthenticatedCheck skeleton={skeleton} error401={error401}>
      {renderedContent}
    </AuthenticatedCheck>
  );
};
