import React from 'react';
import classNames from 'classnames';

import { SimilarityLevel as SimilarityLevelType } from '@hcs/types';

import styles from './SimilarityLevel.module.css';

const SimilarityLevelDot = () => {
  return (
    <svg height="7" width="7" className={styles.SimilarityLevelDot}>
      <circle cx="3.5" cy="3.5" r="3.5" strokeWidth="0" />
    </svg>
  );
};

export interface SimilarityLevelProps {
  dataHcName: string;
  similarityLevel: SimilarityLevelType | null;
  className?: string;
}
export const SimilarityLevel = ({
  dataHcName,
  similarityLevel,
  className,
}: SimilarityLevelProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        className,
        styles.SimilarityLevel,
        styles[similarityLevel as 'high' | 'moderate' | 'low']
      )}
    >
      <SimilarityLevelDot />
      <SimilarityLevelDot />
      <SimilarityLevelDot />
    </div>
  );
};
