import React, { ReactNode } from 'react';

import styles from './RangeChart.module.css';

export interface RangeChartProps {
  dataHcName: string;
  mainValue?: number | string | ReactNode;
  lowTop?: number | string | ReactNode;
  highTop?: number | string | ReactNode;
  lowBottom?: number | string | ReactNode;
  highBottom?: number | string | ReactNode;
  lowLabel?: string;
  highLabel?: string;
}

export const RangeChart = ({
  dataHcName,
  mainValue,
  lowTop,
  highTop,
  lowBottom,
  highBottom,
  lowLabel,
  highLabel,
}: RangeChartProps) => {
  return (
    <div data-hc-name={dataHcName} className={styles.RangeChart}>
      <div className={styles.MainRow} data-hc-name={`${dataHcName}-main-value`}>
        {mainValue}
      </div>
      <div className={styles.Labels}>
        {lowLabel && (
          <span
            className={styles.Label}
            data-hc-name={`${dataHcName}-low-label`}
          >
            {lowLabel}
          </span>
        )}
        <span className={styles.MainIndicator} />
        {highLabel && (
          <span
            className={styles.Label}
            data-hc-name={`${dataHcName}-high-label`}
          >
            {highLabel}
          </span>
        )}
      </div>
      <div data-hc-name={`${dataHcName}-bar`} className={styles.Bar} />
      <div className={styles.HighLowLabels}>
        <div>
          {lowTop && (
            <div
              data-hc-name={`${dataHcName}-low-top`}
              className={styles.ValueTop}
            >
              {lowTop}
            </div>
          )}
          {lowBottom && (
            <div
              data-hc-name={`${dataHcName}-low-bottom`}
              className={styles.ValueBottom}
            >
              {lowBottom}
            </div>
          )}
        </div>
        <div>
          {highTop && (
            <div
              data-hc-name={`${dataHcName}-high-top`}
              className={styles.ValueTop}
            >
              {highTop}
            </div>
          )}
          {highBottom && (
            <div
              data-hc-name={`${dataHcName}-high-bottom`}
              className={styles.ValueBottom}
            >
              {highBottom}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
