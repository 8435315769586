import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps } from '@hcs/types';
import { TableHeaderCellProps } from '@hcs/types';
import {
  PortfolioAsset,
  PortfolioAssetContentCellProps,
  PortfolioAssetFieldConfig,
} from '@hcs/types';
import { formatPercent } from '@hcs/utils';

import { DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL } from '../../constants/PortfolioTable.constants';

const Display = ({ portfolioAsset }: PortfolioAssetContentCellProps) => {
  return (
    <div
      data-hc-name={`${DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL}-interest-rate`}
    >
      <div>{formatPercent(portfolioAsset.data?.interestRate, true, 2)}</div>
    </div>
  );
};

const ContentCell = ({
  portfolioAsset,
  ...tableCellProps
}: {
  portfolioAsset: PortfolioAsset;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display portfolioAsset={portfolioAsset} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return (
    <TableHeaderCell {...tableHeaderCellProps}>Interest Rate</TableHeaderCell>
  );
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export const PORTFOLIO_ASSET_INTEREST_RATE_CONFIG: PortfolioAssetFieldConfig = {
  ContentCell,
  HeaderCell,
  label: 'Interest Rate',
};
