import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './ChipButton.module.css';

export interface ChipButtonProps {
  dataHcName: string;
  children?: ReactNode;
  className?: string;
  icon: ReactNode;
  onClickIcon?: (e: MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}
export const ChipButton = ({
  dataHcName,
  children,
  className,
  icon,
  onClick,
  onClickIcon,
}: ChipButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      data-hc-name={dataHcName}
      className={classNames(styles.ChipButton, className)}
      onClick={onClick}
    >
      <div
        onClick={
          onClickIcon
            ? (e: MouseEvent<HTMLDivElement>) => {
                // don't bubble up event click to button
                e.stopPropagation();
                onClickIcon(e);
              }
            : undefined
        }
        className={classNames(styles.ChipIconContainer, {
          [styles['clickable-icon-container']]: onClickIcon !== undefined,
        })}
      >
        {icon}
      </div>
      {children}
    </button>
  );
};
