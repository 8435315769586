import React, { useState } from 'react';

import { FilterRange, Input } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateFilterProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateEditCallbackArgs,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
} from '@hcs/types';
import { numInputStrStrip } from '@hcs/utils';
import {
  formatNumber,
  formatNumberAbbrev,
  formatSqFt,
  unformat,
} from '@hcs/utils';

const FIELD = PropertyStateFields.lotSize;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Lot Size';
const labelShort = 'Lot Size';
const propertyStatePath: PropertyStatePaths = '/propertyDetails/lotSize';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  }
  return propertyState?.propertyDetails?.lotSize;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatSqFt(getValue(propertyStateArgs));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Lot ft²';
const formatValueShort: FieldConfig['formatValueShort'] = (propertyStateArgs) =>
  formatNumberAbbrev(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)} Lot`;
// Component for displaying a field from a schema
const Display = ({
  propertyStateArgs,
}: PropertyStateArgsProps & {
  includeUnit?: boolean;
}) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: PropertyStateEditCallbackArgs<typeof FIELD>) => void;
  className?: string;
}) => {
  const defaultValue = getValue(propertyStateArgs);
  const [value, setValue] = useState(
    defaultValue === null ? null : formatNumber(defaultValue)
  );

  return (
    <Input
      dataHcName="lot-size-adjust-input"
      className={className}
      value={value?.toString() || ''}
      onChange={(value) => {
        const valueOrNull = value === '' ? null : numInputStrStrip(value);
        setValue(valueOrNull);
        onChange({
          path: propertyStatePath,
          value: valueOrNull === null ? valueOrNull : Number(valueOrNull),
          field: FIELD,
        });
      }}
      maxLength={25}
      onFocus={() => {
        if (value !== null) {
          setValue(unformat(value).toString());
        }
      }}
      onBlur={() => {
        if (value !== null) {
          setValue(formatNumber(Number(value)));
        }
      }}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort} (ft²)</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      {formatNumber(getValue(propertyStateArgs))}
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  return (
    <FilterRange
      className={className}
      disabled={disabled}
      maxVal={99999999}
      initialValue={{
        min: spatialFiltersInput.minLotArea
          ? formatNumber(spatialFiltersInput.minLotArea)
          : '',
        max: spatialFiltersInput.maxLotArea
          ? formatNumber(spatialFiltersInput.maxLotArea)
          : '',
      }}
      onBlur={(value) => {
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            minLotArea: value.min === '' ? null : Number(value.min),
            maxLotArea: value.max === '' ? null : Number(value.max),
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

export const LOT_SIZE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  labelMicro,
  formatValueShort,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
  Filter,
  spatialSortField: SpatialSortField.LotArea,
};
