import { usePortfolioNotificationsSlice } from '../hooks/usePortfolioNotificationsSlice';

export const usePortfolioNotifications = (portfolioId: string) => {
  const { state, actions } = usePortfolioNotificationsSlice();

  const isAcknowledged = state.acknowledgedNotifications.includes(portfolioId);
  const removeAcknowledgedNotification = () =>
    actions.removeAcknowledgedNotification({ portfolioId });
  const acknowledgeNotification = () =>
    actions.acknowledgeNotification({ portfolioId });

  return {
    isAcknowledged,
    removeAcknowledgedNotification,
    acknowledgeNotification,
  };
};
