import React from 'react';

import { Carousel, CarouselProps } from '@hcs/design-system';
import { ChartDimensions } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import {
  DAYS_ON_MARKET_CHART_FEATURES,
  DaysOnMarketChart,
} from '../DaysOnMarketChart/DaysOnMarketChart';
import {
  MARKET_RISK_CHART_FEATURES,
  MarketRiskChart,
} from '../MarketRiskChart';
import {
  MONTHS_OF_SUPPLY_CHART_FEATURES,
  MonthsOfSupplyChart,
} from '../MonthsOfSupplyChart';

import styles from './MarketAnalysis.module.css';

interface MarketAnalysisCarouselProps {
  reportId: ReportId;
  className?: string;
}
const chartDimensions: ChartDimensions = {
  width: 550,
  height: 220,
  padding: { top: 20, right: 10, bottom: 45, left: 65 },
};
const dataHcName = 'market-analysis-carousel';
export const MarketAnalysisCarousel = ({
  reportId,
  className,
}: MarketAnalysisCarouselProps) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const slides: CarouselProps['slides'] = [];
  if (reportFeaturesSupportedAny(reportConfig, MARKET_RISK_CHART_FEATURES)) {
    slides.push(<MarketRiskChart reportId={reportId} />);
  }
  if (reportFeaturesSupportedAny(reportConfig, DAYS_ON_MARKET_CHART_FEATURES)) {
    slides.push(
      <DaysOnMarketChart
        chartDimensions={chartDimensions}
        reportId={reportId}
      />
    );
  }
  if (
    reportFeaturesSupportedAny(reportConfig, MONTHS_OF_SUPPLY_CHART_FEATURES)
  ) {
    slides.push(
      <MonthsOfSupplyChart
        reportId={reportId}
        chartDimensions={chartDimensions}
      />
    );
  }
  return (
    <Carousel
      className={className}
      dataHcName={dataHcName}
      slides={slides}
      theme={{
        ContentCell: styles.ContentCell,
      }}
    />
  );
};
