import {
  AllMsaDetailsQuery,
  AllMsaZipDetailsQuery,
  ListingStatsFragment,
  MsaCoreFragment,
  MsaDetailsQuery,
  MsaHcriCoreFragment,
  MsaHpiCoreFragment,
  MsaHpiTimeSeriesFragment,
  MsaHpiTimeSeriesQuery,
  MsaListingStatsTimeSeriesQuery,
  MsaRpiCoreFragment,
  MsaRpiTimeSeriesQuery,
  RpiTimeSeriesFragment,
  ZipCoreFragment,
  ZipDetailsQuery,
  ZipHcriCoreFragment,
  ZipHpiCoreFragment,
  ZipHpiTimeSeriesFragment,
  ZipHpiTimeSeriesQuery,
  ZipListingStatsTimeSeriesQuery,
  ZipRpiCoreFragment,
  ZipRpiTimeSeriesQuery,
} from '@hcs/types';
import {
  CerberusStatsHcriCore,
  CerberusStatsHpiCore,
  CerberusStatsHpiTS,
  CerberusStatsHpiTSRecord,
  CerberusStatsListingStats,
  CerberusStatsMsa,
  CerberusStatsMsaCalculatedFields,
  CerberusStatsRentalListingStats,
  CerberusStatsRpiCore,
  CerberusStatsRpiTSRecord,
  CerberusStatsType,
  CerberusStatsZip,
  ListingStats,
} from '@hcs/types';
import { logException } from '@hcs/utils';
import { roundToDecimalPlace } from '@hcs/utils';

const getMsaCalculateFields = (
  msaDetails: NonNullable<MsaDetailsQuery['lookupMsaDetails']>
): CerberusStatsMsaCalculatedFields => {
  const calculatedFields: CerberusStatsMsaCalculatedFields = {};
  const ratio = msaDetails.populationStats?.population?.population1YearRatio;
  if (ratio != null) {
    calculatedFields.netPopulationGrowth = roundToDecimalPlace(ratio - 1, 4);
  }
  return calculatedFields;
};

const getListingStats = (
  listingStatsFragment: ListingStatsFragment | null | undefined
): ListingStats | null | undefined => {
  if (listingStatsFragment == null) {
    return listingStatsFragment;
  }
  return {
    ...listingStatsFragment,
    listingsPriceDropPercent:
      listingStatsFragment.listingsPriceDropPercent != null
        ? roundToDecimalPlace(
            listingStatsFragment.listingsPriceDropPercent,
            1
          ) / 100
        : listingStatsFragment.listingsPriceDropPercent,
    listingsPriceDropPercentMovingAvg:
      listingStatsFragment.listingsPriceDropPercentMovingAvg != null
        ? roundToDecimalPlace(
            listingStatsFragment.listingsPriceDropPercentMovingAvg,
            1
          ) / 100
        : listingStatsFragment.listingsPriceDropPercentMovingAvg,
    listingsUnderContractPercent:
      listingStatsFragment.listingsUnderContractPercent != null
        ? roundToDecimalPlace(
            listingStatsFragment.listingsUnderContractPercent,
            1
          ) / 100
        : listingStatsFragment.listingsUnderContractPercent,
    listingsUnderContractPercentMovingAvg:
      listingStatsFragment.listingsUnderContractPercentMovingAvg != null
        ? roundToDecimalPlace(
            listingStatsFragment.listingsUnderContractPercentMovingAvg,
            1
          ) / 100
        : listingStatsFragment.listingsUnderContractPercentMovingAvg,
    listingsRemovedPercent:
      listingStatsFragment.listingsRemovedPercent != null
        ? roundToDecimalPlace(listingStatsFragment.listingsRemovedPercent, 1) /
          100
        : listingStatsFragment.listingsRemovedPercent,
    listingsRemovedPercentMovingAvg:
      listingStatsFragment.listingsRemovedPercentMovingAvg != null
        ? roundToDecimalPlace(
            listingStatsFragment.listingsRemovedPercentMovingAvg,
            1
          ) / 100
        : listingStatsFragment.listingsRemovedPercentMovingAvg,
    monthsOfSupplyMedian:
      listingStatsFragment.monthsOfSupplyMedian != null
        ? roundToDecimalPlace(listingStatsFragment.monthsOfSupplyMedian, 1)
        : listingStatsFragment.monthsOfSupplyMedian,
    monthsOfSupplyMedianMovingAvg:
      listingStatsFragment.monthsOfSupplyMedianMovingAvg != null
        ? roundToDecimalPlace(
            listingStatsFragment.monthsOfSupplyMedianMovingAvg,
            1
          )
        : listingStatsFragment.monthsOfSupplyMedianMovingAvg,
    saleToListPriceMedian:
      listingStatsFragment.saleToListPriceMedian != null
        ? roundToDecimalPlace(listingStatsFragment.saleToListPriceMedian, 1) /
          100
        : listingStatsFragment.saleToListPriceMedian,
    saleToListPriceMedianMovingAvg:
      listingStatsFragment.saleToListPriceMedianMovingAvg != null
        ? roundToDecimalPlace(
            listingStatsFragment.saleToListPriceMedianMovingAvg,
            1
          ) / 100
        : listingStatsFragment.saleToListPriceMedianMovingAvg,
    // this value comes through as a percentage out of 100 but it's easier to deal with as a decimal
    saleToListPriceOriginalMedian:
      listingStatsFragment.saleToListPriceOriginalMedian != null
        ? roundToDecimalPlace(
            listingStatsFragment.saleToListPriceOriginalMedian,
            1
          ) / 100
        : listingStatsFragment.saleToListPriceOriginalMedian,
    saleToListPriceOriginalMedianMovingAvg:
      listingStatsFragment.saleToListPriceOriginalMedianMovingAvg != null
        ? roundToDecimalPlace(
            listingStatsFragment.saleToListPriceOriginalMedianMovingAvg,
            1
          ) / 100
        : listingStatsFragment.saleToListPriceOriginalMedianMovingAvg,
  };
};

const getHpiCore = (
  hpiFragment: MsaHpiCoreFragment | ZipHpiCoreFragment | null | undefined
): CerberusStatsHpiCore | null | undefined => {
  if (hpiFragment == null) {
    return hpiFragment;
  }
  const return1YearForecast =
    hpiFragment.forecastCalcs?.return?.return1YearForecast;
  const risk1YearLoss = hpiFragment.forecastCalcs?.risk1YearLoss;
  return {
    latestMonth: hpiFragment.timeSeriesCurrent,
    return1YearForecast:
      return1YearForecast != null
        ? roundToDecimalPlace(return1YearForecast, 3)
        : return1YearForecast,
    risk1YearLoss:
      risk1YearLoss != null
        ? roundToDecimalPlace(risk1YearLoss, 3)
        : risk1YearLoss,
  };
};

const getRpiCore = (
  rpiFragment: MsaRpiCoreFragment | ZipRpiCoreFragment | null | undefined
): CerberusStatsRpiCore | null | undefined => {
  if (rpiFragment == null) {
    return rpiFragment;
  }
  if (rpiFragment?.forecastCalcs) {
    const return1YearForecast = rpiFragment?.forecastCalcs[0]?.return1Year;
    const risk1YearLoss = rpiFragment.forecastCalcs[0]?.risk1YearLoss;
    return {
      latestMonth: rpiFragment.timeSeriesCurrent,
      return1Year:
        return1YearForecast != null
          ? roundToDecimalPlace(return1YearForecast, 3)
          : return1YearForecast,
      risk1YearLoss:
        risk1YearLoss != null
          ? roundToDecimalPlace(risk1YearLoss, 3)
          : risk1YearLoss,
    };
  } else {
    return null;
  }
};

const getHcriCore = (
  hcriFragment: MsaHcriCoreFragment | ZipHcriCoreFragment | null | undefined
): CerberusStatsHcriCore | null | undefined => {
  if (hcriFragment == null) {
    return hcriFragment;
  }
  return {
    grossYieldMedian: hcriFragment.grossYieldMedian,
  };
};

const getIdFromMsaDetails = (
  msaDetails: NonNullable<MsaDetailsQuery['lookupMsaDetails']>
): MsaCoreFragment['msa'] => {
  return msaDetails.msa;
};

export const msaDetailsToCerberusStatsMsa = (
  msaDetails: NonNullable<MsaDetailsQuery['lookupMsaDetails']>
): CerberusStatsMsa => {
  // it's much easier to deal with a id that has to be there
  // instead of making all the code take this scenario that shouldn't happen, just going to throw an error
  const id = getIdFromMsaDetails(msaDetails);
  if (!id) {
    const error = new Error(
      'msaDetailsToCerberusStatsMsa: null or undefined msa id on msaDetails'
    );
    logException(error);
    throw error;
  }

  return {
    type: CerberusStatsType.Msa,
    id,
    displayName: msaDetails.name,
    ...msaDetails,
    listingStats: getListingStats(msaDetails.listingStats?.latestStats),
    rentalListingStats: getListingStats(
      msaDetails.rentalListingStats?.latestStats
    ),
    hpi: getHpiCore(msaDetails.hpi),
    hcri: getHcriCore(msaDetails.hcri),
    rpi: getRpiCore(msaDetails.rpi),
    populationStats: msaDetails.populationStats?.population,
    calculatedFields: getMsaCalculateFields(msaDetails),
  };
};

export const msaDetailsQueryToCerberusStatsMsa = (
  msaDetailsQuery: MsaDetailsQuery | null | undefined
): CerberusStatsMsa | null | undefined => {
  const lookupMsaDetails = msaDetailsQuery?.lookupMsaDetails;
  if (!lookupMsaDetails) {
    return lookupMsaDetails;
  }
  return msaDetailsToCerberusStatsMsa(lookupMsaDetails);
};

export const allMsaDetailsQueryToCerberusStatsMsa = (
  allMsaDetailsQuery: AllMsaDetailsQuery | null | undefined
): CerberusStatsMsa[] | null => {
  const allMsaDetails = allMsaDetailsQuery?.allMsaDetails;
  if (!allMsaDetails) {
    return null;
  }
  return allMsaDetails.reduce<CerberusStatsMsa[]>((accum, msaDetails) => {
    if (msaDetails) {
      accum.push(msaDetailsToCerberusStatsMsa(msaDetails));
    }
    return accum;
  }, []);
};

export const msaListingStatsTSToCerberusListingStats = (
  msaListingStatsTSQuery: MsaListingStatsTimeSeriesQuery | null | undefined
): {
  saleListingStats: CerberusStatsListingStats[];
  rentalListingStats: CerberusStatsRentalListingStats[];
} | null => {
  const saleTimeSeries =
    msaListingStatsTSQuery?.lookupMsaDetails?.listingStats?.timeSeries;
  const rentalTimeSeries =
    msaListingStatsTSQuery?.lookupMsaDetails?.rentalListingStats?.timeSeries;
  if (!saleTimeSeries || !rentalTimeSeries) {
    return null;
  }
  return {
    saleListingStats: saleTimeSeries.reduce<CerberusStatsListingStats[]>(
      (accum, record) => {
        const listingStatsRecord = getListingStats(record);
        if (listingStatsRecord) {
          accum.push({
            type: CerberusStatsType.ListingStats,
            listingStats: listingStatsRecord,
          });
        }
        return accum;
      },
      []
    ),
    rentalListingStats: rentalTimeSeries.reduce<
      CerberusStatsRentalListingStats[]
    >((accum, record) => {
      const listingStatsRecord = getListingStats(record);
      if (listingStatsRecord) {
        accum.push({
          type: CerberusStatsType.RentalListingStats,
          rentalListingStats: listingStatsRecord,
        });
      }
      return accum;
    }, []),
  };
};

export const zipListingStatsTSToCerberusListingStats = (
  zipListingStatsTSQuery: ZipListingStatsTimeSeriesQuery | null | undefined
): CerberusStatsListingStats[] | null => {
  const timeSeries =
    zipListingStatsTSQuery?.lookupZipDetails?.listingStats?.timeSeries;
  if (!timeSeries) {
    return null;
  }
  return timeSeries.reduce<CerberusStatsListingStats[]>((accum, record) => {
    const listingStatsRecord = getListingStats(record);
    if (listingStatsRecord) {
      accum.push({
        type: CerberusStatsType.ListingStats,
        listingStats: listingStatsRecord,
      });
    }
    return accum;
  }, []);
};

const getIdFromZipDetails = (
  zipDetails: NonNullable<ZipDetailsQuery['lookupZipDetails']>
): ZipCoreFragment['zipcode'] => {
  return zipDetails.zipcode;
};

const zipDetailsToCerberusStatsZip = (
  zipDetails: NonNullable<ZipDetailsQuery['lookupZipDetails']>
): CerberusStatsZip => {
  // it's much easier to deal with a id that has to be there
  // instead of making all the code take this scenario that shouldn't happen, just going to throw an error
  const id = getIdFromZipDetails(zipDetails);
  if (!id) {
    const error = new Error(
      'zipDetailsToCerberusStatsZip: null or undefined zipcode on zipDetails'
    );
    logException(error);
    throw error;
  }
  return {
    type: CerberusStatsType.Zip,
    id,
    displayName: `${
      zipDetails.preferredName ? `${zipDetails.preferredName}, ` : ''
    }${zipDetails.zipcode}`,
    ...zipDetails,
    listingStats: getListingStats(zipDetails.listingStats?.latestStats),
    hpi: getHpiCore(zipDetails.hpi),
    rpi: getRpiCore(zipDetails.rpi),
    hcri: getHcriCore(zipDetails.hcri),
  };
};

export const zipDetailsQueryToCerberusStatsZip = (
  zipDetailsQuery: ZipDetailsQuery | null | undefined
): CerberusStatsZip | null | undefined => {
  const lookupZipDetails = zipDetailsQuery?.lookupZipDetails;
  if (!lookupZipDetails) {
    return lookupZipDetails;
  }
  return zipDetailsToCerberusStatsZip(lookupZipDetails);
};

export const allMsaZipDetailsQueryToCerberusStatsZip = (
  allMsaZipDetailsQuery: AllMsaZipDetailsQuery | null | undefined
): CerberusStatsZip[] | null => {
  const allZipDetails = allMsaZipDetailsQuery?.lookupMsaDetails?.zips;
  if (!allZipDetails) {
    return null;
  }
  return allZipDetails.reduce<CerberusStatsZip[]>((accum, zipDetails) => {
    if (zipDetails) {
      accum.push(zipDetailsToCerberusStatsZip(zipDetails));
    }
    return accum;
  }, []);
};

const getHpiTS = (
  hpiTSRecord: MsaHpiTimeSeriesFragment | ZipHpiTimeSeriesFragment
): CerberusStatsHpiTSRecord => {
  return {
    month: hpiTSRecord.month,
    valueIndexed: hpiTSRecord.valueIndexed,
  };
};

const getRpiTS = (
  rpiTSRecord: RpiTimeSeriesFragment
): CerberusStatsRpiTSRecord => {
  return {
    month: rpiTSRecord.month,
    valueIndexed: rpiTSRecord.valueIndexed,
  };
};

export const msaHpiTSQueryToCerberusHpiTS = (
  msaHpiTSQuery: MsaHpiTimeSeriesQuery | null | undefined
): CerberusStatsHpiTS | null => {
  const hpi = msaHpiTSQuery?.lookupMsaDetails?.hpi;
  if (!hpi) {
    return null;
  }
  return {
    historicalTimeSeries: hpi.historicalTimeSeries?.map(getHpiTS),
    forecastTimeSeries: hpi.forecastTimeSeries?.map(getHpiTS),
  };
};

export const msaRpiTSQueryToCerberusRpiTS = (
  msaRpiTSQuery: MsaRpiTimeSeriesQuery | null | undefined
): CerberusStatsHpiTS | null => {
  const rpi = msaRpiTSQuery?.lookupMsaDetails?.rpi;
  if (!rpi) {
    return null;
  }
  return {
    historicalTimeSeries: rpi.historicalTimeSeries?.map(getRpiTS),
    forecastTimeSeries: rpi.forecastTimeSeries?.map(getRpiTS),
  };
};

export const zipHpiTSQueryToCerberusHpiTS = (
  zipHpiTSQuery: ZipHpiTimeSeriesQuery | null | undefined
): CerberusStatsHpiTS | null => {
  const hpi = zipHpiTSQuery?.lookupZipDetails?.hpi;
  if (!hpi) {
    return null;
  }
  return {
    historicalTimeSeries: hpi.historicalTimeSeries?.map(getHpiTS),
    forecastTimeSeries: hpi.forecastTimeSeries?.map(getHpiTS),
  };
};

export const zipRpiTSQueryToCerberusRpiTS = (
  zipRpiTSQuery: ZipRpiTimeSeriesQuery | null | undefined
): CerberusStatsHpiTS | null => {
  const rpi = zipRpiTSQuery?.lookupZipDetails?.rpi;
  if (!rpi) {
    return null;
  }
  return {
    historicalTimeSeries: rpi.historicalTimeSeries?.map(getRpiTS),
    forecastTimeSeries: rpi.forecastTimeSeries?.map(getRpiTS),
  };
};
