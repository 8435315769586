import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import {
  useComponentDidMount,
  useFixedPortalElement,
  useIsOverflowed,
} from '@hcs/hooks';

import { ButtonProps } from '../../../controls-and-inputs/buttons/Button';
import { CloseButton } from '../../../controls-and-inputs/buttons/CloseButton';
import {
  ActionButtonProps,
  ActionButtons,
} from '../../action-buttons/ActionButtons';
import {
  StatusMessage,
  StatusMessageProps,
} from '../../notifications/StatusMessage';

import styles from './Dialog.module.css';

export type DialogTypes = 'normal' | 'auto' | 'large' | 'fullscreen' | 'small';

export type DialogAction = ButtonProps;

interface DialogTheme {
  Dialog: string;
  Header: string;
  DialogContent: string;
  DialogWrapper: string;
  Actions: string;
  DialogOverlay: string;
}

export interface DialogProps {
  dataHcName: string;
  dataHcEventSection?: string;
  // Children are optional because title, notifications, and actions are enough to render confirmation dialogs
  children?: ReactNode;
  active?: boolean;
  onClose: VoidFunction;
  title?: ReactNode;
  subtitle?: ReactNode;
  theme?: Partial<DialogTheme>;
  actions?: ActionButtonProps['actions'];
  width?: number | string;
  maxHeight?: number | string;
  type?: DialogTypes;
  noContentPadding?: boolean;
  preventClickOutsideClose?: boolean;
  /*
    forceIsOverflowed:
    the dialog will check if it's content is overflowed and style (drop shadow) accordingly automatically
    but if the content uses something like FlexScroll to handle overflow itself, this is a way to tell Dialog that
    content overflow styles need to be applied
  */
  forceIsOverflowed?: boolean;
  isOverlayDialog?: boolean;
  actionPortalId?: string;
  notifications?: StatusMessageProps[];
}

export const DIALOG_ACTIONS_PORTAL_ID = 'dialog-actions-portal';
export const Dialog = ({
  dataHcName,
  dataHcEventSection,
  theme,
  active,
  title,
  onClose,
  actions,
  subtitle,
  children,
  width,
  maxHeight,
  noContentPadding,
  isOverlayDialog,
  type = 'normal',
  preventClickOutsideClose,
  forceIsOverflowed = false,
  actionPortalId = DIALOG_ACTIONS_PORTAL_ID,
  notifications,
}: DialogProps) => {
  const portalElm = useFixedPortalElement();
  const contentElm = useRef<HTMLDivElement | null>(null);
  const isOverflowed = useIsOverflowed(contentElm, [children]);
  const [activeWithTransition, setActiveWithTransition] = useState(false);
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setActiveWithTransition(false);
      onClose();
    }
  };
  useComponentDidMount(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  });
  useEffect(() => {
    setActiveWithTransition(!!active);
  }, [active]);
  if (!portalElm) return null;

  const dialogStyle: CSSProperties = {};

  if (maxHeight) {
    dialogStyle.maxHeight = maxHeight;
  }
  if (width) {
    dialogStyle.width = width;
  }
  return (
    <>
      {createPortal(
        active && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-hc-name={`${dataHcName}-wrapper`}
            className={classNames(styles.DialogWrapper, theme?.DialogWrapper, {
              [styles.active]: activeWithTransition,
              [styles.overlayDialog]: isOverlayDialog,
            })}
          >
            <div
              data-hc-name={dataHcName}
              data-hc-event-section={dataHcEventSection}
              className={classNames(styles.Dialog, theme?.Dialog, {
                [styles[type]]: type,
                [styles.overflowed]: isOverflowed || forceIsOverflowed,
                [styles.noPadding]: noContentPadding,
              })}
              style={dialogStyle}
            >
              <div className={classNames(styles.Header, theme?.Header)}>
                {onClose && (
                  <CloseButton
                    dataHcName={`${dataHcName}-closeButton`}
                    className={styles.CloseButton}
                    onClick={onClose}
                  />
                )}
                {title &&
                  (typeof title === 'string' ? (
                    <h1 data-hc-name={`${dataHcName}-title`}>{title}</h1>
                  ) : (
                    title
                  ))}
                {subtitle && (
                  <h2 data-hc-name={`${dataHcName}-subtitle`}>{subtitle}</h2>
                )}
                {notifications && (
                  <div>
                    {notifications.map((notification) => (
                      <StatusMessage
                        key={notification.dataHcName}
                        {...notification}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div
                ref={contentElm}
                data-hc-name={`${dataHcName}-content`}
                className={classNames(
                  theme?.DialogContent,
                  styles.DialogContent
                )}
              >
                {children}
              </div>
              <ActionButtons
                dataHcName={`${dataHcName}-actions`}
                className={classNames(theme?.Actions, styles.Actions)}
                portalId={actionPortalId}
                actions={actions}
              />
            </div>
            <div
              data-hc-name={`${dataHcName}-overlay`}
              className={classNames(theme?.DialogOverlay, styles.DialogOverlay)}
              onClick={() => {
                if (!preventClickOutsideClose) {
                  onClose();
                }
              }}
            />
          </div>
        ),
        portalElm
      )}
    </>
  );
};
