import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  MsaDetailsDocument,
  MsaDetailsQuery,
  MsaDetailsQueryVariables,
} from '@hcs/types';

import { msaDetailsQueryToCerberusStatsMsa } from '../utils/cerberus-stats.utils';

const QUERY_KEY_CERBERUS_STATS_MSA = 'cerberus-stats-msa';

export const useCerberusStatsMsa = ({ msaId }: MsaDetailsQueryVariables) => {
  return useQuery([QUERY_KEY_CERBERUS_STATS_MSA, msaId], async () => {
    const response = await CerberusApi.fetchQuery<
      MsaDetailsQuery,
      MsaDetailsQueryVariables
    >(MsaDetailsDocument, {
      msaId,
    });
    return msaDetailsQueryToCerberusStatsMsa(response);
  });
};
