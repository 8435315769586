import React, { ReactNode } from 'react';

import { TextButton } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { CompIdentifier, CompTypes, ReportId } from '@hcs/types';

import { CompDetailsHeader } from '../../features/CompDetailsHeader';
import {
  CompTransactionHistory,
  dataHcEventSectionCompHistoryRental,
  dataHcEventSectionCompHistorySale,
} from '../../features/CompTransactionHistory/CompTransactionHistory';

import {
  COMP_TRANSACTION_HISTORY_DIALOG_FEATURES_RENTAL,
  COMP_TRANSACTION_HISTORY_DIALOG_FEATURES_SALE,
} from './CompTransactionHistoryDialog';

interface Props {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  className?: string;
  trigger?: ReactNode;
}

export const COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_SALE =
  COMP_TRANSACTION_HISTORY_DIALOG_FEATURES_SALE;
export const COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_RENTAL =
  COMP_TRANSACTION_HISTORY_DIALOG_FEATURES_RENTAL;
const dataHcName = 'report-property-transaction-history-launcher';
export const CompTransactionHistoryLauncher = ({
  reportId,
  compIdentifier,
  trigger,
  className,
}: Props) => {
  const dataHcEventSection =
    compIdentifier.compType === CompTypes.Rental
      ? dataHcEventSectionCompHistoryRental
      : dataHcEventSectionCompHistorySale;
  const { active, handleOpen, handleClose } = useActiveState();
  return (
    <>
      {trigger ? (
        <span
          onClick={handleOpen}
          data-hc-name={dataHcName}
          data-hc-event-section={dataHcEventSection}
          style={{ cursor: 'pointer' }}
        >
          {trigger}
        </span>
      ) : (
        <TextButton
          onClick={handleOpen}
          dataHcName={dataHcName}
          dataHcEventSection={dataHcEventSection}
        >
          View Transaction History
        </TextButton>
      )}
      <Dialog
        dataHcName={dataHcName}
        dataHcEventSection={dataHcEventSection}
        type="large"
        title={
          <CompDetailsHeader
            reportId={reportId}
            compIdentifier={compIdentifier}
          />
        }
        theme={{
          Dialog: className,
        }}
        active={active}
        onClose={() => {
          handleClose();
        }}
      >
        <CompTransactionHistory
          reportId={reportId}
          compIdentifier={compIdentifier}
        />
      </Dialog>
    </>
  );
};
