import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { AuthApi } from '../api';

const QUERY_KEY_CHECK_RESET_PASSWORD_TOKEN = 'check-reset-password-token';

export const useCheckResetPasswordToken = (token?: string | null) => {
  return useQuery<null, AxiosError<unknown>>(
    [QUERY_KEY_CHECK_RESET_PASSWORD_TOKEN, token],
    async () => {
      if (token) {
        await AuthApi.checkResetPasswordToken(token);
        return null;
      } else {
        // Should never get here because of enabled option below
        throw new Error('[Check Reset Password Token] No Token to Check');
      }
    },
    {
      enabled: !!token,
    }
  );
};
