import { useEffect, useState } from 'react';

const DEFAULT_DEBOUNCE = 500;
export const useDebounce = <T>(value: T, delay: number = DEFAULT_DEBOUNCE) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
