import React from 'react';
import classNames from 'classnames';

import { TableHeaderProps } from '@hcs/types';

import { TableRow } from './TableRow';

import styles from './Table.module.css';

export const TableHeader = (props: TableHeaderProps) => {
  const {
    dataHcName,
    children,
    sticky,
    height,
    isTableHeader,
    isTableRow,
    ...tableRowProps
  } = props;
  return (
    <thead
      data-hc-name={dataHcName}
      className={classNames(styles.TableHeader, {
        [styles.sticky]: sticky,
      })}
    >
      <TableRow
        dataHcName={`${dataHcName}-row`}
        {...tableRowProps}
        height={height}
      >
        {children}
      </TableRow>
    </thead>
  );
};

TableHeader.defaultProps = {
  isTableHeader: true,
};
