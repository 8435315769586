import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './SectionHeader.module.css';

export interface SectionHeaderProps {
  dataHcName: string;
  children: ReactNode;
  className?: string;
}
export const SectionHeader = ({
  children,
  dataHcName,
  className,
}: SectionHeaderProps) => {
  return (
    <h3
      className={classNames(styles.SectionHeader, className)}
      data-hc-name={dataHcName}
    >
      {children}
    </h3>
  );
};
