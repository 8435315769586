import React from 'react';

import { Switch } from '@hcs/design-system';
import { MenuItem } from '@hcs/design-system';
import { HomeMagnifyingGlassIcon } from '@hcs/design-system';
import { useExperienceFlagSystem } from '@hcs/experience-flags';
import { usePatchUiPreferencesForUser } from '@hcs/huell';
import { useUiPreferencesForUser } from '@hcs/huell';

import styles from './UserExperiencePreferences.module.css';

const dataHcName = 'pexp-redesign-control';
export const PexpRedesignControl = () => {
  const { isSuccess, data: userPrefs } = useUiPreferencesForUser();
  const { mutate: patchUserPrefs } = usePatchUiPreferencesForUser();
  const pexpRedesignFlag = useExperienceFlagSystem('PEXP_REDESIGN');
  const pexpRedesignEnabled =
    userPrefs?.pexpRedesignEnabled !== undefined
      ? userPrefs.pexpRedesignEnabled
      : pexpRedesignFlag;
  return (
    <MenuItem
      dataHcName={dataHcName}
      Icon={HomeMagnifyingGlassIcon}
      label="Property Explorer Redesign"
      description="Controls which version of the Property Explorer design is displayed. Disabling will revert to the previous version. Changes will not affect report history."
      contentRight={
        isSuccess &&
        patchUserPrefs && (
          <div className={styles.Switch}>
            <Switch
              switchOffOption={{
                value: false,
                dataHcName: `${dataHcName}-disabled`,
              }}
              switchOnOption={{
                value: true,
                dataHcName: `${dataHcName}-enabled`,
              }}
              dataHcName={`${dataHcName}-switch`}
              value={pexpRedesignEnabled}
              onChange={(value) => {
                patchUserPrefs([
                  {
                    op: 'add',
                    path: '/pexpRedesignEnabled',
                    value,
                  },
                ]);
              }}
            />
            <div className={styles.SwitchLabel}>
              {pexpRedesignEnabled ? 'Enabled' : 'Disabled'}
            </div>
          </div>
        )
      }
    />
  );
};
