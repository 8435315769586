
import { CerberusStatsFields } from '../cerberus-stats';
import { HcMapState } from '../maps';
import { CompFields, PropertyStateFields } from '../property-state';
import { CompsListViewType } from '../report-api';

export enum PersonaTypesOrg {
  CollateralInvestor = 'collateral-investor',
  ServiceProvider = 'service-provider',
  MortgageLender = 'mortgage-lender'
}

export enum PreferencesKeys {
  SubjectDataPriority = 'subjectDataPriority',
  CompTableColumns = 'tableColsComps',
  CompTableWidth = 'tableWidthComps',
  RentalCompTableColumns = 'tableColsRentalComps',
  PhotoGrid = 'photoGrid',
  Maps = 'maps',
  AckRefreshComps = 'skipWarningRefreshComps',
  AckRefreshRentalComps = 'skipWarningRefreshRentalComps',
  ReportFeaturesSubject = 'reportFeaturesSubject',
  ReportFeaturesComps = 'reportFeaturesComps',
  ReportFeaturesRentalComps = 'reportFeaturesRentalComps',
  Persona = 'persona',
  MsaFavorites = 'msaFavorites',
  GeoInterested = 'geoInterested',
  MarketStateColumnsMsa = 'marketStateColumnsMsa',
  MarketStateColumnsZip = 'marketStateColumnsZip',
  MarketStateChartsMsa = 'marketStateChartsMsa',
  MarketStateChartsZip = 'marketStateChartsZip',
  CompsMapSale = 'compsMapSale',
  CompsMapRental = 'compsMapRental',
  PexpRedesignEnabled = 'pexpRedesignEnabled',
  CompListViewTypeDefault = 'compsListViewTypeDefault'
}

export enum ReportFeaturesSubject {
  Comments = 'comments',
  Sharing = 'sharing',
  Download = 'download',
  KeyFacts = 'keyFacts',
  Insights = 'insights',
  Personalization = 'personalization',
  Refresh = 'refresh',
  HeatMaps = 'heatmaps'
}

export enum ReportFeaturesComps {
  Enabled = 'enabled',
  RefreshComps = 'refreshReport',
  AddCompByMlsNumber = 'addCompByMlsNumber',
  SavedCompFilters = 'savedCompFilters',
  Tags = 'tags',
  AdjustComps = 'adjustComps',
  CustomAreaCompSearch = 'customAreaCompSearch',
  AutoSelectHcSuggestedComps = 'autoSelectHcSuggestedComps',
  KeywordSearch = 'keywordSearch'
}

export enum ReportFeaturesRentalComps {
  Enabled = 'enabled',
  RefreshComps = 'refreshReport',
  AddCompByMlsNumber = 'addCompByMlsNumber',
  SavedCompFilters = 'savedCompFilters',
  Tags = 'tags',
  AdjustComps = 'adjustComps',
  CustomAreaCompSearch = 'customAreaCompSearch',
  AutoSelectHcSuggestedComps = 'autoSelectHcSuggestedComps',
  KeywordSearch = 'keywordSearch'
}

export interface MapPreferences {
  disableClusters: boolean;
}

export interface PhotoGridPreferences {
  size: number;
}

export type InactiveColumns<T extends string> = {
  [key in T]?: boolean;
};

export interface DataPriority<T extends string> {
  inactive: InactiveColumns<T>;
  order: T[];
}

export type DataPrioritySubject = DataPriority<PropertyStateFields>;
export type DataPriorityComp = DataPriority<PropertyStateFields | CompFields>;
export type DataPriorityCerberusStats = DataPriority<CerberusStatsFields>;

export enum GeoInterestedType {
  msas = 'msas',
  zips = 'zips'
}
export interface GeoInterested {
  [GeoInterestedType.msas]?: string[];
  [GeoInterestedType.zips]?: string[];
}

export interface HcMapPreferences {
  heatmap: Omit<HcMapState['heatmap'], 'propertyType'>;
}

export interface UiPreferences {
  [PreferencesKeys.SubjectDataPriority]?: DataPrioritySubject;
  [PreferencesKeys.CompTableColumns]?: DataPriorityComp;
  [PreferencesKeys.CompTableWidth]?: number;
  [PreferencesKeys.RentalCompTableColumns]?: DataPriorityComp;
  [PreferencesKeys.PhotoGrid]?: PhotoGridPreferences;
  [PreferencesKeys.Maps]?: MapPreferences;
  [PreferencesKeys.AckRefreshComps]?: boolean;
  [PreferencesKeys.AckRefreshRentalComps]?: boolean;
  [PreferencesKeys.Persona]?: PersonaTypesOrg;
  [PreferencesKeys.ReportFeaturesSubject]?: {
    [key in ReportFeaturesSubject]?: boolean;
  };
  [PreferencesKeys.ReportFeaturesComps]?: {
    [key in ReportFeaturesComps]?: boolean;
  };
  [PreferencesKeys.ReportFeaturesRentalComps]?: {
    [key in ReportFeaturesRentalComps]?: boolean;
  };
  [PreferencesKeys.MsaFavorites]?: string[];
  [PreferencesKeys.GeoInterested]?: GeoInterested;
  [PreferencesKeys.MarketStateChartsMsa]?: DataPriorityCerberusStats;
  [PreferencesKeys.MarketStateChartsZip]?: DataPriorityCerberusStats;
  [PreferencesKeys.MarketStateColumnsMsa]?: DataPriorityCerberusStats;
  [PreferencesKeys.MarketStateColumnsZip]?: DataPriorityCerberusStats;
  [PreferencesKeys.CompsMapSale]?: HcMapPreferences;
  [PreferencesKeys.CompsMapRental]?: HcMapPreferences;
  [PreferencesKeys.PexpRedesignEnabled]?: boolean;
  [PreferencesKeys.CompListViewTypeDefault]?: CompsListViewType;
}
