import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { LayoutGlobalHeaderOutlet } from '@hcs/design-system';
import { LayoutGlobalHeaderChildren } from '@hcs/design-system';
import { AppAccessDevTool } from '@hcs/dev-tools';
import {
  APP_CONFIG_ORG_ADMIN,
  APP_CONFIG_PLATFORM,
  VIEW_PATHS_ORG_ADMIN,
} from '@hcs/hc-products';

import { PrivatePageRedirect } from '../../..//auth-redirects/PrivatePageRedirect';
import { NoAccessibleApplicationsRedirect } from '../../../auth-redirects/NoAccessibleApplicationsRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { DownloadAssetsPage, PlatformPage } from '../pages';

export const PLATFORM_ROOT_PATH = APP_CONFIG_PLATFORM.rootPath;
export const ORG_ADMIN_ROOT_PATH = APP_CONFIG_ORG_ADMIN.rootPath;
export const DOWNLOAD_ASSETS_ROOT_PATH = '/download-assets';

export enum PLATFORM_VIEW_PATHS {
  ORGANIZATION_SETTINGS_LEGACY = 'organization-settings',
}

export const routes = (): RouteObject[] => [
  {
    path: PLATFORM_ROOT_PATH,
    element: (
      <PrivatePageRedirect>
        <LayoutGlobalHeaderOutlet header={<SolutionsPlatformGlobalHeader />} />
      </PrivatePageRedirect>
    ),
    children: [
      {
        path: PLATFORM_ROOT_PATH,
        element: (
          <NoAccessibleApplicationsRedirect>
            <PlatformPage />
            <AppAccessDevTool />
          </NoAccessibleApplicationsRedirect>
        ),
      },
      {
        path: `${PLATFORM_ROOT_PATH}/${PLATFORM_VIEW_PATHS.ORGANIZATION_SETTINGS_LEGACY}`,
        element: (
          <Navigate
            to={`${ORG_ADMIN_ROOT_PATH}/${VIEW_PATHS_ORG_ADMIN.ORG_USERS}`}
            replace
          />
        ),
      },
    ],
  },
  {
    path: DOWNLOAD_ASSETS_ROOT_PATH,
    element: (
      <PrivatePageRedirect>
        <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
          <DownloadAssetsPage />
        </LayoutGlobalHeaderChildren>
      </PrivatePageRedirect>
    ),
  },
];
