import React, { useState } from 'react';

import { Dropdown } from '@hcs/design-system';
import { ReportConfigApi } from '@hcs/types';

import { useReportConfigs } from '../../hooks/useReportConfigs';

interface Props {
  onSelect: (reportConfigApi: ReportConfigApi) => void;
}

const dataHcName = 'configurable-report-dropdown';
export const ConfigurableReportDropdown = ({ onSelect }: Props) => {
  const { data: reportConfigs } = useReportConfigs();
  const [selectedReportConfigSlug, setSelectedReportConfigSlug] = useState<
    string | null
  >(null);

  return (
    <Dropdown
      dataHcName={`${dataHcName}-config-selector`}
      onSelect={(reportConfigSlug) => {
        setSelectedReportConfigSlug(reportConfigSlug);
        const selectedConfig = reportConfigs?.find(
          (c) => c.reportConfigSlug === reportConfigSlug
        );
        if (selectedConfig) {
          onSelect(selectedConfig);
        }
      }}
      options={[
        { label: 'Report Default', value: null },
        ...(reportConfigs?.map((config) => {
          return {
            label: config.reportName,
            value: config.reportConfigSlug,
          };
        }) || []),
      ]}
      value={selectedReportConfigSlug}
      placeholder="Apply Report Config"
    />
  );
};
