import React, { ReactNode } from 'react';
import classNames from 'classnames';

import {
  CARET_DIMENSIONS_DEFAULT,
  Popover,
  PopoverTheme,
  TooltipContentPosition,
} from '../Popover';

import styles from './Tooltip.module.css';

export interface TooltipTheme extends PopoverTheme {
  Content?: string;
}

export interface TooltipProps {
  dataHcName: string;
  trigger: ReactNode;
  label?: ReactNode;
  description?: ReactNode;
  isLargeTooltip?: boolean;
  position?: TooltipContentPosition;
  showOnMouseEnter?: boolean;
  theme?: TooltipTheme;
  active?: boolean;
}

export const TOOLTIP_OFFSET = {
  anchorBottom: -CARET_DIMENSIONS_DEFAULT.height,
  anchorTop: CARET_DIMENSIONS_DEFAULT.height,
};

export const Tooltip = ({
  dataHcName,
  trigger,
  label,
  description,
  isLargeTooltip,
  position,
  showOnMouseEnter = true,
  theme,
  active,
}: TooltipProps) => {
  if (!label && !description) {
    console.warn(`
    ⚠️ Attention ⚠️
    Component Tooltip is not rendering content.
    You must add either a label or description.
    `);
  }

  const content = (
    <div
      data-hc-name={`${dataHcName}-popover-content`}
      className={classNames(styles.PopoverContent, theme?.Content, {
        [styles.textAlignLeft]: isLargeTooltip,
        [styles.noDescription]: isLargeTooltip && !description,
      })}
    >
      {label}
      {description && (
        <div
          data-hc-name={`${dataHcName}-popover-description`}
          className={classNames(styles.Description, {
            [styles.textAlignLeft]: isLargeTooltip,
          })}
        >
          {description}
        </div>
      )}
    </div>
  );

  return (
    <Popover
      active={active}
      positionOffset={TOOLTIP_OFFSET}
      position={position}
      theme={{
        ...theme,
        Caret: classNames(styles.Caret, theme?.Caret),
      }}
      renderCaret
      showOnMouseEnter={showOnMouseEnter}
      trigger={trigger}
      content={content}
      dataHcName={dataHcName}
    />
  );
};
