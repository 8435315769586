import React, { ReactNode } from 'react';

import { Anchor } from '@hcs/design-system';
import { CERBERUS_INTERNAL_API_DOCS_URL } from '@hcs/urls';

interface Props {
  className?: string;
  query?: string;
  variables?: Record<string, unknown>;
  children?: ReactNode;
  /** Text to replace when navigating to the api docs, used for populating query inputs with values */
  queryLinkReplace?: [string, string];
}
const dataHcName = 'cerberus-api-docs-link';
export const CerberusApiDocsLink = ({
  className,
  query,
  variables,
  queryLinkReplace,
  children = 'Cerberus API Docs',
}: Props) => {
  return (
    <Anchor
      dataHcName={dataHcName}
      target="_blank"
      className={className}
      href={
        query
          ? `${CERBERUS_INTERNAL_API_DOCS_URL}?query=${encodeURIComponent(
              queryLinkReplace
                ? query.replace(queryLinkReplace[0], queryLinkReplace[1])
                : query
            )}${
              variables
                ? `&variables=${encodeURIComponent(JSON.stringify(variables))}`
                : ''
            }`
          : CERBERUS_INTERNAL_API_DOCS_URL
      }
    >
      {children}
    </Anchor>
  );
};
