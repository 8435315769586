import React, { CSSProperties, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useFixedPortalElement } from '@hcs/hooks';

import styles from './LoadingSpinner.module.css';

export interface LoadingSpinnerProps {
  dataHcName: string;
  small?: boolean;
  large?: boolean;
  className?: string;
  // Positions content in the center of the nearest non-static parent
  absoluteCenter?: boolean;
  fixedCenter?: boolean;
  // Renders inline
  inline?: boolean;
  containerHeight?: string;
  containerWidth?: string;
}
export const LoadingSpinner = ({
  dataHcName,
  small = false,
  large = true,
  absoluteCenter = true,
  fixedCenter,
  inline = false,
  className,
  containerHeight,
  containerWidth,
}: LoadingSpinnerProps) => {
  const portalRootRef = useRef(useFixedPortalElement());
  const spinner = (
    <div
      className={classNames(styles.LoadingSpinner, className, {
        [styles.large]: large,
        [styles.small]: small,
        [styles.absoluteCenter]: absoluteCenter && !inline,
      })}
      data-hc-name={dataHcName}
    >
      <svg className={styles.Svg} x="0px" y="0px" viewBox="0 0 150 150">
        <circle className={styles.Circle} cx="75" cy="75" r="60" />
      </svg>
    </div>
  );
  if (fixedCenter) {
    if (!portalRootRef.current) return null;
    return createPortal(spinner, portalRootRef.current);
  } else if (absoluteCenter) {
    const inlineStyles: CSSProperties = {};
    if (containerHeight) {
      inlineStyles.height = containerHeight;
    }
    if (containerWidth) {
      inlineStyles.width = containerWidth;
    }
    return (
      <div style={inlineStyles} className={styles.AbsoluteCenterContainer}>
        {spinner}
      </div>
    );
  } else {
    return spinner;
  }
};
