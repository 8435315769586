import React from 'react';
import classNames from 'classnames';

import { formatNumber } from '@hcs/utils';

import styles from './SmallCountChip.module.css';

export interface SmallCountChipProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  count?: number | null;
}
export const SmallCountChip = ({
  dataHcName,
  className,
  count,
}: SmallCountChipProps) => {
  if (!count) return null;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SmallCountChip, className)}
    >
      {formatNumber(count)}
    </div>
  );
};
