import { AppConfig, AppSlugs } from '@hcs/types';

import {
  APP_CONFIG_AEXP,
  APP_CONFIG_AGILE_SUITE,
  APP_CONFIG_DEXP,
  APP_CONFIG_MARKET_INSIGHTS,
  APP_CONFIG_PEXP,
  APP_CONFIG_PORTFOLIO,
} from '../constants';

const HC_PRODUCT_APP_CONFIGS = {
  [AppSlugs.AcquisitionExplorer]: APP_CONFIG_AEXP,
  [AppSlugs.AgileSuite]: APP_CONFIG_AGILE_SUITE,
  [AppSlugs.DataExplorer]: APP_CONFIG_DEXP,
  [AppSlugs.MarketInsights]: APP_CONFIG_MARKET_INSIGHTS,
  [AppSlugs.PortfolioMonitor]: APP_CONFIG_PORTFOLIO,
  [AppSlugs.PropertyExplorer]: APP_CONFIG_PEXP,
};

export const useHcProductsAppConfigs = (): Partial<
  Record<AppSlugs, AppConfig>
> => {
  return HC_PRODUCT_APP_CONFIGS;
};
