import { Account } from '@hcs/types';
import { AuthHeaderFn } from '@hcs/types';

import { queryClient } from '../clients';
import { ACCOUNT_QUERY_KEY } from '../constants';

export const authHeaderFnJwt: AuthHeaderFn = (token: string | null) => {
  if (token) {
    return { authorization: `JWT ${token}` };
  }
  return undefined;
};

export const authHeaderFnAccountApi: AuthHeaderFn = (token: string | null) => {
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  return undefined;
};

export const authHeaderFnEhrmantraut: AuthHeaderFn = (token: string | null) => {
  if (token) {
    return {
      'HC-API-Auth': `Bearer ${token || ''}`,
    };
  }
  return undefined;
};

export const getHcAuthPrincipals = () => {
  const account = queryClient.getQueryData<Account>([ACCOUNT_QUERY_KEY]);
  return {
    'HC-Auth-Principals': [
      `Organization=${account?.currentOrganization.id}`,
      `User=${account?.user.id}`,
      ...(account?.user.roles.map((r) => `Role=${r.id}`) || []),
    ].join('&'),
  };
};

export const getHcAuthHeader = () => {
  return authHeaderFnEhrmantraut(
    queryClient.getQueryData<Account>([ACCOUNT_QUERY_KEY])?.validity.token ||
      null
  );
};
