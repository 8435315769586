import {
  AppraisalSubjectValueDocument,
  DocumentRoles,
  ReportId,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useAppraisalSubjectValueDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<AppraisalSubjectValueDocument>(
    reportId,
    DocumentRoles.AppraisalSubjectValue
  );
};
