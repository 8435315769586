import { CerberusStatsFields } from '@hcs/types';

export const LISTING_STATS_RENTAL_FIELDS = [
  CerberusStatsFields.daysOnMarketMedianRental,
  CerberusStatsFields.daysOnMarketMedianMovingAvgRental,
  CerberusStatsFields.daysOnMarketNotContractMedianRental,
  CerberusStatsFields.daysOnMarketNotContractMedianMovingAvgRental,
  CerberusStatsFields.daysToCloseMedianRental,
  CerberusStatsFields.daysToCloseMedianMovingAvgRental,
  CerberusStatsFields.daysToContractMedianRental,
  CerberusStatsFields.daysToContractMedianMovingAvgRental,
  CerberusStatsFields.daysToFirstPriceDropMedianRental,
  CerberusStatsFields.daysToFirstPriceDropMedianMovingAvgRental,
  CerberusStatsFields.listingsClosedCountRental,
  CerberusStatsFields.listingsClosedCountMovingAvgRental,
  CerberusStatsFields.listingsNewCountRental,
  CerberusStatsFields.listingsNewCountMovingAvgRental,
  CerberusStatsFields.listingsOnMarketCountRental,
  CerberusStatsFields.listingsOnMarketCountMovingAvgRental,
  CerberusStatsFields.listingsPriceChangeCountRental,
  CerberusStatsFields.listingsPriceChangeCountMovingAvgRental,
  CerberusStatsFields.listingsPriceDropCountRental,
  CerberusStatsFields.listingsPriceDropCountMovingAvgRental,
  CerberusStatsFields.listingsPriceDropPercentRental,
  CerberusStatsFields.listingsPriceDropPercentMovingAvgRental,
  CerberusStatsFields.listingsRemovedCountRental,
  CerberusStatsFields.listingsRemovedCountMovingAvgRental,
  CerberusStatsFields.listingsRemovedPercentRental,
  CerberusStatsFields.listingsRemovedPercentMovingAvgRental,
  CerberusStatsFields.listingsUnderContractCountRental,
  CerberusStatsFields.listingsUnderContractCountMovingAvgRental,
  CerberusStatsFields.listingsUnderContractPercentRental,
  CerberusStatsFields.listingsUnderContractPercentMovingAvgRental,
  CerberusStatsFields.listingStatsEventDateRental,
  CerberusStatsFields.monthsOfSupplyMedianRental,
  CerberusStatsFields.monthsOfSupplyMedianMovingAvgRental,
  CerberusStatsFields.priceClosedMedianRental,
  CerberusStatsFields.priceClosedMedianMovingAvgRental,
  CerberusStatsFields.priceNewListMedianRental,
  CerberusStatsFields.priceNewListMedianMovingAvgRental,
  CerberusStatsFields.priceOnMarketMedianRental,
  CerberusStatsFields.priceOnMarketMedianMovingAvgRental,
  CerberusStatsFields.pricePerSqftClosedMedianRental,
  CerberusStatsFields.pricePerSqftClosedMedianMovingAvgRental,
  CerberusStatsFields.pricePerSqftNewListMedianRental,
  CerberusStatsFields.pricePerSqftNewListMedianMovingAvgRental,
  CerberusStatsFields.pricePerSqftOnMarketMedianRental,
  CerberusStatsFields.pricePerSqftOnMarketMedianMovingAvgRental,
  CerberusStatsFields.saleToListPriceMedianRental,
  CerberusStatsFields.saleToListPriceMedianMovingAvgRental,
  CerberusStatsFields.saleToListPriceOriginalMedianRental,
  CerberusStatsFields.saleToListPriceOriginalMedianMovingAvgRental,
];
