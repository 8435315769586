import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { NewOrderApi } from '../api/newOrderApi';

interface Params {
  orderId: number;
  excludeJson: boolean;
}

export const useInitiateNewDownload = (
  options?: UseMutationOptions<void, AxiosError, Params>
) =>
  useMutation<void, AxiosError, Params>(
    async ({ orderId, excludeJson }) =>
      await NewOrderApi.initiateNewDownload(orderId, excludeJson),
    options
  );
