import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OrgAdminApi } from '@hcs/org-admin';
import { useToastSlice } from '@hcs/toast';
import { Organization } from '@hcs/types';
import {
  CreateInvitationPayload,
  RoleAssignmentUpdate,
  UserApplicationUpdateExternal,
} from '@hcs/types';

import { QUERY_KEY_ORG_USERS_LEGACY } from '../hooks/useOrgUsersLegacy';

interface Payload {
  orgId: Organization['id'];
  firstName: string;
  lastName: string;
  email: string;
  roles: RoleAssignmentUpdate[];
  applications?: UserApplicationUpdateExternal[];
}
export const useCreateInvitationLegacy = (
  options: UseMutationOptions<void, AxiosError<{ message: string }>, Payload>
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError<{ message: string }>, Payload>(
    async ({ orgId, roles, ...payload }: Payload) => {
      const createInvitePayload: CreateInvitationPayload = {
        ...payload,
        sendEmail: true,
        organizationId: orgId,
        roles: roles.map((r) => ({ id: r.roleId })),
      };
      await OrgAdminApi.createInvitation(createInvitePayload);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_KEY_ORG_USERS_LEGACY]);
        toastOpen({
          type: 'success',
          title: 'Successfully sent invitation',
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
