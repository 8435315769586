import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItem } from '@hcs/types';

import { DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL } from '../../constants';
import { EditOrderItem } from '../EditOrderItem';

import { DisplayProps } from '.';

const Display = ({ orderItem, orderId }: DisplayProps) => (
  <div
    data-hc-name={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-need-review-actions`}
  >
    {orderId ? <EditOrderItem orderId={orderId} orderItem={orderItem} /> : null}
  </div>
);

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Actions</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  orderId,
  ...tableCellProps
}: {
  orderItem: OrderItem;
  orderId: number;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} orderId={orderId} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_ITEM_NEED_REVIEW_ACTIONS_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Actions',
};
