import { useMemo } from 'react';

import { getMapTileImage } from '@hcs/maps';
import { useStreetView } from '@hcs/street-view';
import {
  PropertyStateImageExtended,
  PropertyStateImagesResult,
} from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { geoPrecisionSupportsStreetView } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { useSubjectDocument } from '../hooks';
import { useReportConfig } from '../hooks/useReportConfig';
import { useSubjectPhotosDocument } from '../hooks/useSubjectPhotosDocument';
import { reportFeaturesSupportedAny } from '../utils/reportConfig.utils';

export const useSubjectPhotos = (reportId: ReportId) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const subjectQuery = useSubjectDocument(reportId);
  const subjectPhotosQuery = useSubjectPhotosDocument(reportId);
  const streetViewSupported =
    geoPrecisionSupportsStreetView(
      subjectQuery.data?.data.propertyState.location
    ) &&
    reportFeaturesSupportedAny(reportConfig, [ReportFeatures.CompsStreetview]);
  const streetViewQuery = useStreetView(
    streetViewSupported &&
      subjectQuery.data?.data.propertyState.location?.latitude &&
      subjectQuery.data?.data.propertyState.location.longitude
      ? subjectQuery.data?.data.propertyState.location
      : undefined,
    {
      size: 'lg',
      publicLinkUid: typeof reportId === 'string' ? reportId : undefined,
    }
  );
  const combinedQuery = combineUseQueryResult([
    subjectQuery,
    streetViewQuery,
    subjectPhotosQuery,
  ]);
  const data = useMemo(() => {
    let allPhotos: PropertyStateImageExtended[] = [];
    subjectPhotosQuery.data?.data.listingPhotos?.forEach((l) => {
      if (l.media?.images) {
        allPhotos = [
          ...allPhotos,
          ...(l.mls?.regulations?.logoOverlay
            ? l.media?.images.map((i) => {
                return { ...i, overlayUrl: l.mls?.regulations?.logoOverlay };
              }) || []
            : l.media.images),
        ];
      }
    });
    const frontPhoto = allPhotos?.[0];
    const streetView =
      streetViewSupported && streetViewQuery.data
        ? {
            url: streetViewQuery.data.url,
          }
        : undefined;
    if (streetView) {
      allPhotos.push(streetView);
    }
    const previewPhoto = frontPhoto || streetView;
    const mapTile = getMapTileImage(subjectQuery.data?.data.propertyState);
    const result: PropertyStateImagesResult | undefined =
      combinedQuery.isInitialLoading
        ? undefined
        : {
            previewPhoto,
            frontPhoto,
            streetView,
            mapTile: mapTile
              ? {
                  url: mapTile,
                }
              : undefined,
            listingPreviewPhoto: allPhotos?.[1],
            listingPhotos: subjectPhotosQuery.data?.data.listingPhotos,
            totalPhotos: allPhotos?.length || 0,
            allPhotos,
          };
    return result;
  }, [
    combinedQuery.isInitialLoading,
    subjectPhotosQuery.data,
    streetViewQuery.data,
    streetViewSupported,
  ]);

  return {
    ...combinedQuery,
    data,
  };
};
