import React, { Children, createElement, ReactElement } from 'react';
import { MapRef, Marker, useMap } from 'react-map-gl';
import { point } from '@turf/helpers';
import { Point } from 'mapbox-gl';
import Supercluster from 'supercluster';

import { MapCluster } from '@hcs/design-system';
import { GeoLocation } from '@hcs/types';

import { useHcMap } from '../../hooks/useHcMap';
import { fitGeoLocationsToViewPort } from '../../utils/viewport.utils';

interface PrivateProps {
  mapId: string;
  /** Mapbox map object */
  map: MapRef;
  /** Minimum zoom level at which clusters are generated */
  minZoom?: number;
  /** Maximum zoom level at which clusters are generated */
  maxZoom?: number;
  /** MapCluster radius; in pixels */
  radius?: number;
  /** (Tiles) Tile extent. Radius is calculated relative to this value */
  extent?: number;
  /** Size of the KD-tree leaf node. Affects performance */
  nodeSize?: number;
  /** Markers as children */
  children: ReactElement[];
  /** When cluster clicked */
  onClick?: (
    cluster:
      | Supercluster.ClusterFeature<Supercluster.AnyProps>
      | Supercluster.PointFeature<Supercluster.AnyProps>,
    superCluster: Supercluster<Supercluster.AnyProps, Supercluster.AnyProps>
  ) => void;
  /** Zoom to expand the cluster or zoom the map to fit all markers within the bounds */
  onClickBehavior?: 'expand' | 'fit';
}

const PADDING = 100;
const ANIMATION_DURATION = 300;
const MARKER_POINT = new Point(-28 / 2, -28);

const _Cluster = ({
  mapId,
  map,
  onClick,
  onClickBehavior = 'expand',
  children,
  minZoom = 0,
  maxZoom = 20,
  radius = 20,
  extent = 512,
  nodeSize = 10,
}: PrivateProps) => {
  const {
    mapState,
    actions: { hcMapViewportChange, hcMapFitBoundsToGeoLocations },
  } = useHcMap(mapId);

  const points = Children.map(children, (child) => {
    if (child) {
      let longitude = child.props.longitude;
      let latitude = child.props.latitude;
      if (child.props.geoLocation) {
        const geoLocation = child.props.geoLocation as GeoLocation;
        longitude = geoLocation.longitude;
        latitude = geoLocation.latitude;
      }
      if (longitude && latitude) {
        return point([longitude, latitude], child);
      }
    }
    console.warn('[Cluster] Marker passed without a location.');
    return undefined;
  });
  const superCluster = new Supercluster({
    minZoom,
    maxZoom,
    radius,
    extent,
    nodeSize,
  }).load(points);
  const zoom = map.getZoom();
  const bounds = map.getBounds().toArray();
  if (
    !bounds[0] ||
    !bounds[0][0] ||
    !bounds[0][1] ||
    !bounds[1] ||
    !bounds[1][0] ||
    !bounds[1][1]
  )
    return null;
  const bbox: [number, number, number, number] = [
    bounds[0][0],
    bounds[0][1],
    bounds[1][0],
    bounds[1][1],
  ];
  const clusters = superCluster
    .getClusters(bbox, Math.floor(zoom))
    .map((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;

      if (cluster.properties.cluster && longitude && latitude) {
        return (
          <Marker
            longitude={longitude}
            latitude={latitude}
            offset={MARKER_POINT}
            key={`cluster-${cluster.properties.cluster_id}`}
          >
            <MapCluster
              onClick={() => {
                if (cluster.id && (onClick || onClickBehavior)) {
                  const clusterId = Number(cluster.id);
                  if (onClickBehavior === 'expand') {
                    const expansionZoom =
                      superCluster.getClusterExpansionZoom(clusterId);

                    map.flyTo({
                      zoom: expansionZoom + 1,
                      duration: ANIMATION_DURATION,
                      center: { lng: longitude, lat: latitude },
                    });
                    hcMapViewportChange({
                      mapId,
                      viewport: {
                        zoom: expansionZoom + 1,
                        latitude,
                        longitude,
                      },
                    });
                  } else if (onClickBehavior === 'fit') {
                    const markers = superCluster.getLeaves(clusterId);
                    const geoLocationsToFit: GeoLocation[] = [];
                    markers.forEach((marker) => {
                      if (
                        marker.geometry.type === 'Point' &&
                        marker.geometry.coordinates[0] &&
                        marker.geometry.coordinates[1]
                      ) {
                        geoLocationsToFit.push({
                          latitude: marker.geometry.coordinates[1],
                          longitude: marker.geometry.coordinates[0],
                        });
                      }
                    });

                    if (geoLocationsToFit.length && mapState) {
                      const viewportInfo = fitGeoLocationsToViewPort(
                        geoLocationsToFit,
                        mapState,
                        PADDING
                      );
                      map.flyTo({
                        center: viewportInfo?.coords,
                        zoom: viewportInfo?.viewport?.zoom,
                        duration: ANIMATION_DURATION,
                      });
                      hcMapFitBoundsToGeoLocations({
                        mapId,
                        fitId: `cluster-${clusterId}`,
                        coords: geoLocationsToFit,
                      });
                    }
                  }
                }
                onClick?.(cluster, superCluster);
              }}
              dataHcName={'fe-cluster'}
              pointCount={cluster.properties.point_count}
            />
          </Marker>
        );
      }
      const { type, key, props } = cluster.properties;
      return createElement(type, { key, ...props });
    });
  return clusters;
};

export interface ClusterProps {
  mapId: string;
  children: ReactElement[];
  /** Minimum zoom level at which clusters are generated */
  minZoom?: number;
  /** Maximum zoom level at which clusters are generated */
  maxZoom?: number;
  /** MapCluster radius; in pixels */
  radius?: number;
  /** (Tiles) Tile extent. Radius is calculated relative to this value */
  extent?: number;
  /** Size of the KD-tree leaf node. Affects performance */
  nodeSize?: number;
  /** Callback when cluster clicked */
  onClick?: PrivateProps['onClick'];
  /** Zoom to expand the cluster or zoom the map to fit all markers within the bounds */
  onClickBehavior?: PrivateProps['onClickBehavior'];
}
// Wrapper Component to ensure map is available before rendering cluster
export const Cluster = (props: ClusterProps) => {
  const { current: map } = useMap();
  if (!map) return null;
  return <>{_Cluster({ map, ...props })}</>;
};
