import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './UserMenu.module.css';

interface UserMenuButtonsProps {
  dataHcName: string;
  className?: string;
  children: ReactNode;
}
export const UserMenuButtons = ({
  dataHcName,
  className,
  children,
}: UserMenuButtonsProps) => {
  return (
    <ul
      data-hc-name={dataHcName}
      className={classNames(styles.Buttons, className)}
    >
      {children}
    </ul>
  );
};
