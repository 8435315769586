import React, { ReactNode, SyntheticEvent } from 'react';
import isEmpty from 'lodash.isempty';

import { Button } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { useActiveState } from '@hcs/hooks';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { SavedCompFilterSetFormDialog } from '../../features/SavedCompFilterSetForm/SavedCompFilterSetFormDialog';
import { SavedCompFilterSetFormDialogDeprecated } from '../../features/SavedCompFilterSetForm/SavedCompFilterSetFormDialogDeprecated';
import { useAppliedSavedCompFilterSet } from '../../hooks/useAppliedSavedCompFilterSet';
import { useCompsFiltersDocument } from '../../hooks/useCompsFiltersDocument';

import {
  dataHcEventSectionSaveFilterSetComps,
  dataHcEventSectionSaveFilterSetRental,
} from './SavedCompFilterSetForm';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  label?: string;
  onClickTrigger?: (e: SyntheticEvent) => void;
  trigger?: ReactNode;
  onSuccess?: () => void;
}
const dataHcName = 'saved-comp-filter-form-launcher';
export const SavedCompFilterSetFormLauncher = ({
  reportId,
  compType,
  trigger,
  onClickTrigger,
  label = 'Save Filters',
  onSuccess,
}: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const {
    data: { appliedFilterSetId },
  } = useAppliedSavedCompFilterSet(reportId, compType);
  const dataHcEventSection =
    compType === CompTypes.Rental
      ? dataHcEventSectionSaveFilterSetRental
      : dataHcEventSectionSaveFilterSetComps;

  if (isEmpty(filterDocument?.data.filters) || appliedFilterSetId) {
    return null;
  }

  return (
    <>
      {trigger ? (
        <span
          data-hc-name={dataHcName}
          data-hc-event-section={dataHcEventSection}
          onClick={(e) => {
            handleOpen();
            onClickTrigger?.(e);
          }}
        >
          {trigger}
        </span>
      ) : (
        <Button
          dataHcName={dataHcName}
          dataHcEventSection={dataHcEventSection}
          label={label}
          onClick={handleOpen}
        />
      )}
      <ExperienceFlag
        experienceFlagId="PEXP_REDESIGN"
        now={
          <SavedCompFilterSetFormDialogDeprecated
            reportId={reportId}
            active={active}
            compType={compType}
            onClose={handleClose}
          />
        }
        next={
          <SavedCompFilterSetFormDialog
            reportId={reportId}
            active={active}
            compType={compType}
            onClose={handleClose}
            onSuccess={onSuccess}
          />
        }
      />
    </>
  );
};
