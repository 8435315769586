import React, { ReactNode } from 'react';

import { SearchChip } from '@hcs/design-system';
import { CompFields, CompTypes } from '@hcs/types';
import { CompFilters, CompsFiltersPaths, ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../../hooks';
import { useDocumentPatch } from '../../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../../hooks/useReportPermissions';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  compField: keyof CompFilters;
  label: ReactNode;
  onClick?: VoidFunction;
  onClose?: VoidFunction;
}
export const CompFilterChip = ({
  reportId,
  className,
  compField,
  compType,
  label,
  onClick,
  onClose,
}: Props) => {
  const dataHcName = `comp-filter-chip-${compField}`;
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const filterValue =
    compField === CompFields.distance
      ? filterDocument?.data.filters?.[CompFields.distance]?.absoluteValue
      : filterDocument?.data.filters?.[compField];

  if (!filterValue || !reportPermissions?.isEditable) {
    return null;
  }

  const handleClose = () => {
    if (filterDocument) {
      const path: CompsFiltersPaths = `/data/filters/${compField}`;
      documentPatchMutation.mutate({
        reportId,
        document: filterDocument,
        operations: [
          {
            op: 'remove',
            path,
          },
        ],
      });
    }
    onClose?.();
  };
  return (
    <SearchChip
      className={className}
      dataHcName={dataHcName}
      onClick={onClick}
      onClose={handleClose}
      label={label}
    />
  );
};
