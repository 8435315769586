import { PropertyStateFields } from '@hcs/types';

export const DEFAULT_PROPERTY_STATE_FIELD_ORDER = [
  PropertyStateFields.livingArea,
  PropertyStateFields.lotSize,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.stories,
  PropertyStateFields.propertyType,
  PropertyStateFields.ownerOccupied,
  PropertyStateFields.yearBuilt,
  PropertyStateFields.basementHas,
  PropertyStateFields.pool,
  PropertyStateFields.daysOnMarketCumulative,
  PropertyStateFields.garageType,
  PropertyStateFields.garageSpaces,
  PropertyStateFields.condition,
  PropertyStateFields.subdivisionName,
  PropertyStateFields.zoning,
  PropertyStateFields.associationName,
  PropertyStateFields.associationFee,
  PropertyStateFields.associationFeeFrequency,
  PropertyStateFields.associationFeeIncludes,
  PropertyStateFields.taxYear,
  PropertyStateFields.taxAmount,
];
