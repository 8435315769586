import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Skeleton.module.css';

type SkeletonTypes = 'button' | 'fill' | 'input' | 'h1' | 'text' | 'fillInline';
export interface SkeletonProps extends CSSProperties {
  dataHcName: string;
  className?: string;
  repeat?: number;
  children?: ReactNode;
  type?: SkeletonTypes;
  style?: CSSProperties;
}
export const Skeleton = ({
  dataHcName,
  className,
  repeat = 1,
  children,
  type = 'fill',
  style = {},
  ...styleProps
}: SkeletonProps) => {
  return (
    <>
      {[...Array(repeat).keys()].map((id) => (
        <div
          key={id}
          data-hc-name={dataHcName}
          className={classNames(styles.Skeleton, styles[type], className)}
          style={{ ...style, ...styleProps }}
        >
          <div className={styles.Animation}>
            <span className={styles.Children}>
              {type === 'text' ? <>&nbsp;</> : null}
              {children}
            </span>
          </div>
        </div>
      ))}
    </>
  );
};
