import React, { useState } from 'react';

import { FilterRange, Input } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateFilterProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateEditCallbackArgs,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
} from '@hcs/types';
import {
  formatMoney,
  formatNumber,
  numInputStrStrip,
  unformat,
} from '@hcs/utils';

const FIELD = PropertyStateFields.currentListingPrice;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'List Price';
const labelShort = 'List $';
const propertyStatePath: PropertyStatePaths =
  '/complexFieldsSale/currentListingPrice';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.complexFieldsSale?.currentListingPrice;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatMoney(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `Listed for ${formatValue(propertyStateArgs)}`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: PropertyStateEditCallbackArgs<typeof FIELD>) => void;
  className?: string;
}) => {
  const defaultValue = getValue(propertyStateArgs);
  const [value, setValue] = useState(
    defaultValue === null ? null : formatNumber(defaultValue)
  );

  return (
    <Input
      dataHcName="list-price-adjust-input"
      className={className}
      value={value?.toString() || ''}
      onChange={(value) =>
        setValue(value === '' ? null : numInputStrStrip(value))
      }
      onFocus={() => {
        if (value !== null) {
          setValue(unformat(value).toString());
        }
      }}
      onBlur={() => {
        if (value !== null) {
          setValue(formatNumber(Number(value)));
        }
        onChange({
          path: propertyStatePath,
          value: value === null ? value : Number(value),
          field: FIELD,
        });
      }}
      maxLength={25}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  return (
    <FilterRange
      className={className}
      disabled={disabled}
      maxVal={99999999}
      initialValue={{
        min: spatialFiltersInput.minListPrice
          ? formatMoney(spatialFiltersInput.minListPrice)
          : '',
        max: spatialFiltersInput.maxListPrice
          ? formatMoney(spatialFiltersInput.maxListPrice)
          : '',
      }}
      onBlur={(value) => {
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            minListPrice: value.min === '' ? null : Number(value.min),
            maxListPrice: value.max === '' ? null : Number(value.max),
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

export const CURRENT_LISTING_PRICE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
  Filter,
  spatialSortField: SpatialSortField.ListPrice,
};
