import { useEffect, useRef } from 'react';

/**
 * This custom hook helps us utilize a forwarded ref inside the child
 * A forwarded ref could be a ref OR a ref callback
 *
 * https://non-traditional.dev/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
 */
export const useForwardedRef = <T>(ref: React.ForwardedRef<T>) => {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
};
