import { useEffect, useState } from 'react';
import { UseMutationResult } from '@tanstack/react-query';

export const useLatestMutationData = <TData, TError, TVariables, TContext>(
  useMutationResult: UseMutationResult<TData, TError, TVariables, TContext>
) => {
  // Return the latest successful result
  // Prevents results in autocomplete components from disappearing as the user types
  const [latestData, setLatestData] = useState<TData | undefined>(
    useMutationResult.data
  );
  const { isSuccess, data } = useMutationResult;
  useEffect(() => {
    if (isSuccess && data) {
      setLatestData(data);
    }
  }, [isSuccess, data]);
  return { ...useMutationResult, data: latestData };
};
