import React from 'react';
import { BarDatum, BarItemProps } from '@nivo/bar';

const RADIUS = 8;

export const BarComponent = ({
  bar: { color, x, data, y, height, width },
}: BarItemProps<BarDatum>) => {
  if (data.value === 0) {
    return <span />;
  }
  return (
    <g id={`a-bar-${data.data.bar}`} transform={`translate(${x}, ${y})`}>
      <svg>
        <path
          d={`M 0 ${RADIUS} A ${RADIUS} ${RADIUS} 0 0 1 ${RADIUS} 0 
        L ${width - RADIUS} 0 A ${RADIUS} ${RADIUS} 0 0 1 ${width} ${RADIUS}
  H${width} V${height} H-${width} V${height} z`}
          fill={color}
        />
      </svg>
    </g>
  );
};
