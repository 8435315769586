import React from 'react';
import { RemoveOperation } from 'fast-json-patch';
import isEmpty from 'lodash/isEmpty';

import { TextButton } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { CompsFiltersPaths, ReportId } from '@hcs/types';

import { useCompsFiltersDocument, useDocumentPatch } from '../../hooks';
import { useReportPermissions } from '../../hooks/useReportPermissions';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  onClick?: VoidFunction;
}

const dataHcName = 'clear-all-filters';
export const CompFiltersClearAllButton = ({
  reportId,
  compType,
  className,
  onClick,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const handleClick = () => {
    if (filterDocument) {
      const operations: RemoveOperation[] = [];
      const path: CompsFiltersPaths = '/data/filters';
      Object.keys(filterDocument.data.filters || {}).forEach((key) => {
        operations.push({
          op: 'remove',
          path: `${path}/${key}`,
        });
      });
      documentPatchMutation.mutate({
        reportId,
        document: filterDocument,
        operations,
      });
      onClick?.();
    }
  };
  if (isEmpty(filterDocument?.data.filters) || !reportPermissions?.isEditable) {
    return null;
  }
  return (
    <TextButton
      className={className}
      dataHcName={dataHcName}
      onClick={handleClick}
      disabled={
        !filterDocument ||
        documentPatchMutation.isLoading ||
        !reportPermissions?.isEditable
      }
    >
      Clear All
    </TextButton>
  );
};
