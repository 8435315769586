import { useMutation,UseMutationOptions } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import {
  EditableLinkCreateResponse,
  EditableLinkPayload,
  ReportId,
} from '@hcs/types';

import { ReportApi } from '../api';

export const useReportEditableLinkCreate = (
  reportId: ReportId,
  options?: UseMutationOptions<
    EditableLinkCreateResponse,
    unknown,
    EditableLinkPayload
  >
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation(
    async (payload: EditableLinkPayload) => {
      return await ReportApi.createEditableLink(reportId, payload);
    },
    {
      ...options,
      onMutate: (variables) => {
        toastOpen({
          type: 'loading',
          title: 'Creating Editable Link...',
        });
        options?.onMutate?.(variables);
      },
      onSuccess: (data, vars, context) => {
        toastOpen({
          type: 'loading-success',
          title: 'Editable Link Created',
        });
        options?.onSuccess?.(data, vars, context);
      },
      onError: (error, vars, context) => {
        toastOpen({
          type: 'loading-failure',
          title: 'Failed to Create Editable Link.',
        });
        options?.onError?.(error, vars, context);
      },
    }
  );
};
