import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const useNavigateToRoot = () => {
  const navigate = useNavigate();
  const navigateToRoot = useCallback(() => {
    navigate('/');
  }, [navigate]);
  return navigateToRoot;
};
