import React from 'react';

import { Card, CardList } from '@hcs/design-system';
import { Menu } from '@hcs/design-system';

import { CompsListViewTypePreferenceControl } from './CompsListViewTypePreferenceControl';
import { PexpRedesignControl } from './PexpRedesignControl';

interface Props {
  className?: string;
}
const dataHcName = 'user-experience-preferences';
export const UserExperiencePreferences = ({ className }: Props) => {
  return (
    <CardList className={className} dataHcName={dataHcName}>
      <Card dataHcName={`${dataHcName}-report-access`}>
        <Menu>
          <PexpRedesignControl />
          <CompsListViewTypePreferenceControl />
        </Menu>
      </Card>
    </CardList>
  );
};
