import React, { useMemo, useState } from 'react';

import { PropertySpatialSearch } from '@hcs/cerberus-spatial-search';
import { Button } from '@hcs/design-system';
import { TableCell } from '@hcs/design-system';
import { PropertyBrokerageCredit } from '@hcs/property-state';
import { CustomCellProps } from '@hcs/property-state';
import { ADDRESS_CONFIG } from '@hcs/property-state';
import { PropertyStateCardProps } from '@hcs/property-state';
import { PropertyStateTableProps } from '@hcs/property-state';
import { LinkComponentType } from '@hcs/routing';
import { BuyBoxFormData, MapPageViewMode } from '@hcs/types';
import { FitBoundsPayload } from '@hcs/types';
import { PropertyStateCerberusInput, PropertyStateFields } from '@hcs/types';
import { CerberusInputHcs } from '@hcs/types';

import { useMsaGeom } from '../../';
import {
  getExcludedZipcodesInput,
  getLastMapViewMode,
  getSpatialFiltersInput,
  setLastMapViewMode,
} from '../../';

import { TopBar } from './TopBar';

import styles from './BuyBoxMapPage.module.css';

const { Display: AddressDisplay, HeaderCell: HeaderCellAddress } =
  ADDRESS_CONFIG;

const MAP_ID = 'buyBoxMap';
const CARD_FIELDS: PropertyStateCardProps['fields'] = [
  PropertyStateFields.currentListingPrice,
  PropertyStateFields.currentListDate,
  PropertyStateFields.currentValueRental,
  PropertyStateFields.rentalYield,
  PropertyStateFields.currentStatus,
  [
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.livingArea,
  ],
  PropertyStateFields.yearBuilt,
];

interface MapPageProps {
  PropertyPreviewLink: LinkComponentType<{
    cerberusInputHcs: CerberusInputHcs;
  }>;
}

type LinkCustomData = {
  PropertyPreviewLink: MapPageProps['PropertyPreviewLink'];
};

const dataHcName = 'map-page';

const AddressCustomCell = ({
  propertyStateArgs,
  customData,
  ...rest
}: CustomCellProps<LinkCustomData>) => {
  const { PropertyPreviewLink } = customData || {};
  const { propertyState } = propertyStateArgs || {};
  return (
    <TableCell dataHcName={`${dataHcName}-address-cell`} {...rest}>
      {propertyState?.hcAddressId && PropertyPreviewLink && (
        <PropertyPreviewLink
          dataHcName="pexp-link"
          cerberusInputHcs={{
            hcAddressId: propertyState.hcAddressId,
          }}
          target="_blank"
          rel="noreferrer"
          className={styles.NoUnderlineLink}
        >
          <AddressDisplay propertyStateArgs={propertyStateArgs} />
          <PropertyBrokerageCredit
            propertyStateArgs={propertyStateArgs}
            isRental={false}
          />
        </PropertyPreviewLink>
      )}
    </TableCell>
  );
};

export const BuyBoxMapPage = ({ PropertyPreviewLink }: MapPageProps) => {
  const [editedBB, setEditedBB] = useState<BuyBoxFormData | null>(null);
  const [viewMode, setViewMode] = useState<MapPageViewMode>(
    getLastMapViewMode() || 'card'
  );

  const { data: msaWithGeom } = useMsaGeom(editedBB?.msaId);
  const fitBoundsPayload = useMemo<
    Omit<FitBoundsPayload, 'mapId'> | undefined
  >(() => {
    return (
      msaWithGeom && {
        fitId: `fit-${msaWithGeom.msaId}`,
        coords: [
          {
            latitude: msaWithGeom?.bbox.neLat,
            longitude: msaWithGeom?.bbox.neLng,
          },
          {
            latitude: msaWithGeom?.bbox.swLat,
            longitude: msaWithGeom?.bbox.swLng,
          },
        ],
      }
    );
  }, [msaWithGeom]);

  const tableColumns: PropertyStateTableProps<LinkCustomData>['fields'] =
    useMemo(() => {
      return [
        {
          type: 'customField',
          value: 'linkAddress',
          header: <HeaderCellAddress noLabel width={200} sticky />,
          CustomCell: AddressCustomCell,
          tableCellProps: { sticky: true, width: 200 },
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.currentListingPrice,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.currentListDate,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.currentValueRental,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.rentalYield,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.currentStatus,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.bedrooms,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.bathrooms,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.livingArea,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.lotSize,
        },
        {
          type: 'propertyStateFields',
          value: PropertyStateFields.yearBuilt,
        },
      ];
    }, []);

  return (
    <div className={styles.PageContainer}>
      <TopBar
        className={styles.TopBar}
        setEditedBB={setEditedBB}
        viewMode={viewMode}
        setViewMode={(viewMode) => {
          setViewMode(viewMode);
          setLastMapViewMode(viewMode);
        }}
        dataHcName={`${dataHcName}-top-bar`}
      />
      <PropertySpatialSearch
        className={styles.PageContent}
        spatialSearchVariables={
          editedBB && {
            id: {
              msa: {
                msaIds: [`${msaWithGeom?.msaId}`],
              },
              excludeZipcodes: getExcludedZipcodesInput(
                editedBB?.unSelectedZipcodes
              ),
            },
            spatialFilters: getSpatialFiltersInput(editedBB),
          }
        }
        mapProps={{
          mapId: MAP_ID,
          latitude: msaWithGeom?.lat,
          longitude: msaWithGeom?.lng,
          enableSearchParams: true,
          // Fit bounds to the MSA bounding box so the entire msa is visible
          fitBounds: fitBoundsPayload,
          mapOutlineConfigs: [{ id: 'msaOutline', geom: msaWithGeom?.geom }],
        }}
        listProps={{
          listType: viewMode === 'list' ? 'table' : 'card',
          resultsInfo: editedBB?.excludeRentalListings
            ? 'Rental listings are excluded from this view'
            : undefined,
          propertyStateTableProps: {
            fields: tableColumns,
            showAddress: false,
            className: styles.Table,
          },
          customDataCreator: { PropertyPreviewLink },
        }}
        propertyStateCardProps={(
          propertyStateCerberusInput: PropertyStateCerberusInput
        ) => {
          return {
            fields: CARD_FIELDS,
            button: propertyStateCerberusInput.cerberusInput && (
              <PropertyPreviewLink
                dataHcName={`${dataHcName}-property-preview`}
                cerberusInputHcs={propertyStateCerberusInput.cerberusInput}
              >
                <Button dataHcName={`${dataHcName}-property-preview-button`}>
                  Full Property Details
                </Button>
              </PropertyPreviewLink>
            ),
          };
        }}
      />
    </div>
  );
};
