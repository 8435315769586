import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Tooltip } from '../../popovers/Tooltip';

import styles from './TwoColumnTable.module.css';

interface TwoColumnTableTheme {
  TwoColumnTable: string;
  Row: string;
  Value: string;
}

export interface TwoColumnTableProps {
  valueWidth?: string;
  rows: Array<{
    label: string | number | ReactNode | null;
    value: string | number | ReactNode | null;
    tooltipContent?: string | number | ReactNode;
    badges?: ReactNode;
  }>;
  theme?: Partial<TwoColumnTableTheme>;
  dataHcName: string;
  badgesEnabled?: boolean;
  compact?: boolean;
  borders?: boolean;
}

export const TwoColumnTable = ({
  valueWidth,
  rows,
  theme,
  badgesEnabled,
  dataHcName,
  compact = false,
  borders = false,
}: TwoColumnTableProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.TwoColumnTable,
        {
          [styles.badgesEnabled]: badgesEnabled,
          [styles.borders]: borders,
          [styles.compact]: compact,
        },
        theme?.TwoColumnTable
      )}
    >
      {rows.map((row, index) => {
        const renderedRow = (
          <div
            key={index}
            data-hc-name={`${dataHcName}-row`}
            className={classNames(
              styles.Row,
              { [styles.LastRow]: rows.length - 1 === index },
              theme?.Row
            )}
            style={
              valueWidth
                ? {
                    gridTemplateColumns: `2fr ${valueWidth} ${
                      badgesEnabled ? '100px' : ''
                    }`,
                  }
                : undefined
            }
          >
            <div
              className={styles.Label}
              data-hc-name={`${dataHcName}-label-${index}`}
            >
              {row.label}
            </div>
            <div
              className={classNames(styles.Value, theme?.Value)}
              data-hc-name={`${dataHcName}-value-${index}`}
            >
              {row.value}
            </div>
            {badgesEnabled && row.badges && (
              <div data-hc-name={`${dataHcName}-badges`}>{row.badges}</div>
            )}
          </div>
        );
        if (row.tooltipContent) {
          return (
            <Tooltip
              theme={{
                Button: styles.PopoverButton,
              }}
              key={index}
              label={row.tooltipContent}
              dataHcName={`${dataHcName}-popover`}
              trigger={renderedRow}
            />
          );
        }

        return renderedRow;
      })}
    </div>
  );
};
