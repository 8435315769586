import React from 'react';
import classNames from 'classnames';

import {
  DotLabel,
  DotLabelProps,
} from '../../../../display/notifications/DotLabel';

import styles from './ChartTooltip.module.css';

export interface DataRow {
  id: number | string;
  dotColor: DotLabelProps['dotColor'];
  dotBorder?: string;
  label?: React.ReactNode;
  value: React.ReactNode;
  isDotDashed?: boolean;
}

export interface ChartTooltipProps {
  dataHcName: string;
  className?: string;
  header?: React.ReactNode;
  dataRows: DataRow[];
}
export const ChartTooltip = ({
  dataHcName,
  className,
  header,
  dataRows,
}: ChartTooltipProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ChartTooltip, className)}
    >
      {header && (
        <div className={styles.Header} data-hc-name={`${dataHcName}-header`}>
          {header}
        </div>
      )}
      {dataRows.map((dataRow) => {
        return (
          <div
            className={styles.TooltipRow}
            key={dataRow.id}
            data-hc-name={`${dataHcName}-row`}
          >
            <DotLabel
              theme={{ Container: styles.OrbLabel }}
              dataHcName={`${dataHcName}-${dataRow.id}-label`}
              dotColor={dataRow.dotColor}
              label={dataRow.label}
              isDotDashed={dataRow.isDotDashed}
            />
            <span
              className={styles.Value}
              data-hc-name={`${dataHcName}-${dataRow.id}-value`}
            >
              {dataRow.value}
            </span>
          </div>
        );
      })}
    </div>
  );
};
