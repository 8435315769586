import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Checkbox } from '../Checkbox';

import styles from './AutoComplete.module.css';

interface Props {
  label: string | ReactNode;
  setRef: (el: HTMLDivElement | null) => void;
  selected: boolean;
  focused: boolean;
  dataHcName: string;
  dataHcEventName?: string;
  dataHcEventSection?: string;
  dataHcEventType?: string;
  handleSelect: VoidFunction;
  multiselect?: boolean;
}

export const AutoCompleteOption = ({
  label,
  setRef,
  selected,
  focused,
  dataHcName,
  dataHcEventName,
  dataHcEventSection,
  dataHcEventType,
  handleSelect,
  multiselect,
}: Props) => {
  return (
    <div
      tabIndex={0}
      ref={(el) => setRef(el)}
      data-hc-name={dataHcName}
      data-hc-event-name={dataHcEventName}
      data-hc-event-section={dataHcEventSection}
      data-hc-event-type={dataHcEventType}
      onClick={handleSelect}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSelect();
        }
      }}
      className={classNames(styles.Option, {
        [styles.OptionLightHighlight || '']: selected,
        [styles.OptionDarkHighlight || '']:
          focused && !(selected && !multiselect),
        [styles.multiselect || '']: multiselect,
      })}
    >
      {multiselect && (
        <Checkbox
          dataHcName={`${dataHcName}-checkbox`}
          checked={selected}
          onChange={handleSelect}
          stopClickPropagation
        />
      )}
      <span className={styles.Label}>{label}</span>
    </div>
  );
};
