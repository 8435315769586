import React from 'react';

import { Button } from '@hcs/design-system';
import { logException } from '@hcs/utils';

import { HcsDevToolsPortal } from '../';

const dataHcName = 'sentry-test-dev-tool';
export const SentryTestDevTool = () => {
  return (
    <HcsDevToolsPortal
      groups={[
        {
          dataHcName,
          title: 'Sentry Test',
          items: [
            {
              type: 'item',
              item: {
                largeContent: true,
                dataHcName: 'error-button-container',
                content: (
                  <Button
                    dataHcName={`${dataHcName}-error-button`}
                    label="Throw Error"
                    onClick={() => {
                      logException(new Error('Sentry Test Error'));
                    }}
                  />
                ),
              },
            },
          ],
        },
      ]}
    />
  );
};
