import { CompIdentifier, ListingIdentifier } from '@hcs/types';

const photosPageSearchParamPrefix = 'reportPhotosPage';

export const PhotosPageSearchParams = {
  Type: `${photosPageSearchParamPrefix}-type`,
  CompIdentifier: `${photosPageSearchParamPrefix}-compIdentifier`,
  ListingIdentifier: `${photosPageSearchParamPrefix}-listingIdentifier`,
};

export interface PhotosPagePayloadSubject {
  type: 'subject';
  listingIdentifier?: ListingIdentifier;
}

export interface PhotosPagePayloadComp {
  type: 'comp';
  compIdentifier: CompIdentifier;
}

export type PhotosPagePayload =
  | PhotosPagePayloadComp
  | PhotosPagePayloadSubject;
