import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { LineClamp } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { SubNav } from '@hcs/design-system';
import { APP_CONFIG_PORTFOLIO } from '@hcs/hc-products';
import { usePortfolios } from '@hcs/portfolio';

import { useNavigateToMonitor } from '../..//hooks/useNavigateToMonitor';
import { useNavigateToPortfolioPage } from '../..//hooks/useNavigateToPortfolioPage';
import { generatePortfolioPath } from '../AllPortfoliosPageNav/utils';

import styles from './AllPortfoliosPageNav.module.css';

const dataHcName = 'all-portfolios-page-nav';

export const AllPortfoliosPageNav = () => {
  const { data: portfolios, isInitialLoading: isLoadingPortfolios } =
    usePortfolios();
  const { portfolioId } = useParams();
  const navigateToPortfolioPage = useNavigateToPortfolioPage();
  const navigateToMonitor = useNavigateToMonitor();

  useEffect(() => {
    if (portfolios && portfolios[0] && !portfolioId) {
      navigateToPortfolioPage(portfolios[0].portfolioId);
    }
  }, [portfolios, portfolioId]);

  if (!portfolios || isLoadingPortfolios)
    return (
      <div className={styles.Container}>
        <Skeleton
          dataHcName={`${dataHcName}-title-skeleton`}
          type="text"
          width={148}
          height={45}
          marginBottom={12}
        />
        <Skeleton
          dataHcName={`${dataHcName}-portfolio-names-skeleton`}
          type="text"
          repeat={5}
          paddingBottom={6}
          height={27}
          width={148}
        />
      </div>
    );

  if (portfolios.length === 0) navigateToMonitor();

  const SUB_NAV_ITEMS_CONFIG = [
    {
      navigateTo: `${APP_CONFIG_PORTFOLIO.rootPath}`,
      end: true,
      label: 'All Portfolios',
      dataHcName: `${dataHcName}-all-portfolio-header`,
      children: portfolios.map((portfolio) => {
        return {
          navigateTo: generatePortfolioPath(portfolio.portfolioId),
          disabled: !portfolio.assetCount,
          label: <LineClamp lines={1}>{portfolio.name}</LineClamp>,
          dataHcName: `${dataHcName}-${portfolio.name}`
        };
      })
    }
  ];

  return (
    <SubNav
      dataHcName={dataHcName}
      subNavItemsConfig={SUB_NAV_ITEMS_CONFIG}
      className={styles.SubNav}
    />
  );
};
