import {
  ArrayOneOrMore,
  HcMapLayerNumberType,
  LegendInterval,
  PropertyTypeEnum,
  TilePropertyTypes,
  VectileLayers,
  VectilesLayerSource,
  VectilesMetricConfig,
  VectilesMetricGroupColors,
  VectilesMetricGroupConfig,
  VectilesMetricGroups,
  VectilesMetricIds,
  VectilesMetricsConsumer,
  VectilesMetricsHcs,
  VectilesMetricsHcsRental,
} from '@hcs/types';

import HalftoneShade1 from '../assets/halftone-shade-1.svg';
import HalftoneShade2 from '../assets/halftone-shade-2.svg';
import HalftoneShade3 from '../assets/halftone-shade-3.svg';
import HalftoneShade4 from '../assets/halftone-shade-4.svg';
import HalftoneShade5 from '../assets/halftone-shade-5.svg';
import HalftoneShadeEmpty from '../assets/halftone-shade-empty.svg';

export const DEFAULT_LAYER_METRIC = VectilesMetricsConsumer.PRICE_AVG;
export const TRANSPARENT_COLOR = 'rgba(0,0,0,0)';

export const VECTILES_LAYERS_SOURCE_HCS: ArrayOneOrMore<VectilesLayerSource> = [
  {
    vectilesLayer: VectileLayers.HCS_BLOCKS,
    zoomRange: {
      min: 12,
      max: 22,
    },
  },
  {
    vectilesLayer: VectileLayers.HCS_BLOCK_GROUPS,
    zoomRange: {
      min: 10,
      max: 12,
    },
  },
];

export const VECTILES_LAYERS_SOURCE_HCS_RENTAL: ArrayOneOrMore<VectilesLayerSource> =
  [
    {
      vectilesLayer: VectileLayers.HCS_RENTAL_BLOCKS,
      zoomRange: {
        min: 13,
        max: 22,
      },
    },
    {
      vectilesLayer: VectileLayers.HCS_RENTAL_BLOCK_GROUPS,
      zoomRange: {
        min: 10,
        max: 13,
      },
    },
  ];

export const VECTILES_LAYERS_SOURCE_CONSUMER: ArrayOneOrMore<VectilesLayerSource> =
  [
    {
      vectilesLayer: VectileLayers.CONSUMER_BLOCKS,
      zoomRange: {
        min: 13,
        max: 22,
      },
    },
    {
      vectilesLayer: VectileLayers.CONSUMER_BLOCK_GROUPS,
      zoomRange: {
        min: 9,
        max: 13,
      },
    },
  ];

export const VECTILES_LAYERS_SOURCE_CONSUMER_CRIME: ArrayOneOrMore<VectilesLayerSource> =
  [
    {
      vectilesLayer: VectileLayers.CONSUMER_BLOCKS,
      zoomRange: {
        min: 13,
        max: 22,
      },
    },
    {
      vectilesLayer: VectileLayers.CONSUMER_BLOCK_GROUPS,
      zoomRange: {
        min: 13,
        max: 13,
      },
    },
  ];

const COLORS_PRICE: VectilesMetricGroupColors = [
  '#d0f89b', // --map-key-10
  '#bbec78', // --map-key-20
  '#8bda7a', // --map-key-30
  '#55c3b0', // --map-key-40
  '#398499', // --map-key-50
];
const COLORS_SCHOOLS: VectilesMetricGroupColors = [
  '#ea875c', // --map-key-80
  '#f1b538', // --map-key-70
  '#e3d74d', // --map-key-60
  '#bbec78', // --map-key-20
  '#8bda7a', // --map-key-30
];
const COLORS_GROWTH: VectilesMetricGroupColors = [
  '#ea875c', // --map-key-80
  '#f1b538', // --map-key-70
  '#e3d74d', // --map-key-60
  '#bbec78', // --map-key-20
  '#8bda7a', // --map-key-30
];

const COLORS_CRIME: VectilesMetricGroupColors = [
  '#8bda7a', // --map-key-30
  '#bbec78', // --map-key-20
  '#e3d74d', // --map-key-60
  '#f1b538', // --map-key-70
  '#ea875c', // --map-key-80
];

/**
 priceSqFt: 'Find the average price/square foot of each local block ',
 growth: 'Discover the historical price growth of every block in your area',
 median: 'See the average block-level price of homes in your area',
 schools:
 'Identify the school district and rating for every home in your area',
 */
export const VECTILES_METRICS_GROUP_CONFIG: {
  [key in VectilesMetricGroups]: VectilesMetricGroupConfig;
} = {
  [VectilesMetricGroups.RentalYield]: {
    label: 'Rental Yield',
    description: 'View rental yields in the area',
    vectilesLayers: VECTILES_LAYERS_SOURCE_HCS_RENTAL,
    vectilesMetricGroup: VectilesMetricGroups.RentalYield,
    vectilesMetricIds: [VectilesMetricIds.RentalYieldGrossAvg],
    colors: COLORS_PRICE,
    numberType: HcMapLayerNumberType.PERCENTAGE,
  },
  [VectilesMetricGroups.RentalPrice]: {
    label: 'Rental Average Price',
    description: 'View the average block-level rental home price',
    vectilesLayers: VECTILES_LAYERS_SOURCE_HCS_RENTAL,
    vectilesMetricGroup: VectilesMetricGroups.RentalPrice,
    vectilesMetricIds: [VectilesMetricIds.RentalPrices],
    colors: COLORS_PRICE,
    numberType: HcMapLayerNumberType.MONEY,
  },
  [VectilesMetricGroups.Schools]: {
    label: 'Schools',
    description: 'View by school district and their ratings',
    vectilesLayers: VECTILES_LAYERS_SOURCE_CONSUMER,
    vectilesMetricGroup: VectilesMetricGroups.Schools,
    vectilesMetricIds: [
      VectilesMetricIds.SchoolElementary,
      VectilesMetricIds.SchoolMiddle,
      VectilesMetricIds.SchoolHigh,
    ],
    colors: COLORS_SCHOOLS,
    numberType: HcMapLayerNumberType.PERCENTAGE,
  },
  [VectilesMetricGroups.Price]: {
    label: 'Average Price',
    description: 'View the average block-level home price',
    vectilesLayers: VECTILES_LAYERS_SOURCE_HCS,
    vectilesMetricGroup: VectilesMetricGroups.Price,
    vectilesMetricIds: [
      VectilesMetricIds.Prices,
      VectilesMetricIds.PricesPerSqFt,
    ],
    colors: COLORS_PRICE,
    numberType: HcMapLayerNumberType.MONEY,
  },
  [VectilesMetricGroups.Growth]: {
    label: 'Historical Growth',
    description: 'View the historical price growth',
    vectilesLayers: VECTILES_LAYERS_SOURCE_HCS,
    vectilesMetricGroup: VectilesMetricGroups.Growth,
    vectilesMetricIds: [
      VectilesMetricIds.GrowthHistorical1Year,
      VectilesMetricIds.GrowthHistorical5Year,
    ],
    colors: COLORS_GROWTH,
    numberType: HcMapLayerNumberType.PERCENTAGE,
  },
  [VectilesMetricGroups.Crime]: {
    label: 'Crime',
    description: 'View crime county report rates',
    vectilesLayers: VECTILES_LAYERS_SOURCE_CONSUMER_CRIME,
    vectilesMetricGroup: VectilesMetricGroups.Crime,
    vectilesMetricIds: [
      VectilesMetricIds.CrimeAll,
      VectilesMetricIds.CrimeProperty,
      VectilesMetricIds.CrimeViolent,
    ],
    colors: COLORS_CRIME,
    numberType: HcMapLayerNumberType.PERCENTAGE,
  },
};

export const VECTILES_METRICS_CONFIG: {
  [key in VectilesMetricIds]: VectilesMetricConfig;
} = {
  // Sold Price Layers
  [VectilesMetricIds.Prices]: {
    vectilesMetricId: VectilesMetricIds.Prices,
    vectilesMetric: VectilesMetricsHcs.PRICES,
    vectilesMetricGroup: VectilesMetricGroups.Price,
    label: 'Per SqFt',
  },
  [VectilesMetricIds.PricesPerSqFt]: {
    vectilesMetricId: VectilesMetricIds.PricesPerSqFt,
    vectilesMetric: VectilesMetricsHcs.PRICE_AVERAGE,
    vectilesMetricGroup: VectilesMetricGroups.Price,
    label: 'Average',
  },
  [VectilesMetricIds.GrowthHistorical1Year]: {
    vectilesMetricId: VectilesMetricIds.GrowthHistorical1Year,
    vectilesMetric: VectilesMetricsHcs.PRICE_PCT_CHG_1YR,
    vectilesMetricGroup: VectilesMetricGroups.Growth,
    label: '1 Year',
  },
  [VectilesMetricIds.GrowthHistorical5Year]: {
    vectilesMetricId: VectilesMetricIds.GrowthHistorical5Year,
    vectilesMetric: VectilesMetricsHcs.PRICE_PCT_CHG_5YR,
    vectilesMetricGroup: VectilesMetricGroups.Growth,
    label: '5 Year',
  },
  // Rental Metrics
  [VectilesMetricIds.RentalYieldGrossAvg]: {
    vectilesMetricId: VectilesMetricIds.RentalYieldGrossAvg,
    vectilesMetric: VectilesMetricsHcs.GROSS_YIELD_AVERAGE,
    vectilesMetricGroup: VectilesMetricGroups.RentalYield,
    label: 'Rental Yield',
  },
  [VectilesMetricIds.RentalPrices]: {
    vectilesMetricId: VectilesMetricIds.RentalPrices,
    vectilesMetric: VectilesMetricsHcsRental.priceAverage,
    vectilesMetricGroup: VectilesMetricGroups.RentalPrice,
    label: 'Rental Price',
  },
  // Consumer Metrics
  [VectilesMetricIds.SchoolElementary]: {
    vectilesMetricId: VectilesMetricIds.SchoolElementary,
    vectilesMetric: VectilesMetricsConsumer.SCHOOLS_ELEMENTARY,
    vectilesMetricGroup: VectilesMetricGroups.Schools,
    label: 'Elementary',
  },
  [VectilesMetricIds.SchoolMiddle]: {
    vectilesMetricId: VectilesMetricIds.SchoolMiddle,
    vectilesMetric: VectilesMetricsConsumer.SCHOOLS_MIDDLE,
    vectilesMetricGroup: VectilesMetricGroups.Schools,
    label: 'Middle',
  },
  [VectilesMetricIds.SchoolHigh]: {
    vectilesMetricId: VectilesMetricIds.SchoolHigh,
    vectilesMetric: VectilesMetricsConsumer.SCHOOLS_HIGH,
    vectilesMetricGroup: VectilesMetricGroups.Schools,
    label: 'High',
  },
  [VectilesMetricIds.CrimeAll]: {
    vectilesMetricId: VectilesMetricIds.CrimeAll,
    vectilesMetric: VectilesMetricsConsumer.CRIME_ALL,
    vectilesMetricGroup: VectilesMetricGroups.Crime,
    label: 'All',
  },
  [VectilesMetricIds.CrimeProperty]: {
    vectilesMetricId: VectilesMetricIds.CrimeProperty,
    vectilesMetric: VectilesMetricsConsumer.CRIME_PROPERTY,
    vectilesMetricGroup: VectilesMetricGroups.Crime,
    label: 'Property',
  },
  [VectilesMetricIds.CrimeViolent]: {
    vectilesMetricId: VectilesMetricIds.CrimeViolent,
    vectilesMetric: VectilesMetricsConsumer.CRIME_VIOLENT,
    vectilesMetricGroup: VectilesMetricGroups.Crime,
    label: 'Violent',
  },
};

export const HALFTONE_IMAGES = [
  { icon: HalftoneShade1, name: '0' },
  { icon: HalftoneShade2, name: '1' },
  { icon: HalftoneShade3, name: '2' },
  { icon: HalftoneShade4, name: '3' },
  { icon: HalftoneShade5, name: '4' },
  { icon: HalftoneShadeEmpty, name: 'empty' },
];

export const SCHOOL_MARKER_IMAGE_IDS = {
  SCHOOL_MARKER_IMAGE_0: 'school-marker-image-0',
  SCHOOL_MARKER_IMAGE_1: 'school-marker-image-1',
  SCHOOL_MARKER_IMAGE_2: 'school-marker-image-2',
  SCHOOL_MARKER_IMAGE_3: 'school-marker-image-3',
  SCHOOL_MARKER_IMAGE_4: 'school-marker-image-4',
  SCHOOL_MARKER_IMAGE_UNRANKED: 'school-marker-image-unranked',
};

/* For each interval: [lowEndOfRange, highEndOfRange, color, rangeDisplay, mapboxImageID] */
export const SCHOOLS_LEGEND_INTERVALS: LegendInterval[] = [
  {
    start: -1,
    end: 20,
    color:
      VECTILES_METRICS_GROUP_CONFIG[VectilesMetricGroups.Schools].colors[0],
    rangeDisplay: { start: '1%', end: '20%' },
    mapboxImageID: SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_0,
  },
  {
    start: 20,
    end: 40,
    color:
      VECTILES_METRICS_GROUP_CONFIG[VectilesMetricGroups.Schools].colors[1],
    rangeDisplay: { start: '21%', end: '40%' },
    mapboxImageID: SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_1,
  },
  {
    start: 40,
    end: 60,
    color:
      VECTILES_METRICS_GROUP_CONFIG[VectilesMetricGroups.Schools].colors[2],
    rangeDisplay: { start: '41%', end: '60%' },
    mapboxImageID: SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_2,
  },
  {
    start: 60,
    end: 80,
    color:
      VECTILES_METRICS_GROUP_CONFIG[VectilesMetricGroups.Schools].colors[3],
    rangeDisplay: { start: '61%', end: '80%' },
    mapboxImageID: SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_3,
  },
  {
    start: 80,
    end: 100,
    color:
      VECTILES_METRICS_GROUP_CONFIG[VectilesMetricGroups.Schools].colors[4],
    rangeDisplay: { start: '81%', end: '100%' },
    mapboxImageID: SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_4,
  },
];

export const SCHOOLS_LAYER_SCHOOL_KIND = 'school';

/* The sought key at feature.properties.level for each school layer */
export const SCHOOLS_LAYER_FILTER_DEF: {
  [key in VectilesMetricsConsumer]?: string;
} = {
  [VectilesMetricsConsumer.SCHOOLS_ELEMENTARY]: 'P',
  [VectilesMetricsConsumer.SCHOOLS_MIDDLE]: 'M',
  [VectilesMetricsConsumer.SCHOOLS_HIGH]: 'H',
};

export const PROPERTY_TYPE_TO_VECTILES_TYPE: {
  [key in PropertyTypeEnum]?: Partial<TilePropertyTypes>;
} = {
  [PropertyTypeEnum.Sfr]: TilePropertyTypes.SFD,
  [PropertyTypeEnum.Townhouse]: TilePropertyTypes.TH,
  [PropertyTypeEnum.Condo]: TilePropertyTypes.CND,
  [PropertyTypeEnum.Manufactured]: TilePropertyTypes.MFH,
  [PropertyTypeEnum.MultiFamily]: TilePropertyTypes.INC,
};

export const DEFAULT_LAYERS_RENTAL: ArrayOneOrMore<VectilesMetricGroups> = [
  VectilesMetricGroups.RentalYield,
  VectilesMetricGroups.RentalPrice,
  VectilesMetricGroups.Schools,
];

export const DEFAULT_LAYERS: ArrayOneOrMore<VectilesMetricGroups> = [
  VectilesMetricGroups.Price,
  VectilesMetricGroups.Growth,
  VectilesMetricGroups.Schools,
  VectilesMetricGroups.Crime,
];
