import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CompFieldConfig,
  CompFields,
  CompFieldsArgs,
  CompFieldsTableCellProps,
} from '@hcs/types';
import { formatMoney } from '@hcs/utils';

const FIELD = CompFields.appraisalTotalDollarAdjustment;
type FieldConfig = CompFieldConfig<typeof FIELD>;
const label = 'Appraisal Adjustment';
const labelShort = 'Appraisal Adjustment';
const compPath: FieldConfig['compPath'] =
  '/userAdjustments/totalDollarAdjustment';
const info: FieldConfig['info'] =
  'The amount the adjusted value of the comparable on the appraisal differs from the market price';
const getValue: FieldConfig['getValue'] = (comp) =>
  comp?.userAdjustments?.totalDollarAdjustment;
const formatValue: FieldConfig['formatValue'] = (comp) =>
  formatMoney(getValue(comp));
const formatInline: FieldConfig['formatInline'] = (comp) =>
  formatMoney(getValue(comp));

// Component for displaying a field from a comp
const Display = ({ comp }: CompFieldsArgs) => {
  return <>{formatValue(comp)}</>;
};

const HeaderCell = (props: TableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({ comp, ...tableCellProps }: CompFieldsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display comp={comp} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const APPRAISAL_TOTAL_DOLLAR_ADJUSTMENT_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  compPath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  info,
};
