import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import Applications from './ApplicationsIcon.svg?react';
import BulkData from './BulkDataIcon.svg?react';
import Capabilities from './CapabilitiesIcon.svg?react';
import CerberusExplorer from './CerberusExplorerIcon.svg?react';
import Devflags from './DevflagsIcon.svg?react';
import EngineeringTools from './EngineeringToolsIcon.svg?react';
import Permissions from './PermissionsIcon.svg?react';
import Roles from './RolesIcon.svg?react';

export const ApplicationsIcon = (props: IconProps) =>
  useIcon(Applications, props);
export const BulkDataIcon = (props: IconProps) => useIcon(BulkData, props);
export const CapabilitiesIcon = (props: IconProps) =>
  useIcon(Capabilities, props);
export const CerberusExplorerIcon = (props: IconProps) =>
  useIcon(CerberusExplorer, props);
export const DevflagsIcon = (props: IconProps) => useIcon(Devflags, props);
export const EngineeringToolsIcon = (props: IconProps) =>
  useIcon(EngineeringTools, props);
export const PermissionsIcon = (props: IconProps) =>
  useIcon(Permissions, props);
export const RolesIcon = (props: IconProps) => useIcon(Roles, props);
