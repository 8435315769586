import { useQuery } from '@tanstack/react-query';

import { PortfolioApi } from '../api';
import { removeUnsubscribedPortfolioEvents } from '../utils';

import { useAccessibleNotifications } from './useAccessibleNotification';

export const QUERY_KEY_PORTFOLIOS = 'portfolios';
export const usePortfolios = () => {
  const { data: accessibleNotifications } = useAccessibleNotifications();

  return useQuery([QUERY_KEY_PORTFOLIOS], async () => {
    const portfolios = await PortfolioApi.fetchPortfolios();

    portfolios.forEach((portfolio) =>
      removeUnsubscribedPortfolioEvents(portfolio, accessibleNotifications)
    );

    return portfolios;
  });
};
