import { useSelector } from 'react-redux';

import { useMapDispatchToSliceActions } from '@hcs/hooks';
import {
  EngagementTrackingPartialAppState,
  GlobalEngagementEventSliceState,
} from '@hcs/types';

import { ENGAGEMENT_TRACKING_REDUCER_KEY } from '../rtk/engagementTracking.reducer';
import { engagementTrackingGlobalDataSlice } from '../rtk/globalData.slice';

export const useGlobalEngagementEventSlice = () => {
  const actions = useMapDispatchToSliceActions(
    engagementTrackingGlobalDataSlice
  );
  return {
    state: useSelector<
      EngagementTrackingPartialAppState,
      GlobalEngagementEventSliceState
    >(
      (state) =>
        state[ENGAGEMENT_TRACKING_REDUCER_KEY][
          engagementTrackingGlobalDataSlice.name
        ]
    ),
    actions,
  };
};
