import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';

import { ResizableFlexColumn } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { HcMapProps } from '@hcs/maps';
import { useHcMap } from '@hcs/maps';
import { CustomCellData } from '@hcs/property-state';
import { PropertyStateCardPropsCreator } from '@hcs/property-state';
import {
  PropertySpatialSearchListQueryVariables,
  PropertySpatialSearchMapQueryVariables,
} from '@hcs/types';
import { ControlPosition, VectilesMetricGroups } from '@hcs/types';
import { PropertyStateCerberusInput } from '@hcs/types';

import {
  PropertySpatialSearchList,
  PropertySpatialSearchListProps,
} from '../PropertySpatialSearchList';
import { PropertySpatialSearchMap } from '../PropertySpatialSearchMap';

import styles from './PropertySpatialSearch.module.css';

const CARD_WIDTH = 250;
const CARD_GAP = 15;

export interface PropertySpatialSearchProps<
  D extends CustomCellData = undefined
> {
  className?: string;
  children?: ReactNode;
  propertyStateCardProps?: PropertyStateCardPropsCreator;
  spatialSearchVariables:
    | (Omit<PropertySpatialSearchMapQueryVariables, 'limit'> &
        Omit<PropertySpatialSearchListQueryVariables, 'limit'>)
    | undefined
    | null;
  mapProps: Omit<
    HcMapProps,
    | 'dataHcName'
    | 'propertyStateCardProps'
    | 'spatialSearchVariables'
    | 'isRental'
  >;
  listProps: Omit<
    PropertySpatialSearchListProps<D>,
    'spatialSearchVariables' | 'propertyStateCardProps'
  >;
  isRental?: boolean;
}

const dataHcName = 'property-spatial-search';

export const PropertySpatialSearch = <D extends CustomCellData = undefined>({
  className,
  mapProps,
  children,
  isRental,
  listProps,
  propertyStateCardProps,
  spatialSearchVariables,
}: PropertySpatialSearchProps<D>) => {
  const { mapState } = useHcMap(mapProps.mapId);
  const [highlightedProperty, setHighlightedProperty] = useState<
    PropertyStateCerberusInput | undefined
  >(undefined);
  const { boundsInfo } = mapState || {};
  return (
    <div className={classNames(styles.PageContent, className)}>
      <div className={styles.MapContainer}>
        {spatialSearchVariables ? (
          <PropertySpatialSearchMap
            highlightedProperty={highlightedProperty}
            isRental={isRental}
            layersControl={{
              vectilesMetricGroups: [
                VectilesMetricGroups.Price,
                VectilesMetricGroups.Growth,
                VectilesMetricGroups.RentalPrice,
                VectilesMetricGroups.RentalYield,
                VectilesMetricGroups.Schools,
                VectilesMetricGroups.Crime,
              ],
              position: ControlPosition.BottomRight,
            }}
            zoomControl={{
              position: ControlPosition.TopRight,
            }}
            satelliteControl={{
              position: ControlPosition.BottomLeft,
            }}
            {...mapProps}
            propertyStateCardProps={propertyStateCardProps}
            // propertyType={editedBB?.propertyType.find(p =)}
            spatialSearchVariables={{
              limit: 25,
              ...spatialSearchVariables,
            }}
          />
        ) : (
          <Skeleton dataHcName={`${dataHcName}-loader`} />
        )}
      </div>
      <ResizableFlexColumn
        dataHcName={`${dataHcName}-results-pane-resize`}
        classNameInner={styles.ResultsPaneInner}
        columns={{
          width: CARD_WIDTH,
          gap: CARD_GAP,
          snapTo: true,
          adjustment: 20,
        }}
        initialWidth={CARD_WIDTH * 2}
      >
        {spatialSearchVariables && boundsInfo && (
          <PropertySpatialSearchList
            {...listProps}
            propertyStateCardProps={propertyStateCardProps}
            onPropertyEnter={(propertyStateCerberusInput) => {
              setHighlightedProperty(propertyStateCerberusInput);
              listProps.onPropertyEnter?.(propertyStateCerberusInput);
            }}
            onPropertyLeave={() => {
              setHighlightedProperty(undefined);
              listProps.onPropertyLeave?.();
            }}
            spatialSearchVariables={{
              limit: 30,
              ...spatialSearchVariables,
              id: {
                ...spatialSearchVariables.id,
                bbox: boundsInfo
                  ? {
                      swLng: boundsInfo.southWest.lng,
                      swLat: boundsInfo.southWest.lat,
                      neLng: boundsInfo.northEast.lng,
                      neLat: boundsInfo.northEast.lat,
                    }
                  : undefined,
              },
            }}
          />
        )}
      </ResizableFlexColumn>
      {children}
    </div>
  );
};
