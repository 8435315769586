import React from 'react';

import { Dialog, DialogProps } from '@hcs/design-system';

import {
  SaveAnalysisTemplate,
  SaveAnalysisTemplateProps,
} from '../SaveAnalysisTemplate/SaveAnalysisTemplate';

import styles from './SaveAnalysisTemplate.module.css';

export interface SaveAnalysisTemplateDialogProps
  extends Omit<SaveAnalysisTemplateProps, 'onCancel'>,
    Omit<DialogProps, 'dataHcName' | 'children'> {}
const dataHcName = 'save-analysis-template-launcher';
export const SaveAnalysisTemplateDialog = ({
  active,
  onClose,
  ...props
}: SaveAnalysisTemplateDialogProps) => {
  return (
    <Dialog
      title={`${
        props.templateId ? 'Update' : 'Save'
      } Data Explorer Analysis Template`}
      dataHcName={dataHcName}
      active={active}
      theme={{ DialogWrapper: styles.Dialog }}
      onClose={onClose}
    >
      <SaveAnalysisTemplate
        {...props}
        onCancel={() => {
          onClose();
        }}
        onSubmit={(template) => {
          onClose();
          props.onSubmit?.(template);
        }}
      />
    </Dialog>
  );
};
