import React from 'react';
import classNames from 'classnames';

import { Toggle } from '@hcs/design-system';
import { HorizontalSelector } from '@hcs/design-system';
import { TabPopover } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { ComparisonIcon, GridIcon, ListIcon } from '@hcs/design-system';
import {
  CardViewIcon,
  CompareViewIcon,
  TableViewIcon,
} from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { CompTypes } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';

import { useCompDocuments } from '../../hooks';
import { useCompFarmList } from '../../hooks/useCompFarmList';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAll } from '../../utils/reportConfig.utils';

import { CompFarmListViewType } from './CompFarmList.types';

import stylesDeprecated from './CompFarmListHeaderDeprecated.module.css';
import styles from './CompFarmListViewTypeControl.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const IconsByViewType = {
  [CompFarmListViewType.Card]: GridIcon,
  [CompFarmListViewType.Table]: ListIcon,
  [CompFarmListViewType.Compare]: ComparisonIcon,
};
const dataHcName = 'comp-farm-list-view-toggle';
export const CompFarmListViewTypeControl = ({
  reportId,
  compType,
  className,
}: Props) => {
  const isRental = compType === CompTypes.Rental;
  const { data: reportConfig } = useReportConfig(reportId);
  const { data: compDocuments } = useCompDocuments(reportId, compType);

  const {
    state: { listViewType },
    compFarmListSetListViewType,
  } = useCompFarmList(reportId, compType);
  const options = [
    {
      label: (
        <>
          <GridIcon className={styles.Icon} height={16} width={16} /> Card View
        </>
      ),
      value: CompFarmListViewType.Card,
      disabled: false,
    },
    {
      label: (
        <>
          <ListIcon className={styles.Icon} height={16} width={16} /> List View
        </>
      ),
      value: CompFarmListViewType.Table,
      disabled: false,
    },
  ];
  const optionsLegacy = [
    {
      label: (
        <CardViewIcon
          dataHcName={`${dataHcName}-card`}
          color="neutral-dark-20"
        />
      ),
      value: CompFarmListViewType.Card,
      disabled: false,
    },
    {
      label: (
        <TableViewIcon
          dataHcName={`${dataHcName}-table`}
          color="neutral-dark-20"
        />
      ),
      value: CompFarmListViewType.Table,
      disabled: false,
    },
  ];
  if (
    reportFeaturesSupportedAll(
      reportConfig,
      isRental
        ? [ReportFeatures.RentalCompsSelect]
        : [ReportFeatures.CompsSelect]
    )
  ) {
    options.push({
      label: (
        <>
          <ComparisonIcon className={styles.Icon} height={16} width={16} />{' '}
          Comparison View
        </>
      ),
      value: CompFarmListViewType.Compare,
      disabled: !compDocuments?.length,
    });
    optionsLegacy.unshift({
      label: (
        <CompareViewIcon
          dataHcName={`${dataHcName}-compare`}
          color="neutral-dark-20"
        />
      ),
      value: CompFarmListViewType.Compare,
      disabled: !compDocuments?.length,
    });
  }
  const SelectedIcon = IconsByViewType[listViewType];
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <TabPopover
          dataHcName={dataHcName}
          chevron="down"
          trigger={
            <Tooltip
              dataHcName={`${dataHcName}-horz-selector`}
              trigger={<SelectedIcon color="neutral-dark-20" />}
              label="Change View"
            />
          }
          content={
            <HorizontalSelector
              dataHcName={`${dataHcName}-horz-selector`}
              value={listViewType}
              className={classNames(
                styles.CompFarmListViewTypeControl,
                styles.HorizontalSelector,
                className
              )}
              options={options}
              onSelect={(newValue) => {
                compFarmListSetListViewType(newValue);
              }}
            />
          }
        />
      }
      now={
        <Toggle
          dataHcName={dataHcName}
          value={listViewType}
          className={className}
          theme={{
            selected: stylesDeprecated.SelectedListType,
          }}
          options={optionsLegacy}
          onChange={compFarmListSetListViewType}
        />
      }
    />
  );
};
