import { AcquisitionExplorerIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, CapabilityApplications } from '@hcs/types';

import AcquisitionExplorerProductImage from '../assets/product-images/aexp_simplified.jpg';

export const APP_CONFIG_AEXP: AppConfig = {
  name: 'Acquisition Explorer',
  appSlug: AppSlugs.AcquisitionExplorer,
  applicationKey: CapabilityApplications.AcquisitionExplorer,
  rootPath: `/aexp`,
  Icon: AcquisitionExplorerIcon,
  descriptionLong: [
    'Research rental investments faster,',
    'evaluate smarter, and purchase confidently.',
  ],
  descriptionShort: 'Real-time information related to on-market properties.',
  marketingUrl: 'https://www.housecanary.com/products/acquisition-explorer/',
  marketingImage: AcquisitionExplorerProductImage,
};

export enum VIEW_PATHS_AEXP {
  BUY_BOXES = 'buy-boxes',
  MAP = 'map',
  ACTIVITY = 'activity',
  ACTIVITY_MONITOR = 'activity_monitor',
  ACTIVITY_PROPERTY_SEARCH = 'activity_property_search',
  OFFER = 'offer',
}
