import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { Input } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';

import styles from './FormFields.module.css';

interface Props {
  name: string;
  label: string;
  error?: string;
  className?: string;
  maxLength?: number;
  type?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const dataHcName = 'form-field-input';
export const FormFieldsInput = ({
  name,
  label,
  error,
  className,
  isLoading,
  maxLength = 50,
  type = 'text',
  disabled = false,
}: Props) => {
  const formContext = useFormContext();
  if (isLoading) {
    return (
      <Skeleton
        dataHcName={`${dataHcName}-${name}-skeleton`}
        type="input"
        className={className}
      />
    );
  }
  return (
    <Controller
      name={name}
      control={formContext.control}
      render={({ field }) => {
        return (
          <div className={classNames(styles.FormGroup, className)}>
            <label>{label}</label>
            <Input
              {...field}
              type={type}
              maxLength={maxLength}
              dataHcName={`${dataHcName}-${name}`}
              value={field.value || ''}
              onChange={(val) => {
                field.onChange(val);
                formContext.clearErrors(name);
              }}
              error={error}
              disabled={disabled}
            />
          </div>
        );
      }}
    />
  );
};
