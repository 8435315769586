import React from 'react';

import {
  CreateNewOrderState,
  DeliverySpeed,
  INSPECTION_TYPE,
  OrderTypeDescriptor,
  ProductType,
  STEP,
} from '@hcs/types';

import { InspectionOption } from './InspectionOption';
import { ProductOption } from './ProductOption';
import { PropertiesOption } from './PropertiesOption';

import styles from './SelectedOptions.module.css';

interface Props {
  orderTypes?: OrderTypeDescriptor[];
  updateStep: (stateToUpdate: Partial<CreateNewOrderState>) => void;
  productType: ProductType;
  disabledSteps: STEP[];
  inspectionType?: INSPECTION_TYPE | null;
  deliverySpeed?: DeliverySpeed;
  stepNum: number;
  includesAsRepairedValue?: boolean;
  orderFile?: File;
  itemsSource?: string;
}

const dataHcName = 'select-options';
export const SelectedOptions = ({
  orderTypes,
  updateStep,
  productType,
  disabledSteps,
  deliverySpeed,
  inspectionType,
  stepNum,
  includesAsRepairedValue,
  orderFile,
  itemsSource,
}: Props) => {
  const handleChangeClick = (step: STEP) => {
    if (step === STEP.SELECT_PRODUCT || step === STEP.INSPECTION_METHOD) {
      updateStep({
        step,
        inspectionType: undefined,
        deliverySpeed: undefined,
        selectedOrderType: undefined,
      });
    } else {
      updateStep({ step });
    }
  };
  const showInspectionOption = !!deliverySpeed || !!inspectionType;
  const hasOneDeliverySpeed =
    !!inspectionType && orderTypes?.[0]?.deliverySpeeds.length === 1;

  return (
    <div data-hc-name={dataHcName} className={styles.OptionsContainer}>
      <ProductOption
        dataHcName={`${dataHcName}-product-option`}
        productType={productType}
        onChangeClick={handleChangeClick}
        disabled={disabledSteps.includes(STEP.SELECT_PRODUCT)}
      />
      {showInspectionOption && (
        <InspectionOption
          dataHcName={`${dataHcName}-inspection-option`}
          inspectionType={inspectionType}
          includesAsRepairedValue={includesAsRepairedValue}
          deliverySpeed={deliverySpeed}
          onChangeClick={handleChangeClick}
          disabled={
            disabledSteps.includes(STEP.INSPECTION_METHOD) ||
            hasOneDeliverySpeed
          }
        />
      )}
      {stepNum === 5 && (
        <PropertiesOption
          dataHcName={`${dataHcName}-properties-option`}
          orderFile={orderFile}
          itemsSource={itemsSource}
          onChangeClick={handleChangeClick}
        />
      )}
    </div>
  );
};
