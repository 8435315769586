import camelcaseKeys from 'camelcase-keys';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import { AddressSearchHit, AddressSearchInputs } from '@hcs/types';
import { ADDRESS_SEARCH_BUILDING_URL, ADDRESS_SEARCH_URL } from '@hcs/urls';

import { addressSearchInputsToApiParams } from '../utils/addressSearch.utils';

export const AddressSearchApi = {
  // Search for addresses. Group buildings in to a single result
  fetchAddressSearchBuilding: async (
    addressSearchInputs: AddressSearchInputs
  ) => {
    const url = ADDRESS_SEARCH_BUILDING_URL;
    const response = await AxiosAccessTokenClientEhrm.get<AddressSearchHit[]>(
      url,
      {
        params: addressSearchInputsToApiParams(addressSearchInputs),
      }
    );
    // Need to cast because the latLng attribute uses the string format in camelcaseKeys and causes ts errors
    return camelcaseKeys(response.data, { deep: true }) as AddressSearchHit[];
  },
  // Search for addresses. Return units as individual results
  fetchAddressSearch: async (addressSearchInputs: AddressSearchInputs) => {
    const url = ADDRESS_SEARCH_URL;
    const response = await AxiosAccessTokenClientEhrm.get<AddressSearchHit[]>(
      url,
      {
        params: addressSearchInputsToApiParams(addressSearchInputs),
      }
    );
    // Need to cast because the latLng attribute uses the string format in camelcaseKeys and causes ts errors
    return camelcaseKeys(response.data, { deep: true }) as AddressSearchHit[];
  },
};
