import React, { useMemo, useRef } from 'react';
import {
  CustomLayerProps,
  LineSvgProps,
  Point,
  ResponsiveLine,
  SliceTooltipProps
} from '@nivo/line';
import classNames from 'classnames';

import { useRerender } from '@hcs/hooks';
import { lineChartPaddedMinMax } from '@hcs/utils';

import { LoadingSpinner } from '../../../../global/loading-errors-null/LoadingSpinner';
import { NoContent } from '../../../../global/loading-errors-null/NoContent';
import { ChartLegend, ChartLegendProps } from '../ChartLegend';
import {
  ChartTooltip,
  ChartTooltipProps,
  DataRow as ChartTooltipDataRow
} from '../ChartTooltip/ChartTooltip';
import { Props as StatsProps, Stats } from '../Stats';

import styles from './ChartTimeSeriesLine.module.css';

type TooltipDateFormats = 'monthDayYear' | 'monthYear';
export const LINE_COLOR = '#6BA0FF';
export const COMMON_RESPONSIVE_LINE_PROPS: Partial<LineSvgProps> = {
  lineWidth: 2,
  colors: [LINE_COLOR],
  margin: { top: 20, right: 70, bottom: 30, left: 5 },
  xScale: {
    type: 'time',
    precision: 'day',
    format: '%Y-%m-%d',
    useUTC: false
  },
  axisBottom: {
    format: '%b %y',
    tickSize: 0
  },
  axisRight: {
    tickSize: 0,
    tickPadding: 10,
    tickValues: 6
  },
  axisLeft: {
    tickSize: 0,
    // hide the left labels. Couldn't find a better way to do this
    format: () => ''
  },
  enablePoints: false,
  enableSlices: 'x',
  enableGridX: false,
  theme: {
    fontFamily: 'Avenir, sans-serif',
    textColor: '#4A4A4A',
    fontSize: 12,
    crosshair: {
      line: {
        stroke: '#C4C4C4',
        strokeDasharray: '1'
      }
    },
    grid: {
      line: {
        stroke: '#F7F7F7'
      }
    }
  }
};

const getDashedLine =
  (forecastSerieId: Point['serieId']) =>
  ({ series, lineGenerator, xScale, yScale }: CustomLayerProps) => {
    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={
          lineGenerator(
            // @ts-expect-error TS bug in the nivo code: https://github.com/plouc/nivo/issues/1604
            data.map((d) => ({
              // @ts-expect-error TS bug in the nivo code: https://github.com/plouc/nivo/issues/1604
              x: xScale(d.data.x),
              // @ts-expect-error TS bug in the nivo code: https://github.com/plouc/nivo/issues/1604
              y: yScale(d.data.y)
            }))
          ) || undefined
        }
        fill="none"
        stroke={color}
        style={
          id === forecastSerieId
            ? {
                strokeWidth: 2,
                strokeDasharray: 4
              }
            : undefined
        }
      />
    ));
  };

const getTooltipDataRowForPoint = (
  point: Point,
  forecastSerieId?: Point['serieId']
): ChartTooltipDataRow => {
  return {
    id: `${point.serieId}`,
    dotColor: { colorType: 'custom', color: point.serieColor },
    label: point.data.xFormatted,
    value: point.data.yFormatted,
    isDotDashed: point.serieId === forecastSerieId
  };
};

const getSliceTooltip =
  (parentDataHcName: string, forecastSerieId?: Point['serieId']) =>
  ({ slice }: SliceTooltipProps) => {
    const dataRows: ChartTooltipProps['dataRows'] = [];
    if (slice.points.length === 1 && slice.points[0]) {
      dataRows.push(
        getTooltipDataRowForPoint(slice.points[0], forecastSerieId)
      );
    } else if (slice.points.length === 2) {
      // in order to make historical and forecast data sets look like one continuous line, we add the last historical record to the forecast time series
      // so if we have points in both series, only show the historical
      if (slice.points[0] && slice.points[0]?.serieId !== forecastSerieId) {
        dataRows.push(
          getTooltipDataRowForPoint(slice.points[0], forecastSerieId)
        );
      } else if (slice.points[1]) {
        dataRows.push(
          getTooltipDataRowForPoint(slice.points[1], forecastSerieId)
        );
      }
    }

    return (
      <ChartTooltip
        dataHcName={`${parentDataHcName}-tooltip`}
        dataRows={dataRows}
      />
    );
  };

export interface ChartTimeSeriesLineTheme {
  Container: string;
  ChartTitle: string;
  CurrentValueContainer: string;
  CurrentValue: string;
  Chart: string;
  ChartLegend: string;
}

export interface ChartTimeSeriesLineProps {
  dataHcName: string;
  isLoading: boolean;
  chartData: LineSvgProps['data'] | null;
  // nivo will set the top and bottom of your chart to the min and max y values, but it looks nicer to have some amount of padding
  shouldPadYRange?: boolean;
  theme?: Partial<ChartTimeSeriesLineTheme>;
  title: string;
  tooltipDateFormat?: TooltipDateFormats;
  // Current value to show under the title
  CurrentValue?: React.ReactNode;
  // formatter for the y value as well as the y axis ticks
  d3FormatterForY?: string;
  // if you also have a forecast series, we will show it with a different style (currently a dotted line)
  forecastSerieId?: Point['serieId'];
  // optional legend items to display under the chart
  legendItems?: ChartLegendProps['legendItems'];
  axisBottom?: LineSvgProps['axisBottom'];
  compactTitle?: boolean;
  stats?: StatsProps['stats'];
}
export const ChartTimeSeriesLine = ({
  dataHcName,
  isLoading,
  chartData,
  shouldPadYRange = true,
  theme,
  title,
  tooltipDateFormat = 'monthDayYear',
  CurrentValue,
  d3FormatterForY,
  forecastSerieId,
  legendItems,
  axisBottom,
  compactTitle,
  stats
}: ChartTimeSeriesLineProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  useRerender({
    shouldRerender: !chartRef.current?.clientWidth,
    max: 1
  });
  const paddedMinMaxY = useMemo(() => {
    if (shouldPadYRange) {
      return lineChartPaddedMinMax(chartData);
    }
    return null;
  }, [chartData, shouldPadYRange]);

  const SliceTooltip = useMemo(() => {
    return getSliceTooltip(dataHcName, forecastSerieId);
  }, [dataHcName, forecastSerieId]);

  // if we have a forecast time series, we want to show that data as a dashed line
  const DashedLine = useMemo(() => {
    if (forecastSerieId) {
      return getDashedLine(forecastSerieId);
    }
    return null;
  }, [forecastSerieId]);
  // Chart labels seem to overlap around 750px
  const tickRotation =
    !chartRef.current?.clientWidth || chartRef.current.clientWidth > 750
      ? 0
      : -40;
  return (
    <div data-hc-name={dataHcName} className={theme?.Container}>
      <div
        data-hc-name={`${dataHcName}-title`}
        className={classNames(styles.ChartTitle, theme?.ChartTitle, {
          [styles.compactTitle]: compactTitle
        })}
      >
        {title}
      </div>
      {CurrentValue !== undefined && !!chartData && (
        <div
          className={classNames(
            styles.CurrentValueContainer,
            theme?.CurrentValueContainer
          )}
        >
          <span
            className={styles.CurrentValue}
            data-hc-name={`${dataHcName}-current-value`}
          >
            {CurrentValue}
          </span>{' '}
          / Current Value
        </div>
      )}
      <div
        data-hc-name={`${dataHcName}-chart`}
        className={classNames(styles.Chart, theme?.Chart, {
          [styles.NoDataDisplay]: !isLoading && !chartData
        })}
        ref={chartRef}
      >
        {isLoading && <LoadingSpinner dataHcName={`${dataHcName}-loader`} />}
        {chartData ? (
          <ResponsiveLine
            {...COMMON_RESPONSIVE_LINE_PROPS}
            xFormat={
              tooltipDateFormat === 'monthDayYear'
                ? 'time:%m/%d/%Y'
                : 'time:%m/%Y'
            }
            axisBottom={{
              ...COMMON_RESPONSIVE_LINE_PROPS.axisBottom,
              tickRotation,
              ...axisBottom
            }}
            margin={{
              ...COMMON_RESPONSIVE_LINE_PROPS.margin,
              bottom: tickRotation
                ? 40
                : COMMON_RESPONSIVE_LINE_PROPS.margin?.bottom
            }}
            data={chartData}
            axisRight={{
              ...(COMMON_RESPONSIVE_LINE_PROPS.axisRight || {}),
              format: d3FormatterForY
            }}
            yScale={{
              type: 'linear',
              min: paddedMinMaxY !== null ? paddedMinMaxY.min : undefined,
              max: paddedMinMaxY !== null ? paddedMinMaxY.max : undefined
            }}
            sliceTooltip={SliceTooltip}
            layers={[
              'grid',
              'markers',
              'axes',
              'areas',
              'crosshair',
              DashedLine !== null ? DashedLine : 'lines',
              'points',
              'slices',
              'mesh',
              'legends'
            ]}
            yFormat={d3FormatterForY}
          />
        ) : (
          !isLoading && (
            <NoContent
              className={styles.NoContent}
              dataHcName={`${dataHcName}-null`}
            >
              No Chart Data Available
            </NoContent>
          )
        )}
      </div>
      {legendItems && (
        <ChartLegend
          dataHcName={`${dataHcName}-legend`}
          legendItems={legendItems}
          theme={{
            Container: classNames(styles.ChartLegend, theme?.ChartLegend)
          }}
        />
      )}
      {stats && (
        <Stats
          dataHcName={`${dataHcName}-stats`}
          divider={true}
          className={styles.Stats}
          theme={{
            Label: styles.StatsLabel,
            Value: styles.StatsValue
          }}
          stats={stats}
        />
      )}
    </div>
  );
};
