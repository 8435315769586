import React from 'react';

import { ReportSummaryOrderItemsCellContentProps } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { DATA_HC_NAME_REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELL } from '../../../constants/ReportSummary.constants';

import { createReportSummaryOrderItemsTableCellConfig } from './ReportSummaryOrderItemsTableCell';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELL}-completed-date`;
const CellContentComponent = ({
  orderItem,
}: ReportSummaryOrderItemsCellContentProps) => (
  <div data-hc-name={dataHcName}>
    {formatDateShort(orderItem.completionDate)}
  </div>
);

export const REPORT_SUMMARY_ORDER_ITEMS_COMPLETED_DATE_CONFIG =
  createReportSummaryOrderItemsTableCellConfig({
    CellContentComponent,
    tableHeaderName: 'Completed Date',
  });
