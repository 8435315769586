import camelcaseKeys from 'camelcase-keys';

import { Account, AccountTokenResponse, UserContactInfo } from '@hcs/types';
import { ACCOUNT_URL, EHRMANTRAUT_URL } from '@hcs/urls';

import { AxiosClient, queryClient } from '../clients';
import { ACCOUNT_QUERY_KEY } from '../constants';

import {
  authHeaderFnAccountApi,
  authHeaderFnEhrmantraut,
} from './authHeaders.utils';

export const getAccessTokenFromCache = () => {
  const account = queryClient.getQueryData<Account>([ACCOUNT_QUERY_KEY]);
  return account?.validity.token;
};

const removeAllQueriesExceptAccount = () => {
  const queryCache = queryClient.getQueryCache();
  queryCache.getAll().forEach(({ queryKey }) => {
    if (queryKey.length === 1 && queryKey[0] === ACCOUNT_QUERY_KEY) {
      // leave the account query
    } else {
      queryClient.removeQueries(queryKey);
    }
  });
};

export const handleAccountUpdate = (account: Account) => {
  // Keep the account query, so the app doesn't log the user out
  removeAllQueriesExceptAccount();
  // Update the account information
  queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
};

export const resetAccountQuery = () => {
  // we need to reset the account query (as opposed to removing) so a refetch is triggered (and thus a re-render to it's subscribers)
  queryClient.resetQueries([ACCOUNT_QUERY_KEY]);
  removeAllQueriesExceptAccount();
};

export const fetchAccount = async (newAccessToken?: string) => {
  // if we have a freshly issued access token, use that instead of getting another from the refresh endpoint
  let accessToken = newAccessToken;
  if (!accessToken) {
    // refresh needs the existing access token to do some login-as checks
    const existingAccessToken = getAccessTokenFromCache();
    const accountResponse = await AxiosClient.post<AccountTokenResponse>(
      `${ACCOUNT_URL}/auth/refresh`,
      undefined,
      {
        headers: authHeaderFnAccountApi(existingAccessToken || null),
        withCredentials: true,
      }
    );
    accessToken = accountResponse.data.token.access;
  }
  const userInfoResponse = await AxiosClient.get<UserContactInfo>(
    `${EHRMANTRAUT_URL}/auth/contact-info`,
    {
      headers: authHeaderFnEhrmantraut(accessToken),
    }
  );
  const userInfoResponseCamelCase = camelcaseKeys(userInfoResponse.data, {
    deep: true,
  });
  // user info response should have everything but Validity
  const account: Account = {
    ...userInfoResponseCamelCase,
    validity: {
      expiration: 0,
      expiresOn: 0,
      token: accessToken,
    },
  };

  return account;
};
