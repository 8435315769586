import {
  CerberusStats,
  CerberusStatsFields,
  CerberusStatsFlat,
} from '@hcs/types';
import { logWarning } from '@hcs/utils';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../configs';

export const getCerberusStatsFieldValue = <F extends CerberusStatsFields>(
  cerberusStatsField: F,
  cerberusStats: CerberusStats | undefined
): CerberusStatsFlat[F] => {
  const config = CERBERUS_STATS_FIELD_CONFIGS[cerberusStatsField];
  if (!config) {
    logWarning(
      `getCerberusStatsFieldValue: no config found for field ${cerberusStatsField}`
    );
    return undefined;
  }
  return config.getValue(cerberusStats);
};

export const getCerberusStatsFieldLabel = <F extends CerberusStatsFields>(
  cerberusStatsField: F
) => {
  const config = CERBERUS_STATS_FIELD_CONFIGS[cerberusStatsField];
  if (!config) {
    logWarning(
      `getCerberusStatsFieldLabel: no config found for field ${cerberusStatsField}`
    );
    return undefined;
  }
  return config.label;
};

export const getCerberusStatsFieldLabelShort = <F extends CerberusStatsFields>(
  cerberusStatsField: F
) => {
  const config = CERBERUS_STATS_FIELD_CONFIGS[cerberusStatsField];
  if (!config) {
    logWarning(
      `getCerberusStatsFieldLabelShort: no config found for field ${cerberusStatsField}`
    );
    return undefined;
  }
  return config.labelShort;
};

export const getCerberusStatsFieldDefinition = <F extends CerberusStatsFields>(
  cerberusStatsField: F
) => {
  const config = CERBERUS_STATS_FIELD_CONFIGS[cerberusStatsField];
  if (!config) {
    logWarning(
      `getCerberusStatsFieldDefinition: no config found for field ${cerberusStatsField}`
    );
    return undefined;
  }
  return config.definition;
};
