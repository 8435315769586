import { useMemo } from 'react';

import { BuyBoxListItem, BuyBoxSearchSort, MsaLookup } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';
import { sortFn } from '@hcs/utils';

import { useBuyBoxSummaries } from './useBuyBoxSummaries';
import { useMsas } from './useMsas';

const searchFilter = (
  buyBoxListItem: BuyBoxListItem,
  search?: string
): boolean => {
  if (!search) {
    return true;
  }
  const lowerCaseSearch = search.toLocaleLowerCase();
  const lowerCaseName = buyBoxListItem.name.toLowerCase();
  const lowerCaseMsaName = buyBoxListItem.msaName
    ? buyBoxListItem.msaName.toLowerCase()
    : null;
  if (lowerCaseName.includes(lowerCaseSearch)) {
    return true;
  }
  if (lowerCaseMsaName !== null && lowerCaseMsaName.includes(lowerCaseSearch)) {
    return true;
  }
  return false;
};

export const buyBoxListFilterSort = (
  buyBoxList: BuyBoxListItem[],
  searchAndSort: BuyBoxSearchSort
) => {
  const { search, sort, removeNotActive = false } = searchAndSort;

  // Array.sort sorts in place, so make a copy to make sure we aren't modifying the passed in list
  let result = [...buyBoxList];
  if (search || removeNotActive) {
    result = buyBoxList.filter((buyBoxListItem) => {
      const passesActiveCheck = removeNotActive
        ? buyBoxListItem.isActive
        : true;
      // less expensive check first
      if (!passesActiveCheck) {
        return false;
      }
      const passesSearchCheck = searchFilter(buyBoxListItem, search);
      return passesSearchCheck;
    });
  }

  if (sort) {
    const sortField = sort.field;
    return result.sort((a, b) => {
      const aField = a[sortField];
      const bField = b[sortField];

      return sortFn(aField, bField, sort.order);
    });
  }

  return result;
};

export const useBuyBoxList = (searchAndSort?: BuyBoxSearchSort) => {
  const buyBoxSummariesQuery = useBuyBoxSummaries();
  const msasQuery = useMsas();

  const msaLookup = useMemo(() => {
    const msas = msasQuery.data;
    return msas
      ? msas.reduce<MsaLookup>((accum, msa) => {
          accum[msa.msaId] = msa;
          return accum;
        }, {})
      : undefined;
  }, [msasQuery.data]);

  let buyBoxList = useMemo(() => {
    const buyBoxes = buyBoxSummariesQuery.data;
    if (!buyBoxes || !msaLookup) {
      return [];
    }

    return buyBoxes.map((buyBox) => {
      let msa = null;
      if (buyBox.msaId && msaLookup[buyBox.msaId]) {
        msa = msaLookup[buyBox.msaId];
      }
      return {
        id: buyBox.id,
        name: buyBox.name,
        msaName: msa ? msa.msaName : null,
        isActive: buyBox.enabled,
        lastModified: new Date(buyBox.lastModified.modifiedAt),
        lastModifiedBy: buyBox.lastModified.email,
      };
    });
  }, [buyBoxSummariesQuery.data, msaLookup]);

  buyBoxList = useMemo(() => {
    if (searchAndSort) {
      return buyBoxListFilterSort(buyBoxList, searchAndSort);
    }
    return buyBoxList;
  }, [buyBoxList, searchAndSort]);

  return {
    ...combineUseQueryResult([buyBoxSummariesQuery, msasQuery]),
    data: buyBoxList,
  };
};
