import { gql } from 'graphql-request';

import { FRAGMENT_LISTING_DETAILS_MEDIA } from './fragments/ListingDetails.graphql';
import { FRAGMENT_LISTING_MEDIA } from './fragments/ListingMedia.graphql';

export const QUERY_PROPERTY_STATE_MEDIA = gql`
  ${FRAGMENT_LISTING_MEDIA}
  ${FRAGMENT_LISTING_DETAILS_MEDIA}
  query PropertyStateMedia($cerberusInput: CerberusInput!) {
    # HC APP: Value Report
    # HC APP: Lead Feed
    lookup(id: $cerberusInput) {
      latestListing {
        sale {
          ...ListingDetailsMedia
          media {
            hc {
              ...ListingMedia
            }
          }
        }
      }
    }
  }
`;
