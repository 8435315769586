import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CerberusApi } from '@hcs/cerberus';
import {
  CerberusInputHcs,
  PropertyStateCoreHcDocument,
  PropertyStateCoreHcQuery,
  PropertyStateCoreHcQueryVariables,
} from '@hcs/types';
import { PropertyStateArgsCore, PropertyStateType } from '@hcs/types';
import { formatFullAddress, logException } from '@hcs/utils';

import { cerberusLookupResponseToPropertyStateHcCore } from '../utils';

export type PropertyStateCoreUseQueryOptions = UseQueryOptions<
  PropertyStateArgsCore,
  AxiosError,
  PropertyStateArgsCore,
  [PropertyStateType.Core, string | number]
>;
export const usePropertyStateCore = (
  cerberusInput: CerberusInputHcs | undefined,
  options?: PropertyStateCoreUseQueryOptions
): UseQueryResult<PropertyStateArgsCore> => {
  const queryResults = useQuery(
    [
      PropertyStateType.Core,
      cerberusInput?.hcAddressId ||
        cerberusInput?.slug ||
        formatFullAddress(cerberusInput),
    ],
    async () => {
      if (cerberusInput) {
        const response = await CerberusApi.fetchQuery<
          PropertyStateCoreHcQuery,
          PropertyStateCoreHcQueryVariables
        >(PropertyStateCoreHcDocument, {
          cerberusInput,
        });
        const result: PropertyStateArgsCore = {
          propertyStateType: PropertyStateType.Core,
          propertyState: cerberusLookupResponseToPropertyStateHcCore(response),
        };
        return result;
      } else {
        const msg =
          'Error in usePropertyState: cerberusInput is unexpectedly null';
        logException(msg);
        throw msg;
      }
    },
    {
      ...options,
      enabled:
        !!cerberusInput &&
        (options?.enabled !== undefined ? options.enabled : true),
    }
  );
  return queryResults;
};
