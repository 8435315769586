import { TableCellProps, TableHeaderCellProps } from '../design-system';

import { OmOrder } from './Order.types';
import { OrderItem } from '.';

export interface ReportSummaryCellContentProps {
  order: OmOrder;
}

export interface ReportSummaryOrderItemsCellContentProps {
  orderItem: OrderItem;
}

export type YearSummary = {
  [monthKey: string]: OmOrder[];
};

export type ReportSummary = {
  [yearKey: string]: YearSummary;
};

export enum ReportSummaryTableOptions {
  completedDate = 'completedDate',
  download = 'download',
  order = 'order',
  orderedBy = 'orderedBy',
  amountOrdered = 'amountOrdered',
  productType = 'productType',
  label = 'label',
  more = 'more'
}

export enum ReportSummaryOrderItemsTableOptions {
  address = 'address',
  city = 'city',
  completedDate = 'completedDate',
  fileId = 'fileId',
  state = 'state',
  zip = 'zip'
}

interface CellConfig {
  HeaderCell: {
    ({ ...tableHeaderCellProps }): JSX.Element;
    defaultProps: Partial<TableHeaderCellProps>;
  };
  label: string;
}

export interface ReportSummaryCellConfig extends CellConfig {
  ContentCell: {
    ({ order }: { order: OmOrder }): JSX.Element;
    defaultProps: Partial<TableCellProps>;
  };
}

export interface ReportSummaryOrderItemsCellConfig extends CellConfig {
  ContentCell: {
    ({ orderItem }: { orderItem: OrderItem }): JSX.Element;
    defaultProps: Partial<TableCellProps>;
  };
}
