import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import Add from './AddIcon.svg?react';
import Check from './CheckIcon.svg?react';
import Complete from './CompleteIcon.svg?react';
import Dash from './DashIcon.svg?react';
import DotDashed from './DotDashedIcon.svg?react';
import DownArrow from './DownArrowIcon.svg?react';
import Error from './ErrorIcon.svg?react';
import Remove from './RemoveIcon.svg?react';
import UpArrow from './UpArrowIcon.svg?react';

export const AddIcon = (props: IconProps) => useIcon(Add, props);
export const CheckIcon = (props: IconProps) => useIcon(Check, props);
export const CompleteIcon = (props: IconProps) => useIcon(Complete, props);
export const DashIcon = (props: IconProps) => useIcon(Dash, props);
export const DotDashedIcon = (props: IconProps) => useIcon(DotDashed, props);
export const DownArrowIcon = (props: IconProps) => useIcon(DownArrow, props);
export const ErrorIcon = (props: IconProps) => useIcon(Error, props);
export const RemoveIcon = (props: IconProps) => useIcon(Remove, props);
export const UpArrowIcon = (props: IconProps) => useIcon(UpArrow, props);
