import React from 'react';

import { Anchor } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import {
  getPropertyPreviewLegacyUrl,
  getPropertyPreviewPath,
} from '@hcs/hc-products';
import {
  LinkComponent,
  LinkComponentProps,
  LinkComponentType,
} from '@hcs/routing';
import { CerberusInputHcs } from '@hcs/types';

interface PropertyPreviewLinkProps {
  cerberusInputHcs: CerberusInputHcs;
}
export const PropertyPreviewLink: LinkComponentType<
  PropertyPreviewLinkProps
> = ({
  dataHcName,
  cerberusInputHcs,
  children,
  className,
  ...rest
}: LinkComponentProps<PropertyPreviewLinkProps>) => {
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REPORT_API"
      next={
        <LinkComponent
          dataHcName={dataHcName}
          to={getPropertyPreviewPath(cerberusInputHcs)}
          className={className}
          {...rest}
        >
          {children}
        </LinkComponent>
      }
      now={
        <Anchor
          target="_blank"
          dataHcName={dataHcName}
          href={getPropertyPreviewLegacyUrl(cerberusInputHcs)}
          className={className}
          {...rest}
        >
          {children}
        </Anchor>
      }
    />
  );
};
