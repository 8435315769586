import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './TextBadge.module.css';

export const TEXT_BADGE_ICON_DIMENSIONS = 16;
export interface TextBadgeProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  value: ReactNode;
  label?: ReactNode;
  icon?: ReactNode;
}
export const TextBadge = ({
  dataHcName,
  value,
  label,
  className,
  icon,
}: TextBadgeProps) => {
  return (
    <div
      className={classNames(styles.TextBadge, className)}
      data-hc-name={dataHcName}
    >
      {icon}
      {label && <label data-hc-name={`${dataHcName}-label`}>{label}:</label>}
      <span data-hc-name={`${dataHcName}-value`}>{value}</span>
    </div>
  );
};

export const TextBadges = ({
  children,
  className,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={classNames(styles.TextBadges, className)}>{children}</div>
  );
};
