import { useQuery } from '@tanstack/react-query';

import { OrgAdminApi } from '../api/org-admin.api';

const QUERY_USER_DATA = 'user-data';
export const useUserData = (userId: string | undefined) => {
  return useQuery(
    [QUERY_USER_DATA, userId],
    async () => {
      if (userId) {
        return await OrgAdminApi.fetchUserData(userId);
      } else {
        throw new Error('[User data] No Owner ID provided');
      }
    },
    {
      enabled: !!userId,
    }
  );
};
