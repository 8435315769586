import { generatePath } from 'react-router';

import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';
import {
  Report,
  ReportCreateApiInputs,
  ReportCreateInputs,
  ReportId,
  ReportSearchApiInputs,
  ReportSearchInputs,
} from '@hcs/types';
import { isNumericStr } from '@hcs/utils';

export const checkIsPublicLink = (reportId: ReportId): boolean =>
  typeof reportId === 'string';

export const generatePublicLinkUrl = (publicLinkUid: string) => {
  const url = `https://${window.location.host}${generatePath(
    `${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.PUBLIC_REPORT}`,
    {
      reportId: publicLinkUid,
    }
  )}`;
  return url;
};

export const parseReportId = (reportId: ReportId | Report): number | string => {
  if (typeof reportId === 'number') {
    return reportId;
  } else if (typeof reportId === 'string') {
    if (isNumericStr(reportId)) {
      return Number(reportId);
    } else {
      // is Public Link UID
      return reportId;
    }
  } else {
    return reportId.id;
  }
};

export const prepReportSearchInputsForApi = (
  reportSearchInputs: ReportSearchInputs
): ReportSearchApiInputs => {
  const {
    hcAddressId,
    ownerId,
    clientId,
    reportType,
    reportConfigSlug,
    ...otherInputs
  } = reportSearchInputs;
  const results: ReportSearchApiInputs = { ...otherInputs };
  if (reportConfigSlug) {
    if (Array.isArray(reportConfigSlug)) {
      if (reportConfigSlug.length) {
        results.reportConfigSlug = reportConfigSlug.join(',');
      }
    } else {
      results.reportConfigSlug = reportConfigSlug;
    }
  }
  if (reportType) {
    results.type = reportType;
  }
  if (hcAddressId) {
    results.addressID = hcAddressId;
  }
  if (ownerId) {
    results.ownerID = ownerId;
  }
  if (clientId) {
    results.clientID = clientId;
  }
  return results;
};
export const prepReportCreateInputsForApi = (
  reportCreateInputs: ReportCreateInputs
): ReportCreateApiInputs => {
  const { hcAddressId, clientFileId, synchronous, comps, ...otherInputs } =
    reportCreateInputs;
  const results: ReportCreateApiInputs = {
    ...otherInputs,
    comps: comps?.map((c) => {
      console.log('c', c.hcAddressId);
      return {
        addressID: c.hcAddressId || 0,
        value: c.value,
      };
    }),
    clientID: clientFileId,
    addressID: hcAddressId,
    async: synchronous ? false : true,
  };
  return results;
};
