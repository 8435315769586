export enum MarketRiskLevel {
  VeryHigh = 'VERY_HIGH',
  High = 'HIGH',
  Modest = 'MODEST',
  Low = 'LOW',
  VeryLow = 'VERY_LOW',
}
export interface MarketAnalysisSchema {
  risk: {
    current: number | null;
    lastYear: number | null;
    score: MarketRiskLevel | null;
    riskOfDecline?: number | null;
  };
}
