import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useAccount } from '@hcs/auth';
import { Button } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { Dropdown } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import {
  AddressFormFields,
  DeliverySpeed,
  FIELD_NAMES,
  OrderDetailsFormFields,
  OrderTypeDescriptor,
} from '@hcs/types';
import { humanReadableDate } from '@hcs/utils';

import { STATES } from '../constants';
import {
  getFieldMetaByOrderType,
  getRequiredErrorText,
  getRequiredIndicator,
  removeFileExtension,
} from '../utils';

import styles from './OrderDetails.module.css';

interface Props {
  itemsSource: string;
  items: AddressFormFields[];
  orderFileName: string;
  deliverySpeed?: DeliverySpeed;
  selectedOrderType?: OrderTypeDescriptor;
  onSubmit: (orderDetails: OrderDetailsFormFields) => void;
  onSubmitIsLoading: boolean;
}

const dataHcName = 'order-details';
export const OrderDetails = ({
  itemsSource,
  items,
  orderFileName,
  deliverySpeed,
  selectedOrderType,
  onSubmit,
  onSubmitIsLoading,
}: Props) => {
  const { data: accountData } = useAccount();

  let name;
  if (itemsSource === 'entry') {
    name = items[0]?.addressFullLine;
  } else if (itemsSource === 'csv') {
    name = orderFileName;
    if (name) {
      name = removeFileExtension(name);
    }
  }
  if (name && name.length > 100) {
    name = name.substr(0, 100);
  }

  const { handleSubmit, control, getValues, formState } =
    useForm<OrderDetailsFormFields>({
      defaultValues: {
        // assumes account already loaded on initial render
        [FIELD_NAMES.CLIENT_NAME]: accountData?.user.organization?.name,
        [FIELD_NAMES.CLIENT_ADDRESS]:
          accountData?.user.streetAddress || undefined,
        [FIELD_NAMES.CLIENT_CITY]: accountData?.user.city || undefined,
        [FIELD_NAMES.CLIENT_STATE]: accountData?.user.state || undefined,
        [FIELD_NAMES.CLIENT_ZIPCODE]: accountData?.user.zipCode || undefined,
        intendedUserSame: true,
        name,
      },
    });

  const intendedUserSameValue = getValues().intendedUserSame;

  if (!selectedOrderType) return null;
  const fieldMeta = getFieldMetaByOrderType(selectedOrderType);

  const stateOptions = STATES.map((state) => ({
    label: state,
    value: state,
  }));

  return (
    <div data-hc-name={dataHcName}>
      <h3>Enter Order Details</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.InputSameRow}>
          {fieldMeta[FIELD_NAMES.ORDER_NAME].shown && (
            <Controller
              name={FIELD_NAMES.ORDER_NAME}
              control={control}
              rules={{ required: fieldMeta[FIELD_NAMES.ORDER_NAME].required }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  className={styles.Input}
                  dataHcName={`${dataHcName}-order-name-input`}
                  placeholder={`Order Name ${getRequiredIndicator(
                    fieldMeta[FIELD_NAMES.ORDER_NAME].required
                  )}`}
                  error={
                    invalid ? getRequiredErrorText('Order Name') : undefined
                  }
                  {...field}
                  value={field.value || ''}
                />
              )}
            />
          )}
          {fieldMeta[FIELD_NAMES.CUSTOMER_ORDER_ID].shown && (
            <Controller
              name={FIELD_NAMES.CUSTOMER_ORDER_ID}
              control={control}
              rules={{
                required: fieldMeta[FIELD_NAMES.CUSTOMER_ORDER_ID].required,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  className={styles.Input}
                  dataHcName={`${dataHcName}-order-id-input`}
                  placeholder={`Order ID  ${getRequiredIndicator(
                    fieldMeta[FIELD_NAMES.CUSTOMER_ORDER_ID].required
                  )}`}
                  error={invalid ? getRequiredErrorText('Order ID') : undefined}
                  {...field}
                  value={field.value || ''}
                />
              )}
            />
          )}
        </div>
        {fieldMeta[FIELD_NAMES.CLIENT_NAME].shown && (
          <Controller
            name={FIELD_NAMES.CLIENT_NAME}
            control={control}
            rules={{
              required: fieldMeta[FIELD_NAMES.CLIENT_NAME].required,
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                className={styles.Input}
                dataHcName={`${dataHcName}-customer-name-input`}
                placeholder={`Customer Name  ${getRequiredIndicator(
                  fieldMeta[FIELD_NAMES.CLIENT_NAME].required
                )}`}
                error={
                  invalid ? getRequiredErrorText('Customer Name') : undefined
                }
                {...field}
                value={field.value || ''}
              />
            )}
          />
        )}
        {fieldMeta[FIELD_NAMES.CLIENT_ADDRESS].shown && (
          <Controller
            name={FIELD_NAMES.CLIENT_ADDRESS}
            control={control}
            rules={{
              required: fieldMeta[FIELD_NAMES.CLIENT_ADDRESS].required,
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                className={styles.Input}
                dataHcName={`${dataHcName}-customer-address-input`}
                placeholder={`Customer Address  ${getRequiredIndicator(
                  fieldMeta[FIELD_NAMES.CLIENT_ADDRESS].required
                )}`}
                error={
                  invalid ? getRequiredErrorText('Customer Address') : undefined
                }
                {...field}
                value={field.value || ''}
              />
            )}
          />
        )}

        <div className={styles.InputSameRow}>
          {fieldMeta[FIELD_NAMES.CLIENT_CITY].shown && (
            <Controller
              name={FIELD_NAMES.CLIENT_CITY}
              control={control}
              rules={{
                required: fieldMeta[FIELD_NAMES.CLIENT_CITY].required,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  className={styles.Input}
                  dataHcName={`${dataHcName}-customer-city-input`}
                  placeholder={`Customer City  ${getRequiredIndicator(
                    fieldMeta[FIELD_NAMES.CLIENT_CITY].required
                  )}`}
                  error={
                    invalid ? getRequiredErrorText('Customer City') : undefined
                  }
                  {...field}
                  value={field.value || ''}
                />
              )}
            />
          )}
          {fieldMeta[FIELD_NAMES.CLIENT_STATE].shown && (
            <Controller
              name={FIELD_NAMES.CLIENT_STATE}
              control={control}
              rules={{
                required: fieldMeta[FIELD_NAMES.CLIENT_STATE].required,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Dropdown
                  error={
                    invalid ? getRequiredErrorText('Customer State') : undefined
                  }
                  className={styles.Input}
                  dataHcName={`${dataHcName}-customer-state-input`}
                  onSelect={field.onChange}
                  value={field.value || null}
                  placeholder={`Customer State  ${getRequiredIndicator(
                    fieldMeta[FIELD_NAMES.CLIENT_STATE].required
                  )}`}
                  options={stateOptions}
                />
              )}
            />
          )}
          {fieldMeta[FIELD_NAMES.CLIENT_ZIPCODE].shown && (
            <Controller
              name={FIELD_NAMES.CLIENT_ZIPCODE}
              control={control}
              rules={{
                required: fieldMeta[FIELD_NAMES.CLIENT_ZIPCODE].required,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  className={styles.Input}
                  dataHcName={`${dataHcName}-customer-zip-input`}
                  placeholder={`Customer Zipcode  ${getRequiredIndicator(
                    fieldMeta[FIELD_NAMES.CLIENT_ZIPCODE].required
                  )}`}
                  error={
                    invalid
                      ? getRequiredErrorText('Customer Zipcode')
                      : undefined
                  }
                  {...field}
                  value={field.value || ''}
                />
              )}
            />
          )}
        </div>
        {fieldMeta[FIELD_NAMES.LENDER_NAME].shown && (
          <Controller
            name={FIELD_NAMES.LENDER_NAME}
            control={control}
            render={({ field }) => (
              <Checkbox
                dataHcName={`${dataHcName}-lender-name-checkbox`}
                checked={!!field.value}
                onChange={field.onChange}
                label="Intended user is the same as customer info"
              />
            )}
          />
        )}
        {fieldMeta[FIELD_NAMES.LENDER_NAME].shown && !intendedUserSameValue && (
          <>
            {fieldMeta[FIELD_NAMES.LENDER_NAME].shown && (
              <Controller
                name={FIELD_NAMES.LENDER_NAME}
                control={control}
                rules={{
                  required: fieldMeta[FIELD_NAMES.LENDER_NAME].required,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    className={styles.Input}
                    dataHcName={`${dataHcName}-intended-user-name-input`}
                    placeholder={`Intended User Name  ${getRequiredIndicator(
                      fieldMeta[FIELD_NAMES.LENDER_NAME].required
                    )}`}
                    error={
                      invalid
                        ? getRequiredErrorText('Intended User Name')
                        : undefined
                    }
                    {...field}
                    value={field.value || ''}
                  />
                )}
              />
            )}
            {fieldMeta[FIELD_NAMES.LENDER_ADDRESS].shown && (
              <Controller
                name={FIELD_NAMES.LENDER_ADDRESS}
                control={control}
                rules={{
                  required: fieldMeta[FIELD_NAMES.LENDER_ADDRESS].required,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    className={styles.Input}
                    dataHcName={`${dataHcName}-lender-address-input`}
                    placeholder={`Intended User Address  ${getRequiredIndicator(
                      fieldMeta[FIELD_NAMES.LENDER_ADDRESS].required
                    )}`}
                    error={
                      invalid
                        ? getRequiredErrorText('Intended User Address')
                        : undefined
                    }
                    {...field}
                    value={field.value || ''}
                  />
                )}
              />
            )}

            <div className={styles.InputSameRow}>
              {fieldMeta[FIELD_NAMES.LENDER_CITY].shown && (
                <Controller
                  name={FIELD_NAMES.LENDER_CITY}
                  control={control}
                  rules={{
                    required: fieldMeta[FIELD_NAMES.LENDER_CITY].required,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      className={styles.Input}
                      dataHcName={`${dataHcName}-lender-city-input`}
                      placeholder={`Intended User City  ${getRequiredIndicator(
                        fieldMeta[FIELD_NAMES.LENDER_CITY].required
                      )}`}
                      error={
                        invalid
                          ? getRequiredErrorText('Intended User City')
                          : undefined
                      }
                      {...field}
                      value={field.value || ''}
                    />
                  )}
                />
              )}
              {fieldMeta[FIELD_NAMES.LENDER_STATE].shown && (
                <Controller
                  name={FIELD_NAMES.LENDER_STATE}
                  control={control}
                  rules={{
                    required: fieldMeta[FIELD_NAMES.LENDER_STATE].required,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Dropdown
                      error={
                        invalid
                          ? getRequiredErrorText('Intended User State')
                          : undefined
                      }
                      className={styles.Input}
                      dataHcName={`${dataHcName}-lender-state-input`}
                      onSelect={field.onChange}
                      value={field.value}
                      placeholder={`Intended User State  ${getRequiredIndicator(
                        fieldMeta[FIELD_NAMES.LENDER_STATE].required
                      )}`}
                      options={stateOptions}
                    />
                  )}
                />
              )}
              {fieldMeta[FIELD_NAMES.LENDER_ZIPCODE].shown && (
                <Controller
                  name={FIELD_NAMES.LENDER_ZIPCODE}
                  control={control}
                  rules={{
                    required: fieldMeta[FIELD_NAMES.LENDER_ZIPCODE].required,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      className={styles.Input}
                      dataHcName={`${dataHcName}-lender-zip-input`}
                      placeholder={`Intended User Zipcode  ${getRequiredIndicator(
                        fieldMeta[FIELD_NAMES.LENDER_ZIPCODE].required
                      )}`}
                      error={
                        invalid
                          ? getRequiredErrorText('Intended User Zipcode')
                          : undefined
                      }
                      {...field}
                      value={field.value || ''}
                    />
                  )}
                />
              )}
            </div>
          </>
        )}

        <div className={styles.InputSameRow}>
          {fieldMeta[FIELD_NAMES.INTENDED_USE].shown && (
            <Controller
              name={FIELD_NAMES.INTENDED_USE}
              control={control}
              rules={{
                required: fieldMeta[FIELD_NAMES.INTENDED_USE].required,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  className={styles.Input}
                  dataHcName={`${dataHcName}-intended-use-input`}
                  placeholder={`Intended Use  ${getRequiredIndicator(
                    fieldMeta[FIELD_NAMES.INTENDED_USE].required
                  )}`}
                  error={
                    invalid ? getRequiredErrorText('Intended Use') : undefined
                  }
                  {...field}
                  value={field.value || ''}
                />
              )}
            />
          )}
          {fieldMeta[FIELD_NAMES.LABEL].shown && (
            <Controller
              name={FIELD_NAMES.LABEL}
              control={control}
              rules={{
                required: fieldMeta[FIELD_NAMES.LABEL].required,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  className={styles.Input}
                  dataHcName={`${dataHcName}-label-input`}
                  placeholder={`Label  ${getRequiredIndicator(
                    fieldMeta[FIELD_NAMES.LABEL].required
                  )}`}
                  error={invalid ? getRequiredErrorText('Label') : undefined}
                  {...field}
                  value={field.value || ''}
                />
              )}
            />
          )}
        </div>

        {fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION].shown && (
          <Controller
            name={FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION}
            control={control}
            rules={{
              required:
                fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION].required,
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                className={styles.Input}
                dataHcName={`${dataHcName}-special-comments-input`}
                placeholder={`Special Comments (inspection)  ${getRequiredIndicator(
                  fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION].required
                )}`}
                error={
                  invalid
                    ? getRequiredErrorText('Special Comments (inspection)')
                    : undefined
                }
                {...field}
                value={field.value || ''}
              />
            )}
          />
        )}
        {fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_AMC].shown && (
          <Controller
            name={FIELD_NAMES.SPECIAL_COMMENTS_AMC}
            control={control}
            rules={{
              required: fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_AMC].required,
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                className={styles.Input}
                dataHcName={`${dataHcName}-special-comments-amc-input`}
                placeholder={`Special Comments (AMC)  ${getRequiredIndicator(
                  fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_AMC].required
                )}`}
                error={
                  invalid
                    ? getRequiredErrorText('Special Comments (AMC)')
                    : undefined
                }
                {...field}
                value={field.value || ''}
              />
            )}
          />
        )}
        {deliverySpeed &&
          deliverySpeed.estimatedDueDate &&
          deliverySpeed.numberOfDays != null && (
            <div className={styles.DueDateContainer}>
              Due date: Reports with no issues will be delivered by{' '}
              {humanReadableDate(new Date(deliverySpeed.estimatedDueDate))}. If
              we find issues with addresses in your order, those addresses will
              be due {deliverySpeed.numberOfDays} business days after the issues
              are resolved.
            </div>
          )}

        <div className={styles.SubmitContainer}>
          <Button
            loading={onSubmitIsLoading}
            dataHcName={`${dataHcName}-submit`}
            type="submit"
            disabled={!formState.isValid}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
