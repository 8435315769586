import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useNavigateToPortfolio } from '@hcs/hc-products';
import { AddPropertiesTabSection } from '@hcs/portfolio';

import { useNavigateToPortfolioPage } from '../../hooks/useNavigateToPortfolioPage';

const dataHcName = 'upload-properties-page';

interface Props {
  className: string;
}

export const UploadPropertiesPage = ({ className }: Props) => {
  const [searchParams] = useSearchParams();
  const portfolioId = searchParams.get('portfolioId');
  const navigateToPortfolioDashboard = useNavigateToPortfolio();
  const navigateToPortfolio = useNavigateToPortfolioPage();

  if (!portfolioId) {
    navigateToPortfolioDashboard();
    return null;
  }

  return (
    <>
      <div className={className} data-hc-name={`${dataHcName}-heading`}>
        Select Properties
      </div>
      <AddPropertiesTabSection
        portfolioId={portfolioId}
        onBulkUploadSuccess={navigateToPortfolioDashboard}
        onClickGoToPortfolio={navigateToPortfolio}
      />
    </>
  );
};

export default UploadPropertiesPage;
