import {
  DrawRectangleMode,
  FeatureCollection,
  ModeProps,
} from '@nebula.gl/edit-modes';
import { PointerMoveEvent } from '@nebula.gl/edit-modes/src/types';

import { handlePointerMove } from '@hcs/maps';

export class MeasureDrawRectangle extends DrawRectangleMode {
  handlePointerMove(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ): void {
    handlePointerMove(event, props, this.getTentativeGuide(props));
    super.handlePointerMove(event, props);
  }
}
