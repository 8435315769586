import { CompTypes } from '@hcs/types';
import {
  CompsFilteredDocument,
  DocumentRoles,
  RentalCompsFilteredDocument,
  ReportId,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useCompsFilteredDocument = (
  reportId: ReportId,
  compType: CompTypes
) => {
  return useSingleDocumentRole<
    CompsFilteredDocument | RentalCompsFilteredDocument
  >(
    reportId,
    compType === CompTypes.Rental
      ? DocumentRoles.RentalCompsFiltered
      : DocumentRoles.CompsFiltered
  );
};
