import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import classNames from 'classnames';

import { CloseButton } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { PhotoMarker } from '@hcs/design-system';
import { SubjectMarker as SubjectMarkerComponent } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { GoogleMapsLink } from '@hcs/street-view';
import { GeoLocation, GeoPrecision } from '@hcs/types';
import { ReportId, SubjectPaths } from '@hcs/types';
import { LimitedReportTypes } from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { useDocumentPatch } from '../../hooks';
import { useReportLimitedType } from '../../hooks/useReportLimitedType';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { useSubjectPhotos } from '../../hooks/useSubjectPhotos';

import styles from './SubjectMarker.module.css';

interface Props {
  reportId: ReportId;
  enableLocationUpdate?: boolean;
  onSave?: VoidFunction;
  onReset?: VoidFunction;
  onClick?: VoidFunction;
}
const PATH_PRECISION: SubjectPaths =
  '/data/propertyState/location/geoPrecision';
const PATH_LAT: SubjectPaths = '/data/propertyState/location/latitude';
const PATH_LNG: SubjectPaths = '/data/propertyState/location/longitude';
const dataHcName = 'subject-marker';
export const SubjectMarker = ({
  reportId,
  onClick,
  enableLocationUpdate,
  onSave,
  onReset,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [intermediateLocation, setIntermediateLocation] =
    useState<GeoLocation>();
  const limitedReportQuery = useReportLimitedType(reportId);
  const subjectQuery = useSubjectDocument(reportId);
  const subjectPhotosQuery = useSubjectPhotos(reportId);
  const { mutate: patchDocument } = useDocumentPatch(reportId);
  const combinedQuery = combineUseQueryResult([
    limitedReportQuery,
    subjectQuery,
  ]);
  const { data: subjectDocument } = subjectQuery;
  const { data: limitedReport } = limitedReportQuery;
  if (!combinedQuery.isFetched || !subjectDocument || !limitedReport) {
    return null;
  }
  const { locationProvidedByUser, limitedMapping } = limitedReport;
  const longitude =
    intermediateLocation?.longitude ||
    subjectDocument.data.propertyState.location?.longitude;
  const latitude =
    intermediateLocation?.latitude ||
    subjectDocument.data.propertyState.location?.latitude;
  const isLocationLimited = limitedMapping[LimitedReportTypes.MissingLocation];
  const subjectDoc = subjectQuery.data;
  const draggable =
    enableLocationUpdate || (isLocationLimited && !locationProvidedByUser);
  if (!latitude || !longitude) return null;
  const offset: mapboxgl.Offset = [-5, -50];
  return (
    <>
      <Marker
        key="subject"
        longitude={longitude}
        latitude={latitude}
        // Marker does not take className anymore, however id does take
        // a style property and zIndex needs to be set at this level.
        style={{ zIndex: 2 }}
        draggable={draggable}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragEnd={(evt) => {
          const newGeoLocation: GeoLocation = {
            longitude: evt.lngLat.lng,
            latitude: evt.lngLat.lat,
          };
          setIsDragging(false);
          setIntermediateLocation(newGeoLocation);
        }}
      >
        <ExperienceFlag
          experienceFlagId="PEXP_REDESIGN"
          next={
            <PhotoMarker
              url={subjectPhotosQuery.data?.previewPhoto?.url}
              className={classNames(styles.SubjectMarker, {
                [styles.locationLimited]:
                  isLocationLimited || limitedReport.locationProvidedByUser,
              })}
              onClick={!draggable ? onClick : undefined}
              dataHcName={dataHcName}
            />
          }
          now={
            <SubjectMarkerComponent
              className={classNames(styles.SubjectMarker, {
                [styles.locationLimited]:
                  isLocationLimited || limitedReport.locationProvidedByUser,
              })}
              onClick={onClick}
              dataHcName={dataHcName}
            />
          }
        />
      </Marker>
      {draggable && !isDragging && (
        <Popup
          longitude={longitude}
          latitude={latitude}
          closeButton={false}
          closeOnClick={false}
          anchor="bottom"
          offset={offset}
        >
          <div
            data-hc-name={`${dataHcName}-instructions`}
            className={styles.LocationInstructions}
          >
            <div
              data-hc-name={`${dataHcName}-instructions-title`}
              className={styles.Title}
            >
              Set Exact Location
            </div>
            {intermediateLocation && subjectDoc ? (
              <>
                <CloseButton
                  dataHcName={`${dataHcName}-close-button`}
                  className={styles.CloseButton}
                  onClick={() => {
                    setIntermediateLocation(undefined);
                    onReset?.();
                  }}
                />
                <ActionButtons
                  dataHcName={`${dataHcName}-actions`}
                  actions={[
                    {
                      dataHcName: `${dataHcName}-save`,
                      label: 'Save Change',
                      onClick: () => {
                        patchDocument({
                          reportId,
                          operations: [
                            {
                              op: 'add',
                              path: PATH_LAT,
                              value: intermediateLocation.latitude,
                            },
                            {
                              op: 'add',
                              path: PATH_LNG,
                              value: intermediateLocation.longitude,
                            },
                            {
                              op: 'add',
                              path: PATH_PRECISION,
                              value: GeoPrecision.Manual,
                            },
                          ],
                          document: subjectDoc,
                        });
                        onSave?.();
                      },
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <p
                  className={styles.Copy}
                  data-hc-name={`${dataHcName}-instructions-copy`}
                >
                  Please drag the pin to the location where the subject property
                  should be.
                </p>
                <div
                  className={styles.Address}
                  data-hc-name={`${dataHcName}-instructions-address`}
                >
                  <div
                    data-hc-name={`${dataHcName}-instructions-address-street`}
                  >
                    {formatStreetAddress(
                      subjectDocument.data.propertyState.location
                    )}
                  </div>
                  <div
                    data-hc-name={`${dataHcName}-instructions-address-citystatezip`}
                  >
                    {formatCityStateZip(
                      subjectDocument.data.propertyState.location
                    )}
                  </div>
                </div>
                <GoogleMapsLink
                  location={subjectDocument.data.propertyState.location}
                />
              </>
            )}
          </div>
        </Popup>
      )}
    </>
  );
};
