import React from 'react';

import styles from './ValueSelector.module.css';

interface Props {
  dataHcName: string;
  onClick: VoidFunction;
}

export const ValueSelector = ({ dataHcName, onClick }: Props) => {
  return (
    <span onClick={onClick} className={styles.Link} data-hc-name={dataHcName}>
      Choose a different value method
    </span>
  );
};
