import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './PortfolioSetupFieldTemplate.module.css';

interface PortfolioSetupFieldTemplateTheme {
  InputContainer?: string;
}

export interface PortfolioSetupFieldTemplateProps {
  className?: string;
  label: ReactNode;
  fieldName: string;
  children?: ReactNode;
  theme?: PortfolioSetupFieldTemplateTheme;
}

export const PortfolioSetupFieldTemplate = ({
  children,
  label,
  fieldName,
  className,
  theme,
}: PortfolioSetupFieldTemplateProps) => {
  const dataHcName = 'portfolio-setup-field';

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.InputLayout, className)}
    >
      <div className={styles.InputLabelContainer}>
        <div
          data-hc-name={`${dataHcName}-${fieldName}`}
          className={styles.InputLabel}
        >
          {label}
        </div>
      </div>
      <div className={classNames(styles.InputContainer, theme?.InputContainer)}>
        {children}
      </div>
    </div>
  );
};
