import React from 'react';

import { IconProps } from '@hcs/types';
import { TractStats } from '@hcs/types';
import { AvmFactor } from '@hcs/types';

import {
  BAR_ONE_COLOR,
  MAIN_BAR_COLOR,
} from '../features/AvmBreakdown/AvmBreakdown';
import { AvmBreakdownPie } from '../features/AvmBreakdown/AvmBreakdownPieChart';
import { AvmBreakdownAvmTypeChart } from '../features/AvmBreakdown/AvmBreakdownTypeBarChart';

const BAR_TWO_COLOR_NEGATIVE = '#FF9F9A';

export const createBarData = (
  subjectValue: number,
  avmValue: number,
  isPercentage: boolean,
  isAgeAvmFactor: boolean
) => {
  let color;
  if (isAgeAvmFactor) {
    // If age is higher than average bar should be negative
    color = avmValue > subjectValue ? MAIN_BAR_COLOR : BAR_TWO_COLOR_NEGATIVE;
  } else {
    color = subjectValue > avmValue ? MAIN_BAR_COLOR : BAR_TWO_COLOR_NEGATIVE;
  }

  return [
    {
      value: isPercentage ? Math.trunc(avmValue * 100) : avmValue,
      bar: 1,
      color: BAR_ONE_COLOR,
    },
    {
      value: isPercentage ? 100 - Math.trunc(avmValue * 100) : subjectValue,
      bar: 2,
      color,
    },
  ];
};

export const getAvmValue = (avmFactor: AvmFactor) => {
  if (avmFactor.__typeName === 'BedroomsAvmFactor') {
    return avmFactor.medianBedrooms;
  } else if (avmFactor.__typeName === 'BasementAvmFactor') {
    return avmFactor.basementPercent;
  } else if (avmFactor.__typeName === 'PoolAvmFactor') {
    return avmFactor.poolPercent;
  } else if (avmFactor.__typeName === 'AgeAvmFactor') {
    return avmFactor.medianAge;
  } else if (avmFactor.__typeName === 'BathroomsAvmFactor') {
    return avmFactor.medianBathrooms;
  } else if (avmFactor.__typeName === 'LotSizeAvmFactor') {
    return avmFactor.medianLotSize;
  } else if (avmFactor.__typeName === 'LivingAreaAvmFactor') {
    return avmFactor.medianLivingArea;
  }
  return 0;
};

export const createAvmFactorTab = (
  dataHcName: string,
  tabId: string,
  avmFactorDataHcName: string,
  subjectValue: number,
  avmFactor: AvmFactor,
  title: string,
  Icon: (svgProps: IconProps) => JSX.Element,
  valueLeftAppendStr: string,
  valueRightAppendStr?: string,
  tractStats?: TractStats
) => {
  const isPercentageType =
    avmFactor.__typeName === 'PoolAvmFactor' ||
    avmFactor.__typeName === 'BasementAvmFactor';

  const chartType =
    avmFactor.__typeName === 'PoolAvmFactor' ? 'Pool' : 'Basement';

  const percent = (value: number) => {
    return 100 - Math.trunc(value * 100);
  };

  const labelFormatter = (value: number) => {
    return `${percent(value)}% no ${chartType.toLowerCase()}`;
  };

  const content = !isPercentageType ? (
    <AvmBreakdownAvmTypeChart
      barData={createBarData(
        subjectValue,
        getAvmValue(avmFactor),
        isPercentageType,
        avmFactor.__typeName === 'AgeAvmFactor'
      )}
      title={title}
      avmFactor={avmFactor}
      valueLeftAppendStr={valueLeftAppendStr}
      valueRightAppendStr={valueRightAppendStr}
    />
  ) : (
    <AvmBreakdownPie
      chartType={chartType}
      id={
        avmFactor.__typeName === 'PoolAvmFactor'
          ? 'background-pool'
          : 'background-basement'
      }
      labelValue={
        avmFactor.__typeName === 'PoolAvmFactor'
          ? percent(avmFactor.poolPercent)
          : percent(avmFactor.basementPercent)
      }
      count={tractStats?.count || 0}
      label={
        avmFactor.__typeName === 'PoolAvmFactor'
          ? labelFormatter(avmFactor.poolPercent)
          : labelFormatter(avmFactor?.basementPercent)
      }
      avmFactor={avmFactor}
    />
  );
  return {
    tabId: tabId,
    tabIconLabel: title,
    tabIcon: (
      <Icon
        dataHcName={`${dataHcName}-${avmFactorDataHcName}-icon`}
        size="lg"
      />
    ),
    tabContent: avmFactor.comparisonDescription,
    tabLabelBottom: avmFactor.valueDescription,
    content: content,
  };
};
