import React, { forwardRef, ReactNode, Ref } from 'react';
import classNames from 'classnames';

import styles from './Page.module.css';

interface Props {
  children: ReactNode;
  className?: string;
  dataHcName: string;
  header?: ReactNode;
  footer?: ReactNode;
}

export const Page = forwardRef(
  (
    { children, dataHcName, className, header, footer }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        data-hc-name={dataHcName}
        role="document"
        className={classNames(styles.Page, className, {
          [styles.hasHeader]: !!header,
          [styles.hasFooter]: !!footer,
        })}
      >
        {header}
        <div
          data-hc-name={`${dataHcName}-content`}
          className={styles.Content}
          ref={ref}
        >
          {children}
        </div>
        {footer}
      </div>
    );
  }
);
