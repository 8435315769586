import { useQuery } from '@tanstack/react-query';

import { GetTagsArg } from '@hcs/types';

import { ReportApi } from '../api';

export const QUERY_KEY_TAGS = 'report-api-document-tags';

export const useTags = (tagsInputs: GetTagsArg) => {
  return useQuery(
    [QUERY_KEY_TAGS, tagsInputs.reportId, tagsInputs.documentId],
    async () => {
      return await ReportApi.getTags(tagsInputs);
    },
    { enabled: !tagsInputs.documentId?.startsWith('optimistic') }
  );
};
