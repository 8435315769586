import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import {
  OrganizationapiSubmitUsageQueryRequest,
  OrganizationapiSubmitUsageQueryResponse,
} from '@hcs/types';

import { BillingApi } from '../api';

import { QUERY_KEY_ORG_USAGE_QUERY_LIST } from './useOrgUsageQueryList';

export const useOrgUsageQueryCreate = (
  orgId: number,
  options?: UseMutationOptions<
    OrganizationapiSubmitUsageQueryResponse,
    AxiosError,
    OrganizationapiSubmitUsageQueryRequest,
    void
  >
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    OrganizationapiSubmitUsageQueryResponse,
    AxiosError,
    OrganizationapiSubmitUsageQueryRequest,
    void
  >(
    async (enteredQueryData: OrganizationapiSubmitUsageQueryRequest) => {
      return await BillingApi.createOrgUsageQuery(orgId, enteredQueryData);
    },
    {
      ...options,
      onMutate: (variables) => {
        toastOpen({
          type: 'loading',
          title: 'Creating New Query...',
          duration: null,
        });
        options?.onMutate?.(variables);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_KEY_ORG_USAGE_QUERY_LIST, orgId]);
        toastOpen({
          type: 'loading-success',
          title: 'Successfully Created New Query',
        });
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        toastOpen({
          type: 'loading-failure',
          title: `${error.message}: Error Creating New Query`,
          duration: 4000,
        });
        options?.onError?.(error, variables, context);
      },
    }
  );
};
