import React from 'react';
import classNames from 'classnames';

import { ReportId } from '@hcs/types';

import { useSubjectPhotos } from '../../hooks/useSubjectPhotos';
import {
  SUBJECT_PHOTO_CAROUSEL_FEATURES,
  SubjectPhotoCarousel,
} from '../SubjectPhotoCarousel';

import styles from './SubjectPhotoCard.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}
export const SUBJECT_PHOTO_CARD_FEATURES = SUBJECT_PHOTO_CAROUSEL_FEATURES;
const dataHcName = 'subject-photo-card';
export const SubjectPhotoCard = ({ className, reportId }: Props) => {
  const { isInitialLoading, data } = useSubjectPhotos(reportId);
  if (!isInitialLoading && !data?.totalPhotos) return null;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SubjectPhotoCard, className)}
    >
      <div className={styles.Photos}>
        <SubjectPhotoCarousel reportId={reportId} />
      </div>
    </div>
  );
};
