import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderOutlet } from '@hcs/design-system';
import { APP_CONFIG_AEXP, VIEW_PATHS_AEXP } from '@hcs/hc-products';
import { build404RedirectRoute } from '@hcs/utils';

import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { PropertyPreviewLink } from '../../pexp/navigation/PropertyPreviewLink';
import { ActivityPageNav } from '../navigation/ActivityPageNav/ActivityPageNav';
import { AexpNav } from '../navigation/AexpNav';
import {
  ActivityMonitorPage,
  ActivityPropertySearchPage,
  AexpMapPage,
  BuyBoxesPage,
} from '../pages';

const AEXP_ROUTE_OBJECT: RouteObject = {
  path: APP_CONFIG_AEXP.rootPath,
  element: (
    <PrivateApplicationPageRedirect>
      <LayoutGlobalHeaderOutlet header={<SolutionsPlatformGlobalHeader />} />
    </PrivateApplicationPageRedirect>
  ),
  children: [
    {
      path: '',
      element: (
        <LayoutContent noPadding>
          <Navigate
            to={`${APP_CONFIG_AEXP.rootPath}/${VIEW_PATHS_AEXP.BUY_BOXES}`}
            replace
          />
        </LayoutContent>
      ),
    },
    {
      path: VIEW_PATHS_AEXP.BUY_BOXES,
      element: (
        <>
          <AexpNav />
          <LayoutContent>
            <BuyBoxesPage />
          </LayoutContent>
        </>
      ),
    },
    {
      path: VIEW_PATHS_AEXP.MAP,
      element: (
        <>
          <AexpNav />
          <LayoutContent noPadding>
            <AexpMapPage />
          </LayoutContent>
        </>
      ),
    },
    {
      path: VIEW_PATHS_AEXP.ACTIVITY,
      element: (
        <>
          <AexpNav />
          <ActivityPageNav />
          <LayoutContent noPadding />
        </>
      ),
      children: [
        {
          path: '',
          element: <Navigate to={VIEW_PATHS_AEXP.ACTIVITY_MONITOR} replace />,
        },
        {
          path: `${VIEW_PATHS_AEXP.ACTIVITY_MONITOR}`,
          element: <ActivityMonitorPage />,
        },
        {
          path: `${VIEW_PATHS_AEXP.ACTIVITY_PROPERTY_SEARCH}`,
          element: (
            <ActivityPropertySearchPage
              PropertyPreviewLink={PropertyPreviewLink}
            />
          ),
        },
      ],
    },
    build404RedirectRoute(APP_CONFIG_AEXP.rootPath),
  ],
};

export const routes = (): RouteObject[] => {
  const routeObjects: RouteObject[] = [AEXP_ROUTE_OBJECT];
  return routeObjects;
};
