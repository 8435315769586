import React, { SVGAttributes } from 'react';

export const SubjectIcon = ({
  dataHcName,
  ...props
}: SVGAttributes<SVGElement> & { dataHcName: string }) => {
  return (
    <svg
      data-hc-name={dataHcName}
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={22}
      width={22}
      {...props}
    >
      <title>Subject Icon</title>
      <defs>
        <linearGradient
          x1="-166.87123%"
          y1="-86.1043294%"
          x2="80.1797084%"
          y2="74.6520996%"
          id="linearGradient-1"
        >
          <stop stopColor="#FFFFFF" stopOpacity={0} offset="0%" />
          <stop stopColor="#0098B9" offset="80.9121507%" />
          <stop stopColor="#006090" offset="100%" />
        </linearGradient>
      </defs>
      <g id="v4" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="700_Maps" transform="translate(-263.000000, -199.000000)">
          <g id="Group-6" transform="translate(264.000000, 200.000000)">
            <g id="Group-7">
              <g id="pin_subject_lrg">
                <g id="Group-11" fill="#0a62ff" stroke="#FFFFFF">
                  <circle
                    id="Oval-Copy"
                    cx="7.32516877"
                    cy="7.32516877"
                    r="7.82516877"
                  />
                </g>
                <path
                  d="M9.63234574,6.20371071 L9.63234574,5.18233847 L8.82640952,5.18233847 L8.8304392,5.66769456 L7.01305303,4.45879838 L3.18485599,7.00610074 C3.31179094,7.14786721 3.44638229,7.28309689 3.5878241,7.41056415 L4.39376032,6.95217229 L4.39376032,10.0849252 L6.40860086,10.0849252 L6.40860086,8.45072963 L7.61750519,8.45072963 L7.61750519,10.0849252 L9.63234574,10.0849252 L9.63234574,6.95217229 L10.438282,7.41056415 C10.5797238,7.28309689 10.7143151,7.14786721 10.8412501,7.00610074 L9.63234574,6.20371071 L9.63234574,6.20371071 Z"
                  id="Imported-Layers"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
