import React from 'react';

import { Dialog, DialogProps } from '@hcs/design-system';

import { useDeleteOrgSamlProvider } from '../../hooks/useDeleteOrgSamlProvider';

import styles from './DeleteOrgSamlProviderDialog.module.css';

interface DeleteOrgSamlProviderDialogProps {
  orgId: number;
  providerId: string;
  active: DialogProps['active'];
  onDeleteSuccess: VoidFunction;
  onClose: DialogProps['onClose'];
}

const dataHcName = 'delete-org-saml-provider-dialog';
export const DeleteOrgSamlProviderDialog = ({
  orgId,
  providerId,
  active,
  onDeleteSuccess,
  onClose,
}: DeleteOrgSamlProviderDialogProps) => {
  const {
    mutate: deleteMutationMutate,
    isLoading: deleteMutationIsLoading,
    isError: deleteMutationIsError,
    error: deleteMutationError,
  } = useDeleteOrgSamlProvider();
  return (
    <Dialog
      dataHcName={dataHcName}
      onClose={onClose}
      active={active}
      title="Delete SAML Provider"
      width={500}
      theme={{
        Header: styles.DialogHeader,
      }}
      actions={[
        {
          label: 'Cancel',
          onClick: onClose,
          dataHcName: `${dataHcName}-cancel-button`,
          primary: false,
        },
        {
          label: 'Delete',
          onClick: () => {
            deleteMutationMutate({
              orgId,
              providerId,
              onDeleteSuccess: () => {
                onDeleteSuccess();
                onClose();
              },
            });
          },
          dataHcName: `${dataHcName}-delete-button`,
          loading: deleteMutationIsLoading,
          highlightRed: true,
        },
      ]}
      notifications={[
        {
          type: 'error',
          show: deleteMutationIsError,
          title:
            deleteMutationError?.message ||
            'An error occurred deleting the SAML provider',
          dataHcName: `${dataHcName}-error-notification`,
        },
      ]}
    >
      <div>Are you sure you want to delete this SAML provider?</div>
    </Dialog>
  );
};
