import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmOrder } from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { DATA_HC_NAME_ORDERS_TABLE_CELL } from '../../../constants';

import { OrdersTableCellsDisplayProps } from '.';

import styles from './Label.module.css';

const Display = ({
  order,
}: OrdersTableCellsDisplayProps): JSX.Element | null => {
  return order?.label ? (
    <div
      className={styles.LabelWrapper}
      data-hc-name={`${DATA_HC_NAME_ORDERS_TABLE_CELL}-label`}
    >
      {formatMissing(order?.label)}
    </div>
  ) : null;
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Label</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export type OrdersTableCellProps = TableCellProps & OmOrder;

const ContentCell = ({ order, ...tableCellProps }: { order: OmOrder }) => {
  return (
    <TableCell {...tableCellProps}>
      <Display order={order} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_LABEL_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Label',
  sortable: true,
};
