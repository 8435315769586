import type { OmOrder, OrderItemStatus } from '@hcs/types';

export const orderPercentComplete = (order: OmOrder): number => {
  const {
    completeOrderItemsCount,
    orderItemsToProcessCount,
    orderItemsToReviewCount,
  } = order;
  const orderItemsCount = order.partialEnabled
    ? orderItemsToReviewCount + orderItemsToProcessCount
    : orderItemsToProcessCount;
  return orderItemsCount > 0
    ? Math.round((completeOrderItemsCount / orderItemsCount) * 100)
    : orderItemsCount;
};

const ORDER_ITEM_STATUSES_TO_LABEL: Record<OrderItemStatus, string> = {
  '': 'Waiting',
  InspectionPending: 'In Inspection',
  InspectionCancelled: 'Inspection Cancelled',
  InspectionComplete: 'In Appraisal',
  AppraisalPending: 'In Appraisal',
  AppraisalRevisionPending: 'In Appraisal-Revision',
  RevisionRequested: 'Revision Requested',
  EvaluationComplete: 'Generating Report',
  ReportPending: 'Generating Report',
  Complete: 'Complete',
  CancelPending: 'Cancel Pending',
  Cancelled: 'Cancelled',
  QCReview: 'QC Review',
  InspectionReview: 'Inspection Review',
  InspectionCorrectionReview: 'Inspection Correction Review',
  ValuationReview: 'Valuation Review',
  InspectionRevision: 'In Inspection Revision',
};

export const getOrderItemStatusLabel = (
  orderItemStatus: OrderItemStatus | null
): string | undefined => {
  let statusLabel = null;
  if (orderItemStatus === null) {
    statusLabel = 'Waiting';
  } else if (
    Object.prototype.hasOwnProperty.call(
      ORDER_ITEM_STATUSES_TO_LABEL,
      orderItemStatus
    )
  ) {
    statusLabel = ORDER_ITEM_STATUSES_TO_LABEL[orderItemStatus];
  } else {
    const statusWords = orderItemStatus.match(/[A-Z][a-z]+/g);
    statusLabel = statusWords != null ? statusWords.join(' ') : '';
  }
  return statusLabel;
};
