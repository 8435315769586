import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  PropertySpatialSearchCountDocument,
  PropertySpatialSearchCountQuery,
  PropertySpatialSearchCountQueryVariables,
} from '@hcs/types';
import { logException } from '@hcs/utils';

export const QUERY_KEY_PROPERTY_SPATIAL_SEARCH_COUNT =
  'propertySpatialSearchCount';
export const usePropertySpatialSearchCount = (
  params: PropertySpatialSearchCountQueryVariables | null
) => {
  return useQuery(
    [QUERY_KEY_PROPERTY_SPATIAL_SEARCH_COUNT, params],
    async () => {
      if (params !== null) {
        const response = await CerberusApi.fetchQuery<
          PropertySpatialSearchCountQuery,
          PropertySpatialSearchCountQueryVariables
        >(PropertySpatialSearchCountDocument, params);
        return response.propertySpatialESSearch;
      } else {
        const err = new Error(
          'usePropertySpatialSearchCount called with null params (check enabled logic)'
        );
        logException(err);
        throw err;
      }
    },
    {
      enabled: params !== null,
    }
  );
};
