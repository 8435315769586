import React from 'react';

import { CERBERUS_STATS_FIELD_CONFIGS } from '@hcs/cerberus-stats';
import { ChartTimeSeriesLine } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { Stats } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { Glossary } from '@hcs/glossary';
import { LineChart } from '@hcs/pdf/charts';
import { CerberusStatsFields } from '@hcs/types';
import { GlossaryTerms } from '@hcs/types';
import { ChartDimensions, ChartSizes } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { useDaysOnMarketChartDocument } from '../../hooks/useDaysOnMarketChartDocument';

const dataHcName = 'days-on-market-chart';
interface Props {
  reportId: ReportId;
  chartSize?: ChartSizes;
  chartDimensions?: ChartDimensions;
  className?: string;
}
const {
  label,
  d3FormatStr,
  // Not using Display yet since @john is actively refactoring
} = CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.daysOnMarketMedian];
export const DAYS_ON_MARKET_CHART_FEATURES = [ReportFeatures.DaysOnMarketChart];
export const DaysOnMarketChart = ({
  reportId,
  chartSize,
  chartDimensions,
  className,
}: Props) => {
  const { isInitialLoading, data: daysOnMarketDoc } =
    useDaysOnMarketChartDocument(reportId);
  if (!isInitialLoading && !daysOnMarketDoc?.data?.chart.data?.length) {
    return (
      <NullState
        title="Days on Market Unavailable"
        dataHcName={`${dataHcName}-null-state`}
        absCenter
      />
    );
  }
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <ChartTimeSeriesLine
          dataHcName={dataHcName}
          isLoading={isInitialLoading}
          chartData={[
            {
              id: 'days-on-market',
              data: daysOnMarketDoc?.data?.chart.data || [],
            },
          ]}
          title={label}
          d3FormatterForY={d3FormatStr}
          theme={{
            Container: className,
          }}
          compactTitle
          stats={[
            {
              label: 'This month last year',
              value: formatNumber(
                daysOnMarketDoc?.data?.chart.stats.median.lastYear
              ),
              dataHcName: 'year-prev',
            },
            {
              label: 'This month',
              value: formatNumber(
                daysOnMarketDoc?.data?.chart.stats.median.current
              ),
              dataHcName: 'year-current',
            },
          ]}
        />
      }
      now={
        <LineChart
          title={
            <>
              Days on Market - Sold or De-listed Properties{' '}
              <Glossary glossaryTerms={[GlossaryTerms.DaysOnMarket]} />
            </>
          }
          dataHcName={dataHcName}
          className={className}
          chartSize={chartSize}
          chartDimensions={chartDimensions}
          isLoading={isInitialLoading}
          chartData={daysOnMarketDoc?.data?.chart.data}
          yAxis={{ label: 'Days on Market' }}
          xAxis={{}}
          contentBelow={
            <Stats
              dataHcName={`${dataHcName}-stats`}
              stats={[
                {
                  label: 'This month last year',
                  value: formatNumber(
                    daysOnMarketDoc?.data?.chart.stats.median.lastYear
                  ),
                  dataHcName: 'year-prev',
                },
                {
                  label: 'This month',
                  value: formatNumber(
                    daysOnMarketDoc?.data?.chart.stats.median.current
                  ),
                  dataHcName: 'year-current',
                },
              ]}
            />
          }
        />
      }
    />
  );
};
