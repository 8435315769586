import { OrganizationIcon } from '@hcs/design-system';
import { AppSlugs, CapabilityApplications, Roles } from '@hcs/types';
import { AppConfig } from '@hcs/types';

export const APP_CONFIG_CLIENT_ADMIN: AppConfig = {
  name: 'Client Admin',
  appSlug: AppSlugs.ClientAdmin,
  applicationKey: CapabilityApplications.InternalClientAdmin,
  rootPath: '/client-admin',
  Icon: OrganizationIcon,
  descriptionLong:
    'Manage Org Users, Subscriptions, Feature Flag Access, and Preferences',
  descriptionShort: 'Organization & User Management',
  feOnlyApp: true,
  requiredRole: [Roles.ClientAdmin],
};

export enum VIEW_PATHS_CLIENT_ADMIN {
  // Orgs
  ORGANIZATIONS = 'organizations',
  ORG = 'organizations/:orgId',
  ORG_FEATURE_FLAGS = 'organizations/:orgId/feature-flags',
  ORG_USERS = 'organizations/:orgId/users',
  ORG_API_RATE_LIMITS = 'organizations/:orgId/api-rate-limits',
  ORG_API_COMPONENT_EXCLUSIONS = 'organizations/:orgId/api-component-exclusions',
  ORG_APPLICATIONS = 'organizations/:orgId/applications',
  ORG_APPLICATION_DETAILS = 'organizations/:orgId/applications/:appId',
  ORG_SUBSCRIPTIONS = 'organizations/:orgId/subscriptions',
  ORG_SSO = 'organizations/:orgId/sso',
  ORG_BILLING = 'organizations/:orgId/billing',
  ORG_USAGE = 'organizations/:orgId/usage',
  ORG_ENTITLEMENTS = 'organizations/:orgId/entitlements',
  ORG_ROLES = 'organizations/:orgId/roles',
  ORG_DEVFLAGS = 'organizations/:orgId/devflags',

  // Users
  USERS = 'users',
  USER = 'users/:userId',
  USER_DETAILS = 'users/:userId/details',
  USER_API_AUTH_KEYS = 'users/:userId/api-auth-keys',
  USER_PASSWORD = 'users/:userId/password',
  USER_ACCESS = 'users/:userId/access',
  USER_LOGIN_AS_USER = 'users/:userId/login-as-user',
}

export const DEFAULT_PATH_CLIENT_ADMIN_ORG = VIEW_PATHS_CLIENT_ADMIN.ORG_USERS;
