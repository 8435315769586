import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EngagementTrackingMeaningfulEventsSliceState } from '@hcs/types';

const initialState: EngagementTrackingMeaningfulEventsSliceState = {
  eventSection: null,
};
export const engagementTrackingMeaningfulEventsSlice = createSlice({
  name: 'meaningfulEvents',
  initialState,
  reducers: {
    setEventSection(
      state,
      {
        payload: { eventSection },
      }: PayloadAction<{ eventSection: string | null }>
    ) {
      state.eventSection = eventSection;
    },
  },
});
