import React from 'react';
import { Pie } from '@nivo/pie';
import { PieCustomLayerProps, PieLayer } from '@nivo/pie/dist/types/types';

import { parseStringOrNumber } from '@hcs/utils';
import { calculateCircumference } from '@hcs/utils';

import { BAR_ONE_COLOR, MAIN_BAR_COLOR } from '../../../features/AvmBreakdown';

import styles from './AvmBreakdownPieChart.module.css';

const DIAMETER_ADD = 219;
const RADIUS_REDUCTION = 171;
const THRESHOLD_FOR_LEGEND_DISPLAY = 25;
const dataHcName = 'avm-breakdwon-pie-chart';
export interface PieDatum {
  [key: string]: string | number;
}

interface SliceLayerProps extends PieCustomLayerProps<PieDatum> {
  chartType?: string;
}

export const CustomPieLayer: PieLayer<PieDatum> = ({
  radius,
  centerX,
  dataWithArc,
  centerY,
}: SliceLayerProps) => {
  const smallRadius = radius - RADIUS_REDUCTION;
  const newRadius = smallRadius + 20;
  const diameter = newRadius * 2;
  const labelValue = parseStringOrNumber(dataWithArc?.[0]?.data?.labelValue);
  const fillPercentage = 100 - (labelValue || 0);
  const circumference = calculateCircumference(newRadius) / 2;

  return (
    <svg
      height={500}
      width={500}
      viewBox={`0 0 ${diameter + DIAMETER_ADD} ${diameter + DIAMETER_ADD}`}
    >
      <circle
        data-hc-name={`${dataHcName}-circle`}
        r={smallRadius}
        cx={centerX}
        cy={centerY}
        fill={BAR_ONE_COLOR}
      />
      <circle
        data-hc-name={`${dataHcName}-circle`}
        r={newRadius}
        cx={centerX}
        cy={centerY - 10}
        fill="transparent"
      />
      <circle
        data-hc-name={`${dataHcName}-circle`}
        r={newRadius / 2}
        cx={centerX}
        cy={centerY}
        fill="transparent"
        stroke={MAIN_BAR_COLOR}
        strokeWidth={newRadius}
        strokeDasharray={`calc(${fillPercentage} * ${circumference} / 100) ${circumference}`}
      />
    </svg>
  );
};

export const TextLayer = ({
  radius,
  centerX,
  chartType,
  dataWithArc,
}: SliceLayerProps) => {
  const smallRadius = radius - RADIUS_REDUCTION;
  const diameter = smallRadius * 2;
  const labelValue = parseStringOrNumber(dataWithArc?.[0]?.data?.labelValue);
  const count = parseStringOrNumber(dataWithArc?.[0]?.data?.count);
  const propertiesWithFactor =
    count && labelValue ? Math.round((labelValue / 100) * count) : 0;
  const propertiesWithoutFactor =
    propertiesWithFactor && count ? count - propertiesWithFactor : 0;
  const bottomLabelValue = labelValue ? 100 - labelValue : 0;
  const xOffset = -10;

  const topLegend =
    labelValue && labelValue > THRESHOLD_FOR_LEGEND_DISPLAY ? (
      <g>
        <text
          fontSize={20}
          fontFamily="Avenir"
          fontWeight={900}
          className={styles.TopLabel}
          textAnchor="start"
          x={centerX + xOffset}
          y={205}
        >
          {labelValue}%
        </text>
        <text
          fontSize={10}
          fontFamily="Avenir"
          fontWeight={800}
          className={styles.TopLabel}
          textAnchor="start"
          x={centerX + xOffset}
          y={222}
        >
          {propertiesWithoutFactor} homes
        </text>
        <text
          fontSize={10}
          fontFamily="Avenir"
          fontWeight={800}
          className={styles.TopLabel}
          textAnchor="start"
          x={centerX + xOffset}
          y={237}
        >
          no {chartType?.toLowerCase()}
        </text>
      </g>
    ) : null;

  const bottomLegend =
    bottomLabelValue && bottomLabelValue > THRESHOLD_FOR_LEGEND_DISPLAY ? (
      <g>
        <text
          fontSize={20}
          fontFamily="Avenir"
          fontWeight={900}
          className={styles.BottomLabel}
          textAnchor="start"
          x={centerX + xOffset}
          y={300}
        >
          {bottomLabelValue}%
        </text>
        <text
          fontSize={10}
          fontFamily="Avenir"
          fontWeight={800}
          textAnchor="start"
          className={styles.BottomLabel}
          x={centerX + xOffset}
          y={317}
        >
          {propertiesWithFactor} homes
        </text>
        <text
          fontSize={10}
          fontFamily="Avenir"
          fontWeight={800}
          className={styles.BottomLabel}
          textAnchor="start"
          x={centerX + xOffset}
          y={332}
        >
          have a {chartType?.toLowerCase()}
        </text>
      </g>
    ) : null;

  return (
    <svg
      height={500}
      width={500}
      viewBox={`0 0 ${diameter + DIAMETER_ADD} ${diameter + DIAMETER_ADD}`}
    >
      {topLegend}
      {bottomLegend}
    </svg>
  );
};

interface Props {
  data: PieDatum[];
  chartType: 'Pool' | 'Basement';
  width: number;
  height: number;
}

export const AvmBreakdownPieChart = ({
  data,
  width,
  height,
  chartType,
}: Props) => {
  return (
    <Pie
      data-hc-name={dataHcName}
      height={height}
      width={width}
      data={data}
      layers={[
        'legends',
        CustomPieLayer,
        (props) => TextLayer({ chartType, ...props }),
      ]}
      colors={{ datum: 'data.color' }}
      margin={{ top: -100, right: -10, bottom: -50, left: -55 }}
      theme={{
        legends: {
          text: {
            fontSize: '12px',
            fontFamily: 'Avenir, sans-serif',
            fontWeight: 400,
          },
        },
      }}
      legends={[
        {
          anchor: 'top',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: 100,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#4a4a4a',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};
