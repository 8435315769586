import React from 'react';

import { InfoTooltip, InfoTooltipProps } from '@hcs/design-system';
import { ValuationMethod } from '@hcs/types';

import {
  VALUATION_METHOD_LABELS,
  VALUATION_METHOD_LABELS_RENTAL,
} from '../../constants';

import styles from './CompValueInfoIcon.module.css';

const dataHcName = 'comp-value-info-icon';

interface CompValueInfoIconProps extends Omit<InfoTooltipProps, 'dataHcName'> {
  isRental: boolean;
  darkInfoIcon?: boolean;
}

export const CompValueInfoIcon = ({
  isRental,
  ...props
}: CompValueInfoIconProps) => {
  return (
    <InfoTooltip
      dataHcName={dataHcName}
      isLargeTooltip
      color={'neutral-light-10'}
      label={
        isRental ? (
          <div className={styles.Info}>
            <label data-hc-name={`${dataHcName}-info-label`}>
              {VALUATION_METHOD_LABELS_RENTAL[ValuationMethod.Comps]}
            </label>
            <div
              data-hc-name={`${dataHcName}-info-content-rental`}
              className={styles.InfoContent}
            >
              We calculate the rental comparable value by averaging the rental
              value of selected rental comparables’. Our HouseCanary rental
              values account for recent and past market shifts including time,
              locational differences, and many other factors.
            </div>
          </div>
        ) : (
          <div className={styles.Info}>
            <label data-hc-name={`info-label-rental`}>
              {VALUATION_METHOD_LABELS[ValuationMethod.Comps]}
            </label>
            <div
              data-hc-name={`info-content-rental`}
              className={styles.InfoContent}
            >
              The “Comparable Value” is an average of the adjusted HouseCanary
              Value of your selected comparables. We automatically adjust each
              comparable property’s current HouseCanary Value by including a
              number of factors, such as locational differences, square feet,
              bedrooms, bathrooms, lot-size, pools, basements, and many other
              possible fields.
            </div>
          </div>
        )
      }
      {...props}
    />
  );
};
