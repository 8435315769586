import { gql } from 'graphql-request';

// Bathrooms Fragments
export const FRAGMENT_BATHROOMS_PREVIEW = gql`
  fragment BathroomsPreview on CommonPropertyDetailsHcBathrooms {
    totalProjected
  }
`;
export const FRAGMENT_BATHROOMS_CORE = FRAGMENT_BATHROOMS_PREVIEW;

// Basement Fragments
export const FRAGMENT_BASEMENT_CORE = gql`
  fragment BasementCore on CommonPropertyDetailsHcBasement {
    finishedPct
    description
    has
    totalArea
  }
`;

// Parking Fragments
export const FRAGMENT_PARKING_CORE = gql`
  fragment ParkingCore on CommonPropertyDetailsHcParking {
    total
    garage
    description
    parkingDetails {
      count
      type
    }
  }
`;

// Tax Fragments
export const FRAGMENT_TAX_CORE = gql`
  fragment TaxCore on CommonPropertyDetailsHcTax {
    amountAnnual
    year
    legalDescription
  }
`;

// Zoning Fragments
export const FRAGMENT_ZONING_CORE = gql`
  fragment ZoningCore on CommonPropertyDetailsHcZoning {
    code
  }
`;

/** PROPERTY STATE | propertyDetails  */

// Property Details that are shown for previews and cards
export const FRAGMENT_PROPERTY_DETAILS_PREVIEW = gql`
  ${FRAGMENT_BATHROOMS_PREVIEW}
  fragment PropertyDetailsPreview on CommonPropertyDetailsHc {
    bathrooms {
      ...BathroomsPreview
    }
    bedrooms
    livingArea
    lotSize
    propertyType
    propertyTypeDescription
    yearBuilt
  }
`;

// Property Details that are shown for full reports
export const FRAGMENT_PROPERTY_DETAILS_CORE = gql`
  ${FRAGMENT_PROPERTY_DETAILS_PREVIEW}
  ${FRAGMENT_BASEMENT_CORE}
  ${FRAGMENT_PARKING_CORE}
  ${FRAGMENT_TAX_CORE}
  ${FRAGMENT_ZONING_CORE}
  fragment PropertyDetailsCore on CommonPropertyDetailsHc {
    ...PropertyDetailsPreview
    apn
    association {
      fee
      frequency
      name
      phone
    }
    associationAmenities
    associationFeeIncludes
    basement {
      ...BasementCore
    }
    constructionTypes
    cooling
    hasAssociation
    heating
    parking {
      ...ParkingCore
    }
    poolYN
    roomsTotal
    storiesNumber
    tax {
      ...TaxCore
    }
    zoning {
      ...ZoningCore
    }
  }
`;
