import React from 'react';

import {
  Dialog,
  DIALOG_ACTIONS_PORTAL_ID,
  DialogProps,
} from '@hcs/design-system';

import { PersonalizationForm } from './PersonalizationForm';

const dataHcName = 'personalization-form-dialog';
export const PersonalizationFormDialog = (
  props: Omit<
    DialogProps,
    'title' | 'subtitle' | 'actions' | 'dataHcName' | 'children'
  >
) => {
  return (
    <Dialog
      {...props}
      type="small"
      dataHcName={dataHcName}
      title="Personalize Reports"
      subtitle="Brand shared reports with your personal info."
    >
      <PersonalizationForm
        actionsPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
        onSuccess={props.onClose}
      />
    </Dialog>
  );
};
