import React, { useMemo } from 'react';

import {
  DataPriorityControl,
  DataPriorityControlLabelsType,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { usePatchUiPreferencesForUser } from '@hcs/huell';
import { CerberusStatsFields } from '@hcs/types';
import { DataPriorityCerberusStats, PreferencesKeys } from '@hcs/types';

import { MARKET_STATE_ZIP_ALL_COLUMNS_ORDER } from '../../constants';
import { useCerberusStatsFields } from '../../hooks/useCerberusStatsFields';
import {
  getCerberusStatsFieldDefinition,
  getCerberusStatsFieldLabelShort,
} from '../../utils/cerberusStatsFields.utils';

const dataHcName = 'cerberus-stats-columns-data-preferences';
export interface MarketStateColumnsZipForUserProps {
  actionsPortalIdRender?: string;
  onUpdate?: (chartDataPriority: DataPriorityCerberusStats) => void;
  onCancel?: VoidFunction;
}
export const MarketStateColumnsZipForUser = ({
  ...props
}: MarketStateColumnsZipForUserProps) => {
  const preferenceKey = PreferencesKeys.MarketStateColumnsZip;
  const { data: customFieldsData, isInitialLoading: isPreferencesLoading } =
    useCerberusStatsFields('columnsZip');
  const { mutate: patchPreferences } = usePatchUiPreferencesForUser();
  const handleSave = (updated: DataPriorityCerberusStats) => {
    // This will actually always be defined here because of the `disabled` prop on the submit button
    patchPreferences?.([
      {
        op: 'add',
        path: `/${preferenceKey}`,
        value: updated,
      },
    ]);
    props.onUpdate?.(updated);
  };

  const labels = useMemo(() => {
    return MARKET_STATE_ZIP_ALL_COLUMNS_ORDER.reduce<
      DataPriorityControlLabelsType<CerberusStatsFields>
    >((accum, f) => {
      accum[f] = {
        label: getCerberusStatsFieldLabelShort(f) || f,
        description: getCerberusStatsFieldDefinition(f),
      };
      return accum;
    }, {});
  }, []);

  if (isPreferencesLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const handleReset = () => {
    patchPreferences?.([
      {
        op: 'remove',
        path: `/${preferenceKey}`,
      },
    ]);
  };

  return (
    <DataPriorityControl
      {...props}
      dataHcName={dataHcName}
      onSubmit={handleSave}
      defaultOrder={MARKET_STATE_ZIP_ALL_COLUMNS_ORDER}
      labels={labels}
      dataPreferences={
        customFieldsData
          ? {
              order: customFieldsData.order,
              inactive: customFieldsData.inactive,
            }
          : undefined
      }
      numCols={2}
      hasReset
      hasSearch
      resetTableOptionsPrefs={handleReset}
    />
  );
};
