import React from 'react';
import classNames from 'classnames';

import { HomeIcon } from '../../../../svgs/icons/generics';

import { MapMarker, MapMarkerProps } from './MapMarker';

import styles from './MapMarkersDeprecated.module.css';

export interface SubjectMarkerProps {
  dataHcName: string;
  className?: string;
  onClick?: VoidFunction;
  shape?: MapMarkerProps['shape'];
  theme?: MapMarkerProps['theme'];
}
export const SubjectMarker = ({
  dataHcName,
  className,
  shape,
  theme,
  onClick,
}: SubjectMarkerProps) => {
  return (
    <MapMarker
      dataHcName={dataHcName}
      onClick={onClick}
      shape={shape}
      theme={theme}
      className={classNames(styles.subject, className)}
    >
      <HomeIcon dataHcName={`${dataHcName}-icon`} className={styles.HomeIcon} />
    </MapMarker>
  );
};
