import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import { isUserAssignedRole, REQUIRED_MESSAGE, useAccount } from '@hcs/auth';
import {
  ActionButtons,
  Anchor,
  Avatar,
  FileInputButton,
  Input,
  LoadingSpinner,
  StateDropdown,
} from '@hcs/design-system';
import {
  BrokerLicense,
  BrokerLicenseField,
  Personalization,
  Roles,
} from '@hcs/types';

import { usePersonalization } from '../../hooks/usePersonalization';
import { useUpdatePersonalizationData } from '../../hooks/useUpdatePersonalizationData';
import { useUploadBrokerSignatureImage } from '../../hooks/useUploadBrokerSignatureImage';
import { useUploadPersonalizationPhotoCompany } from '../../hooks/useUploadPersonalizationPhotoCompany';
import { useUploadPersonalizationPhotoUser } from '../../hooks/useUploadPersonalizationPhotoUser';

import { BrokerLicensesField, licenseTemplate } from './BrokerLicensesField';

import styles from './PersonalizationForm.module.css';

interface Props {
  className?: string;
  actionsPortalIdRender?: string;
  onSuccess?: VoidFunction;
}

const PHONE_NO_REGEX =
  // eslint-disable-next-line security/detect-unsafe-regex
  /(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;

const hasDuplicatedLicenseState = (array: BrokerLicense[]) => {
  const licenseStates = new Set();
  for (const item of array) {
    const licenseState = item[BrokerLicenseField.LicenseState];
    if (licenseStates.has(licenseState)) {
      return true;
    }
    licenseStates.add(licenseState);
  }
  return false;
};

const INVALID_DATE_ERROR_MESSAGE =
  'License expiration must be in YYYY-MM-DD format and must be a future date.';

const getBrokerLicensesYup = () => {
  return yup
    .array()
    .of(
      yup.object().shape({
        [BrokerLicenseField.LicenseState]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value),
        [BrokerLicenseField.Number]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value),
        [BrokerLicenseField.Expiration]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value)
          .matches(
            /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
            INVALID_DATE_ERROR_MESSAGE
          )
          .test('IsValidDateFormat', INVALID_DATE_ERROR_MESSAGE, (value) => {
            if (!value) {
              return false;
            }
            const date = new Date(value);
            return date instanceof Date && !isNaN(date.getTime());
          })
          .test(
            'IsNotPastDate',
            'License expiration must be a future date.',
            (value) => {
              if (!value) {
                return false;
              }
              const today = new Date();
              const date = new Date(value);
              return date >= today;
            }
          ),
        [BrokerLicenseField.Name]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value),
        [BrokerLicenseField.AddressLine1]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value),
        [BrokerLicenseField.AddressLine2]: yup.string().nullable(),
        [BrokerLicenseField.City]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value),
        [BrokerLicenseField.State]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value),
        [BrokerLicenseField.Zipcode]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value)
          .matches(
            /^\d{5}(-\d{4})?$/,
            'Zipcode must be 5 digits or 5 digits followed by a hyphen and 4 digits.'
          ),
        [BrokerLicenseField.Email]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .email('Invalid email format')
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value),
        [BrokerLicenseField.Phone]: yup
          .string()
          // allow null value when adding a new license
          .nullable()
          .test('IsInputNotEmpty', REQUIRED_MESSAGE, (value) => !!value)
          .matches(
            PHONE_NO_REGEX,
            'Phone number must be 10 digits without any special characters.'
          ),
      })
    )
    .test(
      'UniqueBrokerLicensesValues',
      'Duplicate licenses are not allowed',
      (values) => {
        if (values && values.length > 1) {
          return !hasDuplicatedLicenseState(values);
        }
        return true;
      }
    );
};

const yupSchema = yup.object().shape({
  email: yup.string().email(),
  firstName: yup.string().required(REQUIRED_MESSAGE),
  lastName: yup.string().required(REQUIRED_MESSAGE),
  brokerLicenses: getBrokerLicensesYup(),
});

const FIVE_MB_FILE_LIMIT = 5000000;

const dataHcName = 'personalization-form';
const PersonalizationFormInner = ({
  className,
  actionsPortalIdRender,
  onSuccess,
}: Props) => {
  // Loading status checked in the outer component below so form is initialized w/ the correct default values
  const { data: personalizationData } = usePersonalization();
  const { data: account } = useAccount();
  const accountBasedDefaults: Personalization = useMemo(() => {
    return {
      ackPrompt: false,
      addressLine1: null,
      addressLine2: null,
      city: null,
      companyLogoUrl: null,
      companyName: account?.currentOrganization.name || null,
      createdAt: null,
      email: account?.user.email || null,
      firstName: account?.user.firstName || null,
      id: 0,
      includeAddressLine1: false,
      includeAddressLine2: false,
      includeAskQuestion: false,
      includeCityStateZipcode: false,
      includeCompanyLogo: false,
      includeCompanyName: false,
      includeEmail: false,
      includeLicenseExpiration: false,
      includeLicenseNumber: false,
      includeLicenseState: false,
      includeName: false,
      includePhone: false,
      includePhoto: false,
      includeSignature: false,
      includeWebsite: false,
      lastName: account?.user.lastName || null,
      brokerLicenses: null,
      licenseExpiration: null,
      licenseNumber: null,
      licenseState: null,
      organizationId: account?.currentOrganization.id || 0,
      phone: null,
      photoUrl: null,
      signatureUrl: null,
      state: null,
      updatedAt: null,
      userEmail: null,
      userId: account?.user.id || 0,
      websiteUrl: null,
      zipcode: null,
    };
  }, [account]);
  const [imgError, setImgError] = useState<string | undefined>();
  const form = useForm<Personalization>({
    defaultValues: personalizationData || accountBasedDefaults,
    resolver: yupResolver(yupSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const uploadBrokerSignatureImage = useUploadBrokerSignatureImage({
    onSuccess: ({ photoUrl }) => {
      form.setValue('signatureUrl', photoUrl);
      form.setValue('includeSignature', !!photoUrl);
      form.clearErrors('signatureUrl');
      form.clearErrors('includeSignature');
    },
  });
  const uploadPhotoMutationUser = useUploadPersonalizationPhotoUser({
    onSuccess: ({ photoUrl }) => {
      form.setValue('photoUrl', photoUrl);
      form.setValue('includePhoto', !!photoUrl);
      form.clearErrors('photoUrl');
      form.clearErrors('includePhoto');
    },
  });
  const uploadPhotoMutationCompany = useUploadPersonalizationPhotoCompany({
    onSuccess: ({ photoUrl }) => {
      form.setValue('companyLogoUrl', photoUrl);
      form.setValue('includeCompanyLogo', !!photoUrl);
      form.clearErrors('companyLogoUrl');
      form.clearErrors('includeCompanyLogo');
    },
  });
  const updatePersonalizationMutation = useUpdatePersonalizationData({
    onSuccess: () => {
      form.reset();
      onSuccess?.();
    },
  });
  const isBrokerRole = isUserAssignedRole([Roles.Broker], account);

  useEffect(() => {
    // Add a new license field if an user is a broker and there are no licenses
    if (
      isBrokerRole &&
      (!personalizationData || !personalizationData.brokerLicenses)
    ) {
      form.setValue('brokerLicenses', [licenseTemplate]);
    }
  }, [personalizationData, isBrokerRole, form]);

  const handleSubmit = () => {
    const formValues = form.getValues();
    updatePersonalizationMutation.mutate(formValues);
  };
  const getIsBrokerLicenseNull = () => {
    const brokerLicenses = form.getValues().brokerLicenses;
    return (
      brokerLicenses &&
      brokerLicenses?.find(
        (value) => !value.number || !value.state || !value.expiration
      )
    );
  };
  const isBrokerLicensesValid = !getIsBrokerLicenseNull();
  const isFormValid =
    isEmpty(form.formState.errors) && !form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <div
        data-hc-name={dataHcName}
        className={classNames(styles.PersonalizationForm, className)}
      >
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Controller
            name="firstName"
            control={form.control}
            render={({ field }) => (
              <>
                <label
                  className={styles.Label}
                  data-hc-name={`${dataHcName}-${field.name}`}
                >
                  First name
                </label>
                <Input
                  {...field}
                  value={field.value || ''}
                  maxLength={35}
                  dataHcName={`${dataHcName}-${field.name}`}
                  onChange={(value: string) => {
                    form.setValue(field.name, value);
                    form.clearErrors(field.name);
                    form.setValue('includeName', !!value);
                    form.clearErrors('includeName');
                  }}
                  error={form.formState.errors[field.name]?.message}
                />
              </>
            )}
          />
          <Controller
            name="lastName"
            control={form.control}
            render={({ field }) => (
              <>
                <label
                  className={styles.Label}
                  data-hc-name={`${dataHcName}-${field.name}`}
                >
                  Last name
                </label>
                <Input
                  {...field}
                  value={field.value || ''}
                  maxLength={35}
                  dataHcName={`${dataHcName}-${field.name}`}
                  onChange={(value: string) => {
                    form.setValue(field.name, value);
                    form.clearErrors(field.name);
                    form.setValue('includeName', !!value);
                    form.clearErrors('includeName');
                  }}
                  error={form.formState.errors[field.name]?.message}
                />
              </>
            )}
          />
          {!isBrokerRole && (
            <>
              <Controller
                name="companyName"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Company name
                    </label>
                    <Input
                      {...field}
                      value={field.value || ''}
                      maxLength={64}
                      dataHcName={`${dataHcName}-${field.name}`}
                      onChange={(value: string) => {
                        form.setValue(field.name, value);
                        form.clearErrors(field.name);
                        form.setValue('includeCompanyName', !!value);
                        form.clearErrors('includeCompanyName');
                      }}
                      error={form.formState.errors[field.name]?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="addressLine1"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Address Line 1
                    </label>
                    <Input
                      {...field}
                      value={field.value || ''}
                      maxLength={40}
                      dataHcName={`${dataHcName}-${field.name}`}
                      onChange={(value: string) => {
                        form.setValue(field.name, value);
                        form.clearErrors(field.name);
                        form.setValue('includeAddressLine1', !!value);
                        form.clearErrors('includeAddressLine1');
                      }}
                      error={form.formState.errors[field.name]?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="addressLine2"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Address Line 2
                    </label>
                    <Input
                      {...field}
                      value={field.value || ''}
                      maxLength={40}
                      dataHcName={`${dataHcName}-${field.name}`}
                      onChange={(value: string) => {
                        form.setValue(field.name, value);
                        form.clearErrors(field.name);
                        form.setValue('includeAddressLine2', !!value);
                        form.clearErrors('includeAddressLine2');
                      }}
                      error={form.formState.errors[field.name]?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="city"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      City
                    </label>
                    <Input
                      {...field}
                      value={field.value || ''}
                      maxLength={25}
                      dataHcName={`${dataHcName}-${field.name}`}
                      onChange={(value: string) => {
                        const formVals = form.getValues();
                        form.setValue(field.name, value);
                        form.clearErrors(field.name);
                        form.setValue(
                          'includeCityStateZipcode',
                          !!value || !!formVals.state || !!formVals.zipcode
                        );
                        form.clearErrors('includeCityStateZipcode');
                      }}
                      error={form.formState.errors[field.name]?.message}
                    />
                  </>
                )}
              />
              <div className={styles.DualRow}>
                <Controller
                  name="state"
                  control={form.control}
                  render={({ field }) => {
                    const { ref, ...rest } = field;

                    return (
                      <div>
                        <label
                          className={styles.Label}
                          data-hc-name={`${dataHcName}-${field.name}`}
                        >
                          State
                        </label>
                        <StateDropdown
                          // field includes a ref property so it caused
                          // "Warning: Function components cannot be given refs" on the dropdown field.
                          // Since Controller already takes care of the registration process,
                          // we can just remove ref from field to prevent this warning.
                          {...rest}
                          className={styles.Input}
                          value={field.value || ''}
                          dataHcName={`${dataHcName}-${field.name}`}
                          onSelect={(value: string) => {
                            const formVals = form.getValues();
                            form.setValue(field.name, value);
                            form.clearErrors(field.name);
                            form.setValue(
                              'includeCityStateZipcode',
                              !!value || !!formVals.city || !!formVals.zipcode
                            );
                            form.clearErrors('includeCityStateZipcode');
                          }}
                          error={form.formState.errors[field.name]?.message}
                        />
                      </div>
                    );
                  }}
                />
                <Controller
                  name="zipcode"
                  control={form.control}
                  render={({ field }) => (
                    <div>
                      <label
                        className={styles.Label}
                        data-hc-name={`${dataHcName}-${field.name}`}
                      >
                        Zipcode
                      </label>
                      <Input
                        {...field}
                        value={field.value || ''}
                        maxLength={5}
                        dataHcName={`${dataHcName}-${field.name}`}
                        onChange={(value: string) => {
                          const formVals = form.getValues();
                          form.setValue(field.name, value);
                          form.clearErrors(field.name);
                          form.setValue(
                            'includeCityStateZipcode',
                            !!value || !!formVals.state || !!formVals.city
                          );
                          form.clearErrors('includeCityStateZipcode');
                        }}
                        error={form.formState.errors[field.name]?.message}
                      />
                    </div>
                  )}
                />
              </div>
              <Controller
                name="email"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Email
                    </label>
                    <Input
                      {...field}
                      value={field.value || ''}
                      maxLength={40}
                      dataHcName={`${dataHcName}-${field.name}`}
                      onBlur={field.onBlur}
                      onChange={(value: string) => {
                        form.setValue(field.name, value);
                        form.clearErrors(field.name);
                        form.setValue('includeEmail', !!value);
                        form.clearErrors('includeEmail');
                      }}
                      error={form.formState.errors[field.name]?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="phone"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Phone
                    </label>
                    <Input
                      {...field}
                      value={field.value || ''}
                      maxLength={20}
                      dataHcName={`${dataHcName}-${field.name}`}
                      onChange={(value: string) => {
                        form.setValue(field.name, value);
                        form.clearErrors(field.name);
                        form.setValue('includePhone', !!value);
                        form.clearErrors('includePhone');
                      }}
                      error={form.formState.errors[field.name]?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="websiteUrl"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Website Url
                    </label>
                    <Input
                      {...field}
                      value={field.value || ''}
                      maxLength={40}
                      dataHcName={`${dataHcName}-${field.name}`}
                      onChange={(value: string) => {
                        form.setValue(field.name, value);
                        form.clearErrors(field.name);
                        form.setValue('includeWebsite', !!value);
                        form.clearErrors('includeWebsite');
                      }}
                      error={form.formState.errors[field.name]?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="photoUrl"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      User Photo
                    </label>
                    <div className={styles.ImageRow}>
                      <div>
                        <Avatar
                          dataHcName={`${dataHcName}-${field.name}-avatar`}
                          text=""
                          size="lg"
                          url={field.value || undefined}
                        />
                      </div>
                      <div>
                        <FileInputButton
                          buttonProps={
                            field.value
                              ? {
                                  dataHcName: `${dataHcName}-${field.name}-replace`,
                                  label: 'Replace',
                                }
                              : {
                                  dataHcName: `${dataHcName}-${field.name}-upload`,
                                  label: 'Upload',
                                }
                          }
                          fileSizeLimit={FIVE_MB_FILE_LIMIT}
                          inputProps={{
                            accept: 'image/jpeg, image/png',
                            name: 'userPhoto',
                            onChange: (file) => {
                              if (file) {
                                if (imgError) setImgError(undefined);
                                uploadPhotoMutationUser.mutate(file);
                              }
                            },
                          }}
                          onError={setImgError}
                        />
                        {field.value && (
                          <Anchor
                            className={styles.RemovePhoto}
                            onClick={() => {
                              form.setValue(field.name, null);
                            }}
                            dataHcName={`${dataHcName}-${field.name}-remove`}
                          >
                            Remove
                          </Anchor>
                        )}
                      </div>
                    </div>
                  </>
                )}
              />
              <Controller
                name="companyLogoUrl"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Company Logo
                    </label>
                    <div className={styles.ImageRow}>
                      <div>
                        {field.value ? (
                          <img
                            className={styles.CompanyLogo}
                            src={field.value || undefined}
                            data-hc-name={`${dataHcName}-${field.name}-preview`}
                          />
                        ) : (
                          <div
                            data-hc-name={`${dataHcName}-${field.name}-preview`}
                            className={classNames(
                              styles.CompanyLogo,
                              styles.placeholder
                            )}
                          />
                        )}
                      </div>
                      <div>
                        <FileInputButton
                          buttonProps={
                            field.value
                              ? {
                                  dataHcName: `${dataHcName}-${field.name}-replace`,
                                  label: 'Replace',
                                }
                              : {
                                  dataHcName: `${dataHcName}-${field.name}-upload`,
                                  label: 'Upload',
                                }
                          }
                          fileSizeLimit={FIVE_MB_FILE_LIMIT}
                          inputProps={{
                            accept: 'image/jpeg, image/png',
                            name: 'companyLogo',
                            onChange: (file) => {
                              if (file) {
                                if (imgError) setImgError(undefined);
                                uploadPhotoMutationCompany.mutate(file);
                              }
                            },
                          }}
                          onError={setImgError}
                        />
                        {field.value && (
                          <Anchor
                            className={styles.RemovePhoto}
                            onClick={() => {
                              form.setValue(field.name, null);
                            }}
                            dataHcName={`${dataHcName}-${field.name}-remove`}
                          >
                            Remove
                          </Anchor>
                        )}
                      </div>
                    </div>
                  </>
                )}
              />
            </>
          )}
          {isBrokerRole && (
            <>
              <BrokerLicensesField />
              <Controller
                name="signatureUrl"
                control={form.control}
                render={({ field }) => (
                  <>
                    <label
                      className={styles.Label}
                      data-hc-name={`${dataHcName}-${field.name}`}
                    >
                      Broker Signature Image
                    </label>
                    <div className={styles.ImageRow}>
                      <div>
                        {field.value ? (
                          <img
                            className={styles.CompanyLogo}
                            src={field.value || undefined}
                            data-hc-name={`${dataHcName}-${field.name}-preview`}
                          />
                        ) : (
                          <div
                            data-hc-name={`${dataHcName}-${field.name}-preview`}
                            className={classNames(
                              styles.CompanyLogo,
                              styles.placeholder
                            )}
                          />
                        )}
                      </div>
                      <div>
                        <FileInputButton
                          buttonProps={
                            field.value
                              ? {
                                  dataHcName: `${dataHcName}-${field.name}-replace`,
                                  label: 'Replace',
                                }
                              : {
                                  dataHcName: `${dataHcName}-${field.name}-upload`,
                                  label: 'Upload',
                                }
                          }
                          fileSizeLimit={FIVE_MB_FILE_LIMIT}
                          inputProps={{
                            accept: 'image/jpeg, image/png',
                            name: 'signatureUrl',
                            onChange: (file) => {
                              if (file) {
                                if (imgError) setImgError(undefined);
                                uploadBrokerSignatureImage.mutate(file);
                              }
                            },
                          }}
                          onError={setImgError}
                        />
                        {field.value && (
                          <Anchor
                            className={styles.RemovePhoto}
                            onClick={() => {
                              form.setValue(field.name, null);
                            }}
                            dataHcName={`${dataHcName}-${field.name}-remove`}
                          >
                            Remove
                          </Anchor>
                        )}
                      </div>
                    </div>
                  </>
                )}
              />
            </>
          )}
          {imgError && (
            <div
              className={styles.ImageError}
              data-hc-name={`${dataHcName}-image-error`}
            >
              {imgError}
            </div>
          )}
          <div
            className={styles.ImageReqs}
            data-hc-name={`${dataHcName}-image-message`}
          >
            (Maximum file size 5.0 MB, At least 240px in height, PNG, JPEG)
          </div>
          <ActionButtons
            dataHcName={`${dataHcName}-actions`}
            portalIdRender={actionsPortalIdRender}
            actions={[
              {
                dataHcName: `${dataHcName}-save`,
                label: 'Save',
                disabled: isBrokerRole
                  ? !isBrokerLicensesValid || !isFormValid
                  : !isFormValid,
                type: 'submit',
                onClick: handleSubmit,
              },
            ]}
          />
        </form>
      </div>
    </FormProvider>
  );
};
export const PersonalizationForm = (props: Props) => {
  const { isInitialLoading } = usePersonalization();
  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  return <PersonalizationFormInner {...props} />;
};
