import React, { useState } from 'react';

import {
  CopyInput,
  Dialog,
  InputNumber,
  ProfileIcon,
  TextButton,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { useReportPreferencesForUser } from '@hcs/huell';
import {
  EditableLinkCreateResponse,
  MeaningfulEventTypes,
  ReportFeatures,
  ReportId,
} from '@hcs/types';
import { humanReadableDateTime } from '@hcs/utils';

import { useReportPermissions } from '../../hooks';
import { useReportEditableLinkCreate } from '../../hooks/useReportEditableLinkCreate';
import { generatePublicLinkUrl } from '../../utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './ReportPublicLinkEditable.module.css';

interface Props {
  className?: string;
  reportId: ReportId;
}

const dataHcName = 'report-public-link-editable';
export const REPORT_PUBLIC_LINK_EDITABLE_FEATURES = [
  ReportFeatures.EditableLinks,
];
export const ReportPublicLinkEditable = ({ className, reportId }: Props) => {
  const {
    data: reportPermissionsData,
    isInitialLoading: isLoadingReportPermissionsData,
  } = useReportPermissions(reportId);
  const { active, handleOpen, handleClose: closeDialog } = useActiveState();
  const [newLink, setNewLink] = useState<EditableLinkCreateResponse | null>(
    null
  );
  const { data: reportPreferences, isInitialLoading: isLoadingPreferences } =
    useReportPreferencesForUser();
  const [ttl, setTtl] = useState<number>(1);
  const { mutate: createEditableLink, reset: resetMutation } =
    useReportEditableLinkCreate(reportId, {
      onSuccess: (data) => setNewLink(data),
    });
  const handleSubmit = () => {
    createEditableLink({ ttl: ttl * 24 * 60 * 60 });
  };
  if (isLoadingReportPermissionsData || isLoadingPreferences) {
    return <LoadingSpinner dataHcName={`${dataHcName}-loading-spinner`} />;
  }
  const handleClose = () => {
    closeDialog();
    resetMutation();
    setNewLink(null);
  };
  const isCreateDisabled =
    !reportPermissionsData.isEditable ||
    !reportPreferences?.publicLinksEditable;
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={REPORT_PUBLIC_LINK_EDITABLE_FEATURES}
    >
      <div className={className}>
        <div className={styles.TextIconContainer}>
          <ProfileIcon dataHcName={`${dataHcName}-user-icon`} />
          <div>
            <div
              data-hc-name={`${dataHcName}-account-name-label`}
              className={styles.Label}
            >
              Create Editable Public Link
            </div>
            {reportPreferences?.publicLinksEditable &&
            !reportPermissionsData.isEditable ? (
              <div>
                Report expired. Please generate a new report to share publicly.
              </div>
            ) : (
              <div>
                Anyone on the internet with this link can edit this report.
              </div>
            )}
          </div>
          <div className={styles.SwitchContainer}>
            <TextButton
              dataHcName={`${dataHcName}-switch`}
              disabled={isCreateDisabled}
              label="Create"
              onClick={!isCreateDisabled ? handleOpen : undefined}
            />
          </div>
        </div>
      </div>
      <Dialog
        type="small"
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        onClose={handleClose}
        title="Create Editable Public Link"
        actions={
          !newLink
            ? [
                {
                  dataHcName: `${dataHcName}-cancel`,
                  label: 'Cancel',
                  secondary: true,
                  onClick: handleClose,
                },
                {
                  dataHcName: `${dataHcName}-submit`,
                  label: 'Submit',
                  primary: true,
                  onClick: handleSubmit,
                  dataHcEventName: 'Create Editable Link',
                  dataHcEventType: MeaningfulEventTypes.Goal,
                },
              ]
            : undefined
        }
      >
        {newLink ? (
          <div className={styles.NewLinkContent}>
            <div>
              Copy the link below before closing this dialog. Once closed, the
              link will no longer be available and you will need to create
              another.
            </div>
            <CopyInput
              dataHcName={`${dataHcName}-link`}
              value={generatePublicLinkUrl(newLink.publicLinkUid)}
              copyEngagement={{
                dataHcEventName: 'Copy Editable Link',
                dataHcEventType: MeaningfulEventTypes.Goal,
              }}
            />
            <div className={styles.LinkExpiration}>
              Link Expires At:{' '}
              {humanReadableDateTime(new Date(newLink.expiresAt))}
            </div>
          </div>
        ) : (
          <div className={styles.DialogContent}>
            <div className={styles.InputRow}>
              <span>
                <div>
                  Select how long the link should be accessible for:
                  <br />
                  (7 days max)
                </div>
              </span>
              <div className={styles.InputCell}>
                <InputNumber
                  dataHcName={`${dataHcName}-ttl`}
                  className={styles.Input}
                  min={1}
                  max={7}
                  value={ttl}
                  onChange={(v) => {
                    setTtl(v == null ? 1 : Number(v));
                  }}
                />{' '}
                Days
              </div>
            </div>
            <div>
              <b>IMPORTANT:</b> anyone that accesses this link will have full
              edit rights and all changes made will be visible to anyone that
              accesses this report.
            </div>
          </div>
        )}
      </Dialog>
    </ReportFeaturesSupported>
  );
};
