import { ValuationMethod } from '@hcs/types';

export const VALUATION_METHOD_LABELS = {
  [ValuationMethod.Avm]: 'HouseCanary Value',
  [ValuationMethod.Adjusted]: 'Adjusted Value',
  [ValuationMethod.Comps]: 'Comparable Value',
  [ValuationMethod.UserEntered]: 'User Opinion of Price',
};

export const VALUATION_METHOD_LABELS_RENTAL = {
  [ValuationMethod.Avm]: 'HouseCanary Rental Value',
  [ValuationMethod.Adjusted]: 'Adjusted Rental Value',
  [ValuationMethod.Comps]: 'Rental Comparable Value',
  [ValuationMethod.UserEntered]: 'User Opinion of Rental Price',
};

export const VALUATION_METHOD_DESCRIPTIONS = {
  [ValuationMethod.Avm]: 'HouseCanary’s Estimated Value',
  [ValuationMethod.Adjusted]:
    'The HouseCanary value including your adjustments to condition and property details. ',
  [ValuationMethod.Comps]: 'Comparable Value',
  [ValuationMethod.UserEntered]: 'User Opinion of Price',
};

export const VALUATION_METHOD_DESCRIPTIONS_RENTAL = {
  [ValuationMethod.Avm]: 'HouseCanary’s Estimated Rental Value',
  [ValuationMethod.Adjusted]:
    'The HouseCanary Rental value including your adjustments to condition and property details. ',
  [ValuationMethod.Comps]: 'Rental Comparable Value',
  [ValuationMethod.UserEntered]: 'User Opinion of Rental Price',
};

// Does not include /data because the handler adds that part of the path
export const PATH_HC_ADJUSTMENT_DATE =
  '/comparableValue/hcAdjustments/hcAdjustmentDate';
export const PATH_HC_ADJUSTMENT_PROPERTY_DETAILS =
  '/comparableValue/hcAdjustments/hcAdjustmentPropertyDetails';
