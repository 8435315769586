import React from 'react';
import classNames from 'classnames';

import { useOnErrorToast } from '@hcs/toast';
import { OrderItemStatus, StatusGroup } from '@hcs/types';

import { useOrderProgress } from '../../../hooks/useOrderProgress';

import styles from './OrderProgressDetails.module.css';

type OrderProgressDetailsProps = {
  orderNumber: string;
  orderId: number;
  statusGroups: StatusGroup[];
};

export const OrderProgressDetails = (props: OrderProgressDetailsProps) => {
  const { orderNumber, statusGroups, orderId } = props;
  const onErrorToast = useOnErrorToast();
  const { isSuccess, data: orderProgressData } = useOrderProgress(orderId, {
    onError: (error) => onErrorToast({ title: error.message }),
  });

  return (
    <div>
      {orderProgressData &&
        isSuccess &&
        Object.keys(orderProgressData).length > 0 &&
        statusGroups.map((statusGroup: StatusGroup) => {
          const allProgressKeys = Object.keys(
            orderProgressData
          ) as OrderItemStatus[];
          const statusCount = allProgressKeys.reduce(
            (count, orderProgressKey) => {
              const progressData = orderProgressData[orderProgressKey];
              if (
                progressData &&
                statusGroup.includedStatuses.indexOf(orderProgressKey) !== -1
              ) {
                count = progressData;
              }
              return count;
            },
            0
          );
          let rowClassName = styles.Row;
          if (
            statusGroup.includedStatuses.indexOf(OrderItemStatus.Complete) !==
            -1
          ) {
            rowClassName = classNames(styles.Row, styles.Complete);
          }
          return (
            <div
              className={rowClassName}
              key={`${orderNumber}_${statusGroup.name}`}
            >
              <div className={styles.Label}>{statusGroup.name}</div>
              <div className={styles.NumberOfItems}>{statusCount}</div>
            </div>
          );
        })}
    </div>
  );
};
