import React, { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';

import { RangeField, RangeFieldProps } from '@hcs/design-system';
import { AutoComplete, AutoCompleteOptionType } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import { Toggle } from '@hcs/design-system';
import { MultiSelect, Option } from '@hcs/design-system';
import { DialogInputLayout, DialogInputLayoutProps } from '@hcs/forms';
import {
  BulkEditBuyBoxFormData,
  BuyBoxExcludeFormData,
  BuyBoxFormData,
  BuyBoxListItem,
  BuyBoxRangeFormData,
  LeadFeedPropertyTypeEnum,
  MSA,
  PropertyTypes,
  RangeFilters,
} from '@hcs/types';

import { BUY_BOX_RANGE_FIELD_LABELS } from '../../';
import { useMsas } from '../../';
import { BuyBoxAutoComplete } from '../BuyBoxAutoComplete';

import { BulkEditRangeField } from './BulkEditRangeField';
import {
  BuyBoxZipMultiSelect,
  ZipsBuyBoxSourceConfig,
  ZipsMsaSourceConfig,
} from './BuyBoxZipMultiSelect';

import styles from './BuyBoxInputs.module.css';
import commonStyles from './common.module.css';

export interface CommonInputProps {
  required?: boolean;
}
export const NameInput = (props: CommonInputProps) => {
  const dataHcName = 'name-field';
  const { required = false } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext<BuyBoxFormData>();
  return (
    <DialogInputLayout
      label="Name"
      required={required}
      className={commonStyles.FormRow}
      dataHcName={`${dataHcName}-layout`}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => {
          return (
            <Input
              dataHcName={`${dataHcName}-input`}
              placeholder="Name"
              {...field}
              value={field.value || ''}
              error={errors.name?.message}
            />
          );
        }}
      />
    </DialogInputLayout>
  );
};

const MSA_COVERAGES_FOR_WARNING: Array<MSA['coverage']> = [
  'partial',
  'limited',
  'absent',
];

export const MsaAutoComplete = (props: CommonInputProps) => {
  const dataHcName = 'msa-field';
  const { data: msas } = useMsas();
  const options = useMemo(() => {
    return msas
      ? msas.map((msa) => ({
          label: msa.msaName,
          searchString: msa.msaName,
          value: msa.msaId,
        }))
      : [];
  }, [msas]);

  const {
    control,
    formState: { errors },
  } = useFormContext<BuyBoxFormData>();
  const selectedMsaId = useWatch<BuyBoxFormData, 'msaId'>({
    name: 'msaId',
  });
  const selectedMsa = selectedMsaId
    ? msas?.find((msa) => msa.msaId === selectedMsaId)
    : null;

  const { required = false } = props;

  return (
    <DialogInputLayout
      label="MSA"
      required={required}
      className={commonStyles.FormRow}
      dataHcName={`${dataHcName}-layout`}
    >
      <>
        <Controller
          name="msaId"
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => {
            return (
              <AutoComplete<MSA['msaId']>
                dataHcName={`${dataHcName}-input`}
                placeholder="MSA"
                name={name}
                onBlur={onBlur}
                ref={ref}
                options={options}
                error={errors.msaId?.message}
                config={{
                  onSelect: onChange,
                  selectType: 'single',
                  value,
                }}
              />
            );
          }}
        />
        {selectedMsa &&
          MSA_COVERAGES_FOR_WARNING.includes(selectedMsa.coverage) && (
            <div
              data-hc-name={`${dataHcName}-coverage-warning`}
              className={styles.MsaCoverageWarning}
            >
              <div>We do not have complete listing coverage here yet.</div>
              <div>Please note that your results may be limited.</div>
            </div>
          )}
      </>
    </DialogInputLayout>
  );
};

interface MsaReadOnlyProps extends CommonInputProps {
  msaId: MSA['msaId'] | undefined;
}
export const MsaReadOnly = (props: MsaReadOnlyProps) => {
  const dataHcName = 'msa-readonly';
  const { data: msas } = useMsas();
  const { msaId, required = false } = props;
  const { register } = useFormContext<BuyBoxFormData>();
  const msaName = useMemo(() => {
    if (!msas || !msaId) {
      return null;
    }
    const matchingMsa = msas.find((msa) => msa.msaId === msaId);
    return matchingMsa ? matchingMsa.msaName : null;
  }, [msas, msaId]);
  return (
    <>
      <DialogInputLayout
        label="MSA"
        required={required}
        className={commonStyles.FormRow}
        dataHcName={`${dataHcName}-layout`}
        readonly
      >
        {msaName}
      </DialogInputLayout>
      <input type="hidden" {...register('msaId')} />
    </>
  );
};

type BuyBoxRangeFieldNames = keyof BuyBoxRangeFormData;
type BuyBoxRangeFieldProps = RangeFieldProps<
  BuyBoxRangeFieldNames,
  BuyBoxRangeFieldNames
> &
  Omit<DialogInputLayoutProps, 'children'>;

const BuyBoxRangeField = ({
  dataHcName,
  label,
  required,
  minName,
  maxName,
  isFloat,
  shouldFormat,
}: BuyBoxRangeFieldProps) => {
  return (
    <DialogInputLayout
      dataHcName={`${dataHcName}-layout`}
      label={label}
      required={required}
      className={commonStyles.FormRow}
    >
      <RangeField<BuyBoxFormData, BuyBoxRangeFieldNames, BuyBoxRangeFieldNames>
        dataHcName={dataHcName}
        minName={minName}
        maxName={maxName}
        isFloat={isFloat}
        shouldFormat={shouldFormat}
      />
    </DialogInputLayout>
  );
};

export const ListingPriceRange = (props: CommonInputProps) => {
  const dataHcName = 'list-price-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.listPrice}
      dataHcName={dataHcName}
      minName="listingPriceMin"
      maxName="listingPriceMax"
      required={required}
    />
  );
};

export const ListingPriceBulkEditRange = () => {
  const dataHcName = 'list-price';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="listingPriceMin"
      minActiveName="listingPriceMinActive"
      maxName="listingPriceMax"
      maxActiveName="listingPriceMaxActive"
    />
  );
};

export const BedsRange = (props: CommonInputProps) => {
  const dataHcName = 'beds-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.beds}
      dataHcName={dataHcName}
      minName="bedsMin"
      maxName="bedsMax"
      required={required}
    />
  );
};

export const BedsBulkEditRange = () => {
  const dataHcName = 'beds-field';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="bedsMin"
      minActiveName="bedsMinActive"
      maxName="bedsMax"
      maxActiveName="bedsMaxActive"
    />
  );
};

export const BathsRange = (props: CommonInputProps) => {
  const dataHcName = 'baths-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.baths}
      dataHcName={dataHcName}
      minName="bathsMin"
      maxName="bathsMax"
      required={required}
      isFloat={true}
      shouldFormat={{
        shouldFormatNumber: false,
      }}
    />
  );
};

export const BathsBulkEditRange = () => {
  const dataHcName = 'baths-field';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="bathsMin"
      minActiveName="bathsMinActive"
      maxName="bathsMax"
      maxActiveName="bathsMaxActive"
      isFloat={true}
      shouldFormat={{
        shouldFormatNumber: false,
      }}
    />
  );
};

export const GlaRange = (props: CommonInputProps) => {
  const dataHcName = 'gla-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.sqft}
      dataHcName={dataHcName}
      minName="glaMin"
      maxName="glaMax"
      required={required}
    />
  );
};

export const GlaBulkEditRange = () => {
  const dataHcName = 'gla-field';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="glaMin"
      minActiveName="glaMinActive"
      maxName="glaMax"
      maxActiveName="glaMaxActive"
    />
  );
};

export const LotSizeRange = (props: CommonInputProps) => {
  const dataHcName = 'lot-size-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.lotArea}
      dataHcName={dataHcName}
      minName="lotSizeMin"
      maxName="lotSizeMax"
      required={required}
    />
  );
};

export const LotSizeBulkEditRange = () => {
  const dataHcName = 'lot-size-field';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="lotSizeMin"
      minActiveName="lotSizeMinActive"
      maxName="lotSizeMax"
      maxActiveName="lotSizeMaxActive"
    />
  );
};

export const YearBuiltRange = (props: CommonInputProps) => {
  const dataHcName = 'year-built-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.yearBuilt}
      dataHcName={dataHcName}
      minName="yearBuiltMin"
      maxName="yearBuiltMax"
      required={required}
      shouldFormat={{
        shouldFormatNumber: false,
      }}
    />
  );
};

export const YearBuiltBulkEditRange = () => {
  const dataHcName = 'year-built-field';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="yearBuiltMin"
      minActiveName="yearBuiltMinActive"
      maxName="yearBuiltMax"
      maxActiveName="yearBuiltMaxActive"
      shouldFormat={{
        shouldFormatNumber: false,
      }}
    />
  );
};

export const NumStoriesRange = (props: CommonInputProps) => {
  const dataHcName = 'num-stories-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.noOfStories}
      dataHcName={dataHcName}
      minName="numStoriesMin"
      maxName="numStoriesMax"
      required={required}
    />
  );
};

export const NumStoriesBulkEditRange = () => {
  const dataHcName = 'num-stories-field';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="numStoriesMin"
      minActiveName="numStoriesMinActive"
      maxName="numStoriesMax"
      maxActiveName="numStoriesMaxActive"
    />
  );
};

export const GarageSpacesRange = (props: CommonInputProps) => {
  const dataHcName = 'garage-spaces-field';
  const { required = false } = props;

  return (
    <BuyBoxRangeField
      label={BUY_BOX_RANGE_FIELD_LABELS.parkingGarageCount}
      dataHcName={dataHcName}
      minName="garageSpacesMin"
      maxName="garageSpacesMax"
      required={required}
    />
  );
};

export const GarageSpacesBulkEditRange = () => {
  const dataHcName = 'garage-spaces-field';

  return (
    <BulkEditRangeField
      dataHcName={dataHcName}
      minName="garageSpacesMin"
      minActiveName="garageSpacesMinActive"
      maxName="garageSpacesMax"
      maxActiveName="garageSpacesMaxActive"
    />
  );
};

export const PROPERTY_TYPE_OPTIONS: Option<PropertyTypes>[] = [
  {
    value: LeadFeedPropertyTypeEnum.sfr,
    label: 'Single Family',
  },
  { value: LeadFeedPropertyTypeEnum.condo, label: 'Condominium' },
  {
    value: LeadFeedPropertyTypeEnum.townhouse,
    label: 'Townhouse',
  },
  { value: LeadFeedPropertyTypeEnum.multi, label: 'Multifamily' },
  {
    value: LeadFeedPropertyTypeEnum.manufactured,
    label: 'Manufactured/Mobile',
  },
  { value: LeadFeedPropertyTypeEnum.coop, label: 'Co-op' },
];
const PROPERTY_TYPE_FIELD_NAME = 'propertyType';

export const PropertyTypeMultiSelect = (props: CommonInputProps) => {
  const dataHcName = 'property-type-field';
  const { required = false } = props;
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext<BuyBoxFormData>();

  return (
    <DialogInputLayout
      label="Property Type"
      required={required}
      className={classNames(commonStyles.FormRow, styles.CheckboxesInputLayout)}
      dataHcName={`${dataHcName}-layout`}
    >
      <Controller
        name={PROPERTY_TYPE_FIELD_NAME}
        control={control}
        render={({ field }) => {
          return (
            <MultiSelect<PropertyTypes>
              theme={{
                MultiSelect: styles.MultiSelect,
                Option: styles.MultiSelectOption,
              }}
              dataHcName={`${dataHcName}-multiselect`}
              options={PROPERTY_TYPE_OPTIONS}
              onChange={(values: PropertyTypes[]) => {
                field.onChange(values);
                // trigger validation on select / deselect
                trigger(PROPERTY_TYPE_FIELD_NAME);
              }}
              value={field.value || []}
              inline
            />
          );
        }}
      />
      {errors[PROPERTY_TYPE_FIELD_NAME] && (
        <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
          {errors[PROPERTY_TYPE_FIELD_NAME]?.message}
        </div>
      )}
    </DialogInputLayout>
  );
};

interface CheckboxOption {
  name: keyof BuyBoxExcludeFormData;
  label: string;
}
const EXCLUDE_CHECKBOXES_CONFIG: CheckboxOption[] = [
  { name: 'excludePool', label: 'Pool' },
  { name: 'excludeRentalListings', label: 'Rental Listings' },
  { name: 'excludeSeptic', label: 'Septic' },
];

export const ExcludeCheckboxesInput = (props: CommonInputProps) => {
  const dataHcName = 'exclude-field';
  const { required = false } = props;
  const { control } = useFormContext<BuyBoxFormData>();

  return (
    <DialogInputLayout
      label="Exclude"
      required={required}
      className={classNames(commonStyles.FormRow, styles.CheckboxesInputLayout)}
      dataHcName={`${dataHcName}-layout`}
    >
      <div className={styles.CheckboxFieldsContainer}>
        {EXCLUDE_CHECKBOXES_CONFIG.map((config) => (
          <Controller
            key={config.name}
            name={config.name}
            control={control}
            render={({ field }) => {
              return (
                <Checkbox
                  dataHcName={`${dataHcName}-${config.name}-checkbox`}
                  className={styles.Checkbox}
                  {...field}
                  checked={field.value || false}
                  label={config.label}
                />
              );
            }}
          />
        ))}
      </div>
    </DialogInputLayout>
  );
};

interface BuyBoxMultiSelectProps {
  className?: string;
}

export const BuyBoxMultiSelect = ({ className }: BuyBoxMultiSelectProps) => {
  const dataHcName = 'buyboxes-field';
  const {
    control,
    formState: { errors },
  } = useFormContext<BulkEditBuyBoxFormData>();

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(commonStyles.FormRow, className)}
    >
      <Controller
        name="collectionIds"
        control={control}
        render={({ field }) => {
          return (
            <BuyBoxAutoComplete
              config={{
                selectType: 'multi',
                onSelect: (buyboxes: BuyBoxListItem[] | null) =>
                  field.onChange(buyboxes?.map((bb) => bb.id)),
                value: field.value,
                initialSelectedBB: field.value,
                label: 'Buy Boxes',
              }}
              error={errors.collectionIds?.message}
              onBlur={field.onBlur}
            />
          );
        }}
      />
    </div>
  );
};

const BULK_EDIT_FIELD_OPTIONS: AutoCompleteOptionType<keyof RangeFilters>[] = [
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.listPrice,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.listPrice,
    value: 'listPrice',
  },
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.beds,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.beds,
    value: 'beds',
  },
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.baths,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.baths,
    value: 'baths',
  },
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.sqft,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.sqft,
    value: 'sqft',
  },
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.lotArea,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.lotArea,
    value: 'lotArea',
  },
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.yearBuilt,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.yearBuilt,
    value: 'yearBuilt',
  },
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.noOfStories,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.noOfStories,
    value: 'noOfStories',
  },
  {
    label: BUY_BOX_RANGE_FIELD_LABELS.parkingGarageCount,
    searchString: BUY_BOX_RANGE_FIELD_LABELS.parkingGarageCount,
    value: 'parkingGarageCount',
  },
];

const PlaceholderComponent = () => null;

const BULK_EDIT_FIELD_TO_COMPONENT: Record<
  keyof RangeFilters,
  React.ComponentType<CommonInputProps>
> = {
  listPrice: ListingPriceBulkEditRange,
  beds: BedsBulkEditRange,
  baths: BathsBulkEditRange,
  sqft: GlaBulkEditRange,
  lotArea: LotSizeBulkEditRange,
  yearBuilt: YearBuiltBulkEditRange,
  noOfStories: NumStoriesBulkEditRange,
  parkingGarageCount: GarageSpacesBulkEditRange,
};

export const BulkEditField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BulkEditBuyBoxFormData>();
  const dataHcName = 'bulk-edit-field';

  const selectedField = useWatch({
    name: 'field',
    control,
  });
  const ComponentForSelectedField = selectedField
    ? BULK_EDIT_FIELD_TO_COMPONENT[selectedField]
    : PlaceholderComponent;

  return (
    <div data-hc-name={dataHcName} className={styles.FieldAutoComplete}>
      <Controller
        name="field"
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <AutoComplete<keyof RangeFilters>
              className={commonStyles.FormRow}
              dataHcName={`${dataHcName}-choose-autocomplete`}
              placeholder="Choose Parameter"
              name={name}
              onBlur={onBlur}
              ref={ref}
              options={BULK_EDIT_FIELD_OPTIONS}
              error={errors.field?.message}
              config={{
                onSelect: onChange,
                selectType: 'single',
                value: value,
              }}
            />
          );
        }}
      />
      <ComponentForSelectedField />
    </div>
  );
};

interface BuyBoxZipMultiSelectFieldProps extends CommonInputProps {
  config: ZipsBuyBoxSourceConfig | Omit<ZipsMsaSourceConfig, 'msaId'>;
}

export const BuyBoxZipMultiSelectField = (
  props: BuyBoxZipMultiSelectFieldProps
) => {
  const dataHcName = 'zip-field';
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<BuyBoxFormData>();

  const { required = false, config } = props;

  const selectedMsaId = useWatch<BuyBoxFormData, 'msaId'>({
    name: 'msaId',
  });

  return (
    <DialogInputLayout
      label="ZIP Codes"
      required={required}
      className={commonStyles.FormRow}
      dataHcName={`${dataHcName}-layout`}
    >
      <>
        <Controller
          name="selectedZipcodes"
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => {
            return (
              <BuyBoxZipMultiSelect
                theme={{
                  OptionsContainer: styles.OptionsContainer,
                }}
                name={name}
                placeholder="Select Zip Codes"
                onSelect={(selectedZips, unSelectedZips) => {
                  onChange(selectedZips);
                  setValue('unSelectedZipcodes', unSelectedZips);
                }}
                onBlur={onBlur}
                error={errors.selectedZipcodes?.message}
                value={value}
                config={
                  config.source === 'buyBox'
                    ? config
                    : {
                        source: config.source,
                        msaId: selectedMsaId ? selectedMsaId : null,
                      }
                }
                selectAllOnDataChange={config.source === 'msa'}
              />
            );
          }}
        />
        <input type="hidden" {...register('unSelectedZipcodes')} />
      </>
    </DialogInputLayout>
  );
};

const HISTORICAL_TOGGLE_OPTIONS: Option<boolean>[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const HistoricalDetectionToggle = (props: CommonInputProps) => {
  const dataHcName = 'historical-toggle';
  const { required = false } = props;

  const { control } = useFormContext<BuyBoxFormData>();

  return (
    <DialogInputLayout
      fullWidth
      label="Would you like to enable historical detection?"
      labelHelper="Enabling historical detection will run your buy box criteria retroactive for the past 90 days."
      required={required}
      className={commonStyles.FormRow}
      dataHcName={`${dataHcName}-layout`}
    >
      <Controller
        name="historicalDetection"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Toggle<boolean>
            primary
            dataHcName={dataHcName}
            value={value}
            options={HISTORICAL_TOGGLE_OPTIONS}
            onChange={onChange}
          />
        )}
      />
    </DialogInputLayout>
  );
};

const TEMPLATE_TOGGLE_OPTIONS: Option<boolean>[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const TemplateToggle = (
  props: CommonInputProps & { updateMode: boolean }
) => {
  const dataHcName = 'template-toggle';
  const { required = false, updateMode = false } = props;

  const { control, register } = useFormContext<BuyBoxFormData>();

  return (
    <DialogInputLayout
      fullWidth
      label={
        updateMode
          ? 'Would you like to update your default buy box template?'
          : 'Would you like to make this your default buy box template?'
      }
      required={required}
      className={commonStyles.FormRow}
      dataHcName={`${dataHcName}-layout`}
    >
      <Controller
        name="shouldSaveAsTemplate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Toggle<boolean>
            primary
            dataHcName={dataHcName}
            value={value}
            options={TEMPLATE_TOGGLE_OPTIONS}
            onChange={onChange}
          />
        )}
      />
      <input key="templateId" type="hidden" {...register('templateId')} />
    </DialogInputLayout>
  );
};
