import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatBoolYesNo } from '@hcs/utils';

const FIELD = PropertyStateFields.currentFlip;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Flip';
const labelShort = 'Flip';
const propertyStatePath: PropertyStatePaths =
  '/complexFieldsSale/currentFlipYN';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.complexFieldsSale?.currentFlipYN;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatBoolYesNo(getValue(propertyStateArgs));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Flip';
const formatValueShort: FieldConfig['formatValueShort'] = (propertyStateArgs) =>
  formatBoolYesNo(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) => {
  const value = getValue(propertyStateArgs);
  if (value === null || value === undefined) {
    return `Unknown ${labelShort}`;
  } else if (value) {
    return `Is ${labelShort}`;
  } else {
    return `Not ${labelShort}`;
  }
};
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const CURRENT_FLIP_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  labelMicro,
  formatValueShort,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
};
