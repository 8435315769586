import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CerberusStatsFieldConfig,
  CerberusStatsFields,
  CerberusStatsProps,
  CerberusStatsTableCellProps,
  CerberusStatsTableHeaderCellProps,
  CerberusStatsType,
} from '@hcs/types';
import { formatPercentD3, getD3PercentFormat } from '@hcs/utils';

import {
  getRentalLabel,
  logValueUnsupportedWarning,
} from '../../utils/cerberusStatsConfig.utils';

const D3_FORMAT_CONFIG = { precision: 1 };
const D3_FORMAT_STR = getD3PercentFormat(D3_FORMAT_CONFIG);

const FIELD = CerberusStatsFields.listingsRemovedPercentMovingAvg;
const FIELD_RENTAL = CerberusStatsFields.listingsRemovedPercentMovingAvgRental;
type FieldConfig = CerberusStatsFieldConfig<typeof FIELD>;
type FieldConfigRental = CerberusStatsFieldConfig<typeof FIELD_RENTAL>;
const label = 'Listings Removed Percent Moving Average';
const labelRental = getRentalLabel(label);
const labelShort = 'Listings Removed % Moving Avg';
const labelRentalShort = getRentalLabel(labelShort);
const getValue: FieldConfig['getValue'] = (cerberusStats) => {
  if (!cerberusStats) {
    return undefined;
  }
  if (cerberusStats.type !== CerberusStatsType.RentalListingStats) {
    return cerberusStats?.listingStats?.listingsRemovedPercentMovingAvg;
  } else {
    logValueUnsupportedWarning(FIELD, cerberusStats);
    return undefined;
  }
};
const getValueRental: FieldConfigRental['getValue'] = (cerberusStats) => {
  if (!cerberusStats) {
    return undefined;
  }
  if (
    cerberusStats.type === CerberusStatsType.Msa ||
    cerberusStats.type === CerberusStatsType.RentalListingStats
  ) {
    return cerberusStats?.rentalListingStats?.listingsRemovedPercentMovingAvg;
  } else {
    // we expect this to be potentially called for zip, since customized columns are the same for msa and zip
    if (cerberusStats.type !== CerberusStatsType.Zip) {
      logValueUnsupportedWarning(FIELD_RENTAL, cerberusStats);
    }
    return undefined;
  }
};
const formatValue: FieldConfig['formatValue'] = (cerberusStats) =>
  formatPercentD3(getValue(cerberusStats), D3_FORMAT_CONFIG);
const formatValueRental: FieldConfig['formatValue'] = (cerberusStats) =>
  formatPercentD3(getValueRental(cerberusStats), D3_FORMAT_CONFIG);
// Component for displaying a field from a schema
const Display = ({ cerberusStats }: CerberusStatsProps) => {
  return <>{formatValue(cerberusStats)}</>;
};
const DisplayRental = ({ cerberusStats }: CerberusStatsProps) => {
  return <>{formatValueRental(cerberusStats)}</>;
};

const HeaderCell = (props: CerberusStatsTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
const HeaderCellRental = (props: CerberusStatsTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelRentalShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;
HeaderCellRental.defaultProps = defaultHeaderProps;

const ContentCell = ({
  cerberusStats,
  ...tableCellProps
}: CerberusStatsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display cerberusStats={cerberusStats} />
    </TableCell>
  );
};
const ContentCellRental = ({
  cerberusStats,
  ...tableCellProps
}: CerberusStatsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <DisplayRental cerberusStats={cerberusStats} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;
ContentCellRental.defaultProps = defaultContentProps;

const definition =
  'Moving average of percent of all actively listed single family detached properties removed from market during the week ending on the date specified (not due to going into contract or close).';
const filterConfig: FieldConfig['filterConfig'] = {
  isPositive: true,
  isPercent: true,
  decimalPlaces: 1,
};

export const LISTINGS_REMOVED_PERCENT_MOVING_AVG_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  getValue,
  formatValue,
  HeaderCell,
  ContentCell,
  Display,
  d3FormatStr: D3_FORMAT_STR,
  definition,
  filterConfig,
};

export const LISTINGS_REMOVED_PERCENT_MOVING_AVG_RENTAL_CONFIG: FieldConfigRental =
  {
    field: FIELD_RENTAL,
    label: labelRental,
    labelShort: labelRentalShort,
    getValue: getValueRental,
    formatValue: formatValueRental,
    HeaderCell: HeaderCellRental,
    ContentCell: ContentCellRental,
    Display: DisplayRental,
    d3FormatStr: D3_FORMAT_STR,
    definition,
    filterConfig,
  };
