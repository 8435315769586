import React from 'react';
import classNames from 'classnames';

import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';

import { useReportPermissions } from '../../hooks';
import {
  COMP_FILTER_BAR_FEATURES_RENTAL,
  COMP_FILTER_BAR_FEATURES_SALE,
  CompFilterBar,
} from '../CompFilterBar';
import {
  COMP_KEYWORD_SEARCH_FEATURES_RENTAL,
  COMP_KEYWORD_SEARCH_FEATURES_SALE,
  CompKeywordSearch,
} from '../CompKeywordSearchDeprecated';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './CompControls.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  navigateToCompSelectionDraw: NavigateToCompSelectionDrawFn;
}
const dataHcName = 'comp-controls';
export const COMP_CONTROLS_FEATURES_SALE = [
  ...COMP_KEYWORD_SEARCH_FEATURES_SALE,
  ...COMP_FILTER_BAR_FEATURES_SALE,
];
export const COMP_CONTROLS_FEATURES_RENTAL = [
  ...COMP_KEYWORD_SEARCH_FEATURES_RENTAL,
  ...COMP_FILTER_BAR_FEATURES_RENTAL,
];
export const CompControls = ({
  reportId,
  compType,
  className,
  navigateToCompSelectionDraw,
}: Props) => {
  const {
    data: { isEditable },
  } = useReportPermissions(reportId);
  const isRental = compType === CompTypes.Rental;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompControls, className)}
    >
      {isEditable && (
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental
              ? COMP_KEYWORD_SEARCH_FEATURES_SALE
              : COMP_KEYWORD_SEARCH_FEATURES_SALE
          }
        >
          <div className={styles.KeywordSearch}>
            <CompKeywordSearch reportId={reportId} compType={compType} />
          </div>
        </ReportFeaturesSupported>
      )}
      <div className={styles.Filters}>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental
              ? COMP_FILTER_BAR_FEATURES_RENTAL
              : COMP_FILTER_BAR_FEATURES_SALE
          }
        >
          <CompFilterBar
            reportId={reportId}
            compType={compType}
            navigateToCompSelectionDraw={navigateToCompSelectionDraw}
          />
        </ReportFeaturesSupported>
      </div>
    </div>
  );
};
