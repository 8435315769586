import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import {
  Card,
  Skeleton,
  ThumbnailCarousel,
  ThumbnailCarouselProps,
} from '@hcs/design-system';
import {
  CompSchema,
  PropertyStateArgsProps,
  PropertyStateFieldsConfigProps,
} from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import { PropertyBrokerageCredit } from '../PropertyBrokerageCredit';
import { PropertySummaryDetails } from '../PropertySummaryDetails';

import styles from './PropertyCard.module.css';

export interface PropertyCardProps
  extends PropertyStateArgsProps,
    PropertyStateFieldsConfigProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  isLoading?: boolean;
  photos?: ReactNode | Omit<ThumbnailCarouselProps, 'dataHcName'>;
  badges?: ReactNode;
  showBadges?: boolean;
  comp?: Omit<CompSchema, 'propertyState'>;
  children?: ReactNode;
  showBrokerageCredit?: boolean;
  showBrokerageCreditAgentName?: boolean;
  floatingActionButton?: ReactNode;
  style?: CSSProperties;
  flat?: boolean;
  noDetails?: boolean;
  noGallery?: boolean;
  resizable?: boolean;
  horizontal?: boolean;
  highlighted?: boolean;
  clickable?: boolean;
  /** Separate button prop because buttons are rendered below the brokerage credit */
  button?: ReactNode;
  onClick?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  onPhotoViewToggle?: VoidFunction;
}
export const PropertyCard = ({
  dataHcName,
  className,
  isLoading,
  comp,
  badges,
  showBadges = true,
  photos,
  children,
  fields,
  showBrokerageCredit,
  showBrokerageCreditAgentName,
  isRental,
  propertyStateArgs,
  floatingActionButton,
  flat,
  noDetails,
  noGallery = false,
  horizontal,
  button,
  resizable,
  highlighted,
  clickable,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: PropertyCardProps) => {
  const { propertyState } = propertyStateArgs || {};
  return (
    <Card
      dataHcName={dataHcName}
      className={classNames(styles.PropertyCard, className, {
        [styles.flat]: flat,
        [styles.noDetails]: noDetails,
        [styles.horizontal]: horizontal,
        [styles.resizable]: resizable,
        [styles.highlighted]: highlighted,
        [styles.clickable]: !!clickable,
      })}
      theme={{
        Content:
          showBrokerageCredit !== false
            ? styles.CardContentWithBrokerageCredit
            : styles.CardContent,
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      smallPadding={!flat}
      noPadding={flat}
      noBoxShadow={flat}
      media={
        noGallery
          ? undefined
          : {
              ...(horizontal
                ? { width: 180, horizontal: true }
                : { height: 120 }),
              isLoading,
              content:
                photos && typeof photos === 'object' && 'photos' in photos ? (
                  <ThumbnailCarousel
                    {...photos}
                    isLoading={isLoading}
                    dataHcName={`${dataHcName}-thumbnail-carousel`}
                  />
                ) : (
                  photos
                ),
            }
      }
    >
      {showBadges && <div className={styles.Badges}>{badges}</div>}
      {floatingActionButton && (
        <span className={styles.FloatingActionButton}>
          {floatingActionButton}
        </span>
      )}
      <div className={styles.AddressSection}>
        <div
          data-hc-name={`${dataHcName}-streetAddress`}
          className={styles.StreetAddress}
        >
          {isLoading ? (
            <Skeleton
              dataHcName={`${dataHcName}-skeleton-streetAddress`}
              type="text"
              width="85%"
            />
          ) : (
            formatStreetAddress(propertyState?.location)
          )}
        </div>
        <div
          data-hc-name={`${dataHcName}-cityStateZip`}
          className={styles.CityStateZip}
        >
          {isLoading ? (
            <Skeleton
              dataHcName={`${dataHcName}-skeleton-city-state-zip`}
              type="text"
              width="75%"
            />
          ) : (
            formatCityStateZip(propertyState?.location)
          )}
        </div>
      </div>
      {!noDetails && (
        <PropertySummaryDetails
          size="sm"
          propertyStateArgs={propertyStateArgs}
          isLoading={isLoading}
          comp={comp}
          fields={fields}
          isRental={isRental}
        />
      )}
      {children}
      {button && <div className={styles.Button}>{button}</div>}
      {showBrokerageCredit !== false && (
        <div className={styles.BrokerageCredit}>
          {isLoading ? (
            <Skeleton
              dataHcName={`${dataHcName}-showBrokerageCredit-skeleton`}
              type="text"
            />
          ) : (
            <PropertyBrokerageCredit
              propertyStateArgs={propertyStateArgs}
              isRental={!!isRental}
              showAgentName={showBrokerageCreditAgentName}
            />
          )}
        </div>
      )}
    </Card>
  );
};
