/* SECURITY: This file does nothing unsafe with localStorage */
/* eslint scanjs-rules/identifier_localStorage: 0 */
import { useCallback, useEffect, useMemo, useState } from 'react';

/** A localStorage state hook that will trigger renders when the state changes */
type LocalStorageState = Record<string, string | null>;
const callbacks: Record<string, () => void> = {};
const getUpdatedState = (stateKeys: string[]): LocalStorageState => {
  return stateKeys.reduce<LocalStorageState>((state, k) => {
    const v = localStorage.getItem(k);
    if (v) {
      state[k] = v;
    }
    return state;
  }, {});
};

export const useLocalStorageState = (
  key: string,
  stateKeys: string[]
): {
  state: LocalStorageState;
  actions: Storage;
} => {
  const [state, setState] = useState(getUpdatedState(stateKeys));
  const dispatchStateChangeEvent = useCallback(() => {
    document.dispatchEvent(new Event(key));
  }, [key]);

  useEffect(() => {
    const handleLocalStorageChange = () => {
      setState(getUpdatedState(stateKeys));
    };
    // Subscribe hook instance to listeners
    callbacks[key] = handleLocalStorageChange;
    document.addEventListener(key, handleLocalStorageChange, false);

    return () => {
      if (callbacks[key]) {
        delete callbacks[key];
        document.removeEventListener(key, handleLocalStorageChange);
      }
    };
  }, []);

  return {
    state,
    actions: useMemo((): Storage => {
      return {
        getItem: (key: string) => localStorage.getItem(key),
        clear: () => {
          stateKeys.forEach((k) => {
            localStorage.removeItem(k);
          });
          dispatchStateChangeEvent();
        },
        removeItem: (key: string) => {
          console.log('REMOVE LOCAL STORAGE', key);
          localStorage.removeItem(key);
          dispatchStateChangeEvent();
        },
        setItem: (key: string, value: string) => {
          localStorage.setItem(key, value);
          dispatchStateChangeEvent();
        },
        key: localStorage.key,
        length: localStorage.length,
      };
    }, [dispatchStateChangeEvent]),
  };
};
