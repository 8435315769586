import {
  PersonaTypesOrg,
  PreferencesKeys,
  ReportFeaturesComps,
  ReportFeaturesRentalComps,
  ReportFeaturesSubject,
  UiPreferences
} from '@hcs/types';

export const UI_PREFERENCE_TEMPLATE_SERVICE_PROVIDER: UiPreferences = {
  [PreferencesKeys.Persona]: PersonaTypesOrg.ServiceProvider,
  [PreferencesKeys.ReportFeaturesSubject]: {
    [ReportFeaturesSubject.Comments]: false,
    [ReportFeaturesSubject.Sharing]: true,
    [ReportFeaturesSubject.Download]: true,
    [ReportFeaturesSubject.KeyFacts]: true,
    [ReportFeaturesSubject.Insights]: true,
    [ReportFeaturesSubject.Personalization]: false,
    [ReportFeaturesSubject.HeatMaps]: true
  },
  [PreferencesKeys.ReportFeaturesComps]: {
    [ReportFeaturesComps.Enabled]: true,
    [ReportFeaturesComps.AddCompByMlsNumber]: true,
    [ReportFeaturesComps.SavedCompFilters]: true,
    [ReportFeaturesComps.RefreshComps]: false,
    [ReportFeaturesComps.Tags]: true,
    [ReportFeaturesComps.AdjustComps]: true,
    [ReportFeaturesComps.CustomAreaCompSearch]: true,
    [ReportFeaturesComps.AutoSelectHcSuggestedComps]: true,
    [ReportFeaturesComps.KeywordSearch]: true
  },
  [PreferencesKeys.ReportFeaturesRentalComps]: {
    [ReportFeaturesRentalComps.Enabled]: false
  }
};
