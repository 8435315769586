import { useIsHelpChatEnabled, useLogIntercomEvent } from '@hcs/help-chat';
import {
  EngagementEventPayload,
  IntercomEventData,
  MeaningfulEventTypes,
} from '@hcs/types';

export const useTrackIntercomEvent = () => {
  const helpChatEnabled = useIsHelpChatEnabled();
  const logIntercomEvent = useLogIntercomEvent();
  const trackIntercomEvent = (engagementEvent: EngagementEventPayload) => {
    // only track goal events, intercom has a unique event type limit of 120 which we were exceeding by tracking all meaningful events
    if (
      helpChatEnabled &&
      engagementEvent.eventType === MeaningfulEventTypes.Goal &&
      engagementEvent.eventData
    ) {
      const {
        device,
        eventSection,
        dataHcName,
        originalEventType,
        property_report,
        requestedProduct,
        offer_now,
      } = engagementEvent.eventData;

      // note that intercom limits this data to 20 attributes
      const intercomEventData: IntercomEventData = {
        browser: device?.browser,
        browserVersion: device?.browserVersion,
        os: device?.os,
        osVersion: device?.osVersion,
        eventSection,
        eventType: engagementEvent.eventType,
        originalEventType,
        app: engagementEvent.app,
        pageGroup: engagementEvent.pageGroup,
        httpPath: engagementEvent.httpPath,
        httpReferer: engagementEvent.httpReferer,
        dataHcName,
        propertyReportAddressId: property_report?.hcAddressId,
        propertyReportAddressSlug: property_report?.slug,
        propertyReportId: property_report?.reportId,
        offerNowAddressId: offer_now?.hcAddressId,
        offerNowAddressSlug: offer_now?.slug,
        requestedProduct: requestedProduct,
      };

      logIntercomEvent(engagementEvent.eventName, intercomEventData);
    }
  };
  return {
    mutate: trackIntercomEvent,
  };
};
