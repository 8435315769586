import React from 'react';
import { NumberSettings } from 'accounting';

import { InputProps } from '@hcs/types';
import { formatNumForInput, parseNumFromInput } from '@hcs/utils';

import { Input } from '../Input';

export interface InputNumberProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  shouldFormat?:
    | {
        shouldFormatNumber: true;
        formatNumberOptions?: NumberSettings;
      }
    | {
        shouldFormatNumber: false;
      };
  isFloat?: boolean;
  value: string | number | null;
  max?: number;
  min?: number;
  onChange: (value: string | number | null) => void;
}

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  (props: InputNumberProps, ref) => {
    const {
      dataHcName,
      className,
      placeholder,
      shouldFormat = {
        shouldFormatNumber: true,
      },
      isFloat = false,
      value,
      onChange,
      max,
      min,
      ...restProps
    } = props;

    const format = (value: number | string | null) =>
      formatNumForInput(value, shouldFormat);

    const parse = (strValue: string) =>
      parseNumFromInput(strValue, { isFloat });

    return (
      <Input
        placeholder={placeholder}
        dataHcName={dataHcName}
        className={className}
        value={format(value)}
        onChange={(strValue) => {
          let numValue = parse(strValue);
          if (typeof numValue === 'number') {
            if (max != null && numValue > max) {
              numValue = max;
            }
            if (min != null && numValue < min) {
              numValue = min;
            }
          }
          onChange(numValue);
        }}
        ref={ref}
        maxLength={14}
        {...restProps}
      />
    );
  }
);
