import React from 'react';
import classNames from 'classnames';

import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import {
  PropertyStateCore,
  PropertyStateFields,
  PropertyStateSources,
  PropertyStateType,
} from '@hcs/types';
import { NULL_VALUE } from '@hcs/utils';

import { PROPERTY_STATE_FIELD_CONFIGS } from '../../configs/index';

import styles from './PropertyDataSourcesTable.module.css';

export interface PropertyDataSourcesTableProps {
  className?: string;
  isLoading?: boolean;
  propertyFields?: PropertyStateFields[];
  propertyStateSources:
    | {
        [PropertyStateSources.HC]: PropertyStateCore;
        [PropertyStateSources.MLS]: PropertyStateCore;
        [PropertyStateSources.PR]: PropertyStateCore;
      }
    | undefined;
}
const dataHcName = 'property-data-sources';
export const PropertyDataSourcesTable = ({
  className,
  isLoading,
  propertyStateSources,
  propertyFields = [
    PropertyStateFields.livingArea,
    PropertyStateFields.lotSize,
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.stories,
    PropertyStateFields.propertyType,
    PropertyStateFields.ownerOccupied,
    PropertyStateFields.yearBuilt,
    PropertyStateFields.basementHas,
    PropertyStateFields.pool,
    PropertyStateFields.daysOnMarketCumulative,
    PropertyStateFields.garageType,
    PropertyStateFields.garageSpaces,
    PropertyStateFields.condition,
    PropertyStateFields.subdivisionName,
    PropertyStateFields.zoning,
    PropertyStateFields.associationName,
    PropertyStateFields.associationFee,
    PropertyStateFields.associationFeeFrequency,
    PropertyStateFields.associationFeeIncludes,
    PropertyStateFields.taxYear,
    PropertyStateFields.taxAmount,
    PropertyStateFields.apn,
    PropertyStateFields.county,
  ],
}: PropertyDataSourcesTableProps) => {
  const psHc = propertyStateSources?.[PropertyStateSources.HC];
  const psMls = propertyStateSources?.[PropertyStateSources.MLS];
  const psPr = propertyStateSources?.[PropertyStateSources.PR];
  return (
    <Table
      dataHcName={dataHcName}
      skeletonConfig={{
        isLoading: !!isLoading,
        colCount: 4,
        rows: propertyFields.length,
      }}
      className={classNames(styles.PropertyDataSourcesTable, className)}
    >
      <TableHeader>
        <TableHeaderCell></TableHeaderCell>
        <TableHeaderCell width={261}>HouseCanary</TableHeaderCell>
        <TableHeaderCell width={261}>MLS</TableHeaderCell>
        <TableHeaderCell width={261}>Public Record</TableHeaderCell>
      </TableHeader>
      {propertyFields.map((field) => {
        const { label, ContentCell } = PROPERTY_STATE_FIELD_CONFIGS[field];
        return (
          <TableRow key={`data-sources-${field}`}>
            <TableCell>{label}</TableCell>
            {psHc ? (
              <ContentCell
                className={classNames(styles.Value, styles.hcValue)}
                propertyStateArgs={{
                  propertyStateType: PropertyStateType.Core,
                  propertyState: psHc,
                }}
              />
            ) : (
              <TableCell className={styles.Value}>{NULL_VALUE}</TableCell>
            )}
            {psMls ? (
              <ContentCell
                className={styles.Value}
                propertyStateArgs={{
                  propertyStateType: PropertyStateType.Core,
                  propertyState: psMls,
                }}
              />
            ) : (
              <TableCell className={styles.Value}>{NULL_VALUE}</TableCell>
            )}
            {psPr ? (
              <ContentCell
                className={styles.Value}
                propertyStateArgs={{
                  propertyStateType: PropertyStateType.Core,
                  propertyState: psPr,
                }}
              />
            ) : (
              <TableCell className={styles.Value}>{NULL_VALUE}</TableCell>
            )}
          </TableRow>
        );
      })}
    </Table>
  );
};
