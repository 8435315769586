import { PropertyGraphClient } from '@hcs/http-clients';
import { PropertyGraphListing } from '@hcs/types';
import { SearchByMlsNumberInput } from '@hcs/types';

import { SEARCH_BY_MLS_NUMBER } from './queries';

async function submit({ input }: { input: SearchByMlsNumberInput }) {
  const response = await PropertyGraphClient.request<
    {
      searchByMlsNumbers: PropertyGraphListing[];
    },
    SearchByMlsNumberInput
  >(SEARCH_BY_MLS_NUMBER, input);
  return response.searchByMlsNumbers;
}

export const SearchByMlsNumberApi = {
  submit,
};
