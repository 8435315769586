import {
  addYears,
  differenceInBusinessDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  eachDayOfInterval,
  format as dateFnsFormat,
  formatDuration,
  formatISO,
  isToday,
  parse as dateFnsParse,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

import { DateRange, DateStr, DateStrHuman, DatetimeStr } from '@hcs/types';

import { NULL_VALUE } from './formatters.utils';

export const getDatetimeString = (date?: Date): DatetimeStr =>
  dateFnsFormat(date || new Date(), `yyyy-MM-dd hh:mm:ss`) as DatetimeStr;

export const getDateStr = (date?: Date): DateStr | undefined =>
  date ? (dateFnsFormat(date, 'yyyy-MM-dd') as DateStr) : undefined;

export const parseDateString = (dateStr: DateStr | string) => {
  if (dateStr.includes('T')) {
    if (dateStr.includes('Z')) {
      return new Date(dateStr);
    } else if (dateStr.includes('.')) {
      return dateFnsParse(
        dateStr,
        `yyyy-MM-dd'T'kk:mm:ss.SSSSSSXXX`,
        new Date()
      );
    } else {
      return dateFnsParse(
        dateStr.split('T')[0] || '',
        `yyyy-MM-dd`,
        new Date()
      );
    }
  } else {
    if (dateStr.split('-')[0]?.length === 4) {
      return dateFnsParse(dateStr, `yyyy-MM-dd`, new Date());
    }
    return dateFnsParse(dateStr, `yy-MM-dd`, new Date());
  }
};

export const humanReadableDate = (date: Date) => {
  const formatting = 'LLLL d, yyyy';
  return dateFnsFormat(date, formatting);
};

export const humanReadableDateTime = (date: Date) => {
  const formatting = 'LLLL d, yyyy p';
  return dateFnsFormat(date, formatting);
};

export const isDateToday = (date?: DateStr): boolean => {
  if (!date) return false;
  try {
    return isToday(parseDateString(date));
  } catch (e) {
    return false;
  }
};

export const daysAgoToDateRange = (
  daysAgo: number,
  today = new Date()
): DateRange => {
  // remove time portion of date
  today.setHours(0, 0, 0, 0);
  // subtract one day from daysAgo b/c we want to include today as one of the days
  const nDaysAgoDate = subDays(today, daysAgo === 0 ? daysAgo : daysAgo - 1);
  return {
    startDate: nDaysAgoDate,
    endDate: today,
  };
};

export const calculateDaysAgo = (d: Date): number => {
  // Given a date, calculate how many days have past.
  // Same day === 0
  // Yesterday === 1
  const today = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = today.getTime() - d.getTime();
  return Math.floor(diffInTime / oneDay);
};

export const daysAgoToDateStr = (
  daysAgo: number,
  today: Date | undefined = new Date()
): DateStr | undefined => {
  // remove time portion of date
  today.setHours(0, 0, 0, 0);
  // subtract one day from daysAgo b/c we want to include today as one of the days
  const nDaysAgoDate = subDays(today, daysAgo === 0 ? daysAgo : daysAgo - 1);
  return getDateStr(nDaysAgoDate);
};

export const yearsAgoToDateStr = (
  yearsAgo: number,
  today: Date | undefined = new Date()
) => {
  // remove time portion of date
  today.setHours(0, 0, 0, 0);
  const nYearsAgoDate = subYears(today, yearsAgo);
  return getDateStr(nYearsAgoDate);
};

export const yearsAheadToDateStr = (
  yearsAhead: number,
  today = new Date()
): DateStr | undefined => {
  // remove time portion of date
  today.setHours(0, 0, 0, 0);
  const nYearsAheadDate = addYears(today, yearsAhead);
  return getDateStr(nYearsAheadDate);
};

const DATE_ISO_STRING_FORMAT = 'yyyy-MM-dd';
export const dateToISOString = (date: Date): string => {
  return dateFnsFormat(date, DATE_ISO_STRING_FORMAT);
};

export const dateRangeToDates = (dateRange: DateRange): Date[] => {
  return eachDayOfInterval({
    start: dateRange.startDate,
    end: dateRange.endDate,
  });
};

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateStringsFromRange = (dateRange: DateRange) => {
  return dateRangeToDates(dateRange).map((date) => formatISO(date));
};

export const isDateStr = (dateStr: string): dateStr is DateStr => {
  if (dateStr.length !== 10) {
    return false;
  }
  const dateParts = dateStr.split('-');
  const yearString = dateParts[0];
  const monthString = dateParts[1];
  const dayString = dateParts[2];
  if (
    yearString?.length !== 4 ||
    monthString?.length !== 2 ||
    dayString?.length !== 2
  ) {
    return false;
  }
  const year = Number(yearString);
  const month = Number(monthString);
  const day = Number(dayString);

  if (isNaN(year) || isNaN(month) || isNaN(day)) return false;

  if (
    year < 1000 || //sorry charlemagne, you're too old for real estate. go retire or something, sheesh
    month > 12 ||
    month < 1 ||
    day > 31 ||
    day < 1
  ) {
    return false;
  }
  if (month === 2 && day > 29) {
    return false;
  }
  if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
    return false;
  }
  return true;
};

export const isHumanOrderDateStr = (
  dateStr: string
): dateStr is DateStrHuman => {
  if (dateStr.length !== 10) {
    return false;
  }
  const dateParts = dateStr.split('-');

  const yearString = dateParts[2];
  const monthString = dateParts[0];
  const dayString = dateParts[1];
  if (
    yearString?.length !== 4 ||
    monthString?.length !== 2 ||
    dayString?.length !== 2
  ) {
    return false;
  }
  const year = Number(yearString);
  const month = Number(monthString);
  const day = Number(dayString);

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return false;
  }

  if (year < 1000 || month > 12 || month < 1 || day > 31 || day < 1) {
    return false;
  }
  if (month === 2 && day > 29) {
    return false;
  }
  if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
    return false;
  }
  return true;
};

export const humanOrderDateToDateStr = (
  humanDate: string
): undefined | DateStr => {
  if (!isHumanOrderDateStr(humanDate)) {
    return undefined;
  }
  const dateParts = humanDate.split('-');
  const month = dateParts[0];
  const day = dateParts[1];
  const year = dateParts[2];
  return `${year}-${month}-${day}` as DateStr;
};

const getDateStrParts = (
  dateStr: DateStr
): { year: number; month: number; day: number } => {
  const dateParts = dateStr.split('-');
  return {
    year: Number(dateParts[0]),
    month: Number(dateParts[1]),
    day: Number(dateParts[2]),
  };
};

export const dateStrToHumanOrderDate = (
  dateStr: string
): DateStrHuman | undefined => {
  if (!isDateStr(dateStr)) {
    return undefined;
  }
  const { year, month, day } = getDateStrParts(dateStr);
  return `${month}-${day}-${year}`;
};

export const dateStrToFormattedDate = (
  dateStr: string | null | undefined
): string | null => {
  if (!dateStr) {
    return null;
  }
  if (!isDateStr(dateStr)) {
    return null;
  }
  const { year, month, day } = getDateStrParts(dateStr);
  return `${month}/${day}/${year}`;
};

export const nowKeywordToDate = (now: string | number | null) =>
  now === 'now' ? Date.now() : now;

export const formatTimeRemaining = (
  startDate: string | number | null,
  endDate: string | number | null
) => {
  if (!endDate || !startDate) return NULL_VALUE;

  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);
  const dayDiff = differenceInDays(formattedEndDate, formattedStartDate);

  if (dayDiff !== 0) {
    const diffInHours = differenceInHours(formattedEndDate, formattedStartDate);
    const days = diffInHours / 24;
    const flooredDays = Math.floor(days);
    let leftOverHours = days - flooredDays;
    leftOverHours = leftOverHours * 24;
    return `${flooredDays}d ${Math.floor(leftOverHours)}h`;
  } else {
    const diffInMinutes = differenceInMinutes(
      formattedEndDate,
      formattedStartDate
    );
    const hours = diffInMinutes / 60;
    const flooredHours = Math.floor(hours);
    let leftOverMinutes = hours - flooredHours;
    leftOverMinutes = leftOverMinutes * 60;
    return `${flooredHours}h ${Math.floor(leftOverMinutes)}m`;
  }
};

export const dateStrToMonthsAgo = (v: DateStr) => {
  const dateAgo = new Date(v);
  const dateNow = new Date();
  const d = differenceInMonths(dateNow, dateAgo);
  return d;
};

export const monthsAgoToDateStr = (
  monthsAgo: number,
  today: Date | undefined = new Date()
): DateStr | undefined => {
  // remove time portion of date
  today.setHours(0, 0, 0, 0);
  const nMonthsAgo = subMonths(today, monthsAgo);
  return getDateStr(nMonthsAgo);
};

export const formatTimeAgo = (v: number | string | null | undefined) => {
  if (!v) {
    return NULL_VALUE;
  }
  const preDate = new Date(v);
  const nowDate = new Date();
  const formattedDuration = formatDuration({
    years: differenceInYears(nowDate, preDate),
    months: differenceInMonths(nowDate, preDate),
    days: differenceInBusinessDays(nowDate, preDate),
    hours: differenceInHours(nowDate, preDate),
    minutes: differenceInMinutes(nowDate, preDate),
    seconds: differenceInSeconds(nowDate, preDate),
  });
  return `${formattedDuration || 'a few seconds '} ago`;
};

export const formatDate = (v?: Date | null) =>
  v ? dateFnsFormat(v, 'MM/dd/yy').toLowerCase() : NULL_VALUE;
export const formatDateMonth = (v?: Date | null) =>
  v ? dateFnsFormat(v, 'MM/yy') : NULL_VALUE;

export const formatTimestampTime = (timestamp: number | null | undefined) =>
  timestamp ? dateFnsFormat(timestamp, 'h:mm:ss') : NULL_VALUE;

export const convertDateStrToDate = (
  v: DateStr | string | null | undefined
) => {
  if (!v) return null;
  if (!isDateStr(v)) return null;
  const [year, month, day] = v.split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const isFutureDateStr = (v?: string) => {
  if (!v) return false;
  if (isDateStr(v)) {
    const currentDate = new Date();
    const givenDate = convertDateStrToDate(v);
    if (givenDate && givenDate > currentDate) return true;
  }
  return false;
};

export const getDebugTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} ${now.getMilliseconds()}`;
};
