import React, { useCallback, useEffect, useReducer, useState } from 'react';
import classNames from 'classnames';
import { RequestMiddleware } from 'graphql-request/build/esm/types';

import {
  Anchor,
  CountChip,
  DirectionalChevron,
  DocumentIcon,
  FlexScroll,
  IconButton,
  ListIcon,
  RemoveIcon,
  Tooltip,
} from '@hcs/design-system';
import { HcsDevToolsPortal } from '@hcs/dev-tools';
import {
  PropertyLocationQueryVariables,
  PropertySpatialSearchListQueryVariables,
  PropertySpatialSearchMapQueryVariables,
  PropertyStateCoreHcQueryVariables,
  PropertyStateMediaQueryVariables,
  PropertyStatePreviewHcQueryVariables,
} from '@hcs/types';
import { formatTimeAgo, formatTimestampTime } from '@hcs/utils';

import { CerberusClient } from '../../api';
import { CerberusApiDocsLink } from '../../features/CerberusApiDocsLink';

import styles from './CerberusDevTool.module.css';

interface DevToolCerberusQuery {
  timestamp: number;
  operationName: string;
  query: string;
  variables: Record<string, unknown>;
}

const dataHcName = 'cerberus-dev-tool';

type State = Record<string, DevToolCerberusQuery[]>;
const requestsReducer = (
  state: State,
  action:
    | { type: 'add'; payload: DevToolCerberusQuery }
    | { type: 'clear'; payload: { operationName: string } }
): State => {
  switch (action.type) {
    case 'add': {
      return {
        ...state,
        [action.payload.operationName]: [
          action.payload,
          ...(state[action.payload.operationName] || []),
        ],
      };
    }
    case 'clear': {
      const newState = { ...state };
      delete newState[action.payload.operationName];
      return newState;
    }
    default: {
      return state;
    }
  }
};

const OPERATION_ROW: Record<
  string,
  (props: DevToolCerberusQuery) => JSX.Element
> = {
  PropertySpatialSearchList: (props: DevToolCerberusQuery) => {
    const variables =
      props.variables as PropertySpatialSearchListQueryVariables;
    return (
      <>
        <div
          className={styles.noWrap}
        >{`Bounds: ${variables.id.bbox?.neLat}-${variables.id.bbox?.neLng}-${variables.id.bbox?.swLat}-${variables.id.bbox?.swLng}`}</div>
        <div
          className={styles.noWrap}
        >{`Sort: ${variables.sort?.field}, ${variables.sort?.order}`}</div>
      </>
    );
  },
  PropertyLocation: (props: DevToolCerberusQuery) => {
    const variables = props.variables as PropertyLocationQueryVariables;
    return (
      <span
        className={styles.noWrap}
      >{`HcAddressId: ${variables.cerberusInput.hcAddressId}`}</span>
    );
  },
  PropertyStateCoreHc: (props: DevToolCerberusQuery) => {
    const variables = props.variables as PropertyStateCoreHcQueryVariables;
    return (
      <span
        className={styles.noWrap}
      >{`HcAddressId: ${variables.cerberusInput.hcAddressId}`}</span>
    );
  },
  PropertyStateMedia: (props: DevToolCerberusQuery) => {
    const variables = props.variables as PropertyStateMediaQueryVariables;
    return (
      <span
        className={styles.noWrap}
      >{`HcAddressId: ${variables.cerberusInput.hcAddressId}`}</span>
    );
  },
  PropertyStatePreviewHc: (props: DevToolCerberusQuery) => {
    const variables = props.variables as PropertyStatePreviewHcQueryVariables;
    return (
      <span
        className={styles.noWrap}
      >{`HcAddressId: ${variables.cerberusInput.hcAddressId}`}</span>
    );
  },
  PropertySpatialSearchMap: (props: DevToolCerberusQuery) => {
    const variables = props.variables as PropertySpatialSearchMapQueryVariables;
    return (
      <span
        className={styles.noWrap}
      >{`Tile: ${variables.id.tile?.x}-${variables.id.tile?.y}-${variables.id.tile?.zoom}`}</span>
    );
  },
};
export const CerberusDevTool = () => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState<string | null>(
    null
  );
  const [cerberusRequests, dispatch] = useReducer(requestsReducer, {});
  const addRequest = useCallback(
    (payload: DevToolCerberusQuery) =>
      dispatch({
        type: 'add',
        payload,
      }),
    [dispatch]
  );
  const clearRequests = useCallback(
    (operationName: string) =>
      dispatch({
        type: 'clear',
        payload: { operationName },
      }),
    [dispatch]
  );
  const captureRequests: RequestMiddleware = useCallback(
    async (request) => {
      const devToolQuery: DevToolCerberusQuery = {
        timestamp: Date.now(),
        ...JSON.parse(request.body?.toString() || ''),
      };
      addRequest(devToolQuery);
      return request;
    },
    [addRequest]
  );
  useEffect(() => {
    CerberusClient.requestConfig.requestMiddleware = captureRequests;
    return () => {
      CerberusClient.requestConfig.requestMiddleware = undefined;
    };
  }, [captureRequests]);
  const queryEntries = Object.entries(cerberusRequests);
  const selectedOperationRequests = cerberusRequests[selectedOperation || ''];
  const SelectedOperationFormatter =
    selectedOperation && selectedOperation in OPERATION_ROW
      ? OPERATION_ROW[selectedOperation]
      : undefined;
  if (!queryEntries.length) {
    return null;
  }
  return (
    <HcsDevToolsPortal
      groups={[
        {
          dataHcName,
          onChangeExpanded: setExpanded,
          className: classNames({ [styles.expanded]: expanded }),
          title: selectedOperationRequests?.length ? undefined : (
            <div className={styles.Group}>Cerberus Queries</div>
          ),
          items: selectedOperationRequests?.length
            ? [
                {
                  type: 'item',
                  item: {
                    content: (
                      <FlexScroll
                        dataHcName={`${dataHcName}-${selectedOperation}`}
                        header={{
                          height: 50,
                          content: (
                            <div className={styles.RequestsHeader}>
                              <IconButton
                                dataHcName={`${dataHcName}-close-button`}
                                icon={
                                  <DirectionalChevron
                                    direction="left"
                                    size="sm"
                                  />
                                }
                                onClick={() => setSelectedOperation(null)}
                              />
                              {selectedOperation}
                              <CountChip
                                dataHcName={`${dataHcName}-${selectedOperation}-count`}
                                count={selectedOperationRequests.length}
                              />
                            </div>
                          ),
                        }}
                      >
                        {selectedOperationRequests?.map((request, i) => (
                          <div
                            className={styles.RequestRow}
                            key={`request-${i}`}
                          >
                            <div>
                              <div className={styles.Time}>
                                {formatTimestampTime(request.timestamp)}
                              </div>
                              <div>
                                <Tooltip
                                  dataHcName={`${dataHcName}-vars-tooltip`}
                                  trigger={
                                    SelectedOperationFormatter ? (
                                      <SelectedOperationFormatter
                                        {...request}
                                      />
                                    ) : (
                                      selectedOperation
                                    )
                                  }
                                  description={JSON.stringify(
                                    request.variables
                                  )}
                                />
                              </div>
                            </div>
                            <div>
                              <Tooltip
                                dataHcName={`${dataHcName}-log`}
                                trigger={
                                  <Anchor
                                    dataHcName={`${dataHcName}-log-btn`}
                                    onClick={() =>
                                      console.log(
                                        `${request.operationName}:`,
                                        request
                                      )
                                    }
                                  >
                                    <ListIcon color="primary-10" />
                                  </Anchor>
                                }
                                description="Log Query To JS Console"
                              />
                              <CerberusApiDocsLink
                                query={request.query}
                                variables={request.variables}
                              >
                                <Tooltip
                                  dataHcName={`${dataHcName}-docs-link`}
                                  trigger={<DocumentIcon />}
                                  description="See Query in Cerberus API Docs"
                                />
                              </CerberusApiDocsLink>
                            </div>
                          </div>
                        ))}
                      </FlexScroll>
                    ),
                  },
                },
              ]
            : queryEntries.map(([operationName, queries]) => {
                return {
                  type: 'item',
                  item: {
                    dataHcName: operationName,
                    className: styles.OperationRow,
                    onClick: () => setSelectedOperation(operationName),
                    content: (
                      <>
                        <div className={styles.OperationLabel}>
                          {operationName}
                          <div className={styles.Time}>
                            Last Query {formatTimeAgo(queries[0]?.timestamp)}
                          </div>
                        </div>
                        <div className={styles.Actions}>
                          <Tooltip
                            dataHcName={`${dataHcName}-clear-tooltip`}
                            trigger={
                              <IconButton
                                dataHcName={`${dataHcName}-clear`}
                                onClick={() => {
                                  clearRequests(operationName);
                                  setSelectedOperation(null);
                                  setExpanded(false);
                                }}
                                icon={<RemoveIcon color="error-10" />}
                              />
                            }
                            description="Clear Queries"
                          />
                          <CountChip
                            dataHcName={`${dataHcName}-${operationName}-count`}
                            count={queries.length}
                          />
                        </div>
                      </>
                    ),
                  },
                };
              }),
        },
      ]}
    />
  );
};
