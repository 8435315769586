import { useEffect } from 'react';

import { useComponentInternalId } from '@hcs/hooks';
import { EngagementEventData } from '@hcs/types';

import { useEngagementTrackingEventDataSlice } from './useEngagementTrackingEventDataSlice';

export const useRegisterEngagementTrackingData = (
  eventDataId: string,
  eventData: EngagementEventData | undefined
) => {
  const componentId = useComponentInternalId();
  const {
    actions: { registerEventData, deregisterEventData },
  } = useEngagementTrackingEventDataSlice();
  useEffect(() => {
    if (eventData) {
      registerEventData({
        componentId,
        eventDataId,
        eventData,
      });
    }
    return () => {
      deregisterEventData({
        componentId,
        eventDataId,
      });
    };
  }, [
    eventData,
    componentId,
    eventDataId,
    registerEventData,
    deregisterEventData,
  ]);
};
