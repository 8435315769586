import React, { useMemo } from 'react';

import { useUserIsSelfService } from '@hcs/auth';
import { AppAccessSubNav, AppAccessSubNavChildConfig } from '@hcs/authz';
import {
  useExperienceFlag,
  useExperienceFlagSystem,
} from '@hcs/experience-flags';
import { VIEW_PATHS_USER_ADMIN } from '@hcs/hc-products';
import { AppSlugs, CapabilitiesCheckParams } from '@hcs/types';

const API_KEYS_CAPABILITIES_PARAMS: CapabilitiesCheckParams = {
  capabilityKeys: ['create-api-key', 'create-test-api-key'],
  operation: 'OR',
};

const dataHcName = 'user-admin-nav';

export const UserAdminNav = () => {
  const pexpRedesignExpOption = useExperienceFlagSystem('PEXP_REDESIGN');
  const pexpReportApi = useExperienceFlag('PEXP_REPORT_API');
  const { data: isSelfService } = useUserIsSelfService();
  const subNavItemsConfig = useMemo(() => {
    const userSettingsChildren: AppAccessSubNavChildConfig[] = [
      {
        label: 'Usage',
        navigateTo: VIEW_PATHS_USER_ADMIN.USER_USAGE,
        dataHcName: 'usage',
      },
      {
        label: 'API Keys',
        navigateTo: VIEW_PATHS_USER_ADMIN.API_KEYS,
        dataHcName: 'api-keys',
        appSlug: AppSlugs.DataExplorer,
        capabiliesCheckParams: API_KEYS_CAPABILITIES_PARAMS,
      },
      {
        label: 'API Subscriptions',
        navigateTo: VIEW_PATHS_USER_ADMIN.API_SUBSCRIPTIONS,
        dataHcName: 'api-subscriptions',
        appSlug: AppSlugs.DataExplorer,
        capabiliesCheckParams: API_KEYS_CAPABILITIES_PARAMS,
      },
      {
        label: 'Contact Info',
        navigateTo: VIEW_PATHS_USER_ADMIN.USER_CONTACT_INFO,
        dataHcName: 'contact-info',
      },
      {
        label: 'Change Password',
        navigateTo: VIEW_PATHS_USER_ADMIN.USER_CHANGE_PASSWORD,
        dataHcName: 'change-password',
      },
    ];
    if (isSelfService && pexpReportApi) {
      userSettingsChildren.push({
        label: 'Report Settings',
        navigateTo: VIEW_PATHS_USER_ADMIN.REPORT_PREFERENCES,
        dataHcName: 'report-preferences',
        appSlug: AppSlugs.PropertyExplorer,
      });
    }
    if (pexpRedesignExpOption) {
      userSettingsChildren.push({
        label: 'User Experience',
        navigateTo: VIEW_PATHS_USER_ADMIN.USER_EXPERIENCE_PREFERENCES,
        dataHcName: 'user-experience-preferences',
        appSlug: AppSlugs.PropertyExplorer,
      });
    }

    return [
      {
        label: 'User Settings',
        navigateTo: VIEW_PATHS_USER_ADMIN.USER_USAGE,
        dataHcName: 'user-settings-header',
        children: userSettingsChildren,
      },
    ];
  }, [pexpRedesignExpOption, isSelfService, pexpReportApi]);

  return (
    <AppAccessSubNav
      dataHcName={dataHcName}
      subNavItemsConfig={subNavItemsConfig}
    />
  );
};
