import React from 'react';
import classNames from 'classnames';

import { CompFields, CompTypes } from '@hcs/types';
import { CompFilters, CompsFiltersPaths, ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import styles from './CompFilterClearButton.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  compField: keyof CompFilters;
}
const dataHcName = 'comp-filter-clear-button';
export const CompFilterClearButton = ({
  reportId,
  className,
  compField,
  compType,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const filterValue =
    compField === CompFields.distance
      ? filterDocument?.data.filters?.[CompFields.distance]?.absoluteValue
      : filterDocument?.data.filters?.[compField];

  if (!filterValue || !reportPermissions?.isEditable) {
    return null;
  }

  const handleClick = () => {
    if (filterDocument) {
      const path: CompsFiltersPaths = `/data/filters/${compField}`;
      documentPatchMutation.mutate({
        reportId,
        document: filterDocument,
        operations: [
          {
            op: 'remove',
            path,
          },
        ],
      });
    }
  };
  return (
    <button
      data-hc-name={dataHcName}
      className={classNames(styles.CompFilterClearButton, className)}
      onClick={handleClick}
    >
      Clear Filter
    </button>
  );
};
