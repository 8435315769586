import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  AllMsaZipDetailsDocument,
  AllMsaZipDetailsQuery,
  AllMsaZipDetailsQueryVariables,
} from '@hcs/types';

import { allMsaZipDetailsQueryToCerberusStatsZip } from '../utils/cerberus-stats.utils';

export const QUERY_KEY_CERBERUS_STATS_ALL_MSA_ZIPS =
  'cerberus-stats-all-msa-zips';
export const useCerberusStatsAllMsaZips = ({
  msaId,
}: AllMsaZipDetailsQueryVariables) => {
  return useQuery(
    [QUERY_KEY_CERBERUS_STATS_ALL_MSA_ZIPS, msaId],
    async () => {
      const response = await CerberusApi.fetchQuery<
        AllMsaZipDetailsQuery,
        AllMsaZipDetailsQueryVariables
      >(AllMsaZipDetailsDocument, { msaId });

      return allMsaZipDetailsQueryToCerberusStatsZip(response);
    },
    {
      enabled: msaId !== null,
    }
  );
};
