import React, { useMemo, useState } from 'react';

import { AnalysisStatus } from '@hcs/data-explorer';
import { AddressesInput, DexpShoppingCartLauncher } from '@hcs/data-explorer';
import { useSubscribeToDexpJobEvents } from '@hcs/data-explorer';
import { IconButton } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { DataExplorerIcon } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import {
  CompsListTypes,
  CompTypes,
  DexpEvent,
  ReportFeatures,
  ReportId,
} from '@hcs/types';

import { useReport, useReportPermissions } from '../../hooks';
import { useCompsList } from '../../hooks/useCompsList';

export const REPORT_FEATURES_COMP_LIST_DEXP_ANALYSIS_SALE = [
  ReportFeatures.DexpAnalysisComps,
];
export const REPORT_FEATURES_COMP_LIST_DEXP_ANALYSIS_RENTAL = [
  ReportFeatures.DexpAnalysisRentalComps,
];
interface Props {
  reportId: ReportId;
  compType: CompTypes;
  compsListType: CompsListTypes;
  className?: string;
}
const dataHcName = 'report-comps-list-dexp-analysis';
export const ReportCompsListDexpAnalysis = ({
  reportId,
  compType,
  compsListType,
  className,
}: Props) => {
  const [dexpEvent, setDexpEvent] = useState<DexpEvent | undefined>();
  useSubscribeToDexpJobEvents((evt) => {
    if (evt.job.id === dexpEvent?.job.id) {
      setDexpEvent(evt);
    }
  });
  const {
    state: {
      compsListQuery: { data: compsList },
    },
  } = useCompsList({
    reportId,
    compType,
    compsListType,
  });
  const {
    data: { isEditable },
  } = useReportPermissions(reportId);
  const { data: report } = useReport(reportId);
  const addressesInput = useMemo(() => {
    const addressesInput: AddressesInput = [];
    compsList?.forEach((compSchema) => {
      const hcAddressId = compSchema.propertyState.hcAddressId;
      if (hcAddressId) {
        addressesInput.push({
          hcAddressId,
          clientFileId: report?.clientID || undefined,
        });
      }
    });
    return addressesInput;
  }, [compsList]);
  return (
    <ExperienceFlag
      experienceFlagId="DEXP_TEMPLATES"
      now={null}
      next={
        <>
          {addressesInput.length ? (
            <DexpShoppingCartLauncher
              data-hc-name={dataHcName}
              addressesInput={addressesInput}
              theme={{ Trigger: className }}
              onSuccess={(v) => setDexpEvent(v)}
              trigger={
                <Tooltip
                  dataHcName={`${dataHcName}-tooltip`}
                  trigger={
                    <IconButton
                      disabled={!isEditable}
                      dataHcName={`${dataHcName}-refresh-report-btn`}
                      icon={<DataExplorerIcon />}
                      stopPropagation={false}
                    />
                  }
                  label={`Create a Data Explorer Analysis for ${addressesInput.length} Comps`}
                />
              }
            />
          ) : null}
          {dexpEvent && (
            <AnalysisStatus
              statusDisplay={dexpEvent.job.statusDisplay}
              statusMessage={dexpEvent.job.statusMessage}
              outputFile={dexpEvent.job.outputFile}
              status={dexpEvent.job.status}
            />
          )}
        </>
      }
    />
  );
};
