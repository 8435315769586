import { useMemo } from 'react';

import { BuyBoxListItem } from '@hcs/types';

import { useBuyBoxList } from './useBuyBoxList';

export type BuyBoxListItemMap = Record<BuyBoxListItem['id'], BuyBoxListItem>;
export const useBuyBoxMap = (): BuyBoxListItemMap => {
  const { data: buyBoxList } = useBuyBoxList();
  const map = useMemo(() => {
    return buyBoxList.reduce<BuyBoxListItemMap>((accum, buyBoxListItem) => {
      accum[buyBoxListItem.id] = buyBoxListItem;
      return accum;
    }, {});
  }, [buyBoxList]);
  return map;
};
