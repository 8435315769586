import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import {
  FeatureFlagAdminConfig,
  FeatureFlagCreatePayload,
  FeatureFlagOrgTogglePayload,
  FeatureFlags,
  FeatureFlagUpdatePayload,
} from '@hcs/types';
import { HUELL_URL } from '@hcs/urls';

export const HuellFeatureFlagsAdminApi = {
  fetchFeatureFlagConfigs: async () => {
    const response = await AxiosAccessTokenClientEhrm.get<
      FeatureFlagAdminConfig[]
    >(`${HUELL_URL}/emporium/feature_flag/`);
    return camelcaseKeys(response.data, { deep: true });
  },
  createFeatureFlag: async (payload: FeatureFlagCreatePayload) => {
    const response =
      await AxiosAccessTokenClientEhrm.post<FeatureFlagAdminConfig>(
        `${HUELL_URL}/emporium/feature_flag/`,
        payload
      );
    return camelcaseKeys(response.data, { deep: true });
  },
  updateFeatureFlag: async ({
    featureFlag,
    payload,
  }: {
    featureFlag: FeatureFlags;
    payload: FeatureFlagUpdatePayload;
  }) => {
    const response =
      await AxiosAccessTokenClientEhrm.patch<FeatureFlagAdminConfig>(
        `${HUELL_URL}/emporium/feature_flag/${featureFlag}`,
        payload
      );
    return camelcaseKeys(response.data, { deep: true });
  },
  deleteFeatureFlag: async (
    // Only flags that are not in `FeatureFlags` can be deleted from the UI
    featureFlagKey: string
  ) => {
    return await AxiosAccessTokenClientEhrm.delete<FeatureFlagAdminConfig>(
      `${HUELL_URL}/emporium/feature_flag/${featureFlagKey}`
    );
  },
  addFeatureFlagForOrg: async ({
    featureFlagConfig,
    orgId,
  }: FeatureFlagOrgTogglePayload) => {
    // Abstracting away some of the API complexity in this layer
    // The exact endpoint that must be called depends on whether
    // the feature flag is enabled by default or not
    const urlPath = featureFlagConfig.enabledByDefault
      ? 'unblock_org'
      : 'enable_org';
    const response =
      await AxiosAccessTokenClientEhrm.post<FeatureFlagAdminConfig>(
        `${HUELL_URL}/emporium/manage_feature_flag/${urlPath}`,
        snakecaseKeys({ orgId, key: featureFlagConfig.key }, { deep: true })
      );
    return camelcaseKeys(response.data, { deep: true });
  },
  removeFeatureFlagForOrg: async ({
    featureFlagConfig,
    orgId,
  }: FeatureFlagOrgTogglePayload) => {
    // Abstracting away some of the API complexity in this layer
    // The exact endpoint that must be called depends on whether
    // the feature flag is enabled by default or not
    const urlPath = featureFlagConfig.enabledByDefault
      ? 'block_org'
      : 'disable_org';
    const response =
      await AxiosAccessTokenClientEhrm.post<FeatureFlagAdminConfig>(
        `${HUELL_URL}/emporium/manage_feature_flag/${urlPath}`,
        snakecaseKeys({ orgId, key: featureFlagConfig.key }, { deep: true })
      );
    return camelcaseKeys(response.data, { deep: true });
  },
};
