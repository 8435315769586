import { useMemo } from 'react';

import { useAccount, useCheckAppAccess } from '@hcs/auth';
import { useUserIsOrgAdmin } from '@hcs/auth';
import { AppSlugs, Roles } from '@hcs/types';

export const useCurrentOmPermissions = () => {
  const { data: hasOmAccess } = useCheckAppAccess(AppSlugs.AgileSuite);
  const { data: isOrgAdmin } = useUserIsOrgAdmin();
  const accountQuery = useAccount();
  const data = useMemo(() => {
    return accountQuery.isSuccess
      ? {
          createOrders:
            !!hasOmAccess &&
            (!!isOrgAdmin ||
              !!accountQuery.data?.user.roles.find(
                (p) => p.name === Roles.OmCreate
              )),
          viewAllOrders:
            !!hasOmAccess &&
            (!!isOrgAdmin ||
              !!accountQuery.data?.user.roles.find(
                (p) => p.name === Roles.OmView
              )),
        }
      : undefined;
  }, [accountQuery.data, accountQuery.isSuccess, hasOmAccess, isOrgAdmin]);
  return {
    ...accountQuery,
    data,
  };
};
