import { PropertyStateFields } from '@hcs/types';

import { PROPERTY_STATE_FIELD_CONFIGS } from '../configs';
/**
 * Returns PropertyStateConfigs for fields that have rental/sale equivalents
 */
export const useCompPropertyStateConfigs = (showRentalValue?: boolean) => {
  if (showRentalValue) {
    return {
      [PropertyStateFields.currentPrice]:
        PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentPriceRental],
      [PropertyStateFields.currentPricePerSqFt]:
        PROPERTY_STATE_FIELD_CONFIGS[
          PropertyStateFields.currentPricePerSqFtRental
        ],
      [PropertyStateFields.currentStatus]:
        PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentStatusRental],
      [PropertyStateFields.currentStatusDate]:
        PROPERTY_STATE_FIELD_CONFIGS[
          PropertyStateFields.currentStatusDateRental
        ],
    };
  } else {
    return {
      [PropertyStateFields.currentPrice]:
        PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentPrice],
      [PropertyStateFields.currentPricePerSqFt]:
        PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentPricePerSqFt],
      [PropertyStateFields.currentStatus]:
        PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentStatus],
      [PropertyStateFields.currentStatusDate]:
        PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentStatusDate],
    };
  }
};
