import React, { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './SubNav.module.css';

export interface ChildItemConfig {
  navigateTo: string;
  label: ReactNode;
  labelHelper?: string;
  dataHcName: string;
  dataHcEventSection?: string;
  disabled?: boolean;
  end?: boolean;
}
export interface SubNavItemConfig {
  navigateTo: string;
  label: ReactNode;
  dataHcName: string;
  dataHcEventSection?: string;
  // Allow for conditional child items
  children: (ChildItemConfig | null | false | undefined)[];
  end?: boolean;
}

export interface SubNavProps {
  dataHcName: string;
  className?: string;
  // Allow for conditional subnav items
  subNavItemsConfig: (SubNavItemConfig | null | false | undefined)[];
}

export const SubNav = ({
  dataHcName,
  className,
  subNavItemsConfig,
}: SubNavProps) => {
  const [activeHeaderDataHcName, setActiveHeaderDataHcName] = useState<
    string | null
  >(null);
  // The percent of the track that the track indicator should be from the top
  const [trackPosition, setTrackPosition] = useState<number | null>(null);

  const handleSetTrackPosition = (childIdx: number, childrenLength: number) => {
    const position = (childIdx / childrenLength) * 100;
    setTrackPosition(position);
  };

  return (
    <div
      className={classNames(styles.Container, className)}
      data-hc-name={dataHcName}
    >
      {subNavItemsConfig.map((item) => {
        if (!item) return null;
        const {
          label,
          navigateTo,
          dataHcName: headerDataHcName,
          dataHcEventSection,
          end,
          children,
        } = item;
        return (
          <div key={headerDataHcName}>
            <NavLink
              to={navigateTo}
              data-hc-name={headerDataHcName}
              data-hc-event-section={dataHcEventSection}
              end={end}
              className={classNames(styles.Header, {
                [styles.active]: headerDataHcName === activeHeaderDataHcName,
              })}
            >
              {label}
            </NavLink>
            <div className={styles.NavContainer}>
              <div className={styles.TrackContainer}>
                <div className={styles.Track}>
                  {activeHeaderDataHcName === headerDataHcName &&
                    trackPosition !== null && (
                      <div
                        data-hc-name={`${dataHcName}-indicator`}
                        className={styles.Indicator}
                        style={{
                          top: `${trackPosition}%`,
                          height: `${100 / children.length}%`,
                        }}
                      />
                    )}
                </div>
              </div>
              <div className={styles.LinksContainer}>
                {children.map((child, childIdx) => {
                  if (!child) return null;
                  const {
                    label: childLabel,
                    labelHelper: childLabelHelper,
                    navigateTo: childNavigateTo,
                    end: childEnd,
                    dataHcName: childDataHcName,
                    dataHcEventSection: dataHcEventSectionChild,
                    disabled: childDisabled,
                  } = child;
                  return (
                    <NavLink
                      key={childDataHcName}
                      onClick={
                        childDisabled
                          ? (e) => {
                              e.preventDefault();
                            }
                          : undefined
                      }
                      to={childNavigateTo}
                      end={childEnd}
                      data-hc-name={childDataHcName}
                      data-hc-event-section={dataHcEventSectionChild}
                      className={({ isActive }) => {
                        if (isActive) {
                          // setTimeout to avoid forcing a re-render right away
                          setTimeout(() => {
                            setActiveHeaderDataHcName(headerDataHcName);
                            handleSetTrackPosition(childIdx, children.length);
                          });
                        }

                        return classNames(styles.Link, {
                          [styles.active]: isActive,
                          [styles.disabled]: !!childDisabled && !isActive,
                        });
                      }}
                    >
                      {childLabel}
                      {childLabelHelper && (
                        <div
                          data-hc-name={`${dataHcName}-sub-label`}
                          className={styles.LabelHelper}
                        >
                          {childLabelHelper}
                        </div>
                      )}
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
