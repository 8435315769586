import { useMemo } from 'react';

import { LegacyRole, Roles } from '@hcs/types';

import { useSystemRoles } from '../hooks/useSystemRoles';

type RolesByType = Record<Roles, LegacyRole>;

export const useSystemRolesByType = () => {
  const rolesQuery = useSystemRoles();
  return useMemo(() => {
    // Use Partial to create the lookup, then cast to full RolesByType
    const data = rolesQuery.data?.objects.reduce<Partial<RolesByType>>(
      (a, b) => {
        a[b.name] = b;
        return a;
      },
      {}
    ) as RolesByType | undefined;
    return {
      ...rolesQuery,
      data,
    };
  }, [rolesQuery]);
};
