import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { DropdownTheme } from './Dropdown';

import styles from './Dropdown.module.css';

interface Props {
  label: string | ReactNode;
  setRef: (el: HTMLDivElement | null) => void;
  selected: boolean;
  focused: boolean;
  dataHcName: string;
  handleSelect: VoidFunction;
  theme?: DropdownTheme;
}

export const DropdownOption = ({
  label,
  setRef,
  selected,
  focused,
  dataHcName,
  handleSelect,
  theme,
}: Props) => {
  return (
    <div
      tabIndex={0}
      ref={(el) => setRef(el)}
      data-hc-name={dataHcName}
      onClick={(e) => {
        e.stopPropagation();
        handleSelect();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSelect();
        }
      }}
      className={classNames(styles.Option, theme?.Option, {
        [theme?.selected || '']: selected,
        [styles.OptionLightHighlight]: selected,
        [styles.OptionDarkHighlight]: focused && !selected,
      })}
    >
      {label}
    </div>
  );
};
