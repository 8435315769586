import React from 'react';
import classNames from 'classnames';

import { AvmFactor } from '@hcs/types';

import {
  AvmBreakdownAvmTypeBarChart,
  AvmBreakdownAvmTypeData,
} from './AvmBreakdownAvmTypeBarChart';

import styles from './AvmBreakdownAvmTypeChart.module.css';

const dataHcName = 'avm-breakdown-avm-type-chart';

interface Props {
  title: string;
  avmFactor: AvmFactor;
  barData: AvmBreakdownAvmTypeData[];
  valueLeftAppendStr: string;
  valueRightAppendStr?: string;
}

export const AvmBreakdownAvmTypeChart = ({
  barData,
  title,
  avmFactor,
  valueLeftAppendStr,
  valueRightAppendStr,
}: Props) => {
  const isPositive = avmFactor.value >= 0;
  const titleParts = avmFactor.valueDescription.split(' ');
  return (
    <div data-hc-name={dataHcName} className={styles.ChartContainer}>
      <div className={styles.TitleBox}>
        <div
          data-hc-name={`${dataHcName}-main-title`}
          className={styles.MainTitle}
        >
          {title}
        </div>
        <div
          data-hc-name={`${dataHcName}-value-title-box`}
          className={classNames(styles.ValueTitleBox, {
            [styles.TextBlue || '']: isPositive,
            [styles.TextRed || '']: !isPositive,
          })}
        >
          <div className={styles.TitleOne}>
            {titleParts?.slice(0, 2)?.join(' ')}
          </div>
          <div className={styles.TitleTwo}>
            {titleParts?.slice(2)?.join(' ')}
          </div>
        </div>
        <div
          className={styles.Description}
          data-hc-name={`${dataHcName}-description`}
        >
          {avmFactor.comparisonDescription}
        </div>
      </div>
      <AvmBreakdownAvmTypeBarChart
        isPartial={avmFactor.__typeName === 'BathroomsAvmFactor'}
        data={barData}
        valueLeftAppendStr={valueLeftAppendStr}
        valueRightAppendStr={valueRightAppendStr}
      />
    </div>
  );
};
