import { CerberusStatsFields } from '../cerberus-stats/CerberusStatsFields.types';

import { CerberusStatsMsaCalculatedFields } from './CerberusStatsCalculatedFields.types';
import {
  CerberusStatsHcriCore,
  CerberusStatsHpiCore,
  CerberusStatsMsaCore,
  CerberusStatsPopulationCore,
  CerberusStatsRpiCore,
  CerberusStatsZipCore,
  CerberusStatsZipMarketQuality,
  ListingStats,
} from './CerberusStatsFragments.types';

export enum CerberusStatsType {
  Msa = 'Msa',
  Zip = 'Zip',
  ListingStats = 'ListingStats',
  RentalListingStats = 'RentalListingStats',
}

export interface CerberusStatsMsa extends CerberusStatsMsaCore {
  type: CerberusStatsType.Msa;
  id: string;
  displayName: string | null | undefined;
  hpi?: CerberusStatsHpiCore | null;
  hcri?: CerberusStatsHcriCore | null;
  rpi?: CerberusStatsRpiCore | null;
  listingStats?: ListingStats | null;
  rentalListingStats?: ListingStats | null;
  populationStats?: CerberusStatsPopulationCore | null;
  calculatedFields: CerberusStatsMsaCalculatedFields;
}

export interface CerberusStatsZip extends CerberusStatsZipCore {
  type: CerberusStatsType.Zip;
  id: string;
  displayName: string | null | undefined;
  hpi?: CerberusStatsHpiCore | null;
  hcri?: CerberusStatsHcriCore | null;
  rpi?: CerberusStatsRpiCore | null;
  listingStats?: ListingStats | null;
  marketQuality?: CerberusStatsZipMarketQuality | null;
}

export interface CerberusStatsListingStats {
  type: CerberusStatsType.ListingStats;
  listingStats?: ListingStats | null;
}

export interface CerberusStatsRentalListingStats {
  type: CerberusStatsType.RentalListingStats;
  rentalListingStats?: ListingStats | null;
}

export type MarketStatsSearchValue = string | null;

export interface MarketStateSliceState {
  msaSearch: MarketStatsSearchValue;
  zipSearch: MarketStatsSearchValue;
}

export interface MarketStatePartialAppState {
  marketStateSearch: MarketStateSliceState;
}

export type CerberusStats =
  | CerberusStatsMsa
  | CerberusStatsZip
  | CerberusStatsListingStats
  | CerberusStatsRentalListingStats
  | null
  | undefined;

export type CerberusStatsFlat = Partial<{
  [CerberusStatsFields.daysOnMarketMedian]: ListingStats['daysOnMarketMedian'];
  [CerberusStatsFields.daysOnMarketMedianRental]: ListingStats['daysOnMarketMedian'];
  [CerberusStatsFields.daysOnMarketMedianMovingAvg]: ListingStats['daysOnMarketMedianMovingAvg'];
  [CerberusStatsFields.daysOnMarketMedianMovingAvgRental]: ListingStats['daysOnMarketMedianMovingAvg'];
  [CerberusStatsFields.daysOnMarketNotContractMedian]: ListingStats['daysOnMarketNotContractMedian'];
  [CerberusStatsFields.daysOnMarketNotContractMedianRental]: ListingStats['daysOnMarketNotContractMedian'];
  [CerberusStatsFields.daysOnMarketNotContractMedianMovingAvg]: ListingStats['daysOnMarketNotContractMedianMovingAvg'];
  [CerberusStatsFields.daysOnMarketNotContractMedianMovingAvgRental]: ListingStats['daysOnMarketNotContractMedianMovingAvg'];
  [CerberusStatsFields.daysToCloseMedian]: ListingStats['daysToCloseMedian'];
  [CerberusStatsFields.daysToCloseMedianRental]: ListingStats['daysToCloseMedian'];
  [CerberusStatsFields.daysToCloseMedianMovingAvg]: ListingStats['daysToCloseMedianMovingAvg'];
  [CerberusStatsFields.daysToCloseMedianMovingAvgRental]: ListingStats['daysToCloseMedianMovingAvg'];
  [CerberusStatsFields.daysToContractMedian]: ListingStats['daysToContractMedian'];
  [CerberusStatsFields.daysToContractMedianRental]: ListingStats['daysToContractMedian'];
  [CerberusStatsFields.daysToContractMedianMovingAvg]: ListingStats['daysToContractMedianMovingAvg'];
  [CerberusStatsFields.daysToContractMedianMovingAvgRental]: ListingStats['daysToContractMedianMovingAvg'];
  [CerberusStatsFields.daysToFirstPriceDropMedian]: ListingStats['daysToFirstPriceDropMedian'];
  [CerberusStatsFields.daysToFirstPriceDropMedianRental]: ListingStats['daysToFirstPriceDropMedian'];
  [CerberusStatsFields.daysToFirstPriceDropMedianMovingAvg]: ListingStats['daysToFirstPriceDropMedianMovingAvg'];
  [CerberusStatsFields.daysToFirstPriceDropMedianMovingAvgRental]: ListingStats['daysToFirstPriceDropMedianMovingAvg'];
  [CerberusStatsFields.displayName]: string | null | undefined;
  [CerberusStatsFields.grossYieldMedian]: CerberusStatsHcriCore['grossYieldMedian'];
  [CerberusStatsFields.hpiLatestMonth]: CerberusStatsHpiCore['latestMonth'];
  [CerberusStatsFields.listingsClosedCount]: ListingStats['listingsClosedCount'];
  [CerberusStatsFields.listingsClosedCountRental]: ListingStats['listingsClosedCount'];
  [CerberusStatsFields.listingsClosedCountMovingAvg]: ListingStats['listingsClosedCountMovingAvg'];
  [CerberusStatsFields.listingsClosedCountMovingAvgRental]: ListingStats['listingsClosedCountMovingAvg'];
  [CerberusStatsFields.listingsNewCount]: ListingStats['listingsNewCount'];
  [CerberusStatsFields.listingsNewCountRental]: ListingStats['listingsNewCount'];
  [CerberusStatsFields.listingsNewCountMovingAvg]: ListingStats['listingsNewCountMovingAvg'];
  [CerberusStatsFields.listingsNewCountMovingAvgRental]: ListingStats['listingsNewCountMovingAvg'];
  [CerberusStatsFields.listingsOnMarketCount]: ListingStats['listingsOnMarketCount'];
  [CerberusStatsFields.listingsOnMarketCountRental]: ListingStats['listingsOnMarketCount'];
  [CerberusStatsFields.listingsOnMarketCountMovingAvg]: ListingStats['listingsOnMarketCountMovingAvg'];
  [CerberusStatsFields.listingsOnMarketCountMovingAvgRental]: ListingStats['listingsOnMarketCountMovingAvg'];
  [CerberusStatsFields.listingsPriceChangeCount]: ListingStats['listingsPriceChangeCount'];
  [CerberusStatsFields.listingsPriceChangeCountRental]: ListingStats['listingsPriceChangeCount'];
  [CerberusStatsFields.listingsPriceChangeCountMovingAvg]: ListingStats['listingsPriceChangeCountMovingAvg'];
  [CerberusStatsFields.listingsPriceChangeCountMovingAvgRental]: ListingStats['listingsPriceChangeCountMovingAvg'];
  [CerberusStatsFields.listingsPriceDropCount]: ListingStats['listingsPriceDropCount'];
  [CerberusStatsFields.listingsPriceDropCountRental]: ListingStats['listingsPriceDropCount'];
  [CerberusStatsFields.listingsPriceDropCountMovingAvg]: ListingStats['listingsPriceDropCountMovingAvg'];
  [CerberusStatsFields.listingsPriceDropCountMovingAvgRental]: ListingStats['listingsPriceDropCountMovingAvg'];
  [CerberusStatsFields.listingsPriceDropPercent]: ListingStats['listingsPriceDropPercent'];
  [CerberusStatsFields.listingsPriceDropPercentRental]: ListingStats['listingsPriceDropPercent'];
  [CerberusStatsFields.listingsPriceDropPercentMovingAvg]: ListingStats['listingsPriceDropPercentMovingAvg'];
  [CerberusStatsFields.listingsPriceDropPercentMovingAvgRental]: ListingStats['listingsPriceDropPercentMovingAvg'];
  [CerberusStatsFields.listingsRemovedCount]: ListingStats['listingsRemovedCount'];
  [CerberusStatsFields.listingsRemovedCountRental]: ListingStats['listingsRemovedCount'];
  [CerberusStatsFields.listingsRemovedCountMovingAvg]: ListingStats['listingsRemovedCountMovingAvg'];
  [CerberusStatsFields.listingsRemovedCountMovingAvgRental]: ListingStats['listingsRemovedCountMovingAvg'];
  [CerberusStatsFields.listingsRemovedPercent]: ListingStats['listingsRemovedPercent'];
  [CerberusStatsFields.listingsRemovedPercentRental]: ListingStats['listingsRemovedPercent'];
  [CerberusStatsFields.listingsRemovedPercentMovingAvg]: ListingStats['listingsRemovedPercentMovingAvg'];
  [CerberusStatsFields.listingsRemovedPercentMovingAvgRental]: ListingStats['listingsRemovedPercentMovingAvg'];
  [CerberusStatsFields.listingsUnderContractCount]: ListingStats['listingsUnderContractCount'];
  [CerberusStatsFields.listingsUnderContractCountRental]: ListingStats['listingsUnderContractCount'];
  [CerberusStatsFields.listingsUnderContractCountMovingAvg]: ListingStats['listingsUnderContractCountMovingAvg'];
  [CerberusStatsFields.listingsUnderContractCountMovingAvgRental]: ListingStats['listingsUnderContractCountMovingAvg'];
  [CerberusStatsFields.listingsUnderContractPercent]: ListingStats['listingsUnderContractPercent'];
  [CerberusStatsFields.listingsUnderContractPercentRental]: ListingStats['listingsUnderContractPercent'];
  [CerberusStatsFields.listingsUnderContractPercentMovingAvg]: ListingStats['listingsUnderContractPercentMovingAvg'];
  [CerberusStatsFields.listingsUnderContractPercentMovingAvgRental]: ListingStats['listingsUnderContractPercentMovingAvg'];
  [CerberusStatsFields.listingStatsEventDate]: ListingStats['eventDate'];
  [CerberusStatsFields.listingStatsEventDateRental]: ListingStats['eventDate'];
  [CerberusStatsFields.marketGrade]: CerberusStatsZipMarketQuality['grade'];
  [CerberusStatsFields.monthsOfSupplyMedian]: ListingStats['monthsOfSupplyMedian'];
  [CerberusStatsFields.monthsOfSupplyMedianRental]: ListingStats['monthsOfSupplyMedian'];
  [CerberusStatsFields.monthsOfSupplyMedianMovingAvg]: ListingStats['monthsOfSupplyMedianMovingAvg'];
  [CerberusStatsFields.monthsOfSupplyMedianMovingAvgRental]: ListingStats['monthsOfSupplyMedianMovingAvg'];
  [CerberusStatsFields.netPopulationGrowth]: CerberusStatsMsaCalculatedFields['netPopulationGrowth'];
  [CerberusStatsFields.populationLatestMonth]: CerberusStatsPopulationCore['month'];
  [CerberusStatsFields.priceClosedMedian]: ListingStats['priceClosedMedian'];
  [CerberusStatsFields.priceClosedMedianRental]: ListingStats['priceClosedMedian'];
  [CerberusStatsFields.priceClosedMedianMovingAvg]: ListingStats['priceClosedMedianMovingAvg'];
  [CerberusStatsFields.priceClosedMedianMovingAvgRental]: ListingStats['priceClosedMedianMovingAvg'];
  [CerberusStatsFields.priceNewListMedian]: ListingStats['priceNewListMedian'];
  [CerberusStatsFields.priceNewListMedianRental]: ListingStats['priceNewListMedian'];
  [CerberusStatsFields.priceNewListMedianMovingAvg]: ListingStats['priceNewListMedianMovingAvg'];
  [CerberusStatsFields.priceNewListMedianMovingAvgRental]: ListingStats['priceNewListMedianMovingAvg'];
  [CerberusStatsFields.priceOnMarketMedian]: ListingStats['priceOnMarketMedian'];
  [CerberusStatsFields.priceOnMarketMedianRental]: ListingStats['priceOnMarketMedian'];
  [CerberusStatsFields.priceOnMarketMedianMovingAvg]: ListingStats['priceOnMarketMedianMovingAvg'];
  [CerberusStatsFields.priceOnMarketMedianMovingAvgRental]: ListingStats['priceOnMarketMedianMovingAvg'];
  [CerberusStatsFields.pricePerSqftClosedMedian]: ListingStats['pricePerSqftClosedMedian'];
  [CerberusStatsFields.pricePerSqftClosedMedianRental]: ListingStats['pricePerSqftClosedMedian'];
  [CerberusStatsFields.pricePerSqftClosedMedianMovingAvg]: ListingStats['pricePerSqftClosedMedianMovingAvg'];
  [CerberusStatsFields.pricePerSqftClosedMedianMovingAvgRental]: ListingStats['pricePerSqftClosedMedianMovingAvg'];
  [CerberusStatsFields.pricePerSqftNewListMedian]: ListingStats['pricePerSqftNewListMedian'];
  [CerberusStatsFields.pricePerSqftNewListMedianRental]: ListingStats['pricePerSqftNewListMedian'];
  [CerberusStatsFields.pricePerSqftNewListMedianMovingAvg]: ListingStats['pricePerSqftNewListMedianMovingAvg'];
  [CerberusStatsFields.pricePerSqftNewListMedianMovingAvgRental]: ListingStats['pricePerSqftNewListMedianMovingAvg'];
  [CerberusStatsFields.pricePerSqftOnMarketMedian]: ListingStats['pricePerSqftOnMarketMedian'];
  [CerberusStatsFields.pricePerSqftOnMarketMedianRental]: ListingStats['pricePerSqftOnMarketMedian'];
  [CerberusStatsFields.pricePerSqftOnMarketMedianMovingAvg]: ListingStats['pricePerSqftOnMarketMedianMovingAvg'];
  [CerberusStatsFields.pricePerSqftOnMarketMedianMovingAvgRental]: ListingStats['pricePerSqftOnMarketMedianMovingAvg'];
  [CerberusStatsFields.return1YearForecast]: CerberusStatsHpiCore['return1YearForecast'];
  [CerberusStatsFields.risk1YearLoss]: CerberusStatsHpiCore['risk1YearLoss'];
  [CerberusStatsFields.rpiLatestMonth]: CerberusStatsRpiCore['latestMonth'];
  [CerberusStatsFields.rpiReturn1Year]: CerberusStatsRpiCore['return1Year'];
  [CerberusStatsFields.saleToListPriceMedian]: ListingStats['saleToListPriceMedian'];
  [CerberusStatsFields.saleToListPriceMedianRental]: ListingStats['saleToListPriceMedian'];
  [CerberusStatsFields.saleToListPriceMedianMovingAvg]: ListingStats['saleToListPriceMedianMovingAvg'];
  [CerberusStatsFields.saleToListPriceMedianMovingAvgRental]: ListingStats['saleToListPriceMedianMovingAvg'];
  [CerberusStatsFields.saleToListPriceOriginalMedian]: ListingStats['saleToListPriceOriginalMedian'];
  [CerberusStatsFields.saleToListPriceOriginalMedianRental]: ListingStats['saleToListPriceOriginalMedian'];
  [CerberusStatsFields.saleToListPriceOriginalMedianMovingAvg]: ListingStats['saleToListPriceOriginalMedianMovingAvg'];
  [CerberusStatsFields.saleToListPriceOriginalMedianMovingAvgRental]: ListingStats['saleToListPriceOriginalMedianMovingAvg'];
}>;

interface CerberusStatsListInputMsa {
  cerberusStatsType: CerberusStatsType.Msa;
}

interface CerberusStatsListInputZip {
  cerberusStatsType: CerberusStatsType.Zip;
  msaId: string;
}

export type CerberusStatsListInput =
  | CerberusStatsListInputMsa
  | CerberusStatsListInputZip;

export type MarketLevel = 'msa' | 'zip';
