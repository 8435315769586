import React, { CSSProperties, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { SCROLL_ID } from '@hcs/webapps';

import { SkeletonPage } from '../../../global/loading-errors-null/Skeleton/SkeletonPage';

import styles from './LayoutContent.module.css';

export interface LayoutContentProps {
  noPadding?: boolean;
  children?: ReactNode;
  beforeOutlet?: ReactNode;
  noOutlet?: boolean;
  className?: string;
  style?: CSSProperties;
}

export const LayoutContent = ({
  noPadding,
  children,
  noOutlet,
  beforeOutlet,
  style,
  className,
}: LayoutContentProps) => {
  return (
    <div
      className={classNames(styles.LayoutContent, className, {
        [styles.noPadding]: noPadding,
      })}
      id={SCROLL_ID}
      style={style}
    >
      {beforeOutlet}
      <SkeletonPage>
        {!noOutlet && <Outlet />}
        {children}
      </SkeletonPage>
    </div>
  );
};
