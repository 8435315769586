import React from 'react';
import classNames from 'classnames';

import { FlexScrollCard } from '@hcs/design-system';
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { Pagination, PAGINATION_HEIGHT } from '@hcs/design-system';
import { useUrlState } from '@hcs/hooks';

import {
  ANALYSIS_RESULTS_URL_STATE_KEY,
  DEXP_REPORTS_PER_PAGE,
} from '../../constants';
import { useDexpJobs } from '../../hooks/useDexpJobs';
import { AnalysisResultsErrorSummary } from '../AnalysisResultsErrorSummary';

import { analysisResultsTableData } from './AnalysisResultsTableData';

import styles from './AnalysisResults.module.css';

const ROW_HEIGHT = 60;
const dataHcName = 'analysis-results';
export const dataHcEventSectionDexpResults = 'DEXP Analysis Results';
export const AnalysisResults = () => {
  const {
    isInitialLoading: dexpJobsIsLoading,
    isError: dexpJobsIsError,
    data: dexpJobsData,
    loadNextPage,
    loadPrevPage,
  } = useDexpJobs();

  const { state, actions } = useUrlState<{ selectedDexpJob?: number }>(
    ANALYSIS_RESULTS_URL_STATE_KEY,
    {}
  );

  if (dexpJobsIsError) {
    return (
      <NullState
        title="Failed to Load Analysis Results"
        dataHcName={`${dataHcName}-error`}
        absCenter
      />
    );
  }

  return !state.selectedDexpJob ? (
    <FlexScrollCard
      dataHcName={`${dataHcName}-table-card`}
      dataHcEventSection={dataHcEventSectionDexpResults}
      className={classNames(
        styles.AnalaysisResultsTableCard,
        styles.AnalysisResults
      )}
      footer={{
        height: PAGINATION_HEIGHT,
        content: (
          <Pagination
            dataHcName={`${dataHcName}-pagination`}
            {...dexpJobsData?.pagination}
            hideResultCount
            onClickNext={loadNextPage}
            onClickPrev={loadPrevPage}
          />
        ),
      }}
    >
      <Table
        dataHcName={`${dataHcName}-table`}
        skeletonConfig={{
          isLoading: dexpJobsIsLoading,
          colCount: 7,
          rows: DEXP_REPORTS_PER_PAGE,
        }}
      >
        <TableHeader height={ROW_HEIGHT} sticky>
          {analysisResultsTableData.map((tableData) => (
            <TableHeaderCell
              align={tableData.headerCellAlign}
              key={tableData.colName}
              isTableHeaderCell
            >
              {tableData.colName}
            </TableHeaderCell>
          ))}
        </TableHeader>
        {dexpJobsData?.data.map((dexpJob) => (
          <TableRow
            dataHcName={`${dataHcName}-table-row-${dexpJob.id}`}
            key={dexpJob.id}
          >
            {analysisResultsTableData.map(({ Component, key }) => (
              <Component key={key} dexpJob={dexpJob} isTableCell />
            ))}
          </TableRow>
        ))}
      </Table>
    </FlexScrollCard>
  ) : (
    <div className={styles.AnalysisResults}>
      <AnalysisResultsErrorSummary
        dexpJobId={state.selectedDexpJob}
        backToResults={() => actions.setUrlState({})}
      />
    </div>
  );
};
