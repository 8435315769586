import {
  ApplicationType,
  CerberusInput,
  Listing,
  ListingMediaHcImages,
  ListingStatusCerberus,
  LookupResults,
  MsaCoreFragment,
  PropertyStateCoreHcQuery,
  PropertyStateCoreMlsQuery,
  PropertyStateCorePrQuery,
  PropertyStateListingQuery,
  PropertyStateMsaDetails,
  PropertyStatePreviewHcQuery,
  PropertyStatePreviewMlsQuery,
  PropertyStatePreviewPrQuery,
  PropertyTypeEnum,
} from '@hcs/types';
import {
  LivingAreaFields,
  PropertyStateArgsCore,
  PropertyStateCalculatedFields,
  PropertyStateCerberusInput,
  PropertyStateComplexFieldsRentalPreview,
  PropertyStateComplexFieldsSalePreview,
  PropertyStateCore,
  PropertyStateFields,
  PropertyStateListingDetailsCore,
  PropertyStateListingMetadata,
  PropertyStateLocation,
  PropertyStateMedia,
  PropertyStatePreview,
  PropertyStatePropertyDetailsPreview,
  PropertyStateSources,
  PropertyStateType,
} from '@hcs/types';

import { getPropertyStateFieldValue } from '../utils';

const addCalculatedFieldsPreview = (
  propertyState: PropertyStatePreview
): PropertyStatePreview => {
  const calculatedFields: Partial<PropertyStateCalculatedFields> = {};
  const livingArea = getPropertyStateFieldValue(
    PropertyStateFields.livingArea,
    {
      propertyStateType: PropertyStateType.Preview,
      propertyState,
    }
  );
  /**
   * LivingAreaFields
   */
  if (livingArea) {
    const livingAreaFieldsSale: Partial<LivingAreaFields> = {};
    const livingAreaFieldsRental: Partial<LivingAreaFields> = {};
    /**
     * Preview LivingAreaFields
     **/
    if (propertyState?.complexFieldsSale?.currentPrice) {
      livingAreaFieldsSale.currentPricePerSqFt =
        propertyState.complexFieldsSale.currentPrice / livingArea;
    }
    if (propertyState?.complexFieldsRental?.currentPrice) {
      livingAreaFieldsRental.currentPricePerSqFt =
        propertyState.complexFieldsRental.currentPrice / livingArea;
    }

    calculatedFields.livingAreaFieldsSale = livingAreaFieldsSale;
    calculatedFields.livingAreaFieldsRental = livingAreaFieldsSale;
  }
  return {
    ...propertyState,
    calculatedFields,
  };
};

const addCalculatedFieldsCore = (
  propertyState: PropertyStateCore
): PropertyStateCore => {
  const calculatedFields: Partial<PropertyStateCalculatedFields> = {
    ...addCalculatedFieldsPreview(propertyState).calculatedFields,
  };
  const livingArea = getPropertyStateFieldValue(
    PropertyStateFields.livingArea,
    {
      propertyStateType: PropertyStateType.Core,
      propertyState,
    }
  );
  /**
   * Other Core PropertyStateFields
   */
  if (propertyState?.propertyDetails?.yearBuilt) {
    calculatedFields.age =
      new Date().getFullYear() - propertyState.propertyDetails?.yearBuilt;
  }

  /**
   * LivingAreaFields
   */
  if (livingArea) {
    /**
     * Core LivingAreaFields Sale
     */
    const livingAreaFieldsSale: Partial<LivingAreaFields> = {
      ...calculatedFields.livingAreaFieldsSale,
    };
    if (propertyState?.complexFieldsSale?.lastClosePrice) {
      livingAreaFieldsSale.lastClosePricePerSqFt =
        propertyState.complexFieldsSale.lastClosePrice / livingArea;
    }
    if (propertyState?.complexFieldsSale?.currentListingPrice) {
      livingAreaFieldsSale.currentListingPricePerSqFt =
        propertyState.complexFieldsSale.currentListingPrice / livingArea;
    }
    if (propertyState?.propertyValue?.value) {
      livingAreaFieldsSale.propertyValueMeanPerSqFt =
        propertyState.propertyValue.value / livingArea;
    }
    calculatedFields.livingAreaFieldsSale = livingAreaFieldsSale;
    /**
     * Core LivingAreaFields Rental
     */
    const livingAreaFieldsRental: Partial<LivingAreaFields> = {
      ...calculatedFields.livingAreaFieldsRental,
    };
    if (propertyState?.complexFieldsRental?.lastClosePrice) {
      livingAreaFieldsRental.lastClosePricePerSqFt =
        propertyState.complexFieldsRental.lastClosePrice / livingArea;
    }
    if (propertyState?.complexFieldsRental?.currentListingPrice) {
      livingAreaFieldsRental.currentListingPricePerSqFt =
        propertyState.complexFieldsRental.currentListingPrice / livingArea;
    }
    if (propertyState?.propertyValueRental?.value) {
      livingAreaFieldsRental.propertyValueMeanPerSqFt =
        propertyState.propertyValueRental.value / livingArea;
    }
    calculatedFields.livingAreaFieldsRental = livingAreaFieldsSale;
  }
  return {
    ...propertyState,
    calculatedFields,
  };
};

// Every variation of PropertyState includes hcAddressId and location
export const cerberusLookupResponseToPropertyStateCommon = (
  response:
    | PropertyStateCoreHcQuery
    | PropertyStateCoreMlsQuery
    | PropertyStateCorePrQuery
    | PropertyStatePreviewHcQuery
    | PropertyStatePreviewMlsQuery
    | PropertyStatePreviewPrQuery
): PropertyStateLocation => {
  return {
    hcAddressId: response.lookup?.propertyDetails.hcAddressId,
    location:
      response.lookup?.propertyDetails?.location?.discovery?.hcAddress ||
      response.lookup?.propertyDetails?.location?.hc
        ? {
            ...response.lookup.propertyDetails.location?.discovery?.hcAddress,
            ...response.lookup.propertyDetails.location?.hc,
          }
        : undefined,
  };
};

export const cerbusListingToPropertyStateListingMetadata = (
  listing?: Listing | null
): PropertyStateListingMetadata => {
  return {
    entityId: listing?.entityId,
    hcMlsId: listing?.id?.discovery?.hcMlsId,
    listingId: listing?.id?.discovery?.listingId,
    agentOffice: listing?.agentOffice?.hc?.agentOffice,
    mls: listing?.mls,
  };
};

export const cerbusListingToPropertyStateListingDetailsCore = (
  listing?: Listing | null
): PropertyStateListingDetailsCore => {
  return {
    ...cerbusListingToPropertyStateListingMetadata(listing),
    remarks: listing?.listingDetails?.hc?.remarks,
    concessions: {
      ...listing?.listingDetails?.hc?.concessions,
      concessions: listing?.listingDetails?.reso?.concessions,
      concessionsAmount: listing?.listingDetails?.reso?.concessionsAmount,
      concessionsComments: listing?.listingDetails?.reso?.concessionsComments,
    },
  };
};

//  PropertyState Preview fields common to all PropertyStateSources
export const cerberusLookupResponseToPropertyStatePreviewCommon = (
  response: PropertyStateCoreHcQuery | PropertyStatePreviewHcQuery
): Omit<PropertyStatePreview, 'propertyDetails'> => {
  return {
    ...cerberusLookupResponseToPropertyStateCommon(response),
  };
};

//  PropertyState Preview fields specific to PropertyStateSources
export const cerberusLookupResponseToPropertyStateHcPreview = (
  response: PropertyStateCoreHcQuery | PropertyStatePreviewHcQuery
): PropertyStatePreview => {
  return addCalculatedFieldsPreview({
    ...cerberusLookupResponseToPropertyStateCommon(response),
    // TODO: Remove casting when enum types are defined in cerberus. Enums defined as strings are currently causing type errors
    propertyDetails: response.lookup?.propertyDetails.hc?.property as
      | PropertyStatePropertyDetailsPreview
      | undefined,
    complexFieldsSale: response.lookup?.complexFields?.[0]?.sale as
      | PropertyStateComplexFieldsSalePreview
      | undefined,
    complexFieldsRental: response.lookup?.complexFields?.[0]?.rental as
      | PropertyStateComplexFieldsRentalPreview
      | undefined,
    listingDetailsSale: cerbusListingToPropertyStateListingMetadata(
      response.lookup?.latestListing.sale
    ),
  });
};

// report-api renames these fields
const convertMsaDetailsToPropertyStateMsaDetails = (
  msaDetails?: MsaCoreFragment | null
): PropertyStateMsaDetails | null => {
  if (msaDetails) {
    return {
      msaName: msaDetails.name,
      msaId: msaDetails.msa,
    };
  }
  return null;
};

export const cerberusLookupResponseToPropertyStateHcCore = (
  response: PropertyStateCoreHcQuery
): PropertyStateCore => {
  return addCalculatedFieldsCore({
    ...cerberusLookupResponseToPropertyStateHcPreview(response),
    listingDetailsSale: cerbusListingToPropertyStateListingDetailsCore(
      response.lookup?.latestListing.sale
    ),
    listingDetailsRental: cerbusListingToPropertyStateListingDetailsCore(
      response.lookup?.latestListing.rental
    ),
    taxDetails: response.lookup?.taxHistory.hc,
    propertyValue: response.lookup?.value.value,
    propertyValueRental: response.lookup?.value.rental,
    blockDetails: response.lookup?.propertyDetails.blockDetails,
    msaDetails: convertMsaDetailsToPropertyStateMsaDetails(
      response.lookup?.propertyDetails.msaDetails
    ),
  });
};

export const cerberusListingsLookupToPropertyStateArgsCore = (
  response: PropertyStateListingQuery
): PropertyStateArgsCore => {
  const listing = response.lookupListings?.listings?.[0];
  const listingDetails = listing?.listingDetails?.hc;
  const { rentalListing } = listingDetails || {};
  return {
    propertyStateType: PropertyStateType.Core,
    propertyState: addCalculatedFieldsCore({
      hcAddressId: listing?.hcAddressId,
      location:
        listing?.location?.discovery?.hcAddress || listing?.location?.hc
          ? {
              ...listing.location?.discovery?.hcAddress,
              ...listing.location?.hc,
            }
          : undefined,
      propertyDetails: {
        ...listing?.propertyDetails?.hc,
        // TODO: Remove casting when enum types are defined in cerberus. Enums defined as strings are currently causing type errors
        propertyType: listing?.propertyDetails?.hc?.propertyType as
          | PropertyTypeEnum
          | null
          | undefined,
      },
      [rentalListing ? 'listingDetailsRental' : 'listingDetailsSale']:
        listingDetails
          ? {
              entityId: listing.entityId,
              hcMlsId: listing.id?.discovery?.hcMlsId,
              listingId: listing.id?.discovery?.listingId,
              agentOffice: listing.agentOffice?.hc?.agentOffice,
              remarks: listingDetails.remarks,
            }
          : undefined,
      // Fill in complexFields with listingDetails
      [rentalListing ? 'complexFieldsRental' : 'complexFieldsSale']:
        listingDetails
          ? {
              // currentHcpyLookupKey?:
              // currentSourceId?: string | null;
              currentDaysOnMarketCumulative:
                listingDetails.daysOnMarketCumulative,
              currentDaysToCloseCumulative:
                listingDetails.daysToCloseCumulative,
              currentDaysOnMarketMLS: listingDetails.daysOnMarketMLS,
              currentArmsLength: listingDetails.armsLength,
              currentDistressed: listingDetails.distressed,
              currentFlipYN: listingDetails.flipYN,
              currentListDate: listingDetails.listingDate,
              currentListingPrice: listingDetails.listPrice,
              lastCloseDate: listingDetails.closeDate,
              lastClosePrice: listingDetails.closePrice,
              currentHcMlsId: listing.id?.discovery?.hcMlsId,
              currentListingId: listing.id?.discovery?.listingId,
              currentPrice: listingDetails.currentPrice,
              currentStatus: listingDetails.status as  // TODO: Remove casting when enum types are defined in cerberus. Enums defined as strings are currently causing type errors
                | ListingStatusCerberus
                | null
                | undefined,
              currentStatusDate: listingDetails.currentStatusDate,
            }
          : undefined,
    }),
  };
};

export const listingToPropertyStateMedia = (
  listing: Listing | undefined | null
): PropertyStateMedia => {
  if (!listing) {
    return {
      mls: {},
      media: { images: [] },
      listingDetails: {},
    };
  }
  const listingDetails = listing.listingDetails?.hc;
  const listingDetailsMetadata: PropertyStateListingMetadata = {
    entityId: listing.entityId,
    hcMlsId: listing.id?.discovery?.hcMlsId,
    listingId: listing.id?.discovery?.listingId,
    agentOffice: listing.agentOffice?.hc?.agentOffice?.filter(
      (agentOffice) => agentOffice?.type === 'list'
    ),
  };

  const images: ListingMediaHcImages[] = [];
  listing.media?.hc?.images?.forEach((image) => {
    if (image?.url) {
      images.push(image);
    }
  });
  return {
    mls: listing.mls,
    media: { ...listing.media?.hc, images },
    listingDetails: listingDetails
      ? {
          ...listingDetailsMetadata,
          ...listingDetails,
          // TODO: Remove this override when cerberus enums are defined by the api
          status: listingDetails.status
            ? (listingDetails.status as ListingStatusCerberus)
            : undefined,
        }
      : {},
  };
};

export const cerberusLatestListingToPropertyStateMedia = (
  lookupResults: Partial<LookupResults> | null | undefined
): PropertyStateMedia => {
  return listingToPropertyStateMedia(lookupResults?.latestListing?.sale);
};

export const prepPropertyStateCerberusInputs = ({
  cerberusInput,
  propertyStateType,
  propertyStateSource,
}: PropertyStateCerberusInput): {
  cerberusInput: CerberusInput | undefined;
  propertyStateType: PropertyStateType;
  propertyStateSource: PropertyStateSources;
} => {
  return {
    cerberusInput: cerberusInput
      ? {
          ...cerberusInput,
          application: cerberusInput.application
            ? cerberusInput.application
            : ApplicationType.Vow,
        }
      : undefined,
    propertyStateSource: propertyStateSource || PropertyStateSources.HC,
    propertyStateType: propertyStateType || PropertyStateType.Preview,
  };
};
