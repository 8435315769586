import React from 'react';
import InputRange, {
  InputRangeClassNames,
  InputRangeProps,
  Range,
} from 'react-input-range';
import DEFAULT_CLASS_NAMES from 'react-input-range/lib/js/input-range/default-class-names';
import classNames from 'classnames';

import { useComponentDidUpdate } from '@hcs/hooks';

import styles from './RangeSlider.module.css';

export type PartialInputRangeClassNames = Partial<InputRangeClassNames>;
export interface RangeSliderProps {
  theme?: PartialInputRangeClassNames;
  step: number;
  className?: string;
  minValue: number;
  maxValue: number;
  dataHcName: string;
  disabled?: boolean;
  onChange: (v: Range | number) => void;
  initialValue: number;
  onChangeStart?: (v: Range | number) => void;
  onChangeComplete: (v: Range | number) => void;
  formatLabel?: (v: number, l: string) => string;
}

export const RangeSlider = ({
  className,
  theme,
  minValue,
  maxValue,
  step,
  dataHcName,
  onChange,
  initialValue,
  disabled = false,
  onChangeStart,
  onChangeComplete,
  formatLabel = (v, l) => l,
}: RangeSliderProps) => {
  const [value, setValue] =
    React.useState<InputRangeProps['value']>(initialValue);

  useComponentDidUpdate(() => {
    // If we pass in a new initial value then update
    if (value !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (newValue: InputRangeProps['value']) => {
    if (value !== newValue) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  const buildClassName = (className: keyof InputRangeClassNames) =>
    classNames(
      DEFAULT_CLASS_NAMES[className],
      theme?.[className],
      styles[className]
    );

  const irTheme: InputRangeClassNames = {
    activeTrack: buildClassName('activeTrack'),
    disabledInputRange: buildClassName('disabledInputRange'),
    inputRange: buildClassName('inputRange'),
    labelContainer: buildClassName('labelContainer'),
    maxLabel: buildClassName('maxLabel'),
    minLabel: buildClassName('minLabel'),
    slider: buildClassName('slider'),
    sliderContainer: buildClassName('sliderContainer'),
    track: buildClassName('track'),
    valueLabel: buildClassName('valueLabel'),
  };

  return (
    <div data-hc-name={dataHcName} className={className}>
      <InputRange
        classNames={irTheme}
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        formatLabel={formatLabel}
        step={step}
        disabled={disabled}
        onChange={(v) => handleChange?.(v)}
        onChangeStart={onChangeStart}
        onChangeComplete={onChangeComplete}
      />
    </div>
  );
};
