import React, { ReactNode, Suspense } from 'react';

import { LoadingSpinner } from '../LoadingSpinner';

interface Props {
  children: ReactNode;
}
export const SkeletonPage = ({ children }: Props) => {
  return (
    <Suspense
      fallback={<LoadingSpinner dataHcName={`app-skeleton-chunk-load`} />}
    >
      {children}
    </Suspense>
  );
};
