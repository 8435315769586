import { PropertyTypeEnum } from '../cerberus';
import { LngLatObject, MoveMapProps } from '../maps/HcMap.types';
import { ArrayOneOrMore } from '../TypeScriptHelpers.types';

import { ControlConfig } from '.';

export enum VectileLayers {
  HCS_BLOCKS = 'hcs_blocks',
  HCS_BLOCK_GROUPS = 'hcs_block_groups',
  HCS_RENTAL_BLOCK_GROUPS = 'hcs_rental_block_groups',
  HCS_RENTAL_BLOCKS = 'hcs_rental_blocks',
  CONSUMER_BLOCKS = 'consumer_blocks',
  CONSUMER_BLOCK_GROUPS = 'consumer_block_groups',
}

/** Note: this enum contains ALL of the layers that are retrieved from the API
 * We are using a Partial list of these in our interface i.e. crime is using
 * only CRIME_ALL, CRIME_VIOLENT and CRIME_PROPERTY,
 */
export enum VectilesMetricsConsumer {
  ALL_LAND = 'aland', // Start of misc layers
  AVE_PRICE_PER_SQFT = 'avg_price_per_sqft',
  ALL_WATER = 'awater',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PRICE_SQFT = 'avg_price_per_sqft', // end misc layers
  PRICE_AVG = 'avg_price',
  SCHOOLS_ELEMENTARY = 'schools_elementary',
  SCHOOLS_MIDDLE = 'schools_middle',
  SCHOOLS_HIGH = 'schools_high',
  CRIME_ALL = 'all_crime_county_percentile',
  CRIME_VIOLENT = 'violent_crime_county_percentile',
  CRIME_PROPERTY = 'property_crime_county_percentile',
  FORECAST_ONE_YEAR = 'price_pctchg_1_yr_fc',
}

export enum VectilesMetricsHcs {
  GROSS_YIELD_AVERAGE = 'gross_yield_average',
  PRICE_5 = 'price_5',
  PRICE_25 = 'price_25',
  PRICE_50 = 'price_50',
  PRICE_75 = 'price_75',
  PRICE_95 = 'price_95',
  PRICE_AVERAGE = 'price_average',
  PRICE_COUNT = 'price_count',
  PRICE_MAX = 'price_max',
  PRICE_MIN = 'price_min',
  PRICE_PCT_CHG_1YR = 'price_pctchg_1_yr',
  PRICE_PCT_CHG_5YR = 'price_pctchg_5_yr',
  PRICE_SD = 'price_sd',
  PRICES = 'prices',
  UNIT_PRICE_5 = 'unit_price_5',
  UNIT_PRICE_25 = 'unit_price_25',
  UNIT_PRICE_50 = 'unit_price_50',
  UNIT_PRICE_75 = 'unit_price_75',
  UNIT_PRICE_95 = 'unit_price_95',
  UNIT_PRICE_AVERAGE = 'unit_price_average',
  UNIT_PRICE_COUNT = 'unit_price_count',
  UNIT_PRICE_MAX = 'unit_price_max',
  UNIT_PRICE_MIN = 'unit_price_min',
  UNIT_PRICE_PCT_CHG_1YR = 'unit_price_pctchg_1_yr',
  UNIT_PRICE_PCT_CHG_5YR = 'unit_price_pctchg_5_yr',
  UNIT_PRICE_SD = 'unit_price_sd',
}

export enum VectilesMetricsHcsRental {
  grossYield50 = 'gross_yield_50',
  grossYieldAverage = 'gross_yield_average',
  price5 = 'price_5',
  price25 = 'price_25',
  price50 = 'price_50',
  price75 = 'price_75',
  price95 = 'price_95',
  priceAverage = 'price_average',
  priceCount = 'price_count',
  priceMax = 'price_max',
  priceMin = 'price_min',
  priceSd = 'price_sd',
  prices = 'prices',
  unitPrice5 = 'unit_price_5',
  unitPrice25 = 'unit_price_25',
  unitPrice50 = 'unit_price_50',
  unitPrice75 = 'unit_price_75',
  unitPrice95 = 'unit_price_95',
  unitPriceAverage = 'unit_price_average',
  unitPrice_count = 'unit_price_count',
  unitPriceMax = 'unit_price_max',
  unitPriceMin = 'unit_price_min',
  unitPriceSd = 'unit_price_sd',
}

// FE only strings to identify unique layer metrics
export enum VectilesMetricIds {
  // Sold Metrics
  Prices = 'prices',
  PricesPerSqFt = 'prices_per_sqft',
  GrowthHistorical1Year = 'growth_historical_1',
  GrowthHistorical5Year = 'growth_historical_5',
  // Rental Metrics
  RentalYieldGrossAvg = 'rental_yield_gross_avg',
  RentalPrices = 'rental_prices',
  // Consumer Metrics
  SchoolElementary = 'school_elm',
  SchoolMiddle = 'school_middle',
  SchoolHigh = 'school_high',
  // Crime
  CrimeAll = 'all_crime_county_percentile',
  CrimeViolent = 'violent_crime_county_percentile',
  CrimeProperty = 'property_crime_county_percentile',
}

export type VectilesMetrics =
  | VectilesMetricsHcs
  | VectilesMetricsConsumer
  | VectilesMetricsHcsRental;

export enum TilePropertyTypes {
  SFD = 'SFD',
  TH = 'TH',
  CND = 'CND',
  MFH = 'MFH',
  INC = 'INC',
}

export type ConsumerMapLegendStats = {
  [metric in VectilesMetricsConsumer]: MapLegendMetricStats;
};

export type HcMapLegendStats = Record<string, MapLegendMetricStats>;

interface ZoomRange {
  min: number;
  max: number;
}
export interface VectilesLayerSource {
  vectilesLayer: VectileLayers;
  zoomRange: ZoomRange;
}
export type VectilesMetricGroupColors = [
  string,
  string,
  string,
  string,
  string
];
export interface VectilesMetricGroupConfig {
  label: string;
  description: string;
  colors: VectilesMetricGroupColors;
  numberType: HcMapLayerNumberType;
  vectilesMetricGroup: VectilesMetricGroups;
  vectilesMetricIds: ArrayOneOrMore<VectilesMetricIds>;
  vectilesLayers: ArrayOneOrMore<VectilesLayerSource>;
}

export interface VectilesMetricConfig {
  vectilesMetricId: VectilesMetricIds;
  vectilesMetric: VectilesMetrics;
  vectilesMetricGroup: VectilesMetricGroups;
  label: string;
  propertyTypeMetric?: boolean;
}

/* Used to render the colors in the legend */
export type LegendColorTable = Array<{
  color: string;
  label: [string, string];
  patternImageIcon?: string;
}>;

/* Used to render the legend for the currently active heatmap layer in MapLayersControl.tsx */
export type LegendInterval = {
  start: number;
  end: number;
  color: string;
  min?: number;
  max?: number;
  rangeDisplay?: {
    start: string;
    end: string;
  };
  mapboxImageID?: string;
};

export enum HcMapLayerNumberType {
  PERCENTAGE = 'percentage',
  MONEY = 'money',
  NUMBER = 'number',
}

export enum HcMapMapboxLayerIds {
  MARKERS_SYMBOL_LAYER = 'markers-symbol-layer',
  SCHOOL_SYMBOL_LAYER = 'schools-symbol-layer',
  SCHOOL_DISTRICT_BOUNDARY_LAYER = 'school-district-boundary-layer',
}

export type MapLegendMetricStats = {
  min: number;
  max: number;
  mean: number;
  count: number;
  bins: number[];
};

export type VectilesStatsReturnType = {
  num_area_features: number;
  num_boundary_features: number;
  num_label_features: number;
  property_stats: HcMapLegendStats | ConsumerMapLegendStats;
};

export enum VectilesMetricGroups {
  Schools = 'schools',
  Price = 'price',
  Growth = 'growth',
  RentalYield = 'rental-yield',
  RentalPrice = 'rental-price',
  Crime = 'crime',
}

export interface ViewportState
  extends Omit<MoveMapProps, 'latitude' | 'longitude' | 'zoom'> {
  latitude: number;
  longitude: number;
  height: number;
  width: number;
  zoom: number;
}

export interface Markers {
  showMarkers: boolean;
}

export interface Heatmap {
  vectilesMetricId: VectilesMetricIds | null;
  vectilesMetricGroup: VectilesMetricGroups | null;
  showMonochrome: boolean;
  propertyType?: PropertyTypeEnum | null;
}

export type Bounds = [[number, number], [number, number]];

export interface BoundsInfo {
  northEast: LngLatObject;
  southWest: LngLatObject;
  bounds: Bounds;
}

export interface LayersControlConfig extends ControlConfig {
  vectilesMetricGroups: ArrayOneOrMore<VectilesMetricGroups>;
  toggles?: {
    showProperties?: boolean;
  };
}
