import React from 'react';

import { Tooltip } from '@hcs/design-system';
import { ProgressBar } from '@hcs/design-system';
import { OmOrder } from '@hcs/types';

import {
  getOrderItemStatusLabel,
  orderPercentComplete,
} from '../../../utils/status.util';

import { OrderProgressDetails } from './OrderProgressDetails';

import styles from './OrderProgress.module.css';

type OrderProgressProps = {
  dataHcName: string;
  order: OmOrder;
  customerOrderId: string;
  total: number;
};

export const OrderProgress = ({
  dataHcName,
  order,
  customerOrderId,
  total,
}: OrderProgressProps) => {
  const completedOrderPercentage = orderPercentComplete(order);
  const onlyOneItem: boolean = order.partialEnabled
    ? order.orderItemsToProcessCount + order.orderItemsToReviewCount === 1
    : order.orderItemsToProcessCount === 1;
  if (onlyOneItem) {
    const statusLabel = getOrderItemStatusLabel(order.maxItemStatus);
    return <span>{statusLabel}</span>;
  } else {
    return (
      <Tooltip
        dataHcName={dataHcName}
        trigger={
          <ProgressBar
            dataHcName={`${dataHcName}-progress-bar`}
            completedPercentage={completedOrderPercentage}
          />
        }
        label={
          <div data-hc-name={`${dataHcName}-content`}>
            <div className={styles.Row}>
              <div className={styles.CustomerOrderId}>
                Order: {customerOrderId}
              </div>
              <div className={styles.Total}>
                Total: {total.toLocaleString()}
              </div>
            </div>
            <div>
              <OrderProgressDetails
                orderNumber={customerOrderId}
                orderId={order.id}
                statusGroups={order.orderTypeDescriptor.statusGroups}
              />
            </div>
          </div>
        }
      />
    );
  }
};
