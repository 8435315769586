import React from 'react';

export const OrdersPage = React.lazy(() => import('./OrdersPage'));

export const ViewOrderPage = React.lazy(() => import('./ViewOrderPage'));

export const ReportSummaryPage = React.lazy(
  () => import('./ReportSummaryPage')
);

export const BPOReportPdfPage = React.lazy(() => import('./BPOReportPdfPage'));
