import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router';

import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';
import { parseReportId } from '@hcs/report-api';
import { ReportOrReportId } from '@hcs/types';
import { NavigateToReportFn, NavigateToReportFnOptions } from '@hcs/types';

export const useNavigateToReport = (): NavigateToReportFn => {
  const navigate = useNavigate();
  return useCallback(
    (report: ReportOrReportId, options?: NavigateToReportFnOptions) => {
      const reportId = parseReportId(report);
      const isViewingPublicReport = typeof reportId === 'string';

      if (options?.valuationType) {
        navigate(
          `${APP_CONFIG_PEXP.rootPath}/${generatePath(
            isViewingPublicReport
              ? VIEW_PATHS_PEXP.PUBLIC_REPORT_W_VAL_TYPE
              : VIEW_PATHS_PEXP.REPORT_W_VAL_TYPE,
            {
              reportId: reportId.toString(),
              valuationType: options.valuationType,
            }
          )}`,
          {}
        );
      } else {
        navigate(
          `${APP_CONFIG_PEXP.rootPath}/${generatePath(
            isViewingPublicReport
              ? VIEW_PATHS_PEXP.PUBLIC_REPORT
              : VIEW_PATHS_PEXP.REPORT,
            {
              reportId: reportId.toString(),
            }
          )}`,
          {}
        );
      }
    },
    [navigate]
  );
};
