import React, { useState } from 'react';
import classNames from 'classnames';

import {
  CreateNewOrderState,
  DeliverySpeed,
  INSPECTION_TYPE,
  OrderTypeDescriptor,
  OrderTypeId,
  STEP,
} from '@hcs/types';

import { ICONS, INSPECTION_NAMES } from '../constants';
import { getAsRepairedLabel } from '../utils';

import styles from './AddOrderInspectionMethodForm.module.css';

interface Props {
  showDeliveryOptions: boolean;
  updateStep: (stateToUpdate: Partial<CreateNewOrderState>) => void;
  orderTypes?: OrderTypeDescriptor[];
  deliverySpeed?: DeliverySpeed;
  inspectionType?: INSPECTION_TYPE | null;
  includesAsRepairedValue?: boolean;
}

const dataHcName = 'add-order-inspection-method';
export const AddOrderInspectionMethodForm = ({
  showDeliveryOptions,
  updateStep,
  orderTypes,
  inspectionType,
  includesAsRepairedValue,
}: Props) => {
  const [orderInspectionForm, setOrderInspectionForm] = useState<{
    orderTypeId?: OrderTypeId;
    deliverySpeeds?: DeliverySpeed[];
    deliverySpeedId?: string;
    orderType?: OrderTypeDescriptor;
  }>({});

  if (!orderTypes) return null;

  const handleInspectionClick =
    (orderType: OrderTypeDescriptor) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const { inspectionType, includesAsRepairedValue, deliverySpeeds } =
        orderType;

      let deliverySpeedId = undefined;
      if (deliverySpeeds.length < 2) {
        if (deliverySpeeds.length === 1) {
          deliverySpeedId = deliverySpeeds[0]?.id;
          updateStep({
            step: STEP.ADD_ADDRESS_METHOD,
            deliverySpeed: deliverySpeeds[0],
            selectedOrderType: orderType,
            inspectionType: inspectionType || INSPECTION_TYPE.desktop,
            includesAsRepairedValue: !!includesAsRepairedValue,
          });
        } else {
          updateStep({
            step: STEP.ADD_ADDRESS_METHOD,
            selectedOrderType: orderType,
            inspectionType: inspectionType || INSPECTION_TYPE.desktop,
            includesAsRepairedValue: !!includesAsRepairedValue,
          });
        }
      } else {
        updateStep({
          selectedOrderType: orderType,
          inspectionType: inspectionType || INSPECTION_TYPE.desktop,
          includesAsRepairedValue: !!includesAsRepairedValue,
        });
      }

      setOrderInspectionForm({
        deliverySpeeds: deliverySpeeds,
        orderTypeId: orderType.id,
        deliverySpeedId,
        orderType,
      });
    };

  const handleDeliveryClick =
    (option: DeliverySpeed) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setOrderInspectionForm({
        ...orderInspectionForm,
        deliverySpeedId: option.id,
      });
      updateStep({
        step: STEP.ADD_ADDRESS_METHOD,
        deliverySpeed: option,
        selectedOrderType: orderInspectionForm.orderType,
      });
    };

  return (
    <div data-hc-name={dataHcName} className={styles.TileWrap}>
      <div className={styles.InspectionButtonsContainer}>
        {orderTypes.map((orderType) => {
          const selected =
            orderType.inspectionType === inspectionType &&
            !!orderType.includesAsRepairedValue === !!includesAsRepairedValue;
          const inspectionKey = orderType.inspectionType
            ? orderType.inspectionType
            : INSPECTION_TYPE.desktop;
          const iconKey = orderType.inspectionType
            ? orderType.inspectionType
            : INSPECTION_TYPE.desktop;
          const Icon = ICONS[iconKey];
          const asRepairedLabel = getAsRepairedLabel(
            !!orderType.includesAsRepairedValue
          );
          return (
            <div
              className={classNames({ [styles.active]: selected })}
              key={orderType.id}
            >
              <button
                className={styles.Tile}
                onClick={handleInspectionClick(orderType)}
                data-hc-name={`inspection-${inspectionKey}-button`}
              >
                <div className={styles.IconContainer}>
                  <Icon dataHcName={`${dataHcName}-icon`} height="100%" />
                </div>
                <h4>{INSPECTION_NAMES[inspectionKey]}</h4>
                {asRepairedLabel && <div>{asRepairedLabel}</div>}
              </button>
            </div>
          );
        })}
      </div>
      {!orderInspectionForm.deliverySpeedId && showDeliveryOptions && (
        <div className={styles.DeliveryInputContainer}>
          {orderInspectionForm.deliverySpeeds &&
            orderInspectionForm.deliverySpeeds.map((option: DeliverySpeed) => {
              return (
                <button
                  className={styles.Tile}
                  onClick={handleDeliveryClick(option)}
                  key={option.id}
                  data-hc-name={`delivery-${option.id}-button`}
                >
                  <h4>{option.label}</h4>
                  <div className={styles.Surcharge}>
                    {option.surcharge
                      ? `+ $${option.surcharge}/address`
                      : 'Standard'}
                  </div>
                </button>
              );
            })}
        </div>
      )}
    </div>
  );
};
