import { useNavigate } from 'react-router';

import { APP_CONFIG_PORTFOLIO } from '../constants/AppConfigPortfolio.constants';

export const useNavigateToPortfolio = () => {
  const navigate = useNavigate();
  return () => {
    navigate(APP_CONFIG_PORTFOLIO.rootPath);
  };
};
