import { DaysOnMarketChartDocument, DocumentRoles, ReportId } from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useDaysOnMarketChartDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<DaysOnMarketChartDocument>(
    reportId,
    DocumentRoles.DaysOnMarketChart
  );
};
