import React from 'react';

import { Table, TableHeader, TableRow } from '@hcs/design-system';
import { OrderItemGroup } from '@hcs/types';

import { REPORT_SUMMARY_ORDER_ITEMS_TABLE_OPTIONS } from '../../constants/ReportSummary.constants';
import { useOrderItems } from '../../hooks/useOrderItems';

import { REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELLS_CONFIG } from './ReportSummaryOrderItemsTableCells';

interface Props {
  orderId: number;
}

const dataHcName = 'report-summary-order-items-table';
export const ReportSummaryOrderItemsTable = ({ orderId }: Props) => {
  const { data: orderItems, isInitialLoading: orderItemsIsLoading } =
    useOrderItems(orderId, {
      page: 1,
      pageSize: 50,
      orderItemGroup: OrderItemGroup.InOrder,
    });

  return (
    <Table
      skeletonConfig={{
        isLoading: orderItemsIsLoading,
        colCount: 10,
        rows: 5,
      }}
      dataHcName={`${dataHcName}-table`}
    >
      <TableHeader sticky>
        {REPORT_SUMMARY_ORDER_ITEMS_TABLE_OPTIONS.map((option) => {
          const { HeaderCell } =
            REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELLS_CONFIG[option];
          return <HeaderCell key={`${option}`} />;
        })}
      </TableHeader>

      {orderItems?.data.map((orderItem) => {
        return (
          <TableRow key={`row-${orderItem.id}`}>
            {REPORT_SUMMARY_ORDER_ITEMS_TABLE_OPTIONS.map((option) => {
              const { ContentCell } =
                REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELLS_CONFIG[option];
              return (
                <ContentCell
                  key={`cell-${orderItem.id}-${option}`}
                  orderItem={orderItem}
                />
              );
            })}
          </TableRow>
        );
      })}
    </Table>
  );
};
