import { useAccount } from '@hcs/auth';

export interface AuthenticatedCheckProps {
  children: React.ReactNode;
  /** content rendered if the user is not logged in */
  error401?: React.ReactNode;
  /** content rendered if the capability check fails */
  /** content rendered if a capability check request is in progress */
  skeleton?: React.ReactNode;
}
export const AuthenticatedCheck = ({
  children,
  error401,
  skeleton,
}: AuthenticatedCheckProps) => {
  const { isError, data: account } = useAccount();
  if (isError) {
    return error401 || null;
  }
  if (!account) {
    return skeleton || null;
  }
  return children;
};
