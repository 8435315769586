import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './CardBadge.module.css';

export interface CardBadgeProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  dataHcEventName?: string;
  dataHcEventType?: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  primary?: boolean;
  secondary?: boolean;
  clickable?: boolean;
  inline?: boolean;
  children: ReactNode;
  onClick?: VoidFunction;
}
export const CardBadge = ({
  dataHcName,
  dataHcEventName,
  dataHcEventType,
  className,
  primary,
  inline,
  clickable,
  secondary,
  children,
  onClick,
}: CardBadgeProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-name={dataHcEventName}
      data-hc-event-type={dataHcEventType}
      className={classNames(styles.CardBadge, className, {
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.clickable]: clickable,
        [styles.inline]: inline,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
