import React, { useEffect } from 'react';

import { Button } from '@hcs/design-system';
import { AvailableDownloadable } from '@hcs/types';

import { useOrderItemPdfDownload } from '../../hooks/useOrderItemPdfDownload';

interface Props {
  className?: string;
  orderItemId: number;
  orderId: number;
  availableDownloadable: AvailableDownloadable;
}

export const OrderItemPdfButton = ({
  className,
  orderItemId,
  orderId,
  availableDownloadable,
}: Props) => {
  const {
    data: pdfDownloadData,
    mutate: orderItemPdfDownload,
    isSuccess: isSuccessPdfDownloadData,
  } = useOrderItemPdfDownload();

  useEffect(() => {
    // To open a report pdf in another tab after successfully fetching the report
    if (isSuccessPdfDownloadData && pdfDownloadData?.url)
      // SECURITY: false positive, window.open is not fs.open
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(pdfDownloadData.url);
  }, [pdfDownloadData?.url, isSuccessPdfDownloadData]);

  return (
    <Button
      className={className}
      dataHcName={`download-${availableDownloadable.key}-action`}
      onClick={() => {
        orderItemPdfDownload({
          orderItemId: orderItemId,
          orderId,
          pdfType: availableDownloadable.key,
        });
      }}
    >
      View {availableDownloadable.displayName}
    </Button>
  );
};
