import React from 'react';
import classNames from 'classnames';

import { TermsAndPrivacyLinks } from '@hcs/auth';
import { Disclosure } from '@hcs/design-system';
import { MlsAttribution } from '@hcs/mls-lookup';
import { ReportId } from '@hcs/types';

import { useReportMlsIds } from '../../hooks/useReportMlsIds';

import styles from './ReportLegalDisclaimers.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}
const dataHcName = 'report-mls-attribution';
export const ReportLegalDisclaimers = ({ reportId, className }: Props) => {
  const { isSuccess, data: mlsIds } = useReportMlsIds({ reportId });
  if (!isSuccess) return null;
  return (
    <section
      data-hc-name={dataHcName}
      className={classNames(styles.ReportLegalDisclaimers, className)}
    >
      <Disclosure dataHcName="disclosure" includeAVMSection />
      {mlsIds.map((mlsId) => (
        <MlsAttribution
          key={`mls-disclaimer-${mlsId}`}
          mlsId={parseInt(mlsId)}
        />
      ))}
      <TermsAndPrivacyLinks />
    </section>
  );
};
