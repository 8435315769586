import React, { useCallback, useRef, useState } from 'react';
import { Popup } from 'react-map-gl';
import classNames from 'classnames';
import mapboxgl from 'mapbox-gl';

import { ExperienceFlag } from '@hcs/experience-flags';
import { useClickOutsideComponent } from '@hcs/hooks';
import { ReportId } from '@hcs/types';

import { MAX_CARD_WIDTH } from '../../features/CompMarkerPopup';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { SubjectPhotoCarousel } from '../SubjectPhotoCarousel';
import { SubjectPropertyCard } from '../SubjectPropertyCard';
import { SubjectPropertyCardContent } from '../SubjectPropertyCard/SubjectPropertyCardContent';

import stylesDeprecated from '../CompMarkerPopup/CompMarkerPopup.module.css';
import styles from './MarkerPopup.module.css';

interface Props {
  className?: string;
  reportId: ReportId;
  onClose: VoidFunction;
}
const dataHcName = 'subject-marker-popup';
export const SubjectMarkerPopup = ({ reportId, className, onClose }: Props) => {
  const [streetViewOpen, setStreetViewOpen] = useState(false);
  const subjectQuery = useSubjectDocument(reportId);
  const subjectSchema = subjectQuery.data?.data;
  const ref = useRef<HTMLDivElement>(null);
  const handleClose = useCallback(() => {
    if (!streetViewOpen) {
      onClose();
    }
  }, [streetViewOpen, onClose]);
  useClickOutsideComponent(ref, handleClose);
  const { latitude, longitude } = subjectSchema?.propertyState.location || {};
  if (!subjectSchema || !latitude || !longitude) {
    return null;
  }
  const offset: mapboxgl.Offset = [2, -35];
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <Popup
          className={styles.MarkerPopup}
          longitude={longitude}
          latitude={latitude}
          closeButton={false}
          maxWidth={MAX_CARD_WIDTH}
          closeOnClick={false}
          onClose={handleClose}
          anchor="bottom"
          offset={offset}
        >
          <div ref={ref}>
            <SubjectPropertyCard
              reportId={reportId}
              onStreetViewToggle={setStreetViewOpen}
              horizontal
            />
          </div>
        </Popup>
      }
      now={
        <Popup
          className={stylesDeprecated.CompMarkerPopup}
          longitude={longitude}
          latitude={latitude}
          closeButton={false}
          closeOnClick={false}
          maxWidth={MAX_CARD_WIDTH}
          onClose={handleClose}
          anchor="bottom"
          offset={offset}
        >
          <div
            ref={ref}
            data-hc-name={dataHcName}
            className={classNames(
              stylesDeprecated.CompMarkerPopupContent,
              className
            )}
          >
            <div className={stylesDeprecated.Photos}>
              <SubjectPhotoCarousel
                reportId={reportId}
                onStreetViewToggle={setStreetViewOpen}
              />
            </div>
            <div className={stylesDeprecated.Content}>
              <SubjectPropertyCardContent reportId={reportId} showMarketPrice />
            </div>
          </div>
        </Popup>
      }
    />
  );
};
