import React from 'react';

import { IconProps } from '@hcs/types';

import { CloseIcon } from '../../../../svgs/icons/navigation';
import { IconButton, IconButtonProps } from '../IconButton';

export interface CloseButtonProps extends Omit<IconButtonProps, 'icon'> {
  size?: IconProps['size'];
}

export const CloseButton = ({ ...props }: CloseButtonProps) => {
  return (
    <IconButton
      {...props}
      icon={
        <CloseIcon dataHcName={`${props.dataHcName}-icon`} size={props.size} />
      }
    />
  );
};
