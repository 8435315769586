import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { CommentIcon } from '@hcs/design-system';
import { ReportId } from '@hcs/types';

import { useSubjectDocument } from '../../hooks';

import styles from './SubjectCommentIcon.module.css';

interface Props {
  reportId: ReportId;
  onClick?: VoidFunction;
  dataHcName?: string;
  height?: string;
  width?: string;
}

export const SubjectCommentIcon = ({
  reportId,

  dataHcName = 'subject-comment-icon',
  onClick,
}: Props) => {
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!subjectDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={styles.CommentIcon}
      onClick={onClick}
    >
      {!!subjectDocument.data.comments && (
        <div
          data-hc-name={`${dataHcName}-indicator`}
          className={styles.CommentIconIndicator}
        />
      )}
      <CommentIcon size="lg" dataHcName="subject-comment-icon-svg" />
    </div>
  );
};

export default CommentIcon;
