import { useEffect, useMemo } from 'react';

import { PaginationProps } from '@hcs/design-system';
import { useUrlState } from '@hcs/hooks';
import { useUiPreferencesForUser } from '@hcs/huell';
import { CompTypes } from '@hcs/types';
import {
  CompSchema,
  CompsListTypes,
  CompsListViewType,
  ReportId,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { COMPS_LIST_MAX_COMPARE_COMPS } from '../constants';
import { useAppraisalCompDocuments } from '../hooks/useAppraisalCompDocuments';
import { sortCompDocuments } from '../utils';

import { useCompDocuments } from './useCompDocuments';
import { useCompsFarmDocument } from './useCompsFarmDocument';
import { useCompsFilteredDocument } from './useCompsFilteredDocument';

interface CompsListState {
  compsListType: CompsListTypes;
  listViewType: CompsListViewType;
  pagination?: Pick<PaginationProps, 'numPages' | 'totalCount' | 'page'>;
}
export const useCompsList = ({
  reportId,
  compType,
  compsListType,
  initialState,
}: {
  reportId: ReportId;
  compType: CompTypes;
  compsListType: CompsListTypes;
  initialState?: Omit<CompsListState, 'compsListType'>;
}) => {
  const { data: userPrefs } = useUiPreferencesForUser();
  const urlsStateId = `compsList-${compType}`;
  const appraisalCompsQuery = useAppraisalCompDocuments(reportId);
  const compsQuery = useCompDocuments(reportId, compType);
  const farmDocumentQuery = useCompsFarmDocument(reportId, compType);
  const filteredDocumentQuery = useCompsFilteredDocument(reportId, compType);
  const {
    state,
    actions: { patchUrlState },
  } = useUrlState<CompsListState>(urlsStateId, {
    compsListType,
    listViewType: userPrefs?.compsListViewTypeDefault || CompsListViewType.Card,
    ...initialState,
  });
  const { listViewType, pagination } = state;
  const { data: farmDocument } = farmDocumentQuery;
  const { data: filteredDocument } = filteredDocumentQuery;
  const { data, numCompsTotal } = useMemo((): {
    data: CompSchema[];
    numCompsTotal: number;
  } => {
    if (compsListType === 'appraisal') {
      return {
        numCompsTotal: appraisalCompsQuery.data?.length || 0,
        data:
          appraisalCompsQuery.data
            ?.map((compDoc) => compDoc.data)
            .sort((a, b) => {
              if (
                (a.similarity.scoreAdjusted || 0) <
                (b.similarity.scoreAdjusted || 0)
              ) {
                return 1;
              }
              if (
                (a.similarity.scoreAdjusted || 0) >
                (b.similarity.scoreAdjusted || 0)
              ) {
                return -1;
              }
              return 0;
            }) || [],
      };
    } else if (compsListType === 'selected') {
      return {
        numCompsTotal: compsQuery.data?.length || 0,
        data:
          compsQuery.data
            ?.sort(sortCompDocuments)
            ?.map((compDoc) => compDoc.data) || [],
      };
    } else {
      const compIds = filteredDocument?.data[compsListType] || [];
      const results: CompSchema[] = [];
      (pagination?.page
        ? compIds.slice(
            (pagination.page - 1) * COMPS_LIST_MAX_COMPARE_COMPS,
            pagination.page * COMPS_LIST_MAX_COMPARE_COMPS
          )
        : listViewType === CompsListViewType.Compare &&
          compIds.length > COMPS_LIST_MAX_COMPARE_COMPS
        ? // Do not render all comps the first time if pagination should be enabled but is not set yet
          compIds.slice(0, COMPS_LIST_MAX_COMPARE_COMPS)
        : compIds
      ).forEach((compId) => {
        const schema = farmDocument?.data.farm?.[compId];
        if (schema) {
          results.push(schema);
        }
      });
      return {
        numCompsTotal: compIds.length,
        data: results,
      };
    }
  }, [
    farmDocument,
    filteredDocument,
    compsQuery.data,
    listViewType,
    compsListType,
    pagination?.page,
  ]);
  // Control the pagination state when changing listView types
  useEffect(() => {
    if (
      listViewType === CompsListViewType.Compare &&
      compsListType !== 'selected' &&
      numCompsTotal > COMPS_LIST_MAX_COMPARE_COMPS &&
      !pagination
    ) {
      patchUrlState([
        {
          op: 'add',
          path: '/pagination',
          value: {
            page: 1,
            numPages: Math.ceil(numCompsTotal / COMPS_LIST_MAX_COMPARE_COMPS),
            totalCount: numCompsTotal,
          },
        },
      ]);
    } else if (
      !!pagination &&
      (listViewType !== CompsListViewType.Compare ||
        compsListType === 'selected')
    ) {
      patchUrlState([
        {
          op: 'remove',
          path: '/pagination',
        },
      ]);
    }
  }, [listViewType, pagination, numCompsTotal]);
  return {
    state: {
      ...state,
      compsListQuery: {
        ...combineUseQueryResult([
          compsQuery,
          farmDocumentQuery,
          filteredDocumentQuery,
        ]),
        data,
      },
    },
    actions: {
      compsListSetListViewType: (value: CompsListViewType) => {
        patchUrlState([{ op: 'add', path: '/listViewType', value }]);
      },
      compsListPageNext: () => {
        patchUrlState([
          {
            op: 'add',
            path: '/pagination/page',
            value: Math.min(
              (pagination?.page || 0) + 1,
              pagination?.numPages || 1
            ),
          },
        ]);
      },
      compsListPagePrev: () => {
        patchUrlState([
          {
            op: 'add',
            path: '/pagination/page',
            value: Math.max((pagination?.page || 2) - 1, 1),
          },
        ]);
      },
    },
  };
};
