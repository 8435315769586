import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { PropertyStateLocation } from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import styles from './PropertyDetailsHeader.module.css';

export interface PropertyDetailsHeaderProps {
  dataHcName: string;
  className?: string;
  location?: PropertyStateLocation['location'] | null;
  propertyAttributes: (string | ReactNode)[];
  publicRemarks: ReactNode;
  contentRight?: ReactNode;
  children?: ReactNode;
  keywordSearchKey?: string;
  afterAddress?: ReactNode;
}
export const PropertyDetailsHeader = ({
  dataHcName,
  location,
  propertyAttributes,
  afterAddress,
  className,
  publicRemarks,
  contentRight,
  children,
}: PropertyDetailsHeaderProps) => {
  return (
    <header
      data-hc-name={dataHcName}
      className={classNames(styles.PropertyDetailsHeader, className)}
    >
      <div>
        <div className={styles.LineOne}>
          <h1
            className={styles.StreetAddress}
            data-hc-name={`${dataHcName}-street-address`}
          >
            {formatStreetAddress(location)}
            {afterAddress}
          </h1>
          <div className={styles.Children}>{children}</div>
        </div>
        <div className={styles.LineTwo}>
          <div
            data-hc-name={`${dataHcName}-city-state-zip`}
            className={styles.CityStateZip}
          >
            {formatCityStateZip(location)}
          </div>
          <div className={styles.Divider}>•</div>
          <div
            data-hc-name={`${dataHcName}-property-attributes`}
            className={styles.Details}
          >
            {propertyAttributes.map((attr, i) => (
              <div key={`${attr}-${i}`}>{attr}</div>
            ))}
          </div>
          {publicRemarks && (
            <>
              <div className={styles.Divider}>•</div>
              <div className={styles.PublicRemarks}>{publicRemarks}</div>
            </>
          )}
        </div>
      </div>
      {contentRight && (
        <div
          data-hc-name={`${dataHcName}-content-right`}
          className={styles.ContentRight}
        >
          {contentRight}
        </div>
      )}
    </header>
  );
};
