import React, { useState } from 'react';
import classNames from 'classnames';

import { CircleButton } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { Tabs } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { HcCompsIcon } from '@hcs/design-system';
import { PlusIcon } from '@hcs/design-system';
import { CompTypes, MeaningfulEventTypes } from '@hcs/types';
import {
  CompsListTypes,
  CompsListViewType,
  ReportFeatures,
  ReportId,
} from '@hcs/types';
import {
  NavigateToCompSelectionFn,
  NavigateToPropertyPreviewFn,
  NavigateToReportFn,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { COMPS_LIST_VIEW_TYPE_CONFIGS } from '../../../constants';
import {
  useCompsFarmDocument,
  useCompsSelect,
  useReportPermissions,
} from '../../../hooks';
import { useCompsList } from '../../../hooks/useCompsList';
import { useReportConfig } from '../../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../../utils/reportConfig.utils';
import { HoveredCompState } from '../../CompSelectionPage';
import { CompsListMap } from '../CompsListMap';
import { CompsList } from '..';

import styles from './CompsListTabs.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  compsListType: CompsListTypes;
  dataHcEventSectionList?: string;
  dataHcEventSectionMap?: string;
  listViewTypes?: CompsListViewType[];
  isAdjustable?: boolean;
  navigateToReport: NavigateToReportFn;
  navigateToPropertyPreview: NavigateToPropertyPreviewFn;
  navigateToCompSelection: NavigateToCompSelectionFn;
  navigateToCompSelectionDraw: NavigateToCompSelectionFn;
}

export const COMPS_SUMMARY_SECTION_HEIGHT = 894;
export const COMPS_SUMMARY_FEATURES_SALE = [
  ReportFeatures.CompsSelect,
  ReportFeatures.CompsHcSuggested,
];
export const COMPS_SUMMARY_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsSelect,
  ReportFeatures.RentalCompsHcSuggested,
];

export const COMPS_LIST_TABS_ACTION_PORTAL_ID = 'compsListTabsActions';
const dataHcName = 'comps-list-tabs';
export const CompsListTabs = ({
  reportId,
  compType,
  dataHcEventSectionList,
  dataHcEventSectionMap,
  isAdjustable = true,
  compsListType,
  listViewTypes = [
    CompsListViewType.Card,
    CompsListViewType.Table,
    CompsListViewType.Compare,
  ],
  navigateToReport,
  navigateToPropertyPreview,
  navigateToCompSelection,
  navigateToCompSelectionDraw,
}: Props) => {
  const {
    state: { listViewType, compsListQuery },
    actions: { compsListSetListViewType },
  } = useCompsList({ reportId, compType, compsListType });
  const compsFarmQuery = useCompsFarmDocument(reportId, compType);
  const {
    state: { compsListQuery: suggestedCompsQuery },
  } = useCompsList({ reportId, compType, compsListType: 'hcSuggestedComps' });
  const compsSelectMutation = useCompsSelect(reportId, compType);
  const { data: reportConfig } = useReportConfig(reportId);
  const [hoveredComp, setHoveredComp] = useState<HoveredCompState | null>(null);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const { data: reportPermissions } = useReportPermissions(reportId);

  const { isInitialLoading } = combineUseQueryResult([
    suggestedCompsQuery,
    compsListQuery,
    compsFarmQuery,
  ]);

  const hasCompFarm = compsFarmQuery.data?.data.farm
    ? !!Object.keys(compsFarmQuery.data?.data.farm).length
    : false;

  const noCompsMessage =
    'Apply the distance filter to expand your search radius to find more comps.';

  const { data: suggestedCompDocuments } = suggestedCompsQuery;
  const { data: compDocuments } = compsListQuery;

  const isSuggestedOrSelected =
    compsListType === 'hcSuggestedComps' || compsListType === 'selected';

  const isEditableNoAvailableComps =
    !!reportPermissions?.isEditable &&
    suggestedCompDocuments?.length === 0 &&
    compDocuments?.length === 0;

  const navigateToCompDraw = isEditableNoAvailableComps && !hasCompFarm;

  const isRental = compType === CompTypes.Rental;

  const renderedContent = (
    <div className={styles.CompsSummary}>
      {(listViewType === CompsListViewType.Card ||
        isInitialLoading ||
        (!compDocuments?.length && isSuggestedOrSelected)) && (
        <div className={classNames(styles.Column, styles.MapColumn)}>
          <CompsListMap
            reportId={reportId}
            compType={compType}
            dataHcEventSection={dataHcEventSectionMap}
            compsListType={compsListType}
            hoveredComp={hoveredComp}
            key={`${dataHcName}-${compType}-main`}
            scrollZoom={false}
            fitBounds={{ fitOnce: false }}
          />
        </div>
      )}
      <div
        className={classNames(styles.Column, styles.CardColumn)}
        data-hc-event-section={dataHcEventSectionList}
      >
        {isInitialLoading ? (
          <Skeleton dataHcName={`${dataHcName}-skeleton`} />
        ) : !compDocuments?.length && isSuggestedOrSelected ? (
          <div
            data-hc-name={`${dataHcName}-no-comps`}
            className={styles.NoComps}
          >
            <div className={styles.NoCompsRow}>
              <div
                data-hc-name={`${dataHcName}-no-comps-label`}
                className={styles.NoCompsLabel}
              >
                {isRental ? 'Choose your rental comps' : 'Choose your comps'}
              </div>
              <p
                data-hc-name={`${dataHcName}-no-comps-copy`}
                className={styles.NoCompsCopy}
              >
                {isRental
                  ? isEditableNoAvailableComps
                    ? noCompsMessage
                    : `Select your own rental comps, or use HouseCanary’s rental comps based on the similarity to the subject property.`
                  : isEditableNoAvailableComps
                  ? noCompsMessage
                  : `Select your own comps, or use HouseCanary’s comps based on the similarity to the subject property.`}
              </p>
            </div>
            <div
              className={classNames(styles.NoCompsRow, styles.NoCompsButtons)}
            >
              <CircleButton
                dataHcName={`${dataHcName}-select-comps`}
                label={
                  isRental ? 'Self select rental comps' : 'Self select comps'
                }
                size="xl"
                onClick={() =>
                  navigateToCompDraw
                    ? navigateToCompSelectionDraw(reportId, compType)
                    : navigateToCompSelection(reportId, compType)
                }
              >
                <PlusIcon size="xl" />
              </CircleButton>
              {!isEditableNoAvailableComps && (
                <CircleButton
                  dataHcName={`${dataHcName}-use-suggested`}
                  dataHcEventType={MeaningfulEventTypes.Goal}
                  dataHcEventName={
                    isRental
                      ? 'Use Suggested Rental Comps'
                      : 'Use Suggested Sale Comps'
                  }
                  label={
                    isRental
                      ? 'See HouseCanary rental comps'
                      : 'See HouseCanary comps'
                  }
                  size="xl"
                  onClick={() => {
                    const hcAddressIds: number[] = [];
                    suggestedCompsQuery.data.forEach((c) => {
                      if (c.propertyState.hcAddressId) {
                        hcAddressIds.push(c.propertyState.hcAddressId);
                      }
                    });
                    compsSelectMutation.mutate?.({
                      type: 'hcAddressId',
                      hcAddressIds,
                    });
                  }}
                >
                  <HcCompsIcon size="xl" />
                </CircleButton>
              )}
            </div>
          </div>
        ) : (
          <CompsList
            key="CompsList-selected"
            reportId={reportId}
            compType={compType}
            compsListType={compsListType}
            dataHcEventSection={dataHcEventSectionList}
            isAdjustable={isAdjustable}
            includeHeader={false}
            compareActionsPortalIdRender={COMPS_LIST_TABS_ACTION_PORTAL_ID}
            onChangeIsAdjusting={setIsAdjusting}
            navigateToReport={navigateToReport}
            navigateToPropertyPreview={navigateToPropertyPreview}
            onMouseEnter={(hovered: HoveredCompState) => {
              setHoveredComp(hovered);
            }}
            onMouseLeave={() => {
              setHoveredComp(null);
            }}
            primaryCta={{
              actionType: 'custom',
              label:
                compsListType === 'appraisal' ||
                compsListType === 'hcSuggestedComps'
                  ? 'View All Comps'
                  : isRental
                  ? reportPermissions?.isEditable &&
                    reportFeaturesSupportedAny(reportConfig, [
                      ReportFeatures.RentalCompsSelect,
                      ReportFeatures.RentalCompsSelectByAddress,
                      ReportFeatures.RentalCompsSelectByListing,
                    ])
                    ? 'Change Rental Comps'
                    : 'View Rental Comps'
                  : reportPermissions?.isEditable &&
                    reportFeaturesSupportedAny(reportConfig, [
                      ReportFeatures.CompsSelect,
                      ReportFeatures.CompsSelectByAddress,
                      ReportFeatures.CompsSelectByListing,
                    ])
                  ? 'Change Comps'
                  : 'View Comps',
              onClick: () => navigateToCompSelection(reportId, compType),
            }}
            showDisclaimer={false}
          />
        )}
      </div>
    </div>
  );
  return (
    <Tabs
      theme={{
        Tabs: styles.MainTabs,
        TabsSection: styles.TabsSection,
      }}
      active={listViewType}
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSectionList}
      searchParamId={`${dataHcName}-${compType}-main`}
      hideTabs={isAdjusting}
      tabs={
        !isInitialLoading && compDocuments?.length
          ? listViewTypes.map((value) => {
              return {
                value,
                label: `${COMPS_LIST_VIEW_TYPE_CONFIGS[value].name} View`,
                dataHcName: `${dataHcName}-view-toggle-item`,
                onClick: compsListSetListViewType,
                content: renderedContent,
              };
            })
          : [
              {
                value: listViewType,
                label: '',
                onClick: () => undefined,
                content: renderedContent,
              },
            ]
      }
    >
      <ActionButtons
        dataHcName={`${dataHcName}-actions`}
        portalId={COMPS_LIST_TABS_ACTION_PORTAL_ID}
      />
    </Tabs>
  );
};
