import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { Operation } from 'fast-json-patch';

import { jsonPatchApplyEnhanced } from '@hcs/utils';

import { useComponentWillMount } from './useComponentWillMount';

export const useUrlState = <State>(stateId: string, initialState: State) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const serializedState = searchParams.get(stateId);
  let urlState = initialState;
  const clearUrlState = () => {
    searchParams.delete(stateId);
  };
  const setUrlState = (
    newState: State | null | undefined,
    options?: NavigateOptions
  ) => {
    if (newState) {
      searchParams.set(stateId, encodeURIComponent(JSON.stringify(newState)));
    } else {
      clearUrlState();
    }
    setSearchParams(searchParams, { replace: true, ...options });
  };
  if (serializedState) {
    try {
      urlState = JSON.parse(decodeURIComponent(serializedState)) as State;
    } catch (e) {
      // Unsure if it's worth reporting to sentry since this will happen if
      // a user makes a mistake when copying a url or modifies the url
      // Any component using url state wouldn't rely on that state to be present to mount
      // and would then correct the state when it writes to it.
      clearUrlState();
      console.warn('Failed to parse URL state', e);
    }
  }
  useComponentWillMount(() => {
    // Set initialState if passed to hook
    // We don't call setUrlState unless initialState is passed
    // so other instances of the hook using the same stateId
    // can read existing state
    if (initialState && !urlState) {
      setUrlState(initialState);
    }
  });
  return {
    state: urlState,
    actions: {
      setUrlState,
      clearUrlState,
      patchUrlState: (operations: Operation[], options?: NavigateOptions) => {
        const patchedState = jsonPatchApplyEnhanced<State>(
          // Apply enhanced will build out the path of the default object.
          // This lets us support initializing the state with the patchUrlState method, making the feature code simpler
          urlState ? urlState : ({} as State),
          operations
        ).newDocument;
        setUrlState(patchedState, options);
      },
    },
  };
};
