import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DateRange } from '@hcs/types';
import { BuyBoxListItem } from '@hcs/types';

import { dateRangeToFetchBuyBoxActivityArg } from '../utils';
import { FetchBuyBoxActivityArg, LeadFeedApi } from '../';

export const QUERY_KEY_BUY_BOX_ACTIVITY = 'buyBoxActivity';
export const useBuyBoxActivity = (arg: FetchBuyBoxActivityArg | null) => {
  return useQuery(
    [QUERY_KEY_BUY_BOX_ACTIVITY, arg],
    async () => await LeadFeedApi.fetchBuyBoxActivity(arg),
    {
      enabled: arg !== null,
    }
  );
};

const DEFAULT_TOP_NUMBER_BUY_BOXES = 3;
export const useTopBuyBoxesByActivity = (arg: {
  topNumberBuyBoxes?: number;
  dateRange: DateRange | null;
}) => {
  const { topNumberBuyBoxes = DEFAULT_TOP_NUMBER_BUY_BOXES, dateRange } = arg;

  const { data } = useBuyBoxActivity(
    dateRangeToFetchBuyBoxActivityArg(dateRange)
  );

  // get array of objects that includes buy box id and delivered count
  return useMemo(() => {
    // setting topNumberBuyBoxes to 0 is a way to skip this logic
    if (!data || topNumberBuyBoxes === 0) {
      return;
    }
    const deliveredCountArr = Object.values(data).reduce<
      { buyBoxId: BuyBoxListItem['id']; deliveredCount: number }[]
    >((accum, buyBoxActivityFromServer) => {
      if (buyBoxActivityFromServer.enabled) {
        accum.push({
          buyBoxId: buyBoxActivityFromServer.collectionId,
          deliveredCount: buyBoxActivityFromServer.deliveredCountTotal,
        });
      }
      return accum;
    }, []);

    // sort that array by delivered count in descending order
    deliveredCountArr.sort((a, b) => b.deliveredCount - a.deliveredCount);

    // return top n buy boxes
    // if the array length is less than the number asked for, just return whole array
    return deliveredCountArr.length < topNumberBuyBoxes
      ? deliveredCountArr
      : deliveredCountArr.slice(0, topNumberBuyBoxes);
  }, [data, topNumberBuyBoxes]);
};
