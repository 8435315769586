import React from 'react';
import classNames from 'classnames';

import { TextButton } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { Personalization } from '@hcs/types';
import { formatCityStateZip } from '@hcs/utils';

import styles from './PersonalizationHeaderDeprecated.module.css';

export interface PersonalizationHeaderProps {
  personalization: Personalization;
  dataHcName: string;
  className?: string;
}

export const PersonalizationHeaderDeprecated = ({
  personalization,
  dataHcName,
  className,
}: PersonalizationHeaderProps) => {
  const {
    photoUrl,
    includePhoto,
    firstName,
    lastName,
    includeName,
    companyName,
    includeCompanyName,
    phone,
    email,
    websiteUrl,
    includeCompanyLogo,
    companyLogoUrl,
    includeAddressLine1,
    addressLine1,
    includeAddressLine2,
    addressLine2,
    includeCityStateZipcode,
    city,
    state,
    zipcode,
    includeLicenseNumber,
    licenseNumber,
  } = personalization;
  const showExpandSection =
    (includeCityStateZipcode && city && state && zipcode) ||
    (includeAddressLine1 && addressLine1) ||
    (includeLicenseNumber && licenseNumber);
  const { active, handleToggle } = useActiveState();
  return (
    <header data-hc-name={dataHcName}>
      <div
        data-hc-name={`${dataHcName}-default-section`}
        className={classNames(styles.PersonalizationHeader, className)}
      >
        {photoUrl && includePhoto && (
          <div
            className={styles.UserSection}
            data-hc-name={`${dataHcName}-cobrand-user-photo`}
          >
            <div
              style={{ backgroundImage: `url(${photoUrl})` }}
              className={styles.UserPhoto}
            />
          </div>
        )}
        <div className={styles.InfoSection}>
          {(firstName || lastName) && includeName && (
            <div
              data-hc-name={`${dataHcName}-cobrand-fullname`}
              className={styles.UserName}
            >{`${firstName} ${lastName}`}</div>
          )}
          {companyName && includeCompanyName && (
            <div
              className={styles.CompanyName}
              data-hc-name={`${dataHcName}-cobrand-companyname`}
            >
              {companyName}
            </div>
          )}
          <div
            className={styles.ExtendedInfo}
            data-hc-name={`${dataHcName}-cobrand-expanded-section`}
          >
            {(phone || websiteUrl || email) && (
              <div className={styles.ContactInfo}>
                {phone && (
                  <span data-hc-name={`${dataHcName}-cobrand-phone`}>
                    {phone}
                  </span>
                )}
                {email && (
                  <span data-hc-name={`${dataHcName}-cobrand-email-text`}>
                    {email}
                  </span>
                )}
                {websiteUrl && (
                  <div data-hc-name={`${dataHcName}-cobrand-website-url`}>
                    {websiteUrl}
                  </div>
                )}
                {showExpandSection && (
                  <TextButton
                    onClick={handleToggle}
                    dataHcName={`${dataHcName}-expand-control`}
                    className={styles.ExpandButton}
                    label="..."
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className={classNames(styles.Section, 'text-align-right')}>
          {includeCompanyLogo && companyLogoUrl && (
            <img
              alt="Company Logo"
              className={styles.CompanyLogo}
              src={companyLogoUrl}
              data-hc-name={`${dataHcName}-cobrand-company-logo`}
            />
          )}
        </div>
      </div>
      {active && showExpandSection && (
        <div
          data-hc-name={`${dataHcName}-expanded`}
          className={classNames(styles.ExpandedSection, {
            [styles.photo]: photoUrl && includePhoto,
          })}
        >
          {includeAddressLine1 && addressLine1 && (
            <div data-hc-name={`${dataHcName}-address-line-1`}>
              {addressLine1}
            </div>
          )}
          {includeAddressLine2 && addressLine2 && (
            <div data-hc-name={`${dataHcName}-address-line-2`}>
              {addressLine2}
            </div>
          )}
          {includeCityStateZipcode && city && state && zipcode && (
            <div data-hc-name={`${dataHcName}-city-state-zip`}>
              {formatCityStateZip({ city, state, zipcode })}
            </div>
          )}
          {includeLicenseNumber && licenseNumber && (
            <div data-hc-name={`${dataHcName}-license-number-outer`}>
              License Number:{' '}
              <span data-hc-name={`${dataHcName}-license-number`}>
                {licenseNumber}
              </span>
            </div>
          )}
        </div>
      )}
    </header>
  );
};
