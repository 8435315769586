import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { ThumbnailImage } from '@hcs/design-system';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { useKeywordSearchByListingsByCerberusId } from '@hcs/keyword-search';
import { PropertyBrokerageCredit } from '@hcs/property-state';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { ADDRESS_CONFIG } from '@hcs/property-state';
import { getCompFieldConfig, isCompField } from '@hcs/property-state';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { useCompFields } from '@hcs/property-state';
import { TableRowProps } from '@hcs/types';
import {
  CompFields,
  CompTypes,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import {
  CompId,
  CompIdentifier,
  CompSchema,
  CompsFiltersPaths,
  CompsListTypes,
  ReportFeatures,
  ReportId,
} from '@hcs/types';

import {
  useComp,
  useCompCompareDialogSlice,
  useCompsFiltersDocument,
  useDocumentPatch,
  usePhotosPage,
  useSubjectDocument,
} from '../../../hooks';
import { useCompsList } from '../../../hooks/useCompsList';
import { useReportConfig } from '../../../hooks/useReportConfig';
import { useReportPermissions } from '../../../hooks/useReportPermissions';
import { useSubjectPhotos } from '../../../hooks/useSubjectPhotos';
import { compKeywordSearchKey } from '../../../utils';
import { reportFeaturesSupportedAny } from '../../../utils/reportConfig.utils';
import {
  SELECT_CELL_FEATURES_RENTAL,
  SELECT_CELL_FEATURES_SALE,
} from '../../CompFarmList/CompFarmListTableDeprecated/SelectCell';
import { ReportLegalDisclaimers } from '../../ReportLegalDisclaimers';

import { BadgesCell } from './BadgesCell';
import { SelectCell } from './SelectCell';

import styles from './CompsListTable.module.css';

export const COMPS_LIST_TABLE_FEATURES_SALE = [...SELECT_CELL_FEATURES_SALE];
export const COMPS_LIST_TABLE_FEATURES_RENTAL = [
  ...SELECT_CELL_FEATURES_RENTAL,
];
type OnMouseEnter = (hovered: {
  compSchema: CompSchema;
  documentId: string | undefined;
}) => void;

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  compsListType: CompsListTypes;
  showDisclaimer?: boolean;
  onMouseEnter?: OnMouseEnter;
  onMouseLeave?: VoidFunction;
}

const { ContentCell: AddressContentCell, HeaderCell: AddressHeaderCell } =
  ADDRESS_CONFIG;

const dataHcName = 'comps-list-table';

const Row = ({
  compId,
  compType,
  compsListType,
  height,
  columnIds,
  reportId,
  onMouseEnter,
  onMouseLeave,
  onOpen,
  onClose,
  ...tableRowProps
}: {
  compId: CompId;
  compType: CompTypes;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  columnIds: (PropertyStateFields | CompFields)[];
  height: number;
} & Props &
  Omit<TableRowProps, 'onMouseEnter'>) => {
  const compIdentifier = useMemo<CompIdentifier>(
    () => ({
      type: compsListType === 'appraisal' ? 'appraisalComp' : 'compId',
      compType,
      compId,
    }),
    [compType, compId, compsListType]
  );
  const compQuery = useComp(reportId, compIdentifier);
  const { compSchema, documentId } = compQuery.data || {};
  const {
    actions: { compCompareDialogOpen },
  } = useCompCompareDialogSlice();
  const { data: reportConfig } = useReportConfig(reportId);
  const keywordSearchKey = compKeywordSearchKey(compIdentifier.compType);
  const { data: keywordMatches } =
    useKeywordSearchByListingsByCerberusId(keywordSearchKey);
  if (!compSchema) return null;
  const cerberusId =
    compIdentifier.compType === CompTypes.Rental
      ? compSchema.propertyState.listingDetailsRental?.entityId
      : compSchema.propertyState.listingDetailsSale?.entityId;
  const hasKeywordMatch = cerberusId ? keywordMatches[cerberusId] : false;
  /**
   * ReportFeaturesSupported cannot be used inside of Table
   * because the defaultProps trick doesn't work on conditionally rendered columns
   */
  const supportsAppraisalComps =
    compIdentifier.compType === CompTypes.Sold &&
    reportFeaturesSupportedAny(reportConfig, [ReportFeatures.AppraisalComps]);
  const supportsSelectingComps = reportFeaturesSupportedAny(
    reportConfig,
    compType === CompTypes.Rental
      ? SELECT_CELL_FEATURES_RENTAL
      : SELECT_CELL_FEATURES_SALE
  );
  return (
    <TableRow
      {...tableRowProps}
      key={`comp-row-${compId}`}
      onClick={() =>
        compCompareDialogOpen({
          type: 'compId',
          compType,
          compId: compSchema.compID,
        })
      }
      onMouseEnter={() => {
        onMouseEnter?.({ compSchema, documentId });
      }}
      onMouseLeave={onMouseLeave}
      height={height}
    >
      {supportsSelectingComps && (
        <SelectCell
          sticky
          width={80}
          reportId={reportId}
          compIdentifier={compIdentifier}
          className={classNames({
            [styles.keywordSearchMatch]: hasKeywordMatch,
          })}
        />
      )}

      {supportsAppraisalComps && (
        <BadgesCell
          reportId={reportId}
          sticky={true}
          width={80}
          compIdentifier={compIdentifier}
          className={classNames({
            [styles.keywordSearchMatch]: hasKeywordMatch,
          })}
        />
      )}

      <AddressContentCell
        propertyStateArgs={{
          propertyStateType: PropertyStateType.Core,
          propertyState: compSchema.propertyState,
        }}
        sticky
      >
        <PropertyBrokerageCredit
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: compSchema.propertyState,
          }}
          isRental={compType === CompTypes.Rental}
        />
      </AddressContentCell>
      {columnIds.map((columnId) => {
        if (isCompField(columnId)) {
          const { ContentCell } = COMP_FIELDS_CONFIG[columnId];
          return (
            <ContentCell
              key={`column-${compSchema.propertyState.hcAddressId}${columnId}`}
              comp={compSchema}
            />
          );
        } else {
          const { ContentCell } = PROPERTY_STATE_FIELD_CONFIGS[columnId];
          return (
            <ContentCell
              key={`column-${compSchema.propertyState.hcAddressId}${columnId}`}
              propertyStateArgs={{
                propertyStateType: PropertyStateType.Core,
                propertyState: compSchema.propertyState,
              }}
            />
          );
        }
      })}
    </TableRow>
  );
};

// Use defaultProps to satisfy child prop checks in Table
Row.defaultProps = { isTableRow: true };

const SORT_PATH: CompsFiltersPaths = '/data/sort';
const ROW_HEIGHT = 88;
// Needs to be split into separate component to allow hooks to function properly
export const TableContent = (props: Props) => {
  const { reportId, compsListType, compType } = props;
  const { photosPageOpen } = usePhotosPage();
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const { data: compFields } = useCompFields(compType);
  const { data: subjectPhotos } = useSubjectPhotos(reportId);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { data: reportConfig } = useReportConfig(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const {
    state: { compsListQuery },
  } = useCompsList({ reportId, compType, compsListType });
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!compFields || !subjectDocument || !reportPermissions) return null;
  const columnIds = compFields?.order.filter((columnId) => {
    return !!getCompFieldConfig(columnId);
  });
  const onOpen = () => {
    setScrollDisabled(true);
  };
  const onClose = () => {
    setScrollDisabled(false);
  };

  /**
   * ReportFeaturesSupported cannot be used inside of Table
   * because the defaultProps trick doesn't work on conditionally rendered columns
   */
  const supportsAppraisalComps =
    compType === CompTypes.Sold &&
    reportFeaturesSupportedAny(reportConfig, [ReportFeatures.AppraisalComps]);
  const supportsSelectingComps = reportFeaturesSupportedAny(
    reportConfig,
    compType === CompTypes.Rental
      ? SELECT_CELL_FEATURES_RENTAL
      : SELECT_CELL_FEATURES_SALE
  );

  return (
    <Table
      dataHcName={dataHcName}
      scrollDisabled={scrollDisabled}
      belowRows={
        props.showDisclaimer && <ReportLegalDisclaimers reportId={reportId} />
      }
      className={styles.Table}
    >
      <TableHeader sticky height={40}>
        {supportsSelectingComps && <TableHeaderCell sticky width={80} />}
        {supportsAppraisalComps && <TableHeaderCell sticky width={80} />}
        <AddressHeaderCell noLabel />
        {columnIds.map((columnId) => {
          const sort =
            compsListType === 'compIDs' &&
            filterDocument?.data.sort?.field === columnId
              ? filterDocument.data.sort.order
              : undefined;
          const nextSort =
            sort === 'DESC' ? 'ASC' : sort === 'ASC' ? undefined : 'DESC';
          const { HeaderCell } = getCompFieldConfig(columnId);
          return (
            <HeaderCell
              key={`col-header-${columnId}`}
              onClick={
                reportPermissions.isEditable && compsListType === 'compIDs'
                  ? () => {
                      if (filterDocument?.documentId) {
                        if (nextSort !== undefined) {
                          documentPatchMutation.mutate({
                            reportId,
                            document: filterDocument,
                            operations: [
                              {
                                op: 'add',
                                path: SORT_PATH,
                                value: {
                                  field: columnId,
                                  order: nextSort,
                                },
                              },
                            ],
                          });
                        } else {
                          documentPatchMutation.mutate({
                            reportId,
                            document: filterDocument,
                            operations: [
                              {
                                op: 'remove',
                                path: SORT_PATH,
                              },
                            ],
                          });
                        }
                      }
                    }
                  : undefined
              }
              sort={sort}
            />
          );
        })}
      </TableHeader>
      <TableRow height={ROW_HEIGHT} sticky>
        <TableCell
          key="subject-icon-cell"
          width={80}
          sticky
          align="center"
          noBorder
        >
          <div className={styles.SubjectPhoto}>
            <ThumbnailImage
              url={subjectPhotos?.previewPhoto?.url}
              onClick={() => photosPageOpen({ type: 'subject' })}
            />
          </div>
        </TableCell>
        {supportsAppraisalComps && <TableCell sticky width={80} />}
        <AddressContentCell
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: subjectDocument.data.propertyState,
          }}
        />
        {columnIds.map((columnId) => {
          if (isCompField(columnId)) {
            return <TableCell key={`subject-col-${columnId}`} />;
          } else {
            const { ContentCell } = PROPERTY_STATE_FIELD_CONFIGS[columnId];
            return (
              <ContentCell
                key={`subject-col-${columnId}`}
                propertyStateArgs={{
                  propertyStateType: PropertyStateType.Core,
                  propertyState: subjectDocument.data.propertyState,
                }}
              />
            );
          }
        })}
      </TableRow>
      {compsListQuery.data.map((compSchema) => {
        return (
          <Row
            {...props}
            className={styles.TableRow}
            compsListType={compsListType}
            onOpen={onOpen}
            onClose={onClose}
            key={`row-${compSchema.compID}`}
            height={ROW_HEIGHT}
            compId={compSchema.compID}
            columnIds={columnIds}
          />
        );
      })}
    </Table>
  );
};

export const CompsListTable = ({ reportId, ...props }: Props) => {
  const {
    state: {
      compsListQuery: { isInitialLoading },
    },
  } = useCompsList({
    reportId,
    compType: props.compType,
    compsListType: props.compsListType,
  });

  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  return (
    <TableContent
      {...props}
      key={`table-content-${props.compsListType}`}
      reportId={reportId}
    />
  );
};
