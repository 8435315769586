import { ToastProps } from '@hcs/types';

import { useToastSlice } from './useToastSlice';

export const useOnErrorToast = () => {
  const {
    actions: { toastOpen },
  } = useToastSlice();

  return (toastPayload: Omit<ToastProps, 'type'>) => {
    toastOpen({ type: 'error', ...toastPayload });
  };
};
