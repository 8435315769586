import React, { ReactNode } from 'react';

import { LoadingSpinner } from '@hcs/design-system';

import { DATA_HC_NAME_PAGE_AUTH_SPINNER } from '../../constants';
import { useAccount } from '../../hooks/useAccount';

export interface PrivatePageProps {
  unauthenticatedRender: ReactNode;
  children: ReactNode;
}
// Renders Page content (children) if the user is logged in,
// a loading spinner if checking the token,
// value of the `unauthenticatedRender` prop if the token is invalid
export const PrivatePage = ({
  children,
  unauthenticatedRender,
}: PrivatePageProps) => {
  const { isInitialLoading, isSuccess, isFetched } = useAccount();
  if (!isFetched || isInitialLoading) {
    return <LoadingSpinner dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />;
  } else if (isSuccess) {
    return children;
  }
  return unauthenticatedRender;
};
