import {
  CapabilityapiCapabilitiesResponse as CapabilityapiCapabilitiesResponseExt,
  CapabilityapiCapabilityResp as CapabilityapiCapabilityRespExt,
} from './generated/AccountApi.types';
import {
  CapabilityapiCapabilitiesResponse as CapabilityapiCapabilitiesResponseInt,
  CapabilityapiCapabilityExplanationUserResponse as CapabilityapiCapabilityExplanationUserResponseInt,
  CapabilityapiCapabilityResp as CapabilityapiCapabilityRespInt,
  EntitlementapiEntitlementDefResp as EntitlementapiEntitlementDefRespInt,
  EntitlementapiEntitlementOrgResp as EntitlementapiEntitlementOrgRespInt,
} from './generated/ManagerApi.types';

export type CapabilityapiCapabilityExplanationUserResponse =
  CapabilityapiCapabilityExplanationUserResponseInt;
// export type { CapabilityapiCapabilitiesConnectionsResponse as CapabilityapiCapabilitiesConnectionsResponseInt } from './generated/ManagerApi.types';
export type CapabilityapiCapabilitiesConnectionsResponseInt = {
  capability_entitlements?: Record<string, string[]>;
  capability_flags?: Record<string, string[]>;
  capability_hierarchy?: Record<string, string[]>;
  capability_permissions?: Record<string, string[]>;
  permission_roles?: Record<string, string[]>;
};
export type OrgEntitlementStatus = 'active' | 'expired' | 'upcoming';
export interface OrgEntitlementInt extends EntitlementapiEntitlementOrgRespInt {
  status: OrgEntitlementStatus;
}

export enum CapabilityApplications {
  AcquisitionExplorer = 'aexp',
  AgileSuite = 'agile-suite',
  BulkPropertyData = 'bulk-data',
  BulkHpi = 'bulk-hpi',
  BulkRpi = 'bulk-rpi',
  CanaryAI = 'canaryai',
  DataExplorer = 'dexp',
  InternalAgileOps = 'internal-agile-ops',
  InternalBulkData = 'internal-bulk-data',
  InternalEngTools = 'internal-eng-tools',
  InternalClientAdmin = 'internal-client-admin',
  InternalPlatformAdmin = 'internal-platform-admin',
  MarketInsights = 'market-insights',
  MarketPulse = 'market-pulse',
  OrgSettings = 'org-settings',
  Platform = 'platform',
  PortfolioMonitor = 'portfolio',
  PropertyExplorer = 'pexp',
  UserSettings = 'user-settings',
}

// TODO: Add this to generated types via custom script
export type CapabilityKeys =
  | 'appraisal-compare-report'
  | 'effective-date-dynamic-report'
  | 'effective-date-static-report'
  | 'offer-now'
  | 'pexp-dynamic-report'
  | 'pexp-editable-links'
  | 'pexp-editable-links-forgo-scrubbing'
  | 'pexp-manage-org-settings'
  | 'pexp-product-access'
  | 'pexp-static-report'
  | 'marketingavmdetails'
  | 'subdivisiondetails'
  | 'bestpropertycharacteristics'
  | 'mlspropertycharacteristics'
  | 'publicrecordpropertycharacteristics'
  | 'schooldetails'
  | 'crimedetails'
  | 'statesalelistingstats'
  | 'statehomepriceindex'
  | 'staterentalyieldstats'
  | 'affordabilitystats'
  | 'msarentallistingstats'
  | 'msasalelistingstats'
  | 'msahomepriceindex'
  | 'msarentalyieldstats'
  | 'populationstats'
  | 'unemploymentstats'
  | 'ziprentallistingstats'
  | 'zipsalelistingstats'
  | 'ziprentalyieldstats'
  | 'ziphomepriceindex'
  | 'saleavmdetails'
  | 'rentalvaluedetails'
  | 'propensitytosell'
  | 'condition'
  | 'create-api-key'
  | 'create-test-api-key'
  // Typical org-admin capabilities
  | 'invite-users'
  | 'org-sso-config'
  | 'org-usage-queries'
  | 'pexp-manage-org-settings'
  | 'view-org-billing'
  | 'manage-user-permissions'
  // Internal Capabilities
  | 'client-admin-edit';

export enum CapabilityCategories {
  AppAccess = 'app-access',
  ProductAccess = 'product-access',
  RushCharge = 'rush-charge',
  CancellationCharge = 'cancellation-charge',
  Endpoint = 'endpoint',
  Fragment = 'fragment',
  Report = 'report',
}

export type Capability = Required<
  Omit<
    CapabilityapiCapabilityRespInt | CapabilityapiCapabilityRespExt,
    'key' | 'category' | 'application'
  >
> & {
  key: CapabilityKeys;
  category: CapabilityCategories | null;
  application: CapabilityApplications;
};

export type CapabilitiesResponse = Omit<
  CapabilityapiCapabilitiesResponseInt | CapabilityapiCapabilitiesResponseExt,
  'capabilities'
> & {
  capabilities: Capability[];
};

export type EntitlementDef = EntitlementapiEntitlementDefRespInt;

export type AccountApplicationType =
  | 'all'
  | 'external'
  | 'product'
  | 'external-app'
  | 'internal';

export type CapabilitiesFilters = Partial<{
  applicationType: AccountApplicationType;
  application: CapabilityApplications | null;
  category: string | null;
  search: string | null;
}>;

export type ApplicationsFilters = Partial<{
  applicationType: AccountApplicationType;
}>;

export type EntitlementDefsFilters = Partial<{
  application: CapabilityApplications | null;
  search: string | null;
}>;

export interface CapabilitiesCheckParams {
  capabilityKeys: CapabilityKeys[];
  operation: 'AND' | 'OR';
}
