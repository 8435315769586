import React, { useMemo } from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { ListingStatusCerberus } from '@hcs/types';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompsFarmSchema, ReportId } from '@hcs/types';
import { formatListingStatusNormalized, formatNumber } from '@hcs/utils';
import { listingStatusNormalized } from '@hcs/utils';

import { useCompsFarmDocument } from '../../hooks';

import styles from './CompCounts.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}

const dataHcName = 'comp-counts';

export const CompCounts = ({ reportId, className, compType }: Props) => {
  const farmQuery = useCompsFarmDocument(reportId, compType);
  // TS didn't catch a type error here  so I'm annotating this var to catch it next time.
  const farm: CompsFarmSchema['farm'] | undefined = farmQuery.data?.data.farm;
  const counts = useMemo(() => {
    const result = {
      [ListingStatusCerberus.Active]: 0,
      [ListingStatusCerberus.Pending]: 0,
      [ListingStatusCerberus.Closed]: 0,
      total: 0,
    };
    if (farm) {
      Object.entries(farm).forEach((entry) => {
        const comp = entry[1];
        const normalized = listingStatusNormalized(
          getPropertyStateFieldValue(
            compType === CompTypes.Sold
              ? PropertyStateFields.currentStatus
              : PropertyStateFields.currentStatusRental,
            {
              propertyStateType: PropertyStateType.Core,
              propertyState: comp.propertyState,
            }
          )
        );
        if (normalized) {
          result[normalized]++;
        }
        result.total++;
      });
    }
    return result;
  }, [farm, compType]);
  if (farmQuery.isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  } else if (farmQuery.isError || !farmQuery.data) {
    return <div>An error occurred</div>;
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompCounts, className)}
    >
      <h3 data-hc-name={`${dataHcName}-title`}>Comp Counts</h3>
      <div>
        {/* ACTIVE */}
        <div
          data-hc-name={`${dataHcName}-active`}
          className={classNames(styles.Row, styles.active)}
        >
          <div
            data-hc-name={`${dataHcName}-active-label`}
            className={styles.Label}
          >
            {formatListingStatusNormalized(ListingStatusCerberus.Active)}
          </div>
          <div data-hc-name={`${dataHcName}-active-count`}>
            {formatNumber(counts[ListingStatusCerberus.Active])}
          </div>
        </div>
        {/* PENDING */}
        <div
          data-hc-name={`${dataHcName}-pending`}
          className={classNames(styles.Row, styles.pending)}
        >
          <div
            data-hc-name={`${dataHcName}-pending-label`}
            className={styles.Label}
          >
            {formatListingStatusNormalized(ListingStatusCerberus.Pending)}
          </div>
          <div data-hc-name={`${dataHcName}-pending-count`}>
            {formatNumber(counts[ListingStatusCerberus.Pending])}
          </div>
        </div>
        {/* SOLD/LEASED */}
        <div
          data-hc-name={`${dataHcName}-${ListingStatusCerberus.Closed.toLowerCase()}`}
          className={classNames(styles.Row, styles.sold)}
        >
          <div
            data-hc-name={`${dataHcName}-${ListingStatusCerberus.Closed.toLowerCase()}-label`}
            className={styles.Label}
          >
            {formatListingStatusNormalized(ListingStatusCerberus.Closed)}
          </div>
          <div
            data-hc-name={`${dataHcName}-${ListingStatusCerberus.Closed.toLowerCase()}-count`}
          >
            {formatNumber(counts[ListingStatusCerberus.Closed])}
          </div>
        </div>
        {/* TOTAL */}
        <div data-hc-name={`${dataHcName}-total`} className={styles.Row}>
          <div
            data-hc-name={`${dataHcName}-total-label`}
            className={styles.Label}
          >
            Total
          </div>
          <div data-hc-name={`${dataHcName}-total-count`}>
            {formatNumber(counts.total)}
          </div>
        </div>
      </div>
    </div>
  );
};
