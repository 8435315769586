import { useQuery } from '@tanstack/react-query';

import { logException } from '@hcs/utils';

import { AuthzApi } from '../api';

export const QUERY_KEY_CAPABILITIES_FOR_USER =
  'QUERY_KEY_CAPABILITIES_FOR_USER';
export const useCapabilitiesForUser = (userId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_CAPABILITIES_FOR_USER, userId],
    async () => {
      if (!userId) {
        logException('useCapabiltiiesForUser called with undefined userId');
        throw new Error('Failed to get user capabilities');
      }
      const response = await AuthzApi.getCapabilitiesForUser(userId);
      return response.capabilities;
    },
    {
      enabled: !!userId,
    }
  );
};
