import { useMutation } from '@tanstack/react-query';

import { ViewOrderApi } from '../api/viewOrderApi';

export const useOrderItemPdfDownload = () => {
  return useMutation(
    async ({
      orderItemId,
      orderId,
      pdfType,
    }: {
      orderItemId: number;
      orderId: number;
      pdfType: string;
    }) => {
      return await ViewOrderApi.fetchOrderItemPdfDownload(
        orderItemId,
        orderId,
        pdfType
      );
    }
  );
};
