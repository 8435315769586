import React from 'react';

import { MonthsAgoSelect } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompsFiltersPaths, ReportId } from '@hcs/types';
import { formatDateLong } from '@hcs/utils';

import { FilterCard } from '../../components-deprecated';
import { useCompsFiltersDocument, useSubjectDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const FIELD = PropertyStateFields.lastCloseDateRental;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const dataHcName = `comp-filter-${FIELD}`;
export const CompFilterCloseDateRental = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!filterDocument || !subjectDocument || !reportPermissions) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters?.[FIELD];
  const subjectValue = getPropertyStateFieldValue(FIELD, {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument.data.propertyState,
  });
  return (
    <FilterCard
      label="Leased Date"
      info={
        <div>
          {subjectValue === null
            ? 'Missing Subject Value'
            : `Subject: ${formatDateLong(subjectValue)}`}
        </div>
      }
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={FIELD}
        />
      }
    >
      <MonthsAgoSelect
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.relativeValue || null}
        className={className}
        disabled={!reportPermissions.isEditable}
        onChange={(value) => {
          if (value === null) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_REL,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    </FilterCard>
  );
};
