import React, { useState } from 'react';

import { OverlayPage, OverlayPageProps } from '../../layout/OverlayPage';

import { PhotosPage, PhotosPageProps } from './PhotosPage';

export interface PhotosOverlayPageProps extends PhotosPageProps {
  overlayPageProps: Pick<OverlayPageProps, 'active' | 'headerProps'>;
}

export const PhotosOverlayPage = ({
  overlayPageProps,
  ...photosPageProps
}: PhotosOverlayPageProps) => {
  const [fullscreenActive, setFullscreenActive] = useState(false);
  return (
    <OverlayPage
      dataHcName={`${photosPageProps.dataHcName}-overlay-page`}
      {...overlayPageProps}
      dataHcEventSection={photosPageProps.dataHcEventSection}
      // Do not close page when the Escape key is pressed while the fullscreen carousel is open
      headerProps={{
        ...overlayPageProps.headerProps,
        justifyContent: 'spaceBetween',
        onBack: () => {
          if (!fullscreenActive) {
            overlayPageProps.headerProps?.onBack?.();
          }
        },
      }}
    >
      <PhotosPage
        {...photosPageProps}
        onToggleFullscreen={(value: boolean) => {
          photosPageProps.onToggleFullscreen?.(value);
          setFullscreenActive(value);
        }}
      />
    </OverlayPage>
  );
};
