import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReportConfigSliceState } from '@hcs/types';
import { resetOnLogoutAction } from '@hcs/utils';

const initialState: ReportConfigSliceState = {};
export const reportConfigSlice = createSlice({
  name: 'reportConfig',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(resetOnLogoutAction, () => initialState),
  reducers: {
    setReportFeatureSupport(
      state,
      action: PayloadAction<ReportConfigSliceState>
    ) {
      return action.payload;
    },
    setReportFeatureSupportPartial(
      state,
      action: PayloadAction<ReportConfigSliceState>
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});
