import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { LineClamp } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
} from '@hcs/types';
import { formatMissing } from '@hcs/utils';

const FIELD = PropertyStateFields.concessionBuyerDescription;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Concession Buyer Description';
const labelShort = 'Conc Buyer Dscp';
const propertyStatePath: PropertyStatePaths =
  '/listingDetailsSale/concessions/buyerDescription';

const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.listingDetailsSale?.concessions?.buyerDescription;
  }
  return undefined;
};

const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatMissing(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  formatValue(propertyStateArgs);

const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};

const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <LineClamp lines={3} isLargeTooltip renderChildrenAs="tooltipDescription">
        <Display propertyStateArgs={propertyStateArgs} />
      </LineClamp>
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const CONCESSION_BUYER_DSCP: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
};
