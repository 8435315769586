import {
  CompSchema,
  GeoPrecision,
  ListingStatusCerberus,
  PropertyStateCore,
  PropertyTypeEnum,
} from '@hcs/types';

export const SAMPLE_PROPERTY_STATE_CORE: PropertyStateCore = {
  hcAddressId: 25632694,
  location: {
    address: '123 Main St',
    addressSlug: '8648-N-64th-Pl-Paradise-Valley-AZ-85253',
    blockGroupId: '040131050031',
    blockId: '040131050031005',
    city: 'Sample City',
    completeYN: true,
    county: 'Maricopa County',
    fips: '04013',
    geoPrecision: GeoPrecision.Rooftop,
    latitude: 33.5626297,
    longitude: -111.9412384,
    metroDivisionId: '',
    msaId: '38060',
    realYN: true,
    state: 'AZ',
    tractId: '04013105003',
    unit: '',
    verifiedYN: true,
    zipcode: '85253',
    zipcodePlus4: '1828',
    neighborhood: null,
    subdivision: 'CAMELBACK COUNTRY ESTATES',
  },
  propertyDetails: {
    bathrooms: {
      totalProjected: 4.5,
    },
    bedrooms: 4,
    livingArea: 4320,
    lotSize: 44695,
    propertyType: PropertyTypeEnum.Sfr,
    propertyTypeDescription: 'Residential (Single Family Residence)',
    apn: '174-32-048',
    association: [
      {
        fee: 1430,
        frequency: 'Annually',
        name: 'Camelback Country Est',
        phone: '602-863-3600',
      },
    ],
    associationFeeIncludes: 'Common Area Maint',
    basement: {
      has: null,
    },
    hasAssociation: true,
    parking: {
      total: null,
      garage: 3,
      parkingDetails: [
        {
          count: 6,
          type: 'Open',
        },
        {
          count: 3,
          type: 'Covered',
        },
        {
          count: 3,
          type: 'Garage',
        },
      ],
    },
    poolYN: true,
    storiesNumber: 1,
    tax: {
      amountAnnual: 6393.24,
      year: 2022,
    },
    yearBuilt: 1983,
    zoning: {
      code: 'R-43',
    },
  },
  taxDetails: {
    ownership: {
      ownerOccupied: true,
      lastUpdatedDate: '2022-11-07',
    },
  },
  propertyValue: {
    fsd: 0.1259003,
    fsd90Percent: 0.23778553307056427,
    value: 1268710,
    valueLower: 1108979,
    valueMedian: 1005389,
    valueUpper: 1428441,
    valueAtSixConditions: {
      conditionClass: 5,
      neighborhoodConditionClass: 4,
      valueAtCondC1: 1410526,
      valueAtCondC2: 1268710,
      valueAtCondC3: 996366,
      valueAtCondC4: 946423,
      valueAtCondC5: 890073,
      valueAtCondC6: 854470,
    },
  },
  propertyValueRental: {
    fsd: 0.411601,
    fsd90Percent: null,
    value: 9163,
    valueLower: 5392,
    valueMedian: null,
    valueUpper: 12935,
  },
  complexFieldsSale: {
    currentHcMlsId: 46,
    currentListingId: '6466502',
    currentPrice: 2800000,
    currentStatus: ListingStatusCerberus.Closed,
    currentStatusDate: '2023-02-05',
    currentlyOnMarket: false,
    currentHcpyLookupKey: 'DF_ARMLS#6466502#A',
    currentSourceId: 'df-ARMLS',
    currentDaysOnMarketCumulative: 138,
    currentDaysToCloseCumulative: 138,
    lastCloseHcMlsId: 46,
    lastCloseHcpyLookupKey: 'DF_ARMLS#6025622#A',
    lastCloseListingId: '6025622',
    currentArmsLength: true,
    currentDistressed: false,
    currentFlipYN: false,
    currentListDate: '2022-09-20',
    currentListingPrice: 2800000,
    lastCloseDate: '2020-06-23',
    lastClosePrice: 1800000,
  },
  complexFieldsRental: {
    currentHcMlsId: null,
    currentListingId: null,
    currentPrice: null,
    currentStatus: null,
    currentStatusDate: null,
    currentlyOnMarket: null,
    currentHcpyLookupKey: null,
    currentSourceId: null,
    lastCloseHcMlsId: null,
    lastCloseHcpyLookupKey: null,
    lastCloseListingId: null,
    currentDaysOnMarketCumulative: null,
    currentDaysToCloseCumulative: null,
    currentListDate: null,
    currentListingPrice: null,
    lastCloseSourceId: null,
    lastCloseDate: null,
    lastClosePrice: null,
    lastCloseSourceType: null,
  },
  listingDetailsSale: {
    entityId: 'fb6a404781d4b702',
    hcMlsId: 46,
    listingId: '6466502',
    agentOffice: [
      {
        email: null,
        licenseNumber: null,
        name: null,
        office: null,
        phone: null,
        type: 'buyer',
      },
      {
        email: null,
        licenseNumber: null,
        name: null,
        office: null,
        phone: null,
        type: 'coBuyer',
      },
      {
        email: 'libby@libbycohen.com',
        licenseNumber: 'SA572812000',
        name: 'Libby Cohen',
        office: 'RETSY',
        phone: '602-291-1446',
        type: 'list',
      },
      {
        email: 'julierohr@gmail.com',
        licenseNumber: null,
        name: 'Julie Rohr',
        office: null,
        phone: '602-317-5667',
        type: 'coList',
      },
      {
        email: null,
        licenseNumber: null,
        name: null,
        office: null,
        phone: null,
        type: 'coList2',
      },
      {
        email: null,
        licenseNumber: null,
        name: null,
        office: null,
        phone: null,
        type: 'coList3',
      },
    ],
    remarks: [
      {
        type: 'Public',
        value:
          "With spectacular mountain views overlooking the 18th fairway of the Camelback Country Club Golf Course, buyers will love this fully remodeled, soft contemporary styled home. Soaring ceilings and an open floor plan create a light filled haven thoughtfully designed for easy living and fun-filled entertaining. Four generously sized ensuite bedrooms offer flexibility and convenience, while the great room and dining room opening to the chef's kitchen are perfect for cozy evenings at home or large-scale gatherings. Fabulous finishes include custom cabinetry, Quartz countertops, and low maintenance porcelain flooring. The fully remodeled kitchen features a large island, stainless appliances, and a fabulous walk-in pantry that doubles as a bar!",
      },
    ],
  },
  listingDetailsRental: {},
  calculatedFields: {
    saleType: 'Arms-Length',
    age: 40,
    livingAreaFieldsSale: {
      lastClosePricePerSqFt: 416.6666666666667,
      currentListingPricePerSqFt: 648.1481481481482,
      currentPricePerSqFt: 648.1481481481482,
      propertyValueMeanPerSqFt: 630.8641203703704,
    },
    livingAreaFieldsRental: {
      lastClosePricePerSqFt: 416.6666666666667,
      currentListingPricePerSqFt: 648.1481481481482,
      currentPricePerSqFt: 648.1481481481482,
      propertyValueMeanPerSqFt: 630.8641203703704,
    },
  },
};

export const SAMPLE_COMP_DATA: CompSchema = {
  compID: '_935127_66_20612750',
  distance: 0.7788398542376964,
  locationSimilarity: 'Similar',
  hcAdjustments: {
    hcAdjustmentDate: 123,
    hcAdjustmentPropertyDetails: 123,
  },
  similarity: {
    level: null,
    score: null,
    levelAdjusted: null,
    scoreAdjusted: null,
    salesPriceAdjusted: null,
  },
  adjustedAVM: {
    toDate: null,
    fromDate: null,
    fromValue: null,
    adjustedBy: {
      msa: null,
      zip: null,
      block: null,
      state: null,
      blockgroup: null,
    },
    adjustedByMax: null,
    adjustedByMin: null,
  },
  propertyState: { ...SAMPLE_PROPERTY_STATE_CORE },
  adjustedCompValue: {
    value: 1000,
    valuePerSqFt: 1,
  },
};
