export const SEARCH_BY_MLS_NUMBER_SUBMIT = 'SEARCH_BY_MLS_NUMBER_SUBMIT';

export interface SearchByMlsNumberSubmitAction {
  type: typeof SEARCH_BY_MLS_NUMBER_SUBMIT;
  payload: { queryId: string; input: string };
}
export const searchByMlsNumberSubmit = (
  queryId: string,
  input: string
): SearchByMlsNumberSubmitAction => {
  return {
    type: SEARCH_BY_MLS_NUMBER_SUBMIT,
    payload: {
      queryId,
      input
    }
  };
};

export type SearchByMlsNumberActions = SearchByMlsNumberSubmitAction;
