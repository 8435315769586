import React from 'react';

import { ReportSummaryOrderItemsCellContentProps } from '@hcs/types';

import { DATA_HC_NAME_REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELL } from '../../../constants/ReportSummary.constants';

import { createReportSummaryOrderItemsTableCellConfig } from './ReportSummaryOrderItemsTableCell';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELL}-address`;
const CellContentComponent = ({
  orderItem,
}: ReportSummaryOrderItemsCellContentProps) => (
  <div data-hc-name={dataHcName}>{orderItem.address}</div>
);

export const REPORT_SUMMARY_ORDER_ITEMS_ADDRESS_CONFIG =
  createReportSummaryOrderItemsTableCellConfig({
    CellContentComponent,
    tableHeaderName: 'Address',
  });
