import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useDynamicRefs } from '@hcs/hooks';

import styles from './Toggle.module.css';

interface ToggleTheme {
  Toggle?: string;
  Option?: string;
  SelectedMask?: string;
  selected?: string;
  disabled?: string;
  disabledOption?: string;
}

export interface ToggleOption<T> {
  label: string | number | ReactNode;
  value: T;
  disabled?: boolean;
  /** Selector for automated testing. */
  dataHcName?: string;
}

export interface ToggleProps<T> {
  /** Options for the toggle. Format: { label: <string>, value: <any> }  */
  options: Array<ToggleOption<T>>;
  /** Callback executed on select, with the selected option's value as the argument */
  onChange: (value: T) => void;
  /** Whether the control is disabled */
  disabled?: boolean;
  /** The value that should be shown as selected */
  value: T;
  /** Selector for automated testing */
  dataHcName: string;
  /** Class Name */
  className?: string;
  style?: React.CSSProperties;
  theme?: ToggleTheme;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  fullEvenWidth?: boolean;
}

export const Toggle = <T extends string | number | null | boolean>({
  className,
  value,
  options,
  onChange,
  disabled,
  dataHcName,
  style,
  theme,
  primary = true,
  secondary = false,
  tertiary = false,
  fullEvenWidth,
}: ToggleProps<T>) => {
  const [width, setWidth] = useState(0);
  const [setRef, getRef] = useDynamicRefs<HTMLDivElement>();
  const calcWidth = () => {
    let largestOptionsWidth = 0;
    options.forEach((option, i) => {
      const ref = getRef(`option-${i}`);
      if (ref?.current) {
        largestOptionsWidth = Math.max(
          ref.current.offsetWidth,
          largestOptionsWidth
        );
      }
    });
    setWidth(largestOptionsWidth);
  };
  useEffect(() => {
    // only calc width when not using even-full-width options
    if (!fullEvenWidth) {
      calcWidth();
    }
  }, [options]);
  let selectedIndex = 0;

  const isPrimaryStyle = primary && !secondary && !tertiary;

  return (
    <div
      style={{
        ...style,
        visibility: width || fullEvenWidth ? undefined : 'hidden',
      }}
      className={classNames(styles.Toggle, theme?.Toggle, className, {
        [styles.disabled]: disabled,
        [styles.Primary]: isPrimaryStyle,
        [styles.Secondary]: !isPrimaryStyle && secondary,
        [styles.Tertiary]: !isPrimaryStyle && tertiary,
        [styles?.fullEvenWidth]: fullEvenWidth,
      })}
      data-hc-name={dataHcName}
      role="listbox"
    >
      <div className={styles.OptionsContainer}>
        {options.map((option, i) => {
          const isSelected = option.value === value;
          if (isSelected) {
            selectedIndex = i;
          }
          return (
            <div
              key={`toggle-${option.value}`}
              ref={setRef(`option-${i}`)}
              style={width ? { width: `${width}px` } : undefined}
              className={classNames(styles.Option, theme?.Option, {
                [styles.disabledOption]: option.disabled,
                [theme?.disabledOption || '']: option.disabled,
                [styles.selected]: isSelected,
                [theme?.selected || '']: isSelected,
                [styles.fullEvenWidth]: fullEvenWidth,
              })}
              data-hc-name={option.dataHcName || `${dataHcName}-option-${i}`}
              onClick={() => {
                if (!disabled && !option.disabled) {
                  onChange(option.value);
                }
              }}
              role="option"
              aria-selected={isSelected}
            >
              {option.label || option.value}
            </div>
          );
        })}
      </div>
      <div
        className={classNames(styles.SelectedMask, theme?.SelectedMask)}
        style={{
          width: `${width}px`,
          left: `${width * selectedIndex}px`,
        }}
      />
      <input
        readOnly
        type="hidden"
        value={`${dataHcName}-option-${selectedIndex}`}
        data-hc-name={`${dataHcName}-value`}
      />
    </div>
  );
};
