import React, { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { AddressSearch } from '@hcs/address-search';
import { Button } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { Dropdown } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import {
  AddAddressFormFields,
  AddressFormFields,
  CreateNewOrderState,
  ITEM_FIELD_NAMES,
  ItemFieldMeta,
  OmPropertyType,
  OrderTypeDescriptor,
  STEP,
} from '@hcs/types';

import { getItemFieldMetaByOrderType, getRequiredErrorText } from '../utils';

import styles from './AddAddress.module.css';

interface Props {
  selectedOrderType?: OrderTypeDescriptor;
  addressFormFields?: AddressFormFields[];
  updateStep: (stateToUpdate: Partial<CreateNewOrderState>) => void;
  uploadAddress?: {
    uploadAddressSubmit: (addressFormFields: AddressFormFields[]) => void;
    uploadAddressSubmitIsLoading: boolean;
  };
}

const defaultFormFieldsState: AddAddressFormFields = {
  addressMeta: [
    {
      customerItemId: '',
      propertyType: '',
      specialCommentsInspection: '',
      specialCommentsAmc: '',
      inspectionContactName: '',
      inspectionContactPhone: '',
      inspectionContactPhoneIsCell: false,
      inspectionContactEmail: '',
      secondaryInspectionContactName: '',
      secondaryInspectionContactPhone: '',
      secondaryInspectionContactPhoneIsCell: false,
      secondaryInspectionContactEmail: '',
      address: null,
      itemSupportingDocumentFields: null,
    },
  ],
};

const dataHcName = 'add-address-form';
export const AddAddress = ({
  selectedOrderType,
  addressFormFields,
  updateStep,
  uploadAddress,
}: Props) => {
  const initialState = addressFormFields && {
    addressMeta: addressFormFields.map((addressFormField) => {
      const { addressId, addressFullLine, ...rest } = addressFormField;
      const addressFormFieldsMeta = {
        ...rest,
        address: {
          id: addressId,
          fields: {
            fullLine: addressFullLine,
          },
        },
      };

      return addressFormFieldsMeta;
    }),
  };

  const { handleSubmit, control } = useForm<AddAddressFormFields>({
    defaultValues: initialState || defaultFormFieldsState,
  });

  const { fields, append, remove } = useFieldArray<
    AddAddressFormFields,
    'addressMeta',
    'addressMetaId'
  >({
    control: control,
    name: 'addressMeta',
    keyName: 'addressMetaId',
  });

  const [showSecondaryContact, setShowSecondaryContact] = useState<{
    [key: number]: boolean;
  }>({ 0: false });
  const toggleSecondaryContactAtIdx = (idx: number) => {
    const currValue = showSecondaryContact[idx] || false;
    setShowSecondaryContact({
      ...showSecondaryContact,
      [idx]: !currValue,
    });
  };

  if (!selectedOrderType) return null;

  const itemFieldMeta: ItemFieldMeta =
    getItemFieldMetaByOrderType(selectedOrderType);
  const propertyTypeChoices: OmPropertyType[] =
    selectedOrderType?.propertyTypes || [];

  return (
    <div data-hc-name={dataHcName}>
      <div className={styles.AddressTitleContainer}>
        <h3>Add addresses</h3>
        <div
          onClick={() => {
            updateStep({
              step: STEP.ADD_ADDRESS_METHOD,
              itemsSource: 'csv',
            });
          }}
        >
          Switch to bulk file upload
        </div>
      </div>
      <div>
        <form
          onSubmit={handleSubmit(
            (addAddressFormFields: AddAddressFormFields) => {
              const addressFormFields = addAddressFormFields.addressMeta.map(
                (addressMeta) => {
                  const { address, ...rest } = addressMeta;

                  return {
                    ...rest,
                    addressId: address?.id || '',
                    addressFullLine: address?.fields?.fullLine || '',
                  };
                }
              );
              uploadAddress
                ? uploadAddress.uploadAddressSubmit(addressFormFields)
                : updateStep({
                    step: STEP.ORDER_DETAILS,
                    addressFormFields,
                  });
            }
          )}
        >
          {fields.map((item, addressInputIdx) => {
            return (
              <div
                key={item.addressMetaId}
                className={styles.AddressInputContainer}
              >
                {fields.length > 1 && (
                  <div className={styles.AddressDivider}>
                    <div className={styles.AddressDividerNum}>
                      {addressInputIdx + 1}
                    </div>
                  </div>
                )}
                <Controller
                  name={`addressMeta.${addressInputIdx}.address`}
                  control={control}
                  rules={{
                    validate: (addressInput) =>
                      !!(
                        addressInput &&
                        addressInput.fields &&
                        addressInput.fields.fullLine
                      ),
                  }}
                  render={({ field, fieldState: { invalid } }) => {
                    return (
                      <>
                        <AddressSearch
                          focusOnMount
                          className={styles.Input}
                          placeholder="Address *"
                          onSelect={field.onChange}
                          onChange={field.onChange}
                          onClearSelection={() => field.onChange(null)}
                          fallbackValue={
                            field.value?.fields?.fullLine || undefined
                          }
                          hideChevron
                          inputStyle="form"
                        />
                        {invalid && (
                          <div className={styles.ErrorText}>
                            {getRequiredErrorText('Address')}
                          </div>
                        )}
                      </>
                    );
                  }}
                />
                {(itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID].shown ||
                  itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE].shown) && (
                  <div className={styles.InputSameRow}>
                    {itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID].shown && (
                      <Controller
                        name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID}`}
                        control={control}
                        rules={{
                          required:
                            itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID]
                              .required,
                        }}
                        render={({ field, fieldState: { invalid } }) => (
                          <Input
                            className={styles.Input}
                            dataHcName={`${dataHcName}-client-file-id-input`}
                            placeholder={`Client File ID ${
                              itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID]
                                .required
                                ? '*'
                                : ''
                            }`}
                            error={
                              invalid
                                ? getRequiredErrorText('Client File ID')
                                : undefined
                            }
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    )}
                    {itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE].shown && (
                      <Controller
                        name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.LOAN_VALUE}`}
                        control={control}
                        rules={{
                          required: itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE]
                            .required
                            ? getRequiredErrorText('Loan Value')
                            : undefined,
                          validate: (value) => {
                            if (value) {
                              const regex = /^\d+$/g;
                              return regex.test(value)
                                ? true
                                : 'Loan Value must be a number';
                            } else {
                              return true;
                            }
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            className={styles.Input}
                            dataHcName={`${dataHcName}-loan-id-input`}
                            placeholder={`Loan Value ${
                              itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE]
                                .required
                                ? '*'
                                : ''
                            }`}
                            error={error?.message}
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    )}
                  </div>
                )}
                <div className={styles.InputSameRow}>
                  {itemFieldMeta[ITEM_FIELD_NAMES.PROPERTY_TYPE].shown && (
                    <Controller
                      name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.PROPERTY_TYPE}`}
                      control={control}
                      rules={{
                        required:
                          itemFieldMeta[ITEM_FIELD_NAMES.PROPERTY_TYPE]
                            .required,
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Dropdown
                          className={styles.Input}
                          dataHcName={`${dataHcName}-property-type-input`}
                          onSelect={field.onChange}
                          value={field.value}
                          placeholder={`Property Type ${
                            itemFieldMeta[ITEM_FIELD_NAMES.PROPERTY_TYPE]
                              .required
                              ? '*'
                              : ''
                          }`}
                          error={
                            invalid
                              ? getRequiredErrorText('Property Type')
                              : undefined
                          }
                          options={propertyTypeChoices}
                        />
                      )}
                    />
                  )}
                  {itemFieldMeta[ITEM_FIELD_NAMES.COUNTY].shown && (
                    <Controller
                      name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.COUNTY}`}
                      control={control}
                      rules={{
                        required:
                          itemFieldMeta[ITEM_FIELD_NAMES.COUNTY].required,
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Input
                          className={styles.Input}
                          dataHcName={`${dataHcName}-county-input`}
                          placeholder={`County ${
                            itemFieldMeta[ITEM_FIELD_NAMES.COUNTY].required
                              ? '*'
                              : ''
                          }`}
                          error={
                            invalid ? getRequiredErrorText('County') : undefined
                          }
                          {...field}
                          value={field.value || ''}
                        />
                      )}
                    />
                  )}
                </div>
                {itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME]
                  .shown && (
                  <div>
                    <div className={styles.InputSameRow}>
                      <Controller
                        name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME}`}
                        control={control}
                        rules={{
                          required:
                            itemFieldMeta[
                              ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME
                            ].required,
                        }}
                        render={({ field, fieldState: { invalid } }) => (
                          <Input
                            className={styles.Input}
                            dataHcName={`${dataHcName}-contact-full-name-input`}
                            placeholder={`Contact Full Name ${
                              itemFieldMeta[
                                ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME
                              ].required
                                ? '*'
                                : ''
                            }`}
                            error={
                              invalid
                                ? getRequiredErrorText('Contact Full Name')
                                : undefined
                            }
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                      <Controller
                        name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE}`}
                        control={control}
                        rules={{
                          required:
                            itemFieldMeta[
                              ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE
                            ].required,
                        }}
                        render={({ field, fieldState: { invalid } }) => (
                          <Input
                            className={styles.Input}
                            dataHcName={`${dataHcName}-inspection-contact-phone-input`}
                            placeholder={`Contact Phone ${
                              itemFieldMeta[
                                ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE
                              ].required
                                ? '*'
                                : ''
                            }`}
                            error={
                              invalid
                                ? getRequiredErrorText('Contact Phone')
                                : undefined
                            }
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE_IS_CELL}`}
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          dataHcName={`${dataHcName}-contact-can-receive-text-messages-checkbox`}
                          checked={!!field.value}
                          onChange={field.onChange}
                          label="Contact Can Receive Text Message"
                        />
                      )}
                    />
                    <Controller
                      name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.INSPECTION_CONTACT_EMAIL}`}
                      control={control}
                      rules={{
                        required:
                          itemFieldMeta[
                            ITEM_FIELD_NAMES.INSPECTION_CONTACT_EMAIL
                          ].required,
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Input
                          className={styles.Input}
                          dataHcName={`${dataHcName}-contact-email-input`}
                          placeholder={`Contact Email ${
                            itemFieldMeta[
                              ITEM_FIELD_NAMES.INSPECTION_CONTACT_EMAIL
                            ].required
                              ? '*'
                              : ''
                          }`}
                          error={
                            invalid
                              ? getRequiredErrorText('Contact Email')
                              : undefined
                          }
                          {...field}
                          value={field.value || ''}
                        />
                      )}
                    />
                    <Checkbox
                      dataHcName={`${dataHcName}-add-second-client`}
                      checked={showSecondaryContact[addressInputIdx] || false}
                      onChange={() =>
                        toggleSecondaryContactAtIdx(addressInputIdx)
                      }
                      label="Add Secondary Contact"
                    />
                    {showSecondaryContact[addressInputIdx] &&
                      itemFieldMeta[
                        ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_NAME
                      ].shown && (
                        <>
                          <div className={styles.InputSameRow}>
                            <Controller
                              name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_NAME}`}
                              control={control}
                              rules={{
                                required:
                                  itemFieldMeta[
                                    ITEM_FIELD_NAMES
                                      .SECONDARY_INSPECTION_CONTACT_NAME
                                  ].required,
                              }}
                              render={({ field, fieldState: { invalid } }) => (
                                <Input
                                  className={styles.Input}
                                  dataHcName={`${dataHcName}-secondary-contact-full-name-input`}
                                  placeholder={`Secondary Contact Full Name ${
                                    itemFieldMeta[
                                      ITEM_FIELD_NAMES
                                        .SECONDARY_INSPECTION_CONTACT_NAME
                                    ].required
                                      ? '*'
                                      : ''
                                  }`}
                                  error={
                                    invalid
                                      ? getRequiredErrorText(
                                          'Secondary Contact Full Name'
                                        )
                                      : undefined
                                  }
                                  {...field}
                                  value={field.value || ''}
                                />
                              )}
                            />
                            <Controller
                              name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_PHONE}`}
                              control={control}
                              rules={{
                                required:
                                  itemFieldMeta[
                                    ITEM_FIELD_NAMES
                                      .SECONDARY_INSPECTION_CONTACT_PHONE
                                  ].required,
                              }}
                              render={({ field, fieldState: { invalid } }) => (
                                <Input
                                  className={styles.Input}
                                  dataHcName={`${dataHcName}-secondary-inspection-contact-phone-input`}
                                  placeholder={`Secondary Contact Phone ${
                                    itemFieldMeta[
                                      ITEM_FIELD_NAMES
                                        .SECONDARY_INSPECTION_CONTACT_PHONE
                                    ].required
                                      ? '*'
                                      : ''
                                  }`}
                                  error={
                                    invalid
                                      ? getRequiredErrorText(
                                          'Secondary Contact Phone'
                                        )
                                      : undefined
                                  }
                                  {...field}
                                  value={field.value || ''}
                                />
                              )}
                            />
                          </div>
                          <Controller
                            name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_PHONE_IS_CELL}`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                dataHcName={`${dataHcName}-secondary-contact-can-receive-text-messages-checkbox`}
                                checked={!!field.value}
                                onChange={field.onChange}
                                label="Secondary Contact Can Receive Text Message"
                              />
                            )}
                          />
                          <Controller
                            name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_EMAIL}`}
                            control={control}
                            rules={{
                              required:
                                itemFieldMeta[
                                  ITEM_FIELD_NAMES
                                    .SECONDARY_INSPECTION_CONTACT_EMAIL
                                ].required,
                            }}
                            render={({ field, fieldState: { invalid } }) => (
                              <Input
                                className={styles.Input}
                                dataHcName={`${dataHcName}-secondary-contact-email-input`}
                                placeholder={`Secondary Contact Email ${
                                  itemFieldMeta[
                                    ITEM_FIELD_NAMES
                                      .SECONDARY_INSPECTION_CONTACT_EMAIL
                                  ].required
                                    ? '*'
                                    : ''
                                }`}
                                error={
                                  invalid
                                    ? getRequiredErrorText(
                                        'Secondary Contact Email'
                                      )
                                    : undefined
                                }
                                {...field}
                                value={field.value || ''}
                              />
                            )}
                          />
                        </>
                      )}
                  </div>
                )}
                {itemFieldMeta[ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION]
                  .shown && (
                  <Controller
                    name={`addressMeta.${addressInputIdx}.${ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION}`}
                    control={control}
                    rules={{
                      required:
                        itemFieldMeta[
                          ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION
                        ].required,
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        className={styles.Input}
                        dataHcName={`${dataHcName}-comments-input`}
                        placeholder={`Comments for Inspector ${
                          itemFieldMeta[
                            ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION
                          ].required
                            ? '*'
                            : ''
                        }`}
                        error={
                          invalid
                            ? getRequiredErrorText('Comments for Inspector')
                            : undefined
                        }
                        {...field}
                        value={field.value || ''}
                      />
                    )}
                  />
                )}
                {addressInputIdx > 0 && (
                  <div
                    className={styles.RemoveAddress}
                    onClick={() => remove(addressInputIdx)}
                  >
                    X Remove Address
                  </div>
                )}
              </div>
            );
          })}

          <div className={styles.ButtonContainer}>
            <Button
              onClick={() => {
                append({ address: null, itemSupportingDocumentFields: null });
              }}
              secondary
              type="button"
              dataHcName={`${dataHcName}-add-address`}
            >
              Add Address
            </Button>
            {uploadAddress ? (
              <Button
                dataHcName={`${dataHcName}-add-addresses-submit`}
                type="submit"
                loading={uploadAddress.uploadAddressSubmitIsLoading}
              >
                Submit Order
              </Button>
            ) : (
              <Button type="submit" dataHcName={`${dataHcName}-continue`}>
                Continue
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
