import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import AppLauncher from './AppLauncherIcon.svg?react';
import Back from './BackIcon.svg?react';
import ChevronDownLarge from './ChevronDownLargeIcon.svg?react';
import ChevronDownSmall from './ChevronDownSmallIcon.svg?react';
import ChevronLeftLarge from './ChevronLeftLargeIcon.svg?react';
import ChevronLeftSmall from './ChevronLeftSmallIcon.svg?react';
import ChevronRightLarge from './ChevronRightLargeIcon.svg?react';
import ChevronRightSmall from './ChevronRightSmallIcon.svg?react';
import ChevronUpLarge from './ChevronUpLargeIcon.svg?react';
import ChevronUpSmall from './ChevronUpSmallIcon.svg?react';
import Close from './CloseIcon.svg?react';
import Minus from './MinusIcon.svg?react';
import Plus from './PlusIcon.svg?react';
import WhiteChevronLeft from './WhiteChevronLeftIcon.svg?react';
import WhiteChevronRight from './WhiteChevronRightIcon.svg?react';

export const AppLauncherIcon = (props: IconProps) =>
  useIcon(AppLauncher, props);
export const BackIcon = (props: IconProps) => useIcon(Back, props);
export const ChevronDownLargeIcon = (props: IconProps) =>
  useIcon(ChevronDownLarge, props);
export const ChevronDownSmallIcon = (props: IconProps) =>
  useIcon(ChevronDownSmall, props);
export const ChevronLeftLargeIcon = (props: IconProps) =>
  useIcon(ChevronLeftLarge, props);
export const ChevronLeftSmallIcon = (props: IconProps) =>
  useIcon(ChevronLeftSmall, props);
export const ChevronRightLargeIcon = (props: IconProps) =>
  useIcon(ChevronRightLarge, props);
export const ChevronRightSmallIcon = (props: IconProps) =>
  useIcon(ChevronRightSmall, props);
export const ChevronUpLargeIcon = (props: IconProps) =>
  useIcon(ChevronUpLarge, props);
export const ChevronUpSmallIcon = (props: IconProps) =>
  useIcon(ChevronUpSmall, props);
export const CloseIcon = (props: IconProps) => useIcon(Close, props);
export const MinusIcon = (props: IconProps) => useIcon(Minus, props);
export const PlusIcon = (props: IconProps) => useIcon(Plus, props);
export const WhiteChevronLeftIcon = (props: IconProps) =>
  useIcon(WhiteChevronLeft, props);
export const WhiteChevronRightIcon = (props: IconProps) =>
  useIcon(WhiteChevronRight, props);
