import { gql } from 'graphql-request';

export const SEARCH_BY_MLS_NUMBER = gql`
  query searchByMlsNumbers($items: [MlsNumberSearchInputItem]!) {
    searchByMlsNumbers(mlsNumbers: { items: $items }) {
      isListingRental
      address {
        hcAddressId
        slug
        streetAddress
        unit
        city
        state
        zipcode
      }
      association {
        hasAssociation
        fees {
          amount
          frequency
          type
        }
      }
      countyLegal {
        apn
        subdivision
      }
      cerberusID
      listingID
      mlsID
      external {
        parking {
          has
          count
          desc
        }
      }
      site {
        lot {
          areaSqFt
        }
      }
      location {
        latitude
        longitude
        precision
      }
      price
      listingStatus {
        MlsState
        daysOnMarket
        contractDate
        contractPrice
        currentStatus
        currentStatusDate
        listingDate
        listingPrice
        offMarketDate
        onMarketDate
        saleDate
        salePrice
        salePricePerGla
      }
      livingSpace {
        basement {
          has
        }
        bathrooms {
          countTotal
        }
        bedrooms {
          countTotal
        }
        grossLivingArea
      }
      publicRemarks
      structure {
        storiesCount
        yearBuilt
      }
      taxSummary {
        amount
        rateValue
        year
      }
    }
  }
`;
