import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { KeywordSearchListingRemarks } from '@hcs/keyword-search';
import { CompTypes } from '@hcs/types';
import { CompIdentifier, ReportFeatures, ReportId } from '@hcs/types';

import { useComp } from '../../hooks';
import { compKeywordSearchKey } from '../../utils';

interface Props {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  className?: string;
}
const dataHcName = 'comp-public-remarks';
export const COMP_PUBLIC_REMARKS_FEATURES_SALE = [
  ReportFeatures.CompsListingRemarks,
];
export const COMP_PUBLIC_REMARKS_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsListingRemarks,
];
export const CompPublicRemarks = ({
  reportId,
  compIdentifier,
  className,
}: Props) => {
  const { isInitialLoading, data: compData } = useComp(
    reportId,
    compIdentifier
  );
  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const isRental = compIdentifier.compType === CompTypes.Rental;
  return (
    <KeywordSearchListingRemarks
      keywordSearchKey={compKeywordSearchKey(compIdentifier.compType)}
      isRental={isRental}
      date={
        compData?.compSchema.propertyState[
          isRental ? 'complexFieldsRental' : 'complexFieldsSale'
        ]?.currentListDate
      }
      remarks={
        compData?.compSchema.propertyState[
          isRental ? 'listingDetailsRental' : 'listingDetailsSale'
        ]?.remarks?.find((r) => r?.type === 'Public')?.value
      }
      className={className}
    />
  );
};
