import React from 'react';
import classNames from 'classnames';

import styles from './OfferNowDisclaimer.module.css';

export interface OfferNowDisclaimerProps {
  dataHcName: string;
  className?: string;
}

export const OfferNowDisclaimer = ({
  dataHcName,
  className,
}: OfferNowDisclaimerProps) => {
  return (
    <p
      data-hc-name={dataHcName}
      className={classNames(styles.DisclaimerText, className)}
    >
      You acknowledge and agree that this submission does not constitute a
      formal offer nor does it create an agency relationship with ComeHome
      and/or HouseCanary, as applicable. Upon submission, you will be contacted
      for further details regarding next steps for this property purchase.
    </p>
  );
};
