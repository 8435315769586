import { useMemo } from 'react';

import { useKeywordSearch } from './useKeywordSearch';

export const useKeywordSearchByListingsByCerberusId = (searchKey: string) => {
  const {
    state: {
      resultsQuery: { data, ...restOfQuery }
    }
  } = useKeywordSearch(searchKey);
  return {
    ...restOfQuery,
    data: useMemo(() => {
      const byCerberusId: Record<string, boolean> = {};
      data?.forEach((m) => {
        byCerberusId[m.cerberus_id] = true;
      });
      return byCerberusId;
    }, [data])
  };
};
