import React from 'react';

import { AppAccessMainNav, AppAccessMainNavItemConfig } from '@hcs/auth';
import {
  dataHcEventSectionDexpResults,
  dataHcEventSectionSelectDataPoints,
} from '@hcs/data-explorer';
import {
  AnalysisIcon,
  ExternalLinkIcon,
  ListAnalysisIcon,
} from '@hcs/design-system';
import { APP_CONFIG_DEXP, VIEW_PATHS_DEXP } from '@hcs/hc-products';
import { ANALYTICS_API_DOCS_URL } from '@hcs/urls';

interface DexpNavProps {
  className?: string;
}
const dataHcName = 'dexp-left-nav';

const NAV_ITEMS_CONFIG: AppAccessMainNavItemConfig[] = [
  {
    label: 'New analysis',
    navigateTo: `${APP_CONFIG_DEXP.rootPath}/${VIEW_PATHS_DEXP.NEW_ANALYSIS}`,
    Icon: AnalysisIcon,
    dataHcName: `${dataHcName}-new-analysis`,
    dataHcEventSection: dataHcEventSectionSelectDataPoints,
  },
  {
    label: 'Previous analyses',
    navigateTo: `${APP_CONFIG_DEXP.rootPath}/${VIEW_PATHS_DEXP.PREVIOUS_ANALYSES}`,
    Icon: ListAnalysisIcon,
    dataHcName: `${dataHcName}-previous-analyses`,
    dataHcEventSection: dataHcEventSectionDexpResults,
  },
  {
    label: 'API documentation',
    navigateTo: ANALYTICS_API_DOCS_URL,
    isExternalLink: true,
    Icon: ExternalLinkIcon,
    dataHcName: `${dataHcName}-api-documentation`,
    dataHcEventSection: 'DEXP API Docs',
  },
];

export const DexpNavBeta = (props: DexpNavProps) => {
  const { className } = props;
  return (
    <AppAccessMainNav
      dataHcName={dataHcName}
      className={className}
      navItemsConfig={NAV_ITEMS_CONFIG}
      hoverToOpen
    />
  );
};
