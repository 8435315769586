import { PropertyStateFields } from '@hcs/types';

export const SPATIAL_FILTERS_TO_PROPERTY_STATE_FIELDS = {
  basement: PropertyStateFields.basementHas,
  // conditionClass: PropertyStateFields,
  // listDateOrSaleDate: PropertyStateFields,
  maxAvmPrice: PropertyStateFields.currentValue,
  maxBaths: PropertyStateFields.bathrooms,
  maxBathsWithHalfs: PropertyStateFields.bathrooms,
  maxBeds: PropertyStateFields.bedrooms,
  maxLeaseDate: PropertyStateFields.lastCloseDateRental,
  maxLeasePrice: PropertyStateFields.lastClosePriceRental,
  maxListDate: PropertyStateFields.currentListDate,
  maxListPrice: PropertyStateFields.currentListingPrice,
  maxLotArea: PropertyStateFields.lotSize,
  maxParkingSpots: PropertyStateFields.parkingTotal,
  // maxPricePerSqft50: PropertyStateFields,
  maxRentalAvmPrice: PropertyStateFields.currentValueRental,
  maxRentalListDate: PropertyStateFields.currentListDateRental,
  maxRentalListPrice: PropertyStateFields.currentListingPriceRental,
  maxRentalYield: PropertyStateFields.rentalYield,
  maxSaleDate: PropertyStateFields.lastCloseDate,
  maxSalePrice: PropertyStateFields.lastClosePrice,
  maxSqft: PropertyStateFields.livingArea,
  maxStories: PropertyStateFields.stories,
  maxYearBuilt: PropertyStateFields.yearBuilt,

  minAvmPrice: PropertyStateFields.currentValue,
  minBaths: PropertyStateFields.bathrooms,
  minBathsWithHalfs: PropertyStateFields.bathrooms,
  minBeds: PropertyStateFields.bedrooms,
  minLeaseDate: PropertyStateFields.lastCloseDateRental,
  minLeasePrice: PropertyStateFields.lastClosePriceRental,
  minListDate: PropertyStateFields.currentListDate,
  minListPrice: PropertyStateFields.currentListingPrice,
  minLotArea: PropertyStateFields.lotSize,
  minParkingSpots: PropertyStateFields.parkingTotal,
  // minPricePerSqft50: PropertyStateFields,
  minRentalAvmPrice: PropertyStateFields.currentValueRental,
  minRentalListDate: PropertyStateFields.currentListDateRental,
  minRentalListPrice: PropertyStateFields.currentListingPriceRental,
  minRentalYield: PropertyStateFields.rentalYield,
  minSaleDate: PropertyStateFields.lastCloseDate,
  minSalePrice: PropertyStateFields.lastClosePrice,
  minSqft: PropertyStateFields.livingArea,
  minStories: PropertyStateFields.stories,
  minYearBuilt: PropertyStateFields.yearBuilt,

  mlsState: PropertyStateFields.currentStatus,
  mlsStateGroup: PropertyStateFields.currentStatus,
  pool: PropertyStateFields.pool,
  propertyTypeEnum: PropertyStateFields.propertyType,
  rentalMlsState: PropertyStateFields.currentStatusRental,
  rentalMlsStateGroup: PropertyStateFields.currentStatusRental,
};
