/** TEMPORARY FILE UNTIL ENUMS ARE IN THE CERBERUS SCHEMA */
export enum ListingStatusCerberus {
  Active = 'Active',
  ActiveUnderContract = 'Active Under Contract',
  Canceled = 'Canceled',
  Closed = 'Closed',
  ComingSoon = 'Coming Soon',
  Delete = 'Delete',
  Expired = 'Expired',
  Hold = 'Hold',
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  Withdrawn = 'Withdrawn',
  DeedRecorded = 'Deed Recorded',
}

export enum PredictionGroupType {
  House = 'house',
  Kitchen = 'kitchen',
  Room = 'room',
  Bathroom = 'bathroom',
}

export enum ConditionEnum {
  Uninhabitable = 'UNINHABITABLE',
  SignificantRepairs = 'SIGNIFICANT_REPAIRS',
  WornButAdequate = 'WORN_BUT_ADEQUATE',
  WellMaintained = 'WELL_MAINTAINED',
  Excellent = 'EXCELLENT',
  NearlyNew = 'NEARLY_NEW',
}
