import React from 'react';
import classNames from 'classnames';

import { Dialog } from '@hcs/design-system';
import { PropertyStateLocation } from '@hcs/types';
import { StreetViewOptions } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';

import { StreetViewInteractive } from '../StreetViewInteractive';

import styles from './StreetViewDialog.module.css';

interface Props {
  dataHcName: string;
  dataHcEventSection?: string;
  onClose: VoidFunction;
  active: boolean;
  location?: PropertyStateLocation['location'] | null;
  options?: StreetViewOptions;
  className?: string;
}

export const StreetViewDialog = ({
  dataHcName,
  dataHcEventSection,
  onClose,
  active,
  location,
  options,
  className,
}: Props) => {
  return (
    <Dialog
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      active={active}
      onClose={onClose}
      title={formatFullAddress(location)}
      type="large"
      theme={{
        Dialog: classNames(styles.StreetViewDialog, className),
        DialogWrapper: styles.DialogWrapper,
        Header: styles.Header,
      }}
      noContentPadding
    >
      <StreetViewInteractive
        location={location}
        options={options}
        dataHcName={`${dataHcName}-interactive`}
      />
    </Dialog>
  );
};
