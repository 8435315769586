import { gql } from 'graphql-request';

// TODO: Add new fields priceChangeLastClose priceChangePctLastClose
export const FRAGMENT_TRANSACTION_HISTORY_EVENT_HISTORY_SALE = gql`
  fragment TransactionHistoryEventSale on ComplexFieldsSaleEventHistory {
    addressId
    armsLength
    date
    daysOnMarketCumulative
    deedDate
    deedPrice
    deedType
    displayStatus
    distressed
    documentTimestamp
    entityId
    hcMlsId
    hcpyLookupKey
    listingGroupId
    listingId
    marketValuePrice
    mlsTimestamp
    multiParcel
    partialInterest
    price
    priceChangeLastClose
    priceChangePctLastClose
    priceVariance
    propertyType
    propertyTypeEnum
    recorderBook
    recorderDocument
    recorderPage
    reo
    sourceId
    sourceTimestamp
    sourceType
    status
    transfer
  }
`;

export const FRAGMENT_TRANSACTION_HISTORY_EVENT_HISTORY_RENTAL = gql`
  fragment TransactionHistoryEventRental on ComplexFieldsRentalEventHistory {
    addressId
    armsLength
    date
    daysOnMarketCumulative
    displayStatus
    distressed
    documentTimestamp
    entityId
    hcMlsId
    listingId
    listingGroupId
    mlsTimestamp
    price
    priceChangeLastClose
    priceChangePctLastClose
    priceVariance
    propertyType
    propertyTypeEnum
    reo
    sourceId
    sourceTimestamp
    sourceType
    status
  }
`;
