import camelCase from 'camelcase';
import snakecaseKeys from 'snakecase-keys';

import {
  OmOrder,
  OrderFilterIds,
  OrderItemStatus,
  OrderProgress,
  OrdersParams,
  OrdersParamsForApi,
  OrderStatus,
  OrderTypeDescriptor,
  StatusGroup,
} from '@hcs/types';
import { camelCaseToSnakeCase } from '@hcs/utils';

import { ORDER_SET_TO_STATUSES, ORDERS_PER_PAGE } from '../constants';

export const prepOrdersParamsForApi = ({
  sortBy,
  sortOrder,
  ...params
}: OrdersParams): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();
  const paramsForApi: OrdersParamsForApi = snakecaseKeys({
    ...params,
    pageSize: params?.pageSize || ORDERS_PER_PAGE,
    ...(sortBy
      ? {
          ordering: `${sortOrder === 'DESC' ? '-' : ''}${camelCaseToSnakeCase(
            sortBy
          )}`,
        }
      : {}),
  });

  let prop: keyof OrdersParamsForApi;
  for (prop in paramsForApi) {
    if (prop === OrderFilterIds.status) {
      const value = paramsForApi[prop];
      if (value) {
        const selectedOrderStatusArr: OrderStatus[] =
          ORDER_SET_TO_STATUSES[value];
        for (const param of selectedOrderStatusArr) {
          urlSearchParams.append(prop, param);
        }
      }
    } else {
      const value = paramsForApi[prop];
      if (value) {
        urlSearchParams.append(prop, value.toString());
      }
    }
  }

  return urlSearchParams;
};

export const orderTypeDescriptorValuesSnakeToCamel = (
  orderTypeSnake: OrderTypeDescriptor
) => {
  orderTypeSnake.shownFields = orderTypeSnake.shownFields.map(
    (fieldName: string) => camelCase(fieldName)
  );
  orderTypeSnake.requiredFields = orderTypeSnake.requiredFields.map(
    (fieldName: string) => camelCase(fieldName)
  );
  orderTypeSnake.shownItemFields = orderTypeSnake.shownItemFields.map(
    (fieldName: string) => camelCase(fieldName)
  );
  orderTypeSnake.requiredItemFields = orderTypeSnake.requiredItemFields.map(
    (fieldName: string) => camelCase(fieldName)
  );
  return orderTypeSnake;
};

export const getHeaderStatusGroupsDisplay = (
  order: OmOrder,
  orderProgress: OrderProgress
) => {
  return order.orderTypeDescriptor.headerStatusGroups.map(
    (headerStatusGroup: StatusGroup) => {
      const statusGroupCount: number = Object.keys(orderProgress).reduce(
        (count, orderItemStatus) => {
          if (
            headerStatusGroup.includedStatuses.indexOf(orderItemStatus) !== -1
          ) {
            count = count + orderProgress[orderItemStatus as OrderItemStatus];
          }
          return count;
        },
        0
      );
      const statusGroupPercentage =
        statusGroupCount > 0 && order.orderItemsToProcessCount > 0
          ? Math.round(
              (statusGroupCount / order.orderItemsToProcessCount) * 100
            )
          : 0;
      return {
        label: headerStatusGroup.name,
        percentage: statusGroupPercentage,
        key: headerStatusGroup.name.replace(/\s+/g, '-').toLowerCase(),
      };
    }
  );
};
