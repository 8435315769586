import React from 'react';
import pluralize from 'pluralize';

import { ActiveDisabledCount } from '@hcs/design-system';

import { useBuyBoxCount } from '../../';

interface ActiveBuyBoxCountProps {
  className?: string;
  numActiveFiltered?: number;
  numDisabledFiltered?: number;
  hideDisabled?: boolean;
}
const dataHcName = 'buy-boxes-active-count';
export const ActiveBuyBoxCount = ({
  className,
  numActiveFiltered,
  numDisabledFiltered,
  hideDisabled = false,
}: ActiveBuyBoxCountProps) => {
  const { data: buyBoxCount } = useBuyBoxCount();
  const { active: totalNumActive, disabled: totalNumDisabled } = buyBoxCount;
  const activeBuyBoxCount =
    numActiveFiltered === undefined ? totalNumActive : numActiveFiltered;
  const disabledBuyBoxCount =
    numDisabledFiltered === undefined ? totalNumDisabled : numDisabledFiltered;
  return (
    <ActiveDisabledCount
      dataHcName={dataHcName}
      className={className}
      numActive={activeBuyBoxCount}
      activeLabel={`Active Buy ${pluralize('Box', activeBuyBoxCount)}`}
      numDisabled={disabledBuyBoxCount}
      disabledLabel={`Disabled Buy ${pluralize('Box', disabledBuyBoxCount)}`}
      hideDisabled={hideDisabled}
    />
  );
};
