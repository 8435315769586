import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash.isempty';

import { LineClamp } from '@hcs/design-system';
import { Button } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { Menu, MenuProps } from '@hcs/design-system';
import { MeaningfulEventTypes } from '@hcs/types';

import { SaveAnalysisTemplateDialog } from '../../features/SaveAnalysisTemplate/SaveAnalysisTemplateDialog';
import {
  DexpTemplateForUser,
  useDexpTemplatesForUser,
} from '../../hooks/useDexpTemplatesForUser';

import styles from './DexpTemplatesMenuForUser.module.css';

interface Props {
  className?: string;
  onSelect: (template: DexpTemplateForUser) => void;
}
const dataHcName = 'dexp-templates-menu-for-user';
const dataHcEventSectionDexpTemplateMenu = 'DEXP Templates Menu';
export const DexpTemplatesMenuForUser = ({ className, onSelect }: Props) => {
  const [templateToEdit, setTemplateToEdit] = useState<
    DexpTemplateForUser | undefined
  >();
  const { data: dexpTemplates } = useDexpTemplatesForUser();
  const menuItems: MenuProps['menuItems'] = useMemo(() => {
    return Object.entries(dexpTemplates || {}).map(([templateId, template]) => {
      const numPts = Object.keys(template.endpointsData).length;
      return {
        dataHcName: `${dataHcName}-menu-item`,
        label: (
          <LineClamp className={styles.LineClamp} lines={1}>
            {template.name}
          </LineClamp>
        ),
        description: `${numPts} Endpoint${numPts === 1 ? '' : 's'}`,
        onClick: () => setTemplateToEdit(template),
        contentRight: (
          <Button
            dataHcName={`${dataHcName}-button`}
            dataHcEventName="DEXP Template Apply"
            dataHcEventType={MeaningfulEventTypes.Goal}
            label="Apply"
            onClick={() => onSelect(template)}
          />
        ),
      };
    });
  }, [dexpTemplates, onSelect]);

  if (isEmpty(dexpTemplates)) {
    return null;
  }
  return (
    <>
      <Card
        dataHcName={dataHcName}
        dataHcEventSection={dataHcEventSectionDexpTemplateMenu}
        title={<>Saved Analysis Templates</>}
        smallPadding
        className={classNames(styles.DexpTemplatesMenuForUser, className)}
      >
        <Menu menuItems={menuItems} />
      </Card>
      <SaveAnalysisTemplateDialog
        active={!!templateToEdit}
        onClose={() => setTemplateToEdit(undefined)}
        templateId={templateToEdit?.templateId}
        endpointsData={templateToEdit?.endpointsData || {}}
      />
    </>
  );
};
