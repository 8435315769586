import React from 'react';

import { PageTitle } from '@hcs/design-system';
import { UserExperiencePreferences } from '@hcs/user-admin';

const dataHcName = 'user-experience-preferences-page';
export const UserExperiencePreferencesPage = () => {
  return (
    <>
      <PageTitle dataHcName={`${dataHcName}-title`}>
        User Experience Preferences
      </PageTitle>
      <UserExperiencePreferences />
    </>
  );
};
