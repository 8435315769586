import { JSXElementConstructor } from 'react';

import {
  OmDesktopIcon,
  OmExteriorIcon,
  OmInteriorExteriorIcon,
} from '@hcs/design-system';
import {
  AgileEvaluationIcon,
  AgileInsightsIcon,
  BpoIcon,
  InspectionsIcon,
} from '@hcs/design-system';
import { IconProps } from '@hcs/types';
import { GroupKey, INSPECTION_TYPE, ProductType } from '@hcs/types';

export const PRODUCT_DETAILS: {
  [key in ProductType]: {
    title: string;
    description: string;
    Icon: JSXElementConstructor<IconProps>;
    iconProps: IconProps;
  };
} = {
  AGILE_EVALUATION: {
    title: 'Agile Evaluation',
    description:
      'Condition-informed evaluation, complete with onsite inspection and data quality checks.',
    Icon: AgileEvaluationIcon,
    iconProps: {
      dataHcName: 'agile-eval-product-icon',
    },
  },
  INSPECTION: {
    title: 'Inspections',
    description:
      'Onsite inspection report completed by a trained, local inspector.',
    Icon: InspectionsIcon,
    iconProps: {
      dataHcName: 'inspection-product-icon',
    },
  },
  VALUE_REPORT: {
    title: 'Agile Insights',
    description:
      "Automated Valuation report utilizing HouseCanary's flagship AVM.",
    Icon: AgileInsightsIcon,
    iconProps: {
      dataHcName: 'agile-insights-product-icon',
    },
  },
  BROKER_PRICE_OPINION: {
    title: 'Broker Price Opinion (BPO)',
    description:
      "BPO backed by HouseCanary's reliable valuations and analytics, completed by a licensed broker.",
    Icon: BpoIcon,
    iconProps: {
      dataHcName: 'bpo-product-icon',
    },
  },
};

export const PRODUCT_IDS: { [key in GroupKey]: ProductType } = {
  agile_evaluation: 'AGILE_EVALUATION',
  broker_price_opinion: 'BROKER_PRICE_OPINION',
  inspection: 'INSPECTION',
  agile_insights: 'VALUE_REPORT',
};

export const GROUP_KEYS: GroupKey[] = [
  'agile_insights',
  'agile_evaluation',
  'broker_price_opinion',
  'inspection',
];

export const AS_REPAIRED_VALUE_NAMES = {
  asIs: 'As-Is Value',
  asRepaired: 'As-Is & As-Repaired Value',
};

export const INSPECTION_NAMES: { [key in INSPECTION_TYPE]: string } = {
  exterior: 'Exterior Only',
  interior_exterior: 'Interior & Exterior',
  desktop: 'Desktop Only',
};

export const ICONS: {
  [key in INSPECTION_TYPE]: JSXElementConstructor<IconProps>;
} = {
  exterior: OmExteriorIcon,
  interior_exterior: OmInteriorExteriorIcon,
  desktop: OmDesktopIcon,
};

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
