import React from 'react';

import { Avatar, UserMenuHeaderButton } from '@hcs/design-system';

import { useAccount } from '../../hooks/useAccount';

import styles from './UserMenuAvatarButton.module.css';

interface UserMenuButtonsProps {
  className?: string;
}
const dataHcName = 'user-menu-avatar-button';
export const UserMenuAvatarButton = ({ className }: UserMenuButtonsProps) => {
  const accountQuery = useAccount();
  return (
    <UserMenuHeaderButton
      dataHcName={dataHcName}
      className={className}
      icon={
        <Avatar
          dataHcName={`${dataHcName}-avatar`}
          text={
            accountQuery.isSuccess
              ? `${accountQuery.data.user.firstName?.[0] || ''}${
                  accountQuery.data.user.lastName?.[0] || ''
                }`
              : ''
          }
        />
      }
      label={
        <div className={styles.Name}>
          <span data-hc-name={`${dataHcName}-first-name`}>
            {accountQuery.data?.user.firstName || ''}
          </span>
          {accountQuery.data?.user.firstName ? ' ' : ''}
          <span data-hc-name={`${dataHcName}-last-name`}>
            {accountQuery.data?.user.lastName || ''}
          </span>
        </div>
      }
      description={accountQuery.data?.currentOrganization.name || ''}
    />
  );
};
