import React, { ComponentType } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

import styles from './LinkComponent.module.css';

/**
 * Generic props for defining Link components as props that can be passed to features (see aexp/MsaFilterPage)
 */
// Extra props we require to be passed to links:
type HcsLinkProps = LinkProps & {
  dataHcName: string;
  className?: string;
  styledAsAnchor?: boolean;
  underline?: boolean;
  disabled?: boolean;
};
// LinkComponentProps are useful when creating navigation link features:
export type LinkComponentProps<P> = P & Omit<HcsLinkProps, 'to'>;
// LinkComponentType is used for defining props that expect link components:
export type LinkComponentType<P> = ComponentType<LinkComponentProps<P>>;

export const LinkComponent = ({
  dataHcName,
  className,
  styledAsAnchor = false,
  underline = false,
  disabled = false,
  ...rest
}: HcsLinkProps) => {
  return (
    <Link
      className={classNames(
        {
          [styles.LinkComponentAsAnchor]: styledAsAnchor,
          [styles.disabled]: disabled,
          [styles.Underline]: underline,
        },
        className
      )}
      {...rest}
      data-hc-name={dataHcName}
    />
  );
};
