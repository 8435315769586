import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { Feature } from '@nebula.gl/edit-modes';

import {
  DISTANCE_UNITS,
  GEO_JSON_COLOR,
  getGEOJSONPolygonCircleForDisplay,
} from '@hcs/maps';
import { CompFields, CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../hooks/useCompsFiltersDocument';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
}
const layerFillStyle: LayerProps = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-opacity': 0.24,
    'fill-color': GEO_JSON_COLOR,
  },
};

const layerOutlineStyle: LayerProps = {
  id: 'dataOutline',
  type: 'line',
  paint: {
    'line-color': GEO_JSON_COLOR,
    'line-width': 3,
    'line-opacity': 1,
  },
};

export const CompFilterDistanceGeoJsonLayer = ({
  reportId,
  compType,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  // Get the absolute filter value for initial value
  const geoJsonFilterFilter =
    filterDocument?.data.filters?.[CompFields.distance]?.absoluteValue;
  if (!geoJsonFilterFilter) return null;

  // Temp only because the units for relative values are described as meters until
  // the backend change we will update the units for relative values to miles
  // so they are converted correctly into a circle
  // If the backend does update to miles this will still keep working
  // so it's a safe change
  const geoJsonFilter = filterDocument?.data.filters?.[CompFields.distance]
    ?.relativeValue
    ? ({
        ...geoJsonFilterFilter,
        properties: {
          ...geoJsonFilterFilter?.properties,
          radius_unit: DISTANCE_UNITS.METERS,
        },
      } as GeoJSON.Feature)
    : geoJsonFilterFilter;

  // To display circle on the screen we need to ensure that we convert a point and radius to
  // a polygon, this needs to be done for both custom circles drawn by the user
  // and relative values specified in the quick select ie 0.5, 1, 3 etc
  const circleFeature =
    geoJsonFilter?.geometry?.type === 'Point' &&
    geoJsonFilter &&
    filterDocument?.data.filters?.[CompFields.distance]?.absoluteValue
      ?.properties?.radius
      ? getGEOJSONPolygonCircleForDisplay(geoJsonFilter as Feature)
      : undefined;

  if (!geoJsonFilter) return null;
  return (
    <Source
      id="geojson-filter"
      type="geojson"
      data={circleFeature ? circleFeature : geoJsonFilter}
    >
      <Layer {...layerOutlineStyle} />
      <Layer {...layerFillStyle} />
    </Source>
  );
};
