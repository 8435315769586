import React, { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useCurrentOrgName } from '@hcs/auth';
import { Button } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import { InputNumber } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { CardTitle } from '@hcs/design-system';
import { PageTitle } from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import { useScreenSize } from '@hcs/hooks';
import { ScreenSize } from '@hcs/types';
import { SftpSettingsFormData } from '@hcs/types';
import { ValidHostnameRegex } from '@hcs/utils';

import { PLACEHOLDER_PASSWORD } from '../../constants';
import { useSaveSftpSettings } from '../../hooks/useSaveSftpSettings';
import { useSftpSettings } from '../../hooks/useSftpSettings';

import styles from './SftpSettings.module.css';

const dataHcName = 'order-manager-sftp-settings';

interface BaseInputProps {
  label: string;
  required: boolean;
  form: UseFormReturn<SftpSettingsFormData>;
  dataHcName: string;
}

interface TextInputProps extends BaseInputProps {
  fieldName: Exclude<keyof SftpSettingsFormData, 'includeJson' | 'sftpPort'>;
}

const TextField = ({
  label,
  required,
  fieldName,
  form,
  dataHcName,
}: TextInputProps) => {
  return (
    <DialogInputLayout
      label={label}
      required={required}
      dataHcName={dataHcName}
      className={styles.Field}
    >
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <Input
            {...field}
            type={fieldName === 'sftpPassword' ? 'password' : 'text'}
            placeholder={label}
            value={field.value || ''}
            dataHcName={`${dataHcName}-input`}
            onChange={field.onChange}
            required={required}
            error={form.formState.errors[fieldName]?.message}
          />
        )}
      />
    </DialogInputLayout>
  );
};

interface NumberInputProps extends BaseInputProps {
  fieldName: 'sftpPort';
}

const NumberField = ({
  label,
  required,
  fieldName,
  form,
  dataHcName,
}: NumberInputProps) => {
  return (
    <DialogInputLayout
      label={label}
      required={required}
      dataHcName={dataHcName}
      className={styles.Field}
    >
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <InputNumber
            {...field}
            type="number"
            shouldFormat={{
              shouldFormatNumber: false,
            }}
            max={99999}
            placeholder={label}
            dataHcName={`${dataHcName}-input`}
            required={required}
            error={form.formState.errors[fieldName]?.message}
          />
        )}
      />
    </DialogInputLayout>
  );
};

const FORM_SCHEMA = yup.object().shape({
  sftpHostname: yup
    .string()
    .required('Hostname is required')
    // yup has a built in .url() validator but that requires the protocol be part of the value
    .matches(ValidHostnameRegex, 'Invalid hostname format'),
  sftpUsername: yup.string().required('Username is required'),
  sftpPassword: yup.string().required('Password is required'),
  sftpPort: yup
    .number()
    .positive('Port must be positive')
    .required('Port is required'),
  includeJson: yup.boolean(),
});

export const SftpSettings = () => {
  const { data: orgName } = useCurrentOrgName();
  const { data: existingSftpSettings } = useSftpSettings();
  const mutation = useSaveSftpSettings();
  const form = useForm<SftpSettingsFormData>({
    resolver: yupResolver(FORM_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      sftpHostname: null,
      sftpUsername: null,
      sftpPassword: null,
      sftpPort: null,
      includeJson: false,
    },
  });

  const { reset: formReset, getValues } = form;
  // update default values after sftp settings load (if they exist)
  useEffect(() => {
    if (existingSftpSettings) {
      formReset({
        sftpHostname: existingSftpSettings.sftpHostname,
        sftpUsername: existingSftpSettings.sftpUsername,
        sftpPassword: existingSftpSettings.hasPassword
          ? PLACEHOLDER_PASSWORD
          : null,
        sftpPort: existingSftpSettings.sftpPort,
        includeJson: existingSftpSettings.includeJson,
      });
    }
  }, [existingSftpSettings, formReset]);

  const handleSubmit = (formData: SftpSettingsFormData) => {
    console.log('handleSubmit', formData);
    mutation.mutate({
      formData,
      shouldCreate: existingSftpSettings
        ? existingSftpSettings.sftpHostname === null
        : true,
    });
  };

  const { width } = useScreenSize();
  const enableSmallPadding =
    width && width < ScreenSize.SCREEN_WIDTH_800 ? true : false;

  console.log('getValues', getValues());

  return (
    <div data-hc-name={dataHcName}>
      <PageTitle dataHcName={`${dataHcName}-title`}>{`${
        orgName || ''
      } Order Manager Settings`}</PageTitle>
      <div className={styles.Layout}>
        <Card
          smallPadding={enableSmallPadding}
          dataHcName={`${dataHcName}-sftp-card`}
          className={styles.SftpCard}
        >
          <CardTitle
            dataHcName={`${dataHcName}-sftp-card-title`}
            className={styles.SftpCardTitle}
          >
            Secure File Transfer Protocol (SFTP)
          </CardTitle>
          <FormProvider {...form}>
            <form
              data-hc-name={dataHcName}
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <TextField
                label="Hostname"
                required
                fieldName="sftpHostname"
                dataHcName={`${dataHcName}-hostname-field`}
                form={form}
              />
              <TextField
                label="Username"
                required
                fieldName="sftpUsername"
                dataHcName={`${dataHcName}-username-field`}
                form={form}
              />
              <TextField
                label="Password"
                required
                fieldName="sftpPassword"
                dataHcName={`${dataHcName}-password-field`}
                form={form}
              />
              <NumberField
                label="Port"
                required
                fieldName="sftpPort"
                dataHcName={`${dataHcName}-port-field`}
                form={form}
              />
              <DialogInputLayout
                label=""
                required={false}
                dataHcName={`${dataHcName}-include-json-field`}
                className={styles.Field}
              >
                <Controller
                  name="includeJson"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        label="Include JSON"
                        checked={field.value || false}
                        onChange={field.onChange}
                        dataHcName={`${dataHcName}-include-json-field-checkbox`}
                      />
                    );
                  }}
                />
              </DialogInputLayout>
              <Button
                dataHcName={`${dataHcName}-save-button`}
                type="submit"
                label="Save"
                disabled={
                  !form.formState.isDirty ||
                  !form.formState.isValid ||
                  mutation.isLoading
                }
              />
              {mutation.error && (
                <div
                  className={styles.Error}
                  data-hc-name={`${dataHcName}-error-message`}
                >
                  Error: {mutation.error.message}
                </div>
              )}
            </form>
          </FormProvider>
        </Card>
        <div
          className={styles.GettingStartedContainer}
          data-hc-name={`${dataHcName}-getting-started`}
        >
          <CardTitle
            dataHcName={`${dataHcName}-sftp-card-title`}
            className={styles.GettingStartedTitle}
          >
            Getting Started
          </CardTitle>
          <p>
            Secure File Transfer Protocol (SFTP) allows for all initiated
            downloads from Order Manager to be saved to a shared secure server
            hosted by your company.
          </p>
          <p>
            Before enabling this feature, you may need to ask you IT department
            for the required details, and request that they whitelist the
            following IPs:
          </p>

          <div>
            <div>35.85.186.193</div>
            <div>52.37.62.211</div>
            <div>44.237.244.211</div>
            <div>54.185.173.124</div>
          </div>
        </div>
      </div>
    </div>
  );
};
