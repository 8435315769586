import React from 'react';
import classNames from 'classnames';

import { ControlPosition, MapStyles } from '@hcs/types';

import LayerImgMap from '../../assets/layer-map.png';
import LayerImgSatellite from '../../assets/layer-satellite.png';
import { useHcMap } from '../../hooks/useHcMap';

import styles from './SatelliteToggle.module.css';

interface Props {
  mapId: string;
  className?: string;
  position?: ControlPosition;
  onChange?: (mapStyle: MapStyles) => void;
  mapStyle: MapStyles;
}
const dataHcName = 'satellite-toggle';
export const SatelliteToggle = ({
  mapId,
  className,
  position = ControlPosition.BottomLeft,
  onChange,
  mapStyle,
}: Props) => {
  const {
    actions: { hcMapChangeMapStyle },
  } = useHcMap(mapId);

  return (
    <div
      className={classNames(
        className,
        styles.SatelliteToggle,
        styles[position]
      )}
      data-hc-name={dataHcName}
      onClick={() => {
        const newMapStyle =
          mapStyle === MapStyles.Default
            ? MapStyles.Satellite
            : MapStyles.Default;
        hcMapChangeMapStyle({
          mapId,
          mapStyle: newMapStyle,
        });
        onChange?.(newMapStyle);
      }}
    >
      <label className={styles.Label} data-hc-name={`${dataHcName}-label`}>
        {mapStyle === MapStyles.Default ? 'Satellite' : 'Map'}
      </label>
      <div
        className={styles.MapImg}
        data-hc-name={`${dataHcName}-img`}
        style={{
          backgroundImage: `url(${
            mapStyle === MapStyles.Default ? LayerImgSatellite : LayerImgMap
          })`,
        }}
      />
    </div>
  );
};
