import React from 'react';

import {
  CreateNewOrderState,
  OrderTypeDescriptor,
  ProductType,
  STEP,
} from '@hcs/types';

import { PRODUCT_IDS } from '../constants';
import { ProductButton } from '../ProductButton';
import { createProductTypeChoices } from '../utils';

interface Props {
  orderTypeDescriptors: OrderTypeDescriptor[];
  updateStep: (stateToUpdate: Partial<CreateNewOrderState>) => void;
  className?: string;
}

const dataHcName = 'product-selector';
export const ProductSelector = ({
  orderTypeDescriptors,
  updateStep,
  className,
}: Props) => {
  const productTypeChoices = createProductTypeChoices(orderTypeDescriptors);

  const onProductClick = (
    productType: ProductType,
    orderTypes: OrderTypeDescriptor[],
    stateToUpdate?: Partial<CreateNewOrderState>
  ) => {
    const currentOrderType = orderTypes;
    const skipInspection =
      currentOrderType &&
      currentOrderType.length === 1 &&
      currentOrderType[0] &&
      currentOrderType[0].deliverySpeeds.length < 2;
    const nextStep = skipInspection
      ? STEP.ADD_ADDRESS_METHOD
      : STEP.INSPECTION_METHOD;
    updateStep({ step: nextStep, productType, ...stateToUpdate });
  };

  return (
    <div data-hc-name={dataHcName} className={className}>
      {productTypeChoices &&
        productTypeChoices.map((choice) => {
          const productType = PRODUCT_IDS[choice.groupKey] || null;
          if (!productType) return null;
          return (
            <ProductButton
              dataHcName={`${dataHcName}-${productType}`}
              key={productType}
              productType={productType}
              product={choice}
              onClick={(productType, orderTypes, updatedState) => {
                const selectedOrderType =
                  orderTypes?.length === 1 ? orderTypes[0] : undefined;
                onProductClick(productType, orderTypes || [], {
                  ...updatedState,
                  selectedOrderType,
                });
              }}
            />
          );
        })}
    </div>
  );
};
