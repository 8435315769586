import React, { ReactNode, useRef } from 'react';
import anime from 'animejs';

import { Button } from '@hcs/design-system';
import { useComponentDidMount } from '@hcs/hooks';
import { CONTACT_SALES_URL } from '@hcs/urls';

import feather from '../../assets/limited-access/feather-bottom-no-shadow.png';
import FeatherShadow from '../../assets/limited-access/feather-shadow.svg?react';
import featherFlipped from '../../assets/limited-access/feather-top-no-shadow-flipped.png';

import styles from './LimitedAccess.module.css';

const dataHcName = 'restricted-view';

interface Props {
  title?: ReactNode;
  cta?: ReactNode;
  onContactSalesClick?: VoidFunction;
}
export const LimitedAccess = ({ title, cta, onContactSalesClick }: Props) => {
  const featherContainer = useRef<HTMLDivElement | null>(null);
  const firstFeather = useRef<HTMLDivElement | null>(null);
  const firstFeatherImage = useRef<HTMLImageElement | null>(null);
  const firstFeatherShadow = useRef<HTMLDivElement | null>(null);
  const secondFeather = useRef<HTMLDivElement | null>(null);
  const secondFeatherImage = useRef<HTMLImageElement | null>(null);
  const secondFeatherShadow = useRef<HTMLDivElement | null>(null);
  useComponentDidMount(() => {
    const featherContainerEl = featherContainer.current;
    const featherContainerHeight =
      (featherContainerEl && featherContainerEl.clientHeight) || 455;

    const animateFirstFeather = anime.timeline();

    animateFirstFeather
      .add({
        targets: firstFeatherShadow.current,
        translateY: (featherContainerHeight / 6) * 4 + 45,
        scale: 1.2,
        duration: 0,
      })
      .add({
        targets: firstFeatherImage.current,
        opacity: 1,
        scale: 1.2,
        easing: 'easeInOutQuad',
        duration: 1100,
      })
      .add({
        targets: firstFeather.current,
        translateX: 200,
        translateY: featherContainerHeight / 6,
        easing: 'easeInOutQuad',
        duration: 1100,
        rotate: -30,
        offset: '-=1100', // Starts 1100ms before the previous animation ends
      })
      .add({
        targets: firstFeather.current,
        translateX: 0,
        translateY: (featherContainerHeight / 6) * 2,
        easing: 'easeInOutQuad',
        duration: 1200,
        rotate: 0,
      })
      .add({
        targets: firstFeather.current,
        translateX: 200,
        translateY: (featherContainerHeight / 6) * 3,
        easing: 'easeInOutQuad',
        duration: 1300,
        rotate: -30,
      })
      .add({
        targets: firstFeather.current,
        translateX: 0,
        translateY: (featherContainerHeight / 6) * 4,
        easing: 'easeInOutQuad',
        duration: 1400,
        rotate: 0,
      })
      .add({
        targets: firstFeatherShadow.current,
        opacity: 1,
        easing: 'easeInOutQuad',
        duration: 1400,
        offset: '-=500', // Starts 500ms before the previous animation ends
      });

    const animateSecondFeather = anime.timeline();

    animateSecondFeather
      .add({
        targets: secondFeatherShadow.current,
        translateY: (featherContainerHeight / 8) * 4 + 42,
        scale: 0.8,
        duration: 100,
      })
      .add({
        targets: secondFeatherImage.current,
        easing: 'easeInOutQuad',
        scale: 0.8,
        opacity: 0,
        duration: 1500,
      })
      .add({
        targets: secondFeatherImage.current,
        easing: 'easeInOutQuad',
        opacity: 1,
        duration: 500,
      })
      .add({
        targets: secondFeather.current,
        translateX: 100,
        translateY: featherContainerHeight / 8,
        easing: 'easeInOutQuad',
        duration: 1000,
        rotate: -44,
        offset: '-=500', // Starts 500ms before the previous animation ends
      })
      .add({
        targets: secondFeather.current,
        translateX: -100,
        translateY: (featherContainerHeight / 8) * 2,
        easing: 'easeInOutQuad',
        duration: 1100,
        rotate: 0,
      })
      .add({
        targets: secondFeather.current,
        translateX: 100,
        translateY: (featherContainerHeight / 8) * 3,
        easing: 'easeInOutQuad',
        rotate: -42,
        duration: 1200,
      })
      .add({
        targets: secondFeather.current,
        translateX: 0,
        translateY: (featherContainerHeight / 8) * 4,
        easing: 'easeInOutQuad',
        rotate: 0,
        duration: 1300,
      })
      .add({
        targets: secondFeatherShadow.current,
        easing: 'easeInOutQuad',
        opacity: 1,
        duration: 900,
        offset: '-=800', // Starts 800ms before the previous animation ends
      });
  });

  return (
    <div data-hc-name={dataHcName} className={styles.LimitedAccess}>
      <div className={styles.LimitedAccessContent}>
        <p
          className={styles.LimitedAccessMessage}
          data-hc-name={`${dataHcName}-message`}
        >
          {title || 'You have limited access to HouseCanary products'}
        </p>
        <div className={styles.FeatherContainer} ref={featherContainer}>
          <div
            data-hc-name={`${dataHcName}-feather-2`}
            className={styles.SecondFeather}
            ref={secondFeather}
          >
            <img
              alt="feather"
              className={styles.SecondFeatherImage}
              src={featherFlipped}
              ref={secondFeatherImage}
            />
          </div>
          <div className={styles.SecondFeatherShadow} ref={secondFeatherShadow}>
            <FeatherShadow />
          </div>
          <div
            data-hc-name={`${dataHcName}-feather-1`}
            className={styles.FirstFeather}
            ref={firstFeather}
          >
            <img
              alt="feather"
              className={styles.FirstFeatherImage}
              src={feather}
              ref={firstFeatherImage}
            />
          </div>
          <div className={styles.FirstFeatherShadow} ref={firstFeatherShadow}>
            <FeatherShadow />
          </div>
        </div>
        {cta || (
          <a
            onClick={onContactSalesClick}
            target="_blank"
            rel="noreferrer"
            href={CONTACT_SALES_URL}
            data-hc-name={`${dataHcName}-contact-sales-link`}
          >
            <Button dataHcName={`${dataHcName}-contact-sales-button`}>
              Contact Sales
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};
