import { checkUserIsHcAdmin, HC_ORG_ID } from '@hcs/auth';
import { Account } from '@hcs/types';

export const checkUserBelongsToHc = (
  org: Account['user']['organization'] | undefined
) => org?.id === HC_ORG_ID;

export const checkIsAccountInternal = (
  account: Account | undefined
): boolean => {
  return account?.currentOrganization.id === HC_ORG_ID;
};

export const checkIsHcAdmin = (account: Account): boolean => {
  return checkIsAccountInternal(account) && checkUserIsHcAdmin(account);
};
