import React from 'react';
import classNames from 'classnames';

import { Glossary } from '@hcs/glossary';
import { GlossaryTerms } from '@hcs/types';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { useSelectedOrSuggestedComps } from '../../hooks';

import styles from './SelectedOrSuggestedComps.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const dataHcName = 'selected-or-suggested-comps-label';
export const SelectedOrSuggestedCompsLabel = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: compsData } = useSelectedOrSuggestedComps(reportId, compType);
  const isRental = compType === CompTypes.Rental;
  const { comps, isSuggested } = compsData || {};
  return (
    <div
      data-hc-name={`${dataHcName}-message`}
      className={classNames(styles.Label, className)}
    >
      {isSuggested
        ? `HouseCanary Suggested ${isRental ? 'Rental ' : ''}Comps`
        : `You have selected ${formatNumber(comps?.length)} ${
            isRental ? 'rental ' : ''
          }comp${comps?.length !== 1 ? 's' : ''}`}
      <Glossary
        className={styles.Glossary}
        glossaryTerms={[
          isRental ? GlossaryTerms.RentalComps : GlossaryTerms.ComparableValue,
        ]}
      />
    </div>
  );
};
