import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';
import { RiskLevel, RiskType } from '@hcs/types';
import { formatRiskLevel, formatRiskType } from '@hcs/utils';

import { AppraisalRiskLevelIcon } from './AppraisalRiskLevelIcon';

import styles from './AppraisalRisk.module.css';

interface Props {
  riskType: RiskType;
  riskLevel: RiskLevel;
  className?: string;
  children?: ReactNode;
  iconProps?: IconProps;
  includeType?: boolean;
  includeLevel?: boolean;
  includeIcon?: boolean;
  colorBg?: boolean;
}
export const AppraisalRisk = ({
  riskLevel,
  riskType,
  className,
  colorBg,
  iconProps,
  includeIcon,
  includeLevel,
  includeType,
  children,
}: Props) => {
  return (
    <span
      className={classNames(className, {
        [styles.colorBg]: colorBg === true,
        [styles.high]: riskLevel === 'HIGH_RISK',
        [styles.med]: riskLevel === 'RISK',
        [styles.low]: riskLevel === 'LOW_RISK',
      })}
    >
      {includeIcon && (
        <AppraisalRiskLevelIcon {...iconProps} riskLevel={riskLevel} />
      )}
      {includeType && riskLevel === 'RISK' && `${formatRiskType(riskType)} `}
      {includeLevel && formatRiskLevel(riskLevel)}
      {includeType && riskLevel !== 'RISK' && ` ${formatRiskType(riskType)}`}
      {children}
    </span>
  );
};
