import { useMemo } from 'react';

import { AppConfig, AppSlugs, CapabilityApplications } from '@hcs/types';

import { EXTERNAL_APP_CONFIGS } from '../constants';

export const useExternalAppConfigsByKey = () => {
  return useMemo(() => {
    const data: Partial<Record<CapabilityApplications, AppConfig>> = {};
    EXTERNAL_APP_CONFIGS.forEach((appConfig) => {
      data[appConfig.applicationKey] = appConfig;
    });
    return data;
  }, []);
};

export const useExternalAppConfigsByLegacySlug = () => {
  return useMemo(() => {
    const data: Partial<Record<AppSlugs, AppConfig>> = {};
    EXTERNAL_APP_CONFIGS.forEach((appConfig) => {
      data[appConfig.appSlug] = appConfig;
    });
    return data;
  }, []);
};
