import React, { useEffect, useState } from 'react';

import { Switch } from '@hcs/design-system';
import { BuyBoxListItem } from '@hcs/types';

import { useBuyBoxPatch } from '../../';

interface ActiveSwitchProps {
  isActive: boolean;
  buyBoxId: BuyBoxListItem['id'];
}
const dataHcName = 'active-switch';
export const ActiveSwitch = (props: ActiveSwitchProps) => {
  const [isActive, setIsActive] = useState(props.isActive);

  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);

  const { mutate: updateBuyBox } = useBuyBoxPatch();
  const onChange = (isActive: boolean) => {
    // we want an immediate update to the UI
    setIsActive(isActive);
    updateBuyBox({
      id: props.buyBoxId,
      enabled: isActive,
    });
  };

  return (
    <Switch<boolean>
      dataHcName={dataHcName}
      value={isActive}
      onChange={onChange}
      switchOnOption={{ value: true }}
      switchOffOption={{ value: false }}
    />
  );
};
