import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Chart.module.css';

interface Props {
  dataHcName: string;
  className?: string;
  children: ReactNode;
}
export const ChartTitle = ({ dataHcName, className, children }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ChartTitle, className)}
    >
      {children}
    </div>
  );
};
