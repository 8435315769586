import React from 'react';

import { ThumbnailCarousel, ThumbnailCarouselProps } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { StreetViewDialog } from '@hcs/street-view';
import {
  ListingStatusCerberus,
  PropertyStateArgs,
  PropertyStateCerberusInput,
  PropertyStateFields,
} from '@hcs/types';

import { PropertyCardPillLabel } from '../../components';
import { PROPERTY_STATE_FIELD_CONFIGS } from '../../configs';
import { usePropertyStateImages, usePropertyStateLocation } from '../../hooks';
import { PropertyStatePhotoOverlayPage } from '../PropertyStatePhotoOverlayPage';

import styles from './PropertyStateThumbnailCarousel.module.css';

export interface PropertyStateThumbnailCarouselProps
  extends Omit<ThumbnailCarouselProps, 'dataHcName' | 'photos'> {
  propertyStateCerberusInput: PropertyStateCerberusInput;
  includePhotoPage?: boolean;
  propertyStateArgs?: PropertyStateArgs;
  dataHcName?: string;
  showStatusPillLabel?: boolean;
  onPhotoViewToggle?: VoidFunction;
}
export const PropertyStateThumbnailCarousel = ({
  propertyStateCerberusInput,
  includePhotoPage = true,
  propertyStateArgs,
  dataHcName = 'cerberus-thumbnail-carousel',
  showStatusPillLabel,
  onPhotoViewToggle,
  ...thumbnailCarouselProps
}: PropertyStateThumbnailCarouselProps) => {
  const { active, handleClose, handleOpen } = useActiveState();
  const { getValue } =
    PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentStatus];
  const currentStatus = getValue(propertyStateArgs);

  const onHandleClose = () => {
    handleClose();
    onPhotoViewToggle?.();
  };

  const imagesQuery = usePropertyStateImages(propertyStateCerberusInput);
  const locationQuery = usePropertyStateLocation(propertyStateCerberusInput);
  const photoPageAvailable =
    includePhotoPage && !!imagesQuery.data?.listingPhotos?.length;
  const streetViewAvailable = !!imagesQuery.data?.streetView;
  return (
    <>
      {photoPageAvailable ? (
        <PropertyStatePhotoOverlayPage
          active={active}
          onClose={onHandleClose}
          propertyStateCerberusInput={propertyStateCerberusInput}
        />
      ) : streetViewAvailable ? (
        <StreetViewDialog
          dataHcName={`${dataHcName}-street-view`}
          active={active}
          onClose={onHandleClose}
          location={locationQuery.data?.location}
        />
      ) : null}
      <ThumbnailCarousel
        {...thumbnailCarouselProps}
        isLoading={imagesQuery.isInitialLoading}
        dataHcName={dataHcName}
        photos={
          imagesQuery.data?.allPhotos.length
            ? imagesQuery.data.allPhotos
            : imagesQuery.data?.mapTile
            ? [imagesQuery.data.mapTile]
            : []
        }
        onClick={
          photoPageAvailable || streetViewAvailable
            ? () => {
                handleOpen();
                onPhotoViewToggle?.();
              }
            : undefined
        }
      >
        {showStatusPillLabel &&
          propertyStateArgs &&
          currentStatus === ListingStatusCerberus.Active && (
            <PropertyCardPillLabel
              value={ListingStatusCerberus.Active}
              className={styles.ActiveLabel}
              dataHcName={`${dataHcName}-active-pill-label`}
              propertyStateArgs={propertyStateArgs}
            />
          )}
      </ThumbnailCarousel>
    </>
  );
};
