import { gql } from 'graphql-request';

export const FRAGMENT_COMPLEX_FIELDS_SALE_PREVIEW = gql`
  fragment ComplexFieldsSalePreview on ComplexFieldsSale {
    currentHcMlsId
    currentListingId
    currentPrice
    currentStatus
    currentStatusDate
    currentlyOnMarket
    currentDaysOnMarketMLS
  }
`;

export const FRAGMENT_COMPLEX_FIELDS_SALE_CORE = gql`
  ${FRAGMENT_COMPLEX_FIELDS_SALE_PREVIEW}
  fragment ComplexFieldsSaleCore on ComplexFieldsSale {
    ...ComplexFieldsSalePreview
    currentHcpyLookupKey
    currentSourceId
    currentDaysOnMarketCumulative
    currentDaysToCloseCumulative
    lastCloseHcMlsId
    lastCloseHcpyLookupKey
    lastCloseListingId
    currentArmsLength
    currentDistressed
    currentFlipYN
    currentListDate
    currentListingPrice
    lastCloseDate
    lastClosePrice
  }
`;
