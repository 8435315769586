import React, { ReactNode } from 'react';

import { Skeleton, SkeletonFlex } from '@hcs/design-system';
import { KeywordSearchListingRemarksTooltip } from '@hcs/keyword-search';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import {
  formatMissing,
  formatNumber,
  formatPropertyType,
  formatSqFt,
} from '@hcs/utils';

import { PropertyDetailsHeader } from '../../components-deprecated';
import { SubjectListingStatusBadge } from '../../features/SubjectListingStatusBadge';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './SubjectDetailsHeader.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
  contentRight?: ReactNode;
  children?: ReactNode;
}
const dataHcName = 'subject-details-header';
export const SubjectDetailsHeaderDeprecated = ({
  reportId,
  className,
  contentRight,
  children,
}: Props) => {
  const { isInitialLoading, data: subjectDocument } =
    useSubjectDocument(reportId);
  if (isInitialLoading) {
    return (
      <div
        data-hc-name={`${dataHcName}-skeleton`}
        style={{ minHeight: '85px', width: '100%' }}
      >
        <Skeleton
          dataHcName={`${dataHcName}-skeleton-h1`}
          type="h1"
          style={{ padding: '15px' }}
        />
        <SkeletonFlex
          dataHcName={`${dataHcName}-skeleton-details`}
          numCells={3}
          justifyContent="flex-start"
          cellWidth="150px"
        />
      </div>
    );
  }
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument?.data.propertyState,
  };
  return (
    <PropertyDetailsHeader
      dataHcName={dataHcName}
      afterAddress={
        <SubjectListingStatusBadge
          className={styles.ListingStatusIndicator}
          reportId={reportId}
        />
      }
      location={subjectDocument?.data.propertyState.location}
      propertyAttributes={[
        `${formatPropertyType(
          getPropertyStateFieldValue(
            PropertyStateFields.propertyType,
            propertyStateArgs
          )
        )}`,
        `${formatNumber(
          getPropertyStateFieldValue(
            PropertyStateFields.bedrooms,
            propertyStateArgs
          )
        )} Beds`,
        `${formatMissing(
          getPropertyStateFieldValue(
            PropertyStateFields.bathrooms,
            propertyStateArgs
          )
        )} Baths`,
        `${formatSqFt(
          getPropertyStateFieldValue(
            PropertyStateFields.livingArea,
            propertyStateArgs
          )
        )}`,
        `${formatSqFt(
          getPropertyStateFieldValue(
            PropertyStateFields.lotSize,
            propertyStateArgs
          )
        )} Lot`,
      ]}
      publicRemarks={
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[ReportFeatures.SubjectListingRemarks]}
        >
          {subjectDocument?.data.propertyState && (
            <KeywordSearchListingRemarksTooltip
              date={
                subjectDocument.data.propertyState.complexFieldsSale
                  ?.currentListDate
              }
              remarks={
                subjectDocument.data.propertyState.listingDetailsSale?.remarks?.find(
                  (r) => r?.type === 'Public'
                )?.value
              }
            />
          )}
        </ReportFeaturesSupported>
      }
      className={className}
      contentRight={contentRight}
    >
      {children}
    </PropertyDetailsHeader>
  );
};
