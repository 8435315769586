import React, { MouseEventHandler } from 'react';

import { CircleButton } from '@hcs/design-system';
import { StreetViewIcon } from '@hcs/design-system';

interface Props {
  dataHcName: string;
  dataHcEventSection?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
}

export const StreetViewButton = ({
  dataHcName,
  dataHcEventSection,
  onClick,
  className,
  disabled,
}: Props) => {
  return (
    <CircleButton
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      className={className}
      disabled={disabled}
      size="xs"
    >
      <StreetViewIcon dataHcName={`${dataHcName}-street-view-icon`} size="xs" />
    </CircleButton>
  );
};
