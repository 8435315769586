import React, { useCallback } from 'react';

import { useUserIsOrgAdmin } from '@hcs/auth';
import { useUserIsSelfService } from '@hcs/auth';
import { UserMenuButtonProps } from '@hcs/design-system';
import {
  ItemsIcon,
  OrgSettingsIcon,
  PersonalizeReportsIcon,
} from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import { useExperienceFlags } from '@hcs/experience-flags';
import {
  APP_CONFIG_ORG_ADMIN,
  APP_CONFIG_PEXP,
  APP_CONFIG_USER_ADMIN,
  PEXP_UI_REPORT_CONFIG_SLUGS,
  useNavigateToPropertyPreview,
  VIEW_PATHS_ORG_ADMIN,
  VIEW_PATHS_PEXP,
  VIEW_PATHS_USER_ADMIN,
} from '@hcs/hc-products';
import { useActiveState } from '@hcs/hooks';
import { PersonalizationFormDialog } from '@hcs/huell';
import { ReportSearchTabPopover } from '@hcs/report-api';
import {
  AddressSearchHit,
  PropertyGraphListing,
  ReportSearchInputs,
} from '@hcs/types';

import {
  SolutionsPlatformGlobalHeader,
  SolutionsPlatformGlobalHeaderProps,
} from '../../../../navigation/SolutionsPlatformGlobalHeader';
import { useNavigateToReport } from '../../hooks/useNavigateToReport';
import { useNavigateToReportsHistory } from '../../hooks/useNavigateToReportsHistory';

const REPORT_LIST_FILTERS: ReportSearchInputs = {
  reportConfigSlug: PEXP_UI_REPORT_CONFIG_SLUGS,
};
const AuthenticatedActionPopovers = () => {
  const navigateToPropertyPreview = useNavigateToPropertyPreview();
  const navigateToReport = useNavigateToReport();
  const navigateToReportsHistory = useNavigateToReportsHistory();
  const EXPERIENCE_FLAGS = useExperienceFlags();
  const onSelectAddressHit = useCallback(
    (addressHit: AddressSearchHit) => {
      navigateToPropertyPreview({
        hcAddressId: Number(addressHit.id),
        slug: addressHit.fields.slug,
      });
    },
    [navigateToPropertyPreview]
  );
  const onSelectListingHit = useCallback(
    (listing: PropertyGraphListing) => {
      if (listing.address?.hcAddressId || listing.address?.slug) {
        navigateToPropertyPreview({
          hcAddressId: listing.address?.hcAddressId,
          slug: listing.address?.slug,
        });
      }
    },
    [navigateToPropertyPreview]
  );
  return EXPERIENCE_FLAGS.PEXP_REPORT_API ? (
    <ReportSearchTabPopover
      reportSearchInputs={REPORT_LIST_FILTERS}
      onSelectAddressHit={onSelectAddressHit}
      onSelectReportHit={navigateToReport}
      onSelectListingHit={onSelectListingHit}
      onClickFooterLink={navigateToReportsHistory}
    />
  ) : null;
};

export const PexpGlobalHeader = (props: SolutionsPlatformGlobalHeaderProps) => {
  const { active, handleClose, handleOpen } = useActiveState();
  const PEXP_EXPERIENCE_FLAG = useExperienceFlag('PEXP_REPORT_API');
  const { data: isOrgAdmin } = useUserIsOrgAdmin();
  const { data: isSelfService } = useUserIsSelfService();
  const buttonGroup: UserMenuButtonProps[] = [
    {
      dataHcName: 'personalize-reports',
      label: 'Personalize Reports',
      icon: <PersonalizeReportsIcon />,
      description:
        'Manage the branding and personal info of your shared reports.',
      onClick: handleOpen,
    },
  ];
  if (PEXP_EXPERIENCE_FLAG) {
    buttonGroup.push({
      dataHcName: 'report-history',
      label: 'Report History',
      icon: <ItemsIcon />,
      description: 'View your previously reviewed Property Explorer Reports.',
      navigateTo: `${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.REPORT_HISTORY}`,
    });

    if (isOrgAdmin) {
      buttonGroup.push({
        dataHcName: 'report-preferences',
        label: 'Organization Report Preferences',
        icon: <OrgSettingsIcon />,
        description:
          'Change Property Explorer Report settings for your organization',
        navigateTo: `${APP_CONFIG_ORG_ADMIN.rootPath}/${VIEW_PATHS_ORG_ADMIN.ORG_REPORT_PREFERENCES}`,
      });
    } else if (isSelfService) {
      // Self service users aren't allowed to the org admin app
      buttonGroup.push({
        dataHcName: 'report-preferences',
        label: 'Report Preferences',
        icon: <OrgSettingsIcon />,
        description: 'Change Property Explorer Report settings',
        navigateTo: `${APP_CONFIG_USER_ADMIN.rootPath}/${VIEW_PATHS_USER_ADMIN.REPORT_PREFERENCES}`,
      });
    }
  }
  return (
    <>
      <PersonalizationFormDialog active={active} onClose={handleClose} />
      <SolutionsPlatformGlobalHeader
        authenticatedActionPopovers={<AuthenticatedActionPopovers />}
        userMenuProps={{
          buttonGroups: [buttonGroup],
        }}
        {...props}
      />
    </>
  );
};
