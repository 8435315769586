import React from 'react';

import {
  dataHcEventSectionGenerateAnalysis,
  dataHcEventSectionSelectDataPoints,
  useDexpEndpointsTable,
} from '@hcs/data-explorer';
import { SubNav } from '@hcs/design-system';
import { APP_CONFIG_DEXP, VIEW_PATHS_DEXP } from '@hcs/hc-products';

const dataHcName = 'new-analysis-page-nav';
export const NewAnalysisPageNav = () => {
  const {
    state: { selectedLength },
  } = useDexpEndpointsTable();

  const SUB_NAV_ITEMS_CONFIG = [
    {
      navigateTo: `${APP_CONFIG_DEXP.rootPath}/${VIEW_PATHS_DEXP.NEW_ANALYSIS}`,
      label: 'New analysis',
      dataHcName: `${dataHcName}-new-analysis-header`,
      dataHcEventSection: dataHcEventSectionSelectDataPoints,
      children: [
        {
          navigateTo: `${APP_CONFIG_DEXP.rootPath}/${VIEW_PATHS_DEXP.NEW_ANALYSIS}/${VIEW_PATHS_DEXP.SELECT_DATA_POINTS}`,
          label: 'Select data points',
          dataHcName: `${dataHcName}-select-data-points`,
          dataHcEventSection: dataHcEventSectionSelectDataPoints,
        },
        {
          navigateTo: `${APP_CONFIG_DEXP.rootPath}/${VIEW_PATHS_DEXP.NEW_ANALYSIS}/${VIEW_PATHS_DEXP.GENERATE_ANALYSIS}`,
          label: 'Generate analysis',
          dataHcName: `${dataHcName}-generate-analysis`,
          disabled: selectedLength === 0,
          dataHcEventSection:
            selectedLength === 0
              ? undefined
              : dataHcEventSectionGenerateAnalysis,
        },
      ],
    },
  ];

  return (
    <SubNav dataHcName={dataHcName} subNavItemsConfig={SUB_NAV_ITEMS_CONFIG} />
  );
};
