import { useCallback, useState } from 'react';
import Bowser from 'bowser';

import { useWindowResize } from '@hcs/hooks';
import { BrowserEventData } from '@hcs/types';

const browser = Bowser.getParser(window.navigator.userAgent);
const getDeviceEventData = (): BrowserEventData => ({
  browser: browser.getBrowserName(),
  browserVersion: browser.getBrowserVersion(),
  browserHeight: window.innerHeight,
  browserWidth: window.innerWidth,
  os: browser.getOSName(),
  osVersion: browser.getOSVersion(),
  userAgent: browser.getUA(),
});
export const useDeviceEngagementEventData = (): BrowserEventData => {
  const [deviceEventData, setDeviceEventData] = useState(getDeviceEventData());
  const resizeCallback = useCallback(() => {
    setDeviceEventData(getDeviceEventData());
  }, []);
  useWindowResize(resizeCallback);
  return deviceEventData;
};
