import { DexpEndpoint, DexpEndpointLevel } from '@hcs/types';

export const dexpCorrectEndpointIdAndLevel = (endpoint: DexpEndpoint) => {
  if (endpoint.level === DexpEndpointLevel.Property) {
    if (endpoint.id.includes('value_report')) {
      return {
        level: 'value-report',
        endpointId: endpoint.id
          .split('value_report')[1]
          ?.split('_')
          .filter((p) => !!p)
          .join('-'),
      };
    }
    return {
      level: endpoint.level,
      endpointId: endpoint.id.split('/')[1],
    };
  } else if (endpoint.level === DexpEndpointLevel.ValueReport) {
    return {
      level: endpoint.level,
      endpointId: endpoint.id.split('/')[1]?.replace(/_/g, '-'),
    };
  } else {
    return {
      level: endpoint.level,
      endpointId: endpoint.id.split('/')[1]?.split('_')?.slice(1).join('_'),
    };
  }
};

export const dexpFixEndpointId = (endpoint: DexpEndpoint) => {
  const { endpointId, level } = dexpCorrectEndpointIdAndLevel(endpoint);
  return `${level}/${endpointId}`;
};
