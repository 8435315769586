import { JSXElementConstructor } from 'react';

import { SpatialSimilarity } from '../cerberus';
import { TableCellProps, TableHeaderCellProps } from '../design-system';
import { NestedObjectPaths } from '../json-patch/JsonPatch.types';
import { PropertyStateCore, PropertyStatePreview } from '../property-state';
import { CompId, CompSchema, LocationSimilarityType } from '../report-api';

export enum CompTypes {
  Sold = 'SOLD',
  Rental = 'RENTAL',
}
export enum CompFields {
  similarity = 'similarity',
  locationSimilarity = 'locationSimilarity',
  distance = 'distance',
  adjustedCompValue = 'adjustedCompValue.value',
  adjustedCompValuePerSqFt = 'adjustedCompValue.valuePerSqFt',
  adjustedCompValueRental = 'adjustedCompValueRental.value',
  adjustedCompValuePerSqFtRental = 'adjustedCompValueRental.valuePerSqFt',
  hcAdjustmentDate = 'hcAdjustmentDate',
  hcAdjustmentPropertyDetails = 'hcAdjustmentPropertyDetails',
  hcAdjustmentDateRental = 'hcAdjustmentDateRental',
  hcAdjustmentPropertyDetailsRental = 'hcAdjustmentPropertyDetailsRental',
  userTotalDollarAdjustment = 'userTotalDollarAdjustment',
  // Copy of userTotalAdjustment but for appraisal_comps
  appraisalTotalDollarAdjustment = '_appraisalTotalDollarAdjustment',
}

/** Based on Report-Api CompsSchema but supports other propertyState variations */
interface CompState
  extends Omit<Partial<CompSchema>, 'propertyState' | 'compID'> {
  propertyState?: PropertyStateCore | PropertyStatePreview;
  compID: CompId;
}

export type CompStateFlat = Partial<{
  // TODO: Replace w/ Fragment
  [CompFields.similarity]: SpatialSimilarity | null;
  [CompFields.locationSimilarity]: LocationSimilarityType | null;
  [CompFields.distance]: number | null;
  [CompFields.adjustedCompValue]: number | null;
  [CompFields.adjustedCompValuePerSqFt]: number | null;
  [CompFields.adjustedCompValueRental]: number | null;
  [CompFields.adjustedCompValuePerSqFtRental]: number | null;
  [CompFields.hcAdjustmentDate]: number | null;
  [CompFields.hcAdjustmentPropertyDetails]: number | null;
  [CompFields.hcAdjustmentDateRental]: number | null;
  [CompFields.hcAdjustmentPropertyDetailsRental]: number | null;
  [CompFields.userTotalDollarAdjustment]: number | null;
  [CompFields.appraisalTotalDollarAdjustment]: number | null;
}>;

export interface CompFieldsArgs {
  comp: CompState | undefined;
  className?: string;
}

export interface CompFieldsArgsProps extends CompFieldsArgs {
  className?: string;
}

export type CompFieldsTableCellProps = CompFieldsArgs & TableCellProps;

/**
 * Used for type-safe paths in json-patch operations.
 * It won't guarantee you're patching the CORRECT path,
 * but it will guarantee you're patching a path that exists
 */
export type CompPaths = `/${NestedObjectPaths<
  Omit<CompState, 'propertyState'>
>}`;

export interface CompFieldsEditArg<F extends CompFields> {
  path: CompPaths;
  value: CompStateFlat[F];
  field: F;
}

export interface CompFieldConfig<F extends CompFields> {
  field: F;
  /** Full Label */
  label: string;
  /** Abbreviated Label */
  labelShort: string;
  /** Small Label used when rendering multiple fields in a line */
  labelMicro?: string;
  /** Path to this field on the PropertyState type, useful for PATCHing property data */
  compPath: CompPaths;
  getValue: (comp: CompState | undefined) => CompStateFlat[F];
  /** Formats value as a string */
  formatValue: (comp: CompState | undefined) => string;
  /** Formats a value as a string for use when rendering multiple fields in a line */
  formatValueShort?: (comp: CompState | undefined) => string;
  /** Formats value for display inline w/ label (e.g. High Similarity)*/
  formatInline: (comp: CompState | undefined) => string;
  /** Main way to display the field, can be a component such as ListingStatusIndicator */
  Display: JSXElementConstructor<CompFieldsArgsProps>;
  /** TableHeader for when the field is displayed in a table column */
  HeaderCell: JSXElementConstructor<TableHeaderCellProps>;
  /** TableCell for displaying the value in a table */
  ContentCell: JSXElementConstructor<TableCellProps & CompFieldsArgs>;
  /** Input for editing the field. Not supported by all fields  */
  Edit?: JSXElementConstructor<
    CompFieldsArgs & {
      onChange: (args: CompFieldsEditArg<F>) => void;
      className?: string;
    }
  >;
  /** Glossary or info tooltip information description of the field  */
  info?: string;
}
