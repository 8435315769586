import { gql } from 'graphql-request';

export const FRAGMENT_LISTING_IMAGE = gql`
  fragment ListingImage on ListingMediaHcImages {
    derived {
      classification {
        confidence
        prediction
        predictionDescription
        predictionGroup
      }
    }
    order
    type
    url
  }
`;

export const FRAGMENT_LISTING_MEDIA = gql`
  ${FRAGMENT_LISTING_IMAGE}
  fragment ListingMedia on ListingMediaHc {
    images {
      ...ListingImage
    }
  }
`;
