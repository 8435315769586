import { CompFields, PropertyStateFields } from '@hcs/types';
import {
  ReportConfig,
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportFeatureTypes,
} from '@hcs/types';

import { REPORT_FEATURE_TYPE_FEATURES } from '../constants/reportFeatureConfigs.constants';

export const reportFeaturesSupportedAny = (
  reportConfig: ReportConfig | undefined,
  reportFeatures: (ReportFeatures | null)[]
): boolean => {
  for (const reportModule of reportFeatures) {
    if (reportModule && reportConfig?.reportFeaturesSupport[reportModule]) {
      return true;
    }
  }
  return false;
};

export const reportFeaturesSupportedAll = (
  reportConfig: ReportConfig | undefined,
  reportFeatures: (ReportFeatures | null)[]
): boolean => {
  for (const reportModule of reportFeatures) {
    if (reportModule && !reportConfig?.reportFeaturesSupport[reportModule]) {
      return false;
    }
  }
  return true;
};

export const reportFeaturesSupportedMoreThanOne = (
  reportConfig: ReportConfig | undefined,
  reportFeatures: (ReportFeatures | null)[]
): boolean => {
  let supported = 0;
  for (const reportModule of reportFeatures) {
    if (reportModule && reportConfig?.reportFeaturesSupport[reportModule]) {
      supported++;
    }
    if (supported > 1) {
      return true;
    }
  }
  return false;
};

const SUPPORT_TYPE_UTILS: {
  [key in ReportFeaturesSupportTypes]: (
    reportConfig: ReportConfig | undefined,
    reportFeatures: (ReportFeatures | null)[]
  ) => boolean;
} = {
  [ReportFeaturesSupportTypes.Any]: reportFeaturesSupportedAny,
  [ReportFeaturesSupportTypes.All]: reportFeaturesSupportedAll,
  [ReportFeaturesSupportTypes.MoreThanOne]: reportFeaturesSupportedMoreThanOne,
};

export const reportFeaturesSupported = (
  reportConfig: ReportConfig | undefined,
  reportFeatures: (ReportFeatures | null)[],
  reportFeaturesSupportType: ReportFeaturesSupportTypes
) => {
  return SUPPORT_TYPE_UTILS[reportFeaturesSupportType](
    reportConfig,
    reportFeatures
  );
};

export const valueReportSupported = (
  reportConfig: ReportConfig | undefined
): boolean =>
  reportFeaturesSupportedAny(
    reportConfig,
    REPORT_FEATURE_TYPE_FEATURES[ReportFeatureTypes.PropertyValue] || []
  );

export const rentalReportSupported = (
  reportConfig: ReportConfig | undefined
): boolean =>
  reportFeaturesSupportedAny(
    reportConfig,
    REPORT_FEATURE_TYPE_FEATURES[ReportFeatureTypes.PropertyValueRental] || []
  );

export const reportSaleValueOnly = (
  reportConfig: ReportConfig | undefined
): boolean =>
  !rentalReportSupported(reportConfig) && valueReportSupported(reportConfig);

export const reportRentalValueOnly = (
  reportConfig: ReportConfig | undefined
): boolean =>
  rentalReportSupported(reportConfig) && !valueReportSupported(reportConfig);

export const fieldsToSupportMapping = (
  fields: (PropertyStateFields | CompFields)[]
): ReportConfig['fieldsSupport'] => {
  return fields.reduce<ReportConfig['fieldsSupport']>((curr, f) => {
    curr[f] = true;
    return curr;
  }, {});
};
