import { UseQueryResult } from '@tanstack/react-query';

import {
  PropertyStateArgsCore,
  PropertyStateArgsPreview,
  PropertyStateCerberusInput,
  PropertyStateType,
} from '@hcs/types';

import { usePropertyStateCore } from '../hooks/usePropertyStateCore';
import { usePropertyStatePreview } from '../hooks/usePropertyStatePreview';

export const usePropertyState = ({
  cerberusInput,
  propertyStateType = PropertyStateType.Preview,
  onSuccess,
}: PropertyStateCerberusInput & {
  onSuccess?: (
    propertyStateArgs: PropertyStateArgsCore | PropertyStateArgsPreview
  ) => void;
}): UseQueryResult<PropertyStateArgsCore | PropertyStateArgsPreview> => {
  const coreQuery = usePropertyStateCore(
    propertyStateType === PropertyStateType.Core ? cerberusInput : undefined,
    onSuccess ? { onSuccess } : undefined
  );
  const previewQuery = usePropertyStatePreview(
    propertyStateType === PropertyStateType.Preview ? cerberusInput : undefined,
    onSuccess ? { onSuccess } : undefined
  );
  return propertyStateType === PropertyStateType.Core
    ? coreQuery
    : previewQuery;
};
