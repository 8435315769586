export type ValueTypes = 'SOLD' | 'RENTAL';

// Please keep alphabetical
export enum PropertyStateFields {
  age = 'calculatedFields.age',
  apn = 'propertyDetails.apn',
  association = 'propertyDetails.association',
  associationAmenities = 'propertyDetails.associationAmenities',
  associationFee = 'propertyDetails.association.fee',
  associationFeeFrequency = 'propertyDetails.association.frequency',
  associationFeeIncludes = 'propertyDetails.associationFeeIncludes',
  associationName = 'propertyDetails.association.name',
  basement = 'propertyDetails.basement',
  basementHas = 'propertyDetails.basement.has',
  bathrooms = 'propertyDetails.bathrooms.totalProjected',
  bedrooms = 'propertyDetails.bedrooms',
  concessionBuyerAmount = 'listingDetailsSale.concessions.buyerAmount',
  concessionBuyerDescription = 'listingDetailsSale.concessions.buyerDescription',
  concessions = 'listingDetailsSale.concessions.concessions',
  concessionsAmount = 'listingDetailsSale.concessions.concessionsAmount',
  concessionsComments = 'listingDetailsSale.concessions.concessionsComments',
  concessionSellerAmount = 'listingDetailsSale.concessions.sellerAmount',
  concessionSellerDescription = 'listingDetailsSale.concessions.sellerDescription',
  condition = 'propertyValue.valueAtSixConditions.conditionClass',
  cooling = 'propertyDetails.cooling',
  county = 'location.county',
  currentDistressed = 'complexFieldsSale.distressedYN',
  currentFlip = 'complexFieldsSale.currentFlipYN',
  currentListDate = 'complexFieldsSale.currentListDate',
  currentListDateRental = 'complexFieldsRental.currentListDate',
  currentListingPrice = 'complexFieldsSale.currentListingPrice',
  currentListingPricePerSqFt = 'calculatedFields.livingAreaFieldsSale.currentListingPricePerSqFt',
  currentListingPricePerSqFtRental = 'calculatedFields.livingAreaFieldsRental.currentListingPricePerSqFt',
  currentListingPriceRental = 'complexFieldsRental.currentListingPrice',
  currentPrice = 'complexFieldsSale.currentPrice',
  currentPricePerSqFt = 'calculatedFields.livingAreaFieldsSale.currentPricePerSqFt',
  currentPricePerSqFtRental = 'calculatedFields.livingAreaFieldsRental.currentPricePerSqFt',
  currentPriceRental = 'complexFieldsRental.currentPrice',
  currentStatus = 'complexFieldsSale.currentStatus',
  currentStatusDate = 'complexFieldsSale.currentStatusDate',
  currentStatusDateRental = 'complexFieldsRental.currentStatusDate',
  currentStatusRental = 'complexFieldsRental.currentStatus',
  currentValue = 'propertyValue.valueMean',
  currentValuePerSqFt = 'calculatedFields.livingAreaFieldsSale.propertyValueMeanPerSqFt',
  currentValuePerSqFtRental = 'calculatedFields.livingAreaFieldsRental.propertyValueMeanPerSqFt',
  currentValueRental = 'propertyValueRental.valueMean',
  daysOnMarketActive = 'complexFieldsSale.currentDaysOnMarketCumulative',
  daysOnMarketCumulative = 'complexFieldsSale.currentDaysToCloseCumulative',
  daysOnMarketMls = 'complexFieldsSale.currentDaysOnMLS',
  daysOnMarketMlsRental = 'complexFieldsRental.currentDaysOnMLS',
  garageSpaces = 'propertyDetails.parking.garage',
  garageType = 'propertyDetails.parking.parkingDetails.type',
  heating = 'propertyDetails.heating',
  landValue = 'land.valueLand',
  lastCloseDate = 'complexFieldsSale.lastCloseDate',
  lastCloseDateRental = 'complexFieldsRental.lastCloseDate',
  lastClosePrice = 'complexFieldsSale.lastClosePrice',
  lastClosePricePerSqFt = 'calculatedFields.livingAreaFieldsSale.lastClosePricePerSqFt',
  lastClosePricePerSqFtRental = 'calculatedFields.livingAreaFieldsRental.lastClosePricePerSqFt',
  lastClosePriceRental = 'complexFieldsRental.lastClosePrice',
  livingArea = 'propertyDetails.livingArea',
  listingAgentOffice = 'listingDetailsSale.agentOffice[type=list]',
  listingAgentOfficeRental = 'listingDetailsRental.agentOffice[type=list]',
  lotSize = 'propertyDetails.lotSize',
  ownerOccupied = 'taxDetails.ownership.ownerOccupied',
  parking = 'propertyDetails.parking',
  parkingTotal = 'propertyDetails.parking.totalCount',
  pool = 'propertyDetails.poolYN',
  propertyType = 'propertyDetails.propertyType',
  rentalYield = 'summary.rentalYield',
  roomsTotal = 'propertyDetails.roomsTotal',
  saleType = 'calculatedFields.saleType',
  stories = 'propertyDetails.storiesNumber',
  subdivisionName = 'location.subdivision',
  taxAmount = 'propertyDetails.tax.amountAnnual',
  taxYear = 'propertyDetails.tax.year',
  yearBuilt = 'propertyDetails.yearBuilt',
  zoning = 'propertyDetails.zoning.code',
}
