import React from 'react';

import { RiskSchema, RiskType } from '@hcs/types';

import { RISK_FACTORS_CONFIGS } from '../../constants/risk.constants';

import { AppraisalRisk } from './AppraisalRisk';

import styles from './AppraisalRiskFactors.module.css';

const ICON_PROPS = {
  height: 15,
};
interface Props {
  risk: RiskSchema['comparables'] | RiskSchema['value'];
  riskType: RiskType;
}
const dataHcName = 'appraisal-risk';
export const AppraisalRiskFactors = ({ risk, riskType }: Props) => {
  const { riskLevel, riskFactors } = risk;
  return (
    <div data-hc-name={dataHcName} className={styles.BiasRiskFactors}>
      <div className={styles.Value}>
        <AppraisalRisk
          riskType={riskType}
          riskLevel={riskLevel}
          includeLevel
          includeType
        >
          {' '}
          - Factors
        </AppraisalRisk>
      </div>
      <ul className={styles.FactorsList}>
        {riskFactors.map(({ riskFactor, riskLevel: riskLevelFactor }) => {
          const { label } = RISK_FACTORS_CONFIGS[riskFactor];
          return (
            <li key={riskFactor}>
              <AppraisalRisk
                className={styles.Factor}
                riskType={riskType}
                riskLevel={riskLevelFactor}
                iconProps={ICON_PROPS}
                includeIcon
              >
                {label}
              </AppraisalRisk>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
