import { useMutation } from '@tanstack/react-query';

import { useLatestMutationData } from '@hcs/hooks';
import { OrderItemsSearchInputs } from '@hcs/types';

import { OrderManagerApi } from '../api/orderManagerApi';

export const useOrderItemsSearch = () => {
  // Return the latest successful result
  // Prevents results in autocomplete components from disappearing as the user types
  return useLatestMutationData(
    useMutation((orderItemsSearchInputs: OrderItemsSearchInputs) =>
      OrderManagerApi.fetchOrderItemsSearch(orderItemsSearchInputs)
    )
  );
};
