import React from 'react';

export const PexpCompSelectionDrawPage = React.lazy(
  () => import('./PexpCompSelectionDrawPage')
);

export const PexpCompSelectionPage = React.lazy(
  () => import('./PexpCompSelectionPage')
);

export const PexpEntryPage = React.lazy(() => import('./PexpEntryPage'));

export const PexpHuellFeRedirectPage = React.lazy(
  () => import('./PexpHuellFeRedirectPage')
);

export const PexpReportHistoryPage = React.lazy(
  () => import('./PexpReportHistoryPage')
);

export const PexpReportPage = React.lazy(() => import('./PexpReportPage'));

export const BiasPage = React.lazy(() => import('./BiasPage'));
export const PexpReportPdfPage = React.lazy(
  () => import('./PexpReportPdfPage')
);
