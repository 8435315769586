import { useFeatureFlagsForUser } from '@hcs/huell';
import { FeatureFlags } from '@hcs/types';

import { useHcsAdminFeatureFlagForOrg } from './useHcsAdminFeatureFlagForOrg';

interface IsFeatureFlagEnabledProps {
  orgId?: number;
  featureFlag: FeatureFlags;
}

export const useIsFeatureFlagEnabled = ({
  orgId,
  featureFlag,
}: IsFeatureFlagEnabledProps) => {
  const { data: featureFlagsForUser } = useFeatureFlagsForUser();
  const isFeatureEnabled = featureFlagsForUser?.[featureFlag];
  const { data: featureFlagForOrg } = useHcsAdminFeatureFlagForOrg({
    orgId,
    featureFlag: featureFlag,
  });
  return orgId ? featureFlagForOrg?.status === 'enabled' : isFeatureEnabled;
};
