import React, { useEffect, useState } from 'react';

import { ErrorIcon } from '@hcs/design-system';
import { formatMoney, unformat } from '@hcs/utils';

import styles from './ValueSelectorInput.module.css';

interface Props {
  onChange: (inputtedValue: string) => void;
  maxVal: number | null | undefined;
  minVal: number | null | undefined;
  dataHcName: string;
  disabled?: boolean;
  userValue?: number | null;
}

const EMPTY_PLACEHOLDER = '$';

export const ValueSelectorInput = ({
  onChange,
  minVal,
  maxVal,
  disabled,
  dataHcName,
  userValue,
}: Props) => {
  const [value, setValue] = useState(
    userValue ? formatMoney(userValue) : EMPTY_PLACEHOLDER
  );
  const [valueOutsideRange, setValueOutsideRange] = useState(false);

  useEffect(() => {
    const isOutsideRange =
      userValue != null &&
      maxVal != null &&
      minVal != null &&
      unformat(value) === unformat(userValue.toString()) &&
      (userValue > maxVal || userValue < minVal);
    if (isOutsideRange) {
      setValueOutsideRange(true);
    }
  }, [userValue, valueOutsideRange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputtedValue = event.target.value.replace(/\D/g, '').slice(0, 10);
    const inputValInt = parseInt(inputtedValue);
    setValue(inputtedValue);
    onChange(inputtedValue);
    const isOutsideRange =
      maxVal != null &&
      minVal != null &&
      !isNaN(inputValInt) &&
      (inputValInt > maxVal || inputValInt < minVal);
    const isInsideRange =
      !inputtedValue.length ||
      (maxVal != null &&
        minVal != null &&
        inputValInt < maxVal &&
        inputValInt > minVal);

    if (isOutsideRange) {
      setValueOutsideRange(true);
    }
    if (isInsideRange) {
      setValueOutsideRange(false);
    }
  };

  const handleBlur = () => {
    value.length
      ? setValue(formatMoney(unformat(value)))
      : setValue(EMPTY_PLACEHOLDER);
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value.replace('$', ''));
  };

  return (
    <>
      {valueOutsideRange && (
        <ErrorIcon
          dataHcName={`${dataHcName}-alert-icon`}
          className={styles.AlertIcon}
        />
      )}
      <input
        type="text"
        data-hc-name={`${dataHcName}-input`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={styles.Input}
        disabled={disabled}
      />
      {valueOutsideRange && (
        <span
          className={styles.WarningText}
          data-hc-name={`${dataHcName}-outside-range-text`}
        >
          Your price is outside HouseCanary’s predicted value range. Are you
          sure?
        </span>
      )}
    </>
  );
};
