import React from 'react';

import { PersonalizationHeader } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { ReportFeatures, ReportId } from '@hcs/types';

import { PersonalizationHeaderDeprecated } from '../../components-deprecated';
import { useReportCobranding } from '../../hooks/useReportCobranding';
import { hasDefaultCobrandingData } from '../../utils/cobranding.utils';

interface Props {
  reportId: ReportId;
  className?: string;
}
export const REPORT_PERSONALIZATION_HEADER_FEATURES = [
  ReportFeatures.CobrandedReport,
];
const dataHcName = 'cobranding-header';
export const ReportPersonalizationHeader = ({ reportId, className }: Props) => {
  const { data: reportCobranding } = useReportCobranding(reportId);
  const defaultCobrandingData =
    reportCobranding && hasDefaultCobrandingData(reportCobranding);
  return (
    reportCobranding &&
    defaultCobrandingData && (
      <ExperienceFlag
        experienceFlagId="PEXP_REDESIGN"
        now={
          <PersonalizationHeaderDeprecated
            personalization={reportCobranding}
            dataHcName={dataHcName}
            className={className}
          />
        }
        next={
          <PersonalizationHeader
            personalization={reportCobranding}
            dataHcName={dataHcName}
            dataHcEventSection="Cobranding"
            className={className}
          />
        }
      />
    )
  );
};
