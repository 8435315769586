import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItem } from '@hcs/types';

import { DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL } from '../../constants';
import { ItemIssuesPopover } from '../ItemIssuesPopover';

import { DisplayProps } from '.';

const Display = ({ orderItem }: DisplayProps) => {
  return (
    <div data-hc-name={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-issues`}>
      {orderItem?.validationChecksComplete ? (
        <ItemIssuesPopover validationResults={orderItem.validationResults} />
      ) : (
        'Validating...'
      )}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Issue(s)</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_ITEM_ISSUES_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Issues',
  sortable: true,
};
