import React from 'react';

import { PropertyDataSourcesTable } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { CompIdentifierAppraisal, ReportFeatures, ReportId } from '@hcs/types';

import { useAppraisalCompDataSourcesDocument } from '../../hooks/useAppraisalCompDataSourcesDocument';

export interface AppraisalCompDataSourcesProps {
  reportId: ReportId;
  compIdentifier: CompIdentifierAppraisal;
  className?: string;
  propertyFields?: PropertyStateFields[];
}
export const APPRAISAL_COMP_DATA_SOURCES_FEATURES = [
  ReportFeatures.AppraisalComps,
];
export const AppraisalCompDataSources = ({
  reportId,
  compIdentifier,
  propertyFields,
}: AppraisalCompDataSourcesProps) => {
  const { isInitialLoading, data: dataSourcesDoc } =
    useAppraisalCompDataSourcesDocument({ reportId, compIdentifier });
  return (
    <PropertyDataSourcesTable
      isLoading={isInitialLoading}
      propertyFields={propertyFields}
      propertyStateSources={dataSourcesDoc?.data}
    />
  );
};
