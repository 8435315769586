import { GeoPrecision } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { LimitedReportTypes } from '@hcs/types';

import { useReport, useSubjectDocument } from '../hooks';

export const useReportLimitedType = (reportId: ReportId) => {
  const reportQuery = useReport(reportId);
  const subjectQuery = useSubjectDocument(reportId);
  const limitedType: LimitedReportTypes[] = reportQuery.data?.limitedType || [];
  const limitedMapping: Partial<{ [key in LimitedReportTypes]: boolean }> = {};
  limitedType.forEach((l) => {
    limitedMapping[l] = true;
  });
  return {
    ...reportQuery,
    data: reportQuery.data
      ? {
          limitedType,
          limitedMapping,
          locationProvidedByUser:
            limitedMapping[LimitedReportTypes.MissingLocation] &&
            (subjectQuery.data?.data.propertyState.location?.geoPrecision ===
              GeoPrecision.Manual ||
              // TODO: Remove Uppercase condition when EXP-914 is fixed
              subjectQuery.data?.data.propertyState.location?.geoPrecision ===
                GeoPrecision.Manual.toUpperCase()),
        }
      : undefined,
  };
};
