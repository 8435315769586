import { DateStr } from '../Date.types';

import { OrderItemStatus } from './OrderItem.types';
import { INSPECTION_TYPE } from '.';

export enum AgileSuitePdfOrderTypeIds {
  AgileInsights = 'valueReport',
  AgileEvaluationExterior = 'exteriorInspectionAndValueReport',
  AgileEvaluationExteriorPlus = 'exteriorInspectionAndValueReportPlus',
  AgileEvaluationInterior = 'interiorExteriorInspectionAndValueReport',
  AgileEvaluationInteriorPlus = 'interiorExteriorInspectionAndValueReportPlus',
  InspectionExterior = 'exteriorInspection',
  InspectionExteriorPlus = 'exteriorInspectionPlus',
  InspectionInterior = 'interiorExteriorInspection',
  InspectionInteriorPlus = 'interiorExteriorInspectionPlus',
  BrokerPriceOpinionExterior = 'brokerPriceOpinionExterior',
}

export enum OrderSets {
  all = 'allOrders',
  active = 'activeOrders',
  completed = 'completedOrders',
  cancelled = 'cancelledOrders',
}

export type OrderStatus =
  | 'New'
  | 'ClientReview'
  | 'Accepted'
  | 'Complete'
  | 'CancelPending'
  | 'Cancelled';

export enum OrdersTableOptions {
  action = 'action',
  actualDeliveryDate = 'actualDeliveryDate',
  createdAt = 'createdAt',
  label = 'label',
  name = 'name',
  orderItemsCount = 'orderItemsCount',
  orderType = 'orderType',
  ownerEmail = 'ownerEmail',
  status = 'status',
}

export type StatusGroup = {
  name: string;
  includedStatuses: (string | null)[];
};

export type DataType =
  | 'currency'
  | 'date'
  | 'integer'
  | 'float'
  | 'string'
  | 'date_time'
  | 'boolean';

export type ResultColumn = {
  header: string;
  dataType: DataType;
  columnName: string;
};

export type OrderTypeId =
  | 'inspectAndEvaluate'
  | 'interiorExteriorInspectAndEvaluate'
  | 'evaluate'
  | 'exteriorInspectionAndValueReport'
  | 'interiorExteriorInspectionAndValueReport'
  | 'exteriorInspection'
  | 'interiorExteriorInspection'
  | 'valueReport'
  | 'exteriorInspectionAndAgileEvaluationCertified'
  | 'brokerPriceOpinionExterior';

export type OmPropertyType = {
  value: string;
  label: string;
};

export type DeliverySpeed = {
  id: string;
  label: string;
  surcharge?: number;
  estimatedDueDate?: Date;
  numberOfDays?: number;
};

export type GroupKey =
  | 'agile_evaluation'
  | 'inspection'
  | 'agile_insights'
  | 'broker_price_opinion';

export type OrderTypeDescriptor = {
  id: OrderTypeId;
  name: string;
  canCancel: boolean;
  canMessage: boolean;
  canRequestRevision: boolean;
  headerStatusGroups: StatusGroup[];
  needsAppraisal: boolean;
  needsInspection: boolean;
  shownFields: string[];
  requiredFields: string[];
  shownItemFields: string[];
  requiredItemFields: string[];
  statusGroups: StatusGroup[];
  resultColumns: ResultColumn[];
  propertyTypes: OmPropertyType[] | null;
  deliverySpeeds: DeliverySpeed[];
  groupKey?: GroupKey;
  inspectionType?: INSPECTION_TYPE | null;
  includesAsRepairedValue: boolean | null;
};
export interface OmOrder {
  id: number;
  customerOrderId: string;
  createdAt: string;
  actualDeliveryDate: DateStr | null;
  name: string;
  desiredDeliveryDate: string | null;
  expectedDeliveryDate: string | null;
  orderType: OrderTypeId;
  inspectionPartner: string | null;
  valuationPartner: string | null;
  status: OrderStatus;
  orderItemsCount: number;
  orderItemsToProcessCount: number;
  orderItemsToReviewCount: number;
  completeOrderItemsCount: number;
  incomingCommentsCount: number;
  intendedUse?: string;
  specialComments?: string;
  clientName?: string;
  clientAddress?: string;
  clientCity?: string;
  clientState?: string;
  clientZipcode?: string;
  clientPhone?: string;
  lenderName?: string;
  lenderAddress?: string;
  lenderCity?: string;
  lenderState?: string;
  lenderZipcode?: string;
  lenderPhone?: string;
  label?: string | null;
  maxItemStatus: OrderItemStatus | null;
  canCancel: boolean;
  orderTypeDescriptor: OrderTypeDescriptor;
  cancelled: boolean | null;
  ownerId: string;
  partialEnabled: boolean;
  maxPercentInvalidItems: number | null;
  deliverySpeed?: string | null;
  ownerEmail?: string | null;
  orderItemsCertifiedCount: number | null;
}

export enum OrderFilterIds {
  ownerId = 'ownerId',
  page = 'page',
  pageSize = 'pageSize',
  search = 'search',
  sortBy = 'sortBy',
  sortOrder = 'sortOrder',
  status = 'status',
}
export interface OrdersParams {
  [OrderFilterIds.ownerId]?: number;
  [OrderFilterIds.page]?: number;
  [OrderFilterIds.pageSize]?: number;
  [OrderFilterIds.search]?: string;
  [OrderFilterIds.sortBy]?: OrdersTableOptions;
  [OrderFilterIds.sortOrder]?: 'ASC' | 'DESC';
  [OrderFilterIds.status]?: OrderSets;
}
export interface OrdersParamsForApi {
  ownerId?: number;
  page?: number;
  pageSize?: number;
  ordering?: string;
  status?: OrderSets;
}

export type OrderProgress = Record<OrderItemStatus, number>;

export type HeaderStatusGroupDisplay = {
  label: string;
  percentage: number;
  key: string;
};
