import React from 'react';
import isEqual from 'lodash.isequal';

import { FilterRange, FilterRangeType, FORMAT_TYPES } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompFiltersAll, CompsFiltersPaths, ReportId } from '@hcs/types';
import { formatNumberAbbrev } from '@hcs/utils';

import { FilterCard } from '../../components-deprecated';
import { QuickFilter, QuickFilterButton } from '../../components-deprecated';
import {
  useCompsFiltersDocument,
  useDocumentPatch,
  usePatchCompNumberRangeFilter,
  useSubjectDocument,
} from '../../hooks';
import { convertNumArrayToFilterRange, subjectDisplay } from '../../utils';

import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}

const FIELD = PropertyStateFields.currentListingPriceRental;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = `comp-filter-${FIELD}`;

export const CompFilterListPriceRental = ({
  reportId,
  compType,
  className,
}: Props) => {
  const documentPatchMutation = useDocumentPatch(reportId);
  const { mutate: patchFilter } = usePatchCompNumberRangeFilter(
    reportId,
    compType
  );
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);

  if (!filterDocument || !subjectDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const filterValue = filterDocument.data.filters?.[FIELD];
  const subjectValue = getPropertyStateFieldValue(FIELD, {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument.data.propertyState,
  });

  const handleChangeRelativeValue = (
    relativeValue: CompFiltersAll[PropertyStateFields.currentListingPriceRental]['relativeValue']
  ) => {
    documentPatchMutation.mutate({
      reportId,
      document: filterDocument,
      operations: [
        {
          op: 'add',
          path: PATH_REL,
          value: relativeValue,
        },
        {
          op: 'add',
          path: PATH_FIELD,
          value: FIELD,
        },
      ],
    });
  };

  return (
    <FilterCard
      label={fieldConfig.label}
      info={
        subjectValue == null
          ? 'Missing Subject Value'
          : `Subject: ${formatNumberAbbrev(subjectValue)}`
      }
      dataHcName={dataHcName}
      className={className}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={FIELD}
        />
      }
    >
      {subjectValue && (
        <QuickFilter dataHcName={`${dataHcName}-quick-filter`}>
          <QuickFilterButton
            label="5%"
            active={isEqual([-0.05, 0.05], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-quick-filter-btn-0`}
            onClick={() => handleChangeRelativeValue([-0.05, 0.05])}
          />
          <QuickFilterButton
            label="10%"
            active={isEqual([-0.1, 0.1], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-quick-filter-btn-1`}
            onClick={() => handleChangeRelativeValue([-0.1, 0.1])}
          />
          <QuickFilterButton
            label="15%"
            active={isEqual([-0.15, 0.15], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-quick-filter-btn-2`}
            onClick={() => handleChangeRelativeValue([-0.15, 0.15])}
          />
          <QuickFilterButton
            label="20%"
            active={isEqual([-0.2, 0.2], filterValue?.relativeValue)}
            dataHcName={`${dataHcName}-quick-filter-btn-3`}
            onClick={() => handleChangeRelativeValue([-0.2, 0.2])}
          />
        </QuickFilter>
      )}
      <FilterRange
        maxVal={999999}
        initialValue={convertNumArrayToFilterRange(filterValue?.absoluteValue)}
        subjectDisplay={subjectDisplay(
          true,
          convertNumArrayToFilterRange(filterValue?.relativeValue)
        )}
        formatType={FORMAT_TYPES.MONEY}
        onBlur={(value: FilterRangeType) =>
          patchFilter?.absoluteValue(FIELD, value)
        }
        dataHcName={`${dataHcName}-range`}
      />
    </FilterCard>
  );
};
