import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  CerberusInput,
  PropertyStateStrengthOfOfferDocument,
  PropertyStateStrengthOfOfferQuery,
  PropertyStateStrengthOfOfferQueryVariables,
} from '@hcs/types';

const QUERY_KEY_CERBERUS_PROPERTY_STATE_STRENGTH_OF_OFFER =
  'cerberus-property-state-strength-of-offer';
export const usePropertyStateStrengthOfOffer = (
  cerberusInput: CerberusInput | undefined,
  offerPrice: number | null
) => {
  return useQuery(
    [
      QUERY_KEY_CERBERUS_PROPERTY_STATE_STRENGTH_OF_OFFER,
      cerberusInput,
      offerPrice,
    ],
    async () => {
      if (cerberusInput) {
        const result = await CerberusApi.fetchQuery<
          PropertyStateStrengthOfOfferQuery,
          PropertyStateStrengthOfOfferQueryVariables
        >(PropertyStateStrengthOfOfferDocument, {
          cerberusInput,
          offerPrice,
        });
        return result.lookup?.latestListing.sale?.derived?.hc?.strengthOfOffer;
      } else {
        return null;
      }
    },
    {
      enabled: !!cerberusInput,
    }
  );
};
