import { checkUserIsSelfService } from '../utils/appAccess.utils';

import { useAccount } from './useAccount';

export const useUserIsSelfService = () => {
  const accountQuery = useAccount();
  return {
    ...accountQuery,
    data: accountQuery.isSuccess
      ? checkUserIsSelfService(accountQuery.data)
      : undefined
  };
};
