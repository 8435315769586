import React from 'react';

import { MultiSelect, Option } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { ConditionEnum, PropertyStateFields } from '@hcs/types';
import { CompsFiltersPaths } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';
import { formatCondition } from '@hcs/utils';
import { CONDITION_VALUES } from '@hcs/utils';

import {
  useCompsFiltersDocument,
  useDocumentPatch,
  useSubjectDocument,
} from '../../../hooks';
import { useReportPermissions } from '../../../hooks/useReportPermissions';

import { CompFilterChip } from './CompFilterChip';

const OPTIONS: Option<number>[] = [
  {
    value: CONDITION_VALUES[ConditionEnum.Uninhabitable],
    label: formatCondition(CONDITION_VALUES[ConditionEnum.Uninhabitable]),
  },
  {
    value: CONDITION_VALUES[ConditionEnum.SignificantRepairs],
    label: formatCondition(CONDITION_VALUES[ConditionEnum.SignificantRepairs]),
  },
  {
    value: CONDITION_VALUES[ConditionEnum.WornButAdequate],
    label: formatCondition(CONDITION_VALUES[ConditionEnum.WornButAdequate]),
  },
  {
    value: CONDITION_VALUES[ConditionEnum.WellMaintained],
    label: formatCondition(CONDITION_VALUES[ConditionEnum.WellMaintained]),
  },
  {
    value: CONDITION_VALUES[ConditionEnum.Excellent],
    label: formatCondition(CONDITION_VALUES[ConditionEnum.Excellent]),
  },
  {
    value: CONDITION_VALUES[ConditionEnum.NearlyNew],
    label: formatCondition(CONDITION_VALUES[ConditionEnum.NearlyNew]),
  },
];

const FIELD = PropertyStateFields.condition;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = 'comp-filter-condition';
export const COMP_FILTER_CONDITION: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const label = filterValue?.absoluteValue
      ? filterValue.absoluteValue.map(formatCondition).join(', ')
      : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType }: CompFilterControlTypeProps) => {
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument || !reportPermissions) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }
    const filterValue = filterDocument.data.filters?.[FIELD];
    return (
      <MultiSelect<number>
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        disabled={!reportPermissions.isEditable}
        options={OPTIONS}
        onChange={(value: number[]) => {
          if (value.length === 0) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    );
  },
};
