import React, { ReactNode } from 'react';

import { SingleColumnContent } from '@hcs/design-system';
import { DocumentRoles, ReportId } from '@hcs/types';

import { DOCUMENT_ROLE_REPORT_FEATURES } from '../../constants/reportFeatureConfigs.constants';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import { SubjectMap } from '../SubjectMap';
import {
  SUBJECT_PHOTO_CARD_FEATURES,
  SubjectPhotoCard,
} from '../SubjectPhotoCard';

import styles from './SubjectMapBanner.module.css';

interface Props {
  reportId: ReportId;
  children?: ReactNode;
}
const dataHcName = 'subject-map-banner';
export const SUBJECT_MAP_BANNER_FEATURES = [
  ...SUBJECT_PHOTO_CARD_FEATURES,
  ...(DOCUMENT_ROLE_REPORT_FEATURES[DocumentRoles.Subject] || []),
];
export const SubjectMapBanner = ({ reportId, children }: Props) => {
  return (
    <div data-hc-name={dataHcName} className={styles.SubjectMapBanner}>
      <SingleColumnContent className={styles.Content}>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={SUBJECT_PHOTO_CARD_FEATURES}
        >
          <SubjectPhotoCard
            reportId={reportId}
            className={styles.SubjectPhotoCard}
          />
        </ReportFeaturesSupported>
        {children}
      </SingleColumnContent>
      <div className={styles.Map}>
        <SubjectMap reportId={reportId} scrollZoom={false} />
      </div>
    </div>
  );
};
