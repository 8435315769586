import React from 'react';
import classNames from 'classnames';

import { ReportFeatures, ReportId } from '@hcs/types';

import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAll } from '../../utils/reportConfig.utils';
import { DaysOnMarketChart } from '../DaysOnMarketChart/DaysOnMarketChart';
import { MarketRiskChart } from '../MarketRiskChart';
import { MonthsOfSupplyChart } from '../MonthsOfSupplyChart';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './MarketAnalysis.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}
const dataHcName = 'market-analysis';
export const MARKET_ANALYSIS_FEATURES = [
  ReportFeatures.MarketAnalysisChart,
  ReportFeatures.DaysOnMarketChart,
  ReportFeatures.MonthsOfSupplyChart,
];
export const MarketAnalysis = ({ reportId, className }: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  return (
    <div data-hc-name={dataHcName} className={className}>
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={[ReportFeatures.MarketAnalysisChart]}
      >
        <MarketRiskChart reportId={reportId} />
      </ReportFeaturesSupported>
      <div
        className={classNames({
          [styles.DualRow]: reportFeaturesSupportedAll(reportConfig, [
            ReportFeatures.DaysOnMarketChart,
            ReportFeatures.MonthsOfSupplyChart,
          ]),
        })}
      >
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[ReportFeatures.MonthsOfSupplyChart]}
        >
          <MonthsOfSupplyChart reportId={reportId} />
        </ReportFeaturesSupported>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[ReportFeatures.DaysOnMarketChart]}
        >
          <DaysOnMarketChart reportId={reportId} />
        </ReportFeaturesSupported>
      </div>
    </div>
  );
};
