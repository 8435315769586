import camelcaseKeys from 'camelcase-keys';
import fileDownload from 'js-file-download';

import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import { OrderItem, OrderItemsParams } from '@hcs/types';
import { OrderItemPdfDownload } from '@hcs/types';
import { ORDER_MANAGER_CLIENT_API_URL } from '@hcs/urls';
import { makePaginatedResponseData } from '@hcs/utils';

import { ORDER_ITEMS_PER_PAGE } from '../constants';
import { prepOrderItemParamsForApi } from '../utils/orderItem.utils';

const fetchOrderItems = async (
  orderItemId: number,
  params: OrderItemsParams,
  signal?: AbortSignal
) => {
  const response = await AxiosAccessTokenClientJwt.get<OrderItem[]>(
    `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderItemId}/items/`,
    {
      signal,
      paramsSerializer: { indexes: null },
      params: params ? prepOrderItemParamsForApi(params) : undefined,
    }
  );
  return makePaginatedResponseData(
    response,
    {
      page: params?.page || 1,
      pageSize: params?.pageSize || ORDER_ITEMS_PER_PAGE,
    },
    { camelCaseKeys: true }
  );
};

const fetchOrderItemPdfDownload = async (
  orderItemId: number,
  orderId: number,
  pdfType: string
) => {
  const response = await AxiosAccessTokenClientJwt.get<OrderItemPdfDownload>(
    `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/items/${orderItemId}/pdfdownload/${pdfType}`
  );
  return camelcaseKeys(response.data, { deep: true });
};

const fetchOrderPdfDownload = async (orderId: number) => {
  const response = await AxiosAccessTokenClientJwt.get<OrderItemPdfDownload>(
    `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/pdfdownload`
  );
  return camelcaseKeys(response.data, { deep: true });
};

// for the order items page, when websocket updates are coming in and we only need to refetch the updated items
const fetchOrderItemIds = async (orderId: number, itemIds: number[]) => {
  const response = await AxiosAccessTokenClientJwt.get<OrderItem[]>(
    `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/items/`,
    { params: { id: itemIds }, paramsSerializer: { indexes: null } }
  );
  return camelcaseKeys<OrderItem[]>(response.data, { deep: true });
};

const downloadAllOrderItemData = (orderId: number, fileName: string) => {
  const url = `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/download`;

  return AxiosAccessTokenClientJwt.get(url, {
    headers: {
      'Content-Type': 'application/zip',
    },
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, fileName);
  });
};

const cancelOrderItem = async (orderId: number, orderItemId: string) => {
  const url = `${ORDER_MANAGER_CLIENT_API_URL}/orders/${orderId}/items/${orderItemId}/cancel`;

  await AxiosAccessTokenClientJwt.post(url);
};

export const ViewOrderApi = {
  cancelOrderItem,
  fetchOrderItems,
  fetchOrderItemIds,
  fetchOrderItemPdfDownload,
  fetchOrderPdfDownload,
  downloadAllOrderItemData,
};
