import { PortfolioAssetFieldConfig, PortfolioAssetFields } from '@hcs/types';

import { PORTFOLIO_ASSET_AVM_CONFIG } from './Avm';
import { PORTFOLIO_ASSET_CLOSING_DATE_CONFIG } from './ClosingDate';
import { PORTFOLIO_ASSET_CLOSING_PRICE_CONFIG } from './ClosingPrice';
import { PORTFOLIO_ASSET_INTEREST_RATE_CONFIG } from './InterestRate';
import { PORTFOLIO_ASSET_LAST_UPDATE_CONFIG } from './LastUpdate';
import { PORTFOLIO_ASSET_LISTING_DATE_CONFIG } from './ListingDate';
import { PORTFOLIO_ASSET_LISTING_PRICE_CONFIG } from './ListingPrice';
import { PORTFOLIO_ASSET_LOAN_CONTRACT_DATE_CONFIG } from './LoanContractDate';
import { PORTFOLIO_ASSET_LTV_CONFIG } from './Ltv';
import { PORTFOLIO_ASSET_MLS_STATUS_CONFIG } from './MlsStatus';
import { PORTFOLIO_ASSET_YEAR_BUILT_CONFIG } from './YearBuilt';

export const PORTFOLIO_ASSETS_TABLE_CONFIG: {
  [key in PortfolioAssetFields]: PortfolioAssetFieldConfig;
} = {
  [PortfolioAssetFields.ltv]: PORTFOLIO_ASSET_LTV_CONFIG,
  [PortfolioAssetFields.lastUpdate]: PORTFOLIO_ASSET_LAST_UPDATE_CONFIG,
  [PortfolioAssetFields.closingDate]: PORTFOLIO_ASSET_CLOSING_DATE_CONFIG,
  [PortfolioAssetFields.closingPrice]: PORTFOLIO_ASSET_CLOSING_PRICE_CONFIG,
  [PortfolioAssetFields.listingDate]: PORTFOLIO_ASSET_LISTING_DATE_CONFIG,
  [PortfolioAssetFields.listingPrice]: PORTFOLIO_ASSET_LISTING_PRICE_CONFIG,
  [PortfolioAssetFields.interestRate]: PORTFOLIO_ASSET_INTEREST_RATE_CONFIG,
  [PortfolioAssetFields.yearBuilt]: PORTFOLIO_ASSET_YEAR_BUILT_CONFIG,
  [PortfolioAssetFields.loanContractDate]:
    PORTFOLIO_ASSET_LOAN_CONTRACT_DATE_CONFIG,
  [PortfolioAssetFields.mlsStatus]: PORTFOLIO_ASSET_MLS_STATUS_CONFIG,
  [PortfolioAssetFields.avm]: PORTFOLIO_ASSET_AVM_CONFIG,
};
