import React from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';

import { InfoIcon } from '../../../../svgs/icons/function';
import { Tooltip, TooltipProps } from '../Tooltip';

import styles from './InfoTooltip.module.css';

const SIZES = {
  small: 10,
  medium: 15,
  large: 20,
};
export interface InfoTooltipProps extends Omit<TooltipProps, 'trigger'> {
  color?: IconProps['color'];
  size?: keyof typeof SIZES;
}
export const InfoTooltip = ({
  dataHcName,
  showOnMouseEnter,
  label,
  description,
  isLargeTooltip,
  theme,
  color = 'neutral-dark-30',
  size = 'medium',
  position,
}: InfoTooltipProps) => {
  return (
    <Tooltip
      showOnMouseEnter={showOnMouseEnter}
      dataHcName={dataHcName}
      position={position}
      theme={theme}
      isLargeTooltip={isLargeTooltip}
      trigger={
        <InfoIcon
          dataHcName={`${dataHcName}-icon`}
          className={classNames(styles.Icon)}
          size={SIZES[size]}
          color={color}
        />
      }
      label={label}
      description={description}
    />
  );
};
