import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@hcs/design-system';
import { TextButton } from '@hcs/design-system';
import { SearchInput } from '@hcs/design-system';
import { FlexScrollCard } from '@hcs/design-system';
import { UserAlert } from '@hcs/design-system';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { LineClamp } from '@hcs/design-system';
import { AddIcon } from '@hcs/design-system';
import { useSearchAndSort } from '@hcs/hooks';
import { BuyBox, BuyBoxSortableFields } from '@hcs/types';
import { SORT_ORDER, SortType } from '@hcs/types';
import { formatDateTime } from '@hcs/utils';

import { BUY_BOX_PAGE_PARAMS, MAP_PAGE_SEARCH_PARAMS } from '../../';
import { BulkEditBuyBoxDialog } from '../../';
import { CreateBuyBoxDialog } from '../../';
import { EditBuyBoxDialog } from '../../';
import { useBuyBoxList } from '../../';
import { ActiveBuyBoxCount } from '../ActiveBuyBoxCount';
import { ActiveSwitch } from '../ActiveSwitch';

import styles from './BuyBoxTable.module.css';

const ROW_HEIGHT = 60;
export const DEFAULT_BUY_BOX_SORT: SortType<BuyBoxSortableFields> = {
  field: 'lastModified',
  order: SORT_ORDER.Desc,
};
export interface MapPageLinkProps {
  queryString?: string;
  dataHcName: string;
  className?: string;
  label: string;
}

const NAME_COLUMN_WIDTH = 200;
interface BuyBoxTableProps {
  className?: string;
  MapPageLink: React.ComponentType<MapPageLinkProps>;
}

const dataHcName = 'buy-boxes';
export const BuyBoxTable = (props: BuyBoxTableProps) => {
  const { className, MapPageLink } = props;
  const { search, sort, onSearch, onSort } =
    useSearchAndSort<BuyBoxSortableFields>({
      queryParamNames: BUY_BOX_PAGE_PARAMS,
      options: {
        defaultSort: DEFAULT_BUY_BOX_SORT,
        replace: true,
      },
    });
  const [searchParams] = useSearchParams();
  const createMsaIdParam = searchParams.get(BUY_BOX_PAGE_PARAMS.Create);

  const { data: filteredSortedList, isInitialLoading } = useBuyBoxList({
    search: search || undefined,
    sort: sort || undefined,
  });
  const [isCreateDialogActive, setIsCreateDialogActive] = useState(
    createMsaIdParam !== null ? true : false
  );
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);
  const [isEditingBuyBoxId, setIsEditingBuyBoxId] = useState<
    BuyBox['id'] | null
  >(null);
  const [isBulkEditDialogActive, setIsBulkEditDialogActive] = useState(false);

  const [savedRowId, setSavedRowId] = useState<null | number>(null);

  /**
   * After a single buybox is saved, store the buybox's id so we highlight the row in green
   * and scroll to it if needed.
   * Unset the savedRowId after 5 seconds to remove the effect.
   */
  const saveSingleBBSuccess = (buyboxId: number) => {
    setSavedRowId(buyboxId);
    setTimeout(() => {
      setSavedRowId(null);
    }, 5000);
  };

  const isRowInViewport = (el: HTMLTableRowElement) => {
    if (!el) return false;
    const top = el.getBoundingClientRect().top;
    const offsetTop = window.pageYOffset;
    return top + offsetTop >= 0 && top - offsetTop <= window.innerHeight;
  };

  /**
   * Scroll to a row if the ref exists and it isn't in the viewport
   */
  const scrollToRow = useCallback((rowIdx: number) => {
    const scrollToEl = rowRefs.current[rowIdx];
    if (!scrollToEl || isRowInViewport(scrollToEl)) return;
    scrollToEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  /**
   * Listen for changes to length of the filteredSortedList and check if a new savedRowId
   * is set. This will trigger a scroll to that row. This might cause issues when we approach
   * deleting a row. If a user deletes a row right after saving one. Might need to revisit.
   *
   * I took this approach because when we get the new row's saved id, the filteredSortedList
   * isn't yet updated with the new buybox.
   */
  useEffect(() => {
    if (savedRowId) {
      const rowIdx = filteredSortedList.findIndex(
        (buybox) => buybox.id === savedRowId
      );
      if (rowIdx !== -1) scrollToRow(rowIdx);
    }
  }, [filteredSortedList, scrollToRow, savedRowId]);

  const [indicateBulkEditSuccess, setIndicateBulkEditSuccess] = useState(false);

  const disabledBuyBoxCount = useMemo(() => {
    return filteredSortedList.filter((result) => result.isActive === false)
      .length;
  }, [filteredSortedList]);

  const enabledBuyBoxCount = useMemo(
    () =>
      filteredSortedList.filter((result) => result.isActive === true).length,
    [filteredSortedList]
  );

  const createDialogOnClose = useCallback((savedId?: number) => {
    setIsCreateDialogActive(false);

    if (savedId !== undefined) {
      saveSingleBBSuccess(savedId);
    }
  }, []);

  const editBuyBoxOnClose = useCallback((savedId?: number) => {
    setIsEditingBuyBoxId(null);

    if (savedId !== undefined) {
      saveSingleBBSuccess(savedId);
    }
  }, []);

  const bulkEditBuyBoxOnClose = useCallback((saveSuccess: boolean) => {
    setIsBulkEditDialogActive(false);

    // if bulk edit save was successful, show indication for 4 seconds
    if (saveSuccess) {
      setIndicateBulkEditSuccess(true);

      setTimeout(() => {
        setIndicateBulkEditSuccess(false);
      }, 4000);
    }
  }, []);

  return (
    <>
      <div className={className}>
        <div className={styles.Search}>
          <SearchInput
            className={styles.SearchInput}
            value={search || ''}
            onChange={onSearch}
            dataHcName={`${dataHcName}-search-input`}
            placeholder="Quick search Buy Box name or MSA"
          />
          <ActiveBuyBoxCount
            className={styles.ActiveBuyBoxCount}
            numDisabledFiltered={disabledBuyBoxCount}
            numActiveFiltered={enabledBuyBoxCount}
          />
        </div>

        <FlexScrollCard
          fitViewHeight
          dataHcName={dataHcName}
          className={styles.Card}
          header={{
            height: 60,
            className: styles.ActionButtons,
            content: (
              <div className={styles.ActionButtons}>
                <Button
                  primary
                  icon={
                    <AddIcon
                      dataHcName={`${dataHcName}-create-buybox-button-icon`}
                    />
                  }
                  onClick={() => {
                    setIsCreateDialogActive(true);
                    setSavedRowId(null);
                  }}
                  dataHcName={`${dataHcName}-create-buybox-button`}
                >
                  Create New Buy Box
                </Button>
                <Button
                  className={styles.BulkEditButton}
                  secondary
                  onClick={() => {
                    setIsBulkEditDialogActive(true);
                  }}
                  dataHcName={`${dataHcName}-bulk-edit-button`}
                >
                  Bulk Edit
                </Button>
                {indicateBulkEditSuccess && (
                  <UserAlert
                    className={styles.BulkEditSuccessIndicator}
                    dataHcName={`${dataHcName}-bulk-edit-success-check`}
                    type="success"
                    text="Bulk Edit Successful"
                  />
                )}
              </div>
            ),
          }}
        >
          <Table
            dataHcName={`${dataHcName}-table`}
            skeletonConfig={{
              isLoading: isInitialLoading,
              colCount: 6,
              rows: 10,
              rowHeight: `${ROW_HEIGHT}px`,
            }}
          >
            <TableHeader sticky dataHcName={`${dataHcName}-table-header`}>
              <TableHeaderCell
                dataHcName={`${dataHcName}-name-header-cell`}
                sticky
                sortable
                width={NAME_COLUMN_WIDTH}
                sort={sort?.field === 'name' ? sort.order : undefined}
                onClick={() => onSort('name')}
              >
                Name
              </TableHeaderCell>
              <TableHeaderCell
                dataHcName={`${dataHcName}-msa-header-cell`}
                sortable
                sort={sort?.field === 'msaName' ? sort.order : undefined}
                onClick={() => onSort('msaName')}
                width={286}
              >
                MSA
              </TableHeaderCell>
              <TableHeaderCell
                dataHcName={`${dataHcName}-mapview-header-cell`}
                width={60}
              >
                Map View
              </TableHeaderCell>
              <TableHeaderCell
                dataHcName={`${dataHcName}-created-modified-header-cell`}
                sortable
                sort={sort?.field === 'lastModified' ? sort.order : undefined}
                onClick={() => onSort('lastModified')}
                width={140}
              >
                Last Modified
              </TableHeaderCell>
              <TableHeaderCell
                dataHcName={`${dataHcName}-active-header-cell`}
                width={84}
              >
                Active
              </TableHeaderCell>
              <TableHeaderCell
                dataHcName={`${dataHcName}-last-modified-by-header-cell`}
              >
                Last Modified By
              </TableHeaderCell>
            </TableHeader>
            {filteredSortedList.map((buyBox, idx) => (
              <TableRow
                key={buyBox.id}
                highlighted={buyBox.id === savedRowId}
                setRowRef={(el) => (rowRefs.current[idx] = el)}
                height={ROW_HEIGHT}
              >
                <TableCell
                  dataHcName={`${dataHcName}-name-cell`}
                  sticky
                  width={NAME_COLUMN_WIDTH}
                >
                  <TextButton
                    className={styles.EditButton}
                    dataHcName={`${dataHcName}-edit-button`}
                    onClick={() => setIsEditingBuyBoxId(buyBox.id)}
                    noPadding
                  >
                    <LineClamp>{buyBox.name}</LineClamp>
                  </TextButton>
                </TableCell>
                <TableCell dataHcName={`${dataHcName}-msa-cell`}>
                  {buyBox.msaName}
                </TableCell>
                <TableCell dataHcName={`${dataHcName}-mapview-cell`}>
                  <MapPageLink
                    queryString={`${MAP_PAGE_SEARCH_PARAMS.BuyBoxId}=${buyBox.id}`}
                    dataHcName={`${dataHcName}-mapview-link`}
                    className={styles.MapViewLink}
                    label="See Map"
                  />
                </TableCell>
                <TableCell
                  dataHcName={`${dataHcName}-created-modified-cell`}
                  width={160}
                >
                  {formatDateTime(buyBox.lastModified)}
                </TableCell>
                <TableCell dataHcName={`${dataHcName}-active-cell`}>
                  <ActiveSwitch
                    isActive={buyBox.isActive}
                    buyBoxId={buyBox.id}
                  />
                </TableCell>
                <TableCell dataHcName={`${dataHcName}-last-modified-by-cell`}>
                  {buyBox.lastModifiedBy}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </FlexScrollCard>
      </div>
      {isCreateDialogActive && (
        <CreateBuyBoxDialog
          active={isCreateDialogActive}
          msaId={createMsaIdParam !== null ? createMsaIdParam : undefined}
          onClose={createDialogOnClose}
        />
      )}
      {isEditingBuyBoxId !== null && (
        <EditBuyBoxDialog
          active={isEditingBuyBoxId !== null}
          buyBoxId={isEditingBuyBoxId}
          onClose={editBuyBoxOnClose}
        />
      )}
      {isBulkEditDialogActive && (
        <BulkEditBuyBoxDialog
          active={isBulkEditDialogActive}
          onClose={bulkEditBuyBoxOnClose}
        />
      )}
    </>
  );
};
