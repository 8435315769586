import React from 'react';

import { CardBadge } from '@hcs/design-system';
import { TableCell } from '@hcs/design-system';
import { TableCellProps } from '@hcs/types';
import { CompIdentifier, ReportId } from '@hcs/types';

import { useComp } from '../../../hooks';

import styles from './CompsListTableCells.module.css';

interface Props extends TableCellProps {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
}
const dataHcName = 'badges-cell';
export const BadgesCell = ({
  reportId,
  compIdentifier,
  ...tableCellProps
}: Props) => {
  const { data: comp } = useComp(reportId, compIdentifier);
  return (
    <TableCell
      {...tableCellProps}
      width={80}
      dataHcName={dataHcName}
      align="center"
      noBorder
      sticky
    >
      <div className={styles.Badges}>
        {comp?.isHcSuggestedComp && (
          <CardBadge dataHcName={`${dataHcName}-hc-suggested`} primary>
            HC Comp
          </CardBadge>
        )}
        {comp?.isAppraisalComp && (
          <CardBadge dataHcName={`${dataHcName}-hc-suggested`} secondary>
            Appraisal
          </CardBadge>
        )}
      </div>
    </TableCell>
  );
};

// Using default props allows for child.props checks
// in the Table components to work properly
const defaultProps: Partial<TableCellProps> = {
  dataHcName: 'BADGES',
  align: 'center',
  // Needed so child component type-checking passes
  isTableCell: true,
};
BadgesCell.defaultProps = defaultProps;
