import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { AnimatedCheck } from '../../../../foundations/svgs/icons/animated/AnimatedCheck';
import { InfoIcon } from '../../../../svgs/icons/function';
import { ErrorIcon } from '../../../../svgs/icons/indicator';

import styles from './UserAlert.module.css';

export interface UserAlertProps {
  dataHcName: string;
  className?: string;
  text: ReactNode;
  type: 'success' | 'warning' | 'error';
}
export const UserAlert = ({
  dataHcName,
  className,
  type,
  text,
}: UserAlertProps) => {
  let Icon = null;
  const iconDataHcName = `${dataHcName}-icon`;
  if (type === 'success') {
    Icon = (
      <AnimatedCheck className={styles.InfoIcon} dataHcName={iconDataHcName} />
    );
  } else if (type === 'warning') {
    Icon = (
      <InfoIcon
        className={classNames(styles.InfoIcon, {
          [styles.InfoIconWarning]: type === 'warning',
        })}
        dataHcName={iconDataHcName}
        size="lg"
      />
    );
  } else {
    Icon = (
      <ErrorIcon
        dataHcName={iconDataHcName}
        className={classNames(styles.ErrorIcon, {
          [styles.InfoIconError]: type === 'error',
        })}
        size="lg"
        color="error-10"
      />
    );
  }
  return (
    <div
      className={classNames(
        styles.UserAlertContainer,
        {
          [styles.UserAlertError]: type === 'error',
        },
        className
      )}
      data-hc-name={dataHcName}
    >
      {Icon}
      {text}
    </div>
  );
};
