import React from 'react';

import { ListingStatusIndicator } from '@hcs/property-state';
import { PropertyStateType } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useSubjectDocument } from '../../hooks';

interface Props {
  reportId: ReportId;
  isRental?: boolean;
  className?: string;
}
export const SubjectListingStatusBadge = ({
  reportId,
  isRental,
  className,
}: Props) => {
  const { data: subjectDocument } = useSubjectDocument(reportId);
  return (
    <ListingStatusIndicator
      value={{
        type: 'propertyState',
        value: {
          propertyStateType: PropertyStateType.Core,
          propertyState: subjectDocument?.data.propertyState,
        },
      }}
      className={className}
      isRental={isRental}
      showPrice
      showActiveOnly
    />
  );
};
