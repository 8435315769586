export enum FeatureFlags {
  AppraisalComparisonReports = 'appraisal-compare-reports',
  AutoOrgSharing = 'auto-org-sharing',
  EffectiveDateReports = 'effective-date-reports',
  ReportApi = 'report-api',
  ReportApiWebhook = 'report-api-webhook',
  OmReportApiAi = 'om-ai-via-report-api',
  OfferNowAvailable = 'offer-now',
  EditableLinksCompliance = 'editable-links-compliance-flag',
  PexpEditableLinks = 'pexp-editable-links',
  BrokerPartner = 'broker-partner',
}

export type FeatureFlagsData = { [key in FeatureFlags]: boolean };

export interface FeatureFlagAdminConfig {
  key: FeatureFlags;
  enabled: boolean;
  enabledByDefault: boolean;
  orgIdsEnabled: string;
  orgIdsBlocklist: string;
  name: string;
}

export interface FeatureFlagOrgTogglePayload {
  orgId: number;
  featureFlagConfig: FeatureFlagAdminConfig;
}

export type FeatureFlagCreatePayload = Omit<
  FeatureFlagAdminConfig,
  'orgIdsEnabled' | 'orgIdsBlocklist' | 'enabledByDefault'
>;

export type FeatureFlagUpdatePayload = Partial<
  Omit<
    FeatureFlagAdminConfig,
    'key' | 'enabledByDefault' | 'orgIdsEnabled' | 'orgIdsBlocklist'
  >
>;
