import { PortfolioTableColumns } from '@hcs/types';

export const DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL =
  'portfolio-assets-table-cell';

export const ALL_CONFIGURABLE_COLUMNS: PortfolioTableColumns[] = [
  'ltv',
  'closingDate',
  'closingPrice',
  'lastUpdate',
  'mlsStatus',
  'avm',
  'loanContractDate',
  'interestRate',
  'yearBuilt',
  'listingDate',
  'listingPrice',
];
