import React from 'react';
import classNames from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';

import { useAccount } from '@hcs/auth';
import { NoContent } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { ItemsIcon } from '@hcs/design-system';
import { Report, ReportSearchInputs } from '@hcs/types';
import { formatDateShort, formatFullAddress } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { useReports } from '../../hooks';

import styles from './ReportsList.module.css';

interface Props {
  onClick: (report: Report) => void;
  className?: string;
  loadingRows?: number;
  reportSearchInputs?: ReportSearchInputs;
}
const dataHcName = 'reports-list';
export const ReportsList = ({
  className,
  loadingRows = 5,
  reportSearchInputs,
  onClick,
}: Props) => {
  const accountQuery = useAccount();
  const { data: account } = accountQuery;
  const reportsQuery = useReports(
    {
      ownerId: account?.user.id,
      ...(reportSearchInputs || {}),
    },
    {
      enabled: !!account?.user.id,
    }
  );
  const combinedQuery = combineUseQueryResult([accountQuery, reportsQuery]);
  return (
    <ul
      data-hc-name={dataHcName}
      className={classNames(styles.ReportsList, className, {
        [styles.loading]: combinedQuery.isInitialLoading,
      })}
    >
      {combinedQuery.isInitialLoading ? (
        <Skeleton
          className={styles.Skeleton}
          dataHcName={`${dataHcName}-skeleton`}
          repeat={loadingRows}
        />
      ) : reportsQuery.data?.data.length ? (
        reportsQuery.data.data.map((report) => {
          return (
            <li
              className={styles.Row}
              data-hc-name={`${dataHcName}-row`}
              key={`reports-list-${report.id}`}
              onClick={() => onClick(report)}
            >
              <div
                data-hc-name={`${dataHcName}-row-icon`}
                className={styles.Icon}
              >
                <div className={styles.IconWrap}>
                  <ItemsIcon
                    dataHcName={`${dataHcName}-row-icon-svg`}
                    className={styles.IconSvg}
                    color="neutral-dark-20"
                  />
                </div>
              </div>
              <div
                data-hc-name={`${dataHcName}-row-label`}
                className={styles.Label}
              >
                {formatFullAddress({
                  address: report.streetAddress,
                  city: report.city,
                  unit: report.unit,
                  state: report.state,
                  zipcode: report.zipcode,
                })}
                <div
                  data-hc-name={`${dataHcName}-report-type`}
                  className={styles.ReportType}
                >
                  {`${report.reportConfig.reportName}: ${formatDateShort(
                    report.effectiveDate
                  )}`}
                </div>
              </div>
              <div
                data-hc-name={`${dataHcName}-row-date`}
                className={styles.Date}
              >
                {report.metadata.activeStatus === 'active' ? (
                  <>
                    <span data-hc-name={`${dataHcName}-days-remaining`}>
                      {differenceInDays(
                        new Date(report.metadata.expirationDate),
                        new Date()
                      )}
                    </span>{' '}
                    Days Remaining
                  </>
                ) : (
                  <>Expired</>
                )}
              </div>
            </li>
          );
        })
      ) : (
        <NoContent dataHcName={`${dataHcName}-no-content`}>
          No reports found
        </NoContent>
      )}
    </ul>
  );
};
