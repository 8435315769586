import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEventHandler,
  useCallback,
} from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import { useControlledInputHybrid, useForwardedRef } from '@hcs/hooks';

import { CloseIcon } from '../../../../svgs/icons/navigation';
import { SearchInputStyle } from '../SearchInputStyle';

import styles from './SearchInput.module.css';

export interface SearchInputTheme {
  InputElement: string;
}
export interface SearchInputProps {
  className?: string;
  dataHcName: string;
  placeholder?: string;
  disabled?: boolean;
  theme?: SearchInputTheme;
  value: string;
  /** For when this is rendered in a MultiSearch component */
  inMultiSearch?: boolean;
  onChangeDebounce?: number;
  withClear?: boolean;
  onChange: (value: string) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
}

export const SearchInput = forwardRef(
  (props: SearchInputProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      className,
      dataHcName,
      placeholder,
      theme,
      value: valueProp,
      disabled,
      onChange,
      onKeyUp,
      onBlur,
      inMultiSearch,
      withClear = false,
      onChangeDebounce,
    } = props;
    const onChangeCallback: SearchInputProps['onChange'] = useCallback(
      onChangeDebounce
        ? debounce(onChange, onChangeDebounce, { trailing: true })
        : onChange,
      [onChange, onChangeDebounce]
    );
    const [value, setValue] = useControlledInputHybrid(valueProp);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event) {
        const value = event.target.value || '';
        setValue(value);
        onChangeCallback(value);
      }
    };
    const handleClear = () => {
      const clearedValue = '';
      setValue(clearedValue);
      onChangeCallback(clearedValue);
    };
    const forwardedRef = useForwardedRef(ref);
    return (
      <SearchInputStyle
        className={classNames(
          {
            [styles.disabled]: disabled,
          },
          className
        )}
        unstyled={inMultiSearch}
        showIcon={!inMultiSearch}
      >
        <input
          ref={ref ? forwardedRef : undefined}
          className={classNames(styles.InputElement, theme?.InputElement)}
          data-hc-name={dataHcName}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
        />
        {withClear && value !== '' && (
          <CloseIcon className={styles.ClearIcon} onClick={handleClear} />
        )}
      </SearchInputStyle>
    );
  }
);
