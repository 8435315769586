import React from 'react';

import { FilterRange, TableCell, TableHeaderCell } from '@hcs/design-system';
import { PropertyStateFilterProps, SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatMoneyPerMonth } from '@hcs/utils';

const FIELD = PropertyStateFields.currentValueRental;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Current Rental Value';
const labelShort = 'Current Rental Value';
const propertyStatePath: PropertyStatePaths = '/propertyValueRental/value';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.propertyValueRental?.value;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatMoneyPerMonth(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `Rental Value ${formatValue(propertyStateArgs)}`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};
const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  return (
    <FilterRange
      className={className}
      disabled={disabled}
      maxVal={999999}
      initialValue={{
        min: spatialFiltersInput.minRentalAvmPrice
          ? formatMoneyPerMonth(spatialFiltersInput.minRentalAvmPrice)
          : '',
        max: spatialFiltersInput.maxRentalAvmPrice
          ? formatMoneyPerMonth(spatialFiltersInput.maxRentalAvmPrice)
          : '',
      }}
      onBlur={(value) => {
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            minRentalAvmPrice: value.min === '' ? null : Number(value.min),
            maxRentalAvmPrice: value.max === '' ? null : Number(value.max),
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

export const CURRENT_VALUE_RENTAL_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Filter,
  spatialSortField: SpatialSortField.RentalAvm,
};
