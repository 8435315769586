import { RiskFactors, RiskType } from '@hcs/types';

export const RISK_FACTORS_CONFIGS: {
  [key in RiskFactors['riskFactor']]: {
    label: string;
    riskType?: RiskType;
  };
} = {
  HC_COMP_SELECTED: {
    label: 'HouseCanary Suggested Comps Selected',
  },
  IS_HC_COMP: {
    label: 'HouseCanary Suggested Comps',
  },
  AVM_UNAVAILABLE: {
    label: 'AVM Unavailable',
    riskType: 'value',
  },
  VALUE_IN_RANGE: {
    label: 'Accurate Final Value',
    riskType: 'value',
  },
  HIGH_SIMILARITY_COMPS_SELECTED: {
    label: 'Highly Similar Comparables Selected',
    riskType: 'comparables',
  },
  SUBJECT_DETAILS_MATCH: {
    label: 'Accurate Subject Property Details',
    riskType: 'value',
  },
  SUBJECT_CONDITION_MATCH: {
    label: 'Accurate Subject Condition',
    riskType: 'value',
  },
  COMP_DETAILS_MATCH: {
    label: 'Accurate Comparable Property Details',
    riskType: 'comparables',
  },
  SUBJECT_DETAILS_DISCREPANCY: {
    label: 'Subject Property Details Discrepancy',
    riskType: 'value',
  },
  SUBJECT_CONDITION_DISCREPANCY: {
    label: 'Subject Condition Discrepancy',
    riskType: 'value',
  },
  COMP_DETAILS_DISCREPANCY: {
    label: 'Comp Property Details Discrepancy',
    riskType: 'comparables',
  },
  NO_HIGH_SIMILARITY_COMPS_AVAILABLE: {
    label: 'No Highly Similar Comps Available',
    riskType: 'comparables',
  },
  FEW_HIGH_SIMILARITY_COMPS_AVAILABLE: {
    label: 'Few Highly Similar Comps Available',
    riskType: 'comparables',
  },
  NO_MODERATE_SIMILARITY_COMPS_AVAILABLE: {
    label: 'No Similar Comparable Properties Available',
    riskType: 'comparables',
  },
  NO_SIMILAR_COMPS_SELECTED: {
    label: 'No Highly Similar Comps Selected',
    riskType: 'comparables',
  },
  VALUE_LOW: {
    label: 'Low Appraised Value',
    riskType: 'value',
  },
  VALUE_HIGH: {
    label: 'High Appraised Value',
    riskType: 'value',
  },
  COMP_DISTANCE_HALF_MILE: {
    label: 'Within 0.5 Miles',
  },
  COMP_DISTANCE_MILE: {
    label: 'Within 1 Mile',
  },
  COMP_DISTANCE_FAR: {
    label: 'Farther than 1 mile',
  },
  COMP_SIMILARITY_HIGH: {
    label: 'Highly Similar to Subject',
  },
  COMP_SIMILARITY_MODERATE: {
    label: 'Moderately Similar to Subject',
  },
  COMP_SIMILARITY_LOW: {
    label: 'Not Similar to Subject',
  },
  COMP_TRANSACTION_HALF_YEAR: {
    label: 'Transaction within 6 Months',
  },
  COMP_TRANSACTION_YEAR: {
    label: 'Transaction within 1 Year',
  },
  COMP_TRANSACTION_STALE: {
    label: 'Transaction Older Than 1 Year',
  },
};
