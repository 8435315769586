import { AxiosInstance, AxiosRequestConfig, default as axiosLib } from 'axios';
import { Fetch } from 'graphql-request/build/esm/types';
import { Headers as NodeHeaders } from 'node-fetch';

/**
 * Much of this is copied from https://github.com/lifeomic/axios-fetch/tree/master
 * This version is updated to support the newest version of graphql request
 */
export type HeadersLike =
  | string[][]
  | Record<string, string | undefined>
  | Headers
  | NodeHeaders;

export type UrlLike =
  | string
  | {
      href?: string;
      url?: string;
    };

export function createFetchHeaders(
  axiosHeaders: Record<string, string> = {}
): [string, string][] {
  const headers: [string, string][] = [];
  Object.entries(axiosHeaders).forEach(([name, value]) => {
    headers.push([name, value]);
  });
  return headers;
}

const isHeaders = (headers: HeadersLike): headers is Headers =>
  headers.constructor?.name === 'Headers';

export function createAxiosHeaders(
  headers: HeadersLike = {}
): Record<string, string> {
  const rawHeaders: Record<string, string> = {};

  if (isHeaders(headers)) {
    headers.forEach((value, name) => {
      rawHeaders[name] = value;
    });
  } else if (Array.isArray(headers)) {
    headers.forEach(([name, value]) => {
      if (value && name) {
        rawHeaders[name] = value;
      }
    });
  } else {
    Object.entries(headers).forEach(([name, value]) => {
      if (value) {
        rawHeaders[name] = value;
      }
    });
  }
  return rawHeaders;
}

export function getUrl(input?: UrlLike): string | undefined {
  let url: string | undefined;
  if (typeof input === 'string') {
    url = input;
  } else if (input?.href) {
    url = input.href;
  } else if (input?.url) {
    url = input.url;
  }
  return url;
}
export type AxiosTransformer = (
  config: AxiosRequestConfig,
  input: RequestInfo | URL,
  init?: AxiosRequestConfig
) => AxiosRequestConfig;

/**
 * A Fetch WebAPI implementation based on the Axios client
 */
const axiosFetch =
  (axios: AxiosInstance): Fetch =>
  async (input, init) => {
    const rawHeaders = createAxiosHeaders(init?.headers);
    const lowerCasedHeaders: Record<string, string> = {};
    Object.entries(rawHeaders).forEach(([name, value]) => {
      lowerCasedHeaders[name.toLowerCase()] = value;
    });

    if (!('content-type' in lowerCasedHeaders)) {
      lowerCasedHeaders['content-type'] = 'text/plain;charset=UTF-8';
    }

    const config: AxiosRequestConfig = {
      url:
        input instanceof URL || typeof input === 'string'
          ? input.toString()
          : // TS still thinks input can be a URL for some reason
            (input as Request).url,
      method: (init?.method as AxiosRequestConfig['method']) || 'GET',
      data: init?.body,
      headers: lowerCasedHeaders,
      // Force the response to an arraybuffer type. Without this, the Response
      // object will try to guess the content type and add headers that weren't in
      // the response.
      // NOTE: Don't use 'stream' because it's not supported in the browser
      responseType: 'arraybuffer',
    };

    // const config = transformer(rawConfig, input, init);

    let result;
    try {
      result = await axios.request(config);
    } catch (err: unknown) {
      if (axiosLib.isAxiosError(err) && err.response) {
        result = err.response;
      } else {
        throw err;
      }
    }

    return new Response(result.data, {
      status: result.status,
      statusText: result.statusText,
      headers: createFetchHeaders(result.headers as Record<string, string>),
    });
  };

export const buildAxiosFetch = (axios: AxiosInstance) => axiosFetch(axios);
