import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';

import { AuthApi, useSingleUseToken } from '@hcs/auth';
import { hcsConsole } from '@hcs/console';
import { useComponentDidMount } from '@hcs/hooks';
import { useComponentInternalId } from '@hcs/hooks';
import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { Account } from '@hcs/types';
import { DexpEvent, DexpEventsAll } from '@hcs/types';
import { DATA_EXPLORER_URL } from '@hcs/urls';

import { DEXP_WEB_SOCKET_URL } from '../constants';

type DexpOnMessageCallback = (dexpEvent: DexpEvent) => void;
interface Options {
  // Only call onMessage callback if the event is for a particular job id
  jobId?: number;
  onOpen?: VoidFunction;
  onError?: VoidFunction;
}
let dexpWebsocketConnection: WebSocket | null = null;
// Subscribed components keep their callbacks in a global
// mapping so all components can share a single connection
const listeners: {
  onOpen: Map<string, VoidFunction>;
  onError: Map<string, VoidFunction>;
  onMessage: Map<string, DexpOnMessageCallback>;
} = {
  onOpen: new Map(),
  onError: new Map(),
  onMessage: new Map(),
};

let retriedConnection = false;
// Close the websocket connection and clear the listeners
const closeWebsocketConnection = () => {
  hcsConsole.log('DEXP-Api: disconnected');
  listeners.onOpen.clear();
  listeners.onMessage.clear();
  listeners.onError.clear();
  dexpWebsocketConnection?.close();
  dexpWebsocketConnection = null;
  retriedConnection = false;
};

export const useSubscribeToDexpJobEvents = (
  onMessage: DexpOnMessageCallback,
  options?: Options
) => {
  // Internal Component Id used to identify the component that is
  // subscribed to the websocket updates
  const internalId = useComponentInternalId();
  const { jobId, onOpen, onError } = options || {};
  const queryClient = useQueryClient();
  const { mutateAsync: getSingleUseToken } = useSingleUseToken();
  // Create Websocket Connection and retry once
  const createWebsocketConnection = async () => {
    const { singleUseToken } = await getSingleUseToken();
    dexpWebsocketConnection = new WebSocket(
      `${DEXP_WEB_SOCKET_URL}?hctoken=${singleUseToken}`
    );
    dexpWebsocketConnection.onopen = () => {
      hcsConsole.log('DEXP-Api: connected');
      listeners.onOpen.forEach((callback) => {
        callback();
      });
    };
    dexpWebsocketConnection.onmessage = (event: MessageEvent<string>) => {
      const data = camelcaseKeys(JSON.parse(event.data), {
        deep: true,
      }) as DexpEventsAll;
      if (data.event !== 'debug') {
        // Output File in events is only the path, need to complete the url.
        data.job.outputFile = `${DATA_EXPLORER_URL}${data.job.outputFile}`;
        hcsConsole.logVerbose('DEXP-API: WS |', data);
        listeners.onMessage.forEach((callback) => {
          callback(data);
        });
      }
    };
    dexpWebsocketConnection.onerror = async () => {
      // TODO: How to determine if connection error is 401
      // For now, refresh the token and try again one time no matter what
      if (!retriedConnection) {
        closeWebsocketConnection();
        retriedConnection = true;
        const account = await AuthApi.fetchAccount();
        queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
        createWebsocketConnection();
      } else {
        listeners.onError.forEach((callback) => {
          callback();
        });
      }
    };
  };
  // Keep listeners up to date
  useEffect(() => {
    // Add listeners to the global mapping
    if (jobId) {
      // Filter messages if jobId is defined
      listeners.onMessage.set(internalId, (dexpEvent) => {
        if (dexpEvent.job.id === jobId) {
          onMessage(dexpEvent);
        }
      });
    } else {
      listeners.onMessage.set(internalId, onMessage);
    }
    if (onOpen) {
      listeners.onOpen.set(internalId, onOpen);
    }
    if (onError) {
      listeners.onOpen.set(internalId, onError);
    }
  }, [jobId, onMessage, onOpen, onError]);

  // Open and close connection
  useComponentDidMount(() => {
    // Do not attempt to connect if there isn't a token
    // Create the websocket connection if it does not exist
    if (!dexpWebsocketConnection) {
      createWebsocketConnection();
    }
    return () => {
      // Unsubscribe from global listeners
      listeners.onMessage.delete(internalId);
      listeners.onError.delete(internalId);
      listeners.onOpen.delete(internalId);
      // Close the connection if components are no longer subscribed
      if (!listeners.onMessage.size) {
        closeWebsocketConnection();
      }
    };
  });
};
