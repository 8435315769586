export enum ChartModalType {
  NearbyChart = 'nearbyChart',
  SimilarPropertiesChart = 'valuationChart',
  None = 'none'
}

export enum ChartType {
  Nearby = 'nearby',
  Comparables = 'comparables',
  Valuation = 'valuation',
  None = 'none'
}

export interface BarData {
  width: number;
  height: number;
  count: number;
  x: number;
  y: number;
}

export interface IndexedBarData {
  [index: number]: BarData;
}

export enum MenuItemType {
  SITE_AREA = 'siteArea',
  PRICE_SITE_AREA = 'priceSiteArea',
  AGE = 'age',
  BEDS = 'beds',
  BATHS = 'baths',
  SQFT = 'sqFt',
  PRICE_PER_SQ_FT = 'pricePerSqFt'
}

export enum ChartDataType {
  NUMBER = 'number',
  MONEY = 'money',
  NUMBER_ABBREVIATION = 'money_abbreviation'
}

export interface ChartDetails {
  buttonTitle: string;
  chartButtonText: JSX.Element;
}

export interface Section {
  chartType: ChartType;
}
