import { gql } from 'graphql-request';

import { FRAGMENT_MSA_CORE } from '@hcs/cerberus';

import {
  FRAGMENT_MSA_HCRI_CORE,
  FRAGMENT_ZIP_HCRI_CORE,
} from './fragments/Hcri.graphql';
import {
  FRAGMENT_MSA_HPI_CORE,
  FRAGMENT_MSA_HPI_TIME_SERIES,
  FRAGMENT_ZIP_HPI_CORE,
  FRAGMENT_ZIP_HPI_TIME_SERIES,
} from './fragments/Hpi.graphql';
import { FRAGMENT_LISTING_STATS } from './fragments/ListingStats.graphql';
import {
  FRAGMENT_POPULATION,
  FRAGMENT_POPULATION_TIME_SERIES,
  FRAGMENT_UNEMPLOYMENT_RATE_TIME_SERIES,
} from './fragments/PopulationStats.graphql';
import {
  FRAGMENT_MSA_RPI_CORE,
  FRAGMENT_RPI_TIME_SERIES,
  FRAGMENT_ZIP_RPI_CORE,
} from './fragments/Rpi.graphql';
import {
  FRAGMENT_ZIP_CORE,
  FRAGMENT_ZIP_GEOM,
  FRAGMENT_ZIP_MARKET_QUALITY,
} from './fragments/Zip.graphql';

export const QUERY_MSA_DETAILS_ALL_MSAS = gql`
  # HC APP: Market Insights
  ${FRAGMENT_MSA_CORE}
  ${FRAGMENT_LISTING_STATS}
  ${FRAGMENT_MSA_HPI_CORE}
  ${FRAGMENT_MSA_HCRI_CORE}
  query AllMsaDetails {
    allMsaDetails {
      ...MsaCore
      listingStats {
        latestStats {
          ...ListingStats
        }
      }
      rentalListingStats {
        latestStats {
          ...ListingStats
        }
      }
      hpi {
        ...MsaHpiCore
      }
      hcri {
        ...MsaHcriCore
      }
      rpi(propertyType: SINGLE_FAMILY_DETACHED) {
        ...MsaRpiCore
      }
      populationStats {
        population {
          ...PopulationCore
        }
      }
    }
  }
`;

export const QUERY_MSA_DETAILS = gql`
  # HC APP: Market Insights
  ${FRAGMENT_MSA_CORE}
  ${FRAGMENT_LISTING_STATS}
  ${FRAGMENT_MSA_HPI_CORE}
  ${FRAGMENT_MSA_HCRI_CORE}
  ${FRAGMENT_MSA_RPI_CORE}
  ${FRAGMENT_POPULATION}
  query MsaDetails($msaId: String) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      ...MsaCore
      listingStats {
        latestStats {
          ...ListingStats
        }
      }
      rentalListingStats {
        latestStats {
          ...ListingStats
        }
      }
      hpi {
        ...MsaHpiCore
      }
      hcri {
        ...MsaHcriCore
      }
      rpi(propertyType: SINGLE_FAMILY_DETACHED) {
        ...MsaRpiCore
      }
      populationStats {
        population {
          ...PopulationCore
        }
      }
    }
  }
`;

export const QUERY_MSA_LISTING_STATS_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_MSA_CORE}
  ${FRAGMENT_LISTING_STATS}
  query MsaListingStatsTimeSeries(
    $msaId: String
    $fromDate: Date
    $toDate: Date
  ) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      ...MsaCore
      listingStats {
        timeSeries(fromDate: $fromDate, toDate: $toDate, order: ASCENDING) {
          ...ListingStats
        }
      }
      rentalListingStats {
        timeSeries(fromDate: $fromDate, toDate: $toDate, order: ASCENDING) {
          ...ListingStats
        }
      }
    }
  }
`;

export const QUERY_MSA_HPI_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_MSA_CORE}
  ${FRAGMENT_MSA_HPI_TIME_SERIES}
  query MsaHpiTimeSeries($msaId: String, $fromDate: Date, $toDate: Date) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      ...MsaCore
      hpi {
        historicalTimeSeries: timeSeries(
          seriesType: HISTORICAL
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
        ) {
          ...MsaHpiTimeSeries
        }
        forecastTimeSeries: timeSeries(
          seriesType: FORECAST
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
        ) {
          ...MsaHpiTimeSeries
        }
      }
    }
  }
`;

export const QUERY_MSA_RPI_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_MSA_CORE}
  ${FRAGMENT_RPI_TIME_SERIES}
  query MsaRpiTimeSeries($msaId: String, $fromDate: Date, $toDate: Date) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      ...MsaCore
      rpi(propertyType: SINGLE_FAMILY_DETACHED) {
        historicalTimeSeries: timeSeries(
          seriesType: HISTORICAL
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
          propertyType: SINGLE_FAMILY_DETACHED
        ) {
          ...RpiTimeSeries
        }
        forecastTimeSeries: timeSeries(
          seriesType: FORECAST
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
          propertyType: SINGLE_FAMILY_DETACHED
        ) {
          ...RpiTimeSeries
        }
      }
    }
  }
`;

export const QUERY_ZIP_RPI_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_ZIP_CORE}
  ${FRAGMENT_RPI_TIME_SERIES}
  query ZipRpiTimeSeries($zipcode: String, $fromDate: Date, $toDate: Date) {
    lookupZipDetails(id: { zipcode: $zipcode }) {
      ...ZipCore
      rpi(propertyType: SINGLE_FAMILY_DETACHED) {
        historicalTimeSeries: timeSeries(
          seriesType: HISTORICAL
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
          propertyType: SINGLE_FAMILY_DETACHED
        ) {
          ...RpiTimeSeries
        }
        forecastTimeSeries: timeSeries(
          seriesType: FORECAST
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
          propertyType: SINGLE_FAMILY_DETACHED
        ) {
          ...RpiTimeSeries
        }
      }
    }
  }
`;

export const QUERY_MSA_UNEMPLOYMENT_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_MSA_CORE}
  ${FRAGMENT_UNEMPLOYMENT_RATE_TIME_SERIES}
  query MsaUnemploymentTimeSeries(
    $msaId: String
    $fromDate: Date
    $toDate: Date
  ) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      ...MsaCore
      populationStats {
        unemploymentRate {
          timeSeries(fromDate: $fromDate, toDate: $toDate, order: ASCENDING) {
            ...UnemploymentRateTimeSeries
          }
        }
      }
    }
  }
`;

export const QUERY_MSA_POPULATION_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_MSA_CORE}
  ${FRAGMENT_POPULATION_TIME_SERIES}
  query MsaPopulationTimeSeries(
    $msaId: String
    $fromDate: Date
    $toDate: Date
  ) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      ...MsaCore
      populationStats {
        population {
          timeSeries(fromDate: $fromDate, toDate: $toDate, order: ASCENDING) {
            ...PopulationTimeSeries
          }
        }
      }
    }
  }
`;

export const QUERY_MSA_ZIPS_LIST = gql`
  # HC APP: Lead Feed
  query MsaZips($msaId: String) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      zips {
        zipcode
      }
    }
  }
`;

export const QUERY_ZIP_GEOM = gql`
  # HC APP: Market Insights
  ${FRAGMENT_ZIP_CORE}
  ${FRAGMENT_ZIP_GEOM}
  query ZipGeom($zipcode: String) {
    lookupZipDetails(id: { zipcode: $zipcode }) {
      ...ZipCore
      ...ZipGeom
    }
  }
`;

export const QUERY_MSA_ALL_ZIP_DETAILS = gql`
  # HC APP: Market Insights
  ${FRAGMENT_ZIP_CORE}
  ${FRAGMENT_ZIP_HCRI_CORE}
  ${FRAGMENT_ZIP_HPI_CORE}
  ${FRAGMENT_ZIP_RPI_CORE}
  ${FRAGMENT_LISTING_STATS}
  ${FRAGMENT_ZIP_MARKET_QUALITY}
  query AllMsaZipDetails($msaId: String) {
    lookupMsaDetails(id: { msaId: $msaId }) {
      zips {
        ...ZipCore
        listingStats {
          latestStats {
            ...ListingStats
          }
        }
        hpi {
          ...ZipHpiCore
        }
        hcri {
          ...ZipHcriCore
        }
        rpi(propertyType: SINGLE_FAMILY_DETACHED) {
          ...ZipRpiCore
        }
        marketQuality {
          ...ZipMarketQuality
        }
      }
    }
  }
`;

export const QUERY_ZIP_DETAILS = gql`
  # HC APP: Market Insights
  ${FRAGMENT_ZIP_CORE}
  ${FRAGMENT_ZIP_HCRI_CORE}
  ${FRAGMENT_ZIP_HPI_CORE}
  ${FRAGMENT_ZIP_RPI_CORE}
  ${FRAGMENT_LISTING_STATS}
  ${FRAGMENT_ZIP_MARKET_QUALITY}
  query ZipDetails($zipcode: String) {
    lookupZipDetails(id: { zipcode: $zipcode }) {
      ...ZipCore
      listingStats {
        latestStats {
          ...ListingStats
        }
      }
      hpi {
        ...ZipHpiCore
      }
      hcri {
        ...ZipHcriCore
      }
      rpi(propertyType: SINGLE_FAMILY_DETACHED) {
        ...ZipRpiCore
      }
      marketQuality {
        ...ZipMarketQuality
      }
    }
  }
`;

export const QUERY_ZIP_LISTING_STATS_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_LISTING_STATS}
  query ZipListingStatsTimeSeries(
    $zipcode: String
    $fromDate: Date
    $toDate: Date
  ) {
    lookupZipDetails(id: { zipcode: $zipcode }) {
      listingStats {
        timeSeries(fromDate: $fromDate, toDate: $toDate, order: ASCENDING) {
          ...ListingStats
        }
      }
    }
  }
`;

export const QUERY_ZIP_HPI_TIME_SERIES = gql`
  # HC APP: Market Insights
  ${FRAGMENT_ZIP_HPI_TIME_SERIES}
  query ZipHpiTimeSeries($zipcode: String, $fromDate: Date, $toDate: Date) {
    lookupZipDetails(id: { zipcode: $zipcode }) {
      hpi {
        historicalTimeSeries: timeSeries(
          seriesType: HISTORICAL
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
        ) {
          ...ZipHpiTimeSeries
        }
        forecastTimeSeries: timeSeries(
          seriesType: FORECAST
          fromDate: $fromDate
          toDate: $toDate
          order: ASCENDING
        ) {
          ...ZipHpiTimeSeries
        }
      }
    }
  }
`;
