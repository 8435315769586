import React from 'react';

import { MultiSelect, Option } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { SimilarityLevel } from '@hcs/property-state';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { CompFields, CompTypes } from '@hcs/types';
import {
  CompsFiltersPaths,
  ReportId,
  SimilarityLevel as SimilarityLevelType,
} from '@hcs/types';

import { FilterCard } from '../../components-deprecated';
import { useCompsFiltersDocument } from '../../hooks/useCompsFiltersDocument';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';

import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}

const FIELD = CompFields.similarity;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const fieldConfig = COMP_FIELDS_CONFIG[FIELD];
const dataHcName = 'comp-filter-similarity';

const OPTIONS: Option<SimilarityLevelType>[] = [
  {
    value: SimilarityLevelType.High,
    label: (
      <>
        <SimilarityLevel
          dataHcName={`${dataHcName}-option-high`}
          similarityLevel={SimilarityLevelType.High}
        />{' '}
        High (85 - 100)
      </>
    ),
  },
  {
    value: SimilarityLevelType.Moderate,
    label: (
      <>
        <SimilarityLevel
          dataHcName={`${dataHcName}-option-moderate`}
          similarityLevel={SimilarityLevelType.Moderate}
        />{' '}
        Moderate (75 - 84)
      </>
    ),
  },
  {
    value: SimilarityLevelType.Low,
    label: (
      <>
        <SimilarityLevel
          dataHcName={`${dataHcName}-option-low`}
          similarityLevel={SimilarityLevelType.Low}
        />{' '}
        Low (0 - 74)
      </>
    ),
  },
];
export const CompFilterSimilarity = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!filterDocument || !subjectDocument || !reportPermissions) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const filterValue = filterDocument.data.filters?.[FIELD];
  return (
    <FilterCard
      label={fieldConfig.label}
      info="HouseCanary's Similarity Score uses Data Points including bed, bath, GLA, and other property details along with distance and time to create a regression model for the subject property and nearby comparable properties. The location based model provides a numerical score for each possible comparable, ranked from 1-100 in similarity."
      className={className}
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={FIELD}
        />
      }
    >
      <MultiSelect
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        disabled={!reportPermissions.isEditable}
        options={OPTIONS}
        onChange={(value: SimilarityLevelType[]) => {
          if (value.length === 0) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    </FilterCard>
  );
};
