import React from 'react';

import { TextBadge } from '@hcs/design-system';
import { CalendarScheduleSearchIcon } from '@hcs/design-system';
import { ReportId, ReportTypes } from '@hcs/types';
import { isDateToday } from '@hcs/utils';
import { formatDateShort } from '@hcs/utils';

import { useReport } from '../../hooks';

interface Props {
  reportId: ReportId;
  className?: string;
}

const dataHcName = 'effective-date-tag';

export const EffectiveDateBadge = ({ className, reportId }: Props) => {
  const { isInitialLoading, data: report } = useReport(reportId);
  const effectiveDate = report?.effectiveDate;
  const isEffectiveDateReport =
    report?.reportType === ReportTypes.EffectiveDate;

  if (!isEffectiveDateReport || !report || isInitialLoading) return null;

  return (
    <TextBadge
      className={className}
      dataHcName={dataHcName}
      label="Effective Date"
      icon={<CalendarScheduleSearchIcon size="sm" />}
      value={
        isDateToday(effectiveDate) ? 'Today' : formatDateShort(effectiveDate)
      }
    />
  );
};
