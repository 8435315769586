import React from 'react';

import { InfoTooltip, TableHeader, TableHeaderCell } from '@hcs/design-system';
import { TransactionHistoryActionsCallback } from '@hcs/types';

import styles from './PropertyTransactionHistoryTable.module.css';

interface PropertyTransactionHistoryTableProps {
  dataHcName?: string;
  actions?: TransactionHistoryActionsCallback;
}

export const PropertyTransactionHistoryTableHeader = ({
  dataHcName: dataHcNameProp,
  actions,
}: PropertyTransactionHistoryTableProps) => {
  const dataHcName = dataHcNameProp || 'property-transaction-history-table';
  return (
    <TableHeader>
      <TableHeaderCell dataHcName={`${dataHcName}-header-date`}>
        Date
      </TableHeaderCell>
      <TableHeaderCell dataHcName={`${dataHcName}-header-event`}>
        Event
      </TableHeaderCell>
      <TableHeaderCell dataHcName={`${dataHcName}-header-type`}>
        Type
      </TableHeaderCell>
      <TableHeaderCell dataHcName={`${dataHcName}-header-price`} align={'left'}>
        Price
      </TableHeaderCell>
      <TableHeaderCell
        dataHcName={`${dataHcName}-header-appreciation`}
        align={'left'}
      >
        Appreciation
      </TableHeaderCell>
      <TableHeaderCell dataHcName={`${dataHcName}-header-dom`} align={'left'}>
        <div className={styles.AdomTableHeaderCellContent}>
          <div>ADOM</div>
          <InfoTooltip
            dataHcName={`${dataHcName}-dom-tooltip`}
            isLargeTooltip
            label={'Number of active days on market without an accepted offer.'}
            description={
              'This metric is calculated by HouseCanary and measures the number of days between the listing date and when an offer has been accepted by the seller.'
            }
            size="medium"
          />
        </div>
      </TableHeaderCell>
      <TableHeaderCell dataHcName={`${dataHcName}-header-source`} width={190}>
        Source
      </TableHeaderCell>
      {actions && <TableHeaderCell>Actions</TableHeaderCell>}
    </TableHeader>
  );
};

PropertyTransactionHistoryTableHeader.defaultProps = {
  isTableHeader: true,
};
