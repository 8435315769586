import React from 'react';
import classNames from 'classnames';

import { UserAlert } from '@hcs/design-system';

import styles from './OfferSuccess.module.css';

interface OfferSuccessProps {
  dataHcName: string;
  className?: string;
}

export const OfferSuccess = ({ dataHcName, className }: OfferSuccessProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(className, styles.OfferSuccessContainer)}
    >
      <div className={styles.UserAlertContainer}>
        <UserAlert
          dataHcName={`${dataHcName}-user-alert-success`}
          type="success"
          text=""
        />
      </div>
      <p
        className={styles.SuccessText}
        data-hc-name={`${dataHcName}-success-text`}
      >
        Offer information received. HouseCanary will be in touch soon.
      </p>
      <p
        data-hc-name={`${dataHcName}-disclaimer-text`}
        className={styles.DisclaimerText}
      >
        This submission does not constitute a formal offer nor does it create an
        agency relationship with ComeHome and/or HouseCanary, as applicable.
      </p>
    </div>
  );
};
