import { MarketGrade } from '../cerberus';
import { CerberusStatsFields } from '../cerberus-stats';
import { RangeValue } from '../Range.types';
import { SORT_ORDER } from '../Sort.types';

type RangeValueOrNull = RangeValue | null;

export type CerberusStatsNonFilterField = Extract<
  CerberusStatsFields,
  | CerberusStatsFields.displayName
  | CerberusStatsFields.hpiLatestMonth
  | CerberusStatsFields.listingStatsEventDate
  | CerberusStatsFields.listingStatsEventDateRental
  | CerberusStatsFields.populationLatestMonth
  | CerberusStatsFields.rpiLatestMonth
>;

export type CerberusStatsFilterField = Exclude<
  CerberusStatsFields,
  CerberusStatsNonFilterField
>;

export type CerberusStatsRangeFilterField = Exclude<
  CerberusStatsFilterField,
  CerberusStatsFields.marketGrade
>;

export type CerberusStatsFieldFilters = Partial<{
  [Key in CerberusStatsRangeFilterField]: RangeValueOrNull;
}> &
  Partial<{
    [CerberusStatsFields.marketGrade]: MarketGrade[] | null;
  }>;

export type CerberusStatsSortField = CerberusStatsFields | 'interested';

export interface CerberusStatsFilterSet {
  search?: CerberusStatsFields | null;
  sortField?: CerberusStatsFields | null;
  sortOrder?: SORT_ORDER | null;
  fieldFilters?: CerberusStatsFieldFilters | null;
  interestedOnly?: boolean | null;
}

export enum CerberusStatsFilterType {
  msa = 'msa',
  zip = 'zip'
}

export interface CerberusStatsFilters {
  [CerberusStatsFilterType.msa]?: CerberusStatsFilterSet;
  [CerberusStatsFilterType.zip]?: CerberusStatsFilterSet;
}

// unfortunately, dots in field names have special meaning in react-hook-form and cause problems
// this is effectively CerberusStatsFilterField but with underscores instead of dots
export type CerberusStatsFiltersRangeFormField =
  | 'listingStats_daysOnMarketMedian'
  | 'rentalListingStats_daysOnMarketMedian'
  | 'listingStats_daysOnMarketMedianMovingAvg'
  | 'rentalListingStats_daysOnMarketMedianMovingAvg'
  | 'listingStats_daysOnMarketNotContractMedian'
  | 'rentalListingStats_daysOnMarketNotContractMedian'
  | 'listingStats_daysOnMarketNotContractMedianMovingAvg'
  | 'rentalListingStats_daysOnMarketNotContractMedianMovingAvg'
  | 'listingStats_daysToCloseMedian'
  | 'rentalListingStats_daysToCloseMedian'
  | 'listingStats_daysToCloseMedianMovingAvg'
  | 'rentalListingStats_daysToCloseMedianMovingAvg'
  | 'listingStats_daysToContractMedian'
  | 'rentalListingStats_daysToContractMedian'
  | 'listingStats_daysToContractMedianMovingAvg'
  | 'rentalListingStats_daysToContractMedianMovingAvg'
  | 'listingStats_daysToFirstPriceDropMedian'
  | 'rentalListingStats_daysToFirstPriceDropMedian'
  | 'listingStats_daysToFirstPriceDropMedianMovingAvg'
  | 'rentalListingStats_daysToFirstPriceDropMedianMovingAvg'
  | 'hcri_grossYieldMedian'
  | 'listingStats_listingsClosedCount'
  | 'rentalListingStats_listingsClosedCountRental'
  | 'listingStats_listingsClosedCountMovingAvg'
  | 'rentalListingStats_listingsClosedCountMovingAvg'
  | 'listingStats_listingsNewCount'
  | 'rentalListingStats_listingsNewCount'
  | 'listingStats_listingsNewCountMovingAvg'
  | 'rentalListingStats_listingsNewCountMovingAvg'
  | 'listingStats_listingsOnMarketCount'
  | 'rentalListingStats_listingsOnMarketCount'
  | 'listingStats_listingsOnMarketCountMovingAvg'
  | 'rentalListingStats_listingsOnMarketCountMovingAvg'
  | 'listingStats_listingsPriceChangeCount'
  | 'rentalListingStats_listingsPriceChangeCount'
  | 'listingStats_listingsPriceChangeCountMovingAvg'
  | 'rentalListingStats_listingsPriceChangeCountMovingAvg'
  | 'listingStats_listingsPriceDropCount'
  | 'rentalListingStats_listingsPriceDropCount'
  | 'listingStats_listingsPriceDropCountMovingAvg'
  | 'rentalListingStats_listingsPriceDropCountMovingAvg'
  | 'listingStats_listingsPriceDropPercent'
  | 'rentalListingStats_listingsPriceDropPercent'
  | 'listingStats_listingsPriceDropPercentMovingAvg'
  | 'rentalListingStats_listingsPriceDropPercentMovingAvg'
  | 'listingStats_listingsRemovedCount'
  | 'rentalListingStats_listingsRemovedCount'
  | 'listingStats_listingsRemovedCountMovingAvg'
  | 'rentalListingStats_listingsRemovedCountMovingAvg'
  | 'listingStats_listingsRemovedPercent'
  | 'rentalListingStats_listingsRemovedPercent'
  | 'listingStats_listingsRemovedPercentMovingAvg'
  | 'rentalListingStats_listingsRemovedPercentMovingAvg'
  | 'listingStats_listingsUnderContractCount'
  | 'rentalListingStats_listingsUnderContractCount'
  | 'listingStats_listingsUnderContractCountMovingAvg'
  | 'rentalListingStats_listingsUnderContractCountMovingAvg'
  | 'listingStats_listingsUnderContractPercent'
  | 'rentalListingStats_listingsUnderContractPercent'
  | 'listingStats_listingsUnderContractPercentMovingAvg'
  | 'rentalListingStats_listingsUnderContractPercentMovingAvg'
  | 'listingStats_monthsOfSupplyMedian'
  | 'rentalListingStats_monthsOfSupplyMedian'
  | 'listingStats_monthsOfSupplyMedianMovingAvg'
  | 'rentalListingStats_monthsOfSupplyMedianMovingAvg'
  | 'calculatedFields_netPopulationGrowth'
  | 'listingStats_priceClosedMedian'
  | 'rentalListingStats_priceClosedMedian'
  | 'listingStats_priceClosedMedianMovingAvg'
  | 'rentalListingStats_priceClosedMedianMovingAvg'
  | 'listingStats_priceNewListMedian'
  | 'rentalListingStats_priceNewListMedian'
  | 'listingStats_priceNewListMedianMovingAvg'
  | 'rentalListingStats_priceNewListMedianMovingAvg'
  | 'listingStats_priceOnMarketMedian'
  | 'rentalListingStats_priceOnMarketMedian'
  | 'listingStats_priceOnMarketMedianMovingAvg'
  | 'rentalListingStats_priceOnMarketMedianMovingAvg'
  | 'listingStats_pricePerSqftClosedMedian'
  | 'rentalListingStats_pricePerSqftClosedMedian'
  | 'listingStats_pricePerSqftClosedMedianMovingAvg'
  | 'rentalListingStats_pricePerSqftClosedMedianMovingAvg'
  | 'listingStats_pricePerSqftNewListMedian'
  | 'rentalListingStats_pricePerSqftNewListMedian'
  | 'listingStats_pricePerSqftNewListMedianMovingAvg'
  | 'rentalListingStats_pricePerSqftNewListMedianMovingAvg'
  | 'listingStats_pricePerSqftOnMarketMedian'
  | 'rentalListingStats_pricePerSqftOnMarketMedian'
  | 'listingStats_pricePerSqftOnMarketMedianMovingAvg'
  | 'rentalListingStats_pricePerSqftOnMarketMedianMovingAvg'
  | 'hpi_return1YearForecast'
  | 'hpi_risk1YearLoss'
  | 'rpi_return1Year'
  | 'listingStats_saleToListPriceMedian'
  | 'rentalListingStats_saleToListPriceMedian'
  | 'listingStats_saleToListPriceMedianMovingAvg'
  | 'rentalListingStats_saleToListPriceMedianMovingAvg'
  | 'listingStats_saleToListPriceOriginalMedian'
  | 'rentalListingStats_saleToListPriceOriginalMedian'
  | 'listingStats_saleToListPriceOriginalMedianMovingAvg'
  | 'rentalListingStats_saleToListPriceOriginalMedianMovingAvg';

export type CerberusStatsFiltersMarketGradeFormField = 'marketQuality_grade';

export type CerberusStatsFiltersFormMinFieldNames =
  `${CerberusStatsFiltersRangeFormField}Min`;
export type CerberusStatsFiltersFormMaxFieldNames =
  `${CerberusStatsFiltersRangeFormField}Max`;

export type CerberusStatsFiltersRangeFormFieldNames =
  | CerberusStatsFiltersFormMinFieldNames
  | CerberusStatsFiltersFormMaxFieldNames;

export type CerberusStatsFiltersFormData = Partial<
  Record<CerberusStatsFiltersRangeFormFieldNames, number | null> &
    Record<CerberusStatsFiltersMarketGradeFormField, MarketGrade[] | null>
>;
