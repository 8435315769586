import React from 'react';
import classNames from 'classnames';

import { Appreciation as AppreciationType } from '@hcs/types';
import { formatMoney, formatPercent, NULL_VALUE } from '@hcs/utils';

import styles from './Appreciation.module.css';

export interface AppreciationProps {
  dataHcName: string;
  className?: string;
  appreciation?: AppreciationType | null;
}
export const Appreciation = ({
  dataHcName,
  className,
  appreciation,
}: AppreciationProps) => {
  return (
    <div data-hc-name={dataHcName} className={className}>
      {!appreciation ? (
        NULL_VALUE
      ) : (
        <>
          <span
            data-hc-name={`${dataHcName}-rate`}
            className={classNames(styles.Rate, {
              [styles.positive]: appreciation.rate > 0,
              [styles.negative]: appreciation.rate < 0,
            })}
          >
            {formatPercent(appreciation.rate / 100)}
          </span>
          (
          <span data-hc-name={`${dataHcName}-absolute-value`}>
            {formatMoney(appreciation.absoluteValue)}
          </span>
          )
        </>
      )}
    </div>
  );
};
