import React, { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { useSystemRolesByType } from '@hcs/auth';
import { Toggle, ToggleOption } from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import { useHcProductsAppConfigs } from '@hcs/hc-products';
import { AppSlugs, Roles } from '@hcs/types';
import { AdminFeatureOrgProps } from '@hcs/types';
import {
  RoleAssignmentUpdate,
  UserApplicationAccess,
  UserApplicationPermissionsForm,
} from '@hcs/types';

import { useOrganizationLegacy } from '../../hooks/useOrganizationLegacy';

import styles from './UserApplicationPermissionsLegacy.module.css';

const ACCESS_TOGGLE_OPTIONS: ToggleOption<'allow' | 'deny'>[] = [
  {
    label: 'Off',
    value: 'deny',
  },
  {
    label: 'On',
    value: 'allow',
  },
];

export const UserApplicationPermissionsFieldsLegacy = ({
  userApplicationAccess,
  currentOmRoles,
  dataHcName,
  ...adminFeatureOrgProps
}: AdminFeatureOrgProps & {
  dataHcName: string;
  userApplicationAccess?: UserApplicationAccess;
  currentOmRoles?: RoleAssignmentUpdate[];
}) => {
  const { data: rolesByType } = useSystemRolesByType();
  const { data: org } = useOrganizationLegacy(adminFeatureOrgProps);
  const form = useFormContext<UserApplicationPermissionsForm>();
  const productAppConfigs = useHcProductsAppConfigs();
  return org?.applications.map((application, idx) => {
    const appConfig = productAppConfigs[application.name];
    // Only show permissions for core products
    if (!appConfig) {
      return null;
    }
    return (
      <Fragment key={application.name}>
        <Controller
          name={application.name}
          control={form.control}
          defaultValue={userApplicationAccess?.[application.name]}
          render={({ field: { onChange, value } }) => {
            return (
              <DialogInputLayout
                required={false}
                label={appConfig?.name || `f:${application.displayName}`}
                dataHcName={`${dataHcName}-${application.name}-edit-user-permissions-field`}
                className={
                  idx === org.applications.length - 1
                    ? styles.LastFormRow
                    : styles.FormRow
                }
                theme={{
                  InputContainer: styles.InputContainer,
                  InputLabelContainer: styles.InputLabelContainer,
                }}
              >
                <Toggle<'allow' | 'deny'>
                  primary
                  dataHcName={`${dataHcName}-${application.name}-edit-user-permissions-access-toggle`}
                  value={value?.state || 'allow'}
                  options={ACCESS_TOGGLE_OPTIONS}
                  onChange={(newState) => {
                    onChange({
                      appSlug: application.name,
                      applicationId: application.id,
                      state: newState,
                    });
                    // Remove OM Roles if OM access is removed
                    if (
                      application.name === AppSlugs.AgileSuite &&
                      newState === 'deny'
                    ) {
                      form.setValue('orderManagerRoles', []);
                    }
                  }}
                />
              </DialogInputLayout>
            );
          }}
        />
        {application.name === AppSlugs.AgileSuite && (
          <Controller
            name={'orderManagerRoles'}
            control={form.control}
            defaultValue={currentOmRoles}
            render={({ field: { onChange, value = [] } }) => {
              return (
                <>
                  <DialogInputLayout
                    required={false}
                    label="Create Orders"
                    dataHcName={`${dataHcName}-Create Orders-field`}
                    theme={{
                      InputContainer: styles.InputContainer,
                      InputLabelContainer: styles.InputLabelContainer,
                    }}
                    className={classNames(styles.FormRow, styles.SubToggles)}
                  >
                    <Toggle<'allow' | 'deny'>
                      primary
                      dataHcName={`${dataHcName}-Create Orders-toggle`}
                      value={
                        value.find(
                          (r) => r.roleId === rolesByType?.['OM Create'].id
                        )
                          ? 'allow'
                          : 'deny'
                      }
                      options={ACCESS_TOGGLE_OPTIONS}
                      onChange={(newVal) => {
                        onChange(
                          newVal === 'allow'
                            ? [
                                ...value,
                                {
                                  roleId: rolesByType?.['OM Create'].id,
                                },
                              ]
                            : value.filter(
                                (r) =>
                                  r.roleId !== rolesByType?.['OM Create'].id
                              )
                        );
                      }}
                    />
                  </DialogInputLayout>
                  <DialogInputLayout
                    required={false}
                    label="View All Orders"
                    dataHcName={`${dataHcName}-View All Orders-field`}
                    theme={{
                      InputContainer: styles.InputContainer,
                      InputLabelContainer: styles.InputLabelContainer,
                    }}
                    className={classNames(styles.FormRow, styles.SubToggles)}
                  >
                    <Toggle<'allow' | 'deny'>
                      primary
                      dataHcName={`${dataHcName}-View All Orders-toggle`}
                      value={
                        value.find(
                          (r) => r.roleId === rolesByType?.[Roles.OmView].id
                        )
                          ? 'allow'
                          : 'deny'
                      }
                      options={ACCESS_TOGGLE_OPTIONS}
                      onChange={(newVal) => {
                        onChange(
                          newVal === 'allow'
                            ? [
                                ...value,
                                {
                                  roleId: rolesByType?.[Roles.OmView].id,
                                },
                              ]
                            : value.filter(
                                (r) =>
                                  r.roleId !== rolesByType?.[Roles.OmView].id
                              )
                        );
                      }}
                    />
                  </DialogInputLayout>
                </>
              );
            }}
          />
        )}
      </Fragment>
    );
  });
};
