import React from 'react';

import {
  useAccount,
  useAuthDevToolsSlice,
  useBundleAppConfigs,
} from '@hcs/auth';

import { HcsDevToolsPortal } from '../';

const dataHcName = 'app-access-dev-tool';

export const AppAccessDevTool = () => {
  const {
    state: { disabledApps },
    actions: { enableAppAccess, disableAppAccess },
  } = useAuthDevToolsSlice();
  const { data: account } = useAccount();
  const appConfigs = useBundleAppConfigs();
  return (
    <HcsDevToolsPortal
      groups={[
        {
          title: 'App Access',
          items:
            account?.applications.map((appSlug) => {
              return {
                type: 'switch',
                item: {
                  content:
                    appConfigs?.find(
                      (appConfig) => appConfig.appSlug === appSlug
                    )?.name || appSlug,
                },
                switchProps: {
                  dataHcName: `${dataHcName}-${appSlug}-switch`,
                  switchOffOption: { value: 'off' },
                  switchOnOption: { value: 'on' },
                  value: disabledApps[appSlug] ? 'off' : 'on',
                  onChange: (v: 'off' | 'on') => {
                    if (v === 'off') {
                      disableAppAccess({ appSlug });
                    } else {
                      enableAppAccess({ appSlug });
                    }
                  },
                },
              };
            }) || [],
        },
      ]}
    />
  );
};
