import { useQuery } from '@tanstack/react-query';

import { useIsLoggedIn } from '@hcs/auth';

import { HuellApi } from '../api';

export const QUERY_KEY_REPORT_PREFERENCES_ORG = 'reportPreferences-org';
export const useReportPreferencesForOrg = () => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  return useQuery(
    [QUERY_KEY_REPORT_PREFERENCES_ORG],
    HuellApi.fetchReportPreferencesForOrg,
    {
      enabled: isLoggedIn,
    }
  );
};
