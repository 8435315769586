import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CerberusStatsFieldConfig,
  CerberusStatsFields,
  CerberusStatsProps,
  CerberusStatsTableCellProps,
  CerberusStatsTableHeaderCellProps,
  CerberusStatsType,
} from '@hcs/types';
import { formatPercentD3, getD3PercentFormat } from '@hcs/utils';

import { logValueUnsupportedWarning } from '../../utils/cerberusStatsConfig.utils';

const D3_FORMAT_CONFIG = { precision: 1 };
const D3_FORMAT_STR = getD3PercentFormat(D3_FORMAT_CONFIG);

const getRiskLabel = (risk: number | null | undefined): string | null => {
  if (risk == null) {
    return null;
  }
  if (risk > 0.7) {
    return 'Very High';
  }
  if (risk > 0.5) {
    return 'High';
  }
  if (risk > 0.3) {
    return 'Neutral';
  }
  if (risk > 0.1) {
    return 'Low';
  }
  return 'Very Low';
};

const FIELD = CerberusStatsFields.risk1YearLoss;
type FieldConfig = CerberusStatsFieldConfig<typeof FIELD>;
const label = '1-Year Risk of Decline';
const labelShort = '1-Year Risk of Decline';
const getValue: FieldConfig['getValue'] = (cerberusStats) => {
  if (!cerberusStats) {
    return undefined;
  }
  if (
    cerberusStats.type !== CerberusStatsType.ListingStats &&
    cerberusStats.type !== CerberusStatsType.RentalListingStats
  ) {
    return cerberusStats?.hpi?.risk1YearLoss;
  } else {
    logValueUnsupportedWarning(FIELD, cerberusStats);
    return undefined;
  }
};
const formatValue: FieldConfig['formatValue'] = (cerberusStats) => {
  const risk1YearLoss = getValue(cerberusStats);
  const riskLabel = getRiskLabel(risk1YearLoss);
  return `${formatPercentD3(risk1YearLoss, D3_FORMAT_CONFIG)}${
    riskLabel !== null ? ` (${riskLabel})` : ''
  }`;
};

// Component for displaying a field from a schema
const Display = ({ cerberusStats }: CerberusStatsProps) => {
  return <>{formatValue(cerberusStats)}</>;
};

const HeaderCell = (props: CerberusStatsTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{label}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  cerberusStats,
  ...tableCellProps
}: CerberusStatsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display cerberusStats={cerberusStats} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const definition =
  "Probability that this market's hpi will be lower 12 months from now than the current hpi.";

export const RISK_1_YEAR_LOSS_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  getValue,
  formatValue,
  HeaderCell,
  ContentCell,
  Display,
  definition,
  d3FormatStr: D3_FORMAT_STR,
  filterConfig: {
    isPositive: true,
    isPercent: true,
    decimalPlaces: 1,
  },
};
