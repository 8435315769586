import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps } from '@hcs/types';
import { TableHeaderCellProps } from '@hcs/types';
import {
  PortfolioAsset,
  PortfolioAssetContentCellProps,
  PortfolioAssetFieldConfig,
} from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL } from '../../constants/PortfolioTable.constants';

const Display = ({ portfolioAsset }: PortfolioAssetContentCellProps) => {
  return (
    <div
      data-hc-name={`${DATA_HC_NAME_PORTFOLIO_ASSETS_TABLE_CELL}-loan-contract-date`}
    >
      <div>{formatDateShort(portfolioAsset.data?.loanContractDate)}</div>
    </div>
  );
};

const ContentCell = ({
  portfolioAsset,
  ...tableCellProps
}: {
  portfolioAsset: PortfolioAsset;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display portfolioAsset={portfolioAsset} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return (
    <TableHeaderCell {...tableHeaderCellProps}>
      Origination Date
    </TableHeaderCell>
  );
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export const PORTFOLIO_ASSET_LOAN_CONTRACT_DATE_CONFIG: PortfolioAssetFieldConfig =
  {
    ContentCell,
    HeaderCell,
    label: 'Origination Date',
  };
