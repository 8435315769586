import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CompFilterBarSliceState, ReportApiPartialAppState } from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { REPORT_API_REDUCER_KEY } from '../../../rtk';
import { compFilterBarSlice } from '../rtk/compFilterBar.slice';

export const useCompFilterBarSlice = () => {
  const dispatch = useDispatch();
  const state = useSelector<ReportApiPartialAppState, CompFilterBarSliceState>(
    (state) => state[REPORT_API_REDUCER_KEY][compFilterBarSlice.name]
  );
  const { overflowCount, overflowVisible } = state;
  const actions = mapDispatchToSliceActions(dispatch, compFilterBarSlice);
  // Close the overflow element if all filters are visible
  useEffect(() => {
    if (!overflowCount && overflowVisible) {
      actions.overflowVisibilityChange({ visible: false });
    }
  }, [overflowCount]);

  return {
    state,
    actions,
  };
};
