import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { DexpEvent, DexpJob } from '@hcs/types';

import { DataExplorerApi } from '../api/dataExplorer.api';

import { useSubscribeToDexpJobEvents } from './useSubscribeToDexpJobEvents';

interface Options {
  onError?: () => void;
}

const DEXP_JOB_QUERY_KEY = 'dexp-job';
export const useDexpJob = (jobId: number | null, options?: Options) => {
  const queryKey = useMemo(() => [DEXP_JOB_QUERY_KEY, jobId], [jobId]);
  const queryClient = useQueryClient();
  const dexpJobQuery = useQuery(
    queryKey,
    async () => {
      if (jobId) {
        return await DataExplorerApi.fetchDexpJob(jobId);
      } else {
        // Should never get here because of enabled option below
        throw new Error('[Fetch Dexp Job] No job to fetch');
      }
    },
    {
      enabled: !!jobId,
      ...options,
    }
  );
  const dexpEventCallback = useCallback(
    (dexpEvent: DexpEvent) => {
      if (dexpJobQuery.isSuccess && dexpJobQuery.data) {
        if (dexpJobQuery.data.id === dexpEvent.job.id) {
          queryClient.setQueryData<DexpJob>(queryKey, dexpEvent.job);
        }
      }
    },
    [dexpJobQuery.isSuccess, dexpJobQuery.data, queryClient, queryKey]
  );
  useSubscribeToDexpJobEvents(dexpEventCallback, jobId ? { jobId } : {});
  return dexpJobQuery;
};
