import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OmOrder, OrdersParams } from '@hcs/types';
import { PaginatedResponseData } from '@hcs/types';

import { OrderManagerApi } from '../api/orderManagerApi';

export const QUERY_KEY_ORDERS = 'orders';
export type OrdersQueryKey = [
  typeof QUERY_KEY_ORDERS,
  OrdersParams | undefined
];
export const useOrders = (
  params?: OrdersParams,
  options?: UseQueryOptions<
    PaginatedResponseData<OmOrder[]>,
    AxiosError,
    PaginatedResponseData<OmOrder[]>,
    OrdersQueryKey
  >
) => {
  return useQuery(
    [QUERY_KEY_ORDERS, params],
    ({ signal }) => {
      return OrderManagerApi.fetchOrders(params, signal);
    },
    options
  );
};
