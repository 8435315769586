import React from 'react';

import { Dropdown, DropdownOptionType } from '@hcs/design-system';
import { OrderSets } from '@hcs/types';

import { useOrdersTable } from '../../../hooks/useOrdersTable';

import styles from '../OrdersTable.module.css';

interface Props {
  className?: string;
}

const dataHcName = 'orders-filter-status';
interface OrderSetChoice {
  label: string;
  value: OrderSets | null;
}
const ORDER_SET_CHOICES: DropdownOptionType<OrderSetChoice['value']>[] = [
  {
    label: 'All Orders',
    value: OrderSets.all,
  },
  {
    label: 'In Progress',
    value: OrderSets.active,
  },
  {
    label: 'Complete',
    value: OrderSets.completed,
  },
  {
    label: 'Cancelled',
    value: OrderSets.cancelled,
  },
];

export const OrdersTableFilterStatus = ({ className }: Props) => {
  const {
    state: {
      filters: { status },
    },
    actions: { ordersTableChangeFilter },
  } = useOrdersTable();

  const handleSelect = (selectedOrderSetChoiceValue: OrderSets | null) => {
    selectedOrderSetChoiceValue &&
      ordersTableChangeFilter({
        status: selectedOrderSetChoiceValue,
      });
  };

  return (
    <div className={styles.Filter}>
      <label>Order Status:</label>
      <Dropdown
        value={status || ORDER_SET_CHOICES[0]?.value}
        onSelect={handleSelect}
        options={ORDER_SET_CHOICES}
        dataHcName={`${dataHcName}-dropdown`}
      />
    </div>
  );
};
