import React, { useMemo, useState } from 'react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { ActionButtons, Button, FormError } from '@hcs/design-system';
import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import {
  MeaningfulEventTypes,
  Package,
  PaymentMethod,
  SetupIntentKeys,
} from '@hcs/types';
import { logException } from '@hcs/utils';

import { STRIPE_API_KEY } from '../../constants/selfService.constants';
import { useStripeConfirmCardSetup } from '../../hooks/useStripeConfirmCardSetup';
import { SelfServiceReportPrice } from '../SelfServiceReportPrice';

import styles from './StripeCreditCard.module.css';

const CARD_TYPE_FORMATTERS = {
  visa: (last4: string) => `**** **** **** ${last4}`,
  amex: (last4: string) => `**** ****** *${last4}`,
  diners: (last4: string) => `Your card ending in ${last4} will be charged`,
  discover: (last4: string) => `**** **** **** ${last4}`,
  jcb: (last4: string) => `Your card ending in ${last4} will be charged`,
  mastercard: (last4: string) => `**** **** **** ${last4}`,
  unionpay: (last4: string) => `Your card ending in ${last4} will be charged`,
  unknown: (last4: string) => `Your card ending in ${last4} will be charged`,
};
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Avenir,Helvetica,sans-serif',
      fontSize: '18px',
    },
    invalid: {
      color: '#FF8253',
    },
  },
};

const StripeWrapper = ({ children }: { children: React.ReactNode }) => {
  const stripePromise = useMemo(() => {
    return loadStripe(STRIPE_API_KEY);
  }, []);
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export interface StripeSetupIntentProps {
  clientSecret: SetupIntentKeys['clientSecret'];
  paymentErrorMessage?: string;
  onSuccess: VoidFunction;
  onCancel?: VoidFunction;
  dataHcName?: string;
}
const EVENT_NAME_CC_INFO = 'self-serve-save-credit-card-info';
const StripeSetupIntentInternal = ({
  clientSecret,
  paymentErrorMessage,
  onSuccess,
  onCancel,
  dataHcName = 'add-credit-card',
}: StripeSetupIntentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [stripeLoading, setStripeLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(
    paymentErrorMessage || null
  );

  const trackEngagementEventMutation = useTrackEngagementEvent();
  const { mutate: confirmCardSetup } = useStripeConfirmCardSetup({
    onSuccess: (result) => {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        trackEngagementEventMutation.mutate({
          eventName: `${EVENT_NAME_CC_INFO}-error`,
          eventData: {
            self_service_credit_card_save_error: result.error.message,
          },
        });
        setErrorMessage(result.error.message || null);
        setStripeLoading(false);
      } else {
        const status = result.setupIntent?.status;
        if (status === 'succeeded') {
          trackEngagementEventMutation.mutate({
            eventName: `${EVENT_NAME_CC_INFO}-success`,
          });
          onSuccess();
          setStripeLoading(false);
        }
      }
    },
  });

  // Callback to submit the credit card
  const handleSubmit = async (event: React.SyntheticEvent) => {
    setErrorMessage(null);
    event.preventDefault();

    if (!stripe || !elements) {
      logException(
        new Error('StripeSetupIntent: handleSubmit: stripe or elements missing')
      );
      return;
    }
    setStripeLoading(true);
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      confirmCardSetup({
        stripe,
        cardElement,
        clientSecret,
      });
    } else {
      logException(
        new Error('StripeSetupIntent: handleSubmit: cardElement missing')
      );
    }
  };

  return (
    <>
      {errorMessage && (
        <FormError
          dataHcName={`${dataHcName}-error-message`}
          className={styles.ErrorMessage}
          value={errorMessage}
        />
      )}
      <div data-hc-name={`${dataHcName}-title`}>
        Add a new credit card to your account to continue.
      </div>
      <div
        className={styles.CreditCard}
        data-hc-name={`${dataHcName}-cc-number-wrapper`}
      >
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>

      <ActionButtons dataHcName={`${dataHcName}-actions`}>
        <Button
          label="Submit"
          dataHcEventName={`${EVENT_NAME_CC_INFO}-submit`}
          dataHcEventType={MeaningfulEventTypes.Goal}
          loading={stripeLoading}
          disabled={!stripe || stripeLoading}
          dataHcName={`${dataHcName}-purchase-button`}
          onClick={handleSubmit}
        />
        {onCancel && (
          <Button
            label="Cancel"
            dataHcName={`${dataHcName}-cancel-button`}
            secondary
            onClick={onCancel}
          />
        )}
      </ActionButtons>
    </>
  );
};

export const StripeSetupIntent = (props: StripeSetupIntentProps) => {
  return (
    <StripeWrapper>
      <StripeSetupIntentInternal {...props} />
    </StripeWrapper>
  );
};

interface StripePaymentIntentProps {
  selectedPackage: Package;
  paymentMethod: PaymentMethod;
  isSubmittingPurchase: boolean;
  onPurchase: (stripe: Stripe) => void;
  onCancel?: VoidFunction;
  actionsPortalIdRender?: string;
  dataHcName?: string;
}
const StripePaymentIntentInternal = ({
  selectedPackage,
  isSubmittingPurchase,
  paymentMethod,
  actionsPortalIdRender,
  onPurchase,
  onCancel,
  dataHcName = 'purchase-report',
}: StripePaymentIntentProps) => {
  const [paymentClicked, setPaymentClicked] = useState(false);
  const stripe = useStripe();

  const brandFormatter =
    CARD_TYPE_FORMATTERS[paymentMethod.card.brand] ||
    CARD_TYPE_FORMATTERS.unknown;
  return (
    <>
      <h3 data-hc-name={`${dataHcName}-title`}>
        Your credit card will be charged {<SelfServiceReportPrice />}
      </h3>
      <p data-hc-name={`${dataHcName}-cc-preview`}>
        {brandFormatter(paymentMethod.card.last4)}
      </p>
      <p data-hc-name={`${dataHcName}-message`}>
        There are no refunds for Property Explorer 24/7 reports. Are you sure
        you want to continue?
      </p>
      <ActionButtons
        dataHcName={`${dataHcName}-actions`}
        portalIdRender={actionsPortalIdRender}
      >
        <Button
          label={`Purchase ${selectedPackage.amount} report${
            selectedPackage.amount === 1 ? '' : 's'
          }`}
          loading={isSubmittingPurchase}
          disabled={!stripe || paymentClicked || isSubmittingPurchase}
          dataHcName={`${dataHcName}-purchase-button`}
          dataHcEventName={`${dataHcName}-purchase-report`}
          dataHcEventType={MeaningfulEventTypes.Goal}
          onClick={() => {
            setPaymentClicked(true);
            if (stripe) {
              onPurchase(stripe);
            } else {
              logException(
                new Error('StripePaymentIntent: stripe is null on purchase')
              );
            }
          }}
        />
        {onCancel && (
          <Button
            label="Cancel"
            dataHcName={`${dataHcName}-cancel-button`}
            secondary
            onClick={onCancel}
          />
        )}
      </ActionButtons>
    </>
  );
};

export const StripePaymentIntent = (props: StripePaymentIntentProps) => {
  return (
    <StripeWrapper>
      <StripePaymentIntentInternal {...props} />
    </StripeWrapper>
  );
};
