import { useAccount } from '@hcs/auth';

import { checkIsAccountInternal } from '../utils/internal.utils';

export const useIsInternalUser = () => {
  const accountQuery = useAccount();
  if (accountQuery.isSuccess) {
    return {
      ...accountQuery,
      // TODO: This might not be a good way to determine if it's an internal user
      data: checkIsAccountInternal(accountQuery.data),
    };
  }
  return { ...accountQuery, data: undefined };
};
