import React from 'react';
import classNames from 'classnames';

import { PropertyStateLocation } from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import styles from './Details.module.css';

export interface DetailsProps {
  dataHcName: string;
  location?: PropertyStateLocation['location'] | null;
  attributes?: string[];
  hasKeywordMatch?: boolean;
}

export const Details = ({
  dataHcName,
  location,
  attributes,
  hasKeywordMatch,
}: DetailsProps) => {
  return (
    <div data-hc-name={dataHcName} className={classNames(styles.Details)}>
      <div
        className={classNames({
          [styles.keywordMatch]: hasKeywordMatch,
        })}
      >
        <div
          data-hc-name={`${dataHcName}-address`}
          className={styles.StreetAddress}
        >
          {formatStreetAddress(location)}
        </div>
        <div
          data-hc-name={`${dataHcName}-citystatezip`}
          className={styles.CityStateZip}
        >
          {!!location?.city && formatCityStateZip(location)}
        </div>
      </div>
      {attributes && (
        <div data-hc-name={dataHcName} className={styles.Attributes}>
          {attributes.map((attribute, i) => (
            <div
              key={`${dataHcName}-${i}`}
              data-hc-name={`${dataHcName}-${i}`}
              className={styles.Attribute}
            >
              {attribute}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
