import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { Account, AuthHeaderFn } from '@hcs/types';
import { logException } from '@hcs/utils';

import { ACCOUNT_QUERY_KEY } from '../constants';
import {
  authHeaderFnAccountApi,
  authHeaderFnEhrmantraut,
  authHeaderFnJwt,
  fetchAccount,
  getAccessTokenFromCache,
  resetAccountQuery,
} from '../utils';

import { queryClient } from '.';

// This must return a promise
// https://github.com/Flyrell/axios-auth-refresh#syntax
export const refreshAccessToken = async () => {
  try {
    const account = await fetchAccount();
    queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
    return Promise.resolve();
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // if fetch account also throws a 401, we need a new refresh token / login
      if (e.response?.status === 401) {
        if (queryClient.getQueryData<Account>([ACCOUNT_QUERY_KEY])) {
          // Conditionally remove since queries are invalidated
          resetAccountQuery();
        }
      } else if (
        e.code === 'ERR_NETWORK' ||
        e.code === 'ECONNABORTED' ||
        e.code === 'ETIMEDOUT'
      ) {
        // don't report error
      } else {
        logException(new Error(`refreshAccessToken catch error: ${e}`));
      }
    }

    return Promise.reject(e);
  }
};

const createAuthenticatedClient = (authHeaderFn: AuthHeaderFn) => {
  const axiosInstance = axios.create();

  // Instantiate the interceptor to refresh the access token
  createAuthRefreshInterceptor(axiosInstance, refreshAccessToken, {
    statusCodes: [401],
    // Do not pause so simultaneous 401s are handled
    pauseInstanceWhileRefreshing: false,
  });

  // Instantiate the interceptor to add the authorization header
  axiosInstance.interceptors.request.use((request) => {
    const token = getAccessTokenFromCache();
    if (!token) {
      return request;
    }

    const authHeaders = authHeaderFn(token || null);
    for (const key in authHeaders) {
      request.headers.set(key, authHeaders[key]);
    }
    return request;
  });

  return axiosInstance;
};

export const AxiosAccessTokenClientEhrm = createAuthenticatedClient(
  authHeaderFnEhrmantraut
);

export const AxiosAccessTokenClientJwt =
  createAuthenticatedClient(authHeaderFnJwt);

export const AxiosAccessTokenClientBearer = createAuthenticatedClient(
  authHeaderFnAccountApi
);
