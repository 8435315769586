import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { ImportPortfolioAssetsPayload } from '@hcs/types';

import { PortfolioApi } from '../api';
import { QUERY_KEY_PORTFOLIO } from '../hooks/usePortfolio';
import { QUERY_KEY_PORTFOLIO_ASSETS } from '../hooks/usePortfolioAssets';
import { QUERY_KEY_PORTFOLIOS } from '../hooks/usePortfolios';

export const useImportPortfolioAssets = (
  options?: UseMutationOptions<
    void,
    AxiosError,
    ImportPortfolioAssetsPayload,
    void
  >
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();

  return useMutation<void, AxiosError, ImportPortfolioAssetsPayload, void>(
    async ({ portfolioId, formData }: ImportPortfolioAssetsPayload) => {
      return await PortfolioApi.importPortfolioAssetsJob({
        portfolioId,
        formData,
      });
    },
    {
      ...options,
      onMutate: () => {
        toastOpen({
          type: 'loading',
          title: 'Adding properties...',
        });
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_KEY_PORTFOLIO_ASSETS]);
        queryClient.invalidateQueries([QUERY_KEY_PORTFOLIOS]);
        queryClient.invalidateQueries([
          QUERY_KEY_PORTFOLIO,
          variables.portfolioId,
        ]);
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables) => {
        toastOpen({
          type: 'error',
          title: `${
            error ? `${error}` : `Failed to import file`
          }, please try again`,
        });
        options?.onError?.(error, variables);
      },
    }
  );
};
