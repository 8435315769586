import React, { MouseEvent } from 'react';

import { TagIcon } from '../../../../svgs/icons/function';
import { CircleButton } from '../CircleButton';

import styles from './TagButton.module.css';

export interface TagButtonProps {
  dataHcName: string;
  dataHcEventSection?: string;
  onClick: (event?: MouseEvent) => void;
}

export const TagButton = ({
  dataHcName,
  dataHcEventSection,
  onClick,
}: TagButtonProps) => {
  return (
    <CircleButton
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      className={styles.TagButton}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      size="xs"
    >
      <TagIcon size={18} dataHcName={`${dataHcName}-icon`} />
    </CircleButton>
  );
};
