import { useCallback } from 'react';

import { IntercomEventData } from '@hcs/types';

import { useIntercomInstance } from './useIntercomInstance';

export const useLogIntercomEvent = () => {
  const intercomInstance = useIntercomInstance();
  return useCallback(
    (eventName: string, eventData: IntercomEventData) => {
      if (intercomInstance) {
        intercomInstance('trackEvent', eventName, eventData);
      }
    },
    [intercomInstance]
  );
};
