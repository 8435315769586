import { gql } from 'graphql-request';

export const FRAGMENT_COMPLEX_FIELDS_RENTAL_PREVIEW = gql`
  fragment ComplexFieldsRentalPreview on ComplexFieldsRental {
    currentHcMlsId
    currentListingId
    currentPrice
    currentStatus
    currentStatusDate
    currentlyOnMarket
    currentDaysOnMarketMLS
  }
`;

export const FRAGMENT_COMPLEX_FIELDS_RENTAL_CORE = gql`
  ${FRAGMENT_COMPLEX_FIELDS_RENTAL_PREVIEW}
  fragment ComplexFieldsRentalCore on ComplexFieldsRental {
    ...ComplexFieldsRentalPreview
    currentHcpyLookupKey
    currentSourceId
    lastCloseHcMlsId
    lastCloseHcpyLookupKey
    lastCloseListingId
    currentDaysOnMarketCumulative
    currentDaysToCloseCumulative
    currentListDate
    currentListingPrice
    lastCloseSourceId
    lastCloseDate
    lastClosePrice
    lastCloseSourceType
  }
`;
