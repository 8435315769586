import { Operation } from 'fast-json-patch';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import {
  CompTypes,
  DexpTemplates,
  OrgReportPreferencesResponse,
  SavedCompFilters,
  UiPreferences,
} from '@hcs/types';
import { PREFERENCES_API_URL } from '@hcs/urls';
import { expect404 } from '@hcs/utils';

// All Huell endpoints: https://git.housecanary.net/Engineering/huell/-/blob/develop/huell/urls.py

const fetchUiPreferencesForOrg = async () => {
  try {
    const response = await AxiosAccessTokenClientEhrm.get<UiPreferences>(
      `${PREFERENCES_API_URL}/organization_preferences/`
    );
    return response.data;
  } catch (err) {
    return expect404(err);
  }
};

const updateUiPreferencesForOrg = async (uiPreferences: UiPreferences) => {
  const response = await AxiosAccessTokenClientEhrm.post<UiPreferences>(
    `${PREFERENCES_API_URL}/organization_preferences/`,
    uiPreferences
  );
  return response.data;
};

const patchUiPreferencesForOrg = async (operations: Operation[]) => {
  const response = await AxiosAccessTokenClientEhrm.patch<UiPreferences>(
    `${PREFERENCES_API_URL}/organization_preferences/`,
    operations
  );
  return response.data;
};

const fetchReportPreferencesForOrg = async () => {
  try {
    const response =
      await AxiosAccessTokenClientEhrm.get<OrgReportPreferencesResponse>(
        `${PREFERENCES_API_URL}/organization_report_preferences/`
      );
    return response.data;
  } catch (err) {
    return expect404(err);
  }
};

const patchReportPreferencesForOrg = async (operations: Operation[]) => {
  const response =
    await AxiosAccessTokenClientEhrm.patch<OrgReportPreferencesResponse>(
      `${PREFERENCES_API_URL}/organization_report_preferences/`,
      operations
    );
  return response.data;
};

const fetchSavedCompFilterSetsOrg = async (compType: CompTypes) => {
  try {
    const response = await AxiosAccessTokenClientEhrm.get<SavedCompFilters>(
      `${PREFERENCES_API_URL}/organization_comp_filters/?compType=${compType}`
    );
    return response.data;
  } catch (err) {
    return expect404(err);
  }
};

const patchSavedCompFilterSetsForOrg = async ({
  compType,
  operations,
}: {
  compType: CompTypes;
  operations: Operation[];
}) => {
  const response = await AxiosAccessTokenClientEhrm.patch<SavedCompFilters>(
    `${PREFERENCES_API_URL}/organization_comp_filters/?compType=${compType}`,
    operations
  );

  return response.data;
};

export const HuellOrgPreferencesApi = {
  // UI Preferences
  fetchUiPreferencesForOrg,
  updateUiPreferencesForOrg,
  patchUiPreferencesForOrg,
  // Report Preferences
  fetchReportPreferencesForOrg,
  patchReportPreferencesForOrg,
  // Saved Comp Filters
  fetchSavedCompFilterSetsOrg,
  patchSavedCompFilterSetsForOrg,
  // DEXP Templates
  fetchDexpTemplatesForOrg: async () => {
    try {
      const response = await AxiosAccessTokenClientEhrm.get<DexpTemplates>(
        `${PREFERENCES_API_URL}/organization_dexp_templates/`
      );
      return response.data;
    } catch (err) {
      return expect404(err);
    }
  },

  patchDexpTemplatesForOrg: async (operations: Operation[]) => {
    const response = await AxiosAccessTokenClientEhrm.patch<DexpTemplates>(
      `${PREFERENCES_API_URL}/organization_dexp_templates/`,
      operations
    );
    return response.data;
  },
};
