import React from 'react';

import { MultiSelect, Option } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyTypeEnum } from '@hcs/types';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompsFiltersPaths } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';
import { formatPropertyType } from '@hcs/utils';

import { useCompsFiltersDocument, useSubjectDocument } from '../../../hooks';
import { useDocumentPatch } from '../../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../../hooks/useReportPermissions';

import { CompFilterChip } from './CompFilterChip';

import styles from '../CompFilters.module.css';

const PROPERTY_TYPE_OPTIONS: Option<PropertyTypeEnum>[] = [
  {
    value: PropertyTypeEnum.Sfr,
    label: formatPropertyType(PropertyTypeEnum.Sfr),
  },
  {
    value: PropertyTypeEnum.Condo,
    label: formatPropertyType(PropertyTypeEnum.Condo),
  },
  {
    value: PropertyTypeEnum.Townhouse,
    label: formatPropertyType(PropertyTypeEnum.Townhouse),
  },
  {
    value: PropertyTypeEnum.MultiFamily,
    label: formatPropertyType(PropertyTypeEnum.MultiFamily),
  },
  {
    value: PropertyTypeEnum.Manufactured,
    label: formatPropertyType(PropertyTypeEnum.Manufactured),
  },
];

const FIELD = PropertyStateFields.propertyType;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = 'comp-filter-property-type';
export const COMP_FILTER_PROPERTY_TYPE: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const label = filterValue?.absoluteValue
      ? filterValue.absoluteValue.map(formatPropertyType).join(', ')
      : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType }: CompFilterControlTypeProps) => {
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument || !reportPermissions) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }

    const filterValue = filterDocument.data.filters?.[FIELD];
    const subjectValue = getPropertyStateFieldValue(FIELD, {
      propertyStateType: PropertyStateType.Core,
      propertyState: subjectDocument.data.propertyState,
    });

    return (
      <MultiSelect
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        options={PROPERTY_TYPE_OPTIONS.map((o) => {
          if (o.value === subjectValue) {
            return {
              ...o,
              label: (
                <>
                  {o.label}
                  <div
                    className={styles.SubjectPropertyMatch}
                    data-hc-name={`${dataHcName}-subject-match`}
                  >
                    Subject property match
                  </div>
                </>
              ),
            };
          } else {
            return o;
          }
        })}
        disabled={!reportPermissions.isEditable}
        onChange={(value: PropertyTypeEnum[]) => {
          if (value.length === 0) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    );
  },
};
