import React, {
  Children,
  cloneElement,
  Fragment,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import classNames from 'classnames';

import {
  TableCellProps,
  TableHeaderCellProps,
  TableRowProps,
} from '@hcs/types';
import { logException } from '@hcs/utils';

import { Skeleton } from '../../../global/loading-errors-null/Skeleton';

import { TableCell } from './TableCell';

import styles from './Table.module.css';

const isTableCell = (
  child: ReactElement
): child is ReactElement<TableCellProps> => {
  return !!child.props.isTableCell;
};

const isTableHeaderCell = (
  child: ReactElement
): child is ReactElement<TableHeaderCellProps> => {
  return !!child.props.isTableHeaderCell;
};

export const TableRow = ({
  dataHcName,
  dataHcEventData,
  height,
  sticky,
  isLoading,
  stickyOffset,
  className,
  children,
  sortable,
  style = {},
  onClick,
  onMouseEnter,
  onMouseLeave,
  highlighted,
  highlightOnHover,
  setRowRef,
  isScrollingHorz,
}: TableRowProps): ReactElement => {
  const arrayChildren = Children.toArray(children);
  const cells = useMemo(() => {
    const cells: ReactElement[] = [];

    let stickyCellOffset = 0;
    const processChildren = (
      toProcess: ReactNode,
      keyPrefix?: string | number // when we recursively process fragment children, we otherwise up with duplicate keys since we're using index as a key
    ) => {
      Children.forEach(toProcess, (child, i) => {
        if (isLoading) {
          cells.push(
            <TableCell>
              <Skeleton
                height={height}
                type="fill"
                dataHcName={`${dataHcName}-skeleton`}
              >
                &nbsp;
              </Skeleton>
            </TableCell>
          );
        } else {
          const nextChild = arrayChildren[i + 1];
          if (child === null) return;
          if (!React.isValidElement(child)) {
            logException(
              '[TableRow] Invalid Child. Only TableCell, TableHeaderCell, or Fragment can be a child of TableRow.'
            );
            // add an empty cell so columns still line up correctly
            cells.push(<td />);
            return null;
          }
          if (child.type === Fragment) {
            return processChildren(child.props.children, `frag-${i}`);
          } else if (isTableCell(child) || isTableHeaderCell(child)) {
            const keyPrefixStr = keyPrefix ? keyPrefix + '-' : '';
            // Now we know that the child is valid and we can cast a type of it
            if (isTableCell(child)) {
              const id =
                child.props.dataHcName ||
                `${dataHcName}-cell-${keyPrefixStr}${i}`;
              cells.push(
                cloneElement(child, {
                  ...child.props,
                  dataHcName: id,
                  key: id,
                  // the last sticky col renders the shadow
                  renderStickyShadow: !!(
                    child.props.sticky &&
                    nextChild &&
                    React.isValidElement(nextChild) &&
                    nextChild.type !== Fragment &&
                    isTableCell(nextChild) &&
                    !nextChild.props.sticky
                  ),
                  stickyRowOffset: stickyOffset,
                  stickyOffset: child.props.sticky
                    ? child.props.stickyOffset || stickyCellOffset
                    : undefined,
                  height: height !== undefined ? height : child.props.height,
                })
              );
            } else {
              const id = `${dataHcName}-cell-${keyPrefixStr}${i}`;
              cells.push(
                cloneElement(child, {
                  sortable,
                  dataHcName: id,
                  key: id,
                  ...child.props,
                  stickyOffset: child.props.sticky
                    ? child.props.stickyOffset || stickyCellOffset
                    : undefined,
                  height: height !== undefined ? height : child.props.height,
                })
              );
            }

            if (child.props.width && child.props.sticky) {
              stickyCellOffset += child.props.width;
            }
          } else {
            logException(
              '[TableRow] Invalid Child. Only TableCell, TableHeaderCell, or Fragment can be a child of TableRow.'
            );
            // add an empty cell so columns still line up correctly
            cells.push(<td />);
            return null;
          }
        }
      });
    };
    processChildren(children);

    return cells;
  }, [children, isLoading]);

  return (
    <tr
      style={{
        ...style,
        height: height ? `${height}px` : style.height,
      }}
      className={classNames(styles.TableRow, className, {
        [styles.clickable]: !!onClick,
        [styles.sticky]: sticky,
        [styles.highlighted]: highlighted,
        [styles.highlightOnHover]: highlightOnHover,
        [styles.isScrollingHorz]: isScrollingHorz,
      })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-hc-name={dataHcName}
      data-hc-event-data={dataHcEventData}
      ref={(el) => (setRowRef ? setRowRef(el) : {})}
    >
      {cells}
    </tr>
  );
};

TableRow.defaultProps = {
  isTableRow: true,
};
