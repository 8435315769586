import { AxiosAccessTokenClientBearer, AxiosClient } from '@hcs/http-clients';
import { ConfirmUserRequest } from '@hcs/types';
import { ACCOUNT_URL_EXTERNAL } from '@hcs/urls';

export const UserApi = {
  confirmUser: async ({ token }: ConfirmUserRequest) => {
    return await AxiosClient.post<void>(
      `${ACCOUNT_URL_EXTERNAL}/ss/confirm-user`,
      { token },
      { withCredentials: true }
    );
  },
  resendConfirmation: async (userId: number) => {
    return await AxiosAccessTokenClientBearer.post<void>(
      `${ACCOUNT_URL_EXTERNAL}/users/${userId}/resend-confirmation`
    );
  },
};
