import React from 'react';

import { ReportSummaryCellContentProps } from '@hcs/types';

import { DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL } from '../../../constants/ReportSummary.constants';

import { createReportSummaryTableCellConfig } from './ReportSummaryTableCell';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL}-label`;
const CellContentComponent = ({ order }: ReportSummaryCellContentProps) => (
  <div data-hc-name={dataHcName}>{order.label}</div>
);

export const REPORT_SUMMARY_LABEL_CONFIG = createReportSummaryTableCellConfig({
  CellContentComponent,
  tableHeaderName: 'Label',
});
