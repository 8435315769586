import { useSearchParams } from 'react-router-dom';

import { CompIdentifier, ListingIdentifier } from '@hcs/types';

import {
  PhotosPagePayload,
  PhotosPageSearchParams,
} from '../features/ReportPhotosOverlayPage/ReportPhotosOverlayPage.types';

export const usePhotosPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Get current state from the search params
  const type = searchParams.get(PhotosPageSearchParams.Type);
  const listingIdentifierStr = searchParams.get(
    PhotosPageSearchParams.ListingIdentifier
  );
  const compIdentifierStr = searchParams.get(
    PhotosPageSearchParams.CompIdentifier
  );
  // Parse Identifiers
  const listingIdentifier = listingIdentifierStr
    ? (JSON.parse(listingIdentifierStr) as ListingIdentifier)
    : undefined;
  const compIdentifier = compIdentifierStr
    ? (JSON.parse(compIdentifierStr) as CompIdentifier)
    : undefined;

  const state: PhotosPagePayload | null =
    type === 'subject'
      ? {
          type: 'subject',
          listingIdentifier,
        }
      : type === 'comp' && compIdentifier
      ? { type: 'comp', compIdentifier }
      : null;
  return {
    photosPageOpen: (payload: PhotosPagePayload) => {
      if (payload.type === 'subject') {
        searchParams.set(PhotosPageSearchParams.Type, 'subject');
        if (payload.listingIdentifier) {
          searchParams.set(
            PhotosPageSearchParams.ListingIdentifier,
            JSON.stringify(payload.listingIdentifier)
          );
        }
      } else {
        searchParams.set(PhotosPageSearchParams.Type, 'comp');
        searchParams.set(
          PhotosPageSearchParams.CompIdentifier,
          JSON.stringify(payload.compIdentifier)
        );
      }
      setSearchParams(searchParams);
    },
    photosPageClose: () => {
      searchParams.delete(PhotosPageSearchParams.Type);
      searchParams.delete(PhotosPageSearchParams.CompIdentifier);
      searchParams.delete(PhotosPageSearchParams.ListingIdentifier);
      setSearchParams(searchParams);
    },
    state,
  };
};
