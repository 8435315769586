import React from 'react';

import { ReportSummaryCellContentProps } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL } from '../../../constants/ReportSummary.constants';

import { createReportSummaryTableCellConfig } from './ReportSummaryTableCell';

const dataHcName = `${DATA_HC_NAME_REPORT_SUMMARY_TABLE_CELL}-completed-date`;
const CellContentComponent = ({ order }: ReportSummaryCellContentProps) => (
  <div data-hc-name={dataHcName}>
    {formatDateShort(order?.actualDeliveryDate)}
  </div>
);

export const REPORT_SUMMARY_COMPLETED_DATE_CONFIG =
  createReportSummaryTableCellConfig({
    CellContentComponent,
    tableHeaderName: 'Completed Date',
  });
