import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import { KeywordSearchInputs, KeywordSearchResult } from '@hcs/types';
import { KEYWORD_SEARCH_URL } from '@hcs/urls';

export const KeywordSearchApi = {
  fetchKeywordsByListing: async (
    keywordSearchInputs: KeywordSearchInputs | undefined
  ) => {
    if (!keywordSearchInputs) {
      throw new Error('[Keyword Search] Cannot call API without inputs');
    }
    const response = await AxiosAccessTokenClientEhrm.post<
      KeywordSearchResult[]
    >(`${KEYWORD_SEARCH_URL}/by_listings`, keywordSearchInputs);
    return response.data;
  },
};
