import axios from 'axios';
import { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import {
  AxiosAccessTokenClientBearer,
  AxiosAccessTokenClientEhrm,
  AxiosClient,
  fetchAccount,
} from '@hcs/http-clients';
import {
  AuthapiCheckResetPwTokenRequestExt,
  AuthapiForgotPasswordRequest,
  AuthapiLoginRequestExt,
  AuthapiResetPwRequestExt,
  AuthnAccessResponse,
  CheckInvitationCodeResponse,
  OrganizationWithApplications,
  ResetPasswordPayload,
  RolesResponse,
  SignUpPayload,
  SingleUseTokenResponse,
  UserapiUserHmacResponseExt,
} from '@hcs/types';
import { ACCOUNT_URL, ACCOUNT_URL_EXTERNAL, EHRMANTRAUT_URL } from '@hcs/urls';
const LOGIN_UNAUTHORIZED_HTTP_STATUS = 401;
const RESET_PASSWORD_UNAUTHORIZED_HTTP_STATUS = 401;
export class ResetPasswordInvalidTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ResetPasswordInvalidTokenError';
  }
}

export const AuthApi = {
  login: async (payload: AuthapiLoginRequestExt) => {
    try {
      const response = await AxiosClient.post<AuthnAccessResponse>(
        `${ACCOUNT_URL_EXTERNAL}/auth/login`,
        payload,
        {
          withCredentials: true,
        }
      );
      return await fetchAccount(response.data.token?.access);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === LOGIN_UNAUTHORIZED_HTTP_STATUS) {
          throw new Error('Unknown/unconfirmed email or incorrect password');
        }
        if (e.response?.data.status) {
          throw new Error(e.response.data.status);
        }
      }
      throw e;
    }
  },
  // Uses refresh token cookie
  logout: async () => {
    await AxiosClient.post<void>(`${ACCOUNT_URL}/auth/logout`, null, {
      withCredentials: true,
    });
    return;
  },
  forgotPassword: async (payload: AuthapiForgotPasswordRequest) => {
    await AxiosClient.post<
      void,
      AxiosResponse<void>,
      AuthapiForgotPasswordRequest
    >(`${ACCOUNT_URL}/auth/forgot-password`, payload);
  },
  fetchAccount: async () => {
    return await fetchAccount();
  },
  fetchCurrentOrg: async () => {
    const response =
      await AxiosAccessTokenClientEhrm.get<OrganizationWithApplications>(
        `${EHRMANTRAUT_URL}/auth/current-organization`
      );
    return camelcaseKeys(response.data, { deep: true });
  },
  signUp: async (signUpPayload: SignUpPayload) => {
    const response = await AxiosClient.post<AuthnAccessResponse>(
      `${ACCOUNT_URL_EXTERNAL}/auth/invite-register`,
      snakecaseKeys(signUpPayload, { deep: true }),
      {
        withCredentials: true,
      }
    );
    return await fetchAccount(response.data.token?.access);
  },
  checkResetPasswordToken: async (token: string) => {
    try {
      await AxiosClient.post<
        void,
        AxiosResponse<void>,
        AuthapiCheckResetPwTokenRequestExt
      >(`${ACCOUNT_URL_EXTERNAL}/auth/check-reset-password-token`, {
        token,
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (
          e.response &&
          e.response.status === RESET_PASSWORD_UNAUTHORIZED_HTTP_STATUS
        ) {
          throw new ResetPasswordInvalidTokenError('Token expired or invalid');
        }
      }
      throw e;
    }
  },
  resetPassword: async (payload: ResetPasswordPayload) => {
    try {
      const response = await AxiosClient.post<
        AuthnAccessResponse,
        AxiosResponse<AuthnAccessResponse>,
        AuthapiResetPwRequestExt
      >(`${ACCOUNT_URL_EXTERNAL}/auth/reset-password`, payload, {
        withCredentials: true,
      });

      return await fetchAccount(response.data.token?.access);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (
          e.response &&
          e.response.status === RESET_PASSWORD_UNAUTHORIZED_HTTP_STATUS
        ) {
          throw new ResetPasswordInvalidTokenError(
            'Verification link is expired or invalid'
          );
        }
      }
      throw e;
    }
  },
  fetchSystemRoles: async () => {
    const makeRoleRequest = async () => {
      const response = await AxiosAccessTokenClientEhrm.get<RolesResponse>(
        `${EHRMANTRAUT_URL}/admin/role?results_per_page=100`
      );
      return camelcaseKeys(response.data, { deep: true });
    };
    return await makeRoleRequest();
  },
  checkInvitationCode: async (invitationCode: string) => {
    const response = await AxiosClient.get<CheckInvitationCodeResponse>(
      `${EHRMANTRAUT_URL}/invitation/code/${invitationCode}`
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  fetchUserHmacHash: async (userId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<UserapiUserHmacResponseExt>(
        `${ACCOUNT_URL_EXTERNAL}/users/${userId}/hmac`
      );
    return response.data;
  },
  fetchSingleUseToken: async () => {
    const response =
      await AxiosAccessTokenClientEhrm.get<SingleUseTokenResponse>(
        `${EHRMANTRAUT_URL}/auth/single-use-token`
      );
    return camelcaseKeys(response.data);
  },
};
