import React, { useMemo } from 'react';
import classNames from 'classnames';

import {
  HorizontalSelector,
  HorizontalSelectorOption,
} from '@hcs/design-system';
import { TabPopover } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { CompsListTypes, ReportFeatures, ReportId } from '@hcs/types';
import { formatCompsListType } from '@hcs/utils';

import { COMPS_LIST_TYPE_CONFIGS } from '../../../constants';
import { useReportConfig } from '../../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../../utils/reportConfig.utils';

import styles from './CompsListViewTypeControl.module.css';

const FarmConfig = COMPS_LIST_TYPE_CONFIGS.compIDs;
const SelectedConfig = COMPS_LIST_TYPE_CONFIGS.selected;
const SuggestedConfig = COMPS_LIST_TYPE_CONFIGS.hcSuggestedComps;
const AppraisalConfig = COMPS_LIST_TYPE_CONFIGS.appraisal;

const OPTION_FILTERED_FARM: HorizontalSelectorOption<CompsListTypes> = {
  label: (
    <>
      <FarmConfig.Icon
        className={styles.Icon}
        size="sm"
        color="neutral-dark-20"
      />{' '}
      {FarmConfig.name}
    </>
  ),
  value: 'compIDs',
  disabled: false,
};

const OPTION_USER_SELECTED: HorizontalSelectorOption<CompsListTypes> = {
  label: (
    <>
      <SelectedConfig.Icon
        className={styles.Icon}
        size="sm"
        color="neutral-dark-20"
      />{' '}
      {SelectedConfig.name}
    </>
  ),
  value: 'selected',
  disabled: false,
};

const OPTION_HC_SUGGESTED: HorizontalSelectorOption<CompsListTypes> = {
  label: (
    <>
      <SuggestedConfig.Icon
        className={styles.Icon}
        size="sm"
        color="neutral-dark-20"
      />{' '}
      {SuggestedConfig.name}
    </>
  ),
  value: 'hcSuggestedComps',
  disabled: false,
};

const OPTION_APPRAISAL: HorizontalSelectorOption<CompsListTypes> = {
  label: (
    <>
      <AppraisalConfig.Icon
        className={styles.Icon}
        size="sm"
        color="neutral-dark-20"
      />{' '}
      {AppraisalConfig.name}
    </>
  ),
  value: 'appraisal',
  disabled: false,
};

interface Props {
  reportId: ReportId;
  compsListType: CompsListTypes;
  excluded?: Partial<Record<CompsListTypes, boolean>>;
  // Required because compListType must be changed outside the CompsList feature and passed as a prop
  onChange: (compListType: CompsListTypes) => void;
  className?: string;
}

const dataHcName = 'comp-list-type-toggle';
export const CompsListTypeControl = ({
  reportId,
  className,
  compsListType,
  excluded,
  onChange,
}: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const { active, setActiveState, handleClose } = useActiveState();
  const SelectedIcon = COMPS_LIST_TYPE_CONFIGS[compsListType].Icon;
  const COMPS_LIST_HEADER_PADDING: [number, number, number, number] = [
    0, 0, 0, 0,
  ];
  const options = useMemo<HorizontalSelectorOption<CompsListTypes>[]>(() => {
    const result: HorizontalSelectorOption<CompsListTypes>[] = [];
    if (
      !excluded?.compIDs &&
      reportFeaturesSupportedAny(reportConfig, [ReportFeatures.CompsFarmView])
    ) {
      result.push(OPTION_FILTERED_FARM);
    }
    if (
      !excluded?.selected &&
      reportFeaturesSupportedAny(reportConfig, [ReportFeatures.CompsSelect])
    ) {
      result.push(OPTION_USER_SELECTED);
    }
    if (
      !excluded?.hcSuggestedComps &&
      reportFeaturesSupportedAny(reportConfig, [
        ReportFeatures.CompsHcSuggested,
      ])
    ) {
      result.push(OPTION_HC_SUGGESTED);
    }
    if (
      !excluded?.appraisal &&
      reportFeaturesSupportedAny(reportConfig, [ReportFeatures.AppraisalComps])
    ) {
      result.push(OPTION_APPRAISAL);
    }
    return result;
  }, [reportConfig, excluded]);
  return (
    <TabPopover
      pagePadding={COMPS_LIST_HEADER_PADDING}
      active={active}
      dataHcName={dataHcName}
      onChangeActiveState={setActiveState}
      chevron="down"
      trigger={
        <Tooltip
          dataHcName={`${dataHcName}-tooltip`}
          trigger={<SelectedIcon height={24} width={24} />}
          label={`Viewing ${formatCompsListType(compsListType)}`}
        />
      }
      content={
        <HorizontalSelector
          dataHcName={dataHcName}
          value={compsListType}
          className={classNames(
            styles.CompsListViewTypeControl,
            styles.HorizontalSelector,
            className
          )}
          options={options}
          onSelect={(newValue) => {
            onChange(newValue);
            handleClose();
          }}
        />
      }
    />
  );
};
