import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
} from '@hcs/types';
import { formatDateShort } from '@hcs/utils';
import { listingStatusNormalized } from '@hcs/utils';

import { CURRENT_STATUS_CONFIG } from '../../../configs/PropertyStateFields/currentStatus/currentStatus';

const FIELD = PropertyStateFields.currentStatusDate;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Current Status Date';
const labelShort = 'Date';
const propertyStatePath: PropertyStatePaths =
  '/complexFieldsSale/currentStatusDate';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  }
  return propertyState?.complexFieldsSale?.currentStatusDate;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatDateShort(getValue(propertyStateArgs));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Date';
const formatValueShort: FieldConfig['formatValueShort'] = (propertyStateArgs) =>
  formatDateShort(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)}`;

const labelDynamic: FieldConfig['labelDynamic'] = (propertyStateArgs) => {
  const listingStatus =
    listingStatusNormalized(
      CURRENT_STATUS_CONFIG.getValue(propertyStateArgs)
    ) === 'Closed'
      ? 'Closed'
      : 'Listing';
  return `${listingStatus} Date`;
};
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const CURRENT_STATUS_DATE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelDynamic,
  labelShort,
  labelMicro,
  formatValueShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
};
