import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';

import { LoginAsWarning, useIsLoggedIn } from '@hcs/auth';
import { authDevToolsSlice } from '@hcs/auth';
import { SentryUser } from '@hcs/auth';
import { CerberusDevTool } from '@hcs/cerberus';
import { marketStateSearchSlice } from '@hcs/cerberus-stats';
import { HcsConsoleDevTool } from '@hcs/console';
import { dataExplorerSlice } from '@hcs/data-explorer';
import {
  LayoutContent,
  LayoutGlobalHeaderChildren,
  SkeletonPage,
} from '@hcs/design-system';
import { DesignSystemStyles } from '@hcs/design-system';
import {
  ExperienceFlagsDevTool,
  HcsDevTools,
  SentryTestDevTool,
} from '@hcs/dev-tools';
import {
  ENGAGEMENT_TRACKING_REDUCER_KEY,
  EngagementTracking,
  EngagementTrackingDevTool,
  engagementTrackingReducer,
} from '@hcs/engagement-tracking';
import { experienceFlagsSlice } from '@hcs/experience-flags';
import {
  APP_CONFIG_PLATFORM,
  VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM,
} from '@hcs/hc-products';
import { HelpChatProvider } from '@hcs/help-chat';
import { queryClient } from '@hcs/http-clients';
import { keywordSearchSlice } from '@hcs/keyword-search';
import { hcMapSlice } from '@hcs/maps';
import { IntercomUser } from '@hcs/organization';
import { addendumSlice } from '@hcs/pdf/pdf-service';
import {
  PORTFOLIO_REDUCER_KEY,
  portfolioAssetsSlice,
  portfolioNotificationsSlice,
  portfolioReducer,
} from '@hcs/portfolio';
import { REPORT_API_REDUCER_KEY, reportApiReducer } from '@hcs/report-api';
import { RouterErrorBoundary } from '@hcs/routing';
import { SearchByMlsNumberProvider } from '@hcs/search-by-mls-number';
import { SelfServiceEngagementData } from '@hcs/self-service';
import { toastSlice } from '@hcs/toast';
import { Toast } from '@hcs/toast';
import { createHcReduxStore } from '@hcs/utils';
import { addErrorElementToRoutes } from '@hcs/utils';
import { initSentry, ROOT_QUERY_SELECTOR } from '@hcs/webapps';

import { routes as accessRoutes } from './apps/access/routes';
import { routes as aexpRoutes } from './apps/aexp/routes';
import { routes as canaryaiRoutes } from './apps/canaryai/routes';
import { routes as dexpRoutes } from './apps/dexp/routes';
import { routes as miRoutes } from './apps/market-insights/routes';
import { routes as ordersRoutes } from './apps/orders/routes';
import { routes as orgAdminRoutes } from './apps/org-admin/routes';
import {
  publicRoutes as pexpPublicRoutes,
  routes as pexpRoutes,
} from './apps/pexp/routes';
import { routes as platformRoutes } from './apps/platform/routes';
import { routes as portfolioRoutes } from './apps/portfolio/routes';
import { routes as userAdminRoutes } from './apps/user-admin/routes';
import { SelfServeStatusRedirect } from './auth-redirects/SelfServeStatusRedirect/SelfServeStatusRedirect';
import { SolutionsPlatformGlobalHeader } from './navigation/SolutionsPlatformGlobalHeader';
import { SOLUTIONS_PLATFORM_APPS } from './apps';

window.BUNDLE_APP_CONFIGS = SOLUTIONS_PLATFORM_APPS;

initSentry();

const Landing = () => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  return isLoggedIn ? (
    <Navigate to={APP_CONFIG_PLATFORM.rootPath} replace />
  ) : (
    <Navigate to={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGIN} replace />
  );
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const solutionsPlatformRouter = sentryCreateBrowserRouter(
  addErrorElementToRoutes(
    <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
      <LayoutContent>
        <RouterErrorBoundary />
      </LayoutContent>
    </LayoutGlobalHeaderChildren>,
    [
      ...accessRoutes(),
      ...pexpPublicRoutes(),
      {
        path: '',
        element: (
          // Wrap all authenticated routes in the self serve status check
          <SelfServeStatusRedirect>
            <Outlet />
          </SelfServeStatusRedirect>
        ),
        children: [
          {
            path: '/',
            element: <Landing />,
          },
          ...dexpRoutes(),
          ...portfolioRoutes(),
          ...pexpRoutes(),
          ...aexpRoutes(),
          ...miRoutes(),
          ...platformRoutes(),
          ...canaryaiRoutes(),
          ...orgAdminRoutes(),
          ...userAdminRoutes(),
          ...ordersRoutes(),
          {
            path: '*',
            element: <Navigate to="/" replace />,
          },
        ],
      },
    ]
  )
);

const reduxStore = createHcReduxStore(
  [
    toastSlice,
    authDevToolsSlice,
    dataExplorerSlice,
    keywordSearchSlice,
    hcMapSlice,
    experienceFlagsSlice,
    portfolioAssetsSlice,
    portfolioNotificationsSlice,
    marketStateSearchSlice,
    addendumSlice,
  ],
  {
    [REPORT_API_REDUCER_KEY]: reportApiReducer,
    [PORTFOLIO_REDUCER_KEY]: portfolioReducer,
    [ENGAGEMENT_TRACKING_REDUCER_KEY]: engagementTrackingReducer,
  }
);

const root = ReactDOM.createRoot(
  document.querySelector(ROOT_QUERY_SELECTOR) as Element
);
root.render(
  <StrictMode>
    <SkeletonPage>
      <ReduxProvider store={reduxStore}>
        <QueryClientProvider client={queryClient}>
          <SearchByMlsNumberProvider>
            <HelpChatProvider>
              <RouterProvider router={solutionsPlatformRouter} />
              <DesignSystemStyles />
              <LoginAsWarning />
              <HcsDevTools />
              <EngagementTracking />
              <Toast />
              <HcsConsoleDevTool />
              <EngagementTrackingDevTool />
              <ExperienceFlagsDevTool />
              <CerberusDevTool />
              <IntercomUser />
              <SentryUser />
              <SentryTestDevTool />
              <SelfServiceEngagementData />
            </HelpChatProvider>
          </SearchByMlsNumberProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </SkeletonPage>
  </StrictMode>
);
