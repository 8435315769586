import React from 'react';

import { Dropdown } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatBoolYesNoUnknown } from '@hcs/utils';

const FIELD = PropertyStateFields.ownerOccupied;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Owner Occupied';
const labelShort = 'Owner Occupied';
const propertyStatePath: PropertyStatePaths =
  '/taxDetails/ownership/ownerOccupied';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.taxDetails?.ownership?.ownerOccupied;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatBoolYesNoUnknown(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)}`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};
export interface OnChangeArgOwnerOccupiedType {
  path: string;
  value: boolean | null;
  field: PropertyStateFields.ownerOccupied;
}

const EDIT_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Unknown',
    value: null,
  },
];

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: OnChangeArgOwnerOccupiedType) => void;
  className?: string;
}) => {
  const value = getValue(propertyStateArgs);

  return (
    <Dropdown
      dataHcName="owner-occupied-type-edit-dropdown"
      className={className}
      onSelect={(value) => {
        onChange({
          path: propertyStatePath,
          value,
          field: PropertyStateFields.ownerOccupied,
        });
      }}
      options={EDIT_OPTIONS}
      value={value}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const OWNER_OCCUPIED_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
};
