import { DefaultValues } from 'react-hook-form';
import * as yup from 'yup';

import {
  BulkEditBuyBoxFormData,
  BulkEditPatch,
  BulkEditRangeActiveFormData,
  BulkEditRangeFilter,
  BulkEditRangeFilterOrNull,
  BulkEditRangeFilters,
  BuyBox,
  BuyBoxFormData,
  BuyBoxListItem,
  BuyBoxPatch,
  BuyBoxRangeFormData,
  ExcludeFilterOrNull,
  Filters,
  LeadFeedPropertyTypeEnum,
  NewBuyBox,
  PropertyTypes,
  RangeFilters,
  SetFilterOrNull,
  Template,
} from '@hcs/types';
import {
  getRangeMaxYup,
  GetRangeMaxYupOptions,
  getRangeMinYup,
  GetRangeMinYupOptions,
} from '@hcs/utils';
import {
  getMaxFromRangeFilter,
  getMinFromRangeFilter,
  getRangeFilter,
} from '@hcs/utils';

import { getIsExcludedFromexcludeFilter } from './api.utils';

/**
 * Validates the parameter as a whole number with an optional .5 increment
 *
 * ex. (1 OR 1.5) NOT (1.2 OR 1.55)
 */
const wholeNumberHalfIncrement = (val: number | null | undefined) => {
  if (val === null || val === undefined || val.toString().length === 0)
    return true;

  const regex = /^\d*(\.)?[5]?$/;
  return regex.test(val.toString());
};

export const getNameYup = () => {
  return yup.string().trim().required('Name is required').max(255);
};

export const getMsaYup = () => {
  return yup.string().required('MSA is required');
};

// only the bulk fields use the active fields
type BuyBoxMinRangeOptions = GetRangeMinYupOptions<
  keyof BulkEditRangeActiveFormData
>;
type BuyBoxMaxRangeOptions = GetRangeMaxYupOptions<
  keyof BuyBoxRangeFormData,
  keyof BulkEditRangeActiveFormData
>;

export const getMinListingPriceYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup({
    required: true,
    ...options,
  });
};

export const getMaxListingPriceYup = (
  options: Partial<BuyBoxMaxRangeOptions> = {}
) => {
  return getRangeMaxYup({
    required: true,
    // maxValue: 1000000000, // billion dollars
    minFieldName: 'listingPriceMin',
    ...options,
  });
};

export const getMinBedsYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup(options);
};

export const getMaxBedsYup = (options: Partial<BuyBoxMaxRangeOptions> = {}) => {
  return getRangeMaxYup({
    // maxValue: 99, // 99 bedrooms
    minFieldName: 'bedsMin',
    ...options,
  });
};

const HALF_INCREMENT_MESSAGE =
  'Must be whole number with optional .5 increment.';

export const getMinBathYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup(options).test(
    'halfIncrementMinBath',
    HALF_INCREMENT_MESSAGE,
    wholeNumberHalfIncrement
  );
};

export const getMaxBathYup = (options: Partial<BuyBoxMaxRangeOptions> = {}) => {
  return getRangeMaxYup({
    minFieldName: 'bathsMin',
    ...options,
  }).test(
    'halfIncrementMaxBath',
    HALF_INCREMENT_MESSAGE,
    wholeNumberHalfIncrement
  );
};

export const getMinGlaYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup(options);
};

export const getMaxGlaYup = (options: Partial<BuyBoxMaxRangeOptions> = {}) => {
  return getRangeMaxYup({
    // maxValue: 50000, // 50,000 square feet
    minFieldName: 'glaMin',
    ...options,
  });
};

export const getMinLotSizeYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup(options);
};

export const getMaxLotSizeYup = (
  options: Partial<BuyBoxMaxRangeOptions> = {}
) => {
  return getRangeMaxYup({
    // maxValue: 1000000, // 1 million square feet
    minFieldName: 'lotSizeMin',
    ...options,
  });
};

export const getMinYearBuiltYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup({
    minValue: 1700, // year 1700
    shouldFormatNumber: false,
    ...options,
  });
};

export const getMaxYearBuiltYup = (
  options: Partial<BuyBoxMaxRangeOptions> = {}
) => {
  return getRangeMaxYup({
    maxValue: 2050, // year 2050
    minFieldName: 'yearBuiltMin',
    shouldFormatNumber: false,
    ...options,
  });
};

export const getMinNumStoriesYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup(options);
};

export const getMaxNumStoriesYup = (
  options: Partial<BuyBoxMaxRangeOptions> = {}
) => {
  return getRangeMaxYup({
    // maxValue: 99, // 99 stories
    minFieldName: 'numStoriesMin',
    ...options,
  });
};

export const getMinGarageSpacesYup = (options: BuyBoxMinRangeOptions = {}) => {
  return getRangeMinYup(options);
};

export const getMaxGarageSpacesYup = (
  options: Partial<BuyBoxMaxRangeOptions> = {}
) => {
  return getRangeMaxYup({
    // maxValue: 99, // 99 garage spaces
    minFieldName: 'garageSpacesMin',
    ...options,
  });
};

export const getPropertyTypeYup = () => {
  return yup.array().min(1, 'At least one Property Type is required');
};

export const getSelectedZipcodesYup = () => {
  return yup.array().min(1, 'At least one Zip Code is required');
};

export const buyBoxValidationSchema = yup.object().shape({
  name: getNameYup(),
  msaId: getMsaYup(),
  listingPriceMin: getMinListingPriceYup(),
  listingPriceMax: getMaxListingPriceYup(),
  bedsMin: getMinBedsYup(),
  bedsMax: getMaxBedsYup(),
  bathsMin: getMinBathYup(),
  bathsMax: getMaxBathYup(),
  glaMin: getMinGlaYup(),
  glaMax: getMaxGlaYup(),
  lotSizeMin: getMinLotSizeYup(),
  lotSizeMax: getMaxLotSizeYup(),
  yearBuiltMin: getMinYearBuiltYup(),
  yearBuiltMax: getMaxYearBuiltYup(),
  numStoriesMin: getMinNumStoriesYup(),
  numStoriesMax: getMaxNumStoriesYup(),
  garageSpacesMin: getMinGarageSpacesYup(),
  garageSpacesMax: getMaxGarageSpacesYup(),
  propertyType: getPropertyTypeYup(),
  selectedZipcodes: getSelectedZipcodesYup(),
});

export const DEFAULT_FORM_VALUES: DefaultValues<BuyBoxFormData> = {
  name: '', // i would make these null, but TS complains
  msaId: '',
  listingPriceMin: null,
  listingPriceMax: null,
  bedsMin: null,
  bedsMax: null,
  bathsMin: null,
  bathsMax: null,
  glaMin: null,
  glaMax: null,
  lotSizeMin: null,
  lotSizeMax: null,
  yearBuiltMin: null,
  yearBuiltMax: null,
  numStoriesMin: null,
  numStoriesMax: null,
  garageSpacesMin: null,
  garageSpacesMax: null,
  propertyType: Object.values(LeadFeedPropertyTypeEnum),
  excludePool: true,
  excludeRentalListings: true,
  excludeSeptic: true,
  selectedZipcodes: [],
  unSelectedZipcodes: [],
  historicalDetection: false,
  shouldSaveAsTemplate: false,
};

const getSetFilter = <SetValueType>(
  setValues: SetValueType[],
  numberOfOptions: number
): SetFilterOrNull<SetValueType> => {
  if (numberOfOptions) {
    // if all options are checked, set filter to null since all options selected has the same effect as not filtering
    if (setValues.length === numberOfOptions) {
      return null;
    }
  }

  return {
    set: setValues,
  };
};

const POOL_EXCLUDE_VALUE = 1;
const SEWER_EXCLUDE_VALUE = 'septic';
const RENTAL_EXCLUDE_VALUE = 1;

const getExcludeFilter = <ExcludeValueType>(
  excludeChecked: boolean | undefined,
  excludeValue: ExcludeValueType
): ExcludeFilterOrNull<ExcludeValueType> => {
  if (excludeChecked) {
    return {
      set: [excludeValue],
    };
  }
  return null;
};

interface BulkEditRangeConfig {
  rangeFilterName: keyof RangeFilters;
  minActive: keyof BulkEditRangeActiveFormData;
  minField: keyof BuyBoxRangeFormData;
  maxActive: keyof BulkEditRangeActiveFormData;
  maxField: keyof BuyBoxRangeFormData;
}

const getBulkEditRangeFilter = (
  formData: BulkEditBuyBoxFormData,
  config: BulkEditRangeConfig
): BulkEditRangeFilterOrNull => {
  const minActive = formData[config.minActive];
  const minValue = formData[config.minField];
  const maxActive = formData[config.maxActive];
  const maxValue = formData[config.maxField];

  // we are (or should be) setting these to a default of null (with react-hook-form defaultValues),
  // but react-hook-form won't include fields that aren't part of the form (even if they have a default value)
  const minValueNoUndefined = minValue === undefined ? null : minValue;
  const maxValueNoUndefined = maxValue === undefined ? null : maxValue;

  // if both min and max are null, the whole filter should be null
  if (minActive && maxActive) {
    if (minValueNoUndefined === null && maxValueNoUndefined === null) {
      return null;
    }
  }

  const rangeFilter: BulkEditRangeFilter = {};
  if (minActive) {
    rangeFilter.min = minValueNoUndefined;
  }
  if (maxActive) {
    rangeFilter.max = maxValueNoUndefined;
  }

  return rangeFilter;
};

const getFiltersFromFormData = (formData: BuyBoxFormData): Filters => {
  return {
    propertyType: getSetFilter<PropertyTypes>(
      formData.propertyType,
      Object.keys(LeadFeedPropertyTypeEnum).length
    ),
    pool: getExcludeFilter(formData.excludePool, POOL_EXCLUDE_VALUE),
    sewer: getExcludeFilter(formData.excludeSeptic, SEWER_EXCLUDE_VALUE),
    rental: getExcludeFilter(
      formData.excludeRentalListings,
      RENTAL_EXCLUDE_VALUE
    ),
    yearBuilt: getRangeFilter(formData.yearBuiltMin, formData.yearBuiltMax),
    listPrice: getRangeFilter(
      formData.listingPriceMin,
      formData.listingPriceMax
    ),
    beds: getRangeFilter(formData.bedsMin, formData.bedsMax),
    baths: getRangeFilter(formData.bathsMin, formData.bathsMax),
    sqft: getRangeFilter(formData.glaMin, formData.glaMax),
    lotArea: getRangeFilter(formData.lotSizeMin, formData.lotSizeMax),
    noOfStories: getRangeFilter(formData.numStoriesMin, formData.numStoriesMax),
    parkingGarageCount: getRangeFilter(
      formData.garageSpacesMin,
      formData.garageSpacesMax
    ),
    isDisabled: false,
  };
};

export const createFormToNewBuyBox = (formData: BuyBoxFormData): NewBuyBox => {
  return {
    enabled: true,
    name: formData.name,
    msaId: formData.msaId,
    defaults: getFiltersFromFormData(formData),
    disabledZipcodes: formData.unSelectedZipcodes,
    historicalDetectionAfterCreated: formData.historicalDetection,
    saveAsDefaultTemplate: formData.shouldSaveAsTemplate,
  };
};

export const editFormToPatchBuyBox = (
  id: BuyBoxPatch['id'],
  formData: BuyBoxFormData
): BuyBoxPatch => {
  return {
    id,
    name: formData.name,
    defaults: getFiltersFromFormData(formData),
    disabledZipcodes: formData.unSelectedZipcodes,
  };
};

const getSetFromSetFilter = <SetValueType>(
  setFilter: SetFilterOrNull<SetValueType>,
  allValues: SetValueType[]
): SetValueType[] => {
  if (setFilter === null) {
    // null has the same effect as all values selected, but we display all values selected on the frontend
    return [...allValues];
  }
  return setFilter.set;
};

const getDefaultValuesFromFilters = (
  filters: Filters
): DefaultValues<BuyBoxFormData> => {
  return {
    listingPriceMin: getMinFromRangeFilter(filters.listPrice),
    listingPriceMax: getMaxFromRangeFilter(filters.listPrice),
    bedsMin: getMinFromRangeFilter(filters.beds),
    bedsMax: getMaxFromRangeFilter(filters.beds),
    bathsMin: getMinFromRangeFilter(filters.baths),
    bathsMax: getMaxFromRangeFilter(filters.baths),
    glaMin: getMinFromRangeFilter(filters.sqft),
    glaMax: getMaxFromRangeFilter(filters.sqft),
    lotSizeMin: getMinFromRangeFilter(filters.lotArea),
    lotSizeMax: getMaxFromRangeFilter(filters.lotArea),
    yearBuiltMin: getMinFromRangeFilter(filters.yearBuilt),
    yearBuiltMax: getMaxFromRangeFilter(filters.yearBuilt),
    numStoriesMin: getMinFromRangeFilter(filters.noOfStories),
    numStoriesMax: getMaxFromRangeFilter(filters.noOfStories),
    garageSpacesMin: getMinFromRangeFilter(filters.parkingGarageCount),
    garageSpacesMax: getMaxFromRangeFilter(filters.parkingGarageCount),
    propertyType: getSetFromSetFilter(
      filters.propertyType,
      Object.values(LeadFeedPropertyTypeEnum)
    ),
    excludePool: getIsExcludedFromexcludeFilter(filters.pool),
    excludeRentalListings: getIsExcludedFromexcludeFilter(filters.rental),
    excludeSeptic: getIsExcludedFromexcludeFilter(filters.sewer),
  };
};

export const buyBoxToFormDefaultValues = (
  buyBox: BuyBox
): DefaultValues<BuyBoxFormData> => {
  return {
    name: buyBox.name,
    msaId: buyBox.msaId,
    selectedZipcodes: buyBox.enabledZipcodes,
    unSelectedZipcodes: buyBox.disabledZipcodes,
    ...getDefaultValuesFromFilters(buyBox.defaults),
  };
};

export const templateToFormDefaultValues = (
  template: Template
): DefaultValues<BuyBoxFormData> => {
  return {
    templateId: template.id,
    shouldSaveAsTemplate: false,
    historicalDetection: true,
    ...getDefaultValuesFromFilters(template.template),
  };
};

export const buyBoxListToDefaultValues = (
  buyBoxList: BuyBoxListItem[]
): DefaultValues<BulkEditBuyBoxFormData> => {
  return {
    collectionIds: buyBoxList.map((buyBoxListItem) => buyBoxListItem.id),
  };
};

export const BULK_EDIT_FIELD_GROUPS: BulkEditRangeConfig[] = [
  {
    rangeFilterName: 'yearBuilt',
    minActive: 'yearBuiltMinActive',
    minField: 'yearBuiltMin',
    maxActive: 'yearBuiltMaxActive',
    maxField: 'yearBuiltMax',
  },
  {
    rangeFilterName: 'listPrice',
    minActive: 'listingPriceMinActive',
    minField: 'listingPriceMin',
    maxActive: 'listingPriceMaxActive',
    maxField: 'listingPriceMax',
  },
  {
    rangeFilterName: 'beds',
    minActive: 'bedsMinActive',
    minField: 'bedsMin',
    maxActive: 'bedsMaxActive',
    maxField: 'bedsMax',
  },
  {
    rangeFilterName: 'baths',
    minActive: 'bathsMinActive',
    minField: 'bathsMin',
    maxActive: 'bathsMaxActive',
    maxField: 'bathsMax',
  },
  {
    rangeFilterName: 'baths',
    minActive: 'bathsMinActive',
    minField: 'bathsMin',
    maxActive: 'bathsMaxActive',
    maxField: 'bathsMax',
  },
  {
    rangeFilterName: 'sqft',
    minActive: 'glaMinActive',
    minField: 'glaMin',
    maxActive: 'glaMaxActive',
    maxField: 'glaMax',
  },
  {
    rangeFilterName: 'lotArea',
    minActive: 'lotSizeMinActive',
    minField: 'lotSizeMin',
    maxActive: 'lotSizeMaxActive',
    maxField: 'lotSizeMax',
  },
  {
    rangeFilterName: 'noOfStories',
    minActive: 'numStoriesMinActive',
    minField: 'numStoriesMin',
    maxActive: 'numStoriesMaxActive',
    maxField: 'numStoriesMax',
  },
  {
    rangeFilterName: 'parkingGarageCount',
    minActive: 'garageSpacesMinActive',
    minField: 'garageSpacesMin',
    maxActive: 'garageSpacesMaxActive',
    maxField: 'garageSpacesMax',
  },
];

export const BULK_EDIT_FIELD_GROUP_MAP = BULK_EDIT_FIELD_GROUPS.reduce(
  (accum, config) => {
    const { rangeFilterName, ...restConfig } = config;
    accum[rangeFilterName] = restConfig;
    return accum;
  },
  {} as Record<keyof RangeFilters, Omit<BulkEditRangeConfig, 'rangeFilterName'>>
);

export const bulkEditFormToBulkEditPatch = (
  formData: BulkEditBuyBoxFormData
): BulkEditPatch => {
  return {
    ids: formData.collectionIds,
    default: BULK_EDIT_FIELD_GROUPS.reduce<Partial<BulkEditRangeFilters>>(
      (accum, config) => {
        // if either are active, generate the bulk range
        if (formData[config.minActive] || formData[config.maxActive]) {
          accum[config.rangeFilterName] = getBulkEditRangeFilter(
            formData,
            config
          );
        }
        return accum;
      },
      {}
    ),
  };
};
