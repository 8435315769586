import { CompFields, PropertyStateFields } from '@hcs/types';

export const APPRAISAL_REVIEW_PROPERTY_STATE_FIELDS = [
  PropertyStateFields.propertyType,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
  PropertyStateFields.yearBuilt,
  PropertyStateFields.lotSize,
  PropertyStateFields.garageType,
  PropertyStateFields.pool,
  PropertyStateFields.basement,
  PropertyStateFields.stories,
];

export const APPRAISAL_REVIEW_COMP_FIELDS = [
  CompFields.similarity,
  CompFields.distance,
  PropertyStateFields.currentStatus,
  PropertyStateFields.currentStatusDate,
  PropertyStateFields.currentPrice,
  PropertyStateFields.currentPricePerSqFt,
  PropertyStateFields.bedrooms,
  PropertyStateFields.bathrooms,
  PropertyStateFields.livingArea,
];
