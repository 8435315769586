import { useMemo } from 'react';

import { AppSlugs } from '@hcs/types';

import { checkAppAccess } from '../utils/appAccess.utils';

import { useAccount } from './useAccount';
import { useAppConfig } from './useAppConfig';
import { useAuthDevToolsSlice } from './useAuthDevToolsSlice';

export const useCheckAppAccess = (appSlug: AppSlugs) => {
  const {
    state: { disabledApps },
  } = useAuthDevToolsSlice();
  const accountQuery = useAccount();
  const appConfig = useAppConfig(appSlug);
  const data: boolean | undefined = useMemo(() => {
    // Dev tool to mock app permissions
    if (disabledApps[appSlug]) {
      return false;
    } else if (accountQuery.isSuccess && accountQuery.data) {
      return checkAppAccess(accountQuery.data, appConfig);
    } else {
      return undefined;
    }
  }, [accountQuery, appConfig, appSlug, disabledApps]);
  return {
    ...accountQuery,
    data,
  };
};
