import {
  getIsHcsConsoleEnabled,
  getIsHcsConsoleEnabledVerbose,
} from './utils/hcsConsole.utils';
import { PFX } from './constants';
export * from './features';

export const hcsConsole = {
  // Explicit any because that is how the native logging methods are typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: (...args: any) => {
    if (getIsHcsConsoleEnabled()) {
      console.log(PFX, ...args);
    }
  },
  // Explicit any because that is how the native logging methods are typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logVerbose: (...args: any) => {
    if (getIsHcsConsoleEnabledVerbose()) {
      console.log(PFX, ...args);
    }
  },
  // Explicit any because that is how the native logging methods are typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn: (...args: any) => {
    if (getIsHcsConsoleEnabled()) {
      console.warn(PFX, ...args);
    }
  },
  // Explicit any because that is how the native logging methods are typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (...args: any) => {
    if (getIsHcsConsoleEnabled()) {
      console.error(PFX, ...args);
    }
  },
};
