import { ListingStatus,ListingStatusCerberus } from '@hcs/types';

const LISTING_STATUS_PG_TO_CERBERUS: {
  [key in ListingStatus]: ListingStatusCerberus;
} = {
  [ListingStatus.SOLD]: ListingStatusCerberus.Closed,
  [ListingStatus.PENDING]: ListingStatusCerberus.Pending,
  [ListingStatus.LEASED]: ListingStatusCerberus.Closed,
  [ListingStatus.UNKNOWN]: ListingStatusCerberus.Closed,
  [ListingStatus.CONTINGENT]: ListingStatusCerberus.ActiveUnderContract,
  [ListingStatus.ACTIVE]: ListingStatusCerberus.Active,
  [ListingStatus.CLOSED]: ListingStatusCerberus.Closed,
  [ListingStatus.WITHDRAWN]: ListingStatusCerberus.Withdrawn,
  [ListingStatus.DELETED]: ListingStatusCerberus.Delete,
  [ListingStatus.COMING_SOON]: ListingStatusCerberus.ComingSoon,
  [ListingStatus.CANCELLED]: ListingStatusCerberus.Canceled,
  [ListingStatus.EXPIRED]: ListingStatusCerberus.Expired,
};

// Only used by AEXP until the switch to cerberus
export const listingStatusPgToCerberus = (
  listingStatusPg: ListingStatus | null | undefined
): ListingStatusCerberus | null | undefined =>
  listingStatusPg && LISTING_STATUS_PG_TO_CERBERUS[listingStatusPg];
