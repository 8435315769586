import { AppSlugs } from '../auth';
import {
  LegacyRole,
  Organization,
  User,
  UserApplication,
} from '../auth/Account.types';
import { SortType } from '../Sort.types';

export type ClientFacingRoleName = 'Admin' | 'User' | 'Broker';

export enum OrgUserStatus {
  Active = 'Active',
  Invited = 'Invited',
}

export interface OrgInvitation {
  accepted: boolean;
  applications: unknown[];
  companyName: string | null;
  createdOn: string;
  creatorId: number;
  email: string;
  emailParams: unknown | null;
  firstName: string | null;
  hasSfdcContract: boolean;
  id: number;
  lastActive: null; // this is hardcoded as Null on the BE
  lastName: string | null;
  organizationId: number;
  permissions: unknown[];
  roleName: ClientFacingRoleName;
  roles: LegacyRole[];
  status: OrgUserStatus.Invited; // this is hardcoded to "Invited" on the BE
  whitelist: boolean;
}

export interface RoleAssignment {
  createdOn: string;
  id: number;
  organization: Organization;
  role: LegacyRole;
  roleId: LegacyRole['id'];
  user: User;
  userId: User['id'];
}
export interface OrgUser extends User {
  roleAssignments: RoleAssignment[];
  roleName: ClientFacingRoleName;
  status: OrgUserStatus.Active; // this is hardcoded to "Active" on the BE
}
export type OrgUserOrInvitation = OrgInvitation | OrgUser;

export interface RoleAssignmentUpdate {
  roleId: RoleAssignment['id'];
}

export interface UserApplicationUpdateExternal {
  applicationId: UserApplication['applicationId'];
  state: UserApplication['state'];
  appSlug: AppSlugs;
}

export interface EditUserRolePayload {
  roleAssignments: RoleAssignmentUpdate[];
  applications?: UserApplicationUpdateExternal[];
}

export interface EditUserOrgPermissionsPayload {
  roleAssignments?: RoleAssignmentUpdate[];
  applications?: UserApplicationUpdateExternal[];
}

export interface EditUserOrgPermissionsPayloadRequest
  extends EditUserOrgPermissionsPayload {
  id: number;
}

export type OrgUserSortableFields = Extract<
  keyof OrgUserOrInvitation,
  'email' | 'firstName' | 'lastName' | 'lastActive' | 'roleName' | 'status'
>;

export type OrgUserSort = SortType<OrgUserSortableFields>;

export interface OrgUsersResponse {
  invitationsCount: number;
  usersCount: number;
  totalResults: number;
  page: number;
  totalPages: number;
  objects: OrgUserOrInvitation[];
}
export interface ResendInvitationResponse {
  acknowledgmentCode: string;
  message: string;
}
// FE Defined type for managing user app access
export type UserApplicationAccess = Partial<
  Record<
    AppSlugs,
    | {
        appSlug: AppSlugs;
        applicationId: number;
        state: 'allow' | 'deny';
      }
    | undefined
  >
>;

export interface CreateInvitationPayload {
  firstName: string;
  lastName: string;
  email: string;
  applications?: UserApplicationUpdateExternal[];
  roles?: {
    id: LegacyRole['id'];
  }[];
  sendEmail: true;
  organizationId: Organization['id'];
}

export type BulkOrgInvitation = Pick<
  OrgInvitation,
  | 'accepted'
  | 'companyName'
  | 'createdOn'
  | 'creatorId'
  | 'email'
  | 'emailParams'
  | 'firstName'
  | 'hasSfdcContract'
  | 'id'
  | 'lastName'
  | 'organizationId'
  | 'whitelist'
> & {
  code: string;
  url: string;
};
export type BulkInvitationResponse = BulkOrgInvitation[];

export type UserApplicationPermissionsForm = UserApplicationAccess & {
  orderManagerRoles: RoleAssignmentUpdate[] | undefined;
};

export interface CheckInvitationCodeResponse {
  accepted: boolean;
  applications: [];
  code: string;
  companyName: string;
  createdOn: string;
  email: string;
  emailParams: string | null;
  firstName: string;
  hasSfdcContact: boolean;
  id: number;
  isExistingUser: boolean;
  lastName: string;
  permissions: [];
  roles: HcsAdminOrgInvitationRole[];
  url: string;
  whitelist: boolean;
}

export enum OrgUserFilterIds {
  status = 'status',
}

export interface OrgUserFilters {
  [OrgUserFilterIds.status]?: OrgUserStatus;
}
export interface UseQueriesParams {
  page?: number;
  itemsPerPage?: number;
  search?: string | null;
  orderBy: SortType<OrgUserSortableFields> | null;
}

export interface HcsAdminOrgInvitationRole {
  createdBy: string | null;
  createdOn: string | null;
  deletedBy: string | null;
  deletedOn: string | null;
  description: string | null;
  id: number;
  isDeleted: boolean;
  lastUpdatedBy: string | null;
  lastUpdatedOn: string | null;
  name: string;
}
