import React from 'react';
import classNames from 'classnames';

import styles from './QuickFilterButton.module.css';

export interface QuickFilterButtonProps {
  label: string;
  active: boolean;
  dataHcName: string;
  onClick: () => void;
}

export const QuickFilterButton = ({
  label,
  active,
  dataHcName,
  ...props
}: QuickFilterButtonProps) => {
  return (
    <button
      data-hc-name={dataHcName}
      {...props}
      className={classNames(styles.QuickFilterButton, {
        [styles.active]: active,
      })}
    >
      {label}
    </button>
  );
};
