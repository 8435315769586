import { AppSlugs } from '@hcs/types';
import { AppConfigExtended } from '@hcs/types';

import { useAppConfig } from './useAppConfig';
import { useCheckAppAccess } from './useCheckAppAccess';

export const useAppConfigExtended = (
  appSlug: AppSlugs
): AppConfigExtended | undefined => {
  const { data: hasAccess } = useCheckAppAccess(appSlug);
  const appConfig = useAppConfig(appSlug);
  const data: AppConfigExtended | undefined = appConfig
    ? {
        hasAccess,
        appConfig,
      }
    : undefined;
  return data;
};
