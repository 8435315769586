import {
  ReportSummaryOrderItemsCellConfig,
  ReportSummaryOrderItemsTableOptions,
} from '@hcs/types';

import { REPORT_SUMMARY_ORDER_ITEMS_ADDRESS_CONFIG } from './Address';
import { REPORT_SUMMARY_ORDER_ITEMS_CITY_CONFIG } from './City';
import { REPORT_SUMMARY_ORDER_ITEMS_COMPLETED_DATE_CONFIG } from './CompletedDate';
import { REPORT_SUMMARY_ORDER_ITEMS_FILE_ID_CONFIG } from './FileId';
import { REPORT_SUMMARY_ORDER_ITEMS_STATE_CONFIG } from './State';
import { REPORT_SUMMARY_ORDER_ITEMS_ZIP_CONFIG } from './Zip';

export const REPORT_SUMMARY_ORDER_ITEMS_TABLE_CELLS_CONFIG: {
  [key in ReportSummaryOrderItemsTableOptions]: ReportSummaryOrderItemsCellConfig;
} = {
  [ReportSummaryOrderItemsTableOptions.completedDate]:
    REPORT_SUMMARY_ORDER_ITEMS_COMPLETED_DATE_CONFIG,
  [ReportSummaryOrderItemsTableOptions.fileId]:
    REPORT_SUMMARY_ORDER_ITEMS_FILE_ID_CONFIG,
  [ReportSummaryOrderItemsTableOptions.address]:
    REPORT_SUMMARY_ORDER_ITEMS_ADDRESS_CONFIG,
  [ReportSummaryOrderItemsTableOptions.city]:
    REPORT_SUMMARY_ORDER_ITEMS_CITY_CONFIG,
  [ReportSummaryOrderItemsTableOptions.state]:
    REPORT_SUMMARY_ORDER_ITEMS_STATE_CONFIG,
  [ReportSummaryOrderItemsTableOptions.zip]:
    REPORT_SUMMARY_ORDER_ITEMS_ZIP_CONFIG,
};
