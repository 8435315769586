import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderOutlet } from '@hcs/design-system';
import { APP_CONFIG_PORTFOLIO, VIEW_PATHS_PORTFOLIO } from '@hcs/hc-products';
import { build404RedirectRoute } from '@hcs/utils';

import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { AllPortfoliosPageNav } from '../navigation/AllPortfoliosPageNav';
import { NewPortfolioPageNav } from '../navigation/NewPortfolioPageNav';
import { PortfolioNav } from '../navigation/PortfoliosNav';
import {
  PortfolioDashboard,
  PortfolioPage,
  PortfolioSetupPage,
} from '../pages';
import UploadPropertiesPage from '../pages/UploadPropertiesPage';

import styles from './PortfolioPageCommon.module.css';

const PortfolioRouteElement = () => (
  <>
    <PortfolioNav />
    <AllPortfoliosPageNav />
    <LayoutContent>
      <PortfolioPage />
    </LayoutContent>
  </>
);

export const PORTFOLIO_ROUTE_OBJECT: RouteObject = {
  path: APP_CONFIG_PORTFOLIO.rootPath,
  element: (
    <PrivateApplicationPageRedirect>
      <LayoutGlobalHeaderOutlet header={<SolutionsPlatformGlobalHeader />} />
    </PrivateApplicationPageRedirect>
  ),
  children: [
    {
      path: '',
      element: (
        <LayoutContent noPadding>
          <PortfolioDashboard />
        </LayoutContent>
      ),
    },

    {
      path: VIEW_PATHS_PORTFOLIO.NEW_PORTFOLIO,
      element: (
        <>
          <PortfolioNav />
          <NewPortfolioPageNav />
          <LayoutContent noPadding />
        </>
      ),
      children: [
        {
          path: '',
          element: (
            <Navigate to={VIEW_PATHS_PORTFOLIO.PORTFOLIO_SETUP} replace />
          ),
        },
        {
          path: `${VIEW_PATHS_PORTFOLIO.PORTFOLIO_SETUP}`,
          element: (
            <LayoutContent>
              <PortfolioSetupPage className={styles.PageTitle} />
            </LayoutContent>
          ),
        },
        {
          path: `${VIEW_PATHS_PORTFOLIO.UPLOAD}`,
          element: (
            <LayoutContent>
              <UploadPropertiesPage className={styles.PageTitle} />
            </LayoutContent>
          ),
        },
      ],
    },
    {
      path: VIEW_PATHS_PORTFOLIO.ALL_PORTFOLIOS,
      element: (
        <>
          <PortfolioNav />
          <AllPortfoliosPageNav />
          <LayoutContent noPadding />
        </>
      ),
    },
    {
      path: VIEW_PATHS_PORTFOLIO.PORTFOLIO,
      element: <Outlet />,
      children: [
        {
          element: <PortfolioRouteElement />,
          path: ``,
        },
        {
          element: <PortfolioRouteElement />,
          path: VIEW_PATHS_PORTFOLIO.PORTFOLIO_DOWNLOAD_UPDATED_ASSETS.replace(
            `${VIEW_PATHS_PORTFOLIO.PORTFOLIO}/`,
            ''
          ),
        },
      ],
    },
    build404RedirectRoute(APP_CONFIG_PORTFOLIO.rootPath),
  ],
};

export const routes = (): RouteObject[] => {
  const routeObjects: RouteObject[] = [PORTFOLIO_ROUTE_OBJECT];
  return routeObjects;
};
