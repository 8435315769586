import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CerberusStatsFieldConfig,
  CerberusStatsFields,
  CerberusStatsProps,
  CerberusStatsTableCellProps,
  CerberusStatsTableHeaderCellProps,
  CerberusStatsType,
} from '@hcs/types';
import { formatPercentD3, getD3PercentFormat } from '@hcs/utils';

import { logValueUnsupportedWarning } from '../../utils/cerberusStatsConfig.utils';

const D3_PERCENT_FORMAT_CONFIG = { precision: 1 };
const D3_FORMAT_STR = getD3PercentFormat(D3_PERCENT_FORMAT_CONFIG);

const FIELD = CerberusStatsFields.grossYieldMedian;
type FieldConfig = CerberusStatsFieldConfig<typeof FIELD>;
const label = 'Gross Yield Median';
const labelShort = 'Gross Yield Median';
const getValue: FieldConfig['getValue'] = (cerberusStats) => {
  if (!cerberusStats) {
    return undefined;
  }
  if (
    cerberusStats.type !== CerberusStatsType.ListingStats &&
    cerberusStats.type !== CerberusStatsType.RentalListingStats
  ) {
    return cerberusStats?.hcri?.grossYieldMedian;
  } else {
    logValueUnsupportedWarning(FIELD, cerberusStats);
    return undefined;
  }
};
const formatValue: FieldConfig['formatValue'] = (cerberusStats) =>
  formatPercentD3(getValue(cerberusStats), D3_PERCENT_FORMAT_CONFIG);

// Component for displaying a field from a schema
const Display = ({ cerberusStats }: CerberusStatsProps) => {
  return <>{formatValue(cerberusStats)}</>;
};

const HeaderCell = (props: CerberusStatsTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{label}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  cerberusStats,
  ...tableCellProps
}: CerberusStatsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display cerberusStats={cerberusStats} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const definition =
  'Median gross yield for the MSA. Gross_yield = rental AVM * 12 / AVM.';

export const GROSS_YIELD_MEDIAN_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  getValue,
  formatValue,
  HeaderCell,
  ContentCell,
  Display,
  d3FormatStr: D3_FORMAT_STR,
  definition,
  filterConfig: {
    isPositive: false,
    decimalPlaces: 1,
    isPercent: true,
  },
};
