import { LayerProps } from 'react-map-gl';
import { WebMercatorViewport } from '@deck.gl/core/typed';
import { Units } from '@turf/helpers';
import { Geometry } from 'geojson';

import { GeoLocation } from '../cerberus';
import { PreferencesKeys } from '../huell';

import {
  BoundsInfo,
  Heatmap,
  Markers,
  ViewportState,
} from './HcMapLayers.types';
import { MapStyles } from './MapStyles.types';

export interface MoveMapProps {
  longitude: number;
  latitude: number;
  zoom?: number;
  height?: number;
  width?: number;
}

export interface FitGeoLocationsReturn {
  coords: { lng: number; lat: number };
  viewport: WebMercatorViewport;
}

export enum ControlPosition {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}

export interface ControlConfig {
  position: ControlPosition;
}

export enum GeometryTypesEnum {
  CIRCLE = 'Circle',
  RECTANGLE = 'Rectangle',
  POLYGON = 'Polygon',
}

export interface FeatureProperties {
  shape: GeometryTypesEnum;
  drawType: DrawType;
  units?: Units;
  radius?: number;
}
export declare type DrawType = 'radius' | 'custom';

export interface TileCoords {
  x: number;
  y: number;
  zoom: number;
}

export interface LngLatObject {
  lng: number;
  lat: number;
}

export interface MapBounds {
  ne: LngLatObject;
  sw: LngLatObject;
}

/*
 * Creating ReactMapGlBounds type as it's a more specific and correct type (as a typescript tuple) as
 * the built in type is number[][] which is an array of number arrays of any length which is less specific
 * ReactMapGlBounds represents [[lon, lat], [lon, lat]] as the south west and north east corners of the bounds
 */
export type ReactMapGlLngLatPair = [number, number];
export type ReactMapGlBounds = [ReactMapGlLngLatPair, ReactMapGlLngLatPair];

export interface Viewport {
  latitude: number;
  longitude: number;
  zoom: number;
  width?: number;
  height?: number;
}

export type ViewportInitialized = Required<Viewport>;

export interface MarkerOffset {
  offsetTop: number;
  offsetLeft: number;
}

export interface MarkerAnimationStep {
  offset: MarkerOffset;
}

export type TileCoord = {
  x: number;
  y: number;
  z: number;
};

export interface MapOutlineConfig {
  id: string;
  geom: Geometry | undefined;
  layerStyle?: LayerProps[];
}

export interface FitBoundsPayload {
  mapId: string;
  coords: Omit<GeoLocation, 'precision'>[];
  padding?: number;
  /**
   * A string that represents when the coordinates
   * The map will re-fit bounds when the fitId changes
   */
  fitId: string;
}

export interface HcMapState {
  // Optionally save map state to preferences as it changes
  uiPreferencesKey?:
    | PreferencesKeys.CompsMapSale
    | PreferencesKeys.CompsMapRental;
  /** Id of the current fitBounds prop */
  fitId?: string;
  viewport: ViewportState;
  mapStyle: MapStyles;
  heatmap: Heatmap;
  markers: Markers;
  boundsInfo?: BoundsInfo;
}

export interface HcMapSliceState {
  [key: string]: HcMapState;
}

export interface HcMapPartialAppState {
  map: HcMapSliceState;
}
