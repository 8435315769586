import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderChildren } from '@hcs/design-system';
import { AppAccessDevTool } from '@hcs/dev-tools';
import { APP_CONFIG_USER_ADMIN, VIEW_PATHS_USER_ADMIN } from '@hcs/hc-products';
import { RedirectWithSearchParams } from '@hcs/routing';
import { AppSlugs } from '@hcs/types';
import { build404RedirectRoute } from '@hcs/utils';

import { NonSelfServicePageRedirect } from '../../../auth-redirects/NonSelfServicePageRedirect';
import { PrivateApplicationAccessRedirect } from '../../../auth-redirects/PrivateApplicationAccessRedirect';
import { PrivatePageRedirect } from '../../../auth-redirects/PrivatePageRedirect';
import { AdminNav } from '../../../navigation/AdminNav/AdminNav';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { UserAdminNav } from '../navigation/UserAdminNav/UserAdminNav';
import {
  ApiKeysPage,
  ApiSubscriptionsPage,
  UserChangePasswordPage,
  UserContactInfoPage,
  UserReportPreferencesPage,
  UserUsagePage,
} from '../pages';
import { UserExperiencePreferencesPage } from '../pages/UserExperiencePreferencesPage';

const USER_ADMIN_ROOT_PATH = APP_CONFIG_USER_ADMIN.rootPath;
export const routes = (): RouteObject[] => [
  {
    path: USER_ADMIN_ROOT_PATH,
    element: (
      <PrivatePageRedirect>
        <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
          <AdminNav />
          <UserAdminNav />
          <LayoutContent />
          <AppAccessDevTool />
        </LayoutGlobalHeaderChildren>
      </PrivatePageRedirect>
    ),
    children: [
      {
        path: VIEW_PATHS_USER_ADMIN.USER_USAGE,
        element: <UserUsagePage />,
      },
      {
        path: VIEW_PATHS_USER_ADMIN.API_KEYS,
        element: (
          <PrivateApplicationAccessRedirect appSlug={AppSlugs.DataExplorer}>
            <ApiKeysPage />
          </PrivateApplicationAccessRedirect>
        ),
      },
      {
        path: VIEW_PATHS_USER_ADMIN.API_SUBSCRIPTIONS,
        element: (
          <PrivateApplicationAccessRedirect appSlug={AppSlugs.DataExplorer}>
            <ApiSubscriptionsPage />
          </PrivateApplicationAccessRedirect>
        ),
      },
      {
        path: VIEW_PATHS_USER_ADMIN.USER_CONTACT_INFO,
        element: <UserContactInfoPage />,
      },
      {
        path: VIEW_PATHS_USER_ADMIN.USER_CHANGE_PASSWORD,
        element: <UserChangePasswordPage />,
      },
      {
        path: USER_ADMIN_ROOT_PATH,
        element: <Navigate to={VIEW_PATHS_USER_ADMIN.USER_USAGE} replace />,
      },
      {
        // ONLY ACCESSIBLE BY SELF-SERVICE USERS SINCE THIS PAGE MODIFIES ORG PREFERENCES
        path: VIEW_PATHS_USER_ADMIN.REPORT_PREFERENCES,
        element: (
          <NonSelfServicePageRedirect>
            <UserReportPreferencesPage />
          </NonSelfServicePageRedirect>
        ),
      },
      build404RedirectRoute(USER_ADMIN_ROOT_PATH),
      {
        path: VIEW_PATHS_USER_ADMIN.USER_EXPERIENCE_PREFERENCES,
        element: (
          <PrivateApplicationAccessRedirect appSlug={AppSlugs.PropertyExplorer}>
            <UserExperiencePreferencesPage />
          </PrivateApplicationAccessRedirect>
        ),
      },
    ],
  },
  {
    path: VIEW_PATHS_USER_ADMIN.LEGACY_PROFILE,
    element: (
      <RedirectWithSearchParams
        path={`${USER_ADMIN_ROOT_PATH}/${VIEW_PATHS_USER_ADMIN.USER_CONTACT_INFO}`}
      />
    ),
  },
  {
    path: VIEW_PATHS_USER_ADMIN.LEGACY_API_SETTINGS,
    element: (
      <RedirectWithSearchParams
        path={`${USER_ADMIN_ROOT_PATH}/${VIEW_PATHS_USER_ADMIN.API_KEYS}`}
      />
    ),
  },
  {
    path: VIEW_PATHS_USER_ADMIN.LEGACY_USAGE,
    element: (
      <RedirectWithSearchParams
        path={`${USER_ADMIN_ROOT_PATH}/${VIEW_PATHS_USER_ADMIN.USER_USAGE}`}
      />
    ),
  },
];
