import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItem } from '@hcs/types';
import { ReportSummaryOrderItemsCellContentProps } from '@hcs/types';

interface Params {
  tableHeaderName: string;
  CellContentComponent: React.ElementType<ReportSummaryOrderItemsCellContentProps>;
}

export const createReportSummaryOrderItemsTableCellConfig = ({
  CellContentComponent,
  tableHeaderName,
}: Params) => {
  const Display = ({ orderItem }: ReportSummaryOrderItemsCellContentProps) => (
    <CellContentComponent orderItem={orderItem} />
  );

  const HeaderCell = ({ ...tableHeaderCellProps }) => {
    return (
      <TableHeaderCell {...tableHeaderCellProps}>
        {tableHeaderName}
      </TableHeaderCell>
    );
  };

  const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
    isTableHeaderCell: true,
  };

  HeaderCell.defaultProps = defaultHeaderCellProps;

  const ContentCell = ({
    orderItem,
    ...tableCellProps
  }: {
    orderItem: OrderItem;
  }) => {
    return (
      <TableCell {...tableCellProps}>
        <Display orderItem={orderItem} />
      </TableCell>
    );
  };

  const defaultContentProps: Partial<TableCellProps> = {
    isTableCell: true,
  };

  ContentCell.defaultProps = defaultContentProps;

  return {
    HeaderCell,
    ContentCell,
    label: tableHeaderName,
  };
};
