import { MlsState } from '../cerberus';

export enum PortfolioParamsIds {
  PortfolioId = 'portfolioId',
}
export interface PortfolioPageParams {
  [PortfolioParamsIds.PortfolioId]: string;
}

export enum PortfolioFields {
  AssetCount = 'assetCount',
  Columns = 'columns',
  Config = 'config',
  DateCreated = 'dateCreated',
  Name = 'name',
  PortfolioId = 'portfolioId',
  UploadStatus = 'uploadStatus',
}

export type PortfolioTableColumns =
  | 'ltv'
  | 'closingDate'
  | 'closingPrice'
  | 'lastUpdate'
  | 'mlsStatus'
  | 'avm'
  | 'loanContractDate'
  | 'interestRate'
  | 'yearBuilt'
  | 'listingPrice'
  | 'listingDate';

export type PortfolioMechanism = 'EMAIL';

export type MlsStatus = Exclude<MlsState, MlsState.Unknown>;

export enum PortfolioConfigFields {
  Events = 'events',
  Notification = 'notification',
  Webhook = 'webhook',
}

export enum PortfolioNotificationFields {
  Emails = 'emails',
  Mechanism = 'mechanism',
  WebhookUrl = 'webhookUrl',
  WebhookUsername = 'username',
  WebhookPassword = 'password',
}

export enum PortfolioLtvThresholdFields {
  Operator = 'operator',
  Threshold = 'threshold',
}

export enum PortfolioLtvThresholdOperators {
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
}

export enum PortfolioAvmReferences {
  PORTFOLIO_CREATION = 'PORTFOLIO_CREATION',
  LAST_MONTH = 'LAST_MONTH',
}

export enum PortfolioAvmThresholdValueFields {
  Operator = 'operator',
  Value = 'value',
}

export enum PortfolioAvmThresholdOperators {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export enum PortfolioAvmThresholdFields {
  RelativeTo = 'relativeTo',
  Thresholds = 'thresholds',
}

export enum PortfolioEventsFields {
  LtvThresholds = 'ltvThresholds',
  Status = 'status',
  Avm = 'avm',
  Lien = 'lien',
  Nod = 'nod',
}

export interface PortfolioNotification {
  [PortfolioNotificationFields.Emails]: string[];
  [PortfolioNotificationFields.Mechanism]: PortfolioMechanism;
}

export interface PortfolioWebhook {
  [PortfolioNotificationFields.WebhookUrl]: string;
  [PortfolioNotificationFields.WebhookUsername]: string | null;
  [PortfolioNotificationFields.WebhookPassword]: string | null;
}

export interface PortfolioLtvThreshold {
  [PortfolioLtvThresholdFields.Operator]: PortfolioLtvThresholdOperators;
  [PortfolioLtvThresholdFields.Threshold]: number;
}

export interface PortfolioAvmThresholdValue {
  [PortfolioAvmThresholdValueFields.Operator]: PortfolioAvmThresholdOperators;
  [PortfolioAvmThresholdValueFields.Value]: number;
}

export interface PortfolioAvmThresholds {
  [PortfolioAvmThresholdFields.RelativeTo]: PortfolioAvmReferences;
  [PortfolioAvmThresholdFields.Thresholds]: PortfolioAvmThresholdValue[];
}
