import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Stats.module.css';

interface StatsTheme {
  Label: string;
  Value: string;
}

export interface Props {
  className?: string;
  dataHcName: string;
  divider?: boolean;
  stats: { label: ReactNode; value: ReactNode; dataHcName: string }[];
  theme?: Partial<StatsTheme>;
}

export const Stats = ({
  dataHcName,
  className,
  stats,
  theme,
  divider = false,
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Stats, className)}
    >
      {stats.map((stat, idx) => {
        const dataHcNameStat = `${dataHcName}-${stat.dataHcName}`;
        return (
          <React.Fragment key={dataHcNameStat}>
            <div data-hc-name={dataHcNameStat} className={styles.Stat}>
              <div
                data-hc-name={`${dataHcNameStat}`}
                className={classNames(styles.Label, theme?.Label)}
              >
                {stat.label}
              </div>
              <div
                data-hc-name={`${dataHcNameStat}`}
                className={classNames(styles.Value, theme?.Value)}
              >
                {stat.value}
              </div>
            </div>
            {divider &&
              (idx === 0 || (idx < stats.length - 1 && stats.length > 1)) && (
                <div className={styles.Divider} />
              )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
