import { SVGProps, useSvg } from '@hcs/hooks';

import ArrowDownCircledSvg from './chart-icons/arrow-down-circled.svg?react';
import ArrowUpCircledSvg from './chart-icons/arrow-up-circled.svg?react';
import CheckedCircledSvg from './chart-icons/checked-circled.svg?react';
import MapDrawCircleSvg from './map-draw-icons/map-draw-circle.svg?react';
import MapDrawCircleActiveSvg from './map-draw-icons/map-draw-circle-active.svg?react';
import MapDrawPolygonSvg from './map-draw-icons/map-draw-polygon.svg?react';
import MapDrawPolygonActiveSvg from './map-draw-icons/map-draw-polygon-active.svg?react';
import MapDrawRectangleSvg from './map-draw-icons/map-draw-rectangle.svg?react';
import MapDrawRectangleActiveSvg from './map-draw-icons/map-draw-rectangle-active.svg?react';
import MapDrawTrashSvg from './map-draw-icons/map-draw-trash.svg?react';
import OmDesktopSvg from './order-manager/desktop.svg?react';
import OmEmptyListSvg from './order-manager/empty_list.svg?react';
import OmExteriorSvg from './order-manager/exterior.svg?react';
import OmInteriorExteriorSvg from './order-manager/interior-exterior.svg?react';
import AlertSvg from './alert.svg?react';
import CanaryChatSvg from './canary-chat.svg?react';
import CheckCircleSvg from './check-circle.svg?react';
import ContractSvg from './contract.svg?react';
import ExpandSvg from './expand.svg?react';
import GreenCheckSvg from './green-check.svg?react';
import GreyMinusSvg from './grey-minus.svg?react';
import HalfGreenCheckSvg from './half-green-check.svg?react';
import HighConfidenceBadgeSvg from './hc-high-confidence-badge.svg?react';
import HighConfidenceBadgeSvgDeprecated from './high-confidence-badge-deprecated.svg?react';
import HomeCircleSvg from './home_circle.svg?react';

export const AlertIcon = (props: SVGProps) => useSvg(AlertSvg, props);
export const CheckCircleIcon = (props: SVGProps) =>
  useSvg(CheckCircleSvg, props);
export const ContractIcon = (props: SVGProps) => useSvg(ContractSvg, props);
export const ExpandIcon = (props: SVGProps) => useSvg(ExpandSvg, props);
export const HighConfidenceBadgeIcon = (props: SVGProps) =>
  useSvg(HighConfidenceBadgeSvg, props);
export const HighConfidenceBadgeIconDeprecated = (props: SVGProps) =>
  useSvg(HighConfidenceBadgeSvgDeprecated, props);
export const HomeCircleIcon = (props: SVGProps) => useSvg(HomeCircleSvg, props);
export const GreenCheckIcon = (props: SVGProps) => useSvg(GreenCheckSvg, props);
export const GreyMinusIcon = (props: SVGProps) => useSvg(GreyMinusSvg, props);
export const HalfGreenCheckIcon = (props: SVGProps) =>
  useSvg(HalfGreenCheckSvg, props);
// Map Draw
export const MapDrawCircle = (props: SVGProps) =>
  useSvg(MapDrawCircleSvg, props);
export const MapDrawCircleActive = (props: SVGProps) =>
  useSvg(MapDrawCircleActiveSvg, props);
export const MapDrawRectangle = (props: SVGProps) =>
  useSvg(MapDrawRectangleSvg, props);
export const MapDrawRectangleActive = (props: SVGProps) =>
  useSvg(MapDrawRectangleActiveSvg, props);
export const MapDrawPolygon = (props: SVGProps) =>
  useSvg(MapDrawPolygonSvg, props);
export const MapDrawPolygonActive = (props: SVGProps) =>
  useSvg(MapDrawPolygonActiveSvg, props);
export const MapDrawTrash = (props: SVGProps) => useSvg(MapDrawTrashSvg, props);

// Chart Icons
export const ArrowDownCircledIcon = (props: SVGProps) =>
  useSvg(ArrowDownCircledSvg, props);
export const ArrowUpCircledIcon = (props: SVGProps) =>
  useSvg(ArrowUpCircledSvg, props);
export const CheckedCircledIcon = (props: SVGProps) =>
  useSvg(CheckedCircledSvg, props);

// Order Manager Icons
export const OmDesktopIcon = (props: SVGProps) => useSvg(OmDesktopSvg, props);
export const OmExteriorIcon = (props: SVGProps) => useSvg(OmExteriorSvg, props);
export const OmInteriorExteriorIcon = (props: SVGProps) =>
  useSvg(OmInteriorExteriorSvg, props);
export const OmEmptyListIcon = (props: SVGProps) =>
  useSvg(OmEmptyListSvg, props);

// CanaryAI Icons
export const CanaryChatIcon = (props: SVGProps) => useSvg(CanaryChatSvg, props);
