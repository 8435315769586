import {
  CompFields,
  PersonaTypesOrg,
  PreferencesKeys,
  PropertyStateFields,
  ReportFeaturesComps,
  ReportFeaturesRentalComps,
  ReportFeaturesSubject,
  UiPreferences,
} from '@hcs/types';

export const UI_PREFERENCE_TEMPLATE_COLLATERAL_INVESTOR: UiPreferences = {
  [PreferencesKeys.Persona]: PersonaTypesOrg.CollateralInvestor,
  [PreferencesKeys.ReportFeaturesSubject]: {
    [ReportFeaturesSubject.Comments]: false,
    [ReportFeaturesSubject.Sharing]: false,
    [ReportFeaturesSubject.Download]: false,
    [ReportFeaturesSubject.KeyFacts]: true,
    [ReportFeaturesSubject.Insights]: true,
    [ReportFeaturesSubject.Personalization]: false,
    [ReportFeaturesSubject.HeatMaps]: false,
  },
  [PreferencesKeys.ReportFeaturesComps]: {
    [ReportFeaturesComps.Enabled]: true,
    [ReportFeaturesComps.AddCompByMlsNumber]: true,
    [ReportFeaturesComps.SavedCompFilters]: true,
    [ReportFeaturesComps.RefreshComps]: false,
    [ReportFeaturesComps.Tags]: false,
    [ReportFeaturesComps.AdjustComps]: false,
    [ReportFeaturesComps.CustomAreaCompSearch]: false,
    [ReportFeaturesComps.AutoSelectHcSuggestedComps]: true,
    [ReportFeaturesComps.KeywordSearch]: true,
  },
  [PreferencesKeys.ReportFeaturesRentalComps]: {
    [ReportFeaturesRentalComps.Enabled]: false,
  },
  [PreferencesKeys.SubjectDataPriority]: {
    order: [
      PropertyStateFields.yearBuilt,
      PropertyStateFields.bedrooms,
      PropertyStateFields.bathrooms,
      PropertyStateFields.livingArea,
      PropertyStateFields.currentListingPrice,
    ],
    inactive: {},
  },
  [PreferencesKeys.CompTableColumns]: {
    order: [
      PropertyStateFields.yearBuilt,
      CompFields.distance,
      PropertyStateFields.lastClosePrice,
      PropertyStateFields.bedrooms,
      PropertyStateFields.bathrooms,
      PropertyStateFields.livingArea,
    ],
    inactive: {},
  },
};
