import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { ACCOUNT_QUERY_KEY, fetchAccount } from '@hcs/http-clients';
import { QUERY_KEY_ORG_SELF_SERVE_INFO } from '@hcs/organization';
import { Account, RegisterRequest } from '@hcs/types';
import { logException } from '@hcs/utils';

import { SelfServiceApi } from '../api';
type RegisterError = AxiosError<{
  status: string;
  message: string;
  errors?: Partial<{
    accepted_terms: string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  }>;
}>;
// Export hook-specific types here rather than in types file
export type SelfServiceRegisterProps = UseMutationOptions<
  Account,
  RegisterError,
  RegisterRequest,
  void
>;

const EVENT_NAME_SIGN_UP = 'user-sign-up-self-service';

export const useSelfServiceRegister = (options?: SelfServiceRegisterProps) => {
  const queryClient = useQueryClient();
  const trackEngagementEventMutation = useTrackEngagementEvent();
  return useMutation<Account, RegisterError, RegisterRequest, void>(
    async (payload) => {
      await SelfServiceApi.register(payload);
      return await fetchAccount();
    },
    {
      ...options,
      onError(error, variables) {
        // 400 is usually password not meeting requirements or something that isn't a problem to address
        if (error.response?.status !== 400) {
          logException(
            new Error(`Sign Up failed: ${error.response?.data.status}`),
            {
              email: variables.email,
              response: error.response,
            }
          );
        }
        options?.onError?.(error, variables);
        const eventName = `${EVENT_NAME_SIGN_UP}-error`;
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          eventName: eventName,
          eventData: {
            self_service_signup_error: error.response?.data.status,
            email: variables.email,
          },
        });
      },
      onSuccess(data, variables) {
        options?.onSuccess?.(data, variables);
        queryClient.invalidateQueries([QUERY_KEY_ORG_SELF_SERVE_INFO]);
        queryClient.setQueryData([ACCOUNT_QUERY_KEY], data);
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          eventName: `${EVENT_NAME_SIGN_UP}-success`,
          authOrganizationId: data.currentOrganization.id,
          authOrganizationSlug: data.currentOrganization.slug,
          authUserId: data.user.id,
          // Passing these fields directly since the useAccount hook in the scope where trackEngagementEventMutation
          // is defined won't have the updated account data yet
          email: variables.email,
        });
      },
    }
  );
};
