import {
  COMP_FIELD_ORDER_ADJUST,
  RENTAL_COMP_FIELD_ORDER_ADJUST,
} from '@hcs/property-state';
import {
  ActiveTop4CompDocument,
  ClosedTop4CompDocument,
  DateStr,
} from '@hcs/types';
import { IntraOrgReportAccessOptions } from '@hcs/types';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import {
  CompDocument,
  CompFarmUserPropertyAdjustments,
  CompFarmUserPropertyAdjustmentsApi,
  CompId,
  CompIdentifier,
  CompSchema,
  CompsFarmAdjustmentKey,
  DocumentRoles,
  HcSuggestedCompDocument,
  RentalCompDocument,
  RentalHcSuggestedCompDocument,
  SchemaIds,
  UserPropertyAdjustments,
} from '@hcs/types';
import { getDateStr } from '@hcs/utils';

export const compKeywordSearchKey = (compType: CompTypes) =>
  `compPublicRemarks-${compType}`;

export const getCompTransientDocumentId = (
  documentRole: DocumentRoles | undefined,
  compId: string | undefined
) => {
  if (documentRole && compId) {
    return `${documentRole}-${compId}`;
  } else {
    return undefined;
  }
};

export const sortCompSchemas = (comp1: CompSchema, comp2: CompSchema) =>
  (comp1.similarity.scoreAdjusted || 0) < (comp2.similarity.scoreAdjusted || 0)
    ? 1
    : (comp1.similarity.scoreAdjusted || 0) >
      (comp2.similarity.scoreAdjusted || 0)
    ? -1
    : (comp1.distance || 0) > (comp2.distance || 0)
    ? 1
    : (comp1.distance || 0) < (comp2.distance || 0)
    ? -1
    : 0;

export const sortCompDocuments = (
  comp1:
    | CompDocument
    | RentalCompDocument
    | HcSuggestedCompDocument
    | RentalHcSuggestedCompDocument
    | ActiveTop4CompDocument
    | ClosedTop4CompDocument,
  comp2:
    | CompDocument
    | RentalCompDocument
    | HcSuggestedCompDocument
    | RentalHcSuggestedCompDocument
    | ActiveTop4CompDocument
    | ClosedTop4CompDocument
) => sortCompSchemas(comp1.data, comp2.data);

const OPTIMISTIC_DOC_PREFIX = 'optimistic';

export const makeOptimisticDocumentId = (compSchema: CompSchema): string => {
  return `${OPTIMISTIC_DOC_PREFIX}-comp-${compSchema.compID}`;
};

export const isOptimisticDocumentId = (
  documentId: string | undefined
): boolean => {
  if (documentId === undefined) {
    return false;
  }
  return documentId.startsWith(OPTIMISTIC_DOC_PREFIX);
};

export const makeOptimisticDocument = (
  compSchema: CompSchema,
  documentRole: DocumentRoles.Comp | DocumentRoles.RentalComp,
  intraOrgReportAccess: IntraOrgReportAccessOptions,
  adjustments?: UserPropertyAdjustments
): CompDocument | RentalCompDocument => {
  const nowDateStr = getDateStr(new Date()) as DateStr;
  const documentId = makeOptimisticDocumentId(compSchema);
  return {
    hcAddressId: compSchema.propertyState.hcAddressId || 0,
    createdAt: nowDateStr,
    deletedAt: null,
    documentId,
    id: 0,
    role: documentRole,
    schemaId: SchemaIds.Comp,
    updatedAt: nowDateStr,
    dataRetrievedAt: nowDateStr,
    metadata: {
      activeStatus: 'active',
      creationDate: nowDateStr,
      expirationDate: '12-01-2099',
      editable: true,
      revisionID: 0,
      publicLinkUid: '',
      intraOrgReportAccess,
    },
    userPropertyAdjustments: {
      ...adjustments,
      documentID: documentId,
      documentRole,
      reportID: 0,
    },
    data: compSchema,
  };
};

export const transformCompsUserPropertyAdjustments = (
  farmDocumentAdjustments: CompFarmUserPropertyAdjustmentsApi
): CompFarmUserPropertyAdjustments => {
  return Object.entries(farmDocumentAdjustments.adjustments || {}).reduce(
    (curr: CompFarmUserPropertyAdjustments, [key, value]) => {
      // Must cast to get typing correct but types are documented in CompsFarmAdjustmentKey
      const compFarmAdjustmentKey =
        key as CompsFarmAdjustmentKey<PropertyStateFields>;
      const compID = compFarmAdjustmentKey.split('.')[1] as CompId;
      const propertyStateField = compFarmAdjustmentKey
        .split('.')
        .slice(2)
        .join('.') as PropertyStateFields;
      curr[compID] = {
        ...farmDocumentAdjustments,
        adjustments: {
          ...curr[compID]?.adjustments,
          [propertyStateField]: value,
        },
      };
      return curr;
    },
    {}
  );
};

export const checkIsHcAdjustment = (
  compField: CompFields | PropertyStateFields
) => {
  const isHcAdjustmentField =
    compField === CompFields.hcAdjustmentDate ||
    compField === CompFields.hcAdjustmentPropertyDetails ||
    compField === CompFields.hcAdjustmentDateRental ||
    compField === CompFields.hcAdjustmentPropertyDetailsRental;
  const isHcDateAdjustment =
    compField === CompFields.hcAdjustmentDate ||
    compField === CompFields.hcAdjustmentDateRental;
  return { isHcDateAdjustment, isHcAdjustmentField };
};

const HC_ADJUSTMENT_FIELDS: (PropertyStateFields | CompFields)[] = [
  CompFields.hcAdjustmentDate,
  CompFields.hcAdjustmentPropertyDetails,
  CompFields.hcAdjustmentDateRental,
  CompFields.hcAdjustmentPropertyDetailsRental,
];
export const getCompAdjustFieldOrder = (
  compIdentifier: CompIdentifier | undefined
): (PropertyStateFields | CompFields)[] => {
  if (compIdentifier?.type === 'appraisalComp') {
    // filter and replace appraisal specific fields
    const filtered: (PropertyStateFields | CompFields)[] = [];
    COMP_FIELD_ORDER_ADJUST.forEach((f) => {
      if (f === CompFields.userTotalDollarAdjustment) {
        filtered.push(CompFields.appraisalTotalDollarAdjustment);
      } else if (!HC_ADJUSTMENT_FIELDS.includes(f)) {
        filtered.push(f);
      }
    });
    return filtered;
  } else {
    return compIdentifier?.compType === CompTypes.Rental
      ? RENTAL_COMP_FIELD_ORDER_ADJUST
      : COMP_FIELD_ORDER_ADJUST;
  }
};
