import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { PhotoGroup, PhotoProp } from '@hcs/types';

import { FlexScroll } from '../../layout/FlexScroll';
import { TabOption, Tabs } from '../../tabs/Tabs/Tabs';
import {
  PhotoGrid,
  PhotoGridProps,
  PhotoGridSizeControl,
  PhotoGridSizeControlProps,
} from '../PhotoGrid';
import { PhotoViewer } from '../PhotoViewer';

import styles from './PhotosPage.module.css';

export interface PhotoPageGroup extends PhotoGroup {
  id: string;
  // Tab Label
  tabLabel: ReactNode;
}
// Use a module variable since this should not effect component rendering
let scrolling = false;
const makeSectionHtmlId = (id: string | number) => `photoGroup-${id}`;
export interface PhotosPageProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  dataHcEventSection?: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  activeTab?: string;
  tabsBefore?: TabOption<string | number>[];
  photoGroups: PhotoPageGroup[];
  photoGridSize?: PhotoGridProps['size'];
  onChangeSize?: PhotoGridSizeControlProps['onChangeComplete'];
  onToggleFullscreen?: (active: boolean) => void;
}
export const PhotosPage = ({
  photoGroups,
  activeTab: activeTabProp,
  dataHcName,
  dataHcEventSection,
  photoGridSize: photoGridSizeProp = 4,
  onChangeSize,
  onToggleFullscreen,
  tabsBefore,
}: PhotosPageProps) => {
  const [fullscreenIndex, setFullscreenIndex] = useState<null | number>(null);
  const [photoGridSize, setPhotoGidSize] = useState<number>(photoGridSizeProp);
  const [activeTab, setActiveTab] = useState<number | string | undefined>(
    activeTabProp
  );
  const handleSetFullscreenIndex = (value: number | null) => {
    onToggleFullscreen?.(!!value);
    setFullscreenIndex(value);
  };
  const { tabs, photoGroupsContent, fullscreenCarouselPhotos } = useMemo(() => {
    const tabs: TabOption<string | number>[] = [];
    const photoGroupsContent: ReactNode[] = [];
    const fullscreenCarouselPhotos: PhotoProp[] = [];
    let fullIndex = -1;
    photoGroups.forEach((photoGroup, i) => {
      tabs.push({
        label: photoGroup.tabLabel,
        value: photoGroup.id,
        content: null,
      });

      const photoGroupPhotos: PhotoProp[] = [];
      photoGroup.photos.forEach((photo) => {
        const photoIndex = fullIndex + 1;
        fullscreenCarouselPhotos.push({
          photo: photo.photo,
          metadata: photoGroup.header,
        });
        photoGroupPhotos.push({
          ...photo,
          dataHcName: `${dataHcName}-${photoIndex}`,
          onClick: () => {
            handleSetFullscreenIndex(photoIndex);
          },
        });
        fullIndex = photoIndex;
      });

      photoGroupsContent.push(
        <section
          data-photo-group-id={`${photoGroup.id}`}
          id={makeSectionHtmlId(photoGroup.id)}
          key={`photoGroup-${photoGroup.id}`}
          className={styles.Photos}
          data-hc-name={`${dataHcName}-photos-section`}
        >
          {photoGroup.header || (
            <div
              className={classNames(styles.Divider, {
                [styles.firstDivider]: i === 0,
              })}
            />
          )}
          <PhotoGrid
            dataHcName={`${dataHcName}-photo-grid`}
            size={photoGridSize}
            photos={photoGroupPhotos}
          />
        </section>
      );
    });
    return { tabs, photoGroupsContent, fullscreenCarouselPhotos };
  }, [photoGroups, photoGridSize]);
  const checkScrolling = () => scrolling;
  const scrollRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (scrollRef.current && !checkScrolling()) {
      const scrollRect = scrollRef.current.getBoundingClientRect();
      const sections = scrollRef.current.querySelectorAll(
        `[data-photo-group-id]`
      );
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if (section) {
          const rect = section.getBoundingClientRect();
          const photoGroupId = section?.getAttribute('data-photo-group-id');
          if (photoGroupId && photoGroupId !== activeTab && rect) {
            if (
              rect.top - scrollRect.top <= 0 &&
              scrollRect.top <= rect.bottom
            ) {
              requestAnimationFrame(() => setActiveTab(photoGroupId));
              break;
            }
          }
        }
      }
    }
  }, [activeTab, scrollRef]);
  useEffect(() => {
    scrollRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  const handleChangeTab = (photoGroupId: string | number) => {
    scrolling = true;
    setActiveTab(photoGroupId);
  };
  useEffect(() => {
    scrolling = true;
    const scrollIntoViewTimeout = setTimeout(() => {
      scrolling = false;
    }, 1000);
    const tabSection = scrollRef.current?.querySelector(
      `[data-photo-group-id="${activeTab}"]`
    );
    if (tabSection) {
      tabSection.scrollIntoView({ behavior: 'smooth' });
    }
    return () => {
      clearTimeout(scrollIntoViewTimeout);
    };
  }, [activeTab]);
  return (
    <>
      <FlexScroll
        ref={scrollRef}
        dataHcName={`${dataHcName}-scroll`}
        dataHcEventSection={dataHcEventSection}
        theme={{
          Scroll: styles.Scroll,
        }}
        header={{
          height: 57,
          content: (
            <Tabs
              dataHcName={`${dataHcName}-tabs`}
              className={styles.Tabs}
              tabs={tabsBefore ? [...tabsBefore, ...tabs] : tabs}
              active={activeTab || tabs[0]?.value}
              onChange={handleChangeTab}
              searchParamId="photosPage"
              persistState={false}
            >
              <PhotoGridSizeControl
                dataHcName={`${dataHcName}-size-control`}
                onChange={setPhotoGidSize}
                onChangeComplete={onChangeSize}
                value={photoGridSize}
              />
            </Tabs>
          ),
        }}
      >
        {photoGroupsContent}
      </FlexScroll>
      <PhotoViewer
        initialIndex={fullscreenIndex}
        photos={fullscreenCarouselPhotos}
        dataHcName={`${dataHcName}-fullscreen`}
        active={fullscreenIndex !== null}
        onClose={() => {
          handleSetFullscreenIndex(null);
        }}
      />
    </>
  );
};
