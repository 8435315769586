import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { Button, Card, UserAlert } from '@hcs/design-system';
import { logException, logWarning } from '@hcs/utils';

import styles from './RouterErrorBoundary.module.css';

const dataHcName = 'router-error-boundary';

interface ErrorCardProps {
  cardTitle: string;
  errorText: string;
}

const ErrorCard = ({ cardTitle, errorText }: ErrorCardProps) => {
  return (
    <div className={styles.Container}>
      <Card
        dataHcName={`${dataHcName}-loading-error-card`}
        title={cardTitle}
        className={styles.ErrorCard}
      >
        <UserAlert
          type="error"
          dataHcName={`${dataHcName}-loading-error-user-alert`}
          text={errorText}
        />
        <Button
          dataHcName={`${dataHcName}-refresh-button`}
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      </Card>
    </div>
  );
};

const isLoadingError = (error: unknown): boolean => {
  return (
    error instanceof Error &&
    (error?.message.includes('Failed to fetch dynamically imported module') ||
      error?.message.includes('Unable to preload CSS for') ||
      error?.message.includes('Importing a module script failed'))
  );
};

export const RouterErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    const errorMessage = error instanceof Error ? error.message : 'undefined';
    if (isLoadingError(error)) {
      logWarning(`RouterErrorBoundary - loading error: ${errorMessage}`);
    } else {
      logException(`RouterErrorBoundary - unhandled error: ${errorMessage}`);
    }
  }, [error]);

  if (isLoadingError(error)) {
    return (
      <ErrorCard
        cardTitle="Error loading the app"
        errorText="An error occurred loading the app. Please check your internet connection and refresh"
      />
    );
  } else {
    return (
      <ErrorCard
        cardTitle="An unhandled error occurred"
        errorText="The error has been reported. Please try refreshing."
      />
    );
  }
};
