import { useCallback, useEffect } from 'react';

import { BaseEventTypes } from '@hcs/types';

import { buildEngagementEventFromElement } from '../utils';

import { useGlobalEngagementTrackingCallbackDeps } from './useGlobalEngagementTrackingCallbackDeps';
import { useTrackEngagementEvent } from './useTrackEngagementEvent';

// Tracks input blur events
export const useTrackBlurEvents = () => {
  // Need dependent data here so the listener gets rebound with the updated data
  const callbackDeps = useGlobalEngagementTrackingCallbackDeps();
  const trackEngagementEventMutation = useTrackEngagementEvent();
  const blurHandler = useCallback((e: Event) => {
    const element = e.target as Element;
    if (
      element instanceof HTMLInputElement ||
      element instanceof HTMLTextAreaElement ||
      element instanceof HTMLSelectElement
    ) {
      const engagementEvent = buildEngagementEventFromElement(
        element,
        BaseEventTypes.Blur,
        {
          eventType: BaseEventTypes.Blur,
          eventData: {
            inputValue: element.type === 'password' ? '****' : element.value,
          },
        }
      );
      if (engagementEvent) {
        trackEngagementEventMutation.mutate(engagementEvent);
      }
    }
  }, callbackDeps);

  useEffect(() => {
    window.addEventListener('blur', blurHandler, true);
    return () => {
      window.removeEventListener('blur', blurHandler, true);
    };
  }, [blurHandler]);
};
