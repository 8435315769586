import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import {
  Dropdown,
  DropdownOptionTypeExtends,
  DropdownProps,
} from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';

import styles from './FormFields.module.css';

type FormFieldsDropdownProps<OptionValueType> = {
  name: string;
  label: string;
  isLoading?: boolean;
} & Omit<DropdownProps<OptionValueType>, 'onSelect' | 'value' | 'dataHcName'>;

const dataHcName = 'form-field-dropdown';
export const FormFieldsDropdown = <
  OptionValueType extends DropdownOptionTypeExtends
>({
  name,
  label,
  error,
  isLoading,
  className,
  options,
}: FormFieldsDropdownProps<OptionValueType>) => {
  const formContext = useFormContext();
  if (isLoading) {
    return (
      <Skeleton
        dataHcName={`${dataHcName}-${name}-skeleton`}
        type="input"
        className={className}
      />
    );
  }
  return (
    <Controller
      name={name}
      control={formContext.control}
      render={({ field }) => {
        return (
          <div className={classNames(styles.FormGroup, className)}>
            <label>{label}</label>
            <Dropdown
              options={options}
              error={error}
              dataHcName={`${dataHcName}-dropdown`}
              theme={{
                SelectContainer: styles.DropdownSelectContainer,
              }}
              value={field.value}
              onSelect={(val) => field.onChange(val)}
            />
          </div>
        );
      }}
    />
  );
};
