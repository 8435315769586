import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { LayoutBanner } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { LowToHighBandChart } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { EntryPageContainer } from '@hcs/design-system';
import { OverlayPage } from '@hcs/design-system';
import { Anchor } from '@hcs/design-system';
import { OfferNowDisclaimer } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { OfferForm, OfferFormProps } from '@hcs/lead-feed';
import { OfferSuccess } from '@hcs/lead-feed';
import { PropertySummaryHeader } from '@hcs/property-state';
import { usePropertyStateCore } from '@hcs/property-state';
import { usePropertyStateStrengthOfOffer } from '@hcs/property-state';
import { ListingStatusCerberus } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';
import { formatMoneyD3 } from '@hcs/utils';

import { useReport } from '../../hooks/useReport';
import { useSubjectPhotos } from '../../hooks/useSubjectPhotos';

import styles from './OfferFormOverlay.module.css';

const OFFER_FORM_PAGE_SUBMIT_ID = 'offer-overlay';
const PROBABILITY_ACCEPTANCE_MARKERS = [21, 20, 20, 20, 19];

const dataHcName = 'offer-form-overlay';
interface OfferFromReportIdProps extends OfferFormProps {
  reportId: ReportId;
  active: boolean;
  onClose: () => void;
  avm: number | null;
  listPrice: number | null;
  listingStatus: ListingStatusCerberus | null;
}

const OfferFormContent = ({
  avm,
  listingStatus,
  onClose,
  dataHcName,
  reportId,
  listPrice,
  dialogActive,
  onCloseDialog,
}: Omit<OfferFromReportIdProps, 'active'> & {
  dialogActive: boolean;
  onCloseDialog: VoidFunction;
  dataHcName: string;
}) => {
  const { data: reportData } = useReport(reportId);
  const hcAddressId = reportData?.addressID;
  const {
    data: propertyStateArgs,
    isInitialLoading: isLoadingPropertyStateCore,
  } = usePropertyStateCore(hcAddressId ? { hcAddressId } : undefined);
  const { data: photoData, isInitialLoading: isLoadingPhotoData } =
    useSubjectPhotos(reportId);

  const [offerAmount, setOfferAmount] = useState(listPrice);
  const [probability, setProbability] = useState<number | null>(null);

  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

  const {
    data: strengthOfOffer,
    isSuccess,
    isError,
  } = usePropertyStateStrengthOfOffer(
    hcAddressId ? { hcAddressId } : undefined,
    offerAmount
  );

  const probabilityDecimalValue =
    strengthOfOffer?.fipsProbabilityOfAccept ||
    strengthOfOffer?.msaProbabilityOfAccept;

  useEffect(() => {
    if (isSuccess)
      setProbability(
        typeof probabilityDecimalValue === 'number'
          ? probabilityDecimalValue
          : null
      );
    if (isError) setProbability(null);
  }, [probabilityDecimalValue, setProbability, isSuccess, isError]);

  // jdimattia - it complains b/c it can't do the lint dep check on the debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnOfferChange = useCallback(
    debounce((newOfferAmount) => setOfferAmount(newOfferAmount), 1000),
    [setOfferAmount]
  );

  return (
    <>
      <EntryPageContainer className={styles.EntryPageContainer}>
        <LayoutBanner
          dataHcName={`${dataHcName}-layout-banner`}
          className={styles.LayoutBanner}
        >
          <Card dataHcName={`${dataHcName}-card`} className={styles.Card}>
            <div className={styles.OfferFormContainer}>
              <div className={styles.PriceStatusContainer}>
                <div className={styles.InfoBoxContainer}>
                  <div
                    className={styles.InfoBoxLabel}
                    data-hc-name={`${dataHcName}-avm-label`}
                  >
                    HouseCanary AVM
                  </div>
                  <div
                    className={styles.InfoBoxValue}
                    data-hc-name={`${dataHcName}-avm-value`}
                  >
                    {formatMoneyD3(avm)}
                  </div>
                </div>
                <div className={styles.InfoBoxContainer}>
                  <div
                    className={styles.InfoBoxLabel}
                    data-hc-name={`${dataHcName}-list-price-label`}
                  >
                    Current List Price
                  </div>
                  <div
                    className={styles.InfoBoxValue}
                    data-hc-name={`${dataHcName}-list-price-value`}
                  >
                    {formatMoneyD3(listPrice)}
                  </div>
                </div>
                <div className={styles.InfoBoxContainer}>
                  <div
                    className={styles.InfoBoxLabel}
                    data-hc-name={`${dataHcName}-listing-status-label`}
                  >
                    Listing Status
                  </div>
                  <div
                    className={styles.InfoBoxValue}
                    data-hc-name={`${dataHcName}-listing-status-value`}
                  >
                    {listingStatus}
                  </div>
                </div>
              </div>
              <OfferForm
                theme={{
                  InputContainer: styles.InputContainer,
                  DialogInputLayout: styles.DialogInputLayout,
                }}
                onInitialAmountChange={debouncedOnOfferChange}
                onSuccess={() => setFormSubmitSuccess(true)}
                hcAddressId={hcAddressId}
                addressSlug={reportData?.slug}
                fullAddress={formatFullAddress(
                  propertyStateArgs?.propertyState?.location
                )}
                listPrice={listPrice}
                actionsPortalIdRender={OFFER_FORM_PAGE_SUBMIT_ID}
                showOfferSuccess={false}
                showDisclaimerText={false}
                hideSubmitOnSuccess
              />
            </div>
            <div className={styles.OfferProbabilityContainer}>
              <div
                className={styles.OfferProbabilityTitle}
                data-hc-name={`${dataHcName}-offer-acceptance-label`}
              >
                Initial Offer Acceptance Probability
              </div>
              <LowToHighBandChart
                tickRangePercent={PROBABILITY_ACCEPTANCE_MARKERS}
                dataHcName={`${dataHcName}-band-chart`}
                className={styles.LowToHighBandChart}
                valueAsPercent={
                  typeof probability === 'number' ? probability * 100 : null
                }
                label={'Offer Acceptance'}
                noDataLabel={'Unable to determine offer strength'}
                reverseColors
              />
              {formSubmitSuccess && (
                <div className={styles.OnSuccessContainer}>
                  <OfferSuccess dataHcName={`${dataHcName}-success`} />
                  <Anchor
                    dataHcName={`${dataHcName}-back-to-report`}
                    onClick={onClose}
                  >
                    Back to Report
                  </Anchor>
                </div>
              )}
              <div className={styles.SubmitOfferContainer}>
                <div id={OFFER_FORM_PAGE_SUBMIT_ID} />
              </div>
              <OfferNowDisclaimer
                dataHcName={`${dataHcName}-disclaimer`}
                className={styles.DisclaimerText}
              />
            </div>
          </Card>
        </LayoutBanner>
      </EntryPageContainer>
      <Dialog
        dataHcName={`${dataHcName}-property-details-dialog`}
        active={dialogActive}
        title={'Property Details'}
        onClose={onCloseDialog}
        theme={{
          DialogWrapper: styles.DialogWrapper,
        }}
      >
        <PropertySummaryHeader
          expanded="full"
          isLoading={isLoadingPropertyStateCore}
          propertyStateArgs={propertyStateArgs}
          photo={{
            url: photoData?.frontPhoto?.url,
            isLoading: isLoadingPhotoData,
          }}
        />
      </Dialog>
    </>
  );
};

export const OfferFormOverlay = ({
  reportId,
  active,
  avm,
  listPrice,
  listingStatus,
  fullAddress,
  onClose,
}: OfferFromReportIdProps) => {
  const {
    handleOpen: onDialogOpen,
    handleClose: onCloseDialog,
    active: dialogActive,
  } = useActiveState();

  return (
    <OverlayPage
      headerProps={{
        onBack: onClose,
        title: (
          <div
            className={styles.Title}
            data-hc-name={`${dataHcName}-header-title`}
          >
            Submit an offer for {fullAddress}{' '}
            <Anchor
              className={styles.PropertyDetails}
              dataHcName={`${dataHcName}-property-details`}
              onClick={() => onDialogOpen()}
            >
              Property Details
            </Anchor>
          </div>
        ),
      }}
      active={active}
      dataHcName={dataHcName}
    >
      <OfferFormContent
        dataHcName={`${dataHcName}-content`}
        reportId={reportId}
        avm={avm}
        listPrice={listPrice}
        listingStatus={listingStatus}
        onClose={onClose}
        dialogActive={dialogActive}
        onCloseDialog={onCloseDialog}
      />
    </OverlayPage>
  );
};
