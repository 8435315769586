import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import { Personalization } from '@hcs/types';

import { HuellApi } from '../api';

import { QUERY_KEY_PERSONALIZATION } from './usePersonalization';

export const useUpdatePersonalizationData = (
  options?: UseMutationOptions<
    Personalization,
    { message: string },
    Personalization
  >
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const queryClient = useQueryClient();
  return useMutation<Personalization, { message: string }, Personalization>(
    HuellApi.updatePersonalization,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QUERY_KEY_PERSONALIZATION]);
        options?.onSuccess?.(data, variables, context);
        toastOpen({
          type: 'success',
          title: 'Report branding and personal info updated',
        });
      },
    }
  );
};
