import { useAccount } from './useAccount';

export const CURRENT_ORG_QUERY_KEY = 'hcs-user-current-org';
export const useCurrentOrg = () => {
  const accountQuery = useAccount();
  return {
    ...accountQuery,
    data: accountQuery.data?.currentOrganization,
  };
};
