import { useQuery } from '@tanstack/react-query';

import { VectileLayers, VectilesStatsReturnType } from '@hcs/types';

import { VectorTilesApi } from '../api/vectorTiles.api';

export const QUERY_KEY_VECTOR_TILES_STATS = 'vector-tiles-stats';

export const useVectilesStats = (
  blockType: VectileLayers,
  integerZoom: number,
  tileRangeURLSegment: string
) => {
  return useQuery<VectilesStatsReturnType, VectilesStatsReturnType>(
    [QUERY_KEY_VECTOR_TILES_STATS, blockType, integerZoom, tileRangeURLSegment],
    async () => {
      return await VectorTilesApi.fetchVectorTilesStats(
        blockType,
        integerZoom,
        tileRangeURLSegment
      );
    }
  );
};
