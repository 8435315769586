import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './TextButton.module.css';

export interface TextButtonProps {
  /** Data attribute for automated testing */
  dataHcName: string;
  dataHcEventSection?: string;
  dataHcEventType?: string;
  dataHcEventName?: string;
  /** Class to apply to the root element */
  className?: string;
  /** Label to render in place of children */
  label?: ReactNode;
  /** Whether the button is clickable */
  disabled?: boolean;
  /** Callback when button is clicked */
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  /** Use primary styles */
  primary?: boolean;
  children?: ReactNode;
  title?: string;
  noPadding?: boolean;
  /**
   * Adds an icon to the button. Accepts a react component.
   */
  icon?: ReactNode;
  /**
   * Determines icon orientation. Defaults to `left`.
   */
  iconPosition?: 'left' | 'right';
}

export const TextButton = (props: TextButtonProps) => {
  const {
    children,
    className,
    label,
    disabled,
    onClick,
    dataHcName,
    dataHcEventSection,
    dataHcEventType,
    dataHcEventName,
    primary = true,
    title,
    noPadding = false,
    icon,
    iconPosition,
  } = props;
  return (
    <button
      className={classNames(styles.TextButton, className, {
        [styles.secondary]: !primary,
        [styles.disabled]: disabled,
        [styles.noPadding]: noPadding,
        [styles.iconRight]: icon && iconPosition === 'right',
      })}
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      data-hc-event-name={dataHcEventName}
      data-hc-event-type={dataHcEventType}
      onClick={!disabled ? onClick : undefined}
      title={title}
    >
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      {children || label}
    </button>
  );
};
