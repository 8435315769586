import {
  DocumentRoles,
  ForecastChartBlockDocument,
  ReportId,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useForecastChartBlockDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<ForecastChartBlockDocument>(
    reportId,
    DocumentRoles.ForecastChartBlock
  );
};
