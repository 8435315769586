import tilebelt from '@mapbox/tilebelt';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Maybe } from 'yup';

import { CerberusApi } from '@hcs/cerberus';
import { tileCoordsToLatLng } from '@hcs/maps';
import {
  PlaceLocation,
  PropertySpatialSearchMapDocument,
  PropertySpatialSearchMapQuery,
  PropertySpatialSearchMapQueryVariables,
  SpatialSearchCountResult,
  SpatialSearchDetailResults,
  SpatialSearchOverCountResult,
} from '@hcs/types';
import { PropertySpatialHit } from '@hcs/types';
import { logException } from '@hcs/utils';

import {
  convertSpatialHitToPropertySpatialHit,
  makeSafePropertySpatialSearchParams,
} from '../utils';

interface LabelLocation {
  labelLocation: Maybe<{
    latitude: Maybe<number> | undefined;
    longitude: Maybe<number> | undefined;
  }>;
}
const MAX_ZOOM = 23;
type HookResponseTypeCluster =
  | (Omit<SpatialSearchCountResult, 'labelLocation'> & LabelLocation)
  | (Omit<SpatialSearchOverCountResult, 'labelLocation'> & LabelLocation);
type HookResponseTypeDetails = Omit<SpatialSearchDetailResults, 'hits'> & {
  hits: PropertySpatialHit[];
};
export const QUERY_KEY_PROPERTY_SPATIAL_SEARCH_MAP = 'propertySpatialSearchMap';

const makeTileParams = (
  tilebeltTile: number[], // [x, y, z]
  params: PropertySpatialSearchMapQueryVariables
): PropertySpatialSearchMapQueryVariables | undefined => {
  const [x, y, zoom] = tilebeltTile;
  if (x && y && zoom) {
    return makeSafePropertySpatialSearchParams({
      ...params,
      id: {
        ...params.id,
        tile: {
          x,
          y,
          zoom,
        },
      },
    });
  }
  return undefined;
};

const toChildTileResults = (
  params: PropertySpatialSearchMapQueryVariables,
  data: HookResponseTypeDetails
):
  | [PropertySpatialSearchMapQueryVariables, HookResponseTypeDetails][]
  | undefined => {
  const tile = params.id.tile;
  if (!tile) {
    return;
  }
  const nextZoom = tile.zoom + 1;
  if (nextZoom <= MAX_ZOOM) {
    // Setup markers per child tile
    const childTiles: Record<
      `${number}-${number}-${number}`,
      PropertySpatialHit[]
    > = {};
    tilebelt.getChildren([tile.x, tile.y, tile.zoom]).forEach(([x, y, z]) => {
      if (x && y && z) {
        childTiles[`${x}-${y}-${z}`] = [];
      }
    });
    data.hits?.forEach((hit) => {
      const { latitude, longitude } = hit.location || {};
      if (latitude && longitude) {
        const [x, y, z] = tilebelt.pointToTile(longitude, latitude, nextZoom);
        if (x && y && z) {
          childTiles[`${x}-${y}-${z}`]?.push(hit);
        }
      }
    });
    const childTileResults: [
      PropertySpatialSearchMapQueryVariables,
      HookResponseTypeDetails
    ][] = [];
    Object.entries(childTiles).forEach(([tileKey, hits]) => {
      const [x, y, z] = tileKey.split('-').map((v) => Number(v));
      if (x && y && z) {
        const childTileParams: PropertySpatialSearchMapQueryVariables = {
          ...params,
          id: {
            ...params.id,
            tile: {
              x,
              y,
              zoom: z,
            },
          },
        };
        const childTileData: HookResponseTypeDetails = {
          ...data,
          totalCount: hits.length,
          hits,
        };
        childTileResults.push([childTileParams, childTileData]);
      }
    });
    return childTileResults;
  }
  return;
};

export const usePropertySpatialSearchMap = (
  params: PropertySpatialSearchMapQueryVariables | null
) => {
  const queryClient = useQueryClient();
  return useQuery(
    [QUERY_KEY_PROPERTY_SPATIAL_SEARCH_MAP, params],
    async () => {
      if (params !== null) {
        // Check to see if parent tile or child tiles are populated in the cache
        if (params.id.tile) {
          const tilebeltInput = [
            params.id.tile.x,
            params.id.tile.y,
            params.id.tile.zoom,
          ];
          const parentTileParams = makeTileParams(
            tilebelt.getParent(tilebeltInput),
            params
          );
          const parentData = queryClient.getQueryData<
            HookResponseTypeDetails | HookResponseTypeCluster
          >([QUERY_KEY_PROPERTY_SPATIAL_SEARCH_MAP, parentTileParams]);
          // Use cached parent data if it contains detail results
          if (
            parentTileParams?.id.tile &&
            parentData?.__typename === 'SpatialSearchDetailResults'
          ) {
            const thisTileData = toChildTileResults(
              parentTileParams,
              parentData
            )?.find(
              ([tParams]) =>
                tParams.id.tile?.x === params.id.tile?.x &&
                tParams.id.tile?.y === params.id.tile?.y &&
                tParams.id.tile?.zoom === params.id.tile?.zoom
            )?.[1];
            if (thisTileData) {
              return thisTileData;
            }
          }

          // Use cached data if all child tiles have already been loaded
          const childTilesData = tilebelt
            .getChildren(tilebeltInput)
            .map((t) =>
              queryClient.getQueryData<
                HookResponseTypeDetails | HookResponseTypeCluster
              >([
                QUERY_KEY_PROPERTY_SPATIAL_SEARCH_MAP,
                makeTileParams(t, params),
              ])
            );
          if (childTilesData.filter((d) => !d).length === 0) {
            let thisTileData:
              | HookResponseTypeDetails
              | HookResponseTypeCluster
              | undefined;
            for (const cData of childTilesData) {
              if (cData) {
                if (cData.__typename === 'SpatialSearchOverCountResult') {
                  // If any child tiles are an over count result, the parent is too
                  return cData;
                } else if (!thisTileData) {
                  thisTileData = cData;
                } else if (
                  thisTileData.__typename === 'SpatialSearchDetailResults'
                ) {
                  if (cData.__typename === 'SpatialSearchDetailResults') {
                    // Add these hits to this tile
                    thisTileData.hits = thisTileData.hits.concat(cData.hits);
                    thisTileData.totalCount =
                      (thisTileData.totalCount || 0) + (cData.totalCount || 0);
                  } else if (cData.__typename === 'SpatialSearchCountResult') {
                    // Change thisTileData to cluster
                    thisTileData = {
                      ...cData,
                      count:
                        (cData.count || 0) + (thisTileData.totalCount || 0),
                    };
                  }
                } else if (
                  thisTileData.__typename === 'SpatialSearchCountResult'
                ) {
                  if (cData.__typename === 'SpatialSearchDetailResults') {
                    // Add these hits to this tile cluster count
                    thisTileData.count =
                      (thisTileData.count || 0) + (cData.totalCount || 0);
                  } else if (cData.__typename === 'SpatialSearchCountResult') {
                    // Add child cluster count to parent
                    thisTileData.count =
                      (thisTileData.count || 0) + (cData.count || 0);
                  }
                }
              }
            }
            // Convert new tile data to cluster if the hits are over the limit
            if (
              thisTileData?.__typename === 'SpatialSearchDetailResults' &&
              (thisTileData.totalCount || 0) > params.limit
            ) {
              thisTileData = {
                __typename: 'SpatialSearchCountResult',
                count: thisTileData.totalCount,
                // labelLocation will be populated after the loop
                labelLocation: null,
              };
            }
            // Set new cluster tile labelLocation to the center of the tile
            if (
              thisTileData?.__typename === 'SpatialSearchCountResult' ||
              thisTileData?.__typename === 'SpatialSearchOverCountResult'
            ) {
              const tileLatLng = tileCoordsToLatLng(params.id.tile);
              thisTileData.labelLocation = {
                latitude: tileLatLng.lat,
                longitude: tileLatLng.lng,
              };
            }
            return thisTileData;
          }
        }
        // If we got this far, go ahead and ask cerberus-api for the data
        const response = await CerberusApi.fetchQuery<
          PropertySpatialSearchMapQuery,
          PropertySpatialSearchMapQueryVariables
        >(PropertySpatialSearchMapDocument, params);
        if (
          response.propertySpatialESSearch?.__typename ===
          'SpatialSearchDetailResults'
        ) {
          const data: HookResponseTypeDetails = {
            ...response.propertySpatialESSearch,
            hits:
              response.propertySpatialESSearch.hits?.reduce<
                PropertySpatialHit[]
              >((accum, hit) => {
                if (hit) {
                  accum.push(convertSpatialHitToPropertySpatialHit(hit));
                }
                return accum;
              }, []) || [],
          };
          return data;
        } else if (
          response.propertySpatialESSearch?.__typename ===
            'SpatialSearchCountResult' ||
          response.propertySpatialESSearch?.__typename ===
            'SpatialSearchOverCountResult'
        ) {
          const propertySpatialSearch = response.propertySpatialESSearch;
          // type comes back as GeoJsonObject which is incorrect if format:DICT is passed to labelLocation (as it should be in this query)
          const labelLocationDict = propertySpatialSearch.labelLocation as
            | PlaceLocation
            | null
            | undefined;
          return {
            __typename: propertySpatialSearch.__typename,
            count:
              propertySpatialSearch.__typename === 'SpatialSearchCountResult'
                ? propertySpatialSearch.count
                : null,
            moreThan:
              propertySpatialSearch.__typename ===
              'SpatialSearchOverCountResult'
                ? propertySpatialSearch.moreThan
                : null,
            labelLocation: {
              latitude: labelLocationDict?.latitude,
              longitude: labelLocationDict?.longitude,
            },
          };
        } else {
          return undefined;
        }
      } else {
        const err = new Error(
          'usePropertySpatialSearchMap called with null params (check enabled logic)'
        );
        logException(err);
        throw err;
      }
    },
    {
      enabled: params !== null,
    }
  );
};
