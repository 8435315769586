import { CompFieldConfig, CompFields } from '@hcs/types';

import { HC_ADJUSTMENT_PROPERTY_DETAILS_RENTAL_CONFIG } from './hcAdjustmentPropertyDetailsRental/hcAdjustmentPropertyDetailsRental';
import { ADJUSTED_COMP_VALUE_CONFIG } from './adjustedCompValue';
import { ADJUSTED_COMP_VALUE_PER_SQFT_CONFIG } from './adjustedCompValuePerSqFt';
import { ADJUSTED_COMP_VALUE_PER_SQFT_RENTAL_CONFIG } from './adjustedCompValuePerSqFtRental';
import { ADJUSTED_COMP_VALUE_RENTAL_CONFIG } from './adjustedCompValueRental';
import { APPRAISAL_TOTAL_DOLLAR_ADJUSTMENT_CONFIG } from './appraisalTotalDollarAdjustment';
import { DISTANCE_CONFIG } from './distance';
import { HC_ADJUSTMENT_DATE_CONFIG } from './hcAdjustmentDate';
import { HC_ADJUSTMENT_DATE_RENTAL_CONFIG } from './hcAdjustmentDateRental';
import { HC_ADJUSTMENT_PROPERTY_DETAILS_CONFIG } from './hcAdjustmentPropertyDetails';
import { LOCATION_SIMILARITY_CONFIG } from './locationSimilarity';
import { SIMILARITY_CONFIG } from './similarity';
import { USER_TOTAL_DOLLAR_ADJUSTMENT_CONFIG } from './userTotalDollarAdjustment';

export const COMP_FIELDS_CONFIG: {
  [F in CompFields]: CompFieldConfig<F>;
} = {
  [CompFields.distance]: DISTANCE_CONFIG,
  [CompFields.appraisalTotalDollarAdjustment]:
    APPRAISAL_TOTAL_DOLLAR_ADJUSTMENT_CONFIG,
  [CompFields.adjustedCompValue]: ADJUSTED_COMP_VALUE_CONFIG,
  [CompFields.adjustedCompValuePerSqFt]: ADJUSTED_COMP_VALUE_PER_SQFT_CONFIG,
  [CompFields.adjustedCompValueRental]: ADJUSTED_COMP_VALUE_RENTAL_CONFIG,
  [CompFields.adjustedCompValuePerSqFtRental]:
    ADJUSTED_COMP_VALUE_PER_SQFT_RENTAL_CONFIG,
  [CompFields.locationSimilarity]: LOCATION_SIMILARITY_CONFIG,
  [CompFields.similarity]: SIMILARITY_CONFIG,
  [CompFields.hcAdjustmentDate]: HC_ADJUSTMENT_DATE_CONFIG,
  [CompFields.hcAdjustmentPropertyDetails]:
    HC_ADJUSTMENT_PROPERTY_DETAILS_CONFIG,
  [CompFields.hcAdjustmentDateRental]: HC_ADJUSTMENT_DATE_RENTAL_CONFIG,
  [CompFields.hcAdjustmentPropertyDetailsRental]:
    HC_ADJUSTMENT_PROPERTY_DETAILS_RENTAL_CONFIG,
  [CompFields.userTotalDollarAdjustment]: USER_TOTAL_DOLLAR_ADJUSTMENT_CONFIG,
};
