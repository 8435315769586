export const arrayToChunks = <T>(array: T[], chunkSize: number) => {
  const chunkResults: T[][] = [];
  const size = Math.max(...[chunkSize, 1]);
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    chunkResults.push(chunk);
  }
  return chunkResults;
};

// if item exists in array, remove it.
// if item does not exist in array, add it
export const toggleStringInArray = (str: string, arr: string[] | undefined) => {
  if (arr === undefined) {
    return [str];
  } else {
    if (arr.includes(str)) {
      return [...arr].filter((arrStr) => arrStr !== str);
    } else {
      const arrWithNewString = [...arr];
      arrWithNewString.push(str);
      return arrWithNewString;
    }
  }
};
