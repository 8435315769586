import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { AvmQualityBadge } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import {
  ReportFeaturesSupportTypes,
  ReportId,
  ReportTypes,
  ValuationMethod,
  ValuationMethodLabel,
  ValuationMethodLabelRental,
} from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';
import {
  formatMoney,
  formatMoneyPerMonth,
  formatPricePerSqFt,
} from '@hcs/utils';

import { RangeChart } from '../../components-deprecated';
import { AdjustedAvmSelectorItem } from '../../features/ReportValueSelectorDeprecated/AdjustedAvmSelectorItem';
import {
  useReport,
  useReportPermissions,
  useReportValue,
  useSubjectValueDocument,
} from '../../hooks';
import { useLegacyCompValues } from '../../hooks/useLegacyCompValues';
import { OfferFromReportId } from '../OfferFromReportId';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import { AdjustedCompBasedAvgSelectorItem } from '../ReportValueSelectorDeprecated';
import { ComparableValueSelectorItem } from '../ReportValueSelectorDeprecated/ComparableValueSelectorItem';
import { ReportValueSelector } from '../ReportValueSelectorDeprecated/ReportValueSelector';

import {
  REPORT_VALUE_FEATURES_RENTAL,
  REPORT_VALUE_FEATURES_SALE,
} from './ReportValue';

import styles from './ReportValueDeprecated.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
  showRentalValue: boolean;
  onClickSelectComps: VoidFunction;
  onClickProvideLocation: NavigateToCompSelectionDrawFn;
}

const dataHcName = 'report-value';
export const ReportValueDeprecated = ({
  reportId,
  className,
  showRentalValue,
  onClickSelectComps,
  onClickProvideLocation,
}: Props) => {
  const compType = showRentalValue ? CompTypes.Rental : CompTypes.Sold;
  const valueFormatter = showRentalValue ? formatMoneyPerMonth : formatMoney;
  const { data: reportValue } = useReportValue(reportId, showRentalValue);
  const { data: subjectValueDocument } = useSubjectValueDocument(reportId, {
    showRentalValue,
  });
  const { data: report } = useReport(reportId);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { data: legacyCompValues } = useLegacyCompValues(reportId, compType);
  if (!reportValue || !report || !legacyCompValues) {
    return (
      <LoadingSpinner
        dataHcName={`${dataHcName}-skeleton `}
        containerHeight="380px"
      />
    );
  }
  const { valuationMethod, selectedValue } = reportValue;
  const avm = subjectValueDocument?.data[ValuationMethod.Avm].value;
  return (
    <div data-hc-name={dataHcName} className={className}>
      <div data-hc-name={`${dataHcName}-label`} className={styles.Label}>
        {`${
          legacyCompValues?.isCompAvgSelected
            ? `${showRentalValue ? 'Rental ' : ''}Comparable Average`
            : showRentalValue
            ? ValuationMethodLabelRental[valuationMethod]
            : ValuationMethodLabel[valuationMethod]
        } :`}
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            showRentalValue
              ? REPORT_VALUE_FEATURES_RENTAL
              : REPORT_VALUE_FEATURES_SALE
          }
          reportFeaturesSupportType={ReportFeaturesSupportTypes.MoreThanOne}
        >
          <ReportValueSelector
            reportId={reportId}
            showRentalValue={showRentalValue}
            className={styles.ReportValueSelector}
            onClickSelectComps={onClickSelectComps}
            onClickProvideLocation={onClickProvideLocation}
          />
        </ReportFeaturesSupported>
      </div>
      {valuationMethod === ValuationMethod.Adjusted &&
        !selectedValue?.value.value && (
          <AdjustedAvmSelectorItem
            reportId={reportId}
            showRentalValue={showRentalValue}
            className={styles.AvmSelectorInValue}
            onClose={() => undefined}
          />
        )}
      {valuationMethod === ValuationMethod.Comps &&
        !subjectValueDocument?.data[ValuationMethod.Comps].value.value &&
        (legacyCompValues?.isCompAvgSelected ? (
          <AdjustedCompBasedAvgSelectorItem
            reportId={reportId}
            showRentalValue={showRentalValue}
            className={styles.AvmSelectorInValue}
            onClickSelectComps={onClickSelectComps}
            onClickProvideLocation={onClickProvideLocation}
          />
        ) : (
          <ComparableValueSelectorItem
            reportId={reportId}
            showRentalValue={showRentalValue}
            className={styles.AvmSelectorInValue}
            onClickSelectComps={onClickSelectComps}
            onClickProvideLocation={onClickProvideLocation}
          />
        ))}
      {valuationMethod === ValuationMethod.Avm && (
        <RangeChart
          dataHcName={`${dataHcName}-value-chart'`}
          lowLabel="Low Estimate"
          highLabel="High Estimate"
          mainValue={
            <div className={styles.MainValue}>
              <div className={styles.Value}>
                <span
                  className={styles.PriceMean}
                  data-hc-name={`${dataHcName}-main-value`}
                >
                  {valueFormatter(selectedValue?.value.value)}
                </span>
                {avm && (
                  <AvmQualityBadge
                    dataHcName={`${dataHcName}-avm-quality`}
                    avm={avm}
                    showRentalValue={showRentalValue}
                    showQuality={report.reportType === ReportTypes.Value}
                    darkInfoIcon
                  />
                )}
              </div>
              <div
                className={styles.ValuePerSqft}
                data-hc-name={`${dataHcName}-value-per-sqft`}
              >
                {formatPricePerSqFt(
                  selectedValue?.calculatedFields.valuePerSqFt
                )}
              </div>
            </div>
          }
          lowTop={valueFormatter(selectedValue?.value.valueLower)}
          highTop={valueFormatter(selectedValue?.value.valueUpper)}
          lowBottom={formatPricePerSqFt(
            selectedValue?.calculatedFields.valueLowerPerSqFt
          )}
          highBottom={formatPricePerSqFt(
            selectedValue?.calculatedFields.valueUpperPerSqFt
          )}
        />
      )}
      {valuationMethod !== ValuationMethod.Avm &&
        !!selectedValue?.value.value && (
          <div
            className={styles.MainValue}
            data-hc-name={`${dataHcName}-main-value`}
          >
            <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
              {valueFormatter(selectedValue.value.value)}
            </div>
          </div>
        )}
      {valuationMethod === ValuationMethod.UserEntered && (
        <span
          className={styles.Disclaimer}
          data-hc-name={`${dataHcName}-disclaimer`}
        >
          This value is not generated or endorsed by HouseCanary, and it does
          not represent a broker price opinion (BPO) or a formal appraisal.
        </span>
      )}
      {!showRentalValue && (
        <OfferFromReportId
          reportId={reportId}
          theme={{ ButtonContainer: styles.OfferNowButtonContainer }}
        />
      )}
      {!reportPermissions.isEditable && (
        <div
          className={styles.ReadOnly}
          data-hc-name={`${dataHcName}-read-only`}
        >
          Read Only Report
        </div>
      )}
    </div>
  );
};
