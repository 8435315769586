import React from 'react';

import { HomeDarkIcon } from '../../../../svgs/icons/generics';

import { MapMarker } from './MapMarker';

import styles from './MapMarkers.module.css';

export interface PhotoMarkerProps {
  dataHcName: string;
  className?: string;
  url: string | null | undefined;
  onClick?: VoidFunction;
}
export const PhotoMarker = ({
  dataHcName,
  className,
  url,
  onClick,
}: PhotoMarkerProps) => {
  return (
    <MapMarker
      className={className}
      dataHcName={dataHcName}
      onClick={onClick}
      isPhoto
      url={url}
    >
      {!url && <HomeDarkIcon className={styles.NoPhoto} size="md" />}
    </MapMarker>
  );
};
