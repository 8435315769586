import React, { useState } from 'react';
import classNames from 'classnames';

import { DateStr } from '@hcs/types';
import { InputProps } from '@hcs/types';
import { dateStrToHumanOrderDate, humanOrderDateToDateStr } from '@hcs/utils';

import { Input } from '../Input';

import styles from './DateStrInput.module.css';

export interface DateStrInputProps extends Omit<InputProps, 'onChange'> {
  onChange: (value: DateStr | string) => void;
  onErrorChange?: (error?: string) => void;
  validators?: ((v: string) => string | undefined)[];
  ref?: React.Ref<HTMLInputElement>;
  allowNull?: boolean;
}

const DATE_STR_INPUT_ERROR_MESSAGE =
  'Invalid Date: Please use the format MM-DD-YYYY and ensure dates are in valid range';

export const DateStrInput = React.forwardRef<
  HTMLInputElement,
  DateStrInputProps
>((props, ref) => {
  const {
    dataHcName,
    className,
    value,
    disabled,
    allowNull,
    tooltipError,
    error,
    onErrorChange,
    role,
    required,
    validators,
    onChange,
    onKeyUp,
    onBlur,
    onFocus,
  } = props;

  const [dateError, setDateError] = useState<string>();
  const humanOrderValue = dateStrToHumanOrderDate(value);
  const handleSetError = (error: string | undefined) => {
    onErrorChange?.(error);
    setDateError(error);
  };
  const handleChange = (input: string) => {
    //always validates for human order date string
    const dateStr = humanOrderDateToDateStr(input);
    if (dateStr) {
      let error;
      if (validators) {
        validators.forEach((validate) => {
          error = validate(input);
        });
      }
      handleSetError(error);
      onChange(dateStr);
    } else if (!input.length && allowNull) {
      handleSetError(error);
      onChange(input);
    } else {
      handleSetError(error || DATE_STR_INPUT_ERROR_MESSAGE);
      onChange(input);
    }
  };

  return (
    <Input
      value={humanOrderValue ? humanOrderValue : value}
      className={classNames(styles.DateStrInput, className)}
      dataHcName={dataHcName}
      placeholder="MM-DD-YYYY"
      onChange={handleChange}
      required={required}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onFocus={onFocus}
      role={role}
      maxLength={10}
      disabled={disabled}
      error={error || dateError}
      tooltipError={tooltipError}
      ref={ref}
    />
  );
});
