import { OrderSets, OrderStatus } from '@hcs/types';

export enum ORDER_STATUSES {
  ACCEPTED = 'Accepted',
  CLIENT_REVIEW = 'ClientReview',
  NEW = 'New',
  COMPLETE = 'Complete',
  CANCEL_PENDING = 'CancelPending',
  CANCELLED = 'Cancelled',
}

export const ORDER_SET_TO_STATUSES: {
  [key in OrderSets]: OrderStatus[];
} = {
  [OrderSets.all]: [
    ORDER_STATUSES.NEW,
    ORDER_STATUSES.CLIENT_REVIEW,
    ORDER_STATUSES.ACCEPTED,
    ORDER_STATUSES.COMPLETE,
    ORDER_STATUSES.CANCELLED,
    ORDER_STATUSES.CANCEL_PENDING,
  ],
  [OrderSets.active]: [
    ORDER_STATUSES.NEW,
    ORDER_STATUSES.CLIENT_REVIEW,
    ORDER_STATUSES.ACCEPTED,
  ],
  [OrderSets.completed]: [ORDER_STATUSES.COMPLETE],
  [OrderSets.cancelled]: [
    ORDER_STATUSES.CANCELLED,
    ORDER_STATUSES.CANCEL_PENDING,
  ],
};
