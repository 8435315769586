import React from 'react';
import { useMap } from 'react-map-gl';
import classNames from 'classnames';

import { MinusIcon, PlusIcon } from '@hcs/design-system';
import { ControlPosition } from '@hcs/types';
import { isRefObject } from '@hcs/utils';

import styles from './ZoomControl.module.css';

interface Props {
  className?: string;
  zoom: number; // the current zoom value
  position: ControlPosition;
  duration?: number;
  manual?: boolean;
  mapId: string;
  onChange?: (zoom: number) => void;
}
const dataHcName = 'zoom-control';

export const ZoomControl = ({
  className,
  duration = 300,
  zoom,
  onChange,
  manual = false,
  mapId,
  position,
}: Props) => {
  const map = useMap();

  const changeZoom = (newZoom: number) => {
    if (isRefObject(map) && !manual) {
      map?.current?.zoomTo(newZoom, { duration });
    }
    onChange?.(newZoom);
  };

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ZoomControl, styles[position], className)}
    >
      <button
        data-hc-name={`${dataHcName}-zoom-in`}
        className={styles.Button}
        onClick={() => {
          changeZoom(zoom + 1);
        }}
      >
        <PlusIcon size="sm" />
      </button>
      <button
        data-hc-name={`${dataHcName}-zoom-out`}
        className={classNames(styles.Button)}
        onClick={() => {
          changeZoom(zoom - 1);
        }}
      >
        <MinusIcon size="sm" />
      </button>
    </div>
  );
};
