import React, { useMemo, useState } from 'react';

import { Button } from '../../buttons/Button';
import { InputNumber } from '../../inputs/InputNumber';
import { RadioOption, RadioSelect } from '../../selects/RadioSelect';

import styles from './DistanceSelect.module.css';

type DistanceOption = RadioOption<number | null | undefined>;
export const CUSTOM_RADIUS_VALUE = -1;
const MAX_MILES_RADIUS = 500;

export interface DistanceSelectProps {
  dataHcName: string;
  className?: string;
  disabled?: boolean;
  // undefined will not show a radio button selected
  // this is shown when a custom shape has been drawn
  value: number | null | undefined;
  onChange: (value: number | null | undefined) => void;
}

const OPTIONS: DistanceOption[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: '0.5 Mile',
    value: 0.5,
  },
  {
    label: '1 Mile',
    value: 1,
  },
  {
    label: '2 Miles',
    value: 2,
  },
  {
    label: '3 Miles',
    value: 3,
  },
  {
    label: 'Custom',
    value: CUSTOM_RADIUS_VALUE,
  },
];
export const DistanceSelect = ({
  dataHcName,
  className,
  disabled,
  value: valueProp,
  onChange,
}: DistanceSelectProps) => {
  const value = valueProp ? valueProp : null;
  const isStandardValue = useMemo(() => {
    return !value || [0.5, 1, 2, 3].includes(value);
  }, [value]);
  const [customRadius, setCustomRadius] = useState<null | number | string>(
    isStandardValue ? null : `${value}` || null
  );
  const [showCustomInput, setShowCustomInput] = useState<boolean>(
    !isStandardValue
  );
  const handleCustomRadiusChange = (value: string | number | null) => {
    if (value && `${value}`.length > 0) {
      // Don't allow negative numbers
      const parsedValue = parseFloat(`${value}`.replaceAll('-', ''));
      if (parsedValue > MAX_MILES_RADIUS) {
        value = `${MAX_MILES_RADIUS}`;
      }
    }
    setCustomRadius(value);
  };
  const handleRadioSelectChange = (selection: number | null | undefined) => {
    if (selection === CUSTOM_RADIUS_VALUE) {
      setCustomRadius(null);
      setShowCustomInput(true);
    } else {
      if (showCustomInput) {
        setShowCustomInput(false);
      }
      // Only call onChange here if a standard input is selected
      onChange(selection);
    }
  };

  return (
    <>
      <RadioSelect
        dataHcName={dataHcName}
        className={className}
        disabled={disabled}
        value={!showCustomInput ? value : CUSTOM_RADIUS_VALUE}
        options={OPTIONS}
        onChange={handleRadioSelectChange}
        blockOptions
      />
      {showCustomInput && (
        <div className={styles.CustomInputForm}>
          <InputNumber
            shouldFormat={{ shouldFormatNumber: false }}
            isFloat={true}
            className={styles.Input}
            value={customRadius}
            dataHcName={`${dataHcName}-custom-form`}
            onChange={handleCustomRadiusChange}
          />
          <Button
            primary={true}
            // Call the onChange prop with the custom radius value (now a number) and let feature decide what to do since this distance control value has now changed.
            onClick={() =>
              onChange(customRadius ? parseFloat(`${customRadius}`) : null)
            }
            disabled={!customRadius}
            className={styles.SubmitButton}
            dataHcName={`${dataHcName}-submit-custom-range-btn`}
          >
            Apply
          </Button>
        </div>
      )}
    </>
  );
};
