import React, { useMemo, useRef, useState } from 'react';
import { Popup } from 'react-map-gl';
import { Offset } from 'mapbox-gl';

import { useClickOutsideComponent } from '@hcs/hooks';
import { HcMap, HcMapProps } from '@hcs/maps';
import { PropertyMarker } from '@hcs/maps';
import { useHcMap } from '@hcs/maps';
import { getTilesInBoundsWithAdditionalZoom } from '@hcs/maps';
import {
  PropertyStateCard,
  PropertyStateCardPropsCreator,
} from '@hcs/property-state';
import { usePropertyState } from '@hcs/property-state';
import {
  PropertySpatialSearchMapQueryVariables,
  PropertyTypeEnum,
} from '@hcs/types';
import { PropertySpatialHit } from '@hcs/types';
import {
  PropertyStateCerberusInput,
  PropertyStateCore,
  PropertyStatePreview,
  PropertyStateType,
} from '@hcs/types';
import { locationToGeoLocation } from '@hcs/utils';

import { PropertySpatialSearchMapTile } from '../../features/PropertySpatialSearchMap/PropertySpatialSearchMapTile';
import { getPropertyListPriceLabel } from '../../utils';

import styles from './PropertySpatialSearchMap.module.css';

const OFFSET: Offset = {
  top: [0, 35],
  left: [55, 0],
  'top-left': [0, 30],
  'bottom-left': [0, -35],
  right: [-55, 0],
  center: [0, -35],
  bottom: [0, -35],
  'top-right': [0, 30],
  'bottom-right': [0, -35],
};
export interface PropertySpatialSearchMapProps
  extends Omit<HcMapProps, 'dataHcName'> {
  spatialSearchVariables: PropertySpatialSearchMapQueryVariables;
  propertyStateCardProps?: PropertyStateCardPropsCreator;
  highlightedProperty?: PropertyStateCerberusInput;
  isRental?: boolean;
}

const dataHcName = 'property-spatial-search-map';
const HighlightedMarker = ({
  propertyStateCerberusInput,
  onClickPropertyMarker,
}: {
  propertyStateCerberusInput: PropertyStateCerberusInput;
  onClickPropertyMarker: (
    hit: PropertyStateCore | PropertyStatePreview
  ) => void;
}) => {
  const { data: propertyStateArgs } = usePropertyState(
    propertyStateCerberusInput
  );
  const geoLocation = locationToGeoLocation(
    propertyStateArgs?.propertyState?.location
  );
  const propertyState = propertyStateArgs?.propertyState;

  if (!geoLocation || !propertyState) {
    return null;
  }
  const hcAddressId = propertyState.hcAddressId;
  return (
    <PropertyMarker
      key={hcAddressId}
      markerId={`${hcAddressId}-highlight`}
      geoLocation={geoLocation}
      onClick={() => {
        onClickPropertyMarker(propertyState);
      }}
      dataHcName={`${dataHcName}-marker`}
      label={getPropertyListPriceLabel(propertyState)}
      pulse
    />
  );
};

export const PropertySpatialSearchMap = ({
  spatialSearchVariables,
  children,
  propertyStateCardProps,
  highlightedProperty,
  isRental,
  ...mapProps
}: PropertySpatialSearchMapProps) => {
  const [openMarker, setOpenMarker] = useState<PropertySpatialHit | null>(null);
  const [markerPopupOverlayOpen, setMarkerPopupOverlayOpen] =
    useState<boolean>(false);
  const { mapState } = useHcMap(mapProps.mapId);
  const propertyTypeForHeatmaps = useMemo(() => {
    const prioritizedPropertyTypes = [
      PropertyTypeEnum.Sfr,
      PropertyTypeEnum.Townhouse,
      PropertyTypeEnum.Condo,
      PropertyTypeEnum.MultiFamily,
      PropertyTypeEnum.Manufactured,
    ];

    for (const propertyType of prioritizedPropertyTypes) {
      if (
        spatialSearchVariables.spatialFilters.propertyTypeEnum?.find(
          (p) => p === propertyType
        )
      ) {
        return propertyType;
      }
    }
    return PropertyTypeEnum.Sfr;
  }, [spatialSearchVariables.spatialFilters.propertyTypeEnum]);
  const handleClose = () => {
    if (!markerPopupOverlayOpen) {
      setOpenMarker(null);
    }
  };
  const ref = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(ref, handleClose);
  const tiles = useMemo(() => {
    if (mapState?.boundsInfo && mapState?.viewport.zoom) {
      const zoom = Math.ceil(mapState?.viewport.zoom);
      return getTilesInBoundsWithAdditionalZoom(
        {
          ne: mapState.boundsInfo.northEast,
          sw: mapState.boundsInfo.southWest,
        },
        zoom,
        1
      );
    }
    return [];
  }, [mapState?.boundsInfo, mapState?.viewport.zoom]);
  const popupPropertyCardProps =
    typeof propertyStateCardProps === 'function' && openMarker?.hcAddressId
      ? propertyStateCardProps({
          propertyStateType: PropertyStateType.Core,
          cerberusInput: { hcAddressId: openMarker.hcAddressId },
        })
      : !!propertyStateCardProps && typeof propertyStateCardProps !== 'function'
      ? propertyStateCardProps
      : {};
  return (
    <HcMap
      {...mapProps}
      dataHcName={dataHcName}
      propertyType={propertyTypeForHeatmaps}
    >
      {tiles.map((tile) => {
        return (
          <PropertySpatialSearchMapTile
            mapId={mapProps.mapId}
            dataHcName={dataHcName}
            isRental={isRental}
            key={`${tile.x}-${tile.y}-${tile.zoom}`}
            tile={tile}
            spatialSearchVariables={spatialSearchVariables}
            onClickPropertyMarker={setOpenMarker}
          />
        );
      })}
      {children}
      {highlightedProperty && (
        <HighlightedMarker
          onClickPropertyMarker={setOpenMarker}
          propertyStateCerberusInput={highlightedProperty}
        />
      )}
      {openMarker?.hcAddressId &&
        openMarker.location?.longitude &&
        openMarker.location.latitude && (
          <Popup
            className={styles.MarkerPopup}
            longitude={openMarker.location.longitude}
            latitude={openMarker.location.latitude}
            closeButton={false}
            maxWidth="250px"
            closeOnClick={false}
            onClose={handleClose}
            offset={OFFSET}
          >
            <div data-hc-name={`${dataHcName}-marker-popup`} ref={ref}>
              <PropertyStateCard
                {...popupPropertyCardProps}
                propertyStateCerberusInput={{
                  propertyStateType: PropertyStateType.Core,
                  cerberusInput: {
                    hcAddressId: openMarker.hcAddressId,
                  },
                }}
                onPhotoViewToggle={() => {
                  popupPropertyCardProps.onPhotoViewToggle?.();
                  setMarkerPopupOverlayOpen(!markerPopupOverlayOpen);
                }}
                horizontal={false}
              />
            </div>
          </Popup>
        )}
    </HcMap>
  );
};
