import React from 'react';

import {
  Dropdown,
  MultiSelect,
  TableCell,
  TableHeaderCell,
} from '@hcs/design-system';
import {
  ListingStatusCerberus,
  MlsStateGroup,
  PropertyStateEditCallbackArgs,
  PropertyStateFilterProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
} from '@hcs/types';
import { formatListingStatusNormalized, formatMissing } from '@hcs/utils';

import { ListingStatusIndicator } from '../../../components/ListingStatusIndicator';

const FIELD = PropertyStateFields.currentStatus;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Listing Status';
const labelShort = 'Status';
const propertyStatePath: PropertyStatePaths =
  '/complexFieldsSale/currentStatus';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  }
  return propertyState?.complexFieldsSale?.currentStatus;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatMissing(formatListingStatusNormalized(getValue(propertyStateArgs)));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Status';
const formatValueShort: FieldConfig['formatValueShort'] = formatValue;

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)}`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{label}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <ListingStatusIndicator
        value={{
          type: 'listingStatus',
          value: getValue(propertyStateArgs),
        }}
      />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const OPTIONS = [
  {
    label: 'Active',
    value: MlsStateGroup.Active,
  },
  {
    label: 'Contingent',
    value: MlsStateGroup.Contingent,
  },
  {
    label: 'Off Market',
    value: MlsStateGroup.OffMarket,
  },
  {
    label: 'Pending',
    value: MlsStateGroup.Pending,
  },
];

const DROPDOWN_OPTIONS = [
  {
    label: ListingStatusCerberus.Active,
    value: ListingStatusCerberus.Active,
  },
  {
    label: ListingStatusCerberus.ActiveUnderContract,
    value: ListingStatusCerberus.ActiveUnderContract,
  },
  {
    label: ListingStatusCerberus.Canceled,
    value: ListingStatusCerberus.Canceled,
  },
  {
    label: ListingStatusCerberus.Closed,
    value: ListingStatusCerberus.Closed,
  },
  {
    label: ListingStatusCerberus.ComingSoon,
    value: ListingStatusCerberus.ComingSoon,
  },
  {
    label: ListingStatusCerberus.Delete,
    value: ListingStatusCerberus.Delete,
  },
  {
    label: ListingStatusCerberus.Expired,
    value: ListingStatusCerberus.Expired,
  },
  {
    label: ListingStatusCerberus.Hold,
    value: ListingStatusCerberus.Hold,
  },
  {
    label: ListingStatusCerberus.Incomplete,
    value: ListingStatusCerberus.Incomplete,
  },
  {
    label: ListingStatusCerberus.Pending,
    value: ListingStatusCerberus.Pending,
  },
  {
    label: ListingStatusCerberus.Withdrawn,
    value: ListingStatusCerberus.Withdrawn,
  },
  {
    label: ListingStatusCerberus.DeedRecorded,
    value: ListingStatusCerberus.DeedRecorded,
  },
];

const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  return (
    <MultiSelect
      className={className}
      disabled={disabled}
      options={OPTIONS}
      value={spatialFiltersInput.mlsStateGroup || []}
      onChange={(value) => {
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            mlsStateGroup: value,
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: PropertyStateEditCallbackArgs<typeof FIELD>) => void;
  className?: string;
}) => {
  const value = getValue(propertyStateArgs);

  return (
    <Dropdown
      dataHcName="listing-status-edit-dropdown"
      className={className}
      onSelect={(value) =>
        onChange({
          path: propertyStatePath,
          value,
          field: FIELD,
        })
      }
      options={DROPDOWN_OPTIONS}
      value={value}
    />
  );
};

export const CURRENT_STATUS_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  labelMicro,
  formatValueShort,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Filter,
  Edit,
  Display,
};
