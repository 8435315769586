/** Dev / Debug / Internal feature used in Cerberus Explorer and Property State Storybook */
import React from 'react';
import classNames from 'classnames';

import { Dropdown, DropdownProps, InfoTooltip } from '@hcs/design-system';
import { PropertyStateType } from '@hcs/types';

import { PROPERTY_STATE_TYPE_DESCRIPTIONS } from '../../constants';

import styles from './PropertyStateTypeDropdown.module.css';

interface Props {
  value: PropertyStateType | null;
  onSelect: DropdownProps<PropertyStateType | null>['onSelect'];
  className?: string;
}
const OPTIONS = [
  {
    value: PropertyStateType.Preview,
    label: 'Preview',
  },
  {
    value: PropertyStateType.Core,
    label: 'Core',
  },
];
const dataHcName = 'property-state-type-dropdown';
export const PropertyStateTypeDropdown = (props: Props) => {
  return (
    <div className={styles.PropertyStateTypeControl}>
      <div className={styles.PropertyStateTypeControlLabel}>
        Property State Type:
      </div>
      <Dropdown
        dataHcName={dataHcName}
        options={OPTIONS}
        placeholder="Property State Type"
        {...props}
        className={classNames(styles.Dropdown, props.className)}
      />
      <InfoTooltip
        dataHcName={`${dataHcName}-propertyStateTypeDesc`}
        label={
          PROPERTY_STATE_TYPE_DESCRIPTIONS[
            props.value || PropertyStateType.Preview
          ]
        }
      />
    </div>
  );
};
