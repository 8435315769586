import { BulkHpiIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, CapabilityApplications } from '@hcs/types';

export const APP_CONFIG_BULK_HPI: AppConfig = {
  name: 'Bulk HPI Data',
  appSlug: AppSlugs.Platform,
  rootPath: `/`,
  Icon: BulkHpiIcon,
  applicationKey: CapabilityApplications.BulkHpi,
  descriptionLong:
    'Bulk HPI Data at the Zip/MSA/State levels, delivered monthly.',
  descriptionShort: 'Bulk HPI Data at the Zip/MSA/State levels',
};
