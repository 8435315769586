import React from 'react';

import { TabPopover } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { SearchAddIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { ReportFeatures } from '@hcs/types';

import { ReportFeaturesSupported } from '../../features/ReportFeaturesSupported';
import { useReportPermissions } from '../../hooks';

import {
  CompSearch,
  CompSearchProps,
  dataHcEventSectionCompSearch,
} from './CompSearch';

import styles from './CompSearch.module.css';

const dataHcName = 'report-search-popover';
export const CompSearchTabPopover = (props: CompSearchProps) => {
  const { active, setActiveState, handleClose } = useActiveState();
  const { data: reportPermissions } = useReportPermissions(props.reportId);
  if (!reportPermissions?.isEditable) return null;
  const COMPS_LIST_HEADER_PADDING: [number, number, number, number] = [
    0, 0, 0, 0,
  ];
  return (
    <ReportFeaturesSupported
      reportId={props.reportId}
      reportFeatures={[
        ReportFeatures.CompsSelectByAddress,
        ReportFeatures.CompsSelectByListing,
      ]}
    >
      <TabPopover
        pagePadding={COMPS_LIST_HEADER_PADDING}
        onChangeActiveState={setActiveState}
        active={active}
        dataHcName={dataHcName}
        dataHcEventSection={dataHcEventSectionCompSearch}
        trigger={
          <Tooltip
            dataHcName={`${dataHcName}-tooltip`}
            trigger={<SearchAddIcon size="lg" />}
            label="Add a new comp"
          />
        }
        content={
          <div className={styles.Content}>
            <CompSearch
              {...props}
              onSelect={(addressSearchHit) => {
                props.onSelect?.(addressSearchHit);
                handleClose();
              }}
            />
          </div>
        }
        closeOnClick={false}
        theme={{ Content: styles.CompSearchTabPopoverContent }}
      />
    </ReportFeaturesSupported>
  );
};
