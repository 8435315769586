import React from 'react';
import classNames from 'classnames';

import { Skeleton } from '../../../global/loading-errors-null/Skeleton/Skeleton';
import { Card } from '../Card';

import styles from './CardSection.module.css';

interface CardSectionTheme {
  Container: string;
  Card: string;
}

interface SkeletonConfig {
  title: boolean;
  content: {
    show: boolean;
    height?: string;
  };
}
export interface CardSectionProps {
  dataHcName: string;
  title: React.ReactNode;
  children: JSX.Element | null;
  theme?: Partial<CardSectionTheme>;
  skeletonConfig?: SkeletonConfig;
}

export const CardSection = ({
  dataHcName,
  title,
  children,
  theme,
  skeletonConfig,
}: CardSectionProps) => {
  return (
    <div className={classNames(styles.CardSection, theme?.Container)}>
      {skeletonConfig?.title ? (
        <div className={styles.SkeletonTitle}>
          <Skeleton
            type="fillInline"
            dataHcName={`${dataHcName}-title-skeleton`}
          />
        </div>
      ) : (
        <div className={styles.Title} data-hc-name={`${dataHcName}-title`}>
          {title}
        </div>
      )}
      {skeletonConfig?.content.show ? (
        <div
          className={styles.SkeletonContent}
          style={{ height: skeletonConfig?.content.height || 'auto' }}
        >
          <Skeleton
            type="fillInline"
            dataHcName={`${dataHcName}-content-skeleton`}
          />
        </div>
      ) : (
        <Card dataHcName={`${dataHcName}-card`} className={theme?.Card}>
          {children}
        </Card>
      )}
    </div>
  );
};
