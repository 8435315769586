import * as Sentry from '@sentry/react';

import { ENV, IS_SENTRY_ENABLED } from '@hcs/webapps';
export type ExceptionHint = Sentry.EventHint;
export const logException = (
  messageOrError: string | Error,
  // Extra data to be sent to Sentry with the event. Useful for sending local context or state to aid
  // in debugging
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraInfo?: Record<string, any>
) => {
  const error =
    typeof messageOrError === 'string'
      ? new Error(messageOrError)
      : messageOrError;
  if (IS_SENTRY_ENABLED) {
    Sentry.captureException(
      error,
      extraInfo ? { extra: { ...extraInfo } } : {}
    );
  }
  if (ENV !== 'prod' && ENV !== 'stage') {
    console.error(error, extraInfo);
  }
};

export const logWarning = (message: string) => {
  if (IS_SENTRY_ENABLED) {
    Sentry.captureMessage(message, 'warning');
  }
  if (ENV !== 'prod' && ENV !== 'stage') {
    console.warn(message);
  }
};
