import React from 'react';

import { PropertyStateCerberusInput } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import {
  PropertyPhotoOverlayPage,
  PropertyPhotoOverlayPageProps,
} from '../../components/PropertyPhotoOverlayPage';
import { usePropertyState } from '../../hooks/usePropertyState';
import { usePropertyStateImages } from '../../hooks/usePropertyStateImages';

interface Props
  extends Omit<
    PropertyPhotoOverlayPageProps,
    'isLoading' | 'isError' | 'propertyStateArgs' | 'propertyPhotosResult'
  > {
  propertyStateCerberusInput: PropertyStateCerberusInput;
}
export const PropertyStatePhotoOverlayPage = ({
  propertyStateCerberusInput,
  ...photosOverlayPageProps
}: Props) => {
  const propertyQuery = usePropertyState(propertyStateCerberusInput);
  const imagesQuery = usePropertyStateImages(propertyStateCerberusInput);
  const { isInitialLoading, isError } = combineUseQueryResult([
    propertyQuery,
    imagesQuery,
  ]);
  return (
    <PropertyPhotoOverlayPage
      {...photosOverlayPageProps}
      isLoading={isInitialLoading}
      isError={isError}
      propertyStateArgs={propertyQuery.data}
      propertyPhotosResult={imagesQuery.data}
    />
  );
};
