import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { CompTypes } from '@hcs/types';
import { CompFiltersAll, ReportId } from '@hcs/types';

import { FilterButton } from '../../components-deprecated';
import { FilterIcon } from '../../components-deprecated';
import { useCompsFiltersDocument } from '../../hooks/useCompsFiltersDocument';

import { useCompFilterBarSlice } from './hooks/useCompFilterBarSlice';

import styles from './MoreFiltersButton.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const dataHcName = 'more-filters-button';
export const MoreFiltersButton = ({ className, compType, reportId }: Props) => {
  const {
    state: {
      overflowVisible,
      overflowCount,
      overflowButtonVisible,
      buttonsVisible,
    },
    actions: { overflowVisibilityChange, overflowButtonVisibilityChange },
  } = useCompFilterBarSlice();

  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const appliedFilters = filterDocument?.data.filters;

  useEffect(() => {
    if (!!overflowCount !== overflowButtonVisible) {
      overflowButtonVisibilityChange({ visible: !!overflowCount });
    }
  }, [overflowCount, overflowButtonVisible]);

  const activeOverflowCount = useMemo(() => {
    let count = 0;
    if (appliedFilters) {
      Object.entries(appliedFilters).forEach((entry) => {
        const field = entry[0] as keyof CompFiltersAll;
        if (!buttonsVisible[field]) {
          count++;
        }
      });
    }
    return count;
  }, [appliedFilters, buttonsVisible]);

  if (!overflowCount || !filterDocument) {
    return null;
  }

  return (
    <FilterButton
      dataHcName={dataHcName}
      active={overflowVisible}
      className={classNames(styles.MoreFiltersButton, className, {
        [styles.active]: overflowVisible,
      })}
      onClick={() => {
        overflowVisibilityChange({ visible: !overflowVisible });
      }}
    >
      <FilterIcon
        active={overflowVisible}
        dataHcName={`${dataHcName}-more-filters-icon`}
      />{' '}
      More Filters{activeOverflowCount > 0 && ` (${activeOverflowCount})`}
    </FilterButton>
  );
};
