import { AxiosAccessTokenClientBearer } from '@hcs/http-clients';
import {
  AccroleapiRolesResp,
  OrganizationapiOrgResponse,
  OrganizationapiUpdateOrgRequestInt,
  SelfServeOrgInfoResponse,
} from '@hcs/types';
import { ACCOUNT_URL, ACCOUNT_URL_INTERNAL } from '@hcs/urls';

export type UpdateOrgParams = Partial<OrganizationapiUpdateOrgRequestInt> & {
  orgId: number;
};

export const OrganizationApi = {
  fetchOrganization: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<OrganizationapiOrgResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}`
      );
    return response.data;
  },
  patchOrganization: async (payload: UpdateOrgParams) => {
    const { orgId, ...updateOrgReq } = payload;
    const response =
      await AxiosAccessTokenClientBearer.patch<OrganizationapiOrgResponse>(
        `${ACCOUNT_URL_INTERNAL}/organizations/${orgId}`,
        updateOrgReq
      );

    return response.data;
  },
  fetchIsSso: async (orgId: number) => {
    const response = await AxiosAccessTokenClientBearer.get<null>(
      `${ACCOUNT_URL}/organizations/${orgId}/is-sso`
    );
    return response.data;
  },
  fetchRoles: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<AccroleapiRolesResp>(
        `${ACCOUNT_URL_INTERNAL}/organizations/${orgId}/roles`
      );
    return response.data.roles;
  },
  fetchOrgInfo: async () => {
    return await AxiosAccessTokenClientBearer.get<SelfServeOrgInfoResponse>(
      `${ACCOUNT_URL}/ss/org-info`
    );
  },
};
