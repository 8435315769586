import React from 'react';

import { SectionHeader } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';

import {
  COMP_SUMMARY_MAP_FEATURES_RENTAL,
  CompSummaryMap,
} from '../../features/CompsSummaryMap/CompSummaryMap';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import {
  REPORT_VALUE_FEATURES_RENTAL,
  ReportValueDeprecated,
} from '../ReportValue';
import {
  SELECTED_OR_SUGGESETED_COMPS_CARDS_FEATURES_RENTAL,
  SelectedOrSuggestedCompsCards,
  SelectedOrSuggestedCompsLabel,
} from '../SelectedOrSuggestedComps';

import styles from './RentalReportSection.module.css';

interface Props {
  reportId: ReportId;
  onClickSelectComps: VoidFunction;
  onClickProvideLocation: NavigateToCompSelectionDrawFn;
}
const dataHcName = 'rental-report-section';
export const RENTAL_REPORT_SECTION_FEATURES = [
  ...REPORT_VALUE_FEATURES_RENTAL,
  ...SELECTED_OR_SUGGESETED_COMPS_CARDS_FEATURES_RENTAL,
  ...COMP_SUMMARY_MAP_FEATURES_RENTAL,
];
export const RentalReportSection = ({
  reportId,
  onClickSelectComps,
  onClickProvideLocation,
}: Props) => {
  return (
    <section data-hc-name={dataHcName} className={styles.CompsSummary}>
      <SectionHeader dataHcName={`${dataHcName}-header`}>
        HouseCanary Rental Details
      </SectionHeader>
      <div className={styles.Content}>
        <div className={styles.LeftCell}>
          <ReportFeaturesSupported
            reportId={reportId}
            reportFeatures={REPORT_VALUE_FEATURES_RENTAL}
          >
            <ReportValueDeprecated
              reportId={reportId}
              showRentalValue
              onClickSelectComps={onClickSelectComps}
              onClickProvideLocation={onClickProvideLocation}
            />
          </ReportFeaturesSupported>
          <ReportFeaturesSupported
            reportId={reportId}
            reportFeatures={SELECTED_OR_SUGGESETED_COMPS_CARDS_FEATURES_RENTAL}
          >
            <SelectedOrSuggestedCompsCards
              reportId={reportId}
              compType={CompTypes.Rental}
            />
          </ReportFeaturesSupported>
        </div>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={COMP_SUMMARY_MAP_FEATURES_RENTAL}
        >
          <div className={styles.RightCell}>
            <div className={styles.MapHeader}>
              <SelectedOrSuggestedCompsLabel
                reportId={reportId}
                compType={CompTypes.Rental}
              />
            </div>
            <div className={styles.MapContainer}>
              <CompSummaryMap
                reportId={reportId}
                compType={CompTypes.Rental}
                scrollZoom={false}
              />
            </div>
          </div>
        </ReportFeaturesSupported>
      </div>
    </section>
  );
};
