import React, { useState } from 'react';
import classNames from 'classnames';

import { Dialog, DIALOG_ACTIONS_PORTAL_ID } from '@hcs/design-system';
import { useRerender } from '@hcs/hooks';
import { CompTypes } from '@hcs/types';

import {
  CompDataPriorityUser,
  dataHcEventSectionDataPriorityComps,
  dataHcEventSectionDataPriorityRentalComps,
} from './CompDataPriorityUser';

import styles from './CompDataPriorityUser.module.css';

interface Props {
  compType: CompTypes;
  className?: string;
  active: boolean;
  onClose: VoidFunction;
}
const dataHcName = 'comp-farm-table-options-dialog';
export const CompDataPriorityUserDialog = ({
  className,
  compType,
  active: dialogActive,
  onClose,
}: Props) => {
  const isRental = compType === CompTypes.Rental;
  const [numCols, setNumCols] = useState(2);
  // Rerender when dialog opens so we can get an accurate parent height for column calcs
  useRerender({
    deps: [dialogActive],
    shouldRerender: dialogActive,
  });

  return (
    <Dialog
      dataHcName={dataHcName}
      dataHcEventSection={
        isRental
          ? dataHcEventSectionDataPriorityRentalComps
          : dataHcEventSectionDataPriorityComps
      }
      theme={{
        Dialog: classNames(styles.CompTableOptionsDialog, className),
        Header: styles.Header,
      }}
      active={dialogActive}
      onClose={onClose}
      title={`${isRental ? 'Rental' : 'Sold & Active'} Comparables Data`}
      subtitle={`Use the controls below to prioritize or disable property datapoints
            when viewing ${isRental ? 'Rental' : 'Sold & Active'} Comparables`}
      forceIsOverflowed
    >
      <CompDataPriorityUser
        compType={compType}
        onSubmit={onClose}
        onCancel={onClose}
        onChangeNumColumns={(n) => n > 0 && setNumCols(n)}
        actionsPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
        numCols={numCols}
      />
    </Dialog>
  );
};
