import React from 'react';

import { TermsAndPrivacyLinks } from '@hcs/auth';
import { Card } from '@hcs/design-system';
import { SelfServiceSetupCreditCard } from '@hcs/self-service';

import styles from './PexpSelfServiceSetupPage.module.css';

const dataHcName = 'pexp-self-setup-page';
export const PexpSelfServiceSetupPage = () => {
  return (
    <>
      <Card
        dataHcEventSection="Self Serve Credit Card Entry"
        dataHcName={dataHcName}
        className={styles.Card}
      >
        <SelfServiceSetupCreditCard />
      </Card>
      <TermsAndPrivacyLinks />
    </>
  );
};
