import React, { ReactNode } from 'react';

import { TextButton, Tooltip } from '@hcs/design-system';

import {
  KeywordSearchListingRemarks,
  KeywordSearchListingRemarksProps,
} from './KeywordSearchListingRemarks';

interface KeywordSearchListingRemarksPopoverProps
  extends KeywordSearchListingRemarksProps {
  trigger?: ReactNode;
}
const dataHcName = 'keyword-search-listing-remarks-tooltip';
export const KeywordSearchListingRemarksTooltip = ({
  trigger,
  ...props
}: KeywordSearchListingRemarksPopoverProps) => {
  return (
    <Tooltip
      showOnMouseEnter
      {...props}
      dataHcName={dataHcName}
      trigger={
        trigger || (
          <TextButton
            dataHcName={`${dataHcName}-button`}
            label="View Listing Remarks"
          />
        )
      }
      isLargeTooltip
      description={<KeywordSearchListingRemarks {...props} />}
    />
  );
};
