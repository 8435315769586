import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MarketStateSliceState, MarketStatsSearchValue } from '@hcs/types';
import { resetOnLogoutAction } from '@hcs/utils';

const initialState: MarketStateSliceState = {
  msaSearch: null,
  zipSearch: null,
};

export const marketStateSearchSlice = createSlice({
  name: 'marketStateSearch',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(resetOnLogoutAction, () => initialState),
  reducers: {
    updateMsaSearch: (state, action: PayloadAction<MarketStatsSearchValue>) => {
      state.msaSearch = action.payload;
    },
    updateZipSearch: (state, action: PayloadAction<MarketStatsSearchValue>) => {
      state.zipSearch = action.payload;
    },
  },
});
