import { AgileProductGroupings } from '@hcs/types';
import { AgileBillingComponents } from '@hcs/types';

/**
 * Billing codes (uoms) are used to determine
 * which subscriptions a user/org has access to within
 * Order Manager
 */
export const AGILE_PRODUCT_BILLING_CODES_BY_PRODUCT_NAME = {
  [AgileProductGroupings.AgileInsights]: [AgileBillingComponents.AgileInsights],
  [AgileProductGroupings.AgileEvaluation]: [
    AgileBillingComponents.AgileEvaluationExterior,
    AgileBillingComponents.AgileEvaluationInterior,
    AgileBillingComponents.AgileEvaluationExterior2Day,
  ],
  [AgileProductGroupings.AgileInspection]: [
    AgileBillingComponents.AgileInspectionExterior,
    AgileBillingComponents.AgileInspectionInterior,
  ],
};

export const AGILE_PRODUCT_NAMES_BY_BILLING_COMPONENT: {
  [key in AgileBillingComponents]: AgileProductGroupings;
} = {
  [AgileBillingComponents.AgileInsights]: AgileProductGroupings.AgileInsights,
  [AgileBillingComponents.AgileEvaluationExterior]:
    AgileProductGroupings.AgileEvaluation,
  [AgileBillingComponents.AgileEvaluationInterior]:
    AgileProductGroupings.AgileEvaluation,
  [AgileBillingComponents.AgileEvaluationExterior2Day]:
    AgileProductGroupings.AgileEvaluation,
  [AgileBillingComponents.AgileInspectionExterior]:
    AgileProductGroupings.AgileInspection,
  [AgileBillingComponents.AgileInspectionInterior]:
    AgileProductGroupings.AgileInspection,
};
