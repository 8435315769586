import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import {
  PropertyBrokerageCredit,
  useCompPropertyStateConfigs,
} from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { ListingStatusCerberus, ListingStatusNormalized } from '@hcs/types';
import {
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { ReportId } from '@hcs/types';
import { formatMissing, formatSqFt } from '@hcs/utils';
import { listingStatusNormalized } from '@hcs/utils';

import { Details, Price } from '../../components-deprecated';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';

import styles from './SubjectPropertyCard.module.css';

interface Props {
  reportId: ReportId;
  showMarketPrice?: boolean;
  showRentalValue?: boolean;
}
const dataHcName = 'subject-property-card-content';
export const SubjectPropertyCardContent = ({
  reportId,
  showMarketPrice,
  showRentalValue,
}: Props) => {
  const marketPriceFieldConfigs = useCompPropertyStateConfigs(showRentalValue);
  const { Display: MarketPriceDisplay } =
    marketPriceFieldConfigs[PropertyStateFields.currentPrice];
  const { Display: MarketPriceDateDisplay } =
    marketPriceFieldConfigs[PropertyStateFields.currentStatusDate];
  const { Display: MarketPriceStatusDisplay } =
    marketPriceFieldConfigs[PropertyStateFields.currentStatus];
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!subjectDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  const subjectSchema = subjectDocument.data;
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectSchema.propertyState,
  };
  const marketPriceStatus: ListingStatusNormalized | undefined =
    listingStatusNormalized(
      showRentalValue
        ? getPropertyStateFieldValue(
            PropertyStateFields.currentStatusRental,
            propertyStateArgs
          ) || ListingStatusCerberus.Closed
        : getPropertyStateFieldValue(
            PropertyStateFields.currentStatus,
            propertyStateArgs
          ) || ListingStatusCerberus.Closed
    );
  return (
    <>
      <Details
        dataHcName={`${dataHcName}-details`}
        location={subjectSchema.propertyState.location}
        attributes={[
          `${formatMissing(
            getPropertyStateFieldValue(
              PropertyStateFields.bedrooms,
              propertyStateArgs
            )
          )} Bd`,
          `${formatMissing(
            getPropertyStateFieldValue(
              PropertyStateFields.bathrooms,
              propertyStateArgs
            )
          )} Ba`,
          `${formatSqFt(
            getPropertyStateFieldValue(
              PropertyStateFields.livingArea,
              propertyStateArgs
            )
          )}`,
        ]}
      />
      {showMarketPrice && (
        <Price
          dataHcName={`${dataHcName}-price`}
          price={<MarketPriceDisplay propertyStateArgs={propertyStateArgs} />}
          date={
            <MarketPriceDateDisplay propertyStateArgs={propertyStateArgs} />
          }
          label={
            <MarketPriceStatusDisplay propertyStateArgs={propertyStateArgs} />
          }
          listingStatusColor={marketPriceStatus}
        />
      )}
      <div className={styles.Legal}>
        <PropertyBrokerageCredit
          propertyStateArgs={propertyStateArgs}
          isRental={false}
        />
      </div>
    </>
  );
};
