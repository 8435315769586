import React, { useState } from 'react';

import { Checkbox } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { usePatchUiPreferencesForUser } from '@hcs/huell';
import { MeaningfulEventTypes, PreferencesKeys } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useRefreshReportData } from '../../hooks/useRefreshReportData';

import { dataHcEventSectionRefreshReport } from './RefreshReport';

import styles from './RefreshReport.module.css';

interface Props {
  reportId: ReportId;
  isEditable?: boolean;
  className?: string;
  actionsPortalIdRender?: string;
  onSuccess?: VoidFunction;
}

const dataHcName = 'refresh-report-confirm';
export const RefreshReportConfirm = ({
  reportId,
  onSuccess,
  actionsPortalIdRender,
}: Props) => {
  const refreshDataMutation = useRefreshReportData({ onSuccess });
  const [skipWarningRefreshComps, setSkipWarningRefreshComps] = useState(false);
  const { mutate: patchPreferencesForUser } = usePatchUiPreferencesForUser();

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSectionRefreshReport}
      className={styles.RefreshReport}
    >
      <div data-hc-name={`${dataHcName}-message`}>
        Subject property and comparable data will be updated. Any prior
        adjustments made will be saved. This process can take up to a minute.
      </div>

      <Checkbox
        checked={skipWarningRefreshComps}
        dataHcName={`${dataHcName}-dont-show`}
        className={styles.DontShow}
        label="Don't show this again"
        onChange={() => {
          setSkipWarningRefreshComps(!skipWarningRefreshComps);
        }}
      />
      <ActionButtons
        actions={[
          {
            dataHcName: `${dataHcName}-refresh-button`,
            dataHcEventSection: dataHcEventSectionRefreshReport,
            dataHcEventName: 'Refresh Report Data',
            dataHcEventType: MeaningfulEventTypes.Goal,
            label: 'Refresh',
            loading: refreshDataMutation.isLoading,
            onClick: () => {
              if (skipWarningRefreshComps) {
                patchPreferencesForUser?.([
                  {
                    op: 'add',
                    path: `/${PreferencesKeys.AckRefreshComps}`,
                    value: true,
                  },
                ]);
              }
              refreshDataMutation.mutate(reportId);
            },
          },
        ]}
        dataHcName={`${dataHcName}-actions`}
        portalIdRender={actionsPortalIdRender}
      />
    </div>
  );
};
