import { DocumentRoles, NearbyFilteredDocument, ReportId } from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useNearbyFilteredDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<NearbyFilteredDocument>(
    reportId,
    DocumentRoles.NearbyFiltered
  );
};
