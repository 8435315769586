import React from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import {
  ArrowDownCircledIcon,
  ArrowUpCircledIcon,
  CheckedCircledIcon,
} from '@hcs/design-system';
import { AvmQuality } from '@hcs/types';
import { ReportId } from '@hcs/types';
import {
  formatFsdAsPercentage,
  formatMoney,
  formatPricePerSqFt,
} from '@hcs/utils';
import { capitalizeFirstLetter } from '@hcs/utils';

import { useReportHouseCanaryValue } from '../../../hooks/useReportHouseCanaryValue';

import { AvmBreakdownBarChart } from './AvmBreakdownBarChart';

import styles from './AvmBreakdownChart.module.css';

const dataHcName = 'avm-breakdown-chart';

const fixedData = [
  { color: '#D9EFB0', bar: 1, value: 10, height: 14 },
  { color: '#D9EFB0', bar: 2, value: 20, height: 20 },
  { color: '#D9EFB0', bar: 3, value: 30, height: 36 },
  { color: '#D9EFB0', bar: 4, value: 40, height: 50 },
  { color: '#CDECCD', bar: 5, value: 50, height: 74 },
  { color: '#CDECCD', bar: 6, value: 60, height: 98 },
  { color: '#CDECCD', bar: 7, value: 70, height: 112 },
  { color: '#CDECCD', bar: 8, value: 60, height: 98 },
  { color: '#CDECCD', bar: 9, value: 50, height: 74 },
  { color: '#9ED9C3', bar: 10, value: 40, height: 50 },
  { color: '#9ED9C3', bar: 11, value: 30, height: 36 },
  { color: '#9ED9C3', bar: 12, value: 20, height: 20 },
  { color: '#9ED9C3', bar: 13, value: 10, height: 14 },
];

interface Props {
  reportId: ReportId;
}

export const AvmBreakdownChart = ({ reportId }: Props) => {
  const { data: hcValue, isInitialLoading } =
    useReportHouseCanaryValue(reportId);

  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  // We need to intervals one between start and mean and another
  // between mean and the end
  const low = hcValue?.value?.valueLower;
  const median = hcValue?.value?.value;
  const high = hcValue?.value?.valueUpper;
  const meanIndex = Math.round(fixedData.length / 2) - 1; // 6
  const firstInterval = low && median ? (median - low) / meanIndex : 0;
  const secondInterval = high && median ? (high - median) / meanIndex : 0;
  const avmQuality = hcValue?.value.quality;
  const fsd = hcValue?.value.fsd;

  const data = fixedData.map((dataItem, idx) => {
    let barValue: number;
    if (idx === 0) {
      barValue = low || 0;
    } else if (idx === meanIndex) {
      barValue = median || 0;
    } else if (idx === fixedData.length - 1) {
      barValue = high || 0;
    } else if (idx > 0 && idx < meanIndex && low) {
      barValue = idx * firstInterval + low;
    } else if (median) {
      barValue = (idx - meanIndex) * secondInterval + median;
    } else {
      barValue = 0;
    }
    return { ...dataItem, barValue };
  });

  return (
    <div data-hc-name={dataHcName} className={styles.ChartContainer}>
      <AvmBreakdownBarChart data={data} width={528} height={112} />
      <div
        data-hc-name={`${dataHcName}-lower-price-pillar`}
        className={classNames(styles.LeftGroupBar, styles.GroupBar)}
      >
        <div
          className={styles.PillarHeading}
          data-hc-name={`${dataHcName}-pillar-heading`}
        >
          <ArrowDownCircledIcon
            width={16}
            height={16}
            dataHcName={`${dataHcName}-low-icon`}
          />
          Low
        </div>
        <div
          data-hc-name={`${dataHcName}-lower-price-pillar-amount`}
          className={styles.Amount}
        >
          {formatMoney(hcValue?.value?.valueLower)}
        </div>
        <div
          data-hc-name={`${dataHcName}-lower-price-pillar-per-sq-foot`}
          className={styles.PerSqrFoot}
        >
          {formatPricePerSqFt(hcValue?.calculatedFields.valueLowerPerSqFt)}
        </div>
      </div>
      <div
        className={classNames(styles.MiddleGroupBar, styles.GroupBar)}
        data-hc-name={`${dataHcName}-price-mean-pillar`}
      >
        <div
          className={styles.PillarHeading}
          data-hc-name={`${dataHcName}-pillar-heading`}
        >
          <CheckedCircledIcon
            width={16}
            height={16}
            dataHcName={`${dataHcName}-check-icon`}
          />
          HC Estimated Value
        </div>
        <div
          data-hc-name={`${dataHcName}-price-mean-pillar-amount`}
          className={styles.Amount}
        >
          {formatMoney(hcValue?.value?.value)}
        </div>
        <div
          data-hc-name={`${dataHcName}-price-mean-pillar-price-per-sq-foot`}
          className={styles.PerSqrFoot}
        >
          {formatPricePerSqFt(hcValue?.calculatedFields.valuePerSqFt, true)}
        </div>
      </div>
      <div
        data-hc-name={`${dataHcName}-upper-price-pillar`}
        className={classNames(styles.RightGroupBar, styles.GroupBar)}
      >
        <div
          className={styles.PillarHeading}
          data-hc-name={`${dataHcName}-pillar-heading`}
        >
          <ArrowUpCircledIcon
            width={16}
            height={16}
            dataHcName={`${dataHcName}-high-icon`}
          />
          High
        </div>
        <div
          data-hc-name={`${dataHcName}-upper-price-pillar-amount`}
          className={styles.Amount}
        >
          {formatMoney(hcValue?.value?.valueUpper)}
        </div>
        <div
          data-hc-name={`${dataHcName}-upper-price-pillar-price-per-sq-foot`}
          className={styles.PerSqrFoot}
        >
          {formatPricePerSqFt(hcValue?.calculatedFields.valueUpperPerSqFt)}
        </div>
      </div>
      <div data-hc-name={`${dataHcName}-footer`} className={styles.ChartFooter}>
        <label> HouseCanary Estimated Value</label>
        <div
          data-hc-name={`${dataHcName}-avm-quality-section`}
          className={styles.AvmQualitySection}
        >
          {avmQuality && fsd != null && (
            <>
              <span
                data-hc-name={`${dataHcName}-avm-quality`}
                className={classNames(styles.Confidence, {
                  [styles.high]: avmQuality === AvmQuality.High,
                  [styles.average]: avmQuality === AvmQuality.Medium,
                  [styles.low]: avmQuality === AvmQuality.Low,
                })}
              >
                {capitalizeFirstLetter(avmQuality)} Confidence
              </span>{' '}
              <span
                data-hc-name={`${dataHcName}-fsd-percentage`}
                className={styles.Fsd}
              >
                {formatFsdAsPercentage(fsd)}
              </span>
              <div data-hc-name={`${dataHcName}-fsd`} className={styles.Fsd}>
                FSD: {fsd.toFixed(2)}
              </div>
            </>
          )}
        </div>
        The HouseCanary forecast standard deviation (FSD) is a statistical
        estimate of model uncertainty. It is a quantity used to create the upper
        and lower bounds on the value estimate. The value range represents the
        quantity such that the range will actually capture the subsequent
        arm&apos;s-length sale price approximately 68% of the time (one standard
        deviation).
      </div>
    </div>
  );
};
