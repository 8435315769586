import { gql } from 'graphql-request';

export const FRAGMENT_MSA_RPI_CORE = gql`
  fragment MsaRpiCore on MsaRpi {
    forecastCalcs(propertyType: SINGLE_FAMILY_DETACHED) {
      risk1YearLoss
      return1Year
    }
    timeSeriesCurrent
  }
`;

export const FRAGMENT_ZIP_RPI_CORE = gql`
  fragment ZipRpiCore on ZipRpi {
    forecastCalcs(propertyType: SINGLE_FAMILY_DETACHED) {
      risk1YearLoss
      return1Year
    }
    timeSeriesCurrent
  }
`;

export const FRAGMENT_RPI_TIME_SERIES = gql`
  fragment RpiTimeSeries on RpiTimeSeriesElement {
    month
    valueIndexed
  }
`;
