import React from 'react';

import { Anchor } from '@hcs/design-system';
import { AnchorProps, PropertyStateLocation } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';

interface GoogleMapsLinkProps
  extends Omit<
    AnchorProps,
    'dataHcName' | 'href' | 'target' | 'rel' | 'children'
  > {
  location: PropertyStateLocation['location'];
}
const dataHcName = 'google-maps-link';
export const GoogleMapsLink = ({
  location,
  ...anchorProps
}: GoogleMapsLinkProps) => {
  return (
    <Anchor
      {...anchorProps}
      disabled={!location || anchorProps.disabled}
      dataHcName={dataHcName}
      href={`https://www.google.com/search?q=${formatFullAddress(location)}`}
      target="_blank"
      rel="noreferrer"
    >
      View on Google Maps
    </Anchor>
  );
};
