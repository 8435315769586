import React, { ReactNode } from 'react';

import { TextButton } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { useActiveState } from '@hcs/hooks';
import { ReportId } from '@hcs/types';

import { SubjectDetailsHeaderDeprecated } from '../../features/SubjectDetailsHeader';
import {
  dataHcEventSectionSubjectTransactionHistorySale,
  SubjectTransactionHistory,
} from '../../features/SubjectTransactionHistory/SubjectTransactionHistory';
import { SubjectPropertySummaryHeader } from '../SubjectPropertySummaryHeader';

interface Props {
  reportId: ReportId;
  className?: string;
  trigger?: ReactNode;
}
const dataHcName = 'report-property-transaction-history-launcher';
export const SubjectTransactionHistoryLauncher = ({
  reportId,
  className,
  trigger,
}: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  return (
    <>
      {trigger ? (
        <span
          onClick={handleOpen}
          data-hc-name={dataHcName}
          data-hc-event-section={
            dataHcEventSectionSubjectTransactionHistorySale
          }
          style={{ cursor: 'pointer' }}
        >
          {trigger}
        </span>
      ) : (
        <TextButton onClick={handleOpen} dataHcName={dataHcName}>
          View Transaction History
        </TextButton>
      )}
      <Dialog
        dataHcName={dataHcName}
        type="large"
        title={
          <ExperienceFlag
            experienceFlagId="PEXP_REDESIGN"
            now={<SubjectDetailsHeaderDeprecated reportId={reportId} />}
            next={<SubjectPropertySummaryHeader reportId={reportId} />}
          />
        }
        theme={{
          Dialog: className,
        }}
        active={active}
        onClose={() => {
          handleClose();
        }}
      >
        <SubjectTransactionHistory reportId={reportId} />
      </Dialog>
    </>
  );
};
