import React, { useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';
import classNames from 'classnames';
import mapboxgl from 'mapbox-gl';

import { MapMarker } from '@hcs/design-system';
import { MarkerAnimationStep } from '@hcs/types';
import { listingStatusNormalized } from '@hcs/utils';

import {
  PropertyMarkerProps,
  PropertyMarkerTypes,
} from './PropertyMarker.types';

import styles from './PropertyMarker.module.css';

export const PropertyMarker = ({
  geoLocation,
  markerId,
  pulse,
  label,
  className,
  selected,
  onClick,
  offsetLeft,
  offsetTop,
  animationSteps,
  moveToTop,
  listingStatus,
  type = PropertyMarkerTypes.Property,
  dataHcName = 'property-marker',
  shape = 'pin',
  hoverable = true,
  addElementToRefList,
  renderedInMap = true,
  mapMarkerTheme,
}: PropertyMarkerProps) => {
  const statusNormalized =
    listingStatus && listingStatusNormalized(listingStatus);
  const [curAnimationStep, setCurAnimationStep] =
    useState<MarkerAnimationStep | null>(null);

  const offsetX = offsetLeft || curAnimationStep?.offset.offsetLeft || 0;
  const offsetY = offsetTop || curAnimationStep?.offset.offsetTop || 0;
  const offset: mapboxgl.Offset = [offsetX, offsetY];

  // step through animation steps on mount
  useEffect(() => {
    if (!animationSteps) return;

    setCurAnimationStep(animationSteps[0] || null);

    for (let i = 1; i < animationSteps.length; i++) {
      ((ind) => {
        setTimeout(() => {
          setCurAnimationStep(animationSteps[i] || null);
        }, 50 * ind);
      })(i);
    }
  }, []);

  if (geoLocation.latitude && geoLocation.longitude) {
    const marker = (
      <MapMarker
        addElementToRefList={addElementToRefList}
        className={classNames(
          styles.MarkerHover,
          styles.Marker,
          styles[type],
          className,
          {
            [styles.selected]: type === PropertyMarkerTypes.Comp && selected,
            [styles.topLayerMarker]: pulse || moveToTop,
          }
        )}
        theme={mapMarkerTheme}
        dataHcName={dataHcName}
        pulse={pulse}
        onClick={onClick}
        hoverable={hoverable}
        shape={shape}
      >
        <div
          className={styles.MarkerLabel}
          data-hc-name={`${dataHcName}-label`}
        >
          {label}
        </div>
        {statusNormalized && (
          <div
            className={classNames(styles.ListingStatusIndicator, {
              [styles.Closed]: statusNormalized === 'Closed',
              [styles.Pending]: statusNormalized === 'Pending',
              [styles.Active]: statusNormalized === 'Active',
            })}
          />
        )}
      </MapMarker>
    );
    if (renderedInMap) {
      return (
        <Marker
          key={markerId}
          offset={offset}
          latitude={geoLocation.latitude}
          longitude={geoLocation.longitude}
        >
          {marker}
        </Marker>
      );
    } else {
      return marker;
    }
  }
  return null;
};
