import { CompTypes } from '@hcs/types';
import {
  CompIdentifier,
  CompsFarmPhotosDocument,
  DocumentRoles,
  RentalCompsFarmPhotosDocument,
  ReportId,
} from '@hcs/types';

import { getCompTransientDocumentId } from '../utils';

import { useDocumentById } from './useDocumentById';
import { useComp } from '.';

export const useCompPhotosDocument = (
  reportId: ReportId,
  compIdentifier: CompIdentifier
) => {
  const compQuery = useComp(reportId, compIdentifier);
  const documentId = getCompTransientDocumentId(
    compIdentifier.type === 'appraisalComp'
      ? DocumentRoles.AppraisalCompPhotos
      : compIdentifier.compType === CompTypes.Rental
      ? DocumentRoles.RentalCompsFarmPhotos
      : DocumentRoles.CompsFarmPhotos,
    compQuery.data?.compSchema.compID
  );
  return useDocumentById<
    CompsFarmPhotosDocument | RentalCompsFarmPhotosDocument
  >(reportId, documentId);
};
