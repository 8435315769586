import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { AddressSearchHit } from '@hcs/types';
import { AddressParts } from '@hcs/types';

import { AddressApi } from '../api/addressApi';
import { OrderManagerApi } from '../api/orderManagerApi';

import { QUERY_KEY_ORDER_ITEMS } from './useOrderItems';

interface Params {
  orderId: number;
  itemId: number;
  address: {
    originalAddress: AddressParts;
    updatedAddress: AddressSearchHit | null;
  };
  propertyType: string;
}

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, Params>(
    async (payload) => {
      const updatedAddress = payload.address.updatedAddress;
      const updateOrderPayload = {
        id: payload.itemId,
        propertyType: payload.propertyType,
      };

      if (updatedAddress) {
        const parsedAddress = await AddressApi.parseAddress({
          addressId: updatedAddress.id,
          addressFullLine: updatedAddress.fields.fullLine,
          itemSupportingDocumentFields: null,
        });

        return await OrderManagerApi.updateOrder(payload.orderId, {
          ...updateOrderPayload,
          ...parsedAddress,
        });
      } else {
        return await OrderManagerApi.updateOrder(payload.orderId, {
          ...updateOrderPayload,
          ...payload.address.originalAddress,
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY_ORDER_ITEMS]);
      },
    }
  );
};
