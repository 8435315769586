import React from 'react';
import classNames from 'classnames';

import styles from './ResizeHandleVertical.module.css';

export interface ResizeHandleVerticalProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  onClick?: VoidFunction;
}
export const ResizeHandleVertical = ({
  dataHcName,
  className,
  onClick,
}: ResizeHandleVerticalProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ResizeHandleVertical, className)}
      onClick={onClick}
    >
      <div className={styles.Inner} />
    </div>
  );
};
