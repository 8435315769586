import React, { ReactNode } from 'react';
import { IntercomProvider, IntercomProviderProps } from 'react-use-intercom';

import { useIntercomId } from '../../hooks';

interface HelpChatProviderProps extends Omit<IntercomProviderProps, 'appId'> {
  children: ReactNode;
}
// note that you can't use the useIntercom hook inside the same component as the provider (i.e. this component)
export const HelpChatProvider = ({
  children,
  ...restProps
}: HelpChatProviderProps) => {
  const INTERCOM_APP_ID = useIntercomId();
  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID || ''}
      autoBoot={INTERCOM_APP_ID !== undefined}
      {...restProps}
    >
      {children}
    </IntercomProvider>
  );
};
