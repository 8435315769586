import React, { useMemo } from 'react';
import classNames from 'classnames';

import { Pagination } from '@hcs/design-system';
import { CompsListTypes, CompTypes } from '@hcs/types';
import { CompIdentifier, ReportId } from '@hcs/types';
import { NavigateToPropertyPreviewFn } from '@hcs/types';

import { useCompsList } from '../../../hooks/useCompsList';
import { getCompAdjustFieldOrder } from '../../../utils';
import { CompCompare, CompCompareProps } from '../../CompCompare';

import styles from '../CompsList.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  compsListType: CompsListTypes;
  className?: string;
  isAdjustable?: boolean;
  onChangeIsAdjusting?: CompCompareProps['onChangeIsAdjusting'];
  actionsPortalIdRender?: string;
  navigateToPropertyPreview: NavigateToPropertyPreviewFn;
}
const dataHcName = 'comps-list-compare';
export const CompsListCompare = ({
  reportId,
  className,
  compType,
  compsListType,
  actionsPortalIdRender,
  isAdjustable = true,
  onChangeIsAdjusting,
  navigateToPropertyPreview,
}: Props) => {
  const {
    state: { compsListQuery, pagination },
    actions: { compsListPageNext, compsListPagePrev },
  } = useCompsList({ reportId, compType, compsListType });
  const compIdentifiers = useMemo<CompIdentifier[]>(() => {
    return (
      compsListQuery.data.map((compSchema) => {
        return compsListType === 'appraisal'
          ? { type: 'appraisalComp', compType, compId: compSchema.compID }
          : {
              type: 'schema',
              compType,
              compSchema,
            };
      }) || []
    );
  }, [compsListQuery.data, compsListType, compType]);
  const fields = useMemo(() => {
    return getCompAdjustFieldOrder(compIdentifiers?.[0]);
  }, [compIdentifiers]);

  return (
    <>
      <CompCompare
        reportId={reportId}
        compType={compType}
        fields={fields}
        actionsPortalIdRender={actionsPortalIdRender}
        className={classNames(styles.Scroll, className)}
        navigateToPropertyPreview={navigateToPropertyPreview}
        onChangeIsAdjusting={onChangeIsAdjusting}
        isAdjustable={isAdjustable}
        compIdentifiers={compIdentifiers}
      />
      {pagination && (
        <Pagination
          dataHcName={`${dataHcName}-pagination`}
          className={styles.Pagination}
          onClickNext={compsListPageNext}
          onClickPrev={compsListPagePrev}
          {...pagination}
        />
      )}
    </>
  );
};
