import React, { useState } from 'react';
import classNames from 'classnames';
import { Operation } from 'fast-json-patch';

import { Toggle } from '@hcs/design-system';
import { ChartForecastLine } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import {
  ForecastChartFields,
  ForecastChartPaths,
  ForecastDatumMetadata,
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportId,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { ForecastStats } from '../../features/ForecastStats';
import { useDocumentPatch, useReportPermissions } from '../../hooks';
import { useForecastChartBlockDocument } from '../../hooks/useForecastChartBlockDocument';
import { useForecastChartZipDocument } from '../../hooks/useForecastChartZipDocument';
import { useForecastStats } from '../../hooks/useForecastStats';
import { useReportConfig } from '../../hooks/useReportConfig';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './ForecastChart.module.css';

const PATH_SELECTED: ForecastChartPaths = '/data/metaData/selected';
const dataHcName = 'forecast-chart';
interface ForecastChartReportProps {
  reportId: ReportId;
  className?: string;
}
export const FORECAST_CHART_FEATURES = [
  ReportFeatures.SubjectForecastBlock,
  ReportFeatures.SubjectForecastZipcode,
];
export const ForecastChart = ({
  reportId,
  className,
}: ForecastChartReportProps) => {
  const [readOnlyChartType, setReadOnlyChartType] = useState<
    'zip' | 'block' | undefined
  >();
  const { data: reportConfig } = useReportConfig(reportId);
  const reportPermissionsQuery = useReportPermissions(reportId);
  const forecastStatsQuery = useForecastStats(reportId);
  const forecastChartBlockQuery = useForecastChartBlockDocument(reportId);
  const forecastChartZipQuery = useForecastChartZipDocument(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);

  // Combine query metadata for easy loading checking
  const combinedQueryStatus = combineUseQueryResult([
    forecastStatsQuery,
    forecastChartBlockQuery,
    forecastChartZipQuery,
    reportPermissionsQuery,
  ]);
  const forecastType =
    readOnlyChartType ||
    (reportConfig?.reportFeaturesSupport[ReportFeatures.SubjectForecastBlock] &&
    forecastChartBlockQuery.data?.data?.metaData.selected
      ? 'block'
      : 'zip');
  const chartData =
    forecastType === 'block'
      ? forecastChartBlockQuery.data?.data?.chart
      : forecastChartZipQuery.data?.data?.chart;
  if (!combinedQueryStatus.isFetched) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const handleForecastTypeChange = (chartType: 'zip' | 'block') => {
    if (reportPermissionsQuery.data?.isEditable) {
      if (
        forecastChartZipQuery.data?.documentId &&
        forecastChartBlockQuery.data?.documentId
      ) {
        const zipOperation: Operation = {
          op: 'add',
          path: PATH_SELECTED,
          value: chartType === 'zip',
        };

        const zipPatch = {
          document: forecastChartZipQuery.data,
          reportId,
          operations: [zipOperation],
        };

        const blockOperation: Operation = {
          op: 'add',
          path: PATH_SELECTED,
          value: chartType === 'block',
        };

        const blockPatch = {
          document: forecastChartBlockQuery.data,
          reportId,
          operations: [blockOperation],
        };

        documentPatchMutation.mutate(zipPatch);
        documentPatchMutation.mutate(blockPatch);
      }
    } else {
      setReadOnlyChartType(chartType);
    }
  };

  if (!chartData?.data?.length) {
    return (
      <NullState title="Forecast Unavailable" dataHcName={`${dataHcName}-null`}>
        Sorry! There is not enough data to generate the forecast - but our data
        improves everyday. Please check again later!
      </NullState>
    );
  }

  return (
    <div
      data-hc-name={`${dataHcName}-report`}
      className={classNames(styles.ForecastChartReport, className)}
    >
      <ChartForecastLine<ForecastChartFields, ForecastDatumMetadata>
        currentMonthIndex={chartData.currentMonthIndex}
        dataHcName={dataHcName}
        data={chartData}
      />
      <ForecastStats reportId={reportId} forecastType={forecastType} />
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={FORECAST_CHART_FEATURES}
        reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
      >
        <div className={styles.Controls}>
          <Toggle
            dataHcName={`${dataHcName}-toggle`}
            options={[
              {
                label: 'Zip-Code',
                value: 'zip',
              },
              {
                label: 'Block',
                value: 'block',
              },
            ]}
            value={forecastType}
            onChange={handleForecastTypeChange}
          />
        </div>
      </ReportFeaturesSupported>
    </div>
  );
};
