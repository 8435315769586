import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AddressFormFields, OrderViaAddressEntry } from '@hcs/types';

import { AddressApi } from '../api/addressApi';
import { NewOrderApi, OrderSubmitEntryError } from '../api/newOrderApi';

import { QUERY_KEY_ORDERS } from './useOrders';

export interface Params {
  orderViaAddressEntry: OrderViaAddressEntry;
  preParsedItems: AddressFormFields[];
}

export const useNewOrderSubmitEntry = () => {
  const queryClient = useQueryClient();

  return useMutation<void, OrderSubmitEntryError, Params>(
    async ({ orderViaAddressEntry: addOrderWithItems, preParsedItems }) => {
      const parsedAddresses = preParsedItems.map(
        async (enteredAddress: AddressFormFields) => {
          return await AddressApi.parseAddress(enteredAddress);
        }
      );

      const parsedAddressesResponse = await Promise.all(parsedAddresses || []);

      const body: OrderViaAddressEntry = {
        ...addOrderWithItems,
        items: parsedAddressesResponse,
      };

      return await NewOrderApi.newOrderSubmitEntry(body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY_ORDERS]);
      },
    }
  );
};
