import React, { ReactNode } from 'react';

import styles from './QuickFilter.module.css';

export interface QuickFilterProps {
  children: ReactNode;
  dataHcName: string;
}

export const QuickFilter = ({ children, dataHcName }: QuickFilterProps) => {
  return (
    <div className={styles.QuickFilterContainer} data-hc-name={dataHcName}>
      <div
        className={styles.QuickFilterText}
        data-hc-name={`${dataHcName}-text`}
      >
        Quick Filters - Relative to the Subject
      </div>
      <div
        className={styles.QuickFilterButtonContainer}
        data-hc-name={`${dataHcName}-btn-container`}
      >
        {React.Children.count(children) > 1 && (
          <div
            className={styles.QuickFilterLabel}
            data-hc-name={`${dataHcName}-label`}
          >
            +/-
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
