import React from 'react';

import { Tooltip } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { InfoIcon } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItem } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL } from '../../constants';

import { DisplayProps } from '.';

import styles from './DueDate.module.css';

const Display = ({ orderItem }: DisplayProps): JSX.Element | null => {
  return (
    <div data-hc-name={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-due-date`}>
      {formatDateShort(orderItem?.dueDate)}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return (
    <TableHeaderCell {...tableHeaderCellProps}>
      Due
      <Tooltip
        dataHcName={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-due-date-popover`}
        trigger={
          <InfoIcon
            className={styles.InfoIcon}
            size="sm"
            color="neutral-dark-20"
            dataHcName={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-due-date-info-icon`}
          />
        }
        label={
          <span
            data-hc-name={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-due-date-info-text`}
          >
            Orders are due on the provided date by 5:00 PM PST.
          </span>
        }
        showOnMouseEnter
      />
    </TableHeaderCell>
  );
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_ITEM_DUE_DATE_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Due',
  sortable: true,
};
