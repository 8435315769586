import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CerberusStatsFieldConfig,
  CerberusStatsFields,
  CerberusStatsProps,
  CerberusStatsTableCellProps,
  CerberusStatsTableHeaderCellProps,
  CerberusStatsType,
} from '@hcs/types';
import { formatMissing } from '@hcs/utils';

const FIELD = CerberusStatsFields.marketGrade;
type FieldConfig = CerberusStatsFieldConfig<typeof FIELD>;
const label = 'Market Grade';
const labelShort = 'Market Grade';
const getValue: FieldConfig['getValue'] = (cerberusStats) => {
  if (!cerberusStats) {
    return undefined;
  }
  if (
    cerberusStats.type !== CerberusStatsType.ListingStats &&
    cerberusStats.type !== CerberusStatsType.RentalListingStats &&
    cerberusStats.type !== CerberusStatsType.Msa
  ) {
    return cerberusStats?.marketQuality?.grade;
  }
  return;
};
const formatValue: FieldConfig['formatValue'] = (cerberusStats) =>
  formatMissing(getValue(cerberusStats));

// Component for displaying a field from a schema
const Display = ({ cerberusStats }: CerberusStatsProps) => {
  return <>{formatValue(cerberusStats)}</>;
};

const HeaderCell = (props: CerberusStatsTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{label}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  cerberusStats,
  ...tableCellProps
}: CerberusStatsTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display cerberusStats={cerberusStats} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const definition =
  'Grade derived from a HouseCanary proprietary cluster algorithm. One of [A, B, C, D, F] with A being least volatile across market cycles and F being most volatile.';

export const MARKET_GRADE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  getValue,
  formatValue,
  HeaderCell,
  ContentCell,
  Display,
  definition,
};
