import React, { RefObject } from 'react';

const methodFactory = <T>(): [
  (key: string) => undefined | RefObject<T>,
  (key: string) => undefined | RefObject<T>
] => {
  const map = new Map<string, RefObject<unknown>>();
  const setRef = <T>(key: string): RefObject<T> => {
    const ref = React.createRef<T>();
    map.set(key, ref);
    return ref;
  };
  const getRef = <T>(key: string): RefObject<T> | undefined => {
    const ref = map.get(key) as RefObject<T> | undefined;
    return ref;
  };
  return [setRef, getRef];
};

export const useDynamicRefs = <T>(): [
  (key: string) => undefined | RefObject<T>,
  (key: string) => undefined | RefObject<T>
] => {
  return methodFactory<T>();
};
