import React, { CSSProperties } from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { PropertyCard, PropertyCardProps } from '@hcs/property-state';
import { PropertyStateType } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { PropertyCard as PropertyCardDeprecated } from '../../components-deprecated';
import { useSubjectDocument } from '../../hooks/useSubjectDocument';
import {
  SubjectPhotoCarousel,
  SubjectPhotoCarouselProps,
} from '../SubjectPhotoCarousel';

import { SubjectPropertyCardContent } from './SubjectPropertyCardContent';

interface Props
  extends Omit<
    PropertyCardProps,
    'dataHcName' | 'photos' | 'propertyStateArgs'
  > {
  reportId: ReportId;
  showMarketPrice?: boolean;
  style?: CSSProperties;
  onStreetViewToggle?: SubjectPhotoCarouselProps['onStreetViewToggle'];
}
const dataHcName = 'subject-property-card';
export const SubjectPropertyCard = ({
  reportId,
  showMarketPrice,
  style,
  onStreetViewToggle,
  ...propertyCardProps
}: Props) => {
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!subjectDocument) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <PropertyCard
          {...propertyCardProps}
          dataHcName={dataHcName}
          photos={
            <SubjectPhotoCarousel
              reportId={reportId}
              onStreetViewToggle={onStreetViewToggle}
            />
          }
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: subjectDocument.data.propertyState,
          }}
          showBrokerageCredit
        />
      }
      now={
        <PropertyCardDeprecated
          dataHcName={dataHcName}
          className={propertyCardProps.className}
          style={style}
          onClick={propertyCardProps.onClick}
          flat={propertyCardProps.flat}
          onMouseEnter={propertyCardProps.onMouseEnter}
          onMouseLeave={propertyCardProps.onMouseLeave}
          photos={<SubjectPhotoCarousel reportId={reportId} />}
          content={
            <SubjectPropertyCardContent
              reportId={reportId}
              showMarketPrice={showMarketPrice}
            />
          }
        />
      }
    />
  );
};
