import { DexpEndpointLevel } from '@hcs/types';
import { DATA_EXPLORER_URL } from '@hcs/urls';

export const DEXP_REPORTS_PER_PAGE = 20;
export const DEXP_WEB_SOCKET_URL = `${
  window.location.protocol === 'http:' ? 'ws:' : 'wss:'
}//${DATA_EXPLORER_URL.split('://', 2)[1]}/job-updates/`;

export const DEXP_ENDPOINT_LEVEL_LABEL: Record<DexpEndpointLevel, string> = {
  [DexpEndpointLevel.Block]: 'Block',
  [DexpEndpointLevel.Blockgroup]: 'Blockgroup',
  [DexpEndpointLevel.Property]: 'Property',
  [DexpEndpointLevel.Metrodiv]: 'Metrodiv',
  [DexpEndpointLevel.Msa]: 'MSA',
  [DexpEndpointLevel.State]: 'State',
  [DexpEndpointLevel.ValueReport]: 'Value Report',
  [DexpEndpointLevel.Zip]: 'ZIP Code',
};

export const ANALYSIS_RESULTS_URL_STATE_KEY = 'analysis-results-url-state';
