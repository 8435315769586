import React from 'react';
import classNames from 'classnames';

import { Skeleton } from '@hcs/design-system';
import { ReportId } from '@hcs/types';

import {
  AppraisalRiskFactors,
  AppraisalRiskOverallSectionContent,
} from '../../components/AppraisalRisk';
import { useSubjectDocument } from '../../hooks';
import { useAppraisalComparisonDocument } from '../../hooks/useAppraisalComparisonDocument';

import styles from './ReportRiskBreakdown.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}
const dataHcName = 'report-risk-breakdown';
export const ReportRiskBreakdown = ({ reportId, className }: Props) => {
  const { isInitialLoading, data: appraisalCompareDoc } =
    useAppraisalComparisonDocument(reportId);
  const { data: subject } = useSubjectDocument(reportId);
  if (isInitialLoading || !appraisalCompareDoc?.data || !subject) {
    return <Skeleton dataHcName={`${dataHcName}-skeleton`} />;
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ReportRiskBreakdown, className)}
    >
      <div className={styles.Overall}>
        <AppraisalRiskOverallSectionContent
          subject={subject?.data.propertyState}
          appraisalRisk={appraisalCompareDoc.data}
        />
      </div>
      <div>
        <AppraisalRiskFactors
          riskType="value"
          risk={appraisalCompareDoc.data.value}
        />
        <AppraisalRiskFactors
          riskType="comparables"
          risk={appraisalCompareDoc.data.comparables}
        />
      </div>
    </div>
  );
};
