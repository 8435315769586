import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Dialog, DialogAction } from '@hcs/design-system';
import {
  BulkEditBuyBoxFormData,
  MeaningfulEventTypes,
  RangeFilters,
} from '@hcs/types';

import { useBuyBoxBulkEdit } from '../../hooks/useBuyBoxBulkEdit';
import {
  BULK_EDIT_FIELD_GROUP_MAP,
  BULK_EDIT_FIELD_GROUPS,
  getMaxBathYup,
  getMaxBedsYup,
  getMaxGarageSpacesYup,
  getMaxGlaYup,
  getMaxListingPriceYup,
  getMaxLotSizeYup,
  getMaxNumStoriesYup,
  getMaxYearBuiltYup,
  getMinBathYup,
  getMinBedsYup,
  getMinGarageSpacesYup,
  getMinGlaYup,
  getMinListingPriceYup,
  getMinLotSizeYup,
  getMinNumStoriesYup,
  getMinYearBuiltYup,
} from '../../utils';
import { BulkEditField, BuyBoxMultiSelect } from '../BuyBoxInputs';

import { BulkEditSaveError } from './BulkEditSaveError';

import styles from './BulkEditBuyBoxDialog.module.css';

const validationSchema = yup
  .object()
  .shape({
    collectionIds: yup.array().min(1, 'At least one buy box is required'),
    field: yup
      .mixed<keyof RangeFilters>()
      .required('Bulk edit parameter is required'),
    listingPriceMin: getMinListingPriceYup({
      isActiveFieldName: 'listingPriceMinActive',
    }),
    listingPriceMax: getMaxListingPriceYup({
      isActiveFieldName: 'listingPriceMaxActive',
    }),
    listingPriceMinActive: yup.boolean(),
    listingPriceMaxActive: yup.boolean(),
    bedsMin: getMinBedsYup({
      isActiveFieldName: 'bedsMinActive',
    }),
    bedsMax: getMaxBedsYup({
      isActiveFieldName: 'bedsMaxActive',
    }),
    bedsMinActive: yup.boolean(),
    bedsMaxActive: yup.boolean(),
    bathsMin: getMinBathYup({
      isActiveFieldName: 'bathsMinActive',
    }),
    bathsMax: getMaxBathYup({
      isActiveFieldName: 'bathsMaxActive',
    }),
    bathsMinActive: yup.boolean(),
    bathsMaxActive: yup.boolean(),
    glaMin: getMinGlaYup({
      isActiveFieldName: 'glaMinActive',
    }),
    glaMax: getMaxGlaYup({
      isActiveFieldName: 'glaMaxActive',
    }),
    glaMinActive: yup.boolean(),
    glaMaxActive: yup.boolean(),
    lotSizeMin: getMinLotSizeYup({
      isActiveFieldName: 'lotSizeMinActive',
    }),
    lotSizeMax: getMaxLotSizeYup({
      isActiveFieldName: 'lotSizeMaxActive',
    }),
    lotSizeMinActive: yup.boolean(),
    lotSizeMaxActive: yup.boolean(),
    yearBuiltMin: getMinYearBuiltYup({
      isActiveFieldName: 'yearBuiltMinActive',
    }),
    yearBuiltMax: getMaxYearBuiltYup({
      isActiveFieldName: 'yearBuiltMaxActive',
    }),
    yearBuiltMinActive: yup.boolean(),
    yearBuiltMaxActive: yup.boolean(),
    numStoriesMin: getMinNumStoriesYup({
      isActiveFieldName: 'numStoriesMinActive',
    }),
    numStoriesMax: getMaxNumStoriesYup({
      isActiveFieldName: 'numStoriesMaxActive',
    }),
    numStoriesMinActive: yup.boolean(),
    numStoriesMaxActive: yup.boolean(),
    garageSpacesMin: getMinGarageSpacesYup({
      isActiveFieldName: 'garageSpacesMinActive',
    }),
    garageSpacesMax: getMaxGarageSpacesYup({
      isActiveFieldName: 'garageSpacesMaxActive',
    }),
    garageSpacesMinActive: yup.boolean(),
    garageSpacesMaxActive: yup.boolean(),
  })
  .test('one-active-check', (schema) => {
    // validate that AT LEAST ONE of the bounds of the active field have been updated
    // otherwise, there is nothing to be saved
    if (!schema.field) return false;

    return (
      schema[BULK_EDIT_FIELD_GROUP_MAP[schema.field].minActive] === true ||
      schema[BULK_EDIT_FIELD_GROUP_MAP[schema.field].maxActive] === true
    );
  });

const DEFAULT_VALUES = BULK_EDIT_FIELD_GROUPS.reduce<
  DefaultValues<BulkEditBuyBoxFormData>
>((accum, config) => {
  accum[config.minActive] = false;
  accum[config.minField] = null;
  accum[config.maxActive] = false;
  accum[config.maxField] = null;
  return accum;
}, {});

export interface BulkEditBuyBoxDialogProps {
  active: boolean;
  onClose: (saveSuccess: boolean) => void;
}
const dataHcName = 'bulk-edit-box-box-dialog';
export const BulkEditBuyBoxDialog = (props: BulkEditBuyBoxDialogProps) => {
  const { active, onClose } = props;
  const [errorCleared, setErrorCleared] = useState(false);
  const [previousFieldValue, setPreviousFieldValue] = useState<
    keyof RangeFilters | undefined
  >(undefined);
  const {
    mutate: saveEdit,
    error,
    isLoading: isLoadingSave,
    isSuccess,
    isError,
  } = useBuyBoxBulkEdit();

  // changing the name of the watch variable so we don't get a false security positive about using fs.watch (fileSystem watch)
  const { watch: reactHookFormWatch, ...formMethods } =
    useForm<BulkEditBuyBoxFormData>({
      mode: 'onBlur',
      resolver: yupResolver(validationSchema),
      defaultValues: DEFAULT_VALUES,
    });
  const { reset } = formMethods;

  // if the user chooses a new field, we want to reset the form except for the collectionIds and field they've chosen
  const [collectionIdsValue, fieldValue] = reactHookFormWatch([
    'collectionIds',
    'field',
  ]);
  useEffect(() => {
    if (previousFieldValue && fieldValue !== previousFieldValue) {
      reset({
        ...DEFAULT_VALUES,
        collectionIds: collectionIdsValue,
        field: fieldValue,
      });
    }
    setPreviousFieldValue(fieldValue);
  }, [fieldValue, reset, collectionIdsValue, previousFieldValue]);

  const handleClose = useCallback(
    (saveSuccess: boolean) => {
      // reset form with empty values object
      reset(DEFAULT_VALUES);
      onClose(saveSuccess);
    },
    [onClose, reset]
  );

  useEffect(() => {
    if (isSuccess) {
      handleClose(true);
    }
  }, [isSuccess, handleClose]);

  useEffect(() => {
    if (isError) {
      setErrorCleared(false);
    }
  }, [isError]);

  const onSubmit: SubmitHandler<BulkEditBuyBoxFormData> = (formData) => {
    saveEdit(formData);
  };

  // show error dialog if there is a back-end save error AND it's not cleared on front-end
  const showErrorDialog = isError && !errorCleared;

  const actions: DialogAction[] = !showErrorDialog
    ? [
        {
          label: 'Save',
          onClick: () => formMethods.handleSubmit(onSubmit)(),
          dataHcName: `${dataHcName}-save-button`,
          dataHcEventType: MeaningfulEventTypes.Goal,
          dataHcEventName: 'Bulk Edit Buy Box',
          disabled: isLoadingSave || !formMethods.formState.isValid,
        },
      ]
    : [
        {
          label: 'Back',
          onClick: () => setErrorCleared(true),
          dataHcName: `${dataHcName}-back-button`,
          disabled: false,
        },
      ];

  const content = !showErrorDialog ? (
    <div data-hc-name={dataHcName} className={styles.BulkEditBuyBoxForm}>
      <div
        className={styles.InstructionsSection}
        data-hc-name={`${dataHcName}-instructions-section`}
      >
        Please select the parameter you would like to update across all of your
        selected buy boxes.
      </div>
      <div
        className={styles.InstructionsSection}
        data-hc-name={`${dataHcName}-instructions-section`}
      >
        Note that these values will replace any existing values for the
        parameter across all buy boxes that have been selected.
      </div>
      <FormProvider watch={reactHookFormWatch} {...formMethods}>
        <form style={{ height: '400px' }}>
          <BuyBoxMultiSelect />
          <BulkEditField />
        </form>
      </FormProvider>
    </div>
  ) : (
    <BulkEditSaveError className={styles.ErrorMsg} bulkEditError={error} />
  );

  return (
    <Dialog
      dataHcName={dataHcName}
      type="small"
      active={active}
      onClose={() => handleClose(false)}
      title={
        !showErrorDialog
          ? 'Bulk Edit Buy Boxes'
          : "We're sorry, there's been an error."
      }
      actions={actions}
      preventClickOutsideClose
    >
      {content}
    </Dialog>
  );
};
