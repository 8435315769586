import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { AuthapiForgotPasswordRequest } from '@hcs/types';

import { AuthApi } from '../api';

export const useForgotPassword = () => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError<unknown>, AuthapiForgotPasswordRequest>(
    async (forgotPasswordPayload) => {
      await AuthApi.forgotPassword(forgotPasswordPayload);
    },
    {
      onSuccess: () => {
        toastOpen({
          type: 'success',
          title: 'Please check your e-mail for further instructions.',
        });
      },
      onError: (e) => {
        toastOpen({
          type: 'error',
          title: `Forgot Password Error: ${e?.message}`,
        });
      },
    }
  );
};
