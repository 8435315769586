import React, { useState } from 'react';

import {
  FilterRange,
  Input,
  TableCell,
  TableHeaderCell,
} from '@hcs/design-system';
import {
  PropertyStateFilterProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateEditCallbackArgs,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
} from '@hcs/types';
import { formatMissing, numInputStrStrip } from '@hcs/utils';

const FIELD = PropertyStateFields.yearBuilt;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Year Built';
const labelShort = 'Year Built';
const propertyStatePath: PropertyStatePaths = '/propertyDetails/yearBuilt';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.propertyDetails?.yearBuilt;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatMissing(getValue(propertyStateArgs));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Built';
const formatValueShort: FieldConfig['formatValueShort'] = (propertyStateArgs) =>
  formatMissing(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `Built ${formatValue(propertyStateArgs)}`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: PropertyStateEditCallbackArgs<typeof FIELD>) => void;
  className?: string;
}) => {
  const defaultValue = getValue(propertyStateArgs) || null;
  const [value, setValue] = useState(defaultValue);
  const currentYear = new Date().getFullYear();
  const validateYear = (value: number) => {
    let setVal = Number(value);
    if (Number(value) < 1000) {
      setVal = 1000;
    } else if (Number(value) > currentYear) {
      setVal = currentYear;
    }
    return setVal;
  };
  return (
    <Input
      dataHcName="year-build-adjust-input"
      className={className}
      value={value?.toString() || ''}
      onChange={(v) => {
        const valStripped = numInputStrStrip(v);
        const value =
          valStripped && Number(valStripped) > currentYear
            ? currentYear.toString()
            : v;
        const valueOrNull = value === '' ? null : Number(value);
        if (valueOrNull === null || !isNaN(valueOrNull)) {
          setValue(valueOrNull);

          onChange({
            path: propertyStatePath,
            value: (valueOrNull && validateYear(valueOrNull)) || null,
            field: FIELD,
          });
        }
      }}
      maxLength={4}
      onBlur={() => {
        if (value) {
          setValue(validateYear(value));
        }
      }}
    />
  );
};

const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  return (
    <FilterRange
      className={className}
      disabled={disabled}
      maxVal={new Date().getFullYear()}
      minVal={1492}
      initialValue={{
        min: spatialFiltersInput.minYearBuilt
          ? spatialFiltersInput.minYearBuilt.toString()
          : '',
        max: spatialFiltersInput.maxYearBuilt
          ? spatialFiltersInput.maxYearBuilt.toString()
          : '',
      }}
      onBlur={(value) => {
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            minYearBuilt: value.min === '' ? null : Number(value.min),
            maxYearBuilt: value.max === '' ? null : Number(value.max),
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const YEAR_BUILT_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  labelMicro,
  formatValueShort,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
  Filter,
  spatialSortField: SpatialSortField.YearBuilt,
};
