import { CapabilitiesIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, CapabilityApplications, Roles } from '@hcs/types';

export const APP_CONFIG_PLATFORM_ADMIN: AppConfig = {
  name: 'Platform Admin',
  appSlug: AppSlugs.PlatformAdmin,
  applicationKey: CapabilityApplications.InternalPlatformAdmin,
  rootPath: '/platform-admin',
  Icon: CapabilitiesIcon,
  descriptionLong:
    'Manage HouseCanary Platform settings such as Roles, Feature-Flags, Token TTLs, API Components, and Zuora Usage Uploads',
  descriptionShort: 'Manage Platform and Security Settings',
  requiredRole: [Roles.PlatformAdmin],
  feOnlyApp: true,
};

export enum VIEW_PATHS_PLATFORM_ADMIN {
  // Roles
  ROLES = 'roles',
  ROLE = 'roles/:roleId',
  ROLE_DETAILS = 'roles/:roleId/details',
  ROLE_ASSIGNMENTS = 'roles/:roleId/assignments',
  // Settings
  SETTINGS_EXPIRATIONS = 'expiration-settings',
  SETTINGS_API_COMPONENTS = 'api-components',
  SETTINGS_ZUORA_USAGE = 'zuora-usage',
  // Feature Flags
  FEATURE_FLAGS = 'feature-flags',
  // Capability System
  APPLICATIONS = 'applications',
  APPLICATION = 'application/:applicationKey',
  APPLICATION_CAPABILITIES = 'application/:applicationKey/capabilities',
  APPLICATION_ORGANIZATIONS = 'application/:applicationKey/organizations',
  CAPABILITIES = 'capabilities',
  CAPABILITY = 'capability/:capabilityKey',
  CAPABILITY_ENTITLEMENTS = 'capability/:capabilityKey/entitlements',
  CAPABILITY_DEVFLAGS = 'capability/:capabilityKey/devflags',
  CAPABILITY_PERMISSIONS = 'capability/:capabilityKey/permissions',
  CAPABILITY_ORGANIZATIONS = 'capability/:capabilityKey/organizations',
  ENTITLEMENTS = 'entitlements',
  ENTITLEMENT = 'entitlement/:entitlementKey',
  ENTITLEMENT_CAPABILITIES = 'entitlement/:entitlementKey/capabilities',
  ENTITLEMENT_ORGANIZATIONS = 'entitlement/:entitlementKey/organizations',
  ENTITLEMENT_CHANGELOG = 'entitlement/:entitlementKey/changelog',
  DEVFLAGS = 'devflags',
  DEVFLAG = 'devflag/:devflagKey',
  DEVFLAG_CAPABILITIES = 'devflag/:devflagKey/capabilities',
  DEVFLAG_ORGANIZATIONS = 'devflag/:devflagKey/organizations',
  DEVFLAG_CHANGELOG = 'devflag/:devflagKey/changelog',
  PERMISSIONS = 'permissions',
  PERMISSION = 'permission/:permissionKey',
  PERMISSION_CAPABILITIES = 'permission/:permissionKey/capabilities',
  PERMISSION_ROLES = 'permission/:permissionKey/roles',
  ACCROLES = 'accroles',
  ACCROLE = 'accrole/:roleKey',
  ACCROLE_PERMISSIONS = 'accrole/:roleKey/permissions',
  ACCROLE_USERS = 'accrole/:roleKey/users',
  ACCROLE_CHANGELOG = 'accrole/:roleKey/changelog',
}
