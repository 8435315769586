import { VictoryAxisProps } from 'victory';

import { ChartSizes } from '@hcs/types';

export const AXIS_PROPS_DEFAULT_Y: VictoryAxisProps = {
  dependentAxis: true,
};
export const AXIS_PROPS_DEFAULT_X: VictoryAxisProps = {
  dependentAxis: false,
  tickCount: 8,
};

export const CHART_DIMENSIONS = {
  small: {
    width: 238,
    height: 140,
    padding: { top: 5, right: 0, bottom: 45, left: 45 },
  },
  medium: {
    width: 350,
    height: 220,
    padding: { top: 20, right: 10, bottom: 45, left: 65 },
  },
  large: {
    width: 752,
    height: 220,
    padding: { top: 20, right: 10, bottom: 45, left: 75 },
  },
  xlarge: {
    width: 752,
    height: 400,
    padding: { top: 20, right: 10, bottom: 45, left: 85 },
  },
};

const AXIS_STYLES_BASE = {
  axis: { stroke: '#E7E7E7' },
  axisLabel: {
    fontWeight: 900,
    fontFamily: 'Avenir, sans-serif',
    fill: '#929BA4',
  },
  ticks: {
    stroke: '#E7E7E7',
    size: 4,
  },
  tickLabels: {
    fontFamily: 'Avenir, sans-serif',
    fill: '#929BA4',
    fontWeight: 400,
    padding: 3,
  },
};

// Overwrite styles that differ for the Y axis
const AXIS_STYLES_BASE_Y = {
  grid: {
    stroke: '#E7E7E7',
  },
};

const CHART_FONT_SIZES = {
  small: 7,
  medium: 9,
  large: 10,
  xlarge: 10,
};

const buildAxisStylesX = (chartSize: ChartSizes) => {
  const fontSize = CHART_FONT_SIZES[chartSize];
  return {
    ...AXIS_STYLES_BASE,
    axisLabel: {
      ...AXIS_STYLES_BASE.axisLabel,
      padding: CHART_DIMENSIONS[chartSize].padding.bottom - 15,
      fontSize,
    },
    tickLabels: {
      ...AXIS_STYLES_BASE.tickLabels,
      fontSize,
    },
  };
};

export const AXIS_STYLES_X = {
  small: buildAxisStylesX('small'),
  medium: buildAxisStylesX('medium'),
  large: buildAxisStylesX('large'),
  xlarge: buildAxisStylesX('xlarge'),
};

const buildAxisStylesY = (chartSize: ChartSizes) => {
  const xStyles = AXIS_STYLES_X[chartSize];
  return {
    ...xStyles,
    ...AXIS_STYLES_BASE_Y,
    // Don't show gridlines on small charts
    ...(chartSize === 'small'
      ? {
          grid: {
            stroke: 'none',
          },
        }
      : {}),
    axisLabel: {
      ...xStyles.axisLabel,
      padding: CHART_DIMENSIONS[chartSize].padding.left - 15,
    },
  };
};

export const AXIS_STYLES_Y = {
  small: buildAxisStylesY('small'),
  medium: buildAxisStylesY('medium'),
  large: buildAxisStylesY('large'),
  xlarge: buildAxisStylesY('xlarge'),
};
