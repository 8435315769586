import { UseQueryResult } from '@tanstack/react-query';

import {
  PaginatedResponseData,
  PaginationHeaderData,
  PaginationOptions
} from '../Pagination.types';

export interface ZuoraProduct {
  applicationId: number;
  createdOn: string;
  id: number;
  lastUpdatedOn: string;
  name: string;
  zCreatedDate: null;
  zDescription: string;
  zProductId: string;
  zSku: string;
  zUpdatedDate: null;
}
export interface ZuoraInvoiceItem {
  accountingCode: string;
  chargeAmount: number;
  chargeDate: string;
  chargeName: string;
  createdById: string;
  createdDate: string;
  id: string;
  invoiceId: string;
  processingType: number;
  productDescription: string;
  productId: string;
  productName: string;
  quantity: number;
  ratePlanChargeId: string;
  sKU: string;
  serviceEndDate: string;
  serviceStartDate: string;
  subscriptionId: string;
  taxAmount: number;
  taxExemptAmount: number;
  uOM: string;
  unitPrice: number;
  updatedById: string;
  updatedDate: string;
}

export enum ZuoraStatusTypes {
  draft = 'Draft',
  posted = 'Posted',
  cancelled = 'Cancelled'
}

export enum ZuoraOrderDirectionTypes {
  desc = 'DESC',
  asc = 'ASC'
}

export enum ZuoraOrderByTypes {
  invoiceDate = 'InvoiceDate',
  taxExemptAmount = 'TaxExemptAmount',
  updatedDate = 'UpdatedDate',
  creditBalanceAdjustmentAmount = 'CreditBalanceAdjustmentAmount',
  status = 'Status',
  targetDate = 'TargetDate',
  accountId = 'AccountId',
  includedOneTime = 'IncludesOneTime',
  invoiceNumber = 'InvoiceNumber',
  updatedById = 'UpdatedById',
  paymentAmount = 'PaymentAmount',
  id = 'Id',
  taxAmount = 'TaxAmount',
  includeUsage = 'IncludesUsage',
  createdBXyId = 'CreatedById',
  createdDate = 'CreatedDate',
  postedDate = 'PostedDate',
  amount = 'Amount',
  balance = 'Balance',
  refundAmount = 'RefundAmount',
  amountWithoutTaxt = 'AmountWithoutTax',
  adjustmentAmount = 'AdjustmentAmount',
  postedBy = 'PostedBy',
  dueDate = 'DueDate',
  includesRecurring = 'IncludesRecurring'
}

export interface ZuoraRequestParams {
  includeItems: number;
  page: number;
  perPage?: number;
  status?: ZuoraStatusTypes;
  orderBy?: ZuoraOrderByTypes;
  orderDirection?: ZuoraOrderDirectionTypes;
}

export interface ZuoraInvoiceByYearInput {
  status?: ZuoraStatusTypes;
  orderDirection?: ZuoraOrderDirectionTypes;
  page?: number;
  pageSize?: number;
}

export interface ZuoraInvoicesInput extends ZuoraInvoiceByYearInput {
  orderBy?: ZuoraOrderByTypes;
}

export type PaginatedZuoraInvoicesByYear = Omit<
  UseQueryResult<PaginatedResponseData<ZuoraInvoice[]>, unknown>,
  'data' | 'pagination'
> & {
  data:
    | undefined
    | {
        pagination: PaginationOptions & PaginationHeaderData;
        invoiceList: ZuoraInvoice[][];
      };
};

export interface ZuoraInvoice {
  accountId: string;
  adjustmentAmount: number;
  amount: number;
  amountWithoutTax: number;
  balance: number;
  createdById: string;
  createdDate: string;
  creditBalanceAdjustmentAmount: number;
  dueDate: string;
  id: string;
  includesOneTime: boolean;
  includesRecurring: boolean;
  includesUsage: boolean;
  invoiceDate: string;
  invoiceNumber: string;
  paymentAmount: number;
  postedDate: string;
  refundAmount: number;
  status: ZuoraStatusTypes;
  targetDate: string;
  taxAmount: number;
  taxExemptAmount: number;
  updatedById: string;
  updatedDate: string;
  items: ZuoraInvoiceItem[];
  products: ZuoraProduct[];
}

export type ZuoraInvoicesResponse = PaginatedResponseData<ZuoraInvoice[]>;
