import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../../hooks';
import { convertNumArrayToFilterRange, getFilterLabel } from '../../../utils';
import { CompFilterBedrooms } from '../../CompFiltersDeprecated/CompFilterBedrooms';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const COMP_FIELD = PropertyStateFields.bedrooms;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[COMP_FIELD];
const dataHcName = 'comp-filter-button-bedrooms';

export const CompFilterButtonBedrooms = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }

  const filterValue = filterDocument.data.filters?.[COMP_FIELD];
  const active = !!filterValue;
  const label = active
    ? getFilterLabel({
        field: COMP_FIELD,
        value: convertNumArrayToFilterRange(filterValue?.absoluteValue),
      })
    : fieldConfig.labelShort;

  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={label}
      className={className}
      compField={COMP_FIELD}
      content={<CompFilterBedrooms reportId={reportId} compType={compType} />}
      active={active}
    />
  );
};
