import { SelfServeSubscriptionTypes } from '../account-api';
import { AgileProductGroupings, AppSlugs } from '../auth';

export enum BaseEventTypes {
  Click = 'click',
  Blur = 'blur',
}

export enum MeaningfulEventTypes {
  SectionViewed = 'Section Viewed',
  Goal = 'Goal Complete',
}
export type OptionalEngagementProps = Partial<{
  dataHcEventName: string;
  dataHcEventSection: string;
  dataHcEventType: string;
  dataHcEventIgnore: boolean;
}>;

export interface BrowserEventData {
  browser: string;
  browserVersion: string;
  browserHeight: number;
  browserWidth: number;
  os: string;
  osVersion: string;
  userAgent: string;
}

export interface PropertyReportEngagementEventData {
  viewerIsReportOwner?: boolean;
  reportId?: number;
  reportOwnerId?: number;
  reportOwnerOrganizationId?: number;
  reportType?: string;
  reportConfigSlug?: string;
  streetAddress?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  slug?: string;
  hcAddressId?: number;
}

export interface OfferNowEngagementEventData {
  listPrice?: number | null;
  hcAddressId?: number | null;
  streetAddress?: string | null;
  unit?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  slug?: string | null;
  msaId?: string | null;
  countyName?: string | null;
  countyFips?: string | null;
}

export interface ClientAdminEngagementEventData {
  userId?: number;
  orgId?: number;
}

export type EngagementEventData = {
  eventSection?: string;
  device?: BrowserEventData;
  urlSearchParams?: Record<string, string>;
  inputValue?: string;
  dataHcName?: string;
  originalEventType?: string;
  timeEntered?: string;
  timeLeft?: string;
  secondsOnPage?: number;
  property_report?: PropertyReportEngagementEventData;
  offer_now?: OfferNowEngagementEventData;
  requestedProduct?: AppSlugs | AgileProductGroupings;
  self_service_signup_error?: string;
  self_service_credit_card_save_error?: string;
  viewingUser?: ClientAdminEngagementEventData;
  viewingOrg?: ClientAdminEngagementEventData;
  utm?: Record<string, string | null>;
  // Allow arbirary additional fields to be passed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [additionalKey: string]: any;
};

// Attributes that can only be populated when dispatching
export interface EngagementEventBase {
  eventName: string;
  eventType?: BaseEventTypes | MeaningfulEventTypes | string;
  eventData?: EngagementEventData;
  eventSpecificField?: string;
}
// Type for dispatching an event within the app
// Includes overridable globally populated fields
export interface EngagementEvent extends EngagementEventBase {
  // Can optionally override globals
  app?: AppSlugs;
  httpPath?: string; // This is necessary for the pageleave event
  pageGroup?: string;
}

export interface GlobalEngagementEventFields {
  // Globals that can be overridden when dispatching event
  app: AppSlugs;
  httpPath: string;
  pageGroup?: string;
  // Cannot be overridden
  httpReferer: string;
  // User Info - optional so we can track events from unauthenticated users
  authUserId?: number;
  email?: string;
  authOrganizationSlug?: string;
  authOrganizationId?: number;
  sessionId?: string;
  subscriptionType?:
    | 'self-service'
    | 'enterprise'
    | SelfServeSubscriptionTypes
    | null;
  utmCampaign?: string;
  utmContent?: string;
  utmMedium?: string;
  utmSource?: string;
  utm?: {
    utm_campaign?: string | null;
    utm_content?: string | null;
    utm_medium?: string | null;
    utm_source?: string | null;
    referrer?: string | null;
    segment?: string | null;
    sub_segment?: string | null;
    utm_adgroup?: string | null;
    utm_region?: string | null;
    utm_term?: string | null;
  };
  // Event Specific data that Product can request be populated for easier querying
  eventSpecificField?: string;
}

// Full payload after populating dispatch fields and auto populated fields
export interface EngagementEventPayload
  extends EngagementEventBase,
    GlobalEngagementEventFields {}

export interface EngagementTrackingEventDataSliceState {
  [eventDataId: string]:
    | {
        /** Unique Id for the event data - Multiple components can register the same data */
        eventDataId: string;
        /** Event data to be sent with every tracking event */
        eventData: EngagementEventData;
        /** Unique Ids for all instances of components registering this event data */
        components: string[];
      }
    | undefined;
}

export interface EngagementTrackingDevToolState {
  logEvents: false | 'all' | 'meaningful';
  outlineSections: boolean;
}

export interface EngagementTrackingMeaningfulEventsSliceState {
  eventSection: string | null;
}

export interface GlobalEngagementEventSliceState {
  globalEngagementEventFields: Partial<GlobalEngagementEventFields>;
}

export interface EngagementTrackingPartialAppState {
  engagementTracking: {
    eventData: EngagementTrackingEventDataSliceState;
    meaningfulEvents: EngagementTrackingMeaningfulEventsSliceState;
    globalData: GlobalEngagementEventSliceState;
  };
}

export type IntercomEventData = {
  browser?: BrowserEventData['browser'];
  browserVersion?: BrowserEventData['browserVersion'];
  os?: BrowserEventData['os'];
  osVersion?: BrowserEventData['osVersion'];
  eventSection: EngagementEventData['eventSection'];
  eventType: EngagementEventPayload['eventType'];
  app: EngagementEventPayload['app'];
  pageGroup: EngagementEventPayload['pageGroup'];
  httpPath: EngagementEventPayload['httpPath'];
  httpReferer: EngagementEventPayload['httpReferer'];
  dataHcName: EngagementEventData['dataHcName'];
  originalEventType: EngagementEventData['originalEventType'];
  propertyReportAddressId?: PropertyReportEngagementEventData['hcAddressId'];
  propertyReportAddressSlug?: PropertyReportEngagementEventData['slug'];
  propertyReportId?: PropertyReportEngagementEventData['reportId'];
  offerNowAddressId?: OfferNowEngagementEventData['hcAddressId'];
  offerNowAddressSlug?: OfferNowEngagementEventData['slug'];
  requestedProduct?: EngagementEventData['requestedProduct'];
};
