import { useQuery } from '@tanstack/react-query';

import { HuellApi } from '../api';

export const QUERY_KEY_CERBERUS_STATS_FILTERS_USER =
  'cerberus-stats-filters-user';
export const useCerberusStatsFiltersForUser = () => {
  return useQuery([QUERY_KEY_CERBERUS_STATS_FILTERS_USER], async () => {
    return await HuellApi.fetchCerberusStatsFiltersForUser();
  });
};
