import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { DragAndDropUpload } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { APP_CONFIG_DEXP, VIEW_PATHS_DEXP } from '@hcs/hc-products';
import { useToastSlice } from '@hcs/toast';
import { GenerateAnalysisState, MeaningfulEventTypes } from '@hcs/types';

import sampleFile from '../../assets/sample_dexp_input.xlsx';
import { useDexpCreateJobFromUpload } from '../../hooks/useDexpCreateJobFromUpload';
import { useDexpEndpointsTable } from '../../hooks/useDexpEndpointsTable';
import { useDexpJob } from '../../hooks/useDexpJob';
import { getSelectedEndpoints } from '../../utils/generateAnalysis.utils';
import { jobIsCompleted } from '../../utils/jobStatus.utils';
import { GenerateAnalysisError } from '../GenerateAnalysisError';
import { GenerateAnalysisSuccess } from '../GenerateAnalysisSuccess';

import styles from './GenerateAnalysis.module.css';

export const dataHcName = 'generate-analysis';
export const dataHcEventSectionGenerateAnalysis = 'Generate Analysis';
export const GenerateAnalysis = () => {
  const {
    state: { endpointsInShoppingCart },
  } = useDexpEndpointsTable();

  const {
    actions: { toastOpen, toastClose },
  } = useToastSlice();

  const selectedEndpoints = getSelectedEndpoints(endpointsInShoppingCart);
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * Redirect to the select-data-points page,
     * if there is no selected data points
     */
    if (selectedEndpoints.length === 0) {
      const selectDataPointsPath = `${APP_CONFIG_DEXP.rootPath}/${VIEW_PATHS_DEXP.NEW_ANALYSIS}/${VIEW_PATHS_DEXP.SELECT_DATA_POINTS}`;
      navigate(selectDataPointsPath);
    }
  }, [selectedEndpoints.length]);

  const [analysisState, setAnalysisState] = useState<GenerateAnalysisState>(
    GenerateAnalysisState.UPLOAD
  );

  const [file, setFile] = useState<File | null>(null);

  const [dexpJobId, setDexpJobId] = useState<number | null>(null);
  const {
    mutate: createDexpJob,
    isLoading: createDexpJobIsLoading,
    error: generateAnalysisError,
  } = useDexpCreateJobFromUpload({
    onError: () => {
      console.log('59 SET analysisState=', GenerateAnalysisState.FAILURE);
      setAnalysisState(GenerateAnalysisState.FAILURE);
      toastOpen({
        type: 'loading-failure',
        title: 'Analysis failed.',
        duration: 2000,
      });
    },
    onSuccess: (data) => {
      console.log('68 SET analysisState=', GenerateAnalysisState.GENERATING);
      setAnalysisState(GenerateAnalysisState.GENERATING);
      setDexpJobId(data.job.id);
      toastOpen({
        type: 'loading',
        title: 'Generating Analysis...',
        duration: null,
      });
    },
  });

  const { data: dexpJobData } = useDexpJob(dexpJobId, {
    onError: () => {
      console.log('81 SET analysisState=', GenerateAnalysisState.FAILURE);
      setAnalysisState(GenerateAnalysisState.FAILURE);
      toastClose();
    },
  });

  useEffect(() => {
    console.log('analysisState=', analysisState, dexpJobData?.status);
    if (
      dexpJobData &&
      jobIsCompleted(dexpJobData.status) &&
      analysisState !== GenerateAnalysisState.SUCCESS
    ) {
      console.log('94 SET analysisState=', GenerateAnalysisState.SUCCESS);
      setAnalysisState(GenerateAnalysisState.SUCCESS);
      toastOpen({
        type: 'loading-success',
        title: 'Analysis Complete!',
        duration: 2000,
      });
    }
  }, [dexpJobData, analysisState]);

  const generateAnalysis = (addressFile: File) => {
    const formData = new FormData();

    formData.append('addresses', addressFile, addressFile.name);
    formData.append('endpoints', selectedEndpoints.join(','));
    formData.append('template_id', 'null');

    createDexpJob(formData);
  };

  return (
    <Card
      dataHcName={`${dataHcName}-card`}
      dataHcEventSection={dataHcEventSectionGenerateAnalysis}
      className={classNames(styles.GenerateAnalysis, {
        [styles.running]: analysisState === GenerateAnalysisState.GENERATING,
        [styles.finished]:
          analysisState === GenerateAnalysisState.SUCCESS ||
          analysisState === GenerateAnalysisState.FAILURE,
      })}
    >
      {analysisState === GenerateAnalysisState.UPLOAD ||
      analysisState === GenerateAnalysisState.GENERATING ? (
        <>
          <DragAndDropUpload
            dataHcName={`${dataHcName}-uploader`}
            extensions={['.csv', '.xlsx']}
            config={{
              onChange: setFile,
              multipleFiles: false,
            }}
            header={{
              title: 'Upload File',
              sampleFile: {
                file: sampleFile,
                name: 'sampleDexpInput',
              },
            }}
          />
          {file && (
            <div className={styles.GenerateAnalysisButtonWrapper}>
              <Button
                dataHcName={`${dataHcName}-button`}
                dataHcEventType={MeaningfulEventTypes.Goal}
                dataHcEventName={
                  analysisState !== GenerateAnalysisState.UPLOAD
                    ? undefined
                    : 'Submit Analysis'
                }
                onClick={() => generateAnalysis(file)}
                loading={createDexpJobIsLoading}
                disabled={analysisState !== GenerateAnalysisState.UPLOAD}
              >
                Generate Analysis
              </Button>
            </div>
          )}
        </>
      ) : analysisState === GenerateAnalysisState.SUCCESS && dexpJobId ? (
        <GenerateAnalysisSuccess inputFile={file} jobId={dexpJobId} />
      ) : analysisState === GenerateAnalysisState.FAILURE ? (
        <GenerateAnalysisError
          inputFile={file}
          error={generateAnalysisError}
          onTryAgain={() => {
            setFile(null);
            setAnalysisState(GenerateAnalysisState.UPLOAD);
          }}
        />
      ) : null}
    </Card>
  );
};
