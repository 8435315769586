import React from 'react';

import { SubNav, SubNavItemConfig } from '@hcs/design-system';
import {
  APP_CONFIG_MARKET_INSIGHTS,
  VIEW_PATHS_MARKET_INSIGHTS,
} from '@hcs/hc-products';

const dataHcName = 'market-insights-page-nav';

const children: SubNavItemConfig['children'] = [
  {
    navigateTo: `${APP_CONFIG_MARKET_INSIGHTS.rootPath}/${VIEW_PATHS_MARKET_INSIGHTS.SEARCH}/${VIEW_PATHS_MARKET_INSIGHTS.MSAS}`,
    label: 'MSA',
    dataHcName: `${dataHcName}-msas`,
  },
];

const SUB_NAV_ITEMS_CONFIG = [
  {
    navigateTo: `${APP_CONFIG_MARKET_INSIGHTS.rootPath}/${VIEW_PATHS_MARKET_INSIGHTS.SEARCH}`,
    label: 'Market Insights',
    dataHcName: `${dataHcName}-header`,
    children,
  },
];

export const MarketInsightsPageNav = () => {
  return (
    <SubNav dataHcName={dataHcName} subNavItemsConfig={SUB_NAV_ITEMS_CONFIG} />
  );
};
