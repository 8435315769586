import jsonPatch, { Operation, PatchResult, Validator } from 'fast-json-patch';
import cloneDeep from 'lodash.clonedeep';
// Ensures that the object passed to jsonPatch has the necessary parent objects.
// Report-Api allows for patching nested paths directly even if the parent doesn't exist yet
// but the json-patch spec does not. For now I am using this util to prep the object but we may
// decide to modify this to prep additional patches in stead.
export const prepNestedPathsForJsonPatch = <D>(
  original: D,
  ops: Operation[]
) => {
  const clone = cloneDeep(original) as unknown;
  ops.forEach(({ op, path }, i) => {
    let ref = clone;
    if (op === 'add') {
      // Verify the path is prepared since we patch nested paths directly that do not exist
      const pathParts = path.split('/');
      const maxIndex = pathParts.length - 1;
      pathParts.forEach((p) => {
        if (
          p &&
          typeof ref === 'object' &&
          !Array.isArray(ref) &&
          ref !== null
        ) {
          const refObj = ref as Record<typeof p, unknown>;
          if (!(p in refObj) || (!refObj[p] && i < maxIndex)) {
            refObj[p] = {};
          }
          ref = refObj[p];
        }
      });
    }
  });
  return clone as D;
};

// Allows patches to nested paths that don't exist on the source document yet
// Mimics report-api patch behavior
export const jsonPatchApplyEnhanced = <T>(
  document: T,
  patch: Operation[],
  validateOperation?: boolean | Validator<T>,
  mutateDocument?: boolean,
  banPrototypeModifications?: boolean
): PatchResult<T> =>
  jsonPatch.applyPatch(
    prepNestedPathsForJsonPatch(document, patch),
    patch,
    validateOperation,
    mutateDocument,
    banPrototypeModifications
  );
