import React from 'react';
import { formatMoney } from 'accounting';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { ChartTitle } from '@hcs/pdf/charts';
import { ReportId } from '@hcs/types';
import { formatPercent } from '@hcs/utils';

import { useForecastStats } from '../../hooks/useForecastStats';

import styles from './ForecastStatsDeprecated.module.css';

interface Props {
  className?: string;
  reportId: ReportId;
  forecastType: 'zip' | 'block';
}
const dataHcName = 'forecast-stats';
export const ForecastStatsDeprecated = ({
  className,
  reportId,
  forecastType,
}: Props) => {
  const forecastStatsQuery = useForecastStats(reportId);
  if (!forecastStatsQuery.isFetched) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const forecastStats = forecastStatsQuery.data?.[forecastType];

  if (!forecastStats?.length) {
    return null;
  }
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ForecastStats, className)}
    >
      <ChartTitle dataHcName={`${dataHcName}-title`} className={styles.Title}>
        {forecastStats.length} Year Growth
      </ChartTitle>
      <div className={styles.Items}>
        {forecastStats.map((forecast, i) => {
          return (
            <div
              key={`forecast-${forecast.date}`}
              data-hc-name={`${dataHcName}-item`}
              className={styles.Item}
            >
              <div className={classNames(styles.ItemRow, styles.Bar)}>
                <span
                  data-hc-name={`${dataHcName}-bar-label`}
                  className={styles.ItemLabel}
                >
                  {i + 1} Year
                </span>
                <span
                  data-hc-name={`${dataHcName}-percent-change`}
                  className={styles.ItemValue}
                >
                  {formatPercent(forecast.percentDiff, true)}
                </span>
              </div>
              <div className={styles.ItemRow}>
                <span
                  data-hc-name={`${dataHcName}-year`}
                  className={styles.ItemLabel}
                >
                  {forecast.year}
                </span>

                <span
                  data-hc-name={`${dataHcName}-price`}
                  className={styles.ItemValue}
                >
                  {formatMoney(forecast.price)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
