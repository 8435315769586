import { WebMercatorViewport } from '@deck.gl/core/typed';

import {
  BoundsInfo,
  FitGeoLocationsReturn,
  GeoLocation,
  HcMapState,
  Viewport,
  ViewportInitialized,
} from '@hcs/types';
import { isBounds } from '@hcs/utils';

export const isViewportInitialized = (
  viewport: Viewport
): viewport is ViewportInitialized => {
  if (
    viewport?.width &&
    viewport?.height &&
    viewport?.latitude &&
    viewport?.longitude &&
    viewport?.zoom
  ) {
    return true;
  }
  return false;
};

export interface ViewportSearchParamNames {
  latitudeParamName: string;
  longitudeParamName: string;
  zoomParamName: string;
}

export const getViewportFromSearchParams = ({
  searchParams,
  searchParamNames,
}: {
  searchParams: URLSearchParams;
  searchParamNames: ViewportSearchParamNames;
}): Pick<Viewport, 'latitude' | 'longitude' | 'zoom'> | null => {
  const latitude = searchParams.get(searchParamNames.latitudeParamName);
  const longitude = searchParams.get(searchParamNames.longitudeParamName);
  const zoom = searchParams.get(searchParamNames.zoomParamName);
  if (latitude !== null && longitude !== null && zoom !== null) {
    // attempt to parse
    const parsedLatitude = parseFloat(latitude);
    const parsedLongitude = parseFloat(longitude);
    const parsedZoom = parseFloat(zoom);
    if (isNaN(parsedLatitude) || isNaN(parsedLongitude) || isNaN(parsedZoom)) {
      return null;
    } else {
      return {
        latitude: parsedLatitude,
        longitude: parsedLongitude,
        zoom: parsedZoom,
      };
    }
  }
  return null;
};

export const fitGeoLocationsToViewPort = (
  coords: GeoLocation[],
  mapState: Pick<HcMapState, 'viewport'>,
  padding: number
): FitGeoLocationsReturn | undefined => {
  const lats: number[] = [];
  const lngs: number[] = [];
  coords.forEach((geoLocation) => {
    if (geoLocation.latitude) {
      lats.push(geoLocation.latitude);
    }
    if (geoLocation.longitude) {
      lngs.push(geoLocation.longitude);
    }
  });
  const maxLat = Math.max(...lats);
  const minLat = Math.min(...lats);
  const maxLng = Math.max(...lngs);
  const minLng = Math.min(...lngs);
  const newBounds: [[number, number], [number, number]] = [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
  try {
    const nextViewport = new WebMercatorViewport({
      ...mapState?.viewport,
    }).fitBounds(newBounds, {
      padding,
    });
    // Get centre coordinates
    const coords = nextViewport.unproject([
      nextViewport.width / 2,
      nextViewport.height / 2,
    ]);
    return {
      viewport: nextViewport,
      // assert the array items as truthy since we know the values will always exist
      coords: { lng: coords[0]!, lat: coords[1]! },
    };
  } catch (e) {
    // This is expected under fairly common conditions, no need to log
    console.warn('Unable fit markers in map viewport');
  }
  return undefined;
};

export const setViewportSearchParams = ({
  searchParams,
  viewport,
  searchParamNames,
}: {
  searchParams: URLSearchParams;
  viewport: Viewport;
  searchParamNames: ViewportSearchParamNames;
}): URLSearchParams => {
  if (viewport) {
    searchParams.set(
      searchParamNames.latitudeParamName,
      viewport.latitude.toString()
    );
    searchParams.set(
      searchParamNames.longitudeParamName,
      viewport.longitude.toString()
    );
    searchParams.set(searchParamNames.zoomParamName, viewport.zoom.toString());
  }
  return searchParams;
};

export const getBoundsInfoFromViewportState = (
  viewportState: Viewport
): BoundsInfo | undefined => {
  const viewport = new WebMercatorViewport(viewportState);
  const southWest = viewport.unproject([0, viewport.height]);
  const northEast = viewport.unproject([viewport.width, 0]);
  const bounds = [southWest, northEast];

  if (isBounds(bounds)) {
    return {
      // assert the array items as truthy since we know the values will always exist
      southWest: { lng: southWest[0]!, lat: southWest[1]! },
      northEast: { lng: northEast[0]!, lat: northEast[1]! },
      bounds,
    };
  }
  return undefined;
};
