import { useMemo } from 'react';

import { useCheckAppZuoraSubscription } from '@hcs/auth';
import { AppSlugs } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useCurrentOmPermissions } from '../hooks/useCurrentOmPermissions';

export const useOmSftpAccess = () => {
  const agileSuiteSubscriptionQuery = useCheckAppZuoraSubscription(
    AppSlugs.AgileSuite
  );
  const omPermissionsQuery = useCurrentOmPermissions();

  const combinedQuery = combineUseQueryResult([
    agileSuiteSubscriptionQuery,
    omPermissionsQuery,
  ]);

  const data = useMemo(() => {
    if (combinedQuery.isSuccess) {
      return (
        !!agileSuiteSubscriptionQuery.data &&
        !!omPermissionsQuery.data?.createOrders
      );
    } else {
      return undefined;
    }
  }, [
    combinedQuery.isSuccess,
    agileSuiteSubscriptionQuery.data,
    omPermissionsQuery.data?.createOrders,
  ]);

  return { ...combinedQuery, data };
};
