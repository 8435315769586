import React, { ReactNode } from 'react';

import { IconButton } from '@hcs/design-system';
import { Dialog, DIALOG_ACTIONS_PORTAL_ID } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { RefreshIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { useUiPreferencesForUser } from '@hcs/huell';
import { PreferencesKeys } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { useReport, useReportPermissions } from '../../hooks';
import { useRefreshReportData } from '../../hooks/useRefreshReportData';

import { RefreshReportConfirm } from './RefreshReportConfirm';

interface Props {
  className?: string;
  reportId: ReportId;
  trigger?: ReactNode;
}

const dataHcName = 'refresh-report';
export const dataHcEventSectionRefreshReport = 'Refresh Report';
export const REFRESH_REPORT_FEATURES: ReportFeatures[] = [
  ReportFeatures.ReportDataRefresh,
];
export const RefreshReport = ({ className, reportId, trigger }: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { data: report } = useReport(reportId);
  const { data: mergedUserOrgUiPreferences } = useUiPreferencesForUser();
  const refreshDataMutation = useRefreshReportData();
  const handleClickTrigger = () => {
    if (
      !mergedUserOrgUiPreferences?.[PreferencesKeys.AckRefreshComps] &&
      reportPermissions?.isEditable
    ) {
      handleOpen();
    } else {
      refreshDataMutation.mutate(reportId);
    }
  };
  return (
    <>
      <Tooltip
        dataHcName={`${dataHcName}-tooltip`}
        trigger={
          trigger ? (
            <span
              onClick={handleClickTrigger}
              data-hc-name={`${dataHcName}-button`}
              data-hc-event-section={dataHcEventSectionRefreshReport}
            >
              {trigger}
            </span>
          ) : (
            <IconButton
              dataHcName={`${dataHcName}-button`}
              dataHcEventSection={dataHcEventSectionRefreshReport}
              disabled={!reportPermissions?.isEditable}
              label="Refresh Data"
              className={className}
              icon={
                <RefreshIcon
                  dataHcName={`${dataHcName}-button-icon`}
                  size="lg"
                />
              }
              onClick={handleClickTrigger}
            />
          )
        }
        label={`Last refreshed : ${formatDateShort(report?.effectiveDate)}`}
      />
      <Dialog
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        title="Refresh report data"
        onClose={handleClose}
        type="small"
      >
        <RefreshReportConfirm
          reportId={reportId}
          onSuccess={handleClose}
          actionsPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
        />
      </Dialog>
    </>
  );
};
