import { AxiosResponse } from 'axios';

import { AxiosAccessTokenClientBearer } from '@hcs/http-clients';
import {
  AccroleapiInputKeyRequest,
  AccroleapiRolesResp,
  CapabilitiesResponse,
  OrganizationapiBulkInviteUploadTaskStatusResponse,
  OrganizationapiCreateOrgInviteRequest,
  OrganizationapiDeleteInviteReq,
  OrganizationapiInviteListResponse,
  OrganizationapiInviteResponse,
  OrganizationapiOrgUserListResponse,
  OrganizationapiUploadOrgInvitesResponse,
  Role,
} from '@hcs/types';
import { ACCOUNT_URL, ACCOUNT_URL_INTERNAL } from '@hcs/urls';

const getRolesEndpoint = (orgId: number, userId: number) =>
  ACCOUNT_URL === ACCOUNT_URL_INTERNAL
    ? `${ACCOUNT_URL_INTERNAL}/users/${userId}/roles`
    : `${ACCOUNT_URL}/organizations/${orgId}/users/${userId}/roles`;

export const AuthzApi = {
  getCapabilitiesForUser: async (userId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<CapabilitiesResponse>(
        `${ACCOUNT_URL}/users/${userId}/capabilities`
      );
    return response.data;
  },
  getCapabilitiesForOrg: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<CapabilitiesResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}/capabilities`
      );
    return response.data;
  },
  getOrgInvites: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<OrganizationapiInviteListResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}/invites`
      );
    return response.data;
  },
  createOrgInvite: async (
    orgId: number,
    inviteData: OrganizationapiCreateOrgInviteRequest
  ) => {
    const response = await AxiosAccessTokenClientBearer.post<
      OrganizationapiInviteResponse,
      AxiosResponse<OrganizationapiInviteResponse>,
      OrganizationapiCreateOrgInviteRequest
    >(`${ACCOUNT_URL}/organizations/${orgId}/invites`, inviteData);
    return response.data;
  },
  createBulkInvitation: async (orgId: number, payload: FormData) => {
    const response =
      await AxiosAccessTokenClientBearer.post<OrganizationapiUploadOrgInvitesResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}/invites/bulk`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    return response.data;
  },
  getBulkInvitationTaskStatus: async (orgId: number, taskId: string) => {
    const response =
      await AxiosAccessTokenClientBearer.get<OrganizationapiBulkInviteUploadTaskStatusResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}/invites/bulk/${taskId}`
      );
    return response.data;
  },
  deleteOrgInvite: async (
    orgId: number,
    inviteEmailData: OrganizationapiDeleteInviteReq
  ) => {
    await AxiosAccessTokenClientBearer.delete<
      void,
      AxiosResponse<void>,
      OrganizationapiDeleteInviteReq
    >(`${ACCOUNT_URL}/organizations/${orgId}/invites`, {
      data: inviteEmailData,
    });
  },
  getOrgUsers: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<OrganizationapiOrgUserListResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}/users`
      );
    return response.data;
  },
  deleteOrgUser: async (orgId: number, userId: number) => {
    await AxiosAccessTokenClientBearer.delete(
      `${ACCOUNT_URL}/organizations/${orgId}/users/${userId}`
    );
  },
  getOrgRoles: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<AccroleapiRolesResp>(
        `${ACCOUNT_URL}/organizations/${orgId}/roles`
      );
    return response.data;
  },
  getUserRoles: async (orgId: number, userId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<AccroleapiRolesResp>(
        getRolesEndpoint(orgId, userId)
      );
    return response.data;
  },
  addUserRole: async (orgId: number, userId: number, roleKey: Role['key']) => {
    return await AxiosAccessTokenClientBearer.post<
      void,
      AxiosResponse<void>,
      AccroleapiInputKeyRequest
    >(getRolesEndpoint(orgId, userId), {
      key: roleKey,
    });
  },
  removeUserRole: async (
    orgId: number,
    userId: number,
    roleKey: Role['key']
  ) => {
    return await AxiosAccessTokenClientBearer.delete<
      void,
      AxiosResponse<void>,
      AccroleapiInputKeyRequest
    >(getRolesEndpoint(orgId, userId), {
      data: { key: roleKey },
    });
  },
};
