import { ReactNode } from 'react';

import { LoanType } from '../cerberus';
import {
  MlsStatus,
  PortfolioAvmReferences,
  PortfolioAvmThresholdFields,
  PortfolioAvmThresholdOperators,
  PortfolioAvmThresholdValueFields,
  PortfolioLtvThresholdFields,
  PortfolioLtvThresholdOperators,
} from '../portfolio/Portfolio.types';

export interface PortfolioFormFieldCommonProps {
  className?: string;
}
export interface PortfolioFormFieldConfig {
  label: ReactNode;
  placeHolder?: string;
  field: string;
}

export enum PortfolioFormFields {
  Name = 'name',
  NotificationType = 'notificationType',
  MlsStatus = 'mlsStatus',
  LtvThresholds = 'ltvThresholds',
  AvmThresholds = 'avmThresholds',
  Liens = 'liens',
  Nod = 'nod',
  Emails = 'emails',
  WebhookUrl = 'webhookUrl',
  WebhookUsername = 'username',
  WebhookPassword = 'password',
}

export enum PortfolioFormNotificationTypes {
  Nod = 'NOD',
  Mls = 'MLS',
  Ltv = 'LTV',
  Avm = 'AVM',
  Lien = 'LIEN',
}

export interface PortfolioFormLtvThreshold {
  [PortfolioLtvThresholdFields.Operator]: PortfolioLtvThresholdOperators;
  [PortfolioLtvThresholdFields.Threshold]: number | null;
}

export interface PortfolioFormAvmThresholdValue {
  [PortfolioAvmThresholdValueFields.Operator]: PortfolioAvmThresholdOperators;
  [PortfolioAvmThresholdValueFields.Value]: number | null;
}

interface PortfolioFormAvmThresholds {
  [PortfolioAvmThresholdFields.RelativeTo]: PortfolioAvmReferences;
  [PortfolioAvmThresholdFields.Thresholds]:
    | PortfolioFormAvmThresholdValue[]
    | null;
}

export interface PortfolioForm {
  [PortfolioFormFields.Name]: string;
  [PortfolioFormFields.Emails]: string[];
  [PortfolioFormFields.NotificationType]: PortfolioFormNotificationTypes[];
  [PortfolioFormFields.LtvThresholds]: PortfolioFormLtvThreshold[] | null;
  [PortfolioFormFields.AvmThresholds]: PortfolioFormAvmThresholds | null;
  [PortfolioFormFields.MlsStatus]: MlsStatus[] | null;
  [PortfolioFormFields.Nod]: boolean | null;
  [PortfolioFormFields.Liens]: LoanType[] | null;
  [PortfolioFormFields.WebhookUrl]: string | null;
  [PortfolioFormFields.WebhookUsername]?: string | null;
  [PortfolioFormFields.WebhookPassword]?: string | null;
}
