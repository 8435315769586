import { useQuery } from '@tanstack/react-query';

import { KeywordSearchInputs } from '@hcs/types';

import { KeywordSearchApi } from '../api';

import { useKeywordSearchSlice } from './useKeywordSearchSlice';

export const QUERY_KEY_KEYWORD_SEARCH = 'keyword-search-by-listing';
export const useKeywordSearch = (searchKey: string) => {
  const { state, actions } = useKeywordSearchSlice();
  const inputs = state[searchKey];
  const resultsQuery = useQuery(
    [QUERY_KEY_KEYWORD_SEARCH, searchKey, inputs],
    async function () {
      return await KeywordSearchApi.fetchKeywordsByListing(inputs);
    },
    {
      enabled: !!inputs,
      select: (data) => data?.filter((d) => d.remarks_public_match),
    }
  );
  return {
    state: {
      inputs,
      resultsQuery,
    },
    actions: {
      keywordSearchSubmit: (keywordSearchInputs: KeywordSearchInputs) => {
        actions.keywordSearchSubmit?.({ searchKey, keywordSearchInputs });
      },
      keywordSearchClear: () => {
        actions.keywordSearchClear?.({ searchKey });
      },
    },
  };
};
