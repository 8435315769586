import { ValueExtended } from '../Value.types';

export enum GlossaryTerms {
  Active = 'Active',
  AdjustedValue = 'AdjustedValue',
  CompProperties = 'CompProperties',
  ComparableValue = 'ComparableValue',
  ComparableValueSelected = 'ComparableValueSelected',
  CurrentValue = 'CurrentValue',
  DaysOnMarket = 'DaysOnMarket',
  ForecastStandardDeviation = 'ForecastStandardDeviation',
  RentalHcValue = 'RentalHcValue',
  HcSuggestedComps = 'HcSuggestedComps',
  HcValue = 'HcValue',
  MonthsOfSupply = 'MonthsOfSupply',
  NearbyProperties = 'NearbyProperties',
  NonDisclosureState = 'NonDisclosureState',
  OccupancyType = 'OccupancyType',
  PropertyType = 'PropertyType',
  RentalComparableValueSelected = 'RentalComparableValueSelected',
  RiskOfDecline = 'RiskOfDecline',
  Similarity = 'Similarity',
  UserOpinionOfPrice = 'UserOpinionOfPrice',
  RentalUserOpinionOfPrice = 'RentalUserOpinionOfPrice',
  Condition = 'Condition',
  Excellent = 'Excellent',
  Good = 'Good',
  Fair = 'Fair',
  SubPar = 'SubPar',
  Poor = 'Poor',
  ValueForecast = 'ValueForecast',
  MarketIndex = 'MarketIndex',
  MarketStatus = 'MarketStatus',
  OwnerOccupancy = 'OwnerOccupancy',
  RentalComps = 'RentalComps',
  OneYearHpiForecast = 'OneYearHpiForecast',
  MedianGrossYield = 'MedianGrossYield',
  SaleToListPriceRatio = 'SaleToListPriceRatio',
  NumberActiveListings = 'NumberActiveListings',
  MsaMonthsOfSupply = 'MsaMonthsOfSupply'
}

// Support dynamic terms
type GlossaryTermTypes = 'static' | 'avm' | 'compValue';

// avm dynamic terms
export interface GlossaryTermAvm {
  type: 'avm';
  glossaryTerm: GlossaryTerms.HcValue | GlossaryTerms.RentalHcValue;
  avm?: ValueExtended | null;
}

export interface CompValueOptions {
  hpiRpiAdjustment?: boolean | null;
  hcAdjustment?: boolean | null;
}
export interface GlossaryTermCompValue {
  type: 'compValue';
  glossaryTerm:
    | GlossaryTerms.ComparableValueSelected
    | GlossaryTerms.RentalComparableValueSelected;
  compValueOptions?: CompValueOptions | null;
}

export type GlossaryTermsProp = (
  | GlossaryTerms
  | GlossaryTermAvm
  | GlossaryTermCompValue
)[];

interface GlossaryTermConfigBase {
  type: GlossaryTermTypes;
  title: string;
  source?: string;
}

interface GlossaryTermConfigAvm extends GlossaryTermConfigBase {
  type: 'avm';
  content: (avm?: ValueExtended | null) => string[];
}

interface GlossaryTermConfigComparableValue extends GlossaryTermConfigBase {
  type: 'compValue';
  content: (compValueOptions?: CompValueOptions | null) => string[];
}

interface GlossaryTermConfigStatic extends GlossaryTermConfigBase {
  type: 'static';
  content: string[];
}

export type GlossaryTermConfig =
  | GlossaryTermConfigStatic
  | GlossaryTermConfigAvm
  | GlossaryTermConfigComparableValue;

export type GlossaryDefinition = {
  title: string;
  content: string[];
  source?: string;
};
