import React from 'react';
import { createPortal } from 'react-dom';

import { ToastMessage } from '@hcs/design-system';
import { FIXED_QUERY_SELECTOR } from '@hcs/webapps';

import { useToastSlice } from '../hooks/useToastSlice';

interface Props {
  className?: string;
}

export const Toast = ({ className }: Props) => {
  const {
    state: { active: toast },
    actions,
  } = useToastSlice();

  const elm = document.querySelector(FIXED_QUERY_SELECTOR);

  if (toast && elm) {
    return createPortal(
      <ToastMessage
        toast={toast}
        closeToast={actions.toastClose}
        className={className}
      />,
      elm
    );
  } else {
    return null;
  }
};
