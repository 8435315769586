import React, { ReactNode } from 'react';

import { useActiveState } from '@hcs/hooks';
import { usePatchUiPreferencesForUser } from '@hcs/huell';
import { useUiPreferencesForUser } from '@hcs/huell';
import { PropertyPhotoOverlayPage } from '@hcs/property-state';
import { StreetViewDialog } from '@hcs/street-view';
import { CompTypes, PreferencesKeys } from '@hcs/types';
import {
  PropertyStateCore,
  PropertyStateImagesResult,
  PropertyStateType,
} from '@hcs/types';
import { CompIdentifier, ListingIdentifier, ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import {
  useComp,
  usePhotosPage,
  useReportValue,
  useSubjectDocument,
} from '../../hooks';
import { useCompPhotos } from '../../hooks/useCompPhotos';
import { useSubjectPhotos } from '../../hooks/useSubjectPhotos';

/** Private component to reuse common photo and preference logic */
interface PrivateReportPhotosOverlayPageProps {
  dataHcName: string;
  dataHcEventSection: string;
  dataHcEventSectionStreetView: string;
  isLoading?: boolean;
  className?: string;
  isError?: boolean;
  price?: {
    label: ReactNode;
    value: number | null | undefined;
  };
  propertyState: PropertyStateCore | undefined;
  propertyPhotosResult: PropertyStateImagesResult | undefined;
  listingIdentifier?: ListingIdentifier;
  onClose: VoidFunction;
}
const PrivateReportPhotosOverlayPage = ({
  dataHcName,
  dataHcEventSection,
  dataHcEventSectionStreetView,
  isLoading,
  isError,
  price,
  className,
  propertyPhotosResult,
  listingIdentifier,
  propertyState,
  onClose,
}: PrivateReportPhotosOverlayPageProps) => {
  const { data: uiPreferences } = useUiPreferencesForUser();
  const { mutate: patchPreferences } = usePatchUiPreferencesForUser();
  const { active: streetViewActive, handleClose: handleStreetViewClose } =
    useActiveState();

  const handleClose = () => {
    if (!streetViewActive) {
      onClose();
    }
  };

  if (isLoading || isError || !propertyState) {
    return null;
  }

  return (
    <>
      <PropertyPhotoOverlayPage
        active
        dataHcEventSection={dataHcEventSection}
        dataHcEventSectionStreetView={dataHcEventSectionStreetView}
        className={className}
        listingIdentifier={listingIdentifier}
        propertyStateArgs={{
          propertyStateType: PropertyStateType.Core,
          propertyState,
        }}
        price={price}
        onClose={handleClose}
        propertyPhotosResult={propertyPhotosResult}
        photoGridSize={uiPreferences?.[PreferencesKeys.PhotoGrid]?.size}
        onChangeSize={(value) => {
          patchPreferences?.([
            {
              op: 'add',
              path: `/${PreferencesKeys.PhotoGrid}/size`,
              value,
            },
          ]);
        }}
      />
      <StreetViewDialog
        dataHcName={`${dataHcName}-street-view`}
        dataHcEventSection={dataHcEventSectionStreetView}
        active={streetViewActive}
        onClose={handleStreetViewClose}
        location={propertyState.location}
      />
    </>
  );
};

/** Subject Report Photos Overlay Page */
interface SubjectReportPhotosOverlayPageProps {
  reportId: ReportId;
  listingIdentifier?: ListingIdentifier;
  onClose: VoidFunction;
  className?: string;
}
const dataHcNameSubject = 'subject-photos-page';
export const dataHcEventSectionSubjectPhotos = 'Subject Photos';
export const dataHcEventSectionSubjectStreetView = 'Subject Street View';
export const SubjectReportPhotosOverlayPage = ({
  reportId,
  ...passThroughProps
}: SubjectReportPhotosOverlayPageProps) => {
  const reportValueQuery = useReportValue(reportId);
  const subjectQuery = useSubjectDocument(reportId);
  const subjectPhotosQuery = useSubjectPhotos(reportId);
  const { isInitialLoading, isError } = combineUseQueryResult([
    reportValueQuery,
    subjectQuery,
    subjectPhotosQuery,
  ]);
  return (
    <PrivateReportPhotosOverlayPage
      {...passThroughProps}
      dataHcEventSection={dataHcEventSectionSubjectPhotos}
      dataHcEventSectionStreetView={dataHcEventSectionSubjectStreetView}
      price={
        reportValueQuery.data?.label &&
        reportValueQuery.data.selectedValue?.value.value
          ? {
              label: reportValueQuery.data.label,
              value: reportValueQuery.data.selectedValue.value.value,
            }
          : undefined
      }
      dataHcName={dataHcNameSubject}
      isLoading={isInitialLoading}
      isError={isError}
      propertyPhotosResult={subjectPhotosQuery.data}
      propertyState={subjectQuery.data?.data.propertyState}
    />
  );
};

/** Comp Report Photos Overlay Page */
interface CompReportPhotosOverlayPageProps {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  onClose: VoidFunction;
  className?: string;
}
const dataHcNameComp = 'comp-photos-page';
export const dataHcEventSectionCompPhotos = 'Comp Photos';
export const dataHcEventSectionCompStreetView = 'Comp Street View';
export const dataHcEventSectionRentalCompPhotos = 'Rental Comp Photos';
export const dataHcEventSectionRentalCompStreetView = 'Rental Comp Street View';
export const CompReportPhotosOverlayPage = ({
  reportId,
  compIdentifier,
  ...passThroughProps
}: CompReportPhotosOverlayPageProps) => {
  const isRental = compIdentifier.compType === CompTypes.Rental;
  const compQuery = useComp(reportId, compIdentifier);
  const compPhotosQuery = useCompPhotos(reportId, compIdentifier);
  const { isInitialLoading, isError } = combineUseQueryResult([
    compQuery,
    compPhotosQuery,
  ]);
  return (
    <PrivateReportPhotosOverlayPage
      {...passThroughProps}
      dataHcName={dataHcNameComp}
      dataHcEventSection={
        isRental
          ? dataHcEventSectionRentalCompPhotos
          : dataHcEventSectionCompPhotos
      }
      dataHcEventSectionStreetView={
        isRental
          ? dataHcEventSectionRentalCompStreetView
          : dataHcEventSectionCompStreetView
      }
      isLoading={isInitialLoading}
      isError={isError}
      propertyPhotosResult={compPhotosQuery.data}
      propertyState={compQuery.data?.compSchema.propertyState}
    />
  );
};

/** ReportPhotosOverlayPage controller  */
export interface ReportPhotosOverlayPageProps {
  reportId: ReportId;
  className?: string;
}
export const ReportPhotosOverlayPage = ({
  reportId,
  className,
}: ReportPhotosOverlayPageProps) => {
  const { state, photosPageClose } = usePhotosPage();
  if (state?.type === 'subject') {
    return (
      <SubjectReportPhotosOverlayPage
        reportId={reportId}
        listingIdentifier={state.listingIdentifier}
        onClose={photosPageClose}
        className={className}
      />
    );
  } else if (state?.type === 'comp') {
    return (
      <CompReportPhotosOverlayPage
        reportId={reportId}
        compIdentifier={state.compIdentifier}
        onClose={photosPageClose}
        className={className}
      />
    );
  } else {
    return null;
  }
};
