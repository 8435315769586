import React from 'react';
import classNames from 'classnames';

import { IconButton } from '@hcs/design-system';
import { TextButton } from '@hcs/design-system';
import { Drawer } from '@hcs/design-system';
import { DrawerPosition } from '@hcs/design-system';
import { InfoSmallIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { GlossaryDefinition, GlossaryTermsProp } from '@hcs/types';

import { useGlossaryDefinitions } from '../../hooks/useGlossaryDefinitions';

import styles from './Glossary.module.css';

interface Props {
  glossaryTerms: GlossaryTermsProp;
  className?: string;
  theme?: {
    Icon?: string;
  };
}
const dataHcName = 'glossary';

export const Glossary = ({ glossaryTerms, className, theme }: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();

  const glossaryValues: GlossaryDefinition[] =
    useGlossaryDefinitions(glossaryTerms);

  const glossaryItemHCName = `${dataHcName}-item`;

  return (
    <>
      <IconButton
        dataHcName={`${dataHcName}-button`}
        className={className}
        onClick={handleOpen}
        icon={
          <InfoSmallIcon
            size="sm"
            className={classNames(styles.GlossaryIcon, theme?.Icon)}
            dataHcName={`${dataHcName}-icon`}
          />
        }
      />
      <Drawer
        onClose={handleClose}
        active={active}
        dataHcName={`${dataHcName}-drawer`}
        position={DrawerPosition.RIGHT}
      >
        <section>
          <div className={styles.PanelHeader}>
            <TextButton
              dataHcName={`${dataHcName}-info-panel-close-button`}
              label="Close"
              onClick={handleClose}
              primary={false}
            />
          </div>
          {glossaryValues.map((item, i) => (
            <div
              key={`glossary-${i}`}
              data-hc-name={`${glossaryItemHCName}`}
              className={styles.GlossaryTerm}
            >
              {item.title && (
                <div
                  data-hc-name={`${glossaryItemHCName}-header`}
                  className={styles.Title}
                >
                  {item.title}
                </div>
              )}
              <div data-hc-name={`${glossaryItemHCName}-content`}>
                {item.content.map((paragraph: string, paraIndex: number) => (
                  <p
                    key={`${dataHcName}-content-para-${paraIndex}`}
                    data-dc-name={`${glossaryItemHCName}-content-paragraph`}
                  >
                    {paragraph}
                  </p>
                ))}
              </div>
              {item.source && (
                <div className={styles.Source}>
                  Source:{' '}
                  <span data-hc-name={`${glossaryItemHCName}-source`}>
                    {item.source}
                  </span>
                </div>
              )}
            </div>
          ))}
        </section>
      </Drawer>
    </>
  );
};
