import React from 'react';

import { TableCell } from '@hcs/design-system';
import { TableCellProps } from '@hcs/types';
import { CompTypes } from '@hcs/types';
import { CompIdentifier, ReportId } from '@hcs/types';

import {
  COMP_SELECT_BUTTON_FEATURES_RENTAL,
  COMP_SELECT_BUTTON_FEATURES_SALE,
  CompSelectButton,
} from '../../CompSelectButton';
import { ReportFeaturesSupported } from '../../ReportFeaturesSupported';

import styles from './CompsListTableCells.module.css';

interface Props extends TableCellProps {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
}

export const SelectCell = ({
  reportId,
  compIdentifier,
  ...tableCellProps
}: Props) => {
  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={
        compIdentifier.compType === CompTypes.Rental
          ? COMP_SELECT_BUTTON_FEATURES_RENTAL
          : COMP_SELECT_BUTTON_FEATURES_SALE
      }
    >
      <TableCell {...tableCellProps} align="center" noBorder>
        <CompSelectButton
          reportId={reportId}
          compIdentifier={compIdentifier}
          className={styles.CompSelectButton}
        />
      </TableCell>
    </ReportFeaturesSupported>
  );
};

// Using default props allows for child.props checks
// in the Table components to work properly
const defaultProps: Partial<TableCellProps> = {
  width: 80,
  sticky: true,
  align: 'center',
  // Needed so child component type-checking passes
  isTableCell: true,
};
SelectCell.defaultProps = defaultProps;
