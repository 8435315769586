import {
  CommentSchema,
  SaleToListPriceRatioChartSchema,
  SimilarPropertiesChartSchema,
} from '../../report-api';
import { AppraisalInputSchema } from '../../report-api/schemas/AppraisalInputSchema.types';
import { AppraisalXmlSchema } from '../../report-api/schemas/AppraisalXmlSchema.types';

import { AvmBreakdownSchema } from './AvmBreakdownSchema.types';
import { CompSchema } from './CompSchema.types';
import { CompsFarmSchema } from './CompsFarmSchema.types';
import { CompsFilteredSchema } from './CompsFilteredSchema.types';
import { CompsFiltersSchema } from './CompsFiltersSchema.types';
import { ForecastChartSchema } from './ForecastChartSchema.types';
import { InspectionSchema } from './InspectionSchema.types';
import { MarketAnalysisSchema } from './MarketAnalysisSchema.types';
import { MarketChartSchema } from './MarketChartSchema.types';
import { NearbyChartSchema } from './NearbyChartSchema.types';
import { PhotosSchema } from './PhotosSchema.types';
import { PropertyStateSourcesSchema } from './PropertyStateSourcesSchema.types';
import { RiskSchema } from './RiskSchema.types';
import { SubjectSchema } from './SubjectSchema.types';
import {
  ComparableValueSchema,
  SubjectFloodSchema,
  SubjectValueSchema,
} from './SubjectValueSchema.types';
import { TransactionHistorySchema } from './TransactionHistorySchema.types';

export enum SchemaIds {
  AppraisalXml = 'appraisal_xml',
  AppraisalInput = 'appraisal_inputs',
  BiasRisk = 'appraisal_summary',
  SubjectValue = 'subject_value',
  Comp = 'comp',
  ComparableValue = 'comparable_value',
  SimilarPropertiesChart = 'similar_properties_chart',
  CompsFarm = 'comps_farm',
  NearbyFarm = 'nearby_properties_chart',
  AvmBreakdown = 'avm_breakdown',
  CompsFilters = 'comps_filters',
  CompsFiltered = 'comps_filtered',
  Subject = 'subject',
  ForecastChart = 'forecast_chart',
  TransactionHistory = 'transaction_history',
  Photos = 'photos',
  PropertyStateSources = 'property_state_sources',
  MarketAnalysis = 'market_analysis',
  MarketChart = 'market_chart',
  SaleToListPriceRatioChart = 'sale_to_list_price_ratio_chart',
  SubjectFlood = 'subject_flood',
  Comment = 'comment',
  Inspection = 'inspection',
}

export type Schema =
  | AvmBreakdownSchema
  | AppraisalInputSchema
  | AppraisalXmlSchema
  | RiskSchema
  | SubjectValueSchema
  | SubjectSchema
  | CompSchema
  | CompsFarmSchema
  | NearbyChartSchema
  | CompsFilteredSchema
  | CompsFiltersSchema
  | ComparableValueSchema
  | SimilarPropertiesChartSchema
  | ForecastChartSchema
  | TransactionHistorySchema
  | PhotosSchema
  | MarketChartSchema
  | MarketAnalysisSchema
  | PropertyStateSourcesSchema
  | SubjectFloodSchema
  | SaleToListPriceRatioChartSchema
  | CommentSchema
  | InspectionSchema;
