import React from 'react';
import classNames from 'classnames';

import { PropertyStateArgsProps, PropertyStateFields } from '@hcs/types';

import { getPropertyStateFieldValue } from '../../utils';

import styles from './PropertyBrokerageCredit.module.css';

interface Props extends PropertyStateArgsProps {
  className?: string;
  isRental: boolean;
  showAgentName?: boolean;
}
const dataHcName = 'brokerage-credit';
export const PropertyBrokerageCredit = ({
  propertyStateArgs,
  className,
  isRental,
  showAgentName,
}: Props) => {
  const listingOffice = getPropertyStateFieldValue(
    isRental
      ? PropertyStateFields.listingAgentOfficeRental
      : PropertyStateFields.listingAgentOffice,
    propertyStateArgs
  );
  const brokerageName = listingOffice?.office;
  const agentName = listingOffice?.name;

  if (!brokerageName) {
    return null;
  }

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.BrokerageCredit, className)}
    >
      {showAgentName && agentName
        ? `Listed by ${agentName} with ${brokerageName}`
        : `Courtesy of ${brokerageName}`}
    </div>
  );
};
