import React, { CSSProperties, Fragment, ReactNode, useEffect } from 'react';
import classNames from 'classnames';

import { useParentDimensions, usePrevious } from '@hcs/hooks';
import { OptionalEngagementProps } from '@hcs/types';

import styles from './HorizontalSelector.module.css';

export interface HorizontalSelectorOption<T> extends OptionalEngagementProps {
  label: ReactNode;
  value: T;
  disabled?: boolean;
  onClick?: (v: T) => void;
}
export interface HorizontalSelectorProps<T> {
  options: HorizontalSelectorOption<T>[];
  className?: string;
  dataHcName: string;
  onSelect: (v: T) => void;
  value: T | undefined;
  currentMenuIndicatorHeight?: string;
  dotSeparator?: boolean;
  theme?: Partial<{
    List: string;
    ListItem: string;
    Hr: string;
  }>;
}

export const HorizontalSelector = <T extends string | number | null>({
  options,
  className,
  dataHcName,
  onSelect,
  theme,
  value,
  dotSeparator,
}: HorizontalSelectorProps<T>) => {
  const selectedRef = React.useRef<HTMLSpanElement | null>(null);
  const pullDownRef = React.useRef<HTMLUListElement | null>(null);
  const radioMenuRef = React.useRef<HTMLDivElement | null>(null);
  const maxListSize = React.useRef<number | undefined>();
  const [hrStyle, setHrStyle] = React.useState<CSSProperties>();
  const prevValue = usePrevious(value);

  const getHRStyle = () =>
    selectedRef?.current
      ? {
          width: `${selectedRef.current?.getBoundingClientRect().width}px`,
          left: `${selectedRef.current?.offsetLeft || 0}px`,
        }
      : undefined;

  const windowResizeCallback = (newUDimensions?: DOMRect) => {
    if (
      newUDimensions?.width &&
      maxListSize.current &&
      newUDimensions?.width > maxListSize.current
    ) {
      maxListSize.current = newUDimensions?.width;
    }
    setHrStyle(getHRStyle());
  };

  const ulDimensions: DOMRect | undefined = useParentDimensions(
    {
      element: selectedRef.current?.parentElement,
    },
    windowResizeCallback,
    200
  );

  if (ulDimensions?.width) {
    if (!maxListSize.current || ulDimensions?.width > maxListSize.current) {
      maxListSize.current = ulDimensions?.width;
    }
  }

  useEffect(() => {
    if (selectedRef.current && (!hrStyle || value !== prevValue)) {
      setHrStyle(getHRStyle());
    }
  }, [ulDimensions, value, prevValue, selectedRef.current, hrStyle]);
  return (
    <div
      className={classNames(styles.HorizontalSelector, className)}
      data-hc-name={dataHcName}
      ref={radioMenuRef}
    >
      <ul
        className={classNames(styles.List, theme?.List)}
        ref={pullDownRef}
        data-hc-name={`${dataHcName}-menu-list`}
        role="tablist"
      >
        {options.map((option: HorizontalSelectorOption<T>, index) => {
          const isSelected = value === option.value;
          return (
            <Fragment key={`fragment_${index}`}>
              <li
                data-hc-name={`${dataHcName}-item`}
                data-hc-event-section={option.dataHcEventSection}
                data-hc-event-name={option.dataHcEventName}
                data-hc-event-type={option.dataHcEventType}
                key={`menuItem_${index}_${option.value}`}
                className={classNames(styles.ListItem, theme?.ListItem, {
                  [styles.selected]: isSelected,
                  [styles.disabled]: option.disabled,
                })}
                onClick={
                  !option.disabled
                    ? () => {
                        onSelect(option.value);
                        option.onClick?.(option.value);
                      }
                    : undefined
                }
                value={option?.value || ''}
                role="tab"
                aria-selected={isSelected}
              >
                <span
                  key={`menuItem_span_${index}_${option.value}`}
                  ref={value === option.value ? selectedRef : null}
                >
                  {option.label}
                </span>
              </li>
              {dotSeparator && index < options.length - 1 && (
                <li>
                  <div className={styles.DotSeparator}></div>
                </li>
              )}
            </Fragment>
          );
        })}
        {hrStyle && (
          <hr
            className={classNames(styles.Hr, theme?.Hr)}
            key="ul"
            style={hrStyle}
          />
        )}
      </ul>
    </div>
  );
};
