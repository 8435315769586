import React from 'react';
import { Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useFixedPortalElement } from '@hcs/hooks';

import { DataPriorityIcon } from '../../../../svgs/icons/generics';
import { InfoTooltip } from '../../../display/popovers/InfoTooltip';
import { Checkbox } from '../../inputs/Checkbox';

import styles from './DataPriorityControl.module.css';

interface DataPriorityControlOptionProps<T extends string> {
  dataHcName: string;
  field: T;
  index: number;
  label?: string;
  description?: string;
  active: boolean;
  onToggle: VoidFunction;
  disabled?: boolean;
}

interface DraggablePortalHandlerProps {
  children: JSX.Element;
  snapshot: DraggableStateSnapshot;
}

const DraggablePortalHandler = ({
  children,
  snapshot,
}: DraggablePortalHandlerProps) => {
  const portalElm = useFixedPortalElement();
  if (!portalElm) return null;
  if (snapshot.isDragging) return createPortal(children, portalElm);
  return children;
};

export const DataPriorityControlOption = <T extends string>({
  dataHcName,
  active,
  field,
  label,
  description,
  index,
  onToggle,
  disabled,
}: DataPriorityControlOptionProps<T>) => {
  const content = (
    <>
      <div
        className={styles.ColumnToggle}
        data-hc-name={`${dataHcName}-column-toggle`}
      >
        <Checkbox
          dataHcName={`${dataHcName}-checkbox`}
          onChange={onToggle}
          theme={{
            checked: styles.checkboxChecked,
          }}
          className={styles.CheckboxSmall}
          checked={active}
          disabled={disabled}
        />
      </div>
      <div className={styles.ColumnLabel}>
        <div data-hc-name={`${dataHcName}-label`} className={styles.Label}>
          {label || field}
        </div>
        {description && (
          <InfoTooltip
            isLargeTooltip
            size="medium"
            dataHcName={`${dataHcName}-description-tooltip`}
            theme={{
              Trigger: styles.DescriptionTrigger,
            }}
            label={label}
            description={description}
          />
        )}
      </div>
      <div className={styles.DataPriorityIcon}>
        {active && (
          <DataPriorityIcon
            dataHcName={`${dataHcName}-DataPriorityIcon`}
            size={18}
            color="neutral-dark-30"
          />
        )}
      </div>
    </>
  );
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.OrderRow, {
        [styles.disabledOrderRow]: disabled,
      })}
    >
      <div
        data-hc-name={`${dataHcName}-order-number`}
        className={styles.OrderNumber}
      >
        {index + 1}
      </div>
      {active ? (
        <Draggable
          key={`draggable-${field}`}
          draggableId={`draggable-${field}`}
          index={index}
        >
          {(provided, snapshot) => (
            <DraggablePortalHandler snapshot={snapshot}>
              <div
                className={classNames(styles.Column, {
                  [styles.columnDragging]: snapshot.isDragging,
                })}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {content}
              </div>
            </DraggablePortalHandler>
          )}
        </Draggable>
      ) : (
        <div className={styles.Column}>{content}</div>
      )}
    </div>
  );
};
