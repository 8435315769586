import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { LineClamp } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import {
  CerberusStatsFieldConfig,
  CerberusStatsFields,
  CerberusStatsProps,
  CerberusStatsTableCellProps,
  CerberusStatsTableHeaderCellProps,
  CerberusStatsType,
} from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { logValueUnsupportedWarning } from '../../utils/cerberusStatsConfig.utils';

const FIELD = CerberusStatsFields.displayName;
type FieldConfig = CerberusStatsFieldConfig<typeof FIELD>;
// we probably won't use this label or labelShort in practice
const label = 'Display Name';
const labelShort = 'Display Name';
const getValue: FieldConfig['getValue'] = (cerberusStats) => {
  if (!cerberusStats) {
    return undefined;
  }
  if (
    cerberusStats.type !== CerberusStatsType.ListingStats &&
    cerberusStats.type !== CerberusStatsType.RentalListingStats
  ) {
    return cerberusStats?.displayName;
  } else {
    logValueUnsupportedWarning(FIELD, cerberusStats);
    return undefined;
  }
};
const formatValue: FieldConfig['formatValue'] = (cerberusStats) =>
  formatMissing(getValue(cerberusStats));

// Component for displaying a field from a schema
const Display = ({ cerberusStats }: CerberusStatsProps) => {
  return <>{formatValue(cerberusStats)}</>;
};

const HeaderCell = ({
  cerberusStatsType,
  ...restProps
}: CerberusStatsTableHeaderCellProps) => {
  let tableHeaderLabel = label;
  if (cerberusStatsType) {
    if (cerberusStatsType === CerberusStatsType.Msa) {
      tableHeaderLabel = 'MSA Name';
    } else if (cerberusStatsType === CerberusStatsType.Zip) {
      tableHeaderLabel = 'City Name';
    }
  }
  return <TableHeaderCell {...restProps}>{tableHeaderLabel}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  cerberusStats,
  isLineClamp = false,
  ...tableCellProps
}: CerberusStatsTableCellProps) => {
  const display = <Display cerberusStats={cerberusStats} />;
  return (
    <TableCell {...tableCellProps}>
      {isLineClamp && (
        <LineClamp key={`${tableCellProps.dataHcName}-line-clamp`} lines={1}>
          {display}
        </LineClamp>
      )}
      {!isLineClamp && display}
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const DISPLAY_NAME_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  getValue,
  formatValue,
  HeaderCell,
  ContentCell,
  Display,
};
