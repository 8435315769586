import React, { ReactNode } from 'react';

import { UserProfileIcon } from '../../../../svgs/icons/generics';
import { TabPopover } from '../../popovers/TabPopover';

import styles from './UserMenu.module.css';

const dataHcName = 'global-user-menu';

export interface UserMenuProps {
  triggerClassName?: string;
  content: ReactNode;
}

export const UserMenu = ({ triggerClassName, content }: UserMenuProps) => {
  return (
    <TabPopover
      dataHcName={`${dataHcName}-popover`}
      closeOnClick
      theme={{
        Button: triggerClassName,
        Content: styles.Content,
      }}
      trigger={
        <UserProfileIcon
          className={styles.Icon}
          dataHcName={`${dataHcName}-icon`}
          color="neutral-dark-20"
        />
      }
      content={content}
    />
  );
};
