import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import { Offer, OfferRequest } from '@hcs/types';
import { LEAD_FEED_URL } from '@hcs/urls';

const createOffer = async (newOffer: OfferRequest) => {
  await AxiosAccessTokenClientJwt.post<Offer>(
    `${LEAD_FEED_URL}/offer-now/offers`,
    snakecaseKeys(newOffer)
  );
};

export const OfferApi = {
  createOffer,
};
