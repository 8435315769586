import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { BuyBoxPatch } from '@hcs/types';

import { LeadFeedApi } from '../';
import { QUERY_KEY_BUY_BOX_ACTIVITY } from '../';

import { QUERY_KEY_BUY_BOX } from './useBuyBox';
import { QUERY_KEY_BUY_BOX_SUMMARIES } from './useBuyBoxSummaries';

export const useBuyBoxPatch = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, BuyBoxPatch, void>(
    async (patchData) => {
      await LeadFeedApi.patchBuyBox(patchData);
    },
    {
      onSuccess: (emptyResult, patchData) => {
        // the response from the patch endpoint isn't a whole buy box (it's missing the defaults key), so we can't use it to update cache, just have to invalidate
        queryClient.invalidateQueries([
          QUERY_KEY_BUY_BOX,
          { id: patchData.id },
        ]);
        queryClient.invalidateQueries([QUERY_KEY_BUY_BOX_SUMMARIES]);
        queryClient.invalidateQueries([QUERY_KEY_BUY_BOX_ACTIVITY]);
      },
    }
  );
};
